import React, { FC } from 'react';
import { Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslate } from 'translations';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { StyledSpan } from 'routes/Dashboard/Components/organisms/YourRequestWidget/YourRequestWidget.style';

interface Props extends Omit<PopconfirmProps, 'title'> {
  title?: PopconfirmProps['title'];
}

export const ProcessGroupConfirm: FC<Props> = (props) => {
  const { t } = useTranslate();
  const { children, title = t('processManagement.confirm.message'), ...rest } = props;

  return (
    <Popconfirm
      icon={<QuestionCircleOutlined />}
      overlayClassName="ms-teams-confirm"
      placement="topRight"
      title={title}
      okText={t('yes')}
      cancelText={t('no')}
      {...rest}
    >
      <StyledSpan>{children}</StyledSpan>
    </Popconfirm>
  );
};

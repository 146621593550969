import { AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';
import { staticForm } from 'api/StaticForm/StaticForm';

export const useCashAdvanceSubmit = () => {
  return useMutation(async (formData: AxiosRequestConfig['data']) => {
    const { data } = await staticForm.postCashAdvance(formData);
    return data;
  });
};

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const groupsTasks = (state: RootState): RootState['groupsTasksList'] =>
  state.groupsTasksList;

export const groupsTasksData = createSelector(groupsTasks, ({ error, status, data, hasMore }) => ({
  error,
  status,
  data,
  hasMore,
}));

export const ActingAppointmentDetailForm = {
  processDescription:
    'This service to handle travel arrangements for business & training travel purposes.',
  actingAppointmentPurposes: [
    {
      value: 1,
      label: 'Appointment',
    },
    {
      value: 2,
      label: 'Renewal',
    },
  ],
  positionActing: [
    {
      value: 1,
      label: 'Manager',
    },
    {
      value: 2,
      label: 'Director',
    },
    {
      value: 3,
      label: 'GM',
    },
  ],
  department: [
    {
      value: 'Chief Commercial Office',
      label: 'Chief Commercial Office',
    },
    {
      value: 'Chief Operations Officer',
      label: 'Chief Operations Officer',
    },
    {
      value: 'Culture & Engagement',
      label: 'Culture & Engagement',
    },
    {
      value: 'HR Operations',
      label: 'HR Operations',
    },
  ],
  actingDuration: [
    {
      value: 1,
      label: '1 month',
    },
    {
      value: 2,
      label: '2 months',
    },
    {
      value: 3,
      label: '3 months',
    },
    {
      value: 4,
      label: '4 months',
    },
    {
      value: 5,
      label: '5 months',
    },
    {
      value: 6,
      label: '6 months',
    },
    {
      value: 7,
      label: '7 months',
    },
    {
      value: 8,
      label: '8 months',
    },
    {
      value: 9,
      label: '9 months',
    },
    {
      value: 10,
      label: '10 months',
    },
    {
      value: 11,
      label: '11 months',
    },
    {
      value: 12,
      label: '12 months',
    },
  ],
  employeesNames: [{ value: 1, label: 'Ali' }],
};

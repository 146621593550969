import { Col, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { GUTTER_16, StaticFieldsGroup } from 'routes/Tasks';
import { TType } from 'translations';
import { GroupOfGroupFormItem } from 'types';
import { ProductInfoFormWrapper, PRODUCT_INFO_FORM_COL_SPAN } from 'ui/components';
import { space, VerticalSpace } from 'ui/elements';
import { HeaderH2 } from '../FieldsGroup/FieldsGroup.style';

interface FieldsGroupsProps {
  t: TType;
  getFormFields: () => GroupOfGroupFormItem[];
  form?: FormInstance;
}

export const FieldsGroupOfGroupComponent: React.FC<FieldsGroupsProps> = ({
  t,
  getFormFields,
  form,
}) => {
  const formFields = getFormFields();
  return (
    <React.Fragment>
      {formFields.map((groupFields) => {
        return Object.entries(groupFields).map(([groupKey, groupFields], index) => {
          return (
            <ProductInfoFormWrapper key={index}>
              <VerticalSpace size={parseInt(space.moderate)}>
                <Row gutter={GUTTER_16} align={'middle'}>
                  <Col span={PRODUCT_INFO_FORM_COL_SPAN}>
                    <HeaderH2>{groupKey}</HeaderH2>
                  </Col>
                  <Col span={PRODUCT_INFO_FORM_COL_SPAN}>
                    <StaticFieldsGroup
                      t={t}
                      getFormFields={() => groupFields}
                      form={form}
                      isShowSubTitle={true}
                    />
                  </Col>
                </Row>
              </VerticalSpace>
            </ProductInfoFormWrapper>
          );
        });
      })}
    </React.Fragment>
  );
};

export const StaticFieldsGroupOfGroup = React.memo(FieldsGroupOfGroupComponent);

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  GetRequestCatalogDrawerFail,
  GetRequestCatalogDrawerSuccess,
  GetRequestCatalogDrawerStart,
  getRequestCatalogDrawerStart,
  getRequestCatalogDrawerSuccess,
  getRequestCatalogDrawerFail,
} from './requestCatalogDrawerSlice';

export const getRequestCatalogDrawerEpic: Epic<
  GetRequestCatalogDrawerStart,
  GetRequestCatalogDrawerSuccess | GetRequestCatalogDrawerFail,
  RootState,
  typeof API
> = (action$, _, { requestCatalogDrawer }) =>
  action$.pipe(
    filter(getRequestCatalogDrawerStart.match),
    exhaustMap(({ payload }) =>
      from(requestCatalogDrawer.getRequestCatalogDrawerInfo(payload.requestName)).pipe(
        map(({ data }) => getRequestCatalogDrawerSuccess(data)),
        catchError((error) => of(getRequestCatalogDrawerFail(error?.response?.data)))
      )
    )
  );

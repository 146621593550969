import React, { FC, useCallback, useMemo, useState } from 'react';
import { Col, Collapse, Divider, Form, notification, Row, Spin, Typography } from 'antd';
import { span } from 'ui/elements';
import styled from 'styled-components';
import { useForm } from 'antd/lib/form/Form';
import { useTranslate } from 'translations';
import {
  task as taskApi,
  useNonPoGetBudgetCostCenterAccounts,
  useNonPoGetBudgetGetRequestType,
  useNonPoGetBudgetGlAccounts,
  useNonPoGetBudgetProjectAccounts,
  useNonPoSubmitBudgetTeamTask,
} from 'api';
import { useDebounce } from 'ui/hooks';
import { profileData as profileDataSelector, useSelector } from 'store';
import { useTasksContext } from 'routes';
import { PagesUrls } from 'namespace';
import { useHistory } from 'react-router-dom';
import { TaskSubmitFormProps } from '../TaskSubmitForm.types';
import { FieldActionButtons } from '../../../../GeneratedForm/FieldButton';
import { StyledActiveTaskControlWrapper } from '../../TasksSteps.styled';
import { AssigneeInfo, FormCol, StyledCard, TaskStepPanelHeader } from '../../TaskStep';
import { GeneratedForm } from '../../../../GeneratedForm';
import { ButtonsCol } from '../TaskSubmitForm.styled';
import { AttachmentFile, checkWord, filterEmptyFields, submitActiveTask } from '../../helper';
import {
  nonPoBudgetTeamSubmitFormFields,
  nonPoBudgetTeamSubmitFormActionFields,
} from './NonPoBudgetTeamSubmitFormFields.util';

const { Panel } = Collapse;
const { Text } = Typography;

const StyledDivider = styled(Divider)`
  margin: 4px 0 12px 0;
`;
const StyledCollapse = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 20px !important;
    left: 26px;
  }
`;

const { span24 } = span;

const fillWhenRejectionFields = ['feedBack', 'feedback', 'Reason', 'reason'];

export const NonPoBudgetTeamSubmitForm: FC<TaskSubmitFormProps> = ({
  taskInfo,
  reloadTaskList,
  tasksStatus,
  isMyGroupTask,
  processInstanceId,
  activityId,
  processDefinitionId,
}) => {
  const [form] = useForm();
  const { t } = useTranslate();
  const { data: profileData } = useSelector(profileDataSelector);

  const { rootPath } = useTasksContext();
  const history = useHistory();

  const isAssignee = taskInfo.taskAssignee?.adoid;
  const isCurrentUser = isAssignee === profileData?.adOid;

  const [glSearchText, setGlSearchText] = useState<string | undefined>();
  const [costCenterSearchText, setCostCenterSearchText] = useState<string | undefined>();
  const [projectSearchText, setProjectSearchText] = useState<string | undefined>();

  const debouncedGlSearchText = useDebounce(glSearchText, 500);
  const debouncedCostCenterSearchText = useDebounce(costCenterSearchText, 500);
  const debouncedProjectSearchText = useDebounce(projectSearchText, 500);

  const { mutateAsync: submitBudgetTeamTask, isLoading } = useNonPoSubmitBudgetTeamTask();
  const {
    data: requestTypeData,
    isLoading: isRequestTypeLoading,
  } = useNonPoGetBudgetGetRequestType(processInstanceId);
  const { data: glAccountsData, isLoading: glAccountsLoading } = useNonPoGetBudgetGlAccounts(
    debouncedGlSearchText
  );
  const {
    data: costCenterAccountsData,
    isLoading: costCenterAccountsLoading,
  } = useNonPoGetBudgetCostCenterAccounts(debouncedCostCenterSearchText);
  const {
    data: projectAccountsData,
    isLoading: projectAccountsLoading,
  } = useNonPoGetBudgetProjectAccounts(debouncedProjectSearchText);

  const formData = useMemo(() => {
    return nonPoBudgetTeamSubmitFormFields(
      {
        glAccounts: glAccountsData,
        costCenterAccounts: costCenterAccountsData,
        projectAccounts: projectAccountsData,
      },
      {
        glAccountsLoading,
        costCenterAccountsLoading,
        projectAccountsLoading,
      },
      {
        onGlAccountsSearch: (value) => (value ? setGlSearchText(value) : null),
        onCostCenterAccountsSearch: (value) => (value ? setCostCenterSearchText(value) : null),
        onProjectAccountsSearch: (value) => (value ? setProjectSearchText(value) : null),
      },
      !(isCurrentUser || isMyGroupTask),
      requestTypeData?.distributionCombination ?? undefined
    );
  }, [
    costCenterAccountsData,
    costCenterAccountsLoading,
    glAccountsData,
    glAccountsLoading,
    isCurrentUser,
    isMyGroupTask,
    projectAccountsData,
    projectAccountsLoading,
    requestTypeData,
  ]);

  const onSuccessTaskApprove = useCallback(() => {
    notification.success({
      message: t('messages.success'),
      description: t('messages.success.task.completed'),
    });
    reloadTaskList();
    history.push(PagesUrls.MyWork);
  }, [history, reloadTaskList, t]);

  const onSubmit = useCallback(async () => {
    const info = form.getFieldsValue();
    const attachmentsForm: AttachmentFile[] = [];
    const formValues = Object.keys(info);
    const attachmentFields = formValues.filter(
      (k) => k.includes('attachment') || k.includes('Attachment')
    );
    const attachmentFieldId = attachmentFields.length > 0 ? attachmentFields[0] : 'attachment';
    const attachments = info[attachmentFieldId];
    if (attachments) {
      attachmentsForm.push({ [attachmentFieldId]: attachments });
    }
    delete info[attachmentFieldId];
    const rejectFeedBack = formValues.filter((key) => key.includes('rejected'));
    const feedBackField = formValues.filter((key) => checkWord(fillWhenRejectionFields, key));
    const checkRejectedWithFeedback = submitActiveTask(rejectFeedBack, feedBackField, {
      values: info,
      forms: form,
    });
    if (checkRejectedWithFeedback) {
      notification.error({
        message: t('title.error.feedbackRequire'),
        description: t('messages.error.fillFeedbackField'),
      });
    } else {
      delete info['description'];
      if (taskInfo?.taskId) {
        try {
          // setFormLoading(true);
          const attachmentPromises = attachmentsForm.map((attachments: AttachmentFile) => {
            return Object.entries(attachments).map(([attachmentName, attachmentFile]) => {
              return attachmentFile.map(async (attachmentInfo) => {
                const formData = new FormData();
                formData.append('content', attachmentInfo.originFileObj as Blob);
                formData.append('attachmentName', attachmentInfo.name);
                formData.append('filedId', attachmentName);
                formData.append('attachmentDescription', attachmentInfo.name);
                formData.append('attachmentType', attachmentInfo.type);

                return await taskApi.postNonPoTaskAttachment(
                  taskInfo?.taskId,
                  processInstanceId,
                  processDefinitionId,
                  activityId,
                  formData,
                  rootPath
                );
              });
            });
          });
          await Promise.all([
            submitBudgetTeamTask({
              processInstanceId,
              taskId: taskInfo.taskId,
              data: filterEmptyFields(info),
            }),
            attachments ? Promise.all(attachmentPromises) : undefined,
          ]);
          onSuccessTaskApprove();
        } catch (e) {
          notification.error({
            message: t('messages.error.wentWrong'),
            description: t('messages.error.fillForm'),
          });
          return;
        }
      }
    }
  }, [
    activityId,
    form,
    onSuccessTaskApprove,
    processDefinitionId,
    processInstanceId,
    rootPath,
    submitBudgetTeamTask,
    t,
    taskInfo.taskId,
  ]);

  return (
    <Spin spinning={isRequestTypeLoading}>
      <Form form={form} name={'fieldRunnerCandidatesForm'} layout={'vertical'} scrollToFirstError>
        <StyledCard $highlighted>
          <StyledCollapse defaultActiveKey="1" className="tasks_collapse" ghost>
            <Panel
              header={<TaskStepPanelHeader header={taskInfo.taskName} status={taskInfo?.status} />}
              style={{ alignItems: 'center' }}
              key="1"
              extra={
                <AssigneeInfo
                  taskInfo={taskInfo}
                  t={t}
                  reloadTaskList={reloadTaskList}
                  tabStatus={tasksStatus}
                />
              }
            >
              <Row gutter={[24, 24]}>
                {taskInfo.prettyId && (
                  <Col span={24}>
                    <Text type="secondary">{`Task ID: #${taskInfo.prettyId}`}</Text>
                  </Col>
                )}
                <Col span={24}>
                  <StyledDivider />
                </Col>

                <FormCol span={span24}>
                  {!isRequestTypeLoading && (
                    <GeneratedForm
                      withLabel={false}
                      formData={formData}
                      formName={taskInfo?.prettyId}
                      onSubmit={onSubmit}
                      form={form}
                    />
                  )}
                </FormCol>
                {taskInfo && (isCurrentUser || isMyGroupTask) && (
                  <Col span={24}>
                    <FieldActionButtons
                      fields={nonPoBudgetTeamSubmitFormActionFields(onSubmit, isLoading)}
                      form={form}
                      taskId={taskInfo?.taskId}
                      fixed={true}
                    />
                  </Col>
                )}
              </Row>
            </Panel>
          </StyledCollapse>
        </StyledCard>
        {taskInfo && (isCurrentUser || isMyGroupTask) && (
          <StyledActiveTaskControlWrapper>
            <ButtonsCol />
          </StyledActiveTaskControlWrapper>
        )}
      </Form>
    </Spin>
  );
};

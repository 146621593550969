import styled from 'styled-components';
import { Collapse, Menu } from 'antd';

import { colors } from 'ui/elements';

export const SideBar = styled.div`
  min-height: calc(100vh - 56px);
  border-right: 1px solid ${colors.borderGreySecond};
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  align: center;
  justify-content: space-between;
`;

export const StyledCollapse = styled(Collapse)`
  margin-top: 20px;

  & > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 50%;
    transform: translateY(-50%);
    left: 26px;
  }

  &.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-left: 0;
  }

  & > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }

  &.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse-arrow svg {
    fill: ${colors.lightGrey};
    width: 9px;
  }

  .ant-menu {
    padding: 6px 0;
  }
`;

export const StyledPlanMenuItem = styled(Menu.Item)`
  &.ant-menu-item.ant-menu-item-only-child,
  &.ant-menu-item.sidebar-menu-item {
    margin: 0;
    margin-bottom: 0;
    padding: 8px 24px;
    height: auto;

    &.ant-menu-item.ant-menu-item-selected,
    &.ant-menu-item.ant-menu-item-active {
      background-color: #f6f7f9;
    }
  }
`;

export const StyledCategoryRow = styled.div`
  padding-left: 46px;
  font-size: 12px;
  color: ${colors.lightGrey};
`;

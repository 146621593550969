import { connect, ConnectedProps } from 'react-redux';

import { RootState, getTeamsUserAvatarStart, teamsUserAvatarData } from 'store';
import { MSTeamsUserAvatarProps } from './MSTeamsUserAvatar';

const mapDispatch = {
  getTeamsUserAvatarStart,
};

const mapState = (state: RootState, props: MSTeamsUserAvatarProps) => ({
  teamsUserAvatar: teamsUserAvatarData(state, props),
});

export const teamsUserAvatarConnector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof teamsUserAvatarConnector>;

export type ConnectorProps = PropsFromRedux;

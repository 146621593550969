import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AllRequestsCard as CategoryCard, RequestCommonError, RequestStatus } from 'types';
import { CategoryCardState } from './categoryCardType';

export const emptyCategoryCardState: CategoryCardState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetCategoryCardStart = PayloadAction<{ categoryName: string }>;
export type GetCategoryCardSuccess = PayloadAction<CategoryCard>;
export type GetCategoryCardFail = PayloadAction<RequestCommonError>;
export type GetCategoryCardReset = PayloadAction;

const categoryCard = createSlice({
  name: 'categoryCard',
  initialState: emptyCategoryCardState,
  reducers: {
    getCategoryCardStart(state, _: GetCategoryCardStart) {
      state.status = RequestStatus.pending;
    },
    getCategoryCardSuccess(state, action: GetCategoryCardSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getCategoryCardFail(state, action: GetCategoryCardFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getCategoryCardReset(state, _: GetCategoryCardReset) {
      state.status = RequestStatus.idle;
      state.error = null;
      state.data = undefined;
    },
  },
});

export const {
  getCategoryCardFail,
  getCategoryCardStart,
  getCategoryCardSuccess,
  getCategoryCardReset,
} = categoryCard.actions;
export default categoryCard.reducer;

import React from 'react';

import { useTranslate } from 'translations';
import { OtherUserProfileDate } from 'types';
import { PublicProfileContent } from './PublicProfileContent';

export interface Props {
  userProfileData: OtherUserProfileDate;
  email: string;
}

export const PublicProfile: React.FC<Props> = ({ userProfileData, email }) => {
  const { t } = useTranslate();

  return <PublicProfileContent data={userProfileData} t={t} email={email} />;
};

export enum ChangeBankAccountFieldIds {
  EmployeeBankName = 'employeeBankName',
  NewEmployeeBankName = 'newEmployeeBankName',
  Comments = 'Comments',
  IBAN = 'iban',
  CLEARANCE = 'clearance',
}

export interface CBARtLookups {
  processDescription: string;
  currentBank: string;
  banksDTOList: CBARBanksDTOList[];
  displayClearance: boolean;
}

interface CBARBanksDTOList {
  id: number;
  arabicName: string;
  englishName: string;
}

export interface CBARSubmitPostParams {
  employeeBankName: string;
  newEmployeeBankId: string;
  comments: string;
}

export interface CBARSubmitResponse {
  taskId: string;
  processInstanceId: string;
  processDefinitionId: string;
  activityId: string;
  prettyId: string;
}

import React, { FC } from 'react';
import { Col, Row } from 'antd';

import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { ChatMessageData } from 'types';
import { UserPopover } from 'ui';
import { ChatMessage } from '../ChatMessage';

export const ReplyItem: FC<ChatMessageData> = ({ createdDateTime, body, from }) => (
  <Row gutter={[18, 18]} wrap={false} justify="start">
    <Col>
      <UserPopover currentID={from?.user?.id}>
        <MSTeamsUserAvatar
          id={from?.user?.id}
          name={from?.user?.displayName}
          statusSize={12}
          size={40}
        />
      </UserPopover>
    </Col>

    <Col>
      <ChatMessage
        title={from?.user?.displayName}
        createdDateTime={createdDateTime}
        message={body.content}
        messageType={body.contentType}
        small
      />
    </Col>
  </Row>
);

import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from 'api/constants';
import { newProfile } from '../NewProfile';

export const useGetProfileBasicInfo = () =>
  useQuery(
    [QUERIES.profileBasicInfo],
    async () => {
      const { data } = await newProfile.getProfileBasicInfo();
      return data;
    },
    {
      ...PERSIST_QUERY_CONFIG,
    }
  );

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const processDefinitions = (state: RootState): RootState['processDefinitionList'] =>
  state.processDefinitionList;

export const processDefinitionListData = createSelector(
  processDefinitions,
  ({ error, status, data }) => ({
    error,
    status,
    data,
  })
);

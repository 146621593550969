import React, { FC, memo, useEffect, useState } from 'react';

import { Col, Popover, Row } from 'antd';

import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { RequestTeamMember } from 'types/DashboardRequestsCard';
import { UserPopover } from 'ui';

import { Gutter } from 'antd/lib/grid/row';
import { StyledListMoreIcon, StyledPopoverContent, StyledText } from './AvatarGroupStyled';

interface Props {
  groupMember: RequestTeamMember[];
}

const Gutter_12: [Gutter, Gutter] = [12, 8];

export const AvatarListComponent: FC<Props> = ({ groupMember }) => {
  const restOfGroupMembers = groupMember.length;
  const [numberOfMember, setNumberOfMember] = useState<number>();

  useEffect(() => {
    if (restOfGroupMembers > 2) {
      setNumberOfMember(restOfGroupMembers - 2);
    } else {
      setNumberOfMember(restOfGroupMembers);
    }
  }, [restOfGroupMembers]);
  const content = (
    <StyledPopoverContent
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {groupMember &&
        groupMember.map((member: RequestTeamMember, index) => (
          <>
            <Row justify={'start'} align={'middle'} key={member.adoid} gutter={Gutter_12}>
              <Col>
                <UserPopover currentID={member.adoid}>
                  <MSTeamsUserAvatar key={member.adoid} id={member.adoid} name={member.name} />
                </UserPopover>
              </Col>
              <Col>
                <StyledText>{member.name}</StyledText>
              </Col>
            </Row>
          </>
        ))}
    </StyledPopoverContent>
  );

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popover content={content} trigger="hover" placement="bottom">
        <StyledListMoreIcon>+{numberOfMember}</StyledListMoreIcon>
      </Popover>
    </div>
  );
};
export const AvatarList = memo(AvatarListComponent);

import React, { FC } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { StyledCustomPlusMinusCollapse } from './CustomCollapse.styled';

export const CustomCollapseWithPlusMinusIcons: FC = ({ children }) => {
  return (
    <StyledCustomPlusMinusCollapse
      className="styled-custom-plus-minus-collapse"
      bordered={false}
      expandIconPosition="right"
      expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
      defaultActiveKey={[`candidate-group-0`]}
      ghost
    >
      {children}
    </StyledCustomPlusMinusCollapse>
  );
};

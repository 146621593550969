import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, TeamsChannelMembersData } from 'types';
import { TeamsChannelMembersState } from './teamsChannelMembersTypes';

export const emptyTeamsChannelMembersState: TeamsChannelMembersState = {};

export type GetTeamsChannelMembersStart = PayloadAction<{ teamId: string; channelId: string }>;
export type GetTeamsChannelMembersSuccess = PayloadAction<{
  teamId: string;
  channelId: string;
  data: TeamsChannelMembersData;
}>;
export type GetTeamsChannelMembersFail = PayloadAction<{
  teamId: string;
  channelId: string;
  error: RequestCommonError;
}>;

const teamsChannels = createSlice({
  name: 'teamsChannelMembers',
  initialState: emptyTeamsChannelMembersState,
  reducers: {
    getTeamsChannelMembersStart(state, action: GetTeamsChannelMembersStart) {
      if (state[action.payload.teamId]) {
        if (state[action.payload.teamId][action.payload.channelId]) {
          state[action.payload.teamId][action.payload.channelId].status = RequestStatus.pending;

          return;
        }

        state[action.payload.teamId] = {
          ...state[action.payload.teamId],
          [action.payload.channelId]: {
            error: null,
            status: RequestStatus.pending,
          },
        };

        return;
      }

      state[action.payload.teamId] = {
        [action.payload.channelId]: {
          error: null,
          status: RequestStatus.pending,
        },
      };
    },
    getTeamsChannelMembersSuccess(state, action: GetTeamsChannelMembersSuccess) {
      state[action.payload.teamId][action.payload.channelId].status = RequestStatus.resolved;
      state[action.payload.teamId][action.payload.channelId].data = action.payload.data;
    },
    getTeamsChannelMembersFail(state, action: GetTeamsChannelMembersFail) {
      state[action.payload.teamId][action.payload.channelId].status = RequestStatus.rejected;
      state[action.payload.teamId][action.payload.channelId].error = action.payload.error;
    },
  },
});

export const {
  getTeamsChannelMembersStart,
  getTeamsChannelMembersSuccess,
  getTeamsChannelMembersFail,
} = teamsChannels.actions;
export default teamsChannels.reducer;

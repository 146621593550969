import { Gutter } from 'antd/lib/grid/row';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslate } from 'translations';
import { DrawerUi } from 'ui/components';
import { FullWidthSpin, span } from 'ui/elements';
import {
  AllUpdatesStyled,
  ColListStyled,
  RequestIdStyled,
  TemplateRowGeneratedStyled,
} from './components/SeeAllUpdate.style';
import { GeneratedSeeAllUpdate } from './components/SeeAllUpdateGenerated';
import { useSeeAllUpdateDrawerInfo } from './components/useSeeAllUpdateDrawerInfo';
import { OnAllUpdatedNotificationClickProps } from './components';

interface Props {
  isVisible: boolean;
  title: string;
  requestId: string;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onAllUpdatedNotificationClick: (props: OnAllUpdatedNotificationClickProps) => void;
}
const GUTTER_12_32: [Gutter, Gutter] = [12, 33];
const SeeAllUpdateDrawerComponent: React.FC<Props> = ({
  isVisible,
  setIsVisible,
  title,
  requestId,
  onAllUpdatedNotificationClick,
}) => {
  const { t } = useTranslate();
  const { span12 } = span;
  const {
    filterList,
    hasMore,
    loadMoreNotificationUpdates,
    onClose,
    listLength,
    isThereData,
    isErrorFetchAllNotificationUpdatesList,
    reFreshAllNotificationUpdatesList,
  } = useSeeAllUpdateDrawerInfo(requestId, isVisible, setIsVisible);

  const onClickOfSeAllUpdateNotification = (props: OnAllUpdatedNotificationClickProps) => {
    onAllUpdatedNotificationClick(props);
    reFreshAllNotificationUpdatesList();
  };
  return (
    <React.Fragment>
      <DrawerUi
        visible={isVisible}
        drawerTitle={title.trim()}
        keyboard
        onClose={onClose}
        destroyOnClose
        closable={true}
      >
        <TemplateRowGeneratedStyled gutter={GUTTER_12_32}>
          <ColListStyled span={span12}>
            <AllUpdatesStyled>{t('notification.drawer.allUpdates')}</AllUpdatesStyled>
          </ColListStyled>
          <ColListStyled span={span12}>
            <RequestIdStyled>{requestId}</RequestIdStyled>
          </ColListStyled>
        </TemplateRowGeneratedStyled>
        {isThereData ? (
          <FullWidthSpin />
        ) : isErrorFetchAllNotificationUpdatesList ? (
          onClose(false)
        ) : (
          filterList && (
            <InfiniteScroll
              loadMore={loadMoreNotificationUpdates}
              hasMore={hasMore}
              useWindow={false}
              loader={<FullWidthSpin key={0} />}
              initialLoad={false}
            >
              <GeneratedSeeAllUpdate
                onAllUpdatedNotificationClick={onClickOfSeAllUpdateNotification}
                allNotificationUpdatesList={filterList}
                key={listLength}
                refreshList={() => onClose(false)}
              />
            </InfiniteScroll>
          )
        )}
      </DrawerUi>
    </React.Fragment>
  );
};

export const SeeAllUpdateDrawer = React.memo(SeeAllUpdateDrawerComponent);

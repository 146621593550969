import React, { FC, useCallback } from 'react';
import { FormInstance } from 'antd/lib/form';
import { TType } from 'translations';
import { CreateFormItemType, IProfileUpdateEducationalDegreeMapped, SelectOptions } from 'types';
import {
  disableFutureDate,
  englishPatternRule,
  FORM_ITEM_50_CHARACTER_RULES,
  FORM_ITEM_REQUIRED_RULE,
} from 'utils';
import { WRAPPER_COL_SPAN_SIZE_21 } from 'ui/components';
import { UploadFile } from 'antd/lib/upload/interface';
import { CustomFormGroupWrapper } from './CustomFormGroupWrapper';

type Props = {
  t: TType;
  form?: FormInstance;
  hasInitialValues: boolean;
  degreeTypeLookups: SelectOptions;
  educationalDegrees?: IProfileUpdateEducationalDegreeMapped[];
};

export const ProfileUpdateEducationalDegreeFormGroup: FC<Props> = ({
  t,
  form,
  hasInitialValues,
  degreeTypeLookups,
  educationalDegrees,
}) => {
  const onEducationalDegreeFormRemove = (index: number) => {
    if (educationalDegrees?.[index]) {
      educationalDegrees.splice(index, 1);
    }
  };

  const onAttachmentChange = useCallback(
    (formListIndex: number, file?: UploadFile | UploadFile[]) => {
      if (form) {
        form.setFields([
          {
            name: ['educationalDegrees', formListIndex, 'educationalDegreeAttachmentList'],
            value: file,
          },
        ]);
      }
    },
    [form]
  );
  const educationalDegreeFormGroup = useCallback<(formListName: number) => CreateFormItemType[]>(
    (formListName) => {
      return [
        {
          id: `${0}`,
          label: 'Degree type',
          name: [formListName, 'degreeType'],
          type: 'select',
          options: degreeTypeLookups,
          selectTheValueAsKey: true,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Choose degree type',
          disabled: !!educationalDegrees?.[formListName]?.degreeType,
        },
        {
          id: `${1}`,
          name: [formListName, 'erpEducationId'],
          type: 'input',
          hidden: true,
        },
        {
          id: `${2}`,
          label: 'University name',
          name: [formListName, 'universityName'],
          type: 'input',
          rules: [
            ...FORM_ITEM_REQUIRED_RULE,
            ...FORM_ITEM_50_CHARACTER_RULES,
            englishPatternRule(t),
          ],
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Enter university name',
        },
        {
          id: `${2}`,
          label: 'Major',
          name: [formListName, 'major'],
          type: 'input',
          rules: [
            ...FORM_ITEM_REQUIRED_RULE,
            ...FORM_ITEM_50_CHARACTER_RULES,
            englishPatternRule(t),
          ],
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Enter major',
        },
        {
          id: `${4}`,
          label: 'Start date',
          name: [formListName, 'startDate'],
          type: 'date',
          disabledDate: disableFutureDate,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Choose start date',
        },
        {
          id: `${5}`,
          label: 'End date',
          name: [formListName, 'endDate'],
          type: 'date',
          disabledDate: disableFutureDate,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Choose end date',
        },
        {
          id: `${6}`,
          label: 'Educational Degrees',
          name: [formListName, 'educationalDegreeAttachmentList'],
          type: 'file',
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          valuePropName: 'fileList',
          attachmentFileExtensions: '.png,.pdf',
          attachmentFileTypes: 'image/png,application/pdf',
          onFileChange: (file) => {
            onAttachmentChange(formListName, file);
          },
          disabled: !!educationalDegrees?.[formListName]?.educationalDegreeAttachmentList,
        },
      ];
    },
    [degreeTypeLookups, educationalDegrees, onAttachmentChange, t]
  );
  return (
    <CustomFormGroupWrapper
      t={t}
      maxRecords={10}
      formGroupItems={educationalDegreeFormGroup}
      formListName="educationalDegrees"
      sectionTitle="Educational Degree"
      addButtonTitle="Add additional degrees"
      hasAtLeastOne={true}
      hasInitialValue={hasInitialValues}
      form={form}
      onListRemove={onEducationalDegreeFormRemove}
    />
  );
};

import styled from 'styled-components';
import { Button } from 'antd';
import { colors } from 'ui/elements';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100vh;
`;

export const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
  .ant-form-item .ant-input-textarea-show-count::after {
    position: absolute;
    width: 100%;
  }
`;

export const Footer = styled.div`
  flex-shrink: 0;
  background-color: ${colors.white};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${colors.lightGreySecond};
`;

export const StyledSubmitButton = styled(Button)`
  color: ${colors.white};
  background-color: ${colors.orangeShadow};
  border-color: ${colors.orangeShadow};

  &:hover,
  &:focus {
    color: ${colors.white};
    background-color: ${colors.orange};
    border-color: ${colors.orange};
  }
`;

import React, { useCallback } from 'react';
import { Form, Spin } from 'antd';
import { VALIDATE_MESSAGES } from 'utils';
import { useForm } from 'antd/lib/form/Form';
import { useTranslate } from 'translations';
import { FieldData } from 'rc-field-form/lib/interface';
import { WFHFormFields } from './Components';
import { useWFHRequest } from './hooks';

export type WorkFromHomeRequestFormProps = {
  formName?: string;
  adoId?: string;
};
export const WorkFromHomeRequestForm: React.FC<WorkFromHomeRequestFormProps> = ({
  formName,
  adoId,
}: WorkFromHomeRequestFormProps) => {
  const { t } = useTranslate();
  const [form] = useForm();
  const {
    validateDateRange,
    spinning,
    disabledDate,
    isLoadingWFHBalance,
    wfhBalance,
    durationWFHDays,
    remainingWFHDays,
    wfhDaysBalance,
    isLoadingCalculateDuration,
    onSubmitForm,
  } = useWFHRequest({ form, adoId });

  /**
   * Handle on field change
   */
  const onFieldsChange = useCallback((changedFields: FieldData[]) => {
    /**
     * Handle on form change
     */
  }, []);

  return (
    <React.Fragment>
      <Spin spinning={spinning} delay={100}>
        <Form
          form={form}
          layout="vertical"
          name={formName}
          validateMessages={VALIDATE_MESSAGES(t)}
          onFieldsChange={onFieldsChange}
          onFinish={onSubmitForm}
        >
          <WFHFormFields
            form={form}
            adoId={adoId}
            disabledDate={disabledDate}
            isLoadingWFHBalance={isLoadingWFHBalance}
            wfhBalance={wfhBalance}
            durationWFHDays={durationWFHDays}
            remainingWFHDays={remainingWFHDays}
            wfhDaysBalance={wfhDaysBalance}
            isLoadingCalculateDuration={isLoadingCalculateDuration}
            onRangeDateChange={() => validateDateRange()}
          />
        </Form>
      </Spin>
    </React.Fragment>
  );
};

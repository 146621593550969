import React, { FC, memo } from 'react';
import { FullWidthSpin } from 'ui';
import moment from 'moment';
import parse from 'html-react-parser';
import { SettingOutlined } from '@ant-design/icons';
import { useTranslate } from '../../../translations';
import {
  StyleCommentContainerRow,
  StyledActivityContainer,
  StyledActivityIcon,
  StyledCommentBoxHeader,
  StyledCommentParentContainer,
  StyledCommentText,
  StyledCommentTimeText,
  StyledCommentUserNameText,
  StyledCommentUserPopoverContainer,
} from './Comment.styled';

export interface TaskCommentProps {
  id: string;
  message: string;
  time: Date | string;
  isLastCommentLoading: boolean;
}

export const TaskActivityComponent: FC<TaskCommentProps> = ({
  id,
  message,
  time,
  isLastCommentLoading,
}) => {
  const { t } = useTranslate();
  if (isLastCommentLoading) {
    return <FullWidthSpin />;
  }

  return (
    <StyleCommentContainerRow>
      <StyledCommentUserPopoverContainer>
        <StyledActivityIcon>
          <SettingOutlined style={{ fontSize: '22px', color: '#898C9F' }} />
        </StyledActivityIcon>
      </StyledCommentUserPopoverContainer>

      <StyledCommentParentContainer>
        <StyledActivityContainer>
          <StyledCommentBoxHeader>
            <StyledCommentUserNameText>{t('task.activity')}</StyledCommentUserNameText>
            <StyledCommentTimeText>{moment(time).format('DD.MM.YYYY')}</StyledCommentTimeText>
          </StyledCommentBoxHeader>
          <StyledCommentText type="secondary">{parse(message)}</StyledCommentText>
        </StyledActivityContainer>
      </StyledCommentParentContainer>
    </StyleCommentContainerRow>
  );
};

export const TaskActivity = memo(TaskActivityComponent);

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';
import * as API from 'api';
import { RootState } from 'store';
import { Epic } from '../types';
import {
  getNotificationListFil,
  getNotificationListStart,
  getNotificationListSuccess,
  GetNotificationsListFail,
  GetNotificationsListStart,
  GetNotificationsListSuccess,
} from './notificationListSlice';

export const notificationsListEpic: Epic<
  GetNotificationsListStart,
  GetNotificationsListSuccess | GetNotificationsListFail,
  RootState,
  typeof API
> = (action$, _, { notificationCenter }) =>
  action$.pipe(
    filter(getNotificationListStart.match),
    exhaustMap((action) =>
      from(notificationCenter.getNotificationsList(action.payload.params)).pipe(
        map(
          ({ data }) => getNotificationListSuccess(data),
          catchError((error) => of(getNotificationListFil(error?.response.data)))
        )
      )
    )
  );

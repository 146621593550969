import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetRequestPrettyIdStart,
  GetRequestPrettyIdSuccess,
  GetRequestPrettyIdFail,
  getRequestPrettyIdStart,
  getRequestPrettyIdSuccess,
  getRequestPrettyIdFail,
} from './requestPrettyIdSlice';

export const getRequestPrettyIdEpic: Epic<
  GetRequestPrettyIdStart,
  GetRequestPrettyIdSuccess | GetRequestPrettyIdFail,
  RootState,
  typeof API
> = (action$, _, { task }) =>
  action$.pipe(
    filter(getRequestPrettyIdStart.match),
    exhaustMap((payload) =>
      from(task.getRequestPrettyId(payload.payload.ids)).pipe(
        map(({ data }) =>
          getRequestPrettyIdSuccess({
            data,
          })
        ),
        catchError((error) =>
          of(getRequestPrettyIdFail({ error: error?.response?.data, ids: payload.payload.ids }))
        )
      )
    )
  );

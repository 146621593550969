import React, { DependencyList, useEffect } from 'react';
import { notification } from 'antd';

interface NotificationArgs {
  isNotification?: boolean;
  message: string | React.ReactNode;
  description: string | React.ReactNode;
  duration?: number;
  btn?: React.ReactNode;
  closeIcon?: React.ReactNode | null;
}

export const useSuccessNotification = (args: NotificationArgs, deps: DependencyList) =>
  useNotification(
    {
      ...args,
      type: 'success',
    },
    deps
  );

export const useErrorNotification = (args: NotificationArgs, deps: DependencyList) =>
  useNotification(
    {
      ...args,
      type: 'error',
    },
    deps
  );

export const useNotification = (
  {
    isNotification,
    message,
    description,
    type,
    duration,
    btn,
    closeIcon,
  }: NotificationArgs & { type: 'error' | 'success' },
  deps: DependencyList
) => {
  useEffect(() => {
    if (isNotification) {
      notification[type]({
        message,
        description,
        duration,
        btn,
        closeIcon,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

import { useQuery } from 'react-query';
import { staticForm, QUERIES, PERSIST_QUERY_CONFIG } from 'api';
import { AxiosRequestConfig } from 'axios';

export const useHomeLoanInterest = (config?: AxiosRequestConfig) => {
  return useQuery(
    [QUERIES.homeLoanInterest],

    async () => (await staticForm.getHomeLoanInterest(config)).data,
    {
      enabled: false,
      ...PERSIST_QUERY_CONFIG,
    }
  );
};

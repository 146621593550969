import React, { FC, memo, useCallback, useEffect } from 'react';
import { Col, Drawer, notification, Row, Spin } from 'antd';
import { constants } from 'ui';
import moment from 'moment';
import { useTranslate } from 'translations';
import { useELibraryDocumentInfo, useELibraryDocumentDownload } from 'api';
import { saveAs } from 'file-saver';
import {
  StyledContent,
  StyledDocumentInfoLabel,
  StyledDocumentInfoValue,
  StyledDocumentTitle,
  StyledDownloadDocument,
  StyledDrawerFooter,
  StyledViewDocumentButton,
  StyledWrapper,
} from '../ELibrary.styled';

interface Props {
  isVisible: boolean;
  closeDrawer: () => void;
  documentID: string;
}

const ELibraryDocumentDrawerComponent: FC<Props> = ({ isVisible, closeDrawer, documentID }) => {
  const { t } = useTranslate();
  const { data: documentInfo, isLoading, isError } = useELibraryDocumentInfo(documentID, isVisible);
  const mutationResultDownloadDoc = useELibraryDocumentDownload();

  const showError = useCallback(
    () =>
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      }),
    [t]
  );

  useEffect(() => {
    if (isVisible && isError) {
      showError();
    }
  }, [isError, isVisible, showError]);

  const viewFile = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const handleOnDownloadClick = async () => {
    if (documentInfo?.serverRelativeUrl) {
      try {
        const data = await mutationResultDownloadDoc.mutateAsync({
          params: {
            responseType: 'blob',
          },
          serverRelativeURL: documentInfo?.serverRelativeUrl,
        });
        saveAs(data, getFileNameFromPath(documentInfo?.serverRelativeUrl));
      } catch (e) {
        showError();
      }
    }
  };

  const getFileNameFromPath = (serverRelativeURL: string): string => {
    const pathArray = serverRelativeURL.split('/');
    return decodeURI(pathArray[pathArray.length - 1]);
  };

  return (
    <Drawer
      closable
      destroyOnClose
      maskClosable
      visible={isVisible}
      onClose={closeDrawer}
      placement="right"
      width={constants.drawer.width}
    >
      <StyledWrapper>
        <StyledContent>
          {isLoading ? (
            <Spin style={{ width: '100%' }} />
          ) : (
            <>
              <StyledDocumentTitle
                ellipsis={{
                  rows: 2,
                  tooltip: documentInfo?.title,
                }}
              >
                {documentInfo?.title}
              </StyledDocumentTitle>
              <Row
                gutter={[
                  { xs: 8, sm: 15, md: 20, lg: 20 },
                  { xs: 8, sm: 15, md: 20, lg: 20 },
                ]}
                wrap={true}
              >
                <Col sm={24} md={12}>
                  <StyledDocumentInfoLabel>{t('eLibrary.documentID')}</StyledDocumentInfoLabel>
                  <StyledDocumentInfoValue>
                    {documentInfo?.documentId || '--'}
                  </StyledDocumentInfoValue>
                </Col>
                <Col sm={24} md={12}>
                  <StyledDocumentInfoLabel>{t('eLibrary.classification')}</StyledDocumentInfoLabel>
                  <StyledDocumentInfoValue>
                    {documentInfo?.classification || '--'}
                  </StyledDocumentInfoValue>
                </Col>
                <Col sm={24} md={12}>
                  <StyledDocumentInfoLabel>{t('eLibrary.documentType')}</StyledDocumentInfoLabel>
                  <StyledDocumentInfoValue>
                    {documentInfo?.documentType || '--'}
                  </StyledDocumentInfoValue>
                </Col>
                <Col sm={24} md={12}>
                  <StyledDocumentInfoLabel>{t('eLibrary.versionNumber')}</StyledDocumentInfoLabel>
                  <StyledDocumentInfoValue>
                    {documentInfo?.versionNumber || '--'}
                  </StyledDocumentInfoValue>
                </Col>
                <Col sm={24} md={12}>
                  <StyledDocumentInfoLabel>{t('eLibrary.status')}</StyledDocumentInfoLabel>
                  <StyledDocumentInfoValue>{documentInfo?.status || '--'}</StyledDocumentInfoValue>
                </Col>
                <Col sm={24} md={12}>
                  <StyledDocumentInfoLabel>{t('eLibrary.documentOwner')}</StyledDocumentInfoLabel>
                  <StyledDocumentInfoValue>
                    {documentInfo?.documentOwner || '--'}
                  </StyledDocumentInfoValue>
                </Col>
                <Col sm={24} md={12}>
                  <StyledDocumentInfoLabel>{t('eLibrary.effectiveDate')}</StyledDocumentInfoLabel>
                  <StyledDocumentInfoValue>
                    {documentInfo?.effectiveDate
                      ? moment(documentInfo?.effectiveDate).format('DD-MMM-YYYY')
                      : '--'}
                  </StyledDocumentInfoValue>
                </Col>
                <Col sm={24} md={12}>
                  <StyledDocumentInfoLabel>{t('eLibrary.revisionDate')}</StyledDocumentInfoLabel>
                  <StyledDocumentInfoValue>
                    {documentInfo?.revisionDate
                      ? moment(documentInfo?.revisionDate).format('DD-MMM-YYYY')
                      : '--'}
                  </StyledDocumentInfoValue>
                </Col>
              </Row>
            </>
          )}
        </StyledContent>
        {!isLoading && (
          <StyledDrawerFooter>
            <Row>
              <StyledViewDocumentButton onClick={() => viewFile(documentInfo?.documentUrl || '')}>
                {t('viewFile')}
              </StyledViewDocumentButton>
              <StyledDownloadDocument
                loading={mutationResultDownloadDoc.isLoading}
                onClick={handleOnDownloadClick}
                type="link"
                download
              >
                {t('download')}
              </StyledDownloadDocument>
            </Row>
          </StyledDrawerFooter>
        )}
      </StyledWrapper>
    </Drawer>
  );
};
export const ELibraryDocumentDrawer = memo(ELibraryDocumentDrawerComponent);

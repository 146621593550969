import React, { FC, useMemo } from 'react';
import { FormInstance } from 'antd/lib/form';
import { TType } from 'translations';
import { CreateFormItemType } from 'types';
import { FORM_ITEM_REQUIRED_TRUE_RULE } from 'utils';
import { Divider } from 'antd';
import { TitleH3 } from 'ui/elements';
import { GeneratedStaticForm } from '../../../GeneratedStaticForms';
import { StyledScrollBoxContainer } from './CustomFormGroup.styled';

type Props = {
  t: TType;
  form?: FormInstance;
};

export const ProfileUpdatePrivacyConsent: FC<Props> = ({ t, form }) => {
  const termsConditions = useMemo<CreateFormItemType[]>(() => {
    return [
      {
        id: `${0}`,
        name: 'Terms and Conditions',
        type: 'checkboxWithTitle',
        labelCheckBoxTitle: 'I accept the Terms and Conditions',
        valuePropName: 'checked',
        rules: [...FORM_ITEM_REQUIRED_TRUE_RULE],
        containerCol: { span: 24 },
      },
    ];
  }, []);
  const dataPrivacy = useMemo<CreateFormItemType[]>(() => {
    return [
      {
        id: `${1}`,
        name: 'Data Policy',
        type: 'checkboxWithTitle',
        labelCheckBoxTitle: 'I consent to TAWAL data policy',
        valuePropName: 'checked',
        rules: [...FORM_ITEM_REQUIRED_TRUE_RULE],
        containerCol: { span: 24 },
      },
    ];
  }, []);
  return (
    <>
      <Divider />
      <TitleH3>Terms and Conditions</TitleH3>
      <StyledScrollBoxContainer>
        <span dir="rtl">
          <ul>
            <li>
              يخضع دخولك واستخدامك لهذا الموقع لبنود وشروط الاستخدام والأنظمة المملكة العربية
              السعودية، وكذلك يعد وصولك ودخولك إلى الموقع موافقة دون قيد أو شرط على بنود وشروط
              الاستخدام.
            </li>
            <li>
              الغرض من توفير المعلومات الواردة في هذا الموقع هو توفير مرجعية فقط، وليس الغرض منها
              تقديم آراء قانونية أو مشورة لذلك على المستخدم استشارة جهة مؤهلة ومتخصصة إذا كان بحاجة
              لمثل ذلك.
            </li>
            <li>
              يلتزم المستخدم بأحكام وشروط الاستخدام الموضحة في الموقع وتقع على عاتقه مسؤولية الحفاظ
              على كلمة السر الممنوحة له للدخول عليه. كما يتحمل المستخدم مسؤولية دخوله على هذا الموقع
              وجميع الإجراءات التي يقوم بها من خلاله.
            </li>
            <li>
              في حال تزويدك، أو اختيارك اسم المستخدم، أو كلمة المرور أو أية معلومة أخرى كجزء من
              الإجراءات الأمنية الخاصة بالموقع، فيجب عليك التعامل مع هذه المعلومات على أنها معلومات
              سرية. وبناءً على ذلك يجب الامتناع عن الإفصاح عنها إلى أي شخص.
            </li>
            <li>
              يجب على المستخدم المحافظة على سرية معلوماته في الموقع ويجب على المستخدم عدم الإفصاح عن
              محتوياته إلى الغير أو كما هو مطلوب بموجب القانون على خلافه.
            </li>
            <li>
              إن كافة المعلومات المتضمنة في الموقع بما في ذلك وبدون حصر كافة التصاميم والنصوص والصور
              والمقالات الصحفية والمعلومات الأخرى محمية بموجب قوانين المملكة العربية السعودية ولا
              يجوز نسخ، أو استخراج، أو نقل أو عرض أو تنفيذ أو استعمال كل أو جزء من المعلومات بأي شكل
              بدون الموافقة الخطية المسبقة من توال إلا في حال وجود ترخيص خطي بذلك.
            </li>
            <li> ان يحافظ المستخدم على كامل المحتويات والارقام السرية للدخول لحسابك بشكل امان.</li>
            <li>
              لا تتحمل شركة توال أي مسؤولية عن الأضرار الناتجة بسبب إدخال معلومات أو بيانات غير
              صحيحة والتي تم إدخالها عن طريق المستخدم، ويتحمل المسؤولية في حال تم إدخال بيانات أو
              معلومات لعقار غير مصرح له التصريح به.
            </li>
            <li>
              يُقر المُستخدم بأن كافة البيانات والمعلومات والمستندات المُدخلة والمرفقة من قِبله
              صحيحة.
            </li>
            <li>
              يكون لتوال كامل الحق في إعادة تعيين كلمة المرور أو اسم المستخدم، في حال استدعى ذلك.
            </li>
            <li>
              يوافق المستخدم على جميع الشروط والأحكام ويتحمل كافة المسؤولية عن مخالفتها لهذه الشروط
              أو القوانين المنظمة ذات العلاقة.
            </li>
            <li>
              في حال واجه المستخدم اي مشكلة تخص الموقع يتم التواصل مع توال ذاتها ليتم حل المشكلة،
              وفي حال تضرر المستخدم من الموقع عن طريق شخص اخر توال تخلي مسؤوليتها تماما من هذا
              الضرر.
            </li>
          </ul>
        </span>
        <span dir="ltr">
          <ul>
            <li>
              Your access and use of this site are subject to the laws of the Kingdom of Saudi
              Arabia.
            </li>
            <li>
              The purpose of providing the information on this site is to provide a reference only
              and is not intended to provide legal opinions or advice, the user should consult a
              qualified and specialized person or body if needed.
            </li>
            <li>
              The user must comply with all the terms and conditions of use as shown on the site.
            </li>
            <li>
              The user is responsible at all times or maintaining the password granted to access the
              site, orders, bills, and for all actions and steps taken.
            </li>
            <li>
              The chosen username and password, or any type of information provided or inserted on
              the site or as part of the site&lsquo;s security procedures must remain confidential,
              and the user must refrain from disclosing such information to any person, entity, or a
              third party.
            </li>
            <li>
              All information contained on the site, including without limitation all designs, text,
              images, press articles, and other information protected under the laws of the Kingdom
              of Saudi Arabia, must not be copied, extracted, transmitted, displayed, executed, or
              used in any way without prior written consent from TAWAL.
            </li>
            <li>
              {' '}
              TAWAL is not responsible or shall be held liable for any damages resulting from
              incorrect information or any type of data provided by the user.
            </li>
            <li>
              {' '}
              The user acknowledges that this site is prepared to preserve the information and data
              and TAWAL has the right to use the information of the date to the extent that fulfills
              TAWAL&lsquo;s needs.
            </li>
            <li>
              The user confirms that all the provided data, information, and documents are correct
              and valid.
            </li>
            <li> TAWAL has the right to reset the password or username, if necessary.</li>
            <li>
              The user agrees to all the terms and conditions and bears all responsibility and
              liability that may arise as a result of any violation.
            </li>
            <li>
              {' '}
              If the user encounters any issue that may arise during the use of the portal, the user
              must contact TAWAL only, and in the event that the user is harmed by the site as a
              result of any third-party intervention, TAWAL will be completely not responsible.
            </li>
          </ul>
        </span>
      </StyledScrollBoxContainer>
      <GeneratedStaticForm t={t} items={termsConditions} formState={form} />
      <StyledScrollBoxContainer>
        <span dir="rtl">
          أقر وأوافق بموجب هذا الإقرار على تمكين توال من جمع وتخزين واستخدام وأي معالجة أخرى
          لبياناتي الشخصية، وذلك وفقًا للسياسات والعمليات الداخلية ذات الصلة في توال وقوانين ولوائح
          حماية البيانات ذات الصلة في المملكة العربية السعودية.
        </span>
        <span dir="ltr">
          I hereby acknowledge and consent to the collection, storage, usage, and any other
          processing of my personal data by TAWAL, in accordance with TAWAL&lsquo;s relevant
          internal policies and processes, and the relevant data protection laws and regulations in
          the Kingdom of Saudi Arabia.
        </span>
      </StyledScrollBoxContainer>
      <GeneratedStaticForm t={t} items={dataPrivacy} formState={form} />
    </>
  );
};

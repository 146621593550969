import { useQuery } from 'react-query';
import { leaseSites } from '../LeaseSites';
import { QUERIES } from '../../constants';
import { mapDraftCandidatesToFormValues } from './draftCandidatesHelper';

export const useLeaseSitesCandidatesFormDraft = (processInstanceId = '') =>
  useQuery(
    [QUERIES.getLeaseSitesCandidatesFormDraft(processInstanceId)],
    async () => {
      const { data } = await leaseSites.getCandidatesFormDraft(processInstanceId);
      return { candidates: mapDraftCandidatesToFormValues(data), feedback: data.feedback };
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!processInstanceId,
    }
  );

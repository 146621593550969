import { useMutation } from 'react-query';
import { IProfileUpdateData, IProfileUpdateSubmitResponse } from 'types';
import { profileUpdate } from '../ProfileUpdate';

export const useSubmitProfileUpdateRequest = () => {
  return useMutation<IProfileUpdateSubmitResponse, unknown, IProfileUpdateData, unknown>(
    async (body) => {
      const { data } = await profileUpdate.submitProfileRequest(body);
      return data;
    }
  );
};

import { AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';
import { staticForm } from 'api/StaticForm';

export const useSelfServiceLetterSubmit = (adoId: string) => {
  return useMutation(async (formData: AxiosRequestConfig['data']) => {
    const { data } = await staticForm.postSelfServiceLetter(adoId, formData);
    return data;
  });
};

import styled, { css } from 'styled-components';
import { Button, Card, Divider, Col } from 'antd';
import { SwapOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { UserAddIcon } from 'assets';
import Text from 'antd/lib/typography/Text';

import { colors, space, typography } from 'ui/elements';

export const StyledCardIcon = css`
  font-size: ${space.normal};
  color: ${colors.primaryColor};
  font-weight: ${typography.weight.light};
`;

export const CardTitle = styled(Text)`
  font-size: ${typography.size.kilo};
  font-weight: ${typography.weight.semiBold};
`;

export const CardTitleContainer = styled(Col)`
  margin-bottom: 7.5px;
`;

export const StyledDivider = styled(Divider)`
  margin: 12px 0;
`;

export const StepTitle = styled(CardTitle)`
  font-weight: 500;
`;

export const StyledReassignIcon = styled(SwapOutlined)`
  padding: 0;
`;

export const StyledReassignInCardIcon = styled(UserSwitchOutlined)`
  ${StyledCardIcon}
`;

export const StyledClaimTaskIcon = styled(UserAddIcon)`
  ${StyledCardIcon}
`;
export const StyledCard = styled(Card)`
  margin: 0 4px 24px;
  border-radius: 6px;
  border-left: ${(props: { $highlighted?: boolean }) =>
    props.$highlighted ? '4px solid ' + colors.primaryColor : 'outside'};
  box-shadow: 0 2px 4px rgba(28, 43, 101, 0.14);
`;

export const StyledRedText = styled.div`
  color: ${colors.redSecond};
  font-weight: 600;
`;
export const StyledButton = styled(Button)`
  font-size: 13px;
  padding: 7px;
  top: -7px;
`;

export const StyledAssignBtn = styled(Button)`
  width: fit-content;
`;

export const StyledAssignedToCol = styled(Col)`
  &:hover {
    text-decoration: underline;
  }
  font-weight: ${typography.weight.semiBold};
`;

export const StyledTaskAvatar = styled.span``;

export const EndEventText = styled(Text)`
  max-inline-size: -webkit-fill-available;
  color: ${colors.skyColor};
  font-size: ${typography.size.kilo};
  font-weight: ${typography.weight.regular};
`;

export const RequestedStyled = styled(Text)`
  font-size: ${typography.size.hecto};
`;

export const StyledTaskCardColTaskName = styled(Col)`
  align-self: center;
`;

export const StyledTaskName = styled(Text)`
  white-space: nowrap;
  max-width: -webkit-fill-available;
  color: ${colors.primaryColor};
  width: 100%;
`;

export const StyledTaskCardCol = styled(Col)`
  text-align: right;
`;

export const StyledRequestId = styled(Text)`
  max-width: 100%;
`;

export const StyledUserAvatar = styled.span`
  margin-left: -${space.slim};
  .ant-avatar-circle {
    border-left: ${space.micro} solid ${colors.white};
  }
`;

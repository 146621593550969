import { GroupMember, GroupMemberByLastName } from 'types';
import { Core, GROUP_MEMBERS } from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetGroupMembers {
  (prams: AxiosRequestConfig['params'], rootPath?: string): Promise<{ data: GroupMember[] }>;
}

interface GetGroupMembersByLastName {
  (prams: AxiosRequestConfig['params'], rootPath?: string): Promise<{
    data: GroupMemberByLastName;
  }>;
}

class GroupMembers extends Core {
  getGroupMembers: GetGroupMembers = async (params, rootPath?: string) =>
    this.get(GROUP_MEMBERS(rootPath), { params });
  getGroupMembersByLastName: GetGroupMembersByLastName = async (params, rootPath?: string) =>
    this.get(GROUP_MEMBERS(rootPath), { params });
}

export const groupMembers = new GroupMembers();

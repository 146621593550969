import { AxiosRequestConfig, AxiosPromise } from 'axios';

import {
  CamundaAttachmentData,
  TaskData,
  TaskRenderedFormData,
  TaskLabel,
  TaskDetails,
  TaskAssignment,
  PrettyId,
  LeaveSubmitFormData,
  RequestDescription,
  SubmitNewRequestForm,
  RequestGeneralInfo,
  CompletedTaskFormFields,
  CompletedSiteAcquisitionRequestTask,
  FormFields,
  CompletedSiteAcquisitionAssignWoTask,
} from 'types';
import {
  Core,
  TASK,
  TASK_RENDERED_FORM,
  TASK_FORM_VARIABLES,
  TASK_SUBMIT_FORM,
  TASK_ASSIGNMENT,
  SUBMITTED_TASK_FORM_VARIABLES,
  FORM_DATA,
  ATTACHMENT,
  PROCESS_INSTANCE,
  SUBMITTED_TASK_FORM_LABELS,
  COMPLETE_TASK,
  TASK_PRETTY_ID,
  REQUEST_PRETTY_ID,
  TASK_ATTACHMENT,
  HISTORIC_PROCESS_INSTANCE,
  LEAVES_SUBMIT_FORM,
  SERVICE_NEW_REQUEST_FORM,
  NEW_REQUEST_SUBMIT_FORM,
  REQUEST_COMPLETED_TASK,
  REQUEST_GENERAL_INFO,
  REQUEST_ROOT_PATH,
  POST_JIRA_TASK_ATTACHMENT,
  SUBMIT_TASK_APPROVAL,
  LEASE_SITES_REQUEST_TASK,
  LEASE_SITES_ASSIGN_WO_TASK,
  TASK_ATTACHMENT_OLD,
  POST_NON_PO_TASK_ATTACHMENT,
} from 'api';
import { POST_TASK_ATTACHMENT, REQUEST_DESCRIPTION } from 'api/Core';

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

interface GetTask {
  (params: AxiosRequestConfig['params']): Promise<{ data: TaskData }>;
}

interface GetTaskRenderedForm {
  (taskId: string): Promise<{ data: TaskRenderedFormData }>;
}

interface GetTaskAssignment {
  (taskId: string): Promise<{ data: TaskAssignment[] }>;
}

interface GetTaskFormVariables {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (taskId: string): Promise<{ data: any }>;
}

interface PostTask {
  (taskId: string, data: AxiosRequestConfig['data'], rootPath?: string): Promise<unknown>;
}

interface PostLeaveTask {
  (hcmPersonId: string, data: AxiosRequestConfig['data']): Promise<{ data: LeaveSubmitFormData }>;
}

interface GetSubmittedTask {
  (params: AxiosRequestConfig['params']): Promise<{ data: TaskDetails[] }>;
}

interface GetFormData {
  (taskId: string, rootPath?: string): Promise<{ data: FormFields }>;
}

interface GetNewRequestFormData {
  (taskId: string, adOid?: string): Promise<{ data: FormFields }>;
}

interface PostAttachment {
  (taskId: string, data: FormData): Promise<{ data: CamundaAttachmentData }>;
}

interface DownloadTaskAttachment {
  (payload: {
    taskId: string;
    attachmentId: string;
    params?: AxiosRequestConfig['params'];
    rootPath?: string;
  }): Promise<{
    data: string;
  }>;
}

interface DeleteProcessInstance {
  (processInstanceId: string, params?: AxiosRequestConfig['params']): Promise<unknown>;
}

interface DeleteHistoryProcessInstance {
  (processInstanceId: string): Promise<unknown>;
}

interface GetSubmittedTaskLabels {
  (processDefinitionId: string, params: AxiosRequestConfig['params']): Promise<{
    data: TaskLabel[];
  }>;
}

interface PostCompletedTask {
  (taskId: string, data: AxiosRequestConfig['data']): Promise<unknown>;
}

interface GetPrettyId {
  (params: AxiosRequestConfig['params']): Promise<{
    data: PrettyId[];
  }>;
}

interface PostNewRequestForm {
  (
    processDefinitionKey: string,
    data: AxiosRequestConfig['data']
  ): AxiosPromise<SubmitNewRequestForm>;
}

interface GetRequestDescription {
  (processInstanceId: string): AxiosPromise<RequestDescription>;
}

interface PostTaskAttachment {
  (
    taskId: string,
    processInstanceId: string,
    processDefinitionId: string,
    activityId: string,
    data?: FormData,
    params?: AxiosRequestConfig['params'],
    rootPath?: string
  ): Promise<{
    data: string;
  }>;
}

interface PostJiraTaskAttachment {
  (processInstanceId: string, params?: AxiosRequestConfig['params']): Promise<{
    data: string;
  }>;
}

interface GetRequestGeneralInfo {
  (processInstanceId: string | null, rootPath?: string): Promise<{
    data: RequestGeneralInfo;
  }>;
}

interface GetCompletedTaskFormFields {
  (taskId: string, params?: AxiosRequestConfig['params'], rootPath?: string): Promise<{
    data: CompletedTaskFormFields;
  }>;
}

interface GetSiteAcquisitionRequestTask {
  (taskId: string): Promise<{
    data: CompletedSiteAcquisitionRequestTask;
  }>;
}

interface GetSiteAcquisitionRequestAssignWoTask {
  (taskId: string): Promise<{
    data: CompletedSiteAcquisitionAssignWoTask;
  }>;
}

interface GetTaskRootPath {
  (taskId: string | null): Promise<{ data: string }>;
}

interface PostTaskApproval {
  (taskId: string, data: AxiosRequestConfig['data'], rootPath?: string): Promise<unknown>;
}

class Task extends Core {
  getTaskList: GetTask = async (params) => this.get(TASK(), { params });
  getTasksList: GetTask = async (params) => this.get(TASK(), params);
  getTaskRenderedForm: GetTaskRenderedForm = async (taskId) => this.get(TASK_RENDERED_FORM(taskId));
  getTaskFormVariables: GetTaskFormVariables = async (taskId) =>
    this.get(TASK_FORM_VARIABLES(taskId));
  getTaskAssignment: GetTaskAssignment = async (taskId) => this.get(TASK_ASSIGNMENT(taskId));
  postTask: PostTask = async (taskId, data, rootPath) =>
    this.post(TASK_SUBMIT_FORM(taskId, rootPath), data);
  postLeaveTask: PostLeaveTask = async (hcmPersonId, data) =>
    this.post(LEAVES_SUBMIT_FORM(hcmPersonId), data);
  getSubmittedTaskVariables: GetSubmittedTask = async (params) =>
    this.get(SUBMITTED_TASK_FORM_VARIABLES(), { params });
  getFormData: GetFormData = async (taskId, rootPath) => this.get(FORM_DATA(taskId, rootPath));
  getNewRequestForm: GetNewRequestFormData = async (processDefinitionKey: string, adOid?: string) =>
    this.get(SERVICE_NEW_REQUEST_FORM(processDefinitionKey, adOid));
  postNewRequestForm: PostNewRequestForm = async (processDefinitionKey, data) =>
    this.post(NEW_REQUEST_SUBMIT_FORM(processDefinitionKey), data);
  postAttachment: PostAttachment = async (taskId: string, data) =>
    this.post(ATTACHMENT(taskId), data);
  downloadTaskAttachment: DownloadTaskAttachment = async ({
    taskId,
    attachmentId,
    params,
    rootPath,
  }) =>
    this.get(
      disableAggregator
        ? TASK_ATTACHMENT_OLD(taskId, attachmentId)
        : TASK_ATTACHMENT(taskId, attachmentId, rootPath),
      params
    );
  deleteProcessInstance: DeleteProcessInstance = async (processInstanceId, params) =>
    this.delete(PROCESS_INSTANCE(processInstanceId), params);
  deleteHistoryProcessInstance: DeleteHistoryProcessInstance = async (processInstanceId) =>
    this.delete(HISTORIC_PROCESS_INSTANCE(processInstanceId));
  getSubmittedTaskLabels: GetSubmittedTaskLabels = async (processDefinitionId, params) =>
    this.get(SUBMITTED_TASK_FORM_LABELS(processDefinitionId), { params });
  postCompletedTask: PostCompletedTask = async (taskId, data) =>
    this.post(COMPLETE_TASK(taskId), data);
  getTaskPrettyId: GetPrettyId = async (ids) =>
    this.get(TASK_PRETTY_ID(), { params: { uuid: ids } });
  getRequestPrettyId: GetPrettyId = async (ids) =>
    this.get(REQUEST_PRETTY_ID(), { params: { uuid: ids } });

  getRequestDescription: GetRequestDescription = async (processInstanceId) =>
    this.get(REQUEST_DESCRIPTION(processInstanceId));

  postTaskAttachment: PostTaskAttachment = async (
    taskId,
    processInstanceId,
    processDefinitionId,
    activityId,
    data,
    params,
    rootPath
  ) =>
    this.post(
      POST_TASK_ATTACHMENT(taskId, processInstanceId, activityId, processDefinitionId, rootPath),
      data,
      { params }
    );

  postNonPoTaskAttachment: PostTaskAttachment = async (
    taskId,
    processInstanceId,
    processDefinitionId,
    activityId,
    data
  ) =>
    this.post(
      POST_NON_PO_TASK_ATTACHMENT(taskId, processInstanceId, activityId, processDefinitionId),
      data
    );

  postJiraTaskAttachments: PostJiraTaskAttachment = (processInstanceId, data) =>
    this.post(POST_JIRA_TASK_ATTACHMENT(processInstanceId), data);
  getRequestGeneralInfo: GetRequestGeneralInfo = async (processInstanceId, rootPath) =>
    this.get(REQUEST_GENERAL_INFO(processInstanceId, rootPath));

  getCompletedTaskFormFields: GetCompletedTaskFormFields = async (taskId, params, rootPath) =>
    this.get(REQUEST_COMPLETED_TASK(taskId, rootPath), { params });
  getTaskRootPath: GetTaskRootPath = async (taskId) => this.get(REQUEST_ROOT_PATH(taskId));
  postTaskApproval: PostTaskApproval = async (taskId, data, rootPath) =>
    this.post(SUBMIT_TASK_APPROVAL(taskId, rootPath), data);
  getSiteAcquisitionRequestTask: GetSiteAcquisitionRequestTask = async (taskId) =>
    this.get(LEASE_SITES_REQUEST_TASK(taskId));
  getSiteAcquisitionRequestAssignWoTask: GetSiteAcquisitionRequestAssignWoTask = async (taskId) =>
    this.get(LEASE_SITES_ASSIGN_WO_TASK(taskId));
}

export const task = new Task();

import styled from 'styled-components';
import { Typography } from 'antd';
import { colors, space } from 'ui/elements';

const { Text } = Typography;

export const LeaveListWrapper = styled.div`
  margin: ${space.moderate} ${space.normal};
  width: 100%;
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: ${colors.lightGreyFourth};
    cursor: pointer;
  }
  .action-icon:hover {
    cursor: pointer;
  }
`;
export const StyledData = styled(Text)`
  color: ${colors.textColorSecondary};
`;

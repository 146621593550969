import styled from 'styled-components';
import { Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { space, colors } from '../theme';

const MODAL_WIDTH = '384px';
export const modalStyles = { maxWidth: MODAL_WIDTH };
const { primaryColor, accentPrimary } = colors;

export const StyledModalPopOver = styled(Modal).attrs(
  ({
    width,
    forceRender,
    centered,
  }: {
    width?: number;
    forceRender?: boolean;
    centered?: boolean;
  }) => ({
    width: width || MODAL_WIDTH,
    destroyOnClose: true,
    forceRender: forceRender || false,
    centered: centered,
  })
)`
  .ant-modal-close-x {
    width: 80px;
    height: 70px;
    line-height: 70px;
  }

  .ant-modal-header {
    padding-top: ${space.normal};

    .ant-modal-title {
      font-size: 24px;
      line-height: 24px;
      font-weight: 600;
    }
  }

  .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }

  .ant-modal-header,
  .ant-modal-body,
  .ant-modal-footer {
    padding-left: 32px;
    padding-right: 32px;
  }

  .ant-modal-body {
    padding-top: ${space.moderate};
  }

  .ant-btn.ant-btn-primary {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;

    .anticon {
      margin-left: 6px;
    }
  }

  .ant-modal-footer {
    padding-bottom: ${space.normal};
    text-align: ${({ okHidden }: { okHidden: boolean }) => (okHidden ? 'center' : 'right')};
    .ant-btn.ant-btn-primary {
      display: ${({ okHidden }: { okHidden: boolean }) => (okHidden ? 'none' : 'inline-flex')};
    }
  }

  .ant-form-item {
    margin-bottom: ${space.normal};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ConfirmationModalPopOver = styled(Modal)`
  .ant-modal-footer {
    border: none;
    padding: ${space.normal};
    padding-top: 0;
  }

  .ant-modal-body {
    display: flex;
    align-items: center;
  }

  .ant-btn-primary {
    background-color: ${primaryColor};
    border-color: ${primaryColor};

    &:hover,
    &:focus {
      background-color: ${accentPrimary};
      border-color: ${accentPrimary};
    }
  }
`;

export const ConfrimationIcon = styled(QuestionCircleOutlined)`
  font-size: 21px;
  margin-right: ${space.moderate};

  &.anticon {
    color: #faad14;
  }
`;

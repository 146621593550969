import { AttachmentType } from './Attachment';
import { TicketStatus, IncidentSeverity } from './TicketStatus';
import { ResponsePageable } from './ResponsePageable';

export interface SiteIncidentType {
  id: string;
  status: TicketStatus;
  city: string;
  commentsCount: number;
  district: string;
  priority: IncidentSeverity;
  time: string;
  title: string;
}

export type SiteIncidentTypePageable = ResponsePageable & {
  content: SiteIncidentType[];
};

export enum IncidentType {
  ServiceRequest = 'ServiceRequest',
}

export enum SiteVisibility {
  Online = 'Online',
}

export enum IncidentEscalationLevel {
  High = 'High',
}

export enum IncidentRCA {
  Enabled = 'Enabled',
}

export interface SiteIncident {
  id: string;
  description: string;
  assignedTo: string;
  createDate: string;
  severity: IncidentSeverity;
  ticketStatus: TicketStatus;
  ETR: string;
  siteId: string;
  region: string;
  district: string;
  governorate: string;
  owner?: string;
  attachments?: AttachmentType[];
  type: IncidentType;
  loggedBy: string;
  opcoSiteId: string;
  siteVisibility: SiteVisibility;
  equipment: string;
  alarmDescription: string;
  effectedSites: number;
  problemStartDate: string;
  operator: string;
  escalationLevel: IncidentEscalationLevel;
  assignedDate: string;
  ticketMode: string;
  RCACategory: string;
  RCA: IncidentRCA;
  remarks: string;
  alarmDetail: string;
  ticketClosureDate: string;
  duration: string;
}

import React from 'react';
import { Typography } from 'antd';
import { map, pick, pickBy, pipe, toPairs } from 'ramda';
import { Link } from 'react-router-dom';

import { MessagesTypes, TType } from 'translations';
import { InfoItemCol } from './InfoItemCol';

const { Text } = Typography;

export const mapToInfoItemAndRender = <T extends unknown>({
  copyPrefix,
  t,
  links,
}: {
  copyPrefix: string;
  t: TType;
  links?: {
    [key: string]: (id: string) => string;
  };
}) =>
  pipe<
    string[],
    T,
    Partial<T>,
    Partial<T>,
    Array<[string, unknown]>,
    { label: string; value: string }[],
    React.ReactNode[]
  >(
    pick,
    pickBy((value) => !!value),
    toPairs,
    map(([label, value]) => ({
      label: t(`${copyPrefix}.${label}` as MessagesTypes),
      value: `${value}`,
    })),
    map(({ label, value }) => (
      <InfoItemCol label={label} key={`${label}${value}`}>
        {links && links[label] ? (
          <Link to={links[label](value)}>
            <Text underline>{value}</Text>
          </Link>
        ) : (
          value
        )}
      </InfoItemCol>
    ))
  );

import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { leaves } from '../Leaves';

export function useLeavesByReason(hcmId: string) {
  return useQuery(
    [QUERIES.allRequests, hcmId],
    async () => {
      const { data } = await leaves.getLeavesByReasons(hcmId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );
}

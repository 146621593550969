import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OneDriveSharedLibrariesData, RequestCommonError, RequestStatus } from 'types';
import { OneDriveSharedLibrariesState } from './oneDriveSharedLibrariesTypes';

export const emptyOneDriveSharedLibrariesInitialState: OneDriveSharedLibrariesState = {};

export type GetOneDriveSharedLibrariesStart = PayloadAction<string>;
export type GetOneDriveSharedLibrariesSuccess = PayloadAction<{
  groupId: string;
  data: OneDriveSharedLibrariesData;
}>;
export type GetOneDriveSharedLibrariesFail = PayloadAction<{
  groupId: string;
  error: RequestCommonError;
}>;

const oneDriveSharedLibraries = createSlice({
  name: 'oneDriveSharedLibraries',
  initialState: emptyOneDriveSharedLibrariesInitialState,
  reducers: {
    getOneDriveSharedLibrariesStart(state, action: GetOneDriveSharedLibrariesStart) {
      state[action.payload] = {
        ...state[action.payload],
        status: RequestStatus.pending,
      };
    },
    getOneDriveSharedLibrariesSuccess(state, action: GetOneDriveSharedLibrariesSuccess) {
      state[action.payload.groupId].status = RequestStatus.resolved;
      state[action.payload.groupId].data = action.payload.data;
    },
    getOneDriveSharedLibrariesFail(state, action: GetOneDriveSharedLibrariesFail) {
      state[action.payload.groupId].status = RequestStatus.rejected;
      state[action.payload.groupId].error = action.payload.error;
    },
    getOneDriveSharedLibrariesReset(state, action) {
      state[action.payload.groupId].status = RequestStatus.idle;
      state[action.payload.groupId].error = action.payload.error;
    },
  },
});

export const {
  getOneDriveSharedLibrariesStart,
  getOneDriveSharedLibrariesSuccess,
  getOneDriveSharedLibrariesFail,
  getOneDriveSharedLibrariesReset,
} = oneDriveSharedLibraries.actions;
export default oneDriveSharedLibraries.reducer;

import React, { FC, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Col } from 'antd';
import moment from 'moment';

import { eLibraryFilterItems } from '../../../routes/ELibrary/components/ELibraryFilter';
import { FilterTag } from './Filter.styled';
import { useFilterContext } from './FilterContext';
import { FilterOption } from './Filter.types';

const WrapCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
`;

const getTagValue = (
  type: string,
  value: string | string[],
  dateFormat: string,
  options?: FilterOption[]
) => {
  if (type === 'rangePicker') {
    return `${moment(value[0]).format(dateFormat)} - ${moment(value[1]).format(dateFormat)}`;
  }
  if (type === 'datePicker') {
    return `${moment(value).format(dateFormat)}`;
  }
  if (type === 'select' || type === 'lookup') {
    const optionValue = options?.find((option) => option.value === value)?.text;
    if (optionValue !== undefined) {
      return optionValue;
    }
  }
  return value;
};

export interface FilterTagsProps {
  onTagRemoved?: (tag: string) => void;
}

export const FilterTags: FC<FilterTagsProps> = ({ onTagRemoved }: FilterTagsProps) => {
  const { tags, items, setFilterValues, dateFormat } = useFilterContext();

  const onRemoveTag = useCallback(
    (tagName) => {
      setFilterValues((values) => {
        return Object.fromEntries(Object.entries(values).filter(([key]) => key !== tagName));
      });
      /**
       * Call back on tag removed
       * to handle any operation in parent
       * component
       */
      if (onTagRemoved) {
        onTagRemoved(tagName);
      }
      /**
       * Clear out department
       */
      if (tagName === eLibraryFilterItems.sector) {
        setFilterValues((values) => {
          return Object.fromEntries(
            Object.entries(values).filter(([key]) => key !== eLibraryFilterItems.department)
          );
        });
      }
    },
    [setFilterValues, onTagRemoved]
  );

  const tagList = useMemo(
    () =>
      Object.entries(tags).map(([tagName, { tagLabel, displayName, value, type }], i) =>
        value ? (
          <FilterTag closable key={tagName + i} onClose={() => onRemoveTag(tagName)}>
            {`${tagLabel ? tagLabel : displayName?.slice(0, 30)}: ${getTagValue(
              type,
              value,
              dateFormat,
              items.find((item) => item.name === tagName)?.options
            )}`}
          </FilterTag>
        ) : null
      ),
    [tags, dateFormat, items, onRemoveTag]
  );

  return tagList?.length ? <WrapCol>{tagList}</WrapCol> : null;
};

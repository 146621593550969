import styled, { css } from 'styled-components';
import { Card, Row, Typography } from 'antd';
import { borderRadius, colors, space, typography } from 'ui/elements';

const { Text } = Typography;

export const DashboardCardStyle = css`
  border-radius: ${borderRadius.xLarge};
  margin-right: ${space.moderate};
  box-shadow: 0 2px 4px rgba(28, 43, 101, 0.14);
  border: none;
  cursor: pointer;
`;

export const StyledDashboardRequestCard = styled(Card)`
  ${DashboardCardStyle}
`;

export const StyledSpaceDashbordRow = styled(Row)`
  margin-top: ${space.normal};
`;

export const StyledSummaryText = styled(Text)`
  font-size: ${typography.size.hecto};
  font-weight: ${typography.weight.regular};
  color: ${colors.lightGrey};
`;

export const StyledSubCategory = styled(Text)`
  font-weight: ${typography.weight.regular};
  font-size: ${typography.size.uno};
  color: ${colors.metallicSilver};
`;

export const StyledRequestName = styled(Text)`
  font-weight: ${typography.weight.bold};
  font-size: ${typography.size.giga};
  color: ${colors.primaryColor};
`;

import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { allRequestCard } from '../AllRequestCard';

export function useAllRequests() {
  return useQuery([QUERIES.allRequests], async () => {
    const { data } = await allRequestCard.getAllRequestCard();
    return data;
  });
}

import styled from 'styled-components';
import { Form } from 'antd';
import { MentionsInput } from 'react-mentions';
import Paragraph from 'antd/lib/typography/Paragraph';
import { colors } from 'ui/elements';

export const FormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

export const StyledMentionsInput = styled(MentionsInput)`
  line-height: 1.2;
  font-family: inherit;

  strong {
    color: ${colors.mentionTextColor};
    position: relative;
    z-index: 1;
    font-family: inherit;
  }

  textarea {
    line-height: 1.2;
    font-family: inherit;
    max-height: 300px;
    overflow-y: auto;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  &.ant-typography {
    line-height: 1;
    margin: 6px 0;
  }
`;

export const StyledSecondaryParagraph = styled(StyledParagraph).attrs({ type: 'secondary' })`
  font-size: 12px;
`;

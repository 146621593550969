import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetTaskListFail,
  GetTaskListStart,
  GetTaskListSuccess,
  getTaskListFail,
  getTaskListStart,
  getTaskListSuccess,
} from './taskListSlice';

export const taskListEpic: Epic<
  GetTaskListStart,
  GetTaskListSuccess | GetTaskListFail,
  RootState,
  typeof API
> = (action$, _, { taskList }) =>
  action$.pipe(
    filter(getTaskListStart.match),
    exhaustMap(({ payload }) =>
      from(taskList.getTasksList(payload)).pipe(
        map(({ data }) => getTaskListSuccess(data)),
        catchError((error) => of(getTaskListFail(error?.response?.data)))
      )
    )
  );

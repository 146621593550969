import React from 'react';
import {
  TeamsIcon,
  TeamsWhiteIcon,
  FilesIcon,
  TasksIcon,
  PlannerIcon,
  CalendarIcon,
  ProcessManagementIcon,
  ServicesIcon,
} from 'assets';
import { TType, useTranslate } from 'translations';
import { SiderLayoutsMenuItem } from 'layouts/SiderLayout';
import { PagesUrls } from '../namespace';

const DISABLE_PROCESS_MANAGEMENT = process.env.REACT_APP_DISABLE_MENU_PROCESS_MANAGEMENT !== 'true';
const DISABLE_PLANNER = process.env.REACT_APP_DISABLE_MENU_PLANNER !== 'true';
const MENU_ITEMS = (t: TType): SiderLayoutsMenuItem[] => {
  const items = [
    {
      key: 1,
      linkTo: PagesUrls.MyWork,
      linkText: 'menu.myWork',
      icon: TasksIcon,
      iconLight: TasksIcon,
    },
    {
      key: 2,
      linkTo: PagesUrls.Tasks,
      linkText: 'menu.services',
      icon: ServicesIcon,
      iconLight: ServicesIcon,
    },
    {
      key: 3,
      linkTo: PagesUrls.Leaves,
      linkText: 'menu.leaves',
      icon: CalendarIcon,
      iconLight: CalendarIcon,
    },
    DISABLE_PROCESS_MANAGEMENT && {
      key: 4,
      linkTo: PagesUrls.ProcessManagement,
      linkText: 'menu.processManagement',
      icon: ProcessManagementIcon,
      iconLight: ProcessManagementIcon,
    },
    {
      key: 5,
      linkTo: PagesUrls.MSTeams,
      linkText: 'menu.msteams',
      icon: TeamsIcon,
      iconLight: TeamsWhiteIcon,
    },
    {
      key: 6,
      linkTo: PagesUrls.OneDrive,
      linkText: 'menu.oneDrive',
      icon: FilesIcon,
      iconLight: FilesIcon,
    },
    DISABLE_PLANNER && {
      key: 7,
      linkTo: PagesUrls.MSPlanner,
      linkText: 'menu.msplanner',
      icon: PlannerIcon,
      iconLight: PlannerIcon,
    },
  ];
  return items.filter((item) => typeof item !== 'boolean') as SiderLayoutsMenuItem[];
};

export interface WithMenuItemsProps {
  menuItems: SiderLayoutsMenuItem[];
}

//this function used to wrap the other component have menu
export function withMenuItems<T extends WithMenuItemsProps = WithMenuItemsProps>(
  WrappedComponent: React.ComponentType<T>
) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithMenuItems = (props: Omit<T, keyof WithMenuItemsProps>) => {
    const { t } = useTranslate();
    const injectedProps = { menuItems: MENU_ITEMS(t) };
    return <WrappedComponent {...injectedProps} {...(props as T)} />;
  };
  ComponentWithMenuItems.displayName = `withMenuItems(${displayName})`;
  return ComponentWithMenuItems;
}

import React, { useCallback, useEffect, useState, FC } from 'react';
import { Space, Row, Col, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TitleH1 } from 'ui/elements';
import { Header } from 'app';
import { Link } from 'react-router-dom';

import { useTranslate } from 'translations';
import {
  useDispatch,
  useSelector,
  profileExpenseLookupData,
  getProfileExpenseLookupStart,
  profileExpenseAttachment,
} from 'store';
import { LookupDto, RequestStatus, StatusLookup } from 'types';
import { AppLayout } from 'layouts';
import { FilterContextType } from 'ui/components/Filter/Filter.types';
import { NewRequest } from 'routes/Dashboard/Components/organisms/YourRequestWidget/constants';
import { StyledAddFilter, StyledButton } from 'routes/Calendar/page/Leave/Leaves.styled';
import { magicDownload } from './helper';
import { StyledCard, StyledCol, StyledExpensesBody } from './Expenses.styled';
import { ExpensesFilter } from './ExpensesFilter';
import { ExpensesTable } from './ExpeneseTable';

export const Expenses: FC = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const expensesLookup = useSelector(profileExpenseLookupData);
  const expensesAttachment = useSelector(profileExpenseAttachment);

  const [expenseTypes, setExpenseTypes] = useState<LookupDto[]>();
  const [status, setStatus] = useState<StatusLookup[]>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [filterValues, setFilterValues] = useState<FilterContextType['filterValues']>({});

  const getFilterLookups = useCallback(() => {
    try {
      dispatch(getProfileExpenseLookupStart());
    } catch (e) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [dispatch, t]);

  const getUrl = useCallback(() => {
    return NewRequest;
  }, []);

  useEffect(() => {
    if (
      expensesAttachment.status === RequestStatus.resolved &&
      expensesAttachment.data &&
      isClicked
    ) {
      magicDownload(
        expensesAttachment.data[0].fileContentType,
        expensesAttachment.data[0].fileContent,
        expensesAttachment.data[0].fileName
      );
      setIsClicked(false);
    }
  }, [expensesAttachment.status, expensesAttachment.data, isClicked]);

  useEffect(() => {
    getFilterLookups();
  }, [getFilterLookups]);

  useEffect(() => {
    if (expensesLookup.status === RequestStatus.resolved) {
      setExpenseTypes(expensesLookup.data?.expenseTypes);
      setStatus(expensesLookup.data?.status);
    }
  }, [expensesLookup.status, expensesLookup.data?.expenseTypes, expensesLookup.data?.status]);

  const setExpensesFilter = useCallback((values) => {
    setCurrentPage(0);
    setFilterValues(values);
  }, []);

  return (
    <>
      <AppLayout displayFooter={false}>
        <Header>
          <Row align="middle">
            <Col>
              <TitleH1>{t('expenses.title')}</TitleH1>
            </Col>
          </Row>
        </Header>
        <StyledCard className="layout-main">
          <Row justify={'space-between'} align={'middle'}>
            <Col xs={24} sm={12} lg={8}>
              <StyledAddFilter>
                <ExpensesFilter
                  setFilterValues={setExpensesFilter}
                  filterValues={filterValues}
                  expenseTypes={expenseTypes}
                  status={status}
                />
              </StyledAddFilter>
            </Col>
            <StyledCol xs={24} sm={12} lg={8}>
              <Space>
                <Row justify={'end'}>
                  <Col span={24}>
                    <StyledButton type={'primary'}>
                      <Link to={getUrl()}>
                        <PlusOutlined /> {t('expenses.createNew.title')}
                      </Link>
                    </StyledButton>
                  </Col>
                </Row>
              </Space>
            </StyledCol>
          </Row>
        </StyledCard>
        <StyledExpensesBody>
          <ExpensesTable
            t={t}
            filterValues={filterValues}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </StyledExpensesBody>
      </AppLayout>
    </>
  );
};

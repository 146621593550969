import styled from 'styled-components';
import { Drawer, Typography, Col, Collapse } from 'antd';
import { space, typography, colors } from 'ui/elements';

const { Text } = Typography;

export const StyledExpenseDrawer = styled(Drawer)`
  & .ant-drawer-header {
    border-bottom: ${space.zero};
  }
  & .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledExpenseWrapper = styled.div`
  flex-grow: 1;
  padding-bottom: 50px;
`;

export const StyledSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledDescription = styled(Text)`
  font-size: ${typography.size.giga};
  color: ${colors.textColorSecondary};
`;

export const StyledDescriptionText = styled(Text)`
  font-size: ${typography.size.kilo};
  color: ${colors.textColorSecondary};
`;

export const StyledSectionTitle = styled(Text)`
  font-size: ${typography.size.giga};
`;

export const StyledDetailWrapper = styled.div`
  padding: ${space.moderate} ${space.cozy};
  background-color: ${colors.backgroundGrey};
  border-radius: ${space.mini};
  margin-bottom: ${space.moderate};
`;

export const StyledPaymentValueText = styled(Text)`
  font-size: ${typography.size.giga};
`;

export const StyledAmountText = styled(Text)`
  font-size: ${typography.size.kilo};
`;

export const StyledAttachmentCol = styled(Col)`
  .ant-space-vertical {
    max-height: 200px;
    overflow-y: auto;
  }
  .ant-space-item {
    .ant-space-item {
      max-width: 80%;
    }
  }
`;

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    width: 35%;
  }
  .ant-collapse-header,
  .ant-collapse-content {
    left: -${space.moderate};
  }
  .ant-collapse-content-box {
    padding-left: ${space.zero};
  }
  > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    padding: 0 !important;
  }
`;

import React, { FC, memo } from 'react';
import { Row, Col, Typography } from 'antd';
import { StyledFooter } from './FooterStyled';

const { Text } = Typography;

interface Props {
  isLoginPage?: boolean;
}

export const FooterComponent: FC<Props> = ({ isLoginPage }) => {
  return (
    <StyledFooter $isLoginPage={isLoginPage}>
      <Row justify="center">
        <Col>
          <Text type="secondary">
            Riyadh 13512, Saudi Arabia - &copy; {new Date().getFullYear()} - All rights reserved
          </Text>
        </Col>
      </Row>
    </StyledFooter>
  );
};

export const Footer = memo(FooterComponent);

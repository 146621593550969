import { useQuery } from 'react-query';
import { leaseSites } from '../LeaseSites';
import { QUERIES } from '../../constants';
import { mapResponseToRequestsFormDetails } from './helpers';

export const useLeaseSitesRequestsFormDetails = (processInstanceId = '') =>
  useQuery(
    [QUERIES.leaseSitesRequestsFormDetails(processInstanceId)],
    async () => {
      const { data } = await leaseSites.getRequestsFormDetails(processInstanceId);
      return mapResponseToRequestsFormDetails(data);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!processInstanceId,
    }
  );

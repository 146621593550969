import { FormFields, LeaseSitesAssignWoTaskFormDetailsResponse } from 'types';

export const SITE_ACQUISITION_REQUEST_FORM_CUSTOM_FIELDS = (
  formDetails?: LeaseSitesAssignWoTaskFormDetailsResponse
): FormFields => [
  {
    businessKey: false,
    id: 'woAssignmentTaskDescription',
    label: 'Task Description',
    type: {
      name: 'text',
    },
    defaultValue: 'Please review the request details and provide your approval on the request.',
    value: {
      value: 'Please review the request details and provide your approval on the request.',
      type: {
        name: 'string',
      },
    },
    validationConstraints: [],
    properties: {
      requiredField: false,
      viewOnly: 'true',
    },
    typeName: 'text',
  },
  {
    id: 'assigneeType',
    businessKey: false,
    label: 'Assignee type',
    type: {
      name: 'multipleSelect',
      values: formDetails?.assigneeTypes?.reduce(
        (acc, { value, id }) => ({ ...acc, [id]: value }),
        {}
      ),
    },
    defaultValue: null,
    value: {
      value: null,
      type: {
        name: 'multipleSelect',
      },
    },
    validationConstraints: [
      {
        name: 'required',
        configuration: null,
      },
    ],
    properties: {
      requiredField: true,
      enumType: 'select',
    },
    typeName: 'enum',
  },
  {
    id: 'woNumber',
    businessKey: false,
    label: 'WO number',
    type: {
      name: 'string',
    },
    defaultValue: null,
    value: {
      value: null,
      type: {
        name: 'string',
      },
    },
    validationConstraints: [
      {
        name: 'required',
        configuration: null,
      },
    ],
    properties: {
      requiredField: true,
      dependencyFieldKey: 'assigneeType',
      dependencyFieldValue: '2', // SUBCONTRACTOR
    },
    typeName: 'string',
  },
  {
    id: 'subContractor',
    businessKey: false,
    label: 'Subcontractor',
    type: {
      name: 'multipleSelect',
      values: formDetails?.subContractors?.reduce(
        (acc, { value, id }) => ({ ...acc, [id]: value }),
        {}
      ),
    },
    defaultValue: null,
    value: {
      value: null,
      type: {
        name: 'multipleSelect',
      },
    },
    validationConstraints: [
      {
        name: 'required',
        configuration: null,
      },
    ],
    properties: {
      requiredField: true,
      dependencyFieldKey: 'assigneeType',
      dependencyFieldValue: '2', // SUBCONTRACTOR
      enumType: 'select',
    },
    typeName: 'enum',
  },
  {
    businessKey: false,
    id: 'feedback',
    label: 'Feedback',
    type: {
      name: 'textArea',
    },
    defaultValue: null,
    value: {
      value: null,
      type: {
        name: 'textArea',
      },
    },
    validationConstraints: [
      {
        name: 'required',
        configuration: null,
      },
    ],
    properties: {
      requiredField: true,
    },
    typeName: 'textArea',
  },
  {
    id: 'attachment',
    businessKey: false,
    label: 'Attachments',
    type: {
      name: 'file',
    },
    defaultValue: null,
    value: {
      value: null,
      type: {
        name: 'string',
      },
    },
    validationConstraints: [],
    properties: {
      requiredField: false,
    },
    typeName: 'file',
  },
  {
    id: 'approved',
    businessKey: false,
    defaultValue: null,
    value: {
      value: false,
      type: {
        name: 'boolean',
      },
    },
    validationConstraints: [],
    properties: {
      primeColor: 'true',
      requiredField: false,
    },
    typeName: 'button',
    label: 'Submit',
    type: {
      name: 'button',
    },
  },
  {
    businessKey: false,
    id: 'submit',
    label: 'Submit',
    type: {
      name: 'button',
    },
    defaultValue: null,
    value: {
      value: false,
      type: {
        name: 'boolean',
      },
    },
    validationConstraints: [],
    properties: {
      requiredField: false,
      primeColor: 'true',
    },
    typeName: 'button',
  },
];

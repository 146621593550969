import React, { FC } from 'react';
import { FullWidthAndHeightSpin } from 'ui/elements';
import { Empty } from 'antd';
import { useTranslate } from 'translations';
import {
  StyledProfileSectionContainerTitle,
  StyledProfileSectionContainerWrapper,
  StyledProfileSectionContainerEmptyAndLoadingWrapper,
} from './ProfileSectionContainer.styled';

type Props = {
  sectionName?: string;
  isLoading?: boolean;
  isEmpty?: boolean;
  hideOnEmpty?: boolean;
};

export const ProfileSectionContainer: FC<Props> = ({
  sectionName,
  isLoading,
  isEmpty,
  hideOnEmpty,
  children,
}) => {
  const { t } = useTranslate();
  return isEmpty && hideOnEmpty ? null : (
    <StyledProfileSectionContainerWrapper>
      {sectionName && (
        <StyledProfileSectionContainerTitle>{sectionName}</StyledProfileSectionContainerTitle>
      )}
      {isLoading ? (
        <StyledProfileSectionContainerEmptyAndLoadingWrapper>
          <FullWidthAndHeightSpin />
        </StyledProfileSectionContainerEmptyAndLoadingWrapper>
      ) : isEmpty ? (
        <StyledProfileSectionContainerEmptyAndLoadingWrapper>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('profile.personal.sections.empty')}
          />
        </StyledProfileSectionContainerEmptyAndLoadingWrapper>
      ) : (
        children
      )}
    </StyledProfileSectionContainerWrapper>
  );
};

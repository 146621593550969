import React, { FC } from 'react';
import {
  ILeaseSitesMappedCandidateItemGroup,
  ILILeaseMappedCandidateItemGroupFieldAttachment,
} from 'types';
import {
  StyledCandidateGroupFieldContainer,
  StyledCandidateGroupFieldsContainer,
  StyledCandidateGroupFieldTitle,
  StyledCandidateGroupFieldValue,
} from '../SiteAcquisitionCompletedCandidatesTask.styled';
import { TaskAttachment } from '../../../TaskAttachment';

type Props = {
  fieldsGroup: ILeaseSitesMappedCandidateItemGroup;
  taskDefinitionKey: string;
};

export const SiteAcquisitionCompletedCandidateGroupContent: FC<Props> = ({
  fieldsGroup,
  taskDefinitionKey,
}) => {
  const isFieldValueEmpty = (
    value?: string | number | ILILeaseMappedCandidateItemGroupFieldAttachment[]
  ) => {
    return (
      value === undefined ||
      value === null ||
      value === '' ||
      value < 0 ||
      (Array.isArray(value) &&
        (value as ILILeaseMappedCandidateItemGroupFieldAttachment[]).length <= 0)
    );
  };
  return (
    <StyledCandidateGroupFieldsContainer>
      {fieldsGroup.fields.map((field, index) => (
        <StyledCandidateGroupFieldContainer key={`candidate-group-field-${index}`} span={8}>
          <StyledCandidateGroupFieldTitle>{field.key}</StyledCandidateGroupFieldTitle>
          {field.isAttachment ? (
            isFieldValueEmpty(field.value) ? (
              <StyledCandidateGroupFieldValue>-</StyledCandidateGroupFieldValue>
            ) : (
              (field.value as ILILeaseMappedCandidateItemGroupFieldAttachment[]).map(
                (attachment) => (
                  <TaskAttachment
                    id={attachment.id}
                    name={attachment.name}
                    taskId={attachment.taskId}
                    key={attachment.id}
                    taskDefinitionKey={taskDefinitionKey}
                  />
                )
              )
            )
          ) : (
            <StyledCandidateGroupFieldValue>
              {isFieldValueEmpty(field.value) ? '-' : field.value}
            </StyledCandidateGroupFieldValue>
          )}
        </StyledCandidateGroupFieldContainer>
      ))}
    </StyledCandidateGroupFieldsContainer>
  );
};

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const leavesHistory = (state: RootState): RootState['leavesHistory'] => state.leavesHistory;

export const leavesHistoryData = createSelector(leavesHistory, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

import moment from 'moment';

export const disableBeforeDate = (current: moment.Moment) => {
  const toDay = moment().toDate();
  const newDate = moment(toDay.setDate(toDay.getDate() - 1));
  const beforeCurrentDay = current.isBefore(newDate);
  const weekend = current.isoWeekday();
  const isWeekend: boolean = weekend === 5 || weekend === 6;
  return beforeCurrentDay || isWeekend;
};

export const disableBeforeStartDate = (current: moment.Moment, startDate: moment.Moment) => {
  const beforeStartDay = current.isBefore(startDate);
  const weekend = current.isoWeekday();
  const isWeekend: boolean = weekend === 5 || weekend === 6;
  return beforeStartDay || isWeekend;
};

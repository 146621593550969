import { useQuery } from 'react-query';
import { QUERIES } from '../../constants';
import { eLibrary } from '../ELibrary';

export const useELibraryDocumentTypes = (isVisible: boolean) =>
  useQuery(
    [QUERIES.eLibraryDocumentTypes],
    async () => {
      const { data } = await eLibrary.getDocumentType();
      return data;
    },
    {
      enabled: isVisible,
      refetchOnWindowFocus: false,
    }
  );

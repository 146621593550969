import React, { FC, useCallback, memo } from 'react';
import { Col, Row } from 'antd';
import { CategoriesTreeData } from 'types';
import {
  StyledMenu,
  StyledCollapse,
  StyledCategoryRow,
  StyledPlanMenuItem,
} from './RequestCatalogTree.styled';

const { Panel } = StyledCollapse;

export interface Props {
  categoriesTree: CategoriesTreeData;
  onCategoryClick: (name: string) => void;
  onSubCategoryClick: (name: string | undefined) => void;
}

export const RequestTreeCollapseComponent: FC<Props> = ({
  categoriesTree,
  onSubCategoryClick,
  onCategoryClick,
}) => {
  const onSubItemClick = useCallback(
    (name: string) => () => {
      onSubCategoryClick(name);
    },
    [onSubCategoryClick]
  );

  const onChange = useCallback(
    (name: string) => () => {
      onCategoryClick(name);
      onSubCategoryClick(undefined);
    },
    [onCategoryClick, onSubCategoryClick]
  );

  return (
    <>
      {categoriesTree?.map((item, index) => (
        <StyledCollapse ghost accordion key={index} onChange={onChange(item.name)}>
          <Panel key={index} header={<StyledCategoryRow>{item.name}</StyledCategoryRow>}>
            <StyledMenu multiple={false}>
              {item.names.map((category, index) => (
                <StyledPlanMenuItem key={index}>
                  <Row align="middle" gutter={[8, 0]}>
                    <Col span={24}>
                      <div onClick={onSubItemClick(category.name)}>{category.name}</div>
                    </Col>
                  </Row>
                </StyledPlanMenuItem>
              ))}
            </StyledMenu>
          </Panel>
        </StyledCollapse>
      ))}
    </>
  );
};

export const RequestTreeCollapse = memo(RequestTreeCollapseComponent);

import React, { FC, memo, useState } from 'react';
import { TType } from 'translations';
import { FormInstance } from 'antd/lib/form';
import { FieldData } from 'rc-field-form/lib/interface';
import { StyledLabel, normFile } from 'routes/Calendar';
import { CreateFormItemType } from 'types';
import { FORM_ITEM_REQUIRED_RULE } from 'utils';
import { GeneratedStaticForm } from 'routes/Tasks/components/organisms';
import { disableBeforeDate, disableBeforeStartDate } from '../helper';
import { StyledForm } from '../component.style';
import {
  ExclamationCircleOutlinedStyled,
  TitleStyled,
  TitleScheduledLeaveStyled,
  DaysTextStyled,
  SubTitleStyled,
} from '../../ActionButtons/ActionButtons.styled';
import { leavesDrawerConstants } from '../../constants';

interface Props {
  t: TType;
  form: FormInstance;
  handleRescheduleFinish: () => void;
  startDate: moment.Moment;
  duration: number | null;
}

const UpdateLeaveDateComponent: FC<Props> = ({
  t,
  form,
  handleRescheduleFinish,
  startDate,
  duration,
}) => {
  const {
    RESCHEDULE_FORM_NAME,
    RESCHEDULE_START_DATE,
    RESCHEDULE_END_DATE,
    RESCHEDULE_REASON,
    MAX_TEXTAREA_LENGTH,
    ATTACHMENTS_NAME,
  } = leavesDrawerConstants;

  const [newStartDate, setnewStartDate] = useState<moment.Moment>(startDate);

  const getItems = () => {
    const res: CreateFormItemType[] = [];
    res.push(
      {
        id: `${0}`,
        label: <StyledLabel>{t('leaves.reschedule.newStartDate')}</StyledLabel>,
        name: RESCHEDULE_START_DATE,
        type: 'date',
        placeholder: t('leaves.reschedule.newStartDate'),
        rules: FORM_ITEM_REQUIRED_RULE,
        disabledDate: disableBeforeDate,
      },
      {
        id: `${1}`,
        label: <StyledLabel>{t('leaves.reschedule.newEndDate')}</StyledLabel>,
        name: RESCHEDULE_END_DATE,
        type: 'date',
        placeholder: t('leaves.reschedule.newEndDate'),
        rules: FORM_ITEM_REQUIRED_RULE,
        disabledDate: (e) => disableBeforeStartDate(e, newStartDate),
      },
      {
        id: `${2}`,
        label: <SubTitleStyled type="secondary">{t('leaves.reschedule.reason')}</SubTitleStyled>,
        name: RESCHEDULE_REASON,
        type: 'textArea',
        maxNumber: MAX_TEXTAREA_LENGTH,
        placeholder: t('leaves.cancel.commentPlaceholder'),
        rules: [
          {
            required: true,
            message: t('leaves.reschedule.validation.reason'),
          },
        ],
      },
      {
        id: `${3}`,
        name: ATTACHMENTS_NAME,
        type: 'file',
        getValueFromEvent: normFile,
        valuePropName: 'fileList',
        attachmentSecondary: true,
      }
    );
    return res;
  };

  const onFieldsChange = (changedFields: FieldData[]) => {
    const [changeField] = changedFields;
    const { name, value } = changeField || ({} as FieldData);
    if (Array.isArray(name) && name.length) {
      const [fieldName] = name;
      if (fieldName === RESCHEDULE_START_DATE) {
        setnewStartDate(value);
      }
    }
  };

  const items = getItems();

  return (
    <>
      <ExclamationCircleOutlinedStyled />
      <TitleStyled type="secondary">{t('leaves.reschedule.areYouSure')}</TitleStyled>
      <TitleScheduledLeaveStyled type="secondary">
        {t('leaves.reschedule.infoText')}
        <DaysTextStyled>
          {duration} {t('leaves.reschedule.days')}
        </DaysTextStyled>
      </TitleScheduledLeaveStyled>
      <StyledForm
        name={RESCHEDULE_FORM_NAME}
        form={form}
        layout="vertical"
        onFinish={handleRescheduleFinish}
        onFieldsChange={onFieldsChange}
      >
        <GeneratedStaticForm t={t} items={items} formState={form} />
      </StyledForm>
    </>
  );
};

export const UpdateLeaveDate = memo(UpdateLeaveDateComponent);

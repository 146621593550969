import React, { FC, memo, Fragment, useCallback, useState, useMemo } from 'react';
import Form, { useForm } from 'antd/lib/form/Form';
import { useLeaseDistricts, useLeaseSitesFormDetails, useLeaseSitesRequestMutation } from 'api';
import { useTranslate } from 'translations';
import { NEW_SITE_ACQUISITION_FORM } from 'ui/components';
import { VALIDATE_MESSAGES } from 'utils/formValidation';
import { FieldData } from 'rc-field-form/lib/interface';
import { Empty, Spin } from 'antd';
import { LeaseSitesFormData } from 'types';
import { FullWidthSpin } from 'ui/elements/Spin';
import { useHistory } from 'react-router-dom';
import { NewSiteAcquisitionProcessFormFields } from './NewSiteAcquisitionProcessFormFields';

export const NewSiteAcquisitionProcessFormComponent: FC<Props> = ({ formName }) => {
  const [selectedRegionId, setSelectedRegionId] = useState<string | undefined>();

  const formDetailsInfo = useLeaseSitesFormDetails();
  const districtsInfo = useLeaseDistricts(selectedRegionId);

  const { t } = useTranslate();
  const [form] = useForm();
  const history = useHistory();

  const requestMutation = useLeaseSitesRequestMutation();

  const onRegionChange = useCallback(() => {
    form.resetFields([NEW_SITE_ACQUISITION_FORM.district]);
  }, [form]);

  const onFieldsChange = useCallback((changedFields: FieldData[]) => {
    const [changedField] = changedFields;
    const { name, value } = changedField || ({} as FieldData);
    if (Array.isArray(name) && name.length) {
      const [fieldName] = name;

      if (fieldName === NEW_SITE_ACQUISITION_FORM.region) {
        setSelectedRegionId(value);
      }
    }
  }, []);

  const onSubmitFinish = useCallback(
    async (formValues: LeaseSitesFormData) => {
      requestMutation.mutate(formValues, {
        onSuccess(data) {
          history.replace({
            pathname: `/tasks/${data.processInstanceId}`,
            state: { defaultTab: '0' },
          });
        },
      });
    },
    [history, requestMutation]
  );

  const isError = useMemo(() => formDetailsInfo.isError || districtsInfo.isError, [
    formDetailsInfo.isError,
    districtsInfo.isError,
  ]);
  const isLoading = useMemo(() => formDetailsInfo.isLoading, [formDetailsInfo.isLoading]);
  const spinning = useMemo(() => districtsInfo.isLoading || requestMutation.isLoading, [
    districtsInfo.isLoading,
    requestMutation.isLoading,
  ]);

  return (
    <Fragment>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        validateMessages={VALIDATE_MESSAGES(t)}
        onFieldsChange={onFieldsChange}
        onFinish={onSubmitFinish}
      >
        {isError ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
        ) : isLoading ? (
          <FullWidthSpin />
        ) : (
          <Spin spinning={spinning} delay={100}>
            <NewSiteAcquisitionProcessFormFields
              t={t}
              onRegionChange={onRegionChange}
              form={form}
              description={
                formDetailsInfo.data?.processDescription || t('initiateBtsProcess.description')
              }
              formDetailsOptions={formDetailsInfo.data}
              districtsOptions={districtsInfo.data}
            />
          </Spin>
        )}
      </Form>
    </Fragment>
  );
};

export const NewSiteAcquisitionProcessForm = memo(NewSiteAcquisitionProcessFormComponent);

interface Props {
  formName?: string;
}

import { GetDriveItemData } from 'types';
import { Core, GET_DRIVE_ITEM } from 'api';

interface GetOneDriveItem {
  (driveId: string, itemId: string): Promise<{ data: GetDriveItemData }>;
}

class OneDriveGetDriveItem extends Core {
  getOneGetDriveItem: GetOneDriveItem = async (driveId, itemId) =>
    this.get(GET_DRIVE_ITEM(driveId, itemId));
}

export const oneDriveGetDriveItem = new OneDriveGetDriveItem();

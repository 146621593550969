import { notification } from 'antd';
import { AxiosRequestConfig, AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useTranslate } from 'translations';
import { profileExpenses } from '../ProfileExpenses';

export const useNewAllowanceMutation = (adoId: string) => {
  const { t } = useTranslate();
  return useMutation(
    async (formData: AxiosRequestConfig['data']) => {
      const { data } = await profileExpenses.postAllowanceExpense(adoId, formData);
      return data;
    },
    {
      onError: (error: AxiosError) => {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: error.response?.data.errors[0].message,
        });
      },
    }
  );
};

import { Moment } from 'moment/moment';
import { UploadFile } from 'antd/lib/upload/interface';

export interface IInvoiceAttachment {
  name: string;
  fileExtension: string;
  fileSize: number;
  taskAttachmentId: string;
  taskId: string;
}

export interface IQuotationDetails {
  supplierName: string;
  currency: string;
  quotedPrice: number;
  conversionRate: number;
  quotedPriceInSAR: number;
  advancedPayment: number;
  advanceAmountTobePaid: number;
  description: string;
  attachments: IInvoiceAttachment;
}

export interface IBillDetails {
  invoiceDate: string;
  invoiceNumber: string;
  invoiceAmount: number;
  includeWitholdingTax?: string;
  witholdingTaxAmount?: string;
  vatPercentage?: string;
  vatAmount?: string;
  taxAmount?: number;
  conversionRate: number;
  invoiceAmountSA: string;
  invoiceReason?: string;
  invoiceAttachments?: IInvoiceAttachment[];
}

export interface IPreferredPaymentMethod {
  paymentMethod: string;
  accountNumber: string;
  newAccountNumber: string;
  paymentAttachment: IInvoiceAttachment[];
}

export interface INonPO_Data {
  ExpenseType: string;
  invoiceType: string;
  Department: string;
  UtilityType?: string;
  EventDate?: string;
  Others?: string;
  SupplierName?: string;
  Currency: string;
  TotalAmount?: number;
  AlreadyPaid: string;
  description: string;
  paymentOutsideSA?: string;
  serviceOutsideSA?: string;
  ServiceType: string;
  Description: string;
  billDetails: IBillDetails[];
  paymentMethod: IPreferredPaymentMethod[];
  quotationDetails: IQuotationDetails[];
}

type OmitFields = 'billDetails' | 'preferredPaymentMethod' | 'quotationDetails';

export interface INonPoAttachmentMapped {
  uid: string;
  name: string;
  status: string;
  url: string;
  originalObject: IInvoiceAttachment;
}

export type IQuotationDetailsMapped = Omit<IBillDetails, 'invoiceAttachments'> & {
  quotationAttachments?: INonPoAttachmentMapped;
};

export type IBillDetailsMapped = Omit<IBillDetails, 'invoiceDate' | 'invoiceAttachments'> & {
  billAttachments?: INonPoAttachmentMapped;
  invoiceDate: Moment;
};

export type IPreferredPaymentMethodMapped = Omit<IPreferredPaymentMethod, 'paymentAttachment'> & {
  paymentAttachment?: INonPoAttachmentMapped[];
};

export interface IMappedNonPOData extends Omit<INonPO_Data, OmitFields> {
  billDetails: IBillDetailsMapped[];
  preferredPaymentMethod: IPreferredPaymentMethodMapped[];
  quotationDetails: IQuotationDetailsMapped[];
}

export interface INonPOUploadAttachments {
  billDetails: Array<Array<UploadFile>>;
  preferredPaymentMethod: Array<Array<UploadFile>>;
}

export interface INonPOSubmitResponse {
  taskId: string;
  processInstanceId: string;
  processDefinitionId: string;
  activityId: string;
  prettyId: string;
  quotationDetailsIds: number[];
  billDetailsIds: number[];
  preferredPaymentMethodIds: number[];
}

export interface NONPOLookupItem {
  id: string;
  value: string;
  key: string;
}

interface NONPOResponseLookup {
  PAYMENT_OUTSIDE_SAUDI_ARABIA: NONPOLookupItem[];
  EXPENSE_TYPE: NONPOLookupItem[];
  INVOICE_AMOUNT_INCLUDE_WITHOLDING_TAX: NONPOLookupItem[];
  INVOICE_TYPE: NONPOLookupItem[];
  VAT_PERCENTAGE: NONPOLookupItem[];
  UTILITY_TYPE: NONPOLookupItem[];
  SERVICE_OUTSIDE_SAUDI_ARABIA: NONPOLookupItem[];
  ALREADY_PAID: NONPOLookupItem[];
  PAYMENT_METHOD: NONPOLookupItem[];
  SERVICE_TYPE: NONPOLookupItem[];
  WHT_PERCENTAGE: NONPOLookupItem[];
}

export interface NONPOCurrencyList {
  value: number;
  label: string;
}

export interface NONPOLookUpsResponseData {
  processDescription: string;
  lookups: NONPOResponseLookup;
  currencyList: NONPOCurrencyList[];
}

export interface NONPOConversionRateResponseData {
  amount: number;
  conversionRate: number;
  amountWithConversionRate: number;
  conversionDate: Date;
}

export interface NONPOWitholdingTaxRateResponseData {
  amount: number;
  holdingTaxRatePercentage: number;
  holdingTaxRateCode: string;
  holdingTaxAmount: number;
}

export interface NonPoInvoiceSubmitResponse {
  taskId: string;
  processInstanceId: string;
  processDefinitionId: string;
  activityId: string;
  prettyId: string;
  quotationId?: Array<number>;
  billId?: Array<number>;
}

export interface NonPoSubmitCommon {
  expenseTypeId: number | null;
  utilityTypeid: number | null;
  others: string;
  invoiceTypeId: number | null;
  alreadyPaidId: number | null;
  eventDate: string;
  department: string;
  currencyId: number;
  description: string;
  paymentOutsideSaId: number | null;
  serviceOutsideSaId: number | null;
  serviceTypeId: number | null;
  totalAmount: number;
  supplierName: string;
  miscellaneousSupplierName: string | null;
  supplierNumber: string;
  supplierSiteId: string;
  paymentMethodId: number | null;
  accountNumber: string;
  sadadBillerCode: string;
  sadadAccountNumber: string;
  whtPercentageId: number | null;
}

export interface NonPoSubmitExpense extends NonPoSubmitCommon {
  nonPoBillDetails: NonPoSubmitBillDetail[];
  advanceLinkIds: number[];
}

export interface NonPoSubmitAdvance extends NonPoSubmitCommon {
  nonPoQuotationDetails: NonPoSubmitQuotationDetail[];
}

export interface NonPoSubmitQuotationDetail {
  quotationMiscellaneousSupplierName: string | null;
  quotationSupplierName: string;
  supplierNumber: string;
  supplierSiteId: string;
  currencyId: number;
  quotedPrice: number;
  quotationConversionRate: number;
  quotedPriceSar: number;
  advancePaymentPercentage: number;
  advanceAmountToBePaid: number;
  quotationDescription: string;
  recommended: boolean;
  justification: string;
}

export interface NonPoSubmitBillDetail {
  invoiceDate: string;
  invoiceNumber: string;
  invoiceReason: string;
  invoiceAmount: number;
  invoiceIncludeWHTId: number;
  whtRate: number | null;
  whtRateCode: string | null;
  whtAmount: number | null;
  vatPercentageId: number | null;
  vatAmount: number | null;
  billConversionRate: number;
  invoicePriceSar: number;
}

export interface IPayloadGetNonPoInvoiceAdvanceRequest {
  supplierNumber: string;
  processInstanceId?: string;
}

export interface NonPoInvoiceAdvanceRequestResponse {
  id: number;
  invoiceId: string;
  createdDate: string;
  sufaraId: string;
  totalAmount: number;
  description: string;
  paidAmountInSAR: number;
  totalAmountInSAR: number;
}

export interface NonPoInvoiceSuppliersResponse {
  supplierCode: string;
  supplierName: string;
  supplierSite: string;
}

export interface NonPoEditRequestResponse {
  id: number;
  expenseTypeId: number;
  utilityTypeId?: number;
  others?: string;
  invoiceTypeId: number;
  alreadyPaidId: number;
  eventDate?: string;
  department: string;
  currencyId: number;
  description: string;
  serviceTypeId?: number;
  paymentOutsideSaId: number;
  serviceOutsideSaId: number;
  totalAmount: number;
  supplierName: string;
  supplierNumber: string;
  supplierSiteId: string;
  paymentMethodId: number;
  accountNumber: string;
  sadadBillerCode: string;
  sadadAccountNumber: string;
  miscellaneousSupplierName?: string;
  whtPercentageId: number;
  processInstanceId: string;
  processDefinitionId: string;
  formTaskId: string;
  activityId: string;

  nonPoQuotationDetailsDto: NonPoQuotationDetailsDto[];
  nonPoEditBillDetailsDto: NonPoEditBillDetailsDto[];
  advanceLinkIds: number[];
}
export interface EditTaskAttachment {
  createTime: string;
  id: string;
  name: string;
  rootProcessInstanceId: string;
  taskId: string;
  type: string;
  itemId: number;
  fileSize: number;
}

export interface EditTaskAttachmentMapped {
  uid: string;
  name: string;
  status: string;
  url: string;
  originalObject: EditTaskAttachment;
}

export type INonPoEditBillDetailsDtoMapped = Omit<
  EditTaskAttachmentMapped,
  'invoiceDate' | 'NonPoEditBillDetailsDtoAttachmentList'
> & {
  invoiceDate: Moment;
  NonPoEditBillDetailsDtoAttachmentList?: EditTaskAttachmentMapped[];
};

export type INonPoQuotationDetailsDtoMapped = Omit<
  EditTaskAttachmentMapped,
  'NonPoQuotationDetailsDtoAttachmentList'
> & {
  NonPoQuotationDetailsDtoAttachmentList?: EditTaskAttachmentMapped[];
};

export interface NonPoQuotationDetailsDto {
  id: number;
  quotationSupplierName: string;
  supplierNumber: string;
  supplierSiteId: string;
  currencyId: number;
  quotedPrice: number;
  quotationConversionRate: number;
  quotedPriceSar: number;
  advancePaymentPercentage: number;
  advanceAmountToBePaid: number;
  quotationDescription: string;
  recommended: boolean;
  quotationMiscellaneousSupplierName: string;
  justification: string;
  quotationTaskAttachment: EditTaskAttachment[];
}

export interface NonPoEditBillDetailsDto {
  id: number;
  invoiceDate: string;
  invoiceNumber: string;
  invoiceReason: string;
  invoiceAmount: number;
  invoiceIncludeWHTId: number;
  whtRate: number;
  whtRateCode: string;
  whtAmount: number;
  vatPercentageId: number;
  vatAmount: number;
  billConversionRate: number;
  invoicePriceSar: number;
  billTaskAttachment: EditTaskAttachment[];
}
export interface NonPoUpdateRequest {
  id: number;
  utilityTypeId: number | null;
  others: string | null;
  alreadyPaidId: number;
  description: string;
  miscellaneousSupplierName: string | null;
  serviceTypeId: number | null;
  currencyId: number;
  totalAmount: number;
  paymentMethodId: number;
  accountNumber: number;
  sadadBillerCode: number | null;
  sadadAccountNumber: number | null;
  whtPercentageId: number | null;
  eventDate: string | null;
}

export interface NonPoUpdateExpense extends NonPoUpdateRequest {
  nonPoEditBillRequestDto: NonPoEditBillRequestDto[];
  advanceLinkIds: number[];
  deletedBills: number[];
}

export interface NonPoUpdateAdvance extends NonPoUpdateRequest {
  nonPoEditQuotationRequestDto: NonPoEditQuotationRequestDto[];
  deletedQuotation: number[];
}
export interface NonPoEditQuotationRequestDto {
  id: number;
  currencyId: number;
  quotedPrice: number;
  quotationConversionRate: number;
  quotedPriceSar: number;
  advancePaymentPercentage: number;
  advanceAmountToBePaid: number;
  quotationDescription: string;
  recommended: boolean;
  justification: string;
  quotationMiscellaneousSupplierName: string;
  deletedAttachment: string[];
}
export interface NonPoEditBillRequestDto {
  id: number;
  invoiceDate: string;
  invoiceNumber: string;
  invoiceReason: string;
  invoiceAmount: number;
  invoiceIncludeWHTId: number;
  whtRate: number;
  whtRateCode: number;
  whtAmount: number;
  vatPercentageId: number;
  vatAmount: number;
  billConversionRate: number;
  invoicePriceSar: number;
  deletedAttachment: string[];
}

export interface NonPoUpdateRequestAdvanceParams {
  taskId: string;
  processInstanceId: string;
  requestData: NonPoUpdateAdvance;
}

export interface NonPoUpdateRequestExpenseParams {
  taskId: string;
  processInstanceId: string;
  requestData: NonPoUpdateExpense;
}

export enum NONPOFiledIds {
  /**
   * Request detail
   */
  RequestDetail_ExpenseType = 'expenseType',
  RequestDetail_InvoiceType = 'invoiceType',
  RequestDetail_UtilityType = 'utilityType',
  RequestDetail_OtherUtilityType = 'otherUtilityType',
  RequestDetail_Department = 'department',
  RequestDetail_SupplierName = 'supplierName',
  RequestDetail_Miscellaneous_SupplierName = 'miscellaneousSupplierName',
  RequestDetail_ERPInvoice = 'erpInvoice',
  RequestDetail_Currency = 'currency',
  RequestDetail_TotalAmount = 'totalAmount',
  RequestDetail_AlreadyPaid = 'alreadyPaid',
  RequestDetail_PaymentToBeDoneOutsideSaudiArabia = 'paymentToBeDoneOutsideSaudiArabia',
  RequestDetail_ServiceProvidedOutsideSaudiArabia = 'serviceProvidedOutsideSaudiArabia',
  RequestDetail_WithHoldingTaxPercentage = 'withholdingTaxPercentage',
  RequestDetail_ServiceType = 'serviceType',
  RequestDetail_EventDate = 'eventDate',
  RequestDetail_Description = 'description',
  /**
   * Quotation detail
   */
  QuotationDetail_QuotationId = 'id',
  QuotationDetail_QuotationSupplierName = 'quotationSupplierName',
  QuotationDetail_SupplierSiteId = 'supplierSiteId',
  QuotationDetail_SupplierNumber = 'supplierNumber',
  QuotationDetail_AdvanceLinkIds = 'advanceLinkIds',
  QuotationDetail_QuotationDetails = 'quotationDetails',
  QuotationDetail_AttachmentList = 'quotationDetailsAttachmentList',
  QuotationDetail_SupplierName = 'supplierName',
  QuotationDetail_Miscellaneous_SupplierName = 'miscellaneousSupplierName',
  QuotationDetail_Currency = 'currency',
  QuotationDetail_QuotedPrice = 'quotedPrice',
  QuotationDetail_ConversionRate = 'conversionRate',
  QuotationDetail_QuotedPriceSAR = 'quotedPriceSAR',
  QuotationDetail_AdvancePayment = 'advancePayment',
  QuotationDetail_AdvanceAmountToBePaid = 'advanceAmountToBePaid',
  QuotationDetail_Description = 'description',
  QuotationDetail_MarkAsRecommended = 'markAsRecommended',
  QuotationDetail_Justification = 'justification',

  /**
   * Billing detail
   */
  BillDetails_BillId = 'id',
  BillDetails_WithHoldingRateCode = 'whtRateCode',
  BillDetails_WithHoldingRate = 'whtRate',
  BillDetails_BillDetails = 'billDetails',
  BillDetails_AttachmentList = 'billDetailsAttachmentList',
  BillDetails_InvoiceDate = 'invoiceDate',
  BillDetails_InvoiceNumber = 'invoiceNumber',
  BillDetails_InvoiceAmount = 'invoiceAmount',
  BillDetails_IncludesWithHoldingTax = 'includesWithHoldingTax',
  BillDetails_WithHoldingTaxAmount = 'withholdingTaxAmount',
  BillDetails_VatPercentage = 'vatPercentage',
  BillDetails_VatAmount = 'vatAmount',
  BillDetails_TaxAmount = 'taxAmount',
  BillDetails_ConversionRate = 'conversionRate',
  BillDetails_InvoiceAmountInSAR = 'invoiceAmountInSAR',
  BillDetails_InvoiceReason = 'invoiceReason',
  /**
   * Payment detail
   */
  PaymentDetail_SadaAccountNumber = 'sadadAccountNumber',
  PaymentDetail_SadaBillerCode = 'sadadBillerCode',
  PaymentDetail_PaymentMethod = 'paymentMethod',
  PaymentDetail_AccountNumber = 'accountNumber',
  PaymentDetail_NewAccountNumber = 'newAccountNumber',
  PaymentDetail_PaymentMethodAttachmentList = 'paymentMethodAttachmentList',
}

export interface IGetNonPoConversionRateParams {
  currencyId: number;
  amount: number;
}

export interface IGetNonWitholdingTaxParams {
  expensesTypeId: number;
  amount: number;
}

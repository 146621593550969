import React, { FC } from 'react';
import { Filter } from 'ui';
import { useHistory } from 'react-router-dom';
import { useTranslate } from 'translations';
import { FilterContextType } from 'ui/components/Filter/Filter.types';
import { LookupDto, StatusLookup } from 'types';

const expensesFilterItems = {
  typeKey: 'typeKey',
  status: 'status',
  createdDate: 'createdDate',
};

interface ExpenseFilterProps {
  setFilterValues: (values: FilterContextType['filterValues']) => void;
  filterValues: FilterContextType['filterValues'];
  expenseTypes?: LookupDto[];
  status?: StatusLookup[];
}

export const ExpensesFilter: FC<ExpenseFilterProps> = ({
  setFilterValues,
  filterValues,
  expenseTypes,
  status,
}) => {
  const history = useHistory();
  const { t } = useTranslate();

  const requestTypeOptions = expenseTypes?.map(({ key, value }) => ({
    label: value,
    value: key,
    text: value,
  }));

  const statusOptions = status?.map(({ key, value }) => ({
    label: value,
    value: key,
    text: value,
  }));

  return (
    <Filter
      items={[
        {
          type: 'select',
          name: expensesFilterItems.typeKey,
          placeholder: t('expenses.filter.requestType'),
          options: requestTypeOptions,
        },
        {
          type: 'select',
          name: expensesFilterItems.status,
          placeholder: t('expenses.filter.status'),
          options: statusOptions,
        },
        {
          type: 'rangePicker',
          name: expensesFilterItems.createdDate,
          placeholder: [t('expenses.filter.dateFrom'), t('expenses.filter.to')],
        },
      ]}
      t={t}
      history={history}
      isLoading={false}
      onFilterCallback={setFilterValues}
      dateFormat={'DD/MM/YYYY'}
      enableQueryParams={false}
      initialFilterValues={filterValues}
    />
  );
};

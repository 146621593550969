import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const taskList = (state: RootState): RootState['taskList'] => state.taskList;

export const taskListData = createSelector(taskList, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

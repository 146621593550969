import { FormInstance } from 'antd/lib/form';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { useFetchCustomersName } from 'api/StaticForm/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { normFile } from 'routes/Calendar/page';
import { StaticFormWithGroups } from 'routes/Tasks/components';

import { TType } from 'translations';
import { CreateFormItemType, GroupOfFormItem, ScrapInvoiceRequestType } from 'types';

import {
  CommonFieldName,
  DEFAULT_TOTAL_LETTER_ITEM,
  FROM_ITEM_LIST,
  ScrapInvoiceItemName,
  ScrapInvoiceName,
  WRAPPER_COL_SPAN_SIZE_21,
} from 'ui/components/StaticForm';
import {
  FORM_ITEM_FILE_RULES_MAX,
  FORM_ITEM_REQUIRED_RULE,
  INPUT_VALIDATION_DECIMAL_Digit_PATTERN,
  INPUT_VALIDATION_MAX_CHARACTERS_PATTERN,
  INPUT_VALIDATION_MAX_DIGIT_PATTERN,
} from 'utils';

const MAX_DIGITS_8_FOR_INPUT_NUMBER = 8;
const MAX_DIGITS_3_FOR_INPUT_NUMBER = 3;
const MAX_CHARACTERS = 51;
const MAX_DECIMAL = 2;

interface ScrapInvoiceFiledProps {
  t: TType;
  form: FormInstance;
  formDetails: ScrapInvoiceRequestType;
}

export const ScrapInvoiceFiledComponent: React.FC<ScrapInvoiceFiledProps> = ({
  t,
  form,
  formDetails,
}) => {
  const [isSelectedCustomer, setIsSelectedCustomer] = useState<boolean>(false);
  const [selectSearchText, setSearchText] = useState<string>();
  const [searchProps, setSearchParams] = useState<{ customerName?: string }>();
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  const {
    data: customersName,
    isLoading,
    refetch: fetchNewCustomersName,
    status,
  } = useFetchCustomersName({
    params: { ...searchProps },
  });

  const handleSelectCustomer = (value?: string) => {
    if (value) setIsSelectedCustomer(true);
  };

  const handleOnSearchText = (search: string) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(() => {
      if (search) {
        setSearchText(search);
        const searchQuery = { customerName: search };
        setSearchParams(searchQuery);
      }
    }, 350);
  };

  useEffect(() => {
    if (searchProps) {
      fetchNewCustomersName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProps]);

  useEffect(() => {
    if ((status === 'success' || status === 'error') && timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, [status]);

  const scrapFormFields = () => {
    const res: CreateFormItemType[] = [];

    res.push(
      {
        id: `${0}`,
        label: t('scrapInvoice.staticForm.label.customerName'),
        name: ScrapInvoiceName.customerName,
        type: 'select',
        hasFeedback: true,
        options: customersName,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        onSelect: handleSelectCustomer,
        filterOption: false,
        onSearch: (value) => handleOnSearchText(value),
        isLoading: isLoading,
        searchText: selectSearchText,
        placeholder: t('scrapInvoice.staticForm.placeholder.customerName'),
        hasIconInfo: true,
        iconInfoLabel: t('scrapInvoice.staticForm.info.customerName'),
      },
      {
        id: `${1}`,
        label: t('scrapInvoice.staticForm.label.purpose'),
        name: ScrapInvoiceName.purpose,
        type: 'select',
        placeholder: t('scrapInvoice.staticForm.placeholder.purpose'),
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        options: formDetails.saleOfAssetPurposes,
        isHidden: !isSelectedCustomer,
        hasFeedback: true,
        selectTheValueAsKey: true,
      }
    );
    return res;
  };

  const getItemFields = (field: FormListFieldData) => {
    const res: CreateFormItemType[] = [];

    res.push(
      {
        id: `${0}`,
        label: t('scrapInvoice.staticForm.label.item.assetNumber'),
        name: [field.name, ScrapInvoiceItemName.assetNumber],
        type: 'inputNumber',
        placeholder: t('scrapInvoice.staticForm.placeholder.item.assetNumber'),
        rules: INPUT_VALIDATION_MAX_DIGIT_PATTERN(
          true,
          MAX_DIGITS_8_FOR_INPUT_NUMBER,
          t('requestForm.validation.rule.message.maxDigit.8')
        ),
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
      },
      {
        id: `${1}`,
        label: t('scrapInvoice.staticForm.label.item.assetName'),
        name: [field.name, ScrapInvoiceItemName.assetName],
        type: 'input',
        placeholder: t('scrapInvoice.staticForm.placeholder.item.assetName'),
        rules: INPUT_VALIDATION_MAX_CHARACTERS_PATTERN(
          true,
          MAX_CHARACTERS,
          t('requestForm.validation.rule.message.maxCharacters.50')
        ),
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
      },
      {
        id: `${2}`,
        label: t('scrapInvoice.staticForm.label.item.cTagNumber'),
        name: [field.name, ScrapInvoiceItemName.cTagNumber],
        type: 'inputNumber',
        placeholder: t('scrapInvoice.staticForm.placeholder.item.cTagNumber'),
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        rules: INPUT_VALIDATION_MAX_DIGIT_PATTERN(
          false,
          MAX_DIGITS_8_FOR_INPUT_NUMBER,
          t('requestForm.validation.rule.message.maxDigit.8')
        ),
      },
      {
        id: `${3}`,
        label: t('scrapInvoice.staticForm.label.item.p-TagNumber'),
        name: [field.name, ScrapInvoiceItemName.pTagNumber],
        type: 'inputNumber',
        placeholder: t('scrapInvoice.staticForm.placeholder.item.pTagNumber'),
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        rules: INPUT_VALIDATION_MAX_DIGIT_PATTERN(
          false,
          MAX_DIGITS_8_FOR_INPUT_NUMBER,
          t('requestForm.validation.rule.message.maxDigit.8')
        ),
      },
      {
        id: `${4}`,
        label: t('scrapInvoice.staticForm.label.item.technicalReportOfAssets'),
        name: [field.name, ScrapInvoiceItemName.technicalReportOfAssets],
        type: 'multiSelect',
        options: formDetails.technicalReportOfAssets,
        placeholder: t('scrapInvoice.staticForm.placeholder.item.technicalReportOfAssets'),
        rules: FORM_ITEM_REQUIRED_RULE,
        filterOption: false,
        selectTheValueAsKey: true,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
      },
      {
        id: `${5}`,
        label: t('scrapInvoice.staticForm.label.item.quantity'),
        name: [field.name, ScrapInvoiceItemName.quantity],
        type: 'inputNumber',
        placeholder: t('scrapInvoice.staticForm.placeholder.item.quantity'),
        rules: INPUT_VALIDATION_MAX_DIGIT_PATTERN(
          true,
          MAX_DIGITS_3_FOR_INPUT_NUMBER,
          t('requestForm.validation.rule.message.maxDigit.3')
        ),
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
      },
      {
        id: `${6}`,
        label: t('scrapInvoice.staticForm.label.item.amountSAR'),
        name: [field.name, ScrapInvoiceItemName.amountSAR],
        type: 'inputNumber',
        placeholder: t('scrapInvoice.staticForm.label.item.amountSAR'),
        rules: INPUT_VALIDATION_DECIMAL_Digit_PATTERN(
          true,
          MAX_DECIMAL,
          t('requestForm.validation.rule.message.maxDecimal.2')
        ),
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
      }
    );
    return res;
  };

  const getGroupOfForms = () => {
    const res: GroupOfFormItem[] = [];
    res.push({
      '': [
        {
          id: `${0}`,
          label: t('commonField.staticForm.label.description'),
          name: CommonFieldName.description,
          type: 'textArea',
          maxNumber: DEFAULT_TOTAL_LETTER_ITEM,
          placeholder: t('tasks.textArea.placeHolder'),
          hasFeedback: true,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        },
        {
          id: `${1}`,
          label: t('scrapInvoice.staticForm.label.totalAmountSAR'),
          name: ScrapInvoiceName.totalAmountSAR,
          type: 'input',
          placeholder: t('scrapInvoice.staticForm.label.totalAmountSAR'),
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          disabled: true,
        },
      ],
      Attachment: [
        {
          id: `${0}`,
          name: CommonFieldName.attachment,
          type: 'file',
          getValueFromEvent: normFile,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          rules: FORM_ITEM_FILE_RULES_MAX,
          valuePropName: 'fileList',
        },
      ],
    });
    return res;
  };
  return (
    <React.Fragment>
      <StaticFormWithGroups
        t={t}
        description={formDetails.processDescription}
        form={form}
        getItems={getItemFields}
        itemGroupName={t('scrapInvoice.staticForm.groupName.item')}
        maxLimit={formDetails?.maxAssetRecords - 1}
        formListName={FROM_ITEM_LIST}
        startFormFields={scrapFormFields}
        listOfFormField={getGroupOfForms}
        isHidden={!isSelectedCustomer}
      />
    </React.Fragment>
  );
};

export const ScrapInvoiceFields = React.memo(ScrapInvoiceFiledComponent);

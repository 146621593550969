import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetPaySlipsPdfFileFail,
  GetPaySlipsPdfFileSuccess,
  GetPaySlipsPdfFileStart,
  getPaySlipsPdfFileStart,
  getPaySlipsPdfFileSuccess,
  getPaySlipsPdfFileFail,
} from './paySlipsPdfFileSlice';

export const getPaySlipsPdfFileEpic: Epic<
  GetPaySlipsPdfFileStart,
  GetPaySlipsPdfFileSuccess | GetPaySlipsPdfFileFail,
  RootState,
  typeof API
> = (action$, _, { paySlips }) =>
  action$.pipe(
    filter(getPaySlipsPdfFileStart.match),
    exhaustMap(({ payload }) =>
      from(paySlips.getPaySlipsPdfFile(payload.userId, payload.month, payload.year)).pipe(
        map(({ data }) => getPaySlipsPdfFileSuccess(data)),
        catchError((error) => of(getPaySlipsPdfFileFail(error?.response?.data)))
      )
    )
  );

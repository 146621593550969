import styled from 'styled-components';
import { Image, Avatar } from 'antd';

export const StyledImage = styled(Image)`
  object-fit: cover;
`;

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledAvatar = styled(Avatar)<{ $color?: string }>`
  .ant-image,
  .ant-image-img {
    width: inherit;
    height: inherit;
  }

  .ant-avatar-lg {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  background-color: ${(props: { $color?: string }) => (props.$color ? props.$color : null)};
`;

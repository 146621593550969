import { QueryKey, useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from 'api/constants';
import { IPublicProfileInfo, UserActivityStatus } from 'types';
import { newProfile } from '../NewProfile';

export const useGetPublicProfileInfo = (email?: string) =>
  useQuery<unknown, unknown, IPublicProfileInfo, QueryKey>(
    [QUERIES.publicProfileInfo, email],
    async () => {
      const { data } = await newProfile.getPublicProfileInfo(email ?? '');
      const publicProfileInfo: IPublicProfileInfo = {
        hcmId: data.hcmId,
        adOid: data.adOid,
        firstName: data.firstName,
        basicInfo: {
          displayName: data.displayName,
          email: data.email,
          nickName: data.nickName,
          anniversaryYears: data.anniversaryYears,
          status: data.status as UserActivityStatus,
          mobileNumber: data.mobileNumber,
        },
        overView: {
          department: data.department,
          location: data.location,
          managerName: data.managerName,
          mangerEmail: data.managerEmail,
          mangerPersonHcmid: data.managerPersonId,
          position: data.positionName,
        },
      };
      return publicProfileInfo;
    },
    {
      enabled: !!email,
      ...PERSIST_QUERY_CONFIG,
    }
  );

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskDiagramLink, RequestCommonError, RequestStatus } from 'types';
import { TaskDiagramLinkState } from './taskDiagramTypes';

export const emptyTaskDiagramState: TaskDiagramLinkState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetTaskDiagramStart = PayloadAction<{ processDefinitionId: string; rootPath?: string }>;
export type GetTaskDiagramSuccess = PayloadAction<TaskDiagramLink>;
export type GetTaskDiagramFail = PayloadAction<RequestCommonError>;

const taskDiagram = createSlice({
  name: 'taskDiagram',
  initialState: emptyTaskDiagramState,
  reducers: {
    getTaskDiagramStart(state, _: GetTaskDiagramStart) {
      state.status = RequestStatus.pending;
    },
    getTaskDiagramSuccess(state, action: GetTaskDiagramSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getTaskDiagramFail(state, action: GetTaskDiagramFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getTaskDiagramReset(state) {
      state.status = RequestStatus.idle;
      state.data = undefined;
    },
  },
});

export const {
  getTaskDiagramStart,
  getTaskDiagramFail,
  getTaskDiagramSuccess,
  getTaskDiagramReset,
} = taskDiagram.actions;
export default taskDiagram.reducer;

import { useForm } from 'antd/lib/form/Form';
import React, { FC, Fragment, memo } from 'react';
import { Form } from 'antd';
import { TType } from 'translations';
import { VALIDATE_MESSAGES } from 'utils';
import { ActingAppointmentDetailForm } from 'api/mocks/data';
import { ActingAppointmentFields } from './ActingAppointmentFields';

export interface ActingAppointmentProps {
  adoid: string;
  t: TType;
  formName?: string;
}

export const ActingAppointmentFormComponent: FC<ActingAppointmentProps> = ({
  t,
  adoid,
  formName,
}) => {
  const [form] = useForm();

  return (
    <Fragment>
      <Form form={form} layout="vertical" name={formName} validateMessages={VALIDATE_MESSAGES(t)}>
        <ActingAppointmentFields t={t} form={form} fromDetails={ActingAppointmentDetailForm} />
      </Form>
    </Fragment>
  );
};

export const ActingAppointment = memo(ActingAppointmentFormComponent);

import styled from 'styled-components';
import { Col, Collapse, Row } from 'antd';
import { colors } from 'ui/elements';

export const StyledDescriptionContainer = styled(Row)`
  margin: 24px 0;
`;

export const StyledCandidateCollapse = styled(Collapse)`
  margin-top: 8px;

  .ant-collapse-item {
    border: 1px solid ${colors.lightActive};
    border-radius: 4px;
    margin-bottom: 25px;
    padding: 15px;

    .ant-collapse-header {
      justify-content: flex-start !important;

      .ant-collapse-arrow {
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
`;

export const StyledCandidateCollapseHeaderTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;

  p {
    margin-bottom: 0;
    margin-left: 15px;
    font-size: 18px;
    font-weight: 400;
  }

  svg {
    width: 16px;
  }
`;

export const StyledCandidateMapContainer = styled.div`
  width: 100%;
  height: 400px;
  margin-top: 25px;
  border-radius: 4px;
`;

export const StyledCandidateGroupFieldsContainer = styled(Row)`
  margin-top: 25px;
`;

export const StyledCandidateGroupFieldContainer = styled(Col)`
  padding-right: 25px;
  margin-bottom: 25px;
`;

export const StyledCandidateGroupFieldTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.textColorSecondary};
  margin-bottom: 7px;
`;

export const StyledCandidateGroupFieldValue = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  color: ${colors.primaryColor};
`;

import { useQuery } from 'react-query';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';
import { staticForm } from 'api/StaticForm';

export const UseScrapInvoiceFormDetails = () => {
  return useQuery(
    [QUERIES.scrapInvoiceFormDetails],

    async () => (await staticForm.getScrapInvoiceFormDetails()).data,

    {
      ...PERSIST_QUERY_CONFIG,
    }
  );
};

import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { task } from '../Task';

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

export function useRequestGeneralInfo(
  processInstanceId: string | null,
  rootPath?: string,
  getCommentInfo?: boolean
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options: any = {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  };
  if (disableAggregator) {
    options.enabled =
      processInstanceId !== undefined && processInstanceId !== null && getCommentInfo;
  } else {
    options.enabled =
      processInstanceId !== undefined &&
      processInstanceId !== null &&
      getCommentInfo &&
      rootPath !== undefined &&
      rootPath !== null;
  }
  return useQuery(
    [QUERIES.requestGeneralInfo, processInstanceId],
    async () => {
      const { data } = await task.getRequestGeneralInfo(processInstanceId, rootPath);
      return data;
    },
    options
  );
}

import React, { FC, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useTranslate } from 'translations';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { StyledPrivateProfileTabs } from './PrivateProfileTabs.styled';
import { PrivateProfilePersonalTab } from './PrivateProfilePersonalTab';
import { PrivateProfileHierarchyTab } from './PrivateProfileHierarchyTab';
import { PrivateProfileEmploymentDetailsTab } from './PrivateProfileEmploymentDetailsTab';

type Props = {
  hcmid?: string | null;
};

export const PrivateProfileTabs: FC<Props> = ({ hcmid }) => {
  const { t } = useTranslate();
  const { replace } = useHistory();
  const { hash } = useLocation();

  const [currentActiveTab, setCurrentActiveTab] = useState<string>('personal');

  useEffect(() => {
    if (['personal', 'hierarchy', 'details'].includes(hash.substring(1))) {
      setCurrentActiveTab(hash.substring(1));
    } else {
      setCurrentActiveTab('personal');
    }
  }, [hash]);

  return (
    <StyledPrivateProfileTabs
      activeKey={currentActiveTab}
      onTabClick={(key) => {
        replace(`#${key}`);
      }}
      destroyInactiveTabPane={true}
    >
      <Tabs.TabPane tab={t('profile.tab.personal')} key="personal">
        <PrivateProfilePersonalTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('profile.tab.hierarchy')} key="hierarchy">
        <PrivateProfileHierarchyTab hcmid={hcmid} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('employmentDetails.title')} key="details">
        <PrivateProfileEmploymentDetailsTab hcmid={hcmid} />
      </Tabs.TabPane>
    </StyledPrivateProfileTabs>
  );
};

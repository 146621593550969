import React from 'react';
import { Col, Row } from 'antd';
import { EmployeeDirectoryUser } from 'types';
import { MSTeamsUserAvatar } from '../../MSTeams/MSTeamsUserAvatar';
import {
  EmployeeDirectoryUserCardStyledWrapper,
  EmployeeDirectoryUserCardStyledName,
  EmployeeDirectoryUserCardStyledTitle,
} from './EmployeeDirectoryUserCard.styled';

type Props = {
  user: EmployeeDirectoryUser;
  isSelected: boolean;
  onSelect: () => void;
};

export const EmployeeDirectoryUserCard: React.FC<Props> = ({ user, isSelected, onSelect }) => {
  return (
    <EmployeeDirectoryUserCardStyledWrapper
      className={isSelected ? 'is-selected' : ''}
      onClick={onSelect}
    >
      <Row align="middle">
        <Col span={4}>
          <MSTeamsUserAvatar id={user.adOid} name={user.displayName} size={40} />
        </Col>
        <Col span={19} offset={1}>
          <EmployeeDirectoryUserCardStyledName>
            {user.displayName}
          </EmployeeDirectoryUserCardStyledName>
          <EmployeeDirectoryUserCardStyledTitle>
            {user.jobTitle}
          </EmployeeDirectoryUserCardStyledTitle>
        </Col>
      </Row>
    </EmployeeDirectoryUserCardStyledWrapper>
  );
};

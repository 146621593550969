import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { RequestCommonError, RequestStatus } from 'types';
import { UserAvatarState } from './usersTypes';

export const emptyUserAvatarInitialState: UserAvatarState = {
  status: RequestStatus.idle,
  error: null,
  data: {},
};

export type GetUserAvatarStart = PayloadAction<{ photoId: string; params: AxiosRequestConfig }>;
export type GetUserAvatarSuccess = PayloadAction<{
  [key: string]: string;
}>;
export type GetUserAvatarFail = PayloadAction<RequestCommonError>;

const userAvatar = createSlice({
  name: 'userAvatar',
  initialState: emptyUserAvatarInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getUserAvatarStart(state, _: GetUserAvatarStart) {
      state.status = RequestStatus.pending;
    },
    getUserAvatarSuccess(state, action: GetUserAvatarSuccess) {
      state.status = RequestStatus.resolved;
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    getUserAvatarSuccessFail(state, action: GetUserAvatarFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getUserAvatarStart,
  getUserAvatarSuccess,
  getUserAvatarSuccessFail,
} = userAvatar.actions;
export default userAvatar.reducer;

import styled from 'styled-components';
import { Row, Col, Checkbox } from 'antd';
import { TitleH3, typography, space, colors } from 'ui/elements';

export const StyledH3Title = styled(TitleH3)`
  font-weight: ${typography.weight.semiBold};
`;

export const StyledNotificationsList = styled.div`
  margin: ${space.comfy} ${space.zero};
  filter: ${(props: { isDisable?: boolean }) => (props.isDisable ? 'opacity(0.5)' : 'none')};
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colors.white};
    color: ${colors.lightActive};

    &::after {
      border-color: ${colors.lightActive};
    }
  }
`;

export const StyledCheckBox = styled(Checkbox)`
  margin-left: ${space.cozy};
`;

export const StyledRow = styled(Row)`
  margin: ${space.comfy} ${space.zero};
`;

export const StyledFlexCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

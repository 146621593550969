import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetSubordinatesFail,
  GetSubordinatesStart,
  GetSubordinatesSuccess,
  getSubordinatesFail,
  getSubordinatesStart,
  getSubordinatesSuccess,
} from './subordinatesSlice';

export const getSubordinatesEpic: Epic<
  GetSubordinatesStart,
  GetSubordinatesSuccess | GetSubordinatesFail,
  RootState,
  typeof API
> = (action$, _, { subordinates }) =>
  action$.pipe(
    filter(getSubordinatesStart.match),
    mergeMap(({ payload }) =>
      from(subordinates.getSubordinates(payload.params)).pipe(
        map(({ data }) =>
          getSubordinatesSuccess({
            [payload.key]: data,
          })
        ),
        catchError((error) => of(getSubordinatesFail(error?.response?.data)))
      )
    )
  );

import { notification } from 'antd';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useTranslate } from 'translations';
import { LeaseSitesFormData, LeaseSitesRequestData } from 'types';
import { leaseSites } from '../LeaseSites';
import { useErrorDescription, useGetAttachmentPromises } from '../../hooks';

const mapToLeaseSitesRequestData = ({
  attachments,
  ...formData
}: LeaseSitesFormData): LeaseSitesRequestData => {
  return {
    ...formData,
    project: {
      id: formData.project,
    },
    region: {
      id: formData.region,
    },
    district: {
      id: formData.district,
    },
    priority: {
      id: formData.priority,
    },
  };
};

export const useLeaseSitesRequestMutation = () => {
  const { t } = useTranslate();
  const errorDescription = useErrorDescription(t('messages.error.wentWrongReload'));
  const getAttachmentPromises = useGetAttachmentPromises();

  return useMutation(
    async (formValues: LeaseSitesFormData) => {
      const { data } = await leaseSites.postRequest(mapToLeaseSitesRequestData(formValues));

      if (formValues?.attachments?.length) {
        await Promise.all([
          getAttachmentPromises({
            attachment: { files: formValues?.attachments },
            processInstanceId: data.processInstanceId,
            taskId: data.taskId,
            activityId: data.activityId,
            processDefinitionId: data.processDefinitionId,
          }),
        ]);
      }

      return data;
    },
    {
      onError: (error: AxiosError) => {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: errorDescription(error),
        });
      },
    }
  );
};

import { useMutation } from 'react-query';
import { GetLeaveAssignedDelegatesPayload, QUERIES } from 'api';
import { leaves } from '../Leaves';
import { LeavesDelegateType } from '../../../types';

export function useLeaveRequestAssignedDelegates() {
  return useMutation<LeavesDelegateType[], unknown, GetLeaveAssignedDelegatesPayload, unknown>(
    [QUERIES.getLeavesAssignedDelegators],
    async (params) => {
      const { data } = await leaves.getAssignedDelegatorsList(params);
      return data;
    }
  );
}

import { ILeaseSitesCandidatesDraft, SITE_ACQUISITION_TASK_SUBMIT_FORM } from 'types';

export const mapDraftCandidatesToFormValues = (draftInfo: ILeaseSitesCandidatesDraft) => {
  const formValues: Record<string, unknown>[] = [];

  draftInfo.fieldRunnerCandidatesDraftList?.forEach((candidate, index) => {
    const candidateData: Record<string, unknown> = {};
    for (const propertyPath of Object.values(SITE_ACQUISITION_TASK_SUBMIT_FORM)) {
      let propertyValue: unknown | Record<string, unknown> = candidate;
      if (Array.isArray(propertyPath)) {
        for (const path of propertyPath) {
          if (propertyValue) {
            if (Object.prototype.hasOwnProperty.call(propertyValue, path)) {
              propertyValue = (propertyValue as Record<string, unknown>)[path];
            } else {
              if (Object.prototype.hasOwnProperty.call(propertyValue, 'key')) {
                propertyValue = (propertyValue as Record<string, unknown>)['key'];
              } else {
                propertyValue = undefined;
              }
            }
          } else {
            break;
          }
        }
      } else {
        propertyValue = (propertyValue as Record<string, unknown>)[propertyPath];
      }
      if (propertyValue) {
        if (Array.isArray(propertyPath)) {
          propertyPath.reduce(
            (previousValue, currentValue, currentIndex, array) =>
              (previousValue[currentValue] =
                currentIndex === array.length - 1
                  ? (propertyValue as Record<string, unknown>)
                  : (previousValue[currentValue] as Record<string, unknown>) ?? {}),
            candidateData
          );
        } else {
          candidateData[propertyPath] = propertyValue;
        }
      }
    }
    formValues.push(candidateData);
  });
  return formValues;
};

import { Core, APPS } from 'api';
import { ApplistItem } from 'types';

interface GetDashboardApps {
  (): Promise<{ data: ApplistItem[] }>;
}

class DasboardApps extends Core {
  getDasboardApps: GetDashboardApps = async () => this.get(APPS());
}

export const dashboardApps = new DasboardApps();

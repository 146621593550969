import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AllRequestsCard, RequestCommonError, RequestStatus } from 'types';
import { AllRequestsCardState } from './allRequestCardTypes';

export const emptyAllRequestsCardState: AllRequestsCardState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetAllRequestsCardStart = PayloadAction;
export type GetAllRequestsCardSuccess = PayloadAction<AllRequestsCard>;
export type GetAllRequestsCardFail = PayloadAction<RequestCommonError>;

const allRequestsCard = createSlice({
  name: 'allRequestsCard',
  initialState: emptyAllRequestsCardState,
  reducers: {
    getAllRequestsCardStart(state, _: GetAllRequestsCardStart) {
      state.status = RequestStatus.pending;
    },
    getAllRequestsCardSuccess(state, action: GetAllRequestsCardSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getAllRequestsCardFail(state, action: GetAllRequestsCardFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getAllRequestsCardFail,
  getAllRequestsCardStart,
  getAllRequestsCardSuccess,
} = allRequestsCard.actions;
export default allRequestsCard.reducer;

import { TeamsChannelsData } from 'types';
import { Core, TEAMS_CHANNELS } from '../Core';

interface GetTeamsChannels {
  (teamId: string): Promise<{ data: TeamsChannelsData }>;
}

class TeamsChannels extends Core {
  getTeamsChannels: GetTeamsChannels = async (teamId) => this.get(TEAMS_CHANNELS(teamId));
}

export const teamsChannels = new TeamsChannels();

import {
  Core,
  JIRA_CREATE_COMMENT,
  JIRA_FORM_FIELDS,
  JIRA_FORM_SUBMIT,
  JIRA_ASSETS_LIST,
} from 'api';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import {
  JiraCreateCommentInfo,
  JiraDynamicFormFieldType,
  JiraSubmitInfo,
  JiraAssetsList,
} from 'types';

interface GetJiraFormFields {
  (serviceDeskId: number, typeId: number): Promise<{ data: JiraDynamicFormFieldType[] }>;
}

interface PostJiraStartForm {
  (
    serviceDeskId: number,
    typeId: number,
    data: AxiosRequestConfig['data']
  ): AxiosPromise<JiraSubmitInfo>;
}
interface PostJiraComment {
  (taskId: string, data: AxiosRequestConfig['data']): AxiosPromise<JiraCreateCommentInfo>;
}

interface GetJiraAssets {
  (serviceDeskId: number, jiraFieldId: number, assetKey: string): Promise<{
    data: JiraAssetsList;
  }>;
}

class JiraRequestsCenter extends Core {
  getJiraFormFields: GetJiraFormFields = async (serviceDeskId, typeId) =>
    this.get(JIRA_FORM_FIELDS(serviceDeskId, typeId));
  postJiraStartForm: PostJiraStartForm = async (serviceDeskId, typeId, data) =>
    this.post(JIRA_FORM_SUBMIT(serviceDeskId, typeId), data);
  postJiraComment: PostJiraComment = async (taskId, data) =>
    this.post(JIRA_CREATE_COMMENT(taskId), data);
  getJiraAssetsList: GetJiraAssets = async (serviceDeskId, jiraFieldId, assetKey) =>
    this.get(JIRA_ASSETS_LIST(serviceDeskId, jiraFieldId, assetKey));
}

export const jiraRequestsCenter = new JiraRequestsCenter();

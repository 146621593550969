import React, { FC } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const FullWidthSpinWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 24px;
`;

export const FullWidthSpin: FC<{ spinning?: boolean }> = ({ spinning = true }) => (
  <FullWidthSpinWrapper>
    <Spin spinning={spinning} delay={100} />
  </FullWidthSpinWrapper>
);

export const OneDriveFolderShared = {
  list: [
    {
      createdBy: 'Ahmed Alghanmi',
      createdDate: '2021-01-18T06:23:13Z',
      folder: { childCount: 22 },
      id: '01FZPWUMWOTO4P436SPNC25K4EVNSYIPx',
      lastModifiedBy: 'Ahmed Alghanmi',
      lastModifiedDate: '2021-02-02T14:01:58Z',
      name: 'Tawal-app',
      remoteItem: {
        createdBy: 'Ahmed Alghanmi',
        createdDate: '2021-01-18T06:23:13Z',
        folder: { childCount: 22 },
        id: '01FZPWUMWOTO4P436SPNC25K4EVNSYIPQR',
        lastModifiedBy: 'Osama Alghanmi',
        lastModifiedDate: '2021-02-02T14:01:58Z',
        name: 'soe-app',
        parentReference: {
          driveId: 'b!_Ch7e2aEIEqkBnBrC7BXiXMID-LKGWlHh_IneCeYx8a9a5n-nZHUTq7FlP4tFxcJ',
          driveType: 'business',
          id: '01FZPWUMV6Y2GOVW7725BZO354PWSELRRZ',
        },
        size: 0,
        webUrl:
          'https://tawalcomsa-my.sharepoint.com/personal/oghanmi_c_tawal_com_sa/Documents/soe-app',
      },
      size: 0,
      webUrl:
        'https://tawalcomsa-my.sharepoint.com/personal/oghanmi_c_tawal_com_sa/Documents/soe-app',
    },
    {
      createdBy: 'John Smith',
      createdDate: '06.11.2020 13:12',
      file: { MimeType: 'Exul' },
      id: '4',
      lastAccessedDate: '06.11.2020 13:12',
      lastModifiedBy: 'John Smith',
      lastModifiedDate: '06.11.2020 13:12',
      name: 'Dynmic',
    },
  ],
};

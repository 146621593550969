import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslate } from 'translations';
import { FolderOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { TextStyled } from 'ui/elements';
import { ColumnsType } from 'antd/lib/table';
import { Button, notification, Space, Typography } from 'antd';
import { getAvatarSymbols, measureSizeOfFile } from 'utils';
import { withRouter } from 'react-router-dom';
import { RequestStatus } from 'types';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  useDispatch,
  useSelector,
  oneDriveSharedLibrariesData,
  RootState,
  getSharedLibrariesOpenedFoldersData,
  getOneDriveSharedLibraryFoldersListStart,
} from 'store';
import moment from 'moment';
import { core, createNewFolder, uploadNewFileInGroup } from 'api';
import { UserPopover } from 'ui';
import { RestrictedUpload } from 'ui/components/RestrictedUpload';
import { OneDriveCreateFolderPopup } from '../OneDriveCreateFolderPopup';
import { OneDriveConfirm } from '../OneDriveConfirm';
import { OneDriveBreadCrumbs, BreadcrumbItem } from '../OneDriveBreadCrumbs';
import { StyledModifiedby } from '../OneDriveMyFiles/OneDriveMyFiles.styled';
import {
  FileIconStyled,
  OneDiveSubHeader,
  OneDriveButton,
  OneDriveDragger,
  OneDriveWrapper,
  FolderIconStyled,
  StyledTeamAvatar,
  StyledButton,
  StyledTable,
  StyledFiles,
} from './OneDriveSharedLibrary.styled';

const { Text } = Typography;

export const OneDriveSharedLibraryComponent: FC<
  RouteComponentProps<{ groupId: string; libraryId: string }>
> = ({ match: { params } }) => {
  const { t } = useTranslate();
  const { groupId, libraryId } = params;
  const dispatch = useDispatch();
  const openedFoldersData = useSelector(getSharedLibrariesOpenedFoldersData);

  const libraryData = useMemo(() => openedFoldersData[groupId]?.[libraryId], [
    groupId,
    libraryId,
    openedFoldersData,
  ]);
  const [breadCrumbState, setBreadCrumbState] = useState<BreadcrumbItem[]>([]);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [isFolderCreateLoading, setIsFolderCreateLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState(libraryData?.data);
  const librariesData = useSelector((state: RootState) =>
    oneDriveSharedLibrariesData(state, { groupId })
  );

  const foldersByLibrary = openedFoldersData[groupId];

  const currentFolderId = useMemo(() => breadCrumbState[breadCrumbState.length - 1]?.id, [
    breadCrumbState,
  ]);

  const showError = useCallback(() => {
    notification.error({
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    });
  }, [t]);

  const currentSelectedLibrary = librariesData?.data?.find((library) => library.id === libraryId);

  useEffect(() => {
    if (currentFolderId) {
      if (breadCrumbState.length > 1) {
        if (foldersByLibrary?.[currentFolderId]) {
          setTableData(foldersByLibrary?.[currentFolderId]?.data);
        } else {
          dispatch(
            getOneDriveSharedLibraryFoldersListStart({ groupId, folderId: currentFolderId })
          );
        }
      } else {
        if (libraryData?.data) {
          setTableData(libraryData.data);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadCrumbState]);

  useEffect(() => {
    if (currentFolderId && foldersByLibrary[currentFolderId]?.data) {
      setTableData(foldersByLibrary[currentFolderId].data);
    }

    if (currentFolderId && foldersByLibrary?.[currentFolderId]?.status === RequestStatus.rejected) {
      showError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedFoldersData]);

  useEffect(() => {
    if (libraryData?.status === RequestStatus.resolved && !tableData) {
      setTableData(libraryData?.data);
    }
    return () => {
      setTableData(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraryData?.status]);

  useEffect(() => {
    if (librariesData?.status === RequestStatus.resolved && libraryId) {
      const currentSelectedLibrary = librariesData.data.find((library) => library.id === libraryId);

      if (currentSelectedLibrary) {
        setBreadCrumbState([{ id: currentSelectedLibrary.id, title: currentSelectedLibrary.name }]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [librariesData?.status, libraryId]);

  useEffect(() => {
    dispatch(getOneDriveSharedLibraryFoldersListStart({ groupId, folderId: libraryId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, libraryId]);

  const onClickFolder = useCallback(
    (folderId: string, folderName: string) => {
      setBreadCrumbState([...breadCrumbState, { id: folderId, title: folderName }]);
      setTableData([]);
    },
    [breadCrumbState]
  );

  const onUploadFile = useCallback(
    async ({ file }: { file: UploadFile }) => {
      if (currentFolderId) {
        try {
          setIsFileUploading(true);
          const { data } = await uploadNewFileInGroup.uploadNewFileInOneDriveLibrary(
            groupId,
            currentFolderId,
            {
              name: file.name,
              fileSize: file.size,
            }
          );

          await core.put(data.uploadUrl, file, {
            headers: {
              'Content-Type': file.type,
              'Content-Range': `bytes 0-${file.size - 1}/${file.size}`,
            },
          });

          dispatch(
            getOneDriveSharedLibraryFoldersListStart({ groupId, folderId: currentFolderId })
          );
        } catch {
          showError();
        }

        setIsFileUploading(false);
      }
    },
    [currentFolderId, dispatch, groupId, showError]
  );

  const onClickBreadCrumb = useCallback(
    (id) => {
      const currentFolderBreadcrumbIndex = breadCrumbState.findIndex((crumb) => crumb.id === id);

      if (~currentFolderBreadcrumbIndex) {
        setBreadCrumbState(breadCrumbState.slice(0, currentFolderBreadcrumbIndex + 1));
      }
    },
    [breadCrumbState]
  );

  const onConfirm = useCallback((link: string) => {
    window.open(link);
  }, []);

  const data = tableData?.map((item, index) =>
    item.file
      ? {
          key: index,
          file: <FileIconStyled />,
          name: (
            <OneDriveButton type="link">
              <OneDriveConfirm onConfirm={() => onConfirm(item.webUrl)}>
                <TextStyled underline>{item?.name}</TextStyled>
              </OneDriveConfirm>
            </OneDriveButton>
          ),
          modified: (
            <TextStyled>
              {moment(item?.lastModifiedDate || item.createdDate).format('DD.MM.YYYY HH.mm')}
            </TextStyled>
          ),
          modifiedBy: <TextStyled>{item?.lastModifiedBy || item.createdBy}</TextStyled>,
          size: <TextStyled>{measureSizeOfFile(item.size)}</TextStyled>,
          download: <Button type="link" icon={<DownloadOutlined />} href={item.downloadUrl} />,
          adOid: item?.lastModifiedByAdOid,
        }
      : {
          key: index,
          file: <FolderIconStyled />,
          name: (
            <StyledButton type="link" onClick={() => onClickFolder(item.id, item.name)}>
              {item?.name}
            </StyledButton>
          ),
          modified: (
            <TextStyled>
              {moment(item?.lastModifiedDate || item.createdDate).format('DD.MM.YYYY HH.mm')}
            </TextStyled>
          ),
          modifiedBy: <TextStyled>{item?.lastModifiedBy || item.createdBy}</TextStyled>,
          size: <TextStyled>{measureSizeOfFile(item.size)}</TextStyled>,
          adOid: item?.lastModifiedByAdOid,
        }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnsType<any> = [
    {
      dataIndex: 'file',
      key: 'file',
      width: '5%',
    },
    {
      title: t('oneDrive.SharedLibrary.columns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: t('oneDrive.SharedLibrary.columns.modified'),
      dataIndex: 'modified',
      key: 'modified',
      width: '20%',
    },
    {
      title: t('oneDrive.SharedLibrary.columns.modifiedBy'),
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      render: (value, row) =>
        value && (
          <UserPopover currentID={row?.adOid}>
            <StyledModifiedby> {value} </StyledModifiedby>
          </UserPopover>
        ),
      width: '25%',
    },
    {
      title: t('oneDrive.SharedLibrary.columns.size'),
      dataIndex: 'size',
      key: 'size',
      width: '10%',
    },
    {
      dataIndex: 'download',
      key: 'download',
      width: '10%',
    },
  ];

  const onCreateFolder = useCallback(
    async (folderName, cb) => {
      setIsFolderCreateLoading(true);

      if (currentFolderId) {
        try {
          const { data } = await createNewFolder.createNewFolderInOneDriveSharedLibrary(
            groupId,
            currentFolderId,
            folderName
          );
          setBreadCrumbState([...breadCrumbState, { title: data.name, id: data.id }]);
          dispatch(
            getOneDriveSharedLibraryFoldersListStart({ groupId, folderId: currentFolderId })
          );
          cb();
        } catch {
          showError();
        } finally {
          setIsFolderCreateLoading(false);
        }
      }
    },
    [breadCrumbState, currentFolderId, dispatch, groupId, showError]
  );

  const isLoading =
    libraryData?.status === RequestStatus.pending ||
    !!(currentFolderId && foldersByLibrary?.[currentFolderId]?.status === RequestStatus.pending) ||
    isFileUploading;

  return (
    <div>
      <OneDiveSubHeader bordered={false}>
        <Space size="large">
          {currentSelectedLibrary?.name && (
            <>
              <StyledTeamAvatar id={libraryId}>
                {getAvatarSymbols(currentSelectedLibrary.name)}
              </StyledTeamAvatar>
              <Text strong>{currentSelectedLibrary.name}</Text>
            </>
          )}

          <OneDriveCreateFolderPopup
            isLoading={isFolderCreateLoading}
            disabled={isLoading}
            onSubmit={onCreateFolder}
          >
            <OneDriveButton type="link">
              <FolderOutlined />
              {t('oneDrive.SharedLibrary.newFolder')}
            </OneDriveButton>
          </OneDriveCreateFolderPopup>
          <RestrictedUpload customRequest={onUploadFile} multiple={false} showUploadList={false}>
            <OneDriveButton type="link">
              <UploadOutlined />
              {t('oneDrive.SharedLibrary.uploadFile')}
            </OneDriveButton>
          </RestrictedUpload>
        </Space>
      </OneDiveSubHeader>
      <OneDriveWrapper>
        <OneDriveBreadCrumbs list={breadCrumbState} onClick={onClickBreadCrumb} />

        <StyledFiles>
          <StyledTable
            loading={isLoading}
            columns={columns}
            dataSource={data}
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </StyledFiles>

        <OneDriveDragger showUploadList={false} customRequest={onUploadFile} disabled={isLoading}>
          <TextStyled size={16} type="secondary">
            {t('oneDrive.SharedLibrary.dragAndDropToUpload')}
          </TextStyled>
        </OneDriveDragger>
      </OneDriveWrapper>
    </div>
  );
};

export const OneDriveSharedLibrary = withRouter(memo(OneDriveSharedLibraryComponent));

import React, { FC, useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';

import { RequestStatus } from 'types';
import {
  getTeamsChannelMessagesStart,
  getTeamsChannelMessagesData,
  useDispatch,
  useSelector,
  sendTeamsChannelMessageStart,
  updateTeamsChannelMessagesStart,
} from 'store';
import { FullWidthAndHeightSpin, FullWidthSpin } from 'ui';
import { useTranslate } from 'translations';
import { useMSTeamsContext } from '../../MSTeamsProvider';
import {
  ChatContainer,
  ChatHolder,
  ChatScrollbar,
  ChatWrapper,
  ConversationButtonWrapper,
} from './MSTeamsPosts.styled';
import { ChatItem } from './ChatItem';
import { ChatSendMessage } from './ChatSendMessage';

export const MSTeamsPosts: FC = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { currentTeamId, currentChannelId } = useMSTeamsContext();
  const { data, getTeamsChannelMessagesStatus, sendTeamsChannelMessageStatus } = useSelector(
    getTeamsChannelMessagesData
  );
  const { channelMessages } = data;
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollBarRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState(false);
  const [scrollToFirstMessage, setScrollToFirstMessage] = useState(false);
  const isLoading = getTeamsChannelMessagesStatus === RequestStatus.pending;

  const toggleMessageInputVisibility = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (channelMessages?.length) {
      setScrollToFirstMessage(true);
    }

    return () => {
      setScrollToFirstMessage(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelMessages]);

  useEffect(() => {
    if (scrollToFirstMessage) {
      scrollToBottom();
    }
  }, [scrollToFirstMessage, scrollToBottom]);

  useEffect(() => {
    dispatch(getTeamsChannelMessagesStart({ teamId: currentTeamId, channelId: currentChannelId }));
  }, [dispatch, currentTeamId, currentChannelId]);

  const onSubmit = (values: {
    message: {
      body: { content: string; contentType?: string };
      mentions?: {
        mentioned: {
          user: {
            displayName: string;
            id: string;
          };
        };
      }[];
    };
  }) => {
    dispatch(
      sendTeamsChannelMessageStart({
        teamId: currentTeamId,
        channelId: currentChannelId,
        values: {
          ...values.message,
        },
      })
    );
  };

  const loadMore = useCallback(() => {
    if (data.skipToken && getTeamsChannelMessagesStatus !== RequestStatus.pending) {
      dispatch(
        updateTeamsChannelMessagesStart({ teamId: currentTeamId, channelId: currentChannelId })
      );
    }
  }, [currentTeamId, currentChannelId, data.skipToken, dispatch, getTeamsChannelMessagesStatus]);

  const getScrollParent = useCallback(() => scrollBarRef.current, []);

  const chatWithInfinityScroll = useMemo(() => {
    return (
      !!channelMessages?.length && (
        <ChatContainer>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={!!data.skipToken}
            useWindow={false}
            getScrollParent={getScrollParent}
            isReverse
            loader={<FullWidthSpin key={0} />}
            initialLoad={false}
          >
            {channelMessages.map(({ date, messages }, i) => {
              return <ChatItem key={i} title={date} messages={messages} />;
            })}
          </InfiniteScroll>
          <div ref={messagesEndRef} />
        </ChatContainer>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelMessages]);

  return (
    <ChatWrapper>
      <ChatHolder>
        <ChatScrollbar ref={scrollBarRef}>
          {isLoading ? (
            channelMessages?.length ? (
              chatWithInfinityScroll
            ) : (
              <FullWidthAndHeightSpin />
            )
          ) : (
            chatWithInfinityScroll
          )}
        </ChatScrollbar>
      </ChatHolder>
      {!isLoading && isActive && (
        <ChatSendMessage
          channelId={currentChannelId}
          teamId={currentTeamId}
          isActive={isActive}
          setIsActive={setIsActive}
          onSubmit={onSubmit}
          submitStatus={sendTeamsChannelMessageStatus}
        />
      )}
      {!isLoading && !isActive && (
        <ConversationButtonWrapper>
          <Button type="primary" onClick={toggleMessageInputVisibility}>
            <PlusOutlined /> {t('msteams.button.newConversation')}
          </Button>
        </ConversationButtonWrapper>
      )}
    </ChatWrapper>
  );
};

import { TType } from 'translations';
import { ExpenseStatus } from 'types';

export const magicDownload = (fileContentType: string, fileContent: string, fileName: string) => {
  const file = `data: ${fileContentType};base64,${fileContent}`;
  // create hidden link
  const element = document.createElement('a');
  document.body.appendChild(element);
  element.setAttribute('href', file);
  element.setAttribute('download', fileName);
  element.style.display = '';
  element.click();
  document.body.removeChild(element);
};

export const getExpenseStatusColor = (status: ExpenseStatus) =>
  ({
    [ExpenseStatus.rejected]: 'red',
    [ExpenseStatus.inProgress]: 'blue',
    [ExpenseStatus.completed]: 'green',
    [ExpenseStatus.paid]: 'green',
  }[status]);

export const getExpenseStatusText = (t: TType) => ({
  [ExpenseStatus.rejected]: t('profile.expenses.drawer.rejected'),
  [ExpenseStatus.inProgress]: t('profile.expenses.drawer.inProgress'),
  [ExpenseStatus.completed]: t('profile.expenses.drawer.completed'),
  [ExpenseStatus.paid]: t('profile.expenses.drawer.paid'),
});

import { FormFields, TransformedFormData, FormField, TransformedFormField } from 'types';

const groupFormDataByGroupName = (acc: TransformedFormData, item: FormField) => {
  if (item?.properties?.groupName) {
    const group = acc.find((accItem) => accItem.properties.groupName === item.properties.groupName);

    if (group && group.fields) {
      group.fields = [...group.fields, item];
      return acc;
    }

    return [
      ...acc,
      {
        ...item,
        label: item.properties.groupName,
        component: 'group',
        fields: [item],
      },
    ] as TransformedFormData;
  }

  return [...acc, item];
};

const groupFormDataActionButtons = (formData: TransformedFormData): TransformedFormData => {
  const actionButtonItems = formData?.filter(({ typeName }) => typeName === 'actionButton');
  const otherItems = formData?.filter(({ typeName }) => typeName !== 'actionButton');
  return [
    ...otherItems,
    ...(actionButtonItems?.length
      ? [
          {
            ...actionButtonItems[0],
            component: 'actionButtons',
            fields: actionButtonItems,
          } as TransformedFormField,
        ]
      : []),
  ];
};

export const transformGeneratedButtonFormData = (formData: FormFields) =>
  formData.reduce(groupFormDataByGroupName, [] as TransformedFormData);

export const transformGeneratedFormData = (
  formData: FormFields,
  multipleAttachmentLabel: string
) => {
  const formattedFormData = formData
    .map((formDataItem) => {
      /**
       * Add default "Attachments" group name in file
       * type field in case of multiple attachments
       */
      const isMultipleAttachment =
        formData?.filter((forDataItem) => forDataItem?.typeName === 'file')?.length > 1;

      return {
        ...formDataItem,
        properties: {
          ...formDataItem.properties,
          groupName:
            formDataItem?.typeName === 'file'
              ? isMultipleAttachment
                ? multipleAttachmentLabel
                : ''
              : formDataItem.properties.groupName,
        },
      };
    })
    .filter((id) => id.id !== 'requesterId')
    .reduce(groupFormDataByGroupName, [] as TransformedFormData);

  return groupFormDataActionButtons(formattedFormData);
};

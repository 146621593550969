import React, { useState } from 'react';
import { useTranslate } from 'translations';
import { AnnouncementImageViewer } from '../AnnouncemetImageViewer';
import {
  StyledImageGridCellWrapper,
  StyledImageGridContainer,
  StyledImageGridImage,
  StyledImageGridMoreThenFiveOpacityLayer,
  StyledImageGridMoreThenFiveText,
  StyledImageGridRightGridSubWrapper,
  StyledImageGridRightGridWrapper,
  StyledImageGridTitle,
  StyledImageGridWrapper,
} from './AnnouncementImageGrid.styled';

const IMAGE_URL = 'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg';

export const AnnouncementImageGrid: React.FC = () => {
  const [isImageViewerVisible, setShowImageViewer] = useState(false);

  const { t } = useTranslate();
  const gridOf = 4;
  const widthOfFirstImg = gridOf > 1 ? 55 : 100;
  const isRightSectionVisible = gridOf > 1;
  const isThirdSectionVisible = gridOf >= 3;
  const isFourthSectionVisible = gridOf >= 4;

  const handleOnImageViewerClose = () => {
    setShowImageViewer(false);
  };

  const handleOnShowImageViewer = () => {
    setShowImageViewer(true);
  };

  return (
    <StyledImageGridContainer>
      <StyledImageGridTitle>{t('announcement.imageGrid.title')}</StyledImageGridTitle>
      <StyledImageGridWrapper>
        <StyledImageGridCellWrapper>
          <StyledImageGridImage src={IMAGE_URL} width={widthOfFirstImg} />
          {isRightSectionVisible && (
            <StyledImageGridRightGridWrapper>
              <StyledImageGridRightGridSubWrapper>
                <StyledImageGridImage src={IMAGE_URL} />
              </StyledImageGridRightGridSubWrapper>
              {isThirdSectionVisible && (
                <StyledImageGridRightGridSubWrapper>
                  <StyledImageGridRightGridSubWrapper>
                    <StyledImageGridImage src={IMAGE_URL} />
                  </StyledImageGridRightGridSubWrapper>
                  {isFourthSectionVisible && (
                    <StyledImageGridRightGridSubWrapper onClick={handleOnShowImageViewer}>
                      <StyledImageGridImage src={IMAGE_URL} />
                      <StyledImageGridMoreThenFiveOpacityLayer />
                      <StyledImageGridMoreThenFiveText>+5</StyledImageGridMoreThenFiveText>
                    </StyledImageGridRightGridSubWrapper>
                  )}
                </StyledImageGridRightGridSubWrapper>
              )}
            </StyledImageGridRightGridWrapper>
          )}
        </StyledImageGridCellWrapper>
      </StyledImageGridWrapper>
      <AnnouncementImageViewer
        isModalVisible={isImageViewerVisible}
        onClose={handleOnImageViewerClose}
      />
    </StyledImageGridContainer>
  );
};

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const balance = (state: RootState): RootState['balance'] => state.balance;

export const balanceData = createSelector(balance, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { notification } from 'antd';
import { leaves as leavesApi } from 'api';
import { LeavesTypesData, ProfileData } from 'types';
import { selectFilterOption } from 'utils/getFilterOptions';
import { useTranslate } from 'translations';
import { BalanceWidgetWrapper, StyledSelect } from './BalanceWidget.styled';
import { DaysSection } from './DaysSection';

interface BalanceWidgetProps {
  profileData: ProfileData;
  leavesTypes: LeavesTypesData[];
  isLeavesTypesLoading: boolean;
}

export const BalanceWidget: FC<BalanceWidgetProps> = ({
  profileData,
  leavesTypes,
  isLeavesTypesLoading,
}) => {
  const { t } = useTranslate();
  const [balance, setBalance] = useState<{
    remaining_balance?: string;
    completed_balance?: string;
    planned_balance?: string;
  }>({});
  const [currentLeaveId, setCurrentLeaveId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const hcmPersonId = profileData.hcmId as string;

  const leaveTypeOptions = leavesTypes?.map(({ absenceName, id }) => ({
    label: absenceName,
    value: id,
  }));

  const defaultOption = leaveTypeOptions && leaveTypeOptions[0]?.value;

  const getBalance = useCallback(
    (currentLeaveId) =>
      Promise.all([
        leavesApi.getLeaveRemaining(hcmPersonId, currentLeaveId),
        leavesApi.getLeaveCompleted(hcmPersonId, currentLeaveId),
        leavesApi.getLeavePlanned(hcmPersonId, currentLeaveId),
      ]),
    [hcmPersonId]
  );

  useEffect(() => {
    defaultOption && setCurrentLeaveId(defaultOption);
  }, [defaultOption]);

  useEffect(() => {
    (async () => {
      if (currentLeaveId) {
        try {
          setIsLoading(true);
          const balanceResponse = await getBalance(currentLeaveId);
          setBalance({
            remaining_balance: balanceResponse[0].data.remaining_balance,
            completed_balance: balanceResponse[1].data.completed_balance,
            planned_balance: balanceResponse[2].data.planned_balance,
          });
        } catch (e) {
          notification.error({
            message: t('messages.error.wentWrong'),
            description: t('messages.error.tryAgainLater'),
          });
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [currentLeaveId, getBalance, t]);

  const DaysSectionData = useMemo(
    () => [
      {
        balanceTitle: t('leaves.widget.remainingBalance'),
        balance: balance.remaining_balance,
      },
      {
        balanceTitle: t('leaves.widget.planned'),
        balance: balance.planned_balance,
      },
      {
        balanceTitle: t('leaves.widget.completed'),
        balance: balance.completed_balance,
      },
    ],
    [balance, t]
  );

  return (
    <BalanceWidgetWrapper loading={isLeavesTypesLoading}>
      {defaultOption && (
        <StyledSelect
          defaultValue={defaultOption}
          onChange={(value) => setCurrentLeaveId(value as string)}
          bordered={false}
          size="large"
          options={leaveTypeOptions}
          notFoundContent={t('dropdown.noMatching')}
          showSearch
          filterOption={selectFilterOption}
        />
      )}
      {DaysSectionData.map(({ balanceTitle, balance }) => (
        <DaysSection
          balanceTitle={balanceTitle}
          balance={balance}
          isLoading={isLoading}
          key={balanceTitle}
        />
      ))}
    </BalanceWidgetWrapper>
  );
};

import React, { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import parse from 'html-react-parser';
import moment from 'moment';

import { useTranslate } from 'translations';
import { AsyncImage } from '../../AsyncImage';
import {
  ChatCard,
  TextSecondary,
  TextTimeStamp,
  AttachmentCard,
  AttachmentsWrapper,
  StyledFileIcon,
  StyledItalicText,
} from './ChatMessage.styled';

interface Props {
  title?: string;
  message: string;
  messageType: string;
  createdDateTime?: string;
  secondary?: boolean;
  attachments?:
    | {
        id: string;
        name?: string | null;
        content?: string | null;
        contentType?: string | null;
        contentUrl?: string | null;
        thumbnailUrl?: string | null;
      }[]
    | null;
}

const { Text } = Typography;

export const ChatMessage: FC<Props> = ({
  title,
  message,
  messageType,
  createdDateTime,
  secondary = false,
  attachments,
}) => {
  const { t } = useTranslate();

  const changedMessage = message?.replace(/(<([^>]+)>)/gi, '');

  return (
    <Row justify={secondary ? 'end' : 'start'} gutter={[0, 4]}>
      <Col>
        <ChatCard secondary={secondary ? 'yes' : 'no'}>
          <Row gutter={[16, 6]} align="middle">
            {!!title && (
              <Col>
                <Text strong>{title}</Text>
              </Col>
            )}
            {!!createdDateTime && (
              <Col>
                <TextTimeStamp type="secondary">
                  {moment(createdDateTime).format('DD/MM/YYYY HH:mm')}
                </TextTimeStamp>
              </Col>
            )}
          </Row>
          <Row gutter={[0, 8]}>
            <Col>
              <TextSecondary secondary={secondary ? 'no' : 'yes'}>
                {!!message &&
                  messageType === 'HTML' &&
                  parse(changedMessage, {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    replace: (domNode: any) => {
                      if (
                        domNode?.tagName === 'img' &&
                        domNode?.attribs.src.includes('https://graph.microsoft.com')
                      ) {
                        return <AsyncImage src={domNode?.attribs.src} />;
                      }

                      return domNode;
                    },
                  })}
                {!!message && messageType !== 'HTML' && changedMessage}
                {!message && <StyledItalicText>{t('msteams.deletedMessage')}</StyledItalicText>}
              </TextSecondary>
            </Col>
          </Row>
          <AttachmentsWrapper>
            <Row gutter={[8, 8]} wrap>
              {!!attachments?.length &&
                attachments.map(({ id, name, contentUrl }) => (
                  <Col key={id}>
                    <a href={contentUrl || undefined} download target="_blank" rel="noreferrer">
                      <AttachmentCard>
                        <StyledFileIcon /> {name}
                      </AttachmentCard>
                    </a>
                  </Col>
                ))}
            </Row>
          </AttachmentsWrapper>
        </ChatCard>
      </Col>
    </Row>
  );
};

import React, { FC, useCallback, useEffect, useState } from 'react';
import { Col, Row, Tabs, Space, Typography } from 'antd';
import { VideoCameraOutlined, InfoCircleOutlined, InfoCircleFilled } from '@ant-design/icons';

import { useTranslate } from 'translations';
import { getAvatarSymbols } from 'utils';
import { TeamsChannelFile } from 'ui';
import { TabPaneContent, UserContainer } from '../MSTeams.styled';
import { StyledTeamAvatar } from '../MSTeamsTeamsTabs/MSTeamsTeamsSidebar/MSTeamsTeamsSidebar.styled';
import { MSTeamsConfirm } from '../MSTeamsConfirm';
import { useMSTeamsContext } from '../MSTeamsProvider';
import { MSTeamsTeamInfoDrawer } from '../MSTeamsTeamInfoDrawer';
import { StyledSmallButton } from './MSTeamsTeamsTabs.styled';
import { msTeamsTeamsTabsConnector, ConnectorProps } from './MSTeamsTeamsTabs.connector';
import { MSTeamsPosts } from './MSTeamsPosts';

const { Text } = Typography;
const { TabPane } = Tabs;

export interface MSTeamsTeamsTabsProps {
  channelId: string;
}

export const MSTeamsTeamsTabsComponent: FC<MSTeamsTeamsTabsProps & ConnectorProps> = ({
  currentChannelData,
}) => {
  const { t } = useTranslate();
  const { currentTeamId, currentChannelId } = useMSTeamsContext();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const onConfirm = useCallback(() => {
    window.open(currentChannelData?.webUrl, '_blank');
  }, [currentChannelData?.webUrl]);

  const onDrawerClose = useCallback(() => {
    setIsDrawerVisible(false);
  }, []);

  const onChannelInfoButtonClick = useCallback(() => {
    setIsDrawerVisible(!isDrawerVisible);
  }, [isDrawerVisible]);

  useEffect(() => {
    setIsDrawerVisible(false);
  }, [currentChannelId]);

  return (
    <>
      <Tabs
        tabBarExtraContent={{
          left: (
            <UserContainer>
              <Space direction="horizontal" size={16}>
                <StyledTeamAvatar id={currentTeamId}>
                  {currentChannelData?.displayName &&
                    getAvatarSymbols(currentChannelData.displayName)}
                </StyledTeamAvatar>
                <Text strong>{currentChannelData?.displayName}</Text>
              </Space>
            </UserContainer>
          ),
          right: (
            <Row gutter={[10, 0]} align="middle">
              <Col>
                <MSTeamsConfirm onConfirm={onConfirm}>
                  <StyledSmallButton>
                    <VideoCameraOutlined />
                    {t('msteams.button.meet')}
                  </StyledSmallButton>
                </MSTeamsConfirm>
              </Col>
              <Col>
                <StyledSmallButton onClick={onChannelInfoButtonClick} type="text">
                  {isDrawerVisible ? <InfoCircleFilled /> : <InfoCircleOutlined />}
                </StyledSmallButton>
              </Col>
            </Row>
          ),
        }}
      >
        <TabPane tab={t('msteams.tab.posts')} key="1">
          <TabPaneContent>
            <MSTeamsPosts />
            {isDrawerVisible && (
              <MSTeamsTeamInfoDrawer
                teamId={currentTeamId}
                channelId={currentChannelId}
                description={currentChannelData?.description}
                drawerProps={{
                  visible: isDrawerVisible,
                  onClose: onDrawerClose,
                }}
              />
            )}
          </TabPaneContent>
        </TabPane>
        <TabPane tab={t('msteams.tab.files')} key="2">
          <TabPaneContent>
            <TeamsChannelFile
              channelId={currentChannelId}
              teamId={currentTeamId}
              teamName={currentChannelData?.displayName}
            />
          </TabPaneContent>
        </TabPane>
      </Tabs>
    </>
  );
};

export const MSTeamsTeamsTabs = msTeamsTeamsTabsConnector(MSTeamsTeamsTabsComponent);

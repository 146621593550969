import React, { FC, useEffect, useState } from 'react';
import { profileData as profileDataSelector, useSelector } from 'store';
import { useGetEthicsCodeContent, useSubmitEthicsCodeConfirmation } from 'api';
import { RequestStatus } from 'types';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Empty, Form, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FullWidthSpin } from 'ui/elements';
import {
  StyledEthicsCodeDialog,
  StyledEthicsCodeDialogContentContainer,
  StyledEthicsCodeDialogTitle,
  StyledEthicsCodeDialogDescriptionContainer,
  StyledEthicsCodeDialogFormContainer,
  StyledEthicsCodeDialogFooter,
  StyledEthicsCodeDialogDescription,
  StyledEthicsCodeDialogFormCheckbox,
  StyledEthicsCodeDialogFormSubmit,
  StyledEthicsCodeDialogFooterNote,
  StyledEthicsCodeDialogFooterChangeLang,
  StyledEthicsCodeDialogAttachmentContainer,
  StyledEthicsCodeDialogAttachment,
} from './EthicsCodeDialog.styled';

export const EthicsCodeDialog: FC = () => {
  const [form] = useForm();
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [selectedLang, setSelectedLang] = useState<'AR' | 'EN'>('EN');
  const { data: profileData, status } = useSelector(profileDataSelector);

  const {
    data: ethicsCodeContent,
    isError: isEthicsCodeContentError,
    isLoading: isEthicsCodeContentLoading,
  } = useGetEthicsCodeContent(selectedLang, isDialogVisible);
  const {
    mutateAsync: submitEthicsCodeConfirmation,
    isLoading: isSubmitEthicsCodeConfirmationLoading,
  } = useSubmitEthicsCodeConfirmation();

  const onSubmit = ({ confirm }: { confirm: boolean }) => {
    submitEthicsCodeConfirmation(confirm)
      .then(() => {
        setIsDialogVisible(false);
      })
      .catch(() => {
        notification.error({
          message: 'Something went wrong',
          description: 'please try again later',
        });
      });
  };

  useEffect(() => {
    if (status === RequestStatus.resolved) {
      if (profileData?.isCodeOfEthicsRequired) {
        setIsDialogVisible(true);
      } else {
        setIsDialogVisible(false);
      }
    }
  }, [profileData, status]);

  return (
    <StyledEthicsCodeDialog
      visible={isDialogVisible}
      getContainer={false}
      footer={null}
      closable={false}
      style={{ textAlign: selectedLang === 'AR' ? 'right' : 'left' }}
      width="70vw"
      centered
    >
      <StyledEthicsCodeDialogContentContainer dir={selectedLang === 'AR' ? 'rtl' : 'ltr'}>
        {ethicsCodeContent && !isEthicsCodeContentLoading && !isEthicsCodeContentError && (
          <>
            <StyledEthicsCodeDialogTitle>{ethicsCodeContent.subject}</StyledEthicsCodeDialogTitle>
            <StyledEthicsCodeDialogDescriptionContainer>
              <StyledEthicsCodeDialogDescription>
                {ethicsCodeContent.firstDescription}
              </StyledEthicsCodeDialogDescription>
              <StyledEthicsCodeDialogDescription>
                {ethicsCodeContent.secondDescription}
              </StyledEthicsCodeDialogDescription>
            </StyledEthicsCodeDialogDescriptionContainer>
            <StyledEthicsCodeDialogAttachmentContainer>
              <StyledEthicsCodeDialogAttachment
                name={`${ethicsCodeContent.attachmentName}.${ethicsCodeContent.attachmentType}`}
                url={ethicsCodeContent.attachmentUrl}
                isComment={true}
              />
            </StyledEthicsCodeDialogAttachmentContainer>
            <StyledEthicsCodeDialogFormContainer>
              <Form initialValues={{ confirm: false }} onFinish={onSubmit} form={form}>
                <Form.Item
                  name="confirm"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      transform: (value) => value || undefined,
                      type: 'boolean',
                      message: '',
                    },
                  ]}
                >
                  <StyledEthicsCodeDialogFormCheckbox>
                    {ethicsCodeContent.ethicsConfirmation}
                  </StyledEthicsCodeDialogFormCheckbox>
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <StyledEthicsCodeDialogFormSubmit
                      htmlType="submit"
                      disabled={
                        isSubmitEthicsCodeConfirmationLoading ||
                        !form.isFieldTouched('confirm') ||
                        form.getFieldsError().some(({ errors }) => errors.length)
                      }
                    >
                      {selectedLang === 'EN' ? 'Submit' : 'ارسال'}{' '}
                      {selectedLang === 'EN' ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
                    </StyledEthicsCodeDialogFormSubmit>
                  )}
                </Form.Item>
              </Form>
            </StyledEthicsCodeDialogFormContainer>
          </>
        )}
        {isEthicsCodeContentError && !isEthicsCodeContentLoading && (
          <Empty
            style={{ width: '100%' }}
            description={
              selectedLang === 'AR'
                ? 'حدث خطأ، من فضلك أعد تحميل الصفحة.'
                : 'Something went wrong, please refresh the page.'
            }
          />
        )}
        {isEthicsCodeContentLoading ? (
          <FullWidthSpin />
        ) : (
          <StyledEthicsCodeDialogFooter>
            <StyledEthicsCodeDialogFooterNote>
              {selectedLang === 'EN'
                ? 'Note : The completion of this form is Mandatory'
                : '! ملاحظة: يجب تقديم هذا النموذج بشكل الزامي'}
            </StyledEthicsCodeDialogFooterNote>
            <StyledEthicsCodeDialogFooterChangeLang
              type="text"
              onClick={() => {
                setSelectedLang((prevState) => (prevState === 'EN' ? 'AR' : 'EN'));
              }}
            >
              {selectedLang === 'EN' ? 'Arabic' : 'English'}
            </StyledEthicsCodeDialogFooterChangeLang>
          </StyledEthicsCodeDialogFooter>
        )}
      </StyledEthicsCodeDialogContentContainer>
    </StyledEthicsCodeDialog>
  );
};

import React, { FC, useState } from 'react';

import { MSTeamsContext } from './MSTeamsContext';

export const MSTeamsProvider: FC = ({ children }) => {
  const [currentChannelId, setCurrentChannelId] = useState<string>('');
  const [currentTeamId, setCurrentTeamId] = useState<string>('');
  const [currentChatId, setCurrentChatId] = useState<string>('');
  const [currentSearchUserId, setCurrentSearchUserId] = useState<string>('');

  return (
    <MSTeamsContext.Provider
      value={{
        currentChatId,
        setCurrentChatId,
        currentChannelId,
        setCurrentChannelId,
        currentTeamId,
        setCurrentTeamId,
        currentSearchUserId,
        setCurrentSearchUserId,
      }}
    >
      {children}
    </MSTeamsContext.Provider>
  );
};

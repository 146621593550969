import { Button } from 'antd';
import styled from 'styled-components';
import { colors } from 'ui/elements';

export const StyledCreateButton = styled(Button)`
  width: 100%;
  background-color: ${colors.orangeShadow};
  border-color: ${colors.orangeShadow};
  padding: 8px 24px;
  height: 40px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-align: left;

  &:hover,
  &:focus {
    background-color: ${colors.orange};
    border-color: ${colors.orange};
  }
`;

export const SelectWrapper = styled.div`
  .ant-select-arrow svg {
    fill: black;
  }
`;

import { Card, Typography } from 'antd';
import styled from 'styled-components';
import { colors, space, typography } from 'ui/elements';

const { Text } = Typography;
export const StyleContentWrapper = styled(Card)`
  margin: ${space.normal};
  width: 100%;
  padding: ${space.moderate};
`;

export const StyleUnderLineText = styled(Text)`
  font-size: ${typography.size.kilo};
  color: ${colors.primaryColor};
  text-decoration-line: underline;
  cursor: pointer;
`;

export const StyledSecondTextColor = styled(Text)`
  font-size: ${typography.size.kilo};
  color: ${colors.textColorSecondary};
`;

export const StyledUsersContent = styled.div`
  display: flex;
  padding: 1rem;
`;

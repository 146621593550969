import React, { FC, memo } from 'react';
import { TType } from 'translations';
import { FormInstance } from 'antd/lib/form';
import { Col, Row } from 'antd';
import {
  ProcessDescription,
  PRODUCT_INFO_FORM_COL_SPAN,
  ProductInfoFormWrapper,
} from 'ui/components';
import { space, VerticalSpace } from 'ui/elements';
import { IMappedProfileUpdateData, IProfileUpdateDataLookup } from 'types';
import { GUTTER_16 } from '../../../templates';
import {
  ProfileUpdatePersonalDetailsFormGroup,
  ProfileUpdateEducationalDegreeFormGroup,
  ProfileUpdateExperienceFormGroup,
  ProfileUpdateEmergencyContactFormGroup,
  ProfileUpdateDependentFormGroup,
  ProfileUpdateProfessionalCertificatesFormGroup,
  ProfileUpdateTrainingFormGroup,
  ProfileUpdatePrivacyConsent,
} from './components';

type Props = {
  t: TType;
  lookups: IProfileUpdateDataLookup;
  form?: FormInstance;
  userDetails?: IMappedProfileUpdateData;
};

const ProfileUpdateFormFieldsComponent: FC<Props> = ({ form, lookups, t, userDetails }) => {
  return (
    <ProductInfoFormWrapper>
      <VerticalSpace size={parseInt(space.moderate)}>
        <Row gutter={GUTTER_16} align={'middle'}>
          <Col span={PRODUCT_INFO_FORM_COL_SPAN}>
            <ProcessDescription
              descriptionLabel={t('expenses.staticForm.label.requestDescription')}
              descriptionText={lookups.processDescription}
            />
          </Col>
          <Col span={PRODUCT_INFO_FORM_COL_SPAN}>
            <VerticalSpace size={parseInt(space.comfy)}>
              <ProfileUpdatePersonalDetailsFormGroup
                genderLookups={lookups.lookups.GENDER}
                maritalStatusLookups={lookups.lookups.MARITAL_STATUS}
                countriesLookups={lookups.lookups.COUNTRIES}
                t={t}
                form={form}
              />
              <ProfileUpdateEducationalDegreeFormGroup
                t={t}
                form={form}
                hasInitialValues={
                  !!(userDetails?.educationalDegrees && userDetails?.educationalDegrees?.length > 0)
                }
                degreeTypeLookups={lookups.lookups.DEGREE_TYPE}
                educationalDegrees={
                  userDetails?.educationalDegrees ? [...userDetails.educationalDegrees] : undefined
                }
              />
              <ProfileUpdateExperienceFormGroup
                t={t}
                form={form}
                hasInitialValues={
                  !!(userDetails?.experiences && userDetails?.experiences?.length > 0)
                }
                experiences={userDetails?.experiences ? [...userDetails.experiences] : undefined}
              />
              <ProfileUpdateEmergencyContactFormGroup
                t={t}
                form={form}
                hasInitialValues={
                  !!(userDetails?.emergencyContacts && userDetails?.emergencyContacts?.length > 0)
                }
                relationshipLookups={lookups.lookups.EMERGENCY_CONTACT_RELATIONSHIP}
                phoneCodeLookups={lookups.lookups.PHONE_CODES}
              />
              <ProfileUpdateDependentFormGroup
                t={t}
                form={form}
                relationshipLookups={lookups.lookups.DEPENDANT_RELATIONSHIP}
              />
              <ProfileUpdateProfessionalCertificatesFormGroup
                t={t}
                form={form}
                professionalCertificates={
                  userDetails?.professionalCertificates
                    ? [...userDetails.professionalCertificates]
                    : undefined
                }
              />
              <ProfileUpdateTrainingFormGroup
                t={t}
                form={form}
                trainings={userDetails?.training ? [...userDetails.training] : undefined}
              />
              <ProfileUpdatePrivacyConsent t={t} />
            </VerticalSpace>
          </Col>
        </Row>
      </VerticalSpace>
    </ProductInfoFormWrapper>
  );
};

export const ProfileUpdateFormFields = memo(ProfileUpdateFormFieldsComponent);

import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslate } from 'translations';
import { useTasksContext } from 'routes/Tasks/TasksProvider';
import { Empty } from 'antd';
import { useGetRequestComments } from 'ui/hooks';
import { RequestGeneralInfo, TaskStatus } from 'types';
import { colors } from 'ui/elements/theme/constants';
import {
  StyledAddCommentButton,
  StyledAddCommentInputContainer,
  StyledCommentsDrawer,
  StyledCommentsTitle,
  StyledCommentsWrapper,
  StyledNoCommentsWrapper,
} from './TaskCommentsDrawer.styled';
import { TaskComments } from './TaskComments';
import { CommentsInput } from './CommentsInput';

export const TaskCommentsDrawerComponent: FC = () => {
  const { t } = useTranslate();
  const [showAddCommentInput, setShowAddedCommentInput] = useState(false);
  const { setCommentsRequestId, commentsRequestId } = useTasksContext();
  const {
    isCommentLoading,
    commentsForEachTask,
    fetchNewComments,
    commentsCount,
    lastTask,
    requestDetail,
    setRequestDetail,
  } = useGetRequestComments(commentsRequestId);

  const onAddCommentClicked = useCallback(() => {
    setShowAddedCommentInput(!showAddCommentInput);
  }, [showAddCommentInput]);

  const onCommentsClick = useCallback(() => {
    setCommentsRequestId('');
    setShowAddedCommentInput(false);
    setRequestDetail({} as RequestGeneralInfo);
  }, [setCommentsRequestId, setRequestDetail]);

  const onAddCommentFinish = () => {
    fetchNewComments();
    setShowAddedCommentInput(false);
  };

  const onCancelComment = () => {
    setShowAddedCommentInput(false);
  };

  return (
    <StyledCommentsDrawer
      className="taskCommentsDrawer"
      closable
      destroyOnClose
      maskClosable
      getContainer={false}
      placement="right"
      visible={!!commentsRequestId}
      onClose={onCommentsClick}
      width={560}
      title={<StyledCommentsTitle>{t('task.comments')}</StyledCommentsTitle>}
    >
      <StyledCommentsWrapper isCommentInputVisible={showAddCommentInput}>
        {commentsCount ? (
          commentsForEachTask?.map(({ taskName, taskStatus, comments, taskId }, index) => (
            <TaskComments
              index={index}
              key={taskId}
              taskId={taskId}
              taskTitle={taskName}
              taskStatus={taskStatus as TaskStatus}
              taskComments={[...comments].reverse()}
              isComponentLoading={isCommentLoading}
            />
          ))
        ) : (
          <StyledNoCommentsWrapper>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('task.noComments')}
              style={{
                fontFamily: 'inherit',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '44px',
                textAlign: 'center',
                color: colors.lightGrey,
              }}
            />
          </StyledNoCommentsWrapper>
        )}
      </StyledCommentsWrapper>
      <StyledAddCommentInputContainer showShadow={showAddCommentInput}>
        {lastTask?.taskId && showAddCommentInput && (
          <CommentsInput
            taskId={lastTask.taskId}
            onFinish={onAddCommentFinish}
            requestName={requestDetail.requestInfo.processName}
            onCloseComment={onCancelComment}
            isJira={requestDetail.requestInfo?.jiraRequest ?? false}
          />
        )}
        {lastTask?.taskId && !showAddCommentInput && (
          <StyledAddCommentButton type={'primary'} onClick={onAddCommentClicked}>
            {t('task.addComment')}
          </StyledAddCommentButton>
        )}
      </StyledAddCommentInputContainer>
    </StyledCommentsDrawer>
  );
};

export const TaskCommentsDrawer = memo(TaskCommentsDrawerComponent);

import { Core, COUNTRY_CITY_LIST, HANDLE_DISTANCE_BW_CITIES } from 'api/Core';
import { AxiosRequestConfig } from 'axios';
import { CountryCityList, HandleDistance } from 'types';

interface GetCountryCityList {
  (params?: AxiosRequestConfig['params']): Promise<{ data: CountryCityList }>;
}

interface GetDistanceBWCities {
  (originId: number, destinationId: number): Promise<{ data: HandleDistance }>;
}

class CountryCity extends Core {
  getCountryCityList: GetCountryCityList = async (params) => this.get(COUNTRY_CITY_LIST(), params);
  getDistanceBWCities: GetDistanceBWCities = async (originId, destinationId) =>
    this.get(HANDLE_DISTANCE_BW_CITIES(originId, destinationId));
}

export const countryCityList = new CountryCity();

import React, { useRef } from 'react';
import { CardCarousel, CarouselWrapper } from 'ui/components/CardCarousel';
import { CarouselRef } from 'antd/lib/carousel';
import { useTranslate } from 'translations';
import { useAnnouncementsList } from 'api';
import { Announcement, AnnouncementCard } from './Components';
import { ViewAllAnnouncements } from './AnnouncementCard.styled';

const DEFAULT_SLIDES_SHOW = 2.3;

const CMSCarouselComponent: React.FC = () => {
  const MAX_CARDS_NUMBER = 6;
  const carouselEl = useRef<CarouselRef>(null);
  const [cardsLength, setCardsLength] = React.useState<number>(0);
  const [scrollsCount, setScrollsCount] = React.useState<number>(0);
  const { t } = useTranslate();
  const announcementsMutation = useAnnouncementsList();

  const beforeChange = (currentSlide: number, nextSlide: number) => setScrollsCount(nextSlide);

  React.useEffect(() => {
    announcementsMutation.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (announcementsMutation.data) {
      setCardsLength(announcementsMutation.data.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementsMutation.data]);

  return announcementsMutation.isError ? (
    <></>
  ) : (
    <CarouselWrapper
      carouselTitle={t('dashboard.CMS.title')}
      emptyDescription={t('dashboard.myTask.empty.description')}
      isLoading={announcementsMutation.isLoading}
      cardLength={cardsLength > MAX_CARDS_NUMBER ? MAX_CARDS_NUMBER + 1 : cardsLength}
      carouselEL={carouselEl}
      scrollsCount={scrollsCount}
    >
      <CardCarousel
        carouselEL={carouselEl}
        slidesShow={DEFAULT_SLIDES_SHOW}
        beforeChange={beforeChange}
      >
        {announcementsMutation.data &&
          announcementsMutation.data
            .slice(0, MAX_CARDS_NUMBER)
            .map((card) => (
              <Announcement key={`announcement-card-${card.cardName}`} cardInfo={card} />
            ))}
        {cardsLength > MAX_CARDS_NUMBER && (
          <AnnouncementCard centered={true}>
            <ViewAllAnnouncements>{t('common.viewAll')}</ViewAllAnnouncements>
          </AnnouncementCard>
        )}
      </CardCarousel>
    </CarouselWrapper>
  );
};

export const CmsCarousel = React.memo(CMSCarouselComponent);

import React from 'react';
import { Col } from 'antd';
import { StyledYourDelegateTitleTxt } from '../../../routes';
import { useTranslate } from '../../../translations';
import { LeavesDelegateType } from '../../../types';
import { LeaveAssignedDelegatesItem } from './LeaveAssignedDelegatesItem';

export interface LeaveAssignedDelegatesProps {
  delegation: LeavesDelegateType[];
}
export const LeaveAssignedDelegates: React.FC<LeaveAssignedDelegatesProps> = ({
  delegation,
}: LeaveAssignedDelegatesProps) => {
  const { t } = useTranslate();
  return (
    <Col span={24}>
      <StyledYourDelegateTitleTxt>
        {delegation?.length > 1
          ? t('leaves.newRequest.yourDelegates')
          : t('leaves.newRequest.yourDelegate')}
      </StyledYourDelegateTitleTxt>
      <Col>
        {delegation?.map((item, index) => (
          <LeaveAssignedDelegatesItem key={index} delegation={item} />
        ))}
      </Col>
    </Col>
  );
};

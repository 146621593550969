import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';
import { notification } from 'antd';
import { useTranslate } from 'translations';
import { settings } from 'api/Settings';

export const useUpdateNotificationSettings = () => {
  const { t } = useTranslate();

  return useMutation(
    async (body: AxiosRequestConfig['data']) => {
      const { data } = await settings.postNotificationSettings(body);
      return data;
    },
    {
      onError: (error: AxiosError) => {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: error.response?.data.errors[0].message,
        });
      },
    }
  );
};

import { LeavesHistoryData } from 'types';
import { Core, LEAVES_HISTORY } from 'api';

interface GetLeavesHistory {
  (userId: string): Promise<{ data: LeavesHistoryData }>;
}

class LeavesHistory extends Core {
  getLeavesHistory: GetLeavesHistory = async (userId) => this.get(LEAVES_HISTORY(userId));
}

export const leavesHistory = new LeavesHistory();

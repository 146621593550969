import React from 'react';
import { Row } from 'antd';
import { UserTableUserInfo } from 'types';
import { useTranslate } from 'translations';
import { UserDrawerUserInfoDetails } from './components/UserDrawerUserInfoDetails/UserDrawerUserInfoDetails';
import {
  UserDrawerSectionContainer,
  UserDrawerUserInfoTitle,
  UserDrawerJobInfoItem,
  UserDrawerJobInfoItemTitle,
  UserDrawerJobInfoItemValue,
} from './UserDrawerUserInfo.styled';

type Props = {
  userInfo: UserTableUserInfo;
};

export const UserDrawerUserInfo: React.FC<Props> = ({ userInfo }) => {
  const { t } = useTranslate();
  return (
    <UserDrawerSectionContainer>
      <UserDrawerUserInfoDetails
        adoid={userInfo.adOid}
        name={`${userInfo.firstName} ${userInfo.lastName}`}
        email={userInfo.email}
      />
      <UserDrawerSectionContainer>
        <UserDrawerUserInfoTitle>
          {t('processManagement.users.drawer.jobInfo')}
        </UserDrawerUserInfoTitle>
        <Row>
          <UserDrawerJobInfoItem span={12}>
            <UserDrawerJobInfoItemTitle>
              {t('user.publicProfile.positionName')}
            </UserDrawerJobInfoItemTitle>
            <UserDrawerJobInfoItemValue ellipsis={{ tooltip: userInfo.jobTitle }}>
              {userInfo.jobTitle}
            </UserDrawerJobInfoItemValue>
          </UserDrawerJobInfoItem>
          <UserDrawerJobInfoItem span={12}>
            <UserDrawerJobInfoItemTitle>
              {t('user.publicProfile.department')}
            </UserDrawerJobInfoItemTitle>
            <UserDrawerJobInfoItemValue ellipsis={{ tooltip: userInfo.department }}>
              {userInfo.department}
            </UserDrawerJobInfoItemValue>
          </UserDrawerJobInfoItem>
          <UserDrawerJobInfoItem span={12}>
            <UserDrawerJobInfoItemTitle>
              {t('user.publicProfile.location')}
            </UserDrawerJobInfoItemTitle>
            <UserDrawerJobInfoItemValue ellipsis={{ tooltip: userInfo.location }}>
              {userInfo.location}
            </UserDrawerJobInfoItemValue>
          </UserDrawerJobInfoItem>
          <UserDrawerJobInfoItem span={12}>
            <UserDrawerJobInfoItemTitle>
              {t('user.publicProfile.managerName')}
            </UserDrawerJobInfoItemTitle>
            <UserDrawerJobInfoItemValue ellipsis={{ tooltip: userInfo.managerName }}>
              {userInfo.managerName}
            </UserDrawerJobInfoItemValue>
          </UserDrawerJobInfoItem>
          <UserDrawerJobInfoItem span={12}>
            <UserDrawerJobInfoItemTitle>
              {t('user.publicProfile.managerEmail')}
            </UserDrawerJobInfoItemTitle>
            <UserDrawerJobInfoItemValue ellipsis={{ tooltip: userInfo.managerEmail }}>
              {userInfo.managerEmail}
            </UserDrawerJobInfoItemValue>
          </UserDrawerJobInfoItem>
          <UserDrawerJobInfoItem span={12}>
            <UserDrawerJobInfoItemTitle>
              {t('processManagement.users.table.organizationUnit')}
            </UserDrawerJobInfoItemTitle>
            <UserDrawerJobInfoItemValue ellipsis={{ tooltip: userInfo.employeeOrgUnit }}>
              {userInfo.employeeOrgUnit}
            </UserDrawerJobInfoItemValue>
          </UserDrawerJobInfoItem>
        </Row>
      </UserDrawerSectionContainer>
    </UserDrawerSectionContainer>
  );
};

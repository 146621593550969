import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';
import { staticForm } from 'api/StaticForm/StaticForm';
import { notification } from 'antd';
import { useTranslate } from 'translations';

export const useDelegationSubmit = () => {
  const { t } = useTranslate();
  return useMutation(
    async (formData: AxiosRequestConfig['data']) => {
      const { data } = await staticForm.postDelegationForm(formData);
      return data;
    },
    {
      onError: (error: AxiosError) => {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: error.response?.data.errors[0].message,
        });
      },
    }
  );
};

import React, { FC, useCallback, useContext, useMemo } from 'react';
import { FormInstance, Rule } from 'antd/lib/form';
import { TType } from 'translations';
import { UploadFile } from 'antd/lib/upload/interface';
import { CreateFormItemType, NONPOFiledIds, SelectOptions } from 'types';
import {
  FORM_ITEM_REQUIRED_RULE,
  INPUT_VALIDATION_DECIMAL_Digit_PATTERN,
  INPUT_VALIDATION_FOR_100_PERCENTAGE,
} from 'utils';
import { createFormField } from 'utils/formCreation';
import { selectFilterOption } from 'utils/getFilterOptions';
import { MISCELLANEOUS_SUPPLIER_ID, NonPoRequestFormContext } from '../NonPoRequestForm';
import { CustomFormGroupWrapper } from './CustomFormGroupWraper';

type Props = {
  t: TType;
  hasInitialValues: boolean;
  form?: FormInstance;
};

export const QuotationDetailsFormGroup: FC<Props> = ({ t, hasInitialValues, form }) => {
  const {
    dataLookUpResponse,
    recommendedQuotation,
    supplierOptions,
    isEdit,
    searchSupplier,
    isNonPoInvoiceSuppliersLoading,
    isLoadingConversationRate,
    isConversationRateFailed,
    quotationDetailSuppliers,
    updateDeletedQuotationIds,
  } = useContext(NonPoRequestFormContext);

  const getLookUps = useCallback(
    (fieldId: NONPOFiledIds) => {
      let selectOptionsOfNonPoLookUps: SelectOptions = [];
      if (dataLookUpResponse) {
        switch (fieldId) {
          case NONPOFiledIds.QuotationDetail_Currency:
            selectOptionsOfNonPoLookUps = dataLookUpResponse?.currencyList;
            break;
        }
      }
      return selectOptionsOfNonPoLookUps;
    },
    [dataLookUpResponse]
  );

  const onAttachmentChange = useCallback(
    (formListIndex: number, file?: UploadFile | UploadFile[]) => {
      if (form) {
        form.setFields([
          {
            name: [
              NONPOFiledIds.QuotationDetail_QuotationDetails,
              formListIndex,
              NONPOFiledIds.QuotationDetail_AttachmentList,
            ],
            value: file,
          },
        ]);
      }
    },
    [form]
  );

  const mSupplierOptions = useMemo(() => {
    return supplierOptions;
  }, [supplierOptions]);

  const converstionRateValidator = useCallback(
    (value) => {
      if (isConversationRateFailed && !isLoadingConversationRate) {
        return Promise.reject(t('messages.error.conversationRate'));
      }
      return Promise.resolve('');
    },
    [isConversationRateFailed, isLoadingConversationRate, t]
  );

  const CONVERSION_RATE_RULE: Rule[] = useMemo(() => {
    return [
      ...FORM_ITEM_REQUIRED_RULE,
      {
        validator: converstionRateValidator,
      },
    ];
  }, [converstionRateValidator]);

  const showMiscellaneousSupplierName = useCallback(
    (formListName: number) => {
      const supplierNameLabel = quotationDetailSuppliers?.[formListName]?.label?.trim() ?? '';
      const show = supplierNameLabel?.includes(MISCELLANEOUS_SUPPLIER_ID);
      if (!show) {
        const nameMiscellaneousSupplierName = [
          NONPOFiledIds.QuotationDetail_QuotationDetails,
          formListName,
          NONPOFiledIds.QuotationDetail_Miscellaneous_SupplierName,
        ];

        form?.setFields([
          {
            name: nameMiscellaneousSupplierName,
            value: undefined,
            errors: [],
          },
        ]);
      }
      return show;
    },
    [form, quotationDetailSuppliers]
  );

  const QuotationDetailsFormGroupFields = useCallback<
    (formListName: number) => CreateFormItemType[]
  >(
    (formListName) => {
      return [
        createFormField({
          id: 'id',
          name: [formListName, NONPOFiledIds.QuotationDetail_QuotationId],
          type: 'text',
          isHidden: true,
          t,
        }),
        createFormField({
          id: 'supplierName',
          label: t('requestDetails.supplierName.label'),
          name: [formListName, NONPOFiledIds.QuotationDetail_SupplierName],
          type: 'select',
          hasFeedback: true,
          filterOption: false,
          isLoading: isNonPoInvoiceSuppliersLoading,
          options: mSupplierOptions,
          onSearch: searchSupplier,
          noResultFound: 'No such supplier exists',
          placeholder: t('requestDetails.supplierName.placeHolder'),
          disabled: isEdit,
          selectTheValueAsKey: true,
          selectWidth: 318,
          t,
        }),
        createFormField({
          id: NONPOFiledIds.QuotationDetail_Miscellaneous_SupplierName,
          label: t('requestDetails.miscellaneous_supplierName.label'),
          name: [formListName, NONPOFiledIds.QuotationDetail_Miscellaneous_SupplierName],
          type: 'input',
          t,
          disabled: isEdit,
          isHidden: !showMiscellaneousSupplierName(formListName),
        }),
        createFormField({
          id: 'currency',
          label: t('quotationDetails.currency.label'),
          name: [formListName, NONPOFiledIds.QuotationDetail_Currency],
          type: 'select',
          filterOption: selectFilterOption,
          placeholder: t('quotationDetails.currency.placeHolder'),
          options: getLookUps(NONPOFiledIds.QuotationDetail_Currency),
          isLoading: isLoadingConversationRate,
          withFullRow: showMiscellaneousSupplierName(formListName),
          t,
        }),
        createFormField({
          id: 'quotedPrice',
          label: t('quotationDetails.quotedPrice.label'),
          name: [formListName, NONPOFiledIds.QuotationDetail_QuotedPrice],
          type: 'inputNumber',
          placeholder: t('quotationDetails.quotedPrice.placeHolder'),
          rules: INPUT_VALIDATION_DECIMAL_Digit_PATTERN(
            true,
            3,
            t('requestForm.validation.rule.message.maxDecimal.3')
          ),
          t,
        }),
        createFormField({
          id: 'conversionRate',
          label: t('quotationDetails.conversionRate.label'),
          name: [formListName, NONPOFiledIds.QuotationDetail_ConversionRate],
          type: 'input',
          disabled: true,
          rules: CONVERSION_RATE_RULE,
          t,
        }),
        createFormField({
          id: 'quotedPriceSAR',
          label: t('quotationDetails.quotedPriceSAR.label'),
          name: [formListName, NONPOFiledIds.QuotationDetail_QuotedPriceSAR],
          type: 'input',
          rules: INPUT_VALIDATION_DECIMAL_Digit_PATTERN(
            true,
            3,
            t('requestForm.validation.rule.message.maxDecimal.3')
          ),
          placeholder: t('quotationDetails.quotedPriceSAR.placeHolder'),
          disabled: true,
          t,
        }),
        createFormField({
          id: 'advancedPayment',
          label: t('quotationDetails.advancedPayment.label'),
          name: [formListName, NONPOFiledIds.QuotationDetail_AdvancePayment],
          type: 'inputNumber',
          placeholder: t('quotationDetails.advancedPayment.placeHolder'),
          rules: INPUT_VALIDATION_FOR_100_PERCENTAGE(
            true,
            t('requestForm.validation.rule.message.max.100.percentage')
          ),
          maxNumber: 100,
          t,
        }),
        createFormField({
          id: 'advanceAmountToBePaid',
          label: t('quotationDetails.advanceAmountToBePaid.label'),
          name: [formListName, NONPOFiledIds.QuotationDetail_AdvanceAmountToBePaid],
          type: 'input',
          disabled: true,
          t,
        }),
        createFormField({
          id: 'description',
          label: t('quotationDetails.description.label'),
          name: [formListName, NONPOFiledIds.QuotationDetail_Description],
          type: 'textArea',
          placeholder: t('quotationDetails.description.placeHolder'),
          maxNumber: 400,
          t,
        }),
        createFormField(
          {
            id: 'attachment',
            label: t('quotationDetails.attachment.label'),
            name: [formListName, NONPOFiledIds.QuotationDetail_AttachmentList],
            type: 'file',
            valuePropName: 'fileList',
            onFileChange: (file) => {
              onAttachmentChange(formListName, file);
            },
            t,
          },
          true
        ),
        createFormField({
          id: 'markAsRecommended',
          labelCheckBoxTitle: t('quotationDetails.markAsRecommended.label'),
          rules: [],
          name: [formListName, NONPOFiledIds.QuotationDetail_MarkAsRecommended],
          type: 'checkboxWithTitle',
          valuePropName: 'checked',
          initialValue: false,
          t,
        }),
        createFormField({
          id: 'justification',
          label: t('quotationDetails.justification.label'),
          name: [formListName, NONPOFiledIds.QuotationDetail_Justification],
          type: 'textArea',
          isHidden: recommendedQuotation !== formListName,
          placeholder: t('quotationDetails.description.placeHolder'),
          maxNumber: 400,
          t,
        }),
      ];
    },
    [
      t,
      isNonPoInvoiceSuppliersLoading,
      mSupplierOptions,
      searchSupplier,
      isEdit,
      showMiscellaneousSupplierName,
      getLookUps,
      isLoadingConversationRate,
      CONVERSION_RATE_RULE,
      recommendedQuotation,
      onAttachmentChange,
    ]
  );
  return (
    <CustomFormGroupWrapper
      t={t}
      maxRecords={3}
      formGroupItems={QuotationDetailsFormGroupFields}
      formListName={NONPOFiledIds.QuotationDetail_QuotationDetails}
      sectionTitle={t('quotationDetails.sectionTitle')}
      addButtonTitle={t('quotationDetails.buttonTitle')}
      addButtonTitleSecondary={t('quotationDetails.addMore')}
      hasAtLeastOne={true}
      hasInitialValue={hasInitialValues}
      isEdit={isEdit}
      form={form}
      onRemoveFormListItem={updateDeletedQuotationIds}
    />
  );
};

import styled from 'styled-components';
import { colors } from 'ui/elements';
import { Typography } from 'antd';

const { Text } = Typography;

export const TaskDescription = styled.div`
  padding: 24px;
  background: #f6f7f9;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  margin-bottom: 14px;
`;
export const TaskDescriptionDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
`;

export const LabelText = styled(Text)`
  color: ${colors.textColorSecondary} !important;
  font-size: 14px;
  line-height: 22px;
`;

export const InfoText = styled(Text)`
  color: ${colors.primaryColor} !important;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
`;

export const TextDescription = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  color: ${colors.textColorSecondary} !important;
  max-inline-size: -webkit-fill-available;
`;

export const TaskDescriptionText = styled(TextDescription)``;

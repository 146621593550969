import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAttendanceList } from 'api';
import moment from 'moment';
import { DATE_FORMAT, MONTH_YEAR_FORMAT } from 'routes/Attendance/Components/helper';

export const useGetAttendanceList = (hcmId: string, selectedDate?: moment.Moment) => {
  const [currentDate] = useState<moment.Moment>(moment());
  const [TableCurrentDate, setTableCurrentDate] = useState<moment.Moment>(moment());
  const [startDate, setStartDate] = useState<string>(moment().startOf('month').format(DATE_FORMAT));
  const [endDate, setEndDate] = useState<string>(moment().format(DATE_FORMAT));
  const [hideNextMonthBTN, setHideNextMonthBTN] = useState<boolean>(false);

  const { isLoading, isError, refetch, isRefetching, data, isRefetchError } = useAttendanceList(
    hcmId,
    startDate,
    endDate
  );

  useEffect(() => {
    if (selectedDate) {
      if (selectedDate.format(MONTH_YEAR_FORMAT) === currentDate.format(MONTH_YEAR_FORMAT)) {
        setStartDate(selectedDate.startOf('month').format(DATE_FORMAT));
        setEndDate(moment().format(DATE_FORMAT));
        setHideNextMonthBTN(true);
        setTableCurrentDate(moment());
      } else {
        setStartDate(selectedDate.startOf('month').format(DATE_FORMAT));
        setEndDate(selectedDate.endOf('month').format(DATE_FORMAT));
        setHideNextMonthBTN(false);
        setTableCurrentDate(selectedDate);
      }
    } else {
      setStartDate(moment().startOf('month').format(DATE_FORMAT));
      setEndDate(moment().format(DATE_FORMAT));
      setHideNextMonthBTN(true);
      setTableCurrentDate(moment());
    }
  }, [currentDate, selectedDate]);

  const getNextMonth = useCallback(() => {
    const newDate = moment(TableCurrentDate).add(1, 'month');

    if (!hideNextMonthBTN) {
      if (newDate.format(MONTH_YEAR_FORMAT) === currentDate.format(MONTH_YEAR_FORMAT)) {
        setStartDate(moment().startOf('month').format(DATE_FORMAT));
        setEndDate(moment().format(DATE_FORMAT));
        setHideNextMonthBTN(true);
        setTableCurrentDate(moment());
      } else {
        setStartDate(newDate.startOf('month').format(DATE_FORMAT));
        setEndDate(newDate.endOf('month').format(DATE_FORMAT));
        setHideNextMonthBTN(false);
        setTableCurrentDate(newDate);
      }
    }
  }, [TableCurrentDate, currentDate, hideNextMonthBTN]);

  const getPriviesMonth = useCallback(() => {
    const newDate = moment(TableCurrentDate).subtract(1, 'month');
    setStartDate(newDate.startOf('month').format(DATE_FORMAT));
    setEndDate(newDate.endOf('month').format(DATE_FORMAT));
    setHideNextMonthBTN(false);
    setTableCurrentDate(newDate);
  }, [TableCurrentDate]);

  const getCurrentMonth = () => {
    setStartDate(moment().startOf('month').format(DATE_FORMAT));
    setEndDate(moment().format(DATE_FORMAT));
    setHideNextMonthBTN(true);
    setTableCurrentDate(moment());
  };

  const isTableLoading = useMemo(
    () => isLoading || isRefetching,

    [isLoading, isRefetching]
  );

  const totalResult = useMemo(() => data && data?.length, [data]);

  useEffect(() => {
    if (startDate && endDate && hcmId) {
      refetch();
    }
  }, [endDate, refetch, startDate, hcmId]);

  return {
    totalResult,
    isTableLoading,
    isError,
    getCurrentMonth,
    getNextMonth,
    getPriviesMonth,
    TableCurrentDate,
    data,
    hideNextMonthBTN,
    isRefetchError,
  };
};

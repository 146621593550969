export enum JiraDynamicFormFieldTypeEnum {
  TEXT = 'text',
  SELECT = 'enum',
  CHECKBOX = 'checkbox',
  URL = 'url',
  TEXTAREA = 'textArea',
  ATTACHMENT = 'attachment',
  MULTISELECT = 'multipleSelect',
  ASSIGNASSET = 'assignAsset',
  ASSETDEALLOCATE = 'assignAssetCheckbox',
}

export enum JiraDynamicFormFieldPropertiesActionsEnum {
  SHOW = 'SHOW',
  REQUIRED = 'REQUIRED',
}

export interface JiraDynamicFormFieldProperties {
  id: number;
  actionName: JiraDynamicFormFieldPropertiesActionsEnum;
  dependantFieldKey: string;
  dependantFieldValue: string;
}

export interface JiraDynamicFormFieldType {
  id: number;
  label: string;
  key: string;
  type: JiraDynamicFormFieldTypeEnum;
  required: boolean;
  fieldOrder: number;
  fieldEnums?: { id: string; value: string }[];
  fieldProperties?: JiraDynamicFormFieldProperties[];
}

import React, { FC, useCallback, useEffect, useMemo } from 'react';

import { Button, Col, Divider, Row } from 'antd';

import { AppLayout } from 'layouts';
import { Header } from 'app';
import { FullWidthSpin, TitleH1 } from 'ui';
import { useTranslate } from 'translations';
import { Redirect, useLocation } from 'react-router';
import { OneDrivePageRoutes } from 'namespace';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { FolderOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { SelectIcon } from 'assets';
import {
  getTeamsData,
  getTeamsStart,
  oneDriveSharedLibraries,
  useDispatch,
  useSelector,
} from 'store';
import { RequestStatus } from 'types';
import { OneDriveSearch } from '../OneDriveSearch';
import { OneDriveMyFiles } from '../OneDriveMyFiles';
import { OneDriveSharedLibrary } from '../OneDriveSharedLibrary';
import { OneDriveShared } from '../OneDriveShared';
import { SideBar, StyledMenuItem, StyledMenuItemGroup, StyledMenu } from '../OneDrive.styled';
import { OneDriveSharedLibraryRow } from '../OneDriveSharedLibraryRow';
import { useOneDriveContext } from '../OneDriveContextProvider';
import { OneDriveSearchResult } from '../OneDriveSearch/OneDriveSearchResult';

export const OneDriveLayout: FC = () => {
  const { t } = useTranslate();
  const { path } = useRouteMatch();
  const location = useLocation();
  const teamsData = useSelector(getTeamsData);
  const dispatch = useDispatch();
  const oneDriveLibraries = useSelector(oneDriveSharedLibraries);
  const { currentFolderLink, setCurrentFolderLink } = useOneDriveContext();

  const hasLoadedLibrary = useMemo(
    () =>
      Object.values(oneDriveLibraries).some((library) => library.status === RequestStatus.resolved),
    [oneDriveLibraries]
  );

  const getUrl = useCallback((pagePath: string) => `${path}${pagePath}`, [path]);

  const onMenuItemClick = useCallback(() => setCurrentFolderLink(''), [setCurrentFolderLink]);

  const menuItems = useMemo(
    () => [
      { link: OneDrivePageRoutes.MyFiles, label: 'My Files', icon: <FolderOutlined /> },
      { link: OneDrivePageRoutes.Shared, label: 'Shared', icon: <UsergroupAddOutlined /> },
    ],
    []
  );

  useEffect(() => {
    if (teamsData.status !== RequestStatus.resolved) {
      dispatch(getTeamsStart());
    }
  }, [dispatch, teamsData.status]);

  const sharedLibrariesRows = teamsData.data?.map((teamsData) => (
    <OneDriveSharedLibraryRow key={teamsData.id} groupId={teamsData.id} />
  ));

  return (
    <AppLayout displayFooter={false}>
      <Header>
        <Row align="middle">
          <Col>
            <TitleH1>{t('oneDrive.title')}</TitleH1>
          </Col>

          <Col flex="1">
            <Row justify="center" align="middle">
              <Col span={7} style={{ display: 'flex' }}>
                <OneDriveSearch />
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>

      <Divider style={{ margin: 0 }} className="layout-main" />

      <Row>
        <Col span={6}>
          <SideBar>
            <StyledMenu selectedKeys={[location.pathname]}>
              {menuItems.map((menuItem) => (
                <StyledMenuItem
                  onClick={onMenuItemClick}
                  className="sidebar-menu-item"
                  key={getUrl(menuItem.link)}
                  icon={menuItem.icon}
                >
                  <Link to={getUrl(menuItem.link)}>{menuItem.label}</Link>
                </StyledMenuItem>
              ))}

              <StyledMenuItemGroup key="g1" title="Shared Libraries">
                {sharedLibrariesRows}
                {!hasLoadedLibrary && <FullWidthSpin />}
              </StyledMenuItemGroup>
            </StyledMenu>

            {currentFolderLink && (
              <Row justify="center" gutter={[24, 24]}>
                <Col span={22}>
                  <Button href={currentFolderLink} target="_blank" style={{ width: '100%' }}>
                    <Row justify="center" align="middle" gutter={[24, 0]}>
                      <Col>{t('oneDrive.button.sharePoint')}</Col>
                      <Col style={{ display: 'flex' }}>
                        <SelectIcon />
                      </Col>
                    </Row>
                  </Button>
                </Col>
              </Row>
            )}
          </SideBar>
        </Col>

        <Col span={18}>
          <Switch>
            <Route exact path={getUrl(OneDrivePageRoutes.MyFiles)} component={OneDriveMyFiles} />
            <Route exact path={getUrl(OneDrivePageRoutes.Shared)} component={OneDriveShared} />
            <Route
              exact
              path={getUrl(OneDrivePageRoutes.SharedLibrary)}
              component={OneDriveSharedLibrary}
            />
            <Route
              exact
              path={getUrl(OneDrivePageRoutes.SearchResult)}
              component={OneDriveSearchResult}
            />

            <Redirect to={getUrl(OneDrivePageRoutes.MyFiles)} />
          </Switch>
        </Col>
      </Row>
    </AppLayout>
  );
};

import { Col, Row, Space, Typography } from 'antd';
import React, { FC } from 'react';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { TType } from 'translations';
import { UserPopover } from 'ui/components/UserPopover';
import { RequestedStyled, StyledAssignedToCol, StyledTaskAvatar } from './TaskCard.styled';

interface Props {
  requesterId: string;
  requesterName: string;
  requesterEmail: string;
  t: TType;
}

export const RequesterDetail: FC<Props> = ({ requesterEmail, requesterId, requesterName, t }) => {
  const { Text } = Typography;
  return (
    <React.Fragment>
      <Row gutter={[12, 12]}>
        <Col>
          <RequestedStyled type={'secondary'}>{t('task.card.requestedBy')}</RequestedStyled>
        </Col>
        <Col>
          <Space align={'baseline'}>
            <UserPopover currentID={requesterId} currentEmail={requesterEmail}>
              <StyledTaskAvatar
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <MSTeamsUserAvatar id={requesterId} size={24} name={requesterName} />
              </StyledTaskAvatar>
            </UserPopover>

            <Text type={'secondary'} ellipsis strong>
              <Row gutter={[6, 0]} wrap={false}>
                <UserPopover currentID={requesterName} currentEmail={requesterEmail}>
                  <StyledAssignedToCol
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {requesterName}
                  </StyledAssignedToCol>
                </UserPopover>
              </Row>
            </Text>
          </Space>
        </Col>
      </Row>
    </React.Fragment>
  );
};

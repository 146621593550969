import {
  LeaseSitesFormDetails,
  LeaseSitesFormDetailsResponse,
  LeaseSitesRequestsFormDetails,
  LeaseSitesRequestsFormDetailsResponse,
  LoVs,
  SelectOptions,
} from 'types';

export const mapLovToOptions = (lovs: LoVs): SelectOptions =>
  lovs?.map(({ value, id }) => ({
    value: id,
    label: value,
  }));

export const mapResponseToSelectOptions = (
  data: LeaseSitesFormDetailsResponse
): LeaseSitesFormDetails => ({
  ...data,
  projectNamesList: mapLovToOptions(data?.projectNamesList),
  regionsList: mapLovToOptions(data?.regionsList),
  requestPriorityList: mapLovToOptions(data?.requestPriorityList),
});

export const mapResponseToRequestsFormDetails = (
  data: LeaseSitesRequestsFormDetailsResponse
): LeaseSitesRequestsFormDetails => ({
  ...data,
  leasedSpaceTypes: mapLovToOptions(data.leasedSpaceTypes),
  siteTypes: mapLovToOptions(data.siteTypes),
  powerSourceTypes: mapLovToOptions(data.powerSourceTypes),
  accessRoad: mapLovToOptions(data.accessRoad),
  landlordGovernmentTypes: mapLovToOptions(data.landlordGovernmentTypes),
  landlordCountries: mapLovToOptions(data.landlordCountries),
  fullLandlordCountries: data.landlordCountries,
  taxOrganizationTypes: mapLovToOptions(data.taxOrganizationTypes),
  paymentMethods: mapLovToOptions(data.paymentMethods),
  banks: mapLovToOptions(data.banks),
  regions: mapLovToOptions(data.regions),
  landlordTypes: mapLovToOptions(data.landlordTypes),
  genders: mapLovToOptions(data.genders),
  ownerApprovalToInstallSubStation: mapLovToOptions(data.ownerApprovalToInstallSubStation),
  powerSourceDistanceFromSubStation: mapLovToOptions(data.powerSourceDistanceFromSubStation),
  candidateCompliedWithMomraRegulations: mapLovToOptions(
    data.candidateCompliedWithMomraRegulations
  ),
  ownerDeedAndIdDoc: mapLovToOptions(data.ownerDeedAndIdDoc),
  obstructionExist: mapLovToOptions(data.obstructionExist),
  penthouseAvailable: mapLovToOptions(data.penthouseAvailable),
  candidateEligibleForSite: mapLovToOptions(data.candidateEligibleForSite),
  landlordIsVATApplicable: mapLovToOptions(data.landlordIsVATApplicable),
  delegateIsVATApplicable: mapLovToOptions(data.delegateIsVATApplicable),
});

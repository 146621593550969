import styled from 'styled-components';
import img from 'assets/backgrounds/6.jpg';

export const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;

export const LoginBackground = styled.div`
  height: 100vh;
  background-image: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
`;

export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 93vh;
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, BalanceData } from 'types';
import { BalanceState } from './balanceTypes';

export const emptyBalanceInitialState: BalanceState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetBalanceStart = PayloadAction<string>;
export type GetBalanceSuccess = PayloadAction<BalanceData>;
export type GetBalanceFail = PayloadAction<RequestCommonError>;

const balance = createSlice({
  name: 'balance',
  initialState: emptyBalanceInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getBalanceStart(state, _: GetBalanceStart) {
      state.status = RequestStatus.pending;
    },
    getBalanceSuccess(state, action: GetBalanceSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getBalanceFail(state, action: GetBalanceFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const { getBalanceStart, getBalanceSuccess, getBalanceFail } = balance.actions;
export default balance.reducer;

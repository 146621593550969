import { useQuery } from 'react-query';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';
import { AxiosRequestConfig } from 'axios';
import { duration } from '../travelDuration';

export const useGetEstimatedTotalAmount = (
  sourceId: number,
  destinationId: number,
  tripId: number,
  transportationId?: number,
  config?: AxiosRequestConfig
) => {
  return useQuery(
    [
      QUERIES.estimatedTotalAmount,

      sourceId,
      destinationId,
      transportationId,
      tripId,
      config?.params,
    ],
    async () => {
      if (transportationId) {
        const { data } = await duration.getEstimatedTotalAmount(
          sourceId,
          destinationId,
          transportationId,
          tripId,
          config
        );
        return data;
      }
    },
    {
      enabled: !sourceId && !destinationId && !transportationId && !tripId && !config,
      ...PERSIST_QUERY_CONFIG,
    }
  );
};

import React, { ReactNode } from 'react';
import { SpaceSize } from 'antd/lib/space';
import { Space, Typography } from 'antd';

export declare type DirectionSpace = 'horizontal' | 'vertical';
export interface SpaceTextProps {
  labelValue: string;
  isLabelValueStrong?: boolean;
  isTextValueStrong?: boolean;
  textValueStyle?: React.CSSProperties;
  children: ReactNode;
  direction: DirectionSpace;
  size: SpaceSize | [SpaceSize, SpaceSize];
}

const SpaceTextComponent: React.FC<SpaceTextProps> = ({
  labelValue,
  isLabelValueStrong,
  isTextValueStrong,
  direction,
  size,
  textValueStyle,
  children,
}) => {
  const { Text } = Typography;
  return (
    <React.Fragment>
      <Space direction={direction} size={size}>
        <Text type="secondary" strong={isLabelValueStrong}>
          {labelValue}
        </Text>
        <Text style={textValueStyle} strong={isTextValueStrong}>
          {children}
        </Text>
      </Space>
    </React.Fragment>
  );
};

export const SpaceText = React.memo(SpaceTextComponent);

import React, { FC } from 'react';
import { HierarchyData } from 'types';
import { TreeNode } from 'react-organizational-chart';
import { OrganizationalHierarchyEmployeeCard } from './OrganizationalHierarchyEmployeeCard';

interface Props {
  node?: HierarchyData;
}

export const OrganizationalHierarchyNode: FC<Props> = ({ node }) => {
  return (
    <TreeNode
      key={node?.id}
      label={
        node && (
          <OrganizationalHierarchyEmployeeCard
            id={node?.adOid}
            displayName={node?.displayName}
            title={node?.title}
            email={node?.email}
          />
        )
      }
    >
      {node?.subordinates &&
        node?.subordinates?.length > 0 &&
        node?.subordinates.map((child: HierarchyData, index: number) => {
          return <OrganizationalHierarchyNode key={index} node={child} />;
        })}
    </TreeNode>
  );
};

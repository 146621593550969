import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { History } from 'history';
import { TType } from 'translations';
import { useServicesContext } from 'app';
import { Select } from 'antd';
import { TaskStatus } from '../../../types';
import { SelectWrapper } from '../TaskList/TaskList.styled';
import { FilterContextType, FilterItemProps, FilterOption } from './Filter.types';
import { Filter } from './Filter';

const { Option } = Select;

export const CATEGORY_FILTER_FIELD = 'category';
export const SUB_CATEGORY_FILTER_FIELDS = 'subCategory';
export const REQUEST_FILTER_FIELD = 'requestName';
export const ASSIGNEE_FILTER_FIELD = 'assignedTo';
export const STATUS_FILTER_FIELD = 'status';

export const EMPLOYEE_ID_FILTER_FIELD = 'employeeId';

const replaceSpace = (str = '') => str.split(' ').join('_');

const getProcessDefinitionKey = (category: string, subCategory: string, requestName: string) => {
  if (requestName) {
    return requestName;
  }
  if (subCategory) {
    return `${replaceSpace(category)}.${replaceSpace(subCategory)}`;
  }
  if (category) {
    return `${replaceSpace(category)}`;
  }
};

const getDateParams = (dates: [string, string], startName: string, endName: string) => {
  try {
    const [startDateStr, endDateStr] = dates || [];
    const startDate = moment(startDateStr);
    const endDate = moment(endDateStr);

    if (startDateStr && startDate.isValid() && endDateStr && endDate.isValid()) {
      return {
        [startName]: startDate.startOf('day').utc(true).format(),
        [endName]: endDate.endOf('day').utc(true).format(),
      };
    }
  } catch (e) {
    return {};
  }
};

interface Props {
  t: TType;
  isLoading: boolean;
  history: History;
  onValuesChange?: (field: { [key: string]: string }) => void;
  onFilterCallback: (
    filterValues: FilterContextType['filterValues'],
    selectedStatus?: TaskStatus
  ) => void;
  categoryOptions: FilterOption[] | undefined;
  subCategoryOptions: FilterOption[];
  requestNameOptions: FilterOption[];
  statusOptions?: FilterOption[];
  delegatorOptions?: FilterOption[];
  children?: React.ReactNode;
  showAssignedTo?: boolean;
  groupUsers?: FilterOption[] | undefined;
  onResetFilterCallback?: (isReset: boolean) => void;
  showStatusSelect?: boolean;
  statusSelectOptions?: TaskStatus[];
  statusSelectValue?: TaskStatus;
  defaultStatusSelectOption?: TaskStatus;
  onSelectedStatusChangeCallback?: (status: TaskStatus) => void;
}

const ServicesFilterComponent: FC<Props> = ({
  isLoading,
  t,
  history,
  onValuesChange,
  categoryOptions,
  subCategoryOptions,
  requestNameOptions,
  statusOptions,
  delegatorOptions,
  onFilterCallback,
  children,
  showAssignedTo,
  groupUsers,
  onResetFilterCallback,
  showStatusSelect,
  statusSelectOptions,
  defaultStatusSelectOption,
  statusSelectValue,
  onSelectedStatusChangeCallback,
}) => {
  const {
    setRequestInfoRedirection,
    setIsFilterByRequest,
    isFilterByRequest,
    requestInfoRedirection,
  } = useServicesContext();

  const [selectStatus, setSelectStatus] = useState<TaskStatus>(
    defaultStatusSelectOption ?? TaskStatus.inProgress
  );

  const onFilter = useCallback(
    async (params = {}) => {
      const {
        category,
        subCategory,
        requestName,
        prettyId,
        createDate,
        dueDate,
        assignedTo,
        status,
        delegationId,
        employeeId,
      } = params;

      const resParams: Record<string, string> = {
        ...getDateParams(createDate, 'createFrom', 'createTo'),
        ...getDateParams(dueDate, 'dueDateFrom', 'dueDateTo'),
      };

      let processDefinitionKey;
      const filterPDK = (processDefinitionKey = getProcessDefinitionKey(
        category,
        subCategory,
        requestName
      ));

      if (isFilterByRequest && !filterPDK) {
        processDefinitionKey = requestInfoRedirection?.PDK;
      } else {
        processDefinitionKey = filterPDK;
      }
      if (processDefinitionKey) {
        resParams.processDefinitionKey = processDefinitionKey;
      }

      if (prettyId) {
        resParams.prettyId = prettyId;
      }

      if (assignedTo) {
        resParams.assignedTo = assignedTo;
      }

      if (status) {
        resParams.finished = status;
      }
      if (delegationId) {
        resParams.delegationId = delegationId;
      }
      if (employeeId) {
        resParams.employeeId = employeeId;
      }

      onFilterCallback(resParams, selectStatus);

      setIsFilterByRequest(false);
      setRequestInfoRedirection({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFilterByRequest, selectStatus]
  );

  const filterItems: FilterItemProps[] = useMemo(
    () => {
      const delegateFilter: FilterItemProps = {
        type: 'select',
        name: 'delegationId',
        options: delegatorOptions,
        placeholder: t('task.filter.placeHolder.delegator'),
      };
      const items = [
        {
          type: 'select',
          placeholder: 'Category',
          name: CATEGORY_FILTER_FIELD,
          options: categoryOptions,
        },
        {
          type: 'rangePicker',
          name: 'createDate',
          tagLabel: t('task.filter.createDate.filterLabel'),
          placeholder: [t('task.filter.placeHolder.createOnFrom'), t('task.filter.placeHolder.to')],
        },
        {
          type: 'select',
          placeholder: 'Subcategory',
          name: SUB_CATEGORY_FILTER_FIELDS,
          options: subCategoryOptions,
        },
        {
          type: 'rangePicker',
          name: 'dueDate',
          tagLabel: t('task.filter.dueDate.filterLabel'),
          placeholder: [t('task.filter.placeHolder.dueDateFrom'), t('task.filter.placeHolder.to')],
        },
        {
          type: 'select',
          placeholder: 'Request Name',
          name: REQUEST_FILTER_FIELD,
          options: requestNameOptions,
        },
        {
          type: 'input',
          name: 'prettyId',
          placeholder: t('task.filter.placeHolder.requestId'),
        },
      ] as FilterItemProps[];

      if (delegatorOptions?.length && !items.includes(delegateFilter)) {
        items.push(delegateFilter);
      }
      return items;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoryOptions, requestNameOptions, subCategoryOptions, t, delegatorOptions]
  );

  const groupTasksFilterItems: FilterItemProps[] = useMemo(
    () => [
      ...filterItems,
      {
        type: 'lookup',
        placeholder: 'Assigned to',
        name: ASSIGNEE_FILTER_FIELD,
        options: groupUsers,
      },
      {
        type: 'input',
        placeholder: 'Employee ID',
        name: EMPLOYEE_ID_FILTER_FIELD,
      },
    ],
    [filterItems, groupUsers]
  );

  const requestsFilterItems: FilterItemProps[] = useMemo(
    () => [
      ...filterItems,
      {
        type: 'select',
        placeholder: 'Status',
        name: STATUS_FILTER_FIELD,
        options: statusOptions,
      },
    ],
    [filterItems, statusOptions]
  );

  const onReset = useCallback(
    (isReset) => {
      if (onResetFilterCallback) {
        onResetFilterCallback(isReset);
      }
    },
    [onResetFilterCallback]
  );

  useEffect(() => {
    if (statusSelectValue) {
      setSelectStatus(statusSelectValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSelectValue]);

  const onStatusSelectChange = (value: string) => {
    setSelectStatus(value as TaskStatus);
    if (onSelectedStatusChangeCallback) {
      onSelectedStatusChangeCallback(value as TaskStatus);
    }
  };

  return (
    <Filter
      items={
        showAssignedTo ? groupTasksFilterItems : statusOptions ? requestsFilterItems : filterItems
      }
      isLoading={isLoading}
      t={t}
      history={history}
      onFilterCallback={onFilter}
      onValuesChange={onValuesChange}
      onResetFilterCallback={onReset}
      enableQueryParams={false}
      dateFormat={'DD.MM.YYYY'}
    >
      {showStatusSelect && defaultStatusSelectOption && statusSelectOptions && statusSelectValue && (
        <SelectWrapper>
          <Select
            defaultValue={defaultStatusSelectOption}
            bordered={false}
            onSelect={onStatusSelectChange}
            value={selectStatus}
            showSearch
            notFoundContent={t('dropdown.noMatching')}
          >
            {statusSelectOptions.map((statusOption) => (
              <Option key={statusOption} value={statusOption}>
                {statusOption}
              </Option>
            ))}
          </Select>
        </SelectWrapper>
      )}
      {children}
    </Filter>
  );
};

export const ServicesFilter = memo(ServicesFilterComponent);

import { LeaveStatus } from 'types';

export const MyTeamLeavesData = [
  {
    id: 4625,
    absenceTypeId: '300000009258356',
    absenceTypeName: 'Training Leave',
    adOid: '3f908418-5c39-44a0-8748-5ac953db3863',
    processInstanceId: '983f48b5-5f4c-11ec-88e9-0a580a8102a2',
    duration: 1.0,
    startDate: '2021-12-06T00:00:00',
    endDate: '2021-12-06T23:59:00',
    createdDate: '2021-12-15T16:47:00.674387',
    modifiedDate: '2021-12-12T20:33:34.904',
    absenceStatusCd: LeaveStatus.submitted,
    approvalStatusCd: LeaveStatus.approved,
    absenceDispStatus: LeaveStatus.completed,
    personAbsenceEntryId: 300000063493389,
    employeeName: 'SOE Employee 1 QA',
    awaitingLineManagerApproval: false,
  },
  {
    id: 4627,
    absenceTypeId: '300000003679719',
    absenceTypeName: 'Exam Leave',
    adOid: '3f908418-5c39-44a0-8748-5ac953db3863',
    processInstanceId: '983f48b5-5f4c-11ec-88e9-0a580a8102a2',
    duration: 1.0,
    startDate: '2021-11-03T08:00:00',
    endDate: '2021-11-03T17:00:00',
    createdDate: '2021-12-15T16:47:00.910842',
    modifiedDate: '2021-11-24T10:58:12.861',
    absenceStatusCd: LeaveStatus.submitted,
    approvalStatusCd: LeaveStatus.approved,
    absenceDispStatus: LeaveStatus.completed,
    personAbsenceEntryId: 300000063281307,
    employeeName: 'SOE Employee 1 QA',
    awaitingLineManagerApproval: false,
  },
  {
    id: 4626,
    absenceTypeId: '300000009258391',
    absenceTypeName: 'Half Day Leave',
    adOid: '3f908418-5c39-44a0-8748-5ac953db3863',
    processInstanceId: '983f48b5-5f4c-11ec-88e9-0a580a8102a2',
    duration: 0.5,
    startDate: '2022-02-01T08:00:00',
    endDate: '2022-02-01T17:00:00',
    createdDate: '2021-12-15T16:47:00.792833',
    modifiedDate: '2022-02-01T17:04:52.279493',
    absenceStatusCd: 'CANCELATION_IN_PROGRESS',
    approvalStatusCd: LeaveStatus.approved,
    absenceDispStatus: LeaveStatus.completed,
    personAbsenceEntryId: 300000063294401,
    employeeName: 'SOE Employee 1 QA',
    awaitingLineManagerApproval: true,
  },
  {
    id: 4628,
    absenceTypeId: '300000003679719',
    absenceTypeName: 'Exam Leave',
    adOid: '3f908418-5c39-44a0-8748-5ac953db3863',
    processInstanceId: '983f48b5-5f4c-11ec-88e9-0a580a8102a2',
    duration: 1.0,
    startDate: '2021-11-02T08:00:00',
    endDate: '2021-11-02T17:00:00',
    createdDate: '2021-12-15T16:47:00.975859',
    modifiedDate: '2021-11-24T10:57:06.49',
    absenceStatusCd: LeaveStatus.submitted,
    approvalStatusCd: LeaveStatus.approved,
    absenceDispStatus: LeaveStatus.completed,
    personAbsenceEntryId: 300000063281223,
    employeeName: 'SOE Employee 1 QA',
    awaitingLineManagerApproval: false,
  },
  {
    id: 4635,
    absenceTypeId: '300000003679719',
    absenceTypeName: 'Exam Leave',
    adOid: '3f908418-5c39-44a0-8748-5ac953db3863',
    processInstanceId: '983f48b5-5f4c-11ec-88e9-0a580a8102a2',
    duration: 1.0,
    startDate: '2021-12-14T00:00:00',
    endDate: '2021-12-14T23:59:59',
    createdDate: '2021-12-17T18:18:31.799762',
    modifiedDate: '2022-04-24T06:00:00.187647',
    absenceStatusCd: LeaveStatus.submitted,
    approvalStatusCd: LeaveStatus.awaitingApproval,
    absenceDispStatus: LeaveStatus.completed,
    personAbsenceEntryId: 300000063523271,
    employeeName: 'SOE Employee 1 QA',
    awaitingLineManagerApproval: false,
  },
  {
    id: 4636,
    absenceTypeId: '300000009258356',
    absenceTypeName: 'Training Leave',
    adOid: '3f908418-5c39-44a0-8748-5ac953db3863',
    processInstanceId: '6c1f35b1-5f5b-11ec-88e9-0a580a8102a2',
    duration: 1.0,
    startDate: '2021-12-05T00:00:00',
    endDate: '2021-12-05T23:59:59',
    createdDate: '2021-12-17T20:04:40.224236',
    modifiedDate: '2022-04-24T06:00:00.191292',
    absenceStatusCd: LeaveStatus.submitted,
    approvalStatusCd: LeaveStatus.awaitingApproval,
    absenceDispStatus: LeaveStatus.completed,
    personAbsenceEntryId: 300000063523541,
    employeeName: 'SOE Employee 1 QA',
    awaitingLineManagerApproval: true,
  },
  {
    id: 4634,
    absenceTypeId: '300000009258356',
    absenceTypeName: 'Training Leave',
    adOid: '3f908418-5c39-44a0-8748-5ac953db3863',
    processInstanceId: 'bc9ca9e6-5e79-11ec-88e9-0a580a8102a2',
    duration: 1.0,
    startDate: '2021-12-12T00:00:00',
    endDate: '2021-12-12T23:59:59',
    createdDate: '2021-12-16T17:09:08.925754',
    modifiedDate: '2022-04-24T06:00:00.155422',
    absenceStatusCd: LeaveStatus.submitted,
    approvalStatusCd: LeaveStatus.awaitingApproval,
    absenceDispStatus: LeaveStatus.completed,
    personAbsenceEntryId: 300000063519941,
    employeeName: 'SOE Employee 1 QA',
    awaitingLineManagerApproval: false,
  },
  {
    id: 4633,
    absenceTypeId: '300000009258356',
    absenceTypeName: 'Training Leave',
    adOid: '3f908418-5c39-44a0-8748-5ac953db3863',
    processInstanceId: '983f48b5-5f4c-11ec-88e9-0a580a8102a2',
    duration: 1.0,
    startDate: '2021-07-01T00:00:00',
    endDate: '2021-07-01T23:59:00',
    createdDate: '2021-12-16T16:19:42.26072',
    modifiedDate: '2021-12-16T13:13:20.058',
    absenceStatusCd: LeaveStatus.submitted,
    approvalStatusCd: LeaveStatus.approved,
    absenceDispStatus: LeaveStatus.completed,
    personAbsenceEntryId: 300000063505191,
    employeeName: 'SOE Employee 1 QA',
    awaitingLineManagerApproval: false,
  },
  {
    id: 4639,
    absenceTypeId: '300000009258356',
    absenceTypeName: 'Training Leave',
    adOid: '3f908418-5c39-44a0-8748-5ac953db3863',
    processInstanceId: '5461f6d8-60a1-11ec-b60c-0a580a830242',
    duration: 1.0,
    startDate: '2021-12-15T00:00:00',
    endDate: '2021-12-15T23:59:59',
    createdDate: '2021-12-19T10:57:36.307049',
    modifiedDate: '2022-04-24T06:00:00.194749',
    absenceStatusCd: LeaveStatus.submitted,
    approvalStatusCd: LeaveStatus.awaitingApproval,
    absenceDispStatus: LeaveStatus.completed,
    personAbsenceEntryId: 300000063538480,
    employeeName: 'SOE Employee 1 QA',
    awaitingLineManagerApproval: false,
  },
];

export const MyteamLeaveInfo = {
  id: 4799,
  startDate: '2022-06-07T00:00:00',
  endDate: '2022-06-09T23:59:59',
  createdOn: '2022-02-07T17:52:15.724257',
  adOid: 'd8d05175-a0b5-4003-87c9-b118f158de64',
  status: LeaveStatus.awaitingApproval,
  details: 'Sick Leave, waiting for approval',
  reason: null,
  duration: 3.0,
  prettyId: 'SR020370',
  awaitingLineManagerApproval: true,
  attachments: [
    {
      createTime: '2022-05-11T14:35:41.664+00:00',
      description: 'comments (84).jpg',
      fileSize: '359091',
      id: 'a2382ee8-d137-11ec-89e3-0a580a810239',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      links: [] as any,
      name: 'comments (84).jpg',
      taskId: 'a110025b-d137-11ec-89e3-0a580a810239',
      type: 'image/jpeg',
      removalTime: null,
      rootProcessInstanceId: null,
      url: null,
    },
  ],
  comments: [
    {
      attachments: [
        {
          commentId: 'cd994427-d138-11ec-89e3-0a580a810239',
          fileSize: 359091,
          id: 'cd994427-d138-11ec-89e3-0a580a81023',
          mediaType: 'image/jpeg',
          name: 'comments (84).jpg',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          links: [] as any,
          taskId: 'a110025b-d137-11ec-89e3-0a580a810239',
          type: 'image/jpeg',
          removalTime: null,
          rootProcessInstanceId: null,
          url: null,
          description: '',
          createTime: '2022-05-11T14:44:03.938+00:00',
        },
      ],
      id: 'cd994427-d138-11ec-89e3-0a580a810239',
      message: 'Hello world',
      removalTime: null,
      rootProcessInstanceId: 'a10fdb48-d137-11ec-89e3-0a580a810239',
      taskId: 'a117f1d5-d137-11ec-89e3-0a580a810239',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      links: [] as any,
      time: '2022-05-11T14:44:03.938+00:00',
      userId: '3f908418-5c39-44a0-8748-5ac953db3863',
    },
  ],
};

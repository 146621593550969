import React, { FC, Fragment, useCallback, useState, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { DirectionType } from 'antd/lib/config-provider';
import { TranslationsContext } from './translationsContext';
import { Translations, TranslationsProviderProps } from './translationsTypes';

export const TranslationsProvider: FC<TranslationsProviderProps> = ({ children, messages }) => {
  const [pageDirection, setPageDirection] = useState<DirectionType>();
  const [language, setLanguageState] = useState(Translations.en);

  const setLanguage = useCallback((language: Translations) => {
    setLanguageState(language);
    if (language === Translations.ar) {
      setPageDirection('rtl');
    } else {
      setPageDirection('ltr');
    }
  }, []);

  const value = useMemo(() => ({ language, setLanguage, pageDirection }), [
    language,
    setLanguage,
    pageDirection,
  ]);

  return (
    <TranslationsContext.Provider value={value}>
      <ConfigProvider direction={pageDirection}>
        <IntlProvider
          key={language}
          locale={language}
          textComponent={Fragment}
          messages={messages[language]}
        >
          {children}
        </IntlProvider>
      </ConfigProvider>
    </TranslationsContext.Provider>
  );
};

import { UserGruopData } from 'types';
import { Core, USER_GROUP } from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetUserGroup {
  (prams: AxiosRequestConfig['params']): Promise<{ data: UserGruopData }>;
}

class UserGroup extends Core {
  getUserGroup: GetUserGroup = async (params) => this.get(USER_GROUP(), params);
}
export const userGroup = new UserGroup();

import { Col, notification, Popover, Row } from 'antd';
import { useAddMemberToGroup } from 'api';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslate } from 'translations';
import {
  StyledButton,
  StyledCheckIcon,
  StyledPopOver,
  StyledReassignTitle,
} from 'ui/components/TaskList/ClaimTask/ClaimTask.styled';
import { SearchUser } from 'ui/components/TaskList/TaskSteps/ReassignTask/SearchUser';

interface Props {
  children: React.ReactElement;
  groupId: string;
  refetchList: () => void;
}

export const AddUserToGroupComponent: FC<Props> = ({ children, refetchList, groupId }) => {
  const { t } = useTranslate();
  const addUser = useAddMemberToGroup();
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const onClose = useCallback((e) => {
    e.stopPropagation();
    setIsVisible(false);
  }, []);

  const onClickOnAddUser = useCallback((e) => {
    e.stopPropagation();
    setIsVisible(true);
  }, []);

  const getCurrentSearchUserId = useCallback((reassignUserId: string) => {
    if (!reassignUserId) {
      return;
    } else {
      setSelectedUserId(reassignUserId);
    }
  }, []);

  const addUserToGroup = useCallback(() => {
    if (selectedUserId) {
      const Body = {
        groupId: groupId,
        userId: selectedUserId,
      };
      addUser.mutate(Body);
    }
  }, [addUser, groupId, selectedUserId]);

  useEffect(() => {
    if (addUser.isSuccess) {
      notification.success({
        message: t('processManagement.addUser.success.title'),
        description: t('processManagement.addUser.success.message'),
      });
      setIsVisible(false);
      refetchList();
    }
    if (addUser.isError) {
      notification.error({
        message: t('processManagement.addUser.error.title'),
        description: t('processManagement.addUser.error.message'),
      });
    }
  }, [addUser.isSuccess, addUser.isError, refetchList, t]);

  const content = (
    <StyledPopOver>
      <Row align="middle" gutter={[10, 15]}>
        <Col>
          <StyledCheckIcon />
        </Col>
        <Col flex="auto">
          <StyledReassignTitle>{t('processManagement.addUser.success.title')}</StyledReassignTitle>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 5]}>
        <Col flex="auto">
          <StyledReassignTitle>{t('task.reassignTask.chooseUser')}</StyledReassignTitle>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 15]}>
        <Col flex="auto">
          <SearchUser currentSearchUserId={getCurrentSearchUserId} />
        </Col>
      </Row>
      <Row justify="end" gutter={[5, 5]}>
        <Col>
          <StyledButton onClick={onClose}>{t('no')}</StyledButton>
        </Col>
        <Col>
          <StyledButton type={'primary'} loading={addUser.isLoading} onClick={addUserToGroup}>
            {t('yes')}
          </StyledButton>
        </Col>
      </Row>
    </StyledPopOver>
  );
  return (
    <div onClick={onClickOnAddUser}>
      <Popover placement="topRight" trigger={'click'} content={content} visible={isVisible}>
        <span>{children}</span>
      </Popover>
    </div>
  );
};

export const AddUserToGroup = memo(AddUserToGroupComponent);

import { ProcessDefinitionData, ProcessDefinitionListData } from 'types';
import { Core, PROCESS_DEFINITION, PROCESS_DEFINITION_LIST } from 'api';
import { AxiosRequestConfig } from 'axios';

interface PostProcessDefinition {
  (processDefinitionKey: string): Promise<{ data: ProcessDefinitionData }>;
}

interface GetProcessDefinitionList {
  (): Promise<{ data: ProcessDefinitionListData[] }>;
}

interface PostProcessDefinitionForCancel {
  (processDefinitionKey: string, data: AxiosRequestConfig['data']): Promise<{
    data: ProcessDefinitionData;
  }>;
}

class ProcessDefinition extends Core {
  postProcessDefinition: PostProcessDefinition = async (processDefinitionKey) =>
    this.post(PROCESS_DEFINITION(processDefinitionKey), { businessKey: 'myBusinessKey' });

  getProcessDefinitonList: GetProcessDefinitionList = async () =>
    this.get(PROCESS_DEFINITION_LIST());

  postProcessDefinitionForCancel: PostProcessDefinitionForCancel = async (
    processDefinitionKey,
    data
  ) => this.post(PROCESS_DEFINITION(processDefinitionKey), data);
}

export const processDefinition = new ProcessDefinition();

import { useCallback } from 'react';
import { createBreakpoint } from 'react-use';

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const useBreakpointType = createBreakpoint(breakpoints) as () => keyof typeof breakpoints;

export function useBreakpoint() {
  const currentBreakpoint = useBreakpointType();
  const down = useCallback(
    (breakpoint: typeof currentBreakpoint) => {
      switch (breakpoint) {
        case 'xs':
          return false;
        case 'sm':
          return currentBreakpoint === 'xs';
        case 'md':
          return currentBreakpoint === 'xs' || currentBreakpoint === 'sm';
        case 'lg':
          return ['xs', 'sm', 'md'].includes(currentBreakpoint);
        case 'xl':
          return currentBreakpoint !== 'xl';
        default:
          throw new Error('Unknown breakpoint');
      }
    },
    [currentBreakpoint]
  );

  return { down };
}

import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { FORM_FILED_DETAIL_BREAKPOINT } from 'routes/Tasks/components/templates';
import { useTranslate } from 'translations';
import { TaskDescriptionIcon } from 'assets';
import { TaskItemField } from './Components';
import {
  InfoText,
  LabelText,
  TaskDescription,
  TaskDescriptionDetails,
  TaskDescriptionText,
} from './TaskFields.styled';
import { ErrorPattern, TaskDescriptionPattern, TaskFieldProps } from './TaskFieldsTypes';

export const TaskFieldDescription: FC<{ label?: string; value: TaskFieldProps['fieldValue'] }> = ({
  value,
  label,
}) => (
  <TaskDescription>
    <TaskDescriptionIcon />
    <TaskDescriptionDetails>
      {label && <LabelText strong>{label}</LabelText>}
      <TaskDescriptionText>{value}</TaskDescriptionText>
    </TaskDescriptionDetails>
  </TaskDescription>
);

export const TaskFields: FC<TaskFieldProps> = ({ fieldId, fieldValue, typeName, label }) => {
  const { t } = useTranslate();
  if (fieldId?.match(TaskDescriptionPattern)?.length) {
    return (
      <Col span={24} key={fieldId}>
        <TaskFieldDescription value={fieldValue} label={label} />
      </Col>
    );
  }
  if (
    fieldId === 'requesterId' ||
    fieldId === 'requestDescription' ||
    typeName === 'button' ||
    fieldValue === undefined ||
    fieldValue === ''
  ) {
    return null;
  }

  if (typeName === 'string') {
    return (
      <Col {...FORM_FILED_DETAIL_BREAKPOINT} key={fieldId}>
        <Row>
          <LabelText>{label}</LabelText>
        </Row>
        <Row>
          <InfoText strong={true}>{fieldValue}</InfoText>
        </Row>
      </Col>
    );
  }
  if (typeName === 'boolean') {
    return (
      <Col {...FORM_FILED_DETAIL_BREAKPOINT} key={fieldId}>
        <Row>
          <LabelText>{label}</LabelText>
        </Row>
        <Row>
          <InfoText strong={true}>{fieldValue ? t('yes') : t('no')}</InfoText>
        </Row>
      </Col>
    );
  }
  if (typeName === 'checkbox') {
    return (
      <Col {...FORM_FILED_DETAIL_BREAKPOINT} key={fieldId}>
        <Row>
          <LabelText>{label}</LabelText>
        </Row>
        <Row>
          <InfoText strong={true}>{fieldValue ? t('yes') : t('no')}</InfoText>
        </Row>
      </Col>
    );
  }
  if (typeName === 'boolean' || typeName === 'radioButton') {
    return (
      <Col {...FORM_FILED_DETAIL_BREAKPOINT} key={fieldId}>
        <Row>
          <LabelText>{label}</LabelText>
        </Row>
        <Row>
          <InfoText strong={true}>{fieldValue ? t('yes') : t('no')}</InfoText>
        </Row>
      </Col>
    );
  }

  if (fieldId.match(ErrorPattern)?.length) {
    return (
      <Col span={24} key={fieldId}>
        <Row>
          <LabelText>{label}</LabelText>
        </Row>
        <Row>
          <InfoText strong={true}>{fieldValue}</InfoText>
        </Row>
      </Col>
    );
  }

  if (typeName === 'item' && typeof fieldValue !== 'string' && typeof fieldValue !== 'boolean') {
    return <TaskItemField fieldValue={fieldValue} />;
  }

  return (
    <Col {...FORM_FILED_DETAIL_BREAKPOINT} key={fieldId}>
      <Row>
        <LabelText>{label}</LabelText>
      </Row>
      <Row>
        <InfoText strong={true}>{fieldValue}</InfoText>
      </Row>
    </Col>
  );
};

import moment from 'moment';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslate } from 'translations';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Select } from 'antd';
import { OptionData, OptionGroupData, OptionsType } from 'rc-select/lib/interface';
import { SelectValue } from 'antd/lib/select';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { colors } from 'ui/elements';
import {
  ColorCode,
  convertColourNameToHex,
  getStartAndEndDate,
  CalendarHeaderDate,
  CalendarToolbar,
  CalendarEvent,
} from 'routes/Calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { StyledTeamListSelect } from 'routes/Calendar/Components/CalendarView/CalendarStyle';
import { FilterOption, SelectOption } from '../Filter/Filter.types';

export interface EventList {
  key: number;
  title: string;
  start: string;
  end: string;
  status: string;
  isPublicHoliday: boolean;
  isAwaitingForApproval?: boolean;
  employeeName?: string;
}
export interface CalendarProps {
  setCurrentLeaveId: (leaveId: string) => void;
  refetchList?: (startDate: string, endDate: string) => void;
  eventList?: EventList[];
  isMyTeamLeaves?: boolean;
  options?: FilterOption[];
  getTeamLeaves?: (ids: string) => void;
}
const colorLightening = '27';

const CalendarComponent: FC<CalendarProps> = ({
  setCurrentLeaveId,
  refetchList,
  eventList,
  isMyTeamLeaves,
  options,
  getTeamLeaves,
}) => {
  const localizer = momentLocalizer(moment);
  const [selectedMonth, setSelectedMonth] = useState<string>();
  const [totalSelectedMembers, setTotalSelectedMembers] = useState<number>(0);
  const dispatch = useDispatch();
  const { t } = useTranslate();

  useEffect(() => {
    if (!selectedMonth) {
      const currentDate = new Date();
      const fullMonth = getStartAndEndDate(currentDate);
      setSelectedMonth(fullMonth);
    }
  }, [selectedMonth]);

  useEffect(() => {
    if (selectedMonth) {
      refetchList && refetchList(selectedMonth, selectedMonth);
    }
  }, [dispatch, selectedMonth, refetchList]);

  const onNavigate = useCallback((date: Date) => {
    const fullMonth = getStartAndEndDate(date);

    setSelectedMonth(fullMonth);
  }, []);

  const handleChange = useCallback(
    (_: SelectValue, options: OptionData | OptionGroupData | OptionsType) => {
      const ids = options?.map((item: SelectOption) => item.key);
      setTotalSelectedMembers(ids.length);
      if (getTeamLeaves && ids) {
        getTeamLeaves(ids.toString());
      }
    },
    [getTeamLeaves]
  );

  return (
    <React.Fragment>
      {isMyTeamLeaves && (
        <StyledTeamListSelect
          mode={'multiple'}
          placeholder={t('leaves.filter.teamMember')}
          optionLabelProp="label"
          showArrow
          suffixIcon={<SearchOutlined />}
          onChange={handleChange}
          notFoundContent={t('dropdown.noMatching')}
          showSearch
        >
          {options?.map(({ value, label, text }) => (
            <Select.Option key={value} value={label as string}>
              {text}
            </Select.Option>
          ))}
        </StyledTeamListSelect>
      )}
      <Calendar
        className={'LeaveCalender'}
        localizer={localizer}
        events={eventList}
        views={['month']}
        onNavigate={onNavigate}
        startAccessor="start"
        endAccessor="end"
        popup
        style={{ height: '700px', width: '100%' }}
        components={{
          header: CalendarHeaderDate,
          toolbar: (props) => (
            <CalendarToolbar {...props} totalSelectedMembers={totalSelectedMembers} />
          ),
          event: CalendarEvent,
        }}
        eventPropGetter={(event) => {
          const backgroundColor = convertColourNameToHex(event.status as ColorCode);

          if (event.isPublicHoliday) {
            return {
              style: {
                backgroundColor: backgroundColor + colorLightening,
                color: 'black',
                marginBottom: '8px',
                borderLeftColor: 'red',
                borderRadius: '3px',
                height: 'auto',
              },
            };
          } else {
            return {
              style: {
                backgroundColor: backgroundColor + colorLightening,
                color: 'black',
                marginBottom: '8px',
                borderLeftColor: 'red',
                borderRadius: '3px',
                height: 'auto',
                borderLeft: `5px solid ${backgroundColor}`,
              },
            };
          }
        }}
        dayPropGetter={(date) => {
          const weekend = date.getDay() === 5 || date.getDay() === 6;
          const currentDate = new Date();
          const toDay = currentDate.toDateString() === date.toDateString();

          return {
            style: {
              backgroundColor: weekend
                ? colors.weekendBackground
                : toDay
                ? colors.toDayCalendar
                : colors.white,
              borderTop: toDay ? `solid 3.5px ${colors.skySecondary}` : `hidden`,
            },
          };
        }}
        onSelectEvent={(event) => {
          !event.isPublicHoliday && setCurrentLeaveId(event?.key?.toString());
        }}
      />
    </React.Fragment>
  );
};
export const CalendarUI = memo(CalendarComponent);

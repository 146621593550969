import React, { FC } from 'react';
import { Form, Row } from 'antd';
import { FormField } from 'types';
import { StyledFieldLabel, StyledInputNumber } from '../GeneratedForm.Styled';

interface Props {
  field: FormField;
  withLabel?: boolean;
}

export const FieldDecimal: FC<Props> = ({ field, withLabel }) => {
  const fieldIsDisabled = field.properties.viewOnly === 'true';
  const fieldIsRequired = field.validationConstraints.some((rule) => rule.name === 'required');
  const fieldMaxNumber = field.properties.maxValue ?? field.properties.maxNumber;
  const fieldMark = field.properties.showMark;

  return (
    <>
      {withLabel && (
        <Row>
          <StyledFieldLabel>{field.label}</StyledFieldLabel>
        </Row>
      )}
      <Form.Item
        rules={[
          {
            required: fieldIsRequired,
            message: `${field.label} is required`,
          },
          { type: 'number', max: Number(fieldMaxNumber) },
        ]}
        label={withLabel ? null : field.label}
        name={field.id}
        wrapperCol={{ span: 21 }}
      >
        <StyledInputNumber
          value={
            fieldIsDisabled && fieldIsRequired
              ? field.defaultValue
                ? +field.defaultValue
                : typeof field.value.value === 'number'
                ? field.value.value
                : undefined
              : undefined
          }
          style={{ width: '100%' }}
          disabled={fieldIsDisabled}
          autoComplete="off"
          min={0}
          formatter={
            fieldMark
              ? (value) => `${value} ${fieldMark}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : undefined
          }
        />
      </Form.Item>
    </>
  );
};

import { useQuery } from 'react-query';
import moment from 'moment/moment';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { QUERIES } from 'api';
import { useTranslate, TPrefixedType, TType } from 'translations';
import {
  CompletedSiteAcquisitionAssignWoTask,
  CompletedSiteAcquisitionRequestTask,
  CompletedTaskFormFields,
  TaskDefinitionKey,
  TaskDefinitionType,
} from 'types';
import { useErrorDescription } from '../../../hooks';
import { task } from '../Task';

const mapCompletedSiteAcquisitionAssignWoTaskToCompletedTaskFormFields = (
  data: CompletedSiteAcquisitionAssignWoTask
): CompletedTaskFormFields => {
  const { requestDetails } = data;
  return {
    ...data,
    taskFormDetails: [
      {
        id: 'taskDescription',
        label: '',
        typeName: 'text',
        value: requestDetails.taskDescription,
      },
      {
        id: 'assigneeType',
        label: 'Assignee type',
        typeName: 'string',
        value: requestDetails.assigneeType,
      },
      {
        id: 'woNumber',
        label: 'WO number',
        typeName: 'string',
        value: requestDetails.woNumber || '',
      },
      {
        id: 'subContractor',
        label: 'Subcontractor',
        typeName: 'string',
        value: requestDetails.subContractor || '',
      },
      {
        id: 'feedback',
        label: 'Feedback',
        typeName: 'string',
        value: requestDetails.feedback || '',
      },
      {
        id: 'submit',
        label: 'Submit',
        typeName: 'button',
        value: false,
      },
    ],
  };
};

const mapSiteAcquisitionRequestTaskToCompletedTaskFormFields = (
  data: CompletedSiteAcquisitionRequestTask,
  t: TType,
  getPrefixedT: TPrefixedType
): CompletedTaskFormFields => {
  const pt = getPrefixedT('initiateBtsProcess.form.label');
  const { requestDetails } = data;
  return {
    ...data,
    taskFormDetails: [
      {
        id: 'customerSiteID',
        label: pt('customerSiteId'),
        typeName: 'string',
        value: requestDetails.customerSiteID,
      },
      {
        id: 'customerSiteID',
        label: pt('requestingCustomer'),
        typeName: 'string',
        value: requestDetails.requestingCustomerName,
      },
      {
        id: 'agreementNumber',
        label: pt('customerCommercialAgreement'),
        typeName: 'string',
        value: requestDetails.agreementNumber,
      },
      {
        id: 'project',
        label: pt('projectName'),
        typeName: 'string',
        value: requestDetails.project,
      },
      {
        id: 'bssRequestNumber',
        label: pt('bssRequestNumber'),
        typeName: 'string',
        value: requestDetails.bssRequestNumber,
      },
      {
        id: 'customerSiteID',
        label: pt('vipRequest'),
        typeName: 'boolean',
        value: requestDetails.vipRequest,
      },
      {
        id: 'requestTimestamp',
        label: pt('timestamp'),
        typeName: 'string',
        value: moment(requestDetails.requestTimestamp).format('DD.MM.YYYY HH:mm'),
      },
      {
        id: 'region',
        label: pt('region'),
        typeName: 'string',
        value: requestDetails.region,
      },
      {
        id: 'district',
        label: pt('district'),
        typeName: 'string',
        value: requestDetails.district,
      },
      {
        id: 'city',
        label: pt('city'),
        typeName: 'string',
        value: requestDetails.city,
      },
      {
        id: 'geoTypeLocationCluster',
        label: pt('geoType'),
        typeName: 'string',
        value: requestDetails.geoTypeLocationCluster,
      },
      {
        id: 'geoTypeLocationCluster',
        label: pt('searchRingRadius'),
        typeName: 'string',
        value: requestDetails.radius,
      },
      {
        id: 'longitude',
        label: pt('nominalLongitude'),
        typeName: 'string',
        value: requestDetails.longitude,
      },
      {
        id: 'latitude',
        label: pt('nominalLatitude'),
        typeName: 'string',
        value: requestDetails.latitude,
      },
      {
        id: 'dpTowerHeight',
        label: pt('dpTowerHeight'),
        typeName: 'string',
        value: requestDetails.dpProposedTowerHeight,
      },
      {
        id: 'expectedCollocationNumber',
        label: pt('numberOfCollocation'),
        typeName: 'string',
        value: requestDetails.expectedCollocationNumber,
      },
      {
        id: 'expectedCollocationNumber',
        label: pt('potentialCustomer'),
        typeName: 'string',
        value: requestDetails.potentialCustomer,
      },
      {
        id: 'expectedPackages',
        label: pt('expectedPackages'),
        typeName: 'string',
        value: requestDetails.expectedPackages,
      },
      {
        id: 'forecastDate',
        label: pt('forecastDate'),
        typeName: 'string',
        value: moment(requestDetails.forecastDate).format('DD.MM.YYYY HH:mm'),
      },
      {
        id: 'priority',
        label: pt('requestPriority'),
        typeName: 'string',
        value: requestDetails.priority,
      },
    ],
  };
};

export function useCompletedTaskFormFields(
  taskId: string,
  queryParams: {
    processDefinitionId: string;
    taskDefinitionKey: TaskDefinitionKey;
    taskActivityInstanceId: string;
    processInstanceId: string;
  },
  isOpen: boolean,
  rootPath?: string
) {
  const { getPrefixedT, t } = useTranslate();
  const errorDescription = useErrorDescription(t('messages.error.wentWrongReload'));

  return useQuery(
    [QUERIES.completedTaskFormFields, taskId, queryParams, isOpen],
    async () => {
      if (
        queryParams?.taskDefinitionKey === TaskDefinitionType.SITE_ACQUISITION_REQUEST_FORM_TASK
      ) {
        const { data } = await task.getSiteAcquisitionRequestTask(taskId);
        return mapSiteAcquisitionRequestTaskToCompletedTaskFormFields(data, t, getPrefixedT);
      }

      if (queryParams?.taskDefinitionKey === TaskDefinitionType.ASSIGN_WO_FIELD_ASSIGNMENT_TASK) {
        const { data } = await task.getSiteAcquisitionRequestAssignWoTask(taskId);
        return mapCompletedSiteAcquisitionAssignWoTaskToCompletedTaskFormFields(data);
      }

      const res = await task.getCompletedTaskFormFields(taskId, queryParams, rootPath);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: isOpen,
      onError: (error: AxiosError) => {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: errorDescription(error),
        });
      },
    }
  );
}

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  GetProfileFail,
  GetProfileSuccess,
  GetProfileStart,
  getProfileStart,
  getProfileSuccess,
  getProfileFail,
} from './profileSlice';

export const getProfileEpic: Epic<
  GetProfileStart,
  GetProfileSuccess | GetProfileFail,
  RootState,
  typeof API
> = (action$, _, { profile }) =>
  action$.pipe(
    filter(getProfileStart.match),
    exhaustMap(() =>
      from(profile.getProfile()).pipe(
        map(({ data }) => getProfileSuccess(data)),
        catchError((error) => of(getProfileFail(error?.response?.data)))
      )
    )
  );

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const allNotificationUpdatesList = (
  state: RootState
): RootState['allNotificationUpdatesList'] => state.allNotificationUpdatesList;

export const allNotificationUpdatesListSelector = createSelector(
  allNotificationUpdatesList,
  ({ error, data, status }) => ({
    error,
    status,
    data,
  })
);

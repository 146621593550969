import React, { FC, memo } from 'react';
import { Col, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { TType } from 'translations';
import { KiloText, RemoveItemWrapper, UnoText } from './ListItem.Style';

export interface Props {
  t: TType;
  subtitle: string;
  onRemove?: () => void;
}

export const ItemFormSubtitleComponent: FC<Props> = ({ t, subtitle, onRemove }) => (
  <Row justify="space-between">
    <Col>
      <KiloText>{subtitle}</KiloText>
    </Col>
    <Col>
      {onRemove && (
        <RemoveItemWrapper onClick={onRemove}>
          <UnoText type="secondary">
            <DeleteOutlined /> {t('processManagement.groups.drawer.remove')}
          </UnoText>
        </RemoveItemWrapper>
      )}
    </Col>
  </Row>
);

export const ItemFormSubtitle = memo(ItemFormSubtitleComponent);

import { Core, CREATE_NEW_FOLDER_IN_GROUP } from 'api';
import { OneDriveFolder } from 'types';

interface CreateNewFolderInOneDriveSharedLibrary {
  (groupId: string, parentFolderId: string, folderName: string): Promise<{ data: OneDriveFolder }>;
}

class CreateNewFolder extends Core {
  createNewFolderInOneDriveSharedLibrary: CreateNewFolderInOneDriveSharedLibrary = async (
    groupId,
    parentFolderId,
    folderName
  ) => this.post(CREATE_NEW_FOLDER_IN_GROUP(groupId, parentFolderId, folderName));
}

export const createNewFolder = new CreateNewFolder();

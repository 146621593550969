import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from 'api/constants';
import { newProfile } from '../NewProfile';

export const useProfileOnGoingRequest = () =>
  useQuery(
    [QUERIES.profileOnGoingRequest],
    async () => {
      const { data } = await newProfile.getProfileOnGoingRequest();
      return data;
    },
    {
      ...PERSIST_QUERY_CONFIG,
    }
  );

import { useQuery } from 'react-query';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';

import { dashboardApps } from '../DashboardApps';

export function useDashboardApps() {
  return useQuery(
    [QUERIES.dashboardApps],
    async () => {
      const { data } = await dashboardApps.getDasboardApps();
      return data;
    },
    PERSIST_QUERY_CONFIG
  );
}

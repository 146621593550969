import { Checkbox, Form, Row } from 'antd';
import React, { FC, useMemo } from 'react';
import { FormField } from 'types';
import { useGetFromFieldId } from 'ui/hooks';
import { StyledFieldLabel } from '../GeneratedForm.Styled';

interface Props {
  field: FormField;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldsValue: any;
  isBoolean: boolean;
  withLabel?: boolean;
}

export const FieldCheckbox: FC<Props> = ({ field, setFieldsValue, isBoolean, withLabel }) => {
  const options = useMemo(
    () =>
      field.type?.values
        ? Object.entries(field.type?.values).map(([value, label]) => ({ value, label }))
        : [],
    [field]
  );

  const rules = useMemo(
    () => [
      {
        required: field.validationConstraints.some((rule) => rule.name === 'required'),
        message: `${field.label} is required`,
      },
    ],
    [field.label, field.validationConstraints]
  );

  const getFieldId = useGetFromFieldId();
  const fieldId = getFieldId(field.id);

  return (
    <>
      {withLabel && (
        <Row>
          <StyledFieldLabel>{field.label}</StyledFieldLabel>
        </Row>
      )}
      <Form.Item
        label={withLabel ? null : field.label}
        name={field.id}
        wrapperCol={{ span: 8 }}
        rules={rules}
      >
        {isBoolean ? (
          <Checkbox
            disabled={field.properties.viewOnly === 'true'}
            onChange={(e) => setFieldsValue({ [fieldId]: e.target.checked })}
          />
        ) : (
          <Checkbox.Group
            options={options}
            disabled={field.properties.viewOnly === 'true'}
            onChange={(e) => setFieldsValue({ [fieldId]: e })}
          />
        )}
      </Form.Item>
    </>
  );
};

import { notification } from 'antd';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useTranslate } from 'translations';
import { saveAs } from 'file-saver';
import { leaseSites } from '../LeaseSites';
import { useErrorDescription } from '../../hooks';

export const useLeaseTaskAttachmentMutation = ({
  fileName,
  taskId,
}: {
  taskId: string;
  fileName: string;
}) => {
  const { t } = useTranslate();
  const errorDescription = useErrorDescription(t('messages.error.tryAgainLater'));

  return useMutation(
    async (attachmentId: string) =>
      (
        await leaseSites.getTaskAttachment({
          taskId,
          attachmentId,
        })
      ).data,
    {
      onError: (error: AxiosError) => {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: errorDescription(error),
        });
      },
      onSuccess(data) {
        saveAs(data, fileName);
      },
    }
  );
};

import React, { FC, useState } from 'react';
import { Row, Col, Skeleton, Empty } from 'antd';
import { useTranslate } from 'translations';
import moment from 'moment';
import { span } from 'ui/elements';
import { PageHeader } from 'routes/Calendar/page/Leave/Leaves.styled';
import { AttendanceListView, TeamMember } from 'routes/Attendance/Components';
import { DATE_FORMAT, MONTH_YEAR_FORMAT } from 'routes/Attendance/Components/helper';
import { StyledCardWrapper } from 'routes/Attendance/Components/TeamMember/TeamMember.styled';
import { StyledCard as StyledWrapperCard } from 'ui/components/TaskList/TaskCard/TaskCard.styled';
import { StyledCard } from 'layouts/TasksLayout/TasksLayout.styled';
import { useMyTeamAttendance } from 'api';
import { disableFutureDate } from 'routes/Attendance/constants';
import { useGetAttendanceList } from 'ui/hooks/useGetAttendanceList';
import { StyledDatePicker } from '../MyAttendance/MyAttendance.styled';

export const MyTeamAttendance: FC = () => {
  const { t } = useTranslate();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>();
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
  const [hcmPersonId, setHcmPersonId] = useState<string>('');

  const { span6, span18 } = span;

  const getSelectedCard = (index: number) => {
    setActiveIndex(index);
  };

  const {
    isLoading,
    isRefetching,
    refetch,
    data,
    isError: isErrorListMember,
  } = useMyTeamAttendance(selectedDate.format(DATE_FORMAT));
  const {
    isError,
    isTableLoading,
    getCurrentMonth,
    getNextMonth,
    getPriviesMonth,
    totalResult,
    TableCurrentDate,
    data: attendanceData,
    hideNextMonthBTN,
  } = useGetAttendanceList(hcmPersonId, selectedDate);

  const handleSelectNewDate = React.useCallback((newDate) => {
    if (newDate) {
      setSelectedDate(newDate);
      setActiveIndex(undefined);
    } else {
      setSelectedDate(moment());
    }
    setCurrentPage(0);
  }, []);

  React.useEffect(() => {
    if (selectedDate) {
      refetch();
    }
  }, [refetch, selectedDate]);

  return (
    <>
      <PageHeader size="small">
        <Row justify="space-between" align="middle">
          <Col>
            <StyledDatePicker
              placeholder={t('attendance.listView.datePicker.placeholder')}
              defaultValue={moment()}
              onChange={handleSelectNewDate}
              disabledDate={disableFutureDate}
              disabled={isLoading || isTableLoading || !data || !data?.length}
            />
          </Col>
        </Row>
      </PageHeader>
      <Row justify="space-between">
        <Col span={span6}>
          <StyledCard>
            {isLoading || isRefetching ? (
              <Skeleton avatar paragraph={{ rows: 2 }} />
            ) : isErrorListMember ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('messages.error.wentWrong')}
              />
            ) : !data || !data?.length ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('myTeamAttendance.noReportees')}
              />
            ) : (
              data &&
              data.map(({ firstName, lastName, email, adOid, attendanceDetail, hcmId }, index) => (
                <StyledCardWrapper
                  onClick={() => {
                    getSelectedCard(index);
                    setHcmPersonId(hcmId);
                  }}
                  key={index}
                >
                  <StyledWrapperCard $highlighted={activeIndex === index}>
                    <TeamMember
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                      adOid={adOid}
                      attendanceDetail={attendanceDetail}
                      t={t}
                    />
                  </StyledWrapperCard>
                </StyledCardWrapper>
              ))
            )}
          </StyledCard>
        </Col>
        {data && data?.length > 0 && (
          <Col span={span18}>
            <AttendanceListView
              attendanceListData={attendanceData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              tableHeader={TableCurrentDate.format(MONTH_YEAR_FORMAT)}
              isMyTeamAttendance={true}
              isSelected={activeIndex !== undefined}
              isError={isError}
              isLoading={isTableLoading}
              getCurrentMonth={getCurrentMonth}
              getNextMonth={getNextMonth}
              getPriviseMonth={getPriviesMonth}
              totalResult={totalResult}
              isNextButtonDisable={hideNextMonthBTN}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

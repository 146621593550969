import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetExportAttendanceFail,
  GetExportAttendanceStart,
  GetExportAttendanceSuccess,
  getExportAttendanceStart,
  getExportAttendanceSuccess,
  getExportAttendanceFail,
} from './exportAttendanceSlice';

export const exportAttendanceEpic: Epic<
  GetExportAttendanceStart,
  GetExportAttendanceSuccess | GetExportAttendanceFail,
  RootState,
  typeof API
> = (action$, _, { exportAttendance }) =>
  action$.pipe(
    filter(getExportAttendanceStart.match),
    exhaustMap(({ payload }) =>
      from(exportAttendance.getExportAttendance(payload.hcmPersonId, payload.data)).pipe(
        map(({ data }) => {
          return getExportAttendanceSuccess(data);
        }),
        catchError((error) => of(getExportAttendanceFail(error?.response?.data)))
      )
    )
  );

import { OneDrivePageRoutes } from 'namespace';
import React, { FC, useEffect } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { RootState, useDispatch, useSelector } from 'store';
import {
  getOneDriveSharedLibrariesStart,
  oneDriveSharedLibrariesData,
} from 'store/oneDriveSharedLibraries';
import { RequestStatus } from 'types';
import { getAvatarSymbols } from 'utils';
import { StyledLibAvatar, StyledMenuLibraries, StyledMenuItem } from '../OneDrive.styled';
import { useOneDriveContext } from '../OneDriveContextProvider';

export interface Props {
  groupId: string;
}

export const OneDriveSharedLibraryRow: FC<Props> = ({ groupId }) => {
  const route = useRouteMatch();
  const match = useRouteMatch<{ libraryId: string; libraryName: string }>(
    `${route.path}${OneDrivePageRoutes.SharedLibraries}/:libraryId`
  );
  const location = useLocation();
  const { setCurrentFolderLink } = useOneDriveContext();

  const dispatch = useDispatch();
  const librariesData = useSelector((state: RootState) =>
    oneDriveSharedLibrariesData(state, { groupId })
  );

  useEffect(() => {
    if (groupId && librariesData?.status !== RequestStatus.resolved) {
      dispatch(getOneDriveSharedLibrariesStart(groupId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, groupId]);

  useEffect(() => {
    if (librariesData?.status === RequestStatus.resolved) {
      const currentSelectedLibrary = librariesData.data.find(
        (library) => library.id === match?.params.libraryId
      );

      if (currentSelectedLibrary) {
        setCurrentFolderLink(currentSelectedLibrary.webUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [librariesData?.status]);

  const sharedLibrariesRows = librariesData?.data?.map((library) => {
    const currentLibraryPath = `${route.path}${OneDrivePageRoutes.SharedLibraries}/${groupId}/${library.id}`;

    return (
      <StyledMenuItem
        key={currentLibraryPath}
        onClick={() => setCurrentFolderLink(library.webUrl)}
        icon={<StyledLibAvatar id={library.id}>{getAvatarSymbols(library.name)}</StyledLibAvatar>}
      >
        <Link to={currentLibraryPath}>{library.name}</Link>
      </StyledMenuItem>
    );
  });

  return (
    <>
      {librariesData?.status === RequestStatus.resolved && (
        <StyledMenuLibraries selectedKeys={[location.pathname]}>
          {sharedLibrariesRows}
        </StyledMenuLibraries>
      )}
    </>
  );
};

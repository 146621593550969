import React from 'react';
import { IELibraryDocumentTypeListResponse } from 'types';
import {
  StyledELibraryDrawerSubheading,
  StyledELibraryTypesOfDocList,
  StyledELibraryTypesOfDocListRowItem,
  StyledELibraryTypesOfDocListRowItemDocType,
  StyledELibraryTypesOfDocListRowItemText,
} from '../ELibrary.styled';
import { TType } from '../../../translations';

export interface ELibraryTypesOfDocumentProps {
  t: TType;
  documentTypes?: IELibraryDocumentTypeListResponse;
}
export const ELibraryTypesOfDocument: React.FC<ELibraryTypesOfDocumentProps> = ({
  t,
  documentTypes,
}: ELibraryTypesOfDocumentProps) => {
  return (
    <>
      {documentTypes && (
        <StyledELibraryDrawerSubheading>
          {`${documentTypes?.length} ${t('eLibrary.typesOfDocuments')}`}
        </StyledELibraryDrawerSubheading>
      )}
      <StyledELibraryTypesOfDocList>
        {documentTypes?.map((value, index, array) => (
          <StyledELibraryTypesOfDocListRowItem key={index}>
            <StyledELibraryTypesOfDocListRowItemDocType>
              <StyledELibraryTypesOfDocListRowItemText>
                {value}
              </StyledELibraryTypesOfDocListRowItemText>
            </StyledELibraryTypesOfDocListRowItemDocType>
          </StyledELibraryTypesOfDocListRowItem>
        ))}
      </StyledELibraryTypesOfDocList>
    </>
  );
};

import { from, of } from 'rxjs';
import { mergeMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetGroupMembersFail,
  GetGroupMembersStart,
  GetGroupMembersSuccess,
  getGroupMembersFail,
  getGroupMembersStart,
  getGroupMembersSuccess,
} from './groupMembersSlice';

export const groupMembersEpic: Epic<
  GetGroupMembersStart,
  GetGroupMembersSuccess | GetGroupMembersFail,
  RootState,
  typeof API
> = (action$, _, { groupMembers }) =>
  action$.pipe(
    filter(getGroupMembersStart.match),
    mergeMap(({ payload }) =>
      from(groupMembers.getGroupMembers(payload.params, payload.rootPath)).pipe(
        map(({ data }) => getGroupMembersSuccess(data)),
        catchError((error) => of(getGroupMembersFail(error?.response?.data)))
      )
    )
  );

import { TaskAttachment } from 'types';

export interface TaskFieldProps {
  fieldId: string;
  fieldValue: string | TaskFieldList[] | boolean;
  typeName: string;
  label: string;
}

interface ItemsDetailsInfo {
  id: string;
  value: string | TaskFieldList[];
  typeName: string;
  label: string;
}

export interface TaskFieldList {
  label: string;
  fieldsDetails: ItemsDetailsInfo[];
  attachments: TaskAttachment[];
}
export const TaskDescriptionPattern = /taskdescription/i;
export const ErrorPattern = /error/i;

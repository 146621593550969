import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from 'api/constants';
import { AxiosRequestConfig } from 'axios';
import { attendanceInfo } from '../Attendance';

interface DataFile {
  responseType: string;
  params: AxiosRequestConfig['params'];
}

export function useExportAttendanceReport(hcmPersonId: string, dataFile: DataFile) {
  return useQuery(
    [QUERIES.exportAttendanceReport, hcmPersonId, dataFile],
    async () => {
      const { data } = await attendanceInfo.getExportAttendance(hcmPersonId, dataFile);
      return data;
    },
    {
      enabled: false,
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

export function useAttendanceTimeCard(hcmPersonId: string, config?: AxiosRequestConfig) {
  return useQuery(
    [QUERIES.attendanceTimeCard, hcmPersonId, config?.params],
    async () => {
      const { data } = await attendanceInfo.getAttendanceInfo(hcmPersonId, config);
      return data;
    },
    {
      enabled: false,
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

import React, { useCallback, useContext, useMemo } from 'react';
import { TType } from 'translations';
import { FormInstance } from 'antd/lib/form';
import { ChangeBankAccountFieldIds, CreateFormItemType, SelectOptions } from 'types';
import { selectFilterOption } from 'utils/getFilterOptions';
import { StaticFormTemplate } from '../../../templates';
import { createFormField } from './ChangeBankAccountRequestFormCreation';
import { ChangeBankAccountRequestFormContext } from './ChangeBankAccountRequestForm';

const MAX_ATTACHMENT_COUNT = '1';
const ATTACHMENT_EXTENSION =
  '.doc, .docx, .ppt, .pptx, .png, .jpg, .jpeg, .xls,. xlsx, .pdf, .gif, .msg, .txt, .csv, .mp4, .heic, .mov, .mkv, .3gp, .ppt, .pptx';

export interface ChangeBankAccountRequestFormFieldsProps {
  t: TType;
  form?: FormInstance;
}

export const ChangeBankAccountRequestFormFields: React.FC<ChangeBankAccountRequestFormFieldsProps> = ({
  t,
  form,
}: ChangeBankAccountRequestFormFieldsProps) => {
  const { dataLookUp } = useContext(ChangeBankAccountRequestFormContext);

  const isClearanceCertificateAvailable = useMemo(() => {
    return dataLookUp?.displayClearance ?? false;
  }, [dataLookUp]);

  const prefillEmployeeBankName = useCallback(() => {
    form?.setFieldsValue({
      [`${ChangeBankAccountFieldIds.EmployeeBankName}`]: dataLookUp?.currentBank ?? '',
    });
  }, [dataLookUp?.currentBank, form]);

  const bankOptions: SelectOptions = useMemo(() => {
    return (
      dataLookUp?.banksDTOList?.map((bank) => {
        return { label: bank.englishName, value: bank.id };
      }) ?? []
    );
  }, [dataLookUp]);

  const description: string = useMemo(() => {
    return dataLookUp?.processDescription ?? '';
  }, [dataLookUp?.processDescription]);

  useMemo(() => {
    prefillEmployeeBankName();
  }, [prefillEmployeeBankName]);

  const formFields = useCallback<() => CreateFormItemType[]>(() => {
    return [
      createFormField({
        id: ChangeBankAccountFieldIds.EmployeeBankName,
        name: ChangeBankAccountFieldIds.EmployeeBankName,
        label: t('changeBankAccountRequest.form.label.employeeBankName'),
        t: t,
        type: 'input',
        rules: [],
        disabled: true,
      }),
      createFormField({
        id: ChangeBankAccountFieldIds.NewEmployeeBankName,
        name: ChangeBankAccountFieldIds.NewEmployeeBankName,
        label: t('changeBankAccountRequest.form.label.newEmployeeBankName'),
        placeholder: t('changeBankAccountRequest.form.placeHolder.newEmployeeBankName'),
        t: t,
        type: 'select',
        options: bankOptions,
        selectTheValueAsKey: true,
        filterOption: selectFilterOption,
      }),
      createFormField({
        id: ChangeBankAccountFieldIds.Comments,
        name: ChangeBankAccountFieldIds.Comments,
        label: t('changeBankAccountRequest.form.label.comments'),
        placeholder: t('changeBankAccountRequest.form.placeHolder.comments'),
        t: t,
        type: 'textArea',
        maxNumber: 1024,
        rules: [],
      }),
      createFormField({
        id: ChangeBankAccountFieldIds.IBAN,
        name: ChangeBankAccountFieldIds.IBAN,
        label: t('changeBankAccountRequest.form.label.IBAN'),
        t: t,
        type: 'file',
        maxAttachmentCount: MAX_ATTACHMENT_COUNT,
        attachmentFileExtensions: ATTACHMENT_EXTENSION,
      }),
      createFormField({
        id: ChangeBankAccountFieldIds.CLEARANCE,
        name: ChangeBankAccountFieldIds.CLEARANCE,
        label: t('changeBankAccountRequest.form.label.Clearance'),
        t: t,
        type: 'file',
        isHidden: !isClearanceCertificateAvailable,
        maxAttachmentCount: MAX_ATTACHMENT_COUNT,
        attachmentFileExtensions: ATTACHMENT_EXTENSION,
      }),
    ];
  }, [bankOptions, isClearanceCertificateAvailable, t]);

  return (
    <StaticFormTemplate
      showDescriptionIcon={false}
      key={'form-group-request-details'}
      t={t}
      getItems={formFields}
      description={description}
      form={form}
    ></StaticFormTemplate>
  );
};

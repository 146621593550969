import React, { FC, memo } from 'react';
import { Col, Row } from 'antd';

import { useRowGutter } from 'ui/hooks';
import { useTranslate } from 'translations';
import { TaskFieldList } from '../TaskFieldsTypes';
import { TaskFields } from '../TaskFields';
import { TaskAttachment } from '../../TaskAttachment';
import { TaskDescription as TaskItemWrapper } from '../TaskFields.styled';
import { TitleGroupName } from './TitleGroup';
import { AttachmentWrapper } from './ListOfItem.Style';

interface TaskItemFieldProps {
  fieldValue: TaskFieldList[];
}

const TaskItemFieldComponents: FC<TaskItemFieldProps> = ({ fieldValue }) => {
  const { t } = useTranslate();
  const GUTTER_24 = useRowGutter();
  const fieldItems = fieldValue.reduce((acc, filed) => {
    if (filed.label) {
      acc[filed?.label] = [...(acc[filed?.label] || []), filed];
    }
    return acc;
  }, {} as Record<string, TaskFieldList[]>);
  return (
    <React.Fragment>
      <Col span={24}>
        {Object.entries(fieldItems).map(([itemNumber, itemFields], index) => (
          <Row gutter={GUTTER_24} key={index}>
            <Col span={24}>
              {itemFields.map((itemDetails, index) => (
                <TaskItemWrapper key={index}>
                  <TitleGroupName title={itemNumber} withoutTopSpace />
                  <Col span={24}>
                    <Row gutter={[24, 16]} key={itemDetails.label}>
                      {itemDetails.fieldsDetails.map(({ id, label, typeName, value }, index) => (
                        <TaskFields
                          key={index}
                          fieldId={id}
                          fieldValue={value}
                          typeName={typeName}
                          label={label}
                        />
                      ))}
                    </Row>
                    {itemDetails.attachments && itemDetails.attachments.length > 0 && (
                      <AttachmentWrapper>
                        <TitleGroupName title={t('commonField.staticForm.label.attachments')} />
                        {itemDetails.attachments.map((attachment, index) => (
                          <TaskAttachment
                            id={attachment.id}
                            name={attachment.name}
                            taskId={attachment.taskId}
                            key={index}
                            attachmentLabel={attachment.attachmentLabel}
                          />
                        ))}
                      </AttachmentWrapper>
                    )}
                  </Col>
                </TaskItemWrapper>
              ))}
            </Col>
          </Row>
        ))}
      </Col>
    </React.Fragment>
  );
};

export const TaskItemField = memo(TaskItemFieldComponents);

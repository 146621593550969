import React, { FC, useCallback, useEffect, useState, memo } from 'react';
import { Row, Col, Button } from 'antd';
import { LeavesPageRoutes } from 'namespace';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslate } from 'translations';
import { RequestStatus } from 'types';
import {
  getCurrentUserAdoid,
  getPublicHolidaysStart,
  publicHolidaysSelector,
  useDispatch,
  useSelector,
} from 'store';
import { EventList, useErrorNotification, UserAvatartName } from 'ui';
import { useMyTeamLeavesList, useTeamMembers, useLeaveTypes } from 'api';
import { FilterContextType } from 'ui/components/Filter/Filter.types';
import {
  concatEventList,
  convertFilterDates,
  getStartAndEndDate,
  LeaveDrawer,
  LeavesFilter,
  ListView,
  CalendarView,
} from 'routes/Calendar';
import {
  PageHeader,
  StyledAddFilter,
  StyledButton,
  ButtonContent,
  StyledLeavesContent,
  ListActiveIconStyled,
  ListUnActiveIconStyled,
  CalendarFilledIconStyled,
  CalendarOutlinedIconStyled,
} from '../Leave/Leaves.styled';

enum ViewType {
  'list' = 'list',
  'calendar' = 'calendar',
}

interface MyTeamLeavesProps {
  hcmPersonId: string;
}

export const LIST_VIEW_PAGE_SIZE = 10;

const MyTeamLeavesComponent: FC<MyTeamLeavesProps> = ({ hcmPersonId }) => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [defaultDate, setDefaultDate] = useState<string>();
  const [currentLeaveId, setCurrentLeaveId] = useState<string>('');
  const [viewType, setViewType] = useState<ViewType>(ViewType.calendar);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [events, setEvents] = useState<EventList[]>([]);
  const [filterValues, setFilterValues] = useState<FilterContextType['filterValues']>({});
  const { data: publicHolidays, status: publicStatus } = useSelector(publicHolidaysSelector);
  const userAdoId = useSelector(getCurrentUserAdoid);

  const {
    data: LeavesListData,
    isLoading,
    isError,
    isRefetchError,
    refetch,
    status: teamLeavesListStatus,
  } = useMyTeamLeavesList(hcmPersonId, {
    params: {
      ...convertFilterDates(filterValues),
    },
  });

  const { data: LeaveTypesData, isLoading: isLeavesTypesLoading } = useLeaveTypes();

  const { data: teamMembers } = useTeamMembers(hcmPersonId);

  const getUrl = useCallback((pagePath: string) => `${path}${pagePath}${location.search}`, [
    location.search,
    path,
  ]);

  const teamMembersOptions = teamMembers?.map(({ employeeName, adOid }) => ({
    label: employeeName,
    value: adOid,
    text: <UserAvatartName id={adOid} name={employeeName} />,
  }));

  useEffect(() => {
    if (RequestStatus.idle === publicStatus) dispatch(getPublicHolidaysStart());
  }, [dispatch, publicStatus]);

  useEffect(() => {
    const currentDate = new Date();
    const fullMonth = getStartAndEndDate(currentDate);
    setDefaultDate(fullMonth);
  }, []);

  const getLeavesList = useCallback((selectedMonth: string) => {
    setDefaultDate(selectedMonth);
  }, []);

  const getTeamLeaves = (ids: string) => {
    setFilterValues({
      adOid: ids,
    });
  };

  const setLeavesFilter = useCallback((values) => {
    setCurrentPage(0);
    if (values.adOid && !values.teamMember) {
      delete values.adOid;
    }
    setFilterValues(values);
  }, []);

  useEffect(() => {
    if (defaultDate && !isError) {
      refetch();
    }
  }, [defaultDate, refetch, filterValues, viewType, isError]);

  useEffect(() => {
    if (publicHolidays && LeavesListData) {
      const eventList = concatEventList(LeavesListData, publicHolidays);

      eventList && setEvents(eventList);
    }
  }, [publicHolidays, LeavesListData]);

  useErrorNotification(
    {
      isNotification: isError || isRefetchError,
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    },
    [isError, isRefetchError]
  );

  const setListViewType = useCallback(() => setViewType(ViewType.list), []);
  const setCalendarViewType = useCallback(() => setViewType(ViewType.calendar), []);

  return (
    <React.Fragment>
      <PageHeader size="small">
        <Row justify="space-between" align="middle">
          <Col>
            <StyledAddFilter>
              <LeavesFilter
                setFilterValues={setLeavesFilter}
                leavesTypes={LeaveTypesData}
                teamMembers={teamMembers}
                isLeavesTypesLoading={isLeavesTypesLoading}
                filterValues={filterValues}
                isMyTeamLeaves={true}
                isListView={viewType === ViewType.list}
                viewType={viewType}
                teamMembersOptions={teamMembersOptions}
              />
            </StyledAddFilter>
          </Col>
          <Col>
            <Button type="text" onClick={setCalendarViewType}>
              <ButtonContent $active={viewType === ViewType.calendar}>
                {viewType === ViewType.calendar ? (
                  <CalendarFilledIconStyled />
                ) : (
                  <CalendarOutlinedIconStyled />
                )}{' '}
                {t('leaves.calendar.title')}
              </ButtonContent>
            </Button>
            <Button type="text" onClick={setListViewType}>
              <ButtonContent $active={viewType === ViewType.list}>
                {viewType === ViewType.list ? <ListActiveIconStyled /> : <ListUnActiveIconStyled />}{' '}
                {t('leaves.list.title')}
              </ButtonContent>
            </Button>
            <StyledButton type={'primary'}>
              <Link to={getUrl(LeavesPageRoutes.NewLeaveRequest)}>
                <PlusOutlined /> {t('leaves.createNew.title')}
              </Link>
            </StyledButton>
          </Col>
        </Row>
      </PageHeader>
      <StyledLeavesContent>
        {viewType === ViewType.calendar ? (
          <CalendarView
            setCurrentLeaveId={setCurrentLeaveId}
            eventList={events}
            isLoading={isLoading}
            refetchList={getLeavesList}
            options={teamMembersOptions}
            isMyTeamLeaves={true}
            getTeamLeaves={getTeamLeaves}
          />
        ) : (
          <ListView
            setCurrentLeaveId={setCurrentLeaveId}
            filterValues={filterValues}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            leavesListData={LeavesListData}
            leavesListStatus={teamLeavesListStatus}
            isMyTeamLeaves={true}
            isMyLeaveListLoading={isLoading}
          />
        )}
      </StyledLeavesContent>
      {currentLeaveId && userAdoId && (
        <LeaveDrawer
          setCurrentLeaveId={setCurrentLeaveId}
          currentLeaveId={currentLeaveId}
          setListViewType={setListViewType}
          dispatchGetLeaves={refetch}
          myTeamLeave={true}
          t={t}
          adoId={userAdoId}
        />
      )}
    </React.Fragment>
  );
};

export const MyTeamLeaves = memo(MyTeamLeavesComponent);

import React, { useCallback, useState } from 'react';
import { Input } from 'antd';
import { useTranslate } from 'translations';
import { useDispatch, getFilesStart } from 'store';

import { useRouteMatch, useHistory } from 'react-router-dom';
import { OneDrivePageRoutes } from 'namespace';
import { StyledSearchIcon } from './OneDriveSearch.styled';

export const OneDriveSearch = () => {
  const { t } = useTranslate();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const getUrl = useCallback((pagePath: string) => `${path}${pagePath}`, [path]);
  const [value, setValue] = useState('');

  const searchFiles = useCallback(
    (searchText: string) => {
      dispatch(
        getFilesStart({
          params: {
            search: searchText,
          },
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const onKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        searchFiles(e.target.value);
        history.push(getUrl(OneDrivePageRoutes.SearchResult));
      }
    },
    [getUrl, history, searchFiles]
  );

  const onFocus = useCallback(() => {
    setValue('');
  }, []);

  const onChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <Input
      onKeyPress={onKeyPress}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      prefix={<StyledSearchIcon />}
      size="middle"
      placeholder={t('msteams.search')}
    />
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { ServicesCardsInfoAgg, RequestCommonError, RequestStatus } from 'types';
import { MyGroupTaskCardState } from './myGroupTaskCardsTypes';

export const emptyMyGroupTaskCardsState: MyGroupTaskCardState = {
  status: RequestStatus.idle,
  error: null,
  hasMore: true,
};

export type GetMyGroupTaskCardsStart = PayloadAction<{
  userId: string;
  params?: AxiosRequestConfig['params'];
}>;
export type GetMyGroupTaskCardsSuccess = PayloadAction<ServicesCardsInfoAgg>;
export type GetMyGroupTaskCardsFail = PayloadAction<RequestCommonError>;
export type GetMyGroupTaskCardsReset = PayloadAction;

const myGroupTaskCards = createSlice({
  name: 'myGroupTaskCards',
  initialState: emptyMyGroupTaskCardsState,
  reducers: {
    getMyGroupTaskCardsStart(state, _: GetMyGroupTaskCardsStart) {
      state.status = RequestStatus.pending;
    },
    getMyGroupTaskCardsSuccess(state, action: GetMyGroupTaskCardsSuccess) {
      state.status = RequestStatus.resolved;
      if (action.payload?.requestInfoList.length < 10) {
        state.hasMore = false;
      }
      if (!state.data?.requestInfoList.length) {
        state.data = action.payload;
        return;
      }
      state.data.requestInfoList = [
        ...state.data.requestInfoList,
        ...action.payload.requestInfoList,
      ];
    },
    getMyGroupTaskCardsFail(state, action: GetMyGroupTaskCardsFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getMyGroupTaskCardsReset(state, _: GetMyGroupTaskCardsReset) {
      state.status = RequestStatus.idle;
      state.error = null;
      state.data = undefined;
      state.hasMore = true;
    },
  },
});

export const {
  getMyGroupTaskCardsFail,
  getMyGroupTaskCardsStart,
  getMyGroupTaskCardsSuccess,
  getMyGroupTaskCardsReset,
} = myGroupTaskCards.actions;
export default myGroupTaskCards.reducer;

import { Button, Col, Form, Row } from 'antd';
import React, { FC, Fragment, memo, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';

import {
  ItemFormSubtitle,
  ProductInfoFormWrapper,
  PRODUCT_INFO_FORM_COL_SPAN,
} from 'ui/components';
import { space, TitleH2, VerticalSpace } from 'ui/elements';
import { TType } from 'translations';
import { CreateFormItemType } from 'types';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { FormInstance } from 'antd/lib/form';
import { GeneratedStaticForm } from '../GeneratedStaticForms';
import { SpaceDivider, WrapperItem } from './listOfItem.style';

interface ExpenseListProps {
  t: TType;
  getItems: (field: FormListFieldData) => CreateFormItemType[];
  maxLimit: number;
  formListName: string;
  form?: FormInstance;
  itemGroupName: string;
  btnDefaultTitle: string;
  btnAddTitle: string;
}
const BTN_ID_NAME = 'addMore';
const ListOfItemComponents: FC<ExpenseListProps> = ({
  t,
  getItems,
  formListName,
  maxLimit,
  form,
  itemGroupName,
  btnDefaultTitle,
  btnAddTitle,
}) => {
  useEffect(() => {
    const btn = document.getElementById(BTN_ID_NAME);
    btn?.click();
  }, []);

  return (
    <Fragment>
      <SpaceDivider>
        <TitleH2>{itemGroupName}</TitleH2>
      </SpaceDivider>

      <Form.List name={formListName}>
        {(fields, { add, remove }) => {
          return (
            <div style={{ marginBottom: '40px' }}>
              {fields.map((field, index) => {
                const items = getItems(field);

                return (
                  <ProductInfoFormWrapper key={field.key}>
                    <WrapperItem>
                      <VerticalSpace size={parseInt(space.moderate)}>
                        <ItemFormSubtitle
                          subtitle={`#${index + 1}`}
                          t={t}
                          onRemove={fields.length === 1 ? undefined : () => remove(field.name)}
                        />
                        <Row>
                          <Col span={PRODUCT_INFO_FORM_COL_SPAN} key={field.fieldKey}>
                            <GeneratedStaticForm t={t} items={items} formState={form} />
                          </Col>
                        </Row>
                      </VerticalSpace>
                    </WrapperItem>
                  </ProductInfoFormWrapper>
                );
              })}
              {fields.length <= maxLimit && (
                <Button onClick={add} icon={<PlusOutlined />} id={BTN_ID_NAME}>
                  {fields.length === 0 ? btnDefaultTitle : btnAddTitle}
                </Button>
              )}
            </div>
          );
        }}
      </Form.List>
    </Fragment>
  );
};

export const ListOfItem = memo(ListOfItemComponents);

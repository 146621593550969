import { Empty, Form, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { UseScrapInvoiceFormDetails } from 'api/StaticForm/hooks';
import { useScrapInvoiceSubmit } from 'api/StaticForm/hooks/ScrapInvoice/useScrapInvoiceSumbit';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useHandleSubmitStatus } from 'routes/Tasks';
import { AttachmentFile, attachmentsFieldsId } from 'routes/Tasks/components/pages';
import { TType } from 'translations';
import { FROM_ITEM_LIST, FullWidthSpin, ScrapInvoiceItemName, ScrapInvoiceName } from 'ui';
import { submitAttachmentForm } from 'ui/publicFunction';
import { VALIDATE_MESSAGES } from 'utils';
import { ScrapInvoiceFields } from './ScrapInvoiceFiled';

interface ScrapInvoiceProps {
  formName?: string;
  t: TType;
}

interface AssetsTypeList {
  assetNumber: number;
  assetName: string;
  tagC: number;
  tagP: number;
  quantity: number;
  amount: number;
  technicalReportList: number[];
}

interface ScrapInvoiceSubmitType {
  customerId: string;
  customerName: string;
  description: string;
  totalAmount: number;
  items: AssetsTypeList[];
  purposeId: number;
}

export const ScrapInvoiceFormComponent: FC<ScrapInvoiceProps> = ({ formName, t }) => {
  const [form] = useForm();
  const [totalAmount, setTotalAmount] = useState<string>();
  const [attachmentData, setAttachmentData] = useState<AttachmentFile[]>([]);
  const history = useHistory();

  const onFieldsChange = useCallback(() => {
    const TotalAmount = form.getFieldValue(FROM_ITEM_LIST) as [];
    const itemValues: number[] = [];

    if (TotalAmount) {
      TotalAmount.map((item) => {
        return Object.entries(item).map(([name, value]) => {
          return name === ScrapInvoiceItemName.amountSAR ? itemValues.push(value as number) : null;
        });
      });
    }

    const totalSAR = itemValues.reduce((totalNumber, newNumber) => totalNumber + newNumber, 0);

    if (totalSAR > 0 && typeof totalSAR === 'number') {
      setTotalAmount(totalSAR?.toFixed(2));
    } else {
      setTotalAmount(undefined);
    }
  }, [form]);

  const {
    data: scrapInvoiceFormDetails,
    isLoading: isLoadingFetchScrapInvoiceFormDetails,
    isError,
  } = UseScrapInvoiceFormDetails();

  const mutation = useScrapInvoiceSubmit();

  useEffect(() => {
    if (totalAmount) {
      form.setFieldsValue({ [ScrapInvoiceName.totalAmountSAR]: totalAmount });
      form.validateFields([ScrapInvoiceName.totalAmountSAR]);
    } else {
      form.setFieldsValue({ [ScrapInvoiceName.totalAmountSAR]: undefined });
      form.validateFields([ScrapInvoiceName.totalAmountSAR]);
    }
  }, [form, totalAmount]);

  const onSubmitFinish = useCallback(
    async (variables) => {
      const attachmentsForm: AttachmentFile[] = [];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const assetsList: AssetsTypeList[] = variables.ITEMS.map((fields: any, index: number) => {
        return {
          itemLabel: `#${index + 1}`,
          assetNumber: fields.assetNumber,
          assetName: fields.assetName,
          tagC: fields.tagC,
          tagP: fields.tagP,
          quantity: fields.quantity,
          amount: fields.amount,
          technicalReportList: fields.technicalReportList,
        };
      });

      const customerInfo = variables.customerName as string;

      const [customerId, customerName] = customerInfo.split(' - ');

      const formVariables: ScrapInvoiceSubmitType = {
        customerId,
        customerName,
        description: variables.description,
        totalAmount: variables.totalAmount,
        items: assetsList,
        purposeId: variables.purposeId,
      };

      mutation.mutate(formVariables);

      const currentFormFieldsName = Object.keys(variables);

      const currentAttachmentId = currentFormFieldsName.filter((variables: string) => {
        return attachmentsFieldsId.includes(variables);
      });

      if (currentAttachmentId && variables) {
        currentAttachmentId.forEach((fieldId: string) => {
          const attachmentValue = variables[fieldId];
          if (attachmentValue) {
            return attachmentsForm.push({ [fieldId]: attachmentValue });
          }
        });
      }
      if (attachmentsForm) {
        setAttachmentData(attachmentsForm);
      }
    },
    [mutation]
  );

  useEffect(() => {
    mutation.data &&
      submitAttachmentForm(mutation.isSuccess, mutation.data, history, undefined, attachmentData);
  }, [history, attachmentData, mutation]);

  useHandleSubmitStatus(t, mutation.isLoading, mutation.isError, mutation.isSuccess);

  return (
    <React.Fragment>
      <Form
        form={form}
        name={formName}
        validateMessages={VALIDATE_MESSAGES(t)}
        layout="vertical"
        onChange={onFieldsChange}
        onFinish={onSubmitFinish}
      >
        {isError ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
        ) : isLoadingFetchScrapInvoiceFormDetails ? (
          <FullWidthSpin />
        ) : (
          scrapInvoiceFormDetails && (
            <Spin spinning={mutation.isLoading} delay={100}>
              <ScrapInvoiceFields t={t} form={form} formDetails={scrapInvoiceFormDetails} />
            </Spin>
          )
        )}
      </Form>
    </React.Fragment>
  );
};

export const ScrapInvoiceFrom = React.memo(ScrapInvoiceFormComponent);

import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

export const ChatItemTitle = styled(Text)`
  display: block;
  text-align: center;
  font-size: 12px;
  margin: 7px 0 14px;
`;

import React, {
  FC,
  ReactNode,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from 'react';
import { Upload } from 'antd';
import { RcFile, UploadFile, UploadListType } from 'antd/lib/upload/interface';
import { useTranslate } from 'translations';
import { FormInstance } from 'antd/lib/form';
import { useAttachmentStandard } from 'api/Attachment';
import { useErrorNotification } from 'ui/hooks';
import { AttachmentError } from 'types';
import { getGroupedAttachmentData, validateFileSize, validateFileType } from './helper';
import { FILE_FORMAT, FILE_SIZE, MAX_ATTACHMENT } from './constants';

export type RestrictedUploadProps = {
  children: ReactNode;
  disableField?: boolean;
  showUploadList?: boolean;
  multiple?: boolean;
  listType?: UploadListType;
  setCommentsFileList?: Dispatch<SetStateAction<UploadFile[]>>;
  getMaxCount?: (max: number) => void;
  customRequest?: ({ file }: { file: UploadFile }) => void;
  form?: FormInstance;
  fieldId?: string | number | (string | number)[];
  maxAttachmentCount?: string;
  allowedFileTypes?: string;
  allowedFileExtensions?: string;
  onChange?: (file?: UploadFile | UploadFile[]) => void;
  fileList?: [];
};

const LIST_IGNORE = false;

export const RestrictedUpload: FC<RestrictedUploadProps> = ({
  children,
  disableField,
  setCommentsFileList,
  showUploadList,
  multiple,
  listType,
  getMaxCount,
  customRequest,
  form,
  fieldId,
  maxAttachmentCount,
  allowedFileTypes,
  allowedFileExtensions,
  onChange,
  fileList,
}) => {
  const { t } = useTranslate();
  const [innerFileList, setFileList] = useState<UploadFile[]>(fileList ?? []);
  const [allowedFileSize, setAllowedFileSize] = useState<number>();
  const [allowedTypes, setAllowedTypes] = useState<string>();
  const [allowedFormats, setAllowedFormats] = useState<string>();
  const [allowedMaxLength, setAllowedMaxLength] = useState<number>();
  const [errorDescription, setErrorDescription] = useState<AttachmentError>();

  const { data: attachmentStandardData } = useAttachmentStandard();

  const attachmentStandard =
    attachmentStandardData && getGroupedAttachmentData(attachmentStandardData);

  useEffect(() => {
    if (attachmentStandard) {
      setAllowedFileSize(+attachmentStandard?.ATTACHMENT_SIZE.split(' ')[0]);
      setAllowedTypes(allowedFileTypes ?? attachmentStandard?.ATTACHMENT_EXTENSIONS);
      setAllowedFormats(allowedFileExtensions ?? attachmentStandard?.ATTACHMENT_FORMATS);
      setAllowedMaxLength(
        maxAttachmentCount ? +maxAttachmentCount : +attachmentStandard?.ATTACHMENT_MAX_NUMBER
      );
    }
  }, [allowedFileExtensions, allowedFileTypes, attachmentStandard, maxAttachmentCount]);

  useErrorNotification(
    {
      isNotification: !!errorDescription,
      message: t('messages.error.wentWrong'),
      description:
        errorDescription?.description === FILE_FORMAT
          ? t('requestForm.validation.rule.message.fileFormat', {
              type: allowedFormats,
            })
          : errorDescription?.description === FILE_SIZE
          ? t('requestForm.validation.rule.message.fileSizeLimit', {
              size: allowedFileSize,
            })
          : t('requestForm.validation.rule.message.maxAttachmentCount', {
              max: allowedMaxLength,
            }),
    },
    [errorDescription]
  );

  const beforeUpload = useCallback(
    (file: UploadFile) => {
      const { size, lastModified, lastModifiedDate, name, type } = file;
      const fileObj = {
        ...file,
        originFileObj: file as RcFile,
        size,
        lastModified,
        lastModifiedDate,
        name,
        type,
      };

      const isAllowedType = validateFileType(file, allowedTypes, allowedFormats);

      if (!isAllowedType) {
        setFileList((state) => [...state]);

        setErrorDescription({
          name: name,
          description: FILE_FORMAT,
        });

        return LIST_IGNORE;
      }

      const isAllowedSize = validateFileSize(size, allowedFileSize);

      if (!isAllowedSize) {
        setFileList((state) => [...state]);

        setErrorDescription({
          name: name,
          description: FILE_SIZE,
        });

        return LIST_IGNORE;
      }

      if (customRequest) {
        customRequest({ file });
      } else {
        setFileList((prevState) => {
          const updatedList = [...prevState, fileObj];

          const listItems = form?.getFieldValue('ITEMS');
          const isThereAttachmentOnList = Array.isArray(fieldId);
          const index = fieldId?.toString().split(',')[0];

          if (allowedMaxLength && prevState.length < allowedMaxLength) {
            if (listItems?.length > 0 && isThereAttachmentOnList && index) {
              listItems[+index].attachment = updatedList;
              form?.setFieldsValue({ ITEMS: listItems });
            }
            form?.setFieldsValue({ [fieldId as string]: updatedList });

            onChange?.(updatedList);
            return updatedList;
          } else if (form && fieldId) {
            setErrorDescription({
              name: name,
              description: MAX_ATTACHMENT,
            });
          }
          return prevState;
        });
      }

      setCommentsFileList && setCommentsFileList((state) => [...state, file]);
      return LIST_IGNORE;
    },
    [
      allowedFileSize,
      allowedFormats,
      allowedMaxLength,
      allowedTypes,
      customRequest,
      fieldId,
      form,
      onChange,
      setCommentsFileList,
    ]
  );

  const onRemove = useCallback(
    (file: UploadFile) => {
      if (innerFileList.some((item) => item.uid === file.uid)) {
        const fileteredFileList = innerFileList.filter((item) => item.uid !== file.uid);
        setFileList(fileteredFileList);
        form?.setFieldsValue({ [fieldId as string]: fileteredFileList });
        onChange?.(fileteredFileList);
        setCommentsFileList && setCommentsFileList(fileteredFileList);
        return true;
      }
      return false;
    },
    [fieldId, innerFileList, form, onChange, setCommentsFileList]
  );

  const uploadProps = useMemo(
    () => ({
      fileList: innerFileList,
      beforeUpload,
      onRemove,
    }),
    [innerFileList, beforeUpload, onRemove]
  );

  useEffect(() => {
    if (getMaxCount && allowedMaxLength) {
      getMaxCount(allowedMaxLength);
    }
  }, [getMaxCount, allowedMaxLength]);

  return (
    <Upload
      {...uploadProps}
      listType={listType}
      disabled={disableField}
      showUploadList={showUploadList}
      multiple={multiple}
      fileList={innerFileList}
    >
      {children}
    </Upload>
  );
};

import { createContext, useContext, Dispatch, SetStateAction } from 'react';

interface OneDriveContextType {
  currentFolderLink: string;
  setCurrentFolderLink: Dispatch<SetStateAction<string>>;
}

export const OneDriveContext = createContext({} as OneDriveContextType);

export const useOneDriveContext = () => {
  const context = useContext<OneDriveContextType>(OneDriveContext);

  if (!context) {
    throw new Error('useOneDriveContext should be used within a OneDriveContext');
  }

  return context;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeaveRequestCommonError, RequestStatus, PostTaskData, LeaveSubmitFormData } from 'types';
import { SubmitFormState } from './submitFormTypes';

export const emptySubmitFormInitialState: SubmitFormState = {
  status: RequestStatus.idle,
  error: null,
};

export type PostSubmitFormStart = PayloadAction<{ hcmPersonId: string; data: PostTaskData }>;
export type PostSubmitFormSuccess = PayloadAction<LeaveSubmitFormData>;

export type PostSubmitFormFail = PayloadAction<LeaveRequestCommonError>;

const submitForm = createSlice({
  name: 'submitForm',
  initialState: emptySubmitFormInitialState,
  reducers: {
    postSubmitFormStart(state, _: PostSubmitFormStart) {
      state.status = RequestStatus.pending;
    },
    postSumbitFormSuccess(state, action: PostSubmitFormSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    postSubmitFormFail(state, action: PostSubmitFormFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    postSubmitFormReset(state) {
      state.status = RequestStatus.idle;
      state.error = null;
    },
  },
});
export const {
  postSubmitFormStart,
  postSumbitFormSuccess,
  postSubmitFormFail,
  postSubmitFormReset,
} = submitForm.actions;

export default submitForm.reducer;

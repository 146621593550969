import styled from 'styled-components';
import { Row, Typography } from 'antd';
import { space, colors } from 'ui/elements';

const { Text } = Typography;

export const StyledDependentItemWrapper = styled(Row)`
  margin-bottom: ${space.comfy};
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledDependentItemTitle = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: ${colors.primaryColor};
  margin-bottom: ${space.moderate};
`;

import { OneDriveSharedData } from 'types';
import { Core, SHARED_FOLDER } from 'api';

interface GetOneDriveFolderShared {
  (driveId: string, folderId: string): Promise<{ data: OneDriveSharedData }>;
}

class OneDriveFolderShared extends Core {
  getOneDriveFolderShared: GetOneDriveFolderShared = async (driveId, folderId) =>
    this.get(SHARED_FOLDER(driveId, folderId));
}

export const oneDriveFolderShared = new OneDriveFolderShared();

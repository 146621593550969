import React, { FC, memo } from 'react';
import { useTranslate } from 'translations';
import { useAppLayoutAlert } from './hooks/useAppLayoutAlert';
import { AlertWrapperContent, AppAlert } from './AppLayoutStyle';

const AppLayoutAlertComponent: FC = () => {
  const { isAlert, alertMessage, refreshToken, alertIcon } = useAppLayoutAlert();
  const { t } = useTranslate();

  return (
    <>
      {isAlert && (
        <AlertWrapperContent>
          <AppAlert
            icon={alertIcon}
            message={alertMessage}
            type="error"
            closeText={t('layout.alert.cancelText')}
            onClose={refreshToken}
            showIcon
          />
        </AlertWrapperContent>
      )}
    </>
  );
};

export const AppLayoutAlert = memo(AppLayoutAlertComponent);

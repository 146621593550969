import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetRequestsListFail,
  GetRequestsListStart,
  GetRequestsListSuccess,
  getRequestsListStart,
  getRequestsListSuccess,
  getRequestsListFail,
} from './requestsListSlice';

export const requestsListEpic: Epic<
  GetRequestsListStart,
  GetRequestsListSuccess | GetRequestsListFail,
  RootState,
  typeof API
> = (action$, _, { processInstance }) =>
  action$.pipe(
    filter(getRequestsListStart.match),
    exhaustMap(({ payload }) =>
      from(processInstance.getProcessInstanceInfo(payload)).pipe(
        map(({ data }) => getRequestsListSuccess(data)),
        catchError((error) => of(getRequestsListFail(error?.response?.data)))
      )
    )
  );

import React, { useCallback, useMemo } from 'react';
import { NotificationList, RequestStatus } from 'types';
import moment from 'moment';
import { useTranslate } from 'translations';
import {
  getNotificationListRest,
  getNotificationListStart,
  notificationsListSelector,
  useDispatch,
  useSelector,
} from 'store';

const sizeOfNotificationList = 10;
export const useNotificationDrawerInfo = (
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useDispatch();
  const notificationsList = useSelector(notificationsListSelector);
  const { t } = useTranslate();
  const fetchNotificationList = React.useCallback(
    (page = 0) => {
      const requestProps = {
        page: page,
        size: sizeOfNotificationList,
      };
      dispatch(getNotificationListStart({ params: requestProps }));
    },
    [dispatch]
  );
  const reFreshNotificationList = useCallback(() => {
    dispatch(getNotificationListRest());
  }, [dispatch]);
  const isThereData = useMemo(() => !notificationsList.data, [notificationsList]);
  const isDrawerLoading = useMemo(() => notificationsList.status === RequestStatus.pending, [
    notificationsList.status,
  ]);
  const hasMore = useMemo(
    () =>
      notificationsList.data?.list.length !== notificationsList.data?.totalResult &&
      notificationsList.status !== RequestStatus.pending,
    [
      notificationsList.data?.list.length,
      notificationsList.data?.totalResult,
      notificationsList.status,
    ]
  );
  const isErrorFetchNotificationList = useMemo(
    () => notificationsList.status === RequestStatus.rejected,
    [notificationsList.status]
  );
  const loadMoreNotifications = useCallback(() => {
    if (!hasMore || notificationsList.status === RequestStatus.pending) {
      return;
    }
    if (notificationsList.data) {
      fetchNotificationList(notificationsList.data.offset + 1);
    }
  }, [fetchNotificationList, hasMore, notificationsList.data, notificationsList.status]);
  const listLength = useMemo(() => notificationsList.data?.offset, [
    notificationsList.data?.offset,
  ]);
  const onClose = () => {
    setIsOpen(false);
    reFreshNotificationList();
  };
  const filterList = useMemo(() => {
    if (notificationsList.data?.list && !isDrawerLoading) {
      return notificationsList.data?.list.reduce((acc, item) => {
        const currentDate = moment().startOf('day');
        const notificationCreateDate = moment(item.createdDate).startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        const momentDate = moment(item.createdDate).format('DD MMM YYYY');
        if (currentDate.isSame(notificationCreateDate)) {
          acc[t('notification.drawer.today')] = [
            ...(acc[t('notification.drawer.today')] ?? []),
            item,
          ];
        } else if (yesterday.isSame(notificationCreateDate)) {
          acc[t('notification.drawer.yesterday')] = [
            ...(acc[t('notification.drawer.yesterday')] ?? []),
            item,
          ];
        } else {
          acc[momentDate] = [...(acc[momentDate] ?? []), item];
        }
        return acc;
      }, {} as Record<string, NotificationList[]>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsList.data?.list, t]);
  React.useEffect(() => {
    if (isOpen && notificationsList.status === RequestStatus.idle) {
      fetchNotificationList(0);
    }
  }, [fetchNotificationList, isOpen, notificationsList, reFreshNotificationList]);
  return {
    filterList,
    isDrawerLoading,
    reFreshNotificationList,
    loadMoreNotifications,
    hasMore,
    listLength,
    onClose,
    isErrorFetchNotificationList,
    isThereData,
  };
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AllRequestsCard as SubCategoryCard, RequestCommonError, RequestStatus } from 'types';
import { SubCategoryCardState } from './subCategoryCardType';

export const emptySubCategoryCardState: SubCategoryCardState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetSubCategoryCardStart = PayloadAction<{ subCategoryName: string }>;
export type GetSubCategoryCardSuccess = PayloadAction<SubCategoryCard>;
export type GetSubCategoryCardFail = PayloadAction<RequestCommonError>;
export type GetSubCategoryCardReset = PayloadAction;

const subCategoryCard = createSlice({
  name: 'subCategoryCard',
  initialState: emptySubCategoryCardState,
  reducers: {
    getSubCategoryCardStart(state, _: GetSubCategoryCardStart) {
      state.status = RequestStatus.pending;
    },
    getSubCategoryCardSuccess(state, action: GetSubCategoryCardSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getSubCategoryCardFail(state, action: GetSubCategoryCardFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getSubCategoryCardReset(state, action: GetSubCategoryCardReset) {
      state.status = RequestStatus.idle;
      state.error = null;
      state.data = undefined;
    },
  },
});

export const {
  getSubCategoryCardFail,
  getSubCategoryCardStart,
  getSubCategoryCardSuccess,
  getSubCategoryCardReset,
} = subCategoryCard.actions;
export default subCategoryCard.reducer;

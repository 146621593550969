import { AssigneeUserData } from 'types';
import { Core, ASSIGNEE_USER } from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetAssigneeUser {
  (prams: AxiosRequestConfig['params']): Promise<{ data: AssigneeUserData[] }>;
}

class AssigneeData extends Core {
  getAssigneeUser: GetAssigneeUser = async (params) => this.get(ASSIGNEE_USER(), params);
}
export const assigneeData = new AssigneeData();

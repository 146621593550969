import { AxiosRequestConfig } from 'axios';

import { Tasks } from 'types';
import { Core, TASK } from 'api';

interface GetTaskList {
  (prams: AxiosRequestConfig['params']): Promise<{ data: Tasks }>;
}

class TaskList extends Core {
  getTasksList: GetTaskList = async (params) => this.get(TASK(), params);
}
export const taskList = new TaskList();

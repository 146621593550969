import { createSelector } from '@reduxjs/toolkit';

import { RequestStatus } from 'types';
import { RootState } from '../reducers';

export const userProfile = (state: RootState): RootState['userProfile'] => state.userProfile;

export const userProfileData = createSelector(userProfile, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

export const getOtherUserHcmId = createSelector(userProfileData, ({ data }) => data?.hcmId);

export const getOtherUserEmail = createSelector(userProfileData, ({ data }) => data?.email);

export const getOtherUserName = createSelector(
  userProfileData,
  ({ data }) => `${data?.firstName || ''} ${data?.lastName || ''}`
);

export const getHasOtherUserProfileData = createSelector(
  userProfileData,
  ({ status, data }) => status === RequestStatus.resolved && !!data
);

export const getOtherUserProfileDataNotReady = createSelector(
  userProfileData,
  ({ status }) => status === RequestStatus.idle || status === RequestStatus.pending
);

import React from 'react';
import { Badge } from 'antd';
import { useUnreadNotificationCount } from 'api';
import { NotificationDrawer } from 'routes';
import { FavoriteIcon, StyledBadgeContainer, WrapperHeadIcon } from '../Header.styled';

const NotificationBellComponent: React.FC = () => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  const handleVisible = React.useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  const { isLoading, data: counter, isError, refetch } = useUnreadNotificationCount();
  const unreadNotificationCount = React.useMemo(() => {
    if (!isLoading && counter && !isError) {
      return counter.unreadCount;
    }
  }, [counter, isError, isLoading]);

  return (
    <React.Fragment>
      <WrapperHeadIcon onClick={handleVisible}>
        <StyledBadgeContainer>
          <Badge
            className="customBadge"
            count={unreadNotificationCount}
            size={'default'}
            showZero={false}
          />
        </StyledBadgeContainer>
        <FavoriteIcon />
      </WrapperHeadIcon>

      <NotificationDrawer
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        refreshBadge={refetch}
      />
    </React.Fragment>
  );
};

export const NotificationBell = React.memo(NotificationBellComponent);

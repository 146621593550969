import { useDashboardRequestList } from 'api/DashboardRequestCard';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DashboardRequestCardType } from 'types/DashboardRequestsCard';
import { CardCarousel, CarouselWrapper } from 'ui/components/CardCarousel';
import { useTranslate } from 'translations';
import { CarouselRef } from 'antd/lib/carousel';
import { DashboardRequestCard } from './Components/DashboardRequestCard';

export interface TaskCarouselProps {
  userAdoID: string;
}

const DEFAULT_SLIDES_SHOW = 2.75;

const TaskCarouselComponent: React.FC<TaskCarouselProps> = ({ userAdoID }) => {
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const [scrollsCount, setScrollsCount] = useState<number>(0);

  const carouselEl = useRef<CarouselRef>(null);

  const { data, isLoading, isSuccess } = useDashboardRequestList(userAdoID);

  const beforeChange = (currentSlide: number, nextSlide: number) => setScrollsCount(nextSlide);

  const { t } = useTranslate();

  useEffect(() => {
    if (data && isSuccess) {
      setIsEmpty(data.requestCardList.length === 0);
    }
  }, [data, isSuccess]);

  const cardsLength = useMemo(() => {
    return data && isSuccess ? data.requestCardList.length : 0;
  }, [data, isSuccess]);

  return (
    <CarouselWrapper
      carouselTitle={t('dashboard.myTask.title')}
      emptyDescription={t('dashboard.myTask.empty.description')}
      isEmpty={isEmpty}
      isLoading={isLoading}
      cardLength={cardsLength}
      carouselEL={carouselEl}
      scrollsCount={scrollsCount}
    >
      <CardCarousel
        carouselEL={carouselEl}
        beforeChange={beforeChange}
        slidesShow={DEFAULT_SLIDES_SHOW}
      >
        {data &&
          data.requestCardList.map((request: DashboardRequestCardType, index) => {
            return (
              <DashboardRequestCard
                categoryInfo={request.categoryData}
                requestSummary={request.requestSummary}
                requestTeamMember={request.requestTeamMember}
                requestChartData={request.chartData.weekList}
                key={request.categoryData.processDefKey}
              />
            );
          })}
      </CardCarousel>
    </CarouselWrapper>
  );
};

export const TaskCarousel = React.memo(TaskCarouselComponent);

import styled from 'styled-components';
import { Input } from 'antd';
import { colors } from 'ui/elements';

export const CustomSearchbar = styled(Input)`
  &.ant-input-affix-wrapper {
    border: 1px solid ${colors.darkerLightSilver};
    border-radius: 4px;
    padding: 9px 12px;

    &:hover {
      border: 1px solid ${colors.darkerLightSilver} !important;
    }

    &:focus {
      border: 1px solid ${colors.darkerLightSilver} !important;
      outline: none;
      box-shadow: none;
    }

    &-focused {
      box-shadow: none;
    }
  }

  .anticon svg {
    fill: ${colors.lightSilver};
  }
`;

import styled from 'styled-components';
import { colors, typography } from 'ui/elements';

export const EmployeeDirectoryDetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &.is-loading {
    justify-content: center;
    align-items: center;
  }
`;

export const EmployeeDirectoryDetailsEmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 16px 0 0 0;
    text-align: center;
    font-size: ${typography.size.hecto};
    font-weight: ${typography.weight.bold};
    color: ${colors.black};
    max-width: 185px;
  }
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TaskData, RequestCommonError, RequestStatus } from 'types';
import { AxiosRequestConfig } from 'axios';
import { TaskListState } from './tasksListTypes';

export const emptyTasksListState: TaskListState = {
  status: RequestStatus.idle,
  error: null,
  hasMore: true,
};

export type GetTasksListStart = PayloadAction<AxiosRequestConfig>;
export type GetTasksListSuccess = PayloadAction<TaskData>;
export type GetTasksListFail = PayloadAction<RequestCommonError>;

const tasksList = createSlice({
  name: 'tasksList',
  initialState: emptyTasksListState,
  reducers: {
    getTasksListStart(state, _: GetTasksListStart) {
      state.status = RequestStatus.pending;
    },
    getTasksListSuccess(state, action: GetTasksListSuccess) {
      state.status = RequestStatus.resolved;

      if (action.payload?.length < 10) {
        state.hasMore = false;
      }

      if (!state.data?.length) {
        state.data = action.payload;
        return;
      }

      state.data = [...state.data, ...action.payload];
    },
    getTasksListFail(state, action: GetTasksListFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getTasksListReset(state) {
      state.status = RequestStatus.idle;
      state.data = undefined;
    },
  },
});

export const {
  getTasksListFail,
  getTasksListStart,
  getTasksListSuccess,
  getTasksListReset,
} = tasksList.actions;
export default tasksList.reducer;

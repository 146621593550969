import React, { FC } from 'react';
import { Breadcrumb } from 'antd';
import { BreadCrumbText, OneDriveBreadCrumbStyled } from './OneDriveBreadCrumbs.styled';

interface Props {
  list: BreadcrumbItem[];
  onClick?(id?: string): void;
}

export interface BreadcrumbItem {
  title: string;
  id?: string;
  driveId?: string;
}

export const OneDriveBreadCrumbs: FC<Props> = ({ list, onClick }) => {
  return (
    <OneDriveBreadCrumbStyled separator=">">
      {list.map((crumb, i, array) => {
        const isLast = i === array.length - 1;

        return (
          <Breadcrumb.Item
            key={i}
            onClick={() => {
              if (onClick && !isLast) {
                onClick(crumb.id);
              }
            }}
          >
            <BreadCrumbText type={isLast ? undefined : 'secondary'} {...{ strong: isLast }}>
              {crumb.title}
            </BreadCrumbText>
          </Breadcrumb.Item>
        );
      })}
    </OneDriveBreadCrumbStyled>
  );
};

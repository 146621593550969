import { Core, CREATE_UPLOAD_SESSION_FOR_TEAM } from 'api';

interface UploadNewFileInOneDriveLibrary {
  (
    groupId: string,
    itemId: string,
    values: { description?: string; fileSize: number; name: string }
  ): Promise<{
    data: {
      expirationDateTime: Date;
      uploadUrl: string;
    };
  }>;
}

class UploadNewFileInGroup extends Core {
  uploadNewFileInOneDriveLibrary: UploadNewFileInOneDriveLibrary = async (
    groupId,
    itemId,
    values
  ) => this.post(CREATE_UPLOAD_SESSION_FOR_TEAM(groupId, itemId), values);
}

export const uploadNewFileInGroup = new UploadNewFileInGroup();

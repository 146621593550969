import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetCategoryCardFail,
  GetCategoryCardStart,
  GetCategoryCardSuccess,
  getCategoryCardFail,
  getCategoryCardStart,
  getCategoryCardSuccess,
} from './categoryCardSlice';

export const CategoryCardEpic: Epic<
  GetCategoryCardStart,
  GetCategoryCardSuccess | GetCategoryCardFail,
  RootState,
  typeof API
> = (action$, _, { categoryCard }) =>
  action$.pipe(
    filter(getCategoryCardStart.match),
    exhaustMap(({ payload }) =>
      from(categoryCard.getCategoryCard(payload.categoryName)).pipe(
        map(({ data }) => getCategoryCardSuccess(data)),
        catchError((error) => of(getCategoryCardFail(error?.response?.data)))
      )
    )
  );

import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { profileExpenses } from '../ProfileExpenses';

export function useExpensesAllowance(adoId: string) {
  return useQuery(
    [QUERIES.expensesFormValues, adoId],
    async () => (await profileExpenses.getExpensesAllowance(adoId)).data,
    {
      refetchOnWindowFocus: false,
    }
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { UsersTableData, RequestCommonError, RequestStatus } from 'types';
import { UsersTableState } from './UsersTableTypes';

export const emptyUsersTableState: UsersTableState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetUsersTableStart = PayloadAction<{
  hcmId: string;
  params?: AxiosRequestConfig['params'];
}>;
export type GetUsersTableSuccess = PayloadAction<UsersTableData>;
export type GetUsersTableFail = PayloadAction<RequestCommonError>;

const usersTable = createSlice({
  name: 'usersTable',
  initialState: emptyUsersTableState,
  reducers: {
    getUsersTableStart(state, _: GetUsersTableStart) {
      state.status = RequestStatus.pending;
    },
    getUsersTableSuccess(state, action: GetUsersTableSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getUsersTableFail(state, action: GetUsersTableFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const { getUsersTableStart, getUsersTableSuccess, getUsersTableFail } = usersTable.actions;
export default usersTable.reducer;

import React, { FC, Fragment, memo, useCallback, useEffect, useState } from 'react';
import { Empty, Form, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { VALIDATE_MESSAGES } from 'utils';
import { TType } from 'translations';
import { CashAdvanceName, FullWidthSpin } from 'ui';
import { useCashAdvanceDetail, useCashAdvanceSubmit } from 'api/StaticForm/hooks';
import {
  AttachmentFile,
  attachmentsFieldsId,
} from 'routes/Tasks/components/pages/ServiceNewRequest/ServiceNewRequest';
import { submitAttachmentForm } from 'ui/publicFunction';
import { useHistory } from 'react-router-dom';
import { useHandleSubmitStatus } from 'routes/Tasks';
import { CashAdvanceFields } from './CashAdvanceFields';

export interface CashAdvanceProps {
  formName?: string;
  t: TType;
}

export const CashAdvanceComponent: FC<CashAdvanceProps> = ({ formName, t }) => {
  const [attachmentData, setAttachmentData] = useState<AttachmentFile[]>([]);
  const [form] = useForm();

  const mutation = useCashAdvanceSubmit();
  const history = useHistory();

  const { data: creditCardDetail, isLoading, isError } = useCashAdvanceDetail();

  useEffect(() => {
    if (creditCardDetail?.department) {
      form.setFieldsValue({
        [CashAdvanceName.costCenterDepartment]: creditCardDetail?.department,
      });
    }
  }, [creditCardDetail?.department, form]);

  const onSubmitFinish = useCallback(
    async (variables) => {
      const attachmentsForm: AttachmentFile[] = [];
      await mutation.mutate(variables);

      const currentFormFieldsName = Object.keys(variables);

      const currentAttachmentId = currentFormFieldsName.filter((formVariables: string) => {
        return attachmentsFieldsId.includes(formVariables);
      });
      if (currentAttachmentId && variables) {
        currentAttachmentId.forEach((fieldId: string) => {
          const attachmentValue = variables[fieldId];
          if (attachmentValue) {
            return attachmentsForm.push({ [fieldId]: attachmentValue });
          }
        });
      }

      if (attachmentsForm) {
        setAttachmentData(attachmentsForm);
      }
    },
    [mutation]
  );

  useHandleSubmitStatus(t, mutation.isLoading, mutation.isError, mutation.isSuccess);

  useEffect(() => {
    mutation.data &&
      submitAttachmentForm(mutation.isSuccess, mutation.data, history, undefined, attachmentData);
  }, [attachmentData, history, mutation.data, mutation.isSuccess]);

  return (
    <Fragment>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        validateMessages={VALIDATE_MESSAGES(t)}
        onFinish={onSubmitFinish}
      >
        {isError ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
        ) : isLoading ? (
          <FullWidthSpin />
        ) : (
          <Spin spinning={mutation.isLoading} delay={100}>
            <CashAdvanceFields t={t} formFieldDate={creditCardDetail} form={form} />
          </Spin>
        )}
      </Form>
    </Fragment>
  );
};

export const CashAdvance = memo(CashAdvanceComponent);

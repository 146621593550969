import React, { FC, memo, useCallback, useMemo } from 'react';
import { Spin } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { constants } from 'ui';
import { useRequestCatalogDrawerInfo } from 'api';
import { PagesUrls } from 'namespace';
import {
  StyledLeavesDrawer as StyledRequestCatalogDrawer,
  StyledLeavesWrapper as StyledContentWrapper,
} from 'routes/Calendar/templates/LeavesDrawer';
import { TType, withTranslate } from 'translations';
import { DrawerHeader } from './RequestsCatalogDrawerHeader';
import { DrawerTitle } from './RequestsCatalogDrawerHeaderTitle';
import { DrawerSteps } from './RequestsCatalogDrawerSteps';
import { StyledDivider } from './RequestCatalogDrawerStyle';
import { DrawerPrerequisites } from './RequestsCatalogDrawerPrerequisites';
import { DrawerButtons } from './RequestsCatalogDrawerActionButton';
import { DrawerNoAllowMessage } from './RequestCatalogDrawerNotAllowMessage';

type Props = RouteComponentProps & {
  setProcessDefinitionKey: (processDefinitionKey: string) => void;
  processDefinitionKey: string;
  currentProcessName: string;
  notAllowToCreateRequest?: boolean;
  t: TType;
  isDynamic?: boolean;
  isJira?: boolean;
  disabledMessage?: string;
};

const RequestCatalogDrawerComponent: FC<Props> = ({
  setProcessDefinitionKey,
  processDefinitionKey,
  history,
  currentProcessName,
  notAllowToCreateRequest,
  t,
  isDynamic = true,
  isJira = true,
  disabledMessage,
}) => {
  const { data: drawerInfo } = useRequestCatalogDrawerInfo(currentProcessName);

  const onDrawerClose = useCallback(() => {
    setProcessDefinitionKey('');
  }, [setProcessDefinitionKey]);

  const onNewRequest = useCallback(() => {
    let newRequestUrl = `${PagesUrls.Tasks}/${processDefinitionKey}/${isDynamic}/new-request`;
    if (drawerInfo?.serviceInfo.jiraRequestTypeId && drawerInfo?.serviceInfo.jiraServiceDeskId) {
      newRequestUrl = `${newRequestUrl}/${drawerInfo?.serviceInfo.jiraServiceDeskId}/${drawerInfo?.serviceInfo.jiraRequestTypeId}`;
    }
    history?.push(newRequestUrl);
  }, [
    processDefinitionKey,
    isDynamic,
    drawerInfo?.serviceInfo.jiraRequestTypeId,
    drawerInfo?.serviceInfo.jiraServiceDeskId,
    history,
  ]);

  const onRequestDetail = useCallback(() => {
    const location = {
      pathname: `/tasks/${drawerInfo?.serviceInfo?.existingRequestDetails?.processInstanceId}`,
      state: { defaultTab: '0' },
    };
    history.replace(location);
  }, [drawerInfo?.serviceInfo?.existingRequestDetails?.processInstanceId, history]);

  const messageLinkToRequest = useMemo(() => {
    const existingRequestDetails = drawerInfo?.serviceInfo?.existingRequestDetails;

    if (!existingRequestDetails) {
      return '';
    }

    if (existingRequestDetails?.prettyId) {
      return `${t('tasks.serviceCatalog.clickHereToViewRequest')} ${
        existingRequestDetails.prettyId
      }`;
    }

    return '';
  }, [drawerInfo?.serviceInfo?.existingRequestDetails, t]);

  return (
    <StyledRequestCatalogDrawer
      visible={!!processDefinitionKey}
      closable
      destroyOnClose
      maskClosable
      getContainer={false}
      placement="right"
      onClose={onDrawerClose}
      width={constants.drawer.width}
    >
      {drawerInfo ? (
        <StyledContentWrapper>
          <DrawerTitle>{t('requestCatalog.drawer.title')}</DrawerTitle>
          {notAllowToCreateRequest ? (
            <DrawerNoAllowMessage
              t={t}
              disabledMessage={drawerInfo.serviceInfo.disabledMessage}
              linkToRequestMessage={messageLinkToRequest}
              onPressLinkToRequest={onRequestDetail}
            />
          ) : null}

          <DrawerHeader serviceInfo={drawerInfo.serviceInfo} requestName={currentProcessName} />
          <DrawerSteps
            steps={drawerInfo?.steps}
            t={t}
            additionalSteps={drawerInfo?.additionalSteps}
          />
          {drawerInfo?.prerequisites ? (
            <>
              <StyledDivider />
              <DrawerPrerequisites prerequisites={drawerInfo?.prerequisites} t={t} />
            </>
          ) : null}

          <DrawerButtons
            onNewRequest={onNewRequest}
            t={t}
            hideCreateBtn={notAllowToCreateRequest}
            processPolicy={drawerInfo.serviceInfo.processPolicy}
          />
        </StyledContentWrapper>
      ) : (
        <Spin />
      )}
    </StyledRequestCatalogDrawer>
  );
};

export const RequestCatalogDrawer = memo(withRouter(withTranslate(RequestCatalogDrawerComponent)));

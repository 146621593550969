import styled from 'styled-components';
import { Image, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

interface StatusColors {
  green: string;
  gray: string;
  red: string;
  yellow: string;
}

const STATUS_COLORS: StatusColors = {
  green: '#00AC69',
  gray: '#CED1DA',
  red: '#c73904',
  yellow: '#e9bc00',
};

export const AvatarPlaceholder = styled(UserOutlined)`
  color: #f5f5f5;
`;

export const StyledImage = styled(Image)`
  object-fit: cover;
`;

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledAvatar = styled(Avatar)`
  .ant-image,
  .ant-image-img {
    width: inherit;
    height: inherit;
  }
  .ant-avatar-lg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledUserStatus = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: ${(props: { color: keyof StatusColors; statusSize?: number }) =>
    props.statusSize ? `${props.statusSize}px` : '14px'};
  height: ${(props: { color: keyof StatusColors; statusSize?: number }) =>
    props.statusSize ? `${props.statusSize}px` : '14px'};
  border: 2px solid white;
  border-radius: 50%;
  background-color: ${(props: { color: keyof StatusColors }) => STATUS_COLORS[props.color]};
  z-index: 1;
`;

import { Row, Col, Card } from 'antd';
import styled from 'styled-components';
import { space } from 'ui/elements';

export const StyledLogoutRow = styled(Row)`
  cursor: pointer;
`;

export const StyledLogoutIconCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const StyledSettingsCard = styled(Card)`
  margin: ${space.moderate} ${space.normal};

  &.fill-rest-height {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .ant-card-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
`;

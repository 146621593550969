import styled from 'styled-components';

import { Button, Col, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { borderRadius, colors, space, typography } from 'ui/elements';

const { Text } = Typography;
export const StyledLinkButton = styled(Button)`
  padding: 0px;
`;

export const StylePlusIcon = styled(PlusOutlined)`
  background: ${(props: { bgColor?: string }) => props.bgColor ?? colors?.primaryColor};
  border-radius: ${borderRadius.small};
  color: ${colors.white};
  padding: 3px;
  vertical-align: middle;
  font-size: ${typography.size.giga};
`;

export const StyledCreateNewRequest = styled(Text)`
  font-size: ${typography.size.kilo};
  color: ${(props: { color?: string }) => props.color ?? colors?.primaryColor};
  margin-left: ${space.moderate};
`;

export const StyledCardFile = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${(props: { bgColor?: string }) => props.bgColor ?? colors?.greyBackLabel};
  border-radius: ${borderRadius?.large};
  margin-bottom: ${space.slim};
  cursor: pointer;
  transition: border-left 0.3s ease;

  &:hover,
  &:focus {
    border-left: 3px solid #fc4c02;
  }
`;

export const StyledHeaderWidgetCol = styled(Col)`
  margin-bottom: ${space.moderate};
`;

export const StyledPrettyIdText = styled(Text).attrs({})`
  font-size: ${typography.size.uno};
  color: ${(props: { color?: string }) => props.color ?? colors?.lightActive};
`;

export const StyledSpan = styled.span`
  width: 100%;
  text-align-last: start;
`;

export const StatusRequestTag = styled.span`
  border-radius: 20px;
  height: 22px;
  line-height: 20px;
  padding: 3px 14px;
  white-space: nowrap;
  color: ${colors.skyColor};
  border: solid 1px ${colors.skyColor};
`;

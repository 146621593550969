import React, { useCallback, useMemo } from 'react';
import { NotificationList, RequestStatus } from 'types';
import {
  getAllNotificationUpdatesListRest,
  getAllNotificationUpdatesListStart,
  allNotificationUpdatesListSelector,
  useDispatch,
  useSelector,
} from 'store';

const sizeOfAllNotificationUpdatesList = 10;
export const useSeeAllUpdateDrawerInfo = (
  requestId: string,
  isOpen: boolean,
  // eslint-disable-next-line
  setIsOpen: React.Dispatch<React.SetStateAction<any>>
) => {
  const dispatch = useDispatch();
  const allNotificationUpdatesList = useSelector(allNotificationUpdatesListSelector);

  const fetchAllNotificationUpdatesList = React.useCallback(
    (page = 0) => {
      const requestProps = {
        page: page,
        size: sizeOfAllNotificationUpdatesList,
      };
      dispatch(getAllNotificationUpdatesListStart({ requestId, params: requestProps }));
    },
    [dispatch, requestId]
  );
  const reFreshAllNotificationUpdatesList = useCallback(() => {
    dispatch(getAllNotificationUpdatesListRest());
  }, [dispatch]);
  const isThereData = useMemo(() => !allNotificationUpdatesList.data, [allNotificationUpdatesList]);
  const isDrawerLoading = useMemo(
    () => allNotificationUpdatesList.status === RequestStatus.pending,
    [allNotificationUpdatesList.status]
  );
  const hasMore = useMemo(
    () =>
      allNotificationUpdatesList.data?.list.length !==
        allNotificationUpdatesList.data?.totalResult &&
      allNotificationUpdatesList.status !== RequestStatus.pending,
    [
      allNotificationUpdatesList.data?.list.length,
      allNotificationUpdatesList.data?.totalResult,
      allNotificationUpdatesList.status,
    ]
  );
  const isErrorFetchAllNotificationUpdatesList = useMemo(
    () => allNotificationUpdatesList.status === RequestStatus.rejected,
    [allNotificationUpdatesList.status]
  );
  const loadMoreNotificationUpdates = useCallback(() => {
    if (!hasMore || allNotificationUpdatesList.status === RequestStatus.pending) {
      return;
    }
    if (allNotificationUpdatesList.data) {
      fetchAllNotificationUpdatesList(allNotificationUpdatesList.data.offset + 1);
    }
  }, [
    fetchAllNotificationUpdatesList,
    hasMore,
    allNotificationUpdatesList.data,
    allNotificationUpdatesList.status,
  ]);
  const listLength = useMemo(() => allNotificationUpdatesList.data?.offset, [
    allNotificationUpdatesList.data?.offset,
  ]);
  // eslint-disable-next-line
  const onClose = (e: any) => {
    setIsOpen(e);
    reFreshAllNotificationUpdatesList();
  };
  const filterList = useMemo(() => {
    if (allNotificationUpdatesList.data?.list && !isDrawerLoading) {
      return allNotificationUpdatesList.data?.list.reduce((acc, item) => {
        return [...(acc ?? []), item];
      }, [] as NotificationList[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotificationUpdatesList.data?.list]);
  React.useEffect(() => {
    if (isOpen && allNotificationUpdatesList.status === RequestStatus.idle) {
      fetchAllNotificationUpdatesList(0);
    }
  }, [
    fetchAllNotificationUpdatesList,
    isOpen,
    allNotificationUpdatesList,
    reFreshAllNotificationUpdatesList,
  ]);
  return {
    filterList,
    isDrawerLoading,
    reFreshAllNotificationUpdatesList,
    loadMoreNotificationUpdates,
    hasMore,
    listLength,
    onClose,
    isErrorFetchAllNotificationUpdatesList,
    isThereData,
  };
};

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetMyRequestCardsFail,
  GetMyRequestCardsStart,
  GetMyRequestCardsSuccess,
  getMyRequestCardsFail,
  getMyRequestCardsStart,
  getMyRequestCardsSuccess,
} from './myRequestCardsSlice';

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';
export const MyRequestCardsEpic: Epic<
  GetMyRequestCardsStart,
  GetMyRequestCardsSuccess | GetMyRequestCardsFail,
  RootState,
  typeof API
> = (action$, _, { servicesCards }) =>
  action$.pipe(
    filter(getMyRequestCardsStart.match),
    exhaustMap((action) =>
      from(
        disableAggregator
          ? servicesCards.getMyRequestCards(action.payload.userId, action.payload.params)
          : servicesCards.getMyRequestCardsAgg(action.payload.params)
      ).pipe(
        map(({ data }) => {
          return getMyRequestCardsSuccess(data);
        }),
        catchError((error) => of(getMyRequestCardsFail(error?.response?.data)))
      )
    )
  );

import { UserInfoData } from 'types';
import { Core, PROFILE_INFO_CARD } from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetUserInfoCard {
  (data?: { params: AxiosRequestConfig['params'] }): Promise<{ data: UserInfoData }>;
}

class UserInfoCard extends Core {
  getUserInfoCard: GetUserInfoCard = async (data) => this.get(PROFILE_INFO_CARD(), data);
}

export const userInfoCard = new UserInfoCard();

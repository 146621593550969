import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { AxiosRequestConfig } from 'axios';

import { countryCityList } from '../CountryCityList';

export function useCountryCityList(config?: AxiosRequestConfig) {
  return useQuery(
    [QUERIES.countryCityList, config?.params],
    async () => {
      const { data } = await countryCityList.getCountryCityList(config);
      return data;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );
}

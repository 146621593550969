import React, { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'translations';
import { isContractorEmployee } from 'store';
import { constants } from 'ui/elements';
import { ApplistItem } from 'types';
import { appDrawerMenuItems, contractorDrawerMenuItems } from 'api/mocks/data/appdrawermenus';
import { Col } from 'antd';
import {
  StyledAppsDrawer,
  StyledContentWrapper,
  StyledDrawerAppsRow,
  StyledTitleDrawer,
  StyledSecondaryTitle,
} from './AppsDrawer.styled';

import { AppCard } from './Components';

interface Props {
  appsList: ApplistItem[];
  onDrawerClose: () => void;
}

export const AppsDrawerComponent: FC<Props> = ({ appsList, onDrawerClose }) => {
  const { t } = useTranslate();
  const isContractor = useSelector(isContractorEmployee);
  const drawerMenuItems = isContractor ? contractorDrawerMenuItems : appDrawerMenuItems;
  const sortedAppsList = useMemo(() => {
    return appsList.sort((a, b) => (a.orderNo > b.orderNo ? 1 : -1));
  }, [appsList]);

  return (
    <>
      <StyledAppsDrawer
        visible={!!appsList}
        closable
        maskClosable
        destroyOnClose
        getContainer={false}
        placement="right"
        onClose={onDrawerClose}
        width={constants.drawer.width}
        title={<StyledTitleDrawer>{t('dashboard.app.allApps')}</StyledTitleDrawer>}
      >
        <StyledContentWrapper>
          <StyledSecondaryTitle>{t('dashboard.app.drawer.title.internal')}</StyledSecondaryTitle>
          <StyledDrawerAppsRow gutter={[13, 15]}>
            {drawerMenuItems.map(({ key, linkTo, query, linkText, icon: Icon, isShow }) => {
              return (
                isShow && (
                  <Col>
                    <AppCard
                      key={key}
                      appName={linkText}
                      Icon={Icon}
                      isExternalApp
                      t={t}
                      linkTo={linkTo}
                      query={query}
                      onClickHandler={onDrawerClose}
                    />
                  </Col>
                )
              );
            })}
          </StyledDrawerAppsRow>
          <StyledSecondaryTitle>{t('dashboard.app.drawer.title.external')}</StyledSecondaryTitle>
          <StyledDrawerAppsRow gutter={[13, 15]}>
            {sortedAppsList.map(({ appName, url, id, iconUri }) => {
              return (
                <Col key={id}>
                  <AppCard
                    appName={appName}
                    iconURL={iconUri}
                    isExternalApp
                    t={t}
                    webUrl={url}
                    onClickHandler={onDrawerClose}
                  />
                </Col>
              );
            })}
          </StyledDrawerAppsRow>
        </StyledContentWrapper>
      </StyledAppsDrawer>
    </>
  );
};

export const AppsDrawer = memo(AppsDrawerComponent);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus, RequestCommonError, LibraryFile } from 'types';
import { LibraryFilesState as initialState } from './oneDriveSharedLibraryFolderListTypes';

export const emptyOneDriveSharedLibraryFoldersListState: initialState = {};
export type GetOneDriveSharedLibraryFoldersListStart = PayloadAction<{
  groupId: string;
  folderId: string;
}>;
export type GetOneDriveSharedLibraryFoldersListSuccess = PayloadAction<{
  groupId: string;
  folderId: string;
  data: LibraryFile[];
}>;
export type GetOneDriveSharedLibraryFoldersListFail = PayloadAction<{
  groupId: string;
  folderId: string;
  error: RequestCommonError;
}>;

const LibraryFolders = createSlice({
  name: 'oneDriveSharedLibraryFoldersList',
  initialState: emptyOneDriveSharedLibraryFoldersListState,
  reducers: {
    getOneDriveSharedLibraryFoldersListStart(
      state,
      action: GetOneDriveSharedLibraryFoldersListStart
    ) {
      if (state[action.payload.groupId]) {
        if (state[action.payload.groupId][action.payload.folderId]) {
          state[action.payload.groupId][action.payload.folderId].status = RequestStatus.pending;

          return;
        }

        state[action.payload.groupId] = {
          ...state[action.payload.groupId],
          [action.payload.folderId]: {
            error: null,
            status: RequestStatus.pending,
          },
        };

        return;
      }

      state[action.payload.groupId] = {
        [action.payload.folderId]: {
          error: null,
          status: RequestStatus.pending,
        },
      };
    },
    getOneDriveSharedLibraryFoldersListSuccess(
      state,
      action: GetOneDriveSharedLibraryFoldersListSuccess
    ) {
      state[action.payload.groupId][action.payload.folderId].status = RequestStatus.resolved;
      state[action.payload.groupId][action.payload.folderId].data = action.payload.data;
    },
    getOneDriveSharedLibraryFoldersListFail(
      state,
      action: GetOneDriveSharedLibraryFoldersListFail
    ) {
      state[action.payload.groupId][action.payload.folderId].status = RequestStatus.rejected;
      state[action.payload.groupId][action.payload.folderId].error = action.payload.error;
    },
  },
});
export const {
  getOneDriveSharedLibraryFoldersListFail,
  getOneDriveSharedLibraryFoldersListStart,
  getOneDriveSharedLibraryFoldersListSuccess,
} = LibraryFolders.actions;

export default LibraryFolders.reducer;

import { useAuthContext } from 'authentication';
import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslate } from 'translations';
import { WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

export const useAppLayoutAlert = (): {
  isAlert: boolean;
  alertMessage: string;
  refreshToken: () => void;
  alertIcon: ReactNode;
} => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentDate = new Date();

  const { tokenExpireTime, getToken, requestedUrl } = useAuthContext();
  const { t } = useTranslate();

  const isAlert = useMemo(() => (tokenExpireTime ? currentDate >= tokenExpireTime : false), [
    currentDate,
    tokenExpireTime,
  ]);

  const alertMessage = useMemo(() => (tokenExpireTime ? t('layout.alert.tokenExpire') : ''), [
    t,
    tokenExpireTime,
  ]);

  const refreshToken = useCallback(async () => {
    getToken(requestedUrl);
  }, [getToken, requestedUrl]);

  const alertIcon = useMemo(
    // eslint-disable-next-line react/react-in-jsx-scope
    () => (tokenExpireTime ? <WarningOutlined /> : <ExclamationCircleOutlined />),
    [tokenExpireTime]
  );

  return { isAlert, alertMessage, refreshToken, alertIcon };
};

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../reducers';

export const camundaUsersSelector = (state: RootState): RootState['camundaUsers'] =>
  state.camundaUsers;

export const camundaUsersDataSelector = createSelector(
  camundaUsersSelector,
  (camundaUsers) => camundaUsers
);

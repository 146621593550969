import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { leaves } from '../Leaves';

const disableDelegation = process.env.REACT_APP_DISABLE_DELEGATION === 'true';

export function useLeavesDelegates() {
  return useQuery(
    [QUERIES.getLeavesDelegators],
    async () => {
      const { data } = await leaves.getDelegatorsList();
      return data;
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      enabled: !disableDelegation,
    }
  );
}

import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { LeaveStatus } from 'types/LeavesStatus';
import { useTranslate } from 'translations';
import {
  ColorCode,
  convertColourNameToHex,
  getLeaveStatusColor,
  getLeaveStatusText,
} from 'routes/Calendar';
import {
  CalendarHeaderWrapper,
  ListDot,
  ListStatus,
  StyledText,
  UlStatus,
} from './CalendarHeaderStyled';

const leaveStatusList: LeaveStatus[] = [
  LeaveStatus.planned,
  LeaveStatus.awaitingApproval,
  LeaveStatus.scheduled,
  LeaveStatus.inProgress,
  LeaveStatus.completed,
];
const MyTeamleaveStatusList: LeaveStatus[] = [
  LeaveStatus.awaitingApproval,
  LeaveStatus.scheduled,
  LeaveStatus.inProgress,
  LeaveStatus.completed,
];

interface Props {
  isMyTeamLeave?: boolean;
}

export const CalendarHeader: FC<Props> = ({ isMyTeamLeave }) => {
  const { t } = useTranslate();
  return (
    <CalendarHeaderWrapper>
      <Row justify={'space-between'} align={'middle'}>
        <Col span={4}>
          <StyledText>
            {isMyTeamLeave
              ? t('leaves.calendarView.title.leaves')
              : t('leaves.calendarView.title.myLeaves')}
          </StyledText>
        </Col>
        <Col span={20}>
          <UlStatus>
            {isMyTeamLeave
              ? MyTeamleaveStatusList.map((list: LeaveStatus, index) => {
                  return (
                    <ListStatus key={index}>
                      <ListDot
                        $color={convertColourNameToHex(getLeaveStatusColor(list) as ColorCode)}
                      />
                      {getLeaveStatusText(t)[list]}
                    </ListStatus>
                  );
                })
              : leaveStatusList.map((list: LeaveStatus, index) => (
                  <ListStatus key={index}>
                    <ListDot
                      $color={convertColourNameToHex(getLeaveStatusColor(list) as ColorCode)}
                    />
                    {getLeaveStatusText(t)[list]}
                  </ListStatus>
                ))}
          </UlStatus>
        </Col>
      </Row>
    </CalendarHeaderWrapper>
  );
};

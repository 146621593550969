import { UserProfileStatus } from 'types';
import { Core, USER_STATUS, OTHER_USER_STATUS } from 'api';

interface GetUserStatus {
  (): Promise<{ data: UserProfileStatus }>;
}
interface GetOtherUserStatus {
  (adoId: string): Promise<{ data: UserProfileStatus }>;
}

class UserActiveStatus extends Core {
  getUserStatus: GetUserStatus = async () => this.get(USER_STATUS());
  getOtherUserStatus: GetOtherUserStatus = async (adoId: string) =>
    this.get(OTHER_USER_STATUS(adoId));
}

export const userStatus = new UserActiveStatus();

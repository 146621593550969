export const measureSizeOfFile = (size: number) => {
  if (size === 0) {
    return size + ' Mb';
  } else if (size > 0 && size < 1048576) {
    const newSize: number = size / 1024;
    return Number.parseInt(newSize.toString()) + ' Kb';
  } else if (size >= 1048576 && size < 1073741824) {
    const newSize: number = size / 1048576;
    return Number.parseInt(newSize.toString()) + ' Mb';
  } else if (size >= 1073741824) {
    const newSize: number = size / 1073741824;
    return Number.parseInt(newSize.toString()) + ' Gb';
  }
};

import { useMutation } from 'react-query';
import { AnnouncementCardsListResponse, AnnouncementCardType } from 'types';
import { announcements } from '../Announcements';

const refineAnnouncementsList: (
  response: AnnouncementCardsListResponse
) => AnnouncementCardType[] = (response) => {
  const cards: AnnouncementCardType[] = [];
  response.componentOrder.forEach((componentName) => {
    if (response.componentData[componentName] && response.componentData[componentName].length > 0) {
      cards.push({
        ...response.componentData[componentName][0].infoCard,
        cardName: componentName,
      });
    }
  });
  return cards;
};

export const useAnnouncementsList = () => {
  return useMutation<AnnouncementCardType[], unknown, void, unknown>(async () => {
    const { data } = await announcements.getAnnouncementsList();
    return refineAnnouncementsList(data);
  });
};

import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  GetOneDriveFolderSharedStart,
  GetOneDriveFolderSharedSuccess,
  GetOneDriveFolderSharedFail,
  getOneDriveFolderSharedStart,
  getOneDriveFolderSharedSuccess,
  getOneDriveFolderSharedFail,
} from './oneDriveFolderSharedSlice';

export const getOneDriveFolderSharedEpic: Epic<
  GetOneDriveFolderSharedStart,
  GetOneDriveFolderSharedSuccess | GetOneDriveFolderSharedFail,
  RootState,
  typeof API
> = (action$, _, { oneDriveFolderShared }) =>
  action$.pipe(
    filter(getOneDriveFolderSharedStart.match),
    mergeMap(({ payload }) =>
      from(oneDriveFolderShared.getOneDriveFolderShared(payload.driveId, payload.folderId)).pipe(
        map(({ data }) =>
          getOneDriveFolderSharedSuccess({
            driveId: payload.driveId,
            folderId: payload.folderId,
            data,
          })
        ),
        catchError((error) =>
          of(
            getOneDriveFolderSharedFail({
              driveId: payload.driveId,
              folderId: payload.folderId,
              error: error?.response?.data,
            })
          )
        )
      )
    )
  );

import React, { FC, useMemo } from 'react';
import { Col, Row, Space } from 'antd';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { span } from 'ui/elements';

import {
  StyledAssignedToCol,
  StyledTaskAvatar,
} from 'ui/components/TaskList/TaskCard/TaskCard.styled';
import { AttendanceInfoData } from 'types';
import { CheckInIcon, CheckOutIcon } from 'assets';
import { TType } from 'translations';
import moment from 'moment';
import { UserPopover } from 'ui/components';
import { AttendanceDetail } from '../AttendanceDetail';
import { ATTENDANCE_CARD_BREAKPOINT } from '../helper';
import { StyledAttendanceWrapper, StyledOnLeaveText } from './TeamMember.styled';

const { span24 } = span;

interface TeamMemberProps {
  firstName: string;
  lastName: string;
  email: string;
  adOid: string;
  attendanceDetail: AttendanceInfoData;
  t: TType;
}

export const TeamMember: FC<TeamMemberProps> = ({
  firstName,
  lastName,
  email,
  adOid,
  attendanceDetail,
  t,
}) => {
  const memberFullName = `${firstName} ${lastName}`;

  const attendanceState = useMemo(
    () =>
      attendanceDetail
        ? attendanceDetail.isAbsence
          ? t(`myTeamAttendance.card.isAbsence`)
          : attendanceDetail.isHoliday
          ? t('myTeamAttendance.card.isHoliday')
          : attendanceDetail.isOnLeave
          ? `${t('myTeamAttendance.card.onLeave')} ${attendanceDetail.absenseName}`
          : attendanceDetail.isWFH
          ? t('myTeamAttendance.card.wfh')
          : attendanceDetail.isWeekend
          ? t('myTeamAttendance.card.isWeekend')
          : null
        : null,
    [attendanceDetail, t]
  );

  const showDayAttendance = useMemo(
    () =>
      attendanceDetail.isAbsence ||
      attendanceDetail.isHoliday ||
      attendanceDetail.isOnLeave ||
      attendanceDetail.isWeekend,

    [
      attendanceDetail.isAbsence,
      attendanceDetail.isHoliday,
      attendanceDetail.isOnLeave,
      attendanceDetail.isWeekend,
    ]
  );

  const checkInDetail = useMemo(
    () =>
      attendanceDetail.checkInTime
        ? attendanceDetail.checkInTime
        : t('myTeamAttendance.card.noCheckIn'),
    [attendanceDetail.checkInTime, t]
  );

  const inOffice = useMemo(
    () =>
      !attendanceDetail.checkOutTime &&
      attendanceDetail.currentDate === moment().format('DD-MM-YYYY'),
    [attendanceDetail.checkOutTime, attendanceDetail.currentDate]
  );

  const checkOutDetail = useMemo(
    () =>
      attendanceDetail.checkOutTime
        ? attendanceDetail.checkOutTime
        : inOffice
        ? t('myTeamAttendance.card.checkOut.inOffice')
        : t('myTeamAttendance.card.noCheckOut'),
    [attendanceDetail.checkOutTime, inOffice, t]
  );

  return (
    <React.Fragment>
      <Space align={'baseline'}>
        <UserPopover currentID={adOid}>
          <StyledTaskAvatar
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <MSTeamsUserAvatar id={adOid} name={memberFullName} />
          </StyledTaskAvatar>
        </UserPopover>
        <Row>
          <Col span={span24}>
            <UserPopover currentID={adOid} currentEmail={email}>
              <StyledAssignedToCol
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {memberFullName}
              </StyledAssignedToCol>
            </UserPopover>
          </Col>
          {attendanceState && (
            <Col span={span24}>
              <StyledOnLeaveText>{attendanceState}</StyledOnLeaveText>
            </Col>
          )}
        </Row>
      </Space>
      {!showDayAttendance && (
        <StyledAttendanceWrapper>
          <Col {...ATTENDANCE_CARD_BREAKPOINT}>
            <Space align={'start'}>
              <CheckInIcon />
              <AttendanceDetail
                attendanceName={t('myTeamAttendance.card.checkIn')}
                attendanceTime={checkInDetail}
              />
            </Space>
          </Col>
          <Col {...ATTENDANCE_CARD_BREAKPOINT}>
            <Space align={'start'}>
              <CheckOutIcon />
              <AttendanceDetail
                attendanceName={t('myTeamAttendance.card.checkOut')}
                attendanceTime={checkOutDetail}
                inOffice={inOffice}
              />
            </Space>
          </Col>
        </StyledAttendanceWrapper>
      )}
    </React.Fragment>
  );
};

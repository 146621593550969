import { useMutation } from 'react-query';
import { NonPoInvoiceSubmitResponse, NonPoSubmitExpense } from 'types';
import { nonPoInvoice } from '../NONPoInvoice';

export const useNonPoInvoiceSubmitExpenseApi = () => {
  return useMutation<NonPoInvoiceSubmitResponse, unknown, NonPoSubmitExpense, unknown>(
    async (params) => {
      const { data } = await nonPoInvoice.postNonPoInvoiceSubmitExpense(params);
      return data;
    }
  );
};

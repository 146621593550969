import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, TeamsChannelFolderData, RequestStatus } from 'types';
import { TeamsFoldersState } from './teamsChannelFolderListTypes';

export const emptyTeamsChannelFolderState: TeamsFoldersState = {};
export type GetTeamsChannelFolderStart = PayloadAction<{ teamId: string; folderId: string }>;
export type GetTeamsChannelFolderSuccess = PayloadAction<{
  teamId: string;
  folderId: string;
  data: TeamsChannelFolderData;
}>;
export type GetTeamsChannelFolderFail = PayloadAction<{
  teamId: string;
  folderId: string;
  error: RequestCommonError;
}>;

const teamsFolder = createSlice({
  name: 'teamsChannelFolder',
  initialState: emptyTeamsChannelFolderState,
  reducers: {
    getTeamsChannelFolderStart(state, action: GetTeamsChannelFolderStart) {
      if (state[action.payload.teamId]) {
        if (state[action.payload.teamId][action.payload.folderId]) {
          state[action.payload.teamId][action.payload.folderId].status = RequestStatus.pending;

          return;
        }

        state[action.payload.teamId] = {
          ...state[action.payload.teamId],
          [action.payload.folderId]: {
            error: null,
            status: RequestStatus.pending,
          },
        };

        return;
      }

      state[action.payload.teamId] = {
        [action.payload.folderId]: {
          error: null,
          status: RequestStatus.pending,
        },
      };
    },
    getTeamsChannelFolderSuccess(state, action: GetTeamsChannelFolderSuccess) {
      state[action.payload.teamId][action.payload.folderId].status = RequestStatus.resolved;
      state[action.payload.teamId][action.payload.folderId].data = action.payload.data;
    },
    getTeamsChannelFolderFail(state, action: GetTeamsChannelFolderFail) {
      state[action.payload.teamId][action.payload.folderId].status = RequestStatus.rejected;
      state[action.payload.teamId][action.payload.folderId].error = action.payload.error;
    },
  },
});

export const {
  getTeamsChannelFolderStart,
  getTeamsChannelFolderSuccess,
  getTeamsChannelFolderFail,
} = teamsFolder.actions;

export default teamsFolder.reducer;

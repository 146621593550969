import { Tabs } from 'antd';
import styled from 'styled-components';

import { colors } from 'ui/elements';
import { StarOutlined, DownOutlined } from '@ant-design/icons';

export const StyledTabsWrapper = styled.div`
  background-color: ${colors.white};
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    height: 56px;
    margin-bottom: 0;
    padding: 0 24px;
    border-bottom: 1px solid ${colors.borderGreySecond};

    &::before {
      display: none;
    }
  }
`;

export const StyledPlanTitle = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
`;

export const StyledPlanTitleColumn = styled.div`
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1;
`;

export const StyledPlanProjectTitle = styled.div`
  font-size: 12px;
  line-height: 1;
  color: ${colors.lightGrey};
`;

export const StyledStarIcon = styled(StarOutlined)`
  color: ${colors.lightGrey};
  cursor: pointer;
  transition: color 0.3s ease;

  :hover {
    color: ${colors.primaryColor};
  }

  svg {
    width: 14px;
  }
`;

export const StyledDownIcon = styled(DownOutlined)`
  color: ${colors.primaryColor};

  svg {
    width: 9px;
  }
`;

import { Space, Typography, Tooltip } from 'antd';
import React from 'react';
import { colors } from 'ui/elements';
import styled from 'styled-components';

const TooltipStyled = styled(Tooltip)`
  .ant-tooltip-open {
    border-radius: 3px;
  }
`;

interface Props {
  label: string;
  labelDescription: string;
  Icon?: React.ReactNode;
}

export const DescriptionLabel: React.FC<Props> = ({ label, labelDescription, Icon }) => {
  const { Text } = Typography;
  return (
    <React.Fragment>
      <Space direction={'horizontal'} size={7}>
        <Text style={{ color: colors.textColorSecondary }}> {label}</Text>
        {Icon !== undefined ? (
          <TooltipStyled title={labelDescription} color={colors.primaryColor}>
            {Icon}
          </TooltipStyled>
        ) : (
          <Text type={'secondary'}>{labelDescription}</Text>
        )}
      </Space>
    </React.Fragment>
  );
};

import React, { FC, useEffect, useState } from 'react';
import { Collapse, CollapsePanelProps, notification } from 'antd';
import { IELibraryDepartmentListBySector } from 'types';
import { useELibraryDepartments } from 'api';
import { FullWidthSpin } from 'ui/elements';
import { StyledELibraryDepartmentsCollapseDepartment } from '../ELibrary.styled';

const { Panel } = Collapse;

type Props = {
  sector: string;
  isFirstSector: boolean;
  isExpanded: boolean;
  onDepartmentSelect: (departmentName: string, sectorName: string) => void;
  selectedDepartmentName?: string;
  isFilterApplied: boolean;
} & CollapsePanelProps;

export const ELibraryDepartmentCollapsePanel: FC<Props> = ({
  sector,
  isFirstSector,
  isExpanded,
  onDepartmentSelect,
  selectedDepartmentName,
  isFilterApplied,
  ...panelProps
}) => {
  const [departments, setDepartments] = useState<IELibraryDepartmentListBySector>([]);
  const { mutateAsync: getSectorDepartments, isLoading } = useELibraryDepartments();
  useEffect(() => {
    if (!departments.length && isExpanded) {
      getSectorDepartments(sector)
        .then(setDepartments)
        .catch(() => {
          notification.error({
            message: 'Something went wrong',
          });
        });
    }
  }, [departments, getSectorDepartments, isExpanded, sector]);

  useEffect(() => {
    if (isFirstSector && departments.length && !selectedDepartmentName && !isFilterApplied) {
      onDepartmentSelect(departments[0], sector);
    }
  }, [
    departments,
    isFilterApplied,
    isFirstSector,
    onDepartmentSelect,
    sector,
    selectedDepartmentName,
  ]);

  return (
    <Panel {...panelProps}>
      {isLoading ? (
        <FullWidthSpin />
      ) : (
        departments.map((department) => (
          <StyledELibraryDepartmentsCollapseDepartment
            key={`department-${department}`}
            className={selectedDepartmentName === department ? 'selected-department' : undefined}
            onClick={() => onDepartmentSelect(department, sector)}
          >
            {department}
          </StyledELibraryDepartmentsCollapseDepartment>
        ))
      )}
    </Panel>
  );
};

import React, { useCallback, useEffect, useRef } from 'react';
import { Col, notification, Row } from 'antd';
import moment from 'moment';
import { UsergroupAddOutlined } from '@ant-design/icons';

import { FullWidthAndHeightSpin, UserPopover } from 'ui';
import { ChatType, RequestStatus } from 'types';
import {
  useDispatch,
  useSelector,
  getChatsStart,
  chatsData,
  getChatMessagesStart,
  profileData,
} from 'store';
import { getMembersTitleList } from 'utils';
import { useTranslate } from 'translations';
import LazyLoad from 'react-lazyload';
import { useMSTeamsContext } from '../../MSTeamsProvider';
import { MSTeamsUserAvatar } from '../../MSTeamsUserAvatar';
import {
  StyledMessageListWrapper,
  StyledMessageRow,
  StyledRow,
  StyledCol,
  StyledAvatarCol,
  StyledMessageDate,
  StyledChatGroupAvatar,
  StyledChatName,
} from './MSTeamsChatSidebar.styled';

const IMAGE_SIZE = 32;

export const MSTeamsChatSidebar = () => {
  const dispatch = useDispatch();
  const {
    setCurrentChatId,
    currentChatId,
    currentSearchUserId,
    setCurrentSearchUserId,
  } = useMSTeamsContext();
  const { data: userData } = useSelector(profileData);
  const { data, getChatsStatus } = useSelector(chatsData);
  const { chats } = data;
  const { t } = useTranslate();
  const selectedRowRef = useRef<HTMLDivElement>(null);

  const selectNewChat = useCallback(
    (chatId) => {
      setCurrentChatId(chatId);
      dispatch(getChatMessagesStart(chatId));
    },
    [setCurrentChatId, dispatch]
  );

  useEffect(() => {
    dispatch(getChatsStart());
  }, [dispatch]);

  useEffect(() => {
    if (getChatsStatus === RequestStatus.rejected) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [getChatsStatus, t]);

  useEffect(() => {
    if (chats?.length && currentSearchUserId) {
      const chatWithSearchUser = chats.find(
        (chat) =>
          chat.chatType === ChatType.OneOnOne &&
          chat.members &&
          chat.members.some((member) => member.userId === currentSearchUserId)
      );

      if (chatWithSearchUser) {
        setCurrentChatId(chatWithSearchUser.id);
        selectNewChat(chatWithSearchUser.id);
      }
    }
  }, [currentSearchUserId, setCurrentChatId, selectNewChat, chats]);

  return (
    <StyledMessageListWrapper id="MSTeamsChatsWrapper">
      {getChatsStatus === RequestStatus.pending && (
        <Row gutter={[24, 24]} justify="center">
          <Col>
            <FullWidthAndHeightSpin />
          </Col>
        </Row>
      )}

      {getChatsStatus === RequestStatus.resolved && chats?.length
        ? chats.map((item) => {
            const listChatMembers = getMembersTitleList(item.members);
            const chatMember = item.members.find((item) => item.userId !== userData?.adOid);
            const chatTopic =
              item.topic && item.topic.length > 30 ? `${item.topic.slice(0, 30)}...` : item.topic;

            return (
              <StyledMessageRow
                selected={currentChatId === item.id}
                ref={currentChatId === item.id ? selectedRowRef : undefined}
                key={item.id}
                onClick={() => {
                  selectNewChat(item.id);
                  setCurrentSearchUserId('');
                }}
              >
                <StyledAvatarCol>
                  {item.members.length > 2 ? (
                    <StyledChatGroupAvatar id={item.id} icon={<UsergroupAddOutlined />} />
                  ) : (
                    <UserPopover currentID={chatMember?.userId}>
                      <LazyLoad height={IMAGE_SIZE} scrollContainer="#MSTeamsChatsWrapper">
                        <MSTeamsUserAvatar
                          id={chatMember?.userId}
                          name={chatMember?.displayName}
                          size={IMAGE_SIZE}
                          statusSize={10}
                        />
                      </LazyLoad>
                    </UserPopover>
                  )}
                </StyledAvatarCol>
                <StyledCol>
                  <StyledRow justify="space-between">
                    <UserPopover currentID={chatMember?.userId}>
                      <StyledChatName>
                        {item.members.length > 2
                          ? chatTopic || listChatMembers
                          : chatMember?.displayName}
                      </StyledChatName>
                    </UserPopover>
                    <StyledMessageDate>
                      {moment(item.lastUpdatedDateTime).format('DD/MM HH:mm')}
                    </StyledMessageDate>
                  </StyledRow>
                </StyledCol>
              </StyledMessageRow>
            );
          })
        : null}
    </StyledMessageListWrapper>
  );
};

import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { colors } from 'ui/elements/theme/constants';

const { Text } = Typography;

export const StyleCommentContainerRow = styled(Row)`
  display: flex;
  margin-top: 16px;
  overflow: hidden;
`;
export const StyledCommentParentContainer = styled(Col)`
  flex: 1;
  overflow: hidden;
`;

export const StyledCommentContainer = styled(Col)`
  overflow: hidden;
  border: 1px solid ${colors.borderGreySecond};
  margin-left: 12px;
  padding: 16px;
  border-radius: 2px 8px 8px 8px;
`;

export const StyledActivityContainer = styled(Col)`
  overflow: hidden;
  margin-left: 12px;
  padding: 16px;
  background-color: ${colors.orangeShadow}0d;
  border-radius: 2px 8px 8px 8px;
`;

export const StyledCommentUserPopoverContainer = styled.div`
  margin-top: 8px;
`;

export const StyledCommentUserNameText = styled(Text)`
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.primaryColor};
`;

export const StyledCommentTimeText = styled(Text)`
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: ${colors.labelGrey};
`;

export const StyledCommentText = styled(Text)`
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.textColorSecondary};
`;

export const StyledCommentBoxHeader = styled(Row)`
  margin-bottom: 4px;
  justify-content: space-between;
`;

export const StyledActivityIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${colors.greyBackLabel};
  display: flex;
  justify-content: center;
  align-items: center;
`;

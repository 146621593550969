import { css, CSSObject } from 'styled-components';
import { constants } from './constants';

export const xSmallAndDown = (args: CSSObject | TemplateStringsArray) => css`
  @media screen and ${constants.breakpoints.xSmallAndDown} {
    ${css(args)};
  }
`;

export const mediumAndUp = (args: CSSObject | TemplateStringsArray) => css`
  @media screen and ${constants.breakpoints.medium} {
    ${css(args)};
  }
`;

export const small = (args: CSSObject | TemplateStringsArray) => css`
  @media screen and ${constants.breakpoints.small} {
    ${css(args)};
  }
`;

export const mediumAndUpST = (args: CSSObject | TemplateStringsArray) => css`
  @media screen and ${constants.breakpoints.mediumAndUp} {
    ${css(args)};
  }
`;

export const largeAndUp = (args: CSSObject | TemplateStringsArray) => css`
  @media screen and ${constants.breakpoints.largeAndUp} {
    ${css(args)};
  }
`;

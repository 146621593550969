export const teamsChannels = [
  {
    id: '19:974e3e3f7b3f4444a0bce04be44ae5eb@thread.tacv2',
    description: 'SoE Project War Room preparation',
    displayName: 'General',
    webUrl:
      'https://teams.microsoft.com/l/channel/19%3aab6868a16a1a40b095014d9a0e177646%40thread.tacv2/Employee+Portal?groupId=ef729b4f-b6bc-4dfd-a351-44cd3b153a0a&tenantId=f409a13c-bc56-4779-8fdb-948773f05564',
  },
  {
    id: '19:b938a4e918534f829708097ae0ae0436@thread.tacv2',
    description:
      'The goal of this channel is to discuss architecture related questions of the project.',
    displayName: 'Architecture',
    isFavoriteByDefault: false,
    webUrl:
      'https://teams.microsoft.com/l/channel/19%3aab6868a16a1a40b095014d9a0e177646%40thread.tacv2/Employee+Portal?groupId=ef729b4f-b6bc-4dfd-a351-44cd3b153a0a&tenantId=f409a13c-bc56-4779-8fdb-948773f05564',
  },
  {
    id: '19:d05e3f2cad374fb2b5a3ed5a32253bf3@thread.tacv2',
    description:
      'The aim of this channel is to discuss business analysis related questions (workflows, refinements, etc)',
    displayName: 'BA',
    isFavoriteByDefault: false,
    webUrl:
      'https://teams.microsoft.com/l/channel/19%3aab6868a16a1a40b095014d9a0e177646%40thread.tacv2/Employee+Portal?groupId=ef729b4f-b6bc-4dfd-a351-44cd3b153a0a&tenantId=f409a13c-bc56-4779-8fdb-948773f05564',
  },
  {
    id: '19:fb90754b0d37451f8197dc44ed0bbd62@thread.tacv2',
    description:
      'The aim of this channel is to discuss all items related to user interface and user experience',
    displayName: 'UI-UX',
    isFavoriteByDefault: false,
    webUrl:
      'https://teams.microsoft.com/l/channel/19%3aab6868a16a1a40b095014d9a0e177646%40thread.tacv2/Employee+Portal?groupId=ef729b4f-b6bc-4dfd-a351-44cd3b153a0a&tenantId=f409a13c-bc56-4779-8fdb-948773f05564',
  },
  {
    id: '19:82172a9b1a8549bca714f7bb33df960c@thread.tacv2',
    description:
      'The aim of this channel is to discuss all development related questions (Pull Requests, Code reviews, etc).',
    displayName: 'Development',
    isFavoriteByDefault: false,
    webUrl:
      'https://teams.microsoft.com/l/channel/19%3aab6868a16a1a40b095014d9a0e177646%40thread.tacv2/Employee+Portal?groupId=ef729b4f-b6bc-4dfd-a351-44cd3b153a0a&tenantId=f409a13c-bc56-4779-8fdb-948773f05564',
  },
  {
    id: '19:608d7f2bf2bf4f6299f1d0c05eb74844@thread.tacv2',
    description:
      'The aim of this channel is to discuss all questions related to development work testing.',
    displayName: 'Testing',
    isFavoriteByDefault: false,
    webUrl:
      'https://teams.microsoft.com/l/channel/19%3aab6868a16a1a40b095014d9a0e177646%40thread.tacv2/Employee+Portal?groupId=ef729b4f-b6bc-4dfd-a351-44cd3b153a0a&tenantId=f409a13c-bc56-4779-8fdb-948773f05564',
  },
  {
    id: '19:2566903b3d674460afea3841521e82cd@thread.tacv2',
    displayName: 'DevOps',
    isFavoriteByDefault: false,
    webUrl:
      'https://teams.microsoft.com/l/channel/19%3aab6868a16a1a40b095014d9a0e177646%40thread.tacv2/Employee+Portal?groupId=ef729b4f-b6bc-4dfd-a351-44cd3b153a0a&tenantId=f409a13c-bc56-4779-8fdb-948773f05564',
  },
  {
    id: '19:9d7afa496a09439d8f5516f42e90baa3@thread.tacv2',
    description: 'to let Customer Portal team to discuss their tasks',
    displayName: 'Customer Portal',
    isFavoriteByDefault: true,
    webUrl:
      'https://teams.microsoft.com/l/channel/19%3aab6868a16a1a40b095014d9a0e177646%40thread.tacv2/Employee+Portal?groupId=ef729b4f-b6bc-4dfd-a351-44cd3b153a0a&tenantId=f409a13c-bc56-4779-8fdb-948773f05564',
  },
  {
    id: '19:ab6868a16a1a40b095014d9a0e177646@thread.tacv2',
    description: 'to let Employee Portal team to discuss their tasks',
    displayName: 'Employee Portal',
    isFavoriteByDefault: true,
    webUrl:
      'https://teams.microsoft.com/l/channel/19%3aab6868a16a1a40b095014d9a0e177646%40thread.tacv2/Employee+Portal?groupId=ef729b4f-b6bc-4dfd-a351-44cd3b153a0a&tenantId=f409a13c-bc56-4779-8fdb-948773f05564',
  },
];

import styled from 'styled-components';
import { Row, Spin, Typography } from 'antd';
import { colors, space } from 'ui/elements';

export const { Text } = Typography;

export const StyledDateRangeInfoText = styled(Text)`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: ${colors.skyLink};
`;

export const StyledDateRangeInfoTextWrapper = styled.div`
  margin-top: -5px;
  margin-bottom: ${space.cozy};
`;

export const StyledTitleRequestDetailWrapper = styled.div`
  margin-top: ${space.normal};
  margin-bottom: ${space.cozy};
`;
export const StyledWFHFromFieldWrapper = styled.div``;
export const StyledWFHFormHeaderWrapper = styled.div`
  margin-bottom: ${space.comfy};
`;

export const StyledWFHRemainingBalanceCard = styled.div`
  background-color: ${colors.lightGreyFourth};
  width: auto;
  border: none;
  border-radius: 4px;
  flex-direction: row;
  justify-content: space-between;
  height: 52px;
  align-items: center;
  padding-left: ${space.normal};
  padding-right: ${space.normal};
  display: flex;
  margin-top: ${space.comfy};
  margin-bottom: ${space.comfy};
`;

interface StyledWFHRemainingBalanceLabelProps {
  isError?: boolean;
}
export const StyledWFHRemainingBalanceLabel = styled(Text)`
  color: ${(props: StyledWFHRemainingBalanceLabelProps) =>
    props.isError ? colors.red : colors.textColorSecondary};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
`;

export const StyledWFHRemainingBalanceCount = styled(Text)`
  color: ${(props: StyledWFHRemainingBalanceLabelProps) =>
    props.isError ? colors.red : colors.primaryColor};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  padding-left: ${space.mini};
  padding-right: ${space.mini};
`;

export const StyledWFHBalanceCard = styled.div`
  border: 1px, solid, ${colors.lightGreyScroll};
  border-radius: 4px;
  display: flex;
  padding: ${space.normal};
  position: absolute;
  right: -80px;
  max-width: 188px;
  flex-direction: column;

  @media (max-width: 1024px) {
    right: -60px;
    max-width: 168px;
  }
`;

export const StyledWFHBalanceLabel = styled(Text)`
  font-size: 18px;
  font-weight: 400;
  line-height: 21.48px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: ${colors.textColorSecondary};
`;

export const StyledWFHBalanceCount = styled(Text)`
  font-size: 40px;
  font-weight: 500;
  line-height: 47.73px;
  letter-spacing: 1px;
  text-align: left;
  color: ${colors.primaryColor};
  padding-top: ${space.mini};
`;
export const StyledWFHBalanceDays = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: ${colors.textColorSecondary};
  padding-left: ${space.mini};
`;

export const StyledWFHRow = styled(Row)`
  flex-direction: row;
  display: flex;
`;

export const StyledDurationWFHSpin = styled(Spin)`
  margin-left: ${space.mini};
  margin-right: ${space.mini};
`;

export const StyledRemainingBalanceWFHSpin = styled(Spin)``;

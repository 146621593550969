import React, { memo, FC } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { Col, Row, Breadcrumb } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { colors, TitleH1 } from 'ui/elements';
import { Header } from 'app';
import { TType, withTranslate } from 'translations';
import { PagesUrls } from 'namespace';
import styled from 'styled-components';
import { StyledBackIcon } from 'routes/Calendar/page/NewRequest/NewRequest.styled';

const BACK_ICON_STYLES = {
  fontSize: '22px',
  marginRight: '12px',
};

export const BreadcrumbCustomizer = styled.div`
  padding-top: 6px;
  background-color: ${colors.white};
  span,
  .ant-breadcrumb a {
    color: ${colors.lightGrey};
  }
`;

type Props = RouteComponentProps & {
  t: TType;
};

export const NewRequestHeaderComponent: FC<Props> = ({ history: { goBack }, t }) => (
  <Header>
    <Row align="middle">
      <Col span={24}>
        <TitleH1>
          <StyledBackIcon onClick={goBack}>
            <ArrowLeftOutlined style={BACK_ICON_STYLES} />
          </StyledBackIcon>
          {t('tasks.newRequest')}
        </TitleH1>
      </Col>
      <Col span={24}>
        <BreadcrumbCustomizer>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={PagesUrls.Tasks}>{t('tasks.myRequest')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('tasks.newRequest')}</Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbCustomizer>
      </Col>
    </Row>
  </Header>
);

export const ServiceNewRequestHeader = memo(withRouter(withTranslate(NewRequestHeaderComponent)));

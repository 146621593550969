import React, { FC, useCallback, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { useTranslate } from 'translations';
import { AttendanceWidget } from 'routes/Attendance/Components';
import {
  ButtonContent,
  ListActiveIconStyled,
  ListUnActiveIconStyled,
  PageHeader,
} from 'routes/Calendar/page/Leave/Leaves.styled';
import { ViewType } from 'routes/Attendance/constants';
import { AttendanceListView } from 'routes/Attendance/Components/AttendanceListView';
import { MONTH_YEAR_FORMAT } from 'routes/Attendance/Components/helper';
import moment from 'moment';
import { useErrorNotification } from 'ui';
import { span } from 'ui/elements';
import { useGetAttendanceList } from 'ui/hooks/useGetAttendanceList';
import { StyledContainer, StyledDatePicker } from './MyAttendance.styled';

interface Props {
  hcmPersonId: string;
  hireDate: string;
}

export const MyAttendance: FC<Props> = ({ hcmPersonId, hireDate }) => {
  const { span6, span18 } = span;
  const { t } = useTranslate();
  const [viewType, setViewType] = useState<ViewType>(ViewType.list);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
  const [lastModifiedDate, setLastModifiedDate] = useState('');

  const onLastModifiedDateChanged = (updatedDate: string) => {
    setLastModifiedDate(updatedDate);
  };

  const onChangeDate = useCallback(
    (value: moment.Moment | null) => {
      if (value) {
        setSelectedDate(value);
        setCurrentPage(0);
      }
    },
    [setCurrentPage]
  );

  const disableFilterDate = useCallback(
    (currentMonth: moment.Moment) => {
      const CurrentDate = moment()
        .year(currentMonth.year())
        .month(currentMonth.month())
        .startOf('day')
        .isAfter();

      return CurrentDate || currentMonth.isBefore(hireDate);
    },
    [hireDate]
  );

  const {
    isError,
    isTableLoading: isLoading,
    totalResult,
    TableCurrentDate,
    data: attendanceListData,
    isRefetchError,
  } = useGetAttendanceList(hcmPersonId, selectedDate);

  const setListViewType = useCallback(() => setViewType(ViewType.list), []);

  useErrorNotification(
    {
      isNotification: isError || isRefetchError,
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    },
    [isError, isRefetchError]
  );

  return (
    <>
      <PageHeader size="small">
        <Row justify="space-between" align="middle">
          <Col>
            <StyledDatePicker
              onChange={(date) => {
                onChangeDate(date);
              }}
              placeholder={t('attendance.listView.datePicker.placeholder')}
              picker={'month'}
              disabledDate={disableFilterDate}
              format={MONTH_YEAR_FORMAT}
              disabled={isLoading}
            />
          </Col>
          <Col>
            <Button type="text" onClick={setListViewType}>
              <ButtonContent $active={viewType === ViewType.list}>
                {viewType === ViewType.list ? <ListActiveIconStyled /> : <ListUnActiveIconStyled />}
                {t('attendance.tab.list.title')}
              </ButtonContent>
            </Button>
          </Col>
        </Row>
      </PageHeader>
      <Row justify="space-between">
        <Col span={span18}>
          <AttendanceListView
            attendanceListData={attendanceListData}
            isLoading={isLoading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            tableHeader={TableCurrentDate.format(MONTH_YEAR_FORMAT)}
            isSelected={true}
            totalResult={totalResult}
            lastModifiedDate={lastModifiedDate}
          />
        </Col>
        {hcmPersonId && (
          <Col span={span6}>
            <StyledContainer>
              <AttendanceWidget
                hcmPersonId={hcmPersonId}
                hireDate={hireDate}
                updateLastModifiedDate={onLastModifiedDateChanged}
              />
            </StyledContainer>
          </Col>
        )}
      </Row>
    </>
  );
};

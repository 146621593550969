import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslate } from 'translations';
import { is, isEmpty, isNil } from 'ramda';
import {
  FieldRunnerCandidate,
  FieldRunnerCandidates,
  LeaseSitesFieldRunnerFormValues,
  LeaseSitesFieldRunnerResponse,
} from 'types';
import { useCallback } from 'react';
import { leaseSites } from '../LeaseSites';
import { useErrorDescription, useGetAttachmentPromises } from '../../hooks';
import { QUERIES } from '../../constants';

const isAttachmentKey = (key: string) => key === 'attachments';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isEmptyOrSelectIdEmpty = (value: any) =>
  isEmpty(value) ||
  (is(Object, value) &&
    Object.keys(value).length === 1 &&
    // eslint-disable-next-line no-prototype-builtins
    value.hasOwnProperty('id') &&
    isNil(value?.id)) ||
  isNil(value);

const removeEmptyValues = (candidate: FieldRunnerCandidate): FieldRunnerCandidate => {
  const res = Object.entries(candidate).reduce((acc, [key, value]) => {
    if (moment.isMoment(value) || value?._isAMomentObject) {
      return {
        ...acc,
        [key]: moment(value).format(),
      };
    }

    if (isAttachmentKey(key)) {
      return acc;
    }

    if (isEmptyOrSelectIdEmpty(value)) {
      return acc;
    }

    if (is(Object, value)) {
      return {
        ...acc,
        [key]: removeEmptyValues(value),
      };
    }

    return { ...acc, [key]: value };
  }, {} as Partial<FieldRunnerCandidate>);

  return res as FieldRunnerCandidate;
};

const sanitizeValuesAndAddIds = ({
  fieldRunnerCandidates,
  ...rest
}: LeaseSitesFieldRunnerFormValues) => {
  return {
    ...rest,
    fieldRunnerCandidates: fieldRunnerCandidates?.map((candidate, index) =>
      removeEmptyValues({ ...candidate, candidateNumber: index + 1 })
    ),
  };
};

const useGetCandidatesAttachmentPromises = ({
  taskId,
  processInstanceId,
  processDefinitionId,
  activityId,
}: {
  processInstanceId: string;
  taskId: string;
  activityId: string;
  processDefinitionId: string;
}) => {
  const getAttachmentPromises = useGetAttachmentPromises();

  return useCallback(
    (candidates: FieldRunnerCandidates, submittedCandidates: LeaseSitesFieldRunnerResponse) => {
      return candidates.reduce((acc, candidate, candidateIndex) => {
        const candidateAttachmentsEntries = candidate?.attachments
          ? Object.entries(candidate?.attachments)
          : [];

        if (candidateAttachmentsEntries.length) {
          const promises = candidateAttachmentsEntries.map(([key, value]) => {
            return getAttachmentPromises({
              attachment: {
                files: value,
                params: {
                  labelName: key,
                  candidateId: submittedCandidates?.[candidateIndex]?.id,
                },
              },
              processInstanceId,
              taskId,
              activityId,
              processDefinitionId,
            });
          });

          if (promises.length) {
            return [...acc, ...promises];
          }

          return acc;
        }
        return acc;
      }, [] as (Promise<AxiosResponse<string>[]> | undefined)[]);
    },
    [activityId, getAttachmentPromises, processDefinitionId, processInstanceId, taskId]
  );
};

export const useLeaseSitesRequestsFieldRunnerMutation = ({
  taskId,
  processInstanceId,
  processDefinitionId,
  activityId,
}: {
  processInstanceId: string;
  taskId: string;
  activityId: string;
  processDefinitionId: string;
}) => {
  const { t } = useTranslate();
  const errorDescription = useErrorDescription(t('messages.error.wentWrongReload'));
  const getCandidatesAttachmentPromises = useGetCandidatesAttachmentPromises({
    taskId,
    processInstanceId,
    processDefinitionId,
    activityId,
  });
  const queryClient = useQueryClient();

  return useMutation(
    async (values: LeaseSitesFieldRunnerFormValues) => {
      const payloadData = {
        ...sanitizeValuesAndAddIds(values),
        taskId,
      };

      const { data } = await leaseSites.postRequestsFieldRunner(processInstanceId, payloadData);

      if (values?.fieldRunnerCandidates?.length) {
        const attachmentPromises = getCandidatesAttachmentPromises(
          values.fieldRunnerCandidates,
          data
        );
        await Promise.all([attachmentPromises]);
      }

      return data;
    },
    {
      onError(error: AxiosError) {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: errorDescription(error),
        });
      },
      async onSuccess() {
        const quoteItemQueryId = [QUERIES.requestGeneralInfo, processInstanceId];
        await queryClient.invalidateQueries(quoteItemQueryId);
        notification.success({
          message: t('messages.success'),
          description: t('messages.success.leaseSitesRequestsFieldRunner'),
        });
      },
    }
  );
};

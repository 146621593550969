import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const profileExpenseLookup = (state: RootState): RootState['profileExpenseLookup'] =>
  state.profileExpenseLookup;

export const profileExpenseLookupData = createSelector(
  profileExpenseLookup,
  ({ error, status, data }) => ({
    error,
    status,
    data,
  })
);

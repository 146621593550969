import { OtherUserProfileDate } from 'types';
import { Core, USER_PROFILE_EDR } from '../Core';

interface GetUserProfile {
  (adoId: string): Promise<{ data: OtherUserProfileDate }>;
}

class UserProfile extends Core {
  getUserProfile: GetUserProfile = async (email: string) => this.get(USER_PROFILE_EDR(email));
}

export const userProfile = new UserProfile();

import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Col, Empty, notification, Row } from 'antd';
import {
  getMyTaskCardsReset,
  getMyTaskCardsStart,
  getProcessDefinitionKeysStart,
  myTaskCardsSelector,
  processDefinitionKeysData,
  useDispatch,
  useSelector,
} from 'store';
import InfiniteScroll from 'react-infinite-scroller';
import useResizeObserver from 'use-resize-observer';
import { useTranslate } from 'translations';
import { RequestGeneralInfo, RequestStatus, TaskStatus } from 'types';
import { CenteredCard, FullWidthSpin, TitleH3 } from 'ui';
import { TasksLayout } from 'layouts';
import { useTasksContext } from 'routes/Tasks/TasksProvider';
import { useServicesContext } from 'app';
import {
  CATEGORY_FILTER_FIELD,
  ServicesFilter,
  SUB_CATEGORY_FILTER_FIELDS,
} from '../Filter/ServicesFilter';
import { FilterOption } from '../Filter/Filter.types';
import { FilterTag } from '../Filter/Filter.styled';
import { TaskCardUpdate } from './TaskCard';
import { TaskListContainer, TaskListScroller } from './TaskList.styled';
import { TaskSteps } from './TaskSteps';

interface Props {
  userId?: string;
  isQueryParams?: boolean;
  setIsQueryParams?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TaskListComponent: FC<Props> = ({ userId, isQueryParams, setIsQueryParams }) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const getUrl = useCallback((pagePath: string) => `${path}${pagePath}`, [path]);
  const { t } = useTranslate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const processDefinitionKeys = useSelector(processDefinitionKeysData);
  const tasksList = useSelector(myTaskCardsSelector);
  const { ref, height = 1 } = useResizeObserver<HTMLDivElement>();
  const [subCategoryOptions, setSubCategoryOptions] = useState<FilterOption[]>([]);
  const [requestNameOptions, setRequestNameOptions] = useState<FilterOption[]>([]);
  const history = useHistory();
  const { currentActiveTab } = useTasksContext();
  const [tasksStatus, setTasksStatus] = useState<TaskStatus>(TaskStatus.inProgress);
  const taskListArrayLength = tasksList.data?.requestInfoList.length;
  const [params, setParams] = useState({});
  const [isClickOnCard, setIsClickOnCard] = useState<boolean>(false);
  const [prettyId, setPrettyId] = useState<string>('');
  const { isFilterByRequest, isFilterByPrettyId, prettyIdInfoRedirection } = useServicesContext();
  const [RequestInfo, setRequestInfo] = useState<RequestGeneralInfo | undefined>();

  const fetchTasks = useCallback(
    (startFrom = 0, value = TaskStatus.inProgress, params = {}) => {
      if (!userId) {
        return;
      }

      const requestParams = {
        start: startFrom,
        pageSize: 10,
        finished: false,
        ...params,
      };

      if (value === TaskStatus.processed) {
        requestParams.finished = true;
      } else {
        requestParams.finished = false;
      }

      dispatch(getMyTaskCardsStart({ userId, params: requestParams }));
    },
    [dispatch, userId]
  );
  const reloadTaskList = useCallback(() => {
    dispatch(getMyTaskCardsReset());
    fetchTasks(0, tasksStatus, params);
  }, [fetchTasks, dispatch, tasksStatus, params]);

  const onFilterCallback = useCallback(
    async (params = {}, status?: TaskStatus) => {
      if (
        (isFilterByPrettyId && Object.entries(params).length === 0) ||
        (isQueryParams && Object.entries(params).length === 0)
      ) {
        return;
      } else {
        setParams(params);
        setIsLoading(true);
        dispatch(getMyTaskCardsReset());
        fetchTasks(0, status, params);
      }
    },

    [isFilterByPrettyId, isQueryParams, dispatch, fetchTasks]
  );

  useEffect(() => {
    if (currentActiveTab === 'work-0') {
      setIsClickOnCard(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentActiveTab === 'work-0') {
      dispatch(getProcessDefinitionKeysStart());

      if (!isFilterByRequest && !isClickOnCard && RequestInfo && isQueryParams) {
        setIsClickOnCard(true);
        if (RequestInfo.tasks) {
          const reqInfoTasks = RequestInfo.tasks;
          const lastCurrentUserTask = reqInfoTasks
            ?.slice()
            ?.reverse()
            ?.find((task) => task.taskAssignee.adoid === userId);
          const status =
            lastCurrentUserTask && lastCurrentUserTask.status
              ? lastCurrentUserTask.status
              : TaskStatus.processed;
          const prettyId = RequestInfo?.requestInfo.prettyId;
          const reqParams = {
            start: 0,
            prettyId: prettyId,
            finished: status === TaskStatus.processed ? true : false,
            pageSize: 1,
          };
          setPrettyId(prettyId);
          setTasksStatus(
            status === TaskStatus.inProgress ? TaskStatus.inProgress : TaskStatus.processed
          );
          setIsLoading(true);
          dispatch(getMyTaskCardsReset());
          fetchTasks(0, status, reqParams);
        }
      }
      if (isFilterByPrettyId && prettyIdInfoRedirection?.taskPrettyId) {
        const reqParams = {
          prettyId: prettyIdInfoRedirection?.taskPrettyId,
          finished: prettyIdInfoRedirection?.requestStatus === TaskStatus.processed ? true : false,
          pageSize: 1,
        };
        setPrettyId(prettyIdInfoRedirection?.taskPrettyId);
        setTasksStatus(
          prettyIdInfoRedirection.requestStatus === TaskStatus.inProgress
            ? TaskStatus.inProgress
            : TaskStatus.processed
        );
        setIsLoading(true);
        dispatch(getMyTaskCardsReset());
        fetchTasks(0, prettyIdInfoRedirection.requestStatus, reqParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentActiveTab,
    tasksStatus,
    params,
    RequestInfo,
    isQueryParams,
    userId,
    isFilterByRequest,
    isFilterByPrettyId,
  ]);

  useEffect(() => {
    if (!isQueryParams) {
      setTasksStatus(TaskStatus.inProgress);
      setIsClickOnCard(false);
    }
  }, [isQueryParams]);

  const loadMore = useCallback(() => {
    if (!tasksList.hasMore || tasksList.status === RequestStatus.pending) {
      return;
    }
    if (tasksList.data) fetchTasks(tasksList.data.requestInfoList.length, tasksStatus, params);
  }, [tasksList.hasMore, tasksList.status, fetchTasks, tasksStatus, params, tasksList.data]);

  useEffect(() => {
    if (tasksList.status === RequestStatus.rejected) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [t, tasksList.status]);

  useEffect(() => {
    if (
      tasksList.status === RequestStatus.resolved ||
      tasksList.status === RequestStatus.rejected
    ) {
      setIsLoading(false);
    }
  }, [tasksList.status]);

  useEffect(
    () => () => {
      dispatch(getMyTaskCardsReset());
    },
    [dispatch]
  );

  const categoryOptions = useMemo(
    () =>
      processDefinitionKeys.data?.categories.map((category) => ({
        label: category.name,
        value: category.name,
        text: category.name,
      })),
    [processDefinitionKeys.data]
  );

  const onCategorySelect = useCallback(
    (value) => {
      const matchedSubCategory = processDefinitionKeys.data?.subCategories.find(
        (subCategory) => subCategory.name === value
      );

      if (matchedSubCategory) {
        const subCategoryOptions = matchedSubCategory?.names.map(({ name }) => ({
          label: name,
          value: name,
          text: name,
        }));

        setSubCategoryOptions(subCategoryOptions);
      }
    },
    [processDefinitionKeys.data?.subCategories]
  );

  const onSubCategorySelect = useCallback(
    (value) => {
      const matchedRequestName = processDefinitionKeys.data?.requestNames.find(
        (subCategory) => subCategory.name === value
      );

      if (matchedRequestName) {
        const requestNameOptions = matchedRequestName?.names.map(
          ({ name, processDefinitionKey }) => ({
            label: name,
            value: processDefinitionKey,
            text: name,
          })
        );

        setRequestNameOptions(requestNameOptions);
      }
    },
    [processDefinitionKeys.data?.requestNames]
  );

  const onValuesChange = useCallback(
    (field: { [key: string]: string }) => {
      if (field[CATEGORY_FILTER_FIELD]) {
        onCategorySelect(field[CATEGORY_FILTER_FIELD]);
        setRequestNameOptions([]);
      }

      if (field[SUB_CATEGORY_FILTER_FIELDS]) {
        onSubCategorySelect(field[SUB_CATEGORY_FILTER_FIELDS]);
      }
    },
    [onCategorySelect, onSubCategorySelect]
  );

  const onResetFilterCallback = useCallback((isReset) => {
    if (isReset) {
      setSubCategoryOptions([]);
      setRequestNameOptions([]);
    }
  }, []);

  const onRemoveTag = () => {
    setIsLoading(true);
    reloadTaskList();
  };

  const TaskCardList = useMemo(
    () => {
      if (taskListArrayLength) {
        return (
          <TaskListScroller style={(ref as unknown) ? { maxHeight: height - 100 } : undefined}>
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={tasksList.hasMore}
              useWindow={false}
              loader={<FullWidthSpin key={0} />}
              initialLoad={false}
            >
              {tasksList.data?.requestInfoList.map((card) => {
                if (!card) {
                  return undefined;
                }
                return (
                  <>
                    <TaskCardUpdate
                      key={card.prettyId}
                      stepTitle={card.taskInfo.taskName}
                      title={card.processName}
                      processInstanceId={card.processInstanceId}
                      date={card.requestTime}
                      description={card.taskInfo.description}
                      stepStatus={card.taskInfo.status}
                      assignedTo={card.taskInfo.taskAssignee}
                      assigneeId={card.taskInfo.assigneeId}
                      requestPrettyId={card.prettyId}
                      taskId={card.taskInfo.taskId}
                      taskName={card.taskInfo.taskName}
                      endEvent={card.endEvent}
                      mytask={true}
                      isMyRequest={false}
                      isClickedOnCard={isClickOnCard}
                      reLoadCallback={reloadTaskList}
                      isProcessed={tasksStatus === TaskStatus.processed}
                      formFields={card.taskInfo.formFields}
                      setIsClickOnCard={setIsClickOnCard}
                      requester={card.requester}
                      appliedAction={card.taskInfo.appliedAction}
                      rootPath={card.serviceUrl}
                      isJira={card.isJiraRequest}
                      jiraStatus={card.jiraStatus}
                      completedBy={card?.taskInfo.completedBy}
                      processDefinitionKey={card.processDefinitionKey}
                      taskDefinitionKey={card?.taskInfo?.taskDefinitionKey}
                    />
                  </>
                );
              })}
            </InfiniteScroll>
          </TaskListScroller>
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      height,
      ref,
      tasksList.hasMore,
      tasksList.data?.requestInfoList,
      loadMore,
      taskListArrayLength,
      tasksStatus,
      reloadTaskList,
    ]
  );

  useEffect(() => {
    if (currentActiveTab === 'work-0' && !isQueryParams && !isFilterByPrettyId) {
      setIsLoading(true);
      reloadTaskList();
    }
  }, [currentActiveTab, reloadTaskList, history, isQueryParams, isFilterByPrettyId]);

  const onSelect = (value: TaskStatus) => {
    if (!isLoading) {
      setIsLoading(true);
      setTasksStatus(value);
      dispatch(getMyTaskCardsReset());
      fetchTasks(0, value, params);
      if (setIsQueryParams) {
        setIsQueryParams(false);
      }
    }
  };

  return (
    <TasksLayout
      sidebar={
        <Row gutter={[24, 24]} justify="center">
          {
            <Col span={24}>
              {isLoading ? <FullWidthSpin /> : TaskCardList}
              {!isLoading && !taskListArrayLength && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('task.noTasks')} />
              )}
            </Col>
          }
        </Row>
      }
      sidebarTitle={
        <>
          <ServicesFilter
            categoryOptions={categoryOptions}
            subCategoryOptions={subCategoryOptions}
            requestNameOptions={requestNameOptions}
            isLoading={isLoading}
            t={t}
            history={history}
            onFilterCallback={onFilterCallback}
            onValuesChange={onValuesChange}
            onResetFilterCallback={onResetFilterCallback}
            showStatusSelect={true}
            statusSelectOptions={[TaskStatus.inProgress, TaskStatus.processed]}
            defaultStatusSelectOption={TaskStatus.inProgress}
            statusSelectValue={tasksStatus}
            onSelectedStatusChangeCallback={onSelect}
          />
          {isQueryParams && prettyId && Object.entries(params).length === 0 && (
            <FilterTag closable onClose={onRemoveTag}>
              {t('tasks.requestId', { requestId: prettyId })}
            </FilterTag>
          )}
        </>
      }
    >
      <TaskListContainer ref={ref}>
        <Switch>
          {!isClickOnCard ? (
            <CenteredCard>
              <TitleH3 type="secondary">{t('tasks.selectToReview')}</TitleH3>
            </CenteredCard>
          ) : null}

          <Route exact path={getUrl('/:id')}>
            <TaskSteps
              reloadTaskList={reloadTaskList}
              tasksStatus={tasksStatus}
              setSelectedTask={setRequestInfo}
            />
          </Route>
        </Switch>
      </TaskListContainer>
    </TasksLayout>
  );
};

export const TaskList = memo(TaskListComponent);

import { useMutation } from 'react-query';
import { NonPoInvoiceSubmitResponse, NonPoUpdateRequestExpenseParams } from 'types';
import { nonPoInvoice } from '../NONPoInvoice';

export const useNonPoInvoiceUpdateExpenseApi = () => {
  return useMutation<NonPoInvoiceSubmitResponse, unknown, NonPoUpdateRequestExpenseParams, unknown>(
    async (params) => {
      const { data } = await nonPoInvoice.postNonPoInvoiceUpdateExpenseRequest(params);
      return data;
    }
  );
};

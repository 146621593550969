import { Col, Divider } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import React, { ReactNode, useCallback, useMemo } from 'react';
import {
  WrapperIcon,
  WrapperNotification,
} from 'routes/NotificationDrawer/components/NotificationDetail.style';
import { span } from 'ui';
import { useServicesContext } from '../../../../app';
import { SeeAllUpdateTemplate } from './SeeAllUpdateTemplate';

interface Props {
  icon: ReactNode;
  requestId: string;
  titleRequest: string;
  messageRequest: string;
  hasComment: boolean;
  hasUpdatesRequest: boolean;
  commentName?: string | null;
  commentText?: string | null;
  processInstance: string | null;
  isLastElement: boolean;
  notificationRequestType: string;
  formattedDate?: string;
  createdDate?: string;
  refreshList: () => void;
  onAllUpdatedNotificationClick: (props: OnAllUpdatedNotificationClickProps) => void;
  redirectionPage?: string;
  redirectionTab?: string;
  isRead?: boolean;
}

export interface OnAllUpdatedNotificationClickProps {
  isRead: boolean;
  redirectionPage: string;
  redirectionTab: string;
  requestId: string;
  processInstanceId: string;
}

const GUTTER_3_0: [Gutter, Gutter] = [3, 0];
const SeeAllUpdateCardComponent: React.FC<Props> = ({
  icon,
  titleRequest,
  messageRequest,
  hasComment,
  commentName,
  commentText,
  formattedDate,
  createdDate,
  isLastElement,
  onAllUpdatedNotificationClick,
  redirectionPage,
  redirectionTab,
  isRead,
  processInstance,
  requestId,
}) => {
  const { span2, span24, span16, span4 } = span;

  const isHaveRedirectionTabAndPage = useMemo(
    () => redirectionPage !== null && redirectionTab !== null,
    [redirectionPage, redirectionTab]
  );

  const { setIsFilterByPrettyId, setPrettyIdInfoRedirection } = useServicesContext();

  const handleOnNotificationClick = useCallback(
    async (e) => {
      e.stopPropagation();
      if (isHaveRedirectionTabAndPage) {
        onAllUpdatedNotificationClick({
          isRead: isRead ?? false,
          processInstanceId: processInstance ?? '',
          redirectionPage: redirectionPage ?? '',
          redirectionTab: redirectionTab ?? '',
          requestId: requestId ?? '',
        });
      } else {
        setPrettyIdInfoRedirection({});
        setIsFilterByPrettyId(false);
      }
    },
    [
      isHaveRedirectionTabAndPage,
      isRead,
      onAllUpdatedNotificationClick,
      processInstance,
      redirectionPage,
      redirectionTab,
      requestId,
      setIsFilterByPrettyId,
      setPrettyIdInfoRedirection,
    ]
  );

  return (
    <React.Fragment>
      <WrapperNotification
        justify={'space-between'}
        gutter={GUTTER_3_0}
        $isCanRedirection={isHaveRedirectionTabAndPage}
        onClick={handleOnNotificationClick}
      >
        <WrapperIcon xs={span24} xl={span2}>
          {icon}
        </WrapperIcon>

        <Col xs={span24} xl={span16}>
          <SeeAllUpdateTemplate
            title={titleRequest}
            message={messageRequest}
            formattedDate={formattedDate}
            hasComment={hasComment}
            commentText={commentText}
            commenterName={commentName}
            createdDate={createdDate}
          />
        </Col>
        <Col xs={span24} xl={span4}></Col>
      </WrapperNotification>
      {!isLastElement && <Divider />}
    </React.Fragment>
  );
};

export const SeeAllUpdateCard = React.memo(SeeAllUpdateCardComponent);

import { Button, Divider, Typography } from 'antd';
import styled from 'styled-components';
import { colors, space, typography } from 'ui/elements';

const { Text } = Typography;

export const StyledRemoveBtn = styled(Button)`
  font-size: ${typography.size.hecto};
  color: ${colors.lightGrey};
  cursor: pointer;
`;

export const StyledDivider = styled(Divider)`
  margin-top: -15px;
`;

export const UnoText = styled(Text)`
  font-size: ${typography.size.uno};
  line-height: ${typography.size.giga};
  color: ${colors.textColorSecondary};
  &.ant-typography.ant-typography-secondary {
    color: ${colors.lightGrey};
  }
`;

export const KiloText = styled(Text)`
  font-size: ${typography.size.kilo};
  line-height: ${typography.size.tera};
  color: ${colors.primaryColor};
  &.ant-typography.ant-typography-secondary {
    color: ${colors.primaryColor};
  }
`;

export const RemoveItemWrapper = styled.div`
  cursor: pointer;
`;

export const ProductInfoFormWrapper = styled.div<{ $fullWidth?: boolean }>`
  ${({ $fullWidth }) => ($fullWidth ? 'width: 100%;' : '')}
  &:not(:first-child) {
    .create-collocation-order-form_total-count {
      display: none;
    }
  }
`;

export const StyledDividerTopSlim = styled(Divider)`
  margin-top: ${space.slim};
`;

export const StyledItemGroup = styled(Text)`
  font-size: ${typography.size.giga};
  font-weight: ${typography.weight.semiBold};
`;

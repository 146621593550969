import { Button, Typography, Form, DatePicker, InputNumber, Row, Col } from 'antd';

import styled from 'styled-components';
import { colors, space, typography } from 'ui/elements';
import { MemoCircle } from '../../../assets';

const { Text } = Typography;

export const StyledCompleteButton = styled(Button)`
  background-color: ${colors.orange};
  color: ${colors.white};

  &:hover,
  &:focus {
    background-color: ${colors.orangeShadow};
    color: ${colors.white};
    border-color: ${colors.orangeShadow};


`;

export const StyledRejectActionButton = styled(Button)`
  background-color: ${colors.softGray};
  color: ${colors.black};

  &:hover,
  &:focus {
    background-color: ${colors.lightSoftGray};
    color: ${colors.black};
    border-color: ${colors.lightSoftGray};
  }
`;

export const StyledRejectButton = styled(StyledRejectActionButton)<{
  $inCard?: boolean;
  $left?: string;
}>`
  position: ${(props) => (props.$inCard ? 'initial' : 'absolute')};
  left: ${(props) => (props.$left ? `${props.$left}px` : '100px')};
  bottom: 0;
`;

export const StyledTextWrapper = styled.div`
  padding: 18px;
  padding-bottom: 1px;
  background-color: ${colors.backgroundGrey};
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
`;

export const StyleTextLabel = styled(Text)`
  color: ${colors.textColorSecondary} !important;
  font-size: 14px !important;
  line-height: 22px;
`;

export const StyledExampleLabel = styled(Text)`
  color: ${colors.textColorSecondary};
  fonst-size: 14px;
  margin-top: 12px;
  display: block;
`;

export const StyledFormButton = styled(Form)`
  flex-direction: row-reverse;
`;
export const StyledFormFile = styled(Form.Item)`
  font-weight: ${(props: { $FiledBold?: boolean }) =>
    props.$FiledBold ? typography.weight.semiBold : typography.weight.regular};
  color: ${colors.blackWith85Opacity} !important;
  font-size: 14px !important;
  line-height: 22px;
  padding-bottom: 8px;
`;
export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export const FormItemWrapper = styled(Form.Item)`
  margin-bottom: 0;

  .ant-form-item-control-input {
    min-height: 0;
  }
`;

export const FormWrapper = styled(Row)`
  //gap: unset !important;
`;

export const FormItemCol = styled(Col)<{ $isVisible: boolean }>`
  //margin: ${(props) => (props.$isVisible ? '0 16px 16px 0' : 0)};
`;

export const FormItemFieldWrapper = styled.div<{ $withMargin?: boolean }>`
  ${({ $withMargin }) => ($withMargin ? 'margin-right: 16px;' : '')}
`;

export const StyledProcessDescriptionIcon = styled(MemoCircle)`
  width: 27px;
  height: 27px;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding-left: ${space.cozy};
  padding-bottom: ${space.moderate};
`;

export const StyledFieldLabel = styled.label`
  color: ${colors.textColorSecondary} !important;
  font-size: 14px !important;
  line-height: 22px;
  padding-bottom: 8px;
`;
export const StyledFieldAttachmentLabel = styled.label`
  color: ${colors.labelGrey} !important;
  font-size: 18px !important;
  line-height: 22px;
  padding-bottom: 18px;
`;
export const StyledFieldAttachmentGroupLabel = styled.label`
  color: ${colors.blackWith85Opacity} !important;
  font-size: 14px !important;
  line-height: 22px;
  padding-bottom: 8px;
`;

import React from 'react';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { UserPopover } from 'ui/components';
import { StyledTaskAvatar } from './TaskCard.styled';

interface Props {
  currentUserId?: string;
  currentUserName?: string;
}

export const AvatarPupOver: React.FC<Props> = ({ currentUserId, currentUserName }) => {
  return (
    <React.Fragment>
      <UserPopover currentID={currentUserId}>
        <StyledTaskAvatar
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <MSTeamsUserAvatar id={currentUserId} size={38} name={currentUserName} />
        </StyledTaskAvatar>
      </UserPopover>
    </React.Fragment>
  );
};

import { useQuery } from 'react-query';
import { staticForm } from 'api/StaticForm';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';

export function useDelegationFormdDetails() {
  return useQuery(
    [QUERIES.getDelegationFormDetails],
    async () => (await staticForm.getDelegationFormDetails()).data,
    { ...PERSIST_QUERY_CONFIG }
  );
}

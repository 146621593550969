import { Col } from 'antd';
import Row, { Gutter } from 'antd/lib/grid/row';
import React from 'react';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import IconSelector from 'routes/Tasks/components/templates/RequestsCatalogCards/IconSelector';
import { useTranslate } from 'translations';
import { NotificationList, NotificationType } from 'types';
import { colors, space, span } from 'ui/elements';
import { GroupActiveAvatarIcon } from './GroupActivityIcon';
import { NotificationCard } from './NotificationCard';
import { DateOfNotificationStyled } from './NotificationDetail.style';
import { ProcessIcon } from './ProcessIcons';

interface Props {
  notificationList: Record<string, NotificationList[]>;
  refreshList: () => void;
  refreshBadge: () => void;
}
const GUTTER_12_32: [Gutter, Gutter] = [12, 33];

const GeneratedNotificationComponent: React.FC<Props> = ({
  notificationList,
  refreshList,
  refreshBadge,
}) => {
  const { t } = useTranslate();
  const { span24 } = span;

  return (
    <Row gutter={GUTTER_12_32} style={{ marginTop: space.normal }}>
      {Object.entries(notificationList).map(([day, item]) => {
        return (
          <>
            <Col span={span24} key={day} style={{ padding: `${space.cozy} 0px` }}>
              <DateOfNotificationStyled>{day}</DateOfNotificationStyled>
            </Col>

            {item.map((itemCard) => {
              switch (itemCard.notificationContentType) {
                case NotificationType.CREATE_PROCESS:
                case NotificationType.COMPLETE_PROCESS:
                  return (
                    <NotificationCard
                      requestId={itemCard.requestId}
                      requestName={itemCard.requestName}
                      refreshList={refreshList}
                      redirectionPage={itemCard.redirectionPage}
                      redirectionTab={itemCard.redirectionTab}
                      notificationType={itemCard.notificationContentType}
                      titleNotification={` ${itemCard.requestName} ${
                        itemCard.notificationContentType === NotificationType.CREATE_PROCESS
                          ? t('notification.drawer.action.createProcess')
                          : t('notification.drawer.action.completeProcess')
                      }`}
                      messageNotification={`${itemCard.requestName} ${t(
                        'notification.drawer.body.createProcess'
                      )}${
                        itemCard.notificationContentType === NotificationType.CREATE_PROCESS
                          ? t('notification.drawer.action.createProcess')
                          : t('notification.drawer.action.completeProcess')
                      }`}
                      icon={
                        <ProcessIcon
                          Icon={
                            <IconSelector
                              type={itemCard.requestName.replace(/\s/g, '').replace(/-/g, '')}
                              style={{
                                fontSize: 20,
                                height: '20px',
                                width: '20px',
                                color: colors.skyColor,
                                verticalAlign: 'textBottom',
                              }}
                            />
                          }
                        />
                      }
                      hasComment={false}
                      hasNotifications={itemCard.notificationCount > 1}
                      processInstance={itemCard.processInstanceId}
                      isLastElement={item.slice(-1)[0] === itemCard}
                      isRead={itemCard.isRead}
                      id={itemCard.id}
                      refreshBadge={refreshBadge}
                    />
                  );

                case NotificationType.START_TASK:
                  return (
                    <NotificationCard
                      requestId={itemCard.requestId}
                      requestName={itemCard.requestName}
                      refreshList={refreshList}
                      redirectionPage={itemCard.redirectionPage}
                      redirectionTab={itemCard.redirectionTab}
                      notificationType={itemCard.notificationContentType}
                      titleNotification={` ${t('notification.drawer.title.startTask')}`}
                      messageNotification={`${itemCard.taskName} ${t(
                        'notification.drawer.body.startTask.for'
                      )} ${itemCard.requestName} ${t(
                        'notification.drawer.body.startTask.beenAssign'
                      )}`}
                      icon={<GroupActiveAvatarIcon />}
                      hasComment={false}
                      hasNotifications={itemCard.notificationCount > 1}
                      processInstance={itemCard.processInstanceId}
                      isLastElement={item.slice(-1)[0] === itemCard}
                      isRead={itemCard.isRead}
                      id={itemCard.id}
                      refreshBadge={refreshBadge}
                    />
                  );
                case NotificationType.REJECT_TASK:
                case NotificationType.APPROVE_TASK:
                  return (
                    <NotificationCard
                      notificationType={itemCard.notificationContentType}
                      refreshList={refreshList}
                      redirectionPage={itemCard.redirectionPage}
                      redirectionTab={itemCard.redirectionTab}
                      requestId={itemCard.requestId}
                      requestName={itemCard.requestName}
                      titleNotification={`${itemCard.requestName} ${t(
                        'notification.drawer.title.taskAction.updated'
                      )}`}
                      messageNotification={`"${itemCard.taskName}" ${t(
                        'notification.drawer.body.startTask.for'
                      )} ${itemCard.requestName} ${t('notification.drawer.body.taskAction.is')} ${
                        itemCard.notificationContentType === NotificationType.APPROVE_TASK
                          ? t('notification.drawer.action.completedTask.approved')
                          : t('notification.drawer.action.completedTask.reject')
                      }`}
                      icon={
                        <ProcessIcon
                          Icon={
                            <IconSelector
                              type={itemCard.requestName.replace(/\s/g, '').replace(/-/g, '')}
                              style={{
                                fontSize: 20,
                                height: '20px',
                                width: '20px',
                                color: colors.skyColor,
                                verticalAlign: 'textBottom',
                              }}
                            />
                          }
                        />
                      }
                      hasComment={false}
                      hasNotifications={itemCard.notificationCount > 1}
                      processInstance={itemCard.processInstanceId}
                      isLastElement={item.slice(-1)[0] === itemCard}
                      isRead={itemCard.isRead}
                      id={itemCard.id}
                      refreshBadge={refreshBadge}
                    />
                  );
                case NotificationType.ASSIGN_TASK:
                  return (
                    <NotificationCard
                      requestId={itemCard.requestId}
                      requestName={itemCard.requestName}
                      refreshList={refreshList}
                      redirectionPage={itemCard.redirectionPage}
                      redirectionTab={itemCard.redirectionTab}
                      notificationType={itemCard.notificationContentType}
                      titleNotification={` ${t('notification.drawer.title.assignTask')}`}
                      messageNotification={`"${itemCard.taskName}" ${t(
                        'notification.drawer.body.startTask.for'
                      )} ${itemCard.requestName} ${t(
                        'notification.drawer.body.assignTask.hasAssign'
                      )}`}
                      icon={
                        <ProcessIcon
                          Icon={
                            <IconSelector
                              type={itemCard.requestName.replace(/\s/g, '').replace(/-/g, '')}
                              style={{
                                fontSize: 20,
                                height: '20px',
                                width: '20px',
                                color: colors.skyColor,
                                verticalAlign: 'textBottom',
                              }}
                            />
                          }
                        />
                      }
                      hasComment={false}
                      hasNotifications={itemCard.notificationCount > 1}
                      processInstance={itemCard.processInstanceId}
                      isLastElement={item.slice(-1)[0] === itemCard}
                      isRead={itemCard.isRead}
                      id={itemCard.id}
                      refreshBadge={refreshBadge}
                    />
                  );

                case NotificationType.COMMENT_TO_ASSIGNEE:
                case NotificationType.COMMENT_TO_REQUESTER:
                  return (
                    <NotificationCard
                      requestId={itemCard.requestId}
                      requestName={itemCard.requestName}
                      refreshList={refreshList}
                      redirectionPage={itemCard.redirectionPage}
                      redirectionTab={itemCard.redirectionTab}
                      notificationType={itemCard.notificationContentType}
                      titleNotification={`${itemCard.commenterName} ${t(
                        'notification.drawer.title.commentToRequester'
                      )}`}
                      messageNotification={`${itemCard.commenterName} ${t(
                        'notification.drawer.body.commentToRequester'
                      )} ${itemCard.requesterName} ${itemCard.taskName}`}
                      icon={
                        <MSTeamsUserAvatar
                          id={itemCard.commenterId}
                          name={itemCard.commenterName}
                          size={40}
                        />
                      }
                      hasComment={itemCard.commentText !== null}
                      commentName={itemCard.commenterName}
                      commentText={itemCard.commentText}
                      hasNotifications={itemCard.notificationCount > 1}
                      processInstance={itemCard.processInstanceId}
                      isLastElement={item.slice(-1)[0] === itemCard}
                      isRead={itemCard.isRead}
                      id={itemCard.id}
                      refreshBadge={refreshBadge}
                    />
                  );
                case NotificationType.COMMENT_TO_GROUP:
                  return (
                    <NotificationCard
                      requestId={itemCard.requestId}
                      requestName={itemCard.requestName}
                      refreshList={refreshList}
                      redirectionPage={itemCard.redirectionPage}
                      redirectionTab={itemCard.redirectionTab}
                      notificationType={itemCard.notificationContentType}
                      titleNotification={`${itemCard.commenterName} ${t(
                        'notification.drawer.title.commentToGroup'
                      )}`}
                      messageNotification={`${itemCard.commenterName} ${t(
                        'notification.drawer.body.commentToGroup'
                      )} ${itemCard.taskName}`}
                      icon={<GroupActiveAvatarIcon />}
                      hasComment={itemCard.commentText !== null}
                      commentName={itemCard.commenterName}
                      commentText={itemCard.commentText}
                      hasNotifications={itemCard.notificationCount > 1}
                      processInstance={itemCard.processInstanceId}
                      isLastElement={item.slice(-1)[0] === itemCard}
                      isRead={itemCard.isRead}
                      id={itemCard.id}
                      refreshBadge={refreshBadge}
                    />
                  );

                case NotificationType.COMMENT_MENTION:
                  return (
                    <NotificationCard
                      requestId={itemCard.requestId}
                      requestName={itemCard.requestName}
                      refreshList={refreshList}
                      redirectionPage={itemCard.redirectionPage}
                      redirectionTab={itemCard.redirectionTab}
                      notificationType={itemCard.notificationContentType}
                      titleNotification={`${itemCard.commenterName} ${t(
                        'notification.drawer.title.commentMention'
                      )}`}
                      messageNotification={`${itemCard.commenterName} ${t(
                        'notification.drawer.body.commentMention'
                      )} ${itemCard.requestName}`}
                      icon={
                        <MSTeamsUserAvatar
                          id={itemCard.commenterId}
                          name={itemCard.commenterName}
                          size={40}
                        />
                      }
                      hasComment={itemCard.commentText !== null}
                      commentName={itemCard.commenterName}
                      commentText={itemCard.commentText}
                      hasNotifications={itemCard.notificationCount > 1}
                      processInstance={itemCard.processInstanceId}
                      isLastElement={item.slice(-1)[0] === itemCard}
                      isRead={itemCard.isRead}
                      id={itemCard.id}
                      refreshBadge={refreshBadge}
                    />
                  );
                default:
                  return null;
              }
            })}
          </>
        );
      })}
    </Row>
  );
};

export const GeneratedNotification = React.memo(GeneratedNotificationComponent);

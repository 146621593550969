import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Avatar, Col, Divider, Row, Space, Typography } from 'antd';
import { getCurrentUserAdoid, useSelector } from 'store';
import { TaskStatus, FormField, TaskDefinitionType } from 'types';
import { useTranslate } from 'translations';
import { FullWidthSpin, span } from 'ui/elements';
import { Forms } from 'ui/components/Requests/CreateRequestForm';
import { CalendarPlus, WaitingIcon } from 'assets';
import { useRowGutter } from 'ui/hooks';
import { useServicesContext } from 'app';
import { useTasksContext } from 'routes';
import { ReassignTask } from '../TaskSteps/ReassignTask';
import {
  CardTitle,
  CardTitleContainer,
  EndEventText,
  StyledCard,
  StyledReassignInCardIcon,
  StyledRequestId,
  StyledTaskCardCol,
  StyledTaskCardColTaskName,
  StyledTaskName,
  StyledUserAvatar,
} from './TaskCard.styled';
import { TaskCardActionButton } from './TaskCardActionButton';
import { TaskCArdClaimTask } from './TaskCardClaimTask';
import { RequesterDetail } from './TaskCard_RequestedBy';
import { AvatarPupOver } from './TaskCard_Avatar_popover';
import { CompletedByDetail, CompletedByDetailProps } from './TaskCard_CompletedBy';
import { nonPoBudgetTeamFormFields } from './NonPoBudgetTeamSubmitFormFields.util';

type FormData = FormField[];

interface TaskCardProps extends CompletedByDetailProps {
  processInstanceId: string;
  date: string;
  title?: string | null;
  description?: string | null;
  stepTitle: string | null;
  stepStatus: string;
  assignedTo?: string;
  assigneeId?: string;
  taskName: string;
  requestPrettyId?: string;
  mytask: boolean;
  userGroupId?: string;
  reLoadCallback?: () => void;
  isMyRequest: boolean;
  isProcessed?: boolean;
  formFields?: FormData | null;
  taskId?: string;
  endEvent?: string;
  setIsClickOnCard?: (value: boolean) => void;
  myGroupTask?: boolean;
  requester?: { dOid: string; email: string; name: string };
  appliedAction: string | null;
  rootPath?: string;
  isClickedOnCard?: boolean;
  isJira?: boolean;
  jiraStatus?: string;
  processDefinitionKey?: string;
  taskDefinitionKey?: string;
}

const { Text } = Typography;
const { span24, span6, span18 } = span;

export const TaskCardUpdate: FC<TaskCardProps> = ({
  processInstanceId,
  date,
  title,
  stepTitle,
  stepStatus,
  taskName,
  assigneeId,
  mytask,
  userGroupId,
  reLoadCallback: reassignCallback,
  isMyRequest,
  isProcessed,
  requestPrettyId,
  formFields,
  taskId,
  myGroupTask,
  setIsClickOnCard,
  endEvent,
  requester,
  appliedAction,
  assignedTo,
  rootPath,
  isClickedOnCard,
  isJira,
  jiraStatus,
  completedBy,
  processDefinitionKey,
  taskDefinitionKey,
}) => {
  const { t } = useTranslate();
  const { path } = useRouteMatch();
  const getUrl = useCallback((pagePath: string) => `${path}${pagePath}`, [path]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { setRootPath } = useTasksContext();
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [allTasksFormInstances, setAllTasksFormInstances] = useState<Forms | null>(null);
  const currentUserId = useSelector(getCurrentUserAdoid);
  const Gutter_12 = useRowGutter(12);
  const history = useHistory();
  const {
    setFieldHidden,
    setDependenciesFieldsBetweenForms,
    dependenciesFieldsBetweenForms,
  } = useServicesContext();
  const nonPoBudgetTeamTask = useMemo(() => {
    return nonPoBudgetTeamFormFields();
  }, []);

  const cardSelected = useCallback(
    (processInstanceId: string) => {
      setRootPath(undefined);
      setIsOpen(true);
      history.replace(getUrl(`/${processInstanceId}`));
      setFieldHidden({ isHidden: null, fieldId: undefined });

      if (dependenciesFieldsBetweenForms && dependenciesFieldsBetweenForms.fieldId) {
        setDependenciesFieldsBetweenForms({});
      }
      if (setIsClickOnCard) {
        setIsClickOnCard(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getUrl, history]
  );

  const splitterPath = pathname.split('/');
  const id = splitterPath[splitterPath.length - 1];

  useEffect(
    () => {
      if (processInstanceId === id) {
        setIsOpen(true);
        if (!isClickedOnCard) {
          if (setIsClickOnCard) {
            setIsClickOnCard(true);
          }
        }
      } else {
        setIsOpen(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, processInstanceId]
  );

  const isCurrentUser = useMemo(() => assigneeId === currentUserId, [assigneeId, currentUserId]);

  return (
    <React.Fragment>
      {formLoading ? (
        <FullWidthSpin />
      ) : (
        <StyledCard $highlighted={isOpen}>
          <div onClick={() => cardSelected(processInstanceId)}>
            <Row justify="space-between" gutter={Gutter_12}>
              <Col>
                <StyledRequestId type="secondary" ellipsis={true}>
                  {requestPrettyId}
                </StyledRequestId>
              </Col>
              <Col>
                <Text type="secondary">
                  <Space>
                    {date}
                    <CalendarPlus />
                  </Space>
                </Text>
              </Col>

              <CardTitleContainer span={24}>
                <CardTitle>{title}</CardTitle>
              </CardTitleContainer>
            </Row>

            <Row gutter={Gutter_12}>
              <StyledTaskCardColTaskName span={span18}>
                {isJira && jiraStatus ? (
                  <StyledTaskName ellipsis={true}>{jiraStatus}</StyledTaskName>
                ) : (
                  !(isMyRequest && stepStatus === TaskStatus.processed) && (
                    <StyledTaskName ellipsis={true}>{stepTitle}</StyledTaskName>
                  )
                )}
              </StyledTaskCardColTaskName>

              <StyledTaskCardCol span={span6}>
                {isMyRequest ? (
                  stepStatus === TaskStatus.inProgress ? (
                    <Avatar.Group maxCount={2} size={'default'}>
                      <WaitingIcon />
                      <StyledUserAvatar>
                        <AvatarPupOver currentUserId={assigneeId} currentUserName={assignedTo} />
                      </StyledUserAvatar>
                    </Avatar.Group>
                  ) : stepStatus === TaskStatus.pending ? (
                    <WaitingIcon />
                  ) : null
                ) : mytask ? (
                  stepStatus === TaskStatus.inProgress &&
                  taskId && (
                    <ReassignTask
                      taskId={taskId}
                      groupId={userGroupId}
                      reassignCallback={reassignCallback}
                    >
                      <StyledReassignInCardIcon />
                    </ReassignTask>
                  )
                ) : stepStatus === TaskStatus.pending && taskId && userGroupId ? (
                  <TaskCArdClaimTask
                    taskId={taskId}
                    userGroupId={userGroupId}
                    reassignCallback={reassignCallback}
                    t={t}
                  />
                ) : (
                  <AvatarPupOver currentUserId={assigneeId} currentUserName={assignedTo} />
                )}
              </StyledTaskCardCol>
            </Row>

            <Row justify="space-between">
              {!isMyRequest && requester !== undefined && (
                <Col span={span24}>
                  <RequesterDetail
                    requesterEmail={requester.email}
                    requesterId={requester.dOid}
                    t={t}
                    requesterName={requester.name}
                  />
                </Col>
              )}
              {!isMyRequest && (
                <Col span={span24}>
                  <CompletedByDetail completedBy={completedBy} assigneeId={assigneeId} />
                </Col>
              )}

              {isProcessed && (
                <StyledTaskCardCol span={span24}>
                  <EndEventText ellipsis>
                    {isMyRequest && endEvent ? endEvent : appliedAction && appliedAction}
                  </EndEventText>
                </StyledTaskCardCol>
              )}
            </Row>
          </div>

          {!isProcessed &&
            (isCurrentUser || myGroupTask) &&
            formFields?.some((field) => field.typeName === 'button') && (
              <>
                <Divider />

                <Row justify="space-between">
                  <TaskCardActionButton
                    taskId={taskId}
                    taskName={taskName}
                    processInstanceId={processInstanceId}
                    formFields={
                      taskDefinitionKey === TaskDefinitionType.NON_PO_BUDGET_TEAM_FORM_TASK
                        ? nonPoBudgetTeamTask
                        : formFields
                    }
                    setFormLoading={setFormLoading}
                    setAllTasksFormInstances={setAllTasksFormInstances}
                    allTasksFormInstances={allTasksFormInstances}
                    t={t}
                    getUrl={getUrl}
                    reassignCallback={reassignCallback}
                    setIsClickOnCard={setIsClickOnCard}
                    rootPath={rootPath}
                    processDefinitionKey={processDefinitionKey}
                    taskDefinitionKey={taskDefinitionKey}
                  />
                </Row>
              </>
            )}
        </StyledCard>
      )}
    </React.Fragment>
  );
};

import styled from 'styled-components';
import { Collapse } from 'antd';

export const StyledCollapse = styled(Collapse).attrs({
  ghost: true,
})`
  .ant-collapse-header {
    margin-left: -20px;
  }
  .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0 !important;
  }
`;

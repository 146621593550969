import React from 'react';
import { useTranslate } from 'translations';
import { SearchOutlined } from '@ant-design/icons';
import { useDebounce } from 'ui/hooks';
import {
  UserDrawerSearchProcessContainer,
  StyledSearchLabel,
  StyledAutoComplete,
  StyledSearchInput,
  StyledAutoCompleteOption,
} from './UserDrawerSearchProcess.styled';

type Props = {
  onValueChange: (value: number | null) => void;
  processes: { id: number; name: string }[];
};

export const UserDrawerSearchProcess: React.FC<Props> = ({ onValueChange, processes }) => {
  const { t } = useTranslate();
  const [options, setOptions] = React.useState<
    { id: number; value: string; label: React.ReactElement }[]
  >([]);
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const processSearch = React.useCallback(
    (keyword: string) => {
      return processes.filter((process) => process.name.toLowerCase().includes(keyword));
    },
    [processes]
  );
  const buildProcessesOptions = (processes: { id: number; name: string }[]) => {
    return processes.map((option) => {
      const label = <StyledAutoCompleteOption>{option.name}</StyledAutoCompleteOption>;
      return { id: option.id, value: option.name, label: label };
    });
  };
  const onSearch = React.useCallback((value) => {
    if (value) {
      const filteredProcesses = processSearch(value);
      const options: {
        id: number;
        value: string;
        label: React.ReactElement;
      }[] = buildProcessesOptions(filteredProcesses);
      setOptions(options);
    } else {
      const options: {
        id: number;
        value: string;
        label: React.ReactElement;
      }[] = buildProcessesOptions(processes);
      setOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearch]);

  return (
    <UserDrawerSearchProcessContainer>
      <StyledSearchLabel>
        {t('processManagement.users.drawer.popover.search.label')}
      </StyledSearchLabel>
      <StyledAutoComplete
        options={options}
        allowClear
        placeholder={t('search')}
        notFoundContent={t('dropdown.noMatching')}
        onSearch={setSearchTerm}
        onSelect={(value, option) => onValueChange(option.id)}
        onClear={() => onValueChange(null)}
      >
        <StyledSearchInput suffix={<SearchOutlined />} />
      </StyledAutoComplete>
    </UserDrawerSearchProcessContainer>
  );
};

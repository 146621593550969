import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';
import { currentTeamIdAndChannelId } from '../teamsChannelMembers';

export const teamsChannelFiles = (state: RootState): RootState['teamsFiles'] => state.teamsFiles;

export const getCurrentTeamsChannelFilesData = createSelector(
  [teamsChannelFiles, currentTeamIdAndChannelId],
  (channels, { teamId, channelId }) => (channels[teamId] ? channels[teamId][channelId] : undefined)
);

import styled from 'styled-components';
import { TitleH2, typography, space, colors } from 'ui/elements';

export const StyledNotificationTitle = styled(TitleH2)`
  font-weight: ${typography.weight.semiBold};
`;

export const StyledNotificationWrapper = styled.div`
  margin: ${space.comfy} ${space.slim};
  h4 {
    color: ${colors.textColorSecondary};
    font-weight: ${typography.weight.regular};
  }
`;

import { useQuery } from 'react-query';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';
import { AxiosRequestConfig } from 'axios';
import { sitesIds } from '../SiteIds';

export const useGetSiteIds = (config?: AxiosRequestConfig) => {
  return useQuery(
    [QUERIES.siteIds, config?.params],
    async () => {
      const { data } = await sitesIds.getSiteIds(config);
      return data;
    },
    { keepPreviousData: false, ...PERSIST_QUERY_CONFIG }
  );
};

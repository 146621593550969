import { Menu } from 'antd';
import styled from 'styled-components';
import { colors } from 'ui/elements';
import { getRandomColorById } from 'ui';

export const SideBar = styled.div`
  height: 100%;
  min-height: calc(100vh - 56px);
  box-shadow: 0 6px 6px rgba(29, 45, 56, 0.15);
  background: white;
  display: flex;
  flex-direction: column;
  align: center;
  justify-content: space-between;
`;

export const StyledMenuLibraries = styled(Menu)`
  &.ant-menu-vertical {
    padding: 0;
    .ant-menu-item-group {
      max-height: calc(100vh - 100px);
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
`;

export const StyledMenu = styled(Menu)`
  &.ant-menu-vertical {
    padding: 0;
    .ant-menu-item-group {
      max-height: calc(100vh - 100px);
      overflow-x: hidden;
      overflow-y: auto;
    }
   }
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  &.ant-menu-item.ant-menu-item-only-child,
  &.ant-menu-item.sidebar-menu-item {
    margin: 0;
    margin-bottom: 0;
    padding: 0 24px;

    &.ant-menu-item.ant-menu-item-selected,
    &.ant-menu-item.ant-menu-item-active {
      background-color: #f6f7f9;
    }
  }
`;

export const StyledMenuItemGroup = styled(Menu.ItemGroup)`
  .ant-menu-item-group-title {
    font-weight: bold;
    color: inherit;
    padding: 9px 24px;
  }

  &.ant-menu-item-group {
    .ant-menu-item {
      margin: 0;
      margin-bottom: 0;
      padding: 4px 24px;
      display: flex;
      align-items: center;
      height: auto;
      min-height: 40px;

      &.ant-menu-item.ant-menu-item-selected,
      &.ant-menu-item.ant-menu-item-active {
        background-color: #f6f7f9;
      }
    }
  }
`;

export const StyledLibAvatar = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ id }: { id: string }) => getRandomColorById(id)};
  border-radius: 2px;
  font-size: 12px;
  line-height: 24px;
  color: ${colors.white};
`;

import { Card, notification } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { PagesUrls } from 'namespace';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { convertFilterDates } from 'routes/Calendar';
import {
  getCurrentUserAdoid,
  getProfileExpenseStart,
  profileExpenseData,
  useDispatch,
  useSelector,
} from 'store';
import { TType } from 'translations';
import { ExpenseData, RequestStatus, Sort, SortFull } from 'types';
import { StyledTable } from 'ui/components/OneDrive/OneDriveShared/OneDriveShared.styled';
import { StatusTag, table, TagColor } from 'ui/elements';
import { EXPENSE_DATE_FORMAT, LIST_VIEW_PAGE_SIZE } from '../constants';
import { StyledSpan, StyledText } from '../Expenses.styled';
import { ExpenseDetailDrawer, ExpensesDrawer } from '../ExpensesDrawer';

const DISABLE_UPDATED_DRAWER = process.env.REACT_APP_DISABLE_UPDATED_EXPENSE_DRAWER !== 'true';

interface ColumnItem {
  title: string;
  dataIndex: string;
  key: string;
  width: string;
}

interface ExpensesTableProps {
  t: TType;
  filterValues: Record<string, string | string[]>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
}

const { width } = table;
const completedStatus = 'Completed';
const inProgressStatus = 'In Progress';

const ExpensesTableComponent: React.FC<ExpensesTableProps> = ({
  t,
  filterValues,
  setCurrentPage,
  currentPage,
}) => {
  const [currentExpenseId, setCurrentExpenseId] = useState<string>('');
  const [tableData, setTableData] = useState<ExpenseData>();
  const history = useHistory();

  const expenses = useSelector(profileExpenseData);
  const adOid = useSelector(getCurrentUserAdoid);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const onClickHandler = useCallback(
    (processInstanceId: string) => {
      const toCurrentRequest = {
        pathname: `${PagesUrls.Tasks}/${processInstanceId}`,
        state: { defaultTab: '0' },
      };
      history.replace(toCurrentRequest);
    },
    [history]
  );

  const getExpenses = useCallback(
    (sortValue?: string, sortOrder?: string) => {
      try {
        if (adOid && adOid !== null) {
          setIsLoading(true);
          dispatch(
            getProfileExpenseStart({
              adOid,
              params: {
                offset: currentPage * LIST_VIEW_PAGE_SIZE,
                limit: LIST_VIEW_PAGE_SIZE,
                ...(sortValue && sortOrder && { sort: sortValue, sortDirection: sortOrder }),
                ...convertFilterDates(filterValues),
              },
            })
          );
        }
      } catch (e) {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: t('messages.error.tryAgainLater'),
        });
      }
    },
    [currentPage, dispatch, filterValues, adOid, t]
  );

  useEffect(() => {
    getExpenses();
  }, [currentPage, getExpenses, filterValues]);

  useEffect(() => {
    if (expenses.status === RequestStatus.resolved) {
      setIsLoading(false);
      setTableData(expenses?.data);
    } else if (expenses.status === RequestStatus.rejected) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
      setIsLoading(false);
    }
  }, [expenses.status, expenses?.data, t]);

  const tableContent = useMemo(() => {
    if (tableData?.list && tableData?.list.length > 0) {
      return tableData?.list.map((item, index) => ({
        key: index,
        id: item.id,
        prettyId: (
          <StyledText>
            <StyledSpan
              onClick={() => {
                onClickHandler(item?.processInstanceId);
              }}
            >
              {item?.prettyId}
            </StyledSpan>
          </StyledText>
        ),
        requestType: <StyledText>{item?.requestType}</StyledText>,

        createdDate: (
          <StyledText>{moment(item?.createdDate).format(EXPENSE_DATE_FORMAT)}</StyledText>
        ),
        totalAmount: <StyledText>{item?.totalAmount}</StyledText>,
        currency: <StyledText>{item?.currency}</StyledText>,
        status:
          item.status === completedStatus ? (
            <StatusTag color={'green' as TagColor}>{item.status}</StatusTag>
          ) : item.status === inProgressStatus ? (
            <StatusTag color={'blue' as TagColor}>{item.status}</StatusTag>
          ) : (
            <StatusTag color={'red' as TagColor}>{item.status}</StatusTag>
          ),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData?.list]);

  const getColumns = (t: TType) => {
    const columns: ColumnsType<ColumnType<ColumnItem>> = [
      {
        title: t('expenses.table.requestId'),
        dataIndex: 'prettyId',
        key: 'prettyId',
        align: 'left',
        width: width.twenty,
        sorter: true,
      },
      {
        title: t('expenses.table.requestType'),
        dataIndex: 'requestType',
        key: 'requestType',
        align: 'left',
        width: width.twenty,
        sorter: true,
      },
      {
        title: t('date'),
        dataIndex: 'createdDate',
        key: 'createdDate',
        align: 'center',
        width: width.twenty,
        sorter: true,
      },
      {
        title: t('expenses.table.totalAmount'),
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        align: 'center',
        width: width.fifteen,
        sorter: true,
      },
      {
        title: t('expenses.table.currency'),
        dataIndex: 'currency',
        key: 'currency',
        align: 'center',
        width: width.fifteen,
        sorter: true,
      },
      {
        title: t('expenses.table.status'),
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: width.twenty,
        sorter: true,
      },
    ];

    return columns;
  };

  const onTableChange = useCallback((pagination, sorter) => {
    const { current } = pagination;
    setCurrentPage(current ? current - 1 : 0);
    const { field, order } = sorter;
    if (field) {
      const sortOrder =
        order === SortFull.ascend ? Sort.asc : order === SortFull.descend ? Sort.desc : '';
      getExpenses(field, sortOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRow = useCallback(
    (record) => {
      return {
        onClick: () => setCurrentExpenseId(`${record.id}`),
      };
    },
    [setCurrentExpenseId]
  );

  const columns = useMemo(() => getColumns(t), [t]);
  return (
    <React.Fragment>
      <Card>
        <StyledTable
          scroll={{
            scrollToFirstRowOnChange: true,
            x: true,
          }}
          pagination={{
            total: tableData?.totalResult,
            showSizeChanger: false,
            defaultCurrent: currentPage + 1,
            pageSize: LIST_VIEW_PAGE_SIZE,
            current: currentPage + 1,
          }}
          showSorterTooltip={false}
          dataSource={tableContent}
          columns={columns}
          loading={isLoading}
          onChange={onTableChange}
          onRow={onRow}
        />
      </Card>

      {currentExpenseId ? (
        DISABLE_UPDATED_DRAWER ? (
          <ExpenseDetailDrawer
            currentExpenseId={currentExpenseId}
            setCurrentExpenseId={setCurrentExpenseId}
            onPrettyIdClickHandler={onClickHandler}
          />
        ) : (
          <ExpensesDrawer
            currentExpenseId={currentExpenseId}
            setCurrentExpenseId={setCurrentExpenseId}
            onPrettyIdClickHandler={onClickHandler}
          />
        )
      ) : null}
    </React.Fragment>
  );
};

export const ExpensesTable = React.memo(ExpensesTableComponent);

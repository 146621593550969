export const getAvatarSymbols = (name: string) => {
  const [firstWord = ' ', secondWord = ' ', thirdWord = ''] = name
    .replace(/"/g, '')
    .split(' ')
    .map((word) => word.trim())
    .filter((word) => !!word);

  const avatarSymbols =
    firstWord[0].toUpperCase() +
    (thirdWord.length > 0 ? thirdWord[0].toUpperCase() : secondWord[0].toUpperCase());

  return avatarSymbols;
};

import { createContext, useContext } from 'react';

export interface AuthContextType {
  login(redirectPath?: string): Promise<void>;
  logout(): void;
  isAuthenticated: boolean | null;
  redirectPath?: string;
  requestedUrl?: string;
  tokenExpireTime: Date | null;
  getToken: (requestedUrl: string | undefined) => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);
export const useAuthContext = (): AuthContextType => {
  const context = useContext<AuthContextType>(AuthContext);
  if (!context) {
    throw new Error('useAuthContext should be used within a AuthContext');
  }
  return context;
};

import React, { FC, memo, useEffect } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { useServicesContext } from '../../app';
import { TasksProvider } from './TasksProvider';
import { TasksLayout } from './TasksLayout';
import { ServiceNewRequest } from './components';

type Props = RouteComponentProps;

export const TasksComponent: FC<Props> = ({ match: { path } }) => {
  const { setPrettyIdInfoRedirection, setIsFilterByPrettyId } = useServicesContext();

  /**
   * On component deteched
   * clear context which contains
   * current filter of the context
   * for route
   */
  useEffect(() => {
    return () => {
      setIsFilterByPrettyId(false);
      setPrettyIdInfoRedirection({});
    };
  }, [setIsFilterByPrettyId, setPrettyIdInfoRedirection]);

  return (
    <TasksProvider>
      <Switch>
        <Route
          path={[
            `${path}/:processDefinitionKey?/:isDynamicForm?/new-request/:jiraServiceDeskId?/:jiraRequestTypeId?`,
            `${path}/:processDefinitionKey?/:isDynamicForm?/edit-request/:taskId?/:processId?`,
          ]}
          exact
        >
          <ServiceNewRequest />
        </Route>
        <Route path={path}>
          <TasksLayout />
        </Route>
      </Switch>
    </TasksProvider>
  );
};

export const Tasks = memo(withRouter(TasksComponent));

export const constants = {
  breakpoints: {
    smallAndUp: '(min-width: 480px)',
    xSmallAndDown: '(max-width: 479px)',
    small: '(max-width: 767px)',
    medium: '(min-width: 768px)',
    large: '(min-width: 1024px)',
    mediumAndUp: '(min-width: 768px)',
    largeAndUp: '(min-width: 1024px)',
    midLargeAndUp: '(min-width: 1280px)',
    midLargeAndDown: '(max-width: 1280px)',
    xLarge: '(min-width: 1440px)',
    xLargeAndUp: '(min-width: 1440px)',
  },
  drawer: {
    width: 560,
  },
  qr: {
    small: 60,
    large: 550,
  },
};

export const borderRadius = {
  small: '2px',
  large: '4px',
  xLarge: '8px',
  xxLarge: '10px',
};

export const space = {
  zero: '0px',
  micro: '2px',
  mini: '4px',
  little: '6px',
  slim: '8px',
  cozy: '12px',
  moderate: '16px',
  normal: '24px',
  comfy: '32px',
};

export const colors = {
  labelGrey: '#8B93A2',
  darkGrey: '#2E519E',
  borderGrey: '#DFE0EB',
  borderGreySecond: '#dae0e7',
  lightGrey: '#949CAD',
  lightGreySecond: '#E1E2E5',
  lightGreyThird: '#f2f4f8',
  lightGreyFourth: '#F3F4F7',
  lightActive: '#B1B7C8',
  lightActiveWithOpacity: 'rgba(177, 183, 200, 0.1)',
  lightActionSecond: '#e1e2e57d',
  lightGreyScroll: '#dae0e6',
  white: '#fff',
  primaryColor: '#253746',
  primaryColorWithOpacity: 'rgba(37, 55, 70, 0.95)',
  textColorSecondary: '#606f8a',
  blue: '#0145da',
  lightBlue: '#5ab8fc',
  orange: '#f14a04',
  accentOrange: '#fc4c02',
  accentPrimary: '#384752',
  jungle: '#00909e',
  skySecondary: '#00A5B5',
  red: '#f5222d',
  redSecond: '#eb5757',
  lightRed: '#d68383',
  green: '#05965e',
  polarGreen: '#52C41A',
  nature: '#00AC69',
  sand: '#D7A461',
  chatMessage: '#E4E9F1',
  orangeShadow: '#FF5A14',
  plumSecondary: '#7E5475',
  mentionTextColor: '#9ea2ff',
  backgroundGrey: '#F6F7F9',
  lightpink: '#FBEBEA',
  lightYellow: '#edc10a',
  gradientPrimaryColor: '#E8ECFF',
  gradientSecondaryColor: '#cac1e6',
  gradientTeritaryColor: '#8E1515',
  pointerColor: '#FC6111',
  transparentColor: 'transparent',
  black: '#000',
  secondBlack: '#585757',
  darkBlue: '#163C8F',
  lightWhiteBlue: '#264DA3',
  weekendBackground: '#fafbfc',
  toDayCalendar: '#EFFAFB',
  attendanceWarning: '#FF4D4F',
  lightBlueSecondary: '#3b60b2',
  lightOrange: '#FFF2EB',
  darkPink: '#D9166B',
  skyColor: '#0099A8',
  skyLight: '#0099a81a',
  grey: '#898C9F',
  softGray: '#EAEAEA',
  lightSoftGray: '#f8f4f4',
  lightGreenBackground: '#E1F5ED',
  grayTooltip: '#7b7d80ba',
  lightShardGray: '#F6F6F6',
  hoverLightGray: '#f6f6f678',
  lightOverlayBlue: '#606F8A',
  lightSecondaryGray: '#00000073',
  greyBackLabel: '#F0F1F5',
  greyBackLabelHover: '#ededef',
  metallicSilver: '#a8afb5',
  lightSilver: '#D9D9D9',
  darkerLightSilver: '#D0D5DD',
  scrollbarColor: 'rgba(90,91,93,0.4)',
  lightCyan: '#DFF9FB',
  skyLink: '#0099A8',
  blackWith85Opacity: 'rgba(0, 0, 0, 0.85)',
  tawalGrey2: '#505F6B',
  divider: '#E9E9E9',
};

export const typography = {
  weight: {
    light: 300,
    regular: 400,
    bold: 500,
    semiBold: 600,
    bolder: 700,
  },
  size: {
    mini: '10px',
    uno: '12px',
    hecto: '14px',
    kilo: '16px',
    giga: '18px',
    tera: '24px',
    zetta: '32px',
    bronto: '40px',
  },
};

export const carouselBreakPoints = {
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

export const table = {
  align: {
    left: 'left',
    center: 'center',
    right: 'right',
  },
  width: {
    zero: '0%',
    five: '5%',
    ten: '10%',
    fifteen: '15%',
    twenty: '20%',
    twentyfive: '25%',
    thirty: '30%',
  },
};

export const span = {
  span2: 2,
  span3: 3,
  span4: 4,
  span6: 6,
  span8: 8,
  span9: 9,
  span10: 10,
  span12: 12,
  span13: 13,
  span14: 14,
  span16: 16,
  span18: 18,
  span20: 20,
  span24: 24,
  span40: 40,
  span49: 49,
};

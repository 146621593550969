import styled from 'styled-components';
import { Row, Col, Typography } from 'antd';
import ProfileCover from 'assets/backgrounds/profileCover.png';
import { colors, space } from 'ui/elements';

const { Text } = Typography;

export const StyledNewProfilePageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 64px;
  margin-bottom: 25px;
  position: relative;
`;

export const StyledNewProfileCoverWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 340px;
  z-index: 1;
  background-image: url(${ProfileCover});
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const StyledNewProfileContentWrapper = styled(Row)`
  width: 100%;
  margin-top: 200px;
  padding: 0 25px;
  z-index: 2;
`;

export const NewProfileContentRightSideWrapper = styled(Col)`
  max-width: calc(100% - 325px);
`;

export const StyledPublicProfileContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
`;

export const StyledProfileRowContainer = styled(Row)`
  width: 100%;
`;

export const StyledProfileFieldLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.textColorSecondary};
  margin-bottom: 5px;
`;
export const StyledProfileFieldLabelForDate = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.textColorSecondary};
  margin: 0;  !important;
  padding-bottom: 0; !important;
  padding-top: 2px; !important;
  padding-right: 4px; !important;
`;
export const StyledProfileFieldValue = styled(Text)`
  font-size: 16px !important;
  font-weight: 500 !important;
  color: ${colors.primaryColor} !important;
  margin-bottom: 0 !important;
  width: 100%;
`;

export const StyledRequestNameField = styled(Text)`
  font-family: 'Tajawal', sans-serif;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: ${colors.primaryColor} !important;
  margin-bottom: 10 !important ;
  width: 100px;
`;

export const StyledCommentIconContainer = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledCommentIcon = styled(Text)`
  font-size: 16px !important;
  font-weight: 400 !important;
  color: rgba(80, 95, 107, 1) !important;
  width: 100px;
  cursor: pointer;
  margin-left: ${space.mini};
`;

export const StyledRequestCardRequestIdRow = styled(Row)`
  width: 100%;
  margin-bottom: 8px;
`;

export const StyledRequestCardRequestNameRow = styled(Row)`
  width: 100%;
  margin-bottom: 10px;
`;

export const StyledRequestCardRequestCommentsRow = styled(Row)`
  width: 100%;
  margin-top: 20px;
  align-items: center;
`;

export const StyledLink = styled(Typography.Link)`
  font-size: 16px;
`;

export const StyledIconContainer = styled.span`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  //background-color: ${colors.white};
  //box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 35px;
    height: 35px;
    //fill: #aacbe3;
  }
`;

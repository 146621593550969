import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBusinessTravelForm, useCountryCityList, useDistanceBWCities, useGetSiteIds } from 'api';
import {
  CitiesList,
  CountryListParams,
  DurationParams,
  SelectOptions,
  SiteIdParams,
  Transportation,
} from 'types';
import { NamePath } from 'antd/lib/form/interface';
import { useGetEstimatedTotalAmount, useGetTravelDuration } from 'api/TravelDuration';
import {
  checkSelectedTravelLocations,
  checkTheIncludedBetweenDates,
  defaultPageSize,
  durationFormat,
  UseInfoProps,
} from '../helper';

const MIN_DISTANCE = 200;
const METER_ON_KILO = 1000;

export const useInformationOfForm = (useInfoProps: UseInfoProps) => {
  const [searchParams, setSearchParams] = useState<SiteIdParams>();
  const [citySearchParams, setCitySearchParams] = useState<CountryListParams>();
  const [durationParams, setDurationParams] = useState<DurationParams>();
  const { data: formData, isError, isLoading: isBusinessTravelLoading } = useBusinessTravelForm(
    useInfoProps.adoid
  );
  const [mSearchedCities, setMSearchedCities] = useState<CitiesList[] | undefined>([]);
  const [mDestinationData, setMDestinationData] = useState<CitiesList | undefined>(undefined);
  const { data, isLoading, refetch: refetchSiteId, isRefetching } = useGetSiteIds({
    params: { ...searchParams },
  });

  const {
    data: searchCitiesList,
    refetch: refetchCountryCityList,
    isLoading: isCityLoading,
    isRefetching: isRefetchingCities,
  } = useCountryCityList({
    params: { ...citySearchParams, ...defaultPageSize },
  });

  useMemo(() => {
    if (searchCitiesList) {
      if (setMSearchedCities) {
        setMSearchedCities(searchCitiesList.list);
      }
    }
  }, [searchCitiesList]);

  const handleOnToDestinationSelected = useCallback(
    (selectedDestinationID: string) => {
      if (selectedDestinationID) {
        if (mSearchedCities) {
          const destinationData = mSearchedCities.filter(
            (city) => city.id === Number(selectedDestinationID)
          )?.[0];
          if (destinationData) {
            setMDestinationData(destinationData);
          }
        }
      }
    },
    [mSearchedCities]
  );

  const { data: travelDuration, refetch: refetchTravelDuration } = useGetTravelDuration({
    params: { ...durationParams },
  });

  const destinationId = useMemo(
    () => useInfoProps.selectedSiteId || useInfoProps.destinationCityId,

    [useInfoProps.destinationCityId, useInfoProps.selectedSiteId]
  );

  const { data: distanceData, refetch: refetchCitiesDistance } = useDistanceBWCities(
    useInfoProps.sourceCityId as number,
    destinationId as number
  );

  useEffect(() => {
    if (
      useInfoProps.sourceCityId &&
      (useInfoProps.destinationCityId || useInfoProps.selectedSiteId)
    ) {
      refetchCitiesDistance();
    }
  }, [
    refetchCitiesDistance,
    useInfoProps.sourceCityId,
    useInfoProps.destinationCityId,
    useInfoProps.selectedSiteId,
  ]);

  const totalDistance = useMemo(() => distanceData && distanceData?.distance, [distanceData]);

  const siteIdOption = useMemo(() => {
    return data?.map((site) => {
      const siteInfo = { value: site.value, label: site.label };
      return siteInfo;
    });
  }, [data]);

  const onSearchSitId = (value?: string) => {
    if (value) {
      setSearchParams({ search: value });
    } else {
      setSearchParams(undefined);
    }
  };

  const onSearchCityId = (cityInSaudi: boolean, value?: string) => {
    if (value) {
      setCitySearchParams({ search: value, inSaudi: cityInSaudi });
    }
  };

  const tripTypeOption = useMemo(() => {
    return formData?.tripTypes?.map((type) => {
      const typeInfo = { value: type.id, label: type.type };
      return typeInfo;
    });
  }, [formData]);

  const submitDateVariables = useMemo(() => {
    let variables;
    if (useInfoProps.businessDate) {
      const [start, end] = useInfoProps.businessDate;
      if (useInfoProps.actualTravelDate) {
        const [startDate, endDate] = useInfoProps.actualTravelDate;
        variables = {
          businessStartDate: start.format(durationFormat),
          businessEndDate: end.format(durationFormat),
          actualStartDate: startDate.format(durationFormat),
          actualEndDate: endDate.format(durationFormat),
        };
      } else {
        variables = {
          businessStartDate: start.format(durationFormat),
          businessEndDate: end.format(durationFormat),
          actualStartDate: start.format(durationFormat),
          actualEndDate: end.format(durationFormat),
        };
      }
    }
    if (variables) return variables;
  }, [useInfoProps.actualTravelDate, useInfoProps.businessDate]);

  const showSiteIds = useMemo(() => {
    const isShowSite = formData
      ? formData.travelPurposes.some((purposes) => {
          return (
            useInfoProps.purposeId &&
            purposes.id === useInfoProps.purposeId &&
            purposes.showSiteId === true
          );
        })
      : false;
    return isShowSite;
  }, [formData, useInfoProps.purposeId]);

  const cityList = useMemo(() => {
    return searchCitiesList?.list.map(({ id, countryName, cityName }) => {
      return { value: id, label: `${cityName}, ${countryName}` };
    });
  }, [searchCitiesList]);

  const citiesLoading = useMemo(() => isRefetchingCities || isCityLoading, [
    isCityLoading,
    isRefetchingCities,
  ]);

  const purposeList = useMemo(
    () =>
      formData &&
      formData.travelPurposes?.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [formData]
  );

  const transportationMeanList = useMemo(() => {
    if (formData) {
      const listOfOptions = formData.transportations?.map(({ id, type }) => ({
        value: id,
        label: type,
      }));

      return distanceData && Math.round(distanceData.distanceInMeter / METER_ON_KILO) < MIN_DISTANCE
        ? listOfOptions.slice(0, 2)
        : listOfOptions;
    }
  }, [distanceData, formData]);

  const cityName = (cities?: SelectOptions, cityId?: NamePath) => {
    return cities?.reduce((acc, city) => {
      const [citySource, ,] = city.label.split(',');
      if (city.value === cityId) {
        acc = citySource;
      }
      return acc;
    }, '' as string | undefined);
  };

  const cityInSaudiId = useMemo(() => {
    let selectedCity;
    const tripInfo = formData?.tripTypes.filter((trip) => trip.inSaudi === true);
    if (useInfoProps.destinationCityId) {
      selectedCity = searchCitiesList?.list
        .filter((city) => city.id === useInfoProps.destinationCityId)
        .reduce((acc, item) => {
          const tripInfo = formData?.tripTypes.filter((trip) => trip.inSaudi === item.inSaudi);
          if (tripInfo) {
            acc = tripInfo[0]?.id;
          }
          return acc;
        }, 0 as number | undefined);
    }
    if (useInfoProps.selectedSiteId && tripInfo) {
      return tripInfo[0].id as number;
    }
    return selectedCity;
  }, [
    formData?.tripTypes,
    searchCitiesList?.list,
    useInfoProps.destinationCityId,
    useInfoProps.selectedSiteId,
  ]);

  const siteInSaudiId = useMemo(() => {
    if (useInfoProps.selectedSiteId) {
      return formData?.tripTypes.filter((trip) => trip.inSaudi === true)[0].id;
    }
  }, [formData?.tripTypes, useInfoProps.selectedSiteId]);

  const sourceCity = useMemo(() => cityName(cityList, useInfoProps.sourceCityId), [
    cityList,
    useInfoProps.sourceCityId,
  ]);

  const destinationCity = useMemo(() => cityName(cityList, useInfoProps.destinationCityId), [
    cityList,
    useInfoProps.destinationCityId,
  ]);

  const isSourceDifferentFromDestination = useMemo(
    () => checkSelectedTravelLocations(sourceCity, destinationCity),
    [sourceCity, destinationCity]
  );

  const disableMeanOfTransportation = useMemo(() => {
    /* return searchCitiesList?.list
      .filter((item) => item.id === useInfoProps.destinationCityId)
      .reduce((acc, item) => {
        if (item.hideTransportationMeans) {
          acc = item.hideTransportationMeans;
        }
        return acc;
      }, false as boolean);*/
    return mDestinationData?.hideTransportationMeans ?? false;
  }, [mDestinationData?.hideTransportationMeans]);

  const planeValue = useMemo(() => {
    return formData?.transportations.reduce((acc, mean) => {
      if (mean.type === 'Plane') {
        acc = mean;
      }
      return acc;
    }, {} as Transportation);
  }, [formData]);

  const ticketClass = useMemo(() => {
    return formData?.transportations.reduce((acc, mean) => {
      const ticket = mean.ticketClass;

      if (mean.id === useInfoProps.transportation && mean.hasTicketClass === true) {
        acc = ticket.trim();
      }
      if (disableMeanOfTransportation) {
        acc = planeValue?.ticketClass;
      }
      return acc;
    }, '' as string | undefined);
  }, [
    disableMeanOfTransportation,
    formData?.transportations,
    planeValue?.ticketClass,
    useInfoProps.transportation,
  ]);

  const disableTicketClass = useMemo(() => {
    if (ticketClass) return ticketClass.split('').length > 0 ? true : false;
  }, [ticketClass]);

  const estimatedAmountParameter = useMemo(() => {
    return {
      adOid: useInfoProps.adoid,
      sourceId: useInfoProps.sourceCityId ? (useInfoProps.sourceCityId as number) : 0,
      destinationId: useInfoProps.destinationCityId
        ? (useInfoProps.destinationCityId as number)
        : (useInfoProps.selectedSiteId as number),
      transportationId: useInfoProps.transportation && (useInfoProps.transportation as number),
      tripType: cityInSaudiId ? cityInSaudiId : siteInSaudiId ? siteInSaudiId : 0,
    };
  }, [cityInSaudiId, siteInSaudiId, useInfoProps]);

  useEffect(() => {
    refetchSiteId();
  }, [refetchSiteId, searchParams]);

  useEffect(() => {
    if (citySearchParams) refetchCountryCityList();
  }, [refetchCountryCityList, citySearchParams]);

  useEffect(() => {
    if (
      useInfoProps.businessDate &&
      (useInfoProps.destinationCityId || useInfoProps.selectedSiteId)
    ) {
      const [startDate, endDate] = useInfoProps.businessDate;
      setDurationParams({
        destinationId:
          (useInfoProps.destinationCityId as string) || (useInfoProps.selectedSiteId as string),
        startDate: startDate.format(durationFormat),
        endDate: endDate.format(durationFormat),
      });
    } else {
      setDurationParams(undefined);
    }
  }, [useInfoProps.businessDate, useInfoProps.destinationCityId, useInfoProps.selectedSiteId]);

  useEffect(() => {
    if (durationParams) {
      refetchTravelDuration();
    }
  }, [durationParams, refetchTravelDuration]);

  const {
    data: estimatedAmount,
    refetch: refetchEstimated,
  } = useGetEstimatedTotalAmount(
    estimatedAmountParameter.sourceId,
    estimatedAmountParameter.destinationId,
    estimatedAmountParameter.tripType,
    estimatedAmountParameter.transportationId as number,
    { params: { startDate: durationParams?.startDate, endDate: durationParams?.endDate } }
  );

  useEffect(() => {
    if (
      useInfoProps.adoid &&
      useInfoProps.sourceCityId &&
      (useInfoProps.destinationCityId || useInfoProps.selectedSiteId) &&
      (useInfoProps.transportation || planeValue) &&
      cityInSaudiId &&
      useInfoProps.businessDate &&
      durationParams
    ) {
      refetchEstimated();
    }
  }, [
    cityInSaudiId,
    durationParams,
    planeValue,
    refetchEstimated,
    useInfoProps.adoid,
    useInfoProps.businessDate,
    useInfoProps.destinationCityId,
    useInfoProps.selectedSiteId,
    useInfoProps.sourceCityId,
    useInfoProps.transportation,
  ]);

  const actualDateValidation = useCallback(() => {
    if (useInfoProps.actualTravelDate && useInfoProps.businessDate) {
      return checkTheIncludedBetweenDates(useInfoProps.actualTravelDate, useInfoProps.businessDate)
        ? Promise.resolve()
        : Promise.reject(useInfoProps.t('businessTravel.staticForm.validation.from.isInclude'));
    }
  }, [useInfoProps]);

  const siteIdLoading = useMemo(() => isLoading || isRefetching, [isLoading, isRefetching]);

  return {
    siteIdLoading,
    siteIdOption,
    onSearchSitId,
    searchParams,
    formData,
    isError,
    isBusinessTravelLoading,
    showSiteIds,
    citiesLoading,
    totalDistance,
    setCitySearchParams,
    cityList,
    purposeList,
    transportationMeanList,
    citySearchParams,
    isSourceDifferentFromDestination,
    ticketClass,
    disableTicketClass,
    disableMeanOfTransportation,
    planeValue,
    onSearchCityId,
    travelDuration,
    tripTypeOption,
    cityInSaudiId,
    estimatedAmount,
    submitDateVariables,
    siteInSaudiId,
    actualDateValidation,
    handleOnToDestinationSelected,
  };
};

import React, { FC, useCallback, useMemo, useState } from 'react';
import { Col, Empty, Row, Space, Typography } from 'antd';
import { useTranslate } from 'translations';
import { AttachmentMeta, LeaveComment } from 'types';
import { historicActivityInstanceItemSelector, useSelector } from 'store';
import { CommentsInput } from 'ui/components/TaskCommentsDrawer/CommentsInput';
import { colors, FullWidthSpin } from 'ui';
import {
  StyledAddCommentButton,
  StyledAddCommentInputContainer,
  StyledCommentsWrapper,
  StyledNoCommentsWrapper,
} from 'ui/components/TaskCommentsDrawer/TaskCommentsDrawer.styled';
import { TaskComment } from 'ui/components/TaskCommentsDrawer/Comment';

const { Text } = Typography;

interface LeaveCommentsProps {
  comments?: LeaveComment[];
  processInstanceId: string;
  fetchNewComments: () => void;
  isNewCommentsLoading?: boolean;
  isMyTeamLeaves?: boolean;
  hasBottomPadding?: boolean;
}

export const LeaveComments: FC<LeaveCommentsProps> = ({
  comments,
  processInstanceId,
  fetchNewComments,
  isNewCommentsLoading,
  isMyTeamLeaves,
  hasBottomPadding,
}) => {
  const { t } = useTranslate();

  const requestTasks = useSelector(historicActivityInstanceItemSelector(processInstanceId));
  const lastTask = useMemo(() => requestTasks?.data?.slice(-1)[0], [requestTasks?.data]);

  const [isAddComment, setIsAddComment] = useState<boolean>(false);

  const onAddCommentClicked = useCallback(() => {
    setIsAddComment(!isAddComment);
  }, [isAddComment]);

  const onAddCommentFinish = () => {
    fetchNewComments();
    setIsAddComment(false);
  };

  const onCancelComment = () => {
    setIsAddComment(false);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col>
          <Space direction="vertical" size={6}>
            <Text type="secondary" style={{ fontSize: 18 }}>
              <Space size={8}>{t('leaves.drawer.comments')}</Space>
            </Text>
          </Space>
        </Col>
      </Row>

      <StyledCommentsWrapper isCommentInputVisible={isAddComment}>
        {comments?.length ? (
          [...comments]?.reverse().map(({ id, time, message, userId, attachments }, index) => {
            const commentAttachments: AttachmentMeta[] = [];
            attachments?.forEach((attachment) => {
              commentAttachments.push({
                id: (attachment.id as unknown) as number,
                name: attachment.name,
                mediaType: attachment.type,
              });
            });
            return (
              <TaskComment
                id={id}
                message={message}
                userId={userId}
                time={time}
                key={id}
                attachments={commentAttachments}
                isLastComment={index === 0}
                isLastCommentLoading={false}
              />
            );
          })
        ) : (
          <StyledNoCommentsWrapper>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('task.noComments')}
              style={{
                fontFamily: 'inherit',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '44px',
                textAlign: 'center',
                color: colors.lightGrey,
              }}
            />
          </StyledNoCommentsWrapper>
        )}
      </StyledCommentsWrapper>

      {isNewCommentsLoading ? <FullWidthSpin /> : null}

      <StyledAddCommentInputContainer
        showShadow={isAddComment}
        bottom={hasBottomPadding ? '47px' : '0'}
        width="550px"
      >
        {lastTask?.taskId && isAddComment && (
          <CommentsInput
            taskId={lastTask.taskId}
            onFinish={onAddCommentFinish}
            onCloseComment={onCancelComment}
          />
        )}
        {lastTask?.taskId && !isAddComment && (
          <StyledAddCommentButton type={'primary'} onClick={onAddCommentClicked}>
            {t('task.addComment')}
          </StyledAddCommentButton>
        )}
      </StyledAddCommentInputContainer>
    </>
  );
};

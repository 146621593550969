export enum LeaveStatus {
  planned = 'PLANNED',
  submitted = 'SUBMITTED',
  awaitingApproval = 'AWAITING_APPROVAL',
  rejected = 'REJECTED',
  denied = 'DENIED',
  scheduled = 'SCHEDULED',
  inProgress = 'INPROGRESS',
  completed = 'COMPLETED',
  withdrawn = 'WITHDRAWN',
  awaitingWithdrawalApproval = 'AWAITING_WITHDRAWAL_APPROVAL',
  approved = 'APPROVED',
  canceled = 'CANCELED',
  cancelationInProgress = 'CANCELATION_IN_PROGRESS',
  rescheduleInProgress = 'RESCHEDULE_IN_PROGRESS',
}

import React, { FC, useCallback, useState } from 'react';
import { IELibrarySectorListResponse } from 'types';
import { StyledELibraryDepartmentsCollapse } from '../ELibrary.styled';
import { ELibraryDepartmentCollapsePanel } from './ELibraryDepartmentCollapsePanel';

type Props = {
  sectors: IELibrarySectorListResponse;
  onDepartmentSelect: (departmentName: string, sectorName: string) => void;
  selectedDepartmentName?: string;
  isFilterApplied: boolean;
};

export const ELibrarySectorCollapse: FC<Props> = ({
  sectors,
  onDepartmentSelect,
  selectedDepartmentName,
  isFilterApplied,
}) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([`sector-${sectors[0]}`]);
  const isPanelExpanded = useCallback(
    (sector: string) => {
      return activeKeys.some((value) => value === `sector-${sector}`);
    },
    [activeKeys]
  );
  return (
    <StyledELibraryDepartmentsCollapse
      className="e-library-department-collapse"
      bordered={false}
      defaultActiveKey={`sector-${sectors[0]}`}
      ghost
      onChange={(aKeys) => setActiveKeys(aKeys as string[])}
    >
      {sectors.map((sector, index) => (
        <ELibraryDepartmentCollapsePanel
          sector={sector}
          isFirstSector={index === 0}
          isExpanded={isPanelExpanded(sector)}
          onDepartmentSelect={onDepartmentSelect}
          selectedDepartmentName={selectedDepartmentName}
          key={`sector-${sector}`}
          header={<p>{sector}</p>}
          isFilterApplied={isFilterApplied}
        />
      ))}
    </StyledELibraryDepartmentsCollapse>
  );
};

import { useMutation } from 'react-query';
import { WFHCalculateDurationResponse } from 'types';
import { AxiosError } from 'axios';
import { workFromHomeRequest } from '../WorkFromHomeRequest';

interface ErrorResponse {
  // Define your error response structure here
  errors: Array<{ message: string }>;
}
export const useWFHDurationCalculation = (errorCallBack: (errorsMessage: string) => void) => {
  return useMutation<
    WFHCalculateDurationResponse,
    AxiosError<ErrorResponse>,
    {
      startDate: string;
      endDate: string;
    },
    unknown
  >(
    async ({ startDate, endDate }) => {
      const { data } = await workFromHomeRequest.getWorkFromHomeCalculateDuration({
        startDate,
        endDate,
      });
      return data;
    },
    {
      retry: false,
      onError: (error) => {
        if (error?.response?.status === 400) {
          const errorDetails = error?.response.data?.errors;
          if (errorDetails && errorDetails?.length > 0) {
            errorCallBack(errorDetails?.[0]?.message);
          }
        }
      },
    }
  );
};

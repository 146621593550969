import { AxiosPromise } from 'axios';
import { IEthicsCode } from 'types';
import { Core, ETHICS_CODE_CONTENT } from '../Core';

interface GetEthicsCodeContent {
  (language: 'AR' | 'EN'): AxiosPromise<IEthicsCode>;
}

interface SubmitEthicsCodeConfirmation {
  (confirm: boolean): AxiosPromise<unknown>;
}

export class EthicsCode extends Core {
  getEthicsCodeContent: GetEthicsCodeContent = async (language) =>
    this.get(ETHICS_CODE_CONTENT(), { params: { language } });
  submitEthicsCodeConfirmation: SubmitEthicsCodeConfirmation = async (confirm) =>
    this.post(ETHICS_CODE_CONTENT(), { confirm });
}

export const ethicsCode = new EthicsCode();

import { useMutation } from 'react-query';
import { processManagement } from '../ProcessManagement';

export function useRemoveProcessFromUserProcesses() {
  return useMutation<unknown, unknown, { selectedUserAdOid: string; processKey: number }, unknown>(
    async (body) => {
      await processManagement.removeProcessFromUserProcesses(body);
    }
  );
}

import { Col, notification, Row } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import {
  getUsersTableStart,
  usersTableData,
  useDispatch,
  useSelector,
  getCurrentUserHcmId,
} from 'store';
import { TType } from 'translations';
import { RequestStatus, Sort, SortFull, UsersTableData, UserTableUserInfo } from 'types';
import { UserPopover } from 'ui';
import { table } from 'ui/elements';
import { LIST_VIEW_PAGE_SIZE } from '../../../components';
import {
  StyledCard,
  StyledCol,
  StyledParagraph,
  StyledSpanName,
  StyledText,
  StyledUsersTable,
} from './UsersTable.styled';
import { UserTableStatus } from './UserTableStatus';

interface ColumnItem {
  title: string;
  dataIndex: string;
  key: string;
  width: string;
}

interface UsersTableProps {
  t: TType;
  filterValues: Record<string, string | string[]>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  onUserClick: (user: UserTableUserInfo) => void;
}

const { width } = table;

const UsersTableComponent: React.FC<UsersTableProps> = ({
  t,
  filterValues,
  setCurrentPage,
  currentPage,
  onUserClick,
}) => {
  const [tableData, setTableData] = useState<UsersTableData>();

  const users = useSelector(usersTableData);

  const hcmId = useSelector(getCurrentUserHcmId);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const getUsers = useCallback(
    (sortValue?: string, sortOrder?: string) => {
      try {
        if (hcmId && hcmId != null) {
          setIsLoading(true);
          dispatch(
            getUsersTableStart({
              hcmId,
              params: {
                offset: currentPage * LIST_VIEW_PAGE_SIZE,
                limit: LIST_VIEW_PAGE_SIZE,
                ...(sortValue && sortOrder && { sort: sortValue, sortDirection: sortOrder }),
              },
            })
          );
        }
      } catch (e) {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: t('messages.error.tryAgainLater'),
        });
      }
    },
    [currentPage, dispatch, hcmId, t]
  );

  useEffect(() => {
    getUsers();
  }, [currentPage, getUsers, filterValues]);

  useEffect(() => {
    if (users.status === RequestStatus.resolved) {
      setIsLoading(false);
      setTableData(users?.data);
    } else if (users.status === RequestStatus.rejected) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
      setIsLoading(false);
    }
  }, [users.status, users?.data, t]);

  const tableContent = useMemo(() => {
    if (tableData?.content && tableData?.content.length > 0) {
      return tableData?.content.map((item, index) => ({
        key: index,
        id: item.id,
        prettyId: (
          <Row align={'middle'}>
            <StyledCol>
              <UserPopover currentID={item?.adOid}>
                <MSTeamsUserAvatar
                  id={item?.adOid}
                  name={`${item?.firstName} ${item?.lastName}`}
                  size={32}
                  statusSize={10}
                />
              </UserPopover>
            </StyledCol>
            <Col>
              <Row align={'middle'}>
                <Col>
                  <StyledSpanName>
                    {item?.firstName} {item?.lastName}
                  </StyledSpanName>
                </Col>
              </Row>
              <Row align={'middle'}>
                <Col>
                  <StyledText>{item?.email}</StyledText>
                </Col>
              </Row>
            </Col>
          </Row>
        ),
        mobileNumber: <StyledText>{item?.mobileNumber}</StyledText>,
        jobTitle: <StyledText>{item?.jobTitle}</StyledText>,
        department: <StyledParagraph ellipsis={{ rows: 2 }}>{item?.department}</StyledParagraph>,
        managerName: <StyledParagraph ellipsis={{ rows: 2 }}>{item?.managerName}</StyledParagraph>,
        managerEmail: <StyledText>{item?.managerEmail}</StyledText>,
        location: <StyledText>{item?.location}</StyledText>,
        status: <UserTableStatus key={item?.id} currentEmail={item?.email} />,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData?.content]);

  const getColumns = (t: TType) => {
    const columns: ColumnsType<ColumnType<ColumnItem>> = [
      {
        title: t('processManagement.users.table.user'),
        dataIndex: 'prettyId',
        key: 'prettyId',
        align: 'left',
        width: width.twentyfive,
        sorter: false,
      },
      {
        title: t('processManagement.users.table.phoneNumber'),
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
        align: 'left',
        width: width.fifteen,
        sorter: false,
      },
      {
        title: t('processManagement.users.table.jobTitle'),
        dataIndex: 'jobTitle',
        key: 'jobTitle',
        align: 'left',
        width: width.ten,
        sorter: false,
      },
      {
        title: t('processManagement.users.table.organizationUnit'),
        dataIndex: 'department',
        key: 'department',
        align: 'left',
        width: width.fifteen,
        sorter: false,
      },
      {
        title: t('processManagement.users.table.lineManager'),
        dataIndex: 'managerName',
        key: 'managerName',
        align: 'left',
        width: width.fifteen,
        sorter: false,
      },
      {
        title: t('processManagement.users.table.status'),
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: width.five,
        sorter: false,
      },
    ];

    return columns;
  };

  const onTableChange = useCallback((pagination, sorter) => {
    const { current } = pagination;
    setCurrentPage(current ? current - 1 : 0);
    const { field, order } = sorter;
    if (field) {
      const sortOrder =
        order === SortFull.ascend ? Sort.asc : order === SortFull.descend ? Sort.desc : '';
      getUsers(field, sortOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(() => getColumns(t), [t]);
  return (
    <React.Fragment>
      <StyledCard>
        <StyledUsersTable
          scroll={{
            scrollToFirstRowOnChange: true,
            x: true,
          }}
          pagination={{
            total: tableData?.numberOfElements,
            showSizeChanger: false,
            defaultCurrent: currentPage + 1,
            pageSize: LIST_VIEW_PAGE_SIZE,
            current: currentPage + 1,
          }}
          showSorterTooltip={false}
          dataSource={tableContent}
          columns={columns}
          loading={isLoading}
          onChange={onTableChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                if (rowIndex !== undefined && tableData) {
                  //eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onUserClick(tableData.content[(record as any).key]);
                }
              },
            };
          }}
        />
      </StyledCard>
    </React.Fragment>
  );
};
export const UsersTable = React.memo(UsersTableComponent);

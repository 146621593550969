import React, { FC } from 'react';
import Text from 'antd/lib/typography/Text';
import { Spin } from 'antd';
import { useTranslate } from 'translations';
import { DaysBlockStyled } from './BalanceWidget.styled';

interface DaysBlockProps {
  balanceTitle: string;
  balance?: string;
  isLoading: boolean;
}

const NO_LIMIT = 'No Limit';

const pluralize = (count: string, noun: string, suffix: string) =>
  `${noun}${count !== '1' ? suffix : ''}`;

export const DaysSection: FC<DaysBlockProps> = ({ isLoading, balanceTitle, balance = '' }) => {
  const { t } = useTranslate();

  return (
    <DaysBlockStyled>
      <div>
        <Text type="secondary" style={{ fontSize: 16 }}>
          {balanceTitle}
        </Text>
      </div>
      {isLoading ? (
        <Text style={{ fontSize: 40 }} strong>
          <Spin spinning delay={100} />
        </Text>
      ) : (
        <>
          <Text style={{ fontSize: 40 }} strong>
            {balance === NO_LIMIT ? '' : balance}
          </Text>
          <Text type="secondary" style={{ fontSize: balance === NO_LIMIT ? 16 : 14 }}>
            {' '}
            {balance === NO_LIMIT
              ? t('leaves.widget.noLimits')
              : pluralize(balance, t('leaves.widget.day'), t('leaves.widget.daySuffix'))}
          </Text>
        </>
      )}
    </DaysBlockStyled>
  );
};

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../reducers';

export const chats = (state: RootState): RootState['chats'] => state.chats;

export const chatsData = createSelector(
  chats,
  ({
    error,
    data,
    getChatsStatus,
    getChatMessagesStatus,
    sendChatMessageStatus,
    createGroupChatStatus,
    addChatMemberStatus,
  }) => ({
    error,
    data: {
      ...data,
      chatMessages: data.chatMessages?.list,
      skipToken: data.chatMessages?.skipToken,
    },
    getChatsStatus,
    getChatMessagesStatus,
    sendChatMessageStatus,
    createGroupChatStatus,
    addChatMemberStatus,
  })
);

import styled from 'styled-components';
import { FileOutlined } from '@ant-design/icons';
import { colors } from 'ui/elements';
import { Card, Typography } from 'antd';

const { Text } = Typography;
const { textColorSecondary } = colors;

interface AdditionalProps {
  secondary: 'yes' | 'no'; // for some reason
}

export const ChatCard = styled(Card).attrs({ bordered: false })`
  display: inline-flex;
  border-radius: 4px;
  background-color: ${(props: AdditionalProps) => props.secondary === 'yes' && colors.chatMessage};

  .ant-card-body {
    padding: 16px 16px 12px;
  }
`;

export const TextSecondary = styled(Text)`
  color: ${(props: AdditionalProps) => props.secondary === 'yes' && textColorSecondary};
`;

export const TextTimeStamp = styled(Text)`
  font-size: 12px;
`;

export const AttachmentCard = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  padding: 8px 16px;
  background-color: ${colors.lightGreyThird};
  border: 1px solid ${colors.borderGreySecond};
  border-radius: 2px;
`;

export const AttachmentsWrapper = styled.div`
  margin-top: 8px;
`;

export const StyledFileIcon = styled(FileOutlined)`
  font-size: 26px;
  margin-right: 14px;
`;

export const StyledItalicText = styled.span`
  font-style: italic;
`;

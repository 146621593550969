import { Badge, Button, Card, DatePicker, Divider, Typography } from 'antd';
import styled from 'styled-components';
import { EditFilled } from '@ant-design/icons';

import { colors, borderRadius, space, typography } from 'ui/elements';

const { Text } = Typography;

export const StyledEditIcon = styled(EditFilled)`
  margin-left: 10px;
`;

export const StyledAnchor = styled.a`
  font-size: 16px;

  .anticon {
    margin-left: 6px;
  }
`;

export const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 24px;
  }
`;

export const TitleName = styled.div`
  font-size: ${typography.size.hecto};
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const StyledText = styled(Text)`
  font-size: ${typography.size.uno};
`;

export const StyledAttendanceCard = styled(Card)`
  margin-left: 20px;
`;

export const StyledAttendanceTimeCard = styled(Card)`
  margin-left: 20px;
  height: 225px;
`;

export const StyledExportCard = styled.span`
  font-size: ${typography.size.uno};
  font-weight: normal;
  text-decoration: underline;
  padding-top: ${space.micro};
`;

export const StyledCardStatus = styled.span`
  font-size: 11px;
  background-color: ${colors.backgroundGrey};
  color: ${colors.skySecondary};
  border-radius: ${borderRadius.xxLarge};
  float: right;
  text-decoration: underline;
  padding: 4px 12px 4px 12px;
  text-decoration: none;
`;

export const StyledCardWFHStatus = styled.span`
  font-size: 11px;
  background-color: ${colors.lightGreenBackground};
  color: ${colors.nature};
  border-radius: ${borderRadius.xxLarge};
  float: right;
  text-decoration: underline;
  padding: 4px 12px 4px 12px;
  text-decoration: none;
`;

export const StyledCardStatusAbsent = styled.span`
  font-size: 11px;
  background-color: ${colors.lightpink};
  color: ${colors.red};
  border-radius: ${borderRadius.xxLarge};
  float: right;
  text-decoration: underline;
  padding: 4px 12px 4px 12px;
  text-decoration: none;
`;

export const StyledCalendarOutlined = styled.span`
  margin-left: -7px;
`;

export const StyledDownOutlined = styled.span`
  margin-left: -27px;
`;

export const StyledExportOuter = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: end;
`;

export const StyledCalender = styled.div`
  padding: ${space.slim};
`;

export const StyledPicker = styled.div`
  border: none;
`;

export const StyledDurationFont = styled.p`
  font-size: 12px;
  color: ${colors.labelGrey};
`;

export const StyledCheck = styled.div`
  padding: ${space.slim};
  background-color: ${colors.backgroundGrey};
  border-radius: ${borderRadius.xxLarge};
  margin-bottom: 10px;
`;

export const StyledCheckText = styled.div`
  text-align: center;
  color: ${colors.labelGrey};
`;

export const StyledCheckIn = styled.div`
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  color: ${(props: { lateCheckIn: string | null }) =>
    props.lateCheckIn ? colors.attendanceWarning : null};
`;

export const StyledCheckOut = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
  color: ${(props: { earlyCheckOut: string | null }) =>
    props.earlyCheckOut ? colors.attendanceWarning : null};
`;

export const StyledInOffice = styled.div`
  text-align: center;
  font-weight: bold;
  color: ${colors.nature};
`;

export const StyledDatePicker = styled(DatePicker)`
  border: none;
`;

export const CardBox = styled.div`
  .card-width {
    max-width: 100%;
  }
  .picker-ui {
    border: none;
  }
  .pd-icon {
    padding: 4px 10px;
  }
`;

export const StyledEditButton = styled.div`
  cursor: pointer;
`;

export const UserName = styled.div`
  color: ${colors.primaryColor};
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
`;

export const UserLastActivity = styled.div`
  color: ${colors.lightGrey};
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  margin-top: 6px;
`;

export const StyledButton = styled(Button)`
  padding: 5px;
`;
export const StyedDivider = styled(Divider)`
  margin-top: 0px;
`;
export const StyledBadge = styled(Badge)`
  sup {
    width: ${space.cozy};
    height: ${space.cozy};
  }
`;
export const StyledSuggestionIcon = styled.span`
  padding-right: ${space.mini};
  margin-left: -${space.slim};
`;

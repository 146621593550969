import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../reducers';

export const leavesTypes = (state: RootState): RootState['leavesTypes'] => state.leavesTypes;

export const leavesTypesData = createSelector(leavesTypes, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

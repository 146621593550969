import { connect, ConnectedProps } from 'react-redux';

import { RootState, getGeneralInfoStart, generalInfoData } from 'store';

const mapDispatch = {
  getGeneralInfoStart,
};

const mapState = (state: RootState) => ({
  generalInfoData: generalInfoData(state),
});

export const generalInformationConnector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof generalInformationConnector>;

export type ConnectorProps = PropsFromRedux;

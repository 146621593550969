import { TeamsChannelFilesListData } from 'types';
import { Core, TEAMS_CHANNEL_FILES } from 'api';

interface GetTeamChannelFilesList {
  (teamId: string, channelId: string): Promise<{ data: TeamsChannelFilesListData }>;
}

class TeamChannelFilesList extends Core {
  getTeamChannelFilesList: GetTeamChannelFilesList = async (teamId, channelId) =>
    this.get(TEAMS_CHANNEL_FILES(teamId, channelId));
}

export const teamsChannelFileList = new TeamChannelFilesList();

import React, { useMemo } from 'react';
import { TType } from 'translations';
import moment from 'moment';
import { IProfessionalCertificate } from 'types/NewProfile';
import { PrivateProfileProfessionalCertificateItem } from './PrivateProfileProfessionalCertificateItem';

export interface PrivateProfileProfessionalCertificateProps {
  t: TType;
  professionalCertificates: IProfessionalCertificate[];
}

export const PrivateProfileProfessionalCertificate: React.FC<PrivateProfileProfessionalCertificateProps> = ({
  t,
  professionalCertificates,
}: PrivateProfileProfessionalCertificateProps) => {
  const dataProfessionalCertificates: IProfessionalCertificate[] = useMemo(() => {
    return professionalCertificates;
  }, [professionalCertificates]);
  return (
    <>
      {dataProfessionalCertificates.map((item, index) => (
        <PrivateProfileProfessionalCertificateItem
          t={t}
          key={index}
          certificateName={item.name}
          issuingOrganisation={item.issuingOrganization}
          issueDate={moment(item.issueDate).format('DD.MM.YYYY')}
          expirationDate={moment(item.expirationDate).format('DD.MM.YYYY')}
          professionalCertificateAttachmentList={item.professionalCertificateAttachmentList}
        />
      ))}
    </>
  );
};

import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { leaves } from '../Leaves';

export function useTeamMembers(hcmPersonId: string) {
  return useQuery(
    [QUERIES.myTeamMembersList, hcmPersonId],
    async () => {
      const { data } = await leaves.getMyTeamMembersList(hcmPersonId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );
}

import styled from 'styled-components';
import { Col, Row } from 'antd';
import { CloseButton, Left, Right } from 'assets';
import { colors, space } from 'ui';

export type StyledFooterImageProps = {
  isSelected: boolean;
};

export type StyledImageViewerFooterWrapperProps = {
  isImageExceedLimit: boolean;
};

export const StyledImageViewerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${space.normal};
`;

export const StyledImageViewerHeader = styled(Row)`
  justify-content: flex-end;
  display: flex;
  width: 100%;
`;

export const StyledImageViewerContextContainer = styled(Row)`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const StyledImageViewerControlBtnContainer = styled(Col)`
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  width: 5%;
  padding-bottom: 10%;
`;

export const StyledImageViewerFullImageContainer = styled.div`
  width: 90%;
  height: 100%;
  flex-direction: column;
`;

export const StyledCloseButton = styled(CloseButton)``;

export const StyledFullImageWrapper = styled(Col)`
  width: 100%;
  height: 80%;
  padding: ${space.normal};
`;

export const StyledFullImage = styled.img`
  object-fit: cover;
  border-radius: 8px;
  width: 100%;
  height: 100%;
`;

export const StyledLeftButton = styled(Left)``;

export const StyledRightButton = styled(Right)``;

export const StyledImageViewerFooter = styled.div`
  width: 100%;
  padding-left: ${space.normal};
  padding-right: ${space.normal};
  height: 20%;
  display: flex;
  overflow: auto;
`;

export const StyledImageViewerFooterWrapper = styled(Row)<StyledImageViewerFooterWrapperProps>`
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: ${(props) => (props.isImageExceedLimit ? 'flow-root' : 'flex')};
  padding-left: 2px;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  flex-wrap: nowrap;
`;

export const StyledFooterImageWrapper = styled(Row)`
  white-space: nowrap;
  width: 100%;
`;

export const StyledFooterImage = styled.img<StyledFooterImageProps>`
  border-radius: 8px;
  width: 126px;
  height: 126px;
  object-fit: cover;
  margin-right: ${space.normal};
  border: ${(props) => (props.isSelected ? `1px solid ${colors.accentOrange}` : 'none')};
`;

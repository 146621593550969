import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetOneDriveItemChildrenFail,
  GetOneDriveItemChildrenStart,
  GetOneDriveItemChildrenSuccess,
  getOneDriveItemChildrenFail,
  getOneDriveItemChildrenStart,
  getOneDriveItemChildrenSuccess,
} from './oneDriveItemChildrenSlice';

export const OneDriveItemChildrenEpic: Epic<
  GetOneDriveItemChildrenStart,
  GetOneDriveItemChildrenSuccess | GetOneDriveItemChildrenFail,
  RootState,
  typeof API
> = (action$, _, { oneDriveItemChildren }) =>
  action$.pipe(
    filter(getOneDriveItemChildrenStart.match),
    mergeMap(({ payload }) =>
      from(oneDriveItemChildren.getOneDriveItemChildren(payload)).pipe(
        map(({ data }) =>
          getOneDriveItemChildrenSuccess({
            folderId: payload,
            data,
          })
        ),
        catchError((error) =>
          of(
            getOneDriveItemChildrenFail({
              folderId: payload,
              error: error?.response?.message,
            })
          )
        )
      )
    )
  );

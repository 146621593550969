import React, { FC } from 'react';
import { TaskDefinitionType, TaskStatus } from 'types';
import { TaskSubmitFormProps } from './TaskSubmitForm.types';
import { TaskSubmitForm } from './TaskSubmitForm';
import { SiteAcquisitionTaskSubmitForm } from './SiteAcquisitionTaskSubmitForm';
import { NonPoBudgetTeamSubmitForm } from './NonPoBudgetTeamSubmitForm';

const ENABLED_LEASE = process.env.REACT_APP_DISABLE_LEASE !== 'true';

export const TaskSubmitFormSelector: FC<TaskSubmitFormProps> = (props) => {
  if (
    ENABLED_LEASE &&
    props.activityId === TaskDefinitionType.SITE_ACQUISITION_REQUEST_FORM_TASK &&
    props.taskInfo.taskDefinitionKey === TaskDefinitionType.FIELD_RUNNER_TASK &&
    (props.taskInfo.status === TaskStatus.pending ||
      props.taskInfo.status === TaskStatus.inProgress)
  ) {
    return <SiteAcquisitionTaskSubmitForm {...props} />;
  }

  if (props.taskInfo.taskDefinitionKey === TaskDefinitionType.NON_PO_BUDGET_TEAM_FORM_TASK) {
    return <NonPoBudgetTeamSubmitForm {...props} />;
  }

  return <TaskSubmitForm {...props} />;
};

import { Button, Card, Table } from 'antd';
import styled from 'styled-components';

import { FileOutlined, FolderOutlined } from '@ant-design/icons';

import { colors } from 'ui/elements';

export const StyledTable = styled(Table).attrs({ size: 'middle' })`
  background-color: ${colors.white};

  .ant-tabs-nav-wrap {
    padding: 24px;
    background-color: ${colors.white};
  }
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: ${colors.labelGrey};

    .ant-tabs-tab-active {
      font-weight: 500;
    }
  }
  .ant-table-thead > tr > th {
    background-color: ${colors.white};
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }

  td span {
    word-break: break-all;
    white-space: pre-wrap;
    text-align: left;
  }
`;

export const StyledPageWrapper = styled.div`
  margin: 24px;
`;

export const StyledFiles = styled(Card)`
  margin: 24px 0;
  padding: 0;
`;

export const StyledLoading = styled(Card)`
  width: 100%;
`;
export const StyledButton = styled(Button)`
  padding: 0;
`;

export const StyledFile = styled(FileOutlined)`
  font-size: 16px;
`;

export const StyledFolder = styled(FolderOutlined)`
  font-size: 16px;
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, TeamsChannelFilesListData, RequestStatus } from 'types';
import { TeamsFilesState } from './teamsChannelFilesListTypes';

export const emptyTeamsChannelFilesState: TeamsFilesState = {};
export type GetTeamsChannelFilesStart = PayloadAction<{ teamId: string; channelId: string }>;
export type GetTeamsChannelFilesSuccess = PayloadAction<{
  teamId: string;
  channelId: string;
  data: TeamsChannelFilesListData;
}>;
export type GetTeamsChannelFilesFail = PayloadAction<{
  teamId: string;
  channelId: string;
  error: RequestCommonError;
}>;

const teamsFiles = createSlice({
  name: 'teamsChannelFiles',
  initialState: emptyTeamsChannelFilesState,
  reducers: {
    getTeamsChannelFilesStart(state, action: GetTeamsChannelFilesStart) {
      if (state[action.payload.teamId]) {
        if (state[action.payload.teamId][action.payload.channelId]) {
          state[action.payload.teamId][action.payload.channelId].status = RequestStatus.pending;

          return;
        }

        state[action.payload.teamId] = {
          ...state[action.payload.teamId],
          [action.payload.channelId]: {
            error: null,
            status: RequestStatus.pending,
          },
        };

        return;
      }

      state[action.payload.teamId] = {
        [action.payload.channelId]: {
          error: null,
          status: RequestStatus.pending,
        },
      };
    },
    getTeamsChannelFilesSuccess(state, action: GetTeamsChannelFilesSuccess) {
      state[action.payload.teamId][action.payload.channelId].status = RequestStatus.resolved;
      state[action.payload.teamId][action.payload.channelId].data = action.payload.data;
    },
    getTeamsChannelFilesFail(state, action: GetTeamsChannelFilesFail) {
      state[action.payload.teamId][action.payload.channelId].status = RequestStatus.rejected;
      state[action.payload.teamId][action.payload.channelId].error = action.payload.error;
    },
  },
});

export const {
  getTeamsChannelFilesStart,
  getTeamsChannelFilesSuccess,
  getTeamsChannelFilesFail,
} = teamsFiles.actions;

export default teamsFiles.reducer;

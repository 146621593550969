import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  GetUserProfileFail,
  GetUserProfileSuccess,
  GetUserProfileStart,
  getUserProfileStart,
  getUserProfileSuccess,
  getUserProfileFail,
} from './userProfileSlice';

export const getUserProfileEpic: Epic<
  GetUserProfileStart,
  GetUserProfileSuccess | GetUserProfileFail,
  RootState,
  typeof API
> = (action$, _, { userProfile }) =>
  action$.pipe(
    filter(getUserProfileStart.match),
    exhaustMap(({ payload }) =>
      from(userProfile.getUserProfile(payload.email)).pipe(
        map(({ data }) => getUserProfileSuccess(data)),
        catchError((error) => of(getUserProfileFail(error?.response?.data)))
      )
    )
  );

import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG } from '../../constants';
import { hierarchy } from '../Hierarchy';

export const useGetUserHierarchy = (userId: string) => {
  return useQuery({
    queryKey: ['user_hierarchy', userId],
    queryFn: async () => {
      const { data } = await hierarchy.getHierarchy(userId);
      return data;
    },
    ...PERSIST_QUERY_CONFIG,
  });
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { NotificationListData, RequestCommonError, RequestStatus } from 'types';
import { NotificationListState } from './notificationListType';

export const notificationsListsInitialState: NotificationListState = {
  status: RequestStatus.idle,
  error: null,
  data: undefined,
};

export type GetNotificationsListStart = PayloadAction<{ params?: AxiosRequestConfig['params'] }>;
export type GetNotificationsListSuccess = PayloadAction<NotificationListData>;
export type GetNotificationsListFail = PayloadAction<RequestCommonError>;
export type GetNotificationsListRest = PayloadAction;

const notificationsListSlice = createSlice({
  name: 'notificationList',
  initialState: notificationsListsInitialState,
  reducers: {
    getNotificationListStart(state, _: GetNotificationsListStart) {
      state.status = RequestStatus.pending;
    },
    getNotificationListSuccess(state, action: GetNotificationsListSuccess) {
      state.status = RequestStatus.resolved;
      if (!state.data?.list.length) {
        state.data = action.payload;
        return;
      }
      state.data.list = [...state.data.list, ...action.payload.list];
      state.data.limit = action.payload.limit;
      state.data.offset = action.payload.offset;
      state.data.totalResult = action.payload.totalResult;
    },
    getNotificationListFil(state, action: GetNotificationsListFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getNotificationListRest(state, _: GetNotificationsListRest) {
      state.status = RequestStatus.idle;
      state.error = null;
      state.data = undefined;
    },
  },
});

export const {
  getNotificationListFil,
  getNotificationListRest,
  getNotificationListStart,
  getNotificationListSuccess,
} = notificationsListSlice.actions;

export default notificationsListSlice.reducer;

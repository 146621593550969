import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { Col, notification, Row } from 'antd';
import { FullWidthSpin, StatusTag, TagColor } from 'ui/elements';
import { Comments } from 'ui/hooks';
import { AttachmentMeta, RequestStatus, TaskStatus } from 'types';
import {
  camundaUsersDataSelector,
  getCamundaUserStart,
  taskCommentsDataSelector,
  useDispatch,
  useSelector,
} from 'store';
import { taskComments as taskCommentsApi } from 'api';
import { useTranslate } from 'translations';
import { useTasksContext } from 'routes';
import { getStatusColor } from '../TaskList/TaskSteps/helper';
import { StyledCommentsWrapper, StyledDivider, StyledH3Title } from './TaskCommentsDrawer.styled';
import { TaskComment } from './Comment';
import { TaskActivity } from './TaskActivity';

export interface TaskCommentsDrawerProps {
  taskId: string;
  taskTitle: string;
  taskStatus: TaskStatus;
  index: number;
  taskComments: Comments[];
  isComponentLoading: boolean;
}

interface CommentAttachments {
  [key: string]: AttachmentMeta[];
}

export const TaskCommentsComponent: FC<TaskCommentsDrawerProps> = ({
  taskId,
  taskTitle,
  taskComments,
  index,
  taskStatus,
  isComponentLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const [attachments, setAttachments] = useState<CommentAttachments>({});

  const { taskAttachmentStatus } = useSelector(taskCommentsDataSelector);

  const { rootPath } = useTasksContext();

  const camundaUsers = useSelector(camundaUsersDataSelector);

  const [isAttachmentsLoading, setIsAttachmentsLoading] = useState(true);

  const usersIds = useMemo(
    () =>
      taskComments
        .map(({ userId }) => userId)
        .filter((id, index, array) => array.indexOf(id) === index)
        .filter((id) => !camundaUsers[id]?.data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taskComments]
  );

  useEffect(() => {
    usersIds?.length &&
      dispatch(
        getCamundaUserStart({
          params: {
            idIn: usersIds.join(','),
          },
        })
      );
  }, [dispatch, usersIds]);

  useEffect(() => {
    taskAttachmentStatus !== RequestStatus.pending &&
      taskComments?.length &&
      (async () => {
        setIsAttachmentsLoading(true);
        const notJiraComments = taskComments.filter((comment) => !comment.isJira);
        try {
          const attachmentsPromises = notJiraComments.map(
            async (comment) =>
              await taskCommentsApi.getTaskCommentAttachment(comment.commentId, rootPath)
          );

          const attachmentsResp = await Promise.all(attachmentsPromises);

          const attachmentsByTasks = taskComments?.reduce((acc, item, index) => {
            acc[item.commentId] = attachmentsResp[index]?.data;
            return acc;
          }, {} as CommentAttachments);

          setAttachments(attachmentsByTasks);
        } catch (e) {
          notification.error({
            message: t('messages.error.wentWrong'),
            description: t('messages.error.tryAgainLater'),
          });
        } finally {
          setIsAttachmentsLoading(false);
        }
      })();
  }, [rootPath, t, taskAttachmentStatus, taskComments, taskId]);

  const isUsersLoading = useMemo(
    () => usersIds.some((user) => camundaUsers[user]?.status === RequestStatus.pending),
    [camundaUsers, usersIds]
  );

  return (
    <>
      {isComponentLoading || isUsersLoading ? (
        <FullWidthSpin />
      ) : taskComments.length ? (
        <StyledCommentsWrapper>
          {index ? <StyledDivider /> : null}
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row align="top">
                <Col span={taskStatus === TaskStatus.pending ? 19 : 18}>
                  <StyledH3Title status={taskStatus} taskStatus={TaskStatus}>
                    {taskTitle}
                  </StyledH3Title>
                </Col>
                <Col span={4}>
                  <StatusTag color={getStatusColor(taskStatus) as TagColor}>{taskStatus}</StatusTag>
                </Col>
              </Row>
            </Col>
          </Row>
          {taskComments.map(
            ({ message, userId, commentDate, commentId, isJira, isActivity }, index) =>
              isJira && isActivity ? (
                <TaskActivity
                  id={commentId}
                  message={message}
                  time={commentDate}
                  isLastCommentLoading={taskComments.length - 1 === index && isAttachmentsLoading}
                />
              ) : (
                <TaskComment
                  id={commentId}
                  message={message}
                  userId={userId}
                  time={commentDate}
                  key={commentId}
                  attachments={attachments[commentId]}
                  isLastComment={index === 0}
                  isLastCommentLoading={index === 0 && isAttachmentsLoading}
                />
              )
          )}
        </StyledCommentsWrapper>
      ) : null}
    </>
  );
};

export const TaskComments = memo(TaskCommentsComponent);

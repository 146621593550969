import { Col, Row } from 'antd';
import React, { FC, memo } from 'react';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { MemberOfGroupInfo } from 'types';
import { UserPopover } from 'ui';

import {
  StyledMemberListLabel,
  StyledMemberText,
  StyledWrapperMemberList,
} from './ProcessGroupDrawer.styled';
import { RemoveUserFromGroup } from './ProcessGroupDrawerRemoveUser';

interface Props {
  memberList: MemberOfGroupInfo[];
  groupId: string;
  refetchList: () => void;
}

export const DrawerMemberListComponent: FC<Props> = ({ memberList, groupId, refetchList }) => {
  return (
    <div>
      {memberList.length > 0 && (
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <StyledMemberListLabel>{`Members(${memberList.length})`}</StyledMemberListLabel>
          </Col>
          <StyledWrapperMemberList>
            {memberList.map((member) => (
              <Col span={24} key={member.adoid}>
                <Row justify={'space-between'} align={'middle'} gutter={[12, 10]}>
                  <Col>
                    <Row align={'middle'}>
                      <Col>
                        <UserPopover currentID={member.adoid}>
                          <MSTeamsUserAvatar id={member.adoid} size={40} />
                        </UserPopover>
                      </Col>
                      <Col>
                        <StyledMemberText>{member.name}</StyledMemberText>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <RemoveUserFromGroup
                      groupId={groupId}
                      userId={member.adoid}
                      refetchList={refetchList}
                    />
                  </Col>
                </Row>
              </Col>
            ))}
          </StyledWrapperMemberList>
        </Row>
      )}
    </div>
  );
};

export const DrawerMemberList = memo(DrawerMemberListComponent);

import styled from 'styled-components';
import { Card } from 'antd';

export const StyledContainer = styled.div`
  margin: 24px 24px 0;
  height: 100%;
`;

export const StyledCard = styled(Card)`
  height: 100%;
  min-height: calc(100vh - 96px);
  .ant-card-body {
    padding: ${(props: { $isMaxWidth?: boolean }) => (props.$isMaxWidth ? '0' : '24px')};
  }
`;

import React, { FC } from 'react';
import { OneDriveContextProvider } from './OneDriveContextProvider';
import { OneDriveLayout } from './OneDriveLayout';

export const OneDrive: FC = () => {
  return (
    <OneDriveContextProvider>
      <OneDriveLayout />
    </OneDriveContextProvider>
  );
};

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetProcessDefinitionKeysFail,
  GetProcessDefinitionKeysStart,
  GetProcessDefinitionKeysSuccess,
  getProcessDefinitionKeysStart,
  getProcessDefinitionKeysSuccess,
  getProcessDefinitionKeysFail,
} from './processDefinitionKeysSlice';

export const processDefinitionKeysEpic: Epic<
  GetProcessDefinitionKeysStart,
  GetProcessDefinitionKeysSuccess | GetProcessDefinitionKeysFail,
  RootState,
  typeof API
> = (action$, _, { processDefinitionKeys }) =>
  action$.pipe(
    filter(getProcessDefinitionKeysStart.match),
    exhaustMap(() =>
      from(processDefinitionKeys.getProcessDefinitionKeys()).pipe(
        map(({ data }) => getProcessDefinitionKeysSuccess(data)),
        catchError((error) => of(getProcessDefinitionKeysFail(error?.response?.data)))
      )
    )
  );

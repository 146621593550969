import React, { FC, useState } from 'react';

import { useTranslate } from 'translations';
import { ProfileData } from 'types';
import { ReadView } from './ReadView';
import { EditView } from './EditView';

export interface PublicProfileProps {
  profileData: ProfileData;
}

export const Public: FC<PublicProfileProps> = ({ profileData }) => {
  const { t } = useTranslate();
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? (
    <EditView setIsEditing={setIsEditing} t={t} />
  ) : (
    <ReadView setIsEditing={setIsEditing} data={profileData} t={t} />
  );
};

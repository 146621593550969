import { LibraryFile } from 'types';
import { Core, FILE_IN_GROUP_FOLDER } from 'api';

interface GetOneDriveSharedLibraryList {
  (groupId: string, LibraryId: string): Promise<{ data: LibraryFile[] }>;
}

class OneDriveSharedLibraryList extends Core {
  getOneDriveSharedLibraryList: GetOneDriveSharedLibraryList = async (groupId, LibraryId) =>
    this.get(FILE_IN_GROUP_FOLDER(groupId, LibraryId));
}

export const oneDriveSharedLibraryList = new OneDriveSharedLibraryList();

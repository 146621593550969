import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';
import { colors, space, typography } from 'ui/elements';

const { Text } = Typography;

export const StyledAppWidgetRow = styled(Row)`
  padding: ${space.moderate} ${space.zero};
`;
export const StyledAppsRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;
export const StyledAppCol = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  flex: 1;
  height: 90px;
  background-color: ${(props: { bgColor?: string; color?: string; focusBgColor?: string }) =>
    props.bgColor ?? colors?.greyBackLabel};
  color: ${(props: { color?: string }) => props.color ?? colors?.textColorSecondary};
  &:hover,
  &:focus {
    background-color: ${(props: { focusBgColor?: string }) =>
      props.focusBgColor ?? colors?.greyBackLabelHover};
  }
  .ant-typography {
    text-align: center;
  }
`;
export const StyledAppRedirectLink = styled.a`
  display: flex;
  flex: 1;
  margin-right: ${space.cozy};
`;

export const AppTitleStyled = styled(Text).attrs({})`
  font-size: ${(props: { size?: number; color?: string }) =>
    props.size ? props.size + 'px' : 'inherit'};
  &.ant-typography {
    display: inline;
  }
  color: ${(props: { color?: string }) => props.color ?? colors?.textColorSecondary};
`;

export const AppViewAllStyled = styled.span`
  font-size: ${typography.size.hecto};
  &.ant-typography {
    display: inline;
  }
  color: ${colors.textColorSecondary};
  text-decoration-skip-ink: auto;
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

import { useDrawerMyLeaveDetail, useMyLeaveTeamDrawer } from 'api';
import React, { useCallback, useEffect, useState } from 'react';
import { TType } from 'translations';
import { LeaveInfo } from 'types';
import { useErrorNotification } from 'ui';
import { leavesListDataSelector, useSelector } from 'store';
import { LeavesDrawerDetails } from './LeaveDrawerDetail';

export interface LeaveDrawerProps {
  myTeamLeave: boolean;
  setCurrentLeaveId: (leaveId: string) => void;
  adoId: string;
  t: TType;
  currentLeaveId: string;
  setListViewType: () => void;
  dispatchGetLeaves: () => void;
}

const LeaveDrawerComponent: React.FC<LeaveDrawerProps> = ({
  myTeamLeave,
  currentLeaveId,
  t,
  adoId,
  ...rest
}) => {
  const [leaveInfo, setLeaveInfo] = useState<LeaveInfo>({} as LeaveInfo);
  const [isAddNewComment, setIsAddNewComment] = useState<boolean>(false);
  const { data: leavesListData } = useSelector(leavesListDataSelector);

  const processInstanceId = leavesListData.list.find(({ id }) => id === leaveInfo.id)
    ?.processInstanceId;

  const {
    data: myLeaveInfo,
    refetch: fetchMyTeamLeave,
    isLoading: isMyLeaveDrawerLoading,
    isError: isMyLeaveDrawerError,
  } = useMyLeaveTeamDrawer(currentLeaveId, adoId);
  const {
    data: myTeamLeaveInfo,
    refetch: fetchMyLeave,
    isLoading: isMyTeamLeaveDrawerLoading,
    isError: isMyTeamLeaveDrawerError,
  } = useDrawerMyLeaveDetail(currentLeaveId);

  const fetchDrawerInfo = useCallback(() => {
    myTeamLeave ? fetchMyTeamLeave() : fetchMyLeave();
  }, [fetchMyLeave, fetchMyTeamLeave, myTeamLeave]);

  const fetchNewComments = useCallback(() => {
    fetchDrawerInfo();
    setIsAddNewComment(true);
  }, [fetchDrawerInfo]);

  useEffect(() => {
    fetchDrawerInfo();
  }, [fetchDrawerInfo]);

  useEffect(() => {
    if (myLeaveInfo || myTeamLeaveInfo) {
      const drawerDetail: LeaveInfo | undefined = myLeaveInfo || myTeamLeaveInfo;
      drawerDetail
        ? isAddNewComment
          ? setLeaveInfo({ ...leaveInfo, comments: drawerDetail.comments })
          : setLeaveInfo(drawerDetail)
        : setLeaveInfo({} as LeaveInfo);
      setIsAddNewComment(false);
    }
  }, [isAddNewComment, leaveInfo, myLeaveInfo, myTeamLeaveInfo]);

  useErrorNotification(
    {
      isNotification: isMyLeaveDrawerError || isMyTeamLeaveDrawerError,
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    },
    [isMyLeaveDrawerError, isMyTeamLeaveDrawerError]
  );

  return (
    <React.Fragment>
      <>
        {leaveInfo.id && (
          <LeavesDrawerDetails
            drawerInfo={leaveInfo}
            isLoading={isMyLeaveDrawerLoading || isMyTeamLeaveDrawerLoading}
            currentLeaveId={currentLeaveId}
            isMyTeamLeaves={myTeamLeave}
            isError={isMyLeaveDrawerError || isMyTeamLeaveDrawerError}
            fetchNewComment={fetchNewComments}
            requestProcessInstanceId={processInstanceId}
            {...rest}
          />
        )}
      </>
    </React.Fragment>
  );
};

export const LeaveDrawer = React.memo(LeaveDrawerComponent);

import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { UserPopover } from 'ui/components';
import { StyledOHUserName } from './OrganizationalHierarchy.styled';

const { Text } = Typography;

interface Props {
  userName: string;
  title: string;
  strong?: boolean;
  id?: string;
  email?: string;
  hcmId?: string;
}

export const OrganizationalHierarchyTitle: FC<Props> = ({
  userName,
  title,
  strong,
  id,
  email,
  hcmId,
}) => {
  return (
    <UserPopover {...(email ? { currentEmail: email } : id ? { currentID: id } : { hcmID: hcmId })}>
      <Space size={20}>
        <StyledOHUserName
          strong={strong}
          displayunderline={id || email || hcmId ? 'underline' : 'none'}
        >
          {userName}
        </StyledOHUserName>
        <Text type="secondary">{title}</Text>
      </Space>
    </UserPopover>
  );
};

import React from 'react';
import { IProfileAttachment } from 'types';
import { PublicProfileAttachmentDivider } from '../PublicProfile.styled';
import { PublicProfileAttachmentItem } from './PublicProfileAttachmentItem';

export interface IPublicProfileAttachments {
  title: string;
  attachments: IProfileAttachment[];
}

export interface PublicProfileAttachmentsProps {
  data: IPublicProfileAttachments[];
}

export const PublicProfileAttachments: React.FC<PublicProfileAttachmentsProps> = ({
  data,
}: PublicProfileAttachmentsProps) => {
  return (
    <>
      {data.map(({ title, attachments }, index) => (
        <>
          <PublicProfileAttachmentItem key={title} title={title} attachments={attachments} />
          {index !== data.length - 1 && <PublicProfileAttachmentDivider />}
        </>
      ))}
    </>
  );
};

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const processInstanceInfo = (state: RootState): RootState['processInstanceInfo'] =>
  state.processInstanceInfo;

export const processInstanceInfoData = createSelector(
  processInstanceInfo,
  ({ error, status, data }) => ({ error, status, data })
);

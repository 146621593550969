import { useMutation } from 'react-query';
import { NONPOWitholdingTaxRateResponseData, IGetNonWitholdingTaxParams } from 'types';
import { nonPoInvoice } from '../NONPoInvoice';

export function useNonPoInvoiceWitholdingTaxRateApi() {
  return useMutation<
    NONPOWitholdingTaxRateResponseData,
    unknown,
    IGetNonWitholdingTaxParams,
    unknown
  >(async (params) => {
    const { data } = await nonPoInvoice.postNonPoWitholdingTaxRate(params);
    return data;
  });
}

import React, { FC, memo } from 'react';
import { AdditionalSteps } from 'types';
import { StyledAdditionalStep, StyledStepTitle } from './RequestCatalogDrawerStyle';

interface Props {
  step: string;
  additionalSteps?: AdditionalSteps[];
}

export const DrawerStepsDescriptionComponent: FC<Props> = ({ step, additionalSteps }) => (
  <StyledStepTitle>
    {step}
    {additionalSteps && additionalSteps.length > 0 && additionalSteps[0].stepName === step ? (
      <ul>
        {additionalSteps[0]?.additionalSteps.map((additionalStep, index) => (
          <StyledAdditionalStep key={index}> {additionalStep} </StyledAdditionalStep>
        ))}
      </ul>
    ) : null}
  </StyledStepTitle>
);

export const DrawerStepsDescription = memo(DrawerStepsDescriptionComponent);

import { CalendarOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { colors, space, typography } from 'ui/elements';

export const StyledIcon = styled(CalendarOutlined)`
  align-self: center;
  color: ${colors.primaryColor};
  line-height: 1;
  pointer-events: none;
  position: absolute;
  font-size: ${typography.size.kilo};
  margin-right: ${space.mini};
  left: -${space.comfy};
  top: 3px;
`;

import React, { FC, useState } from 'react';

import { AppLayout } from 'layouts';
import { Header } from 'app';
import { TType, withTranslate } from 'translations';
import { TitleH1 } from 'ui/elements';
import { Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { FilterContextType } from 'ui/components/Filter/Filter.types';
import {
  StyledBackIcon,
  StyledPageContainer,
  StyledFilterHeader,
  StyledTableContainer,
} from './LeaseSites.styled';
import { LeaseSitesFilter, LeaseSitesTable } from './components';

interface Props {
  t: TType;
}

export const LeaseSites: FC<Props> = withTranslate(({ t }) => {
  const history = useHistory();
  const [filterValues, setFilterValues] = useState<FilterContextType['filterValues']>({});

  const goBack = () => {
    history.goBack();
  };

  return (
    <AppLayout displayFooter={false}>
      <Header withoutShadow={true}>
        <Row align="middle">
          <StyledBackIcon onClick={goBack} />
          <TitleH1 fontWeight={500} primary>
            {t('lease.leaseSites')}
          </TitleH1>
        </Row>
      </Header>

      <StyledPageContainer>
        <StyledFilterHeader span={24}>
          <LeaseSitesFilter setFilterValues={setFilterValues} filterValues={filterValues} />
        </StyledFilterHeader>
        <StyledTableContainer>
          <LeaseSitesTable filterValues={filterValues} />
        </StyledTableContainer>
      </StyledPageContainer>
    </AppLayout>
  );
});

import { DashboardRequestsListsCards, RequestCardsList } from 'types';
import {
  Core,
  DASHBOARD_REQUEST_LIST_CARDS,
  DASHBOARD_REQUEST_LIST_CARDS_OLD,
  MY_REQUEST_CARDS_WIDGET,
  MY_REQUEST_CARDS_WIDGET_OLD,
} from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetDashboardRequestCard {
  (adoid: string): Promise<{ data: DashboardRequestsListsCards }>;
}

interface GetMyRequestWidgetCards {
  (userId: string, params?: AxiosRequestConfig['params']): Promise<{
    data: RequestCardsList;
  }>;
}

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

class DashboardRequestCards extends Core {
  getDashboardRequestCard: GetDashboardRequestCard = async (adoid: string) =>
    this.get(
      disableAggregator ? DASHBOARD_REQUEST_LIST_CARDS_OLD(adoid) : DASHBOARD_REQUEST_LIST_CARDS()
    );

  getMyRequestCardWidget: GetMyRequestWidgetCards = async (userId: string, params) =>
    this.get(disableAggregator ? MY_REQUEST_CARDS_WIDGET_OLD(userId) : MY_REQUEST_CARDS_WIDGET(), {
      params,
    });
}

export const dashboardRequestCards = new DashboardRequestCards();

import React, { useCallback, useMemo, useState } from 'react';
import { useTranslate } from 'translations';

import { task } from 'api';
import { saveAs } from 'file-saver';
import { notification, Popconfirm, Spin } from 'antd';
import { IProfileAttachment } from 'types';
import moment from 'moment/moment';
import {
  StyledAttachmentName,
  StyledAttachmentNameWrapper,
  StyledAttachmentUploadedDate,
  StyledFileArrowDownIcon,
  StyledProfileAttachmentContainer,
  StyledFileWorldIcon,
  StyledFilePPTIcon,
  StyledFileImageIcon,
  StyledFileExcelIcon,
  StyledFilePDFIcon,
  StyledFileCsvIcon,
  StyledFileVideoIcon,
  StyledFileTextIcon,
} from './PrivateProfileAttachment.styles';

export interface PrivateProfileAttachmentProps {
  attachment: IProfileAttachment;
  withAcknowledge?: boolean;
}

export const PrivateProfileAttachment: React.FC<PrivateProfileAttachmentProps> = ({
  attachment,
  withAcknowledge,
}: PrivateProfileAttachmentProps) => {
  const { t } = useTranslate();
  const { name: fileName, taskAttachmentId, taskId, fileExtension, uploadDate } = attachment;
  const date = useMemo(() => {
    return uploadDate ? moment(uploadDate).format('DD.MM.YYYY') : '';
  }, [uploadDate]);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState<boolean>(false);
  const uploadedOn = `${t('user.privateProfile.attachment.uploadedOn')} ${date}`;

  const downloadAttachment = useCallback(() => {
    setIsAttachmentLoading(true);
    task
      .downloadTaskAttachment({
        taskId,
        attachmentId: taskAttachmentId,
        params: {
          responseType: 'blob',
        },
      })
      .then(({ data }) => {
        saveAs(data, fileName);
      })
      .catch(() => {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: t('messages.error.tryAgainLater'),
        });
      })
      .finally(() => {
        setIsAttachmentLoading(false);
      });
  }, [taskId, taskAttachmentId, fileName, t]);

  const icon: JSX.Element = useMemo(() => {
    if (fileExtension) {
      return (
        <>
          {(fileExtension.toLowerCase().includes('doc') ||
            fileExtension.toLowerCase().includes('docx')) && <StyledFileWorldIcon />}

          {(fileExtension.toLowerCase().includes('ppt') ||
            fileExtension.toLowerCase().includes('pptx')) && <StyledFilePPTIcon />}

          {(fileExtension.toLowerCase().includes('jpg') ||
            fileExtension.toLowerCase().includes('jpeg') ||
            fileExtension.toLowerCase().includes('heic') ||
            fileExtension.toLowerCase().includes('png') ||
            fileExtension.toLowerCase().includes('gif')) && <StyledFileImageIcon />}

          {(fileExtension.toLowerCase().includes('xls') ||
            fileExtension.toLowerCase().includes('xlsx')) && <StyledFileExcelIcon />}

          {fileExtension.toLowerCase().includes('pdf') && <StyledFilePDFIcon />}

          {(fileExtension.toLowerCase().includes('txt') ||
            fileExtension.toLowerCase().includes('msg')) && <StyledFileTextIcon />}

          {fileExtension.toLowerCase().includes('csv') && <StyledFileCsvIcon />}

          {(fileExtension.toLowerCase().includes('mp4') ||
            fileExtension.toLowerCase().includes('mov') ||
            fileExtension.toLowerCase().includes('mkv') ||
            fileExtension.toLowerCase().includes('3gp')) && <StyledFileVideoIcon />}
        </>
      );
    } else {
      return <StyledFileTextIcon />;
    }
  }, [fileExtension]);

  const attachmentUi = useMemo(
    () => (
      <StyledProfileAttachmentContainer
        // href={url || undefined}
        // target="_blank"
        // rel="noreferrer"
        // download
        onClick={() => (withAcknowledge ? null : downloadAttachment())}
      >
        {icon}
        <StyledAttachmentNameWrapper>
          <StyledAttachmentName>{fileName}</StyledAttachmentName>
          <StyledAttachmentUploadedDate>{uploadedOn}</StyledAttachmentUploadedDate>
        </StyledAttachmentNameWrapper>
        {isAttachmentLoading ? <Spin size="small" /> : <StyledFileArrowDownIcon />}
      </StyledProfileAttachmentContainer>
    ),
    [icon, fileName, uploadedOn, isAttachmentLoading, withAcknowledge, downloadAttachment]
  );

  return withAcknowledge ? (
    <Popconfirm
      title="I acknowledge that these files are confidential and can be used only according to TAWAL's policy."
      onConfirm={() => {
        downloadAttachment();
      }}
      okText="Confirm"
      cancelText="Cancle"
    >
      {attachmentUi}
    </Popconfirm>
  ) : (
    attachmentUi
  );
};

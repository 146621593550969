import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const requestsList = (state: RootState): RootState['requestsList'] => state.requestsList;

export const requestsListData = createSelector(
  requestsList,
  ({ error, status, data, hasMore }) => ({
    error,
    status,
    data,
    hasMore,
  })
);

import { FormInstance } from 'antd/lib/form';
import React, { FC, memo, useState } from 'react';
import { normFile } from 'routes/Calendar';
import { StaticFormWithGroups } from 'routes/Tasks/components';
import { TType } from 'translations';
import {
  ActingAppointmentDetail,
  CreateFormItemType,
  GroupOfFormItem,
  GroupOfGroupFormItem,
} from 'types';
import {
  ActingAppointmentName,
  CommonFieldName,
  DEFAULT_TOTAL_LETTER_ITEM,
  FROM_ITEM_LIST,
  WRAPPER_COL_SPAN_SIZE_21,
} from 'ui';
import { FORM_ITEM_FILE_RULES_MAX, FORM_ITEM_REQUIRED_RULE } from 'utils';

interface ActingAppointmentProps {
  t: TType;
  form?: FormInstance;
  fromDetails?: ActingAppointmentDetail;
}

export const ActingAppointmentFieldsComponent: FC<ActingAppointmentProps> = ({
  t,
  form,
  fromDetails,
}) => {
  const [isRenewal, setIsRenewal] = useState<boolean>(false);

  const handleSelectEmployee = (value?: string) => {
    setIsRenewal(true);
  };

  const actingFormFields = () => {
    const res: CreateFormItemType[] = [];

    res.push(
      {
        id: `${0}`,
        label: t('actingAppointment.staticForm.label.employeeName'),
        name: ActingAppointmentName.employeeName,
        type: 'select',
        hasFeedback: true,
        options: fromDetails?.employeesNames,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        onSelect: handleSelectEmployee,
        filterOption: false,
        placeholder: t('actingAppointment.staticForm.placeholder.employeeName'),
      },
      {
        id: `${1}`,
        label: t('actingAppointment.staticForm.label.purpose'),
        name: ActingAppointmentName.purpose,
        type: 'input',
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        disabled: true,
      },
      {
        id: `${2}`,
        label: t('actingAppointment.staticForm.label.positionActing'),
        name: ActingAppointmentName.positionActing,
        type: 'select',
        options: fromDetails?.positionActing,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
        placeholder: t('actingAppointment.staticForm.placeholder.positionActing'),
      },
      {
        id: `${3}`,
        label: t('actingAppointment.staticForm.label.department'),
        name: ActingAppointmentName.positionActing,
        type: 'select',
        options: fromDetails?.department,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
        placeholder: t('actingAppointment.staticForm.placeholder.department'),
      }
    );
    return res;
  };

  const getAppointmentGroupOfForms = () => {
    const res: GroupOfFormItem[] = [];
    res.push({
      'Appointment details': [
        {
          id: `${0}`,
          label: t('actingAppointment.staticForm.label.actingDuration'),
          name: ActingAppointmentName.actingDuration,
          type: 'select',
          options: fromDetails?.actingDuration,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          hasFeedback: true,
          placeholder: t('actingAppointment.staticForm.placeholder.actingDuration'),
        },
        {
          id: `${1}`,
          label: t('actingAppointment.staticForm.label.startDate'),
          name: ActingAppointmentName.startDate,
          type: 'date',
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          picker: 'date',
          placeholder: t('actingAppointment.staticForm.placeholder.startDate'),
        },
        {
          id: `${2}`,
          label: t('commonField.staticForm.label.description'),
          name: CommonFieldName.description,
          type: 'textArea',
          maxNumber: 400,
          rules: FORM_ITEM_REQUIRED_RULE,
          placeholder: t('tasks.textArea.placeHolder'),
          hasFeedback: true,
        },
        {
          id: `${3}`,
          label: t('commonField.staticForm.label.attachment'),
          name: CommonFieldName.attachment,
          type: 'file',
          getValueFromEvent: normFile,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          rules: FORM_ITEM_FILE_RULES_MAX,
          valuePropName: 'fileList',
        },
      ],
    });
    return res;
  };

  const getRenewalGroupOfForms = () => {
    const res: GroupOfGroupFormItem[] = [];
    res.push({
      'Renewal details': [
        {
          'Appointment details': [
            {
              id: `${0}`,
              label: t('actingAppointment.staticForm.label.actingDuration'),
              name: ActingAppointmentName.actingDuration,
              type: 'select',
              options: fromDetails?.actingDuration,
              rules: FORM_ITEM_REQUIRED_RULE,
              wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
              hasFeedback: true,
              placeholder: t('actingAppointment.staticForm.placeholder.actingDuration'),
              disabled: true,
            },
            {
              id: `${1}`,
              label: t('actingAppointment.staticForm.label.actingDates'),
              name: ActingAppointmentName.startDate,
              type: 'date',
              rules: FORM_ITEM_REQUIRED_RULE,
              wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
              picker: 'date',
              placeholder: t('actingAppointment.staticForm.placeholder.actingDates'),
              disabled: true,
            },
          ],
        },
        {
          'Reappointment details': [
            {
              id: `${0}`,
              label: t('actingAppointment.staticForm.label.actingDuration'),
              name: ActingAppointmentName.actingDuration,
              type: 'select',
              options: fromDetails?.actingDuration,
              rules: FORM_ITEM_REQUIRED_RULE,
              wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
              hasFeedback: true,
              placeholder: t('actingAppointment.staticForm.placeholder.actingDuration'),
            },
            {
              id: `${1}`,
              label: t('actingAppointment.staticForm.label.reappointmentDates'),
              name: ActingAppointmentName.purpose,
              type: 'input',
              wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
              disabled: true,
            },
          ],
        },
        {
          '': [
            {
              id: `${0}`,
              label: t('commonField.staticForm.label.description'),
              name: CommonFieldName.description,
              type: 'textArea',
              maxNumber: DEFAULT_TOTAL_LETTER_ITEM,
              placeholder: t('tasks.textArea.placeHolder'),
              hasFeedback: true,
            },
            {
              id: `${1}`,
              label: t('commonField.staticForm.label.attachment'),
              name: CommonFieldName.attachment,
              type: 'file',
              getValueFromEvent: normFile,
              wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
              rules: FORM_ITEM_FILE_RULES_MAX,
              valuePropName: 'fileList',
            },
          ],
        },
      ],
    });
    return res;
  };
  return (
    <React.Fragment>
      <StaticFormWithGroups
        t={t}
        description={fromDetails?.processDescription}
        form={form}
        formListName={FROM_ITEM_LIST}
        isHidden={false}
        startFormFields={actingFormFields}
        listOfFormField={!isRenewal ? getAppointmentGroupOfForms : undefined}
        groupOflistOfFormField={isRenewal ? getRenewalGroupOfForms : undefined}
      />
    </React.Fragment>
  );
};

export const ActingAppointmentFields = memo(ActingAppointmentFieldsComponent);

import moment from 'moment';
import React, { FC } from 'react';
import { HeaderProps } from 'react-big-calendar';
import { StyledCalendarHeader } from 'routes/Calendar/Components/CalendarView/CalendarStyle';

export const CalendarHeaderDate: FC<HeaderProps> = ({ date }) => {
  const currentDate = moment(date);
  const currentDay = currentDate.format('dd');
  return <StyledCalendarHeader>{currentDay}</StyledCalendarHeader>;
};

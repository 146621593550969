import { Core, EXPORT_ATTENDANCE } from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetExportAttendance {
  (
    hcmPersonId: string,
    data: {
      responseType: string;
      params: AxiosRequestConfig['params'];
    }
  ): Promise<{ data: string }>;
}

class ExportAttendance extends Core {
  getExportAttendance: GetExportAttendance = async (hcmPersonId, data) =>
    this.get(EXPORT_ATTENDANCE(hcmPersonId), data);
}

export const exportAttendance = new ExportAttendance();

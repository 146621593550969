import { eLibrary, QUERIES, PERSIST_QUERY_CONFIG } from 'api';
import { useQuery } from 'react-query';

export function useELibraryDocuments(departmentName?: string) {
  return useQuery(
    [QUERIES.eLibraryDocuments, departmentName],
    async () => {
      const { data } = await eLibrary.getDocuments(departmentName ?? '');
      return data;
    },
    {
      enabled: !!departmentName,
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

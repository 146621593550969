import styled from 'styled-components';
import { Button } from 'ui/components/LoginForm/LoginForm.styled';
import { colors } from '../../../elements/theme';

interface AdditionalProps {
  selected?: boolean;
}

export const Navigation = styled.div`
  padding: 13px;
  padding-right: 0;
  padding-bottom: 0;
`;
export const StyledRow = styled.a`
  background-color: ${(props: AdditionalProps) => !!props.selected && '#f6f7f9'};
  transition: background-color 0.3s;
`;
export const StyledLink = styled.a``;
export const StyledIcon = styled.div`
  font-size: 17px;
  color: ${colors.textColorSecondary};
  cursor: pointer;
`;
export const StyledButton = styled(Button)`
  padding: 0;
`;

import React from 'react';
import { Col, Row } from 'antd';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { AnnouncementCardType } from 'types';
import moment from 'moment';
import {
  AnnouncementAvatarWrapper,
  AnnouncementCreate,
  AnnouncementTitle,
} from '../../AnnouncementCard.styled';

import { AnnouncementCard, AnnouncementImage } from './Components';

type Props = {
  cardInfo: AnnouncementCardType;
};

export const Announcement: React.FC<Props> = ({ cardInfo }) => {
  return (
    <AnnouncementCard cover={<AnnouncementImage imageURL={cardInfo.bannerRefImage} />}>
      <Row align={'middle'}>
        {cardInfo.publish && (
          <AnnouncementAvatarWrapper>
            <MSTeamsUserAvatar
              size={35}
              name={cardInfo.publish.senderName}
              email={cardInfo.publish.senderEmail}
            />
          </AnnouncementAvatarWrapper>
        )}
        <Col span={19}>
          <Row>
            <Col span={24}>
              <AnnouncementTitle ellipsis>{cardInfo.title}</AnnouncementTitle>
            </Col>
            <Col span={24}>
              <AnnouncementCreate ellipsis>
                {moment(cardInfo.createdDateTime).format('DD.MM.YYYY')}
              </AnnouncementCreate>
            </Col>
          </Row>
        </Col>
      </Row>
    </AnnouncementCard>
  );
};

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const taskCommentsSelector = (state: RootState): RootState['taskComments'] =>
  state.taskComments;

export const taskCommentsDataSelector = createSelector(taskCommentsSelector, (data) => data);

export const taskCommentSelector = (taskId: string) =>
  createSelector(taskCommentsDataSelector, (state) => state.comments[taskId] || {});

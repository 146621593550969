import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PrettyId, RequestCommonError, RequestStatus } from 'types';
import { TaskPrettyIdState } from './taskPrettyIdTypes';

export const TaskPrettyIdInitialState: TaskPrettyIdState = {};

export type GetTaskPrettyIdStart = PayloadAction<{ ids: string }>;
export type GetTaskPrettyIdSuccess = PayloadAction<{
  data: PrettyId[];
}>;
export type GetTaskPrettyIdFail = PayloadAction<{
  ids: string;
  error: RequestCommonError;
}>;

const taskPrettyIdSlice = createSlice({
  name: 'taskPrettyId',
  initialState: TaskPrettyIdInitialState,
  reducers: {
    getTaskPrettyIdStart(state, action: GetTaskPrettyIdStart) {
      const idsArr: string[] = action.payload.ids.split(',');

      idsArr.forEach((id) => {
        state[id] = {
          ...state[id],
          status: RequestStatus.pending,
        };
      });
    },
    getTaskPrettyIdSuccess(state, action: GetTaskPrettyIdSuccess) {
      const prettyIdsResp = action.payload.data;

      prettyIdsResp.forEach((prettyId) => {
        state[prettyId.camundaId].status = RequestStatus.resolved;
        state[prettyId.camundaId].data = prettyId;
      });
    },
    getTaskPrettyIdFail(state, action: GetTaskPrettyIdFail) {
      const idsArr: string[] = action.payload.ids.split(',');

      idsArr.forEach((id) => {
        state[id] = {
          ...state[id],
          status: RequestStatus.rejected,
          error: action.payload.error,
        };
      });
    },
  },
});

export const {
  getTaskPrettyIdStart,
  getTaskPrettyIdSuccess,
  getTaskPrettyIdFail,
} = taskPrettyIdSlice.actions;

export default taskPrettyIdSlice.reducer;

import styled from 'styled-components';
import { Card, Col, Table, Typography } from 'antd';
import { colors, space } from 'ui/elements';

const { Text, Paragraph } = Typography;
export const StyledCol = styled(Col)`
  padding: 6px 16px 0px 0px;
`;
export const StyledText = styled(Text)`
  color: ${colors.textColorSecondary};
  margin-bottom: 0 !important;
  font-size: 14px;
`;
export const StyledParagraph = styled(Paragraph)`
  color: ${colors.textColorSecondary};
  margin-bottom: 0 !important;
  font-size: 14px;
`;
export const StyledCard = styled(Card)`
  width: 100%;
  .ant-card-body {
    padding: ${space.zero} ${space.slim};
  }
`;
export const StyledSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${colors.primaryColor};
`;

export const StyledSpanName = styled.span`
  color: ${colors.primaryColor};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

export const StyledUsersBody = styled.div`
  margin: ${space.moderate} ${space.normal};
`;

export const StyledUsersTable = styled(Table).attrs({ size: 'middle' })`
  .ant-tabs-nav-wrap {
    padding: 24px;
    background-color: ${colors.white};
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: ${colors.primaryColor};

    .ant-tabs-tab-active {
      font-weight: 500;
    }
  }

  .ant-table-thead > tr > th {
    background-color: ${colors.white};
    color: ${colors.primaryColor};
    font-weight: 600;
  }

  .ant-table-thead > tr > th.ant-table-cell {
    padding: 16px;
  }

  .ant-table-row {
    height: 54px;
  }

  .ant-pagination.mini li {
    border: 1px solid ${colors.lightSilver} !important;
    margin-right: ${space.slim} !important;
    border-radius: 4px !important;
    width: 32px;
    height: 32px;
  }

  .ant-pagination.mini li button {
    font-weight: 400;
    font-size: 14px;
    color: #595959;
  }

  .ant-pagination.mini li a {
    font-weight: 400;
    font-size: 14px;
    color: #595959;
    padding: 4px;
  }

  .ant-table-column-sorter-full {
    position: absolute;
    right: 5px;
  }

  .ant-pagination.mini .ant-pagination-item-active {
    border: 1px solid ${colors.primaryColor} !important;
  }

  .ant-table-tbody {
    .ant-table-row {
      cursor: pointer;
    }
  }
`;

export const StatusUserTag = styled.span`
  border-radius: 20px;
  height: 22px;
  line-height: 20px;
  padding: 3px 14px;
  white-space: nowrap;
  color: ${colors.skyColor};
  border: solid 1px ${colors.skyColor};
`;

import { useMutation } from 'react-query';
import { NONPOConversionRateResponseData, IGetNonPoConversionRateParams } from 'types';
import { nonPoInvoice } from '../NONPoInvoice';

export const useNonPoInvoiceConversionRateApi = () => {
  return useMutation<
    NONPOConversionRateResponseData,
    unknown,
    IGetNonPoConversionRateParams,
    unknown
  >(async (params) => {
    const { data } = await nonPoInvoice.postNonPoConversionRate(params);
    return data;
  });
};

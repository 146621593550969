import React from 'react';
import {
  StyledFileCsvIcon,
  StyledFileExcelIcon,
  StyledFileImageIcon,
  StyledFilePDFIcon,
  StyledFilePPTIcon,
  StyledFileTextIcon,
  StyledFileVideoIcon,
  StyledFileWorldIcon,
} from './DownloadAttachment.styled';

export interface AttachmentIconProps {
  fileName?: string;
}

export const AttachmentIcon: React.FC<AttachmentIconProps> = ({
  fileName,
}: AttachmentIconProps) => {
  const fileExtension = fileName?.split('.').pop();
  if (fileExtension) {
    return (
      <>
        {(fileExtension.toLowerCase().includes('doc') ||
          fileExtension.toLowerCase().includes('docx')) && <StyledFileWorldIcon />}

        {(fileExtension.toLowerCase().includes('ppt') ||
          fileExtension.toLowerCase().includes('pptx')) && <StyledFilePPTIcon />}

        {(fileExtension.toLowerCase().includes('jpg') ||
          fileExtension.toLowerCase().includes('jpeg') ||
          fileExtension.toLowerCase().includes('heic') ||
          fileExtension.toLowerCase().includes('png') ||
          fileExtension.toLowerCase().includes('gif')) && <StyledFileImageIcon />}

        {(fileExtension.toLowerCase().includes('xls') ||
          fileExtension.toLowerCase().includes('xlsx')) && <StyledFileExcelIcon />}

        {fileExtension.toLowerCase().includes('pdf') && <StyledFilePDFIcon />}

        {(fileExtension.toLowerCase().includes('txt') ||
          fileExtension.toLowerCase().includes('msg')) && <StyledFileTextIcon />}

        {fileExtension.toLowerCase().includes('csv') && <StyledFileCsvIcon />}

        {(fileExtension.toLowerCase().includes('mp4') ||
          fileExtension.toLowerCase().includes('mov') ||
          fileExtension.toLowerCase().includes('mkv') ||
          fileExtension.toLowerCase().includes('3gp')) && <StyledFileVideoIcon />}
      </>
    );
  } else {
    return <StyledFileTextIcon />;
  }
};

import React, { FC, Fragment, memo, useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { TType } from 'translations';
import { normFile } from 'routes/Calendar/page';
import { CreateFormItemType, DelegationRequestType, SelectOptions } from 'types';
import { CommonFieldName, DEFAULT_TOTAL_LETTER, WRAPPER_COL_SPAN_SIZE_21 } from 'ui';
import { FORM_ITEM_REQUIRED_RULE, FORM_ITEM_FILE_RULES_MAX } from 'utils';
import { StaticFormTemplate } from 'routes/Tasks/components';
import moment from 'moment';

interface DelegationFormFieldsProps {
  t: TType;
  formFieldData?: DelegationRequestType;
  form?: FormInstance;
  startDate?: moment.Moment;
}

const DelegationFieldsComponents: FC<DelegationFormFieldsProps> = ({
  t,
  formFieldData,
  form,
  startDate,
}) => {
  const [delegateNames, setDelegateNames] = useState<SelectOptions>();
  // const [processes, setProcesses] = useState<SelectOptions>();
  // const hierarchyOptions = [
  //   { value: 1, label: 'Yes' },
  //   { value: 0, label: 'No' },
  // ];
  useEffect(() => {
    const delegateFields = formFieldData?.delegatesNameList.map((field) => {
      return { value: field.id, label: field.name };
    });

    // const processeseFields = formFieldData?.processesList.map((field) => {
    //   return { value: field.id, label: field.name };
    // });
    setDelegateNames(delegateFields);
    // setProcesses(processeseFields);
  }, [formFieldData]);

  const handleEndDateLogic = (current: moment.Moment): boolean => {
    const currentDate = startDate ?? moment();
    const today = moment().add(-1, 'days').endOf('day');
    if (currentDate.isBefore(today)) {
      return current.isBefore(today);
    }
    return current.isBefore(currentDate);
  };

  const getItems = () => {
    const res: CreateFormItemType[] = [];
    res.push(
      {
        id: `${0}`,
        label: 'Delegate Name',
        name: 'delegateName',
        type: 'select',
        options: delegateNames,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
        placeholder: `Delegate name`,
      },
      {
        id: `${1}`,
        label: '',
        name: '',
        type: 'text',
      },
      // {
      //   id: `${1}`,
      //   label: 'OU processes to be Delegated',
      //   name: 'OUProceesses',
      //   type: 'multiSelect',
      //   options: processes,
      //   rules: FORM_ITEM_REQUIRED_RULE,
      //   wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
      //   hasFeedback: true,
      //   placeholder: 'Choose processes',
      // },
      // {
      //   id: `${2}`,
      //   label: 'Do you want to delegate the hierarchy approval tasks?',
      //   name: 'hierarchyApproval',
      //   type: 'radio',
      //   options: hierarchyOptions,
      //   description:
      //     'Choosing “Yes” will let the delegate access all your hierarchy approval tasks, along with OU tasks that you selected above. Choosing “No” will let the delegate access to only the OU tasks you selected above.',
      //   rules: FORM_ITEM_REQUIRED_RULE,
      //   isFullWidth: true,
      // },
      {
        id: `${3}`,
        label: 'Delegation Start Date',
        name: 'delegationStartDate',
        type: 'date',
        picker: 'date',
        // disabledDate: disableReturningDate,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        placeholder: 'Choose start date',
      },
      {
        id: `${4}`,
        label: 'Delegation End Date',
        name: 'delegationEndDate',
        type: 'date',
        picker: 'date',
        disabledDate: handleEndDateLogic,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        placeholder: 'Choose end date',
      },
      {
        id: `${5}`,
        label: 'Delegation Reason',
        name: CommonFieldName.description,
        type: 'textArea',
        maxNumber: DEFAULT_TOTAL_LETTER,
        placeholder: 'Enter your reason here',
        hasFeedback: true,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
      },
      {
        id: `${6}`,
        label: t('commonField.staticForm.label.attachment'),
        name: CommonFieldName.attachment,
        type: 'file',
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        valuePropName: 'fileList',
        getValueFromEvent: normFile,
        rules: FORM_ITEM_FILE_RULES_MAX,
      }
    );
    return res;
  };

  return (
    <Fragment>
      <StaticFormTemplate
        t={t}
        getItems={getItems}
        description={formFieldData?.processDescription || 'Description'}
        example={formFieldData?.processDescriptionExample || 'Example'}
        form={form}
      />
    </Fragment>
  );
};

export const DelegationFields = memo(DelegationFieldsComponents);

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetGroupsTasksListFail,
  GetGroupsTasksListStart,
  GetGroupsTasksListSuccess,
  getGroupsTasksListStart,
  getGroupsTasksListSuccess,
  getGroupsTasksListFail,
} from './groupsTasksListSlice';

export const groupsTasksListEpic: Epic<
  GetGroupsTasksListStart,
  GetGroupsTasksListSuccess | GetGroupsTasksListFail,
  RootState,
  typeof API
> = (action$, _, { task }) =>
  action$.pipe(
    filter(getGroupsTasksListStart.match),
    exhaustMap(({ payload }) =>
      from(task.getTasksList(payload)).pipe(
        map(({ data }) => getGroupsTasksListSuccess(data)),
        catchError((error) => of(getGroupsTasksListFail(error?.response?.data)))
      )
    )
  );

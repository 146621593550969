import React from 'react';
import { useTranslate } from 'translations';
import {
  StyledAttachmentContainer,
  StyledAttachmentIcon,
  StyledAttachmentItemText,
  StyledAttachmentItemWrapper,
  StyledAttachmentTitle,
} from './AnnouncementAttachment.styled';

export const AnnouncementAttachment: React.FC = () => {
  const { t } = useTranslate();
  return (
    <StyledAttachmentContainer>
      <StyledAttachmentTitle>{t('announcement.attachment')}</StyledAttachmentTitle>
      {['Licence Agreement', 'Circulation', 'Notice'].map((attachment, index) => (
        <StyledAttachmentItemWrapper key={index}>
          <StyledAttachmentItemText>{attachment}</StyledAttachmentItemText>
          <StyledAttachmentIcon />
        </StyledAttachmentItemWrapper>
      ))}
    </StyledAttachmentContainer>
  );
};

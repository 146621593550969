import styled from 'styled-components';
import { Button } from 'antd';
import { colors, typography } from 'ui/elements';

export const StyledPopoverContent = styled.div`
  width: 275px;
  padding: 12px;
`;

export const StyledPopoverTitle = styled.span`
  font-size: ${typography.size.hecto};
  font-weight: ${typography.weight.regular};
  color: ${colors.textColorSecondary};

  svg {
    margin-right: 10px;
  }
`;

export const StyledPopoverSearchContainer = styled.div`
  margin-top: 12px;
  margin-left: 25px;
`;
export const StyledPopoverActionsContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;
export const StyledPopoverActionButton = styled(Button)``;
export const AddUserProcessButton = styled(Button)`
  color: ${colors.white} !important;
  background-color: ${colors.orangeShadow} !important;
  border-radius: 2px;
  font-size: ${typography.size.hecto} !important;
  font-weight: ${typography.weight.regular} !important;

  &:hover,
  &:focus {
    background-color: ${colors.primaryColor} !important;
  }
`;

import React, { FC } from 'react';
import { VerticalSpace } from 'ui';
import { Row, Col } from 'antd';
import { SPAN_SIZE_24 } from 'routes';
import { useSelector } from 'react-redux';
import { isContractorEmployee } from 'store';
import { GeneralInformation } from './GeneralInformation';
import { Payroll } from './Payroll';

interface Props {
  setIsEditing: (val: boolean) => void;
  hcmPersonId: string | null;
  hireDate: string;
}

export const ReadView: FC<Props> = ({ setIsEditing, hcmPersonId, hireDate }) => {
  const isContractor = useSelector(isContractorEmployee);
  return (
    <Row>
      <Col md={SPAN_SIZE_24}>
        <VerticalSpace>
          <GeneralInformation hcmPersonId={hcmPersonId} setIsEditing={setIsEditing} />
          {isContractor ? <></> : <Payroll hcmPersonId={hcmPersonId} hireDate={hireDate} />}
        </VerticalSpace>
      </Col>
    </Row>
  );
};

import { FormInstance, Rule } from 'antd/lib/form';
import React, { FC, Fragment, memo } from 'react';
import { normFile } from 'routes/Calendar/page';
import { StaticFormTemplate } from 'routes/Tasks/components';
import { TType } from 'translations';
import { CreateFormItemType, CashAdvanceData } from 'types';
import {
  CommonFieldName,
  CashAdvanceName,
  DEFAULT_TOTAL_LETTER,
  WRAPPER_COL_SPAN_SIZE_21,
} from 'ui';
import { disableReturningDate, FORM_FILE_RULES_NOT_REQUIRED, FORM_ITEM_REQUIRED_RULE } from 'utils';

interface CashAdvanceFieldsProps {
  t: TType;
  formFieldDate?: CashAdvanceData;
  form?: FormInstance;
}

const CashAdvanceFieldsComponents: FC<CashAdvanceFieldsProps> = ({ t, formFieldDate, form }) => {
  const REQUIRED_FORM_ITEM_MAX_LENGTH_RULES: Rule[] = [
    {
      max: Number(formFieldDate?.maxTotalAmount),
      required: true,
      type: 'number',
    },
  ];
  const getItems = () => {
    const res: CreateFormItemType[] = [];
    res.push(
      {
        id: `${0}`,
        label: t('cashAdvance.staticForm.label.expectedInvoiceDate'),
        name: CashAdvanceName.expectedInvoiceDate,
        type: 'date',
        disabledDate: disableReturningDate,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        allowClear: true,
        placeholder: t('datePicker.date.placeholder'),
      },
      {
        id: `${1}`,
        label: t(`cashAdvance.staticForm.label.costCenterDepartment`),
        name: CashAdvanceName.costCenterDepartment,
        type: 'input',
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        disabled: true,
      },
      {
        id: `${2}`,
        label: t('cashAdvance.staticForm.label.totalAmount'),
        name: CashAdvanceName.totalAmount,
        type: 'inputNumber',
        maxNumber: Number(formFieldDate?.maxTotalAmount),
        rules: REQUIRED_FORM_ITEM_MAX_LENGTH_RULES,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
      },
      {
        id: `${3}`,
        label: t('cashAdvance.staticForm.label.description'),
        name: CommonFieldName.description,
        type: 'textArea',
        maxNumber: DEFAULT_TOTAL_LETTER,
        placeholder: t('tasks.textArea.placeHolder'),
        hasFeedback: true,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        rules: FORM_ITEM_REQUIRED_RULE,
      },
      {
        id: `${4}`,
        label: t('commonField.staticForm.label.attachment'),
        name: CommonFieldName.attachment,
        type: 'file',
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        valuePropName: 'fileList',
        getValueFromEvent: normFile,
        rules: FORM_FILE_RULES_NOT_REQUIRED,
      }
    );
    return res;
  };

  return (
    <Fragment>
      <StaticFormTemplate
        t={t}
        getItems={getItems}
        description={formFieldDate?.processDescription}
        form={form}
      />
    </Fragment>
  );
};

export const CashAdvanceFields = memo(CashAdvanceFieldsComponents);

import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { AppLayout } from 'layouts';
import { Header } from 'app';
import { TitleH1 } from 'ui';
import { useTranslate } from 'translations';
import { useSelector } from 'react-redux';
import { useGetProfileBasicInfo } from 'api';
import { profileData as profileDataSelector } from '../../store';
import { PrivateProfileTabs, UserInfoCard } from './components';
import {
  NewProfileContentRightSideWrapper,
  StyledNewProfileContentWrapper,
  StyledNewProfileCoverWrapper,
  StyledNewProfilePageWrapper,
} from './NewProfile.styled';

export const PrivateProfile: FC = () => {
  const { t } = useTranslate();
  const { data: profileData } = useSelector(profileDataSelector);
  const {
    data: profileBasicInfo,
    isLoading: profileBasicInfoLoading,
    isError: profileBasicInfoError,
  } = useGetProfileBasicInfo();

  return (
    <>
      <AppLayout displayFooter={false}>
        <Header>
          <Row align="middle">
            <Col>
              <TitleH1>{t('profile.title')}</TitleH1>
            </Col>
          </Row>
        </Header>
        <StyledNewProfilePageWrapper>
          <StyledNewProfileCoverWrapper />
          <StyledNewProfileContentWrapper gutter={[25, 0]}>
            <Col flex="300px">
              <UserInfoCard
                t={t}
                basicInfo={profileBasicInfo}
                isLoading={profileBasicInfoLoading}
                isError={profileBasicInfoError}
                selfView={true}
                adOid={profileData?.adOid}
              />
            </Col>
            <NewProfileContentRightSideWrapper flex="auto">
              <PrivateProfileTabs hcmid={profileData?.hcmId} />
            </NewProfileContentRightSideWrapper>
          </StyledNewProfileContentWrapper>
        </StyledNewProfilePageWrapper>
      </AppLayout>
    </>
  );
};

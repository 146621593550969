import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TaskDetailsData, RequestCommonError, RequestStatus } from 'types';
import { TaskDetailsState } from './taskDetailsTypes';

export const emptyTaskDetailsState: TaskDetailsState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetTaskDetailsStart = PayloadAction<{ taskId: string }>;
export type GetTaskDetailsSuccess = PayloadAction<TaskDetailsData>;
export type GetTaskDetailsFail = PayloadAction<RequestCommonError>;

const taskDetails = createSlice({
  name: 'taskDetials',
  initialState: emptyTaskDetailsState,
  reducers: {
    getTaskDetailsStart(state, _: GetTaskDetailsStart) {
      state.status = RequestStatus.pending;
    },
    getTaskDetailsSuccess(state, action: GetTaskDetailsSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getTaskDetailsFail(state, action: GetTaskDetailsFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getTaskDetailsStart,
  getTaskDetailsSuccess,
  getTaskDetailsFail,
} = taskDetails.actions;
export default taskDetails.reducer;

import React, { FC } from 'react';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { TType } from 'translations';
import { UserPopover } from 'ui';
import { StyledName, StyledNode, StyledTitle } from './OrganizationalChart.styled';

interface EmployeeCardProps {
  t: TType;
  id?: string;
  displayName?: string;
  title?: string;
  email?: string;
}

const EmployeeCardComponent: FC<EmployeeCardProps> = ({ t, id, displayName, title, email }) => {
  return (
    <StyledNode>
      <UserPopover currentID={id}>
        <MSTeamsUserAvatar id={id} name={displayName} statusSize={12} size={50} />
      </UserPopover>
      <StyledName>{displayName}</StyledName>
      <StyledTitle>{title}</StyledTitle>
    </StyledNode>
  );
};

export const EmployeeCard = EmployeeCardComponent;

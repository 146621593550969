import styled from 'styled-components';
import { Drawer, Typography, Col, Row, Button } from 'antd';
import { colors, space } from 'ui/elements';

const { Text } = Typography;

type Props = typeof Text & {
  readonly isScheduledAnnualLeave?: boolean;
  readonly isHighFromBottom?: boolean;
};

export type StyledTextProps = {
  isClickable?: boolean;
};

export const StyledLeavesDrawer = styled(Drawer)`
  & .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledLeavesWrapper = styled.div`
  flex-grow: 1;
  padding-bottom: 50px;
`;

export const StyledTimeStamp = styled(Text)`
  font-size: 16px;
`;

export const StyledAttachmentsTitle = styled(Text)`
  display: inline-block;
  height: 24px;
  border-bottom: 1px dashed ${colors.textColorSecondary};
`;
export const StyledActionButtonWrapper = styled.div<Props>`
  position: absolute;
  bottom: 0;
  padding-top: 10px;
  width: ${({ isScheduledAnnualLeave }) => (isScheduledAnnualLeave ? '100%' : '90.2%')};
  background: ${({ isScheduledAnnualLeave }) =>
    isScheduledAnnualLeave ? colors.lightGreyThird : colors.white};
  display: ${({ isScheduledAnnualLeave }) => (isScheduledAnnualLeave ? 'flex' : 'block')};
  left: ${({ isScheduledAnnualLeave }) => (isScheduledAnnualLeave ? `${space.zero}` : 'auto')};
  justify-content: ${({ isScheduledAnnualLeave }) => (isScheduledAnnualLeave ? 'end' : 'normal')};
  padding: ${({ isScheduledAnnualLeave }) =>
    isScheduledAnnualLeave ? `${space.normal} ${space.normal} ${space.zero}` : space.zero};
  padding-bottom: ${({ isHighFromBottom }) => (isHighFromBottom ? '15px' : '0px')};
`;

export const StyledCol = styled(Col)`
  text-align-last: end;
`;
export const StyledText = styled(Text)<StyledTextProps>`
  color: ${colors.textColorSecondary};
  font-size: 14px;
  text-decoration: ${(props) => (props.isClickable ? 'underline' : 'none')};
`;

export const StyledTextButton = styled(Button)`
  border: none;
  padding: 0 !important;
  height: 18px;
  margin: 0;
  align-self: center;
`;

export const StyledSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledLeaveRow = styled(Row)`
  justify-content: space-between;
`;

export const StyledApproveRow = styled(Row)`
  padding: ${space.slim} ${space.moderate};
  background-color: ${colors.lightOrange};
  margin-bottom: ${space.moderate};
`;

export const StyledApproveIcon = styled(Col)`
  display: flex;
  align-items: center;
`;

export const StyledApproveText = styled(Col)`
  padding-left: ${space.slim};
`;

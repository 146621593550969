import React, { FC } from 'react';
import {
  Wrapper as GoogleMapsWrapper,
  Status as GoogleMapsStatus,
} from '@googlemaps/react-wrapper';
import { FullWidthAndHeightSpin } from 'ui/elements';

type Props = {
  onMapLoad?: () => void;
  loader?: React.ReactElement;
};

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const GoogleMapsLoader: FC<Props> = ({ onMapLoad, loader, children }) => {
  const render = (status: GoogleMapsStatus): React.ReactElement => {
    if (status === GoogleMapsStatus.SUCCESS) {
      if (onMapLoad) {
        onMapLoad();
      }
      return children as React.ReactElement;
    } else if (status === GoogleMapsStatus.FAILURE) {
      return <>Error</>;
    }
    return loader ?? <FullWidthAndHeightSpin />;
  };

  return GOOGLE_API_KEY ? (
    <GoogleMapsWrapper
      apiKey={GOOGLE_API_KEY}
      render={render}
      version="quarterly"
      libraries={['core', 'maps', 'marker', 'geometry', 'drawing']}
      retries={3}
    />
  ) : (
    <></>
  );
};

import { useMutation } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { nonPoInvoice } from '../NONPoInvoice';

export const useNonPoSubmitBudgetTeamTask = () => {
  return useMutation<
    unknown,
    unknown,
    {
      processInstanceId: string;
      taskId: string;
      data: AxiosRequestConfig['data'];
    },
    unknown
  >(async ({ processInstanceId, taskId, data: formData }) => {
    const { data } = await nonPoInvoice.postNonPoBudgetTask(processInstanceId, taskId, formData);
    return data;
  });
};

import React, { FC, memo } from 'react';
import { Col, Steps } from 'antd';
import { TType } from 'translations';
import { AdditionalSteps } from 'types';
import {
  StyledDrawerStep,
  StyledDrawerSteps,
  StyledDrawerStepContainer,
} from './RequestCatalogDrawerStyle';
import { DrawerStepsDescription } from './RequestCatalogDrawerStepDescription';

const { Step } = Steps;

interface Props {
  steps: string[];
  t: TType;
  additionalSteps?: AdditionalSteps[];
}

export const DrawerStepsComponent: FC<Props> = ({ steps, t, additionalSteps }) => (
  <StyledDrawerStepContainer>
    <Col span={24}>
      <StyledDrawerStep strong>{t('requestCatalog.drawer.step.title')}</StyledDrawerStep>
      <StyledDrawerSteps direction={'vertical'}>
        {steps.map((step, index) => (
          <Step
            title={<DrawerStepsDescription step={step} additionalSteps={additionalSteps} />}
            key={`${step}-${index}`}
            status="wait"
          />
        ))}
      </StyledDrawerSteps>
    </Col>
  </StyledDrawerStepContainer>
);

export const DrawerSteps = memo(DrawerStepsComponent);

import React, { FC, useEffect, useRef, useCallback, useState } from 'react';
import { Col, Input, Row, Form } from 'antd';

import {
  useDispatch,
  useSelector,
  sendChatMessageStart,
  chatsData,
  getChatsStart,
  createOneToOneChatStart,
  profileData,
  addChatMemberReset,
} from 'store';
import { ChatType, RequestStatus, UserInfoData } from 'types';
import { FormItem, ArrowR, SendButton, CardInput } from './InputMessage.styled';

interface Props {
  data?: UserInfoData;
}

interface CreateChatProps {
  currentUserAdoid: string;
  targetUserAdoid: string;
  userName: string;
  userEmail: string;
  targetName: string;
  targetMail: string;
}
export const InputMessage: FC<Props> = ({ data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const profile = useSelector(profileData);
  const profileInfo = profile.data;
  const inputRef = useRef<Input | null>(null);
  const [chatWithUser, setChatWithUser] = useState<string>();
  const [startNewMessage, setStartNewMessage] = useState<boolean>(false);
  const { sendChatMessageStatus } = useSelector(chatsData);
  const isLoading = sendChatMessageStatus === RequestStatus.pending;
  const { data: chatData, getChatsStatus } = useSelector(chatsData);
  const { chats } = chatData;

  const createChatOneToOne = useCallback(
    (requestInfo: CreateChatProps) => {
      dispatch(
        createOneToOneChatStart({
          params: {
            userIds: [requestInfo.targetUserAdoid, requestInfo.currentUserAdoid].join(','),
          },
          chatMembers: [
            {
              displayName: requestInfo.targetName,
              email: requestInfo.targetMail,
              userId: requestInfo.targetUserAdoid,
            },
            {
              displayName: requestInfo.userName,
              email: requestInfo.userEmail,
              userId: requestInfo.currentUserAdoid,
            },
          ],
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (startNewMessage) {
      dispatch(getChatsStart());
    }
  }, [dispatch, startNewMessage]);

  useEffect(() => {
    if (chats?.length && data) {
      dispatch(addChatMemberReset());
      const chatWithSearchUser = chats.find(
        (chat) =>
          chat.chatType === ChatType.OneOnOne &&
          chat.members &&
          chat.members.some((member) => member.userId === data.adOid)
      );

      if (chatWithSearchUser) {
        setChatWithUser(chatWithSearchUser.id);
      } else if (data && profileInfo) {
        const requestInfo: CreateChatProps = {
          currentUserAdoid: profileInfo.adOid,
          userEmail: profileInfo.email || '',
          userName: [profileInfo.firstName, profileInfo.lastName].join(' '),
          targetUserAdoid: data.adOid || '',
          targetMail: data.email || '',
          targetName: data.displayName || '',
        };
        createChatOneToOne(requestInfo);
      }
    }
  }, [chats, data, dispatch, createChatOneToOne, profileInfo]);

  const onFinish = useCallback(
    (values: { message: string }) => {
      setStartNewMessage(true);
      if (getChatsStatus === RequestStatus.resolved && chatWithUser) {
        dispatch(
          sendChatMessageStart({
            chatId: chatWithUser,
            message: {
              body: {
                content: values.message,
              },
            },
          })
        );
      }
    },
    [dispatch, chatWithUser, getChatsStatus]
  );

  useEffect(() => {
    if (sendChatMessageStatus === RequestStatus.resolved) {
      form.resetFields();
      inputRef.current?.focus();
    }
  }, [form, sendChatMessageStatus]);

  return (
    <CardInput style={{ padding: 0 }} bordered={false}>
      <Form layout="horizontal" onFinish={onFinish} form={form} name="sendMessage">
        <Row gutter={[5, 0]} justify="center">
          <Col lg={25} flex="auto">
            <FormItem name="message">
              <Input
                readOnly={isLoading}
                ref={inputRef}
                autoComplete="off"
                placeholder={`Message ${data?.displayName}`}
              />
            </FormItem>
          </Col>
          <Col>
            <SendButton disabled={isLoading} htmlType="submit" type="link">
              <ArrowR />
            </SendButton>
          </Col>
        </Row>
      </Form>
    </CardInput>
  );
};

import { connect, ConnectedProps } from 'react-redux';

import { RootState, getCurrentTeamsChannelMembersData, getTeamsChannelMembersStart } from 'store';
import { ChatSendMessageProps } from './ChatSendMessage';

const mapDispatch = {
  getTeamsChannelMembersStart,
};

const mapState = (state: RootState, props: ChatSendMessageProps) => ({
  currentChannelMembersData: getCurrentTeamsChannelMembersData(state, props),
});

export const msTeamsChatSendMessageConnector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof msTeamsChatSendMessageConnector>;

export type ConnectorProps = PropsFromRedux;

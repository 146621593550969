import React, { useMemo } from 'react';
import { TType } from 'translations';
import { IEducationalDegree } from 'types';
import { PrivateProfileAttachments, ProfileEntryField } from 'ui';
import moment from 'moment/moment';
import { Col } from 'antd';
import { StyledProfileRowContainer } from 'routes/NewProfile/NewProfile.styled';
import {
  StyledDegreeType,
  StyledDependentItemWrapper,
} from './PrivateProfileEducationalDegree.styles';

type Props = {
  t: TType;
  educationalDegree: IEducationalDegree;
};

export const PrivateProfileEducationalDegreeItem: React.FC<Props> = ({ t, educationalDegree }) => {
  const {
    degreeType,
    universityName,
    major,
    startDate,
    endDate,
    educationalDegreeAttachmentList,
  } = educationalDegree;
  const degreeFields = useMemo(
    () => [
      { label: t('user.privateProfile.educationalDegrees.universityName'), value: universityName },
      { label: t('user.privateProfile.educationalDegrees.major'), value: major },
      {
        label: t('user.privateProfile.educationalDegrees.startDate'),
        value: startDate ? moment(startDate).format('DD.MM.YYYY') : undefined,
      },
      {
        label: t('user.privateProfile.educationalDegrees.endDate'),
        value: endDate ? moment(endDate).format('DD.MM.YYYY') : undefined,
      },
    ],
    [universityName, major, startDate, endDate, t]
  );

  return (
    <StyledDependentItemWrapper>
      <StyledDegreeType>{degreeType ?? ''}</StyledDegreeType>
      <StyledProfileRowContainer gutter={[25, 15]} wrap={true}>
        {degreeFields.map(({ label, value }, index) => (
          <Col xs={24} sm={24} md={12} lg={8} key={index}>
            <ProfileEntryField label={label} value={value} />
          </Col>
        ))}
      </StyledProfileRowContainer>
      <PrivateProfileAttachments attachments={educationalDegreeAttachmentList ?? []} />
    </StyledDependentItemWrapper>
  );
};

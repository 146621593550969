import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const processDefinitionKeys = (state: RootState): RootState['processDefinitionKeys'] =>
  state.processDefinitionKeys;

export const processDefinitionKeysData = createSelector(
  processDefinitionKeys,
  ({ error, status, data }) => ({
    error,
    status,
    data,
  })
);

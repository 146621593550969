import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const requestPrettyIdSelector = (state: RootState): RootState['requestPrettyId'] =>
  state.requestPrettyId;

export const requestPrettyIdStateSelector = createSelector(requestPrettyIdSelector, (data) => data);

export const requestPrettyIdItemSelector = (id: string) =>
  createSelector(requestPrettyIdSelector, (state) => state[id] || {});

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const historicActivityInstanceSelector = (
  state: RootState
): RootState['historicActivityInstance'] => state.historicActivityInstance;

export const historicActivityInstanceResponseSelector = createSelector(
  historicActivityInstanceSelector,
  (data) => data
);

export const historicActivityInstanceItemSelector = (id: string) =>
  createSelector(historicActivityInstanceSelector, (state) => state[id]);

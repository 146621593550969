import React, { FC, memo, Fragment, useCallback } from 'react';
import { FormInstance } from 'antd/lib/form';
import { StaticFormTemplate } from 'routes/Tasks/components';
import { TType } from 'translations';
import { CreateFormItemType, LeaseSitesFormDetails, SelectOptions } from 'types';
import { NEW_SITE_ACQUISITION_FORM, WRAPPER_COL_SPAN_SIZE_21 } from 'ui/components';
import {
  FORM_ITEM_FILE_RULES_MAX,
  FORM_ITEM_REQUIRED_RULE,
  FORM_ITEM_NOT_REQUIRED_NUMBER_RULE,
} from 'utils/formValidation';
import { normFile } from 'ui/components/GeneratedForm/FieldFile';

const VIP_REQUEST_OPTIONS: SelectOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const NewSiteAcquisitionProcessFormFieldsComponent: FC<Props> = ({
  description,
  districtsOptions,
  form,
  formDetailsOptions,
  onRegionChange,
  t,
}) => {
  const getItems = useCallback<() => CreateFormItemType[]>(
    () => [
      {
        type: 'input',
        id: `${1}`,
        label: t('initiateBtsProcess.form.label.customerSiteId'),
        name: NEW_SITE_ACQUISITION_FORM.customerSiteID,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.customerSiteId'),
      },
      {
        type: 'input',
        id: `${2}`,
        label: t('initiateBtsProcess.form.label.requestingCustomer'),
        name: NEW_SITE_ACQUISITION_FORM.requestingCustomerName,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.requestingCustomer'),
      },
      {
        type: 'input',
        id: `${3}`,
        label: t('initiateBtsProcess.form.label.customerCommercialAgreement'),
        name: NEW_SITE_ACQUISITION_FORM.agreementNumber,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.customerCommercialAgreement'),
      },
      {
        type: 'select',
        id: `${4}`,
        label: t('initiateBtsProcess.form.label.projectName'),
        name: NEW_SITE_ACQUISITION_FORM.project,
        options: formDetailsOptions?.projectNamesList,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.projectName'),
        selectTheValueAsKey: true,
      },
      {
        type: 'input',
        id: `${5}`,
        label: t('initiateBtsProcess.form.label.bssRequestNumber'),
        name: NEW_SITE_ACQUISITION_FORM.bssRequestNumber,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.bssRequestNumber'),
      },
      {
        type: 'radio',
        id: `${6}`,
        label: t('initiateBtsProcess.form.label.vipRequest'),
        name: NEW_SITE_ACQUISITION_FORM.vipRequest,
        options: VIP_REQUEST_OPTIONS,
        selectTheValueAsKey: true,
      },
      {
        type: 'date',
        id: `${7}`,
        label: t('initiateBtsProcess.form.label.timestamp'),
        name: NEW_SITE_ACQUISITION_FORM.requestTimestamp,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.timestamp'),
        picker: 'date',
      },
      {
        type: 'select',
        id: `${9}`,
        label: t('initiateBtsProcess.form.label.region'),
        name: NEW_SITE_ACQUISITION_FORM.region,
        options: formDetailsOptions?.regionsList,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.region'),
        resetFormFields: onRegionChange,
        selectTheValueAsKey: true,
      },
      {
        type: 'select',
        id: `${11}`,
        label: t('initiateBtsProcess.form.label.district'),
        name: NEW_SITE_ACQUISITION_FORM.district,
        disabled: !districtsOptions,
        options: districtsOptions,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.district'),
        selectTheValueAsKey: true,
      },
      {
        type: 'input',
        id: `${12}`,
        label: t('initiateBtsProcess.form.label.geoType'),
        name: NEW_SITE_ACQUISITION_FORM.geoTypeLocationCluster,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.geoType'),
      },
      {
        type: 'inputNumber',
        id: `${15}`,
        label: t('initiateBtsProcess.form.label.searchRingRadius'),
        name: NEW_SITE_ACQUISITION_FORM.radius,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.searchRingRadius'),
      },
      {
        type: 'inputNumber',
        id: `${17}`,
        label: t('initiateBtsProcess.form.label.nominalLatitude'),
        name: NEW_SITE_ACQUISITION_FORM.latitude,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.nominalLatitude'),
      },
      {
        type: 'inputNumber',
        id: `${16}`,
        label: t('initiateBtsProcess.form.label.nominalLongitude'),
        name: NEW_SITE_ACQUISITION_FORM.longitude,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.nominalLongitude'),
      },
      {
        type: 'inputNumber',
        id: `${19}`,
        label: t('initiateBtsProcess.form.label.dpTowerHeight'),
        name: NEW_SITE_ACQUISITION_FORM.dpProposedTowerHeight,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.dpTowerHeight'),
      },
      {
        type: 'inputNumber',
        id: `${20}`,
        label: t('initiateBtsProcess.form.label.numberOfCollocation'),
        name: NEW_SITE_ACQUISITION_FORM.expectedCollocationNumber,
        placeholder: t('initiateBtsProcess.form.placeholder.numberOfCollocation'),
      },
      {
        type: 'inputNumber',
        id: `${21}`,
        label: t('initiateBtsProcess.form.label.potentialCustomer'),
        name: NEW_SITE_ACQUISITION_FORM.potentialCustomer,
        placeholder: t('initiateBtsProcess.form.placeholder.potentialCustomer'),
        rules: FORM_ITEM_NOT_REQUIRED_NUMBER_RULE,
      },
      {
        type: 'inputNumber',
        id: `${22}`,
        label: t('initiateBtsProcess.form.label.expectedPackages'),
        name: NEW_SITE_ACQUISITION_FORM.expectedPackages,
        placeholder: t('initiateBtsProcess.form.placeholder.expectedPackages'),
      },
      {
        type: 'date',
        id: `${23}`,
        label: t('initiateBtsProcess.form.label.forecastDate'),
        name: NEW_SITE_ACQUISITION_FORM.forecastDate,
        picker: 'date',
        placeholder: t('initiateBtsProcess.form.placeholder.forecastDate'),
      },
      {
        type: 'select',
        id: `${24}`,
        label: t('initiateBtsProcess.form.label.requestPriority'),
        name: NEW_SITE_ACQUISITION_FORM.priority,
        options: formDetailsOptions?.requestPriorityList,
        rules: FORM_ITEM_REQUIRED_RULE,
        placeholder: t('initiateBtsProcess.form.placeholder.requestPriority'),
        selectTheValueAsKey: true,
      },
      {
        id: `${25}`,
        label: t('initiateBtsProcess.form.label.attachment'),
        name: NEW_SITE_ACQUISITION_FORM.attachments,
        type: 'file',
        getValueFromEvent: normFile,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        rules: FORM_ITEM_FILE_RULES_MAX,
        valuePropName: 'fileList',
        withDivider: true,
        labelSize: 'large',
      },
    ],
    [
      districtsOptions,
      formDetailsOptions?.projectNamesList,
      formDetailsOptions?.regionsList,
      formDetailsOptions?.requestPriorityList,
      onRegionChange,
      t,
    ]
  );

  return (
    <Fragment>
      <StaticFormTemplate t={t} getItems={getItems} description={description} form={form} />
    </Fragment>
  );
};

export const NewSiteAcquisitionProcessFormFields = memo(
  NewSiteAcquisitionProcessFormFieldsComponent
);

interface Props {
  description?: string;
  districtsOptions?: SelectOptions;
  form?: FormInstance;
  formDetailsOptions?: LeaseSitesFormDetails;
  onRegionChange: () => void;
  t: TType;
}

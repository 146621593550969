import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const subCategoryCard = (state: RootState): RootState['subCategoryCard'] =>
  state.subCategoryCard;

export const subCategoryCardSelector = createSelector(
  subCategoryCard,
  ({ error, status, data }) => ({
    error,
    status,
    data,
  })
);

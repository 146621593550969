import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Card, Typography } from 'antd';

import moment from 'moment';
import { TType, useTranslate } from 'translations';
import { StatusTag, TagColor, TextStyled } from 'ui';
import { StyledTable } from 'ui/components/TeamsChannelFilesList/TeamsChannelFilesList.styled';
import { RequestStatus } from 'types';
import { postSubmitFormReset, useDispatch } from 'store';
import { ExclamationCircle } from 'assets';
import { FilterContextType } from 'ui/components/Filter/Filter.types';
import { LeavesListItem } from 'store/leavesList/leavesListTypes';
import {
  useUsersInfo,
  LIST_VIEW_PAGE_SIZE,
  getLeaveStatusColor,
  getLeaveStatusText,
} from 'routes/Calendar';
import { ColumnType, ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { DelegateInfo } from './DelegateInfo';
import { LeaveListWrapper, StyledData } from './ListView.styled';

const { Text } = Typography;

interface ColumnItem {
  title: string;
  dataIndex: string;
  key: string;
  width: string;
}

interface ListViewProps {
  setCurrentLeaveId: (leaveId: string) => void;
  filterValues: FilterContextType['filterValues'];
  currentPage: number;
  setCurrentPage: (pageNumber: number) => void;
  dispatchGetLeaves?: () => void;
  leavesListData?: LeavesListItem[];
  leavesListStatus: string;
  totalResult?: number;
  isMyTeamLeaves?: boolean;
  isMyLeaveListLoading?: boolean;
}

const getColumns = (t: TType, isMyTeamLeaves?: boolean) => {
  const columns: ColumnsType<ColumnType<ColumnItem>> = [
    {
      title: <Text strong>{t('leaves.listView.column.teamMember')}</Text>,
      dataIndex: 'employeeName',
      key: 'employeeName',
      width: '15%',
    },
    {
      title: <Text strong>{t('leaves.listView.column.leaveType')}</Text>,
      dataIndex: 'absenceTypeName',
      key: 'absenceTypeName',
      width: '15%',
    },
    {
      title: <Text strong>{t('leaves.listView.column.status')}</Text>,
      dataIndex: 'status',
      key: 'status',
      width: '10%',
    },
    {
      title: <Text strong>{t('leaves.listView.column.startDate')}</Text>,
      dataIndex: 'startDate',
      key: 'startDate',
      width: '10%',
    },
    {
      title: <Text strong>{t('leaves.listView.column.endDate')}</Text>,
      dataIndex: 'endDate',
      key: 'endDate',
      width: '10%',
    },
    {
      title: <Text strong>{t('leaves.listView.column.absenceDuration')}</Text>,
      dataIndex: 'duration',
      key: 'duration',
      width: '10%',
    },
    {
      title: <Text strong>{t('leaves.listView.column.delegate')}</Text>,
      dataIndex: 'adOid',
      key: 'adOid',
      width: '15%',
    },
    {
      title: <Text strong>{t('leaves.listView.column.createdOn')}</Text>,
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: '10%',
    },
    {
      dataIndex: 'isManagerApproval',
      key: 'isManagerApproval',
      className: 'action-icon',
    },
  ];

  if (!isMyTeamLeaves) {
    delete columns[0];
    delete columns[columns.length - 1];
  }

  return columns;
};

export const ListView: FC<ListViewProps> = ({
  setCurrentLeaveId,
  filterValues,
  currentPage,
  setCurrentPage,
  dispatchGetLeaves,
  leavesListData,
  leavesListStatus,
  totalResult,
  isMyTeamLeaves,
  isMyLeaveListLoading,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const { isUsersInfoLoading } = useUsersInfo(leavesListData?.map(({ adOid }) => adOid));

  const isLoading = useMemo(
    () => leavesListStatus === RequestStatus.pending || isUsersInfoLoading || isMyLeaveListLoading,
    [isUsersInfoLoading, leavesListStatus, isMyLeaveListLoading]
  );

  const columns = useMemo(() => getColumns(t, isMyTeamLeaves), [t, isMyTeamLeaves]);

  useEffect(() => {
    dispatch(postSubmitFormReset());
  }, [dispatch]);

  const listViewData = useMemo(
    () =>
      leavesListData?.map(
        ({
          id,
          adOid,
          absenceTypeName,
          absenceDispStatus,
          createdDate,
          startDate,
          endDate,
          duration,
          employeeName,
          awaitingLineManagerApproval,
        }) => {
          return {
            key: id,
            employeeName: <DelegateInfo adOid={adOid} employeeName={employeeName} />,
            absenceTypeName: <TextStyled underline>{absenceTypeName}</TextStyled>,
            status: (
              <StatusTag color={getLeaveStatusColor(absenceDispStatus) as TagColor}>
                {getLeaveStatusText(t)[absenceDispStatus]}
              </StatusTag>
            ),
            createdDate: <StyledData>{moment(createdDate).format('DD/MM/YYYY')}</StyledData>,
            duration: duration,
            startDate: <StyledData>{moment(startDate).format('DD/MM/YYYY')} </StyledData>,
            endDate: <StyledData>{moment(endDate).format('DD/MM/YYYY')}</StyledData>,
            adOid: <DelegateInfo adOid={adOid} />,
            ...(awaitingLineManagerApproval && { isManagerApproval: <ExclamationCircle /> }),
          };
        }
      ),
    [leavesListData, t]
  );

  const onTableChange = (pagination: TablePaginationConfig) => {
    const { current } = pagination;

    setCurrentPage(current ? current - 1 : 0);
  };

  const onRow = useCallback(
    (record) => {
      return {
        onClick: () => setCurrentLeaveId(`${record.key}`),
      };
    },
    [setCurrentLeaveId]
  );

  useEffect(() => {
    if (dispatchGetLeaves) {
      dispatchGetLeaves();
    }
  }, [currentPage, dispatchGetLeaves, filterValues]);

  return (
    <LeaveListWrapper>
      <Card>
        <StyledTable
          columns={columns}
          dataSource={listViewData}
          scroll={{
            scrollToFirstRowOnChange: true,
            x: true,
          }}
          pagination={{
            total: totalResult,
            showSizeChanger: false,
            defaultCurrent: currentPage + 1,
            pageSize: LIST_VIEW_PAGE_SIZE,
            current: currentPage + 1,
          }}
          loading={isLoading}
          onChange={onTableChange}
          onRow={onRow}
        />
      </Card>
    </LeaveListWrapper>
  );
};

import { UsersTableData } from 'types';
import { AxiosRequestConfig } from 'axios';
import { Core, PROCESS_USERS } from 'api/Core';

interface GetUsersTable {
  (hcmId: string, params?: AxiosRequestConfig['params']): Promise<{ data: UsersTableData }>;
}

class UsersTable extends Core {
  getUsersTable: GetUsersTable = async (hcmId, params) =>
    this.get(PROCESS_USERS(hcmId), { params });
}

export const usersTable = new UsersTable();

import React, { FC } from 'react';
import { Filter } from 'ui';
import { useHistory } from 'react-router-dom';
import { useTranslate } from 'translations';
import { LeaveStatus, LeavesTypesData, TeamMembers } from 'types';
import {
  FilterContextType,
  FilterItemProps,
  FilterOption,
} from 'ui/components/Filter/Filter.types';
import { myTeamsFilterIndex, myTeamsListFilterIndex } from 'routes/Calendar';

const leavesFilterItems = {
  leaveType: 'absenceTypeId',
  leaveStatus: 'status',
  approvalStatusCd: 'approvalStatusCd',
  startDate: 'startDate',
  endDate: 'endDate',
  createdOn: 'createdOn',
  teamMember: 'teamMember',
};

interface LeavesFilterProps {
  setFilterValues: (values: FilterContextType['filterValues']) => void;
  leavesTypes?: LeavesTypesData[];
  teamMembers?: TeamMembers[];
  isLeavesTypesLoading: boolean;
  filterValues: FilterContextType['filterValues'];
  isMyTeamLeaves?: boolean;
  isListView?: boolean;
  viewType?: string;
  teamMembersOptions?: FilterOption[];
}

export const LeavesFilter: FC<LeavesFilterProps> = ({
  setFilterValues,
  leavesTypes,
  isLeavesTypesLoading,
  filterValues,
  isMyTeamLeaves,
  isListView,
  viewType,
  teamMembersOptions,
}) => {
  const history = useHistory();
  const { t } = useTranslate();

  const leaveTypeOptions = leavesTypes?.map(({ absenceName, id }) => ({
    label: absenceName,
    value: id,
    text: absenceName,
  }));

  const leaveStatusOptions = [
    {
      label: t('leaves.status.awaitingApproval'),
      value: LeaveStatus.awaitingApproval,
      text: t('leaves.status.awaitingApproval'),
    },
    {
      label: t('leaves.status.canceled'),
      value: LeaveStatus.canceled,
      text: t('leaves.status.canceled'),
    },
    {
      label: t('leaves.status.cancelationInProgress'),
      value: LeaveStatus.cancelationInProgress,
      text: t('leaves.status.cancelationInProgress'),
    },
    {
      label: t('leaves.status.completed'),
      value: LeaveStatus.completed,
      text: t('leaves.status.completed'),
    },
    {
      label: t('leaves.status.denied'),
      value: LeaveStatus.denied,
      text: t('leaves.status.denied'),
    },
    {
      label: t('leaves.status.inProgress'),
      value: LeaveStatus.inProgress,
      text: t('leaves.status.inProgress'),
    },
    {
      label: t('leaves.status.saved'),
      value: LeaveStatus.planned,
      text: t('leaves.status.saved'),
    },
    {
      label: t('leaves.status.scheduled'),
      value: LeaveStatus.scheduled,
      text: t('leaves.status.scheduled'),
    },
    {
      label: t('leaves.status.withdrawn'),
      value: LeaveStatus.withdrawn,
      text: t('leaves.status.withdrawn'),
    },
  ];

  const getFilterItems = () => {
    const filterItems: FilterItemProps[] = [
      {
        type: 'rangePicker',
        name: leavesFilterItems.startDate,
        placeholder: [t('leaves.filter.startDateFrom'), t('leaves.filter.to')],
      },
      {
        type: 'select',
        placeholder: t('leaves.filter.leaveType'),
        name: leavesFilterItems.leaveType,
        options: leaveTypeOptions,
      },
      {
        type: 'rangePicker',
        name: leavesFilterItems.endDate,
        placeholder: [t('leaves.filter.endDateFrom'), t('leaves.filter.to')],
      },
      {
        type: 'select',
        placeholder: 'Status',
        name: leavesFilterItems.leaveStatus,
        options: leaveStatusOptions,
      },
      {
        type: 'rangePicker',
        name: leavesFilterItems.createdOn,
        placeholder: [t('leaves.filter.createdOnFrom'), t('leaves.filter.to')],
      },
    ];

    if (isMyTeamLeaves && !isListView) {
      const mappedResult = myTeamsListFilterIndex.map((i) => filterItems[i]);
      return mappedResult;
    }

    if (isMyTeamLeaves && isListView) {
      const leaveTeamMemberOption: FilterItemProps = {
        type: 'multiSelect',
        placeholder: t('leaves.filter.teamMember'),
        name: leavesFilterItems.teamMember,
        options: teamMembersOptions,
      };
      filterItems.push(leaveTeamMemberOption);
      const mappedResult = myTeamsFilterIndex.map((i) => filterItems[i]);
      return mappedResult;
    }

    return filterItems;
  };

  return (
    <Filter
      items={getFilterItems()}
      isLoading={isLeavesTypesLoading}
      t={t}
      history={history}
      onFilterCallback={setFilterValues}
      dateFormat={'DD/MM/YYYY'}
      enableQueryParams={false}
      initialFilterValues={filterValues}
      viewType={viewType}
    />
  );
};

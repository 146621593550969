import { Typography, Col } from 'antd';
import styled from 'styled-components';
import { space, typography } from 'ui/elements';

const { Text } = Typography;

export const StyledTitle = styled(Text)`
  font-size: 24px;
  padding-left: 24px;
`;

export const StyledTitleCol = styled(Col)`
  margin-top: 23px;
  padding: 0px;
`;

export const StyledGroupCol = styled(Col)`
  margin-bottom: ${space.slim};
`;

export const StyledNoFoundText = styled.div`
  font-size: ${typography.size.hecto};
  text-align: center;
`;

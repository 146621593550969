import styled from 'styled-components';
import { Collapse, Menu } from 'antd';
import { colors } from 'ui/elements';

export const SideBar = styled.div`
  min-height: calc(100vh - 56px);
  background: white;
  display: flex;
  flex-direction: column;
`;

export const StyledMenu = styled(Menu)`
  &.ant-menu-vertical {
    padding: 0;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  &.ant-menu-item.ant-menu-item-only-child,
  &.ant-menu-item.sidebar-menu-item {
    margin: 0;
    margin-bottom: 0;
    padding: 0 24px;

    &.ant-menu-item.ant-menu-item-selected,
    &.ant-menu-item.ant-menu-item-active {
      background-color: #f6f7f9;
    }
  }
`;

export const StyledCollapse = styled(Collapse).attrs({
  ghost: true,
})`
  padding: 0px;

  .ant-collapse-header {
    margin-left: -20px;
    padding: 0px;
  }

  .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse-arrow {
    padding-left: 29px;
  }
  .ant-collapse-content.ant-collapse-content-active {
    background-color: #fafbfc;
  }
  .ant-collapse-item.ant-collapse-item-active {
    &:not(.ant-collapse-content.ant-collapse-content-active) {
      .ant-collapse-header {
        font-weight: 600;
        background-color: #f3f5f7;
      }
    }
  }
`;

export const StyledCategoryRow = styled.div`
  font-size: 16px;
  padding-bottom: 0px;
  padding-left: 30px;
  color: ${colors.primaryColor};
`;
export const StyledPlanMenuItem = styled(Menu.Item)`
  &.ant-menu-item.ant-menu-item-only-child,
  &.ant-menu-item.sidebar-menu-item {
    margin: 0;
    margin-bottom: 0;
    padding: 3px 49px;
    height: auto;
    background-color: #fafbfc;

    &.ant-menu-item.ant-menu-item-selected,
    &.ant-menu-item.ant-menu-item-active {
      background-color: #f6f7f9;
      border-left: 4px solid ${colors.primaryColor};
    }
  }
`;

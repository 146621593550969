import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  GetTeamsFail,
  GetTeamsSuccess,
  GetTeamsStart,
  getTeamsStart,
  getTeamsSuccess,
  getTeamsFail,
} from './teamsSlice';

export const getTeamsEpic: Epic<
  GetTeamsStart,
  GetTeamsSuccess | GetTeamsFail,
  RootState,
  typeof API
> = (action$, _, { teams }) =>
  action$.pipe(
    filter(getTeamsStart.match),
    exhaustMap(() =>
      from(teams.getTeams()).pipe(
        map(({ data }) => getTeamsSuccess(data)),
        catchError((error) => of(getTeamsFail(error?.response?.data)))
      )
    )
  );

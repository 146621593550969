import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row, Space, Tabs, Typography, Form, Select, Button, Popover } from 'antd';
import { VideoCameraOutlined, PhoneOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { RefSelectProps } from 'antd/lib/select';

import { Chat, UserPopover } from 'ui';
import { useTranslate } from 'translations';
import {
  chatsData,
  profileData,
  useDispatch,
  useSelector,
  getUsersStart,
  usersData,
  createGroupChatStart,
  getChatMessagesStart,
  createGroupChatReset,
  addChatMemberStart,
  addChatMemberReset,
} from 'store';
import { getMembersTitleList } from 'utils';
import { RequestStatus } from 'types';
import { TabPaneContent, UserContainer } from '../MSTeams.styled';
import { useMSTeamsContext } from '../MSTeamsProvider';
import { MSTeamsConfirm } from '../MSTeamsConfirm';
import { MSTeamsUserAvatar } from '../MSTeamsUserAvatar';
import { StyledButton, StyledPopoverTitle, popoverStyles } from './MSTeamsChatTabs.styled';
import { StyledChatGroupAvatar } from './MSTeamsChatSidebar/MSTeamsChatSidebar.styled';

const { Text } = Typography;
const { TabPane } = Tabs;

export const MSTeamsChatTabs = () => {
  const [form] = Form.useForm();
  const { t } = useTranslate();
  const { currentChatId, setCurrentChatId } = useMSTeamsContext();
  const { data: userList, status } = useSelector(usersData);
  const { data: userData } = useSelector(profileData);
  const { data, createGroupChatStatus, addChatMemberStatus } = useSelector(chatsData);
  const dispatch = useDispatch();
  const [pickedUsers, setPickedUsers] = useState<string[]>([]);
  const [isInvitePopoverVisible, setIsInvitePopoverVisible] = useState(false);
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();
  const multiSelect = useRef<RefSelectProps | null>(null);

  const { chats } = data;
  const currentChat = chats?.find((chat) => chat.id === currentChatId);
  const chatMember = currentChat?.members.find((item) => item.userId !== userData?.adOid);

  const searchUsers = useCallback(
    (searchText: string) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        dispatch(
          getUsersStart({
            params: {
              search: searchText,
            },
          })
        );
      }, 350);
    },
    [dispatch]
  );

  const handleSearch = useCallback(
    (searchText: string) => {
      if (searchText) {
        searchUsers(searchText);
      }
    },
    [searchUsers]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (_: string[], option: any) => {
    if (!option.length) {
      setPickedUsers([]);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setPickedUsers(option.map((item: any) => item.key));

    multiSelect.current?.blur();
  };

  const onConfirm = () => {
    window.open('msteams:');
  };

  const chatMemberList = currentChat && getMembersTitleList(currentChat.members);

  const onFinish = useCallback(
    (values: { name: string[] }) => {
      if (!currentChat?.members?.length) {
        return;
      }

      const memberNames = currentChat.members.map((member) => member.displayName);
      const memberIds = currentChat.members.map((member) => member.userId);
      const pickedIds = pickedUsers.map((item) => item.split(', ')[0]);
      const pickedEmails = pickedUsers.map((item) => item.split(', ')[1]);

      if (currentChat.members.length > 2) {
        pickedIds.forEach((item) => {
          dispatch(
            addChatMemberStart({
              chatId: currentChatId,
              params: {
                userId: item,
              },
            })
          );
        });

        return;
      }

      dispatch(
        createGroupChatStart({
          params: {
            topic: [...memberNames, ...values.name].join(', '),
            userIds: [...memberIds, ...pickedIds].join(','),
          },
          chatMembers: [
            ...currentChat.members,
            ...values.name.map((item, i) => ({
              displayName: item,
              userId: pickedIds[i],
              email: pickedEmails[i],
            })),
          ],
        })
      );
    },
    [pickedUsers, dispatch, currentChat?.members, currentChatId]
  );

  const toggleInvitePopoverVisibility = useCallback(
    () => setIsInvitePopoverVisible(!isInvitePopoverVisible),
    [isInvitePopoverVisible]
  );

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      setIsInvitePopoverVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const selectNewChat = useCallback(
    (chatId) => {
      setCurrentChatId(chatId);
      dispatch(getChatMessagesStart(chatId));
    },
    [setCurrentChatId, dispatch]
  );

  useEffect(() => {
    if (chats && createGroupChatStatus === RequestStatus.resolved) {
      form.resetFields();
      dispatch(createGroupChatReset());

      setIsInvitePopoverVisible(false);
      selectNewChat(chats[0]?.id);
    }
  }, [dispatch, form, selectNewChat, chats, createGroupChatStatus]);

  useEffect(() => {
    if (addChatMemberStatus === RequestStatus.resolved) {
      form.resetFields();
      dispatch(addChatMemberReset());

      setIsInvitePopoverVisible(false);
    }
  }, [dispatch, form, addChatMemberStatus]);

  return (
    <Tabs
      tabBarExtraContent={{
        left: (
          <UserContainer>
            <Space direction="horizontal" size={16}>
              {currentChat && currentChat.members.length > 2 ? (
                <StyledChatGroupAvatar
                  icon={<UsergroupAddOutlined />}
                  size={32}
                  id={currentChatId}
                />
              ) : (
                <UserPopover currentID={chatMember?.userId}>
                  <MSTeamsUserAvatar
                    id={chatMember?.userId}
                    name={chatMember?.displayName}
                    size={32}
                    statusSize={10}
                  />
                </UserPopover>
              )}
              <Text strong>
                {currentChat && currentChat.members.length > 2
                  ? currentChat.topic || chatMemberList
                  : chatMember?.displayName}
              </Text>
            </Space>
          </UserContainer>
        ),
        right: (
          <Row gutter={[2, 0]}>
            <Col>
              <StyledButton type="primary">
                <MSTeamsConfirm onConfirm={onConfirm}>
                  <VideoCameraOutlined />
                </MSTeamsConfirm>
              </StyledButton>
            </Col>
            <Col>
              <MSTeamsConfirm onConfirm={onConfirm}>
                <StyledButton type="primary">
                  <PhoneOutlined />
                </StyledButton>
              </MSTeamsConfirm>
            </Col>
            <Col>
              <Popover
                overlayStyle={popoverStyles}
                placement="bottomRight"
                visible={isInvitePopoverVisible}
                content={
                  <>
                    <StyledPopoverTitle>{t('msteams.title.addUser')}</StyledPopoverTitle>
                    <Form onFinish={onFinish} name="inviteUserForm" form={form}>
                      <Form.Item name="name">
                        <Select
                          mode="multiple"
                          placeholder={t('msteams.button.addUserPlaceholder')}
                          defaultActiveFirstOption={false}
                          loading={status === RequestStatus.pending}
                          onSearch={handleSearch}
                          onChange={handleChange}
                          ref={multiSelect}
                          notFoundContent={t('dropdown.noMatching')}
                          showSearch
                        >
                          {!!userList?.users?.length &&
                            userList.users.map(({ id, displayName, mail }) =>
                              id ? (
                                <Select.Option value={displayName || ''} key={`${id}, ${mail}`}>
                                  {displayName}
                                </Select.Option>
                              ) : null
                            )}
                        </Select>
                      </Form.Item>
                      <Row justify="end" gutter={[8, 0]}>
                        <Col>
                          <Button onClick={toggleInvitePopoverVisibility}>{t('cancel')}</Button>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={
                              createGroupChatStatus === RequestStatus.pending ||
                              addChatMemberStatus === RequestStatus.pending
                            }
                            disabled={
                              createGroupChatStatus === RequestStatus.pending ||
                              addChatMemberStatus === RequestStatus.pending
                            }
                          >
                            {t('msteams.button.addUser')}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </>
                }
              >
                <StyledButton type="text" onClick={toggleInvitePopoverVisibility}>
                  <UsergroupAddOutlined />
                </StyledButton>
              </Popover>
            </Col>
          </Row>
        ),
      }}
    >
      <TabPane tab={t('msteams.tab.chat')} key="1">
        <TabPaneContent>
          <Chat />
        </TabPaneContent>
      </TabPane>
    </Tabs>
  );
};

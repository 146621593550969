import React, { memo, FC, useCallback, useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, notification, Space, Empty, Popconfirm } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { withTranslate } from 'translations';
import { useJiraFormFields, useNewRequestFormData, useNewRequestMutation } from 'api';

import { FullWidthSpin } from 'ui/elements';
import { UploadFile } from 'antd/lib/upload/interface';
import { submitAttachmentForm } from 'ui/publicFunction';
import { useTasksContext } from 'routes/Tasks/TasksProvider';
import {
  JiraNewRequestForm,
  ServiceNewRequestForm,
  ServiceNewRequestHeader,
  SERVICE_NEW_REQUEST_FORM,
} from '../../organisms';
import { StyledSubmitButton, Content, Footer, Wrapper } from './ServiceNewRequest.styled';
import { Props, connector } from './connector';

export interface AttachmentFile {
  [key: string]: UploadFile[];
}

export const attachmentsFieldsId = [
  'ATTACHMENT',
  'attachment',
  'iban',
  'clearance',
  'tawalIdFile',
  'carHireForm',
  'idOrIqama',
  'driverLicense',
];

export const ServiceNewRequestComponent: FC<Props> = ({
  history: { goBack },
  history,
  match: {
    params: { processDefinitionKey, isDynamicForm, jiraServiceDeskId, jiraRequestTypeId },
  },
  t,
  profileData,
}) => {
  const { isSubmitLoading, isSubmitDisable, isFormValuesChanged } = useTasksContext();

  const isDynamic = isDynamicForm === 'true';
  const isJira = !!(jiraServiceDeskId && jiraRequestTypeId);

  const formInfo = useNewRequestFormData(
    processDefinitionKey,
    profileData?.adOid,
    isDynamic,
    isJira
  );
  const jiraForm = useJiraFormFields(jiraServiceDeskId, jiraRequestTypeId);
  const newRequestMutation = useNewRequestMutation(processDefinitionKey);
  const [attachmentData, setAttachmentData] = useState<AttachmentFile[]>([]);

  const onFormSubmit = useCallback(
    (variables) => {
      const attachmentsForm: AttachmentFile[] = [];
      newRequestMutation.mutate({ variables });
      const currentFormFieldsName = Object.keys(variables);

      const currentAttachmentId = currentFormFieldsName.filter((variables: string) => {
        return attachmentsFieldsId.includes(variables);
      });

      if (currentAttachmentId && variables) {
        currentAttachmentId.forEach((fieldId: string) => {
          const attachmentValue = variables[fieldId];
          if (attachmentValue) {
            return attachmentsForm.push({ [fieldId]: attachmentValue });
          }
        });
      }
      if (attachmentsForm) {
        setAttachmentData(attachmentsForm);
      }
    },
    [newRequestMutation]
  );

  useEffect(() => {
    if (newRequestMutation.isError) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [newRequestMutation.isError, t]);

  useEffect(() => {
    newRequestMutation.data &&
      submitAttachmentForm(
        newRequestMutation.isSuccess,
        newRequestMutation.data,
        history,
        undefined,
        attachmentData
      );
  }, [attachmentData, history, newRequestMutation.data, newRequestMutation.isSuccess]);

  const formData = formInfo.data || null;
  const jiraFormData = jiraForm.data || null;
  const isLoading = useMemo(() => {
    return (
      formInfo.isLoading || newRequestMutation.isLoading || formInfo.isError || isSubmitLoading
    );
  }, [formInfo, newRequestMutation, isSubmitLoading]);

  return (
    <Wrapper>
      <ServiceNewRequestHeader />
      <Content>
        {formInfo.isError ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
        ) : formInfo.isLoading || jiraForm.isLoading ? (
          <FullWidthSpin />
        ) : isJira ? (
          profileData?.adOid && (
            <JiraNewRequestForm
              t={t}
              processDefinitionKey={processDefinitionKey}
              loading={jiraForm.isLoading}
              data={jiraFormData}
              isError={jiraForm.isError}
              deskId={Number(jiraServiceDeskId)}
              typeId={Number(jiraRequestTypeId)}
            />
          )
        ) : (
          <ServiceNewRequestForm
            data={formData}
            t={t}
            processDefinitionKey={processDefinitionKey}
            onFormSubmit={onFormSubmit}
            loading={newRequestMutation.isLoading}
            isDynamic={isDynamic}
          />
        )}
      </Content>
      <Footer>
        <Space>
          {isFormValuesChanged ? (
            <Popconfirm
              title="Are you sure you want to cancel all changes?"
              onConfirm={goBack}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={isLoading}>{t('back')}</Button>
            </Popconfirm>
          ) : (
            <Button onClick={goBack} disabled={isLoading}>
              {t('back')}
            </Button>
          )}
          <StyledSubmitButton
            htmlType="submit"
            form={SERVICE_NEW_REQUEST_FORM}
            disabled={isLoading || isSubmitDisable}
          >
            {t('submit')} <ArrowRightOutlined />
          </StyledSubmitButton>
        </Space>
      </Footer>
    </Wrapper>
  );
};

export const ServiceNewRequest = memo(
  withRouter(withTranslate(connector(ServiceNewRequestComponent)))
);

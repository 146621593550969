import moment from 'moment';
import { LeaveStatus, PublicHolidays } from 'types';
import { Values } from 'ui/components/TaskList/TaskSteps/TaskSteps.types';
import { TType } from 'translations';
import { FilterContextType } from 'ui/components/Filter/Filter.types';
import { LeavesListItem } from 'store/leavesList/leavesListTypes';
import { UploadFile } from 'antd/lib/upload/interface';

export enum ColorCode {
  orange = 'orange',
  blue = 'blue',
  red = 'red',
  jungle = 'jungle',
  green = 'green',
  gray = 'gray',
  lightOrange = 'lightOrange',
  accentOrange = 'accentOrange',
  pink = 'pink',
}

const getHexColor = (color: ColorCode) =>
  ({
    [ColorCode.orange]: '#D7A461',
    [ColorCode.blue]: '#2A5BC8',
    [ColorCode.red]: '#FF0000',
    [ColorCode.jungle]: '#00A5B5',
    [ColorCode.green]: '#00AC69',
    [ColorCode.gray]: '#808080',
    [ColorCode.lightOrange]: '#FF5A14',
    [ColorCode.accentOrange]: '#FC4C02',
    [ColorCode.pink]: '#FFEAF3',
  }[color]);

export const getLeaveStatusColor = (status: LeaveStatus) =>
  ({
    [LeaveStatus.planned]: 'orange',
    [LeaveStatus.submitted]: 'orange',
    [LeaveStatus.awaitingApproval]: 'accentOrange',
    [LeaveStatus.rejected]: 'red',
    [LeaveStatus.denied]: 'red',
    [LeaveStatus.scheduled]: 'jungle',
    [LeaveStatus.inProgress]: 'blue',
    [LeaveStatus.completed]: 'green',
    [LeaveStatus.withdrawn]: 'gray',
    [LeaveStatus.awaitingWithdrawalApproval]: 'orange',
    [LeaveStatus.approved]: 'green',
    [LeaveStatus.canceled]: 'red',
    [LeaveStatus.cancelationInProgress]: 'red',
    [LeaveStatus.rescheduleInProgress]: 'pink',
  }[status]);

export const convertColourNameToHex = (color: ColorCode) => {
  const hexColor = getHexColor(color);
  return hexColor;
};

export const getLeaveStatusText = (t: TType) => ({
  [LeaveStatus.planned]: t('leaves.status.saved'),
  [LeaveStatus.submitted]: t('leaves.status.submitted'),
  [LeaveStatus.awaitingApproval]: t('leaves.status.awaitingApproval'),
  [LeaveStatus.rejected]: t('leaves.status.rejected'),
  [LeaveStatus.denied]: t('leaves.status.denied'),
  [LeaveStatus.scheduled]: t('leaves.status.scheduled'),
  [LeaveStatus.inProgress]: t('leaves.status.inProgress'),
  [LeaveStatus.completed]: t('leaves.status.completed'),
  [LeaveStatus.withdrawn]: t('leaves.status.withdrawn'),
  [LeaveStatus.awaitingWithdrawalApproval]: t('leaves.status.awaitingWithdrawalApproval'),
  [LeaveStatus.approved]: t('leaves.status.approved'),
  [LeaveStatus.canceled]: t('leaves.status.canceled'),
  [LeaveStatus.cancelationInProgress]: t('leaves.status.cancelationInProgress'),
  [LeaveStatus.rescheduleInProgress]: t('leaves.status.rescheduleInProgress'),
});
// const JiraValues = {
//   fieldId: 'fieldId',
//   fieldLabel: 'label',
//   fieldValue: 'value',
//   fieldType: 'type',
// };

export const enumerateDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const dates = [];
  while (moment(startDate) <= moment(endDate)) {
    dates.push(startDate);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD') as any;
  }

  return dates;
};

// const filePattern = /file/i;
const jiraFilePattern = /ATTACHMENT/i;
const teamMember = 'teamMember';

export const workday_count = (
  start: moment.Moment,
  end: moment.Moment,
  publicHolidays?: Date[]
) => {
  const sta = start.toDate();
  const en = end.toDate();
  const range = enumerateDaysBetweenDates(sta, en);
  const numOfDays = end.diff(start, 'days') + 1;
  let holidayDay = 0;

  range.forEach((date) => {
    if (
      (publicHolidays && publicHolidays.find((holiday) => holiday === date)) ||
      moment(date).isoWeekday() === 5 ||
      moment(date).isoWeekday() === 6
    ) {
      holidayDay += 1;
    }
  });

  return numOfDays - holidayDay;
};

export const convertToVariables = (values: Values) => {
  return Object.entries(values).reduce((acc, [fieldName, fieldValue]) => {
    acc[fieldName] = {
      value: moment.isMoment(fieldValue) ? moment(fieldValue).format('DD/MM/YYYY') : fieldValue,
    };

    return acc;
  }, {} as Values);
};

export const convertToValues = (values: Values) => {
  return Object.entries(values).reduce((acc, [fieldName, fieldValue]) => {
    acc[fieldName] = moment.isMoment(fieldValue)
      ? moment(fieldValue).format('DD/MM/YYYY')
      : fieldValue;
    return acc;
  }, {} as Values);
};

export const filterEmptyFields = (values: Values) =>
  Object.fromEntries(Object.entries(values).filter(([_, fieldValue]) => fieldValue !== undefined));

export const convertFilterDates = (filterValues: FilterContextType['filterValues']) => {
  return Object.keys(filterValues).reduce((acc, filterKey) => {
    const current = filterValues[filterKey];
    if (Array.isArray(current)) {
      if (filterKey !== teamMember) {
        acc[filterKey] = current.map((date) => moment(date).format('YYYY-MM-DD')).join('_');
      }
    } else {
      acc[filterKey] = current;
    }
    return acc;
  }, {} as FilterContextType['filterValues']);
};

export const checkBetweenTwoDates = (firstDate: Date, secondDate: Date) => {
  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
  );
};

export const getStartAndEndDate = (date: Date) => {
  const firstDay = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DD');
  const lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format('YYYY-MM-DD');

  const fullMonth = firstDay + '_' + lastDay;
  return fullMonth;
};

export const isThereNoLimitBalance = (balance: string) => {
  return balance.split(' ').includes('No');
};

export const directEndDate = (startDate: moment.Moment, leaveBalance: number) => {
  const startDateToDate = startDate.toDate();
  const newDate = startDateToDate.setDate(startDateToDate.getDate() + leaveBalance - 1);

  return moment(newDate);
};

export const handelSelectEndDate = (
  selectedBalance: number,
  leaveBalance: number,
  endDate: moment.Moment
) => {
  const diff = leaveBalance - selectedBalance;
  let directDate;
  if (diff === 0) {
    directDate = endDate;
    return directDate;
  } else {
    const endDateToDate = endDate.toDate();
    const newEndDate = endDateToDate.setDate(endDateToDate.getDate() + diff);
    directDate = moment(newEndDate);
    return directDate;
  }
};

export const convertJiraVariables = (values: Values) => {
  const variables = Object.entries(values);
  const filterVariables = variables.filter(([fieldName]) => {
    return !fieldName.match(jiraFilePattern);
  });
  const fieldVariablesList: { key: string; value: unknown }[] = filterVariables.map(
    ([fieldName, fieldValue]) => {
      return {
        key: fieldName,
        value: fieldValue,
      };
    }
  );

  return fieldVariablesList;
};

export const getJiraAttachments = (values: Values): File[] => {
  const variables = Object.keys(values);
  const attachmentsKeys = variables.filter((key) => {
    return key.match(jiraFilePattern);
  });
  const attachments: File[] = [];
  attachmentsKeys.forEach((key) => {
    if (values[key] && (values[key] as UploadFile[]).length) {
      (values[key] as UploadFile[]).forEach((file) => {
        attachments.push(file.originFileObj as File);
      });
    }
  });
  return attachments;
};

export const concatEventList = (
  leaveList: LeavesListItem[],
  publicHolidaysList: PublicHolidays[]
) => {
  const leaveEventList = leaveList
    .filter(({ absenceDispStatus }) =>
      [
        LeaveStatus.planned,
        LeaveStatus.awaitingApproval,
        LeaveStatus.scheduled,
        LeaveStatus.inProgress,
        LeaveStatus.completed,
      ].includes(absenceDispStatus)
    )
    .map(
      ({
        id,
        absenceTypeName,
        absenceDispStatus,
        startDate,
        endDate,
        employeeName,
        awaitingLineManagerApproval,
      }) => {
        const updateEndDate = moment(endDate).format('YYYY-MM-DD');
        return {
          key: id,
          title: absenceTypeName,
          start: startDate,
          end: updateEndDate,
          status: getLeaveStatusColor(absenceDispStatus),
          isPublicHoliday: false,
          employeeName: employeeName,
          isAwaitingForApproval: awaitingLineManagerApproval,
        };
      }
    );

  const publicHolidayEventList = publicHolidaysList.map(({ eventId, startDate, endDate, name }) => {
    return {
      key: Number(eventId),
      title: name,
      start: startDate.toString(),
      end: endDate.toString(),
      status: ColorCode.gray,
      isPublicHoliday: true,
      employeeName: undefined,
      isAwaitingForApproval: undefined,
    };
  });

  const eventList = leaveEventList.concat(publicHolidayEventList);
  return eventList;
};

export const myTeamsFilterIndex = [0, 5, 2, 4, 3, 1];
export const myTeamsListFilterIndex = [0, 1, 2, 4, 3];

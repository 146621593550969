import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  GetTeamsChannelsFail,
  GetTeamsChannelsSuccess,
  GetTeamsChannelsStart,
  getTeamsChannelsStart,
  getTeamsChannelsSuccess,
  getTeamsChannelsFail,
} from './teamsChannelsSlice';

export const getTeamsChannelsEpic: Epic<
  GetTeamsChannelsStart,
  GetTeamsChannelsSuccess | GetTeamsChannelsFail,
  RootState,
  typeof API
> = (action$, _, { teamsChannels }) =>
  action$.pipe(
    filter(getTeamsChannelsStart.match),
    mergeMap(({ payload }) =>
      from(teamsChannels.getTeamsChannels(payload)).pipe(
        map(({ data }) => getTeamsChannelsSuccess({ teamId: payload, data })),
        catchError((error) =>
          of(getTeamsChannelsFail({ teamId: payload, error: error?.response?.data }))
        )
      )
    )
  );

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus } from 'types';
import { GeneralInfoState } from './generalInfoTypes';
import { MappedGeneralInfoData } from './mapData';

export const emptyGeneralInfoInitialState: GeneralInfoState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetGeneralInfoStart = PayloadAction<string>;
export type GetGeneralInfoSuccess = PayloadAction<MappedGeneralInfoData>;
export type GetGeneralInfoFail = PayloadAction<RequestCommonError>;

const generalInfo = createSlice({
  name: 'generalInfo',
  initialState: emptyGeneralInfoInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getGeneralInfoStart(state, _: GetGeneralInfoStart) {
      state.status = RequestStatus.pending;
    },
    getGeneralInfoSuccess(state, action: GetGeneralInfoSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getGeneralInfoFail(state, action: GetGeneralInfoFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getGeneralInfoStart,
  getGeneralInfoSuccess,
  getGeneralInfoFail,
} = generalInfo.actions;
export default generalInfo.reducer;

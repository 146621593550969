import { Space, Spin } from 'antd';
import { ColProps } from 'antd/lib/col';
import React, { memo, FC, Fragment } from 'react';
import styled from 'styled-components';
import { UserOutlined } from '@ant-design/icons';
import { UserPopover, TeamsUserAvatar, StyledAvatar } from 'ui/components';
import { PARAM_TYPE } from 'ui/components/UserPopover/constants';
import { useErrorNotification } from 'ui/hooks';
import { useTranslate } from 'translations';
import { useGetUserInfoCard } from 'api';
import { TextStrong } from '../text';

const StyledSpace = styled(Space)`
  width: 100%;
  position: relative;

  .ant-space-item {
    width: 100%;
  }
`;

const StyleText = styled.div`
  width: 80%;
  left: 40px;
  position: absolute;
`;

const AVATAR_SIZE = 30;

interface Props {
  managerEmail?: string | null;
  managerHcmid?: string | null;
  managerName?: string;
  unStyledChildren?: boolean;
}

export const UserManagerInfoComponent: FC<ColProps & Props> = ({
  children,
  managerEmail,
  managerHcmid,
  managerName,
  unStyledChildren,
}) => {
  const { t } = useTranslate();
  const value = managerEmail ? managerEmail : managerHcmid;
  const type = managerEmail ? PARAM_TYPE.email : managerHcmid ? PARAM_TYPE.hcmId : undefined;
  const {
    data: userManagerData,
    isLoading: isUserManagerDataLoading,
    isError: isUserManagerError,
  } = useGetUserInfoCard(type, value);

  useErrorNotification(
    {
      isNotification: isUserManagerError,
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    },
    [isUserManagerError]
  );

  return (
    <Fragment>
      <StyledSpace size={10} align={'start'}>
        <UserPopover
          {...(managerEmail ? { currentEmail: managerEmail } : { hcmID: managerHcmid?.toString() })}
        >
          <>
            {isUserManagerDataLoading ? (
              <Spin size={'small'} />
            ) : userManagerData ? (
              <TeamsUserAvatar
                name={userManagerData.displayName}
                id={userManagerData?.adOid}
                statusSize={12}
                size={AVATAR_SIZE}
              />
            ) : managerName ? (
              <TeamsUserAvatar
                email={managerEmail ?? undefined}
                name={managerName}
                statusSize={12}
                size={AVATAR_SIZE}
              />
            ) : (
              <StyledAvatar size={AVATAR_SIZE} icon={<UserOutlined />} />
            )}
          </>
        </UserPopover>
        <StyleText>
          {unStyledChildren ? children : <TextStrong ellipsis>{children || '-'}</TextStrong>}
        </StyleText>
      </StyledSpace>
    </Fragment>
  );
};

export const UserManagerInfo = memo(UserManagerInfoComponent);

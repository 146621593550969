import { Col, Row, Typography } from 'antd';
import React from 'react';
import { TaskStatus } from 'types';
import { StatusTag, TagColor } from 'ui/elements';
import styled from 'styled-components';
import { getStatusColor } from '../../helper';

const { Text } = Typography;

export const CollapseHeaderContainer = styled.div`
  flex: 100%;
  justify-content: space-between;
`;

export const CollapseHeaderTitle = styled(Text)`
  font-size: 18px;
  line-height: 2.2;
`;

export const TaskStepPanelHeader: React.FC<Props> = ({ status, header, disabled }) => {
  return (
    <CollapseHeaderContainer>
      <Row align="middle" gutter={[12, 12]}>
        <Col>
          <CollapseHeaderTitle type={disabled ? 'secondary' : undefined}>
            {header}
          </CollapseHeaderTitle>
        </Col>
        <Col>
          <StatusTag color={getStatusColor(status as TaskStatus) as TagColor}>{status}</StatusTag>
        </Col>
      </Row>
    </CollapseHeaderContainer>
  );
};

interface Props {
  disabled?: boolean;
  header?: string;
  status?: TaskStatus | string;
}

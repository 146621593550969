import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { dashboardRequestCards } from '../DashboardRequestCard';

export function useMyRequestWidget(
  userId: string,
  requestSize: { start: number; pageSize: number }
) {
  return useQuery(
    [QUERIES.myRequestWidget, requestSize, userId],
    async () => (await dashboardRequestCards.getMyRequestCardWidget(userId, requestSize)).data,
    {
      refetchOnWindowFocus: false,
      enabled: !!userId,
    }
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { RequestStatus, RequestCommonError, OneDriveSearchResultData } from 'types';
import { SearchResultState } from './oneDriveSearchType';

export const emptyOneDriveSearchResultState: SearchResultState = {
  error: null,
  status: RequestStatus.idle,
};

export type GetFilesStart = PayloadAction<AxiosRequestConfig>;
export type GetFilesSuccess = PayloadAction<OneDriveSearchResultData>;
export type GetFIlesFail = PayloadAction<RequestCommonError>;

const shearchFile = createSlice({
  name: 'oneDriveShearchFiles',
  initialState: emptyOneDriveSearchResultState,
  reducers: {
    getFilesStart(state, _: GetFilesStart) {
      state.status = RequestStatus.pending;
    },
    getFilesSuccess(state, action: GetFilesSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getFilesFail(state, action: GetFIlesFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getFilesReset(state) {
      state.status = RequestStatus.idle;
      state.error = emptyOneDriveSearchResultState.error;
    },
  },
});

export const { getFilesStart, getFilesSuccess, getFilesFail } = shearchFile.actions;
export default shearchFile.reducer;

import { Col, Row, Space } from 'antd';
import React from 'react';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { TType } from 'translations';
import { RequestSteps, TaskStatus } from 'types';
import { UserPopover } from 'ui/components/UserPopover';
import { TextStyled } from 'ui/elements';
import { StyledReassignUserInfo } from '../TaskStep.styled';
import { ReassignButton } from './ReassignButton';

interface Props {
  t: TType;
  reloadTaskList?: () => void;
  tabStatus: TaskStatus;
  taskInfo?: RequestSteps;
}

const AssigneeInfoComponent: React.FC<Props> = ({ t, reloadTaskList, tabStatus, taskInfo }) => {
  const assigneeId = taskInfo?.taskAssignee?.adoid;
  const taskId = taskInfo?.taskId;
  const groupId = taskInfo?.groupId;
  const fullName =
    taskInfo?.taskAssignee && taskInfo?.taskAssignee.firstName && taskInfo?.taskAssignee?.lastName
      ? [taskInfo?.taskAssignee?.firstName, taskInfo?.taskAssignee?.lastName].join(' ')
      : taskInfo?.taskAssignee?.name;
  const isAssignee = taskInfo?.taskAssignee?.adoid;

  return (
    <React.Fragment>
      {isAssignee ? (
        <Row wrap={false} gutter={[10, 0]} align="middle">
          <Col>
            <UserPopover currentID={assigneeId}>
              <MSTeamsUserAvatar id={assigneeId} name={fullName} size={35} />
            </UserPopover>
          </Col>
          <Col>
            <Space direction="vertical" size={0}>
              <TextStyled size={12} strong>
                <UserPopover currentID={assigneeId}>
                  <StyledReassignUserInfo style={{ whiteSpace: 'nowrap' }}>
                    {fullName}
                  </StyledReassignUserInfo>
                </UserPopover>
              </TextStyled>
            </Space>
          </Col>
          {tabStatus !== TaskStatus.processed && (
            <Col>
              <ReassignButton groupId={groupId} taskId={taskId} reloadTaskList={reloadTaskList} />
            </Col>
          )}
        </Row>
      ) : (
        <Row wrap={false} gutter={[20, 0]} align="middle">
          <Col>
            <TextStyled style={{ whiteSpace: 'nowrap', color: '#EB5757' }}>
              {t('task.notAssigned')}
            </TextStyled>
          </Col>
          {tabStatus !== TaskStatus.processed && (
            <Col flex="100%">
              <ReassignButton groupId={groupId} taskId={taskId} reloadTaskList={reloadTaskList} />
            </Col>
          )}
        </Row>
      )}
    </React.Fragment>
  );
};

export const AssigneeInfo = React.memo(AssigneeInfoComponent);

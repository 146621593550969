import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Empty, Row, Spin } from 'antd';
import { ClockCircleOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  CardBox,
  TitleName,
  StyledAttendanceCard,
  StyledExportCard,
  StyledExportOuter,
  StyledCalender,
  StyledDurationFont,
  StyledCheck,
  StyledCheckText,
  StyledCheckIn,
  StyledCheckOut,
  StyledCardStatus,
  StyledAttendanceTimeCard,
  StyledCardStatusAbsent,
  StyledDatePicker,
  StyledInOffice,
  StyledSuggestionIcon,
  StyledText,
  StyledCardWFHStatus,
} from 'routes/Profile/Public/ReadView.styled';
import { useTranslate } from 'translations';

import { StyledLoadingOutlined } from 'ui/components/Hierarchy/Hierarchy.styled';
import { FullWidthSpin, useErrorNotification } from 'ui';
import { SuggestedGreyIcon, SuggestedRedIcon } from 'assets';
import { useAttendanceTimeCard, useExportAttendanceReport } from 'api';
import { getDuration } from '../helper';
import { StyledIcon } from './AttendaceWidget.Styled';
import { exportDownload } from './helper';

const attendanceDateFormat = 'MM-DD-YYYY';
const lastModifiedDateFormat = 'DD.MM.YYYY hh:mm a';

interface Props {
  hcmPersonId: string;
  hireDate: string;
  updateLastModifiedDate?: (date: string) => void;
}

export const AttendanceWidgetComponent: FC<Props> = ({
  hcmPersonId,
  hireDate,
  updateLastModifiedDate,
}) => {
  const [newDate, setNewDate] = useState<moment.Moment>();
  const { t } = useTranslate();
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [todaysDate] = useState<moment.Moment>(moment());
  const [selectedDay, setSelectedDay] = useState<string>();

  const {
    data: attendanceInfoData,
    isLoading,
    refetch,
    isError: attendanceTimeCardError,
  } = useAttendanceTimeCard(hcmPersonId, {
    params: { requestedDate: selectedDay },
  });

  const {
    data: exportAttendanceData,
    isLoading: isLoadingExportAttendance,
    refetch: fetchAttendance,
    isError: exportAttendanceError,
  } = useExportAttendanceReport(hcmPersonId, {
    responseType: 'blob',
    params: {
      requestedDate: selectedDay,
    },
  });

  useMemo(() => {
    if (attendanceInfoData && updateLastModifiedDate) {
      updateLastModifiedDate(
        attendanceInfoData?.lastModifiedDate
          ? `${t('attendance.widget.last.updated.on')} ${moment(
              attendanceInfoData.lastModifiedDate
            ).format(lastModifiedDateFormat)}`
          : ''
      );
    }
  }, [t, attendanceInfoData, updateLastModifiedDate]);

  useErrorNotification(
    {
      isNotification: attendanceTimeCardError || exportAttendanceError,
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    },
    [attendanceTimeCardError, exportAttendanceError]
  );

  useEffect(() => {
    if (selectedDay) {
      refetch();
    }
  }, [selectedDay, refetch]);

  useEffect(() => {
    const toDayDate = moment();
    toDayDate && setSelectedDay(toDayDate.format(attendanceDateFormat));
  }, []);

  const handleDate = useCallback((date: moment.Moment | null) => {
    if (date) {
      setNewDate(date);
      setSelectedDay(date.format(attendanceDateFormat));
    }
  }, []);

  const onExportAttendance = useCallback(() => {
    setIsClicked(true);
    const selectedDate = (newDate ? newDate : todaysDate).format(attendanceDateFormat);
    selectedDate && fetchAttendance();
  }, [fetchAttendance, newDate, todaysDate]);

  useEffect(() => {
    if (exportAttendanceData && isClicked) {
      const selectedDate = (newDate ? newDate : todaysDate).format(`MMMM_YYYY`);
      exportDownload(exportAttendanceData, selectedDate);
      setIsClicked(false);
    }
  }, [exportAttendanceData, newDate, isClicked, todaysDate]);

  const disableFilterDate = useCallback(
    (currentMonth: moment.Moment) => {
      const CurrentDate = moment()
        .year(currentMonth.year())
        .month(currentMonth.month())
        .startOf('day')
        .isAfter();

      return CurrentDate || currentMonth.isBefore(hireDate);
    },
    [hireDate]
  );

  return (
    <CardBox>
      <StyledAttendanceCard>
        <TitleName>
          {t('attendance.card.todays.attendance')}
          {!isLoadingExportAttendance ? (
            <StyledExportOuter onClick={onExportAttendance}>
              <StyledExportCard>{t('attendance.card.export')}</StyledExportCard>
              <VerticalAlignBottomOutlined className="pd-icon" />
            </StyledExportOuter>
          ) : (
            <StyledExportOuter>
              <StyledText type="secondary">
                <StyledLoadingOutlined /> {t('attendance.card.preparing.export')}
              </StyledText>
            </StyledExportOuter>
          )}
        </TitleName>

        <StyledCalender>
          <Row justify={'space-between'} align={'middle'}>
            <Col>
              <StyledDatePicker
                defaultValue={moment()}
                format={'ddd, D MMM'}
                allowClear={false}
                suffixIcon={<StyledIcon />}
                disabledDate={disableFilterDate}
                onChange={(value) => {
                  handleDate(value);
                }}
              />
            </Col>
            <Col>
              {isLoading ? (
                <Spin size={'small'} />
              ) : attendanceInfoData && attendanceInfoData.isHoliday === true ? (
                <StyledCardStatus>{t('attendance.card.status.holiday')}</StyledCardStatus>
              ) : attendanceInfoData && attendanceInfoData.isWeekend === true ? (
                <StyledCardStatus>{t('attendance.card.status.weekend')}</StyledCardStatus>
              ) : attendanceInfoData && attendanceInfoData.isOnLeave === true ? (
                <StyledCardStatus>{t('attendance.card.status.leave')}</StyledCardStatus>
              ) : attendanceInfoData && attendanceInfoData.isWFH === true ? (
                <StyledCardWFHStatus>{t('attendance.card.status.wfh')}</StyledCardWFHStatus>
              ) : attendanceInfoData && attendanceInfoData.isAbsence === true ? (
                <StyledCardStatusAbsent>
                  {t('attendance.card.status.absent')}
                </StyledCardStatusAbsent>
              ) : attendanceInfoData &&
                (attendanceInfoData.lateCheckInDuration ||
                  attendanceInfoData.earlyCheckOutDuration) ? (
                <SuggestedRedIcon />
              ) : (
                <SuggestedGreyIcon />
              )}
            </Col>
          </Row>
        </StyledCalender>
      </StyledAttendanceCard>
      <StyledAttendanceTimeCard>
        {isLoading ? (
          <FullWidthSpin />
        ) : attendanceInfoData ? (
          attendanceInfoData && attendanceInfoData.isHoliday === true ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('attendance.card.no.time.tracked')}
            />
          ) : attendanceInfoData && attendanceInfoData.isWeekend === true ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('attendance.card.no.time.tracked')}
            />
          ) : attendanceInfoData && attendanceInfoData.isOnLeave === true ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('attendance.card.no.time.tracked')}
            />
          ) : attendanceInfoData && attendanceInfoData.isWFH === true ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('attendance.card.no.time.tracked')}
            />
          ) : attendanceInfoData && attendanceInfoData.isAbsence === true ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('attendance.card.no.time.tracked')}
            />
          ) : (
            <div>
              <StyledDurationFont>
                <ClockCircleOutlined />
                {attendanceInfoData?.durationInOffice
                  ? `${t('attendance.card.durationTime')} ${getDuration(
                      attendanceInfoData?.durationInOffice
                    )}`
                  : t('attendance.card.duration')}
              </StyledDurationFont>
              <StyledCheck>
                <StyledCheckText>{t('attendance.card.check.in')}</StyledCheckText>
                <StyledCheckIn lateCheckIn={attendanceInfoData.lateCheckInDuration}>
                  {attendanceInfoData.lateCheckInDuration && (
                    <StyledSuggestionIcon>
                      <SuggestedRedIcon />
                    </StyledSuggestionIcon>
                  )}
                  {attendanceInfoData && attendanceInfoData.checkOutTime !== null
                    ? attendanceInfoData.checkInTime
                    : attendanceInfoData && attendanceInfoData.checkInTime}
                </StyledCheckIn>
              </StyledCheck>
              <StyledCheck>
                <StyledCheckText>{t('attendance.card.check.out')}</StyledCheckText>
                <StyledCheckOut earlyCheckOut={attendanceInfoData.earlyCheckOutDuration}>
                  {attendanceInfoData.earlyCheckOutDuration && (
                    <StyledSuggestionIcon>
                      <SuggestedRedIcon />
                    </StyledSuggestionIcon>
                  )}
                  {attendanceInfoData && attendanceInfoData.checkOutTime === null ? (
                    <StyledInOffice>{t('attendance.card.in.office')}</StyledInOffice>
                  ) : (
                    attendanceInfoData && attendanceInfoData.checkOutTime
                  )}
                </StyledCheckOut>
              </StyledCheck>
            </div>
          )
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No Data'} />
        )}
      </StyledAttendanceTimeCard>
    </CardBox>
  );
};

export const AttendanceWidget = memo(AttendanceWidgetComponent);

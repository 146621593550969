import { Gutter } from 'antd/lib/grid/row';
import React from 'react';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import {
  GroupActiveAvatarIcon,
  OnAllUpdatedNotificationClickProps,
  ProcessIcon,
} from 'routes/NotificationDrawer';
import IconSelector from 'routes/Tasks/components/templates/RequestsCatalogCards/IconSelector';
import { useTranslate } from 'translations';
import { NotificationList, NotificationType } from 'types';
import { colors } from 'ui';
import { TemplateRowGeneratedStyled } from './SeeAllUpdate.style';
import { SeeAllUpdateCard } from './SeeAllUpdateCard';

interface Props {
  allNotificationUpdatesList: NotificationList[];
  refreshList: () => void;
  onAllUpdatedNotificationClick: (props: OnAllUpdatedNotificationClickProps) => void;
}
const GUTTER_12_32: [Gutter, Gutter] = [12, 33];

const SeeAllUpdateGeneratedComponent: React.FC<Props> = ({
  allNotificationUpdatesList,
  refreshList,
  onAllUpdatedNotificationClick,
}) => {
  const { t } = useTranslate();
  return (
    <TemplateRowGeneratedStyled gutter={GUTTER_12_32}>
      {allNotificationUpdatesList.map((itemCard) => {
        switch (itemCard.notificationContentType) {
          case NotificationType.CREATE_PROCESS:
          case NotificationType.COMPLETE_PROCESS:
            return (
              <SeeAllUpdateCard
                isRead={itemCard.isRead}
                onAllUpdatedNotificationClick={onAllUpdatedNotificationClick}
                requestId={itemCard.requestId}
                refreshList={refreshList}
                notificationRequestType={itemCard.notificationContentType}
                titleRequest={` ${itemCard.requestName} ${
                  itemCard.notificationContentType === NotificationType.CREATE_PROCESS
                    ? t('notification.drawer.action.createProcess')
                    : t('notification.drawer.action.completeProcess')
                }`}
                messageRequest={`${itemCard.requestName} ${t(
                  'notification.drawer.body.createProcess'
                )}${
                  itemCard.notificationContentType === NotificationType.CREATE_PROCESS
                    ? t('notification.drawer.action.createProcess')
                    : t('notification.drawer.action.completeProcess')
                }`}
                icon={
                  <ProcessIcon
                    Icon={
                      <IconSelector
                        type={itemCard.requestName.replace(/\s/g, '').replace(/-/g, '')}
                        style={{
                          fontSize: 20,
                          height: '20px',
                          width: '20px',
                          color: colors.skyColor,
                          verticalAlign: 'textBottom',
                        }}
                      />
                    }
                  />
                }
                hasComment={false}
                hasUpdatesRequest={itemCard.notificationCount > 1}
                processInstance={itemCard.processInstanceId}
                isLastElement={allNotificationUpdatesList.slice(-1)[0] === itemCard}
                formattedDate={itemCard.formattedDate}
                createdDate={itemCard.createdDate}
                redirectionPage={itemCard.redirectionPage}
                redirectionTab={itemCard.redirectionTab}
              />
            );

          case NotificationType.START_TASK:
            return (
              <SeeAllUpdateCard
                isRead={itemCard.isRead}
                onAllUpdatedNotificationClick={onAllUpdatedNotificationClick}
                requestId={itemCard.requestId}
                refreshList={refreshList}
                notificationRequestType={itemCard.notificationContentType}
                titleRequest={` ${t('notification.drawer.title.startTask')}`}
                messageRequest={`${itemCard.taskName} ${t(
                  'notification.drawer.body.startTask.for'
                )} ${itemCard.requestName} ${t('notification.drawer.body.startTask.beenAssign')}`}
                icon={<GroupActiveAvatarIcon />}
                hasComment={false}
                hasUpdatesRequest={itemCard.notificationCount > 1}
                processInstance={itemCard.processInstanceId}
                isLastElement={allNotificationUpdatesList.slice(-1)[0] === itemCard}
                formattedDate={itemCard.formattedDate}
                createdDate={itemCard.createdDate}
                redirectionPage={itemCard.redirectionPage}
                redirectionTab={itemCard.redirectionTab}
              />
            );
          case NotificationType.REJECT_TASK:
          case NotificationType.APPROVE_TASK:
            return (
              <SeeAllUpdateCard
                isRead={itemCard.isRead}
                onAllUpdatedNotificationClick={onAllUpdatedNotificationClick}
                notificationRequestType={itemCard.notificationContentType}
                refreshList={refreshList}
                requestId={itemCard.requestId}
                titleRequest={`${itemCard.requestName} ${t(
                  'notification.drawer.title.taskAction.updated'
                )}`}
                messageRequest={`"${itemCard.taskName}" ${t(
                  'notification.drawer.body.startTask.for'
                )} ${itemCard.requestName} ${t('notification.drawer.body.taskAction.is')} ${
                  itemCard.notificationContentType === NotificationType.APPROVE_TASK
                    ? t('notification.drawer.action.completedTask.approved')
                    : t('notification.drawer.action.completedTask.reject')
                }`}
                icon={
                  <ProcessIcon
                    Icon={
                      <IconSelector
                        type={itemCard.requestName.replace(/\s/g, '').replace(/-/g, '')}
                        style={{
                          fontSize: 20,
                          height: '20px',
                          width: '20px',
                          color: colors.skyColor,
                          verticalAlign: 'textBottom',
                        }}
                      />
                    }
                  />
                }
                hasComment={false}
                hasUpdatesRequest={itemCard.notificationCount > 1}
                processInstance={itemCard.processInstanceId}
                isLastElement={allNotificationUpdatesList.slice(-1)[0] === itemCard}
                formattedDate={itemCard.formattedDate}
                createdDate={itemCard.createdDate}
                redirectionPage={itemCard.redirectionPage}
                redirectionTab={itemCard.redirectionTab}
              />
            );
          case NotificationType.ASSIGN_TASK:
            return (
              <SeeAllUpdateCard
                isRead={itemCard.isRead}
                onAllUpdatedNotificationClick={onAllUpdatedNotificationClick}
                requestId={itemCard.requestId}
                refreshList={refreshList}
                notificationRequestType={itemCard.notificationContentType}
                titleRequest={` ${t('notification.drawer.title.assignTask')}`}
                messageRequest={`"${itemCard.taskName}" ${t(
                  'notification.drawer.body.startTask.for'
                )} ${itemCard.requestName} ${t('notification.drawer.body.assignTask.hasAssign')}`}
                icon={
                  <ProcessIcon
                    Icon={
                      <IconSelector
                        type={itemCard.requestName.replace(/\s/g, '').replace(/-/g, '')}
                        style={{
                          fontSize: 20,
                          height: '20px',
                          width: '20px',
                          color: colors.skyColor,
                          verticalAlign: 'textBottom',
                        }}
                      />
                    }
                  />
                }
                hasComment={false}
                hasUpdatesRequest={itemCard.notificationCount > 1}
                processInstance={itemCard.processInstanceId}
                isLastElement={allNotificationUpdatesList.slice(-1)[0] === itemCard}
                formattedDate={itemCard.formattedDate}
                createdDate={itemCard.createdDate}
                redirectionPage={itemCard.redirectionPage}
                redirectionTab={itemCard.redirectionTab}
              />
            );

          case NotificationType.COMMENT_TO_ASSIGNEE:
          case NotificationType.COMMENT_TO_REQUESTER:
            return (
              <SeeAllUpdateCard
                isRead={itemCard.isRead}
                onAllUpdatedNotificationClick={onAllUpdatedNotificationClick}
                requestId={itemCard.requestId}
                refreshList={refreshList}
                notificationRequestType={itemCard.notificationContentType}
                titleRequest={`${itemCard.commenterName} ${t(
                  'notification.drawer.title.commentToRequester'
                )}`}
                messageRequest={`${itemCard.commenterName} ${t(
                  'notification.drawer.body.commentToRequester'
                )} ${itemCard.requesterName} ${itemCard.taskName}`}
                icon={
                  <MSTeamsUserAvatar
                    id={itemCard.commenterId}
                    name={itemCard.commenterName}
                    size={40}
                  />
                }
                hasComment={itemCard.commentText !== null}
                commentName={itemCard.commenterName}
                commentText={itemCard.commentText}
                hasUpdatesRequest={itemCard.notificationCount > 1}
                processInstance={itemCard.processInstanceId}
                isLastElement={allNotificationUpdatesList.slice(-1)[0] === itemCard}
                formattedDate={itemCard.formattedDate}
                createdDate={itemCard.createdDate}
                redirectionPage={itemCard.redirectionPage}
                redirectionTab={itemCard.redirectionTab}
              />
            );
          case NotificationType.COMMENT_TO_GROUP:
            return (
              <SeeAllUpdateCard
                isRead={itemCard.isRead}
                onAllUpdatedNotificationClick={onAllUpdatedNotificationClick}
                requestId={itemCard.requestId}
                refreshList={refreshList}
                notificationRequestType={itemCard.notificationContentType}
                titleRequest={`${itemCard.commenterName} ${t(
                  'notification.drawer.title.commentToGroup'
                )}`}
                messageRequest={`${itemCard.commenterName} ${t(
                  'notification.drawer.body.commentToGroup'
                )} ${itemCard.taskName}`}
                icon={<GroupActiveAvatarIcon />}
                hasComment={itemCard.commentText !== null}
                commentName={itemCard.commenterName}
                commentText={itemCard.commentText}
                hasUpdatesRequest={itemCard.notificationCount > 1}
                processInstance={itemCard.processInstanceId}
                isLastElement={allNotificationUpdatesList.slice(-1)[0] === itemCard}
                formattedDate={itemCard.formattedDate}
                createdDate={itemCard.createdDate}
                redirectionPage={itemCard.redirectionPage}
                redirectionTab={itemCard.redirectionTab}
              />
            );

          case NotificationType.COMMENT_MENTION:
            return (
              <SeeAllUpdateCard
                isRead={itemCard.isRead}
                onAllUpdatedNotificationClick={onAllUpdatedNotificationClick}
                requestId={itemCard.requestId}
                refreshList={refreshList}
                notificationRequestType={itemCard.notificationContentType}
                titleRequest={`${itemCard.commenterName} ${t(
                  'notification.drawer.title.commentMention'
                )}`}
                messageRequest={`${itemCard.commenterName} ${t(
                  'notification.drawer.body.commentMention'
                )} ${itemCard.requestName}`}
                icon={
                  <MSTeamsUserAvatar
                    id={itemCard.commenterId}
                    name={itemCard.commenterName}
                    size={40}
                  />
                }
                hasComment={itemCard.commentText !== null}
                commentName={itemCard.commenterName}
                commentText={itemCard.commentText}
                hasUpdatesRequest={itemCard.notificationCount > 1}
                processInstance={itemCard.processInstanceId}
                isLastElement={allNotificationUpdatesList.slice(-1)[0] === itemCard}
                formattedDate={itemCard.formattedDate}
                createdDate={itemCard.createdDate}
                redirectionPage={itemCard.redirectionPage}
                redirectionTab={itemCard.redirectionTab}
              />
            );
          default:
            return null;
        }
      })}
    </TemplateRowGeneratedStyled>
  );
};

export const GeneratedSeeAllUpdate = React.memo(SeeAllUpdateGeneratedComponent);

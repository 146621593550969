import React, { createContext, useCallback, useMemo, useState } from 'react';
import { TType } from 'translations';
import { useForm } from 'antd/lib/form/Form';
import { Empty, Form, Spin } from 'antd';
import { VALIDATE_MESSAGES } from 'utils';
import { CBARtLookups, ChangeBankAccountFieldIds } from 'types';
import { useChangeBankAccountRequestLookUp, useChangeBankAccountRequestSubmit } from 'api';
import { FullWidthAndHeightSpin } from 'ui';
import { notification } from 'antd/es';
import { uploadAttachments } from 'ui/publicFunction';
import { useHistory } from 'react-router-dom';
import { AttachmentFile } from '../../../pages';
import { ChangeBankAccountRequestFormFields } from './ChangeBankAccountRequestFormFields';

type Props = {
  t: TType;
  formName?: string;
};

export interface ChangeBankAccountRequestFormContextType {
  testVariable: string;
  dataLookUp?: CBARtLookups;
}

export const ChangeBankAccountRequestFormContext = createContext<ChangeBankAccountRequestFormContextType>(
  { testVariable: '', dataLookUp: undefined }
);

export const ChangeBankAccountRequestForm: React.FC<Props> = ({ t, formName }: Props) => {
  const history = useHistory();
  const [isAttachmentUploadLoading, setIsAttachmentUploadLoading] = useState(false);

  const {
    isLoading: isLoadingLookUp,
    data: dataLookUp,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isError: isErrorLookUp,
  } = useChangeBankAccountRequestLookUp();

  const mutationSubmitChangeBankAccountRequest = useChangeBankAccountRequestSubmit();

  // eslint-disable-next-line
  console.log('DataLookUP', useMemo(()=> dataLookUp,[dataLookUp]));

  const [form] = useForm();
  const onFieldsChange = useCallback(() => {
    /**
     * Handle code on any field has been changed
     */
  }, []);
  const onSubmitForm = useCallback(
    (variables) => {
      /**
       * Handle code when form submission is required
       */
      const attachments: AttachmentFile[] = [];
      attachments.push({
        [`${ChangeBankAccountFieldIds.IBAN}`]: variables[`${ChangeBankAccountFieldIds.IBAN}`],
      });
      if (variables[`${ChangeBankAccountFieldIds.CLEARANCE}`]) {
        attachments.push({
          [`${ChangeBankAccountFieldIds.CLEARANCE}`]: variables[
            `${ChangeBankAccountFieldIds.CLEARANCE}`
          ],
        });
      }
      mutationSubmitChangeBankAccountRequest
        .mutateAsync({
          comments: variables[`${ChangeBankAccountFieldIds.Comments}`],
          employeeBankName: variables[`${ChangeBankAccountFieldIds.EmployeeBankName}`],
          newEmployeeBankId: variables[`${ChangeBankAccountFieldIds.NewEmployeeBankName}`],
        })
        .then((cbarResponse) => {
          setIsAttachmentUploadLoading(true);
          uploadAttachments(
            cbarResponse.taskId,
            cbarResponse.processDefinitionId,
            cbarResponse.processInstanceId,
            cbarResponse.activityId,
            attachments,
            ''
          );

          const location = {
            pathname: `/tasks/${cbarResponse.processInstanceId}`,
            state: { defaultTab: '0' },
          };

          setTimeout(() => {
            setIsAttachmentUploadLoading(false);
            history.replace(location);
          }, 3000);
        })
        .catch(() => {
          /*
           handle error
           */
          notification.error({
            message: t('messages.error.wentWrong'),
            description: t('messages.error.tryAgainLater'),
          });
        });
    },
    [history, mutationSubmitChangeBankAccountRequest, t]
  );

  return (
    <ChangeBankAccountRequestFormContext.Provider value={{ testVariable: '', dataLookUp }}>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        validateMessages={VALIDATE_MESSAGES(t)}
        onFieldsChange={onFieldsChange}
        onFinish={onSubmitForm}
      >
        <React.Fragment>
          {isLoadingLookUp ? (
            <FullWidthAndHeightSpin />
          ) : isErrorLookUp ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('messages.error.wentWrong')}
            />
          ) : (
            <Spin
              spinning={
                mutationSubmitChangeBankAccountRequest.isLoading || isAttachmentUploadLoading
              }
              delay={100}
            >
              <ChangeBankAccountRequestFormFields t={t} form={form} />
            </Spin>
          )}
        </React.Fragment>
      </Form>
    </ChangeBankAccountRequestFormContext.Provider>
  );
};

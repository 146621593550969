import React, { FC, memo } from 'react';
import { Col, Row } from 'antd';
import { ServiceInformation } from 'types';
import IconSelector from '../RequestsCatalogCards/IconSelector';
import {
  StyledCol,
  StyledDrawerDescription,
  StyledDrawerHeader,
  StyledDrawerPath,
  StyledDrawerServiceName,
} from './RequestCatalogDrawerStyle';
import { GUTTER_0_16, GUTTER_16 } from './constants';

interface Props {
  serviceInfo: ServiceInformation;
  requestName: string;
}

export const DrawerHeaderComponent: FC<Props> = ({ serviceInfo, requestName }) => (
  <Row>
    <Col span={24}>
      <StyledDrawerHeader>
        <Row gutter={GUTTER_16}>
          <Col span={24}>
            <StyledDrawerPath>{serviceInfo.path}</StyledDrawerPath>
          </Col>
        </Row>
        <Row justify={'space-between'} gutter={GUTTER_0_16}>
          <Col sm={24} md={20} lg={18}>
            <StyledDrawerServiceName strong>{requestName}</StyledDrawerServiceName>
          </Col>
          <StyledCol sm={24} md={12} lg={6}>
            <IconSelector
              type={requestName.replace(/\s/g, '').replace(/-/g, '')}
              style={{ fontSize: 24, height: '24px', width: '24px', color: serviceInfo.iconColor }}
            />
          </StyledCol>
        </Row>
        <Row>
          <Col span={24}>
            <StyledDrawerDescription>{serviceInfo.description}</StyledDrawerDescription>
          </Col>
        </Row>
      </StyledDrawerHeader>
    </Col>
  </Row>
);
export const DrawerHeader = memo(DrawerHeaderComponent);

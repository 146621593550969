/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { notification } from 'antd';

import { ColumnsType } from 'antd/lib/table';

import { useTranslate } from 'translations';
import { RequestStatus } from 'types';
import moment from 'moment';
import { TextStyled } from 'ui/elements';
import { BreadcrumbItem, OneDriveBreadCrumbs } from '../OneDriveBreadCrumbs';
import {
  FileIconStyled,
  FolderIconStyled,
  OneDriveButton,
  StyledBreadcrumb,
  StyledTable,
} from '../OneDriveSearch/OneDriveSearchResult/OneDriveSearchResult.styled';
import { OneDriveConfirm } from '../OneDriveConfirm';
import { OneDriveDownload } from '../OneDriveDownload';
import { StyledPageWrapper, StyledButton, StyledFiles } from './OneDriveShared.styled';
import { ConnectorProps, oneDriveSharedConnector } from './OneDriveShared.connector';

export const OneDriveSharedComponent: FC<ConnectorProps> = ({
  getOneDriveSharedStart,
  sharedFile,
  getOneDriveFolderSharedStart,
  folderChildren,
}) => {
  const { t } = useTranslate();
  const [tableData, setTableData] = useState(sharedFile?.data);
  const [breadCrumbState, setBreadCrumbState] = useState<BreadcrumbItem[]>([
    {
      title: t('oneDrive.shared.title'),
      id: 'Shared with you',
    },
  ]);
  const currentFolderId = useMemo(() => breadCrumbState[breadCrumbState.length - 1]?.id, [
    breadCrumbState,
  ]);
  const { driveId, id } = breadCrumbState[breadCrumbState.length - 1];

  useEffect(() => {
    if (currentFolderId) {
      if (breadCrumbState.length > 1) {
        if (driveId && folderChildren[driveId]?.[currentFolderId]?.data) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setTableData(folderChildren[driveId]?.[currentFolderId]?.data as any);
        } else {
          if (driveId && id) {
            getOneDriveFolderSharedStart({
              driveId,
              folderId: id,
            });
          }
        }
      } else {
        setTableData(sharedFile?.data);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadCrumbState]);

  const showError = useCallback(() => {
    notification.error({
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    });
  }, [t]);

  useEffect(() => {
    getOneDriveSharedStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirm = useCallback((link: string) => {
    window.open(link);
  }, []);

  const onClickFolder = (folderId: string, folderName: string, driveId?: string) => {
    setBreadCrumbState([...breadCrumbState, { id: folderId, title: folderName, driveId }]);
    setTableData([]);
  };

  const onClickBreadCrumb = useCallback(
    (id) => {
      const currentFolderBreadcrumbIndex = breadCrumbState.findIndex((crumb) => crumb.id === id);

      if (~currentFolderBreadcrumbIndex) {
        setBreadCrumbState(breadCrumbState.slice(0, currentFolderBreadcrumbIndex + 1));
      }
    },
    [breadCrumbState]
  );

  const data = tableData?.map((item, index) => ({
    key: index,
    file: item.file ? <FileIconStyled /> : <FolderIconStyled />,
    name: item.file ? (
      <OneDriveButton type="link">
        <OneDriveConfirm onConfirm={() => onConfirm(item.webUrl)}>
          <TextStyled underline>{item?.name}</TextStyled>
        </OneDriveConfirm>
      </OneDriveButton>
    ) : (
      <StyledButton
        type="link"
        onClick={() =>
          onClickFolder(
            item.id,
            item.name,
            item.remoteItem
              ? item.remoteItem.parentReference.driveId
              : (item as any).parentReference.driveId
          )
        }
      >
        {item?.name}
      </StyledButton>
    ),
    dateShared: (
      <TextStyled>
        {moment(item?.lastModifiedDate || item.createdDate).format('DD.MM.YYYY HH.mm')}
      </TextStyled>
    ),
    sharedBy: <TextStyled>{item?.lastModifiedBy || item.createdBy}</TextStyled>,
    download: item.file ? (
      <OneDriveDownload
        driveId={
          item.remoteItem
            ? item.remoteItem.parentReference.driveId
            : (item as any).parentReference.driveId
        }
        itemId={item.id}
      />
    ) : undefined,
  }));

  useEffect(() => {
    if (currentFolderId && driveId && folderChildren[driveId]?.[currentFolderId]?.data) {
      const folderData = driveId && folderChildren[driveId]?.[currentFolderId]?.data;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setTableData(folderData as any);
    }

    if (
      currentFolderId &&
      driveId &&
      folderChildren[driveId][currentFolderId]?.status === RequestStatus.rejected
    ) {
      showError();
      setTableData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderChildren]);

  useEffect(() => {
    if (sharedFile.status === RequestStatus.resolved) {
      setTableData(sharedFile?.data);
      setBreadCrumbState([
        {
          title: t('oneDrive.shared.title'),
          id: 'Shared with you',
        },
      ]);
    }

    if (sharedFile.status === RequestStatus.rejected) {
      showError();
      setTableData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedFile.status]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnsType<any> = [
    {
      dataIndex: 'file',
      key: 'file',
      width: '5%',
    },
    {
      title: t('oneDrive.shared.columns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '50%',
    },
    {
      title: t('oneDrive.shared.columns.dateShared'),
      dataIndex: 'dateShared',
      key: 'dateShared',
      width: '20%',
    },
    {
      title: t('oneDrive.shared.columns.sharedBy'),
      dataIndex: 'sharedBy',
      key: 'sharedBy',
      width: '25%',
    },
    {
      dataIndex: 'download',
      key: 'download',
      width: '20%',
    },
  ];

  const isLoading =
    sharedFile.status === RequestStatus.pending ||
    (!!currentFolderId &&
      !!driveId &&
      folderChildren[driveId]?.[currentFolderId]?.status === RequestStatus.pending);

  return (
    <StyledPageWrapper>
      <StyledBreadcrumb style={{ marginLeft: 0 }}>
        <OneDriveBreadCrumbs list={breadCrumbState} onClick={onClickBreadCrumb} />
      </StyledBreadcrumb>
      <StyledFiles>
        <StyledTable
          loading={isLoading}
          columns={columns}
          dataSource={data}
          pagination={{
            hideOnSinglePage: true,
          }}
        />
      </StyledFiles>
    </StyledPageWrapper>
  );
};

export const OneDriveShared = oneDriveSharedConnector(memo(OneDriveSharedComponent));

import { Form, Input } from 'antd';
import React, { FC } from 'react';
import { FIELDS_REQUIRE } from 'routes/Tasks/components/templates/RequestsCatalogDrawer/constants';
import { useTranslate } from 'translations';

interface Props {
  maxLength: number;
  formItemId: string;
  formItemLabel: string;
  isRequired: boolean;
}

const { TextArea } = Input;

export const FelidTextArea: FC<Props> = ({ maxLength, formItemId, formItemLabel, isRequired }) => {
  const { t } = useTranslate();

  return (
    <Form.Item name={formItemId} label={formItemLabel} rules={FIELDS_REQUIRE(isRequired)}>
      <TextArea
        maxLength={maxLength}
        showCount={true}
        placeholder={t('tasks.textArea.placeHolder')}
      />
    </Form.Item>
  );
};

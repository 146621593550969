import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  GetLeavesTypesFail,
  GetLeavesTypesSuccess,
  GetLeavesTypesStart,
  getLeavesTypesStart,
  getLeavesTypesSuccess,
  getLeavesTypesFail,
} from './leavesTypesSlice';

export const LeavesTypesEpic: Epic<
  GetLeavesTypesStart,
  GetLeavesTypesSuccess | GetLeavesTypesFail,
  RootState,
  typeof API
> = (action$, _, { leaves }) =>
  action$.pipe(
    filter(getLeavesTypesStart.match),
    exhaustMap((action) =>
      from(leaves.getLeavesTypes(action.payload.hcmPersonId)).pipe(
        map(({ data }) => getLeavesTypesSuccess(data)),
        catchError((error) => of(getLeavesTypesFail(error?.response?.data)))
      )
    )
  );

import React, { FC, memo } from 'react';
import { Button, Col, Row } from 'antd';
import { TType } from 'translations';
import { ArrowRightOutlined } from '@ant-design/icons';
import { StyledActionButtonWrapper } from 'routes/Calendar/templates/LeavesDrawer';
import { StyledButton } from './RequestCatalogDrawerStyle';
import { GUTTER_16 } from './constants';

interface Props {
  t: TType;
  onNewRequest: () => void;
  hideCreateBtn?: boolean;
  processPolicy?: string;
}

export const DrawerButtonsComponent: FC<Props> = ({
  onNewRequest,
  t,
  hideCreateBtn,
  processPolicy,
}) => (
  <StyledActionButtonWrapper isHighFromBottom={true}>
    <Row gutter={GUTTER_16} justify={'space-between'}>
      {processPolicy ? (
        <Col>
          <Button download target="_blank" href={processPolicy}>
            {t('requestCatalog.drawer.actionButton.viewPolicy')}
          </Button>
        </Col>
      ) : (
        <Col>
          <Button disabled>{t('requestCatalog.drawer.actionButton.viewPolicy')}</Button>
        </Col>
      )}

      {hideCreateBtn ? null : (
        <Col>
          <StyledButton onClick={onNewRequest}>
            {t('requestCatalog.drawer.actionButton.startRequest')}
            <ArrowRightOutlined />
          </StyledButton>
        </Col>
      )}
    </Row>
  </StyledActionButtonWrapper>
);
export const DrawerButtons = memo(DrawerButtonsComponent);

import { connect, ConnectedProps } from 'react-redux';

import { RootState, getUserAvatarStart, userAvatarData } from 'store';

const mapDispatch = {
  getUserAvatarStart,
};

const mapState = (state: RootState) => ({
  userAvatar: userAvatarData(state),
});

export const userAvatarConnector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof userAvatarConnector>;

export type ConnectorProps = PropsFromRedux;

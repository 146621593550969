import React from 'react';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { CustomSearchbar } from './Searchbar.styled';

type Props = {
  onChange: (keyword: string) => void;
  isLoading: boolean;
  placeholder: string;
};
export const Searchbar: React.FC<Props> = ({ onChange, isLoading, placeholder }) => {
  const onSearch: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );
  const loadingIcon = <Spin spinning indicator={<LoadingOutlined />} />;
  return (
    <CustomSearchbar
      placeholder={placeholder}
      onChange={onSearch}
      suffix={isLoading ? loadingIcon : <SearchOutlined />}
    />
  );
};

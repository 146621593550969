import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

const { Title: AntTitle } = Typography;

type Props = typeof AntTitle & {
  readonly withSpace?: boolean;
};

export const TitleH3 = styled(({ ...rest }) => <AntTitle {...rest} />).attrs({
  level: 3,
})`
  &.ant-typography {
    margin-top: 0;
    margin-bottom: ${(props: Props) => (props.withSpace ? '24px' : 0)};
    font-size: 18px;
  }
  &.ant-typography.ant-typography-secondary {
    color: #8b93a2;
    font-weight: normal;
  }
`;

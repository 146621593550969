import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const categoryCard = (state: RootState): RootState['categoryCard'] => state.categoryCard;

export const categoryCardSelector = createSelector(categoryCard, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

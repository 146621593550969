import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { VerticalSpace } from 'ui';
import { span } from 'ui/elements';
import { StyledSettingsCard } from 'routes/Settings/Settings.styled';
import { useTranslate } from 'translations';
import { StyledNotificationTitle, StyledNotificationWrapper } from './Notification.styled';
import { NotificationForm } from './components';

export const Notification: FC = () => {
  const { t } = useTranslate();
  const { span24 } = span;
  return (
    <VerticalSpace className="layout-main">
      <StyledSettingsCard>
        <Row align="middle">
          <Col span={span24}>
            <StyledNotificationTitle>{t('settings.notification.title')}</StyledNotificationTitle>
          </Col>
        </Row>
        <StyledNotificationWrapper>
          <NotificationForm />
        </StyledNotificationWrapper>
      </StyledSettingsCard>
    </VerticalSpace>
  );
};

import styled from 'styled-components';
import { Button } from 'antd';
import { colors, typography } from 'ui/elements';

export const ProcessContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const ProcessName = styled.p`
  font-size: ${typography.size.kilo};
  font-weight: ${typography.weight.bold};
  color: ${colors.primaryColor};
  margin-bottom: 0;
`;

export const RemoveButton = styled(Button)`
  font-size: ${typography.size.uno};
  font-weight: ${typography.weight.regular};
  color: ${colors.lightGrey};
`;

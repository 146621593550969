import { Card, Divider, Typography, Row } from 'antd';
import styled from 'styled-components';
import { colors, space } from 'ui/elements';

const { Text } = Typography;

export const StyledCard = styled(Card)<{ $highlighted?: boolean; $withGap?: boolean }>`
  margin-top: ${({ $withGap }) => $withGap && space.normal};

  &.ant-card-bordered {
    border: none;
    border-left: ${({ $highlighted }) =>
      $highlighted ? '4px solid ' + colors.primaryColor : 'none'};
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 10px 0;
`;

export const TextStyledH2 = styled(Text)`
  margin-top: 9px;
  font-size: 17px;
  color: ${colors.textColorSecondary};
`;

export const TextAppliedAction = styled(Text)`
  color: ${colors.primaryColor};
  font-size: 18px;
  padding-top: 0px;
`;

export const StyledRow = styled(Row)`
  width: 130px;
`;

export const StyledFullWidthRow = styled(Row)`
  width: 100%;
`;

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetLeavesHistoryFail,
  GetLeavesHistorySuccess,
  GetLeavesHistoryStart,
  getLeavesHistoryStart,
  getLeavesHistorySuccess,
  getLeavesHistoryFail,
} from './leavesHistorySlice';

export const getBalanceEpic: Epic<
  GetLeavesHistoryStart,
  GetLeavesHistorySuccess | GetLeavesHistoryFail,
  RootState,
  typeof API
> = (action$, _, { leavesHistory }) =>
  action$.pipe(
    filter(getLeavesHistoryStart.match),
    exhaustMap(({ payload }) =>
      from(leavesHistory.getLeavesHistory(payload)).pipe(
        map(({ data }) => getLeavesHistorySuccess(data)),
        catchError((error) => of(getLeavesHistoryFail(error?.response?.data)))
      )
    )
  );

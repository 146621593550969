import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { userAvatar } from '../UserAvatar';
import { QUERIES } from '../../constants';

export function useUserAvatarByEmail(action: {
  payload: {
    email: string | undefined;
    isAdoidNotFound: boolean;
    data: {
      responseType: string;
      headers: {
        [key: string]: string;
      };
      params: AxiosRequestConfig['params'];
    };
  };
}) {
  return useQuery(
    [QUERIES.userAvatarByEmail, action.payload.data],
    async () => {
      const { data } = await userAvatar.getTeamsUserAvatarByEmail(action.payload.data);
      return URL.createObjectURL(new Blob([data]));
    },
    {
      refetchOnWindowFocus: false,
      enabled: action.payload.isAdoidNotFound,
    }
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, OneDriveSharedData, RequestStatus } from 'types';
import { OneDriveFolderSharedState } from './oneDriveFolderSharedType';

export const emptyOneDriveFolderSharedState: OneDriveFolderSharedState = {};
export type GetOneDriveFolderSharedStart = PayloadAction<{ driveId: string; folderId: string }>;
export type GetOneDriveFolderSharedSuccess = PayloadAction<{
  driveId: string;
  folderId: string;
  data: OneDriveSharedData;
}>;
export type GetOneDriveFolderSharedFail = PayloadAction<{
  driveId: string;
  folderId: string;
  error: RequestCommonError;
}>;

const oneDriveFolderShared = createSlice({
  name: 'oneDriveFolderShared',
  initialState: emptyOneDriveFolderSharedState,
  reducers: {
    getOneDriveFolderSharedStart(state, action: GetOneDriveFolderSharedStart) {
      if (state[action.payload.driveId]) {
        if (state[action.payload.driveId][action.payload.folderId]) {
          state[action.payload.driveId][action.payload.folderId].status = RequestStatus.pending;

          return;
        }

        state[action.payload.driveId] = {
          ...state[action.payload.driveId],
          [action.payload.folderId]: {
            error: null,
            status: RequestStatus.pending,
          },
        };

        return;
      }

      state[action.payload.driveId] = {
        [action.payload.folderId]: {
          error: null,
          status: RequestStatus.pending,
        },
      };
    },
    getOneDriveFolderSharedSuccess(state, action: GetOneDriveFolderSharedSuccess) {
      state[action.payload.driveId][action.payload.folderId].status = RequestStatus.resolved;
      state[action.payload.driveId][action.payload.folderId].data = action.payload.data;
    },
    getOneDriveFolderSharedFail(state, action: GetOneDriveFolderSharedFail) {
      state[action.payload.driveId][action.payload.folderId].status = RequestStatus.rejected;
      state[action.payload.driveId][action.payload.folderId].error = action.payload.error;
    },
  },
});

export const {
  getOneDriveFolderSharedStart,
  getOneDriveFolderSharedSuccess,
  getOneDriveFolderSharedFail,
} = oneDriveFolderShared.actions;

export default oneDriveFolderShared.reducer;

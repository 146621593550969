import { PagesUrls } from 'namespace';
import {
  AttendanceIcon,
  MenuPayrollIcon,
  MenuExpenseIcon,
  MenuSettingsIcon,
  EmploymentDetailsIcon,
  DashboardIcon,
  TasksIcon,
  ServicesIcon,
  CalendarIcon,
  ProcessManagementIcon,
  TeamsIcon,
  FilesIcon,
  PlannerIcon,
  EmployeeDirectoryIcon,
  SitesAppIcon,
  ELibraryIcon,
} from 'assets';

const DISABLE_PROCESS_MANAGEMENT = process.env.REACT_APP_DISABLE_MENU_PROCESS_MANAGEMENT !== 'true';
const DISABLE_PLANNER = process.env.REACT_APP_DISABLE_MENU_PLANNER !== 'true';
const DISABLE_LEASE = process.env.REACT_APP_DISABLE_LEASE !== 'true';

export const contractorDrawerMenuItems = [
  {
    key: 0,
    linkTo: PagesUrls.Dashboard,
    linkText: 'menu.home',
    icon: DashboardIcon,
    isShow: true,
  },
  {
    key: 1,
    linkTo: PagesUrls.MyWork,
    linkText: 'menu.myWork',
    icon: TasksIcon,
    isShow: true,
  },
  {
    key: 2,
    linkTo: PagesUrls.Tasks,
    linkText: 'menu.services',
    icon: ServicesIcon,
    isShow: true,
  },
  {
    key: 3,
    linkTo: PagesUrls.Leaves,
    linkText: 'menu.leaves',
    icon: CalendarIcon,
    isShow: true,
  },
  {
    key: 4,
    linkTo: PagesUrls.ProcessManagement,
    linkText: 'menu.processManagementSecondary',
    icon: ProcessManagementIcon,
    isShow: DISABLE_PROCESS_MANAGEMENT,
  },
  {
    key: 6,
    linkTo: PagesUrls.OneDrive,
    linkText: 'menu.oneDrive',
    icon: FilesIcon,
    isShow: true,
  },
  {
    key: 7,
    linkTo: PagesUrls.MSPlanner,
    linkText: 'menu.msplanner',
    icon: PlannerIcon,
    isShow: DISABLE_PLANNER,
  },
  { key: 8, linkTo: PagesUrls.MSTeams, linkText: 'menu.msteams', icon: TeamsIcon, isShow: true },
  {
    key: 10,
    linkTo: PagesUrls.Profile,
    linkText: 'menu.employmentDetails',
    icon: EmploymentDetailsIcon,
    isShow: true,
    query: 'details',
  },
  {
    key: 12,
    linkTo: PagesUrls.EmployeeDirectory,
    linkText: 'menu.employeeDirectory',
    icon: EmployeeDirectoryIcon,
    isShow: true,
  },
  {
    key: 13,
    linkTo: PagesUrls.ELibrary,
    linkText: 'menu.eLibrary',
    icon: ELibraryIcon,
    isShow: true,
  },
  {
    key: 14,
    linkTo: PagesUrls.Settings,
    linkText: 'menu.settings',
    icon: MenuSettingsIcon,
    isShow: true,
  },
  {
    key: 15,
    linkTo: PagesUrls.LeaseSites,
    linkText: 'menu.leaseSites',
    icon: SitesAppIcon,
    isShow: DISABLE_LEASE,
  },
];

export const appDrawerMenuItems = [
  ...contractorDrawerMenuItems,
  {
    key: 5,
    linkTo: PagesUrls.Expenses,
    linkText: 'menu.expenses',
    icon: MenuExpenseIcon,
    isShow: true,
  },
  {
    key: 9,
    linkTo: PagesUrls.Attendance,
    linkText: 'menu.attendance',
    icon: AttendanceIcon,
    isShow: true,
  },
  {
    key: 11,
    linkTo: PagesUrls.Profile,
    linkText: 'menu.payroll',
    icon: MenuPayrollIcon,
    isShow: true,
    query: 'details',
  },
];

import { UploadFile } from 'antd/lib/upload/interface';
import { AttachmentStandard, AttachmentProperties, TaskAttachment as TTaskAttachment } from 'types';
import { BYTES_IN_MB, ACCEPT_FILE_EXTENSIONS } from './constants';

export const validateFileSize = (sizeInBytes: number, allowedFileSizeInMB?: number) =>
  allowedFileSizeInMB ? sizeInBytes / BYTES_IN_MB < allowedFileSizeInMB : true;

const getFileExtension = (fileName: string) => fileName.split('.').pop() || '';

export const validateFileType = (
  { type, name }: UploadFile,
  allowedTypes?: string,
  allowedFormats?: string
) => {
  if (!allowedTypes) {
    return true;
  }

  if (type) {
    return allowedTypes.includes(type);
  }
  if (allowedFormats) {
    return allowedFormats.includes(getFileExtension(name));
  }

  return ACCEPT_FILE_EXTENSIONS.includes(getFileExtension(name));
};

export const getGroupedAttachmentData = (attachmentStandardData: AttachmentStandard[]) => {
  return attachmentStandardData.reduce(
    (acc, { propertyName, propertyValue }) => ({
      ...acc,
      [propertyName]: propertyValue,
    }),
    {} as AttachmentProperties
  );
};

export const groupAttachmentsByLabel = (
  attachments: TTaskAttachment[]
): Record<string, TTaskAttachment[]> => {
  return attachments.reduce(
    (grouped: Record<string, TTaskAttachment[]>, attachment: TTaskAttachment) => {
      const label = attachment.attachmentLabel ?? '';

      if (!grouped[label]) {
        grouped[label] = [];
      }

      grouped[label].push(attachment);

      return grouped;
    },
    {}
  );
};

import { Form } from 'antd';
import React, { FC, useEffect } from 'react';
import { FormInstance } from 'antd/lib/form';
import { FormField } from 'types';
import { useGetFromFieldId } from 'ui/hooks';
import { useHistory } from 'react-router-dom';
import { PagesUrls } from 'namespace';
import { StyledCompleteButton, StyledRejectButton } from '../GeneratedForm.Styled';
import { useDisableFieldDependOnOtherField } from './FieldButton.hooks';

interface Props {
  field: FormField;
  inCard?: boolean;
  form?: FormInstance;
  taskId?: string;
  processDefinitionKey?: string;
  processId?: string;
}

export const FieldButton: FC<Props> = ({
  field,
  inCard,
  form,
  taskId,
  processDefinitionKey,
  processId,
}) => {
  const completedBtn = document.getElementsByClassName('completeBtn');
  const history = useHistory();
  const [leftSpace, setLeftSpace] = React.useState<string>();

  const disableFieldDependOnOtherField = useDisableFieldDependOnOtherField(taskId);

  const getFieldId = useGetFromFieldId();
  const fieldId = getFieldId(field.id);

  useEffect(() => {
    if (completedBtn) {
      const widthBtn = completedBtn.item(0)?.getBoundingClientRect().width;

      widthBtn && setLeftSpace((widthBtn + 20).toString());
    }
  }, [completedBtn]);

  const RejectBtn = React.useMemo(
    () => (
      <StyledRejectButton
        disabled={
          field.properties.viewOnly === 'true' || disableFieldDependOnOtherField('rejected')
        }
        htmlType="submit"
        $inCard={inCard ? inCard : false}
        $left={!inCard && leftSpace ? leftSpace : undefined}
        onClick={() =>
          form?.setFieldsValue({
            [fieldId]: true,
            ...(field.id === 'rejected' && { approved: false }),
          })
        }
      >
        {field.label}
      </StyledRejectButton>
    ),
    [
      disableFieldDependOnOtherField,
      field.id,
      field.label,
      field.properties.viewOnly,
      fieldId,
      form,
      inCard,
      leftSpace,
    ]
  );

  const CancelBtn = React.useMemo(
    () => (
      <StyledRejectButton
        disabled={field.properties.viewOnly === 'true' || disableFieldDependOnOtherField('cancel')}
        htmlType="submit"
        $inCard={inCard ? inCard : false}
        $left={!inCard && leftSpace ? leftSpace : undefined}
        onClick={() =>
          form?.setFieldsValue({
            [fieldId]: true,
            ...(field.id === 'canceled' && { approved: false }),
          })
        }
      >
        {field.label}
      </StyledRejectButton>
    ),
    [
      disableFieldDependOnOtherField,
      field.id,
      field.label,
      field.properties.viewOnly,
      fieldId,
      form,
      inCard,
      leftSpace,
    ]
  );

  const CompeleteBtn = React.useMemo(
    () => (
      <StyledCompleteButton
        disabled={
          field.properties.viewOnly === 'true' || disableFieldDependOnOtherField('approved')
        }
        id={'completedBtn'}
        htmlType="submit"
        onClick={() =>
          form?.setFieldsValue({
            [fieldId]: true,
            ...(field.id === 'approved' && { rejected: false }),
          })
        }
      >
        {field.label}
      </StyledCompleteButton>
    ),
    [
      disableFieldDependOnOtherField,
      field.id,
      field.label,
      field.properties.viewOnly,
      fieldId,
      form,
    ]
  );

  const ResubmitBtn = React.useMemo(
    () => (
      <StyledCompleteButton
        disabled={
          field.properties.viewOnly === 'true' || disableFieldDependOnOtherField('resubmit')
        }
        id={'completedBtn'}
        htmlType="submit"
        onClick={() => {
          history.replace({
            pathname: `${PagesUrls.Tasks}/${processDefinitionKey}/false/edit-request/${taskId}/${processId}`,
          });
        }}
      >
        {field.label}
      </StyledCompleteButton>
    ),
    [
      disableFieldDependOnOtherField,
      field.label,
      field.properties.viewOnly,
      history,
      processDefinitionKey,
      processId,
      taskId,
    ]
  );

  return (
    <Form.Item
      name={field.id}
      wrapperCol={{ span: 8 }}
      valuePropName="button"
      className={`formButtonRow ${inCard ? field.id : 'completeBtn'}`}
    >
      {field.id === 'submit'
        ? ResubmitBtn
        : field.id === 'cancel'
        ? CancelBtn
        : field.properties.primeColor === 'true' || field.id === 'approved'
        ? CompeleteBtn
        : RejectBtn}
    </Form.Item>
  );
};

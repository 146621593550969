import React, { FC, useEffect, useState, useMemo, useCallback, memo } from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { Col, Row, notification, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';
import useResizeObserver from 'use-resize-observer';
import { useTranslate } from 'translations';
import { TasksLayout } from 'layouts';
import { FullWidthSpin, CenteredCard, TitleH3 } from 'ui';
import { RequestStatus, TaskStatus } from 'types';
import qs from 'query-string';
import {
  useDispatch,
  useSelector,
  getMyRequestCardsReset,
  getMyRequestCardsStart,
  myRequestCardsSelector,
  getProcessDefinitionKeysStart,
  processDefinitionKeysData,
} from 'store';
import { useTasksContext } from 'routes/Tasks/TasksProvider';
import { useServicesContext } from 'app';
import {
  ServicesFilter,
  CATEGORY_FILTER_FIELD,
  SUB_CATEGORY_FILTER_FIELDS,
} from '../Filter/ServicesFilter';
import { TaskListContainer, TaskListScroller } from '../TaskList/TaskList.styled';
import { TaskSteps } from '../TaskList/TaskSteps';
import { FilterOption } from '../Filter/Filter.types';
import { TaskCardUpdate } from '../TaskList/TaskCard';
import { StyledCreateButton } from './Requests.styled';
import { handleDownloadClick } from './helper';

interface Props {
  userId?: string;
  openRequestCatalogTab: () => void;
}

const RequestsComponent: FC<Props> = ({ userId, openRequestCatalogTab }) => {
  const { path } = useRouteMatch();
  const getUrl = useCallback((pagePath: string) => `${path}${pagePath}`, [path]);
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [requestsStatus] = useState<TaskStatus>(TaskStatus.inProgress);
  const processDefinitionKeys = useSelector(processDefinitionKeysData);
  const activeRequestCardsData = useSelector(myRequestCardsSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { ref, height = 1 } = useResizeObserver<HTMLDivElement>();
  const { currentActiveTab, rootPath } = useTasksContext();
  const [subCategoryOptions, setSubCategoryOptions] = useState<FilterOption[]>([]);
  const [requestNameOptions, setRequestNameOptions] = useState<FilterOption[]>([]);
  const [params, setParams] = useState({});
  const { isFilterByPrettyId, prettyIdInfoRedirection } = useServicesContext();
  const history = useHistory();

  const search = useMemo(
    () =>
      qs.parse(history.location.search) as {
        downloadName: string;
        downloadCommentId: string;
        downloadAttachmentId: string;
      },
    [history.location]
  );

  const fetchRequests = useCallback(
    (startFrom = 0, params = {}) => {
      if (!userId) {
        return;
      }

      const requestParams = {
        start: startFrom,
        pageSize: 10,
        ...params,
      };

      dispatch(getMyRequestCardsStart({ userId, params: requestParams }));
    },
    [dispatch, userId]
  );

  const reloadRequestList = useCallback(() => {
    dispatch(getMyRequestCardsReset());

    fetchRequests(0, params);
  }, [dispatch, fetchRequests, params]);

  useEffect(() => {
    if (currentActiveTab === '1') {
      setIsLoading(true);
      dispatch(getMyRequestCardsReset());
      fetchRequests(0, params);
    }
  }, [currentActiveTab, fetchRequests, dispatch, params]);

  const loadMore = useCallback(() => {
    if (
      !activeRequestCardsData.hasMore ||
      activeRequestCardsData.status === RequestStatus.pending
    ) {
      return;
    }
    if (activeRequestCardsData.data)
      fetchRequests(activeRequestCardsData.data.requestInfoList.length, params);
    return;
  }, [
    activeRequestCardsData.data,
    activeRequestCardsData.hasMore,
    activeRequestCardsData.status,
    fetchRequests,
    params,
  ]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getProcessDefinitionKeysStart());
    if (!isFilterByPrettyId) {
      dispatch(getMyRequestCardsReset());
      fetchRequests(0, params);
    }
  }, [dispatch, t, fetchRequests, params, isFilterByPrettyId]);

  useEffect(() => {
    if (
      activeRequestCardsData.status === RequestStatus.resolved &&
      processDefinitionKeys.status === RequestStatus.resolved
    ) {
      setIsLoading(false);
    }
  }, [activeRequestCardsData.status, processDefinitionKeys.status]);

  useEffect(() => {
    if (
      activeRequestCardsData.status === RequestStatus.rejected &&
      processDefinitionKeys.status === RequestStatus.resolved
    ) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
      setIsLoading(false);
    }
  }, [activeRequestCardsData, t, processDefinitionKeys.status]);

  const onFilterCallBack = useCallback(
    async (params = {}) => {
      if (isFilterByPrettyId && Object.entries(params).length === 0) {
        return;
      } else {
        setParams(params);
        dispatch(getMyRequestCardsReset());
        fetchRequests(0, params);
      }
    },
    [dispatch, fetchRequests, isFilterByPrettyId]
  );

  useEffect(() => {
    if (isFilterByPrettyId && prettyIdInfoRedirection) {
      const reqParams = {
        prettyId: prettyIdInfoRedirection?.taskPrettyId,
        pageSize: 1,
      };
      setIsLoading(true);
      dispatch(getMyRequestCardsReset());
      fetchRequests(0, reqParams);
    }
  }, [
    dispatch,
    fetchRequests,
    isFilterByPrettyId,
    prettyIdInfoRedirection,
    prettyIdInfoRedirection?.taskPrettyId,
  ]);

  useEffect(
    () => () => {
      dispatch(getMyRequestCardsReset());
    },
    [dispatch]
  );

  const categoryOptions = useMemo(
    () =>
      processDefinitionKeys.data?.categories.map((category) => ({
        label: category.name,
        value: category.name,
        text: category.name,
      })),
    [processDefinitionKeys.data]
  );

  const statusOptions = useMemo(() => {
    const statusList = [
      { name: TaskStatus.inProgress, value: 'false' },
      { name: TaskStatus.processed, value: 'true' },
    ];

    return statusList.map((status) => ({
      label: status.name,
      value: status.value,
      text: status.name,
    }));
  }, []);

  const onCategorySelect = useCallback(
    (value) => {
      const matchedSubCategory = processDefinitionKeys.data?.subCategories.find(
        (subCategory) => subCategory.name === value
      );

      if (matchedSubCategory) {
        const subCategoryOptions = matchedSubCategory?.names.map(({ name }) => ({
          label: name,
          value: name,
          text: name,
        }));

        setSubCategoryOptions(subCategoryOptions);
      }
    },
    [processDefinitionKeys.data?.subCategories]
  );

  const onSubCategorySelect = useCallback(
    (value) => {
      const matchedRequestName = processDefinitionKeys.data?.requestNames.find(
        (subCategory) => subCategory.name === value
      );

      if (matchedRequestName) {
        const requestNameOptions = matchedRequestName?.names.map(
          ({ name, processDefinitionKey }) => ({
            label: name,
            value: processDefinitionKey,
            text: name,
          })
        );

        setRequestNameOptions(requestNameOptions);
      }
    },
    [processDefinitionKeys.data?.requestNames]
  );

  const onValuesChange = useCallback(
    (field: { [key: string]: string }) => {
      if (field[CATEGORY_FILTER_FIELD]) {
        onCategorySelect(field[CATEGORY_FILTER_FIELD]);
        setRequestNameOptions([]);
      }

      if (field[SUB_CATEGORY_FILTER_FIELDS]) {
        onSubCategorySelect(field[SUB_CATEGORY_FILTER_FIELDS]);
      }
    },
    [onCategorySelect, onSubCategorySelect]
  );

  const onResetFilterCallback = useCallback((isReset) => {
    if (isReset) {
      setSubCategoryOptions([]);
      setRequestNameOptions([]);
    }
  }, []);

  useEffect(() => {
    const { downloadName, downloadCommentId, downloadAttachmentId } = search;
    if (downloadName && downloadCommentId && downloadAttachmentId) {
      setIsLoading(true);
      const data = handleDownloadClick(
        downloadName,
        downloadCommentId,
        downloadAttachmentId,
        t,
        rootPath
      );
      data.then(() => setIsLoading(false));
    }
  }, [rootPath, search, t]);

  const RequestCardList = useMemo(() => {
    if (activeRequestCardsData.data && activeRequestCardsData.data.requestInfoList.length) {
      return (
        <TaskListScroller style={(ref as unknown) ? { maxHeight: height - 100 } : undefined}>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={activeRequestCardsData.hasMore}
            useWindow={false}
            loader={<FullWidthSpin key={0} />}
            initialLoad={false}
          >
            {activeRequestCardsData.data.requestInfoList.map((card) => {
              return (
                <>
                  {card.taskInfo && (
                    <TaskCardUpdate
                      key={card.prettyId}
                      processInstanceId={card.processInstanceId}
                      title={card.processName}
                      description={card.taskInfo.description}
                      stepStatus={card.taskInfo.status}
                      stepTitle={card.taskInfo.taskName}
                      taskName={card.taskInfo.taskName}
                      date={card.requestTime}
                      assignedTo={card.taskInfo.taskAssignee}
                      assigneeId={card.taskInfo.assigneeId}
                      taskId={card.taskInfo.taskId}
                      endEvent={card.endEvent}
                      mytask={false}
                      isMyRequest
                      isProcessed={card.taskInfo.status === TaskStatus.processed}
                      requestPrettyId={card.prettyId}
                      formFields={card.taskInfo.formFields}
                      appliedAction={card.taskInfo.appliedAction}
                      rootPath={card.serviceUrl}
                      isJira={card.isJiraRequest}
                      jiraStatus={card.jiraStatus}
                      processDefinitionKey={card.processDefinitionKey}
                      taskDefinitionKey={card?.taskInfo?.taskDefinitionKey}
                    />
                  )}
                </>
              );
            })}
          </InfiniteScroll>
        </TaskListScroller>
      );
    }
  }, [activeRequestCardsData.data, height, loadMore, ref, activeRequestCardsData.hasMore]);

  return (
    <TasksLayout
      sidebar={
        <Row gutter={[0, 24]}>
          <Col span={24}>
            {isLoading ? <FullWidthSpin /> : RequestCardList}
            {!isLoading && !activeRequestCardsData.data?.requestInfoList.length && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('task.noRequests')} />
            )}
          </Col>
        </Row>
      }
      sidebarTitle={
        <ServicesFilter
          t={t}
          history={history}
          isLoading={isLoading}
          categoryOptions={categoryOptions}
          requestNameOptions={requestNameOptions}
          subCategoryOptions={subCategoryOptions}
          statusOptions={statusOptions}
          onFilterCallback={onFilterCallBack}
          onValuesChange={onValuesChange}
          onResetFilterCallback={onResetFilterCallback}
        >
          <StyledCreateButton type="primary" onClick={openRequestCatalogTab}>
            <PlusOutlined />
            {t('tasks.tab.requests.createNewRequest')}
          </StyledCreateButton>
        </ServicesFilter>
      }
    >
      <TaskListContainer ref={ref}>
        <Switch>
          <Route exact path={path}>
            <CenteredCard>
              <TitleH3 type="secondary">{t('tasks.selectToReview')}</TitleH3>
            </CenteredCard>
          </Route>
          <Route exact path={getUrl('/:id')}>
            <TaskSteps reloadTaskList={reloadRequestList} tasksStatus={requestsStatus} />
          </Route>
        </Switch>
      </TaskListContainer>
    </TasksLayout>
  );
};

export const Requests = memo(RequestsComponent);

import { Collapse, Typography } from 'antd';
import React from 'react';
import { LeaveAttachment } from 'types';
import { TaskAttachment } from 'ui/components/TaskList/TaskSteps/TaskAttachment';
import { StyledAttachmentsTitle } from '../LeaveDrawerDetail';
import { StyledAttachmentCollapse } from './component.style';

export interface AttachmentLeaveDrawerProps {
  title: string;
  attachmentList?: LeaveAttachment[];
}

const AttachmentLeaveDrawerComponent: React.FC<AttachmentLeaveDrawerProps> = ({
  title,
  attachmentList,
}) => {
  const { Text } = Typography;
  const { Panel } = Collapse;
  return (
    <React.Fragment>
      <Text type="secondary">
        <StyledAttachmentCollapse defaultActiveKey={['1']} ghost>
          <Panel header={<StyledAttachmentsTitle>{title}</StyledAttachmentsTitle>} key={'1'}>
            {attachmentList?.map(({ id, taskId, name }) => (
              <TaskAttachment id={id} taskId={taskId} name={name} key={id} />
            ))}
          </Panel>
        </StyledAttachmentCollapse>
      </Text>
    </React.Fragment>
  );
};

export const AttachmentLeaveDrawer = React.memo(AttachmentLeaveDrawerComponent);

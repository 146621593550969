import { useQuery } from 'react-query';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api/constants';
import { attendanceInfo } from '../Attendance';

export function useAttendanceList(hcmPersonId: string, startDate: string, endDate: string) {
  return useQuery(
    [QUERIES.attendanceList, hcmPersonId, startDate, endDate],
    async () => {
      const { data } = await attendanceInfo.getAttendanceList(hcmPersonId, startDate, endDate);
      return data;
    },
    {
      enabled: !!hcmPersonId,
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

import styled from 'styled-components';
import { Collapse } from 'antd';
import { colors } from '../theme';

export const StyledCustomPlusMinusCollapse = styled(Collapse)`
  margin-top: 25px;

  &.styled-custom-plus-minus-collapse > .ant-collapse-item {
    border: none;
    border-radius: 4px;
    margin-bottom: 25px;
    padding: 24px;
    background-color: #f8f8f8;

    &:last-child {
      margin-bottom: 0 !important;
    }

    & > .ant-collapse-header {
      justify-content: flex-start !important;
      padding: 0 !important;

      & > .ant-collapse-arrow {
        left: unset !important;
        right: 0 !important;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
`;

export const StyledCustomPlusMinusCollapsePanelTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0 !important;

  p {
    margin-bottom: 0;
    margin-left: 0 !important;
    font-size: 16px;
    font-weight: 400;
    color: ${colors.textColorSecondary};
  }
`;

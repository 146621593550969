import { useMutation } from 'react-query';
import { eLibrary } from 'api';
import { IELibraryDepartmentListBySector } from 'types';

export function useELibraryDepartments() {
  return useMutation<IELibraryDepartmentListBySector, unknown, string, unknown>(
    async (sectorName) => {
      const { data } = await eLibrary.getDepartments(sectorName);
      return data;
    }
  );
}

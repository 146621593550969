import { Col, Row } from 'antd';
import React from 'react';
import { useTranslate } from 'translations';
import { OnAllUpdatedNotificationClickProps, SeeAllUpdateDrawer } from '../seeAllUpdates';

import {
  CommenterNameStyled,
  SeeAllUpdatesStyled,
  TemplateCommentWrapper,
  TemplateMessageStyled,
  TemplateTitleStyled,
  WrapperSeeAllNotificationDrawer,
} from './NotificationDetail.style';

interface Props {
  title: string;
  message: string;
  hasComment: boolean;
  hasNotifications: boolean;
  commenterName?: string | null;
  commentText?: string | null;
  requestId: string;
  requestName: string;
  onSeeAllUpdatesClick: () => void;
  onAllUpdatedNotificationClick: (props: OnAllUpdatedNotificationClickProps) => void;
}

const NotificationTemplateComponent: React.FC<Props> = ({
  title,
  message,
  hasComment,
  hasNotifications,
  commentText,
  commenterName,
  requestId,
  requestName,
  onSeeAllUpdatesClick,
  onAllUpdatedNotificationClick,
}) => {
  const { t } = useTranslate();

  const [isShowAllUpdate, setShowAllUpdate] = React.useState<boolean>(false);
  const handleShowAllUpdate = React.useCallback(
    (e) => {
      e.stopPropagation();
      setShowAllUpdate(!isShowAllUpdate);
    },
    [isShowAllUpdate]
  );
  const handleSeeAllUpdatesClick = (e: React.MouseEvent) => {
    handleShowAllUpdate(e);
    onSeeAllUpdatesClick();
  };
  return (
    <React.Fragment>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <TemplateTitleStyled> {title}</TemplateTitleStyled>
        </Col>
        <Col span={24}>
          <TemplateMessageStyled> {message}</TemplateMessageStyled>
        </Col>
        {hasComment && (
          <Col span={24}>
            <TemplateCommentWrapper>
              <CommenterNameStyled>{commenterName}</CommenterNameStyled>{' '}
              <TemplateMessageStyled>
                <div dangerouslySetInnerHTML={{ __html: commentText ?? '' }}></div>
              </TemplateMessageStyled>
            </TemplateCommentWrapper>
          </Col>
        )}
        {hasNotifications && (
          <Col span={24}>
            <SeeAllUpdatesStyled onClick={handleSeeAllUpdatesClick}>
              {t('notification.drawer.seeAllUpdates')}
            </SeeAllUpdatesStyled>
            <WrapperSeeAllNotificationDrawer
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <SeeAllUpdateDrawer
                onAllUpdatedNotificationClick={onAllUpdatedNotificationClick}
                isVisible={isShowAllUpdate}
                title={requestName}
                requestId={requestId}
                setIsVisible={handleShowAllUpdate}
              />
            </WrapperSeeAllNotificationDrawer>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export const NotificationTemplate = React.memo(NotificationTemplateComponent);

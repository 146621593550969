import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, CategoriesTreeData } from 'types';
import { CategoriesTreeState } from './categoriesTreeType';

export const emptyCategoriesTreeInitialState: CategoriesTreeState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetCategoriesTreeStart = PayloadAction;
export type GetCategoriesTreeSuccess = PayloadAction<CategoriesTreeData>;
export type GetCategoriesTreeFail = PayloadAction<RequestCommonError>;
export type GetCategoriesTreeReset = PayloadAction;

const categoriesTree = createSlice({
  name: 'categoriesTree',
  initialState: emptyCategoriesTreeInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCategoriesTreeStart(state, _: GetCategoriesTreeStart) {
      state.status = RequestStatus.pending;
    },
    getCategoriesTreeSuccess(state, action: GetCategoriesTreeSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getCategoriesTreeFail(state, action: GetCategoriesTreeFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getCategoriesTreeReset(state, action: GetCategoriesTreeReset) {
      state.status = RequestStatus.idle;
      state.error = null;
      state.data = undefined;
    },
  },
});

export const {
  getCategoriesTreeStart,
  getCategoriesTreeSuccess,
  getCategoriesTreeFail,
  getCategoriesTreeReset,
} = categoriesTree.actions;
export default categoriesTree.reducer;

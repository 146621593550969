import { Table, Card, Button } from 'antd';
import styled from 'styled-components';
import { colors, space } from 'ui/elements/theme';

export const StyledFileList = styled(Card)`
  margin: 24px;
`;

export const StyledTable = styled(Table).attrs({ size: 'middle' })`
  .ant-tabs-nav-wrap {
    padding: 24px;
    background-color: ${colors.white};
  }
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: ${colors.labelGrey};

    .ant-tabs-tab-active {
      font-weight: 500;
    }
  }
  .ant-table-thead > tr > th {
    background-color: ${colors.white};
  }
  .ant-pagination.mini li {
    border: 1px solid ${colors.borderGrey} !important;
    margin-right: ${space.slim} !important;
  }

  .ant-pagination.mini .ant-pagination-item-active {
    border: 1px solid ${colors.primaryColor} !important;
  }
`;

export const StyledText = styled.text`
  color: ${colors.textColorSecondary};
`;

export const StyledLoading = styled(Card)`
  width: 100%;
`;
export const StyledButton = styled(Button)`
  padding: 0;
`;

import { UploadFile } from 'antd/lib/upload/interface';
import { CreateFormItemType } from 'types';
import { WRAPPER_COL_SPAN_SIZE_21 } from 'ui';
import { TType } from 'translations';
import { FORM_ITEM_REQUIRED_RULE } from 'utils';

export interface FormItemOnFileChange extends CreateFormItemType {
  onFileChange?: (file?: UploadFile | UploadFile[]) => void;
  t: TType;
}

export const createFormField = (props: FormItemOnFileChange): FormItemOnFileChange => {
  return {
    ...props,
    wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
    rules: [...(props.rules ?? FORM_ITEM_REQUIRED_RULE)],
    selectTheValueAsKey: true,
  };
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CamundaUser, RequestCommonError, RequestStatus } from 'types';
import { CamundaUserState } from './camundaUsersTypes';

export const camundaUsersInitialState: CamundaUserState = {};

export type GetCamundaUserStart = PayloadAction<{ params: { idIn: string } }>;
export type GetCamundaUserSuccess = PayloadAction<{
  data: CamundaUser[];
}>;
export type GetCamundaUserFail = PayloadAction<{
  error: RequestCommonError;
  params: { idIn: string };
}>;

const camundaUsers = createSlice({
  name: 'camundaUsers',
  initialState: camundaUsersInitialState,
  reducers: {
    getCamundaUserStart(state, action: GetCamundaUserStart) {
      const users = action.payload.params.idIn.split(',');
      users.forEach((user) => {
        state[user] = {
          status: RequestStatus.pending,
          error: null,
        };
      });
    },
    getCamundaUserSuccess(state, action: GetCamundaUserSuccess) {
      const { data } = action.payload;
      data.forEach((user) => {
        state[user.id] = {
          data: user,
          status: RequestStatus.resolved,
          error: null,
        };
      });
    },
    getCamundaUserFail(state, action: GetCamundaUserFail) {
      const users = action.payload.params.idIn.split(',');
      users.forEach((user) => {
        state[user] = {
          status: RequestStatus.rejected,
          error: action.payload.error,
        };
      });
    },
  },
});

export const {
  getCamundaUserStart,
  getCamundaUserSuccess,
  getCamundaUserFail,
} = camundaUsers.actions;
export default camundaUsers.reducer;

import React from 'react';
import {
  QuestionOutlined,
  LaptopOutlined,
  SolutionOutlined,
  TeamOutlined,
  CarOutlined,
  RocketOutlined,
  ThunderboltOutlined,
  FlagOutlined,
  VideoCameraOutlined,
  MessageOutlined,
  ToolOutlined,
  UnlockOutlined,
  IdcardOutlined,
  ClockCircleOutlined,
  PercentageOutlined,
  BookOutlined,
  FileTextOutlined,
  AuditOutlined,
  FolderOpenOutlined,
  ExceptionOutlined,
  FundOutlined,
  UsergroupAddOutlined,
  SwapOutlined,
  BellOutlined,
  ContactsOutlined,
  ProjectOutlined,
  CreditCardOutlined,
  BankOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import {
  MedicalInsurance,
  Allowance,
  BusinessTrainingTravelIcon,
  TroubleTicket,
  InfrastructureServices,
  ExpenseIcon,
  CashAdvanceRequest,
  ScrapRequest,
  ActingAppointmentIcon,
  AssignDelegateIcon,
  Reports,
  ServiceEntry,
  SiteLease,
  IDBadge,
  HqItSupport,
  InfrastructureServicesNetwork,
  TechnicalEventSupport,
  AssetDeallocate,
  AssetUpgrade,
  AssetRequest,
  Database,
  Middleware,
  Incident,
  SoftwareLicence,
  ProjectRequest,
  ProfileUpdateIcon,
  NonPoRequestIcon,
  WfhIcon,
} from 'assets';

interface IconSelectorProps {
  type: string;
  style?: React.CSSProperties;
}

const IconSelector: React.FC<IconSelectorProps> = ({ type, ...rest }) => {
  const Icons = {
    EducationAllowanceOutlined: <LaptopOutlined {...rest} />,
    IqamaRenewalOutlined: <SolutionOutlined {...rest} />,
    TeamOutlined: <TeamOutlined {...rest} />,
    CarHireOutlined: <CarOutlined {...rest} />,
    RocketOutlined: <RocketOutlined {...rest} />,
    ThunderboltOutlined: <ThunderboltOutlined {...rest} />,
    FlagOutlined: <FlagOutlined {...rest} />,
    VideoCameraOutlined: <VideoCameraOutlined {...rest} />,
    MessageOutlined: <MessageOutlined {...rest} />,
    CorrectiveMaintenanceOutlined: <ToolOutlined {...rest} />,
    UnlockOutlined: <UnlockOutlined {...rest} />,
    SelfServiceLettersOutlined: <AuditOutlined {...rest} />,
    BusinessCardOutlined: <IdcardOutlined {...rest} />,
    OverTimeOutlined: <ClockCircleOutlined {...rest} />,
    SharikateProgramOutlined: <PercentageOutlined {...rest} />,
    EmployeeGrievanceAndComplaintServiceOutlined: <BookOutlined {...rest} />,
    AdministrationGeneralRequestOutlined: <FileTextOutlined {...rest} />,
    ClearanceOutlined: <FolderOpenOutlined {...rest} />,
    OTLReportOutlined: <ExceptionOutlined {...rest} />,
    ProvisionsOutlined: <FundOutlined {...rest} />,
    TheJoiningFormOutlined: <UsergroupAddOutlined {...rest} />,
    ChangeBankAccountOutlined: <SwapOutlined {...rest} />,
    HaveBankChangeNotificationAutomatedOutlined: <BellOutlined {...rest} />,
    MedicalInsuranceOutlined: <MedicalInsurance {...rest} />,
    ExitReEntryVisaOutlined: <ContactsOutlined {...rest} />,
    ExpenseRequestOutlined: <ProjectOutlined {...rest} />,
    AllowanceRequestOutlined: <Allowance {...rest} />,
    BusinessandTrainingTravelOutlined: <BusinessTrainingTravelIcon {...rest} />,
    PrintingAndScanningOutlined: <TroubleTicket {...rest} />,
    TechnicalIssueOutlined: <InfrastructureServices {...rest} />,
    CreditCardRequestOutlined: <CreditCardOutlined {...rest} />,
    ExpenseReimbursementOutlined: <ExpenseIcon {...rest} />,
    HomeLoanRequestOutlined: <BankOutlined {...rest} />,
    LeaverequestOutlined: <CalendarOutlined {...rest} />,
    CashAdvanceRequestOutlined: <CashAdvanceRequest {...rest} />,
    SaleOfAssetInvoiceRequestOutlined: <ScrapRequest {...rest} />,
    ActingAppointmentOutlined: <ActingAppointmentIcon {...rest} />,
    DelegationOutlined: <AssignDelegateIcon {...rest} />,
    SoftwareOutlined: <TroubleTicket {...rest} />,
    JiraOutlined: <TroubleTicket {...rest} />,
    TawaliOutlined: <TroubleTicket {...rest} />,
    ERPOutlined: <TroubleTicket {...rest} />,
    BIOutlined: <TroubleTicket {...rest} />,
    BSSOutlined: <TroubleTicket {...rest} />,
    SmartTowerOutlined: <TroubleTicket {...rest} />,
    TMSOutlined: <TroubleTicket {...rest} />,
    SufraaOutlined: <TroubleTicket {...rest} />,
    UmlaaOutlined: <TroubleTicket {...rest} />,
    OperatingSystemOutlined: <TroubleTicket {...rest} />,
    ReportsOutlined: <Reports {...rest} />,
    ServiceEntryOutlined: <ServiceEntry {...rest} />,
    NewSiteAcquisitionOutlined: <SiteLease {...rest} />,
    IdBadgeOutlined: <IDBadge {...rest} />,
    HqItSupportOutlined: <HqItSupport {...rest} />,
    InfrastructureServicesNetworkOutlined: <InfrastructureServicesNetwork {...rest} />,
    TechnicalEventSupportOutlined: <TechnicalEventSupport {...rest} />,
    AssetRequestOutlined: <AssetRequest {...rest} />,
    AssetUpgradeOutlined: <AssetUpgrade {...rest} />,
    AssetDeAllocationOutlined: <AssetDeallocate {...rest} />,
    DatabaseOutlined: <Database {...rest} />,
    MiddlewareOutlined: <Middleware {...rest} />,
    IncidentOutlined: <Incident {...rest} />,
    SoftwareLicenseOutlined: <SoftwareLicence {...rest} />,
    ProjectRequestOutlined: <ProjectRequest {...rest} />,
    ProfileUpdateOutlined: <ProfileUpdateIcon {...rest} />,
    NonPOInvoiceOutlined: <NonPoRequestIcon {...rest} />,
    WorkfromHomeOutLined: <WfhIcon {...rest} />,
  };

  const getIcon = (type: string) => {
    // Default Icon when not found
    let comp = <QuestionOutlined />;

    let typeNew = type;

    // Default is Outlined when no theme was appended (ex: 'smile')
    if (!typeNew.match(/.+(Outlined|Filled|TwoTone)$/i)) {
      typeNew += 'Outlined';
    }

    // If found by key then return value which is component
    const found = Object.entries(Icons).find(([k]) => k.toLowerCase() === typeNew.toLowerCase());
    if (found) {
      [, comp] = found;
    }

    return comp;
  };

  return getIcon(type);
};

export default IconSelector;

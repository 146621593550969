import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetHistoricTaskListFail,
  GetHistoricTaskListStart,
  GetHistoricTaskListSuccess,
  getHistoricTaskListStart,
  getHistoricTaskListSuccess,
  getHistoricTaskListFail,
} from './historicTaskListSlice';

export const historicTaskListEpic: Epic<
  GetHistoricTaskListStart,
  GetHistoricTaskListSuccess | GetHistoricTaskListFail,
  RootState,
  typeof API
> = (action$, _, { historicTask }) =>
  action$.pipe(
    filter(getHistoricTaskListStart.match),
    mergeMap(({ payload }) =>
      from(historicTask.getHistoricTaskList(payload)).pipe(
        map(({ data }) => getHistoricTaskListSuccess(data)),
        catchError((error) => of(getHistoricTaskListFail(error?.response?.data)))
      )
    )
  );

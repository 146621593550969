import styled from 'styled-components';
import { Table, Typography } from 'antd';
import { colors, space } from 'ui/elements';

const { Text } = Typography;

export const LeaseSitesListWrapper = styled.div`
  width: 100%;
`;

export const StyledTable = styled(Table).attrs({ size: 'middle' })`
  .ant-tabs-nav-wrap {
    padding: 24px;
    background-color: ${colors.white};
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: ${colors.labelGrey};

    .ant-tabs-tab-active {
      font-weight: 500;
    }
  }

  .ant-table-thead > tr > th {
    background-color: ${colors.white};
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr:not(.ant-table-measure-row) > td {
    text-align: center;
    vertical-align: middle;
    padding: 16px 8px !important;
  }

  .ant-table-content {
    padding: 20px 0;
  }

  .ant-pagination.mini li {
    border: 1px solid ${colors.borderGrey} !important;
    margin-right: ${space.slim} !important;
    border-radius: 4px;
    padding-top: 3px;
    min-width: 32px;
    height: 32px;
  }

  .ant-pagination.mini.ant-table-pagination.ant-table-pagination-right {
    margin-left: 30px;
    margin-right: 15px;
  }

  .ant-pagination.mini .ant-pagination-total-text {
    border: none !important;
    margin-right: auto !important;
    font-size: 14px;
    color: ${colors.labelGrey};
  }

  .ant-pagination.mini .ant-pagination-item-active {
    border: 1px solid ${colors.primaryColor} !important;
  }
`;

export const StyledColumnHeader = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.labelGrey};
`;

export const StyledColumnValue = styled.p`
  color: ${colors.primaryColor};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
`;

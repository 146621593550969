import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { profileExpenses } from '../ProfileExpenses';

export function useExpensesDrawerInfo(expenseId: string, hcmId: string) {
  return useQuery(
    [QUERIES.expenseInfo, expenseId, hcmId],
    async () => (await profileExpenses.getExpenseDrawer(expenseId, hcmId)).data,
    {
      refetchOnWindowFocus: false,
    }
  );
}

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const myTaskCards = (state: RootState): RootState['myTaskCards'] => state.myTaskCards;

export const myTaskCardsSelector = createSelector(
  myTaskCards,
  ({ error, status, data, hasMore }) => ({
    error,
    status,
    data,
    hasMore,
  })
);

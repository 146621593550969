import styled from 'styled-components';
import { Typography, Card, Col, Steps, Divider, Collapse, Button, Row } from 'antd';
import { colors, typography, borderRadius, space } from 'ui/elements';
import { CloseCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

export const StyledDrawerTitle = styled(Text)`
  font-size: ${typography.size.giga};
  color: ${colors.labelGrey};
`;

export const StyledTitleCol = styled(Col)`
  top: -10px;
`;

export const StyledDrawerHeader = styled(Card)`
  padding: 24px;
  border-radius: ${borderRadius.large};
  background-color: ${colors.backgroundGrey};
  .ant-card-body {
    padding: 0px;
  }
  border: none;
`;

export const StyledDrawerPath = styled(Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.textColorSecondary};
`;

export const StyledCol = styled(Col)`
  text-align-last: end;
  place-self: center;
`;

export const StyledDrawerServiceName = styled(Text)`
  font-size: ${typography.size.tera};
  color: ${colors.primaryColor};
`;

export const StyledDrawerDescription = styled(Text)`
  font-size: ${typography.size.kilo};
  color: ${colors.textColorSecondary};
`;

export const StyledDrawerSteps = styled(Steps)`
  margin-top: 18px;
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: ${colors.white};
    border-color: black;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: black;
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: black;
  }
`;

export const StyledStepTitle = styled(Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.textColorSecondary};
`;

export const StyledDrawerStep = styled(Text)`
  font-size: ${typography.size.giga};
  color: ${colors.primaryColor};
`;

export const StyledDrawerStepContainer = styled(Row)`
  margin-top: 24px;
`;

export const StyledDivider = styled(Divider)`
  margin: 5px;
`;

export const StyledCollapse = styled(Collapse)`
  &.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    left: -16px;
    margin-top: 3px;
  }
  .ant-collapse-content {
    font-size: ${typography.size.hecto};
    color: ${colors.textColorSecondary};
  }
  .ant-collapse-arrow {
    top: 20px !important;
    padding: 0 0 0 8px !important;
  }
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    color: ${colors.white};
    background-color: ${colors.primaryColor};
    border-color: ${colors.primaryColor};

    &:hover,
    &:focus {
      color: ${colors.white};
      background-color: ${colors.accentPrimary};
      border-color: ${colors.accentPrimary};
    }
  }
`;

export const StyledList = styled.li`
  padding-bottom: ${space.moderate};
`;

export const StyledDrawerErrorWrapper = styled(Row)`
  border: 1px solid ${colors.red};
  box-sizing: border-box;
  border-radius: ${borderRadius.large};
  padding: ${space.moderate};
  margin-bottom: ${space.normal};
  display: flex;
  flex-direction: row;
  margin-top: 0;
  flex: 1;
  align-items: center;
`;

export const StyledErrorIcon = styled(CloseCircleOutlined)`
  color: ${colors.red};
  font-size: ${typography.size.giga};
  margin-right: ${space.cozy};
  margin-left: ${space.micro};
`;

export const StyledDrawerErrorMessage = styled(Text)`
  font-size: ${typography.size.hecto};
  color: black;
`;

export const StyledAdditionalStep = styled.li`
  list-style-type: disc;
`;

export const StyledLinkToRequestMessage = styled.a`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: ${colors.primaryColor};
  margin-left: ${space.comfy};
  margin-top: ${space.cozy};
  max-width: max-content;
  text-decoration: underline;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const StyledDrawerErrorMessageRow = styled(Row)`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

export const StyledDrawerErrorMessageColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

import styled from 'styled-components';
import { Typography } from 'antd';
import { colors } from 'ui/elements';

const { Title } = Typography;

export const TitleH1 = styled(Title).attrs({
  level: 1,
})<{ fontWeight?: number; primary?: boolean }>`
  &.ant-typography {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 28px;
    line-height: 33px;
    font-weight: ${($props) => $props.fontWeight ?? 600};
    color: ${($props) => ($props.primary ? colors.primaryColor : undefined)};
  }

  &.ant-typography.ant-typography-secondary {
    color: #8b93a2;
    font-weight: normal;
  }
`;

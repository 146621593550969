import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const shearchFilesResult = (state: RootState): RootState['oneDriveSearchFiles'] =>
  state.oneDriveSearchFiles;

export const searchFilesResultData = createSelector(
  shearchFilesResult,
  ({ error, status, data }) => ({ error, data, status })
);

import { from, of } from 'rxjs';
import { filter, map, catchError, exhaustMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';

import {
  PostSubmitFormStart,
  PostSubmitFormSuccess,
  PostSubmitFormFail,
  postSubmitFormStart,
  postSumbitFormSuccess,
  postSubmitFormFail,
} from './submitFormSlice';

export const submitFormEpic: Epic<
  PostSubmitFormStart,
  PostSubmitFormSuccess | PostSubmitFormFail,
  RootState,
  typeof API
> = (action$, _, { task }) =>
  action$.pipe(
    filter(postSubmitFormStart.match),
    exhaustMap(({ payload }) =>
      from(task.postLeaveTask(payload.hcmPersonId, payload.data)).pipe(
        map(({ data }) => postSumbitFormSuccess(data)),
        catchError((error) => of(postSubmitFormFail(error?.response?.data)))
      )
    )
  );

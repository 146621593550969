import React from 'react';
import { useTranslate } from 'translations';
import {
  StyledAnnouncementShareWrapper,
  StyledLinkedInIcon,
  StyledShareOnText,
} from './AnnouncementShare.styled';

export const AnnouncementShare: React.FC = () => {
  const { t } = useTranslate();
  return (
    <StyledAnnouncementShareWrapper>
      <StyledShareOnText>{t('announcement.shareOn')}</StyledShareOnText>
      <StyledLinkedInIcon />
    </StyledAnnouncementShareWrapper>
  );
};

import React, { ReactNode } from 'react';
import { HierarchyData } from 'types';
import { TeamsUserAvatar, UserPopover } from 'ui/components';
import { OrganizationalHierarchyTitle } from './OrganizationalHierarchyTitle';

export interface OrganizationalHierarchyDataNode {
  title?: ReactNode;
  key: string;
  isLeaf?: boolean;
  icon: JSX.Element;
  children?: OrganizationalHierarchyDataNode[];
  subordinatesIds?: string[];
}

export const organizationalHierarchyConvertToTree = (
  hierarchyData: HierarchyData[],
  id = '0'
): OrganizationalHierarchyDataNode[] =>
  hierarchyData.map((item, i) => {
    const newItem = {
      title: (
        <OrganizationalHierarchyTitle
          strong={item.self}
          userName={item.displayName}
          title={item.title}
          id={item.adOid}
          email={item.email}
          hcmId={item.id}
        />
      ),
      key: id + `-${i}`,
      icon: (
        <UserPopover
          {...(item.email
            ? { currentEmail: item.email }
            : item.adOid
            ? { currentID: item.adOid }
            : { hcmID: item.id })}
        >
          <TeamsUserAvatar
            email={item.email}
            id={item.adOid}
            name={item.displayName}
            statusSize={12}
            size={30}
          />
        </UserPopover>
      ),
    };

    if (item.subordinates) {
      return {
        ...newItem,
        children: organizationalHierarchyConvertToTree(item.subordinates, id + '-0'),
      };
    }

    if (item.subordinatesIds) {
      return {
        ...newItem,
        subordinatesIds: item.subordinatesIds,
        isLeaf: false,
      };
    }

    return {
      ...newItem,
      title: (
        <OrganizationalHierarchyTitle
          strong={item.self}
          userName={item.displayName}
          title={item.title}
          id={item.adOid}
          email={item.email}
          hcmId={item.id}
        />
      ),
      isLeaf: true,
    };
  });

import styled from 'styled-components';
import { colors, typography } from 'ui/elements';

export const EmployeeDirectoryUserCardStyledWrapper = styled.div`
  width: 100%;
  border-left: none;
  box-shadow: 1px 2px 4px 3px rgba(28, 43, 101, 0.14);
  padding: 24px;
  border-radius: 4px;
  background: ${colors.white};
  cursor: pointer;
  margin-bottom: 16px;

  &.is-selected {
    border-left: 5px solid ${colors.primaryColor};
    padding-left: 14px;
  }
`;

export const EmployeeDirectoryUserCardStyledName = styled.p`
  font-size: ${typography.size.kilo};
  font-weight: ${typography.weight.bold};
  color: ${colors.primaryColor};
  line-height: 20px;
  margin-bottom: 5px;
`;

export const EmployeeDirectoryUserCardStyledTitle = styled.p`
  font-size: ${typography.size.hecto};
  font-weight: ${typography.weight.regular};
  color: ${colors.grey};
  line-height: 20px;
  margin: 0;
`;

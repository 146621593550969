import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const oneDriveItemChildren = (state: RootState): RootState['oneDriveItemChildren'] =>
  state.oneDriveItemChildren;

export const oneDriveItemChildrenData = createSelector(
  oneDriveItemChildren,
  (oneDriveItemChildren) => oneDriveItemChildren
);

import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { RequestCardDetails } from 'types';
import { PathText, StyledCard, StyledCardTitle, StyledText } from './RequestsCatalogCards.styled';
import IconSelector from './IconSelector';

export const ICON_STYLE = {
  fontSize: 18,
  height: '21px',
  width: '21px',
};

interface Props {
  card: RequestCardDetails;
  onCardClick: (
    process:
      | {
          processDefinitionKey: string;
          name: string;
          notAllowToCreateRequest?: boolean;
          isDynamic?: boolean;
          isJira?: boolean;
        }
      | undefined
  ) => void;
  isPath: boolean;
  userDepartment?: string | null;
}

export const RequestsCatalogCategoryCardComponent: FC<Props> = ({
  card: {
    name,
    description,
    processDefinitionKey,
    processGroup,
    iconColor,
    isDynamic,
    isJira,
    active,
  },
  onCardClick,
  isPath,
  userDepartment,
}) => {
  const [notAllowToCreateProcess, setNotAllowToCreateProcess] = useState<boolean>(false);

  useEffect(() => {
    if (userDepartment) {
      if (processGroup) {
        if (processGroup.includes(userDepartment)) {
          setNotAllowToCreateProcess(false);
        } else {
          setNotAllowToCreateProcess(true);
        }
      } else if (active === undefined || active) {
        setNotAllowToCreateProcess(false);
      } else {
        setNotAllowToCreateProcess(true);
      }
    } else {
      setNotAllowToCreateProcess(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCardClickHandler = useCallback(() => {
    if (processDefinitionKey && name) {
      onCardClick({
        processDefinitionKey,
        name,
        notAllowToCreateRequest: notAllowToCreateProcess,
        isDynamic,
        isJira,
      });
    }
  }, [name, onCardClick, processDefinitionKey, notAllowToCreateProcess, isDynamic, isJira]);

  const [category, subCategory] = useMemo(
    () => processDefinitionKey.replaceAll('_', ' ').split('.'),
    [processDefinitionKey]
  );

  return (
    <StyledCard onClick={onCardClickHandler} isAllow={notAllowToCreateProcess}>
      <IconSelector
        type={name.replace(/\s/g, '').replace(/-/g, '')}
        style={{ fontSize: 24, height: '24px', width: '24px', color: iconColor }}
      />
      <StyledCardTitle>{name}</StyledCardTitle>
      <StyledText>{description}</StyledText>
      {isPath && <PathText>{`${category} / ${subCategory}`}</PathText>}
    </StyledCard>
  );
};

export const RequestsCatalogCategoryCard = memo(RequestsCatalogCategoryCardComponent);

import { useRequestGeneralInfo, useRequestRootPath } from 'api';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TaskStatus } from 'types';
import {
  DependenciesFieldsBetweenForms,
  FiledHiddenProp,
  FilterByPrettyIdType,
  FilterByRequestType,
  ServicesContext,
} from './ServicesContext';

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

export const ServiceProvider: FC = ({ children }) => {
  const [isFilterByRequest, setIsFilterByRequest] = useState<boolean>(false);

  const [requestInfoRedirection, setRequestInfoRedirection] = useState<FilterByRequestType>({});

  const [isFilterByPrettyId, setIsFilterByPrettyId] = useState<boolean>(false);

  const [prettyIdInfoRedirection, setPrettyIdInfoRedirection] = useState<FilterByPrettyIdType>({});

  const [
    dependenciesFieldsBetweenForms,
    setDependenciesFieldsBetweenForms,
  ] = useState<DependenciesFieldsBetweenForms>({});

  const [fieldIsHidden, setFieldHidden] = useState<FiledHiddenProp>({
    isHidden: null,
    fieldId: undefined,
  });

  const history = useHistory();
  const [rootPath, setRootPath] = useState<string | undefined>();
  const { mutateAsync: getRootPath } = useRequestRootPath();
  const { data: requestInfo, isFetched, refetch } = useRequestGeneralInfo(
    prettyIdInfoRedirection.processInstanceId ? prettyIdInfoRedirection.processInstanceId : null,
    rootPath,
    true
  );

  useEffect(() => {
    if (!isFilterByRequest) {
      setRequestInfoRedirection({});
    } else {
      history.replace({
        pathname: `/${requestInfoRedirection.redirectionPage}`,
        state: { defaultTab: requestInfoRedirection.redirectionTab },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestInfoRedirection.redirectionPage,
    requestInfoRedirection.redirectionTab,
    isFilterByRequest,
  ]);

  useEffect(() => {
    if (prettyIdInfoRedirection.processInstanceId && !disableAggregator) {
      getRootPath(prettyIdInfoRedirection.processInstanceId).then((remoteRootPath) => {
        setRootPath(remoteRootPath);
        refetch();
      });
    }
  }, [getRootPath, prettyIdInfoRedirection.processInstanceId, refetch]);

  useEffect(() => {
    if (requestInfo && isFetched) {
      const requestStatus = requestInfo.tasks.slice(-1)[0].status;

      setPrettyIdInfoRedirection({
        ...prettyIdInfoRedirection,
        requestStatus: requestStatus as TaskStatus,
      });
      setIsFilterByPrettyId(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestInfo, isFetched]);

  useEffect(() => {
    if (
      isFilterByPrettyId &&
      prettyIdInfoRedirection.redirectionPage &&
      prettyIdInfoRedirection.redirectionTab
    ) {
      history.replace({
        pathname: `/${prettyIdInfoRedirection.redirectionPage}/${prettyIdInfoRedirection.processInstanceId}`,
        state: { defaultTab: prettyIdInfoRedirection.redirectionTab },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterByPrettyId, prettyIdInfoRedirection]);

  return (
    <ServicesContext.Provider
      value={{
        isFilterByRequest,
        setIsFilterByRequest,
        requestInfoRedirection,
        setRequestInfoRedirection,
        isFilterByPrettyId,
        setIsFilterByPrettyId,
        fieldIsHidden,
        setFieldHidden,
        prettyIdInfoRedirection,
        setPrettyIdInfoRedirection,
        dependenciesFieldsBetweenForms,
        setDependenciesFieldsBetweenForms,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PublicHolidays, RequestCommonError, RequestStatus } from 'types';

import { PublicHolidaysState } from './publicHolidaysTypes';

export const emptyPublicHolidaysState: PublicHolidaysState = {
  status: RequestStatus.idle,
  error: null,
  data: [],
};

export type GetPublicHolidaysStart = PayloadAction;
export type GetPublicHolidaysSuccess = PayloadAction<PublicHolidays[]>;
export type GetPublicHolidaysFail = PayloadAction<RequestCommonError>;

const publicHolidays = createSlice({
  name: 'publicHolidays',
  initialState: emptyPublicHolidaysState,
  reducers: {
    getPublicHolidaysStart(state, _: GetPublicHolidaysStart) {
      state.status = RequestStatus.pending;
    },
    getPublicHolidaysSuccess(state, action: GetPublicHolidaysSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getPublicHolidaysFail(state, action: GetPublicHolidaysFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getPublicHolidaysStart,
  getPublicHolidaysSuccess,
  getPublicHolidaysFail,
} = publicHolidays.actions;

export default publicHolidays.reducer;

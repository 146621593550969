import { useQuery } from 'react-query';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';
import { leaves } from '../Leaves';

export function useDrawerMyLeaveDetail(leaveId: string) {
  return useQuery(
    [QUERIES.myLeaveDrawer, leaveId],
    async () => {
      const { data } = await leaves.getLeaveInfo(leaveId);
      return data;
    },
    {
      enabled: false,
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

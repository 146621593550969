import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

type Props = typeof Text & {
  readonly $withOutUnderline?: boolean;
  readonly size?: number;
};

export const TextStyled = styled(Text).attrs({})`
  font-size: ${(props: Props) => (props.size ? props.size + 'px' : 'inherit')};
  &.ant-typography {
    display: inline;
  }
  &:hover {
    text-decoration: ${(props: Props) => (props.$withOutUnderline ? 'none' : 'underline')};
  }
`;

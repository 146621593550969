import { OneDrivePages, Pages, MSPlannerPages, LeavesPages } from './routes.enum';

export type PageTypes = {
  [page in Pages]: string;
};

export const PagesUrls: PageTypes = {
  Dashboard: '/home',
  MSTeams: '/ms-teams',
  MSPlanner: '/ms-planner',
  Tasks: '/tasks',
  Settings: '/settings',
  EmploymentDetails: '/employment-details',
  Profile: '/profile',
  NotFound: '/404',
  Login: '/',
  OneDrive: '/one-drive',
  OtherUserProfile: '/user-profile',
  Leaves: '/leaves',
  ProcessManagement: '/Process-management',
  MyWork: '/my-work',
  PersonalProfile: '/profile?tab=2',
  Expenses: '/expenses',
  Attendance: '/attendance',
  EmployeeDirectory: '/employee-directory',
  ELibrary: '/e-library',
  AnnouncementDetail: '/announcement-detail',
  LeaseSites: '/lease-sites',
};

type OneDrivePageType = {
  [page in OneDrivePages]: string;
};

export const OneDrivePageRoutes: OneDrivePageType = {
  MyFiles: '/my-files',
  Shared: '/shared',
  SearchResult: '/search-result',
  SharedLibrary: '/shared-libraries/:groupId/:libraryId',
  SharedLibraries: '/shared-libraries',
};

type MSPlannerPageType = {
  [page in MSPlannerPages]: string;
};

export const MSPlannerPageRoutes: MSPlannerPageType = {
  PlannerHub: '/planner-hub',
  MyTasks: '/my-tasks',
  Project: '/project/:projectId',
};

type LeavesPagesType = {
  [page in LeavesPages]: string;
};

export const LeavesPageRoutes: LeavesPagesType = {
  Calendar: '/calendar',
  List: '/list',
  NewLeaveRequest: '/new-leave-request',
};

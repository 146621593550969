import React, { FC, useCallback, useState, useMemo } from 'react';
import { Form, notification, Row, Col } from 'antd';
import { task as taskApi, useTaskFormData, useLeaseSitesRequestAssignWoTaskMutation } from 'api';
import { TaskStatus } from 'types';
import { useTranslate } from 'translations';
import moment from 'moment';
import { profileData as profileDataSelector, useSelector } from 'store';
import { useHistory } from 'react-router-dom';
import { PagesUrls } from 'namespace';
import { useTasksContext } from 'routes';
import {
  AttachmentFile,
  checkWord,
  convertToVariables,
  filterEmptyFields,
  isAttacmentsPresent,
  submitActiveTask,
} from '../helper';
import { Forms } from '../TaskSteps.types';
import { TaskStep } from '../TaskStep';
import { StyledActiveTaskControlWrapper } from '../TasksSteps.styled';
import { ButtonsCol } from './TaskSubmitForm.styled';
import { TaskSubmitFormProps } from './TaskSubmitForm.types';

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';
const fillWhenRejectionFields = ['feedback', 'Reason', 'reason'];
const PROVIDE_BANK_DOCUMENTS = 'Provide Bank Documents';

export const TaskSubmitForm: FC<TaskSubmitFormProps> = ({
  taskInfo,
  groupName,
  processDefinitionId,
  processInstanceId,
  activityId,
  reloadTaskList,
  isFetched,
  tasksStatus,
  isMyGroupTask = false,
  processDefinitionKey,
}) => {
  const { t } = useTranslate();
  const { data: profileData } = useSelector(profileDataSelector);
  const [form] = Form.useForm();
  const [allTasksFormInstances, setAllTasksFormInstances] = useState<Forms | null>(null);
  const [isFormLoading, setFormLoading] = useState<boolean>(false);

  const { rootPath } = useTasksContext();
  const history = useHistory();

  const isAssignee = taskInfo.taskAssignee?.adoid;
  const isCurrentUser = isAssignee === profileData?.adOid;
  const taskId = taskInfo?.taskId;

  const formDataInfo = useTaskFormData({
    taskId,
    rootPath,
    isMyGroupTask,
    isCurrentUser,
    activityId,
  });
  const formData = useMemo(() => formDataInfo.data || [], [formDataInfo.data]);
  const leaseAssignTaskMutation = useLeaseSitesRequestAssignWoTaskMutation({
    processInstanceId,
    taskId: taskInfo.taskId,
    activityId,
    processDefinitionId,
  });

  const isLoading = isFormLoading || formDataInfo.isLoading || leaseAssignTaskMutation.isLoading;

  const onSuccessTaskApprove = useCallback(() => {
    notification.success({
      message: t('messages.success'),
      description: t('messages.success.task.completed'),
    });
    reloadTaskList();
    history.push(PagesUrls.MyWork);
  }, [history, reloadTaskList, t]);

  const onFormFinish = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (formName: string, info: { values: any; forms: any }) => {
      if (taskInfo.taskDefinitionKey === 'ASSIGN_WO_FIELD_ASSIGNMENT_TASK') {
        leaseAssignTaskMutation.mutate(info.values, { onSuccess: onSuccessTaskApprove });
        return;
      }

      const taskName = taskInfo?.taskName;
      const taskId = taskInfo?.taskId;

      const attachmentsForm: AttachmentFile[] = [];

      if (formName === taskName) {
        const formValues = Object.keys(info.values);
        const attachmentFields = formValues.filter(
          (k) => k.includes('attachment') || k.includes('Attachment')
        );
        const attachmentFieldId = attachmentFields.length > 0 ? attachmentFields[0] : 'attachment';
        const attachments = info.values[attachmentFieldId];
        if (attachments) {
          attachmentsForm.push({ [attachmentFieldId]: attachments });
        }
        delete info.values[attachmentFieldId];

        const dateFields = formValues.filter((k) => k.includes('date') || k.includes('Date'));

        if (dateFields.length > 0) {
          dateFields.forEach((key) => {
            if (info.values[key] instanceof moment) {
              info.values = {
                ...info.values,
                [key]: info.values[key].format('DD/MM/YYYY'),
              };
            }
          });
        }

        const rejectFeedBack = formValues.filter((key) => key.includes('rejected'));
        const feedBackField = formValues.filter((key) => checkWord(fillWhenRejectionFields, key));
        const checkRejectedWithFeedback = submitActiveTask(rejectFeedBack, feedBackField, info);
        if (checkRejectedWithFeedback) {
          notification.error({
            message: t('title.error.feedbackRequire'),
            description: t('messages.error.fillFeedbackField'),
          });
        } else if (taskName === PROVIDE_BANK_DOCUMENTS && !isAttacmentsPresent(attachmentsForm)) {
          notification.error({
            message: t('title.error.attachmentsRequired'),
            description: t('messages.error.attachmentsRequired'),
          });
        } else {
          if (taskId) {
            try {
              setFormLoading(true);
              const attachmentPromises = attachmentsForm.map((attachments: AttachmentFile) => {
                return Object.entries(attachments).map(([attachmentName, attachmentFile]) => {
                  return attachmentFile.map(async (attachmentInfo) => {
                    const formData = new FormData();
                    formData.append('content', attachmentInfo.originFileObj as Blob);
                    formData.append('attachmentName', attachmentInfo.name);
                    formData.append('filedId', attachmentName);
                    formData.append('attachmentDescription', attachmentInfo.name);
                    formData.append('attachmentType', attachmentInfo.type);

                    return await taskApi.postTaskAttachment(
                      taskId,
                      processInstanceId,
                      activityId,
                      processDefinitionId,
                      formData,
                      undefined,
                      rootPath
                    );
                  });
                });
              });
              await Promise.all([
                disableAggregator
                  ? taskApi.postTask(
                      taskId,
                      {
                        variables: convertToVariables(filterEmptyFields(info.values)),
                      },
                      rootPath
                    )
                  : taskApi.postTaskApproval(
                      taskId,
                      {
                        variables: convertToVariables(filterEmptyFields(info.values)),
                      },
                      rootPath
                    ),
                attachments ? Promise.all(attachmentPromises) : undefined,
              ]);
            } catch (e) {
              notification.error({
                message: t('messages.error.wentWrong'),
                description: t('messages.error.fillForm'),
              });
              return;
            } finally {
              setFormLoading(false);
            }
          }
          onSuccessTaskApprove();
        }
      }
    },
    [
      activityId,
      leaseAssignTaskMutation,
      onSuccessTaskApprove,
      processDefinitionId,
      processInstanceId,
      rootPath,
      t,
      taskInfo.taskDefinitionKey,
      taskInfo?.taskId,
      taskInfo?.taskName,
    ]
  );

  const onFormChange = useCallback(
    (values, changes) => {
      const taskName = taskInfo?.taskName;
      const tasksForms = Object.fromEntries(
        Object.entries(changes.forms).filter(([formName]) => formName === taskName)
      );

      if (allTasksFormInstances === null) {
        setAllTasksFormInstances(tasksForms as Forms);
        return;
      }

      const onlyNewForms = Object.entries(tasksForms).filter(
        ([formName]) => !allTasksFormInstances[formName]
      );

      if (onlyNewForms.length) {
        setAllTasksFormInstances({
          ...allTasksFormInstances,
          ...(Object.fromEntries(onlyNewForms) as Forms),
        });
      }
    },
    [allTasksFormInstances, taskInfo?.taskName]
  );

  return (
    <>
      <Form.Provider onFormChange={onFormChange} onFormFinish={onFormFinish}>
        <Row>
          <Col span={24}>
            <TaskStep
              highlighted
              isOpened
              status={taskInfo.status as TaskStatus}
              taskData={taskInfo}
              groupName={groupName}
              reloadTaskList={reloadTaskList}
              formData={formData}
              isLoading={isLoading}
              isAssignee={isAssignee}
              form={form}
              tasksStatus={tasksStatus}
              processDefinitionKey={processDefinitionKey}
              processId={processInstanceId}
            />
          </Col>
        </Row>
        {taskInfo && !isLoading && (isCurrentUser || isMyGroupTask) && (
          <StyledActiveTaskControlWrapper>
            <ButtonsCol />
          </StyledActiveTaskControlWrapper>
        )}
      </Form.Provider>
    </>
  );
};

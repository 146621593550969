import React, { FC } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';

import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { ChatMessageData } from 'types';
import { UserPopover } from 'ui';
import { ChatMessage } from '../ChatMessage';
import { ChatItemTitle } from './ChatItem.styled';

interface Props {
  title: string;
  messages: (ChatMessageData & { title?: string })[];
}

export const ChatItem: FC<Props> = ({ title, messages }) => {
  const messagesList = messages.map(
    ({ id, isMine, createdDateTime, body, from, attachments }, i, array) => {
      const dynamicStyles = {
        marginBottom: array[++i] && isMine !== array[i].isMine ? 10 : undefined,
      };

      return (
        <Row key={i} gutter={[24, 0]} wrap={false} justify="start" style={dynamicStyles}>
          <Col>
            <UserPopover currentID={from?.user?.id}>
              <MSTeamsUserAvatar
                id={from?.user?.id}
                name={from?.user?.displayName}
                statusSize={12}
                size={40}
              />
            </UserPopover>
          </Col>

          <Col>
            <ChatMessage
              id={id}
              title={from?.user?.displayName}
              createdDateTime={createdDateTime}
              message={body.content}
              messageType={body.contentType}
              attachments={attachments}
              withReplyButton
            />
          </Col>
        </Row>
      );
    }
  );

  return (
    <Row>
      <Col span={24}>
        <ChatItemTitle type="secondary">{moment(title).format('DD MMMM YYYY')}</ChatItemTitle>
      </Col>
      <Col span={24}>{messagesList}</Col>
    </Row>
  );
};

import React, { useMemo } from 'react';
import { TType } from 'translations';
import { IEducationalDegree } from 'types/NewProfile';
import { PrivateProfileEducationalDegreeItem } from './PrivateProfileEducationalDegreeItem';

type Props = {
  t: TType;
  educationalDegrees: IEducationalDegree[];
};

export const PrivateProfileEducationalDegree: React.FC<Props> = ({ t, educationalDegrees }) => {
  const dataEducationalDegrees: IEducationalDegree[] = useMemo(() => {
    return educationalDegrees;
  }, [educationalDegrees]);
  return (
    <>
      {dataEducationalDegrees.map((item, index) => (
        <PrivateProfileEducationalDegreeItem key={index} t={t} educationalDegree={item} />
      ))}
    </>
  );
};

import React, { FC, useCallback, useEffect } from 'react';
import { Col, Drawer, notification, Row, Space } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { FullWidthSpin, TitleH4, UserPopover } from 'ui';
import Text from 'antd/lib/typography/Text';
import { useTranslate } from 'translations';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { RequestStatus } from 'types';
import { MSTeamsUserAvatar } from '../MSTeamsUserAvatar';
import { msTeamsTeamInfoDrawerConnector, ConnectorProps } from './MSTeamsTeamInfoDrawer.connector';

export interface MSTeamsTeamInfoDrawerProps {
  channelId: string;
  teamId: string;
  description?: string;
  drawerProps: DrawerProps;
}

export const MSTeamsTeamInfoDrawerComponent: FC<MSTeamsTeamInfoDrawerProps & ConnectorProps> = (
  props
) => {
  const {
    description,
    drawerProps,
    teamId,
    channelId,
    getTeamsChannelMembersStart,
    currentChannelMembersData,
  } = props;

  const { t } = useTranslate();

  const onAfterVisibleChange = useCallback((visible: boolean) => {
    if (visible) {
      forceCheck();
    }
  }, []);

  useEffect(() => {
    if (
      currentChannelMembersData?.status !== RequestStatus.pending &&
      !currentChannelMembersData?.data
    ) {
      getTeamsChannelMembersStart({ teamId, channelId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentChannelMembersData?.status === RequestStatus.rejected) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: currentChannelMembersData?.error?.message,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChannelMembersData?.status]);

  return (
    <Drawer
      className="MSTeamsDrawer"
      closable={false}
      maskStyle={{ backgroundColor: 'transparent' }}
      getContainer={false}
      style={{ position: 'absolute' }}
      width={460}
      afterVisibleChange={onAfterVisibleChange}
      {...drawerProps}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <TitleH4>{t('msteams.channelInfo.title')}</TitleH4>
        </Col>
        {description && (
          <Col span={24}>
            <Text type="secondary">{description}</Text>
          </Col>
        )}
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row align="middle">
            <Col>
              <Text style={{ fontSize: 16 }} strong>
                {t('msteams.channelInfo.listTitle')}{' '}
                {currentChannelMembersData?.data && `(${currentChannelMembersData.data.length})`}
              </Text>
            </Col>
          </Row>
        </Col>

        {currentChannelMembersData?.status === RequestStatus.pending ? (
          <Col span={24}>
            <Row justify="center">
              <Col>
                <FullWidthSpin />
              </Col>
            </Row>
          </Col>
        ) : (
          currentChannelMembersData?.data?.map((member, i) => (
            <Col key={i} span={24}>
              <LazyLoad height={32} overflow scrollContainer=".MSTeamsDrawer .ant-drawer-body">
                <Space size={12}>
                  <UserPopover currentID={member.userId}>
                    <MSTeamsUserAvatar id={member.userId} name={member.displayName} size={32} />
                  </UserPopover>
                  <Text>{member.displayName}</Text>
                </Space>
              </LazyLoad>
            </Col>
          ))
        )}
      </Row>
    </Drawer>
  );
};

export const MSTeamsTeamInfoDrawer = msTeamsTeamInfoDrawerConnector(MSTeamsTeamInfoDrawerComponent);

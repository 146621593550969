import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from 'api';
import { staticForm } from 'api/StaticForm';

export function useBusinessTravelForm(adoId: string) {
  return useQuery(
    [QUERIES.businessTravel, adoId],
    async () => (await staticForm.getBusinessTravel(adoId)).data,
    {
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

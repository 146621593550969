import { useDashboardApps } from 'api/DashboardApps';
import React from 'react';
import { AppsDrawer } from 'routes/Dashboard/Components/templates/AppsDrawer';
import { AppsIcon, WrapperHeadIcon } from '../Header.styled';

const AppIconComponent: React.FC = () => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const appsList = useDashboardApps();

  const handleVisible = React.useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);
  return (
    <React.Fragment>
      <WrapperHeadIcon>
        <AppsIcon onClick={handleVisible} />
      </WrapperHeadIcon>
      {isVisible && appsList.data && (
        <AppsDrawer appsList={appsList.data} onDrawerClose={handleVisible} />
      )}
    </React.Fragment>
  );
};

export const AppIcon = React.memo(AppIconComponent);

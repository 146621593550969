import { HierarchyData } from 'types';
import { Core, HIERARCHY } from 'api';

interface GetHierarchy {
  (userId: string): Promise<{ data: HierarchyData }>;
}

class Hierarchy extends Core {
  getHierarchy: GetHierarchy = async (userId) => this.get(HIERARCHY(userId));
}

export const hierarchy = new Hierarchy();

import { Gutter } from 'antd/lib/grid/row';
import { typography } from 'ui/elements';
import { DirectionSpace } from '../templates';

export const fieldsFormName = {
  startDate: 'startDate',
  endDate: 'endDate',
  reason: 'reason',
  unpaidOther: 'unpaidOther',
  moreDetails: 'moreDetails',
  attachment: 'attachment',
  finishDate: 'finishDate',
};

export const leaveFormName = 'Fill the form and submit';
export const unpaidOther = 'OTHER' || 'Other';
export const halfDayLeaveBalance = 1;
export const selectWidth = 365;
export const maxLength = 1024;
export const DEFAULT_CALENDAR_ITEM_SPAN = 24;
export const GUTTER_12: [Gutter, Gutter] = [12, 12];

export const DEFAULT_PROPS_SPACE = {
  testValueStyle: { fontSize: typography.size.kilo },
  isTextValueStrong: true,
  direction: 'vertical' as DirectionSpace,
  size: 2,
};
export const STRONG_LABEL_PROPS_SPACE = {
  isLabelValueStrong: true,
  direction: 'vertical' as DirectionSpace,
  size: 2,
};

export const SPAN_SIZE_3 = 3;
export const SPAN_SIZE_10 = 10;
export const SPAN_SIZE_12 = 12;
export const SPAN_SIZE_24 = 24;

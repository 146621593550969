import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Header } from 'app';
import { AppLayout } from 'layouts';
import { StyledTab, TabPaneContent } from 'routes';
import { myTeamList } from 'api/mocks/data/myTeamList';
import { profileData as profileDataSelector } from 'store';
import { useTranslate } from 'translations';
import { ProfileData } from 'types';
import { TitleH1 } from 'ui';
import qs from 'query-string';
import { MyAttendance, MyTeamAttendance } from './Pages';

const { TabPane } = StyledTab;

export const Attendance: FC = () => {
  const history = useHistory();
  const { t } = useTranslate();
  const { data } = useSelector(profileDataSelector);
  const profileData = data as ProfileData;
  const [teamList] = useState(myTeamList);
  const search = useMemo(() => qs.parse(history.location.search) as { tab: string | undefined }, [
    history.location,
  ]);

  return (
    <AppLayout displayFooter={false}>
      <Header>
        <Row align="middle">
          <Col>
            <TitleH1>{t('menu.attendance')}</TitleH1>
          </Col>
        </Row>
      </Header>
      <StyledTab activeKey={search.tab} className="layout-main">
        {profileData.hcmId && (
          <>
            <TabPane tab={t('attendance.tab.myAttendance')} key="1">
              <TabPaneContent>
                <MyAttendance hcmPersonId={profileData.hcmId} hireDate={profileData.hireDate} />
              </TabPaneContent>
            </TabPane>
            {teamList && teamList.length > 0 && (
              <TabPane tab={t('attendance.tab.myTeamAttendance')} key="2">
                <TabPaneContent>
                  <MyTeamAttendance />
                </TabPaneContent>
              </TabPane>
            )}
          </>
        )}
      </StyledTab>
    </AppLayout>
  );
};

import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from 'api';
import { notificationCenter } from '../NotificationCenter';

export function useUnreadNotificationCount() {
  return useQuery(
    [QUERIES.unreadNotification],
    async () => {
      const { data } = await notificationCenter.getUnreadNotificationCount();
      return data;
    },
    { ...PERSIST_QUERY_CONFIG }
  );
}

import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const CenteredText = styled(Text)`
  align-self: center;
  width: 100%;
  text-align: center;
`;

import { Col, Form, Row, Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  StyleDatePicker,
  StyledLabel,
  StyledNewRequestCheckBox,
} from 'routes/Calendar/page/NewRequest/NewRequest.styled';
import moment from 'moment';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormInstance } from 'antd/es';
import { useTranslate } from '../../../translations';
import { GUTTER_16, selectWidth } from '../../../routes';
import { useLeaveRequestAssignedDelegates } from '../../../api';
import { selectFilterOption } from '../../../utils/getFilterOptions';
import { useDelegationFormdDetails } from '../../../api/StaticForm/hooks/Delegation';
import { FullWidthSpin } from '../../elements';
import { LeaveAssignedDelegates } from './LeaveAssignedDelegates';

export interface LeaveAssignDelegateFormProps {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  form: FormInstance;
}

export const LeaveAssignDelegateForm: React.FC<LeaveAssignDelegateFormProps> = ({
  startDate,
  endDate,
  form,
}: LeaveAssignDelegateFormProps) => {
  const { t } = useTranslate();
  const assignedDelegateMutation = useLeaveRequestAssignedDelegates();

  const [isCheckedAssignDelegate, setIsCheckedAssignDelegate] = useState(false);

  const { data } = useDelegationFormdDetails();

  const delegationDate = (isStartDate: boolean): JSX.Element => {
    return (
      <Col span={12}>
        <Form.Item
          label={
            <StyledLabel>
              {t(
                isStartDate
                  ? `leaves.newRequest.delegationStartDate`
                  : `leaves.newRequest.delegationEndDate`
              )}
            </StyledLabel>
          }
          rules={[
            {
              required: isCheckedAssignDelegate,
            },
          ]}
          name={isStartDate ? 'delegationStartDate' : 'delegationEndDate'}
          id={isStartDate ? 'delegationStartDate' : 'delegationEndDate'}
        >
          <StyleDatePicker
            disabled
            defaultValue={isStartDate ? startDate : endDate}
            value={isStartDate ? startDate : endDate}
            format="DD/MM/YYYY"
          />
        </Form.Item>
      </Col>
    );
  };

  const handleOnCheckBoxChange = useCallback((e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    setIsCheckedAssignDelegate(checked);
    form.resetFields(['delegateId']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldComponentVisible = useCallback(() => {
    return !!startDate && !!endDate;
  }, [startDate, endDate]);

  const shouldAssignDelegateComponentVisible = useCallback(() => {
    return (
      shouldComponentVisible() &&
      !assignedDelegateMutation.isLoading &&
      (assignedDelegateMutation?.data ?? []).length <= 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldComponentVisible, assignedDelegateMutation.isLoading]);

  const shouldAssignedDelegateComponentVisible = useCallback(() => {
    return (
      shouldComponentVisible() &&
      !assignedDelegateMutation.isLoading &&
      (assignedDelegateMutation?.data ?? []).length > 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldComponentVisible, assignedDelegateMutation.isLoading]);

  const delegateOptions = useCallback(() => {
    return data?.delegatesNameList.map((item) => {
      return {
        key: item.id,
        value: item.id,
        label: item.name,
        ...item,
      };
    });
  }, [data]);

  useEffect(() => {
    if (!shouldAssignDelegateComponentVisible()) {
      setIsCheckedAssignDelegate(false);
    } else {
      form.setFieldsValue({ delegationStartDate: startDate });
      form.setFieldsValue({ delegationEndDate: endDate });
    }
  }, [shouldAssignDelegateComponentVisible, form, startDate, endDate]);

  useMemo(() => {
    if (shouldComponentVisible()) {
      assignedDelegateMutation.mutate({
        startDate: moment(startDate).format('DD/MM/YYYY'),
        endDate: moment(endDate).format('DD/MM/YYYY'),
      });
    } else {
      setIsCheckedAssignDelegate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldComponentVisible]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [isCheckedAssignDelegate]);

  return (
    <>
      {shouldAssignDelegateComponentVisible() && (
        <Row gutter={[24, 24]}>
          <StyledNewRequestCheckBox onChange={handleOnCheckBoxChange}>
            {t('leaves.newRequest.assignDelegate')}
          </StyledNewRequestCheckBox>
          {isCheckedAssignDelegate && (
            <>
              <Col span={24}>
                <Form.Item
                  label={<StyledLabel>{t('leaves.newRequest.delegateName')}</StyledLabel>}
                  rules={[
                    {
                      required: isCheckedAssignDelegate,
                      message: t('leaves.newRequest.validation.delegateName'),
                    },
                  ]}
                  name={'delegateId'}
                  id={'delegateId'}
                >
                  <Select
                    style={{ width: selectWidth }}
                    placeholder={t('leaves.newRequest.placeholder.delegateName')}
                    optionFilterProp="children"
                    options={delegateOptions() ?? []}
                    notFoundContent={t('dropdown.noMatching')}
                    showSearch
                    filterOption={selectFilterOption}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Row justify={'space-between'} gutter={GUTTER_16}>
                  {delegationDate(true)}
                  {delegationDate(false)}
                </Row>
              </Col>
            </>
          )}
        </Row>
      )}
      {shouldAssignedDelegateComponentVisible() && (
        <LeaveAssignedDelegates delegation={assignedDelegateMutation?.data ?? []} />
      )}
      {shouldComponentVisible() && assignedDelegateMutation.isLoading && (
        <Col span={24}>
          <FullWidthSpin />
        </Col>
      )}
    </>
  );
};

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetAttendanceInfoFail,
  GetAttendanceInfoStart,
  GetAttendanceInfoSuccess,
  getAttendanceInfoStart,
  getAttendanceInfoSuccess,
  getAttendanceInfoFail,
} from './attendanceInfoSlice';
import { mapData } from './mapData';

export const getAttendanceInfoEpic: Epic<
  GetAttendanceInfoStart,
  GetAttendanceInfoSuccess | GetAttendanceInfoFail,
  RootState,
  typeof API
> = (action$, _, { attendanceInfo }) =>
  action$.pipe(
    filter(getAttendanceInfoStart.match),
    exhaustMap(({ payload }) =>
      from(attendanceInfo.getAttendanceInfo(payload.hcmPersonId, payload.date)).pipe(
        map(({ data }) => getAttendanceInfoSuccess(mapData(data))),
        catchError((error) => of(getAttendanceInfoFail(error?.response?.data)))
      )
    )
  );

import {
  usePublicHolidaysApi,
  useWFHDurationCalculation,
  useWFHRemainingBalance,
  useWFHSubmitApi,
} from 'api';
import { useCallback, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { FormInstance } from 'antd/lib/form';
import { WFHFieldIds } from 'types';
import { profileData, useSelector } from 'store';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTasksContext } from '../../../../../TasksProvider';
import { useTranslate } from '../../../../../../../translations';

interface UseWFHRequestProps {
  form: FormInstance;
  adoId?: string;
}
export const useWFHRequest = ({ form }: UseWFHRequestProps) => {
  const { setFields } = form;
  const { t } = useTranslate();
  const { data: ProfileDate } = useSelector(profileData);
  const hcmPersonId = ProfileDate?.hcmId as string;
  const [durationWFHDays, setDurationWFHDays] = useState(0);
  const [remainingWFHDays, setRemainingWFHDays] = useState(0);
  const { setIsSubmitDisable } = useTasksContext();
  const history = useHistory();
  const { mutateAsync: mutateAsyncWFHSubmitApi, isLoading: isLoadingSubmitAPi } = useWFHSubmitApi(
    hcmPersonId
  );

  const {
    data: publicHolidays,
    isLoading: isLoadingPublicHolidays,
    isSuccess: isSuccessPublicHolidays,
  } = usePublicHolidaysApi();

  const {
    data: wfhBalance,
    isLoading: isLoadingWFHBalance,
    isSuccess: isSuccessWFHBalance,
  } = useWFHRemainingBalance(hcmPersonId as string);

  /**
   * Balance of wfh days
   */
  const wfhDaysBalance = useMemo(() => {
    if (isSuccessWFHBalance) {
      const remainingBalance = wfhBalance?.remaining_balance;
      if (remainingBalance?.includes('-')) {
        return 0;
      }
      return Number.parseInt(remainingBalance as string, 10);
    }
    return 0;
  }, [isSuccessWFHBalance, wfhBalance?.remaining_balance]);

  useMemo(() => {
    setRemainingWFHDays(wfhDaysBalance);
  }, [wfhDaysBalance]);

  const setErrorTORangeDatePicker = useCallback(
    (errorMessage: string) => {
      /**
       * Set validation error message to form
       */
      setFields([
        {
          name: WFHFieldIds.startDateEndDate,
          errors: [errorMessage],
        },
      ]);

      setRemainingWFHDays(wfhDaysBalance);
      setDurationWFHDays(0);
      setTimeout(() => {
        setIsSubmitDisable(true);
      }, 100);
    },
    [setFields, wfhDaysBalance, setIsSubmitDisable]
  );

  /**
   * Handle the exception
   * while calculating duration
   *
   * all the exception will be handled by Back End
   * and this method will check and display that errors to FE
   */
  const handleOnCalculateDurationError = useCallback(
    (errorMessage: string) => {
      setErrorTORangeDatePicker(errorMessage);
    },
    [setErrorTORangeDatePicker]
  );

  const {
    mutateAsync: mutationAsyncDurationCalculation,
    isLoading: isLoadingCalculateDuration,
  } = useWFHDurationCalculation(handleOnCalculateDurationError);

  useMemo(() => {
    setIsSubmitDisable(isLoadingSubmitAPi || isLoadingCalculateDuration);
  }, [setIsSubmitDisable, isLoadingSubmitAPi, isLoadingCalculateDuration]);

  /**
   * Check calling the end point
   * for validating dates
   */
  const isValidatingDateRange = useMemo(() => {
    return false;
  }, []);

  /**
   * Call to api calculate
   * days based on selected start date and end date
   */
  const calculateWFHBalanceDays = useCallback(
    (selectedDays: number) => {
      setDurationWFHDays(selectedDays);
      setRemainingWFHDays(wfhDaysBalance);

      if (wfhDaysBalance !== undefined) {
        const balance = wfhDaysBalance - selectedDays;
        setRemainingWFHDays(balance);
        if (balance < 0) {
          setTimeout(() => {
            //Disable the submit button
            setIsSubmitDisable(true);
            //Show erorr on the Date range picker
            setFields([
              {
                name: WFHFieldIds.startDateEndDate,
                errors: [t('wfh.request.wfhbalance.exceedsRemainigBalance')],
              },
            ]);
          }, 100);
        } else {
          setIsSubmitDisable(false);
        }
      }
    },
    [wfhDaysBalance, setIsSubmitDisable, setFields, t]
  );

  /**
   * isLoading
   *  - wfh balance
   *  - public holidays
   *  - submit wfh request
   */
  const spinning = useMemo(() => {
    return isLoadingPublicHolidays || isLoadingWFHBalance || isLoadingSubmitAPi;
  }, [isLoadingPublicHolidays, isLoadingWFHBalance, isLoadingSubmitAPi]);

  const calculateDurationApiCall = useCallback(
    (startDate: moment.Moment, endDate: moment.Moment) => {
      mutationAsyncDurationCalculation({
        endDate: moment(endDate).format('YYYY-MM-DD'),
        startDate: moment(startDate).format('YYYY-MM-DD'),
      }).then((response) => {
        calculateWFHBalanceDays(Math.floor(response.duration));
      });
    },
    [calculateWFHBalanceDays, mutationAsyncDurationCalculation]
  );

  /**
   * It will validate the date range before submit
   * and after every selection of start date and end date
   */
  const validateDateRange = useCallback(() => {
    const dateRange = form.getFieldValue(WFHFieldIds.startDateEndDate) as [Moment, Moment] | null;
    if (!dateRange || dateRange.length !== 2) {
      return;
    }

    const [startDate, endDate] = dateRange;
    calculateDurationApiCall(startDate, endDate);
  }, [calculateDurationApiCall, form]);

  /**
   * It verrify that day is falling
   * to holiday or not
   */
  const isDayFallIntoPublicHolidays = useCallback(
    (current: Moment | null) => {
      if (isSuccessPublicHolidays && publicHolidays && current) {
        for (const event of publicHolidays) {
          const eventStartDate = moment(event.startDate).startOf('day');
          const eventEndDate = moment(event.endDate).endOf('day');

          if (current.isBetween(eventStartDate, eventEndDate, null, '[]')) {
            return true;
          }
        }
      }
      return false;
    },
    [isSuccessPublicHolidays, publicHolidays]
  );
  /**
   * Disable all week ends (Friday and Saturday)
   * Disable all date apart from current year
   * Disable all holidays
   * @param current
   */
  const disabledDate = useCallback(
    (current: Moment | null): boolean => {
      if (current === null) {
        return true;
      }

      // Get today's date
      const today = moment();

      // Disable past dates including today
      if (current.isSameOrBefore(today)) {
        return true;
      }

      // Get the start and end of the current year
      const startOfYear = moment().startOf('year');
      const endOfYear = moment().endOf('year');

      // Disable dates outside of the current year
      if (current.isBefore(startOfYear) || current.isAfter(endOfYear)) {
        return true;
      }

      // Disable Fridays and Saturdays
      const dayOfWeek = current.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
      if (dayOfWeek === 5 || dayOfWeek === 6) {
        return true;
      }

      return isDayFallIntoPublicHolidays(current);
    },
    [isDayFallIntoPublicHolidays]
  );

  const onSubmitForm = useCallback(
    (variables) => {
      const [startDate, endDate] = variables[WFHFieldIds.startDateEndDate];

      const balanceVariables = {
        absenceDuration: durationWFHDays,
        endDate: moment(endDate).format('DD/MM/YYYY'),
        leaveType: 'WORK_FROM_HOME_KEY',
        leaveBalance: wfhDaysBalance,
        startDate: moment(startDate).format('DD/MM/YYYY'),
      };

      delete variables[WFHFieldIds.startDateEndDate];

      const formValues = Object.assign(variables, balanceVariables);

      mutateAsyncWFHSubmitApi(formValues).then((requestInfo) => {
        notification.success({
          message: t('messages.success'),
          description: t('messages.success.requestCreated'),
        });

        const location = {
          pathname: `/tasks/${requestInfo.processInstanceId}`,
          state: { defaultTab: '0' },
        };
        history.replace(location);
      });
    },
    [durationWFHDays, history, mutateAsyncWFHSubmitApi, t, wfhDaysBalance]
  );

  return {
    wfhDaysBalance,
    isValidatingDateRange,
    spinning,
    validateDateRange,
    disabledDate,
    isLoadingWFHBalance,
    wfhBalance,
    durationWFHDays,
    remainingWFHDays,
    isLoadingCalculateDuration,
    onSubmitForm,
  };
};

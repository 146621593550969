import styled from 'styled-components';
import { Tag as AntTag } from 'antd';

export const Tag = styled(AntTag)`
  &.ant-tag {
    color: #0099a8;
    background-color: #dff9fb;
    font-size: inherit;
    border-radius: 30px;
    border: none;
    padding: 2px 8px;
    margin-right: 20px;
  }

  & .ant-tag-close-icon {
    color: #0099a8;
  }
`;

import React, { FC, memo, useCallback } from 'react';
import { Row, Col } from 'antd';
import {
  CategoryData,
  ChartWeek,
  RequestSummary,
  RequestTeamMember,
} from 'types/DashboardRequestsCard';
import { useServicesContext } from 'app';
import { CardSummaryRequest } from 'routes/Dashboard/Components/molecules/DashboardRequestCardSummaryInfo/DashboardRequestCardSummaryInfo';
import {
  StyledDashboardRequestCard,
  StyledRequestName,
  StyledSubCategory,
} from './DashboardRequestCardStyle';

interface Props {
  categoryInfo: CategoryData;
  requestSummary: RequestSummary;
  requestTeamMember?: RequestTeamMember[];
  requestChartData?: ChartWeek[];
}

export const DashboardRequestCardComponent: FC<Props> = ({
  categoryInfo,
  requestSummary,
  requestTeamMember,
  requestChartData,
}) => {
  const { setIsFilterByRequest, setRequestInfoRedirection } = useServicesContext();

  const redirectionToTasks = useCallback(() => {
    if (categoryInfo.processDefKey) {
      setRequestInfoRedirection({
        PDK: categoryInfo.processDefKey,
        redirectionPage: 'my-work',
        redirectionTab: 'work-0',
      });
      setIsFilterByRequest(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryInfo.processDefKey]);

  return (
    <StyledDashboardRequestCard onClick={redirectionToTasks}>
      <Row align="middle">
        <Col span={24}>
          <StyledSubCategory>{categoryInfo.subCategory}</StyledSubCategory>
        </Col>
        <Col span={24}>
          <StyledRequestName ellipsis={true} style={{ maxWidth: '100%' }}>
            {categoryInfo.requestName}
          </StyledRequestName>
        </Col>
      </Row>
      <CardSummaryRequest requestMember={requestTeamMember} numberOfTasks={requestSummary.total} />
    </StyledDashboardRequestCard>
  );
};
export const DashboardRequestCard = memo(DashboardRequestCardComponent);

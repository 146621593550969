import React, { FC, useCallback, useState } from 'react';
import { Col, notification } from 'antd';
import { task, useLeaseTaskAttachmentMutation } from 'api';
import { saveAs } from 'file-saver';
import { DownloadAttachment } from 'ui';
import { useTranslate } from 'translations';
import { StyledLabel } from 'routes/Calendar/page/NewRequest/NewRequest.styled';
import { useTasksContext } from 'routes';
import { TaskDefinitionKey, TaskDefinitionType } from 'types';
import { StyledRow } from './TaskAttachmentStyled';

interface TaskAttachmentProps {
  taskId: string;
  id: string;
  name: string;
  attachmentLabel?: string;
  taskDefinitionKey?: TaskDefinitionKey;
}

const styleCol = { marginTop: '10px' };

const isSiteAcquisitionRequest = (taskDefinitionKey?: TaskDefinitionKey) =>
  taskDefinitionKey === TaskDefinitionType.ASSIGN_WO_FIELD_ASSIGNMENT_TASK ||
  taskDefinitionKey === TaskDefinitionType.SITE_ACQUISITION_REQUEST_FORM_TASK ||
  taskDefinitionKey === TaskDefinitionType.FIELD_RUNNER_TASK;

export const TaskAttachment: FC<TaskAttachmentProps> = ({
  id,
  name,
  taskId,
  attachmentLabel,
  taskDefinitionKey,
}) => {
  const { t } = useTranslate();
  const { rootPath } = useTasksContext();
  const leastAttachmentMutation = useLeaseTaskAttachmentMutation({ taskId, fileName: name });
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);

  const handleDownloadClick = useCallback(async () => {
    if (isSiteAcquisitionRequest(taskDefinitionKey)) {
      leastAttachmentMutation.mutate(id);
      return;
    }

    try {
      setIsAttachmentLoading(true);
      const { data } = await task.downloadTaskAttachment({
        taskId,
        attachmentId: id,
        params: {
          responseType: 'blob',
        },
        rootPath,
      });
      saveAs(data, name);
    } catch (e) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    } finally {
      setIsAttachmentLoading(false);
    }
  }, [id, leastAttachmentMutation, name, rootPath, t, taskDefinitionKey, taskId]);

  const isLoading = isAttachmentLoading || leastAttachmentMutation.isLoading;
  return (
    <StyledRow>
      {attachmentLabel ? (
        <Col span={24}>
          <StyledLabel>{attachmentLabel}</StyledLabel>
        </Col>
      ) : null}

      <Col span={24} style={styleCol}>
        <DownloadAttachment
          name={name}
          onDownloadClick={handleDownloadClick}
          isLoading={isLoading}
        />
      </Col>
    </StyledRow>
  );
};

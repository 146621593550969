import React, { FC } from 'react';
import { Space } from 'antd';
import { TextStyled } from 'ui';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';

interface AvatarNameProps {
  id: string;
  name: string;
}

export const UserAvatartName: FC<AvatarNameProps> = ({ id, name }) => (
  <Space size="small">
    <MSTeamsUserAvatar id={id} name={name} />
    <Space direction="horizontal">
      <TextStyled>{name}</TextStyled>
    </Space>
  </Space>
);

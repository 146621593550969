import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetUserGroupFail,
  GetUserGroupStart,
  GetUserGroupSuccess,
  getUserGroupFail,
  getUserGroupStart,
  getUserGroupSuccess,
} from './userGoupsSlice';

export const userGroupEpic: Epic<
  GetUserGroupStart,
  GetUserGroupSuccess | GetUserGroupFail,
  RootState,
  typeof API
> = (action$, _, { userGroup }) =>
  action$.pipe(
    filter(getUserGroupStart.match),
    exhaustMap(({ payload }) =>
      from(userGroup.getUserGroup(payload)).pipe(
        map(({ data }) => getUserGroupSuccess(data)),
        catchError((error) => of(getUserGroupFail(error?.response?.data)))
      )
    )
  );

import { useQuery } from 'react-query';
import { paySlips } from '../PaySlip';
import { QUERIES, PERSIST_QUERY_CONFIG } from '../../constants';

export const useGetPayslipInfo = (
  userId: string | null,
  selectedMonth: string,
  isSelectedMonth?: boolean
) => {
  return useQuery(
    [QUERIES.payslipInfo, userId, selectedMonth, isSelectedMonth],
    async () => {
      const { data } = await paySlips.getPaySlips(userId, selectedMonth, {
        selectedMonth: isSelectedMonth,
      });
      return data;
    },
    {
      enabled: !!userId,
      ...PERSIST_QUERY_CONFIG,
    }
  );
};

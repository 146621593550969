import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';

import {
  GetProfileExpenseFail,
  GetProfileExpenseStart,
  GetProfileExpenseSuccess,
  getProfileExpenseFail,
  getProfileExpenseStart,
  getProfileExpenseSuccess,
} from './ProfileExpenseSlice';

export const profileExpenseEpic: Epic<
  GetProfileExpenseStart,
  GetProfileExpenseSuccess | GetProfileExpenseFail,
  RootState,
  typeof API
> = (action$, _, { profileExpenses }) =>
  action$.pipe(
    filter(getProfileExpenseStart.match),
    exhaustMap(({ payload }) =>
      from(profileExpenses.getProfileExpenses(payload.params)).pipe(
        map(({ data }) => getProfileExpenseSuccess(data)),
        catchError((error) => of(getProfileExpenseFail(error?.response?.data)))
      )
    )
  );

import styled from 'styled-components';
import { colors, typography } from 'ui/elements';
import { Typography } from 'antd';

const { Text } = Typography;

export const DescriptionText = styled(Text)`
  color: ${colors.textColorSecondary};
`;

export const CategoryText = styled(Text)`
  color: ${colors.lightGrey};
`;

export const StyledH2 = styled(Text)`
  &.ant-typography {
    margin-top: 0;
    margin-bottom: 0;
    font-size: ${typography.size.tera};
    font-weight: ${typography.weight.bold};
    color: ${colors.primaryColor};
  }
  &.ant-typography.ant-typography-secondary {
    color: #8b93a2;
    font-weight: normal;
  }
  font-weight: bold;
`;

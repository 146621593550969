import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetCamundaUserStart,
  GetCamundaUserSuccess,
  GetCamundaUserFail,
  getCamundaUserStart,
  getCamundaUserSuccess,
  getCamundaUserFail,
} from './camundaUsersSlice';

export const camundaUsersEpic: Epic<
  GetCamundaUserStart,
  GetCamundaUserSuccess | GetCamundaUserFail,
  RootState,
  typeof API
> = (action$, _, { camundaUser }) =>
  action$.pipe(
    filter(getCamundaUserStart.match),
    exhaustMap(({ payload }) =>
      from(camundaUser.getCamundaUser(payload)).pipe(
        map((resp) => getCamundaUserSuccess(resp)),
        catchError((error) =>
          of(getCamundaUserFail({ error: error?.response?.data, params: payload.params }))
        )
      )
    )
  );

import React, { FC } from 'react';
import { ILeaseSitesMappedCandidateItem } from 'types';
import { Collapse } from 'antd';
import {
  CustomCollapseWithPlusMinusIcons,
  StyledCustomPlusMinusCollapsePanelTitleContainer,
} from 'ui/elements';
import { SiteAcquisitionCompletedCandidateGroupContent } from './SiteAcquisitionCompletedCandidateGroupContent';

const { Panel } = Collapse;

type Props = {
  candidate: ILeaseSitesMappedCandidateItem;
  taskDefinitionKey: string;
};

export const SiteAcquisitionCompletedCandidateCollapseContent: FC<Props> = ({
  candidate,
  taskDefinitionKey,
}) => {
  return (
    <CustomCollapseWithPlusMinusIcons>
      {candidate.candidateInformationGroup.map((group, index) => (
        <Panel
          key={`candidate-group-${index}`}
          header={
            <StyledCustomPlusMinusCollapsePanelTitleContainer>
              <p>{group.name}</p>
            </StyledCustomPlusMinusCollapsePanelTitleContainer>
          }
        >
          <SiteAcquisitionCompletedCandidateGroupContent
            taskDefinitionKey={taskDefinitionKey}
            fieldsGroup={group}
          />
        </Panel>
      ))}
    </CustomCollapseWithPlusMinusIcons>
  );
};

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { FullWidthAndHeightSpin, TeamsUserAvatar, UserPopover } from 'ui';
import { MailOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { Phone, BlackShortLogo } from 'assets';
import { IProfileBasicInfo, USER_STATUS_MAP } from 'types';
import { TType } from 'translations';
import { useProfileSetNickname, usePublicProfileDelegateInfo } from 'api';
import moment from 'moment';
import { getAvatarColor, useSelector } from 'store';
import {
  StyledProfileUserInfoCardWrapper,
  StyledProfileUserInfoCard,
  StyledProfileUserInfoCardAnniversaryWrapper,
  StyledProfileUserInfoCardDelegationWrapper,
  StyledProfileUserInfoCardBasicInfo,
  StyledProfileUserInfoCardLogo,
  StyledProfileUserInfoCardAnnouncementText,
  StyledProfileUserInfoCardDelegateAvatar,
  StyledProfileUserInfoCardDelegate,
  StyledProfileUserInfoCardDelegateWrapper,
  StyledProfileUserInfoCardEditNickName,
  StyledProfileUserInfoCardUserName,
  StyledProfileUserInfoCardNickname,
  StyledProfileUserInfoCardNicknameEditIcon,
  StyledProfileUserInfoCardContactLink,
  StyledProfileUserInfoCardContactsContainer,
  StyledProfileUserInfoCardDelegateLabel,
  StyledProfileUserInfoCardDelegateName,
  StyledStatusBadge,
} from './UserInfoCard.styles';

type ProfileViewProps = {
  t: TType;
  selfView: boolean;
  basicInfo?: IProfileBasicInfo;
  isLoading: boolean;
  isError: boolean;
  adOid?: string;
};

export const UserInfoCard: FC<ProfileViewProps> = ({
  t,
  selfView,
  basicInfo,
  isError,
  isLoading,
  adOid,
}) => {
  const [nickName, setNickName] = useState<string | undefined>();
  const { mutateAsync: postNickname, isLoading: isSetNicknameLoading } = useProfileSetNickname();
  const avatarColor = useSelector(getAvatarColor);
  const {
    data: delegateInfo,
    isLoading: isDelegateInfoLoading,
    isError: isDelegateInfoError,
  } = usePublicProfileDelegateInfo(adOid ?? '');

  const showDelegate: boolean = useMemo(() => {
    if (!isDelegateInfoLoading && !isDelegateInfoError) {
      if (delegateInfo) {
        return !!(delegateInfo.delegate && delegateInfo.leave);
      }
      return false;
    }
    return false;
  }, [delegateInfo, isDelegateInfoError, isDelegateInfoLoading]);

  const onNicknameUpdate = useCallback(
    (newNickname: string) => {
      if (newNickname !== nickName) {
        postNickname(newNickname)
          .then(() => {
            setNickName(newNickname);
          })
          .catch(() => {
            notification.error({
              message: t('messages.error.wentWrong'),
              description: t('messages.error.tryAgainLater'),
            });
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nickName]
  );

  useEffect(() => {
    if (basicInfo) {
      setNickName(basicInfo.nickName);
    }
  }, [basicInfo]);

  return (
    <StyledProfileUserInfoCardWrapper>
      <StyledProfileUserInfoCard>
        {basicInfo && !isError && (
          <>
            <StyledProfileUserInfoCardBasicInfo>
              <TeamsUserAvatar
                id={adOid}
                email={basicInfo.email}
                size={130}
                name={basicInfo.displayName}
                color={selfView ? avatarColor : undefined}
              />
              <StyledProfileUserInfoCardUserName>
                {basicInfo.displayName}
              </StyledProfileUserInfoCardUserName>
              <StyledProfileUserInfoCardEditNickName>
                {selfView ? (
                  isSetNicknameLoading ? (
                    <FullWidthAndHeightSpin />
                  ) : (
                    <StyledProfileUserInfoCardNickname
                      editable={{
                        icon: <StyledProfileUserInfoCardNicknameEditIcon />,
                        onChange: onNicknameUpdate,
                        autoSize: { minRows: 1, maxRows: 1 },
                        maxLength: 24,
                      }}
                      ellipsis={{ rows: 1, tooltip: nickName }}
                      strong
                      $isEmpty={!nickName && !basicInfo?.nickName}
                    >
                      {nickName ?? basicInfo?.nickName ?? ''}
                    </StyledProfileUserInfoCardNickname>
                  )
                ) : (
                  basicInfo.nickName && (
                    <StyledProfileUserInfoCardNickname>
                      {basicInfo.nickName}
                    </StyledProfileUserInfoCardNickname>
                  )
                )}
              </StyledProfileUserInfoCardEditNickName>
              <StyledStatusBadge
                color={USER_STATUS_MAP[basicInfo.status ?? 'PresenceUnknown']?.color}
                text={t(USER_STATUS_MAP[basicInfo.status ?? 'PresenceUnknown']?.text)}
              />
              <StyledProfileUserInfoCardContactsContainer>
                <StyledProfileUserInfoCardContactLink
                  href={basicInfo.email ? `mailo:${basicInfo.email}` : '#'}
                >
                  <MailOutlined />
                  <span>{basicInfo.email}</span>
                </StyledProfileUserInfoCardContactLink>
                {basicInfo.mobileNumber && (
                  <StyledProfileUserInfoCardContactLink href={`tel:${basicInfo.mobileNumber}`}>
                    <Phone />
                    <span>{basicInfo.mobileNumber}</span>
                  </StyledProfileUserInfoCardContactLink>
                )}
              </StyledProfileUserInfoCardContactsContainer>
              {selfView && (
                <Link
                  to={{
                    pathname: '/tasks',
                    state: { defaultTab: '1', catalogSearch: 'Profile Update' },
                  }}
                >
                  <Button>Edit Profile</Button>
                </Link>
              )}
            </StyledProfileUserInfoCardBasicInfo>
            {basicInfo.anniversaryYears && (
              <StyledProfileUserInfoCardAnniversaryWrapper>
                <StyledProfileUserInfoCardAnnouncementText>
                  {basicInfo.anniversaryYears} Year{basicInfo.anniversaryYears > 1 ? 's' : ''}{' '}
                  Anniversary
                </StyledProfileUserInfoCardAnnouncementText>
                <StyledProfileUserInfoCardLogo>
                  <BlackShortLogo />
                </StyledProfileUserInfoCardLogo>
              </StyledProfileUserInfoCardAnniversaryWrapper>
            )}
            {showDelegate && !selfView && (
              <StyledProfileUserInfoCardDelegationWrapper>
                <StyledProfileUserInfoCardAnnouncementText fontWeight={400} fontSize={13}>
                  {basicInfo.displayName} {t('profile.delegateInfo.isOnLiveTill')}{' '}
                  {moment(delegateInfo?.leave?.endDate).format('DD.MM.YYYY')}
                </StyledProfileUserInfoCardAnnouncementText>
                <StyledProfileUserInfoCardDelegateWrapper>
                  <StyledProfileUserInfoCardDelegate>
                    <StyledProfileUserInfoCardDelegateLabel>
                      {t('leaves.newRequest.delegate')}
                    </StyledProfileUserInfoCardDelegateLabel>
                    <StyledProfileUserInfoCardDelegateName>
                      {delegateInfo?.delegate?.name}
                    </StyledProfileUserInfoCardDelegateName>
                  </StyledProfileUserInfoCardDelegate>
                  <StyledProfileUserInfoCardDelegateAvatar>
                    <UserPopover currentID={delegateInfo?.delegate?.adOid}>
                      <TeamsUserAvatar
                        size={40}
                        id={delegateInfo?.delegate?.adOid}
                        name={delegateInfo?.delegate?.name}
                      />
                    </UserPopover>
                  </StyledProfileUserInfoCardDelegateAvatar>
                </StyledProfileUserInfoCardDelegateWrapper>
              </StyledProfileUserInfoCardDelegationWrapper>
            )}
          </>
        )}
        {isLoading && <FullWidthAndHeightSpin />}
      </StyledProfileUserInfoCard>
    </StyledProfileUserInfoCardWrapper>
  );
};

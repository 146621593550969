import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { RequestCommonError, RequestStatus, UsersData } from 'types';
import { UsersState } from './usersTypes';

export const emptyUsersInitialState: UsersState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetUsersStart = PayloadAction<AxiosRequestConfig>;
export type GetUsersSuccess = PayloadAction<UsersData>;
export type GetUsersFail = PayloadAction<RequestCommonError>;

const users = createSlice({
  name: 'users',
  initialState: emptyUsersInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getUsersStart(state, _: GetUsersStart) {
      state.status = RequestStatus.pending;
    },
    getUsersSuccess(state, action: GetUsersSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getUsersFail(state, action: GetUsersFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const { getUsersStart, getUsersSuccess, getUsersFail } = users.actions;
export default users.reducer;

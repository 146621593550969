import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const oneDriveFolderShared = (state: RootState): RootState['oneDriveFolderShared'] =>
  state.oneDriveFolderShared;

export const oneDriveFolderSharedData = createSelector(
  oneDriveFolderShared,
  (oneDriveFolderShared) => oneDriveFolderShared
);

import React, { FC, Fragment, memo, useCallback, useEffect, useState } from 'react';
import { Empty, Spin, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useDelegationFormdDetails, useDelegationSubmit } from 'api/StaticForm/hooks/Delegation';
import { TType } from 'translations';
import { FullWidthSpin } from 'ui';
import {
  AttachmentFile,
  attachmentsFieldsId,
} from 'routes/Tasks/components/pages/ServiceNewRequest/ServiceNewRequest';
import { VALIDATE_MESSAGES } from 'utils';
import { FieldData } from 'rc-field-form/lib/interface';
import { useHistory } from 'react-router-dom';
import { submitAttachmentForm } from 'ui/publicFunction';
import moment from 'moment';
import { useHandleSubmitStatus } from 'routes/Tasks';
import { DelegationFields } from './DelegationRequestFields';
import { DelegationSubmitForm } from './types';

export interface DelegationProps {
  formName?: string;
  t: TType;
}

export const DelegationFormComponent: FC<DelegationProps> = ({ formName, t }) => {
  const [attachmentData, setAttachmentData] = useState<AttachmentFile[]>([]);
  const [formStartDate, setFormStartDate] = useState<moment.Moment>(moment());

  const submitForm = useDelegationSubmit();
  const [form] = useForm();
  const history = useHistory();
  const { data: formDetails, isLoading, isError } = useDelegationFormdDetails();
  const dateFormat = 'DD/MM/YYYY';
  useEffect(
    () => {
      form.setFieldsValue({
        hierarchyApproval: 'No',
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSubmitForm = useCallback(
    async (variables) => {
      const {
        delegateName,
        description,
        hierarchyApproval,
        delegationStartDate,
        delegationEndDate,
        OUProceesses,
      } = variables;
      const attachmentsForm: AttachmentFile[] = [];
      const approval = hierarchyApproval ? hierarchyApproval === 'Yes' : null;
      const delegateAdOid =
        formDetails?.delegatesNameList.find((val) => val.name === delegateName)?.id || '';
      const startDate = moment(delegationStartDate).format(dateFormat);
      const endDate = moment(delegationEndDate).format(dateFormat);
      const taskIdList = OUProceesses
        ? formDetails?.processesList.filter((element) => OUProceesses.includes(element.name)) || []
        : null;

      const formVariables: DelegationSubmitForm = {
        delegateAdOid: delegateAdOid,
        delegationStartDate: startDate,
        delegationEndDate: endDate,
        delegationReason: description,
        hierarchyApproval: approval,
        taskIdList: taskIdList,
      };

      submitForm.mutate(formVariables);

      const currentFormFieldsName = Object.keys(variables);
      const currentAttachmentId = currentFormFieldsName.filter((variables: string) => {
        return attachmentsFieldsId.includes(variables);
      });

      if (currentAttachmentId && variables) {
        currentAttachmentId.forEach((fieldId: string) => {
          const attachmentValue = variables[fieldId];
          if (attachmentValue) {
            return attachmentsForm.push({ [fieldId]: attachmentValue });
          }
        });
      }

      if (attachmentsForm) {
        setAttachmentData(attachmentsForm);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [submitForm]
  );

  const onFieldsChange = useCallback(
    (changedFields: FieldData[]) => {
      const [changeField] = changedFields;
      const { name, value } = changeField || ({} as FieldData);
      if (Array.isArray(name) && name.length && formDetails) {
        const [fieldName] = name;
        if (fieldName === 'delegationStartDate') {
          setFormStartDate(moment(value));
          if (moment(value).isAfter(form.getFieldValue('delegationEndDate'))) {
            form.setFieldsValue({ delegationEndDate: undefined });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formDetails]
  );

  useHandleSubmitStatus(t, submitForm.isLoading, submitForm.isError, submitForm.isSuccess, true);

  useEffect(() => {
    submitForm.data &&
      submitAttachmentForm(
        submitForm.isSuccess,
        submitForm.data,
        history,
        undefined,
        attachmentData
      );
  }, [attachmentData, history, submitForm.data, submitForm.isSuccess]);

  return (
    <Fragment>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        validateMessages={VALIDATE_MESSAGES(t)}
        onFinish={onSubmitForm}
        onFieldsChange={onFieldsChange}
      >
        {isError ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
        ) : isLoading ? (
          <FullWidthSpin />
        ) : (
          <Spin spinning={isLoading} delay={100}>
            <DelegationFields
              t={t}
              formFieldData={formDetails}
              startDate={formStartDate}
              form={form}
            />
          </Spin>
        )}
      </Form>
    </Fragment>
  );
};

export const DelegationForm = memo(DelegationFormComponent);

import { useCallback } from 'react';
import { useServicesContext } from 'app';

export const useDisableFieldDependOnOtherField = (taskId?: string) => {
  const { dependenciesFieldsBetweenForms } = useServicesContext();

  return useCallback(
    (fieldId: string) => {
      return dependenciesFieldsBetweenForms
        ? dependenciesFieldsBetweenForms.taskId
          ? dependenciesFieldsBetweenForms.taskId === taskId
            ? dependenciesFieldsBetweenForms.fieldId === fieldId
            : false
          : false
        : false;
    },
    [dependenciesFieldsBetweenForms, taskId]
  );
};

import styled from 'styled-components';
import { Row } from 'antd';
import { space } from 'ui/elements';

export const HeaderTitle = styled(Row)`
  padding-top: ${space.slim};
  width: calc(100% - 93px);
  margin: 0 !important;
  padding-left: 24px;
`;

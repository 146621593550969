import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const oneDriveSharedLibraries = (state: RootState): RootState['oneDriveSharedLibraries'] =>
  state.oneDriveSharedLibraries;

const getCurrentGroupId = (
  state: RootState,
  props: {
    groupId: string;
  }
) => props.groupId;

export const oneDriveSharedLibrariesData = createSelector(
  [oneDriveSharedLibraries, getCurrentGroupId],
  (oneDriveSharedLibraries, groupId) => oneDriveSharedLibraries[groupId]
);

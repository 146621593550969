import { useQuery } from 'react-query';
import { leaseSites } from '../LeaseSites';
import { QUERIES } from '../../constants';
import { mapLovToOptions } from './helpers';

export const useLeaseCities = (cityName = '') =>
  useQuery(
    [QUERIES.leaseCities(cityName)],
    async () => {
      const { data } = await leaseSites.getCities(cityName);
      return { ...data, list: mapLovToOptions(data.list) };
    },
    {
      refetchOnWindowFocus: false,
    }
  );

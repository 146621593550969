import { useQuery } from 'react-query';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';
import { staticForm } from 'api/StaticForm';

export const useExpenseReimbursement = (adoId: string) => {
  return useQuery(
    [QUERIES.expenseReimbursement, adoId],
    async () => (await staticForm.getExpensesReimbursementDetail(adoId)).data,
    {
      ...PERSIST_QUERY_CONFIG,
    }
  );
};

import React, { useCallback } from 'react';
import { ProcessDescription, TitleH2 } from 'ui';
import { useTranslate } from 'translations';
import { CreateFormItemType, WFHFieldIds, WFHRemainingBalanceResponse } from 'types';
import { createFormField } from 'utils/formCreation';
import { Col } from 'antd';
import { Moment } from 'moment/moment';
import { FormInstance } from 'antd/lib/form';
import { GeneratedStaticForm } from '../../../GeneratedStaticForms';
import {
  StyledDateRangeInfoText,
  StyledDateRangeInfoTextWrapper,
  StyledTitleRequestDetailWrapper,
  StyledWFHFormHeaderWrapper,
  StyledWFHFromFieldWrapper,
  StyledWFHRow,
} from './WFHFormFields.styles';
import { WFHBalanceCard } from './WFHBalanceCard';
import { WFHRemainingBalanceCard } from './WFHRemainingBalanceCard';

export interface WFHFormFieldsProps {
  adoId?: string;
  form: FormInstance;
  disabledDate: (date: Moment) => boolean;
  isLoadingWFHBalance: boolean;
  wfhBalance?: WFHRemainingBalanceResponse;
  durationWFHDays: number;
  remainingWFHDays: number;
  wfhDaysBalance: number;
  isLoadingCalculateDuration: boolean;
  onRangeDateChange: () => void;
}

export const WFHFormFields: React.FC<WFHFormFieldsProps> = ({
  disabledDate,
  isLoadingWFHBalance,
  wfhBalance,
  durationWFHDays,
  remainingWFHDays,
  wfhDaysBalance,
  isLoadingCalculateDuration,
  onRangeDateChange,
}: WFHFormFieldsProps) => {
  const { t } = useTranslate();

  const RangePickerFormGroup = useCallback<() => CreateFormItemType[]>(() => {
    return [
      createFormField({
        id: WFHFieldIds.startDateEndDate,
        label: t('wfh.request.startDateEndDate.label'),
        name: WFHFieldIds.startDateEndDate,
        type: 'rangePicker',
        disabled: isLoadingCalculateDuration,
        placeholder: t('wfh.request.startDateEndDate.placeholder'),
        disabledDate: disabledDate,
        t,
        onRangeDateChange: onRangeDateChange,
      }),
    ];
  }, [disabledDate, t, onRangeDateChange, isLoadingCalculateDuration]);

  const DescriptionFormGroup = useCallback<() => CreateFormItemType[]>(() => {
    return [
      createFormField({
        t: t,
        id: WFHFieldIds.description,
        label: t('wfh.request.description.field.label'),
        name: WFHFieldIds.description,
        type: 'textArea',
        placeholder: t('wfh.request.description.field.placeholder'),
        maxNumber: 400,
        rules: [],
      }),
    ];
  }, [t]);

  return (
    <StyledWFHFromFieldWrapper>
      <StyledWFHFormHeaderWrapper>
        <ProcessDescription
          showIcon={false}
          showDescriptionIcon={false}
          descriptionLabel={t('wfh.request.description.label')}
          descriptionText={t('wfh.request.description')}
          exampleText={''}
        />
      </StyledWFHFormHeaderWrapper>
      <StyledTitleRequestDetailWrapper>
        <TitleH2 style={{ marginBottom: '27px' }}>{t('tasks.tab.requests.requestDetails')}</TitleH2>
      </StyledTitleRequestDetailWrapper>
      <StyledWFHRow>
        <Col span={18}>
          <GeneratedStaticForm items={RangePickerFormGroup()} t={t} />
          <StyledDateRangeInfoTextWrapper>
            <StyledDateRangeInfoText>
              {t('wfh.request.startDateEndDate.info.label')}
            </StyledDateRangeInfoText>
          </StyledDateRangeInfoTextWrapper>
          <WFHRemainingBalanceCard
            durationWFHDays={durationWFHDays}
            remainingWFHDays={remainingWFHDays}
            wfhDaysBalance={wfhDaysBalance}
            isLoadingCalculateDuration={isLoadingCalculateDuration}
          />
          <GeneratedStaticForm items={DescriptionFormGroup()} t={t} />
        </Col>
        <WFHBalanceCard isLoadingWFHBalance={isLoadingWFHBalance} wfhBalance={wfhBalance} />
      </StyledWFHRow>
    </StyledWFHFromFieldWrapper>
  );
};

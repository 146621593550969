import { Core, SITE_ID } from 'api/Core';
import { AxiosRequestConfig } from 'axios';
import { SiteIdsTravelAndBusiness } from 'types';

interface GetSiteIds {
  (params?: AxiosRequestConfig['params']): Promise<{ data: SiteIdsTravelAndBusiness[] }>;
}

class SiteIds extends Core {
  getSiteIds: GetSiteIds = async (params) => this.get(SITE_ID(), params);
}

export const sitesIds = new SiteIds();

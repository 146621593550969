import styled from 'styled-components';
import { Button, DatePicker, Typography, Card, Row, Empty } from 'antd';
import { colors, typography } from 'ui/elements';
import { CalendarOutlined, MoreOutlined } from '@ant-design/icons';

const { Text } = Typography;

export const StyledDatePicker = styled(DatePicker)`
  border: none;
  margin-right: -15px;
  .ant-picker-input {
    border: none;
    color: ${colors.primaryColor};
  }
  .ant-picker-input > input {
    font-weight: 600;
  }
`;
export const StyledIcon = styled(CalendarOutlined)`
  align-self: center;
  color: ${colors.primaryColor};
  line-height: 1;
  pointer-events: none;
  position: absolute;
  font-size: 20px;
  margin-right: 4px;
  left: -35px;
  top: 1px;
`;

export const StyledMoreIcon = styled(MoreOutlined)``;

export const StyledCardValue = styled(Text)`
  font-size: 24px;
  color: ${colors.primaryColor};
`;

export const StyledAmount = styled(Text)`
  font-size: 18px;
  color: ${colors.textColorSecondary};
`;
export const StyledDownloadBtn = styled(Button)`
  padding-left: 10px;
`;

export const StyledPayrollCard = styled(Card)`
  radius: 4px;
`;
export const StyledWrapperRow = styled(Row)`
  width: 100%;
  justify-content: start;
`;

export const StyledNoDataEmpty = styled(Empty)`
  width: 100%;
`;
export const StyledButton = styled(Button)`
  font-weight: ${typography.weight.semiBold};
`;

import { AxiosPromise } from 'axios';
import {
  IELibraryDepartmentListBySector,
  IELibraryDocumentInfo,
  IELibraryDocumentList,
  IELibraryDocumentTypeListResponse,
  IELibraryDownloadDocument,
  IELibrarySectorListResponse,
} from 'types';
import {
  Core,
  ELIBRARY_DEPARTMENTS_BY_SECTOR,
  ELIBRARY_DOCUMENT_INFO,
  ELIBRARY_DOCUMENT_TYPE,
  ELIBRARY_DOCUMENTS_BY_DEPARTMENT,
  ELIBRARY_DOWNLOAD_DOCUMENT,
  ELIBRARY_SECTORS,
  ELIBRARY_DOCUMENTS_FILTER,
} from '../Core';

interface GetDocumentTypeList {
  (): AxiosPromise<IELibraryDocumentTypeListResponse>;
}

interface GetSectorList {
  (): AxiosPromise<IELibrarySectorListResponse>;
}

interface GetDepartmentListBySector {
  (sectorName: string): AxiosPromise<IELibraryDepartmentListBySector>;
}

interface GetDocumentListByDepartment {
  (departmentName: string): AxiosPromise<IELibraryDocumentList>;
}

interface GetDocumentInfo {
  (documentId: string): AxiosPromise<IELibraryDocumentInfo>;
}

interface GetDownloadDocument {
  (payload: IELibraryDownloadDocument): Promise<{
    data: string;
  }>;
}

interface GetDocumentListFilter {
  (filterParams: { [key: string]: string }): AxiosPromise<IELibraryDocumentList>;
}

export class ELibrary extends Core {
  getDocumentType: GetDocumentTypeList = async () => this.get(ELIBRARY_DOCUMENT_TYPE());
  getSectors: GetSectorList = async () => this.get(ELIBRARY_SECTORS());
  getDepartments: GetDepartmentListBySector = async (sectorName: string) =>
    this.get(ELIBRARY_DEPARTMENTS_BY_SECTOR(sectorName));
  getDocuments: GetDocumentListByDepartment = async (departmentName: string) =>
    this.get(ELIBRARY_DOCUMENTS_BY_DEPARTMENT(departmentName));
  getDocumentInfo: GetDocumentInfo = async (id) => this.get(ELIBRARY_DOCUMENT_INFO(id));
  downloadDocument: GetDownloadDocument = async ({ serverRelativeURL, params }) =>
    this.get(ELIBRARY_DOWNLOAD_DOCUMENT(serverRelativeURL), params);
  getDocumentsFilter: GetDocumentListFilter = async (params) =>
    this.get(ELIBRARY_DOCUMENTS_FILTER(), { params });
}

export const eLibrary = new ELibrary();

import { APIVersion } from './ApiCommonInterface';

export const CAMUNDA_CONTEXT_URL = '/empl/camunda/v1.0';
export const CAMUNDA_CONTEXT_URL_V2 = '/empl/camunda/v2.0';
export const CAMUNDA_ENGINE_CONTEXT_URL = '/empl/camunda/v1.0/engine-rest';
export const AGGREGATOR_CONTEXT_URL = '/employee/aggregator/v1.0';
export const AGGREGATOR_CONTEXT_URL_V2 = '/employee/aggregator/v2.0';
export const JIRA_CONTEXT_URL = 'employee/jira/v1.0';
export const JIRA_CONTEXT_URL_V2 = 'employee/jira/v2.0';
export const FINANCE_V1 = '/finance/v1.0';

export const HISTORIC_ACTIVITY_INSTANCE = () =>
  `${CAMUNDA_ENGINE_CONTEXT_URL}/history/activity-instance`;

// TODO: remove unnecessary
export const TASK_PRETTY_ID = (): string => `${CAMUNDA_CONTEXT_URL}/mapping/task/id`;
export const REQUEST_PRETTY_ID = (): string => `${CAMUNDA_CONTEXT_URL}/mapping/request/id`;
export const TASK_ID_BY_PRETTY_ID = (id: string): string =>
  `${CAMUNDA_CONTEXT_URL}/mapping/task/${id}`;
export const REQUEST_ID_BY_PRETTY_ID = (id: string): string =>
  `${CAMUNDA_CONTEXT_URL}/mapping/request/${id}`;

//leaves
export const LEAVES_TYPES_LIST = (hcmPersonId: string): string =>
  `${CAMUNDA_CONTEXT_URL}/absence/${hcmPersonId}/allowed`;
export const LEAVES_LIST = (hcmPersonId: string): string =>
  `${CAMUNDA_CONTEXT_URL}/leave/${hcmPersonId}`;
export const LEAVES_INFO = (leaveId: string): string =>
  `${CAMUNDA_CONTEXT_URL}/leave/info/${leaveId}`;
export const HISTORIC_PROCESS_INSTANCE = (processInstanceId: string) =>
  `${PROCESS_INSTANCE_INFO()}/${processInstanceId}`;
export const LEAVES_REMAINING = (hcmPersonId: string, absenceTypeId: string): string =>
  `${CAMUNDA_CONTEXT_URL}/absence/remaining/duration/${hcmPersonId}/${absenceTypeId}`;
export const LEAVES_PLANNED = (hcmPersonId: string, absenceTypeId: string): string =>
  `${CAMUNDA_CONTEXT_URL}/absence/planned/duration/${hcmPersonId}/${absenceTypeId}`;
export const LEAVES_COMPLETED = (hcmPersonId: string, absenceTypeId: string): string =>
  `${CAMUNDA_CONTEXT_URL}/absence/completed/duration/${hcmPersonId}/${absenceTypeId}`;
export const LEAVES_SUBMIT_FORM = (hcmPersonId: string) =>
  `${CAMUNDA_CONTEXT_URL}/leave/${hcmPersonId}`;
export const LEAVES_REASON = (hcmPersonId: string) =>
  `${CAMUNDA_CONTEXT_URL}/absence/reason/${hcmPersonId}`;
export const MY_TEAM_LEAVES_LIST = (hcmPersonId: string): string =>
  `${CAMUNDA_CONTEXT_URL}/leave/my-team/${hcmPersonId}`;
export const MY_TEAM_MEMBERS_LIST = (hcmPersonId: string): string =>
  `${CAMUNDA_CONTEXT_URL}/leave/my-team-list/${hcmPersonId}`;
export const MY_TEAM_LEAVE_DRAWER_DETAILS = (leaveId: string, adoId: string) =>
  `${CAMUNDA_CONTEXT_URL}/leave/my-team/drawer/${leaveId}/${adoId}`;
export const LEAVE_RESCHEDULE = () => `${CAMUNDA_CONTEXT_URL}/leave/reschedule`;
export const LEAVES_DELEGATORS = () => `${AGGREGATOR_CONTEXT_URL}/delegation/delegates`;
export const LEAVES_ASSIGNED_DELEGATORS = (startDate: string, endDate: string) =>
  `${CAMUNDA_CONTEXT_URL}/delegation/delegates?startDate=${startDate}&endDate=${endDate}`;

//services
export const MY_REQUEST_CARDS = (userId: string) => `${CAMUNDA_CONTEXT_URL}/my-requests/${userId}`;
export const MY_REQUEST_CARDS_AGGREGATOR = () => `${AGGREGATOR_CONTEXT_URL}/my-requests`;
export const MY_TASK_CARDS = (userId: string) => `${CAMUNDA_CONTEXT_URL}/my-tasks/${userId}`;
export const MY_TASK_CARDS_AGGREGATOR = () => `${AGGREGATOR_CONTEXT_URL_V2}/my-tasks`;
export const MY_GROUP_CARDS = (userId: string) =>
  `${CAMUNDA_CONTEXT_URL}/my-group-tasks/v2/${userId}`;
export const MY_GROUP_CARDS_AGGREGATOR = () => `${AGGREGATOR_CONTEXT_URL_V2}/my-group-tasks`;
export const TASK_ROOT_SERVICE_AGGREGATOR = (taskId: string) =>
  `${AGGREGATOR_CONTEXT_URL}/task/${taskId}`;
export const CATEGORIES_TREE = () => `${AGGREGATOR_CONTEXT_URL}/sub-categories`;
export const CATEGORIES_TREE_OLD = () => `${CAMUNDA_CONTEXT_URL}/sub-categories`;
export const ALL_REQUEST_CARD = () => `${AGGREGATOR_CONTEXT_URL}/all-request`;
export const ALL_REQUEST_CARD_OLD = () => `${CAMUNDA_CONTEXT_URL}/all-request`;
export const SUB_CATEGORY_CARD = (subCategoryName: string) =>
  `${AGGREGATOR_CONTEXT_URL}/process-definition-keys/sub-category/${subCategoryName}`;
export const SUB_CATEGORY_CARD_OLD = (subCategoryName: string) =>
  `${CAMUNDA_CONTEXT_URL}/process-definition-keys/sub-category/${subCategoryName}`;
export const CATEGORY_CARD = (categoryName: string) =>
  `${CAMUNDA_CONTEXT_URL}/catalog/${categoryName}`;
export const PROCESS_DEFINITION_KEYS = (): string =>
  `${AGGREGATOR_CONTEXT_URL}/process-definition-keys`;
export const PROCESS_DEFINITION_KEYS_OLD = (): string =>
  `${CAMUNDA_CONTEXT_URL}/process-definition-keys`;

export const PROCESS_DEFINITION = (processDefinitionKey: string) =>
  `${CAMUNDA_ENGINE_CONTEXT_URL}/process-definition/key/${processDefinitionKey}/submit-form`;
export const TASK = () => `${CAMUNDA_ENGINE_CONTEXT_URL}/task`;
export const HISTORIC_TASK = () => `${CAMUNDA_ENGINE_CONTEXT_URL}/history/task`;
export const CAMUNDA_USER = () => `${CAMUNDA_ENGINE_CONTEXT_URL}/user`;
export const PROCESS_INSTANCE = (processInstanceId: string) =>
  `${CAMUNDA_CONTEXT_URL}/process-instance/${processInstanceId}`;
export const TASK_RENDERED_FORM = (taskId: string) => `${TASK()}/${taskId}/rendered-form`;
export const FORM_DATA = (taskId: string, rootPath?: string) =>
  `${rootPath ? rootPath + APIVersion.V1 : CAMUNDA_CONTEXT_URL}/task/${taskId}/form-data`;
export const SERVICE_NEW_REQUEST_FORM = (processDefinitionKey: string, adOid?: string) =>
  `${CAMUNDA_CONTEXT_URL}/${processDefinitionKey}/${adOid}`;
export const NEW_REQUEST_SUBMIT_FORM = (processDefinitionKey: string) =>
  `${CAMUNDA_CONTEXT_URL}/submit-process/${processDefinitionKey}`;

export const TASK_FORM_VARIABLES = (taskId: string) => `${TASK()}/${taskId}/form-variables`;
export const TASK_SUBMIT_FORM = (taskId: string, rootPath?: string) =>
  `${
    rootPath ? rootPath + APIVersion.V1 + '/engine-rest' : CAMUNDA_ENGINE_CONTEXT_URL
  }/task/${taskId}/submit-form`;
export const SUBMITTED_TASK_FORM_VARIABLES = () => `${CAMUNDA_ENGINE_CONTEXT_URL}/history/detail`;
export const SUBMITTED_TASK_FORM_LABELS = (processDefinitionId: string) =>
  `${CAMUNDA_CONTEXT_URL}/process-definition/${processDefinitionId}/task-definition/form-data`;
export const REASSIGN_TASK = (taskId: string, rootPath?: string) =>
  `${
    rootPath ? rootPath + APIVersion.V1 + '/engine-rest' : CAMUNDA_ENGINE_CONTEXT_URL
  }/task/${taskId}/assignee`;
export const PROCESS_INSTANCE_INFO = () => `${CAMUNDA_ENGINE_CONTEXT_URL}/history/process-instance`;
export const TASK_DATA = (taskId: string) => `${TASK()}/${taskId}`;
export const TASK_DIAGRAM = (processDefinitionId: string, rootPath?: string) =>
  `${
    rootPath ? rootPath + APIVersion.V1 + '/engine-rest' : CAMUNDA_ENGINE_CONTEXT_URL
  }/process-definition/${processDefinitionId}/xml`;
export const TASK_COMMENTS = (taskId: string) => `${TASK()}/${taskId}/comment`;
export const TASK_COMMENT_CREATE = (taskId: string, rootPath?: string) =>
  `${rootPath ? rootPath + APIVersion.V1 : CAMUNDA_CONTEXT_URL}/task/${taskId}/comment/create`;

export const TASK_ASSIGNMENT = (taskId: string) => `${TASK()}/${taskId}/identity-links`;
export const USER_GROUP = () => `${CAMUNDA_ENGINE_CONTEXT_URL}/identity/groups`;
export const GROUP = () => `${CAMUNDA_ENGINE_CONTEXT_URL}/group`;
export const COMPLETE_TASK = (taskId: string) => `${TASK()}/${taskId}/complete`;
export const PROCESS_DEFINITION_LIST = () => `${CAMUNDA_ENGINE_CONTEXT_URL}/process-definition`;
export const ASSIGNEE_USER = () => `${CAMUNDA_USER()}`;
export const GROUP_MEMBERS = (rootPath?: string) =>
  `${rootPath ? rootPath + APIVersion.V1 + '/engine-rest' : CAMUNDA_ENGINE_CONTEXT_URL}/user`;
export const CLAIM_TASK = (taskId: string) => `${TASK()}/${taskId}/claim`;
export const REQUEST_CATALOG_DRAWER = (requestName: string) =>
  `${AGGREGATOR_CONTEXT_URL}/drawer/${requestName}`;
export const REQUEST_CATALOG_DRAWER_OLD = (requestName: string) =>
  `${CAMUNDA_CONTEXT_URL}/drawer/${requestName}`;
export const REQUEST_DESCRIPTION = (processInstanceId: string) =>
  `${CAMUNDA_CONTEXT_URL}/history/${processInstanceId}`;
export const REQUEST_GENERAL_INFO = (processInstanceId: string | null, rootPath?: string) =>
  `${
    rootPath ? rootPath + APIVersion.V1 : CAMUNDA_CONTEXT_URL
  }/request/general-info/${processInstanceId}`;
export const REQUEST_ROOT_PATH = (taskId: string | null) =>
  `${AGGREGATOR_CONTEXT_URL}/task/${taskId}`;
export const REQUEST_COMPLETED_TASK = (taskId: string, rootPath?: string) =>
  `${rootPath ? rootPath + APIVersion.V1 : CAMUNDA_CONTEXT_URL}/task/details/${taskId}`;
export const SCRAP_INVOICE_CUSTOMERS_NAME = () =>
  `${CAMUNDA_CONTEXT_URL}/sale-of-asset-invoice/customer-details`;

// tasks_attachment
export const ATTACHMENT = (taskId: string) => `${TASK()}/${taskId}/attachment/create`;
export const TASK_ATTACHMENT = (taskId: string, attachmentId: string, rootPath?: string) =>
  `${
    rootPath ? rootPath + APIVersion.V1 : CAMUNDA_CONTEXT_URL
  }/document/task/${taskId}/attachment/${attachmentId}`;
export const TASK_ATTACHMENT_OLD = (taskId: string, attachmentId: string) =>
  `${TASK()}/${taskId}/attachment/${attachmentId}/data`;
export const TASK_COMMENT_ATTACHMENT = (commentId: string, rootPath?: string) =>
  `${rootPath ? rootPath + APIVersion.V1 : CAMUNDA_CONTEXT_URL}/comments/${commentId}/attachments`;
export const TASK_COMMENT_ATTACHMENT_DOWNLOAD = (
  commentId: string,
  attachmentId: string,
  rootPath?: string
) =>
  `${
    rootPath ? rootPath + APIVersion.V1 : CAMUNDA_CONTEXT_URL
  }/comments/${commentId}/attachments/${attachmentId}`;
export const POST_TASK_ATTACHMENT = (
  taskId: string,
  processInstanceId: string,
  processDefinitionId: string,
  activityId: string,
  rootPath?: string
) =>
  `${
    rootPath ? rootPath + APIVersion.V1 : CAMUNDA_CONTEXT_URL
  }/create-Attachment/${taskId}/${processInstanceId}/${activityId}/${processDefinitionId}`;
export const POST_NON_PO_TASK_ATTACHMENT = (
  taskId: string,
  processInstanceId: string,
  processDefinitionId: string,
  activityId: string
) =>
  `${FINANCE_V1}/create-Attachment/${taskId}/${processInstanceId}/${activityId}/${processDefinitionId}`;
export const POST_JIRA_TASK_ATTACHMENT = (processInstanceId: string) =>
  `${JIRA_CONTEXT_URL}/document/request/${processInstanceId}`;

//service_static_form
export const SELF_SERVICE_LETTER_VALUES = () => `${CAMUNDA_CONTEXT_URL}/employee-letter-values`;
export const EXPENSES_ALLOWANCE = (adoId: string) =>
  `${CAMUNDA_CONTEXT_URL}/expense/allowance/form/details/${adoId}`;
export const ALLOWANCE_SUBMIT = (adoId: string) =>
  `${CAMUNDA_CONTEXT_URL}/expense/allowance/submit/${adoId}`;
export const SELF_SERVICE_LETTER = (adoId: string) =>
  `${CAMUNDA_CONTEXT_URL}/letter/form/details/${adoId}`;
export const SELF_SERVICE_LETTER_SUBMIT = (adoId: string) =>
  `${CAMUNDA_CONTEXT_URL}/letter/submit/${adoId}`;
export const BUSINESS_TRAVEL_FORM = (adoId: string) =>
  `${CAMUNDA_CONTEXT_URL}/travel/form/details/${adoId}`;
export const BUSINESSTRAVEL_SUBMIT = () => `${CAMUNDA_CONTEXT_URL}/travel/submit`;
export const CREDIT_CARD_DETAIL = (adoId: string, processDefinitionKey: string) =>
  `${CAMUNDA_CONTEXT_URL}/expense/creditInfo/${adoId}/${processDefinitionKey}`;
export const CREDIT_CARD_SUBMIT = (adoId: string) =>
  `${CAMUNDA_CONTEXT_URL}/expense/creditCard/submit/${adoId}`;
export const EXPENSE_REPORT_DETAIL = (adoId: string) =>
  `${CAMUNDA_CONTEXT_URL}/expense/reimbursement/form/details/${adoId}`;
export const EXPENSE_REPORT_SUBMIT = (adoId: string) =>
  `${CAMUNDA_CONTEXT_URL}/expense/report/submit/${adoId}`;
export const HOME_LOAN_SUBMIT = (adoId: string) =>
  `${CAMUNDA_CONTEXT_URL}/home-loan/submit/${adoId}`;
export const HOME_LOAN_INTEREST = () => `${CAMUNDA_CONTEXT_URL}/home-loan/interset-amount`;
export const SCRAP_INVOICE_FORM_DETAILS = () =>
  `${CAMUNDA_CONTEXT_URL}/sale-of-asset-invoice/form/details`;
export const SCRAP_INVOICE_SUBMIT = () => `${CAMUNDA_CONTEXT_URL_V2}/sale-of-asset-invoice/submit`;
export const DELEGATION_FORM_DETAILS = () => `${CAMUNDA_CONTEXT_URL}/delegation/form/details`;
export const DELEGATION_FORM_SUBMIT = () => `${CAMUNDA_CONTEXT_URL}/delegation/form/submit`;

//dashboard
export const DASHBOARD_REQUEST_LIST_CARDS = () => `${AGGREGATOR_CONTEXT_URL}/dashboard`;
export const DASHBOARD_REQUEST_LIST_CARDS_OLD = (adoid: string) =>
  `${CAMUNDA_CONTEXT_URL}/dashboard/${adoid}`;
export const MY_REQUEST_CARDS_WIDGET = () => `${AGGREGATOR_CONTEXT_URL}/dashboard/my-requests`;
export const MY_REQUEST_CARDS_WIDGET_OLD = (userId: string) =>
  `${CAMUNDA_CONTEXT_URL}/dashboard/my-requests/${userId}`;

//processManagements
export const PROCESS_GROUP = () => `${CAMUNDA_CONTEXT_URL}/groups`;
export const LIST_ALL_MEMBER_GROUP = (groupId: string) =>
  `${CAMUNDA_CONTEXT_URL}/group/${groupId}/members`;
export const REMOVE_USER_GROUP = () => `${CAMUNDA_CONTEXT_URL}/group/remove`;
export const ADD_USER_GROUP = () => `${CAMUNDA_CONTEXT_URL}/group/add`;
export const PROCESS_USERS = (hcmId: string) =>
  `${CAMUNDA_CONTEXT_URL_V2}/process/users/${hcmId}/subordinates`;
export const USER_ASSIGNED_PROCESSES = () => `${CAMUNDA_CONTEXT_URL}/process`;
export const REMOVE_USER_ASSIGNED_PROCESS = () => `${CAMUNDA_CONTEXT_URL}/process/employee`;
export const ADD_USER_ASSIGNED_PROCESS = () => `${CAMUNDA_CONTEXT_URL}/process/employee`;
export const SEARCH_PROCESSES = () => `${CAMUNDA_CONTEXT_URL}/process/search`;

//expenses
export const EXPENSES_DATA = () => `${CAMUNDA_CONTEXT_URL_V2}/expense`;
export const EXPENSES_LOOKUP = () => `${CAMUNDA_CONTEXT_URL_V2}/expense/lookup`;
export const EXPENSE_DRAWER = (expenseId: string, hcmPersonId: string) =>
  `${CAMUNDA_CONTEXT_URL}/expense/drawer/${expenseId}/${hcmPersonId}`;

// Jira OLD
export const JIRA_FORM_FIELDS_OLD = (processDefinitionKey: string) =>
  `${CAMUNDA_CONTEXT_URL}/jira/form/details/${processDefinitionKey}`;
export const JIRA_FORM_SUBMIT_OLD = (adoId: string, processDefinitionKey: string) =>
  `${CAMUNDA_CONTEXT_URL}/jira/submit/${adoId}/${processDefinitionKey}`;

// Jira New
export const JIRA_FORM_FIELDS = (serviceDeskId: number, typeId: number) =>
  `${JIRA_CONTEXT_URL}/common/form/details/desk/id/${serviceDeskId}/type/id/${typeId}`;

export const JIRA_FORM_SUBMIT = (serviceDeskId: number, typeId: number) =>
  `${JIRA_CONTEXT_URL_V2}/common/submit/desk/id/${serviceDeskId}/type/id/${typeId}`;

export const JIRA_CREATE_COMMENT = (taskId: string) =>
  `${JIRA_CONTEXT_URL_V2}/task/${taskId}/comment/create`;
export const JIRA_ASSETS_LIST = (serviceDeskId: number, jiraFieldId: number, assetKey: string) =>
  `${JIRA_CONTEXT_URL}/asset/${serviceDeskId}/${jiraFieldId}/${assetKey}`;

//Attachment
export const ATTACHMENT_STANDARD = () => `${CAMUNDA_CONTEXT_URL}/attachment/standard`;

// Expense_Business_Training_Trave
export const COUNTRY_CITY_LIST = () => `${CAMUNDA_CONTEXT_URL}/cities`;
export const HANDLE_DISTANCE_BW_CITIES = (originId: number, destinationId: number) =>
  `${CAMUNDA_CONTEXT_URL}/cities/travel/${originId}/${destinationId}`;
export const SITE_ID = () => `${CAMUNDA_CONTEXT_URL}/sites`;
export const TRAVEL_DURATION = () => `${CAMUNDA_CONTEXT_URL}/travel/duration`;
export const ESTIMATED_AMOUNT = (
  sourceId: number,
  destinationId: number,
  transportationId: number,
  tripId: number
) =>
  `${CAMUNDA_CONTEXT_URL}/travel/total-estimated-amount/${sourceId}/${destinationId}/${transportationId}/${tripId}`;

export const CASH_ADVANCE_DETAIL = () => `${CAMUNDA_CONTEXT_URL}/cash-advance/form/details`;
export const CASH_ADVANCE_SUBMIT = () => `${CAMUNDA_CONTEXT_URL}/cash-advance/submit`;

// Filter Delegators
export const DELEGATOR_NAMES_LIST = () => `${AGGREGATOR_CONTEXT_URL}/delegation/delegator`;

//New api for submit task
export const SUBMIT_TASK_APPROVAL = (taskId: string, rootPath?: string) =>
  `${rootPath ? rootPath + APIVersion.V1 : CAMUNDA_CONTEXT_URL}/submit-task/${taskId}`;

//New profile on going request
export const NEW_PROFILE_ONGOING_REQUEST = () => `${CAMUNDA_CONTEXT_URL}/profile/ongoing-request`;

//New public profile delegate info
export const OTHER_PROFILE_DELEGATE_INFO = (adOid: string) =>
  `${CAMUNDA_CONTEXT_URL}/leave/${adOid}/in-progress`;

//New Profile Request
export const SUBMIT_PROFILE_UPDATE_REQUEST = () => `${CAMUNDA_CONTEXT_URL}/profile`;
export const GET_PROFILE_UPDATE_REQUEST_LOOKUPS = () =>
  `${CAMUNDA_CONTEXT_URL}/profile/form/details`;

//Change bank account request lookups
export const CHANGE_BANK_ACCOUNT_REQUEST_LOOKUP = () =>
  `${CAMUNDA_CONTEXT_URL}/change-bank-account/lookups`;

//Submit Change bank account request
export const SUBMIT_CHANGE_BANK_ACCOUNT_REQUEST = () =>
  `${CAMUNDA_CONTEXT_URL}/change-bank-account/submit`;

//NON-Po

export const NON_PO_INVOICE = `${FINANCE_V1}/non-po-invoice`;
export const NON_PO_INVOICE_LOOKUP_LIST = () => `${NON_PO_INVOICE}/lookup/list`;
export const NON_PO_INVOICE_CONVERTION_RATE = () => `${NON_PO_INVOICE}/currency-conversion/rate`;
export const NON_PO_INVOICE_WITHHOLDING_TAX_RATE = () => `${NON_PO_INVOICE}/withholding-tax/rate`;
export const NON_PO_INVOICE_SUBMIT = () => `${NON_PO_INVOICE}`;
export const NON_PO_INVOICE_ADVANCE_REQUESTS = (
  supplierNumber: string,
  processInstanceId?: string
) =>
  `${NON_PO_INVOICE}/employee-expenses/supplier/advance-requests?supplierNumber=${supplierNumber}&processInstanceId=${
    processInstanceId ?? ''
  }`;
export const NON_PO_INVOICE_SUPPLIER = (codeOrName: string) =>
  `${NON_PO_INVOICE}/employee-expenses/supplier?search=${codeOrName}`;
export const NON_PO_INVOICE_EDIT_REQUEST = (processInstanceId: string) =>
  `${NON_PO_INVOICE}/request/${processInstanceId}`;
export const NON_PO_INVOICE_UPDATE_REQUEST = (processInstanceId: string, taskId: string) =>
  `${NON_PO_INVOICE}/${taskId}/${processInstanceId}`;
export const NON_PO_BUDGET_TEAM_GET_GEL_ACCOUNTS = () => `${NON_PO_INVOICE}/gl-account`;
export const NON_PO_BUDGET_TEAM_GET_COST_CENTER_ACCOUNTS = () => `${NON_PO_INVOICE}/cost-center`;
export const NON_PO_BUDGET_TEAM_GET_PROJECT_ACCOUNTS = () => `${NON_PO_INVOICE}/project`;
export const NON_PO_BUDGET_TEAM_GET_REQUEST_TYPE = (requestId: string) =>
  `${NON_PO_INVOICE}/distribution-combination/${requestId}`;
export const NON_PO_BUDGET_TEAM_POST_TASK = (processInstanceId: string, taskId: string) =>
  `${NON_PO_INVOICE}/budget-form-details/${processInstanceId}/${taskId}`;
export const WORK_FROM_HOME_REMAINING_BALANCE = (hcmId: string) =>
  `${CAMUNDA_CONTEXT_URL}/absence/remaining/duration/${hcmId}/WORK_FROM_HOME_KEY`;

/**
 *
 * @param startDate 2024-11-24
 * @param endDate 2024-11-24
 * @constructor
 */
export const WORK_FROM_HOME_CALCULATE_DURATION = (startDate: string, endDate: string) =>
  `${CAMUNDA_CONTEXT_URL}/leave/duration/WORK_FROM_HOME_KEY?startDate=${startDate}&endDate=${endDate}`;

export const WORK_FROM_HOME_SUBMIT_REQUEST = (hcmPersonId: string) =>
  `${CAMUNDA_CONTEXT_URL}/leave/${hcmPersonId}`;

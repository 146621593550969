import styled from 'styled-components';
import { Card, Typography, Divider, Col, Collapse } from 'antd';
import { MessageOutlined, FileOutlined } from '@ant-design/icons';

import { colors } from 'ui/elements';

const { Text } = Typography;
const { textColorSecondary } = colors;

interface AdditionalProps {
  secondary: 'yes' | 'no'; // for some reason
}

interface SizeProps {
  small: 'yes' | 'no'; // for some reason
}

export const ChatCard = styled(Card).attrs({ bordered: false })`
  display: inline-flex;
  border-radius: 4px;
  background-color: ${(props: AdditionalProps & SizeProps) =>
    props.secondary === 'yes' && colors.chatMessage};
  color: ${colors.primaryColor};
  box-shadow: ${(props: SizeProps) =>
    props.small === 'yes' ? 'none' : '0px 2px 4px rgba(28, 43, 101, 0.14)'};

  .ant-card-body {
    padding: 0;
  }
`;

export const ChatCardContent = styled.div`
  padding: ${(props: SizeProps) => (props.small === 'yes' ? '0' : '16px 16px 12px')};
`;

export const TextSecondary = styled(Text)`
  color: ${(props: AdditionalProps) => props.secondary === 'yes' && textColorSecondary};
`;

export const TextTimeStamp = styled(Text)`
  font-size: 12px;
`;

export const ReplyListBlock = styled.div`
  padding: 6px 16px;
`;

export const ReplyBlock = styled.div`
  padding: 6px 16px;
  user-select: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 24px;
`;

export const ReplyBlockWrapper = styled.div`
  padding: 8px 0;
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
  border-color: ${colors.borderGrey};
`;

export const StyledMessageIcon = styled(MessageOutlined)`
  margin-right: 6px;
`;

export const StyledCol = styled(Col)`
  display: flex;
`;

export const StyledCollapse = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    font-size: 10px;
  }

  & > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    padding-left: 16px;
    font-size: 12px;
    color: ${colors.lightGrey};
  }

  &.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    padding-top: 10px;
  }
`;

export const StyledSeeRepliesButton = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
  user-select: none;
`;

export const AttachmentCard = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  padding: 8px 16px;
  background-color: ${colors.lightGreyThird};
  border: 1px solid ${colors.borderGreySecond};
  border-radius: 2px;
`;

export const AttachmentsWrapper = styled.div`
  margin-top: 8px;
`;

export const StyledFileIcon = styled(FileOutlined)`
  font-size: 26px;
  margin-right: 14px;
`;

export const StyledItalicText = styled.span`
  font-style: italic;
`;

import { useQuery } from 'react-query';
import { nonPoInvoice } from '../NONPoInvoice';
import { QUERIES } from '../../constants';

export const useNonPoInvoiceEditRequestApi = (processId: string, isEnabled: boolean) => {
  return useQuery(
    [QUERIES.nonPoInvoiceGetEditRequest, processId],
    async () => {
      const { data } = await nonPoInvoice.getNonPoInvoiceEditRequest(processId);
      return data;
    },
    { enabled: isEnabled, keepPreviousData: false }
  );
};

import { Col } from 'antd';
import React, { ReactNode } from 'react';
import { DrawerHeaderRow, DrawerTitle as DrawerTitleStyled } from './DrawerUi.styled';

interface Props {
  DrawerTitle: string;
  extra?: ReactNode;
}

export const DrawerHeader: React.FC<Props> = ({ DrawerTitle, extra }) => {
  return (
    <DrawerHeaderRow justify="space-between">
      <Col>
        <DrawerTitleStyled ellipsis>{DrawerTitle}</DrawerTitleStyled>
      </Col>
      <Col style={{ alignSelf: 'center' }}>{extra}</Col>
    </DrawerHeaderRow>
  );
};

import { useQuery } from 'react-query';
import { QUERIES } from '../../constants';
import { categoryCard } from '../CategoryCard';

export function useCategoryCard(categoryName: string) {
  return useQuery(
    [QUERIES.categoryCard, categoryName],
    async () => (await categoryCard.getCategoryCard(categoryName)).data,
    {
      refetchOnWindowFocus: false,
      enabled: !!categoryName,
    }
  );
}

import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { Card, notification, Row, Col, Empty } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import {
  useDispatch,
  useSelector,
  paySlipsPdfFileSelector,
  getPaySlipsPdfFileStart,
  getPaySlipsPdfFileRest,
} from 'store';
import { TitleH3 } from 'ui';
import { useTranslate } from 'translations';
import { PaySlipData, RequestStatus } from 'types';
import moment from 'moment';
import { StyledDivider } from 'ui/components/TaskList/TaskCard/TaskCard.styled';
import { magicDownload } from 'routes/Expenses/helper';
import {
  StyledAmount,
  StyledButton,
  StyledCardValue,
  StyledDatePicker,
  StyledIcon,
  StyledNoDataEmpty,
  StyledPayrollCard,
  StyledWrapperRow,
} from './Payroll.styled';
import { ConnectorProps, paySlipsConnector } from './paySlips.connector';

interface Props {
  hcmPersonId: string | null;
  hireDate: string;
}

export const PayrollComponent: FC<Props & ConnectorProps> = ({
  paySlipsData,
  getPaySlipsStart,
  hcmPersonId,
  hireDate,
}) => {
  const [selectedYear, setSelectedYear] = useState<string>();
  const [selectedMonth, setSelectedMonth] = useState<string>();
  const [payrollCardData, setPayrollCardData] = useState<PaySlipData>();
  const [isSelectMonth, setIsSelectMonth] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<string>();
  const dispatch = useDispatch();
  const paySlipsFile = useSelector(paySlipsPdfFileSelector);

  const { t } = useTranslate();

  const onSelectDate = useCallback(
    (date: moment.Moment) => {
      setSelectedYear(date.get('year').toString());
      setSelectedMonth(date.format('MM/yyyy'));
    },
    [setSelectedMonth, setSelectedYear]
  );

  useEffect(() => {
    if (paySlipsFile.data) {
      dispatch(getPaySlipsPdfFileRest());
    }
  }, [paySlipsFile, dispatch]);

  useEffect(() => {
    if (paySlipsFile.status === RequestStatus.resolved && paySlipsFile.data) {
      magicDownload(
        paySlipsFile.data.fileContentType,
        paySlipsFile.data.fileContent,
        paySlipsFile.data.fileName
      );
    }
  }, [paySlipsFile]);

  const disableDownloadButton = useMemo(() => {
    if (payrollCardData && payrollCardData.length > 0 && isSelectMonth) {
      return true;
    } else {
      return false;
    }
  }, [payrollCardData, isSelectMonth]);

  const onClickDownloadPaySlips = useCallback(() => {
    if (hcmPersonId && selectedMonth && selectedYear) {
      const month = parseInt(selectedMonth).toString();

      dispatch(
        getPaySlipsPdfFileStart({
          userId: hcmPersonId,
          month: month,
          year: selectedYear,
        })
      );
    }
  }, [hcmPersonId, selectedMonth, selectedYear, dispatch]);

  useEffect(() => {
    if (paySlipsData.data && paySlipsData.data.length > 0) {
      if (paySlipsData.data.length > 3) {
        const first4PaySlips = paySlipsData.data.slice(0, 4);
        setPayrollCardData(first4PaySlips);
      } else {
        setPayrollCardData(paySlipsData.data);
      }
    }
  }, [paySlipsData.data, selectedMonth, setPayrollCardData]);

  const onChangeDate = useCallback(
    (selectedDate) => {
      if (selectedDate) {
        onSelectDate(selectedDate);
        setIsSelectMonth(true);
      } else {
        setSelectedYear(undefined);
        setSelectedMonth(undefined);
        setIsSelectMonth(false);
        dispatch(getPaySlipsPdfFileRest());
      }
    },
    [dispatch, onSelectDate]
  );

  useEffect(() => {
    if (!selectedYear) {
      setCurrentDate(moment().format('MM/yyyy'));
    }
  }, [selectedYear]);

  useEffect(() => {
    if (hcmPersonId && currentDate) {
      const payload = { selectedMonth: isSelectMonth };

      if (isSelectMonth && selectedMonth) {
        setPayrollCardData(undefined);
        dispatch(getPaySlipsPdfFileRest());
        getPaySlipsStart({ userId: hcmPersonId, year: selectedMonth, params: payload });
      } else {
        setPayrollCardData(undefined);
        dispatch(getPaySlipsPdfFileRest());
        getPaySlipsStart({ userId: hcmPersonId, year: currentDate, params: payload });
      }
    }
  }, [getPaySlipsStart, hcmPersonId, currentDate, isSelectMonth, selectedMonth, dispatch]);

  useEffect(() => {
    if (paySlipsData.status === RequestStatus.rejected) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [paySlipsData.status, t]);

  const isLoading = paySlipsData.status === RequestStatus.pending;
  const isLoadingPdfFile = paySlipsFile.status === RequestStatus.pending;

  const disableBeforeHire = useCallback(
    (current: moment.Moment) => {
      const beforeHire = current.isBefore(hireDate, 'months');

      return beforeHire;
    },
    [hireDate]
  );

  return (
    <>
      <Card>
        <Row justify={'space-between'}>
          <Col>
            <TitleH3 type="primary">{t('user.privateProfile.title.payroll')}</TitleH3>
          </Col>
          <Col>
            <Row align={'middle'} justify={'space-between'}>
              <Col>
                <StyledDatePicker
                  placeholder={t('user.privateProfile.payroll.datePicker.placeholder')}
                  picker={'month'}
                  format={'MMMM YY'}
                  suffixIcon={<StyledIcon />}
                  disabledDate={disableBeforeHire}
                  onChange={(date) => onChangeDate(date)}
                />
              </Col>
              <Col>
                <StyledButton
                  type="link"
                  onClick={onClickDownloadPaySlips}
                  loading={isLoadingPdfFile}
                  disabled={!disableDownloadButton}
                  download
                >
                  <DownloadOutlined /> {t('user.privateProfile.payroll.downloadPayslip')}
                </StyledButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Card loading={isLoading}>
        <StyledWrapperRow gutter={[24, 24]}>
          {payrollCardData && payrollCardData.length > 0 ? (
            payrollCardData.map((item, index) => {
              return (
                <Col key={index} xs={24} sm={12} lg={6}>
                  <StyledPayrollCard>
                    <Row justify={'center'} align={'middle'}>
                      <Col span={24} style={{ textAlign: 'center', width: '100%' }}>
                        <StyledCardValue strong ellipsis>
                          {moment(item.paymentDate).format('MMMM YY')}
                        </StyledCardValue>
                      </Col>
                      <StyledDivider />
                      <Col span={24}>
                        <Row align={'middle'} justify={'center'}>
                          <Col>
                            <StyledAmount>
                              {item.defaultCurrencyCode
                                ? item.defaultCurrencyCode.concat(
                                    item.amount ? ' '.concat(item.amount) : ''
                                  )
                                : item.amount}
                            </StyledAmount>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </StyledPayrollCard>
                </Col>
              );
            })
          ) : (
            <StyledNoDataEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </StyledWrapperRow>
      </Card>
    </>
  );
};

export const Payroll = paySlipsConnector(PayrollComponent);

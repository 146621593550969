export const AllowanceFieldsFormName = {
  requestType: 'requestType',
  requestDescription: 'requestDescription',
  allowanceType: 'allowanceType',
  cashInAdvance: 'cashInAdvance',
  Department: 'department',
  totalAmount: 'totalAmount',
  description: 'description',
  expectedInvoiceDate: 'expectedInvoiceDate',
  invoiceDate: 'invoiceDate',
  amount: 'amount',
  allowanceTypeValue: 'allowanceTypeValue',
  remainingBalance: 'remainingBalance',
};

export const SelfServiceLetterFieldName = {
  letterType: 'letterType',
  directedTo: 'directedTo',
  bankName: 'bankName',
  userBankNameAR: 'bankNameArabic',
  userBankNameENG: 'bankNameEnglish',
  otherAR: 'otherArabic',
  otherENG: 'otherEnglish',
  embassy: 'embassyName',
  chamberOfCommerce: 'chamberOfCommerce',
};

export const BusinessTrainingTravelName = {
  travelPurpose: 'travelPurposeId',
  from: 'fromId',
  country: 'country',
  city: 'city',
  to: 'toId',
  destinationCountry: 'destinationCountry',
  destinationCity: 'destinationCity',
  departingDate: 'departingDate',
  returningDate: 'returningDate',
  travelDuration: 'travelDuration',
  tripType: 'tripTypeId',
  transportation: 'transportationId',
  ticketClass: 'ticketClass',
  totalDistance: 'totalDistance',
  siteId: 'siteId',
  destinationCountryCategory: 'destinationCountryCategory',
  businessDates: 'businessDates',
  actualTravelDates: 'actualTravelDates',
  estimatedTotalAmount: 'estimatedTotalAmount',
  currentLocation: 'currentLocation',
  details: 'details',
};

export const CreditCardName = {
  costCenterDepartment: 'deptCostCenter',
  totalAmount: 'maxAmount',
};

export const ExpenseReportName = {
  cashAdvanceOrCard: 'cashAdvanceOrCard',
  totalAmount: 'totalAmount',
  departmentCostCenter: 'departmentCostCenter',
};

export const ExpenseReportItem = {
  expenseType: 'expenseType',
  currency: 'currency',
  amount: 'amount',
};

export const CommonFieldName = {
  attachment: 'attachment',
  moreDetail: 'moreDetails',
  description: 'description',
};

export const ExpenseTypeName = {
  education: 'Education',
  healthClub: 'Health club',
};

export const HomeLoanName = {
  department: 'department',
  joiningDate: 'joiningDate',
  workingYears: 'workingYears',
  ongoingHomeLoan: 'isHomeLoanOngoing',
  details: 'details',
};

export const CashAdvanceName = {
  expectedInvoiceDate: 'invoiceDate',
  costCenterDepartment: 'department',
  totalAmount: 'totalAmount',
};

export const ScrapInvoiceName = {
  customerName: 'customerName',
  totalAmountSAR: 'totalAmount',
  purpose: 'purposeId',
};
export const ScrapInvoiceItemName = {
  assetName: 'assetName',
  assetNumber: 'assetNumber',
  cTagNumber: 'tagC',
  pTagNumber: 'tagP',
  technicalReportOfAssets: 'technicalReportList',
  quantity: 'quantity',
  amountSAR: 'amount',
};

export const TaskStepBudgetName = {
  allocatedBudge: 'allocatedBudge',
  budgetReviewfeedback: 'budgetReviewfeedback',
  yes: 'yes',
  approved: 'approved',
};

export const ActingAppointmentName = {
  employeeName: 'employeeName',
  purpose: 'purpose',
  positionActing: 'positionActing',
  department: 'department',
  actingDuration: 'actingDuration',
  startDate: 'startDate',
};

export const PRODUCT_INFO_FORM_COL_SPAN = 24;

export const DEFAULT_TOTAL_LETTER = 1024;

export const DEFAULT_TOTAL_LETTER_ITEM = 250;

export const WRAPPER_COL_SPAN_SIZE_21 = { span: 21 };

export const FROM_ITEM_LIST = 'ITEMS';

export const checkBetweenTwoMonths = (firstDate: Date, secondDate: Date) => {
  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth()
  );
};

export const NEW_SITE_ACQUISITION_FORM = {
  customerSiteID: 'customerSiteID',
  requestingCustomerName: 'requestingCustomerName',
  agreementNumber: 'agreementNumber',
  project: 'project',
  requestTimestamp: 'requestTimestamp',
  bssRequestNumber: 'bssRequestNumber',
  vipRequest: 'vipRequest',
  region: 'region',
  district: 'district',
  radius: 'radius',
  longitude: 'longitude',
  geoTypeLocationCluster: 'geoTypeLocationCluster',
  potentialCustomer: 'potentialCustomer',
  attachments: 'attachments',
  latitude: 'latitude',
  dpProposedTowerHeight: 'dpProposedTowerHeight',
  expectedCollocationNumber: 'expectedCollocationNumber',
  expectedPackages: 'expectedPackages',
  forecastDate: 'forecastDate',
  priority: 'priority',
};

import { useMutation } from 'react-query';
import { notificationCenter } from '../NotificationCenter';

export const useMarkNotificationAsRead = (triggerAction: () => void) => {
  return useMutation(
    async (id) => {
      const params = {
        requestId: id,
      };
      const { data } = await notificationCenter.markNotificationAsRead(params);
      return data;
    },
    {
      onSuccess: async (data: string, context: string) => {
        triggerAction();
      },
    }
  );
};

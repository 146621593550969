import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { UserInfoData, RequestCommonError, RequestStatus } from 'types';
import { UserInfoState } from './userInfoCardTypes';

export const UserInfoCardInitialState: UserInfoState = {};

export type GetUserInfoCardStart = PayloadAction<{
  [key: string]: string;
  params: AxiosRequestConfig['params'];
}>;
export type GetUserInfoCardSuccess = PayloadAction<{
  inputValue: string;
  data: UserInfoData;
}>;
export type GetUserInfoCardFail = PayloadAction<{
  inputValue: string;
  error: RequestCommonError;
}>;

const userInfoCard = createSlice({
  name: 'userInfoCard',
  initialState: UserInfoCardInitialState,
  reducers: {
    getUserInfoCardStart(state, action: GetUserInfoCardStart) {
      const inputValue = Object.values(action.payload)[0];
      if (state[inputValue]) {
        state[inputValue].status = RequestStatus.pending;
        return;
      }
      state[inputValue] = {
        ...state[inputValue],
        status: RequestStatus.pending,
      };
    },
    getUserInfoCardSuccess(state, action: GetUserInfoCardSuccess) {
      state[action.payload.inputValue].status = RequestStatus.resolved;
      state[action.payload.inputValue].data = action.payload.data;
    },
    getUserInfoCardFail(state, action: GetUserInfoCardFail) {
      state[action.payload.inputValue] = {
        ...state[action.payload.inputValue],
        status: RequestStatus.rejected,
      };
      state[action.payload.inputValue].error = action.payload.error;
    },
  },
});

export const {
  getUserInfoCardStart,
  getUserInfoCardSuccess,
  getUserInfoCardFail,
} = userInfoCard.actions;
export default userInfoCard.reducer;

import React from 'react';
import { useTranslate } from 'translations';
import { useGetUserAssignedProcesses, useRemoveProcessFromUserProcesses } from 'api';
import { FullWidthSpin } from 'ui/elements';
import { Empty, notification } from 'antd';
import { UserTableUserProcesses } from 'types';
import {
  UserProcessesContainer,
  UserProcesses,
  ManagerActionsContainer,
  UserProcessTitle,
  FullWidthRow,
} from './UserDrawerUserProcesses.styled';
import { UserDrawerUserProcess } from './components/UserDrawerUserProcess/UserDrawerUserProcess';
import { UserDrawerAddProcessPopover } from './components/UserDrawerAddProcessPopover/UserDrawerAddProcessPopover';

type Props = {
  userId: string;
  onProcessesLoad: (processes: UserTableUserProcesses) => void;
};

export const UserDrawerUserProcesses: React.FC<Props> = ({ userId, onProcessesLoad }) => {
  const { t } = useTranslate();
  const [userProcesses, setUserProcesses] = React.useState<UserTableUserProcesses | undefined>();
  const {
    isLoading: userProcessesLoading,
    mutateAsync: fetchUserProcesses,
  } = useGetUserAssignedProcesses();
  const {
    isLoading: removeProcessLoading,
    mutateAsync: removeProcessFromUser,
  } = useRemoveProcessFromUserProcesses();
  const getUserProcesses = React.useCallback(() => {
    fetchUserProcesses(userId).then((processes) => {
      onProcessesLoad(processes);
      setUserProcesses(processes);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  const onProcessRemove = (processId: number) => {
    if (userId && processId) {
      removeProcessFromUser({ selectedUserAdOid: userId, processKey: processId })
        .then(() => {
          getUserProcesses();
          notification.success({
            message: t('processManagement.users.drawer.notification.removed.success'),
          });
        })
        .catch(() => {
          notification.error({
            message: t('messages.error.wentWrong'),
          });
        });
    }
  };
  React.useEffect(() => {
    getUserProcesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <UserProcessesContainer>
      <UserProcessTitle>
        {t('processManagement.users.drawer.process')}{' '}
        {userProcesses && `(${userProcesses.processes.length})`}
      </UserProcessTitle>
      {userProcessesLoading && <FullWidthSpin />}
      {userProcesses && !userProcessesLoading && (
        <>
          <UserProcesses>
            {userProcesses.processes.length > 0 ? (
              userProcesses.processes.map((process) => (
                <UserDrawerUserProcess
                  key={process.id}
                  id={process.id}
                  name={process.name}
                  showManagerView={userProcesses?.isManager && !userProcesses?.isSelf}
                  isRemoveLoading={removeProcessLoading}
                  onRemove={() => {
                    onProcessRemove(process.id);
                  }}
                />
              ))
            ) : (
              <FullWidthRow justify="center" align="middle">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('processManagement.users.drawer.no.process')}
                />
              </FullWidthRow>
            )}
          </UserProcesses>
          {userProcesses.isManager && !userProcesses.isSelf && (
            <ManagerActionsContainer>
              <UserDrawerAddProcessPopover onAddProcess={getUserProcesses} userId={userId} />
            </ManagerActionsContainer>
          )}
        </>
      )}
    </UserProcessesContainer>
  );
};

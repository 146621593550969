import { TeamsChannelFolderData } from 'types';
import { Core, FILE_IN_GROUP_FOLDER } from 'api';

interface GetTeamChannelFolderList {
  (teamId: string, folderId: string): Promise<{ data: TeamsChannelFolderData }>;
}

class TeamChannelFolderList extends Core {
  getTeamChannelFolderList: GetTeamChannelFolderList = async (teamId, folderId) =>
    this.get(FILE_IN_GROUP_FOLDER(teamId, folderId));
}

export const teamsChannelFolderList = new TeamChannelFolderList();

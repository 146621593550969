import React, { FC, ReactNode } from 'react';
import { TType } from 'translations';
import {
  StyledProfileRowContainer,
  StyledLink,
  StyledIconContainer,
} from '../../NewProfile.styled';

type Props = {
  t: TType;
  icon: ReactNode;
  title: string;
  link: string;
};

export const PrivateProfilePrivacyNotice: FC<Props> = ({ t, link, icon, title }) => {
  return (
    <StyledProfileRowContainer justify="space-between" align="middle" gutter={[25, 15]} wrap={true}>
      <StyledLink href={link} target="_blank" underline={true}>
        {title}
      </StyledLink>
      <StyledIconContainer>{icon}</StyledIconContainer>
    </StyledProfileRowContainer>
  );
};

import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { StyledContainer, StyledCard } from './TasksLayout.styled';

interface TasksLayoutProps {
  sidebar: React.ReactNode;
  sidebarTitle?: React.ReactNode;
  isMaxWidth?: boolean;
}

export const TasksLayout: FC<TasksLayoutProps> = ({
  sidebar,
  sidebarTitle,
  children,
  isMaxWidth,
}) => {
  return (
    <Row>
      <Col span={6}>
        <StyledCard title={sidebarTitle} $isMaxWidth={isMaxWidth}>
          {sidebar}
        </StyledCard>
      </Col>
      <Col span={18}>
        <StyledContainer>{children}</StyledContainer>
      </Col>
    </Row>
  );
};

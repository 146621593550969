import { taskComments as taskCommentsApi } from 'api';
import { notification } from 'antd';
import { TType } from 'translations';

export const handleDownloadClick = async (
  name: string,
  commentId: string,
  attachmentId: string,
  t: TType,
  rootPath?: string
) => {
  const downloadWindow = window.open('', '_blank');
  try {
    const resp = await taskCommentsApi.downloadCommentAttachment(
      commentId,
      attachmentId,
      {
        responseType: 'blob',
      },
      rootPath
    );
    saveAs(resp?.data, name);
    return resp?.data;
  } catch (e) {
    notification.error({
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    });
    return true;
  } finally {
    downloadWindow?.close();
  }
};

import { useMutation } from 'react-query';
import { QUERIES } from '../../constants';
import { changeBankAccountRequest } from '../ChangeBankAccountRequest';
import { CBARSubmitPostParams } from '../../../types';

export const useChangeBankAccountRequestSubmit = () =>
  useMutation([QUERIES.changeBankAccountRequestSubmit], async (params: CBARSubmitPostParams) => {
    const { data } = await changeBankAccountRequest.submitRequest(params);
    return data;
  });

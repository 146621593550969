import React, { FC, useEffect, memo } from 'react';
import { RouteProps, Redirect, useHistory, useLocation } from 'react-router-dom';
import { notification } from 'antd';

import { PagesUrls } from 'namespace/routes.mappings';
import { useTranslate } from 'translations';
import { useAuthContext } from 'authentication';
import { PageSpin } from 'ui';
import { DataLoadingError } from 'routes';
import { useSelector } from 'react-redux';
import { isContractorEmployee } from 'store';
import { useAppLayoutAlert } from 'layouts/AppLayout/hooks/useAppLayoutAlert';
import { ConnectorProps, privateRouteConnector } from './PrivateRouteAppLayout.connector';
import { RouteAppLayout } from './RouteAppLayout';

interface Props extends RouteProps {
  component: RouteProps['component'];
}

const PrivateRouteAppLayoutComponent: FC<Props & ConnectorProps> = ({
  component,
  hasUserProfileData,
  getProfileStart,
  userProfileDataNotReady,
  ...rest
}) => {
  const { isAuthenticated, requestedUrl } = useAuthContext();
  const { pathname } = useLocation();
  const { isAlert } = useAppLayoutAlert();
  const { t } = useTranslate();
  const history = useHistory();
  const isContractor = useSelector(isContractorEmployee);
  const blockedRoutes = ['/expenses', '/attendance'];
  useEffect(() => {
    if (isAuthenticated && !hasUserProfileData) {
      getProfileStart();
    }
  }, [isAuthenticated, getProfileStart, hasUserProfileData]);

  useEffect(() => {
    if (!userProfileDataNotReady && !hasUserProfileData) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [userProfileDataNotReady, hasUserProfileData, t]);

  useEffect(() => {
    if (isAuthenticated && requestedUrl) {
      const [path] = requestedUrl.split('?');
      const pathname = `/${path.split('/')[1]}`;
      if (
        pathname === PagesUrls.Tasks ||
        pathname === PagesUrls.MyWork ||
        pathname === PagesUrls.Settings
      ) {
        history.push(requestedUrl);
      }
    }
  }, [history, isAuthenticated, requestedUrl]);

  if (isContractor && blockedRoutes.includes(pathname)) {
    return <Redirect to={PagesUrls.Dashboard} />;
  }
  if (isAuthenticated === null) {
    return <PageSpin tip={t('authentication')} />;
  }
  if (!isAuthenticated && isAlert) {
    return <RouteAppLayout component={component} {...rest} />;
  } else if (!isAuthenticated) {
    return <Redirect to={PagesUrls.Login} />;
  }
  if (userProfileDataNotReady) {
    return <PageSpin tip={t('retrievingProfileInformation')} />;
  }
  if (hasUserProfileData) {
    return <RouteAppLayout component={component} {...rest} />;
  }
  return <DataLoadingError />;
};

export const PrivateRouteAppLayout = memo(privateRouteConnector(PrivateRouteAppLayoutComponent));

import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { Core, NOTIFICATION_SETTINGS } from 'api';
import { NotificationSetting } from 'types';

interface GetNotificationSettings {
  (): Promise<{ data: NotificationSetting }>;
}

interface PostNotificationSettings {
  (data: AxiosRequestConfig['data']): AxiosPromise<NotificationSetting>;
}

class Settings extends Core {
  getNotificationSettings: GetNotificationSettings = async () => this.get(NOTIFICATION_SETTINGS());
  postNotificationSettings: PostNotificationSettings = async (data) =>
    this.post(NOTIFICATION_SETTINGS(), data);
}

export const settings = new Settings();

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const hierarchy = (state: RootState): RootState['hierarchy'] => state.hierarchy;

export const hierarchyData = createSelector(hierarchy, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

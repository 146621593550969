import { OneDriveSharedData } from 'types';
import { Core, SHARED_WITH_ME } from 'api';

interface GetOneDriveShared {
  (): Promise<{ data: OneDriveSharedData }>;
}

class OneDriveSharedModule extends Core {
  getOneDriveShared: GetOneDriveShared = async () => this.get(SHARED_WITH_ME());
}

export const oneDriveShared = new OneDriveSharedModule();

import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';
import { colors } from 'ui/elements';
import { AutoComplete } from 'antd';

export const StyledSearchIcon = styled(SearchOutlined)`
  color: ${colors.lightGrey};
`;

export const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
`;

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  getPublicHolidaysFail,
  getPublicHolidaysStart,
  getPublicHolidaysSuccess,
  GetPublicHolidaysFail,
  GetPublicHolidaysStart,
  GetPublicHolidaysSuccess,
} from './publicHolidaysSlice';

export const publicHolidaysEpic: Epic<
  GetPublicHolidaysStart,
  GetPublicHolidaysSuccess | GetPublicHolidaysFail,
  RootState,
  typeof API
> = (action$, _, { publicHolidays }) =>
  action$.pipe(
    filter(getPublicHolidaysStart.match),
    exhaustMap(({ payload }) =>
      from(publicHolidays.getPublicHolidays()).pipe(
        map(({ data }) => getPublicHolidaysSuccess(data)),
        catchError((error) => of(getPublicHolidaysFail(error?.response?.data)))
      )
    )
  );

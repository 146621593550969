import React, { FC } from 'react';
import { useTranslate } from 'translations';
import { StyledTeamsPage } from '../MSTeams.styled';
import { MSTeamsChatTabs } from '../MSTeamsChatTabs';
import { MSTeamsTeamsTabs } from '../MSTeamsTeamsTabs';
import { useMSTeamsContext } from '../MSTeamsProvider';
import { CenteredText, PageContainer } from './MSTeamsPage.styled';

interface Props {
  activeTabKey: string;
}

export const MSTeamsPage: FC<Props> = ({ activeTabKey }) => {
  const { t } = useTranslate();
  const { currentChannelId, currentChatId } = useMSTeamsContext();

  const isTeamsTabActive = activeTabKey === '0';

  const teams = currentChannelId ? (
    <MSTeamsTeamsTabs channelId={currentChannelId} />
  ) : (
    <CenteredText type="secondary">{t('msteams.selectChannel')}</CenteredText>
  );
  const chat = currentChatId ? (
    <MSTeamsChatTabs />
  ) : (
    <CenteredText type="secondary">{t('msteams.selectChat')}</CenteredText>
  );

  return (
    <StyledTeamsPage>
      <PageContainer>{isTeamsTabActive ? teams : chat}</PageContainer>
    </StyledTeamsPage>
  );
};

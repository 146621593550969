import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetOneDriveSharedStart,
  GetOneDriveSharedSuccess,
  GetOneDriveSharedFail,
  getOneDriveSharedFail,
  getOneDriveSharedStart,
  getOneDriveSharedSuccess,
} from './oneDriveSharedSlice';

export const getOneDrive: Epic<
  GetOneDriveSharedStart,
  GetOneDriveSharedSuccess | GetOneDriveSharedFail,
  RootState,
  typeof API
> = (action$, _, { oneDriveShared }) =>
  action$.pipe(
    filter(getOneDriveSharedStart.match),
    exhaustMap(() =>
      from(oneDriveShared.getOneDriveShared()).pipe(
        map(({ data }) => getOneDriveSharedSuccess({ data })),
        catchError((error) => of(getOneDriveSharedFail(error?.response?.data)))
      )
    )
  );

import React from 'react';
import { TType } from '../../../translations';
import {
  StyledELibraryCodesContainer,
  StyledELibraryCodeTextDash,
  StyledELibraryDrawerSubheading,
} from '../ELibrary.styled';
import { ELibraryCode } from './ELibraryCode';

export interface ELibraryHowToReadDocumentCodesProps {
  t: TType;
}

export const ELibraryHowToReadDocumentCodes: React.FC<ELibraryHowToReadDocumentCodesProps> = ({
  t,
}: ELibraryHowToReadDocumentCodesProps) => {
  const dash = <StyledELibraryCodeTextDash>{'-'}</StyledELibraryCodeTextDash>;
  return (
    <>
      <StyledELibraryDrawerSubheading>{t('eLibrary.howToRead')}</StyledELibraryDrawerSubheading>
      <StyledELibraryCodesContainer>
        <ELibraryCode t={t} text={'XX'} leftHint={true} hint={t('eLibrary.sectorCode')} />
        {dash}
        <ELibraryCode
          t={t}
          text={'YY'}
          bigArrow={true}
          leftHint={true}
          hint={t('eLibrary.functionCode')}
        />
        {dash}
        <ELibraryCode t={t} text={'ZZ'} bigArrow={true} hint={t('eLibrary.documentCode')} />
        {dash}
        <ELibraryCode t={t} text={'##'} hint={t('eLibrary.documentNo')} />
      </StyledELibraryCodesContainer>
    </>
  );
};

import { Empty, Form, Spin } from 'antd';
import React, { FC, Fragment, memo } from 'react';
import { useTranslate } from 'translations';
import {
  FormField,
  FormFields,
  FormFiledType,
  JiraDynamicFormFieldProperties,
  JiraDynamicFormFieldPropertiesActionsEnum,
  JiraDynamicFormFieldType,
  JiraDynamicFormFieldTypeEnum,
  ValidationConstraintsType,
} from 'types';
import { Values } from 'ui/components/TaskList/TaskSteps/TaskSteps.types';
import { VALIDATE_MESSAGES } from 'utils';
import { useForm } from 'antd/lib/form/Form';
import { GeneratedForm } from 'ui/components/GeneratedForm';
import { SERVICE_NEW_REQUEST_FORM } from '../../ServiceNewRequestForm';

interface Props {
  formData: JiraDynamicFormFieldType[] | null;
  formName: string;
  onSubmitJira: (variables?: Values) => void;
  isError?: boolean;
  description?: string;
  isLoading?: boolean;
  assetsList?: { id: string; value: string }[];
  processDefinitionKey?: string;
}

export const GeneratedJiraFormComponent: FC<Props> = ({
  formData,
  formName,
  isError,
  description,
  onSubmitJira,
  isLoading,
  assetsList,
  processDefinitionKey,
}) => {
  const [form] = useForm();
  const { t } = useTranslate();
  const [transformedFormData, setTransformedFormData] = React.useState<FormFields>([]);

  const getFormFieldType = (formFieldType: JiraDynamicFormFieldTypeEnum): FormFiledType => {
    let type: FormFiledType = 'text';
    switch (formFieldType) {
      case JiraDynamicFormFieldTypeEnum.TEXT:
        type = 'string';
        break;
      case JiraDynamicFormFieldTypeEnum.TEXTAREA:
        type = 'textArea';
        break;
      case JiraDynamicFormFieldTypeEnum.SELECT:
        type = 'enum';
        break;
      case JiraDynamicFormFieldTypeEnum.MULTISELECT:
        type = 'multipleSelect';
        break;
      case JiraDynamicFormFieldTypeEnum.URL:
        type = 'string';
        break;
      case JiraDynamicFormFieldTypeEnum.CHECKBOX:
        type = 'checkbox';
        break;
      case JiraDynamicFormFieldTypeEnum.ATTACHMENT:
        type = 'file';
        break;
      case JiraDynamicFormFieldTypeEnum.ASSIGNASSET:
        type = 'assignAsset';
        break;
      case JiraDynamicFormFieldTypeEnum.ASSETDEALLOCATE:
        type = 'assignAssetCheckbox';
        break;
      default:
        type = 'text';
        break;
    }
    return type;
  };

  const getFormFiledEnums = (
    formFieldEnums?: { id: string; value: string }[]
  ): { [key: string]: string } | undefined => {
    if (!formFieldEnums) return;
    const transformedEnums: { [key: string]: string } = {};
    formFieldEnums.forEach((formFieldEnum) => {
      transformedEnums[formFieldEnum.id] = formFieldEnum.value;
    });
    return transformedEnums;
  };

  const getDependentFields = (
    fieldProperties: JiraDynamicFormFieldProperties[] | undefined
  ):
    | { key: string; value: string; action: JiraDynamicFormFieldPropertiesActionsEnum }
    | undefined => {
    if (!fieldProperties) return;
    return {
      key: fieldProperties[0].dependantFieldKey,
      value: fieldProperties[0].dependantFieldValue,
      action: fieldProperties[0].actionName,
    };
  };

  React.useEffect(() => {
    const tempTransformedFormData: FormFields = [];
    if (formData) {
      formData.forEach((formField) => {
        const isAssetUpgrade =
          formField.key === 'ASSET_UPGRADE' || formField.key === 'ASSET_TO_DE_ALLOCATE';
        const validationConstraints: ValidationConstraintsType[] = [];
        if (formField.required) {
          validationConstraints.push({ name: 'required', configuration: null });
        }
        if (formField.type === JiraDynamicFormFieldTypeEnum.URL) {
          validationConstraints.push({
            name: 'pattern',
            // eslint-disable-next-line
            configuration: /(http(s)?):\/\/[(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          });
        }
        const formFiledDependentFields = getDependentFields(formField.fieldProperties);
        const formFiledType = getFormFieldType(formField.type);
        const transformedFormField: FormField = {
          businessKey: false,
          id: formField.key,
          label: formField.label,
          type: {
            name: formFiledType,
            values: isAssetUpgrade
              ? getFormFiledEnums(assetsList)
              : getFormFiledEnums(formField.fieldEnums),
          },
          defaultValue: null,
          value: {
            value: null,
            type: {
              name: formFiledType,
            },
          },
          typeName: formFiledType,
          validationConstraints: validationConstraints,
          properties: {
            requiredField: formField.required,
            fullWidth: formField.type === JiraDynamicFormFieldTypeEnum.CHECKBOX,
            dependencyFieldKey: formFiledDependentFields?.key,
            dependencyFieldValue: formFiledDependentFields?.value,
            dependencyFieldAction: formFiledDependentFields?.action,
            defaultHidden:
              formFiledDependentFields?.action === JiraDynamicFormFieldPropertiesActionsEnum.SHOW
                ? 'true'
                : undefined,
          },
        };
        tempTransformedFormData.push(transformedFormField);
      });
    }
    setTransformedFormData(tempTransformedFormData);
  }, [formData, assetsList]);

  return (
    <Fragment>
      <Form
        form={form}
        layout={'vertical'}
        name={formName}
        id={formName}
        validateMessages={VALIDATE_MESSAGES(t)}
        onFinish={onSubmitJira}
      >
        {isError ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
        ) : (
          <Spin spinning={isLoading} delay={100}>
            <GeneratedForm
              formData={transformedFormData}
              formName={SERVICE_NEW_REQUEST_FORM}
              onSubmit={() => {
                onSubmitJira(form.getFieldsValue());
              }}
              form={form}
              customSpaces={true}
              processDefinitionKey={processDefinitionKey}
            />
          </Spin>
        )}
      </Form>
    </Fragment>
  );
};

export const GeneratedJiraForm = memo(GeneratedJiraFormComponent);

import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Empty, Form, Spin } from 'antd';
import { VALIDATE_MESSAGES } from 'utils';
import { TType } from 'translations';
import { useForm } from 'antd/lib/form/Form';
import { ExpenseReportItem, ExpenseReportName, FROM_ITEM_LIST, FullWidthSpin } from 'ui';
import {
  useExpenseReimbursement,
  useExpenseReimbursementSubmit,
} from 'api/StaticForm/hooks/ExpenseReimbursement';
import { useHistory } from 'react-router-dom';
import { submitAttachmentForm } from 'ui/publicFunction';
import { UploadFile } from 'antd/lib/upload/interface';
import { useHandleSubmitStatus } from 'routes/Tasks';
import { ExpenseReportFields } from './ExpensesReportFields';
import { ExpenseReportSubmitForm, ItemList } from './helper';

interface ExpenseReportFormProps {
  formName?: string;
  t: TType;
  adoId: string;
}
const ExpenseReportFormComponent: FC<ExpenseReportFormProps> = ({ formName, t, adoId }) => {
  const [form] = useForm();
  const {
    data: expenseReportDetail,
    isLoading: isExpensesReportDetailLoading,
    isError: isExpenseReportDetailError,
  } = useExpenseReimbursement(adoId);

  const [totalAmount, setTotalAmount] = useState<number>();
  const [attachmentData, setAttachmentData] = useState<[UploadFile[]]>();
  const history = useHistory();

  const submitForm = useExpenseReimbursementSubmit(adoId);

  const onFieldsChange = useCallback(() => {
    const TotalAmount = form.getFieldValue(FROM_ITEM_LIST) as [];
    const itemValues: number[] = [];

    if (TotalAmount) {
      TotalAmount.map((item) => {
        return Object.entries(item).map(([name, value]) => {
          return name === ExpenseReportItem.amount ? itemValues.push(value as number) : null;
        });
      });
    }

    const totalSAR = itemValues.reduce((partialSum, a) => partialSum + a, 0);

    if (totalSAR > 0) {
      setTotalAmount(totalSAR);
    } else {
      setTotalAmount(undefined);
    }
  }, [form]);

  useEffect(() => {
    if (totalAmount) {
      form.setFieldsValue({ [ExpenseReportName.totalAmount]: totalAmount });
      form.validateFields([ExpenseReportName.totalAmount]);
    }
  }, [form, totalAmount]);

  useEffect(() => {
    if (expenseReportDetail) {
      form.setFieldsValue({
        [ExpenseReportName.departmentCostCenter]: expenseReportDetail.departmentCostCenter,
      });
    }
  }, [expenseReportDetail, form]);

  const onSubmitForm = useCallback(
    async (variables) => {
      const attachmentsForm: [UploadFile[]] = [[]];
      attachmentsForm.shift();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const FieldItems: ItemList[] = variables.ITEMS.map((field: any, index: number) => {
        attachmentsForm.push(field.attachment);

        return {
          itemLabel: `#${index + 1}`,
          expenseType: field.expenseType,
          amount: field.amount,
          currency: field.currency,
          description: field.description,
        };
      });

      const formVariables: ExpenseReportSubmitForm = {
        items: FieldItems,
        cashAdvanceOrCard: variables.cashAdvanceOrCard,
        totalAmount: variables.totalAmount,
        departmentCostCenter: variables.departmentCostCenter,
        currency: variables.currency,
      };
      await submitForm.mutate(formVariables);

      attachmentsForm.length && setAttachmentData(attachmentsForm);
    },
    [submitForm]
  );

  useHandleSubmitStatus(t, submitForm.isLoading, undefined, submitForm.isSuccess);

  useEffect(() => {
    submitForm.data &&
      submitAttachmentForm(true, submitForm.data, history, true, undefined, attachmentData);
  }, [attachmentData, history, submitForm.data]);

  return (
    <React.Fragment>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        validateMessages={VALIDATE_MESSAGES(t)}
        onFieldsChange={onFieldsChange}
        onFinish={onSubmitForm}
      >
        {isExpenseReportDetailError ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
        ) : isExpensesReportDetailLoading ? (
          <FullWidthSpin />
        ) : (
          <Spin spinning={submitForm.isLoading} delay={100}>
            <ExpenseReportFields form={form} t={t} fromDetails={expenseReportDetail} />
          </Spin>
        )}
      </Form>
    </React.Fragment>
  );
};

export const ExpenseReportForm = memo(ExpenseReportFormComponent);

import React from 'react';
import { Avatar } from 'antd';
import {
  StyledCreationDate,
  StyledCreatorInfoContainer,
  StyledCreatorName,
  StyledCreatorNameWrapper,
} from './AnnouncementCreatorInfo.styled';

export const AnnouncementCreatorInfo: React.FC = () => {
  return (
    <StyledCreatorInfoContainer>
      <Avatar size={40} />
      <StyledCreatorNameWrapper>
        <StyledCreatorName>John Dou</StyledCreatorName>
        <StyledCreationDate>Posted on 15/01/2022</StyledCreationDate>
      </StyledCreatorNameWrapper>
    </StyledCreatorInfoContainer>
  );
};

import React, { FC } from 'react';
import { StyledDivider } from 'ui/components/TaskList/TaskCard/TaskCard.styled';
import { CalendarUI, EventList } from 'ui/components';
import { Spin } from 'antd';
import { FilterOption } from 'ui/components/Filter/Filter.types';
import { CalendarHeader } from 'routes/Calendar/Components';
import { CalendarWrapper } from './CalendarView.styled';

interface Props {
  setCurrentLeaveId: (leaveId: string) => void;
  refetchList?: (selectedMonth: string) => void;
  eventList?: EventList[];
  isLoading?: boolean;
  isMyTeamLeaves?: boolean;
  options?: FilterOption[];
  getTeamLeaves?: (ids: string) => void;
}

export const CalendarView: FC<Props> = ({
  setCurrentLeaveId,
  refetchList,
  eventList,
  isLoading,
  isMyTeamLeaves,
  options,
  getTeamLeaves,
}) => {
  return (
    <CalendarWrapper>
      <CalendarHeader isMyTeamLeave={isMyTeamLeaves} />
      <StyledDivider />
      <Spin spinning={isLoading} delay={100}>
        <CalendarUI
          setCurrentLeaveId={setCurrentLeaveId}
          refetchList={refetchList}
          eventList={eventList}
          isMyTeamLeaves={isMyTeamLeaves}
          options={options}
          getTeamLeaves={getTeamLeaves}
        />
      </Spin>
    </CalendarWrapper>
  );
};

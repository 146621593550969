import React, { FC, useMemo } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';
import qs from 'query-string';
import { AppLayout } from 'layouts';
import { Header } from 'app';
import { TitleH1 } from 'ui';
import { TType, withTranslate } from 'translations';
import { Col, Row } from 'antd';
import { PagesUrls } from 'namespace';
import { StyledTab, TabPaneContent } from 'routes/Calendar';
import { ProcessGroups, Users } from './pages';

const { TabPane } = StyledTab;
interface Props {
  t: TType;
}

export const ProcessManagementComponent: FC<Props> = ({ t }) => {
  const history = useHistory();

  const search = useMemo(() => qs.parse(history.location.search) as { tab: string | undefined }, [
    history.location,
  ]);

  const ProcessManagementTab = () => (
    <AppLayout displayFooter={false}>
      <Header>
        <Row align="middle">
          <Col>
            <TitleH1>{t('menu.processManagement')}</TitleH1>
          </Col>
        </Row>
      </Header>
      <StyledTab activeKey={search.tab} className="layout-main">
        <TabPane tab={t('processManagement.tab.user')} key="1">
          <TabPaneContent>
            <Users t={t} />
          </TabPaneContent>
        </TabPane>
        <TabPane tab={t('processManagement.tab.process')} key="2">
          <TabPaneContent>processComponents</TabPaneContent>
        </TabPane>
        <TabPane tab={t('processManagement.tab.groups')} key="3">
          <TabPaneContent>
            <ProcessGroups t={t} />
          </TabPaneContent>
        </TabPane>
      </StyledTab>
    </AppLayout>
  );

  return (
    <Switch>
      <Route path={PagesUrls.ProcessManagement} component={ProcessManagementTab} />
    </Switch>
  );
};

export const ProcessManagement = withTranslate(ProcessManagementComponent);

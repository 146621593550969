import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { FormFieldId, TaskStatus } from 'types';

export interface requestInfoRedirectionType {
  PDK?: string;
  redirectionPage?: string;
  redirectionTab?: string;
  taskPrettyId?: string;
  requestStatus?: TaskStatus;
  processInstanceId?: string | null;
}

export interface FiledHiddenProp {
  isHidden: boolean | null;
  fieldId?: FormFieldId;
}

export type FilterByRequestType = Pick<
  requestInfoRedirectionType,
  'PDK' | 'redirectionPage' | 'redirectionTab'
>;

export type FilterByPrettyIdType = Pick<
  requestInfoRedirectionType,
  | 'redirectionPage'
  | 'redirectionTab'
  | 'taskPrettyId'
  | 'requestStatus'
  | 'processInstanceId'
  | 'PDK'
>;

export type DependenciesFieldsBetweenForms = {
  taskId?: string;
  fieldId?: FormFieldId;
  isThereARequiredFields?: boolean;
};

interface ServiceContextType {
  isFilterByRequest?: boolean;
  setIsFilterByRequest: Dispatch<SetStateAction<boolean>>;
  isFilterByPrettyId?: boolean;
  setIsFilterByPrettyId: Dispatch<SetStateAction<boolean>>;
  requestInfoRedirection?: FilterByRequestType;
  setRequestInfoRedirection: Dispatch<SetStateAction<FilterByRequestType>>;
  prettyIdInfoRedirection?: FilterByPrettyIdType;
  setPrettyIdInfoRedirection: Dispatch<SetStateAction<FilterByPrettyIdType>>;
  fieldIsHidden?: FiledHiddenProp;
  setFieldHidden: Dispatch<SetStateAction<FiledHiddenProp>>;
  dependenciesFieldsBetweenForms?: DependenciesFieldsBetweenForms;
  setDependenciesFieldsBetweenForms: Dispatch<SetStateAction<DependenciesFieldsBetweenForms>>;
}

export const ServicesContext = createContext({} as ServiceContextType);

export const useServicesContext = () => {
  const context = useContext<ServiceContextType>(ServicesContext);

  if (!context) {
    throw new Error('useServiceContext should be used within a ServicesContext');
  }
  return context;
};

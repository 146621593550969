import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import { Header } from 'app';
import { AppLayout } from 'layouts';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslate } from 'translations';
import { RestrictedUpload, TitleH1 } from 'ui';
import { selectFilterOption } from 'utils/getFilterOptions';
import {
  ArrowRightOutlined,
  UploadOutlined,
  SaveOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  balanceData,
  getBalanceStart,
  getLeavesTypesStart,
  leavesTypesData,
  postSubmitFormReset,
  postSubmitFormStart,
  profileData,
  submitFormData,
  useDispatch,
  useSelector,
  getPublicHolidaysStart,
  publicHolidaysSelector,
} from 'store';
import { task as taskApi, leaves as leavesApi, useLeavesByReason } from 'api';
import { PublicHolidaysData, RequestStatus } from 'types';
import { Forms } from 'ui/components/Requests/CreateRequestForm';
import { UploadFile } from 'antd/lib/upload/interface';
import { useForm } from 'antd/lib/form/Form';
import { VALIDATE_MESSAGES } from 'utils';
import {
  fieldsFormName,
  leaveFormName,
  unpaidOther,
  halfDayLeaveBalance,
  selectWidth,
  maxLength,
  DEFAULT_CALENDAR_ITEM_SPAN,
  GUTTER_12,
} from 'routes/Calendar';
import { GUTTER_16 } from 'routes/Tasks';
import {
  workday_count,
  enumerateDaysBetweenDates,
  checkBetweenTwoDates,
  isThereNoLimitBalance,
  convertToValues,
  handelSelectEndDate,
  directEndDate,
} from '../../helper';
import { LeaveAssignDelegateForm } from '../../../../ui/components/Leave';
import {
  StyledAbsenceBalanceCard,
  StyleDatePicker,
  StyledBackIcon,
  StyledBalanceNumber,
  StyledDivider,
  StyledErrorDiv,
  StyledH3Label,
  StyledH3Primary,
  StyledH4Label,
  StyledLabel,
  StyledLeaveTitle,
  StyledNoLimitText,
  StyledRemainingBalanceCard,
  StyledRequestDetailCard,
  StyledRow,
  StyledSaveButtonWrapper,
  StyledSaveCol,
  StyledSubmitButton,
  StyleNoLimitCol,
} from './NewRequest.styled';

const isProduction = process.env.REACT_APP_ENV === 'prod';

const { TextArea } = Input;

type Options = Option[];

interface Option {
  value: string;
  id: string | null;
  leavesBalance: number | null;
}

interface ReasonItem {
  value: string;
  key: number;
  id: number;
  label: string;
  duration: number;
}

const LeavesId = {
  annualLeaveId: 300000001409417,
  companionLeaveId: isProduction ? 300000127776255 : 300000125609806,
  compassionateLeaveId: isProduction ? 300000127776291 : 300000001409485,
  annualLeavePlanId: 300000001459561,
  eddehLeaveId: 300000001399698,
  maternityLeaveId: 300000001399936,
  hajjLeaveId: 300000001399766,
  marriageLeaveId: 300000007087203,
  halfDayLeaveId: 300000009258391,
  paternityLeaveId: 300000001433958,
  unpaidLeaveId: 300000001399868,
  extensionMaternityLeaveId: 300000009178441,
  sickLeaveId: 300000001474494,
};

interface AttachmentFile {
  [key: string]: UploadFile[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const NewRequest: FC = () => {
  const { t } = useTranslate();

  const history = useHistory();

  const dispatch = useDispatch();

  const leavesTypes = useSelector(leavesTypesData);

  const leavesRemainingBalance = useSelector(balanceData);

  const { data: ProfileDate } = useSelector(profileData);

  const submitForm = useSelector(submitFormData);

  const publicHolidays = useSelector(publicHolidaysSelector);

  const [absenceDuration, setAbsenceDuration] = useState<number>(0);

  const [remainingLeaveBalance, setRemainingLeaveBalance] = useState<number>();

  const [remainingBalance, setRemainingBalance] = useState<number>();

  const [currentLeaveBalance, setCurrentLeaveBalance] = useState<number>();

  const [absenceTypeBalance, setAbsenceTypeBalance] = useState<string>('');

  const [options, setOptions] = useState<Options>([]);

  const [selectedLeaveId, setSelectedLeaveId] = useState<number>();

  const [selectedLeavePlanId, setSelectedLeavePlanId] = useState<string>('');

  const [leaveRemainingBalance, setLeaveRemainingBalance] = useState<number>();

  const [isSelectedLeaveCalenderDays, setIsSelectedLeaveCalenderDays] = useState<boolean>(false);

  const [isMoreThenBalance, setIsMoreThenBalance] = useState<boolean>(false);

  const hcmPersonId = ProfileDate?.hcmId as string;

  const [startDate, setStartDate] = useState<moment.Moment>();

  const [finishDate, setFinishDate] = useState<moment.Moment>();

  const [retrieveLeaveBalance, setRetrieveLeaveBalance] = useState<boolean>();

  const [allTasksFormInstances, setAllTasksFormInstances] = useState<Forms | null>(null);

  const [formLoading, setFormLoading] = useState<boolean>(false);

  const [isCompassionateLeave, setIsCompassionateLeave] = useState<boolean>(false);

  const [isUnpaidLeave, seIsUnpaidLeave] = useState<boolean>(false);

  const [isSelectedLeave, setIsSelectedLeave] = useState<boolean>(false);

  const [form] = useForm();

  const [publicHoliday, setPublicHolidays] = useState<PublicHolidaysData>();

  const [holidaysDates, setHolidaysDates] = useState<Date[]>([]);

  const [isThereBalance, setIsThereBalance] = useState<boolean>(false);

  const [isAnnualBalanceMoreThen20, setIsAnnualBalanceMoreThen20] = useState<boolean>(false);

  const [isDirectLeave, setIsDirectLeave] = useState<boolean>(false);

  const [isHalfDayLeave, setIsHalfDayLeave] = useState<boolean>(false);

  const [isSubmitRequest, setIsSubmitRequest] = useState<boolean>(false);
  const [isSaveRequest, setIsSaveRequest] = useState<boolean>(false);
  const [attachmentsData, setAttachmentsData] = useState<AttachmentFile[]>([]);
  const [reasons, setReasons] = useState<ReasonItem[]>([]);
  const [isReasonSelected, setIsReasonSelected] = useState<boolean>(false);
  const [isUnpaidotherReason, setIsUnpaidOtherReason] = useState<boolean>(false);
  const [annualLeaveBalance, setAnnualLeaveBalance] = useState<number>();
  const leavesByReason = useLeavesByReason(hcmPersonId);

  const remainingOfLeaveBalance = useCallback(
    (leaveBalance: number, diff: number) => {
      const counter = leaveBalance - diff;
      const roundingTheRemaining = Number(counter.toPrecision(4));
      if (counter < 0) {
        setIsMoreThenBalance(true);
      }
      setRemainingBalance(roundingTheRemaining);
    },
    [setRemainingBalance]
  );

  useEffect(() => {
    dispatch(postSubmitFormReset());
    dispatch(getPublicHolidaysStart());
  }, [dispatch]);

  useEffect(() => {
    if (publicHolidays.status === RequestStatus.resolved && publicHolidays.data) {
      setPublicHolidays(publicHolidays.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicHolidays.status, publicHolidays.data]);

  useEffect(() => {
    if (leavesRemainingBalance.status === RequestStatus.resolved && leavesRemainingBalance.data) {
      const selectedLeave = leavesRemainingBalance.data.find(
        (leave) => leave.planId === LeavesId.annualLeavePlanId.toString()
      );
      if (selectedLeave && selectedLeave) {
        const leaveBalance = selectedLeave.balanceAsOfBalanceCalculationDate;
        setAnnualLeaveBalance(+leaveBalance);
      }
    }
  }, [leavesRemainingBalance.status, leavesRemainingBalance.data]);

  useEffect(() => {
    if (hcmPersonId) {
      dispatch(getLeavesTypesStart({ hcmPersonId }));
    }
  }, [dispatch, hcmPersonId]);

  useEffect(() => {
    if (submitForm.status === RequestStatus.resolved && submitForm.data) {
      notification.success({
        message: t('messages.success'),
        description: t('messages.success.requestCreated'),
      });

      if (attachmentsData.length > 0 && submitForm.data.taskId) {
        const taskId = submitForm.data.taskId;
        const processInstanceId = submitForm.data.processInstanceId;
        const processDefinitionId = submitForm.data.processDefinitionId;
        const activityId = submitForm.data.activityId;

        attachmentsData.map((attachments: AttachmentFile) => {
          return Object.entries(attachments).map(([attachmentName, attachmentFile]) => {
            return attachmentFile.map(async (attachmentInfo) => {
              const formData = new FormData();
              formData.append('content', attachmentInfo.originFileObj as Blob);
              formData.append('attachmentName', attachmentInfo.name);
              formData.append('filedId', attachmentName);
              formData.append('attachmentDescription', attachmentInfo.name);
              formData.append('attachmentType', attachmentInfo.type);

              return await taskApi.postTaskAttachment(
                taskId,
                processInstanceId,
                activityId,
                processDefinitionId,
                formData
              );
            });
          });
        });
        setFormLoading(false);
        history.goBack();
      } else {
        setFormLoading(false);
        history.goBack();
      }
    } else if (submitForm.status === RequestStatus.rejected) {
      setFormLoading(false);
      notification.error({
        message: t('leaves.newRequest.message.leaveRequestError'),
        description: submitForm?.error?.error
          ? t('messages.error.wentWrong')
          : submitForm.error?.errors[0]?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentsData, submitForm.status, history, submitForm.data, t]);

  const submitAllTasksForms = useCallback(async () => {
    if (isSaveRequest === true) {
      setIsSaveRequest(false);
    }
    if (allTasksFormInstances) {
      setIsSubmitRequest(true);
      for (const formName in allTasksFormInstances) {
        allTasksFormInstances[formName].submit();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTasksFormInstances, isSaveRequest]);

  const saveAllTasksForms = useCallback(async () => {
    if (isSubmitRequest === true) {
      setIsSubmitRequest(false);
    }
    if (allTasksFormInstances) {
      setIsSaveRequest(true);
      for (const formName in allTasksFormInstances) {
        allTasksFormInstances[formName].submit();
      }
    }
  }, [allTasksFormInstances, isSubmitRequest]);

  const hasTouchedTasksForms =
    !!allTasksFormInstances && !!Object.keys(allTasksFormInstances).length;

  const getBalance = useCallback(
    (currentLeaveId) => leavesApi.getLeaveRemaining(hcmPersonId, currentLeaveId),

    [hcmPersonId]
  );
  const onSelectLeaveType = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: string, option: any) => {
      if (option.id !== LeavesId.companionLeaveId) {
        setIsAnnualBalanceMoreThen20(false);
      }
      setSelectedLeavePlanId(option.planId);
      setSelectedLeaveId(option.id);
      setIsSelectedLeaveCalenderDays(option.calenderDays);
      setIsCompassionateLeave(option.id === LeavesId.compassionateLeaveId);
      seIsUnpaidLeave(option.id === LeavesId.unpaidLeaveId);
      setIsSelectedLeave(true);
      setIsReasonSelected(false);
      form.resetFields([fieldsFormName.reason]);
    },
    [form]
  );

  useEffect(() => {
    if (leavesByReason.data) {
      const filteredReasons = leavesByReason.data.filter(
        (leave) =>
          leave.absenceTypeId ===
          (isUnpaidLeave ? LeavesId.unpaidLeaveId : LeavesId.compassionateLeaveId)
      );
      const leaveReasons = filteredReasons.map((leave) => ({
        label: leave.absenceName,
        key: leave.id,
        value: leave.absenceName.replace(/ /g, '_').toUpperCase(),
        id: leave.id,
        duration: leave?.duration,
      }));
      setReasons(leaveReasons);
    }
  }, [leavesByReason.data, isUnpaidLeave, isCompassionateLeave]);

  const onSelectReason = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: string, option: any) => {
      setIsReasonSelected(true);
      setAbsenceDuration(0);
      setStartDate(undefined);
      setFinishDate(undefined);
      setIsDirectLeave(false);
      setIsHalfDayLeave(false);
      setIsUnpaidOtherReason(value === unpaidOther);
      if (option.duration) {
        leaveBalanceChecker(option.duration);
        setRemainingLeaveBalance(option.duration);
        setRemainingBalance(option.duration);
        setCurrentLeaveBalance(option.duration);
        setLeaveRemainingBalance(option.duration);
        setAbsenceTypeBalance(option.duration.toString());
        setIsDirectLeave(true);
      }
      form.resetFields([
        fieldsFormName.unpaidOther,
        fieldsFormName.startDate,
        fieldsFormName.finishDate,
        fieldsFormName.moreDetails,
        fieldsFormName.attachment,
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  useEffect(() => {
    (async () => {
      if (selectedLeaveId) {
        try {
          setRetrieveLeaveBalance(true);
          if (!isCompassionateLeave) {
            const balanceResponse = await getBalance(selectedLeaveId);
            const leaveBalance = balanceResponse.data.remaining_balance;
            leaveBalanceChecker(Number(leaveBalance));
            setRemainingLeaveBalance(Number(leaveBalance));
            setRemainingBalance(Number(leaveBalance));
            setCurrentLeaveBalance(Number(leaveBalance));
            setLeaveRemainingBalance(Number(leaveBalance));
            setAbsenceTypeBalance(leaveBalance);
          }
        } catch (e) {
          notification.error({
            message: t('messages.error.wentWrong'),
            description: t('messages.error.tryAgainLater'),
          });
        } finally {
          setRetrieveLeaveBalance(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLeaveId, isCompassionateLeave, getBalance, t]);

  const goBack = useCallback(() => {
    setIsSelectedLeave(false);
    history.goBack();
  }, [history, setIsSelectedLeave]);

  useEffect(() => {
    if (leavesTypes.status === RequestStatus.resolved && leavesTypes.data) {
      const options = leavesTypes.data.map((leave) => ({
        label: leave.absenceName,
        key: leave.id,
        value: leave.absenceName.replace(/ /g, '_').toUpperCase(),
        id: leave.id,
        leavesBalance: leave.absenceDefaultBalance,
        planId: leave.planId,
        limited: leave.limited,
        duration: leave?.duration,
        calenderDays: leave.includeWeekendHolidays,
        title: leave.absenceName,
      }));
      setOptions(options);
    }
  }, [leavesTypes.data, leavesTypes.status]);

  useEffect(() => {
    if (
      leavesRemainingBalance.status === RequestStatus.resolved &&
      leavesRemainingBalance.data &&
      selectedLeavePlanId
    ) {
      const leavesTypeBalance = leavesRemainingBalance.data;
      const selectedLeave = leavesTypeBalance.find(
        (leave) => leave.planId === selectedLeavePlanId.toString()
      );
      if (selectedLeave) {
        const leaveBalance: number = selectedLeave.balanceAsOfBalanceCalculationDate;

        setLeaveRemainingBalance(leaveBalance);

        setRemainingLeaveBalance(leaveBalance);

        setRemainingBalance(leaveBalance);
      }
    } else if (leavesRemainingBalance.status === RequestStatus.idle && hcmPersonId) {
      dispatch(getBalanceStart(hcmPersonId));
    }
  }, [
    leavesRemainingBalance.data,
    leavesRemainingBalance.status,
    selectedLeavePlanId,
    dispatch,
    hcmPersonId,
  ]);

  const disableStartDate = useCallback(
    (current: moment.Moment) => {
      const weekend = current.isoWeekday();
      const isWeekend: boolean = weekend === 5 || weekend === 6;

      const isHoliday = holidaysDates.some((holiday) => {
        const currentDay = current.toDate();
        const newHoliday = new Date(holiday);
        return checkBetweenTwoDates(currentDay, newHoliday);
      });

      return isHoliday || isWeekend;
    },
    [holidaysDates]
  );

  useEffect(() => {
    const updateData: Date[] = [];

    publicHoliday?.forEach((holiday) => {
      const eventStartDate = holiday.startDate;
      const eventEndDate = holiday.endDate;

      const holidayDates = enumerateDaysBetweenDates(eventStartDate, eventEndDate);

      holidayDates.forEach((item) => {
        updateData.push(item);
      });
    });

    setHolidaysDates(updateData);
  }, [publicHoliday]);

  const disableEndDate = (current: moment.Moment) => {
    const weekend = current.isoWeekday();

    const isHoliday = holidaysDates.some((holiday) => {
      const currentDay = current.toDate();
      const newHoliday = new Date(holiday);

      return checkBetweenTwoDates(currentDay, newHoliday);
    });

    const isWeekend: boolean = weekend === 5 || weekend === 6;

    const isBeforeCurrentDay: boolean = current.isBefore(startDate);

    return isWeekend || isBeforeCurrentDay || isHoliday;
  };

  useEffect(() => {
    if (startDate && finishDate) {
      let difference = 0;

      !isSelectedLeaveCalenderDays
        ? (difference = workday_count(
            startDate.startOf('day'),
            finishDate.startOf('day'),
            holidaysDates
          ))
        : (difference = finishDate.startOf('day').diff(startDate.startOf('day'), 'days') + 1);

      if (isDirectLeave && remainingLeaveBalance) {
        if (isHalfDayLeave) {
          const directEndDate = handelSelectEndDate(difference, halfDayLeaveBalance, finishDate);

          setFinishDate(directEndDate);
        } else {
          const directEndDate = handelSelectEndDate(difference, remainingLeaveBalance, finishDate);
          setFinishDate(directEndDate);
        }
      }
      if (selectedLeaveId === LeavesId.halfDayLeaveId && remainingLeaveBalance) {
        let halfDay = 0;
        if (difference > 1) {
          halfDay = difference;
        } else {
          halfDay = difference / 2;
        }
        setAbsenceDuration(halfDay);
        remainingOfLeaveBalance(remainingLeaveBalance, halfDay);
      } else {
        setAbsenceDuration(difference);
      }
      if (remainingLeaveBalance && !isHalfDayLeave) {
        setRemainingBalance(remainingLeaveBalance);
        remainingOfLeaveBalance(remainingLeaveBalance, difference);
      }
    }
  }, [
    startDate,
    finishDate,
    remainingBalance,
    isSelectedLeaveCalenderDays,
    remainingLeaveBalance,
    remainingOfLeaveBalance,
    selectedLeaveId,
    holidaysDates,
    isDirectLeave,
    isHalfDayLeave,
  ]);

  const onChange = useCallback(
    (value) => {
      setFinishDate(value);
      setIsMoreThenBalance(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFinishDate, setIsMoreThenBalance]
  );

  const onChangeStartDate = useCallback(
    (value) => {
      setStartDate(value);
      if (finishDate) {
        form.resetFields([fieldsFormName.finishDate]);
        setFinishDate(undefined);
        setRemainingBalance(currentLeaveBalance);
        setAbsenceDuration(0);
        setIsMoreThenBalance(false);
      }
      if (isDirectLeave && remainingLeaveBalance) {
        if (isHalfDayLeave) {
          const newDirectEndDate = directEndDate(value, halfDayLeaveBalance);
          setFinishDate(newDirectEndDate);
        } else {
          const newDirectEndDate = directEndDate(value, remainingLeaveBalance);

          setFinishDate(newDirectEndDate);
        }
      }
    },
    [
      setStartDate,
      form,
      finishDate,
      currentLeaveBalance,
      isDirectLeave,
      isHalfDayLeave,
      remainingLeaveBalance,
    ]
  );
  const leaveBalanceChecker = useCallback(
    (leaveBalance) => {
      if ((isHalfDayLeave && leaveBalance <= 0) || (!isHalfDayLeave && leaveBalance < 1)) {
        setIsThereBalance(true);
        notification.error({
          message: t('leaves.newRequest.message.leaveRequestError'),
          description: t('leaves.newRequest.error.noBalance'),
        });
      } else {
        setIsThereBalance(false);
      }
    },
    [isHalfDayLeave, t]
  );

  useEffect(() => {
    if (selectedLeaveId && !isCompassionateLeave) {
      setRemainingBalance(remainingLeaveBalance);
      setAbsenceDuration(0);
      setIsMoreThenBalance(false);
      setStartDate(undefined);
      setFinishDate(undefined);
      setIsAnnualBalanceMoreThen20(false);
      setIsDirectLeave(false);
      setIsHalfDayLeave(false);
      setIsThereBalance(false);
      setIsUnpaidOtherReason(false);
      form.resetFields([
        fieldsFormName.startDate,
        fieldsFormName.finishDate,
        fieldsFormName.reason,
        fieldsFormName.unpaidOther,
        fieldsFormName.moreDetails,
        fieldsFormName.attachment,
      ]);
    }
  }, [selectedLeaveId, isCompassionateLeave, remainingLeaveBalance, form]);

  useEffect(() => {
    if (selectedLeaveId) {
      if (
        selectedLeaveId === LeavesId.companionLeaveId &&
        annualLeaveBalance &&
        annualLeaveBalance > 20
      ) {
        setIsAnnualBalanceMoreThen20(true);
      }
      if (
        selectedLeaveId === LeavesId.eddehLeaveId ||
        selectedLeaveId === LeavesId.hajjLeaveId ||
        selectedLeaveId === LeavesId.marriageLeaveId ||
        isHalfDayLeave ||
        selectedLeaveId === LeavesId.paternityLeaveId ||
        selectedLeaveId === LeavesId.maternityLeaveId ||
        selectedLeaveId === LeavesId.extensionMaternityLeaveId
      ) {
        setIsDirectLeave(true);
        setIsMoreThenBalance(false);
      }

      if (selectedLeaveId === LeavesId.halfDayLeaveId) {
        setIsHalfDayLeave(true);
      }
    }
  }, [
    selectedLeaveId,
    remainingBalance,
    finishDate,
    leaveRemainingBalance,
    isHalfDayLeave,
    annualLeaveBalance,
  ]);

  useEffect(() => {
    if (
      isHalfDayLeave &&
      remainingLeaveBalance &&
      leaveRemainingBalance &&
      remainingLeaveBalance > leaveRemainingBalance
    ) {
      setRemainingLeaveBalance(leaveRemainingBalance);
    }
  }, [isHalfDayLeave, leaveRemainingBalance, remainingLeaveBalance]);

  return (
    <AppLayout displayFooter={false}>
      <Header>
        <Space size="large" />
        <Row align="middle">
          <Col>
            <StyledBackIcon onClick={goBack} />
          </Col>
          <Col>
            <TitleH1>{t('leaves.newLeaveRequest.title')}</TitleH1>
          </Col>
        </Row>
      </Header>
      <StyledRow className="layout-main">
        <Col>
          <StyledLeaveTitle>{t('leaves.newRequest.title')}</StyledLeaveTitle>
        </Col>
      </StyledRow>

      <Divider style={{ margin: 0 }} />

      <StyledRequestDetailCard>
        <Form.Provider
          onFormChange={(value, changes) => {
            const tasksForms = Object.fromEntries(
              Object.entries(changes.forms).filter(([formName]) => formName === leaveFormName)
            );

            if (allTasksFormInstances === null) {
              setAllTasksFormInstances(tasksForms as Forms);
              return;
            }

            const onlyNewForms = Object.entries(tasksForms).filter(
              ([formName]) => !allTasksFormInstances[formName]
            );

            if (onlyNewForms.length) {
              setAllTasksFormInstances({
                ...allTasksFormInstances,
                ...(Object.fromEntries(onlyNewForms) as Forms),
              });
            }
            const startDateFieldValue = form.getFieldValue('startDate');

            startDateFieldValue
              ? form.setFieldsValue({ finishDate: finishDate })
              : form.setFieldsValue({ finishDate: undefined });
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onFormFinish={async (FormName: string, info: { values: any; forms: any }) => {
            if (FormName === leaveFormName) {
              const attachmentFieldId = 'attachment';
              const attachmentsForm: AttachmentFile[] = [];
              const attachments = info.values[attachmentFieldId];
              delete info.values['attachment'];
              setFormLoading(true);

              const isDelegateVariableIsAvailable = !!info.values['delegateId'];
              let delegateVariable = {};
              if (isDelegateVariableIsAvailable) {
                delegateVariable = Object.assign({
                  createDelegate: { delegateAdOid: info.values['delegateId'] },
                });
                delete info.values['delegateId'];
                delete info.values['delegationStartDate'];
                delete info.values['delegationEndDate'];
              }
              // eslint-disable-next-line no-console
              //console.log('CHECK_VER_INFO', JSON.stringify(info, null, 4));

              let formVariables = convertToValues(info.values);
              const { finishDate: deletedFinishedDate, ...rest } = formVariables;
              formVariables = rest;
              const balanceVariables = {
                leaveBalance: remainingLeaveBalance,
                absenceDuration: absenceDuration,
                remainingBalance: typeof remainingBalance === 'number' ? remainingBalance : null,
                submit: isSubmitRequest,
                save: isSaveRequest,
                leaveTypeId: selectedLeaveId,
                endDate: finishDate?.format('DD/MM/YYYY'),
              };

              const formValues = Object.assign(formVariables, balanceVariables, delegateVariable);

              // eslint-disable-next-line no-console
              //console.log('CHECK_FORM_VALUES', JSON.stringify(formValues, null, 4));

              if (hcmPersonId && formValues) {
                dispatch(
                  postSubmitFormStart({
                    hcmPersonId: hcmPersonId,
                    data: formValues,
                  })
                );
                if (attachments) {
                  attachmentsForm.push({ [attachmentFieldId]: attachments });
                }
              }
              if (attachmentsForm) {
                setAttachmentsData(attachmentsForm);
              }
            }
          }}
        >
          <Form
            name={leaveFormName}
            form={form}
            layout={'vertical'}
            validateMessages={VALIDATE_MESSAGES(t)}
          >
            <Row>
              <Col offset={6}>
                <TitleH1>{t('leaves.newRequest.detail')}</TitleH1>
              </Col>
            </Row>

            <Row>
              <Col offset={6} span={12}>
                <Row justify="start" align="middle" gutter={[16, 30]}>
                  <Col span={24} style={{ display: 'flex' }} />
                  <Col span={24}>
                    <Row gutter={[12, 12]}>
                      <Col span={24}>
                        <Form.Item
                          label={<StyledLabel>{t('leaves.newRequest.leaveType')}</StyledLabel>}
                          rules={[
                            {
                              required: true,
                              message: t('leaves.newRequest.validation.selectLeave'),
                            },
                          ]}
                          name={'leaveType'}
                          id={'leaveType'}
                        >
                          <Select
                            style={{ width: selectWidth }}
                            placeholder={t('leaves.newRequest.placeholder.selectLeave')}
                            optionFilterProp="children"
                            options={options}
                            onSelect={onSelectLeaveType}
                            notFoundContent={t('dropdown.noMatching')}
                            showSearch
                            filterOption={selectFilterOption}
                          />
                        </Form.Item>
                      </Col>

                      {isAnnualBalanceMoreThen20 ? (
                        <Col span={24}>
                          <StyledErrorDiv>
                            {t('leaves.newRequest.error.annualBalanceMoreThen20')}
                          </StyledErrorDiv>
                        </Col>
                      ) : null}
                    </Row>
                  </Col>

                  <>
                    {(isCompassionateLeave || isUnpaidLeave) && selectedLeaveId ? (
                      <Row gutter={GUTTER_12}>
                        <Col span={DEFAULT_CALENDAR_ITEM_SPAN}>
                          <Col span={24}>
                            <Form.Item
                              label={<StyledLabel>{t('leaves.newRequest.reason')}</StyledLabel>}
                              name="reason"
                              rules={[
                                {
                                  required: true,
                                  message: t('leaves.newRequest.validation.reason'),
                                },
                              ]}
                            >
                              <Select
                                style={{ width: selectWidth }}
                                placeholder={t('leaves.newRequest.reason.placeholder')}
                                optionFilterProp="children"
                                options={reasons}
                                onSelect={onSelectReason}
                                notFoundContent={t('dropdown.noMatching')}
                                showSearch
                                filterOption={selectFilterOption}
                              />
                            </Form.Item>
                          </Col>
                        </Col>
                      </Row>
                    ) : null}

                    {isUnpaidotherReason ? (
                      <Col span={24}>
                        <Form.Item
                          name="unpaidOther"
                          rules={[
                            {
                              required: true,
                              message: t('leaves.newRequest.validation.reason.other'),
                            },
                          ]}
                        >
                          <TextArea
                            showCount
                            maxLength={maxLength}
                            disabled={isThereBalance}
                          ></TextArea>
                        </Form.Item>
                      </Col>
                    ) : null}

                    {(selectedLeaveId && !isCompassionateLeave && !isUnpaidLeave) ||
                    ((isCompassionateLeave || isUnpaidLeave) && isReasonSelected) ? (
                      <>
                        <Col span={24}>
                          <Row gutter={[12, 12]}>
                            <Col span={24}>
                              <Row justify={'space-between'} gutter={GUTTER_16}>
                                <Col span={12}>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: t('leaves.newRequest.validation.selectStartDate'),
                                      },
                                    ]}
                                    name="startDate"
                                    wrapperCol={{ span: 24 }}
                                    label={
                                      <>
                                        <StyledLabel>
                                          {t('leaves.newRequest.startDate')}
                                        </StyledLabel>
                                        <Tooltip
                                          placement="top"
                                          title={t('leaves.newRequest.tooltip')}
                                          className="tooltip"
                                          overlayStyle={{ fontSize: 10 }}
                                        >
                                          <div>
                                            <InfoCircleOutlined
                                              style={{
                                                color: '#949CAD',
                                                marginLeft: 6,
                                              }}
                                            />
                                          </div>
                                        </Tooltip>
                                      </>
                                    }
                                  >
                                    <StyleDatePicker
                                      onChange={(date) => {
                                        onChangeStartDate(date);
                                      }}
                                      disabledDate={disableStartDate}
                                      disabled={isThereBalance || retrieveLeaveBalance}
                                      value={startDate}
                                      placeholder={t('leaves.newRequest.startDate.placeholder')}
                                      format="DD/MM/YYYY"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col span={12}>
                                  <Form.Item
                                    label={
                                      <StyledLabel>{t('leaves.newRequest.endDate')}</StyledLabel>
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: t('leaves.newRequest.validation.selectEndDate'),
                                      },
                                    ]}
                                    name="finishDate"
                                  >
                                    {
                                      /** ToDo: work around to force update the ui, module should be refactored */
                                      finishDate ? '' : ''
                                    }

                                    <StyleDatePicker
                                      onChange={(date) => {
                                        onChange(date);
                                      }}
                                      value={finishDate}
                                      disabledDate={disableEndDate}
                                      placeholder={t('leaves.newRequest.endDate.placeholder')}
                                      disabled={!startDate || retrieveLeaveBalance || isDirectLeave}
                                      $isThereError={isMoreThenBalance}
                                      format="DD/MM/YYYY"
                                    />
                                  </Form.Item>

                                  {isMoreThenBalance ? (
                                    <Col span={24}>
                                      <StyledErrorDiv>
                                        {t('leaves.newRequest.error.exceedsRemainingBalance')}
                                      </StyledErrorDiv>
                                    </Col>
                                  ) : null}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>

                        {isThereBalance ? null : (
                          <Col span={24}>
                            <StyledRemainingBalanceCard>
                              <Row justify="space-between">
                                <Col>
                                  <StyledLabel>
                                    {t('leaves.newRequest.absenceDuration')}
                                    <StyledH4Label strong>{absenceDuration}</StyledH4Label>
                                    {t('leaves.newRequest.absenceDuration.days')}
                                  </StyledLabel>
                                </Col>
                                {!remainingLeaveBalance ? null : (
                                  <Col>
                                    <StyledLabel $isThereError={isMoreThenBalance}>
                                      {t('leaves.newRequest.remainingBalance')}
                                      <StyledH4Label strong $isThereError={isMoreThenBalance}>
                                        {retrieveLeaveBalance ? (
                                          <Spin size={'small'} />
                                        ) : (
                                          remainingBalance
                                        )}
                                      </StyledH4Label>
                                      {t('leaves.newRequest.absenceDuration.days')}
                                    </StyledLabel>
                                  </Col>
                                )}
                              </Row>
                            </StyledRemainingBalanceCard>
                          </Col>
                        )}

                        <Col span={24}>
                          <StyledDivider />
                        </Col>
                        <Col span={24}>
                          <StyledH3Label>{t('leaves.newRequest.moreDetails')}</StyledH3Label>
                        </Col>

                        <Col span={24}>
                          <Form.Item name="moreDetails">
                            <TextArea
                              showCount
                              maxLength={maxLength}
                              disabled={isThereBalance}
                            ></TextArea>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <LeaveAssignDelegateForm
                            form={form}
                            startDate={startDate}
                            endDate={finishDate}
                          />
                        </Col>
                        <Col span={24}>
                          <StyledDivider />
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            label={
                              <Col span={24}>
                                <StyledH3Label>
                                  {t('leaves.newRequest.attachedFiles')}
                                </StyledH3Label>
                              </Col>
                            }
                            name="attachment"
                            rules={
                              selectedLeaveId === LeavesId.sickLeaveId
                                ? [
                                    {
                                      required: true,
                                      message: t(
                                        'leaves.newRequest.validation.sickLeaveAttachments'
                                      ),
                                    },
                                  ]
                                : undefined
                            }
                            getValueFromEvent={normFile}
                          >
                            <RestrictedUpload
                              listType="text"
                              multiple={true}
                              form={form}
                              fieldId="attachment"
                            >
                              <Button icon={<UploadOutlined />}>
                                {t('oneDrive.myFiles.uploadFile')}
                              </Button>
                            </RestrictedUpload>
                          </Form.Item>
                        </Col>
                      </>
                    ) : null}
                  </>
                </Row>
              </Col>
              {(selectedLeaveId && !isCompassionateLeave && !isUnpaidLeave) ||
              ((isCompassionateLeave || isUnpaidLeave) && isReasonSelected) ? (
                <Col>
                  <StyledAbsenceBalanceCard>
                    <Row justify="start" align="middle" style={{ display: 'block' }}>
                      <Col>
                        <StyledH3Primary>
                          {t('leaves.newRequest.absenceTypeBalance')}
                        </StyledH3Primary>
                      </Col>
                      {isThereNoLimitBalance(absenceTypeBalance) ? (
                        <StyleNoLimitCol>
                          <StyledNoLimitText>
                            {retrieveLeaveBalance ? <Spin size={'small'} /> : absenceTypeBalance}
                          </StyledNoLimitText>
                        </StyleNoLimitCol>
                      ) : (
                        <Col>
                          <StyledBalanceNumber strong>
                            {retrieveLeaveBalance ? <Spin size={'small'} /> : absenceTypeBalance}
                          </StyledBalanceNumber>
                          <StyledH3Primary>
                            {t('leaves.newRequest.absenceDuration.days')}
                          </StyledH3Primary>
                        </Col>
                      )}
                    </Row>
                  </StyledAbsenceBalanceCard>
                </Col>
              ) : null}
            </Row>
            <StyledDivider />

            <Row gutter={[8, 0]} justify={'center'}>
              <Col>
                <Button
                  onClick={goBack}
                  disabled={
                    !isSelectedLeave ||
                    isMoreThenBalance ||
                    !hasTouchedTasksForms ||
                    isThereBalance ||
                    isAnnualBalanceMoreThen20 ||
                    retrieveLeaveBalance
                  }
                  loading={formLoading && (isSubmitRequest || isSaveRequest)}
                >
                  {t('back')}
                </Button>
              </Col>
              <Col>
                <StyledSubmitButton
                  type="primary"
                  disabled={
                    !isSelectedLeave ||
                    isMoreThenBalance ||
                    !hasTouchedTasksForms ||
                    isThereBalance ||
                    isAnnualBalanceMoreThen20 ||
                    retrieveLeaveBalance
                  }
                  loading={formLoading && (isSubmitRequest || isSaveRequest)}
                  onClick={submitAllTasksForms}
                >
                  {t('submit')}
                  <ArrowRightOutlined />
                </StyledSubmitButton>
              </Col>

              <StyledSaveCol>
                <StyledSaveButtonWrapper>
                  <Button
                    onClick={saveAllTasksForms}
                    disabled={
                      !isSelectedLeave ||
                      isMoreThenBalance ||
                      !hasTouchedTasksForms ||
                      isThereBalance ||
                      isAnnualBalanceMoreThen20 ||
                      retrieveLeaveBalance
                    }
                    loading={formLoading && (isSubmitRequest || isSaveRequest)}
                  >
                    <SaveOutlined />
                    {t('leaves.newRequest.button.save')}
                  </Button>
                </StyledSaveButtonWrapper>
              </StyledSaveCol>
            </Row>
          </Form>
        </Form.Provider>
      </StyledRequestDetailCard>
    </AppLayout>
  );
};

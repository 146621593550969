import { Typography } from 'antd';
import styled from 'styled-components';
import { colors } from 'ui/elements';

const { Text } = Typography;

export const FormItemsTitle = styled(Text).attrs({ type: 'secondary' })`
  font-size: 18px;
  line-height: 1.2;
`;

export const FormItemsMainTitle = styled(Text)`
  font-size: 20px;
  line-height: 1.2;
  color: #606f8a;
`;

export const CandidateFormGoogleMapWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 0;
  border: none;
  border-radius: 4px;

  &.with-out-zone-error {
    padding: 5px;
    border: 1px solid ${colors.red};
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.textColorSecondary};
    margin-bottom: 0 !important;
  }
`;

export const CandidateFormGoogleMapContainer = styled.div`
  width: 100%;
  height: 400px;
  margin-top: 10px;
  border-radius: 4px;
`;

export const CandidateFormGoogleMapOutZoneError = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.red} !important;
  margin-top: 10px;
  margin-bottom: 0 !important;
`;

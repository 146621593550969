import { combineEpics } from 'redux-observable';

import * as profileEpic from './profile/profileEpic';
import * as hierarchyEpic from './hierarchy/hierarchyEpics';
import * as subordinatesEpic from './subordinates/subordinatesEpics';
import * as userAvatarEpic from './userAvatar/userAvatarEpics';
import * as generalInfoEpic from './generalInfo/generalInfoEpics';
import * as teamsEpic from './teams/teamsEpic';
import * as teamsUserAvatarEpic from './teamsUserAvatar/teamsUserAvatarEpic';
import * as teamsChannelsEpic from './teamsChannels/teamsChannelsEpic';
import * as chatsEpic from './chats/chatsEpic';
import * as userInfoEpic from './userInfo/userInfoEpics';
import * as teamsChannelMembersEpic from './teamsChannelMembers/teamsChannelMembersEpic';
import * as teamsPostsEpic from './teamsPosts/teamsPostsEpic';
import * as teamsChannelFilesEpice from './teamsFilesList/teamsChannelFilesListEpic';
import * as teamsChannelFolderEpic from './teamsChannelFolder/teamsChannelFolderListEpic';
import * as usersEpic from './users/usersEpics';
import * as oneDriveShared from './oneDriveShared/oneDriveSharedEpics';
import * as oneDriveSharedLibrariesEpic from './oneDriveSharedLibraries/oneDriveSharedLibrariesEpic';
import * as oneDriveFolderSharedEpic from './oneDriveFolderShared/oneDriveFolderSharedEpics';
import * as oneDriveSharedLibrarieFoldersListEpic from './oneDriveSharedLibraryListFolder/oneDriveSharedLibraryFolderListEpics';
import * as processDefinitionKeysEpic from './processDefinitionKeys/processDefinitionKeysEpics';
import * as processDefinitionEpic from './processDefinition/processDefinitionEpics';
import * as taskEpic from './task/taskEpics';
import * as oneDriveSearchFilesEpic from './oneDriveSearch/oneDriveSearchEpic';
import * as oneDriveRootFolderEpic from './oneDriveRootFolder/oneDriveRootFolderEpics';
import * as oneDriveItemChildrenEpic from './oneDriveItemChildren/oneDriveItemChildrenEpic';
import * as oneDriveRecentFilesEpic from './oneDriveRecentFiles/oneDriveRecentFilesEpic';
import * as processInstanceInfoEpic from './processInstanceInfo/processInstanceInfoEpics';
import * as taskDetailsEpic from './taskDetails/taskDetailsEpics';
import * as taskDiagramEpic from './taskDiagram/taskDiagramEpics';
import * as taskCommentsEpic from './taskComments/taskCommentsEpics';
import * as taskListEpic from './taskList/taskListEpics';
import * as reassignTaskEpic from './reassignTask/reassignTaskEpics';
import * as userGroupEpic from './userGroup/userGroupEpics';
import * as processDefinitionListEpic from './processDefinitonList/processDefinitionListEpic';
import * as tasksListEpic from './tasksList/tasksListEpics';
import * as assigneeUserEpic from './assigneeUser/assigneeUserEpic';
import * as groupMembersEpic from './groupMembers/groupMembersEpic';
import * as claimTaskEpic from './claimTask/claimTaskEpic';
import * as requestsListEpic from './requestsList/requestsListEpics';
import * as groupMembersByLastNamEpic from './groupMembers/groupMebersByLastName/groupMebersByLastNameEpic';
import * as groupsTasksListEpic from './groupsTasksList/groupsTasksListEpics';
import * as leavesTypesEpic from './leavesTypes/leavesTypesEpic';
import * as historicActivityInstanceEpic from './historicActivityInstance/historicActivityInstanceEpics';
import * as profileExpenseEpic from './ProfileExpense/ProfileExpenseEpic';
import * as profileExpenseAttachmentEpic from './ProfileExpenseAttachment/ProfileExpenseAttachmentEpic';
import * as camundaUsersEpic from './camundaUsers/camundaUsersEpics';
import * as balanceEpic from './balance/balanceEpics';
import * as leavesHistoryEpic from './leavesHistory/leavesHistoryEpics';
import * as leavesListEpic from './leavesList/leavesListEpic';
import * as historicTaskListEpic from './historicTaskList/historicTaskListEpics';
import * as paySlipsEpic from './paySlips/paySlipsEpics';
import * as requestPrettyIdEpic from './requestPrettyId/requestPrettyIdEpic';
import * as taskPrettyIdEpic from './taskPrettyId/taskPrettyIdEpic';
import * as submitFormEpic from './submitForm/submitFormEpics';
import * as publicHolidaysEpic from './publicHolidays/publicHolidaysEpics';
import * as attendanceInfoEpic from './attendance/AttendanceInfoEpics';
import * as userStatusEpic from './userStatus/userStatusEpic';
import * as userProfileEpic from './userProfile/userProfileEpic';
import * as otherUserStatusEpic from './otherUserStatus/otherUserStatusEpic';
import * as categoriesTreeEpic from './categoriesTree/categoriesTreeEpic';
import * as allRequestCardEpic from './allRequestCard/allRequestsCardEpic';
import * as subCategoryCardEpic from './subCategoryCard/subCategoryCardEpic';
import * as categoryCardEpic from './categoryCard/categoryCardEpic';
import * as myRequestCardsEpic from './myRequestCards/myRequestCardsEpic';
import * as myTaskCardsEpic from './myTasksCards/myTaskCardsEpic';
import * as myGroupTaskEpic from './myGroupTaskCard/myGroupTasksCardsEpic';
import * as requestCatalogDrawerEpic from './requestCatalogDrawer/requestCatalogDrawerEpic';
import * as paySlipsPdfFileEpic from './paySlipsPdfFile/paySlipsPdfFileEpic';
import * as exportAttendanceEpic from './exportAttendance/exportAttendanceEpics';
import * as userInfoCardEpic from './userInfoCard/userInfoCardEpics';
import * as profileExpenseLookupEpic from './ProfileExpensesLookup/ProfileExpenseLookupEpic';
import * as userInfoStatusEpic from './userInfoStatus/userInfoStatusEpics';
import * as notificationsListEpic from './notificationList/notificationListEpic';
import * as allNotificationUpdatesListEpic from './notificationList/seeAllUpdates/allNotificationUpdatesListEpic';
import * as usersTableEpic from './UsersTable/UsersTableEpic';
import * as employeeDirectorySearchEpic from './employeeDirectorySearch/employeeDirectorySearchEpics';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mergeEpics = (...objects: Record<string, any>[]) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objects.reduce((acc: any[], object) => [...acc, ...Object.values(object)], []);

const rootEpic = combineEpics(
  ...mergeEpics(
    profileEpic,
    hierarchyEpic,
    subordinatesEpic,
    userAvatarEpic,
    generalInfoEpic,
    teamsEpic,
    teamsUserAvatarEpic,
    teamsChannelsEpic,
    chatsEpic,
    userInfoEpic,
    teamsChannelMembersEpic,
    teamsPostsEpic,
    teamsChannelFilesEpice,
    teamsChannelFolderEpic,
    usersEpic,
    oneDriveSharedLibrariesEpic,
    oneDriveFolderSharedEpic,
    oneDriveSharedLibrarieFoldersListEpic,
    processDefinitionKeysEpic,
    processDefinitionEpic,
    taskEpic,
    oneDriveShared,
    oneDriveSearchFilesEpic,
    oneDriveRootFolderEpic,
    oneDriveItemChildrenEpic,
    oneDriveRecentFilesEpic,
    processInstanceInfoEpic,
    taskDetailsEpic,
    taskDiagramEpic,
    taskCommentsEpic,
    taskListEpic,
    reassignTaskEpic,
    userGroupEpic,
    processDefinitionListEpic,
    tasksListEpic,
    assigneeUserEpic,
    groupMembersEpic,
    claimTaskEpic,
    requestsListEpic,
    groupMembersByLastNamEpic,
    groupsTasksListEpic,
    leavesTypesEpic,
    historicActivityInstanceEpic,
    profileExpenseEpic,
    profileExpenseAttachmentEpic,
    camundaUsersEpic,
    balanceEpic,
    leavesHistoryEpic,
    leavesListEpic,
    historicTaskListEpic,
    paySlipsEpic,
    requestPrettyIdEpic,
    taskPrettyIdEpic,
    submitFormEpic,
    publicHolidaysEpic,
    attendanceInfoEpic,
    userStatusEpic,
    userProfileEpic,
    otherUserStatusEpic,
    categoriesTreeEpic,
    allRequestCardEpic,
    subCategoryCardEpic,
    categoryCardEpic,
    myRequestCardsEpic,
    myTaskCardsEpic,
    myGroupTaskEpic,
    requestCatalogDrawerEpic,
    paySlipsPdfFileEpic,
    exportAttendanceEpic,
    userInfoCardEpic,
    profileExpenseLookupEpic,
    userInfoStatusEpic,
    notificationsListEpic,
    allNotificationUpdatesListEpic,
    usersTableEpic,
    employeeDirectorySearchEpic
  )
);

export default rootEpic;

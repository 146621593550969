import React, { FC, useState } from 'react';

import { TasksContext } from './TasksContext';

export const TasksProvider: FC = ({ children }) => {
  const [currentActiveTab, setCurrentActiveTab] = useState<string | undefined>('0');
  const [processInstanceId, setProcessInstanceId] = useState('');
  const [rootPath, setRootPath] = useState<string | undefined>();
  const [commentsRequestId, setCommentsRequestId] = useState<string>('');
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isSubmitDisable, setIsSubmitDisable] = useState<boolean>(false);
  const [isFormValuesChanged, setIsFormValuesChanged] = useState<boolean>(false);
  return (
    <TasksContext.Provider
      value={{
        currentActiveTab,
        setCurrentActiveTab,
        processInstanceId,
        setProcessInstanceId,
        rootPath,
        setRootPath,
        commentsRequestId,
        setCommentsRequestId,
        isSubmitLoading,
        setIsSubmitLoading,
        isSubmitDisable,
        setIsSubmitDisable,
        isFormValuesChanged,
        setIsFormValuesChanged,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

import React, { FC } from 'react';
import { Col, Collapse, Row } from 'antd';
import { TaskDefinitionKey, TaskDefinitionType, TaskStatus } from 'types';
import { StatusTag, TagColor, TextStyled } from 'ui/elements';
import { UserPopover } from 'ui/components/UserPopover';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { useFullName } from 'ui/hooks';
import { useTranslate } from 'translations';
import { getStatusColor } from '../../helper';
import { CollapseHeaderContainer, CollapseHeaderTitle } from '../../TaskStep';
import { StyledCard, StyledRow } from '../TaskInfo.styled';
import { AssigneeInfo } from '../../TaskSteps.types';

const { Panel } = Collapse;

interface TaskInfoWrapperProps {
  status: TaskStatus;
  title: string;
  taskPrettyId: string;
  taskDefinitionKey: TaskDefinitionKey;
  onClickOnCollapse: () => void;
  disabled?: boolean;
  isOpened?: boolean;
  highlighted?: boolean;
  created?: string;
  assigneeInfo?: AssigneeInfo;
  userGroupName?: [string];
  collapsible?: 'header' | 'disabled';
}

const disableLease = process.env.REACT_APP_DISABLE_LEASE === 'true';

export const TaskInfoWrapper: FC<TaskInfoWrapperProps> = ({
  highlighted,
  taskDefinitionKey,
  isOpened,
  collapsible,
  status,
  disabled,
  title,
  assigneeInfo,
  created,
  userGroupName,
  taskPrettyId,
  children,
  onClickOnCollapse,
}) => {
  const { t } = useTranslate();

  const fullName = useFullName(assigneeInfo?.firstName, assigneeInfo?.lastName);
  const isLeaseTask = Object.values(TaskDefinitionType).includes(
    taskDefinitionKey as TaskDefinitionType
  );

  return (
    <StyledCard $highlighted={highlighted} $withGap={isLeaseTask}>
      <Collapse
        defaultActiveKey={isOpened ? '1' : undefined}
        collapsible={collapsible}
        className="tasks_collapse"
        ghost
        onChange={onClickOnCollapse}
      >
        <Panel
          header={
            <CollapseHeaderContainer>
              <Row align="middle" gutter={[12, 0]}>
                <Col>
                  <CollapseHeaderTitle type={disabled ? 'secondary' : undefined}>
                    {title}
                  </CollapseHeaderTitle>
                </Col>
                <Col>
                  {status !== TaskStatus.processed && (
                    <StatusTag color={getStatusColor(status) as TagColor}>{status}</StatusTag>
                  )}
                </Col>
              </Row>
            </CollapseHeaderContainer>
          }
          style={{ alignItems: 'center' }}
          key="1"
          extra={
            <Row wrap={false} gutter={[40, 0]}>
              <Col span={24}>
                <Row gutter={[0, 5]} align="middle">
                  <Col>
                    <Row wrap={false} gutter={[10, 0]} align="middle">
                      {assigneeInfo && (
                        <Col>
                          <UserPopover currentID={assigneeInfo?.assigneeId}>
                            <MSTeamsUserAvatar id={assigneeInfo?.assigneeId} name={fullName} />
                          </UserPopover>
                        </Col>
                      )}
                      <Col flex="100%">
                        <StyledRow>
                          <Col span={24}>
                            <UserPopover currentID={assigneeInfo?.assigneeId}>
                              <TextStyled size={12} strong>
                                {fullName}
                              </TextStyled>
                            </UserPopover>
                          </Col>
                          <Col span={24}>
                            <TextStyled size={12} type="secondary">
                              {created?.split(' ')[0]}
                            </TextStyled>
                          </Col>
                        </StyledRow>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Row justify="space-between" gutter={[24, 12]}>
                <Col>
                  <TextStyled type="secondary">
                    {disableLease ? taskPrettyId : t('task.taskId', { taskId: taskPrettyId })}
                  </TextStyled>
                </Col>
                {userGroupName && (
                  <Col>
                    <TextStyled type="secondary">
                      {t('task.assignedGroup')} {userGroupName}
                    </TextStyled>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>

          {children}
        </Panel>
      </Collapse>
    </StyledCard>
  );
};

import { Core, CLAIM_TASK } from 'api';

interface PostClaimTask {
  (taskId: string, userId: string): Promise<unknown>;
}

class ClaimTask extends Core {
  postClaimTask: PostClaimTask = async (taskId, userId) =>
    this.post(CLAIM_TASK(taskId), { userId: userId });
}
export const claimTask = new ClaimTask();

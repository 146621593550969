import React, { useMemo } from 'react';
import { TType } from 'translations';
import { ITraining } from 'types/NewProfile';
import moment from 'moment';
import { PrivateProfileTrainingItem } from './PrivateProfileTrainingItem';

export interface PrivateProfileTrainingProps {
  t: TType;
  training: ITraining[];
}

export const PrivateProfileTraining: React.FC<PrivateProfileTrainingProps> = ({
  t,
  training,
}: PrivateProfileTrainingProps) => {
  const dataTraining: ITraining[] = useMemo(() => {
    return training;
  }, [training]);
  return (
    <>
      {dataTraining.map((item, index) => (
        <PrivateProfileTrainingItem
          t={t}
          key={index}
          certificateName={item.name}
          issuingOrganisation={item.issuingOrganization}
          issueDate={moment(item.issueDate).format('DD.MM.YYYY')}
          expirationDate={moment(item.expirationDate).format('DD.MM.YYYY')}
          trainingAttachmentList={item.trainingAttachmentList}
        />
      ))}
    </>
  );
};

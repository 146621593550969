import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from '../../constants';
import { publicHolidays } from '../../PublicHolidays';

export const usePublicHolidaysApi = () => {
  return useQuery(
    [QUERIES.getPublicHolidays],
    async () => {
      const { data } = await publicHolidays.getPublicHolidays();
      return data;
    },
    {
      ...PERSIST_QUERY_CONFIG,
    }
  );
};

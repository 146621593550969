import styled from 'styled-components';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { colors, space } from 'ui/elements';
import { Typography, Button } from 'antd';

const { Text } = Typography;

export const ExclamationCircleOutlinedStyled = styled(QuestionCircleOutlined)`
  margin-right: ${space.slim};
  color: ${colors.redSecond};
`;

export const TitleStyled = styled(Text)`
  display: inline-block;
  margin-top: ${space.slim};
  font-size: ${space.moderate};
`;

export const TitleScheduledLeaveStyled = styled(Text)`
  font-size: ${space.moderate};
  display: block;
  padding-left: ${space.normal};
  padding-bottom: ${space.normal};
`;

export const DaysTextStyled = styled(Text)`
  font-size: ${space.moderate};
  color: ${colors.redSecond};
  padding-left: ${space.mini};
`;

export const SubTitleStyled = styled(Text)`
  display: inline-block;
  margin: ${space.cozy} 0;
  font-size: ${space.moderate};
`;

export const RescheduleButtonStyled = styled(Button)`
  color: ${colors.orange};
`;

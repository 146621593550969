import { connect, ConnectedProps } from 'react-redux';

import {
  RootState,
  getTeamsChannelFilesStart,
  getCurrentTeamsChannelFilesData,
  getTeamsChannelFolderStart,
  getCurrentTeamsFolderData,
} from 'store';
import { ChannelTeamsFileListProps } from './TeamsChannelFilesList';

const mapDispatch = { getTeamsChannelFilesStart, getTeamsChannelFolderStart };

const mapState = (state: RootState, props: ChannelTeamsFileListProps) => ({
  teamsFileList: getCurrentTeamsChannelFilesData(state, props),
  teamsFolder: getCurrentTeamsFolderData(state, props),
});
export const teamsFileListConnector = connect(mapState, mapDispatch);

type PropsFormRedux = ConnectedProps<typeof teamsFileListConnector>;
export type ConnectorProps = PropsFormRedux;

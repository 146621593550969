import React, { FC, memo } from 'react';
import { CarouselRef } from 'antd/lib/carousel';
import { carouselBreakPoints } from 'ui/elements';
import { StyledCarousel } from './CardCarousel.styled';

interface Props {
  children: React.ReactNode;
  carouselEL: React.RefObject<CarouselRef>;
  slidesShow: number;
  beforeChange?: (currentSlide: number, nextSlide: number) => void;
}

export const CardCarouselComponent: FC<Props> = ({
  children,
  carouselEL,
  beforeChange,
  slidesShow,
}) => {
  return (
    <StyledCarousel
      ref={carouselEL}
      slidesToShow={slidesShow}
      dots={true}
      slidesToScroll={1}
      infinite={false}
      beforeChange={beforeChange}
      {...carouselBreakPoints}
    >
      {children}
    </StyledCarousel>
  );
};

export const CardCarousel = memo(CardCarouselComponent);

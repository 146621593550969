import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ExpenseAttachment, RequestCommonError, RequestStatus } from 'types';
import { ProfileExpenseAttachmentsState } from './ProfileExpenseAttachmentType';

export const emptyProfileExpenseAttachmentsState: ProfileExpenseAttachmentsState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetProfileExpenseAttachmentsStart = PayloadAction<{
  PersonId: string;
  expensesID: string;
}>;
export type GetProfileExpenseAttachmentsSuccess = PayloadAction<ExpenseAttachment[]>;
export type GetProfileExpenseAttachmentsFail = PayloadAction<RequestCommonError>;

const profileExpenseAttachments = createSlice({
  name: 'profileExpenseAttachments',
  initialState: emptyProfileExpenseAttachmentsState,
  reducers: {
    getProfileExpenseAttachmentsStart(state, _: GetProfileExpenseAttachmentsStart) {
      state.status = RequestStatus.pending;
    },
    getProfileExpenseAttachmentsSuccess(state, action: GetProfileExpenseAttachmentsSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getProfileExpenseAttachmentsFail(state, action: GetProfileExpenseAttachmentsFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getProfileExpenseAttachmentsStart,
  getProfileExpenseAttachmentsSuccess,
  getProfileExpenseAttachmentsFail,
} = profileExpenseAttachments.actions;
export default profileExpenseAttachments.reducer;

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetBalanceFail,
  GetBalanceSuccess,
  GetBalanceStart,
  getBalanceStart,
  getBalanceSuccess,
  getBalanceFail,
} from './balanceSlice';

export const getBalanceEpic: Epic<
  GetBalanceStart,
  GetBalanceSuccess | GetBalanceFail,
  RootState,
  typeof API
> = (action$, _, { balance }) =>
  action$.pipe(
    filter(getBalanceStart.match),
    exhaustMap(({ payload }) =>
      from(balance.getBalance(payload)).pipe(
        map(({ data }) => getBalanceSuccess(data)),
        catchError((error) => of(getBalanceFail(error?.response?.data)))
      )
    )
  );

import Card, { CardProps } from 'antd/lib/card';
import React from 'react';
import styled from 'styled-components';
import { borderRadius, space } from 'ui/elements';

const WrapperCard = styled(Card)<{ centered?: boolean }>`
  .ant-card-body {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
    align-items: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  }

  .ant-card-cover {
    height: 60%;
    margin: 10px 10px 0px 10px;
  }

  border-radius: ${borderRadius.xLarge} !important;

  margin-right: ${space.moderate};
  box-shadow: 0 2px 4px rgba(28, 43, 101, 0.14);
  border: none;
  cursor: pointer;
  height: 250px;
  overflow: hidden;
  word-wrap: break-word;
  transition: all 0.3s ease-in-out;
`;

export const AnnouncementCard: React.FC<CardProps & { centered?: boolean }> = ({
  children,
  ...rest
}) => {
  return <WrapperCard {...rest}>{children}</WrapperCard>;
};

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetTasksListFail,
  GetTasksListStart,
  GetTasksListSuccess,
  getTasksListFail,
  getTasksListStart,
  getTasksListSuccess,
} from './tasksListSlice';

export const tasksListEpic: Epic<
  GetTasksListStart,
  GetTasksListSuccess | GetTasksListFail,
  RootState,
  typeof API
> = (action$, _, { task }) =>
  action$.pipe(
    filter(getTasksListStart.match),
    exhaustMap(({ payload }) =>
      from(task.getTasksList(payload)).pipe(
        map(({ data }) => getTasksListSuccess(data)),
        catchError((error) => of(getTasksListFail(error?.response?.data)))
      )
    )
  );

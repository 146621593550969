import React, { memo, FC, useMemo, useCallback } from 'react';
import { Card, Row, Col, Spin, notification } from 'antd';
import { useJiraAssetsList } from 'api/Jira/hooks/useJiraAssetsList';
import styled from 'styled-components';
import { TType, MessagesTypes } from 'translations';
import { space, TitleH2 } from 'ui/elements';
import { JiraDynamicFormFieldType, JiraSubmitInfo } from 'types';

import { useJiraSubmitForm } from 'api/Jira';
import { useTasksContext } from 'routes/Tasks/TasksProvider';
import { useHistory } from 'react-router-dom';
import { convertJiraVariables, getJiraAttachments } from 'routes/Calendar';
import { filterEmptyFields } from 'ui/components/TaskList/TaskSteps/helper';
import { Gutter } from 'antd/lib/grid/row';
import { PathText } from '../../templates/RequestsCatalogCards/RequestsCatalogCards.styled';
import { SERVICE_NEW_REQUEST_FORM } from '../ServiceNewRequestForm';
import { GeneratedJiraForm } from './JiraDynamicForm';

const Wrapper = styled.div`
  padding: ${space.normal};
`;

const FORM_LAYOUT = {
  xs: { span: 24, offset: 0 },
  lg: { span: 16, offset: 4 },
  xl: { span: 14, offset: 5 },
  xxl: { span: 12, offset: 6 },
};

const ROW_GUTTER: [Gutter, Gutter] = [0, 24];

interface Props {
  t: TType;
  data: JiraDynamicFormFieldType[] | null;
  processDefinitionKey: string;
  loading?: boolean;
  description?: string;
  isError?: boolean;
  deskId: number;
  typeId: number;
}

export const JiraNewRequestFormComponent: FC<Props> = ({
  processDefinitionKey,
  t,
  data,
  loading,
  isError,
  description,
  deskId,
  typeId,
}) => {
  const history = useHistory();
  const { mutateAsync: submitJiraForm, isLoading } = useJiraSubmitForm(deskId, typeId);
  const assetKey = data?.[0].key || '';
  const assetKeys = ['ASSET_UPGRADE', 'ASSET_TO_DE_ALLOCATE'];
  const fetchAssets = assetKeys.includes(assetKey);
  const { data: assetsList } = useJiraAssetsList(deskId, typeId, assetKey, fetchAssets);
  const [category, subCategory] = useMemo(
    () => processDefinitionKey.replaceAll('-', ' ').split('.'),
    [processDefinitionKey]
  );
  const { setIsSubmitLoading } = useTasksContext();
  const getAssetVal = (assetKey: string[]) => {
    const assets = assetsList?.filter((asset) => assetKey.indexOf(asset.id) > -1);
    return assets?.map((asset) => {
      return { objectKey: asset?.id, objectName: asset?.value };
    });
  };

  const onSubmitJira = useCallback(
    async (variables) => {
      const currentFormField = convertJiraVariables(filterEmptyFields(variables));
      const formData = new FormData();
      currentFormField.forEach(({ key, value }, index) => {
        formData.append(`fieldDetails[${index}].key`, key);
        if (Array.isArray(value) && !assetKeys.includes(key)) {
          value.forEach((v) => {
            formData.append(`fieldDetails[${index}].value`, v as string);
          });
        } else {
          if (assetKeys.includes(key)) {
            formData.append(
              `fieldDetails[${index}].value`,
              JSON.stringify(getAssetVal(value as string[]))
            );
          } else {
            formData.append(`fieldDetails[${index}].value`, value as string);
          }
        }
      });
      const currentFormAttachments = getJiraAttachments(variables);
      currentFormAttachments.forEach((attachment) => {
        formData.append('attachment', attachment);
      });
      setIsSubmitLoading(true);
      submitJiraForm(formData)
        .then((result) => {
          onSuccessSubmit(result);
          notification.success({
            message: t('messages.success'),
            description: t('messages.success.requestCreated'),
          });
        })
        .catch((e) => {
          notification.error({
            message: t('messages.error.wentWrong'),
            description: t('messages.error.tryAgainLater'),
          });
        })
        .finally(() => {
          setIsSubmitLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [submitJiraForm, assetsList]
  );

  const onSuccessSubmit = (jiraSubmitInfo: JiraSubmitInfo) => {
    const location = {
      pathname: `/tasks/${jiraSubmitInfo.processInstanceId}`,
      state: { defaultTab: '0' },
    };
    history.replace(location);
  };

  return (
    <Wrapper>
      <Card>
        <Spin spinning={loading} delay={100}>
          <Row gutter={ROW_GUTTER}>
            <Col {...FORM_LAYOUT}>
              <PathText $isPathForm={true}>{`${category} / ${subCategory}`}</PathText>
            </Col>
            <Col {...FORM_LAYOUT}>
              <TitleH2 strong>
                {t(`service.newRequest.title.${processDefinitionKey}` as MessagesTypes)}
              </TitleH2>
            </Col>
            <Col {...FORM_LAYOUT}>
              <GeneratedJiraForm
                formData={data}
                formName={SERVICE_NEW_REQUEST_FORM}
                isError={isError}
                description={description}
                onSubmitJira={onSubmitJira}
                isLoading={isLoading}
                assetsList={assetsList}
                processDefinitionKey={processDefinitionKey}
              />
            </Col>
          </Row>
        </Spin>
      </Card>
    </Wrapper>
  );
};

export const JiraNewRequestForm = memo(JiraNewRequestFormComponent);

import React, { FC, useEffect } from 'react';
import { Col, notification, Row, Spin } from 'antd';
import moment from 'moment';

import { InfoItemCol, TitleH3 } from 'ui';
import { MessagesTypes, useTranslate } from 'translations';
import { RequestStatus } from 'types';
import { MappedGeneralInfoData } from 'store/generalInfo/mapData';
import { StyledCard } from '../../../Public/ReadView.styled';
import { ConnectorProps, generalInformationConnector } from './generalInformation.connector';
import {
  StyledCardInfo,
  StyledCardLabel,
  StyledCardValue,
  StyledCol,
  StyledMoreDetailContainer,
  StyledMoreDetailTitleContainer,
  StyledRowFieldsInCardContainer,
  StyledTitle,
} from './GeneralInformation.styled';
import ProfileIconSelector from './iconsSelector';

const FIELDS_IN_CARD = ['hireDate', 'lastPromotionDate', 'yearsWorkedInTheCompany', 'employeeId'];

const FIELDS_TO_RENDER = [
  'employeeId',
  'workerType',
  'assignmentStatus',
  'department',
  'location',
  'dateOfBirth',
  'gender',
  'assignmentName',
];

interface Props {
  setIsEditing: (val: boolean) => void;
  hcmPersonId: string | null;
}

export const GeneralInformationComponent: FC<Props & ConnectorProps> = ({
  generalInfoData,
  getGeneralInfoStart,
  hcmPersonId,
}) => {
  const { t } = useTranslate();

  useEffect(() => {
    if (hcmPersonId) {
      getGeneralInfoStart(hcmPersonId);
    }
  }, [getGeneralInfoStart, hcmPersonId]);

  useEffect(() => {
    if (generalInfoData.status === RequestStatus.rejected) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [generalInfoData.status, t]);

  return (
    <StyledCard>
      <Row justify="space-between" gutter={[24, 24]}>
        <Col>
          <TitleH3 type={'primary'}>{t('user.privateProfile.title.generalInformation')}</TitleH3>
        </Col>
      </Row>
      <StyledRowFieldsInCardContainer justify={'space-between'} gutter={[24, 24]}>
        {FIELDS_IN_CARD.map((field, i) => {
          return (
            <Col key={i} xs={24} sm={8} lg={6}>
              <StyledCardInfo>
                <Row align={'middle'}>
                  <StyledCol span={24}>
                    {generalInfoData.data ? (
                      <StyledCardValue strong ellipsis>
                        {field === 'hireDate'
                          ? generalInfoData.data &&
                            moment(
                              generalInfoData.data[field as keyof MappedGeneralInfoData]
                            ).format('DD/MM/YYYY')
                          : generalInfoData.data &&
                            generalInfoData.data[field as keyof MappedGeneralInfoData]}
                      </StyledCardValue>
                    ) : (
                      <Spin size={'small'} />
                    )}
                  </StyledCol>
                  <StyledCol span={24}>
                    <ProfileIconSelector
                      type={field.replace(/\s/g, '').replace(/-/g, '')}
                      style={{ fontSize: 24, height: '24px', width: '24px' }}
                    />
                    <StyledCardLabel>
                      {t(`user.privateProfile.${field}` as MessagesTypes)}
                    </StyledCardLabel>
                  </StyledCol>
                </Row>
              </StyledCardInfo>
            </Col>
          );
        })}
      </StyledRowFieldsInCardContainer>
      <Row justify={'start'}>
        <Col>
          <StyledMoreDetailTitleContainer>
            <StyledTitle>{t('user.privateProfile.collapse.moreDetails')}</StyledTitle>
          </StyledMoreDetailTitleContainer>

          <StyledMoreDetailContainer gutter={[30, 30]}>
            {FIELDS_TO_RENDER.map((field, i) => {
              const gender =
                generalInfoData.data?.gender === 'F'
                  ? t('user.privateProfile.gender.female')
                  : t('user.privateProfile.gender.male');

              return (
                <InfoItemCol
                  xs={24}
                  sm={12}
                  lg={8}
                  label={t(`user.privateProfile.${field}` as MessagesTypes)}
                  key={i}
                >
                  {generalInfoData.data ? (
                    field === 'gender' ? (
                      gender
                    ) : field === 'dateOfBirth' ? (
                      generalInfoData.data &&
                      moment(generalInfoData.data[field as keyof MappedGeneralInfoData]).format(
                        'DD.MM.YYYY'
                      )
                    ) : (
                      generalInfoData.data &&
                      generalInfoData.data[field as keyof MappedGeneralInfoData]
                    )
                  ) : (
                    <Spin size={'small'} />
                  )}
                </InfoItemCol>
              );
            })}
          </StyledMoreDetailContainer>
        </Col>
      </Row>
    </StyledCard>
  );
};

export const GeneralInformation = generalInformationConnector(GeneralInformationComponent);

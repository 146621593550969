import { Collapse } from 'antd';
import styled from 'styled-components';

import { colors } from 'ui/elements';
import { getRandomColorById } from 'ui';
import { StyledMessageRow } from '../../MSTeamsChatTabs/MSTeamsChatSidebar/MSTeamsChatSidebar.styled';

export const StyledTeamAvatar = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ id }: { id: string }) => getRandomColorById(id)};
  border-radius: 2px;
  font-size: 12px;
  line-height: 24px;
  color: ${colors.white};
`;

export const StyledCollapse = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 50%;
    transform: translateY(-50%);
    left: 26px;
  }

  &.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-left: 0;
  }

  & > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }

  &.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

export const StyledTeamChannelRow = styled(StyledMessageRow)`
  padding-left: 90px;
`;

export const StyledTeamRow = styled(StyledMessageRow)`
  padding-left: 46px;
`;

import { useMutation } from 'react-query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { notification } from 'antd';
import { useTranslate } from 'translations';
import { workFromHomeRequest } from '../WorkFromHomeRequest';

export const useWFHSubmitApi = (hcmPersonId: string) => {
  const { t } = useTranslate();
  return useMutation(
    async (formData: AxiosRequestConfig['data']) => {
      const { data } = await workFromHomeRequest.postWorkFromHomeRequestSubmit(
        hcmPersonId,
        formData
      );
      return data;
    },
    {
      onError: (error: AxiosError) => {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: error.response?.data.errors[0].message,
        });
      },
    }
  );
};

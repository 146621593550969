import { Col } from 'antd';
import React, { FC, memo } from 'react';
import {
  StyledColumn,
  StyledExampleLabel,
  StyledProcessDescriptionIcon,
  StyledRow,
  StyledTextWrapper,
  StyleTextLabel,
} from 'ui/components/GeneratedForm/GeneratedForm.Styled';

interface DescriptionProps {
  descriptionText: string;
  descriptionLabel: string;
  exampleText?: string;
  showDescriptionIcon?: boolean;
  showIcon?: boolean;
}

export const ProcessDescriptionComponent: FC<DescriptionProps> = ({
  descriptionLabel,
  exampleText,
  descriptionText,
  showDescriptionIcon = true,
  showIcon = true,
}) => {
  return (
    <StyledTextWrapper>
      <StyledRow>
        {(showDescriptionIcon || showIcon) && <StyledProcessDescriptionIcon />}

        <StyledColumn>
          {!exampleText && (
            <Col span={12}>
              <StyleTextLabel strong>{descriptionLabel}</StyleTextLabel>
            </Col>
          )}
          <StyleTextLabel>{descriptionText}</StyleTextLabel>
          <StyledExampleLabel>{exampleText}</StyledExampleLabel>
        </StyledColumn>
      </StyledRow>
    </StyledTextWrapper>
  );
};

export const ProcessDescription = memo(ProcessDescriptionComponent);

import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from 'api/constants';
import { profileUpdate } from '../ProfileUpdate';

export const useGetProfileUpdateRequestData = (enabled: boolean) =>
  useQuery(
    [QUERIES.profileUpdateRequestData],
    async () => {
      const { data } = await profileUpdate.getProfileUpdateData();
      return data;
    },
    {
      ...PERSIST_QUERY_CONFIG,
      enabled,
      staleTime: 1000,
      cacheTime: 1000,
    }
  );

import { OneDriveSearchResultData } from 'types';
import { Core, SEARCH_FILES } from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetOneDriveSearchFiles {
  (params: AxiosRequestConfig): Promise<{ data: OneDriveSearchResultData }>;
}

class OneDriveSearch extends Core {
  getOneDirveSearchFiles: GetOneDriveSearchFiles = async (params) =>
    this.get(SEARCH_FILES(), params);
}

export const oneDriveSearch = new OneDriveSearch();

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const myGroupTaskCards = (state: RootState): RootState['myGroupTaskCards'] =>
  state.myGroupTaskCards;

export const myGroupTaskCardsSelector = createSelector(
  myGroupTaskCards,
  ({ error, status, data, hasMore }) => ({
    error,
    status,
    data,
    hasMore,
  })
);

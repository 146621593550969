import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { LogoutIcon } from 'assets';
import { VerticalSpace, TitleH3 } from 'ui';
import { useTranslate } from 'translations';
import { useAuthContext } from 'authentication';
import { StyledSettingsCard } from 'routes/Settings/Settings.styled';
import { GUTTER_8_0 } from '../constants';
import { StyledLogoutRow, StyledLogoutIconCol } from './Logout.styled';

export const Logout: FC = () => {
  const { logout } = useAuthContext();
  const { t } = useTranslate();

  return (
    <VerticalSpace>
      <StyledSettingsCard>
        <Row align="middle">
          <Col onClick={logout}>
            <StyledLogoutRow gutter={GUTTER_8_0}>
              <StyledLogoutIconCol flex="auto">
                <LogoutIcon />
              </StyledLogoutIconCol>
              <Col>
                <TitleH3 type="prime">{t('logout.title')}</TitleH3>
              </Col>
            </StyledLogoutRow>
          </Col>
        </Row>
      </StyledSettingsCard>
    </VerticalSpace>
  );
};

import { AxiosRequestConfig } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TaskData, RequestCommonError, RequestStatus } from 'types';
import { HistoricTaskListState } from './historicTaskListTypes';

export const emptyHistoricTaskListInitialState: HistoricTaskListState = {
  status: RequestStatus.idle,
  error: null,
  hasMore: true,
};

export type GetHistoricTaskListStart = PayloadAction<AxiosRequestConfig>;
export type GetHistoricTaskListSuccess = PayloadAction<TaskData>;
export type GetHistoricTaskListFail = PayloadAction<RequestCommonError>;

const task = createSlice({
  name: 'historicTaskList',
  initialState: emptyHistoricTaskListInitialState,
  reducers: {
    getHistoricTaskListStart(state, action: GetHistoricTaskListStart) {
      state.status = RequestStatus.pending;
    },
    getHistoricTaskListSuccess(state, action: GetHistoricTaskListSuccess) {
      state.status = RequestStatus.resolved;

      if (action.payload?.length < 10) {
        state.hasMore = false;
      }

      if (!state.data?.length) {
        state.data = action.payload;
        return;
      }

      state.data = [...state.data, ...action.payload];
    },
    getHistoricTaskListFail(state, action: GetHistoricTaskListFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getHistoricTaskListStart,
  getHistoricTaskListSuccess,
  getHistoricTaskListFail,
} = task.actions;
export default task.reducer;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const generalInfo = (state: RootState): RootState['generalInfo'] => state.generalInfo;

export const generalInfoData = createSelector(generalInfo, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

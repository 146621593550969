import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { borderRadius, colors, space, typography } from 'ui/elements';

const { Text } = Typography;

export const AllUpdatesStyled = styled(Text)`
  font-size: ${typography.size.giga};
  color: ${colors.lightOverlayBlue};
  cursor: pointer;
  font-style: normal;
  font-weight: ${typography.weight.regular};
`;

export const RequestIdStyled = styled.div`
  font-size: ${typography.size.uno};
  color: ${colors.grey};
  text-align: end;
`;

export const TemplateTitleStyled = styled(Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.primaryColor};
  font-style: normal;
  font-weight: ${typography.weight.bold};
`;

export const TemplateMessageStyled = styled(Text)`
  font-size: ${typography.size.hecto};
  font-style: normal;
  font-weight: ${typography.weight.regular};
  color: ${colors.lightOverlayBlue};
`;

export const TemplateDateOfRequestStyled = styled(Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.grey};
  font-weight: ${typography.weight.bold};
  font-style: normal;
  font-weight: ${typography.weight.regular};
`;

export const TemplateRowGeneratedStyled = styled(Row)`
  margin-top: ${space.normal} !important;
`;

export const ColListStyled = styled(Col)`
  padding: ${space.cozy} 0px;
`;

export const TemplateComment = styled.div`
  padding: ${space.cozy};
  width: inherent;
  background-color: ${colors.lightShardGray};
  border-radius: ${borderRadius.small};
`;

export const CommenterTextStyled = styled(Text)`
  font-size: ${typography.size.hecto};
  font-weight: ${typography.weight.regular};
  color: ${colors.primaryColor};
`;

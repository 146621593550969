import { AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';
import { task } from '../Task';

export const useNewRequestMutation = (processDefinitionKey: string) => {
  return useMutation(async (formData: AxiosRequestConfig['data']) => {
    const { data } = await task.postNewRequestForm(processDefinitionKey, formData);
    return data;
  });
};

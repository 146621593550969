import { connect, ConnectedProps } from 'react-redux';

import { RootState, getTeamsData, getTeamsStart } from 'store';

const mapDispatch = {
  getTeamsStart,
};

const mapState = (state: RootState) => ({
  teamsData: getTeamsData(state),
});

export const teamsConnector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof teamsConnector>;

export type ConnectorProps = PropsFromRedux;

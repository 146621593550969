import React, { FC } from 'react';
import { Form, Input } from 'antd';
import parse from 'html-react-parser';

const { TextArea } = Input;

interface Props {
  markup: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readOnlyFields: any;
}

export const DynamicForm: FC<Props> = ({ markup, readOnlyFields }): JSX.Element => {
  const parsedDynamicForm = parse(markup.trim(), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    replace: (domNode: any) => {
      const readOnlyField =
        domNode?.tagName === 'input' || domNode?.tagName === 'textarea'
          ? readOnlyFields[domNode?.attribs.name]
          : null;

      if (
        domNode?.tagName === 'div' &&
        (domNode?.attribs['ng-if'] || domNode?.attribs['ng-show'])
      ) {
        return <></>;
      }

      if (domNode?.tagName === 'input') {
        if (readOnlyField && readOnlyField.value) {
          return (
            <Form.Item name={domNode?.attribs.name}>
              <Input {...domNode?.attribs} defaultValue={readOnlyField.value} disabled />
            </Form.Item>
          );
        }

        return (
          <Form.Item name={domNode?.attribs.name}>
            <Input {...domNode?.attribs} />
          </Form.Item>
        );
      }

      if (domNode?.tagName === 'textarea') {
        if (readOnlyField && readOnlyField.value) {
          return (
            <Form.Item name={domNode?.attribs.name}>
              <TextArea {...domNode?.attribs} value={readOnlyField.value} disabled />
            </Form.Item>
          );
        }

        return (
          <Form.Item name={domNode?.attribs.name}>
            <TextArea {...domNode?.attribs} />
          </Form.Item>
        );
      }

      return;
    },
  });

  return parsedDynamicForm as JSX.Element;
};

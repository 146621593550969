import styled from 'styled-components';
import { colors, space, typography } from 'ui/elements';
import { Col, Row, Typography } from 'antd';

const { Text } = Typography;

export const StyledSpaceCardRow = styled(Row)`
  margin-top: ${space.normal};
`;

export const StyledMyWorkRow = styled(Row)`
  gap: 2px;
  margin-left: 2px;
`;

export const StyledMyWork = styled(Text)`
  font-size: ${typography.size.hecto};
  font-weight: ${typography.weight.regular};
  color: ${colors.lightOverlayBlue};
`;

export const StyledNumberCard = styled(Text)`
  font-size: ${typography.size.hecto};
  font-weight: ${typography.weight.bolder};
  color: ${colors.primaryColor};
`;

export const StyledColWork = styled(Col)`
  align-self: center;
  padding-bottom: 3px;
`;

export const StyledWorkIcon = styled(Col)`
  margin: auto;
`;

import { useQuery } from 'react-query';
import { QUERIES, PERSIST_QUERY_CONFIG } from '../../constants';
import { eLibrary } from '../ELibrary';

export const useELibrarySectors = () =>
  useQuery(
    [QUERIES.eLibrarySectors],
    async () => {
      const { data } = await eLibrary.getSectors();
      return data;
    },
    {
      ...PERSIST_QUERY_CONFIG,
    }
  );

import { AxiosRequestConfig } from 'axios';
import { ServicesCardsInfoAgg } from 'types';
import {
  Core,
  MY_REQUEST_CARDS,
  MY_TASK_CARDS,
  MY_GROUP_CARDS,
  MY_REQUEST_CARDS_AGGREGATOR,
  MY_TASK_CARDS_AGGREGATOR,
  MY_GROUP_CARDS_AGGREGATOR,
} from 'api';

interface GetMyServicesCards {
  (userId: string, params?: AxiosRequestConfig['params']): Promise<{
    data: ServicesCardsInfoAgg;
  }>;
}

interface GetMyServicesCardsAgg {
  (params?: AxiosRequestConfig['params']): Promise<{
    data: ServicesCardsInfoAgg;
  }>;
}

class ServiceCards extends Core {
  getMyRequestCards: GetMyServicesCards = async (userId: string, params) =>
    this.get(MY_REQUEST_CARDS(userId), { params });
  getMyRequestCardsAgg: GetMyServicesCardsAgg = async (params) =>
    this.get(MY_REQUEST_CARDS_AGGREGATOR(), { params });
  getMyTaskCards: GetMyServicesCards = async (userId: string, params) =>
    this.get(MY_TASK_CARDS(userId), { params });
  getMyTaskCardsAgg: GetMyServicesCardsAgg = async (params) =>
    this.get(MY_TASK_CARDS_AGGREGATOR(), { params });
  getMyGroupTaskCard: GetMyServicesCards = async (userId: string, params) =>
    this.get(MY_GROUP_CARDS(userId), { params });
  getMyGroupTaskCardsAgg: GetMyServicesCardsAgg = async (params) =>
    this.get(MY_GROUP_CARDS_AGGREGATOR(), { params });
}

export const servicesCards = new ServiceCards();

import { Col, Divider, Row } from 'antd';
import React, { FC, memo } from 'react';
import { AssigneeData } from '../LeaveDrawerDetail';
import { AssigneeInfo } from './AssigneeInfo';

interface Props {
  assignees: AssigneeData[];
}

const LeaveAssigneeInfoComponent: FC<Props> = ({ assignees }) => {
  return (
    <React.Fragment>
      <Divider />

      <Row justify={'space-between'} align="middle">
        {assignees.map((item: AssigneeData) => {
          return (
            <Col key={item.key}>
              <AssigneeInfo
                assigneeId={item.assigneeId}
                assigneeValue={item.assigneeValue}
                title={item.title}
              />
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
};

export const LeaveAssigneeInfo = memo(LeaveAssigneeInfoComponent);

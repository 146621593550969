const transformText = (type: string, inputText: string) => {
  if (type) {
    return type.toLowerCase()?.indexOf(inputText?.toLowerCase()) >= 0;
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectFilterOption = (input: string, option: any) => {
  if (option) {
    return (
      transformText(option?.label, input) ||
      transformText(option?.children, input) ||
      transformText(option?.title, input)
    );
  }
  return false;
};

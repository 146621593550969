import { from, of } from 'rxjs';
import { mergeMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../../types';
import { RootState } from '../../reducers';
import {
  GetGroupMembersByLastNameFail,
  GetGroupMembersByLastNameStart,
  GetGroupMembersByLastNameSuccess,
  getGroupMembersByLastNameFail,
  getGroupMembersByLastNameStart,
  getGroupMembersByLastNameSuccess,
} from './groupMebersByLastNameSlice';

export const groupMembersByLastNamEpic: Epic<
  GetGroupMembersByLastNameStart,
  GetGroupMembersByLastNameSuccess | GetGroupMembersByLastNameFail,
  RootState,
  typeof API
> = (action$, _, { groupMembers }) =>
  action$.pipe(
    filter(getGroupMembersByLastNameStart.match),
    mergeMap(({ payload }) =>
      from(groupMembers.getGroupMembersByLastName(payload.params, payload.rootPath)).pipe(
        map(({ data }) => getGroupMembersByLastNameSuccess(data)),
        catchError((error) => of(getGroupMembersByLastNameFail(error?.response?.data)))
      )
    )
  );

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const taskPrettyIdSelector = (state: RootState): RootState['taskPrettyId'] =>
  state.taskPrettyId;

export const taskPrettyIdStateSelector = createSelector(taskPrettyIdSelector, (data) => data);

export const taskPrettyIdItemSelector = (id: string) =>
  createSelector(taskPrettyIdSelector, (state) => state[id] || {});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProcessDefinitionKeysData, RequestCommonError, RequestStatus } from 'types';
import { ProcessDefinitionKeysState } from './processDefinitionKeysTypes';

export const emptyProcessDefinitionKeysInitialState: ProcessDefinitionKeysState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetProcessDefinitionKeysStart = PayloadAction;
export type GetProcessDefinitionKeysSuccess = PayloadAction<ProcessDefinitionKeysData>;
export type GetProcessDefinitionKeysFail = PayloadAction<RequestCommonError>;

const processDefinitionKeys = createSlice({
  name: 'processDefinitionKeys',
  initialState: emptyProcessDefinitionKeysInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getProcessDefinitionKeysStart(state, _: GetProcessDefinitionKeysStart) {
      state.status = RequestStatus.pending;
    },
    getProcessDefinitionKeysSuccess(state, action: GetProcessDefinitionKeysSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getProcessDefinitionKeysFail(state, action: GetProcessDefinitionKeysFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getProcessDefinitionKeysStart,
  getProcessDefinitionKeysSuccess,
  getProcessDefinitionKeysFail,
} = processDefinitionKeys.actions;
export default processDefinitionKeys.reducer;

import { CreateUploadSessionForUserData } from 'types';
import { Core, CREATE_UPLOAD_SESSION_FOR_USER } from 'api';

interface PostCreateUploadSessionForUser {
  (
    folderId: string,
    folder: {
      name: string;
      fileSize: number;
    }
  ): Promise<{ data: CreateUploadSessionForUserData }>;
}

class CreateUploadSessionForUser extends Core {
  uploadFile: PostCreateUploadSessionForUser = async (folderId, folder) =>
    this.post(CREATE_UPLOAD_SESSION_FOR_USER(folderId), folder);
}

export const createUploadSessionForUser = new CreateUploadSessionForUser();

import { Button, Col, Input, Popover, Row, Space, Form } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { TextStyled } from 'ui/elements';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslate } from 'translations';
import { useForm } from 'antd/lib/form/Form';

interface Props {
  onSubmit?(folderName: string, cb?: () => void): void;
  isLoading?: boolean;
  disabled?: boolean;
}

const inputName = 'folderName';

export const OneDriveCreateFolderPopup: FC<Props> = ({
  children,
  onSubmit,
  isLoading = false,
  disabled = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslate();
  const [form] = useForm();

  const onCloseHandler = useCallback(() => {
    setIsVisible(false);
  }, []);
  const onKeyDownHandler = useCallback(
    (e) => {
      if (e.key === 'Escape' && isVisible) {
        setIsVisible(false);
      }
    },
    [setIsVisible, isVisible]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDownHandler);

    return () => {
      document.removeEventListener('keydown', onKeyDownHandler);
    };
  }, [onKeyDownHandler]);

  const onFinish = useCallback(() => {
    if (onSubmit) {
      onSubmit(form.getFieldValue(inputName), () => {
        form.resetFields();
        setIsVisible(false);
      });
    }
  }, [form, onSubmit]);

  const content = (
    <>
      <Row align="middle" gutter={[0, 24]} style={{ marginBottom: 0 }}>
        <Col flex="1">
          <TextStyled strong size={24}>
            {t('oneDrive.myFiles.popup.newFolder')}
          </TextStyled>
        </Col>
        <Col>
          <Button type="text" onClick={onCloseHandler}>
            <CloseOutlined />
          </Button>
        </Col>

        <Col span={24} style={{ paddingBottom: 0 }}>
          <Form onFinish={onFinish} form={form} layout="vertical">
            <Form.Item
              label={
                <TextStyled type="secondary">{t('oneDrive.myFiles.popup.folderName')}</TextStyled>
              }
              name={inputName}
            >
              <Input autoComplete="off" disabled={isLoading} />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Row justify="end">
                <Col>
                  <Space>
                    <Button disabled={isLoading} onClick={onCloseHandler}>
                      {t('oneDrive.myFiles.popup.cancel')}
                    </Button>
                    <Button
                      disabled={disabled}
                      loading={isLoading}
                      type="primary"
                      htmlType="submit"
                    >
                      {t('oneDrive.myFiles.popup.create')}
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );

  return (
    <Popover
      visible={isVisible}
      onVisibleChange={setIsVisible}
      overlayClassName="oneDriveOverlayPopover"
      content={content}
      trigger="click"
    >
      <span>{children}</span>
    </Popover>
  );
};

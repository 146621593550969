import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { RequestCommonError, RequestStatus, RecentFilesData } from 'types';
import { RecentFilesState } from './oneDriveRecentFilesTypes';

export const emptyRecentFilesInitialState: RecentFilesState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetRecentFilesStart = PayloadAction<AxiosRequestConfig['params']>;
export type GetRecentFilesSuccess = PayloadAction<RecentFilesData>;
export type GetRecentFilesFail = PayloadAction<RequestCommonError>;

const recentFiles = createSlice({
  name: 'recentFiles',
  initialState: emptyRecentFilesInitialState,
  reducers: {
    getRecentFilesStart(state, _: GetRecentFilesStart) {
      state.status = RequestStatus.pending;
    },
    getRecentFilesSuccess(state, action: GetRecentFilesSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getRecentFilesFail(state, action: GetRecentFilesFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getRecentFilesStart,
  getRecentFilesSuccess,
  getRecentFilesFail,
} = recentFiles.actions;
export default recentFiles.reducer;

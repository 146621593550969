import { Typography } from 'antd';
import React from 'react';
import { TType } from 'translations';
import { ExpenseCreateInfo, ExpenseTitle } from '../Molecules';

interface ExpenseDrawerHeaderProps {
  expenseDate: string;
  expensePrettyId: string;
  expenseTitle: string;
  expenseType?: string;
  expenseDescription?: string;
  expenseStatus?: string;
  t: TType;
  onPrettyIdClickHandler: (processInstanceId: string) => void;
  processInstanceId: string;
}

const { Text } = Typography;

const ExpenseDrawerHeaderComponent: React.FC<ExpenseDrawerHeaderProps> = ({
  expenseDate,
  expensePrettyId,
  expenseStatus,
  expenseTitle,
  expenseDescription,
  expenseType,
  processInstanceId,
  onPrettyIdClickHandler,
  t,
}) => {
  return (
    <React.Fragment>
      <ExpenseCreateInfo
        date={expenseDate}
        prettyId={expensePrettyId}
        onPrettyIdClickHandler={onPrettyIdClickHandler}
        processInstanceId={processInstanceId}
      />
      <ExpenseTitle
        expenseTitle={expenseTitle}
        expenseSubTitle={expenseType}
        expenseStatus={expenseStatus}
        t={t}
      />
      {expenseDescription ? (
        <Text ellipsis type={'secondary'}>
          {expenseDescription}
        </Text>
      ) : null}
    </React.Fragment>
  );
};

export const ExpenseDrawerHeader = React.memo(ExpenseDrawerHeaderComponent);

import * as API from 'api';
import { RootState as ReducersRootState } from './reducers';
import { StoreCreator } from './store';

export { useDispatch, useSelector } from 'react-redux';
export type RootState = ReducersRootState;
export * from './store';
export * from './profile';
export * from './hierarchy';
export * from './subordinates';
export * from './userAvatar';
export * from './generalInfo';
export * from './attendance';
export * from './teams';
export * from './teamsUserAvatar';
export * from './teamsChannels';
export * from './chats';
export * from './userInfo';
export * from './teamsChannelMembers';
export * from './teamsPosts';
export * from './teamsFilesList';
export * from './teamsChannelFolder';
export * from './users';
export * from './oneDriveShared';
export * from './oneDriveSharedLibraries';
export * from './oneDriveFolderShared';
export * from './oneDriveSharedLibraryListFolder';
export * from './processDefinition';
export * from './processDefinitionKeys';
export * from './task';
export * from './oneDriveSearch';
export * from './oneDriveRootFolder';
export * from './oneDriveItemChildren';
export * from './oneDriveRecentFiles';
export * from './processInstanceInfo';
export * from './taskDetails';
export * from './taskDiagram';
export * from './taskComments';
export * from './taskList';
export * from './reassignTask';
export * from './userGroup';
export * from './processDefinitonList';
export * from './tasksList';
export * from './assigneeUser';
export * from './groupMembers';
export * from './claimTask';
export * from './requestsList';
export * from './groupMembers/groupMebersByLastName';
export * from './groupsTasksList';
export * from './leavesTypes';
export * from './historicActivityInstance';
export * from './ProfileExpense';
export * from './ProfileExpenseAttachment';
export * from './camundaUsers';
export * from './balance';
export * from './leavesHistory';
export * from './leavesList';
export * from './historicTaskList';
export * from './paySlips';
export * from './requestPrettyId';
export * from './taskPrettyId';
export * from './submitForm';
export * from './publicHolidays';
export * from './userStatus';
export * from './userProfile';
export * from './otherUserStatus';
export * from './categoriesTree';
export * from './allRequestCard';
export * from './subCategoryCard';
export * from './categoryCard';
export * from './myRequestCards';
export * from './myTasksCards';
export * from './myGroupTaskCard';
export * from './requestCatalogDrawer';
export * from './paySlipsPdfFile';
export * from './exportAttendance';
export * from './userInfoCard';
export * from './ProfileExpensesLookup';
export * from './userInfoStatus';
export * from './notificationList';
export * from './UsersTable';
export * from './employeeDirectorySearch';

const storeCreator = new StoreCreator(API);
export const { store } = storeCreator.getStore();

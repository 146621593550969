import React from 'react';
import { IProfileAttachment } from 'types';
import { PrivateProfileAttachments } from 'ui';
import {
  PublicProfileStyledItemTitle,
  PublicProfileStyledItemWrapper,
} from '../PublicProfile.styled';

export interface PublicProfileProfessionalCertificateAttachmentsProps {
  title: string;
  attachments: IProfileAttachment[];
}

export const PublicProfileAttachmentItem: React.FC<PublicProfileProfessionalCertificateAttachmentsProps> = ({
  title,
  attachments,
}: PublicProfileProfessionalCertificateAttachmentsProps) => {
  return (
    <PublicProfileStyledItemWrapper>
      <PublicProfileStyledItemTitle>{title}</PublicProfileStyledItemTitle>
      <PrivateProfileAttachments attachments={attachments} withAcknowledge />
    </PublicProfileStyledItemWrapper>
  );
};

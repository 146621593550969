import styled from 'styled-components';
import { Button, Col, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { colors } from 'ui/elements';
import { CustomFilterIcon } from 'assets';

export const StyledBackIcon = styled(ArrowLeftOutlined)`
  margin-right: 10px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: ${colors.primaryColor};
  }
`;

export const StyledPageContainer = styled(Row)`
  padding-top: 64px;
`;

export const StyledFilterHeader = styled(Col)`
  background-color: ${colors.white};
  padding: 5px 25px;
`;
export const StyledFilterButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;
export const StyledFilterButtonIcon = styled(CustomFilterIcon)`
  fill: ${colors.primaryColor};
  width: 14px;
  height: 13px;
  margin-top: 2px;
  margin-right: 8px;
`;

export const StyledTableContainer = styled(Col)`
  margin: 24px;
  padding-bottom: 30px;
  background-color: ${colors.white};
  border-radius: 3px;
  max-width: calc(100% - 48px) !important;
  width: calc(100% - 48px) !important;
`;

import { AxiosPromise } from 'axios';
import { CBARSubmitPostParams, CBARSubmitResponse, CBARtLookups } from 'types';
import {
  CHANGE_BANK_ACCOUNT_REQUEST_LOOKUP,
  Core,
  SUBMIT_CHANGE_BANK_ACCOUNT_REQUEST,
} from '../Core';

interface GetChangeBankAccountRequestLookUp {
  (): AxiosPromise<CBARtLookups>;
}

interface PostSubmitChangeBankAccountRequest {
  (params: CBARSubmitPostParams): AxiosPromise<CBARSubmitResponse>;
}

export class ChangeBankAccountRequest extends Core {
  getLookups: GetChangeBankAccountRequestLookUp = async () =>
    this.get(CHANGE_BANK_ACCOUNT_REQUEST_LOOKUP());
  submitRequest: PostSubmitChangeBankAccountRequest = async (params) =>
    this.post(SUBMIT_CHANGE_BANK_ACCOUNT_REQUEST(), params);
}

export const changeBankAccountRequest = new ChangeBankAccountRequest();

import styled from 'styled-components';
import { Button, Row, Col, Tag as AntTag, Select } from 'antd';
import { PlusCircleFilled, SearchOutlined } from '@ant-design/icons';

import { small, colors, space, borderRadius } from '../../elements';

const { primaryColor, accentPrimary } = colors;

const Tag = styled(AntTag)`
  &.ant-tag {
    color: #0099a8;
    background-color: #dff9fb;
    font-size: inherit;
    border-radius: 30px;
    border: none;
    padding: 2px 8px;
    margin-right: 20px;
  }

  & .ant-tag-close-icon {
    color: #0099a8;
  }
`;

export const FilterTag = styled(Tag)`
  &.ant-tag {
    max-width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    white-space: break-spaces;
    word-break: break-all;

    ${small`
      margin-right: 8px;
    `}
  }
`;

export const PlusCircleIcon = styled(PlusCircleFilled)`
  font-size: 16px;
  margin-right: 8px;
`;

export const ApplyFilter = styled(Button)`
  &.ant-btn {
    background-color: ${primaryColor};
    border-color: ${primaryColor};

    &:hover,
    &:focus {
      background-color: ${accentPrimary};
      border-color: ${accentPrimary};
    }
    border-radius: ${borderRadius.large};
  }
`;

export const FilterFirstRow = styled(Row)`
  width: 100%;
  margin: ${space.moderate} 0;
`;

export const StyledCol = styled(Col)`
  .ant-select-selector {
    width: 150px;
  }
`;

export const ClearFilter = styled(Button)`
  &.ant-btn {
    &:hover,
    &:focus {
      background-color: ${colors.white};
    }
  }
  &.ant-btn-text {
    color: ${colors.lightGrey};

    &:hover {
      color: ${colors.primaryColor};
    }
  }
`;

export const FilterSecondRow = styled(FilterFirstRow)`
  margin: 0 0 ${space.moderate};

  &:empty {
    display: none;
  }
`;

export const StyledSearchIcon = styled(SearchOutlined)`
  color: ${colors.lightGrey};
`;

export const SelectFilter = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${borderRadius.large};
  }
`;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledEmergencyContactLinkContainer = styled(Link)`
  width: 100%;
`;

export const StyledEmergencyContactContainer = styled.div`
  width: 100%;
`;

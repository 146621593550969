import { ProcessInstanceInfoData } from 'types';
import { Core, PROCESS_INSTANCE_INFO } from 'api';
import { AxiosRequestConfig } from 'axios';

interface ProcessInstanceInfo {
  (params: AxiosRequestConfig['params']): Promise<{ data: ProcessInstanceInfoData[] }>;
}

class ProcessInstance extends Core {
  getProcessInstanceInfo: ProcessInstanceInfo = async (params) =>
    this.get(PROCESS_INSTANCE_INFO(), params);
}

export const processInstance = new ProcessInstance();

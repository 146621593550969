import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const categoriesTreeData = (state: RootState): RootState['categoriesTree'] =>
  state.categoriesTree;

export const categoriesTreeSelector = createSelector(
  categoriesTreeData,
  ({ error, status, data }) => ({
    error,
    status,
    data,
  })
);

import React, { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router';
import { Link, useRouteMatch } from 'react-router-dom';
import { Typography, Row, Col } from 'antd';
import { PlusOutlined, Loading3QuartersOutlined, UserOutlined } from '@ant-design/icons';

import { getAvatarSymbols } from 'utils/getAvatarSymbols';
import { MSPlannerPageRoutes } from 'namespace';
import { StyledMenuItem, StyledMenu } from 'ui/components/OneDrive/OneDrive.styled';
import { useTranslate } from 'translations';
import { StyledTeamAvatar } from '../../MSTeams/MSTeamsTeamsTabs/MSTeamsTeamsSidebar/MSTeamsTeamsSidebar.styled';
import {
  SideBar,
  StyledCollapse,
  StyledCategoryRow,
  StyledPlanMenuItem,
} from './MSPlannerSidebar.styled';

const { Panel } = StyledCollapse;
const { Text } = Typography;

export const MSPlannerSidebar = () => {
  const { t } = useTranslate();
  const location = useLocation();
  const { path } = useRouteMatch();
  const getUrl = useCallback((pagePath: string) => `${path}${pagePath}`, [path]);
  const menuItems = useMemo(
    () => [
      {
        link: '#',
        label: t('msplanner.sidebar.newPlan'),
        icon: <PlusOutlined />,
      },
      {
        link: MSPlannerPageRoutes.PlannerHub,
        label: t('msplanner.sidebar.plannerHub'),
        icon: <Loading3QuartersOutlined />,
      },
      {
        link: MSPlannerPageRoutes.MyTasks,
        label: t('msplanner.sidebar.myTasks'),
        icon: <UserOutlined />,
      },
    ],
    [t]
  );

  const favoriteProjects = [
    {
      link: getUrl('/project/1'),
      label: 'Project Tasks',
    },
  ];

  const recentProjects = [
    {
      link: getUrl('/project/2'),
      label: 'Project Tasks',
    },
    {
      link: getUrl('/project/3'),
      label: 'Teams Work',
    },
  ];

  return (
    <SideBar>
      <StyledMenu selectedKeys={[location.pathname]}>
        {menuItems.map((menuItem) => (
          <StyledMenuItem
            className="sidebar-menu-item"
            key={getUrl(menuItem.link)}
            icon={menuItem.icon}
          >
            <Link to={getUrl(menuItem.link)}>{menuItem.label}</Link>
          </StyledMenuItem>
        ))}
        <StyledCollapse ghost defaultActiveKey={['1', '2']}>
          <Panel
            key="1"
            header={<StyledCategoryRow>{t('msplanner.sidebar.favorites')}</StyledCategoryRow>}
          >
            <StyledMenu selectedKeys={[location.pathname]}>
              {favoriteProjects.map(({ link, label }) => (
                <StyledPlanMenuItem key={link}>
                  <Link to={link}>
                    <Row align="middle" gutter={[16, 0]}>
                      <Col>
                        <StyledTeamAvatar id="1">{getAvatarSymbols(label)}</StyledTeamAvatar>
                      </Col>
                      <Col>
                        <Text strong>{label}</Text>
                      </Col>
                    </Row>
                  </Link>
                </StyledPlanMenuItem>
              ))}
            </StyledMenu>
          </Panel>
          <Panel
            key="2"
            header={<StyledCategoryRow>{t('msplanner.sidebar.recent')}</StyledCategoryRow>}
          >
            <StyledMenu selectedKeys={[location.pathname]}>
              {recentProjects.map(({ link, label }) => (
                <StyledPlanMenuItem key={link}>
                  <Link to={link}>
                    <Row align="middle" gutter={[16, 0]}>
                      <Col>
                        <StyledTeamAvatar id="1">{getAvatarSymbols(label)}</StyledTeamAvatar>
                      </Col>
                      <Col>
                        <Text strong>{label}</Text>
                      </Col>
                    </Row>
                  </Link>
                </StyledPlanMenuItem>
              ))}
            </StyledMenu>
          </Panel>
        </StyledCollapse>
      </StyledMenu>
    </SideBar>
  );
};

import { Col, Popconfirm, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useMemo } from 'react';
import { GeneratedStaticForm, GUTTER_16 } from 'routes/Tasks';
import { TType } from 'translations';
import { GroupOfFormItem } from 'types';
import { PRODUCT_INFO_FORM_COL_SPAN, ProductInfoFormWrapper } from 'ui/components';
import { space, VerticalSpace } from 'ui/elements';
import { DeleteOutlined } from '@ant-design/icons';
import {
  HeaderH2,
  HeaderH4,
  StyledFormGroupInnerHeader,
  StyledFormGroupRemoveButton,
} from './FieldsGroup.style';

interface FieldsGroupsProps {
  t: TType;
  getFormFields: () => GroupOfFormItem[];
  form?: FormInstance;
  isShowSubTitle?: boolean;
  hideTitle?: boolean;
  withoutSpace?: boolean;
  fullWidth?: boolean;
  isRemoveButtonEnabled?: boolean;
  remove?: (index: number) => void;
}

export const FieldsGroupComponent: React.FC<FieldsGroupsProps> = ({
  t,
  getFormFields,
  form,
  isShowSubTitle,
  hideTitle,
  withoutSpace,
  fullWidth,
  isRemoveButtonEnabled = false,
  remove,
}) => {
  const formFields = useMemo(() => {
    return getFormFields();
  }, [getFormFields]);
  return (
    <React.Fragment>
      {formFields.map((groupFields) => {
        return Object.entries(groupFields).map(([groupKey, groupFields], index) => {
          return (
            <ProductInfoFormWrapper key={index} $fullWidth={fullWidth}>
              <VerticalSpace size={withoutSpace ? undefined : parseInt(space.moderate)}>
                <Row gutter={withoutSpace ? undefined : GUTTER_16} align={'middle'}>
                  <Col span={PRODUCT_INFO_FORM_COL_SPAN}>
                    <StyledFormGroupInnerHeader>
                      {!hideTitle ? (
                        isShowSubTitle ? (
                          <HeaderH4>{groupKey}</HeaderH4>
                        ) : (
                          <HeaderH2>{groupKey}</HeaderH2>
                        )
                      ) : null}
                      {isRemoveButtonEnabled && (
                        <Popconfirm
                          title={t('removeRecord.confirmation')}
                          onConfirm={() => remove && remove(index)}
                          okText={t('removeRecord.Ok')}
                          cancelText={t('removeRecord.Cancel')}
                        >
                          <StyledFormGroupRemoveButton type="text" icon={<DeleteOutlined />}>
                            {t('nonPo.request.remove')}
                          </StyledFormGroupRemoveButton>
                        </Popconfirm>
                      )}
                    </StyledFormGroupInnerHeader>
                  </Col>
                  <Col span={PRODUCT_INFO_FORM_COL_SPAN}>
                    <GeneratedStaticForm t={t} items={groupFields} formState={form} />
                  </Col>
                </Row>
              </VerticalSpace>
            </ProductInfoFormWrapper>
          );
        });
      })}
    </React.Fragment>
  );
};

export const StaticFieldsGroup = React.memo(FieldsGroupComponent);

import { useQuery } from 'react-query';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';
import { attendanceInfo } from '../Attendance';

export const useMyTeamAttendance = (selectedDate: string) => {
  return useQuery(
    [QUERIES.myTeamAttendance],
    async () => {
      const { data } = await attendanceInfo.getMyTeamAttendance(selectedDate);
      return data;
    },
    {
      enabled: false,
      ...PERSIST_QUERY_CONFIG,
    }
  );
};

import { useMutation } from 'react-query';
import {
  LeaseSitesFieldRunnerRequestDraftData,
  FieldRunnerCandidate,
  LeaseSitesFieldRunnerResponse,
} from 'types';
import { leaseSites } from '../LeaseSites';

const mapDraftFormValues = (formValues: LeaseSitesFieldRunnerRequestDraftData) => {
  const candidatesAttachments: (unknown[] | undefined)[] = [];
  const candidatesValues: Partial<FieldRunnerCandidate>[] = [];
  formValues.fieldRunnerCandidates?.forEach((candidate, index) => {
    if (candidate) {
      const { attachments, ...restValues } = candidate;
      // TODO: for Nsr to check if commented code is needed
      // candidatesAttachments[index] = attachments;
      candidatesValues[index] = {
        ...restValues,
        candidateNumber: restValues?.candidateNumber ?? index + 1,
      };
    }
  });

  return { attachments: candidatesAttachments, restFormValues: candidatesValues };
};

export const useLeaseSitesRequestSaveCandidatesDraft = () => {
  return useMutation<
    LeaseSitesFieldRunnerResponse,
    unknown,
    { processInstanceId: string; formValues: LeaseSitesFieldRunnerRequestDraftData },
    unknown
  >(async (draftInfo) => {
    const { restFormValues } = mapDraftFormValues(draftInfo.formValues);
    const { data } = await leaseSites.postRequestsFieldRunnerDraft(draftInfo.processInstanceId, {
      ...draftInfo.formValues,
      fieldRunnerCandidates: restFormValues,
    });
    return data;
  });
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { GroupMember, RequestCommonError, RequestStatus } from 'types';
import { GroupMembersState } from './groupMembersTypes';

export const emptyGroupMembers: GroupMembersState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetGroupMembersStart = PayloadAction<{
  params: AxiosRequestConfig['params'];
  rootPath?: string;
}>;
export type GetGroupMembersSuccess = PayloadAction<GroupMember[]>;
export type GetGroupMembersFail = PayloadAction<RequestCommonError>;

const groupMembers = createSlice({
  name: 'groupMembers',
  initialState: emptyGroupMembers,
  reducers: {
    getGroupMembersStart(state, _: GetGroupMembersStart) {
      state.status = RequestStatus.pending;
    },
    getGroupMembersSuccess(state, action: GetGroupMembersSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getGroupMembersFail(state, action: GetGroupMembersFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getGroupMembersFail,
  getGroupMembersStart,
  getGroupMembersSuccess,
} = groupMembers.actions;
export default groupMembers.reducer;

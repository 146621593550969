import { AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';
import { jiraRequestsCenter } from '../Jira';

export const useJiraCreateComment = (taskId: string) => {
  return useMutation(async (formData: AxiosRequestConfig['data']) => {
    const { data } = await jiraRequestsCenter.postJiraComment(taskId, formData);
    return data;
  });
};

import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Row, Col, Divider, Form, Empty } from 'antd';
import { TitleH4, useErrorNotification, useRowGutter, useSuccessNotification } from 'ui';
import { FullWidthSpin, span } from 'ui/elements';
import { NotificationSetting } from 'types';
import { useNotificationSettings, useUpdateNotificationSettings } from 'api/Settings';
import { useTranslate } from 'translations';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useForm } from 'antd/lib/form/Form';
import {
  StyledH3Title,
  StyledNotificationsList,
  StyledCheckBox,
  StyledRow,
  StyledFlexCol,
} from './NotificationForm.styled';
import { NotificationCategory } from './NotificationCategory';
import { NotificationActionButton } from './NotificationActionButton';

const HIDE_SMS_EMAIL = process.env.REACT_APP_DISABLE_NOTIFICATION_SETTING_SMS_EMAIL !== 'true';

export const NotificationFormComponent: FC = () => {
  const [form] = useForm();
  const { t } = useTranslate();
  const {
    data: notificationSettingData,
    isLoading,
    isError: isNotificationSettingError,
  } = useNotificationSettings();
  const {
    mutate: mutuateNotificationSettings,
    isLoading: isMutuateNotificationLoading,
    isSuccess: isMutuateNotificationSuccess,
  } = useUpdateNotificationSettings();
  const [notificationData, setNotificationData] = useState<NotificationSetting>();
  const GUTTER_16 = useRowGutter(16);
  const { span16, span8, span14, span4, span9, span24 } = span;
  const turnOffN = 'turnOffN';

  useEffect(() => {
    if (notificationSettingData) {
      setNotificationData(notificationSettingData);
    }
  }, [notificationSettingData]);

  useErrorNotification(
    {
      isNotification: isNotificationSettingError,
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    },
    [isNotificationSettingError]
  );

  useSuccessNotification(
    {
      isNotification: isMutuateNotificationSuccess,
      message: t('messages.success'),
      description: t('messages.success.requestCreated'),
    },
    [isMutuateNotificationSuccess]
  );

  const handleChange = useCallback((event: CheckboxChangeEvent, index: number, itemId: number) => {
    const { name, checked } = event.target;
    if (name) {
      setNotificationData((prev) => {
        if (prev) {
          const notificationMatch = prev.notificationSetting[index].userSetting.find(
            ({ id }) => itemId === id
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as any;
          notificationMatch[name] = checked;
          return { ...prev };
        }
      });
    }
  }, []);

  const handleTurnOffAllNotifications = useCallback((e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    setNotificationData((prev) => {
      if (prev) {
        prev.turnOffNotification = checked;
        return { ...prev };
      }
    });
  }, []);

  const handleUpdateNotification = useCallback(() => {
    if (notificationData) {
      mutuateNotificationSettings(notificationData);
    }
  }, [notificationData, mutuateNotificationSettings]);

  return (
    <>
      {isLoading ? (
        <FullWidthSpin />
      ) : isNotificationSettingError ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
      ) : (
        <>
          <Form form={form}>
            <Row>
              <Col xl={span16} xs={span24}>
                <Row gutter={GUTTER_16}>
                  <Col span={span8}>
                    <StyledH3Title>{t('settings.notification.turnOff.title')}</StyledH3Title>
                  </Col>
                  <Col span={span9}>
                    <TitleH4> {t('settings.notification.turnOff.label')} </TitleH4>
                  </Col>
                  <StyledFlexCol span={span4}>
                    <StyledCheckBox
                      checked={notificationData?.turnOffNotification}
                      name={turnOffN}
                      onChange={handleTurnOffAllNotifications}
                    />
                  </StyledFlexCol>
                </Row>
                <Divider />
                <StyledNotificationsList isDisable={notificationData?.turnOffNotification}>
                  <StyledRow>
                    <Col offset={span8} span={span14}>
                      <Row>
                        <Col offset={span16} span={span8}>
                          <Row justify="space-between">
                            {HIDE_SMS_EMAIL && (
                              <>
                                <Col>
                                  <TitleH4>{t('settings.notification.Mobile')}</TitleH4>
                                </Col>
                                <Col>
                                  <TitleH4>{t('settings.notification.SMS')}</TitleH4>
                                </Col>
                              </>
                            )}

                            <Col>
                              <TitleH4>{t('settings.notification.Email')}</TitleH4>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </StyledRow>
                  {notificationData && (
                    // TODO:Test this
                    <NotificationCategory
                      notificationSetting={notificationData?.notificationSetting}
                      handleChange={handleChange}
                      turnOffNotification={notificationData?.turnOffNotification}
                    />
                  )}
                </StyledNotificationsList>
              </Col>
            </Row>
          </Form>
          <NotificationActionButton
            t={t}
            handleUpdateNotification={handleUpdateNotification}
            isMutuateNotificationLoading={isMutuateNotificationLoading}
          />
        </>
      )}
    </>
  );
};

export const NotificationForm = memo(NotificationFormComponent);

import styled from 'styled-components';
import { Typography, Col, Collapse, Card, Row } from 'antd';
import { colors, space } from 'ui/elements';

const { Text } = Typography;

export const StyledCardValue = styled(Text)`
  font-size: 28px;
  color: ${colors.primaryColor};
  width: 100%;
`;

export const StyledCol = styled(Col)`
  text-align: center;
`;

export const StyledCardLabel = styled(Text)`
  font-size: 16px;
  color: ${colors.textColorSecondary};
  margin-left: ${space.slim};
`;

export const StyledTitle = styled(Text)`
  font-size: 16px;
  color: ${colors.primaryColor};
  text-decoration: none;
`;

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    left: -20px;
  }
`;
export const StyledCardInfo = styled(Card)`
  border-radius: 5px;
`;
export const StyledMoreDetailTitleContainer = styled(Col)`
  margin-top: 24px;
  margin-bottom: 12px;
  padding-bottom: 1px;
  display: inline-block;
  border-bottom: 1px dashed ${colors.textColorSecondary};
`;
export const StyledMoreDetailContainer = styled(Row)`
  padding-top: 24px;
  padding-bottom: 24px;
`;
export const StyledRowFieldsInCardContainer = styled(Row)`
  margin-top: 20px;
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { RequestCommonError, RequestStatus } from 'types';
import { ExportAttendanceState } from './exportAttendanceTypes';

export const emptyExportAttendanceInitialState: ExportAttendanceState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetExportAttendanceStart = PayloadAction<{
  hcmPersonId: string;
  data: {
    responseType: string;
    params: AxiosRequestConfig['params'];
  };
}>;
export type GetExportAttendanceSuccess = PayloadAction<string>;
export type GetExportAttendanceFail = PayloadAction<RequestCommonError>;

const exportAttendance = createSlice({
  name: 'exportAttendance',
  initialState: emptyExportAttendanceInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getExportAttendanceStart(state, _: GetExportAttendanceStart) {
      state.status = RequestStatus.pending;
    },
    getExportAttendanceSuccess(state, action: GetExportAttendanceSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getExportAttendanceFail(state, action: GetExportAttendanceFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getExportAttendanceStart,
  getExportAttendanceSuccess,
  getExportAttendanceFail,
} = exportAttendance.actions;
export default exportAttendance.reducer;

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';
import * as API from 'api';
import { RootState } from 'store';
import { Epic } from '../types';
import {
  employeeDirectorySearchFail,
  employeeDirectorySearchStart,
  employeeDirectorySearchSuccess,
  EmployeeDirectorySearchFail,
  EmployeeDirectorySearchStart,
  EmployeeDirectorySearchSuccess,
} from './employeeDirectorySearchSlice';

export const employeeDirectorySearchEpics: Epic<
  EmployeeDirectorySearchStart,
  EmployeeDirectorySearchSuccess | EmployeeDirectorySearchFail,
  RootState,
  typeof API
> = (action$, _, { employeeDirectory }) =>
  action$.pipe(
    filter(employeeDirectorySearchStart.match),
    exhaustMap((action) =>
      from(employeeDirectory.searchForEmployees(action.payload.params)).pipe(
        map(({ data }) => employeeDirectorySearchSuccess(data)),
        catchError((error) => of(employeeDirectorySearchFail(error?.response?.data)))
      )
    )
  );

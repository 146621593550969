import React, { FC, useCallback } from 'react';
import { FormInstance } from 'antd/lib/form';
import { TType } from 'translations';
import { CreateFormItemType, SelectOptions } from 'types';
import {
  FORM_ITEM_REQUIRED_RULE,
  FORM_ITEM_20_CHARACTER_RULES,
  numberPatternRule,
  englishPatternRule,
  FORM_ITEM_MOBILE_NUMBER_WITHOUT_COUNTRY_CODE_RULES,
} from 'utils';
import { WRAPPER_COL_SPAN_SIZE_21 } from 'ui/components';
import { Select } from 'antd';
import { CustomFormGroupWrapper } from './CustomFormGroupWrapper';
import { StyledPhoneCodeFormItem, StyledPhoneCodeSelect } from './CustomFormGroup.styled';

const { Option, OptGroup } = Select;

type Props = {
  t: TType;
  form?: FormInstance;
  hasInitialValues: boolean;
  relationshipLookups: SelectOptions;
  phoneCodeLookups: SelectOptions;
};

export const ProfileUpdateEmergencyContactFormGroup: FC<Props> = ({
  t,
  form,
  hasInitialValues,
  relationshipLookups,
  phoneCodeLookups,
}) => {
  const phoneCodeSelect = useCallback(
    (formListName: number) => (
      <StyledPhoneCodeFormItem
        initialValue={phoneCodeLookups[0].value}
        name={[formListName, 'phoneCountryCode']}
        rules={FORM_ITEM_REQUIRED_RULE}
        id="4"
      >
        <StyledPhoneCodeSelect
          dropdownMatchSelectWidth={false}
          showSearch
          showArrow
          filterOption={(input, option) =>
            ((option?.label as string) || option?.children || '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {phoneCodeLookups.map(({ label, value }, index) => {
            const [phoneCode, countryName] = label.split('-');
            return (
              <OptGroup label={countryName} key={`phone-code-${countryName}-${index}`}>
                <Option key={`phone-code-${value}-${index}`} value={value}>
                  {phoneCode}
                </Option>
              </OptGroup>
            );
          })}
        </StyledPhoneCodeSelect>
      </StyledPhoneCodeFormItem>
    ),
    [phoneCodeLookups]
  );

  const emergencyContactFormGroup = useCallback<(formListName: number) => CreateFormItemType[]>(
    (formListName) => {
      return [
        {
          id: `${0}`,
          label: 'First name',
          name: [formListName, 'firstName'],
          type: 'input',
          rules: [
            ...FORM_ITEM_REQUIRED_RULE,
            ...FORM_ITEM_20_CHARACTER_RULES,
            englishPatternRule(t),
          ],
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Enter first name',
        },
        {
          id: `${1}`,
          name: [formListName, 'erpPersonNumber'],
          type: 'input',
          hidden: true,
        },
        {
          id: `${2}`,
          label: 'Family name',
          name: [formListName, 'familyName'],
          type: 'input',
          rules: [
            ...FORM_ITEM_REQUIRED_RULE,
            ...FORM_ITEM_20_CHARACTER_RULES,
            englishPatternRule(t),
          ],
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Enter family name',
        },
        {
          id: `${3}`,
          label: 'Relationship',
          name: [formListName, 'relationship'],
          type: 'select',
          options: relationshipLookups,
          selectTheValueAsKey: true,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Choose relationship',
        },
        {
          id: `${4}`,
          label: 'Mobile number',
          name: [formListName, 'mobileNumber'],
          type: 'input',
          rules: [
            ...FORM_ITEM_REQUIRED_RULE,
            ...FORM_ITEM_MOBILE_NUMBER_WITHOUT_COUNTRY_CODE_RULES,
            numberPatternRule(t),
          ],
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Enter mobile number',
          addonBefore: phoneCodeSelect(formListName),
        },
      ];
    },
    [relationshipLookups, phoneCodeSelect, t]
  );
  return (
    <CustomFormGroupWrapper
      t={t}
      maxRecords={5}
      formGroupItems={emergencyContactFormGroup}
      formListName="emergencyContacts"
      sectionTitle="Emergency Contact Details"
      addButtonTitle="Add additional emergency contacts"
      hasAtLeastOne={true}
      hasInitialValue={hasInitialValues}
      form={form}
    />
  );
};

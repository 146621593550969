import styled from 'styled-components';
import { Card } from 'antd';

export const CenteredCard = styled(Card)`
  height: 100%;
  min-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

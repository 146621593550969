import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const teamsChannelMembers = (state: RootState): RootState['teamsChannelMembers'] =>
  state.teamsChannelMembers;

export const currentTeamIdAndChannelId = (
  _: RootState,
  props: {
    teamId: string;
    channelId: string;
  }
) => ({
  teamId: props.teamId,
  channelId: props.channelId,
});

export const getCurrentTeamsChannelMembersData = createSelector(
  [teamsChannelMembers, currentTeamIdAndChannelId],
  (channels, { teamId, channelId }) => (channels[teamId] ? channels[teamId][channelId] : undefined)
);

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const employeeDirectorySearch = (state: RootState): RootState['employeeDirectorySearch'] =>
  state.employeeDirectorySearch;

export const employeeDirectorySearchSelector = createSelector(
  employeeDirectorySearch,
  ({ error, data, status }) => ({
    error,
    status,
    data,
  })
);

import React, { FC, useCallback } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  StyledDeleteAttachment,
  StyledUploadCommentAttachment,
  StyledUploadCommentAttachmentName,
} from '../TaskCommentsDrawer';

interface UploadAttachmentProps {
  attachment: UploadFile;
  onRemove: (file: UploadFile) => void;
}

export const UploadAttachment: FC<UploadAttachmentProps> = ({ attachment, onRemove }) => {
  const onAttachmentRemove = useCallback(() => {
    onRemove(attachment);
  }, [attachment, onRemove]);

  return (
    <StyledUploadCommentAttachment>
      <StyledUploadCommentAttachmentName>{attachment.name}</StyledUploadCommentAttachmentName>
      <StyledDeleteAttachment onClick={onAttachmentRemove} />
    </StyledUploadCommentAttachment>
  );
};

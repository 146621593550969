import { connect, ConnectedProps } from 'react-redux';

import {
  RootState,
  getOneDriveSharedStart,
  sharedFileData,
  oneDriveFolderSharedData,
  getOneDriveFolderSharedStart,
} from 'store';

const mapDispatch = { getOneDriveSharedStart, getOneDriveFolderSharedStart };

const mapState = (state: RootState) => ({
  sharedFile: sharedFileData(state),
  folderChildren: oneDriveFolderSharedData(state),
});

export const oneDriveSharedConnector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof oneDriveSharedConnector>;

export type ConnectorProps = PropsFromRedux;

import React, { FC } from 'react';
import { TreeNode } from 'react-organizational-chart';
import { TType } from 'translations';
import { HierarchyData } from 'types';
import { EmployeeCard } from './EmployeeCard';

interface OrganizationalChartNodeProps {
  t: TType;
  node?: HierarchyData;
}

const OrganizationalChartNodeComponent: FC<OrganizationalChartNodeProps> = ({ t, node }) => {
  return (
    <TreeNode
      key={node?.id}
      label={
        node && (
          <EmployeeCard
            t={t}
            id={node?.adOid}
            displayName={node?.displayName}
            title={node?.title}
            email={node?.email}
          />
        )
      }
    >
      {node?.subordinates &&
        node?.subordinates?.length > 0 &&
        node?.subordinates.map((child: HierarchyData, index: number) => {
          return <OrganizationalChartNodeComponent key={index} t={t} node={child} />;
        })}
    </TreeNode>
  );
};

export const OrganizationalChartNode = OrganizationalChartNodeComponent;

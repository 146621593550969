import { UserInfoStatus } from 'types';
import { Core, PROFILE_INFO_STATUS } from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetUserStatus {
  (data: { params: AxiosRequestConfig['params'] }): Promise<{ data: UserInfoStatus }>;
}

class UserInfoCardStatus extends Core {
  getUserStatus: GetUserStatus = async (data) => this.get(PROFILE_INFO_STATUS(), data);
}

export const userInfoCardStatus = new UserInfoCardStatus();

import { Col, Form, notification, Space } from 'antd';
import { PagesUrls } from 'namespace';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { TType } from 'translations';
import { task as taskApi } from 'api';
import { GeneratedButton } from 'ui/components/GeneratedForm';
import { FormField, TaskDefinitionType } from 'types';
import { useServicesContext } from 'app';
import { Forms } from '../TaskSteps/TaskSteps.types';
import { convertToVariables, filterEmptyFields } from '../TaskSteps/helper';

type FormData = FormField[];

interface TaskActionButtonProps {
  taskName: string;
  allTasksFormInstances: Forms | null;
  setAllTasksFormInstances: React.Dispatch<React.SetStateAction<Forms | null>>;
  t: TType;
  setFormLoading: React.Dispatch<React.SetStateAction<boolean>>;
  reassignCallback?: () => void;
  formFields: FormData;
  processInstanceId: string;
  taskId: string | undefined;
  getUrl: (pagePath: string) => string;
  setIsClickOnCard?: (value: boolean) => void;
  rootPath?: string;
  processDefinitionKey?: string;
  taskDefinitionKey?: string;
}

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

const TaskCardActionButtonComponent: React.FC<TaskActionButtonProps> = ({
  taskName,
  allTasksFormInstances,
  setAllTasksFormInstances,
  t,
  setFormLoading,
  reassignCallback,
  formFields,
  taskId,
  processInstanceId,
  getUrl,
  setIsClickOnCard,
  rootPath,
  processDefinitionKey,
  taskDefinitionKey,
}) => {
  const { dependenciesFieldsBetweenForms } = useServicesContext();
  const history = useHistory();
  return (
    <Col>
      <Space size={2}>
        <Form.Provider
          onFormChange={(values, changes) => {
            const tasksForms = Object.fromEntries(
              Object.entries(changes.forms).filter(([formName]) => formName === taskName)
            );

            if (allTasksFormInstances === null) {
              setAllTasksFormInstances(tasksForms as Forms);

              return;
            }

            const onlyNewForms = Object.entries(tasksForms).filter(
              ([formName]) => !allTasksFormInstances[formName]
            );

            if (onlyNewForms.length) {
              setAllTasksFormInstances({
                ...allTasksFormInstances,
                ...(Object.fromEntries(onlyNewForms) as Forms),
              });
            }
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onFormFinish={async (formName: string, info: { values: any; forms: any }) => {
            const formValues = Object.keys(info.values);
            const rejectFeedBack = formValues.filter((key) => key.includes('rejected'));
            const isClickOnRejectedBtn = info.values[rejectFeedBack[0]] === true;

            if (formName === taskName) {
              if (isClickOnRejectedBtn) {
                notification.error({
                  message: t('title.error.feedbackRequire'),
                  description: t('messages.error.fillFeedbackField'),
                });
                if (setIsClickOnCard) {
                  setIsClickOnCard(true);
                }
                history.push(getUrl(`/${processInstanceId}`));
              } else if (
                !isClickOnRejectedBtn &&
                dependenciesFieldsBetweenForms &&
                dependenciesFieldsBetweenForms.isThereARequiredFields
              ) {
                notification.error({
                  message: t('messages.error.fillForm'),
                  description: t('messages.error.fillForm'),
                });
              } else if (taskDefinitionKey === TaskDefinitionType.NON_PO_BUDGET_TEAM_FORM_TASK) {
                notification.error({
                  message: t('messages.error.fillForm'),
                  description: t('messages.error.fillForm'),
                });
                history.push(getUrl(`/${processInstanceId}`));
              } else {
                if (taskId) {
                  try {
                    setFormLoading(true);
                    await Promise.all([
                      disableAggregator
                        ? taskApi.postTask(
                            taskId,
                            {
                              variables: convertToVariables(filterEmptyFields(info.values)),
                            },
                            rootPath
                          )
                        : taskApi.postTaskApproval(
                            taskId,
                            {
                              variables: convertToVariables(filterEmptyFields(info.values)),
                            },
                            rootPath
                          ),
                    ]);
                  } catch {
                    notification.error({
                      message: t('messages.error.wentWrong'),
                      description: t('messages.error.fillForm'),
                    });
                    history.push(getUrl(`/${processInstanceId}`));
                    return;
                  } finally {
                    setFormLoading(false);
                  }
                }
                notification.success({
                  message: t('messages.success'),
                  description: t('messages.success.task.completed'),
                });

                if (reassignCallback) {
                  reassignCallback();
                }
                history.push(PagesUrls.MyWork);
              }
            }
          }}
        >
          <GeneratedButton
            formData={formFields}
            formName={taskName}
            inCard
            taskId={taskId}
            processDefinitionKey={processDefinitionKey}
            processId={processInstanceId}
          />
        </Form.Provider>
      </Space>
    </Col>
  );
};

export const TaskCardActionButton = React.memo(TaskCardActionButtonComponent);

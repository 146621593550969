import { AttendanceInfoData, MyTeamAttendanceData } from 'types';
import {
  Core,
  ATTENDANCE_TIME_CARD,
  EXPORT_ATTENDANCE,
  ATTENDANCE_LIST,
  MY_TEAM_ATTENDANCE,
} from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetAttendanceInfo {
  (hcmPersonId: string, params?: AxiosRequestConfig['params']): Promise<{
    data: AttendanceInfoData;
  }>;
}

interface GetExportAttendance {
  (
    hcmPersonId: string,
    data: {
      responseType: string;
      params: AxiosRequestConfig['params'];
    }
  ): Promise<{ data: string }>;
}

interface GetMyTeamAttendance {
  (selectedDate: string): Promise<{ data: MyTeamAttendanceData[] }>;
}

interface GetAttendanceList {
  (hcmPersonId: string, startDate: string, endDate: string): Promise<{
    data: AttendanceInfoData[];
  }>;
}

class AttendanceInfo extends Core {
  getAttendanceInfo: GetAttendanceInfo = async (hcmPersonId, params) =>
    this.get(ATTENDANCE_TIME_CARD(hcmPersonId), params);
  getExportAttendance: GetExportAttendance = async (hcmPersonId, data) =>
    this.get(EXPORT_ATTENDANCE(hcmPersonId), data);
  getAttendanceList: GetAttendanceList = async (hcmPersonId, startDate, endDate) =>
    this.get(ATTENDANCE_LIST(hcmPersonId, startDate, endDate));
  getMyTeamAttendance: GetMyTeamAttendance = async (selectedDate) =>
    this.get(MY_TEAM_ATTENDANCE(selectedDate));
}

export const attendanceInfo = new AttendanceInfo();

import { createSelector } from '@reduxjs/toolkit';

import { RequestStatus } from 'types';
import { RootState } from '../reducers';

export const profile = (state: RootState): RootState['profile'] => state.profile;

export const profileData = createSelector(profile, ({ error, status, data }) => ({
  error,
  status,
  data,
}));
export const getAvatarColor = createSelector(profile, ({ data }) => data?.color);

export const getCurrentUserHcmId = createSelector(profileData, ({ data }) => data?.hcmId);

export const getCurrentUserAdoid = createSelector(profileData, ({ data }) => data?.adOid);

export const getUserEmail = createSelector(profileData, ({ data }) => data?.email);

export const getUserName = createSelector(
  profileData,
  ({ data }) => `${data?.firstName || ''} ${data?.lastName || ''}`
);

export const getUserFirstName = createSelector(
  profileData,
  ({ data }) => `${data?.firstName || ''}!`
);
export const getUserDepartment = createSelector(profileData, ({ data }) => data?.department);

export const getUserMangerHcmId = createSelector(profileData, ({ data }) => data?.managerPersonId);
export const getUserMangerEmail = createSelector(profileData, ({ data }) => data?.managerEmail);

export const getHasUserProfileData = createSelector(
  profileData,
  ({ status, data }) => status === RequestStatus.resolved && !!data
);

export const isContractorEmployee = createSelector(
  profileData,
  ({ data }) => !data?.isInternalEmployee
);

export const getUserProfileDataNotReady = createSelector(
  profileData,
  ({ status }) => status === RequestStatus.idle || status === RequestStatus.pending
);

export const getUserCurrentLocation = createSelector(profileData, ({ data }) => data?.location);

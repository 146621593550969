import styled from 'styled-components';
import { Badge, Card, Typography } from 'antd';
import { colors } from 'ui/elements';
import { EditPin } from 'assets';

const { Paragraph, Title } = Typography;

export const StyledProfileUserInfoCardWrapper = styled(Card)`
  width: 100%;
  border-radius: 5px;

  .ant-card-body {
    padding: 35px 24px;
  }
`;

export const StyledProfileUserInfoCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledProfileUserInfoCardBasicInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledProfileUserInfoCardUserName = styled(Title)`
  font-size: 20px !important;
  text-align: center;
  font-weight: 500;
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  color: ${colors.primaryColor};
`;

export const StyledProfileUserInfoCardEditNickName = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const StyledProfileUserInfoCardNickname = styled(Paragraph)<{ $isEmpty?: boolean }>`
  font-size: 15px !important;
  font-weight: 400;
  font-style: italic;
  margin: 0 5px 10px 5px !important;
  color: ${colors.labelGrey};

  strong {
    font-weight: 400;
    &:before {
      content: ${({ $isEmpty }) => ($isEmpty ? 'open-quote "Add Nickname"' : 'open-quote')};
    }

    &:after {
      content: close-quote;
    }
  }
`;

export const StyledProfileUserInfoCardNicknameEditIcon = styled(EditPin)`
  width: 14px;
  height: 14px;
  fill: ${colors.primaryColor};
  cursor: pointer;
`;

export const StyledProfileUserInfoCardContactsContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
`;

export const StyledProfileUserInfoCardContactLink = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  color: ${colors.textColorSecondary};
`;

export const StyledProfileUserInfoCardAnniversaryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
  padding: 14px;
  background-color: #fbecdb;
  border-radius: 5px;
`;

export const StyledProfileUserInfoCardLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

export const StyledProfileUserInfoCardAnnouncementText = styled(Paragraph)<{
  fontWeight?: number;
  fontSize?: number;
}>`
  font-size: ${({ fontSize }) => fontSize ?? 14}px;
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  color: ${colors.primaryColor};
  margin: 0 !important;
`;

export const StyledProfileUserInfoCardDelegationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 14px;
  background-color: #fbecdb;
  border-radius: 5px;
  margin-top: 14px;
`;

export const StyledProfileUserInfoCardDelegateWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 14px;
  gap: 10px;
`;
export const StyledProfileUserInfoCardDelegate = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

export const StyledProfileUserInfoCardDelegateLabel = styled(Paragraph)`
  margin-bottom: 10px !important;
  font-size: 12px;
  font-weight: 400;
  color: ${colors.textColorSecondary};
`;

export const StyledProfileUserInfoCardDelegateName = styled(Paragraph)`
  margin: 0 !important;
  font-size: 13px;
  font-weight: 400;
  color: ${colors.primaryColor};
`;

export const StyledProfileUserInfoCardDelegateAvatar = styled.div`
  flex: 0 0 30px;
`;

export const StyledStatusBadge = styled(Badge)`
  margin-bottom: 20px;
  .ant-badge-status-text {
    font-size: 14px;
    font-weight: normal;
    color: ${colors.lightGrey};
  }

  .ant-badge-status-dot {
    width: 12px;
    height: 12px;
  }
`;

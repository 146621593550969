import { useQuery } from 'react-query';
import { leaseSites } from '../LeaseSites';
import { QUERIES } from '../../constants';
import { mapLovToOptions } from './helpers';

export const useLeaseBankBranches = (bankId = '') =>
  useQuery(
    [QUERIES.leaseBankBranches(bankId)],
    async () => {
      const { data } = await leaseSites.getBankBranches(bankId);
      return mapLovToOptions(data);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!bankId,
    }
  );

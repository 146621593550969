import { ChatMessagesData, ChatMessageData, ChannelRepliesData } from 'types';
import {
  Core,
  TEAMS_CHANNEL_MESSAGES,
  SEND_TEAMS_CHANNEL_MESSAGE,
  TEAMS_CHANNEL_REPLIES,
  SEND_TEAMS_CHANNEL_REPLY,
} from '../Core';

interface GetTeamsChannelMessages {
  ({ teamId, channelId }: { teamId: string; channelId: string }, skipToken?: string): Promise<{
    data: ChatMessagesData;
  }>;
}
interface SendTeamsChannelMessage {
  (teamId: string, channelId: string, values: { body: { content: string } }): Promise<{
    data: ChatMessageData;
  }>;
}
interface GetTeamsChannelReplies {
  ({
    teamId,
    channelId,
    messageId,
    skipToken,
  }: {
    teamId: string;
    channelId: string;
    messageId: string;
    skipToken?: string;
  }): Promise<{ data: ChannelRepliesData }>;
}
interface SendTeamsChannelReply {
  (
    teamId: string,
    channelId: string,
    messageId: string,
    values: { body: { content: string } }
  ): Promise<{
    data: ChatMessageData;
  }>;
}

class TeamsPosts extends Core {
  getTeamsChannelMessages: GetTeamsChannelMessages = async ({ teamId, channelId }, skipToken) =>
    this.get(TEAMS_CHANNEL_MESSAGES(teamId, channelId), {
      ...(skipToken ? { headers: { skipToken } } : null),
    });
  sendTeamsChannelMessage: SendTeamsChannelMessage = async (teamId, channelId, values) =>
    this.post(SEND_TEAMS_CHANNEL_MESSAGE(teamId, channelId), values);
  getTeamsChannelReplies: GetTeamsChannelReplies = async ({
    teamId,
    channelId,
    messageId,
    skipToken,
  }) =>
    this.get(TEAMS_CHANNEL_REPLIES(teamId, channelId, messageId), {
      ...(skipToken ? { headers: { skipToken } } : null),
    });
  sendTeamsChannelReply: SendTeamsChannelReply = async (teamId, channelId, messageId, values) =>
    this.post(SEND_TEAMS_CHANNEL_REPLY(teamId, channelId, messageId), values);
}

export const teamsPosts = new TeamsPosts();

import styled from 'styled-components';
import { Typography } from 'antd';
import { colors, space } from '../theme';

const { Text } = Typography;

export const StyledInfoColumnTitle = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.textColorSecondary};
`;

export const StyledInfoColumnValue = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.primaryColor};
  &.ant-typography {
    display: block;
  }
`;

export const StyledInfoColumnValueWithAvatar = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.primaryColor};
  padding-top: ${space.micro};
  &.ant-typography {
    display: block;
  }
`;

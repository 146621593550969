import { useMutation } from 'react-query';
import { NonPoInvoiceSuppliersResponse } from 'types';
import { nonPoInvoice } from '../NONPoInvoice';

export const useNonPoInvoiceSuppliersApi = () => {
  return useMutation<NonPoInvoiceSuppliersResponse[], unknown, string, unknown>(
    async (codeOrName) => {
      const { data } = await nonPoInvoice.getNonPoInvoiceSuppliers(codeOrName);
      return data;
    }
  );
};

import { Carousel, Col, Row, Typography } from 'antd';
import styled from 'styled-components';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { borderRadius, colors, space, typography } from 'ui/elements';

const { Text } = Typography;

export const StyledCarousel = styled(Carousel)`
  padding-bottom: ${space.normal};
  width: inherent;

  .slick-dots {
    position: absolute;
    z-index: 15;
    display: flex !important;
    justify-content: center;
    margin-right: 50%;
    margin-left: 50%;
    list-style: none;
  }

  .slick-dots-bottom {
    bottom: -${space.cozy};
    background: ${colors.lightGreyScroll};
    width: max-content;
  }

  .slick-dots li button {
    width: 0px;
    height: 0px;
  }

  .slick-dots li.slick-active button {
    width: ${space.moderate};
    height: ${space.mini};
    border-radius: ${borderRadius.small};
    background: ${colors.lightActive};
  }

  .slick-slide {
    margin-bottom: ${space.mini};
  }

  .slick-cloned {
    display: none !important;
  }
`;

export const BACK_ICON_STYLES = {
  fontSize: space.normal,
  color: colors.lightGrey,
};

export const StyledRowTitle = styled(Row)`
  margin: 0px ${space.normal} 0px ${space.normal};
`;

export const StyledCarouselCol = styled(Col)`
  margin-top: ${space.normal};
  height: 249px;
  background-color: ${(props: { $isEmpty?: boolean }) => (props.$isEmpty ? colors.white : 'none')};
  border-radius: ${borderRadius.xxLarge};
  width: 100% !important;
  display: ${(props: { $isEmpty?: boolean }) => (props.$isEmpty ? 'flex' : 'block')};
  justify-content: center;
  align-items: center;
`;

export const StyledTitleTask = styled(Text)`
  font-size: ${typography.size.tera};
  font-weight: ${typography.weight.semiBold};
`;

export const StyledGoToService = styled.a`
  text-decoration: underline;
  vertical-align: -webkit-baseline-middle;
  color: ${colors.primaryColor};
  font-size: ${space.moderate};
`;

export const StyledLeftArrowIcon = styled(ArrowLeftOutlined)<{ disabledArrow: boolean }>`
  margin-right: ${space.moderate};

  svg {
    fill: ${(props) => (props.disabledArrow ? colors.borderGreySecond : colors.lightGrey)};
  }
`;

export const StyledRightArrowIcon = styled(ArrowRightOutlined)<{ disabledArrow: boolean }>`
  svg {
    fill: ${(props) => (props.disabledArrow ? colors.borderGreySecond : colors.lightGrey)};
  }
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProcessInstanceInfoData, RequestCommonError, RequestStatus } from 'types';
import { AxiosRequestConfig } from 'axios';
import { RequestListState } from './requestsListTypes';

export const emptyRequestsListState: RequestListState = {
  status: RequestStatus.idle,
  hasMore: true,
  error: null,
};

export type GetRequestsListStart = PayloadAction<AxiosRequestConfig>;
export type GetRequestsListSuccess = PayloadAction<ProcessInstanceInfoData[]>;
export type GetRequestsListFail = PayloadAction<RequestCommonError>;

const requestsList = createSlice({
  name: 'requestsList',
  initialState: emptyRequestsListState,
  reducers: {
    getRequestsListStart(state, _: GetRequestsListStart) {
      state.status = RequestStatus.pending;
    },
    getRequestsListSuccess(state, action: GetRequestsListSuccess) {
      state.status = RequestStatus.resolved;

      if (action.payload?.length < 10) {
        state.hasMore = false;
      }

      if (!state.data?.length) {
        state.data = action.payload;
        return;
      }

      state.data = [...state.data, ...action.payload];
    },
    getRequestsListFail(state, action: GetRequestsListFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getRequestsListReset(state) {
      state.status = RequestStatus.idle;
      state.data = undefined;
    },
  },
});

export const {
  getRequestsListFail,
  getRequestsListStart,
  getRequestsListSuccess,
  getRequestsListReset,
} = requestsList.actions;
export default requestsList.reducer;

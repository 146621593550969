import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetTaskDetailsStart,
  GetTaskDetailsFail,
  GetTaskDetailsSuccess,
  getTaskDetailsStart,
  getTaskDetailsFail,
  getTaskDetailsSuccess,
} from './taskDetailsSlice';

export const taskDetailsEpice: Epic<
  GetTaskDetailsStart,
  GetTaskDetailsSuccess | GetTaskDetailsFail,
  RootState,
  typeof API
> = (action$, _, { taskDetails }) =>
  action$.pipe(
    filter(getTaskDetailsStart.match),
    mergeMap(({ payload }) =>
      from(taskDetails.getTaskDetails(payload.taskId)).pipe(
        map(({ data }) => getTaskDetailsSuccess(data)),
        catchError((error) => of(getTaskDetailsFail(error?.response?.data)))
      )
    )
  );

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  PostProcessDefinitionFail,
  PostProcessDefinitionStart,
  PostProcessDefinitionSuccess,
  postProcessDefinitionStart,
  postProcessDefinitionSuccess,
  postProcessDefinitionFail,
} from './processDefinitionSlice';

export const processDefinitionEpic: Epic<
  PostProcessDefinitionStart,
  PostProcessDefinitionSuccess | PostProcessDefinitionFail,
  RootState,
  typeof API
> = (action$, _, { processDefinition }) =>
  action$.pipe(
    filter(postProcessDefinitionStart.match),
    exhaustMap(({ payload }) =>
      from(processDefinition.postProcessDefinition(payload)).pipe(
        map(({ data }) => postProcessDefinitionSuccess({ processDefinitionKey: payload, data })),
        catchError((error) =>
          of(
            postProcessDefinitionFail({
              processDefinitionKey: payload,
              error: error?.response?.data,
            })
          )
        )
      )
    )
  );

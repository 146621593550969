import styled from 'styled-components';
import { Col, Typography } from 'antd';
import { colors, space } from 'ui/elements';
import {
  FileArrowDown,
  FileCsv,
  FileExcel,
  FileImage,
  FilePDF,
  FilePPT,
  FileText,
  FileVideo,
  FileWorld,
} from 'assets';
import React from 'react';

const { Text } = Typography;

export const StyledProfileAttachmentContainer = styled.a`
  background: ${colors.white};
  border: 1px solid ${colors.borderGreySecond};
  border-radius: 4px;
  overflow: hidden;
  width: 29%;
  height: 68px;
  align-items: center;
  padding-left: ${space.cozy};
  padding-right: ${space.cozy};
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

interface StyledIconProps {
  as: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const StyledIcon = styled(({ as: Icon, ...props }: StyledIconProps) => <Icon {...props} />)`
  width: 20px;
  height: 24px;
`;

export const StyledFileCsvIcon = () => <StyledIcon as={FileCsv} />;
export const StyledFileExcelIcon = () => <StyledIcon as={FileExcel} />;
export const StyledFileImageIcon = () => <StyledIcon as={FileImage} />;
export const StyledFilePDFIcon = () => <StyledIcon as={FilePDF} />;
export const StyledFilePPTIcon = () => <StyledIcon as={FilePPT} />;
export const StyledFileTextIcon = () => <StyledIcon as={FileText} />;
export const StyledFileVideoIcon = () => <StyledIcon as={FileVideo} />;
export const StyledFileWorldIcon = () => <StyledIcon as={FileWorld} />;

export const StyledFileArrowDownIcon = styled(FileArrowDown)`
  width: 15px;
  height: 20px;
`;

export const StyledAttachmentName = styled(Text)`
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: ${colors.primaryColor};
  white-space: nowrap;
  overflow: clip;
  text-overflow-ellipsis: ellipsis;
  width: 100%;
`;

export const StyledAttachmentUploadedDate = styled(Text)`
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: ${colors.lightActive};
`;

export const StyledAttachmentNameWrapper = styled(Col)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: ${space.slim};
  padding-right: ${space.slim};
  overflow: hidden;
`;

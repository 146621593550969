import { Col, Row } from 'antd';
import React, { FC, Fragment, memo } from 'react';
import { TType } from 'translations';
import { space, TitleH2, VerticalSpace } from 'ui/elements';

import {
  ProcessDescription,
  ProductInfoFormWrapper,
  PRODUCT_INFO_FORM_COL_SPAN,
} from 'ui/components';
import { FormInstance } from 'antd/lib/form';
import { GUTTER_16 } from 'routes/Tasks/components/templates';
import { CreateFormItemType } from 'types';
import { GeneratedStaticForm } from '../../organisms/GeneratedStaticForms/GenerateStaticForms';

export interface GeneratedStaticFormProps {
  t: TType;
  getItems: () => CreateFormItemType[];
  description?: string;
  example?: string;
  form?: FormInstance;
  showDescriptionIcon?: boolean;
}

export const StaticFormTemplateComponent: FC<GeneratedStaticFormProps> = ({
  t,
  getItems,
  description,
  example,
  form,
  showDescriptionIcon,
}) => {
  const items = getItems();

  return (
    <Fragment>
      <ProductInfoFormWrapper>
        <VerticalSpace size={parseInt(space.moderate)}>
          <Row gutter={GUTTER_16} align={'middle'}>
            <Col span={PRODUCT_INFO_FORM_COL_SPAN}>
              {description && (
                <ProcessDescription
                  showDescriptionIcon={showDescriptionIcon}
                  descriptionLabel={t('expenses.staticForm.label.requestDescription')}
                  descriptionText={description}
                  exampleText={example}
                />
              )}
            </Col>
            <Col span={PRODUCT_INFO_FORM_COL_SPAN}>
              <TitleH2 style={{ marginBottom: '27px' }}>
                {t('tasks.tab.requests.requestDetails')}
              </TitleH2>
            </Col>
            <Col span={PRODUCT_INFO_FORM_COL_SPAN}>
              <GeneratedStaticForm t={t} items={items} formState={form} />
            </Col>
          </Row>
        </VerticalSpace>
      </ProductInfoFormWrapper>
    </Fragment>
  );
};

export const StaticFormTemplate = memo(StaticFormTemplateComponent);

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  GetLeavesListFail,
  GetLeavesListSuccess,
  GetLeavesListStart,
  getLeavesListStart,
  getLeavesListSuccess,
  getLeavesListFail,
} from './leavesListSlice';

export const LeavesListEpic: Epic<
  GetLeavesListStart,
  GetLeavesListSuccess | GetLeavesListFail,
  RootState,
  typeof API
> = (action$, _, { leaves }) =>
  action$.pipe(
    filter(getLeavesListStart.match),
    exhaustMap((action) =>
      from(leaves.getLeavesList(action.payload.hcmPersonId, action.payload.params)).pipe(
        map(({ data }) => getLeavesListSuccess(data)),
        catchError((error) => of(getLeavesListFail(error?.response?.data)))
      )
    )
  );

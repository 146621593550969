import { IGoogleMapsAddressResponse } from 'types';
import { Core, GET_GOOGLE_ADDRESS_FROM_LAT_LNG } from '../../Core';

interface GetAddressByLocation {
  (params: { latlng: string; result_type: string; key: string }): Promise<{
    data: IGoogleMapsAddressResponse;
  }>;
}

class GoogleMaps extends Core {
  getAddressByLocation: GetAddressByLocation = (params) =>
    this.outBoundGet(GET_GOOGLE_ADDRESS_FROM_LAT_LNG(), { params });
}

export const googleMapsApi = new GoogleMaps();

import React, { FC, memo, useEffect, useState } from 'react';
import { Empty } from 'antd';
import { FullWidthSpin, TitleH3 } from 'ui';
import { useTranslate } from 'translations';
import { HierarchyData, RequestStatus } from 'types';
import { Tree } from 'react-organizational-chart';
import { getHierarchyStart, hierarchyData, useSelector, useDispatch } from 'store';
import { hierarchyConnector } from '../hierarchy.connector';
import { convertToTree } from '../utils';
import { DataNode } from '../Hierarchy.types';
import { OrganizationalChartNode } from './OrganizationalChartNode';
import { EmployeeCard } from './EmployeeCard';
import {
  StyledOrgTitle,
  StyledRow,
  StyledTitleContainer,
  StyledTreeContainer,
} from './OrganizationalChart.styled';

interface OrganizationalChartProps {
  hcmId: string;
  isPublicProfile?: boolean;
}

export const OrganizationalChartComponent: FC<OrganizationalChartProps> = ({
  hcmId,
  isPublicProfile = false,
}) => {
  const [treeData, setTreeData] = useState<DataNode[] | null>();
  const [hierarchies, setHierarchies] = useState<HierarchyData[] | null>();
  const [isError, setIsError] = useState(false);
  const hierarchy = useSelector(hierarchyData);
  const dispatch = useDispatch();
  const { t } = useTranslate();

  useEffect(() => {
    if (hcmId !== undefined && hcmId !== null) {
      setTreeData(null);
      setHierarchies(null);
      setIsError(false);
      dispatch(getHierarchyStart(hcmId));
    }
  }, [dispatch, hcmId]);

  useEffect(() => {
    if (hierarchy.status === RequestStatus.resolved) {
      setHierarchies(hierarchy.data);
      if (hierarchy.data) {
        setTreeData(convertToTree(hierarchy.data));
      }
      setIsError(false);
    } else if (hierarchy.status === RequestStatus.rejected) {
      setIsError(true);
    }
  }, [hierarchy.status, hierarchy.data]);

  return (
    <>
      <StyledRow gutter={[0, 18]}>
        <StyledTitleContainer span={24}>
          {isPublicProfile ? (
            <StyledOrgTitle>{t('hierarchy.title')}</StyledOrgTitle>
          ) : (
            <TitleH3 type="prime">{t('hierarchy.title')}</TitleH3>
          )}
        </StyledTitleContainer>

        <StyledTreeContainer
          span={24}
          className={isError || hierarchy.status === RequestStatus.pending ? 'centered' : ''}
        >
          {!treeData || hierarchy.status === RequestStatus.pending ? (
            <FullWidthSpin />
          ) : isError ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <Tree
              lineWidth="1px"
              lineColor="#E6E6E6"
              nodePadding="0px"
              label={
                hierarchies && (
                  <EmployeeCard
                    t={t}
                    id={hierarchies[0]?.adOid}
                    displayName={hierarchies[0]?.displayName}
                    title={hierarchies[0]?.title}
                    email={hierarchies[0]?.email}
                  />
                )
              }
            >
              {hierarchies &&
                hierarchies[0].subordinates?.map((_: HierarchyData, i: number) => {
                  return <OrganizationalChartNode key={i} t={t} node={_} />;
                })}
            </Tree>
          )}
        </StyledTreeContainer>
      </StyledRow>
    </>
  );
};

export const OrganizationalChart = hierarchyConnector(memo(OrganizationalChartComponent));

import { Button, Typography } from 'antd';
import styled from 'styled-components';
import { LinkedIn } from 'assets';
import { colors, space } from 'ui';

const { Text } = Typography;

export const StyledAnnouncementShareWrapper = styled(Button)`
  display: flex;
  flex-direction: row;
  border: none;
  box-shadow: none;
  background-color: ${colors.white};
  &:hover,
  &:focus {
    background-color: ${colors.white};
  }
`;
export const StyledLinkedInIcon = styled(LinkedIn)`
  margin-left: ${space.mini};
`;
export const StyledShareOnText = styled(Text)`
  color: ${colors.grey};
  font-size: 14px;
  font-family: inherit;
`;

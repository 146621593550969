import React, { FC, memo } from 'react';
import { useTranslate } from 'translations';
import { FullWidthSpin } from 'ui';
import { StyledUserNameText } from './UserNameStyled';

export interface UserNameProps {
  userName?: string;
}

export const UserNameComponent: FC<UserNameProps> = ({ userName }) => {
  const { t } = useTranslate();

  return (
    <>
      {userName ? (
        <StyledUserNameText>
          {t('dashboard.hello')}
          {userName}
        </StyledUserNameText>
      ) : (
        <FullWidthSpin />
      )}
    </>
  );
};

export const UserName = memo(UserNameComponent);

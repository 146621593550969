import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { UserInfoStatus, RequestCommonError, RequestStatus } from 'types';
import { UserInfoStatusState } from './userInfoStatusTypes';

export const userInfoStatusInitialState: UserInfoStatusState = {};

export type GetuserInfoStatusStart = PayloadAction<{
  [key: string]: string;
  params: AxiosRequestConfig['params'];
}>;
export type GetuserInfoStatusSuccess = PayloadAction<{
  inputValue: string;
  data: UserInfoStatus;
}>;
export type GetuserInfoStatusFail = PayloadAction<{
  inputValue: string;
  error: RequestCommonError;
}>;

const userInfoStatus = createSlice({
  name: 'userInfoStatus',
  initialState: userInfoStatusInitialState,
  reducers: {
    getuserInfoStatusStart(state, action: GetuserInfoStatusStart) {
      const inputValue = Object.values(action.payload)[0];
      if (state[inputValue]) {
        state[inputValue].status = RequestStatus.pending;
        return;
      }
      state[inputValue] = {
        ...state[inputValue],
        status: RequestStatus.pending,
      };
    },
    getuserInfoStatusSuccess(state, action: GetuserInfoStatusSuccess) {
      state[action.payload.inputValue].status = RequestStatus.resolved;
      state[action.payload.inputValue].data = action.payload.data;
    },
    getuserInfoStatusFail(state, action: GetuserInfoStatusFail) {
      state[action.payload.inputValue] = {
        ...state[action.payload.inputValue],
        status: RequestStatus.rejected,
      };
      state[action.payload.inputValue].error = action.payload.error;
    },
  },
});

export const {
  getuserInfoStatusStart,
  getuserInfoStatusSuccess,
  getuserInfoStatusFail,
} = userInfoStatus.actions;
export default userInfoStatus.reducer;

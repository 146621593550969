import React, { FC, useMemo } from 'react';
import { TType } from 'translations';
import { IProfileEmergencyContact } from 'types/NewProfile';
import { PrivateProfileEmergencyContactItem } from '../PrivateProfileEmergencyContactItem';
import { StyledEmergencyContactContainer } from './ProfilePersonalDetails.styled';

type Props = {
  t: TType;
  emergencyContacts: IProfileEmergencyContact[];
};

export const PrivateProfileEmergencyContact: FC<Props> = ({ t, emergencyContacts }) => {
  const data: IProfileEmergencyContact[] = useMemo(() => {
    return emergencyContacts;
  }, [emergencyContacts]);
  return (
    <StyledEmergencyContactContainer>
      {data.map((item, index) => (
        <PrivateProfileEmergencyContactItem
          key={index}
          familyName={item.familyName}
          mobileNumber={`${item.phoneCountryCode ?? ''}${item.mobileNumber}`}
          relationship={item.relationship}
          title={item.firstName}
          t={t}
        />
      ))}
    </StyledEmergencyContactContainer>
  );
};

import React from 'react';
import { AppLayout } from 'layouts';
import { Col, Row } from 'antd';
import { Header } from 'app';
import { TitleH1 } from 'ui';
import { useHistory } from 'react-router-dom';
import { AnnouncementTags } from '../AnnouncementTags';
import { AnnouncementCreatorInfo } from '../AnnouncementCreatorInfo';
import { AnnouncementShare } from '../AnnouncementShare';
import { AnnouncementAttachment } from '../AnnouncementAttachment';
import { AnnouncementBodyText } from '../AnnouncementBodyText';
import { AnnouncementImageGrid } from '../AnnouncementImageGrid';
import {
  StyledBackArrow,
  StyledBannerImage,
  StyledBodyContainer,
  StyledContainer,
  StyledDetailContainer,
  StyledTitle,
  StyledTitleWrapper,
  StyledUserInfoContainer,
} from './AnnouncementDetailLayout.styled';

const IMAGE_URL = 'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg';
export const AnnouncementDetailLayout = () => {
  const history = useHistory();
  const handleOnBackClick = () => {
    history.goBack();
  };

  return (
    <AppLayout displayFooter={false}>
      <Header>
        <Row align="middle">
          <StyledBackArrow onClick={handleOnBackClick} />
          <Col>
            <TitleH1>{'What’s happening around you'}</TitleH1>
          </Col>
        </Row>
      </Header>
      <StyledContainer className="layout-main">
        <StyledDetailContainer>
          <StyledBannerImage src={IMAGE_URL} />
          <StyledBodyContainer>
            <AnnouncementTags />
            <StyledTitleWrapper>
              <StyledTitle>{'Eid Mubarak to Tawal Family'}</StyledTitle>
            </StyledTitleWrapper>
            <StyledUserInfoContainer>
              <AnnouncementCreatorInfo />
              <AnnouncementShare />
            </StyledUserInfoContainer>
            <AnnouncementBodyText />
            <AnnouncementImageGrid />
            <AnnouncementAttachment />
          </StyledBodyContainer>
        </StyledDetailContainer>
      </StyledContainer>
    </AppLayout>
  );
};

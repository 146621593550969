import { AllRequestsCard } from 'types';
import { Core, ALL_REQUEST_CARD, ALL_REQUEST_CARD_OLD } from 'api';

interface GetAllRequestCard {
  (): Promise<{ data: AllRequestsCard }>;
}

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

class AllRequestCard extends Core {
  getAllRequestCard: GetAllRequestCard = async () =>
    this.get(disableAggregator ? ALL_REQUEST_CARD_OLD() : ALL_REQUEST_CARD());
}

export const allRequestCard = new AllRequestCard();

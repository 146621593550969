import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMSTeamsContext } from 'routes/MSTeams/MSTeamsProvider';
import InfiniteScroll from 'react-infinite-scroller';

import { useSelector, chatsData, useDispatch, updateChatMessagesStart } from 'store';
import { RequestStatus } from 'types';
import { FullWidthAndHeightSpin, FullWidthSpin } from 'ui';
import { ChatContainer, ChatHolder, ChatScrollbar, ChatWrapper } from './Chat.styled';
import { ChatItem } from './ChatItem';
import { ChatSendMessage } from './ChatSendMessage';

export const Chat: FC = () => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollBarRef = useRef<HTMLDivElement>(null);
  const { data, getChatMessagesStatus } = useSelector(chatsData);
  const { chatMessages } = data;
  const [scrollToFirstMessage, setScrollToFirstMessage] = useState(false);
  const { currentChatId } = useMSTeamsContext();
  const dispatch = useDispatch();

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView();
  }, []);

  const loadMore = useCallback(() => {
    if (data.skipToken && getChatMessagesStatus !== RequestStatus.pending) {
      dispatch(updateChatMessagesStart(currentChatId));
    }
  }, [currentChatId, data.skipToken, dispatch, getChatMessagesStatus]);

  const getScrollParent = useCallback(() => scrollBarRef.current, []);

  useEffect(() => {
    if (chatMessages?.length) {
      setScrollToFirstMessage(true);
    }

    return () => {
      setScrollToFirstMessage(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages]);

  useEffect(() => {
    if (scrollToFirstMessage) {
      scrollToBottom();
    }
  }, [scrollToFirstMessage, scrollToBottom]);

  const chatWithInfinityScroll = useMemo(() => {
    return (
      chatMessages && (
        <ChatContainer>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={!!data.skipToken}
            useWindow={false}
            getScrollParent={getScrollParent}
            isReverse
            loader={<FullWidthSpin key={0} />}
            initialLoad={false}
          >
            {chatMessages.map(({ date, messages }, i) => (
              <ChatItem key={date + i} title={date} messages={messages} />
            ))}
          </InfiniteScroll>
          <div ref={messagesEndRef} />
        </ChatContainer>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages]);

  return (
    <ChatWrapper>
      <ChatHolder>
        <ChatScrollbar ref={scrollBarRef}>
          {getChatMessagesStatus === RequestStatus.pending ? (
            chatMessages?.length ? (
              chatWithInfinityScroll
            ) : (
              <FullWidthAndHeightSpin />
            )
          ) : (
            chatWithInfinityScroll
          )}
        </ChatScrollbar>
      </ChatHolder>
      <ChatSendMessage
        key={currentChatId}
        userList={data.chats?.find((chat) => chat.id === currentChatId)?.members}
      />
    </ChatWrapper>
  );
};

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const taskDetailsData = (state: RootState): RootState['taskDetails'] => state.taskDetails;

export const taskInformation = createSelector(taskDetailsData, ({ status, data, error }) => ({
  status,
  data,
  error,
}));

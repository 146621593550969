import { Tabs } from 'antd';
import styled from 'styled-components';

import { colors } from 'ui/elements';

export const StyledTab = styled(Tabs)`
  .ant-tabs-nav-wrap {
    padding: 0 24px;
    background-color: ${colors.white};
  }
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 50px;
    color: ${colors.labelGrey};

    .ant-tabs-tab-active {
      font-weight: 500;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-table-thead > tr > th {
    background-color: ${colors.white};
  }
`;

export const TabPaneContent = styled.div`
  padding: 24px 24px 32px;
`;

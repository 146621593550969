import { createSelector } from '@reduxjs/toolkit';
import { MSTeamsTeamsTabsProps } from 'routes/MSTeams/MSTeamsTeamsTabs';
import { MSTeamsTeamsSidebarTeamProps } from 'routes/MSTeams/MSTeamsTeamsTabs/MSTeamsTeamsSidebar/MSTeamsTeamsSidebarTeam';
import { TeamsChannel } from 'types';

import { RootState } from '../reducers';

export const teamsChannels = (state: RootState): RootState['teamsChannels'] => state.teamsChannels;
export const currentTeamId = (
  _: RootState,
  props: MSTeamsTeamsSidebarTeamProps
): string | undefined => (props.team ? props.team?.id : undefined);

export const getCurrentChannelId = (
  state: RootState,
  { channelId }: MSTeamsTeamsTabsProps
): string => channelId;

export const getCurrentTeamsChannel = createSelector(
  [teamsChannels, getCurrentChannelId],
  (channels, id) =>
    Object.values(channels).reduce((acc, channel) => {
      const currentChannel = channel.data?.find((channelData) => channelData.id === id);
      if (currentChannel) {
        acc = currentChannel;
      }
      return acc;
    }, {} as TeamsChannel | undefined)
);
export const getCurrentTeamsChannels = createSelector(
  [teamsChannels, currentTeamId],
  (channels, id) => (id ? channels[id] : undefined)
);

export const getTeamsChannelsData = createSelector(teamsChannels, (data) => data);

import { AxiosRequestConfig } from 'axios';
import { RecentFilesData } from 'types';
import { Core, GET_RECENT_FILES } from '../Core';

interface GetRecentFiles {
  (params?: AxiosRequestConfig['params']): Promise<{ data: RecentFilesData }>;
}

class RecentFiles extends Core {
  getRecentFiles: GetRecentFiles = async (params) => this.get(GET_RECENT_FILES(), { params });
}

export const recentFiles = new RecentFiles();

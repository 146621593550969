import { IGoogleMapsAddressResponse } from 'types';

export const getFormattedAddressFromGoogleResponse = (
  response: IGoogleMapsAddressResponse
): string | undefined => {
  if (response.results.length) {
    const firstResult = response.results[0];
    if (firstResult?.formatted_address && firstResult?.formatted_address !== '') {
      return firstResult?.formatted_address;
    }
  }
  return;
};

import { ExpenseStatus } from 'types';

export const expenseDrawerMockData = {
  id: 184,
  expenseCreatedDate: '2022-04-08T14:10:40.142+0300',
  prettyId: 'SR782309',
  expenseName: 'Allowance - Mobile',
  status: ExpenseStatus.completed,
  description: 'Rapidiously negotiate go forward leadership skills and parallel vortals.',
  payment: {
    invoiceID: '35525252',
    invoiceTotalAmount: 15000.0,
    invoiceStatus: ExpenseStatus.paid,
  },
  items: [
    {
      id: 1,
      itemType: 'Health club',
      itemLabel: '#1',
      invoiceDate: '2022-03-18T12:00:21.691',
      amount: 1231.0,
    },
    {
      id: 2,
      itemType: 'Recruitment Fees',
      itemLabel: '#2',
      invoiceDate: '2022-03-18T12:00:21.691',
      amount: 804.0,
    },
  ],
  attachments: [
    {
      attachmentLabel: null,
      createTime: '2022-04-03T12:02:17.160+00:00',
      description: 'jenkins8460.jpg',
      id: 'e835d4dd-b345-11ec-8efb-0a580a830260',
      name: 'jenkins8460.jpg',
      removalTime: null,
      rootProcessInstanceId: null,
      taskId: 'e800e1e2-b345-11ec-8efb-0a580a830260',
      type: 'image/jpeg',
      url: null,
      attachmentOrder: -1,
      itemId: -1,
      fileSize: '34511',
    },
  ],
  processInstanceId: 'e800bacf-b345-11ec-8efb-0a580a830260',
};

import React, { FC } from 'react';
import { TType } from 'translations';
import { Spin } from 'antd';
import { useELibrarySectors } from 'api';
import {
  StyledELibrarySectionContainer,
  StyledELibraryTitle,
  StyledELibraryTitleContainer,
} from '../ELibrary.styled';
import { ELibrarySectorCollapse } from './ELibrarySectorCollapse';

type Props = {
  onDepartmentSelect: (departmentName: string, sectorName: string) => void;
  selectedDepartmentName?: string;
  isFilterApplied: boolean;
  t: TType;
};

export const ELibrarySideBar: FC<Props> = ({
  onDepartmentSelect,
  selectedDepartmentName,
  isFilterApplied,
  t,
}) => {
  const { data: sectors, isLoading } = useELibrarySectors();

  return (
    <>
      {isLoading && <Spin spinning={isLoading} delay={100} />}
      {!isLoading && (
        <StyledELibrarySectionContainer>
          <StyledELibraryTitleContainer className="sidebar-title-container">
            <StyledELibraryTitle>{t('eLibrary.sectors')}</StyledELibraryTitle>
          </StyledELibraryTitleContainer>
          <ELibrarySectorCollapse
            sectors={sectors ?? []}
            selectedDepartmentName={selectedDepartmentName}
            onDepartmentSelect={onDepartmentSelect}
            isFilterApplied={isFilterApplied}
          />
        </StyledELibrarySectionContainer>
      )}
    </>
  );
};

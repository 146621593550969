import { Alert } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Layout } from 'layouts/SiderLayout/SiderLayout.styled';
import styled from 'styled-components';
import { colors, space } from 'ui/elements';

export const LayoutComponent = styled(Layout)`
  min-height: 100vh;
  background: ${(props: { $isLoginPage?: boolean }) => (props.$isLoginPage ? 'none' : 'normal')};

  .layout-fixed-header {
    position: fixed;
    width: 100%;
    z-index: 5;
    box-shadow: 0 0px 4px #dfe0eb;
  }

  .layout-main {
    padding-top: 65px;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
  }
`;

export const AppAlert = styled(Alert)`
  .ant-alert-message {
    color: ${colors.primaryColor};
    padding-left: ${space.moderate};
  }

  .ant-alert-close-text {
    color: ${colors.lightRed};

    &:hover,
    &:focus {
      color: ${colors.red};
    }
  }
`;

export const AlertWrapperContent = styled(Content)`
  flex: initial;
`;

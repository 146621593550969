import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';

import {
  GetProfileExpenseAttachmentsFail,
  GetProfileExpenseAttachmentsStart,
  GetProfileExpenseAttachmentsSuccess,
  getProfileExpenseAttachmentsFail,
  getProfileExpenseAttachmentsStart,
  getProfileExpenseAttachmentsSuccess,
} from './ProfileExpenseAttachmentSlice';

export const ProfileExpenseAttachmentsEpic: Epic<
  GetProfileExpenseAttachmentsStart,
  GetProfileExpenseAttachmentsSuccess | GetProfileExpenseAttachmentsFail,
  RootState,
  typeof API
> = (action$, _, { profileExpenses }) =>
  action$.pipe(
    filter(getProfileExpenseAttachmentsStart.match),
    exhaustMap(({ payload }) =>
      from(profileExpenses.getProfileExpensesAttachment(payload.PersonId, payload.expensesID)).pipe(
        map(({ data }) => getProfileExpenseAttachmentsSuccess(data)),
        catchError((error) => of(getProfileExpenseAttachmentsFail(error?.response?.data)))
      )
    )
  );

import { colors } from 'ui/elements';
import { position } from './Constants';

export const ChartOptions = {
  legend: {
    display: false,
  },
  layout: {
    padding: {
      left: 5,
      right: 5,
      top: 15,
      bottom: 5,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      intersect: false,
      yAlign: position.BOTTOM,
      backgroundColor: colors.transparentColor,
      displayColors: false,
      callbacks: {
        labelTextColor: () => colors.black,
        title: () => null,
      },
      padding: {
        top: -1,
        bottom: 0,
        left: 0,
      },
    },
  },
  scales: {
    y: {
      ticks: {
        display: false,
      },
      gridLines: {
        display: false,
      },
      display: false,
      offset: true,
    },
    x: {
      gridLines: {
        display: false,
      },
      ticks: {
        display: false,
      },
      display: false,
      offset: true,
    },
  },
};

export const getGradientFill = (ctx: CanvasRenderingContext2D) => {
  const gradientFillStroke = ctx.createLinearGradient(0, 10, 50, 0);
  gradientFillStroke.addColorStop(0.3, colors.gradientPrimaryColor);
  gradientFillStroke.addColorStop(0.5, colors.gradientSecondaryColor);
  gradientFillStroke.addColorStop(1.0, colors.gradientTeritaryColor);
  return gradientFillStroke;
};

export const getDataSetsValue = (chartData: number[], gradientFill: CanvasGradient) => ({
  borderColor: gradientFill,
  pointBackgroundColor: gradientFill,
  pointHoverBackgroundColor: colors.pointerColor,
  pointHoverBorderColor: colors.pointerColor,
  pointBorderWidth: 0,
  pointHoverBorderWidth: 1,
  pointRadius: 0,
  pointHoverRadius: 7,
  fill: false,
  borderWidth: 5,
  data: chartData,
  lineTension: 0.4,
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmployeeDirectorySearchResponse, RequestCommonError, RequestStatus } from 'types';
import { EmployeeDirectorySearchState } from './employeeDirectorySearchType';

export const employeeDirectorySearchInitialState: EmployeeDirectorySearchState = {
  status: RequestStatus.idle,
  error: null,
  data: undefined,
};

export type EmployeeDirectorySearchStart = PayloadAction<{
  params: { searchParams: string | null; offset: number; limit: number };
}>;
export type EmployeeDirectorySearchSuccess = PayloadAction<EmployeeDirectorySearchResponse>;
export type EmployeeDirectorySearchFail = PayloadAction<RequestCommonError>;
export type EmployeeDirectorySearchRest = PayloadAction;

const employeeDirectorySearchSlice = createSlice({
  name: 'employeeDirectorySearch',
  initialState: employeeDirectorySearchInitialState,
  reducers: {
    employeeDirectorySearchStart(state, _: EmployeeDirectorySearchStart) {
      state.status = RequestStatus.pending;
    },
    employeeDirectorySearchSuccess(state, action: EmployeeDirectorySearchSuccess) {
      state.status = RequestStatus.resolved;
      if (!state.data?.list.length) {
        state.data = action.payload;
        return;
      }
      state.data.list = [...state.data.list, ...action.payload.list];
      state.data.limit = action.payload.limit;
      state.data.offset = action.payload.offset;
      state.data.totalResult = action.payload.totalResult;
    },
    employeeDirectorySearchFail(state, action: EmployeeDirectorySearchFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    employeeDirectorySearchRest(state, _: EmployeeDirectorySearchRest) {
      state.status = RequestStatus.idle;
      state.error = null;
      state.data = undefined;
    },
  },
});

export const {
  employeeDirectorySearchFail,
  employeeDirectorySearchRest,
  employeeDirectorySearchStart,
  employeeDirectorySearchSuccess,
} = employeeDirectorySearchSlice.actions;

export default employeeDirectorySearchSlice.reducer;

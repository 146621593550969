import { Card } from 'antd';
import styled from 'styled-components';

export const StyledPopover = styled.div`
  width: 100%;
`;
export const StyledLoading = styled(Card)`
  width: 300px;
  border: none;
`;

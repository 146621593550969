import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Col, Empty, notification, Row } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import useResizeObserver from 'use-resize-observer';
import { useTranslate } from 'translations';
import { TasksLayout } from 'layouts';
import { CenteredCard, FullWidthSpin, TitleH3 } from 'ui';
import { GroupTaskStatus, RequestGeneralInfo, RequestStatus, TaskStatus } from 'types';
import {
  getMyGroupTaskCardsReset,
  getMyGroupTaskCardsStart,
  getProcessDefinitionKeysStart,
  getUserGroupStart,
  myGroupTaskCardsSelector,
  processDefinitionKeysData,
  profileData,
  useDispatch,
  userGroupData,
  useSelector,
} from 'store';
import { useFilterDelegatorNames } from 'api/StaticForm/hooks/Filter/useFilterDelegatorNames';
import { useTasksContext } from 'routes/Tasks/TasksProvider';
import { useServicesContext } from 'app';
import { TaskListContainer, TaskListScroller } from '../TaskList/TaskList.styled';
import { TaskCardUpdate } from '../TaskList/TaskCard';
import { TaskSteps } from '../TaskList/TaskSteps';
import { FilterOption } from '../Filter/Filter.types';
import {
  CATEGORY_FILTER_FIELD,
  ServicesFilter,
  SUB_CATEGORY_FILTER_FIELDS,
} from '../Filter/ServicesFilter';
import { FilterTag } from '../Filter/Filter.styled';

interface Props {
  userId?: string;
  isQueryParams?: boolean;
  setIsQueryParams?: React.Dispatch<React.SetStateAction<boolean>>;
}

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';
const disableDelegation = process.env.REACT_APP_DISABLE_DELEGATION === 'true';

const GroupsTasksComponent: FC<Props> = ({ userId, isQueryParams, setIsQueryParams }) => {
  const { path } = useRouteMatch();
  const getUrl = useCallback((pagePath: string) => `${path}${pagePath}`, [path]);
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { data: delegatorsOptions } = useFilterDelegatorNames();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const groupsTasks = useSelector(myGroupTaskCardsSelector);
  const userGroup = useSelector(userGroupData);
  const { data: userData } = useSelector(profileData);
  const { ref, height = 1 } = useResizeObserver<HTMLDivElement>();
  const processDefinitionKeys = useSelector(processDefinitionKeysData);
  const groupsTasksLength = groupsTasks.data?.requestInfoList.length || 0;
  const groupsTasksFetchingFinished =
    groupsTasks.status === RequestStatus.resolved || groupsTasks.status === RequestStatus.rejected;
  const history = useHistory();
  const [subCategoryOptions, setSubCategoryOptions] = useState<FilterOption[]>([]);
  const [requestNameOptions, setRequestNameOptions] = useState<FilterOption[]>([]);
  const [delegatorOptions, setDelegatorOptions] = useState<FilterOption[]>([]);
  const { currentActiveTab } = useTasksContext();
  const [tasksStatus, setTasksStatus] = useState<TaskStatus>(TaskStatus.processed);
  const [params, setParams] = useState({});
  const [prettyId, setPrettyId] = useState<string>();
  const { isFilterByPrettyId, prettyIdInfoRedirection } = useServicesContext();
  const location = useLocation();
  const pathName = location.pathname.split('/');
  const [RequestInfo, setRequestInfo] = useState<RequestGeneralInfo | undefined>();

  const fetchGroupsTasks = useCallback(
    (startFrom = 0, value = TaskStatus.inProgress, params = {}) => {
      if (!userId) {
        return;
      }

      const requestParams = {
        start: startFrom,
        pageSize: 10,
        ...params,
      };

      if (value === TaskStatus.processed) {
        requestParams.status = GroupTaskStatus.processed;
      } else if (value === TaskStatus.inProgress) {
        requestParams.status = GroupTaskStatus.inProgress;
      } else {
        requestParams.status = disableDelegation
          ? GroupTaskStatus.pending
          : GroupTaskStatus.inProgress;
      }

      dispatch(
        getMyGroupTaskCardsStart({
          userId,
          params: requestParams,
        })
      );
    },
    [dispatch, userId]
  );

  const reloadTaskList = useCallback(() => {
    dispatch(getMyGroupTaskCardsReset());
    fetchGroupsTasks(0, tasksStatus, params);
  }, [dispatch, fetchGroupsTasks, params, tasksStatus]);

  useEffect(() => {
    if (currentActiveTab === 'work-1' && !isQueryParams && !isFilterByPrettyId) {
      setIsLoading(true);
      reloadTaskList();
    }
  }, [currentActiveTab, reloadTaskList, history, isQueryParams, isFilterByPrettyId]);

  useEffect(() => {
    const delegatorList = delegatorsOptions?.map((field) => {
      return { value: field.delegationId.toString(), text: field.name } as FilterOption;
    });
    setDelegatorOptions(delegatorList ?? []);
  }, [delegatorsOptions]);

  useEffect(() => {
    if (!isQueryParams) {
      setTasksStatus(disableDelegation ? TaskStatus.pending : TaskStatus.inProgress);
    }
  }, [isQueryParams]);

  const loadMore = useCallback(() => {
    if (!groupsTasks?.hasMore || groupsTasks.status === RequestStatus.pending) {
      return;
    }

    fetchGroupsTasks(groupsTasks.data?.requestInfoList.length, tasksStatus, params);
  }, [
    groupsTasks.hasMore,
    groupsTasks.data?.requestInfoList.length,
    groupsTasks.status,
    fetchGroupsTasks,
    tasksStatus,
    params,
  ]);

  useEffect(() => {
    try {
      dispatch(getProcessDefinitionKeysStart());
      if (pathName.length !== 4 && RequestInfo && isQueryParams && RequestInfo.tasks) {
        const reqInfoTasks = RequestInfo.tasks;
        const lastTaskIndex = reqInfoTasks.length - 1;
        const lastTask = reqInfoTasks[lastTaskIndex];
        const status = lastTask && lastTask.groupId ? lastTask.status : TaskStatus.processed;
        const prettyId = RequestInfo?.requestInfo.prettyId;
        const reqParams = {
          start: 0,
          prettyId: prettyId,
          finished: status === TaskStatus.processed,
          pageSize: 1,
        };
        setPrettyId(prettyId);
        setTasksStatus(
          status === TaskStatus.inProgress
            ? TaskStatus.inProgress
            : status === TaskStatus.pending
            ? TaskStatus.pending
            : TaskStatus.processed
        );
        fetchGroupsTasks(0, status, reqParams);
      }
      if (isFilterByPrettyId && prettyIdInfoRedirection?.taskPrettyId) {
        const reqParams = {
          start: 0,
          prettyId: prettyIdInfoRedirection?.taskPrettyId,
          pageSize: 1,
        };
        setPrettyId(prettyIdInfoRedirection?.taskPrettyId);
        setTasksStatus(
          prettyIdInfoRedirection.requestStatus === TaskStatus.inProgress
            ? TaskStatus.inProgress
            : prettyIdInfoRedirection.requestStatus === TaskStatus.pending
            ? TaskStatus.pending
            : TaskStatus.processed
        );
        setIsLoading(true);
        dispatch(getMyGroupTaskCardsReset());
        fetchGroupsTasks(0, prettyIdInfoRedirection.requestStatus, reqParams);
      }
    } catch {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
      setIsLoading(false);
    }
  }, [
    t,
    dispatch,
    userId,
    fetchGroupsTasks,
    params,
    tasksStatus,
    pathName.length,
    isQueryParams,
    RequestInfo,
    setIsQueryParams,
    isFilterByPrettyId,
    prettyIdInfoRedirection?.taskPrettyId,
    prettyIdInfoRedirection?.requestStatus,
  ]);

  useEffect(
    () => () => {
      dispatch(getMyGroupTaskCardsReset());
    },
    [dispatch]
  );

  useEffect(() => {
    if (groupsTasksFetchingFinished && processDefinitionKeys.status === RequestStatus.resolved) {
      setIsLoading(false);
    }
  }, [groupsTasksFetchingFinished, processDefinitionKeys.status]);

  useEffect(() => {
    if (userId) {
      dispatch(getUserGroupStart({ params: { userId } }));
    }
  }, [dispatch, userId]);

  const onFilterCallback = useCallback(
    async (params = {}, status?: TaskStatus) => {
      if (
        (isFilterByPrettyId && Object.entries(params).length === 0) ||
        (isQueryParams && Object.entries(params).length === 0)
      ) {
        return;
      } else {
        setParams(params);
        dispatch(getMyGroupTaskCardsReset());
        fetchGroupsTasks(0, status, params);
      }
    },
    [isFilterByPrettyId, isQueryParams, dispatch, fetchGroupsTasks]
  );

  const categoryOptions = useMemo(
    () =>
      processDefinitionKeys.data?.categories.map((category) => ({
        label: category.name,
        value: category.name,
        text: category.name,
      })),
    [processDefinitionKeys.data]
  );

  const currentUserData = useMemo(
    () => ({
      label: userData?.displayName as string,
      value: userData?.adOid as string,
      text: userData?.displayName as string,
    }),
    [userData]
  );

  const groupUserOptions = useMemo(
    () => [
      currentUserData,
      ...(userGroup.data?.groupUsers.map(({ displayName, id }) => ({
        label: displayName,
        value: id,
        text: displayName,
      })) ?? []),
    ],
    [userGroup.data, currentUserData]
  );

  const onCategorySelect = useCallback(
    (value) => {
      const matchedSubCategory = processDefinitionKeys.data?.subCategories.find(
        (subCategory) => subCategory.name === value
      );

      if (matchedSubCategory) {
        const subCategoryOptions = matchedSubCategory?.names.map(({ name }) => ({
          label: name,
          value: name,
          text: name,
        }));

        setSubCategoryOptions(subCategoryOptions);
      }
    },
    [processDefinitionKeys.data?.subCategories]
  );

  const onSubCategorySelect = useCallback(
    (value) => {
      const matchedRequestName = processDefinitionKeys.data?.requestNames.find(
        (subCategory) => subCategory.name === value
      );

      if (matchedRequestName) {
        const requestNameOptions = matchedRequestName?.names.map(
          ({ name, processDefinitionKey }) => ({
            label: name,
            value: processDefinitionKey,
            text: name,
          })
        );

        setRequestNameOptions(requestNameOptions);
      }
    },
    [processDefinitionKeys.data?.requestNames]
  );

  const onValuesChange = useCallback(
    (field: { [key: string]: string }) => {
      if (field[CATEGORY_FILTER_FIELD]) {
        onCategorySelect(field[CATEGORY_FILTER_FIELD]);
        setRequestNameOptions([]);
      }

      if (field[SUB_CATEGORY_FILTER_FIELDS]) {
        onSubCategorySelect(field[SUB_CATEGORY_FILTER_FIELDS]);
      }
    },
    [onCategorySelect, onSubCategorySelect]
  );

  const GroupTaskCardList = useMemo(
    () => {
      if (groupsTasksLength) {
        return (
          <TaskListScroller style={(ref as unknown) ? { maxHeight: height - 100 } : undefined}>
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={groupsTasks?.hasMore}
              useWindow={false}
              loader={<FullWidthSpin key={0} />}
              initialLoad={false}
            >
              {
                // eslint-disable-next-line array-callback-return
                groupsTasks.data?.requestInfoList.map((card) => {
                  if (!card) {
                    return undefined;
                  }
                  return (
                    <>
                      <TaskCardUpdate
                        key={card.prettyId}
                        stepTitle={card.taskInfo.taskName}
                        title={card.processName}
                        processInstanceId={card.processInstanceId}
                        date={card.requestTime}
                        description={card.taskInfo.description}
                        stepStatus={card.taskInfo.status}
                        assignedTo={card.taskInfo.taskAssignee}
                        assigneeId={card.taskInfo.assigneeId}
                        taskId={card.taskInfo.taskId}
                        taskName={card.taskInfo.taskName}
                        mytask={false}
                        myGroupTask={true}
                        isMyRequest={false}
                        userGroupId={card.taskInfo.groupId}
                        reLoadCallback={reloadTaskList}
                        requestPrettyId={card.prettyId}
                        formFields={card.taskInfo.formFields}
                        isProcessed={tasksStatus === TaskStatus.processed}
                        endEvent={card.endEvent}
                        requester={card.requester}
                        appliedAction={card.taskInfo.appliedAction}
                        rootPath={card.serviceUrl}
                        isJira={card.isJiraRequest}
                        jiraStatus={card.jiraStatus}
                        completedBy={card.taskInfo?.completedBy}
                        processDefinitionKey={card.processDefinitionKey}
                        taskDefinitionKey={card?.taskInfo?.taskDefinitionKey}
                      />
                    </>
                  );
                })
              }
            </InfiniteScroll>
          </TaskListScroller>
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      height,
      ref,
      groupsTasks.data,
      groupsTasksLength,
      loadMore,
      reloadTaskList,
      tasksStatus,
      groupsTasks?.hasMore,
      userId,
    ]
  );

  const onSelect = useCallback(
    (value: TaskStatus) => {
      if (setIsQueryParams) {
        setIsQueryParams(false);
      }
      setTasksStatus(value);
      dispatch(getMyGroupTaskCardsReset());
      fetchGroupsTasks(0, value, params);
    },
    [dispatch, fetchGroupsTasks, params, setIsQueryParams]
  );

  const onResetFilterCallback = useCallback((isReset) => {
    if (isReset) {
      setSubCategoryOptions([]);
      setRequestNameOptions([]);
    }
  }, []);

  const onRemoveTag = () => {
    setIsLoading(true);
    reloadTaskList();
  };

  return (
    <TasksLayout
      sidebar={
        <Row gutter={[0, 24]}>
          <Col span={24}>
            {isLoading ? <FullWidthSpin /> : GroupTaskCardList}
            {groupsTasksFetchingFinished && !groupsTasksLength && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('task.noGroupsTasks')} />
            )}
          </Col>
        </Row>
      }
      sidebarTitle={
        <>
          <ServicesFilter
            t={t}
            history={history}
            isLoading={isLoading}
            categoryOptions={categoryOptions}
            requestNameOptions={requestNameOptions}
            subCategoryOptions={subCategoryOptions}
            delegatorOptions={delegatorOptions}
            onFilterCallback={onFilterCallback}
            onValuesChange={onValuesChange}
            showAssignedTo={tasksStatus !== TaskStatus.pending && !disableAggregator}
            groupUsers={groupUserOptions}
            onResetFilterCallback={onResetFilterCallback}
            showStatusSelect={true}
            statusSelectOptions={
              disableDelegation
                ? [TaskStatus.pending, TaskStatus.inProgress, TaskStatus.processed]
                : [TaskStatus.inProgress, TaskStatus.processed]
            }
            defaultStatusSelectOption={
              disableDelegation ? TaskStatus.pending : TaskStatus.inProgress
            }
            statusSelectValue={tasksStatus}
            onSelectedStatusChangeCallback={onSelect}
          ></ServicesFilter>
          {isQueryParams && prettyId && Object.entries(params).length === 0 && (
            <FilterTag closable onClose={onRemoveTag}>
              {t('tasks.requestId', { requestId: prettyId })}
            </FilterTag>
          )}
        </>
      }
    >
      <TaskListContainer ref={ref}>
        <Switch>
          <Route exact path={path}>
            <CenteredCard>
              <TitleH3 type="secondary">{t('tasks.selectToAssign')}</TitleH3>
            </CenteredCard>
          </Route>
          <Route exact path={getUrl('/:id')}>
            <TaskSteps
              reloadTaskList={reloadTaskList}
              tasksStatus={tasksStatus}
              setSelectedTask={setRequestInfo}
              isMyGroupTask={true}
            />
          </Route>
        </Switch>
      </TaskListContainer>
    </TasksLayout>
  );
};

export const GroupsTasks = memo(GroupsTasksComponent);

import { Core, TRAVEL_DURATION, ESTIMATED_AMOUNT } from 'api/Core';
import { AxiosRequestConfig } from 'axios';

interface TravelDuration {
  duration?: number;
}
interface EstimatedTotalAmount {
  totalEstimatedAmt?: number;
}
interface GetTravelDuration {
  (params?: AxiosRequestConfig['params']): Promise<{ data: TravelDuration }>;
}

interface GetEstimatedTotalAmount {
  (
    sourceId: number,
    destinationId: number,
    transportationId: number,
    tripId: number,
    params?: AxiosRequestConfig['params']
  ): Promise<{ data: EstimatedTotalAmount }>;
}

class Duration extends Core {
  getTravelDuration: GetTravelDuration = async (params) => this.get(TRAVEL_DURATION(), params);
  getEstimatedTotalAmount: GetEstimatedTotalAmount = async (
    sourceId,
    destinationId,
    transportationId,
    tripId,
    params
  ) => this.get(ESTIMATED_AMOUNT(sourceId, destinationId, transportationId, tripId), params);
}

export const duration = new Duration();

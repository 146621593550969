import React, { memo, FC, useMemo } from 'react';
import { Card, Row, Col, Spin } from 'antd';
import styled from 'styled-components';
import { TType, MessagesTypes } from 'translations';
import { GeneratedForm } from 'ui/components/GeneratedForm';
import { useForm } from 'antd/lib/form/Form';
import { space, TitleH2 } from 'ui/elements';
import { FormFields } from 'types';
import { getCurrentUserAdoid, useSelector } from 'store';
import ServiceFormSelector from 'ui/components/StaticForm/ServiceFormSelector';
import { Gutter } from 'antd/lib/grid/row';
import { PathText } from '../../templates/RequestsCatalogCards/RequestsCatalogCards.styled';

const Wrapper = styled.div`
  padding: ${space.normal};
`;

const FORM_LAYOUT = {
  xs: { span: 24, offset: 0 },
  lg: { span: 16, offset: 4 },
  xl: { span: 14, offset: 5 },
  xxl: { span: 12, offset: 6 },
};

const FORM_LAYOUT_3_Column = {
  xs: { span: 24, offset: 0 },
  lg: { span: 20, offset: 2 },
  xl: { span: 18, offset: 3 },
  xxl: { span: 18, offset: 3 },
};

const FORM_LAYOUT_MORE_WIDTH = {
  xs: { span: 24, offset: 1 },
  lg: { span: 20, offset: 2 },
  xl: { span: 18, offset: 3 },
  xxl: { span: 16, offset: 4 },
};

const ROW_GUTTER: [Gutter, Gutter] = [0, 24];

export const SERVICE_NEW_REQUEST_FORM = 'SERVICE_NEW_REQUEST_FORM';

interface Props {
  t: TType;
  data: FormFields | null;
  processDefinitionKey: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFormSubmit?(data?: any): void;

  loading?: boolean;
  isDynamic: boolean;
}

const SERVICES_WITH_3_COLUMNS = ['Human_Resource.HR_services.Business_and_Training_Travel'];
const SERVICES_WITH_MORE_WIDTH = ['Human_Resource.HR_services.Work_From_Home'];

export const ServiceNewRequestFormComponent: FC<Props> = ({
  processDefinitionKey,
  t,
  data,
  onFormSubmit,
  loading,
  isDynamic,
}) => {
  const processName = processDefinitionKey.replace(/\./g, '').replace(/-/g, '');
  const currentUserAdoId = useSelector(getCurrentUserAdoid);
  const [form] = useForm();
  const [category, subCategory] = useMemo(
    () => processDefinitionKey.replaceAll('_', ' ').split('.'),
    [processDefinitionKey]
  );
  const formLayout = useMemo(() => {
    if (SERVICES_WITH_3_COLUMNS.includes(processDefinitionKey)) {
      return FORM_LAYOUT_3_Column;
    }
    if (SERVICES_WITH_MORE_WIDTH.includes(processDefinitionKey)) {
      return FORM_LAYOUT_MORE_WIDTH;
    }
    return FORM_LAYOUT;
  }, [processDefinitionKey]);

  return (
    <Wrapper>
      <Card>
        <Spin spinning={loading} delay={100}>
          <Row gutter={ROW_GUTTER}>
            <Col {...formLayout}>
              <PathText $isPathForm={true}>{`${category} / ${subCategory}`}</PathText>
            </Col>
            <Col {...formLayout}>
              <TitleH2 strong>
                {t(`service.newRequest.title.${processDefinitionKey}` as MessagesTypes)}
              </TitleH2>
            </Col>
            <Col {...formLayout}>
              {isDynamic && data ? (
                <GeneratedForm
                  formData={data}
                  formName={SERVICE_NEW_REQUEST_FORM}
                  onSubmit={onFormSubmit}
                  form={form}
                  processDefinitionKey={processDefinitionKey}
                />
              ) : (
                currentUserAdoId && (
                  <ServiceFormSelector
                    serviceName={processName}
                    adoid={currentUserAdoId}
                    t={t}
                    formName={SERVICE_NEW_REQUEST_FORM}
                    pdk={processDefinitionKey}
                  />
                )
              )}
            </Col>
          </Row>
        </Spin>
      </Card>
    </Wrapper>
  );
};

export const ServiceNewRequestForm = memo(ServiceNewRequestFormComponent);

import { DatePicker } from 'antd';
import styled from 'styled-components';
import { space } from 'ui/elements';

export const StyledContainer = styled.div`
  margin: ${space.moderate};
  height: 100%;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 200px;
  margin-left: ${space.cozy};
`;

import { useQuery } from 'react-query';
import { QUERIES } from '../../constants';
import { workFromHomeRequest } from '../WorkFromHomeRequest';

export const useWFHRemainingBalance = (hcmId: string) => {
  return useQuery(
    [QUERIES.getWorkFromHomeRemainingBalance],
    async () => {
      const { data } = await workFromHomeRequest.getWorkFromHomeRemainingBalance({ hcmId });
      return data;
    },
    { keepPreviousData: false, refetchOnMount: false, refetchOnWindowFocus: false }
  );
};

import React, { useState } from 'react';
import { Form, Popconfirm, Row, Typography } from 'antd';
import { useTranslate } from 'translations';
import { TawalLogo } from 'ui/components';
import { SufaraLogoWhiteBg } from 'assets';
import { Button, Wrapper, RegisterCard, WelcomeTitle, PoweredByText } from './LoginForm.styled';

interface LoginFormProps {
  onClickLogin(): void;
}

const { Text } = Typography;

export const LoginForm: React.FC<LoginFormProps> = ({ onClickLogin }) => {
  const { t } = useTranslate();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const fetchCookieBySubstringAndValue = (substring: string, value: string) => {
    const cookies = decodeURIComponent(document.cookie).split(';');
    const result: { key: string; value: string }[] = [];

    cookies.forEach((cookie) => {
      const key = cookie.split('=')[0].trim();
      const value = cookie.split('=')[1];

      if (key?.includes(substring) || value?.includes(substring)) {
        result.push({ key, value });
      }
    });

    return result;
  };

  const deleteCookie = (name: string) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  };

  const getAzureInteractionStatusCookie = () => {
    return fetchCookieBySubstringAndValue('interaction.status', 'interaction_in_progress');
  };

  const deleteAzureInProgressCookies = () => {
    const inProgressCookies = getAzureInteractionStatusCookie();
    inProgressCookies.forEach((cookie) => {
      deleteCookie(cookie.key);
    });
  };

  const onConfirmOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      setIsConfirmOpen(newOpen);
      return;
    }

    if (getAzureInteractionStatusCookie().length > 0) {
      setIsConfirmOpen(newOpen);
    } else {
      setIsConfirmOpen(false);
      onClickLogin();
    }
  };

  return (
    <Row justify="center">
      <Form layout="vertical" name="loginForm">
        <RegisterCard>
          <SufaraLogoWhiteBg />
          <WelcomeTitle>{t('welcome')}</WelcomeTitle>
          <Wrapper>
            <Text type="secondary">{t('welcomeDescription')}</Text>
          </Wrapper>

          <Popconfirm
            visible={isConfirmOpen}
            onVisibleChange={onConfirmOpenChange}
            title={t('inProgressLoginError')}
            onConfirm={() => {
              deleteAzureInProgressCookies();
              onClickLogin();
            }}
          >
            <Button size="large" type="primary">
              {t('login')}
            </Button>
          </Popconfirm>
        </RegisterCard>
        <PoweredByText>powered by</PoweredByText>
        <TawalLogo />
      </Form>
    </Row>
  );
};

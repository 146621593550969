import { connect, ConnectedProps } from 'react-redux';

import {
  RootState,
  hierarchyData,
  getHierarchyStart,
  getCurrentUserHcmId,
  getSubordinatesStart,
  subordinatesData,
} from 'store';

const mapDispatch = {
  getHierarchyStart,
  getSubordinatesStart,
};

const mapState = (state: RootState) => ({
  hierarchyData: hierarchyData(state),
  subordinatesData: subordinatesData(state),
  currentUserHcmId: getCurrentUserHcmId(state),
});

export const hierarchyConnector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof hierarchyConnector>;

export type ConnectorProps = PropsFromRedux;

import React, { FC, memo } from 'react';
import { Row, Col, Checkbox } from 'antd';
import { NotificationGroupSetting } from 'types';
import { TitleH4, useRowGutter } from 'ui';
import { span } from 'ui/elements';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { StyledH3Title, StyledRow, StyledFlexCol } from './NotificationForm.styled';

interface Props {
  notificationSetting: NotificationGroupSetting[];
  handleChange: (e: CheckboxChangeEvent, index: number, itemId: number) => void;
  turnOffNotification: boolean;
}

const HIDE_SMS_EMAIL = process.env.REACT_APP_DISABLE_NOTIFICATION_SETTING_SMS_EMAIL !== 'true';
export const NotificationCategoryComponent: FC<Props> = ({
  notificationSetting,
  handleChange,
  turnOffNotification,
}) => {
  const GUTTER_16 = useRowGutter(16);
  const { span16, span8, span14 } = span;
  return (
    <>
      {notificationSetting.map(({ groupName, userSetting }, i) => {
        return (
          <StyledRow key={i} gutter={GUTTER_16}>
            <Col span={span8}>
              <StyledH3Title> {groupName} </StyledH3Title>
            </Col>
            <Col span={span14}>
              {userSetting.map(({ notificationLabel, id, mobile, sms, email }) => {
                return (
                  <Row key={id} gutter={GUTTER_16}>
                    <Col span={span16}>
                      <TitleH4> {notificationLabel} </TitleH4>
                    </Col>
                    <StyledFlexCol span={span8}>
                      {HIDE_SMS_EMAIL && (
                        <>
                          <Checkbox
                            name={`mobile`}
                            checked={mobile}
                            onChange={(e) => {
                              handleChange(e, i, id);
                            }}
                            disabled={turnOffNotification}
                          />
                          <Checkbox
                            name={`sms`}
                            checked={sms}
                            onChange={(e) => {
                              handleChange(e, i, id);
                            }}
                            disabled={turnOffNotification}
                          />
                        </>
                      )}

                      <Checkbox
                        name={`email`}
                        checked={email}
                        onChange={(e) => {
                          handleChange(e, i, id);
                        }}
                        disabled={turnOffNotification}
                      />
                    </StyledFlexCol>
                  </Row>
                );
              })}
            </Col>
          </StyledRow>
        );
      })}
    </>
  );
};

export const NotificationCategory = memo(NotificationCategoryComponent);

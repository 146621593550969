import styled from 'styled-components';
import { Row } from 'antd';

import { colors } from 'ui/elements';
import { HEADER_HEIGHT } from 'app/Header/Header.styled';

export const StyledEmployeeDirectorySidebar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${colors.white};
  border-right: none;
  padding: 16px 24px 0 24px;
  height: 100%;
  max-height: calc(100vh - ${HEADER_HEIGHT});
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: ${colors.scrollbarColor} ${colors.white};

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.white};
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.scrollbarColor};
    border-radius: 2px;
  }
`;

export const StyledEmployeeDirectoryWrapper = styled.div`
  display: flex;
  height: calc(100% - ${HEADER_HEIGHT});
  border-top: 1px solid ${colors.borderGrey};
`;

export const StyledRow = styled(Row)`
  width: 100%;
`;

export const StyledEmployeeDirectoryPage = styled.div`
  height: 100%;
  min-height: calc(100vh - ${HEADER_HEIGHT});
  max-height: calc(100vh - ${HEADER_HEIGHT});
  line-height: 24px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
  padding: 24px;
`;

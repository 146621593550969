import React, { FC } from 'react';
import { Col, Image, Tooltip } from 'antd';
import { colors, span } from 'ui/elements';
import { AppNameStyle } from './Components';

interface Props {
  iconURL: string | null;
  appName: string;
}
const AppDrawerContentComponent: FC<Props> = ({ iconURL, appName }) => {
  const { span24 } = span;
  return (
    <React.Fragment>
      <Tooltip
        placement="top"
        title={appName}
        trigger={'hover'}
        color={colors.grayTooltip}
        getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      >
        <div>
          <Col span={span24}>
            {iconURL && <Image height={40} src={iconURL} alt={appName} preview={false} />}
          </Col>

          <Col span={span24}>{AppNameStyle(appName)}</Col>
        </div>
      </Tooltip>
    </React.Fragment>
  );
};

export const AppDrawerContent = React.memo(AppDrawerContentComponent);

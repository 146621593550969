import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PrettyId, RequestCommonError, RequestStatus } from 'types';
import { RequestPrettyIdState } from './requestPrettyIdTypes';

export const RequestPrettyIdInitialState: RequestPrettyIdState = {};

export type GetRequestPrettyIdStart = PayloadAction<{ ids: string }>;
export type GetRequestPrettyIdSuccess = PayloadAction<{
  data: PrettyId[];
}>;
export type GetRequestPrettyIdFail = PayloadAction<{
  ids: string;
  error: RequestCommonError;
}>;

const requestPrettyIdSlice = createSlice({
  name: 'requestPrettyId',
  initialState: RequestPrettyIdInitialState,
  reducers: {
    getRequestPrettyIdStart(state, action: GetRequestPrettyIdStart) {
      const idsArr: string[] = action.payload.ids.split(',');

      idsArr.forEach((id) => {
        state[id] = {
          ...state[id],
          status: RequestStatus.pending,
        };
      });
    },
    getRequestPrettyIdSuccess(state, action: GetRequestPrettyIdSuccess) {
      const prettyIdsResp = action.payload.data;

      prettyIdsResp.forEach((prettyId) => {
        state[prettyId.camundaId].status = RequestStatus.resolved;
        state[prettyId.camundaId].data = prettyId;
      });
    },
    getRequestPrettyIdFail(state, action: GetRequestPrettyIdFail) {
      const idsArr: string[] = action.payload.ids.split(',');

      idsArr.forEach((id) => {
        state[id] = {
          ...state[id],
          status: RequestStatus.rejected,
          error: action.payload.error,
        };
      });
    },
  },
});

export const {
  getRequestPrettyIdStart,
  getRequestPrettyIdSuccess,
  getRequestPrettyIdFail,
} = requestPrettyIdSlice.actions;

export default requestPrettyIdSlice.reducer;

import { Col, Row } from 'antd';
import React, { FC, memo, useEffect, useState } from 'react';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { RequestTeamMember } from 'types/DashboardRequestsCard';
import { UserPopover } from 'ui/components';
import { getAvatarSymbols } from 'utils';
import { StyledAvatarGroup, StyledAvatarName } from './AvatarGroupStyled';
import { AvatarList } from './AvatarList';

interface Props {
  groupMember: RequestTeamMember[];
}

export const AvatarGroupComponent: FC<Props> = ({ groupMember }) => {
  const [first2Members, setFirst2Members] = useState<RequestTeamMember[]>();
  const [isMoreThen2Members, setIsMoreThen2Members] = useState<boolean>(false);

  useEffect(() => {
    if (groupMember && groupMember.length > 0) {
      if (groupMember.length === 1) {
        const firstMembers = groupMember.slice(0, 2);
        setFirst2Members(firstMembers);
        setIsMoreThen2Members(false);
      } else {
        const firstMembers = groupMember.slice(0, 2);
        setFirst2Members(firstMembers);
        setIsMoreThen2Members(true);
      }
    } else {
      setIsMoreThen2Members(false);
    }
  }, [groupMember]);

  return (
    <>
      <Row justify={'start'} align="bottom">
        <Col>
          <StyledAvatarGroup maxCount={2} size="large">
            {first2Members &&
              first2Members.map((member: RequestTeamMember) => (
                <UserPopover key={member.adoid} currentID={member.adoid}>
                  <MSTeamsUserAvatar
                    id={member.adoid}
                    name={member.name}
                    styledAvatarName={
                      <StyledAvatarName>{getAvatarSymbols(member.name)}</StyledAvatarName>
                    }
                  />
                </UserPopover>
              ))}
          </StyledAvatarGroup>
        </Col>
        {isMoreThen2Members && (
          <Col>
            <AvatarList groupMember={groupMember} />
          </Col>
        )}
      </Row>
    </>
  );
};

export const AvatarGroup = memo(AvatarGroupComponent);

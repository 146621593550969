import { Button, Typography, Col, Row, Card } from 'antd';
import styled from 'styled-components';
import { borderRadius, colors, typography, space } from 'ui/elements';

const { Text } = Typography;

export const StyledButtonWithOutText = styled(Button)`
  color: ${colors.lightGrey};
  border-color: ${colors.borderGreySecond};
  border-radius: ${borderRadius.small};
`;

export const StyledRequestId = styled(Text)`
  color: ${colors.lightGrey};
  font-size: ${typography.size.hecto};
  font-weight: ${typography.weight.regular};
`;

export const StyledRow = styled(Row)`
  margin-bottom: ${space.zero} !important;
  margin-top: ${space.slim} !important;
`;

export const StyledCol = styled(Col)`
  padding-bottom: ${space.zero} !important;
`;

export const StyledCard = styled(Card)<{ $normalPadding?: boolean }>`
  border: none;
  box-shadow: 0 2px 14px 0 #0000000f;

  .ant-card-body {
    padding-bottom: ${({ $normalPadding }) => ($normalPadding ? space.normal : '5px')};
  }
`;

import React, { FC, useEffect } from 'react';
import { Typography, Collapse, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { RequestStatus, TeamsItem } from 'types';
import { getAvatarSymbols } from 'utils';
import {
  StyledRow,
  StyledCol,
  StyledAvatarCol,
} from '../../../MSTeamsChatTabs/MSTeamsChatSidebar/MSTeamsChatSidebar.styled';
import { StyledTeamAvatar, StyledCollapse, StyledTeamRow } from '../MSTeamsTeamsSidebar.styled';
import { MSTeamsTeamsSidebarChannels } from '../MSTeamsTeamsSidebarChannels';
import { ConnectorProps, teamConnectorConnector } from './MSTeamsTeamsSidebarTeam.connector';

export interface MSTeamsTeamsSidebarTeamProps {
  team: TeamsItem;
}

const { Text } = Typography;
const { Panel } = Collapse;

export const MSTeamsTeamsSidebarTeamComponent: FC<
  MSTeamsTeamsSidebarTeamProps & ConnectorProps
> = ({ team, currentTeamsChannelsData, getTeamsChannelsStart }) => {
  const avatarSymbols = getAvatarSymbols(team.displayName);

  const status = currentTeamsChannelsData?.status;

  useEffect(() => {
    if (!status || status === RequestStatus.idle) {
      getTeamsChannelsStart(team.id);
    }
  }, [team.id, getTeamsChannelsStart, status]);

  return (
    <StyledCollapse
      ghost
      defaultActiveKey={team.id}
      expandIcon={
        status === RequestStatus.pending
          ? () => <Spin size="small" indicator={<LoadingOutlined spin />} />
          : undefined
      }
    >
      <Panel
        key={team.id}
        header={
          <StyledTeamRow align="middle">
            <StyledAvatarCol>
              <StyledTeamAvatar id={team.id}>{avatarSymbols}</StyledTeamAvatar>
            </StyledAvatarCol>
            <StyledCol>
              <StyledRow justify="space-between">
                <Text>{team.displayName}</Text>
              </StyledRow>
            </StyledCol>
          </StyledTeamRow>
        }
      >
        <MSTeamsTeamsSidebarChannels teamId={team.id} channels={currentTeamsChannelsData?.data} />
      </Panel>
    </StyledCollapse>
  );
};

export const MSTeamsTeamsSidebarTeam = teamConnectorConnector(MSTeamsTeamsSidebarTeamComponent);

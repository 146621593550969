import { OneDriveSharedLibrariesData } from 'types';
import { Core, ONE_DRIVE_SHARED_LIBRARIES } from '../Core';

interface GetOneDriveSharedLibraries {
  (groupId: string): Promise<{ data: OneDriveSharedLibrariesData }>;
}

class OneDriveSharedLibraries extends Core {
  getOneDriveSharedLibraries: GetOneDriveSharedLibraries = async (groupId) =>
    this.get(ONE_DRIVE_SHARED_LIBRARIES(groupId));
}

export const oneDriveSharedLibraries = new OneDriveSharedLibraries();

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, TeamsChannelsData } from 'types';
import { TeamsChannelsState } from './teamsChannelsTypes';

export const emptyTeamsChannelsInitialState: TeamsChannelsState = {
  undefined: {
    status: RequestStatus.idle,
    error: null,
  },
};

export type GetTeamsChannelsStart = PayloadAction<string>;
export type GetTeamsChannelsSuccess = PayloadAction<{ teamId: string; data: TeamsChannelsData }>;
export type GetTeamsChannelsFail = PayloadAction<{ teamId: string; error: RequestCommonError }>;

const teamsChannels = createSlice({
  name: 'teamsChannels',
  initialState: emptyTeamsChannelsInitialState,
  reducers: {
    getTeamsChannelsStart(state, action: GetTeamsChannelsStart) {
      state[action.payload] = {
        ...state[action.payload],
        status: RequestStatus.pending,
      };
    },
    getTeamsChannelsSuccess(state, action: GetTeamsChannelsSuccess) {
      state[action.payload.teamId].status = RequestStatus.resolved;
      state[action.payload.teamId].data = action.payload.data;
    },
    getTeamsChannelsFail(state, action: GetTeamsChannelsFail) {
      state[action.payload.teamId].status = RequestStatus.rejected;
      state[action.payload.teamId].error = action.payload.error;
    },
  },
});

export const {
  getTeamsChannelsStart,
  getTeamsChannelsSuccess,
  getTeamsChannelsFail,
} = teamsChannels.actions;
export default teamsChannels.reducer;

import React, { useMemo } from 'react';
import { Col } from 'antd';
import { TType } from 'translations';
import moment from 'moment';
import { IProfileAttachment } from 'types';
import { ProfileEntryField, PrivateProfileAttachments } from 'ui';
import { StyledProfileRowContainer } from '../../NewProfile.styled';
import {
  StyledDependentItemTitle,
  StyledDependentItemWrapper,
} from './PrivateProfileDependentDetailItem.styles';

type PrivateProfileDependentDetailItemProps = {
  t: TType;
  dateOfBirth: string;
  idNumber: string;
  relationship: string;
  title: string;
  dependentAttachmentList: IProfileAttachment[];
};

export const PrivateProfileDependentDetailItem: React.FC<PrivateProfileDependentDetailItemProps> = ({
  t,
  dateOfBirth,
  idNumber,
  relationship,
  title,
  dependentAttachmentList,
}: PrivateProfileDependentDetailItemProps) => {
  const dependentFields = useMemo(
    () => [
      {
        label: t('user.privateProfile.dependentDetails.dateOfBirth'),
        value: moment(dateOfBirth).format('DD.MM.YYYY'),
      },
      { label: t('user.privateProfile.dependentDetails.idNumber'), value: idNumber },
      { label: t('user.privateProfile.dependentDetails.relationship'), value: relationship },
    ],
    [dateOfBirth, idNumber, relationship, t]
  );

  return (
    <StyledDependentItemWrapper>
      <StyledDependentItemTitle>{title}</StyledDependentItemTitle>
      <StyledProfileRowContainer gutter={[25, 15]} wrap={true}>
        {dependentFields.map(({ label, value }, index) => (
          <Col xs={24} sm={24} md={12} lg={8} key={index}>
            <ProfileEntryField label={label} value={value} />
          </Col>
        ))}
      </StyledProfileRowContainer>
      <PrivateProfileAttachments attachments={dependentAttachmentList} />
    </StyledDependentItemWrapper>
  );
};

import React, { FC, useEffect, useState } from 'react';
import { useTranslate } from 'translations';
import { useGetUserHierarchy } from 'api';
import { Empty } from 'antd';
import { Tree } from 'react-organizational-chart';
import { FullWidthSpin, TitleH3 } from 'ui/elements';
import { HierarchyData } from 'types';
import {
  StyledRow,
  StyledOHTitleContainer,
  StyledOHTreeContainer,
} from './OrganizationalHierarchy.styled';
import {
  organizationalHierarchyConvertToTree,
  OrganizationalHierarchyDataNode,
} from './OrganizationalHierarchyTreeBuilder';
import { OrganizationalHierarchyEmployeeCard } from './OrganizationalHierarchyEmployeeCard';
import { OrganizationalHierarchyNode } from './OrganizationalHierarchyNode';

interface Props {
  hcmId: string;
  withoutTitle?: boolean;
  className?: string;
}

export const OrganizationalHierarchy: FC<Props> = ({ hcmId, withoutTitle, className }) => {
  const { t } = useTranslate();
  const [treeData, setTreeData] = useState<OrganizationalHierarchyDataNode[]>([]);
  const { data: userHierarchyData, isError, isLoading } = useGetUserHierarchy(hcmId);

  useEffect(() => {
    if (userHierarchyData && !isError) {
      const tempTreeData = organizationalHierarchyConvertToTree([userHierarchyData]);
      setTreeData(tempTreeData);
    } else {
      setTreeData([]);
    }
  }, [userHierarchyData, isError]);

  return (
    <StyledRow gutter={[0, 18]} className={className}>
      {!withoutTitle && (
        <StyledOHTitleContainer span={24}>
          <TitleH3 type="prime">{t('hierarchy.title')}</TitleH3>
        </StyledOHTitleContainer>
      )}

      <StyledOHTreeContainer
        span={24}
        className={`organizational-hierarchy-tree-container ${
          isError || isLoading ? 'centered' : ''
        }`}
      >
        {!treeData || isLoading ? (
          <FullWidthSpin />
        ) : isError ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <Tree
            lineWidth="1px"
            lineColor="#E6E6E6"
            nodePadding="0px"
            label={
              userHierarchyData && (
                <OrganizationalHierarchyEmployeeCard
                  id={userHierarchyData.adOid}
                  displayName={userHierarchyData.displayName}
                  title={userHierarchyData.title}
                  email={userHierarchyData.email}
                />
              )
            }
          >
            {userHierarchyData &&
              userHierarchyData.subordinates?.map((_: HierarchyData, i: number) => {
                return <OrganizationalHierarchyNode key={i} node={_} />;
              })}
          </Tree>
        )}
      </StyledOHTreeContainer>
    </StyledRow>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import moment from 'moment';

import {
  RequestCommonError,
  RequestStatus,
  ChatData,
  ChatMessagesData,
  ChatMessageData,
  CreateOneToOneChatData,
  ChatMember,
} from 'types';
import { ChatsState } from './chatsTypes';

export const emptyChatsInitialState: ChatsState = {
  getChatsStatus: RequestStatus.idle,
  getChatMessagesStatus: RequestStatus.idle,
  sendChatMessageStatus: RequestStatus.idle,
  createOneToOneChatStatus: RequestStatus.idle,
  createGroupChatStatus: RequestStatus.idle,
  addChatMemberStatus: RequestStatus.idle,
  error: null,
  data: {},
};

export type GetChatsStart = PayloadAction;
export type GetChatsSuccess = PayloadAction<ChatData[]>;
export type GetChatsFail = PayloadAction<RequestCommonError>;
export type CreateOneToOneChatStart = PayloadAction<{
  params: AxiosRequestConfig['params'];
  chatMembers: ChatMember[];
}>;
export type CreateOneToOneChatSuccess = PayloadAction<{
  data: CreateOneToOneChatData;
  chatMembers: ChatMember[];
}>;
export type CreateOneToOneChatFail = PayloadAction<RequestCommonError>;
export type GetChatMessagesStart = PayloadAction<string>;
export type GetChatMessagesSuccess = PayloadAction<ChatMessagesData>;
export type GetChatMessagesFail = PayloadAction<RequestCommonError>;
export type SendChatMessageStart = PayloadAction<{
  chatId: string;
  message: {
    body: { content: string; contentType?: string };
    mentions?: {
      mentioned: {
        user: {
          displayName: string;
          id: string;
        };
      };
    }[];
  };
}>;
export type SendChatMessageSuccess = PayloadAction<ChatMessageData>;
export type SendChatMessageFail = PayloadAction<RequestCommonError>;
export type CreateGroupChatStart = PayloadAction<{
  params: AxiosRequestConfig['params'];
  chatMembers: ChatMember[];
}>;
export type CreateGroupChatSuccess = PayloadAction<{
  data: ChatData;
  chatMembers: ChatMember[];
}>;
export type CreateGroupChatFail = PayloadAction<RequestCommonError>;
export type AddChatMemberStart = PayloadAction<{
  chatId: string;
  params: AxiosRequestConfig['params'];
}>;
export type AddChatMemberSuccess = PayloadAction;
export type AddChatMemberFail = PayloadAction<RequestCommonError>;

const chats = createSlice({
  name: 'chats',
  initialState: emptyChatsInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getChatsStart(state, _: GetChatsStart) {
      state.getChatsStatus = RequestStatus.pending;
    },
    getChatsSuccess(state, action: GetChatsSuccess) {
      state.getChatsStatus = RequestStatus.resolved;
      state.data.chats = action.payload;
    },
    getChatsFail(state, action: GetChatsFail) {
      state.getChatsStatus = RequestStatus.rejected;
      state.error = action.payload;
    },
    getChatMessagesStart(state, _: GetChatMessagesStart) {
      state.getChatMessagesStatus = RequestStatus.pending;
      state.data.chatMessages = undefined;
    },
    getChatMessagesSuccess(state, action: GetChatMessagesSuccess) {
      state.getChatMessagesStatus = RequestStatus.resolved;
      state.data.chatMessages = action.payload;
    },
    getChatMessagesFail(state, action: GetChatMessagesFail) {
      state.getChatMessagesStatus = RequestStatus.rejected;
      state.error = action.payload;
    },
    sendChatMessageStart(state, _: SendChatMessageStart) {
      state.sendChatMessageStatus = RequestStatus.pending;
    },
    sendChatMessageSuccess(state, action: SendChatMessageSuccess) {
      state.sendChatMessageStatus = RequestStatus.resolved;

      if (!state.data.chatMessages) {
        return;
      }

      const lastItem = state.data.chatMessages.list[state.data.chatMessages.list.length - 1];

      if (!lastItem) {
        state.data.chatMessages.list = [
          {
            date: moment().toString(),
            messages: [action.payload],
          },
        ];
        return;
      }

      lastItem.messages = [...lastItem.messages, action.payload];
    },
    sendChatMessageFail(state, action: SendChatMessageFail) {
      state.sendChatMessageStatus = RequestStatus.rejected;
      state.error = action.payload;
    },
    updateChatMessagesStart(state, _: GetChatMessagesStart) {
      state.getChatMessagesStatus = RequestStatus.pending;
    },
    updateChatMessagesSuccess(state, action: GetChatMessagesSuccess) {
      state.getChatMessagesStatus = RequestStatus.resolved;

      if (!state.data.chatMessages) {
        state.data.chatMessages = action.payload;
        return;
      }

      const messagesNotInChat = action.payload.list.filter(
        (message) =>
          !(state.data.chatMessages as ChatMessagesData).list.some(
            (msg) => msg.date === message.date
          )
      );

      const messagesInChat = action.payload.list.filter((message) =>
        (state.data.chatMessages as ChatMessagesData).list.some((msg) => msg.date === message.date)
      );

      const updatedStateMessages = state.data.chatMessages.list.map((message) => {
        const updMsg = messagesInChat.find((msg) => msg.date === message.date);

        const msg = updMsg ? [...updMsg.messages, ...message.messages] : message.messages;

        return {
          ...message,
          messages: msg.sort((a, b) => moment(a.createdDateTime).diff(b.createdDateTime)),
        };
      });

      const newChatMessages = {
        skipToken: action.payload.skipToken,
        list: [...messagesNotInChat, ...updatedStateMessages].sort((a, b) =>
          moment(a.date).diff(b.date)
        ),
      };

      state.data.chatMessages = newChatMessages;
    },
    updateChatMessagesFail(state, action: GetChatMessagesFail) {
      state.getChatMessagesStatus = RequestStatus.rejected;
      state.error = action.payload;
    },
    createOneToOneChatStart(state, _: CreateOneToOneChatStart) {
      state.createOneToOneChatStatus = RequestStatus.pending;
    },
    createOneToOneChatSuccess(state, action: CreateOneToOneChatSuccess) {
      state.createOneToOneChatStatus = RequestStatus.resolved;

      const mappedPayload = {
        ...action.payload.data,
        members: action.payload.data.members
          ? action.payload.data.members
          : action.payload.chatMembers,
      };

      state.data.chats = state.data.chats
        ? state.data.chats.some((chat) => chat.id === action.payload.data.id)
          ? state.data.chats
          : [mappedPayload, ...state.data.chats]
        : [mappedPayload];
    },
    createOneToOneChatFail(state, action: CreateOneToOneChatFail) {
      state.createOneToOneChatStatus = RequestStatus.rejected;
      state.error = action.payload;
    },
    createGroupChatStart(state, _: CreateGroupChatStart) {
      state.createGroupChatStatus = RequestStatus.pending;
    },
    createGroupChatSuccess(state, action: CreateGroupChatSuccess) {
      state.createGroupChatStatus = RequestStatus.resolved;

      state.data.chats?.unshift({
        ...action.payload.data,
        members: action.payload.data.members?.length
          ? action.payload.data.members
          : action.payload.chatMembers,
      });
    },
    createGroupChatFail(state, action: CreateGroupChatFail) {
      state.createGroupChatStatus = RequestStatus.rejected;
      state.error = action.payload;
    },
    createGroupChatReset(state) {
      state.createGroupChatStatus = RequestStatus.idle;
      state.error = '' as RequestCommonError;
    },
    addChatMemberStart(state, _: AddChatMemberStart) {
      state.addChatMemberStatus = RequestStatus.pending;
    },
    addChatMemberSuccess(state, action: AddChatMemberSuccess) {
      state.addChatMemberStatus = RequestStatus.resolved;
    },
    addChatMemberFail(state, action: AddChatMemberFail) {
      state.addChatMemberStatus = RequestStatus.rejected;
      state.error = action.payload;
    },
    addChatMemberReset(state) {
      state.addChatMemberStatus = RequestStatus.idle;
      state.error = '' as RequestCommonError;
    },
  },
});

export const {
  getChatsStart,
  getChatsSuccess,
  getChatsFail,
  getChatMessagesStart,
  getChatMessagesSuccess,
  getChatMessagesFail,
  sendChatMessageStart,
  sendChatMessageSuccess,
  sendChatMessageFail,
  updateChatMessagesFail,
  updateChatMessagesStart,
  updateChatMessagesSuccess,
  createOneToOneChatStart,
  createOneToOneChatSuccess,
  createOneToOneChatFail,
  createGroupChatStart,
  createGroupChatSuccess,
  createGroupChatFail,
  createGroupChatReset,
  addChatMemberStart,
  addChatMemberSuccess,
  addChatMemberFail,
  addChatMemberReset,
} = chats.actions;
export default chats.reducer;

import React, { FC, useCallback } from 'react';
import { FormInstance } from 'antd/lib/form';
import { TType } from 'translations';
import { CreateFormItemType, IProfileUpdateCertificateMapped } from 'types';
import {
  FORM_ITEM_REQUIRED_RULE,
  FORM_ITEM_50_CHARACTER_RULES,
  disableFutureDate,
  englishPatternRule,
} from 'utils';
import { WRAPPER_COL_SPAN_SIZE_21 } from 'ui/components';
import { UploadFile } from 'antd/lib/upload/interface';
import { CustomFormGroupWrapper } from './CustomFormGroupWrapper';

type Props = {
  t: TType;
  form?: FormInstance;
  professionalCertificates?: IProfileUpdateCertificateMapped[];
};

export const ProfileUpdateProfessionalCertificatesFormGroup: FC<Props> = ({
  t,
  form,
  professionalCertificates,
}) => {
  const onProfessionalCertificatesFormRemove = (index: number) => {
    if (professionalCertificates?.[index]) {
      professionalCertificates.splice(index, 1);
    }
  };
  const onAttachmentChange = useCallback(
    (formListIndex: number, file?: UploadFile | UploadFile[]) => {
      if (form) {
        form.setFields([
          {
            name: [
              'professionalCertificates',
              formListIndex,
              'professionalCertificateAttachmentList',
            ],
            value: file,
          },
        ]);
      }
    },
    [form]
  );

  const professionalCertificatesFormGroup = useCallback<
    (formListName: number) => CreateFormItemType[]
  >(
    (formListName) => {
      return [
        {
          id: `${0}`,
          label: 'Name',
          name: [formListName, 'name'],
          type: 'input',
          rules: [
            ...FORM_ITEM_REQUIRED_RULE,
            ...FORM_ITEM_50_CHARACTER_RULES,
            englishPatternRule(t),
          ],
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Enter name',
          disabled: !!professionalCertificates?.[formListName]?.name,
        },
        {
          id: `${1}`,
          name: [formListName, 'erpCertificateId'],
          type: 'input',
          hidden: true,
        },
        {
          id: `${2}`,
          label: 'Issuing organization',
          name: [formListName, 'issuingOrganization'],
          type: 'input',
          rules: [
            ...FORM_ITEM_REQUIRED_RULE,
            ...FORM_ITEM_50_CHARACTER_RULES,
            englishPatternRule(t),
          ],
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Enter issuing organization’s name',
        },
        {
          id: `${3}`,
          label: 'Issue date',
          name: [formListName, 'issueDate'],
          type: 'date',
          disabledDate: disableFutureDate,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Choose issue date',
        },
        {
          id: `${4}`,
          label: 'Expiration date',
          name: [formListName, 'expirationDate'],
          type: 'date',
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Choose expiration date',
        },
        {
          id: `${5}`,
          label: 'Certificate',
          name: [formListName, 'professionalCertificateAttachmentList'],
          type: 'file',
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          valuePropName: 'fileList',
          attachmentFileExtensions: '.png,.pdf',
          attachmentFileTypes: 'image/png,application/pdf',
          onFileChange: (file) => onAttachmentChange(formListName, file),
        },
      ];
    },
    [onAttachmentChange, professionalCertificates, t]
  );
  return (
    <CustomFormGroupWrapper
      t={t}
      maxRecords={10}
      formGroupItems={professionalCertificatesFormGroup}
      formListName="professionalCertificates"
      sectionTitle="Professional Certificates"
      addButtonTitle="Add additional professional certificates"
      hasAtLeastOne={false}
      form={form}
      onListRemove={onProfessionalCertificatesFormRemove}
    />
  );
};

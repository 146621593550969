import React, { FC, memo, useEffect } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { TasksProvider } from '../Tasks';
import { useServicesContext } from '../../app';
import { MyWorkLayout } from './MyWorkLayout';

type Props = RouteComponentProps;

export const MyWorkComponent: FC<Props> = ({ match: { path } }) => {
  const { setPrettyIdInfoRedirection, setIsFilterByPrettyId } = useServicesContext();

  /**
   * On component deteched
   * clear context which contains
   * current filter of the context
   * for route
   */
  useEffect(() => {
    return () => {
      setIsFilterByPrettyId(false);
      setPrettyIdInfoRedirection({});
    };
  }, [setIsFilterByPrettyId, setPrettyIdInfoRedirection]);

  return (
    <Switch>
      <Route path={path}>
        <TasksProvider>
          <MyWorkLayout />
        </TasksProvider>
      </Route>
    </Switch>
  );
};

export const MyWork = memo(withRouter(MyWorkComponent));

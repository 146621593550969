import React, { FC, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useRequestGeneralInfo, useRequestRootPath } from 'api';
import { RequestGeneralInfo, TaskDefinitionType, TaskStates, TaskStatus } from 'types';
import { FullWidthSpin } from 'ui/elements';
import { useTasksContext } from 'routes';
import { RequestHeader } from './RequestHeader';
import { TaskSubmitFormSelector } from './TaskSubmitForm';
import { SiteAcquisitionCompletedCandidatesTask, TaskInfo } from './TaskInfo';
import { RequestInformation } from './helper';
import { RequestHeaderWrapper } from './TasksSteps.styled';

export interface RequestTaskStepsProps {
  reloadTaskList: () => void;
  tasksStatus: TaskStatus;
  setSelectedTask?: (requestInfo?: RequestGeneralInfo) => void;
  isMyGroupTask?: boolean;
}

const COMPLETED_STATUS = 'Completed';

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

export const TaskSteps: FC<RequestTaskStepsProps> = ({
  reloadTaskList,
  tasksStatus,
  setSelectedTask,
  isMyGroupTask = false,
}) => {
  const [requestHeaderInfo, setRequestHeaderInfo] = useState<RequestInformation>();
  const { id: requestId } = useParams<{ id: string }>();
  const { rootPath, setRootPath } = useTasksContext();
  const { mutateAsync: getRootPath, isLoading: isRootPathLoading } = useRequestRootPath();
  const { data: RequestInfo, isLoading, isFetched } = useRequestGeneralInfo(
    requestId,
    rootPath,
    true
  );

  const commentsCount = useMemo(
    () => RequestInfo?.tasks?.reduce((sum, task) => sum + task.taskCommentCount, 0),
    [RequestInfo]
  );

  useEffect(() => {
    if (!disableAggregator) {
      getRootPath(requestId).then((remoteRootPath) => {
        setRootPath(remoteRootPath);
      });
      return () => {
        setRootPath(undefined);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);

  useEffect(() => {
    if (RequestInfo) {
      setRequestHeaderInfo({
        requestDescription: RequestInfo.processMeta.description,
        requestId: requestId,
        requestName: RequestInfo.requestInfo.processName,
        requestPrettyId: RequestInfo.requestInfo.prettyId,
        processDefinitionId: RequestInfo.requestInfo.processDefinitionId,
        commentsCount: commentsCount,
        endEvent: RequestInfo.requestInfo.endEvent,
        isRequestStatusComplete:
          RequestInfo.requestInfo.state === TaskStates.completed ||
          RequestInfo.requestInfo.state === TaskStates.Completed,
      });
    }
  }, [RequestInfo, requestId, commentsCount]);

  useEffect(() => {
    if (setSelectedTask) {
      setSelectedTask(RequestInfo);
    }
  }, [RequestInfo, setSelectedTask]);

  const inProgressTask = useMemo(() => {
    return RequestInfo?.tasks?.find((task) => task.status !== COMPLETED_STATUS);
  }, [RequestInfo]);

  const requestDetailsTask = useMemo(() => {
    return RequestInfo?.tasks?.find((task) => task.taskName.toLowerCase() === 'request details');
  }, [RequestInfo]);

  if (isLoading || isRootPathLoading) {
    return <FullWidthSpin />;
  }

  return (
    <>
      <RequestHeaderWrapper gutter={[12, 12]}>
        <Col span={24}>
          <RequestHeader
            requestHeaderInfo={requestHeaderInfo}
            taskDefinitionKey={requestDetailsTask?.taskDefinitionKey}
          />
          {requestDetailsTask && RequestInfo && (
            <Row gutter={[12, 12]} key={requestDetailsTask.taskId}>
              <Col span={24}>
                <TaskInfo
                  taskId={requestDetailsTask.taskId}
                  title={requestDetailsTask.taskName}
                  status={TaskStatus.processed}
                  processDefinitionId={RequestInfo.requestInfo.processDefinitionId}
                  processInstanceId={RequestInfo.requestInfo.processInstanceId}
                  created={requestDetailsTask.endTime}
                  assigneeInfo={
                    requestDetailsTask.taskAssignee
                      ? {
                          assigneeId: requestDetailsTask.taskAssignee.adoid,
                          firstName: requestDetailsTask.taskAssignee.firstName,
                          lastName: requestDetailsTask.taskAssignee.lastName,
                        }
                      : undefined
                  }
                  taskPrettyId={requestDetailsTask.prettyId}
                  taskActivityInstanceId={requestDetailsTask.taskActivityInstanceId}
                  taskDefinitionKey={requestDetailsTask.taskDefinitionKey}
                  userGroupName={RequestInfo.processMeta.groupArr}
                />
              </Col>
            </Row>
          )}
        </Col>
      </RequestHeaderWrapper>
      {RequestInfo &&
        RequestInfo?.tasks?.map(
          (task) =>
            task.status === TaskStatus.processed &&
            task.taskName.toLowerCase() !== 'request details' && (
              <Row gutter={[12, 12]} key={task.taskId}>
                <Col span={24}>
                  {task.taskDefinitionKey === TaskDefinitionType.FIELD_RUNNER_TASK ? (
                    <SiteAcquisitionCompletedCandidatesTask
                      taskId={task.taskId}
                      title={task.taskName}
                      status={TaskStatus.processed}
                      processDefinitionId={RequestInfo.requestInfo.processDefinitionId}
                      processInstanceId={RequestInfo.requestInfo.processInstanceId}
                      created={task.endTime}
                      assigneeInfo={
                        task.taskAssignee
                          ? {
                              assigneeId: task.taskAssignee.adoid,
                              firstName: task.taskAssignee.firstName,
                              lastName: task.taskAssignee.lastName,
                            }
                          : undefined
                      }
                      taskPrettyId={task.prettyId}
                      taskActivityInstanceId={task.taskActivityInstanceId}
                      taskDefinitionKey={task.taskDefinitionKey}
                      userGroupName={RequestInfo.processMeta.groupArr}
                    />
                  ) : (
                    <TaskInfo
                      taskId={task.taskId}
                      title={task.taskName}
                      status={task.status}
                      processDefinitionId={RequestInfo.requestInfo.processDefinitionId}
                      processInstanceId={RequestInfo.requestInfo.processInstanceId}
                      created={task.endTime}
                      assigneeInfo={
                        task.taskAssignee
                          ? {
                              assigneeId: task.taskAssignee.adoid,
                              firstName: task.taskAssignee.firstName,
                              lastName: task.taskAssignee.lastName,
                            }
                          : undefined
                      }
                      taskPrettyId={task.prettyId}
                      taskActivityInstanceId={task.taskActivityInstanceId}
                      taskDefinitionKey={task.taskDefinitionKey}
                      userGroupName={RequestInfo.processMeta.groupArr}
                    />
                  )}
                </Col>
              </Row>
            )
        )}
      {inProgressTask && RequestInfo && (
        <TaskSubmitFormSelector
          taskInfo={inProgressTask}
          processDefinitionId={RequestInfo?.requestInfo.processDefinitionId}
          processInstanceId={RequestInfo.requestInfo.processInstanceId}
          activityId={RequestInfo.processMeta.activityId}
          reloadTaskList={reloadTaskList}
          groupName={RequestInfo.processMeta.groupArr}
          isFetched={isFetched}
          tasksStatus={tasksStatus}
          isMyGroupTask={isMyGroupTask}
          processDefinitionKey={RequestInfo?.requestInfo?.processDefinitionKey}
        />
      )}
    </>
  );
};

import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { task } from '../Task';

export function useNewRequestFormData(
  processDefinitionKey: string,
  adOid?: string,
  isDynamic?: boolean,
  isJira?: boolean
) {
  return useQuery(
    [QUERIES.newRequestForm, processDefinitionKey, adOid],
    async () => {
      const res = await task.getNewRequestForm(processDefinitionKey, adOid);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: isDynamic && !isJira,
    }
  );
}

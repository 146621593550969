import React, { FC, memo } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { AnnouncementDetailLayout } from './AnnouncementDetailLayout';

type Props = RouteComponentProps;

export const AnnouncementDetailComponent: FC<Props> = ({ match: { path } }) => (
  <Switch>
    <Route path={path}>
      <AnnouncementDetailLayout />
    </Route>
  </Switch>
);

export const AnnouncementDetail = memo(withRouter(AnnouncementDetailComponent));

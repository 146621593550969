import React from 'react';
import { FieldData } from 'rc-field-form/lib/interface';
import { Empty } from 'antd';
import { TType } from 'translations';
import {
  ActingAppointment,
  AllowanceForm,
  BusinessTrainingTravel,
  CashAdvance,
  ChangeBankAccountRequestForm,
  CreditCard,
  DelegationForm,
  ExpenseReportForm,
  HomeLoan,
  NewSiteAcquisitionProcessForm,
  ProfileUpdateForm,
  SelfServiceLetter,
  WorkFromHomeRequestForm,
} from 'routes/Tasks';
import { ScrapInvoiceFrom } from 'routes/Tasks/components/organisms/ServiceStartForm/ScrapInvoiceRequest';
import { NonPoRequestForm } from '../../../routes/Tasks/components/organisms/ServiceStartForm/NonPoRequest';

interface ServiceFormProps {
  serviceName: string;
  onSubmit?(): void;
  adoid: string;
  formName?: string;
  onFieldsChange?: (changedFields: FieldData[], allFields: FieldData[]) => void;
  fieldsToHide?: string[];
  t: TType;
  pdk: string;
}

const ServiceFormSelector: React.FC<ServiceFormProps> = ({
  serviceName,
  adoid,
  pdk,
  t,
  ...rest
}) => {
  const Forms = {
    Human_ResourceHR_servicesAllowance_Request: <AllowanceForm adoid={adoid} {...rest} />,
    Human_ResourceHR_servicesSelfService_Letters: <SelfServiceLetter adoid={adoid} {...rest} />,
    Human_ResourceHR_servicesBusiness_and_Training_Travel: (
      <BusinessTrainingTravel adoid={adoid} {...rest} />
    ),
    Human_ResourceHR_servicesActing_Appointment: (
      <ActingAppointment t={t} adoid={adoid} {...rest} />
    ),
    Human_ResourceTotal_RewardsHome_Loan_Request: <HomeLoan t={t} adoid={adoid} {...rest} />,
    FinanceExpensesCredit_Card_Request: (
      <CreditCard adoId={adoid} processDefinitionKey={pdk} t={t} {...rest} />
    ),
    FinanceExpensesExpense_Reimbursement: <ExpenseReportForm t={t} adoId={adoid} {...rest} />,
    FinanceExpensesCash_Advance_Request: <CashAdvance t={t} {...rest} />,
    FinanceZakat_and_VatSale_of_Asset_Invoice_Request: <ScrapInvoiceFrom t={t} {...rest} />,
    Human_ResourceHR_servicesDelegation: <DelegationForm t={t} {...rest} />,
    Site_LeaseSAQNew_Site_Acquisition: <NewSiteAcquisitionProcessForm {...rest} />,
    Human_ResourceHR_servicesProfile_Update: <ProfileUpdateForm t={t} {...rest} />,
    Human_ResourceHR_OperationsChange_Bank_Account: (
      <ChangeBankAccountRequestForm t={t} {...rest} />
    ),
    FinanceFinance_ControllingNonPO_Invoice: <NonPoRequestForm t={t} adoId={adoid} {...rest} />,
    Human_ResourceHR_servicesWork_From_Home: <WorkFromHomeRequestForm adoId={adoid} {...rest} />,
  };

  const getForm = (type: string) => {
    let comp = (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
    );
    const typeNew = type;
    const found = Object.entries(Forms).find(([k]) => k.toLowerCase() === typeNew.toLowerCase());
    if (found) {
      [, comp] = found;
    }

    return comp;
  };

  return getForm(serviceName);
};

export default ServiceFormSelector;

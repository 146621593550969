import styled from 'styled-components';
import { colors } from 'ui/elements';
import { Col, Row } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

export const FilterIconContainer = styled(Col)`
  line-height: 1px;

  svg {
    fill: ${colors.primaryColor};
  }
`;

export const UsersCardsContainer = styled(Row)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledInfiniteScroll = styled(InfiniteScroll)`
  width: 100%;
  margin-top: 24px;
`;

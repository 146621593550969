import { useQuery } from 'react-query';
import { ethicsCode } from '../EthicsCode';
import { QUERIES, PERSIST_QUERY_CONFIG } from '../../constants';

export const useGetEthicsCodeContent = (lang: 'AR' | 'EN', isEnabled: boolean) =>
  useQuery(
    [QUERIES.ethicsCodeContent, lang],
    async () => {
      const { data } = await ethicsCode.getEthicsCodeContent(lang);
      // const data = Promise.resolve(
      //   lang === 'AR'
      //     ? {
      //         subject: 'نموذج إقرار ميثاق الاخلاقيات',
      //         firstDescription:
      //           '*ميثاق الاخلاقيات لـ توال “الميثاق" يمثل التزام توال بالنزاهة. لقد قمت بقرائه وفهم الميثاق وأنه من واجبي الالتزام بما ورد فيه وطلب المشورة عندما يكون لدي استفسار يتعلق بتوضيح أو تطبيق الميثاق وأتعهد بالإبلاغ الفوري للشؤون القانونية عن أي مخالفات قد تنطوي على انتهاك للميثاق عبر القنوات المخصصة.',
      //         secondDescription:
      //           'أؤكد بانني لا اعلم ولم أشارك في أي نشاط لم يتم الإبلاغ عنه قد يمثل انتهاك للميثاق منذ قيامي بعمل اخر إقرار بالميثاق',
      //         ethicsConfirmation:
      //           'أقر بما ورد أعلاه وأؤكد أيضاً أن إخفاقي بالالتزام بالميثاق قد يؤدي الى اتخاذ إجراءات تأديبية تشتمل وقد تصل الى انهاء خدماتي في توال',
      //         attachmentName: 'TAWAL_Code_of_Ethic-Arabic',
      //         attachmentType: 'pdf',
      //         attachmentUrl:
      //           'https://s3.us-east-2.amazonaws.com/styleguide.tawal.com.sa/static/media/TAWAL_Code_of_Ethic-Arabic.pdf',
      //         language: 'AR',
      //       }
      //     : {
      //         subject: 'Code of Ethics Acknowledgement',
      //         firstDescription:
      //           'TAWAL Code of Ethics (“The Code”) is TAWAL’s commitment to integrity I have read the Code and understand that it is my obligation to abide by it, seek advice when I have a question concerning the application or interpretation of the Code and promptly Speak Up and report any concerns to Legal Affairs that may involve a violation of the Code through the approved channels.',
      //         secondDescription:
      //           'I confirm that I have neither participated in nor am I aware of any unreported activity that may constitute a violation of the Code since my last acknowledgment of the Code.',
      //         ethicsConfirmation:
      //           'I acknowledge the above and confirm that my failure to comply with the Code may lead to disciplinary action up to and including termination of my employment with TAWAL.',
      //         attachmentName: 'TAWAL_Code_of_Ethic-English',
      //         attachmentType: 'pdf',
      //         attachmentUrl:
      //           'https://s3.us-east-2.amazonaws.com/styleguide.tawal.com.sa/static/media/TAWAL_Code_of_Ethic-English.pdf',
      //         language: 'EN',
      //       }
      // );
      return data;
    },
    {
      enabled: isEnabled,
      ...PERSIST_QUERY_CONFIG,
    }
  );

import styled from 'styled-components';
import { Button, Card, Typography, Input } from 'antd';
import { colors, space, TitleH3, TitleH4, typography } from 'ui/elements';

const { Text } = Typography;

export const CategoryWrapper = styled.div``;

export const CategoryTitle = styled(TitleH4)`
  && {
    margin-bottom: ${space.cozy} !important;
  }
`;

export const StyledCard = styled(Card)`
  width: 306px;
  margin: 0 ${space.normal} ${space.normal} 0;
  cursor: pointer;
  filter: ${(props: { isAllow?: boolean }) => (props.isAllow ? 'opacity(0.5)' : 'none')};

  .ant-card-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
`;

export const StyledCardTitle = styled(TitleH3)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-top: 19px;
`;

export const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: ${colors.textColorSecondary};
  margin-top: ${space.moderate};
`;
export const StyledSearchText = styled(Text)`
  size: 15px;
  color: ${colors.primaryColor};
`;
export const StyleSearchBtn = styled(Button)`
  padding: 0px;
`;

export const StyledInput = styled(Input)`
  background-color: white;
`;

export const PathText = styled(Text)`
  font-size: ${(props: { $isPathForm?: boolean }) =>
    props.$isPathForm ? typography.size.hecto : typography.size.uno};
  color: ${colors.lightGrey};
  margin-top: ${space.cozy};
`;

import { Drawer, Row, Typography, Card } from 'antd';

import styled from 'styled-components';
import { space, colors, typography, borderRadius } from 'ui/elements';

const { Text } = Typography;

export const StyledAppsDrawer = styled(Drawer)`
  & .ant-drawer-body {
    display: flex;
    flex-direction: column;
    overflow: overlay;
  }
`;

export const StyledDrawerAppsRow = styled(Row)`
  padding: ${space.moderate} ${space.zero};
`;

export const StyledAppCard = styled(Card)`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  width: 90px;
  min-height: 120px;
  height: 100%;
  border-radius: ${borderRadius.large};
  background-color: ${colors.lightGreyFourth};
  padding: ${space.slim} ${space.mini};
  transition: background-color 0.3s;
  justify-content: center;
  .ant-card-body {
    padding: ${space.little};
  }
  &:hover {
    background-color: ${colors.lightGreyScroll};
  }
`;

export const StyledTitleDrawer = styled(Text)`
  font-size: ${typography.size.tera};
  color: ${colors.lightGrey};
  margin-left: ${space.slim};
`;

export const StyledContentWrapper = styled.div`
  flex-grow: 1;
`;

export const StyledDrawerAppName = styled.span`
  margin-top: ${space.slim};
  font-size: ${typography.size.hecto};
  color: ${colors.primaryColor};
  display: block;
  width: 90px;
`;

export const StyledAppNameText = styled(Text)`
  margin-top: ${space.slim};
  font-size: ${typography.size.hecto};
  color: ${colors.primaryColor};
  width: 100%;
  padding: 0px ${space.slim};
`;
export const StyledAppNameWrapper = styled.div`
  width: 90px;
`;

export const StyledSecondaryTitle = styled(Text)`
  font-size: ${typography.size.giga};
  color: ${colors.lightGrey};
  margin-left: ${space.slim};
`;

import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  getUserStatusStart,
  getUserStatusSuccess,
  getUserStatusFail,
  GetUserStatusFail,
  GetUserStatusStart,
  GetUserStatusSuccess,
} from './userStatusSlice';

export const getUserStatusEpic: Epic<
  GetUserStatusStart,
  GetUserStatusSuccess | GetUserStatusFail,
  RootState,
  typeof API
> = (action$, _, { userStatus }) =>
  action$.pipe(
    filter(getUserStatusStart.match),
    mergeMap(({ payload }) =>
      from(userStatus.getUserStatus()).pipe(
        map(({ data }) => getUserStatusSuccess(data)),
        catchError((error) => of(getUserStatusFail(error?.response?.data)))
      )
    )
  );

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { NotificationListData, RequestCommonError, RequestStatus } from 'types';
import { AllNotificationUpdatesListState } from './allNotificationUpdatesListType';

export const allNotificationUpdatesListsInitialState: AllNotificationUpdatesListState = {
  status: RequestStatus.idle,
  error: null,
  data: undefined,
};

export type GetAllNotificationUpdatesListStart = PayloadAction<{
  requestId: string;
  params?: AxiosRequestConfig['params'];
}>;
export type GetAllNotificationUpdatesListSuccess = PayloadAction<NotificationListData>;
export type GetAllNotificationUpdatesListFail = PayloadAction<RequestCommonError>;
export type GetAllNotificationUpdatesListRest = PayloadAction;

const allNotificationUpdatesListSlice = createSlice({
  name: 'allNotificationUpdatesList',
  initialState: allNotificationUpdatesListsInitialState,
  reducers: {
    getAllNotificationUpdatesListStart(state, _: GetAllNotificationUpdatesListStart) {
      state.status = RequestStatus.pending;
    },
    getAllNotificationUpdatesListSuccess(state, action: GetAllNotificationUpdatesListSuccess) {
      state.status = RequestStatus.resolved;
      if (!state.data?.list.length) {
        state.data = action.payload;
        return;
      }
      state.data.list = [...state.data.list, ...action.payload.list];
      state.data.limit = action.payload.limit;
      state.data.offset = action.payload.offset;
      state.data.totalResult = action.payload.totalResult;
    },
    getAllNotificationUpdatesListFil(state, action: GetAllNotificationUpdatesListFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getAllNotificationUpdatesListRest(state, _: GetAllNotificationUpdatesListRest) {
      state.status = RequestStatus.idle;
      state.error = null;
      state.data = undefined;
    },
  },
});

export const {
  getAllNotificationUpdatesListFil,
  getAllNotificationUpdatesListRest,
  getAllNotificationUpdatesListStart,
  getAllNotificationUpdatesListSuccess,
} = allNotificationUpdatesListSlice.actions;

export default allNotificationUpdatesListSlice.reducer;

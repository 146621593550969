import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';

import { TType } from 'translations';
import { PlusCircleIcon } from 'ui/components/Filter/Filter.styled';
import { StyledTable } from 'ui/components/TeamsChannelFilesList/TeamsChannelFilesList.styled';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { useProcessGroups } from 'api';
import { PageHeader } from 'routes/Calendar/page/Leave/Leaves.styled';
import { ColumnsTableType } from '../../components';
import {
  StyleContentWrapper,
  StyleUnderLineText,
  StyledGroupContent,
} from './ProcessGroups.styled';
import { ProcessGroupDrawer } from './ProcessGroupDrawer/ProcessGroupDrawer';

const { Text } = Typography;

interface Props {
  t: TType;
}

export interface SelectedProps {
  groupId: string;
  groupName: string;
}

export const ProcessGroupsComponent: FC<Props> = ({ t }) => {
  const { isLoading, data } = useProcessGroups();
  const [selectedGroup, setSelectedGroup] = useState<SelectedProps>();

  const onGroupClicked = useCallback(
    (groupId: string, groupName: string) => () => {
      if (groupId && groupName) {
        setSelectedGroup({ groupId, groupName });
      }
    },
    [setSelectedGroup]
  );

  const onDrawerClose = () => {
    setSelectedGroup(undefined);
  };
  const columns: ColumnsType<ColumnType<ColumnsTableType>> = [
    {
      key: 'groupName',
      title: 'Group Name',
      dataIndex: 'groupName',
      width: '50%',
    },
  ];

  const dataSource = useMemo(
    () =>
      data?.map(({ groupName, groupId }, index) => {
        return {
          key: index,
          groupName: (
            <Button type={'link'} onClick={onGroupClicked(groupId, groupName)}>
              <StyleUnderLineText>{groupName}</StyleUnderLineText>
            </Button>
          ),
        };
      }),
    [data, onGroupClicked]
  );

  return (
    <>
      <PageHeader size="small">
        <Row justify="start" align="middle">
          <Col>
            <Button type={'link'}>
              <PlusCircleIcon />
              <Text strong>{t('button.addFilter')}</Text>
            </Button>
          </Col>
        </Row>
      </PageHeader>
      <StyledGroupContent>
        <StyleContentWrapper>
          <StyledTable
            showSorterTooltip={false}
            loading={isLoading}
            columns={columns}
            dataSource={dataSource}
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </StyleContentWrapper>
      </StyledGroupContent>
      {selectedGroup && (
        <ProcessGroupDrawer selectedGroupInfo={selectedGroup} onDrawerClose={onDrawerClose} />
      )}
    </>
  );
};

export const ProcessGroups = memo(ProcessGroupsComponent);

import { AllListGroupMember, ProcessGroupInfo, UserTableUserProcesses } from 'types';
import {
  Core,
  LIST_ALL_MEMBER_GROUP,
  PROCESS_GROUP,
  REMOVE_USER_GROUP,
  USER_ASSIGNED_PROCESSES,
  REMOVE_USER_ASSIGNED_PROCESS,
  ADD_USER_ASSIGNED_PROCESS,
  SEARCH_PROCESSES,
} from 'api';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { ADD_USER_GROUP } from 'api/Core';

interface GetProcessGroups {
  (): Promise<{
    data: ProcessGroupInfo[];
  }>;
}

interface GetAllMemberOfGroup {
  (groupId: string): Promise<{ data: AllListGroupMember }>;
}

interface RemoveMemberFromGroup {
  (data: AxiosRequestConfig['data']): AxiosPromise<unknown>;
}

interface AddMemberToGroup {
  (data?: AxiosRequestConfig['data']): AxiosPromise<unknown>;
}

interface GetUserAssignedProcesses {
  (userId: string): Promise<{ data: UserTableUserProcesses }>;
}

interface RemoveProcessFromUserProcesses {
  (body: { selectedUserAdOid: string; processKey: number }): AxiosPromise<unknown>;
}

interface AddProcessFromUserProcesses {
  (body: { selectedUserAdOid: string; processKey: number }): AxiosPromise<unknown>;
}

interface SearchProcesses {
  (keyword: string): Promise<{ data: { id: number; name: string }[] }>;
}

class ProcessManagement extends Core {
  getProcessGroups: GetProcessGroups = async () => this.get(PROCESS_GROUP());
  getAllMemberOfGroup: GetAllMemberOfGroup = async (groupId: string) =>
    this.get(LIST_ALL_MEMBER_GROUP(groupId));
  removeMemberFromGroup: RemoveMemberFromGroup = async (data) =>
    this.post(REMOVE_USER_GROUP(), data);
  addMemberToGroup: AddMemberToGroup = async (data) => this.post(ADD_USER_GROUP(), data);
  getUserAssignedProcesses: GetUserAssignedProcesses = async (userId) =>
    this.get(USER_ASSIGNED_PROCESSES(), { params: { employeeId: userId } });
  searchProcesses: SearchProcesses = async (userId) =>
    this.get(SEARCH_PROCESSES(), { params: { employeeId: userId } });
  addProcessToUserProcesses: AddProcessFromUserProcesses = async (body) =>
    this.post(ADD_USER_ASSIGNED_PROCESS(), body);
  removeProcessFromUserProcesses: RemoveProcessFromUserProcesses = async (body) =>
    this.delete(REMOVE_USER_ASSIGNED_PROCESS(), { params: body });
}

export const processManagement = new ProcessManagement();

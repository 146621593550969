import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { colors } from 'ui/elements';

export const GlobalStyles = createGlobalStyle`
 ${normalize}
 at {
  color: ${colors.mentionTextColor};
 }

 html,
 body,
 #⚛ {
  height: 100%;
 }

 .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background-color: ${colors.labelGrey};
  color: white;

  &::after {
   border-color: white;
  }
 }

 .oneDriveOverlayPopover {
  max-width: 368px;
  width: 100%;

  .ant-popover-inner-content {
   padding: 24px;
  }
 }

 .filter-overlay {
  max-width: 620px;
  width: 620px;

  .ant-popover-inner-content {
   border-radius: 4px;
   padding: 32px;
  }
 }

 .tasks_collapse {

  &.ant-collapse {
   .ant-collapse-item-disabled {
    .ant-collapse-header {
     cursor: default;

     .ant-collapse-arrow {
      color: ${colors.lightGrey}
     }
    }
   }

   &.tasks_collapse--description {
    .ant-collapse-arrow {
     color: ${colors.lightGrey}
    }
   }

   .ant-collapse-header {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-collapse-arrow {
     top: 50% !important;
     transform: translateY(-50%);
     left: 0 !important;
     padding-left: 0 !important;
    }
   }
  }
 }

 .MSTeamsDrawer.ant-drawer {
  .ant-drawer-header {
   border-bottom: none;
  }

  .ant-drawer-content-wrapper {
   box-shadow: none;
   border: 1px solid ${colors.borderGrey};
   border-top: none;
  }
 }

 .ms-teams-confirm {
  max-width: 240px;

  .ant-popover-message-title {
   color: ${colors.textColorSecondary};
  }
 }

 ::-webkit-scrollbar {
  width: 7px;
 }


 ::-webkit-scrollbar-thumb {
  background-color: ${colors.lightGrey};
  border-radius: 6px;

  :hover {
   background-color: #555;
  }
 }

 .ant-divider {
  margin: 12px 0;
 }

 .ant-select-dropdown {
  .rc-virtual-list {
   .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${colors.backgroundGrey};
    color: ${colors.primaryColor}
   }
  }
 }

 .addProcessPopover {
  .ant-popover-arrow {
   display: none;
  }

  .ant-popover-inner-content {
   padding: 0;
  }
 }

 // Ant version update style fixes
 .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 17px;
  left: 16px;
  padding: 0 0 0 29px;
 }
 
 .ant-col-24{
  max-width: 100% !important;
 }
 
 .customBadge {
   .ant-badge-multiple-words {
     padding: 0 4px;
   }
 }

 .non-hr-popup-overlay {
  width: 160px;

  .ant-popover-inner-content {
   border-radius: 4px;
  }
 }


`;

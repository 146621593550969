import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, sendChatMessageStart, chatsData, profileData } from 'store';
import { Button, Card, Col, Row, Space } from 'antd';
import { Mention, MentionItem } from 'react-mentions';

import { useTranslate } from 'translations';
import { ChatMember, RequestStatus } from 'types';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { wrapIntoAnchorTag } from 'utils';
import { MAX_COMMENT_LENGTH } from 'ui/components/RestrictedUpload/constants';
import { colors } from 'ui/elements';
import { useMSTeamsContext } from '../../../../routes/MSTeams/MSTeamsProvider';
import {
  StyledMentionsInput,
  StyledParagraph,
  StyledSecondaryParagraph,
} from './ChatSendMessage.styled';
import { chatSendMessageStyles } from './chatSendMessageStyles';

interface ChatSendMessageProps {
  userList?: ChatMember[];
}

export const ChatSendMessage: FC<ChatSendMessageProps> = ({ userList }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { sendChatMessageStatus } = useSelector(chatsData);
  const { data } = useSelector(profileData);
  const { currentChatId } = useMSTeamsContext();
  const isLoading = sendChatMessageStatus === RequestStatus.pending;
  const [fieldValue, setFieldValue] = useState('');
  const [mentionedList, setMentionedList] = useState<MentionItem[]>([]);

  useEffect(() => {
    if (sendChatMessageStatus === RequestStatus.resolved) {
      setFieldValue('');
      inputRef.current?.focus();
    }
  }, [sendChatMessageStatus]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (
    e: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]
  ) => {
    if (e.target.value.length > MAX_COMMENT_LENGTH) return;
    setFieldValue(e.target.value);
    setMentionedList(mentions);
  };

  const submitMessage = () => {
    dispatch(
      sendChatMessageStart({
        chatId: currentChatId,
        message: {
          body: {
            contentType: 'html',
            content: wrapIntoAnchorTag(
              fieldValue
                .split('__,__')
                .map((chunk, i) => {
                  return chunk.replace(/markup_id={(.*?)}/g, `id="${i}"`);
                })
                .join('')
            ),
          },
          mentions: mentionedList.map((mention, i) => ({
            id: i,
            mentionText: mention.display,
            mentioned: {
              user: {
                id: mention.id,
                displayName: mention.display,
              },
            },
          })),
        },
      })
    );

    inputRef.current?.focus();
  };

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submitMessage();
    }
  };

  return (
    <Card>
      <Row gutter={[16, 0]} justify="center">
        <Col lg={20} flex="auto">
          {userList && (
            <StyledMentionsInput
              inputRef={inputRef}
              allowSpaceInQuery
              value={fieldValue}
              onChange={onChange}
              onKeyDown={onKeyDown}
              allowSuggestionsAboveCursor={true}
              style={chatSendMessageStyles}
              spellCheck={false}
              placeholder={t('msteams.button.sendPlaceholder')}
              maxLength={MAX_COMMENT_LENGTH}
            >
              <Mention
                markup={`<at markup_id={"__id__"}>__display__</at>__,__`}
                style={chatSendMessageStyles}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                renderSuggestion={(suggestion: any, search, highlightedDisplay) => {
                  return (
                    <Space size={10}>
                      <MSTeamsUserAvatar name={suggestion.display} id={suggestion.id as string} />

                      <div>
                        <StyledParagraph>{highlightedDisplay}</StyledParagraph>
                        <StyledSecondaryParagraph>{suggestion?.email}</StyledSecondaryParagraph>
                      </div>
                    </Space>
                  );
                }}
                appendSpaceOnAdd
                trigger="@"
                data={userList
                  .filter((user) => user.userId !== data?.adOid)
                  .map((user) => ({
                    id: user.userId,
                    display: user.displayName,
                    email: user.email,
                  }))}
              />
            </StyledMentionsInput>
          )}
          <Row justify="end">
            <Col>
              <span style={{ color: colors.lightGrey }}>
                {fieldValue.length} / {MAX_COMMENT_LENGTH}
              </span>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button disabled={isLoading} onClick={submitMessage} type="primary">
            {t('send')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

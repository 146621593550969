import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  GetOneDriveSharedLibraryFoldersListFail,
  GetOneDriveSharedLibraryFoldersListStart,
  GetOneDriveSharedLibraryFoldersListSuccess,
  getOneDriveSharedLibraryFoldersListFail,
  getOneDriveSharedLibraryFoldersListStart,
  getOneDriveSharedLibraryFoldersListSuccess,
} from './oneDriveSharedLibraryFolderListSlice';

export const getOneDriveSharedLibrarieFoldersListEpice: Epic<
  GetOneDriveSharedLibraryFoldersListStart,
  GetOneDriveSharedLibraryFoldersListSuccess | GetOneDriveSharedLibraryFoldersListFail,
  RootState,
  typeof API
> = (action$, _, { oneDriveSharedLibraryList }) =>
  action$.pipe(
    filter(getOneDriveSharedLibraryFoldersListStart.match),
    mergeMap(({ payload }) =>
      from(
        oneDriveSharedLibraryList.getOneDriveSharedLibraryList(payload.groupId, payload.folderId)
      ).pipe(
        map(({ data }) =>
          getOneDriveSharedLibraryFoldersListSuccess({
            groupId: payload.groupId,
            folderId: payload.folderId,
            data,
          })
        ),
        catchError((error) =>
          of(
            getOneDriveSharedLibraryFoldersListFail({
              groupId: payload.groupId,
              folderId: payload.folderId,
              error: error?.response?.data,
            })
          )
        )
      )
    )
  );

import { useQuery } from 'react-query';
import { leaseSites } from '../LeaseSites';
import { QUERIES } from '../../constants';
import { convertResponseToMappedCandidates } from './submittedCandidatesHelper';

export const useLeaseSitesSubmittedCandidatesForm = (taskId = '', isEnabled?: boolean) =>
  useQuery(
    [QUERIES.getLeaseSitesSubmittedCandidatesForm(taskId)],
    async () => {
      const { data } = await leaseSites.getSubmittedCandidatesForm(taskId);
      return convertResponseToMappedCandidates(data);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!taskId && isEnabled,
    }
  );

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, AttendanceUserData } from 'types';
import { AttendanceInfoState } from './attendanceInfoTypes';
import { MappedAttendanceInfoData } from './mapData';

export const emptyAttendanceInfoInitialState: AttendanceInfoState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetAttendanceInfoStart = PayloadAction<AttendanceUserData>;
export type GetAttendanceInfoSuccess = PayloadAction<MappedAttendanceInfoData>;
export type GetAttendanceInfoFail = PayloadAction<RequestCommonError>;

const attendanceInfo = createSlice({
  name: 'attendanceInfo',
  initialState: emptyAttendanceInfoInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getAttendanceInfoStart(state, _: GetAttendanceInfoStart) {
      state.status = RequestStatus.pending;
    },
    getAttendanceInfoSuccess(state, action: GetAttendanceInfoSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getAttendanceInfoFail(state, action: GetAttendanceInfoFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getAttendanceInfoStart,
  getAttendanceInfoSuccess,
  getAttendanceInfoFail,
} = attendanceInfo.actions;
export default attendanceInfo.reducer;

import React, { FC, useCallback, useEffect, useState } from 'react';
import { GoogleMapsLoader, GoogleMapsContainer } from './components';

type Props = {
  center: google.maps.LatLngLiteral;
  onMapLoad?: () => void;
  onMapInitialized?: (map: google.maps.Map) => void;
  loader?: React.ReactElement;
  zoom?: number;
  staticMarker?: { position: google.maps.LatLngLiteral; iconUrl?: string };
  removableMarker?: { position: google.maps.LatLngLiteral; iconUrl?: string };
  enableMarkerDraw?: {
    single: boolean;
    onAdd?: (markerPosition?: google.maps.LatLngLiteral, inZone?: boolean) => void;
  };
  circleZone?: {
    center: google.maps.LatLngLiteral;
    radius: number;
    onAdd?: (circle?: google.maps.Circle) => void;
    dashed?: boolean;
  };
};

export const InteractiveGoogleMap: FC<Props> = ({
  center,
  onMapLoad,
  onMapInitialized,
  loader,
  zoom,
  staticMarker,
  removableMarker,
  enableMarkerDraw,
  circleZone,
}) => {
  const [circle, setCircle] = useState<google.maps.Circle | undefined>();

  const onCircleAdd = useCallback(
    (circle?: google.maps.Circle) => {
      setCircle(circle);
      if (circleZone?.onAdd) {
        circleZone.onAdd(circle);
      }
    },
    [circleZone]
  );

  const isCircleContainsLocation = (
    location: google.maps.LatLngLiteral,
    circle: google.maps.Circle
  ) => {
    const circleCenter = circle.getCenter();
    const circleRadius = circle.getRadius();
    if (circleCenter && circleRadius) {
      const km = circleRadius / 1000;
      const kx = Math.cos((Math.PI * circleCenter.lat()) / 180) * 111;
      const dx = Math.abs(circleCenter.lng() - location.lng) * kx;
      const dy = Math.abs(circleCenter.lat() - location.lat) * 111;
      return Math.sqrt(dx * dx + dy * dy) <= km;
    }
    return false;
  };

  const onAddMarker = useCallback(
    (markerPosition?: google.maps.LatLngLiteral) => {
      let isInZone = true;
      if (circle && markerPosition) {
        isInZone = isCircleContainsLocation(markerPosition, circle);
      }
      if (enableMarkerDraw?.onAdd) {
        enableMarkerDraw.onAdd(markerPosition, isInZone);
      }
    },
    [circle, enableMarkerDraw]
  );

  useEffect(() => {
    if (!circleZone) {
      setCircle(undefined);
    }
  }, [circleZone]);

  return (
    <GoogleMapsLoader onMapLoad={onMapLoad} loader={loader}>
      <GoogleMapsContainer
        center={center}
        zoom={zoom}
        onMapInitialized={onMapInitialized}
        staticMarker={staticMarker}
        removableMarker={removableMarker}
        enableMarkerDraw={
          enableMarkerDraw ? { ...enableMarkerDraw, onAdd: onAddMarker } : undefined
        }
        circleZone={circleZone ? { ...circleZone, onAdd: onCircleAdd } : undefined}
      />
    </GoogleMapsLoader>
  );
};

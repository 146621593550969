import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { GroupMemberByLastName, RequestCommonError, RequestStatus } from 'types';
import { GroupMembersByLastNameState } from './groupMebersByLastNameTypes';

export const emptyGroupMembersByLastName: GroupMembersByLastNameState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetGroupMembersByLastNameStart = PayloadAction<{
  params: AxiosRequestConfig['params'];
  rootPath?: string;
}>;
export type GetGroupMembersByLastNameSuccess = PayloadAction<GroupMemberByLastName>;
export type GetGroupMembersByLastNameFail = PayloadAction<RequestCommonError>;

const groupMembersByLastName = createSlice({
  name: 'GroupMembersByLastName',
  initialState: emptyGroupMembersByLastName,
  reducers: {
    getGroupMembersByLastNameStart(state, _: GetGroupMembersByLastNameStart) {
      state.status = RequestStatus.pending;
    },
    getGroupMembersByLastNameSuccess(state, action: GetGroupMembersByLastNameSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getGroupMembersByLastNameFail(state, action: GetGroupMembersByLastNameFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getGroupMembersByLastNameFail,
  getGroupMembersByLastNameStart,
  getGroupMembersByLastNameSuccess,
} = groupMembersByLastName.actions;
export default groupMembersByLastName.reducer;

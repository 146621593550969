import React, { useState, useEffect, memo } from 'react';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { FileOutlined, DownloadOutlined, FolderOutlined } from '@ant-design/icons';

import { FilesList } from 'types';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useTranslate } from 'translations';
import { Breadcrumb } from './Breadcrumb';
import { folderType } from './Breadcrumb/Breadcrumb.types';
import {
  StyledTable,
  StyledFileList,
  StyledText,
  StyledLoading,
  StyledButton,
} from './TeamsChannelFilesList.styled';
import { ConnectorProps, teamsFileListConnector } from './TeamsChannelFilesList.connector';

export interface ChannelTeamsFileListProps {
  teamId: string;
  channelId: string;
  teamName?: string;
}

export const FileListComponent: React.FC<
  ChannelTeamsFileListProps & ConnectorProps & RouteComponentProps
> = ({
  channelId,
  getTeamsChannelFilesStart,
  getTeamsChannelFolderStart,
  teamId,
  teamsFileList,
  teamsFolder,
  teamName,
}) => {
  const [currentFolderId, setCurrentFolderId] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [fileSource, setFileSource] = useState<any>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<folderType[]>([]);
  const dataSource = teamsFileList?.data?.list;
  const folderData = teamsFolder;
  const { t } = useTranslate();

  useEffect(() => {
    if (teamId !== undefined && channelId !== undefined)
      getTeamsChannelFilesStart({ teamId, channelId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  const requestFolder = (folderId: string, folderName: string) => {
    setCurrentFolderId(folderId);
    getTeamsChannelFolderStart({ teamId, folderId });

    setBreadcrumbs([...breadcrumbs, { folderId: folderId, folderName: folderName }]);
  };

  const changeCurrentFolderId = (newFolderId: string) => {
    setCurrentFolderId(newFolderId);
  };

  const removerBreadcrumb = (folderIndex: number) => {
    if (folderIndex === -1) {
      setBreadcrumbs([]);
    } else setBreadcrumbs([...breadcrumbs.filter((_, index) => index <= folderIndex)]);
  };

  const convertToDataSource = (list?: FilesList['list']) =>
    list
      ? list.map((item, index) =>
          item.file
            ? {
                key: index,
                Name: <StyledText>{item?.name}</StyledText>,
                file: <FileOutlined />,
                Modified: (
                  <StyledText>
                    {moment(item?.lastModifiedDate || item.createdDate).format('DD.MM.YYYY HH.mm')}
                  </StyledText>
                ),
                modifiedBy: <StyledText>{item?.lastModifiedBy || item.createdBy}</StyledText>,
                Download: <Button type="link" icon={<DownloadOutlined />} href={item?.webUrl} />,
              }
            : {
                key: index,
                Name: (
                  <StyledButton type="link" onClick={() => requestFolder(item.id, item.name)}>
                    <StyledText>{item?.name}</StyledText>
                  </StyledButton>
                ),
                file: <FolderOutlined />,
                Modified: (
                  <StyledText>
                    {moment(item?.lastModifiedDate || item.createdDate).format('DD.MM.YYYY HH.mm')}
                  </StyledText>
                ),
                modifiedBy: <StyledText>{item?.lastModifiedBy || item.createdBy}</StyledText>,
              }
        )
      : [];

  useEffect(() => {
    if (
      teamsFolder &&
      currentFolderId !== undefined &&
      teamsFolder[currentFolderId as string].data
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setFileSource(convertToDataSource(teamsFolder[currentFolderId as string].data as any));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsFolder]);

  useEffect(() => {
    setFileSource(convertToDataSource(dataSource));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  useEffect(() => {
    setBreadcrumbs([]);
  }, [channelId]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnsType<any> = [
    {
      title: <FileOutlined />,
      dataIndex: 'file',
      key: 'file',
      width: '3%',
    },
    {
      title: t('fileList.columns.Name'),
      dataIndex: 'Name',
      key: 'Name',
      width: '30%',
    },
    {
      title: t('fileList.columns.Modified'),
      dataIndex: 'Modified',
      key: 'Modified',

      width: '30%',
    },
    {
      title: t('fileList.columns.ModifiedBy'),
      dataIndex: 'modifiedBy',
      key: 'ModifiedBy',
      width: '30%',
    },
    {
      title: t('fileList.columns.Download'),
      dataIndex: 'Download',
      key: 'Download',
      align: 'center',
      width: '30%',
    },
  ];

  return (
    <StyledFileList>
      <Breadcrumb
        breadcrumbItems={breadcrumbs}
        teamId={teamId}
        teamName={teamName}
        channelId={channelId}
        changeFolderId={changeCurrentFolderId}
        removerBreadcrumb={removerBreadcrumb}
      />
      {dataSource || folderData ? (
        <StyledTable columns={columns} dataSource={fileSource} />
      ) : (
        <StyledLoading loading={true} bordered={false} />
      )}
    </StyledFileList>
  );
};

export const TeamsChannelFile = withRouter(teamsFileListConnector(memo(FileListComponent)));

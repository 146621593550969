import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

export const TextStrong = styled(Text).attrs({
  strong: true,
})`
  font-size: 16px;
  line-height: 24px;

  &.ant-typography {
    display: block;
  }
`;

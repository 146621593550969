import styled from 'styled-components';
import { Button, Row } from 'antd';
import { colors } from 'ui/elements';

export const StyledActiveTaskControlWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px 24px;
  background-color: ${colors.white};
  margin-top: 50px;
  margin-left: -24px;
  margin-right: -24px;
`;

export const StyledCompletedButton = styled(Button)`
  background-color: ${colors.orangeShadow};
  border-color: ${colors.orangeShadow};

  &:hover,
  &:focus {
    background-color: ${colors.orange};
    border-color: ${colors.orange};
  }
`;

export const RequestHeaderWrapper = styled(Row)`
  margin-bottom: 24px;
`;

import React, { FC } from 'react';
import { MSTeamsLayout } from './MSTeamsLayout';
import { MSTeamsProvider } from './MSTeamsProvider';

export const MSTeams: FC = () => {
  return (
    <MSTeamsProvider>
      <MSTeamsLayout />
    </MSTeamsProvider>
  );
};

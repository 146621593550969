import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { TasksLayout } from 'layouts';
import { TType, withTranslate } from 'translations';
import { useLocation } from 'react-router';
import { RequestsCatalogCards, RequestCatalogTree } from '../../templates';
import { useTasksContext } from '../../../TasksProvider';
import { StyledTitle, StyledTitleCol } from './RequestCatalog.styled';

export const RequestCatalogComponent: FC<{ t: TType }> = ({ t }) => {
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>();
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | undefined>();
  const { state: routeState } = useLocation<{ catalogSearch: string }>();

  const [search, setSearch] = useState('');
  const onSearchChange = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const onCategoryClick = useCallback((name: string | undefined) => {
    setSearch('');
    setSelectedCategory(name);
  }, []);

  const onSubCategoryClick = useCallback((name: string | undefined) => {
    setSearch('');
    setSelectedSubCategory(name);
  }, []);

  const { currentActiveTab } = useTasksContext();

  useEffect(() => {
    if (currentActiveTab === '0') {
      setSearch('');
    }
  }, [currentActiveTab]);

  useEffect(() => {
    if (routeState?.catalogSearch) {
      setSearch(routeState?.catalogSearch || '');
    }
  }, [routeState?.catalogSearch]);

  return (
    <TasksLayout
      isMaxWidth
      sidebar={
        <Row>
          <StyledTitleCol span={24}>
            <StyledTitle strong>{t('tasks.requestCatalog.title')}</StyledTitle>
          </StyledTitleCol>
          <Col span={24}>
            <RequestCatalogTree
              onCategoryClick={onCategoryClick}
              onSubCategoryClick={onSubCategoryClick}
            />
          </Col>
        </Row>
      }
    >
      <RequestsCatalogCards
        selectedCategory={selectedCategory}
        selectedSubCategory={selectedSubCategory}
        search={search}
        searchTitle={search ? t('searchResults') : ''}
        onSearchChange={onSearchChange}
        key={currentActiveTab}
      />
    </TasksLayout>
  );
};

export const RequestCatalog = memo(withTranslate(RequestCatalogComponent));

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, LeavesTypesData } from 'types';
import { LeavesTypesState } from './leavesTypesTypes';

export const emptyLeavesTypesState: LeavesTypesState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetLeavesTypesStart = PayloadAction<{
  hcmPersonId: string;
}>;
export type GetLeavesTypesSuccess = PayloadAction<LeavesTypesData[]>;
export type GetLeavesTypesFail = PayloadAction<{ error: RequestCommonError }>;

const leavesTypes = createSlice({
  name: 'leavesTypes',
  initialState: emptyLeavesTypesState,
  reducers: {
    getLeavesTypesStart(state, _: GetLeavesTypesStart) {
      state.status = RequestStatus.pending;
    },
    getLeavesTypesSuccess(state, action: GetLeavesTypesSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getLeavesTypesFail(state, action: GetLeavesTypesFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload.error;
    },
  },
});

export const {
  getLeavesTypesFail,
  getLeavesTypesStart,
  getLeavesTypesSuccess,
} = leavesTypes.actions;

export default leavesTypes.reducer;

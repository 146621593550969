import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { Col, Empty, notification, Row } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslate } from 'translations';
import { PaySlipData } from 'types';
import { useGetPayslipInfo, usePaySlipsPdfFile } from 'api';
import { downloadFile } from 'utils';
import {
  StyledPayrollInfoButton,
  StyledPayrollInfoCalenderIcon,
  StyledPayrollInfoCardAmount,
  StyledPayrollInfoCardValue,
  StyledPayrollInfoContainerCard,
  StyledPayrollInfoDatePicker,
  StyledPayrollInfoDivider,
  StyledPayrollInfoMonthCard,
  StyledPayrollInfoNoDataEmpty,
  StyledPayrollInfoSectionTitle,
  StyledPayrollInfoWrapperRow,
} from './PayrollInformation.styled';

type Props = {
  hcmPersonId: string | null;
  hireDate: string;
};

export const PayrollInformation: FC<Props> = ({ hcmPersonId, hireDate }) => {
  const { t } = useTranslate();
  const [selectedYear, setSelectedYear] = useState<string>();
  const [selectedMonth, setSelectedMonth] = useState<string>();
  const [payrollData, setPayrollData] = useState<PaySlipData>([]);

  const {
    data: payslipInfoData,
    isLoading: isGetPayslipInfoLoading,
    isError: isGetPayslipInfoError,
  } = useGetPayslipInfo(hcmPersonId, selectedMonth ?? moment().format('MM/yyyy'), !!selectedMonth);
  const {
    mutateAsync: downloadPayslipFile,
    isLoading: isDownloadPayslipFileLoading,
    isError: isDownloadPayslipFileError,
  } = usePaySlipsPdfFile();

  const disableBeforeHire = useCallback(
    (current: moment.Moment) => {
      return current.isBefore(hireDate, 'months');
    },
    [hireDate]
  );

  const onClickDownloadPaySlips = () => {
    if (hcmPersonId && selectedMonth && selectedYear) {
      const month = parseInt(selectedMonth).toString();
      downloadPayslipFile({
        userId: hcmPersonId,
        selectedMonth: month,
        selectedYear: selectedYear,
      }).then((payslipFile) => {
        downloadFile(payslipFile.fileContentType, payslipFile.fileContent, payslipFile.fileName);
      });
    }
  };

  const onChangeDate = useCallback((selectedDate) => {
    if (selectedDate) {
      setSelectedYear(selectedDate.get('year').toString());
      setSelectedMonth(selectedDate.format('MM/yyyy'));
    } else {
      setSelectedYear(undefined);
      setSelectedMonth(undefined);
    }
  }, []);

  const disableDownloadButton = useMemo(() => {
    return !!(payrollData.length > 0 && selectedMonth);
  }, [selectedMonth, payrollData]);

  useEffect(() => {
    if (payslipInfoData) {
      setPayrollData(payslipInfoData.slice(0, 4));
    } else {
      setPayrollData([]);
    }
  }, [payslipInfoData]);

  useEffect(() => {
    if (isGetPayslipInfoError || isDownloadPayslipFileError) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [isGetPayslipInfoError, isDownloadPayslipFileError, t]);

  return (
    <>
      <StyledPayrollInfoContainerCard $withTitle>
        <Row justify={'space-between'}>
          <Col>
            <StyledPayrollInfoSectionTitle>
              {t('user.privateProfile.title.payroll')}
            </StyledPayrollInfoSectionTitle>
          </Col>
          <Col>
            <Row align={'middle'} justify={'space-between'}>
              <Col>
                <StyledPayrollInfoDatePicker
                  placeholder={t('user.privateProfile.payroll.datePicker.placeholder')}
                  picker={'month'}
                  format={'MMMM YY'}
                  suffixIcon={<StyledPayrollInfoCalenderIcon />}
                  disabledDate={disableBeforeHire}
                  onChange={(date) => onChangeDate(date)}
                />
              </Col>
              <Col>
                <StyledPayrollInfoButton
                  type="link"
                  onClick={onClickDownloadPaySlips}
                  loading={isDownloadPayslipFileLoading}
                  disabled={!disableDownloadButton}
                  download
                >
                  <DownloadOutlined /> {t('user.privateProfile.payroll.downloadPayslip')}
                </StyledPayrollInfoButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledPayrollInfoContainerCard>
      <StyledPayrollInfoContainerCard loading={isGetPayslipInfoLoading}>
        <StyledPayrollInfoWrapperRow gutter={[24, 24]}>
          {payrollData && payrollData.length > 0 ? (
            payrollData.map((item, index) => {
              return (
                <StyledPayrollInfoMonthCard key={`month-card-${index}`}>
                  <StyledPayrollInfoCardValue ellipsis>
                    {moment(item.paymentDate).format('MMMM YY')}
                  </StyledPayrollInfoCardValue>
                  <StyledPayrollInfoDivider />
                  <StyledPayrollInfoCardAmount>
                    {item.defaultCurrencyCode
                      ? item.defaultCurrencyCode.concat(item.amount ? ' '.concat(item.amount) : '')
                      : item.amount}
                  </StyledPayrollInfoCardAmount>
                </StyledPayrollInfoMonthCard>
              );
            })
          ) : (
            <StyledPayrollInfoNoDataEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </StyledPayrollInfoWrapperRow>
      </StyledPayrollInfoContainerCard>
    </>
  );
};

import React, { FC, useEffect } from 'react';
import { Button, Form, Popconfirm } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { TType } from 'translations';
import { FormInstance } from 'antd/lib/form';
import { CreateFormItemType } from 'types';
import { TitleH2, TitleH3 } from 'ui/elements';
import { StaticFieldsGroup } from '../../../FieldsGroup';
import {
  StyledCustomFormGroupContainer,
  StyledCustomFormGroupInnerContainer,
  StyledCustomFormGroupInnerHeader,
  StyledCustomFormGroupRemoveButton,
} from './CustomFormGroup.styled';

type Props = {
  t: TType;
  formListName: string;
  formGroupItems: (formListName: number) => CreateFormItemType[];
  maxRecords: number;
  sectionTitle?: string;
  addButtonTitle: string;
  form?: FormInstance;
  hasAtLeastOne?: boolean;
  hasInitialValue?: boolean;
  onListRemove?: (index: number) => void;
};

export const CustomFormGroupWrapper: FC<Props> = ({
  t,
  form,
  formGroupItems,
  formListName,
  maxRecords,
  sectionTitle,
  hasAtLeastOne,
  hasInitialValue,
  addButtonTitle,
  onListRemove,
}) => {
  useEffect(
    () => {
      if (hasAtLeastOne && !hasInitialValue) {
        setTimeout(() => {
          form?.setFieldsValue({ [formListName]: [{}] });
        }, 200);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasAtLeastOne, hasInitialValue]
  );
  return (
    <Form.List name={formListName}>
      {(fields, { add, remove }) => (
        <StyledCustomFormGroupContainer>
          {sectionTitle && <TitleH2>{sectionTitle}</TitleH2>}
          {fields.map((group, index) => (
            <StyledCustomFormGroupInnerContainer key={`${formListName}-${index}`}>
              <StyledCustomFormGroupInnerHeader>
                <TitleH3>#{index + 1}</TitleH3>
                {((fields.length > 1 && hasAtLeastOne) || !hasAtLeastOne) && (
                  <Popconfirm
                    title="Are you sure you want to remove this record?"
                    onConfirm={() => {
                      onListRemove?.(index);
                      remove(index);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <StyledCustomFormGroupRemoveButton type="text" icon={<DeleteOutlined />}>
                      Remove
                    </StyledCustomFormGroupRemoveButton>{' '}
                  </Popconfirm>
                )}
              </StyledCustomFormGroupInnerHeader>
              <StaticFieldsGroup
                key={`form-group-${formListName}-${group.name}`}
                t={t}
                getFormFields={() => [{ [index + 1]: formGroupItems(group.name) }]}
                form={form}
                isShowSubTitle={false}
                hideTitle={true}
                withoutSpace={true}
                fullWidth={true}
              />
            </StyledCustomFormGroupInnerContainer>
          ))}
          {fields.length < maxRecords && (
            <Button icon={<PlusOutlined />} onClick={() => add()}>
              {addButtonTitle}
            </Button>
          )}
        </StyledCustomFormGroupContainer>
      )}
    </Form.List>
  );
};

import React, { FC, Fragment } from 'react';
import { TType } from 'translations';
import { TitleH2 } from 'ui/elements';
import { FORM_FILED_BREAKPOINT } from 'routes/Tasks/components/templates';
import { DEFAULT_ITEM_SPAN } from 'routes/Dashboard/Components/organisms/constants';
import { FormInstance } from 'antd/lib/form';
import { TransformedFormField } from 'types';
import { useGetFromFieldId } from 'ui/hooks';
import { Field } from '../Field';
import { FieldSelect } from '../FieldSelect';
import { FieldTextArea } from '../FieldTextArea';
import { FieldNumber } from '../FieldNumber';
import { FieldFile } from '../FieldFile';
import { FieldDate } from '../FieldDate';
import { FieldCheckbox } from '../FieldCheckbox';
import { FieldText } from '../FieldText';
import { FieldTel } from '../FieldTel';
import { FormItemCol, StyledFieldAttachmentGroupLabel } from '../GeneratedForm.Styled';

interface FieldGroupProps {
  fields?: TransformedFormField[];
  field: TransformedFormField;
  fieldsToHide?: string[];
  form?: FormInstance;
  t: TType;
}

export const FieldGroup: FC<FieldGroupProps> = ({ fields, field, fieldsToHide, form, t }) => {
  const getFieldId = useGetFromFieldId();
  const fieldId = getFieldId(field.id);

  return (
    <Fragment>
      <FormItemCol $isVisible span={24}>
        {field?.label?.toLowerCase() === 'attachments' ? (
          <StyledFieldAttachmentGroupLabel>{field.label}</StyledFieldAttachmentGroupLabel>
        ) : (
          <TitleH2 style={{ marginBottom: 24 }}>{field.label}</TitleH2>
        )}
      </FormItemCol>
      {fields?.map((field) => {
        if (fieldsToHide?.includes(fieldId)) {
          return null;
        }
        switch (field.typeName) {
          case 'enum':
            return (
              <FormItemCol $isVisible {...FORM_FILED_BREAKPOINT}>
                <FieldSelect key={fieldId} field={field} t={t} />
              </FormItemCol>
            );
          case 'textArea':
            return (
              <FormItemCol $isVisible span={24}>
                <FieldTextArea key={fieldId} field={field} />
              </FormItemCol>
            );
          case 'long':
            return (
              <FormItemCol $isVisible {...FORM_FILED_BREAKPOINT}>
                <FieldNumber key={fieldId} field={field} />
              </FormItemCol>
            );
          case 'tel':
            return (
              <FormItemCol $isVisible {...FORM_FILED_BREAKPOINT}>
                <FieldTel key={fieldId} field={field} />
              </FormItemCol>
            );
          case 'file':
            return (
              <FormItemCol $isVisible span={DEFAULT_ITEM_SPAN}>
                <FieldFile
                  key={fieldId}
                  field={field}
                  form={form}
                  hideAttachmentLabel={Number(field?.properties?.groupName?.length) > 0}
                />
              </FormItemCol>
            );
          case 'date':
            return (
              <FormItemCol $isVisible {...FORM_FILED_BREAKPOINT}>
                <FieldDate key={fieldId} field={field} />
              </FormItemCol>
            );
          case 'boolean':
            return (
              <FormItemCol $isVisible {...FORM_FILED_BREAKPOINT}>
                <FieldCheckbox
                  key={fieldId}
                  field={field}
                  setFieldsValue={form?.setFieldsValue}
                  isBoolean={true}
                />
              </FormItemCol>
            );
          case 'text':
            return (
              <FormItemCol $isVisible span={20}>
                <FieldText key={fieldId} field={field} />
              </FormItemCol>
            );
          case 'button':
            return null;
          case 'string':
          default:
            return (
              <FormItemCol $isVisible {...FORM_FILED_BREAKPOINT}>
                <Field key={fieldId} field={field} />
              </FormItemCol>
            );
        }
      })}
    </Fragment>
  );
};

import React from 'react';
import { Row, Typography, Col } from 'antd';
import styled from 'styled-components';
import { colors, typography } from 'ui/elements';
import { Gutter } from 'antd/lib/grid/row';

interface DrawerTitleProps {
  title: string;
  subTitle?: string;
}
const { Text } = Typography;

const StyledTitle = styled(Text)`
  font-size: ${typography.size.tera};
  font-weight: ${typography.weight.bold};
  color: ${colors.primaryColor};
`;

const StyledSubTitle = styled(Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.primaryColor};
`;

const Gutter_5: [Gutter, Gutter] = [5, 0];

const DrawerTitle: React.FC<DrawerTitleProps> = ({ title, subTitle }) => {
  return (
    <React.Fragment>
      <Row gutter={Gutter_5} align="middle">
        <Col>
          <StyledTitle>{title}</StyledTitle>
        </Col>
        {subTitle ? (
          <Col>
            <StyledSubTitle>({subTitle})</StyledSubTitle>
          </Col>
        ) : null}
      </Row>
    </React.Fragment>
  );
};

export default DrawerTitle;

import styled from 'styled-components';
import { borderRadius, colors, space } from 'ui/elements';

export const SpaceDivider = styled.div`
  margin: ${space.normal} 0;
`;

export const WrapperItem = styled.div`
  background-color: ${colors.backgroundGrey};
  padding: ${space.normal};
  border-radius: ${borderRadius.large};
  margin-bottom: ${space.normal};
`;

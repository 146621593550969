import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const attendanceInfo = (state: RootState): RootState['attendanceInfo'] =>
  state.attendanceInfo;

export const attendanceInfoData = createSelector(attendanceInfo, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

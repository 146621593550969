import { Col, Row, Typography } from 'antd';
import React, { useMemo } from 'react';
import { AttachmentsItem, ExpenseItem } from 'types';

import styled from 'styled-components';
import { TType } from 'translations';
import { useRowGutter } from 'ui/hooks';
import { StyledDivider } from 'ui/components';
import { colors, space, typography } from 'ui/elements';
import { StyledAttachmentCol, StyledDetailWrapper } from '../ExpensesDrawer/ExpenseDrawer.styled';
import ItemTitle from '../Atoms/itemTitle';
import { ExpenseAttachment } from './expenseAttachment';

interface ItemCardProps {
  itemInfo: ExpenseItem;
  isHideItemDate?: boolean;
  itemAttachments: AttachmentsItem[];
  children: React.ReactNode;
  isItems?: boolean;
  t: TType;
}

const StyledGroupTitle = styled(Typography.Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.textColorSecondary};
  margin-bottom: ${space.cozy};
  display: inline-block;
`;

const ItemCardComponent: React.FC<ItemCardProps> = ({
  itemAttachments,
  itemInfo,
  children,
  isItems,
  t,
}) => {
  const gutter16 = useRowGutter(16);
  const isItemHaveAttachment = useMemo(() => itemAttachments.length > 0, [itemAttachments.length]);

  return (
    <React.Fragment>
      <StyledDetailWrapper>
        <Row>
          <Col span={24}>
            <ItemTitle itemId={itemInfo.itemLabel} title={itemInfo.itemType} isItems={isItems} />
          </Col>
          {children}
          {isItemHaveAttachment ? (
            <>
              <StyledDivider />
              <Col span={24}>
                <StyledGroupTitle>{t('attachments')}</StyledGroupTitle>
                <Row gutter={gutter16}>
                  <StyledAttachmentCol span={24}>
                    {itemAttachments.map(
                      ({ id, itemId, taskId, name }) =>
                        itemId === itemInfo.id && (
                          <ExpenseAttachment id={id} taskId={taskId} name={name} key={id} />
                        )
                    )}
                  </StyledAttachmentCol>
                </Row>
              </Col>
            </>
          ) : null}
        </Row>
      </StyledDetailWrapper>
    </React.Fragment>
  );
};

export const ItemCard = React.memo(ItemCardComponent);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus } from 'types';
import { ReassignTaskState } from './reassignTaskTypes';

export const emptyReassignTaskState: ReassignTaskState = {
  status: RequestStatus.idle,
  error: null,
};

export type PostReassignTaskStart = PayloadAction<{
  taskId: string;
  userId: string;
  rootPath?: string;
}>;
export type PostReassignTaskSuccess = PayloadAction;
export type PostReassignTaskFail = PayloadAction<RequestCommonError>;
export type PostReassignTaskReset = PayloadAction;

const reassignTask = createSlice({
  name: 'reassignTask',
  initialState: emptyReassignTaskState,
  reducers: {
    postReassignTaskStart(state, _: PostReassignTaskStart) {
      state.status = RequestStatus.pending;
    },
    postReassignTaskSuccess(state, _: PostReassignTaskSuccess) {
      state.status = RequestStatus.resolved;
    },
    postReassignTaskFail(state, action: PostReassignTaskFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    postReassignTaskReset(state, _: PostReassignTaskReset) {
      state.status = RequestStatus.idle;
      state.error = null;
    },
  },
});
export const {
  postReassignTaskFail,
  postReassignTaskStart,
  postReassignTaskSuccess,
  postReassignTaskReset,
} = reassignTask.actions;
export default reassignTask.reducer;

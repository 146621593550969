import { CamundaUser } from 'types';
import { CAMUNDA_USER, Core } from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetUser {
  (prams: AxiosRequestConfig['params']): Promise<{ data: CamundaUser[] }>;
}

class User extends Core {
  getCamundaUser: GetUser = async (params) => this.get(CAMUNDA_USER(), params);
}

export const camundaUser = new User();

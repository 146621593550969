import {
  ILeaseSitesMappedCandidateItem,
  ILeaseSitesMappedCandidates,
  ILeaseSitesSubmittedCandidateItem,
  ILeaseSitesSubmittedCandidateItemAttachments,
  ILeaseSitesSubmittedCandidates,
  ILILeaseMappedCandidateItemGroupField,
  ILILeaseMappedCandidateItemGroupFieldAttachment,
} from 'types';

interface IMappedAttachments {
  [key: string]: ILILeaseMappedCandidateItemGroupFieldAttachment[];
}

export const convertResponseToMappedCandidates = (
  response: ILeaseSitesSubmittedCandidates
): ILeaseSitesMappedCandidates => {
  const mappedResponse: ILeaseSitesMappedCandidates = {
    candidatesList: [],
    feedback: response.feedback,
  };
  response.fieldRunnerCandidatesDraftList?.forEach((responseCandidate) => {
    const candidateMappedAttachments = mapCandidatesAttachments(
      responseCandidate.fieldRunnerCandidatesAttachment
    );
    const candidate: ILeaseSitesMappedCandidateItem = {
      candidateNumber: responseCandidate.candidateNumber,
      mapInfo: {
        zone: {
          center: {
            lat: response.requestLocationDetails.latitude,
            lng: response.requestLocationDetails.longitude,
          },
          radius: response.requestLocationDetails.radius,
        },
        markerPosition: { lat: responseCandidate.latitude, lng: responseCandidate.longitude },
      },
      candidateInformationGroup: [
        {
          name: 'Essential Property Information',
          fields: getEssentialPropertyInformationFields(
            responseCandidate,
            candidateMappedAttachments
          ),
        },
        {
          name: 'Comprehensive Property Information',
          fields: getComprehensivePropertyInformation(responseCandidate),
        },
        {
          name: 'Property Documents',
          fields: getPropertyDocuments(responseCandidate, candidateMappedAttachments),
        },
        {
          name: 'ISR/MOU/COI Documents',
          fields: getISRDocuments(candidateMappedAttachments),
        },
        {
          name: 'Essential Landlord Information',
          fields: getEssentialLandlordInformation(responseCandidate),
        },
        {
          name: 'Comprehensive Landlord Information',
          fields: getComprehensiveLandlordInformation(responseCandidate),
        },
        {
          name: 'Landlord Documents',
          fields: getLandlordDocuments(candidateMappedAttachments),
        },
        {
          name: 'Delegate Details',
          fields: getDelegateDetails(responseCandidate, candidateMappedAttachments),
        },
        {
          name: 'Account Details',
          fields: getAccountDetails(responseCandidate, candidateMappedAttachments),
        },
      ],
    };
    mappedResponse.candidatesList.push(candidate);
  });
  return mappedResponse;
};

const mapCandidatesAttachments = (
  responseCandidateAttachments: ILeaseSitesSubmittedCandidateItemAttachments[]
): IMappedAttachments => {
  const mappedAttachments: IMappedAttachments = {};
  responseCandidateAttachments.forEach((attachment) => {
    if (!Object.prototype.hasOwnProperty.call(mappedAttachments, attachment.attachmentLabel)) {
      mappedAttachments[attachment.attachmentLabel] = [];
    }
    mappedAttachments[attachment.attachmentLabel].push({
      name: attachment.name,
      id: attachment.id,
      taskId: attachment.taskId,
    });
  });
  return mappedAttachments;
};

const getEssentialPropertyInformationFields = (
  responseCandidate: ILeaseSitesSubmittedCandidateItem,
  responseMappedAttachments: IMappedAttachments
): ILILeaseMappedCandidateItemGroupField[] => {
  return [
    {
      key: 'Candidate ID',
      value: responseCandidate.id,
    },
    {
      key: 'Property timestamp',
      value: responseCandidate.propertyTimestamp,
    },
    {
      key: 'Site neighborhood',
      value: responseCandidate.siteNeighbourhood,
    },
    {
      key: 'Leased space type',
      value: responseCandidate.leasedSpaceTypes?.value,
    },
    {
      key: 'Site type',
      value: responseCandidate.siteTypes?.value,
    },
    {
      key: 'Distance from nominal (in Meter)',
      value: responseCandidate.distanceFromNominal,
    },
    {
      key: 'Latitude',
      value: responseCandidate.latitude,
    },
    {
      key: 'Longitude',
      value: responseCandidate.longitude,
    },
    {
      key: 'Power source type',
      value: responseCandidate.powerSourceTypes?.value,
    },
    {
      key: 'Available power source from owner to be used by Tawal',
      value: responseCandidate.powerSourceFromOwner,
    },
    {
      key: 'Owner approval to install Sub Station',
      value: responseCandidate.ownerApproval?.key,
    },
    {
      key: 'Access road',
      value: responseCandidate.accessRoadRequired?.key,
    },
    {
      key: 'Site area rooftop',
      value: responseCandidate.siteAreaRooftop,
    },
    {
      key: 'Site area greenfield (in length*width)',
      value: responseCandidate.siteAreaGreenfield,
    },
    {
      key: 'Power source distance from substation',
      value: responseCandidate.powerSourceDistance?.key,
    },
    {
      key: 'Nearest operator tower',
      value: responseCandidate.nearestOperatorTower,
    },
    {
      key: 'Distance from nearest operator tower',
      value: responseCandidate.distanceFromNearestTower,
    },
    {
      key: 'Candidate complied with MOMRA regulations?',
      value: responseCandidate.momraRegulations?.key,
    },
    {
      key: 'Owner deed and ID doc (to be provided)',
      value: responseCandidate.ownerDeed?.key,
    },
    {
      key: 'Penthouse available?',
      value: responseCandidate.penthouseAvailable?.key,
    },
    {
      key: 'Obstruction(s) exist?',
      value: responseCandidate.obstructionExist?.key,
    },
    {
      key: 'Obstruction(s) photo(s)',
      isAttachment: true,
      value: responseMappedAttachments['obstructionPhoto'],
    },
    {
      key: 'Approx.building height without parapet (In Meter)',
      value: responseCandidate.buildingHeightWithoutParapet,
    },
    {
      key: 'Site address',
      value: responseCandidate.siteAddress,
    },
    {
      key: 'Region',
      value: responseCandidate.region?.value,
    },
    {
      key: 'District',
      value: responseCandidate.district?.value,
    },
    {
      key: 'City',
      value: responseCandidate.city?.value,
    },
    {
      key: 'Site street',
      value: responseCandidate.siteStreet,
    },
    {
      key: 'Distance from road (in Meter)',
      value: responseCandidate.distanceFromRoad,
    },
    {
      key: 'Lease area (Sq Meter)',
      value: responseCandidate.leaseArea,
    },
    {
      key: 'Nearest electricity point distance (In Meter)',
      value: responseCandidate.nearestElectricityPoint,
    },
    {
      key: 'Landlord price (quoted)',
      value: responseCandidate.landlordPrice,
    },
  ];
};

const getComprehensivePropertyInformation = (
  responseCandidate: ILeaseSitesSubmittedCandidateItem
): ILILeaseMappedCandidateItemGroupField[] => {
  return [
    {
      key: 'Civil ranking',
      value: responseCandidate.candidatesProperty?.civilRanking,
    },
    {
      key: 'Approx.penthouse height (in Meter)',
      value: responseCandidate.candidatesProperty?.pentHouseHeight,
    },
    {
      key: 'SCECO Distance (In Meter)',
      value: responseCandidate.candidatesProperty?.scecoDistance,
    },
    {
      key: 'Number of buildings',
      value: responseCandidate.candidatesProperty?.numberOfBuildings,
    },
    {
      key: 'Total real estate space',
      value: responseCandidate.candidatesProperty?.totalRealEstateSpace,
    },
    {
      key: 'Building height',
      value: responseCandidate.candidatesProperty?.buildingHeight,
    },
    {
      key: 'Remarks',
      value: responseCandidate.candidatesProperty?.remarks,
    },
  ];
};

const getPropertyDocuments = (
  responseCandidate: ILeaseSitesSubmittedCandidateItem,
  responseMappedAttachments: IMappedAttachments
): ILILeaseMappedCandidateItemGroupField[] => {
  return [
    {
      key: 'Property ownership document',
      isAttachment: true,
      value: responseMappedAttachments['landlordPropertyOwnerShip'],
    },
    {
      key: 'Property pictures',
      isAttachment: true,
      value: responseMappedAttachments['proPicture'],
    },
    {
      key: 'Map photo',
      isAttachment: true,
      value: responseMappedAttachments['map'],
    },
    {
      key: 'Photo of front view',
      isAttachment: true,
      value: responseMappedAttachments['photoFront'],
    },
    {
      key: 'Layout',
      isAttachment: true,
      value: responseMappedAttachments['layout'],
    },
    {
      key: 'Other property documents',
      isAttachment: true,
      value: responseMappedAttachments['proOtherDocument'],
    },
    {
      key: 'Land Deed is available?',
      value: responseCandidate.candidatesProperty?.landDeedIsAvailable?.key,
    },
    {
      key: 'Land deed',
      isAttachment: true,
      value: responseMappedAttachments['landDeed'],
    },
  ];
};

const getISRDocuments = (
  responseMappedAttachments: IMappedAttachments
): ILILeaseMappedCandidateItemGroupField[] => {
  return [
    {
      key: 'ISR document',
      isAttachment: true,
      value: responseMappedAttachments['isrDocument'],
    },
    {
      key: 'COI disclosure form',
      isAttachment: true,
      value: responseMappedAttachments['coiDocument'],
    },
    {
      key: 'MOU document',
      isAttachment: true,
      value: responseMappedAttachments['mouDocument'],
    },
  ];
};

const getEssentialLandlordInformation = (
  responseCandidate: ILeaseSitesSubmittedCandidateItem
): ILILeaseMappedCandidateItemGroupField[] => {
  return [
    {
      key: 'Landlord ID',
      value: responseCandidate.candidatesLandlord?.landlordId,
    },
    {
      key: 'Candidate eligible for site?',
      value: responseCandidate.candidatesLandlord?.candidateEligibleForSite?.key,
    },
    {
      key: 'Justification for ineligible candidate',
      value: responseCandidate.candidatesLandlord?.justificationForIneligibleCandidate,
    },
    {
      key: 'Landlord national ID number',
      value: responseCandidate.candidatesLandlord?.landlordNationalIdNumber,
    },
    {
      key: 'Commercial registration number',
      value: responseCandidate.candidatesLandlord?.commercialRegistrationNumber,
    },
    {
      key: 'Landlord type',
      value: responseCandidate.candidatesLandlord?.landlordTypes?.value,
    },
    {
      key: 'Landlord government type',
      value: responseCandidate.candidatesLandlord?.landlordGovernmentTypes?.value,
    },
    {
      key: 'Landlord name',
      value: responseCandidate.candidatesLandlord?.landlordName,
    },
    {
      key: 'Landlord name Arabic',
      value: responseCandidate.candidatesLandlord?.landlordNameArabic,
    },
    {
      key: 'Landlord country',
      value: responseCandidate.candidatesLandlord?.landlordCountries?.value,
    },
    {
      key: 'Tax organization type',
      value: responseCandidate.candidatesLandlord?.taxOrganizationTypes?.value,
    },
    {
      key: 'Landlord district',
      value: responseCandidate.candidatesLandlord?.landlordDistrict?.value,
    },
    {
      key: 'Landlord city',
      value: responseCandidate.candidatesLandlord?.landlordCity?.value,
    },
    {
      key: 'National PO box number',
      value: responseCandidate.candidatesLandlord?.nationalPoBoxNumber,
    },
    {
      key: 'Is VAT applicable?',
      value: responseCandidate.candidatesLandlord?.landlordIsVatApplicable?.key,
    },
    {
      key: 'VAT number',
      value: responseCandidate.candidatesLandlord?.vatNumber,
    },
  ];
};

const getComprehensiveLandlordInformation = (
  responseCandidate: ILeaseSitesSubmittedCandidateItem
): ILILeaseMappedCandidateItemGroupField[] => {
  return [
    {
      key: 'Landlord street',
      value: responseCandidate.candidatesLandlord?.landlordStreet,
    },
    {
      key: 'Country code',
      value: responseCandidate.candidatesLandlord?.countryCode,
    },
    {
      key: 'Landlord Phone Country Code',
      value: responseCandidate.candidatesLandlord?.landlordCountryPhoneCode,
    },
    {
      key: 'Landlord telephone',
      value: responseCandidate.candidatesLandlord?.landlordTelephone,
    },
    {
      key: 'Landlord email',
      value: responseCandidate.candidatesLandlord?.landlordEmail,
    },
    {
      key: 'Justification for special case',
      value: responseCandidate.candidatesLandlord?.justificationForSpecialCase,
    },
    {
      key: 'Nationality In English',
      value: responseCandidate.candidatesLandlord?.nationalityEnglish,
    },
    {
      key: 'Nationality code',
      value: responseCandidate.candidatesLandlord?.nationalityCode,
    },
    {
      key: 'Special access request required',
      value: responseCandidate.candidatesLandlord?.specialAccessRequestRequired,
    },
    {
      key: 'Date of Birth In Gregorian',
      value: responseCandidate.candidatesLandlord?.dateOfBirthInGregorian,
    },
    {
      key: 'Date of Birth In Hijri',
      value: responseCandidate.candidatesLandlord?.dateOfBirthInHijri,
    },
    {
      key: 'Family name In English',
      value: responseCandidate.candidatesLandlord?.familyName,
    },
    {
      key: 'Used language',
      value: responseCandidate.candidatesLandlord?.usedLanguage,
    },
    {
      key: 'First name in English',
      value: responseCandidate.candidatesLandlord?.firstNameInEnglish,
    },
    {
      key: 'First name in Arabic',
      value: responseCandidate.candidatesLandlord?.firstNameInArabic,
    },
    {
      key: 'Father’s name in English',
      value: responseCandidate.candidatesLandlord?.fatherNameInEnglish,
    },
    {
      key: 'Father’s name in Arabic',
      value: responseCandidate.candidatesLandlord?.fatherNameInArabic,
    },
    {
      key: 'Grandfather’s name in English',
      value: responseCandidate.candidatesLandlord?.grandfatherNameInEnglish,
    },
    {
      key: 'Grandfather’s name in Arabic',
      value: responseCandidate.candidatesLandlord?.grandfatherNameInArabic,
    },
    {
      key: 'Issuance date in Gregorian',
      value: responseCandidate.candidatesLandlord?.issuanceDateInGregorian,
    },
    {
      key: 'Issuance date in Hijri',
      value: responseCandidate.candidatesLandlord?.issuanceDateInHijri,
    },
    {
      key: 'Issuance place in English',
      value: responseCandidate.candidatesLandlord?.issuancePlaceInEnglish,
    },
    {
      key: 'Issuance place in Arabic',
      value: responseCandidate.candidatesLandlord?.issuancePlaceInArabic,
    },
    {
      key: 'Iqama expiration date In Gregorian',
      value: responseCandidate.candidatesLandlord?.iqamaExpirationDateInGregorian,
    },
    {
      key: 'Iqama expiration date In Hijri',
      value: responseCandidate.candidatesLandlord?.iqamaExpirationDateInHijri,
    },
    {
      key: 'National ID expiration date In Gregorian',
      value: responseCandidate.candidatesLandlord?.nationalIdExpirationDateGregorian,
    },
    {
      key: 'National ID expiration date In Hijri',
      value: responseCandidate.candidatesLandlord?.nationalIdExpirationDateHijri,
    },
    {
      key: 'ID version number',
      value: responseCandidate.candidatesLandlord?.idVersionNumber,
    },
    {
      key: 'Gender',
      value: responseCandidate.candidatesLandlord?.gender?.key,
    },
    {
      key: 'Commercial registration expiry date In Gregorian',
      value: responseCandidate.candidatesLandlord?.commercialRegistrationExpiryDate,
    },
    {
      key: 'Job title',
      value: responseCandidate.candidatesLandlord?.jobTitle,
    },
    {
      key: 'Building number',
      value: responseCandidate.candidatesLandlord?.buildingNumber,
    },
    {
      key: 'Company name',
      value: responseCandidate.candidatesLandlord?.company,
    },
    {
      key: 'Secondary numbers',
      value: responseCandidate.candidatesLandlord?.secondaryNumbers,
    },
    {
      key: 'Unit number',
      value: responseCandidate.candidatesLandlord?.unitNumber,
    },
    {
      key: 'Postal code',
      value: responseCandidate.candidatesLandlord?.postalCode,
    },
  ];
};

const getLandlordDocuments = (
  responseMappedAttachments: IMappedAttachments
): ILILeaseMappedCandidateItemGroupField[] => {
  return [
    {
      key: 'Profile picture',
      isAttachment: true,
      value: responseMappedAttachments['landlordProfile'],
    },
    {
      key: 'VAT certificate',
      isAttachment: true,
      value: responseMappedAttachments['landlordVar'],
    },
    {
      key: 'ID verification picture',
      isAttachment: true,
      value: responseMappedAttachments['landlordIDPicture'],
    },
    {
      key: 'Commercial registration document',
      isAttachment: true,
      value: responseMappedAttachments['landlordCommercialDocument'],
    },
    {
      key: 'Government registration copy',
      isAttachment: true,
      value: responseMappedAttachments['landlordGovernmentRegis'],
    },
    {
      key: 'Property ownership',
      isAttachment: true,
      value: responseMappedAttachments['proOwnerDocument'],
    },
    {
      key: 'Authorized signatory',
      isAttachment: true,
      value: responseMappedAttachments['landlordAuthorizedSignatory'],
    },
    {
      key: 'Deed list of heirs',
      isAttachment: true,
      value: responseMappedAttachments['landlordDeedListHeirs'],
    },
    {
      key: 'Endowment caretaker',
      isAttachment: true,
      value: responseMappedAttachments['landlordEndowmentCaretaker'],
    },
    {
      key: 'Electricity acknowledgment',
      isAttachment: true,
      value: responseMappedAttachments['landlordElectricityAcknowledgment'],
    },
    {
      key: 'Evaluation report',
      isAttachment: true,
      value: responseMappedAttachments['landlordEvaluationReport'],
    },
    {
      key: 'Other lease documents',
      isAttachment: true,
      value: responseMappedAttachments['landlordOtherLeaseDocument'],
    },
    {
      key: 'SADAD document',
      isAttachment: true,
      value: responseMappedAttachments['landlordSADADDocument'],
    },
    {
      key: 'National address document',
      isAttachment: true,
      value: responseMappedAttachments['landlordNationalAddressDocument'],
    },
    {
      key: 'Power of attorney letter',
      isAttachment: true,
      value: responseMappedAttachments['landlordPowerAttorney'],
    },
    {
      key: 'Inheritance document',
      isAttachment: true,
      value: responseMappedAttachments['landlordInheritanceDocument'],
    },
    {
      key: 'Official price quotation',
      isAttachment: true,
      value: responseMappedAttachments['landlordOfficialPriceQuotation'],
    },
    {
      key: 'No objection letter',
      isAttachment: true,
      value: responseMappedAttachments['landlordNOC'],
    },
    {
      key: 'Proof of eligibility to sign for the rent',
      isAttachment: true,
      value: responseMappedAttachments['landlordProofElegibility'],
    },
    {
      key: 'Investment contract',
      isAttachment: true,
      value: responseMappedAttachments['landlordInvestmentContract'],
    },
    {
      key: 'Contract',
      isAttachment: true,
      value: responseMappedAttachments['landlordContract'],
    },
  ];
};

const getDelegateDetails = (
  responseCandidate: ILeaseSitesSubmittedCandidateItem,
  responseMappedAttachments: IMappedAttachments
): ILILeaseMappedCandidateItemGroupField[] => {
  return [
    {
      key: 'Delegate ID',
      value: responseCandidate.candidatesDelegate?.delegateId,
    },
    {
      key: 'Delegate national ID number',
      value: responseCandidate.candidatesDelegate?.delegateNationalId,
    },
    {
      key: 'Delegate expiry date',
      value: responseCandidate.candidatesDelegate?.delegateExpiryDate,
    },
    {
      key: 'Delegate roles',
      value: responseCandidate.candidatesDelegate?.delegateRoles,
    },
    {
      key: 'Delegate name',
      value: responseCandidate.candidatesDelegate?.delegateName,
    },
    {
      key: 'Delegate commercial registration number',
      value: responseCandidate.candidatesDelegate?.delegateCommercialRegistration,
    },
    {
      key: 'Delegate country',
      value: responseCandidate.candidatesDelegate?.delegateCountry?.value,
    },
    {
      key: 'Delegate district',
      value: responseCandidate.candidatesDelegate?.delegateDistrict?.value,
    },
    {
      key: 'Delegate city',
      value: responseCandidate.candidatesDelegate?.delegateCity?.value,
    },
    {
      key: 'Delegate start date',
      value: responseCandidate.candidatesDelegate?.delegateStartDate,
    },
    {
      key: 'Is VAT applicable?',
      value: responseCandidate.candidatesDelegate?.delegateIsVatApplicable?.key,
    },
    {
      key: 'Delegation letter',
      isAttachment: true,
      value: responseMappedAttachments['landlordDelegationLetter'],
    },
  ];
};

const getAccountDetails = (
  responseCandidate: ILeaseSitesSubmittedCandidateItem,
  responseMappedAttachments: IMappedAttachments
): ILILeaseMappedCandidateItemGroupField[] => {
  return [
    {
      key: 'Payment method',
      value: responseCandidate.candidatesPayment?.paymentMethods?.value,
    },
    {
      key: 'Account number',
      value: responseCandidate.candidatesPayment?.accountNumber,
    },
    {
      key: 'Currency code',
      value: responseCandidate.candidatesPayment?.currencyCode,
    },
    {
      key: 'Bank name',
      value: responseCandidate.candidatesPayment?.bank?.value,
    },
    {
      key: 'Bank branch name',
      value: responseCandidate.candidatesPayment?.bankBranch?.value,
    },
    {
      key: 'SADAD document',
      isAttachment: true,
      value: responseMappedAttachments['accountSADADDocument'],
    },
  ];
};

import React, { useCallback, useMemo } from 'react';
import { TType } from 'translations';
import moment from 'moment';
import { IExperience } from 'types/NewProfile';
import { PrivateProfileExperienceItem } from './PrivateProfileExperienceItem';

type Props = {
  t: TType;
  experiences: IExperience[];
};

export const PrivateProfileExperience: React.FC<Props> = ({ t, experiences }) => {
  const dataExperiences = useMemo(() => {
    return experiences;
  }, [experiences]);

  const period = useCallback((periodFrom: string, periodTo: string) => {
    const mPeriodFrom = moment(periodFrom).format('DD.MM.YYYY');
    const mPeriodTo = moment(periodTo).format('DD.MM.YYYY');
    return `${mPeriodFrom} - ${mPeriodTo}`;
  }, []);

  return (
    <>
      {dataExperiences.map((item, index) => (
        <PrivateProfileExperienceItem
          industry={item.industry}
          yearsOfExperience={item.yearsOfExperience?.toString()}
          period={period(item.periodFrom, item.periodTo)}
          t={t}
          key={index}
          companyName={item.organizationName}
          position={item.positionTitle}
          experienceAttachmentList={item.experienceAttachmentList}
        />
      ))}
    </>
  );
};

import styled from 'styled-components';
import { Card, Divider, Row, Col } from 'antd';
import { colors, space } from 'ui/elements';

type Props = {
  readonly $isMaxWidth?: boolean;
  readonly bgColor?: string;
};

export const StyledRowLayout = styled(Row)`
  margin: ${space.normal as string};
`;

export const StyledContainer = styled.div`
  margin: 24px 24px 0;
  height: 100%;
`;

export const StyledDashboardCard = styled(Card).attrs({})`
  min-height: 100vh;
  background: ${(props: Props) => props.bgColor ?? colors.white};
  .ant-card-body {
    padding: ${(props: Props) => (props.$isMaxWidth ? '0' : '24px')};
  }
  width: 100%;
  overflow-x: hidden;
`;

export const StyledDivider = styled(Divider)`
  background-color: ${(props: { bgColor?: string }) => props.bgColor ?? colors?.borderGreySecond};
`;

export const StyledCMSCarouselContainer = styled(Col)`
  margin-bottom: 65px;
`;

import { FormInstance, Rule } from 'antd/lib/form';
import React, { FC, Fragment, memo } from 'react';
import { normFile } from 'routes/Calendar/page';
import { StaticFormTemplate } from 'routes/Tasks/components';
import { TType } from 'translations';
import { CreateFormItemType, CreditCardData } from 'types';
import {
  CommonFieldName,
  CreditCardName,
  DEFAULT_TOTAL_LETTER,
  WRAPPER_COL_SPAN_SIZE_21,
} from 'ui';
import { FORM_ITEM_FILE_RULES, FORM_ITEM_REQUIRED_RULE } from 'utils';

interface CreditCardFieldsProps {
  t: TType;
  formFieldDate?: CreditCardData;
  form?: FormInstance;
}

const CreditCardFieldsComponents: FC<CreditCardFieldsProps> = ({ t, formFieldDate, form }) => {
  const REQUIRED_FORM_ITEM_MAX_LENGTH_RULES: Rule[] = [
    {
      max: formFieldDate?.maxAmount,
      required: true,
      type: 'number',
    },
  ];
  const getItems = () => {
    const res: CreateFormItemType[] = [];
    res.push(
      {
        id: `${0}`,
        label: t(`creditCard.staticForm.label.costCenterDepartment`),
        name: CreditCardName.costCenterDepartment,
        type: 'input',
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        disabled: true,
      },
      {
        id: `${1}`,
        label: t('creditCard.staticForm.label.totalAmount'),
        name: CreditCardName.totalAmount,
        type: 'inputNumber',
        maxNumber: formFieldDate?.maxAmount,
        rules: REQUIRED_FORM_ITEM_MAX_LENGTH_RULES,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
      },
      {
        id: `${2}`,
        label: t('creditCard.staticForm.label.description'),
        name: CommonFieldName.description,
        type: 'textArea',
        maxNumber: DEFAULT_TOTAL_LETTER,
        placeholder: t('tasks.textArea.placeHolder'),
        hasFeedback: true,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        rules: FORM_ITEM_REQUIRED_RULE,
      },
      {
        id: `${3}`,
        label: t('commonField.staticForm.label.attachment'),
        name: CommonFieldName.attachment,
        type: 'file',
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        valuePropName: 'fileList',
        getValueFromEvent: normFile,
        rules: FORM_ITEM_FILE_RULES,
      }
    );
    return res;
  };

  return (
    <Fragment>
      <StaticFormTemplate
        t={t}
        getItems={getItems}
        description={formFieldDate?.description}
        form={form}
      />
    </Fragment>
  );
};

export const CreditCardFields = memo(CreditCardFieldsComponents);

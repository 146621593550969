import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { leaves } from '../Leaves';

export function useLeaveTypes() {
  return useQuery(
    [QUERIES.allRequests],
    async () => {
      const { data } = await leaves.getLeavesAllTypes();
      return data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );
}

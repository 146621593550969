import { UploadFile } from 'antd/lib/upload/interface';
import { CreateFormItemType, NONPOLookupItem, SelectOptions } from 'types';
import { WRAPPER_COL_SPAN_SIZE_21 } from 'ui';
import { TType } from 'translations';
import { ENGLISH_FIELDS_ALPHA_NUMERIC_RULES, FORM_ITEM_REQUIRED_RULE } from './formValidation';

export interface FormItemOnFileChange extends CreateFormItemType {
  onFileChange?: (file?: UploadFile | UploadFile[]) => void;
  t: TType;
}

export const createFormField = (
  props: FormItemOnFileChange,
  withoutNumericRule?: boolean
): FormItemOnFileChange => {
  return {
    ...props,
    wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
    rules: [
      ...(withoutNumericRule ? [] : ENGLISH_FIELDS_ALPHA_NUMERIC_RULES(false, props.t)),
      ...(props.rules ?? FORM_ITEM_REQUIRED_RULE),
    ],
    selectTheValueAsKey: true,
  };
};

export const getSelectOptionObFromNonPoLookupItem = (
  nonPoItem: NONPOLookupItem[]
): SelectOptions => {
  const selectOptions: SelectOptions = [];
  const set = new Set();
  nonPoItem.forEach(({ key, value }) => {
    if (!set.has(key)) {
      set.add(key);
      selectOptions.push({ label: value, value: key });
    }
  });
  // return selectOptions.sort((a, b) => {
  //   const textA = a.label.toLowerCase();
  //   const textB = b.label.toLowerCase();
  //   return textA < textB ? -1 : textA > textB ? 1 : 0;
  // });
  return selectOptions;
};

export const getSelectOptionObFromNonPoLookupItemKeyAsLabel = (
  nonPoItem: NONPOLookupItem[]
): SelectOptions => {
  const selectOptions: SelectOptions = [];
  const set = new Set();
  nonPoItem.forEach(({ key, value }) => {
    if (!set.has(key)) {
      set.add(key);
      selectOptions.push({ label: key, value: value });
    }
  });
  // return selectOptions.sort((a, b) => {
  //   const textA = a.label.toLowerCase();
  //   const textB = b.label.toLowerCase();
  //   return textA < textB ? -1 : textA > textB ? 1 : 0;
  // });
  return selectOptions;
};

export const getSelectOptionObFromNonPoLookupWHTRequestDetail = (
  nonPoItem: NONPOLookupItem[]
): SelectOptions => {
  const selectOptions: SelectOptions = [];
  const set = new Set();
  nonPoItem.forEach(({ key, value }) => {
    if (!set.has(key)) {
      set.add(key);
      selectOptions.push({ label: `${value}`, value: value });
    }
  });
  // return selectOptions.sort((a, b) => {
  //   const textA = a.label.toLowerCase();
  //   const textB = b.label.toLowerCase();
  //   return textA < textB ? -1 : textA > textB ? 1 : 0;
  // });
  return selectOptions;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { RequestCommonError, RequestStatus, PaySlipData } from 'types';
import { PaySlipsState } from './paySlipsTyps';

export const emptyPaySlipsInitialState: PaySlipsState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetPaySlipsStart = PayloadAction<{
  userId: string;
  year: string;
  params?: AxiosRequestConfig['params'];
}>;
export type GetPaySlipsSuccess = PayloadAction<PaySlipData>;
export type GetPaySlipsFail = PayloadAction<RequestCommonError>;

const paySlips = createSlice({
  name: 'payslips',
  initialState: emptyPaySlipsInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getPaySlipsStart(state, _: GetPaySlipsStart) {
      state.status = RequestStatus.pending;
    },
    getPaySlipsSuccess(state, action: GetPaySlipsSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getPaySlipsFail(state, action: GetPaySlipsFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const { getPaySlipsStart, getPaySlipsSuccess, getPaySlipsFail } = paySlips.actions;
export default paySlips.reducer;

import { OneDriveCreateNewFolderInUserData } from 'types';
import { Core, CREATE_NEW_FOLDER_IN_USER } from 'api';

interface PostOneDriveCreateNewFolderInUser {
  (folderId: string, folderName: string): Promise<{ data: OneDriveCreateNewFolderInUserData }>;
}

class OneDriveCreateNewFolderInUser extends Core {
  createNewFolder: PostOneDriveCreateNewFolderInUser = async (folderId, folderName) =>
    this.post(CREATE_NEW_FOLDER_IN_USER(folderId, folderName));
}

export const oneDriveCreateNewFolderInUser = new OneDriveCreateNewFolderInUser();

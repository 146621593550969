import { useCallback, useMemo } from 'react';
import { FormFieldId } from 'types';
import { FormListFieldData } from 'antd/lib/form/FormList';

export const useFromFieldName = (fieldId: FormFieldId, formListField?: FormListFieldData) =>
  useMemo(() => {
    const fieldIdArr = Array.isArray(fieldId) ? fieldId : [fieldId] || [];

    return formListField?.name === undefined || formListField?.name === null
      ? fieldId
      : [formListField?.name, ...fieldIdArr];
  }, [fieldId, formListField?.name]);

export const useGetFormFieldName = () => {
  return useCallback((fieldId: FormFieldId, formListField?: FormListFieldData) => {
    const fieldIdArr = Array.isArray(fieldId) ? fieldId : [fieldId] || [];

    return formListField?.name === undefined || formListField?.name === null
      ? fieldId
      : [formListField?.name, ...fieldIdArr];
  }, []);
};

import React, { FC, useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Form } from 'antd';
import { FormFields } from 'types';
import { useGetFromFieldId } from 'ui/hooks';
import { FieldButton } from './FieldButton';
import { transformGeneratedButtonFormData } from './GeneratedForm.helpers';

interface GeneratedFormProps {
  formData: FormFields;

  onSubmit?(): void;

  formName?: string;
  inCard?: boolean;
  taskId?: string;
  processDefinitionKey?: string;
  processId?: string;
}

export const GeneratedButton: FC<GeneratedFormProps> = ({
  formData,
  formName,
  inCard,
  taskId,
  processDefinitionKey,
  processId,
}) => {
  const [form] = useForm();
  const getFieldId = useGetFromFieldId();

  const initialValues = useMemo(
    () =>
      formData.reduce((acc, item) => {
        const value = item.value.value;
        const defaultValue = item.defaultValue;
        const fieldId = getFieldId(item.id);

        if (value !== undefined && value !== null) {
          acc[fieldId] = item.value.value;
          return acc;
        }

        if (defaultValue !== undefined && defaultValue !== null) {
          acc[fieldId] = item.defaultValue;
          return acc;
        }

        return acc;
      }, {} as { [key: string]: string | boolean | Date | null }),
    [formData, getFieldId]
  );

  const transformedFormData = useMemo(() => transformGeneratedButtonFormData(formData), [formData]);

  return (
    <Form
      initialValues={initialValues}
      form={form}
      layout="inline"
      name={formName}
      style={{ flexDirection: undefined }}
    >
      {transformedFormData.map((field) => {
        switch (field.typeName) {
          case 'button':
            return (
              <FieldButton
                key={getFieldId(field.id)}
                field={field}
                inCard={inCard}
                form={form}
                taskId={taskId}
                processDefinitionKey={processDefinitionKey}
                processId={processId}
              />
            );
          default:
            // eslint-disable-next-line array-callback-return
            return;
        }
      })}
    </Form>
  );
};

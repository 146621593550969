import { AxiosPromise } from 'axios';
import {
  IEmploymentDetail,
  IOngoingRequestInfo,
  IProfileBasicInfo,
  IProfileDelegateInfo,
  IProfilePersonalData,
  IPublicProfileInfoResponse,
} from 'types/NewProfile';
import {
  Core,
  NEW_PROFILE_EMPLOYMENT_DETAILS_WITHOUT_PAY_ROLL,
  NEW_PROFILE_PERSONAL_DATA,
  NEW_PROFILE_BASIC_INFO,
  USER_PROFILE,
  PROFILE_SET_NICKNAME,
  NEW_PROFILE_ONGOING_REQUEST,
  OTHER_PROFILE_DELEGATE_INFO,
  USER_PROFILE_EDR,
} from '../Core';

interface GetPersonalData {
  (): AxiosPromise<IProfilePersonalData>;
}

interface GetEmploymentDetails {
  (): AxiosPromise<IEmploymentDetail>;
}

interface GetProfileBasicInfo {
  (): AxiosPromise<IProfileBasicInfo>;
}

interface GetPublicProfileInfo {
  (email: string): AxiosPromise<IPublicProfileInfoResponse>;
}

interface PostProfileNickname {
  (nickname: string): AxiosPromise<unknown>;
}

interface GetProfileOnGoingRequest {
  (): AxiosPromise<IOngoingRequestInfo>;
}

interface GetPublicProfileDelegationInfoRequest {
  (adOid: string): AxiosPromise<IProfileDelegateInfo>;
}

export class NewProfile extends Core {
  getPersonalData: GetPersonalData = async () => this.get(NEW_PROFILE_PERSONAL_DATA());
  getEmploymentDetails: GetEmploymentDetails = async () =>
    this.get(NEW_PROFILE_EMPLOYMENT_DETAILS_WITHOUT_PAY_ROLL());
  getProfileBasicInfo: GetProfileBasicInfo = async () => this.get(NEW_PROFILE_BASIC_INFO());
  getPublicProfileInfo: GetPublicProfileInfo = async (email) => this.get(USER_PROFILE(email));
  postProfileNickName: PostProfileNickname = async (nickname) =>
    this.put(PROFILE_SET_NICKNAME(), {}, { params: { nickname } });
  getProfileOnGoingRequest: GetProfileOnGoingRequest = async () =>
    this.get(NEW_PROFILE_ONGOING_REQUEST());
  getPublicProfileDelegateInfo: GetPublicProfileDelegationInfoRequest = async (adOid: string) =>
    this.get(OTHER_PROFILE_DELEGATE_INFO(adOid));
  getPublicProfileInfoEDR: GetPublicProfileInfo = async (email) =>
    this.get(USER_PROFILE_EDR(email));
}

export const newProfile = new NewProfile();

import React from 'react';
import { useTranslate } from 'translations';
import { WFHRemainingBalanceResponse } from 'types';
import {
  StyledRemainingBalanceWFHSpin,
  StyledWFHBalanceCard,
  StyledWFHBalanceCount,
  StyledWFHBalanceDays,
  StyledWFHBalanceLabel,
} from './WFHFormFields.styles';

export interface WFHBalanceCardProps {
  isLoadingWFHBalance: boolean;
  wfhBalance?: WFHRemainingBalanceResponse;
}
export const WFHBalanceCard: React.FC<WFHBalanceCardProps> = ({
  isLoadingWFHBalance,
  wfhBalance,
}: WFHBalanceCardProps) => {
  const { t } = useTranslate();
  return (
    <StyledWFHBalanceCard>
      <StyledWFHBalanceLabel>{t('wfh.request.wfhbalance.label')}</StyledWFHBalanceLabel>
      <StyledWFHBalanceCount>
        {isLoadingWFHBalance ? (
          <StyledRemainingBalanceWFHSpin size={'small'} />
        ) : (
          wfhBalance?.remaining_balance ?? '-'
        )}
        <StyledWFHBalanceDays>{t('wfh.request.days.label')}</StyledWFHBalanceDays>
      </StyledWFHBalanceCount>
    </StyledWFHBalanceCard>
  );
};

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const groupMember = (state: RootState): RootState['groupMembers'] => state.groupMembers;

export const GroupMembersData = createSelector(groupMember, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

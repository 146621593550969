import React, { FC } from 'react';
import { Form, Input, Row } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { FormField } from 'types';
import { StyledFieldLabel } from '../GeneratedForm.Styled';
import { useFieldPlaceholder } from '../GeneratedForm.hooks';
import { useFromFieldName } from '../../../hooks';

interface FieldProps {
  field: FormField;
  withLabel?: boolean;
  wrapperColSpan?: number;
  formListField?: FormListFieldData;
}

export const Field: FC<FieldProps> = ({ field, withLabel, wrapperColSpan = 21, formListField }) => {
  const maxLengthFromValidation = field.validationConstraints.find(
    (rule) => rule.name === 'maxlength'
  );
  const minLengthFromValidation = field.validationConstraints.find(
    (rule) => rule.name === 'minlength'
  );
  const patternFromValidation = field.validationConstraints.find((rule) => rule.name === 'pattern');
  const maxLengthFromProperties = field.properties.maxlength
    ? +field.properties.maxlength
    : field.properties.length
    ? +field.properties.length
    : undefined;

  const minLengthFromProperties = field.properties.minLength
    ? +field.properties.minLength
    : field.properties.minlength
    ? +field.properties.minlength
    : field.properties.length
    ? +field.properties.length
    : undefined;
  const patternFromProperties = field.properties.pattern ? field.properties.pattern : undefined;

  const maxLength =
    maxLengthFromValidation && typeof maxLengthFromValidation.configuration === 'string'
      ? +maxLengthFromValidation.configuration
      : maxLengthFromProperties;

  const minLength =
    minLengthFromValidation && typeof minLengthFromValidation.configuration === 'string'
      ? +minLengthFromValidation.configuration
      : minLengthFromProperties;
  const pattern =
    patternFromValidation && patternFromValidation.configuration instanceof RegExp
      ? patternFromValidation.configuration
      : patternFromProperties;

  const fieldIsDisabled = field.properties.viewOnly === 'true';
  const fieldIsRequired = field.validationConstraints.some((rule) => rule.name === 'required');

  const placeholder = useFieldPlaceholder({
    placeholder: field.placeholder,
    label: field.label,
    placeholderPrefix: 'Enter',
  });

  const name = useFromFieldName(field.id, formListField);

  return (
    <>
      {withLabel && (
        <Row>
          <StyledFieldLabel>{field.label}</StyledFieldLabel>
        </Row>
      )}
      <Form.Item
        {...formListField}
        validateFirst
        label={withLabel ? null : field.label}
        name={name}
        wrapperCol={{ span: wrapperColSpan }}
        rules={[
          {
            required: fieldIsRequired,
            message: `${field.label} is required`,
          },
          {
            max: maxLength,
            message: `Maximum allowed length is ${maxLength} characters`,
          },
          {
            min: minLength,
            message: `Minimum allowed length is ${minLength} characters`,
          },
          {
            pattern: pattern,
            message: patternFromValidation?.message || 'Invalid format',
          },
        ]}
        // getValueProps={(value) => {
        //   // if (field.type?.name === 'string') {
        //   //   value = value?.toString();
        //   // }
        //   console.log(value);
        //   return { value: value };
        // }}
      >
        <Input
          value={
            fieldIsDisabled && fieldIsRequired
              ? field.defaultValue
                ? field.defaultValue
                : typeof field.value?.value === 'string'
                ? field.value.value
                : undefined
              : undefined
          }
          disabled={fieldIsDisabled}
          autoComplete="off"
          type={field.type?.name}
          placeholder={placeholder}
        />
      </Form.Item>
    </>
  );
};

import { useQuery } from 'react-query';
import { staticForm } from 'api/StaticForm';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';

export function useCashAdvanceDetail() {
  return useQuery(
    [QUERIES.cashAdvanceDetail],
    async () => (await staticForm.getCashAdvanceDetail()).data,
    {
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetUserAvatarFail,
  GetUserAvatarStart,
  GetUserAvatarSuccess,
  getUserAvatarSuccess,
  getUserAvatarSuccessFail,
  getUserAvatarStart,
} from './userAvatarSlice';

export const getUserAvatarEpic: Epic<
  GetUserAvatarStart,
  GetUserAvatarSuccess | GetUserAvatarFail,
  RootState,
  typeof API
> = (action$, _, { userAvatar }) =>
  action$.pipe(
    filter(getUserAvatarStart.match),
    mergeMap(({ payload }) =>
      from(
        userAvatar
          .getUserAvatar(payload.photoId, payload.params)
          .then((data) => new Blob([data.data]))
          .then((blob) => URL.createObjectURL(blob))
      ).pipe(
        map((data) =>
          getUserAvatarSuccess({
            [payload.photoId]: data,
          })
        ),
        catchError((error) => of(getUserAvatarSuccessFail(error?.response?.data)))
      )
    )
  );

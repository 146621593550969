import React from 'react';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { HierarchyData } from 'types';
import { UserPopover } from 'ui';
import { StyledLoadingOutlined } from './Hierarchy.styled';
import { DataNode } from './Hierarchy.types';
import { HierarchyTitle } from './HierarchyTitle';

export const updateSwitcherIcon = (
  tree: DataNode[],
  key: React.Key,
  isLoading = true
): DataNode[] =>
  tree.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        switcherIcon: isLoading ? <StyledLoadingOutlined /> : null,
      };
    } else if (node.children) {
      return {
        ...node,
        children: updateSwitcherIcon(node.children, key, isLoading),
      };
    } else {
      if (node.subordinatesIds) {
        return {
          ...node,
          loading: isLoading,
        };
      }
    }
    return node;
  });

export const getInitialExpandedKeys = (hierarchyData: HierarchyData[], id = '0'): string[] =>
  hierarchyData.reduce((acc, item, i) => {
    if (item.subordinates) {
      return [
        ...acc,
        id + `-${i}`,
        ...getInitialExpandedKeys(item.subordinates, id + '-0'),
      ] as string[];
    } else {
      return acc;
    }
  }, [] as string[]);

export const updateTreeData = (
  list: DataNode[],
  key: React.Key,
  children: DataNode[]
): DataNode[] => {
  return list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    } else if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }

    return node;
  });
};

export const convertToTree = (hierarchyData: HierarchyData[], id = '0'): DataNode[] =>
  hierarchyData.map((item, i) => {
    const newItem = {
      title: (
        <HierarchyTitle
          strong={item.self}
          userName={item.displayName}
          title={item.title}
          id={item.adOid}
          email={item.email}
          hcmId={item.id}
        />
      ),
      key: id + `-${i}`,
      icon: (
        <UserPopover
          {...(item.email
            ? { currentEmail: item.email }
            : item.adOid
            ? { currentID: item.adOid }
            : { hcmID: item.id })}
        >
          <MSTeamsUserAvatar
            email={item.email}
            id={item.adOid}
            name={item.displayName}
            statusSize={12}
            size={30}
          />
        </UserPopover>
      ),
    };

    if (item.subordinates) {
      return {
        ...newItem,
        children: convertToTree(item.subordinates, id + '-0'),
      };
    }

    if (item.subordinatesIds) {
      return {
        ...newItem,
        subordinatesIds: item.subordinatesIds,
        isLeaf: false,
      };
    }

    return {
      ...newItem,
      title: (
        <HierarchyTitle
          strong={item.self}
          userName={item.displayName}
          title={item.title}
          id={item.adOid}
          email={item.email}
          hcmId={item.id}
        />
      ),
      isLeaf: true,
    };
  });

import { useCallback, useMemo } from 'react';
import { AllRequestCard } from 'types';
import { TType } from 'translations';

export const useCards = ({
  allCards = ([] as unknown) as AllRequestCard[],
  selectedCategory,
  selectedSubCategory,
  t,
  search,
  searchTitle,
}: {
  allCards?: AllRequestCard[];
  selectedCategory: string;
  selectedSubCategory: string;
  search: string;
  searchTitle: string;
  t: TType;
}) => {
  const filterCards = useCallback((cards: AllRequestCard[], _search: string) => {
    return cards.reduce<AllRequestCard[]>((acc, card) => {
      const search = _search.trim().toLowerCase();

      const filteredNestedCard = card.names.filter(
        ({ name, description }) =>
          name.toLowerCase().includes(search) || description.toLowerCase().includes(search)
      );
      if (filteredNestedCard.length) {
        return [
          ...acc,
          {
            ...card,
            names: filteredNestedCard,
          },
        ];
      }
      return acc;
    }, []);
  }, []);

  return useMemo(() => {
    const title = searchTitle
      ? searchTitle
      : selectedSubCategory
      ? selectedSubCategory
      : selectedCategory
      ? selectedCategory
      : t('request.catalog.allService');

    let cards = selectedSubCategory
      ? allCards?.filter(({ name, names }) => name === selectedSubCategory)
      : allCards;

    if (search) {
      cards = filterCards(cards, search);
    }

    return {
      title,
      isSubTitle: !selectedSubCategory,
      cards,
    };
  }, [allCards, filterCards, search, searchTitle, selectedCategory, selectedSubCategory, t]);
};

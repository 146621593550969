import React from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import {
  CoinsIcon,
  EnvelopeOpenDollarIcon,
  FilterCircleDollarIcon,
  HandsHoldingDollarIcon,
  LandmarkIcon,
  MoneyBillTransferIcon,
  PeopleRoofIcon,
  UsersIcon,
  // AwardSimpleIcon,
  ServerIcon,
  UsersViewfinderIcon,
  HouseUserIcon,
  GlobeIcon,
  MicrochipIcon,
  PenRulerIcon,
  ScrewdriverWrenchIcon,
  TowerCellIcon,
  BrainCircuitIcon,
  BriefcaseIcon,
  BuildingShieldIcon,
  BuildingUserIcon,
  ChairOfficeIcon,
  ChessIcon,
  FileInvoiceDollarIcon,
  FilePenIcon,
  FileShieldIcon,
  SatelliteDishIcon,
  TruckFieldIcon,
  UserShieldIcon,
  UserTieIcon,
  UserVneckIcon,
  // UsersGearIcon,
  UsersRaysIcon,
} from 'assets';

interface IconSelectorProps {
  departmentName?: string;
  sectorName?: string;
  style?: React.CSSProperties;
}

const ELibraryIconSelector: React.FC<IconSelectorProps> = ({
  departmentName,
  sectorName,
  ...rest
}) => {
  const Icons = {
    //Commercial
    CommercialOutlined: <BriefcaseIcon {...rest} />,
    CommercialExcellenceOutlined: <BuildingShieldIcon {...rest} />,
    CommercialOperationsBillingOutlined: <FileInvoiceDollarIcon {...rest} />,
    StrategicKeyAccountsOutlined: <TruckFieldIcon {...rest} />,
    //Finance
    FinanceOutlined: <MoneyBillTransferIcon {...rest} />,
    FinancialControllingOutlined: <FilterCircleDollarIcon {...rest} />,
    CorporateFinanceOutlined: <EnvelopeOpenDollarIcon {...rest} />,
    FinancialReportingBusinessFinanceOutlined: <LandmarkIcon {...rest} />,
    ProcurementOutlined: <HandsHoldingDollarIcon {...rest} />,
    SiteLeasingAcquisitionOutlined: <CoinsIcon {...rest} />,
    //HumanResources
    HumanResourcesOutlined: <PeopleRoofIcon {...rest} />,
    HROperationsOutlined: <UsersIcon {...rest} />,
    HRCenterofExcellenceOutlined: <UsersViewfinderIcon {...rest} />,
    FacilitiesSupportServicesOutlined: <ServerIcon {...rest} />,
    CultureEngagementOutlined: <HouseUserIcon {...rest} />,
    //StrategyGovernance
    StrategyGovernanceOutlined: <BrainCircuitIcon {...rest} />,
    GovernanceQARiskAssuranceOutlined: <BuildingUserIcon {...rest} />,
    LegalOutlined: <FileShieldIcon {...rest} />,
    StrategyPlanningManagementOutlined: <ChessIcon {...rest} />,
    RegulatoryAffairsOutlined: <UserShieldIcon {...rest} />,
    //Operations
    OperationsOutlined: <TowerCellIcon {...rest} />,
    EngineeringSolutionsOperationsPlanningOutlined: <PenRulerIcon {...rest} />,
    NetworkOutlined: <GlobeIcon {...rest} />,
    OperationsMaintenanceOutlined: <ScrewdriverWrenchIcon {...rest} />,
    TechnologyInnovationOutlined: <MicrochipIcon {...rest} />,
    //ChiefExecutiveOfficerBoardofDirectors
    ChiefExecutiveOfficerBoardofDirectorsOutlined: <UserTieIcon {...rest} />,
    CorporateCommunicationOutlined: <SatelliteDishIcon {...rest} />,
    BoardSecretaryOutlined: <UserVneckIcon {...rest} />,
    InternalAuditOutlined: <FilePenIcon {...rest} />,
    CEOOfficeOutlined: <ChairOfficeIcon {...rest} />,
    ProjectManagementOfficeOutlined: <UsersRaysIcon {...rest} />,
  };

  const getIcon = (type?: string): JSX.Element | undefined => {
    let comp: JSX.Element | undefined = undefined;

    if (!type?.match(/.+(Outlined|Filled|TwoTone)$/i)) {
      type += 'Outlined';
    }

    const found = Object.entries(Icons).find(([k]) => k.toLowerCase() === type?.toLowerCase());
    if (found) {
      [, comp] = found;
    }

    return comp;
  };

  return getIcon(departmentName) ?? getIcon(sectorName) ?? <FileTextOutlined {...rest} />;
};

export default ELibraryIconSelector;

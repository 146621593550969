import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  GetTeamsChannelFolderStart,
  GetTeamsChannelFolderSuccess,
  GetTeamsChannelFolderFail,
  getTeamsChannelFolderStart,
  getTeamsChannelFolderSuccess,
  getTeamsChannelFolderFail,
} from './teamsChannelFolderSlice';

export const getTeamsCHannelFolderEpic: Epic<
  GetTeamsChannelFolderStart,
  GetTeamsChannelFolderSuccess | GetTeamsChannelFolderFail,
  RootState,
  typeof API
> = (action$, _, { teamsChannelFolderList }) =>
  action$.pipe(
    filter(getTeamsChannelFolderStart.match),
    mergeMap(({ payload }) =>
      from(teamsChannelFolderList.getTeamChannelFolderList(payload.teamId, payload.folderId)).pipe(
        map(({ data }) =>
          getTeamsChannelFolderSuccess({
            teamId: payload.teamId,
            folderId: payload.folderId,
            data,
          })
        ),
        catchError((error) =>
          of(
            getTeamsChannelFolderFail({
              teamId: payload.teamId,
              folderId: payload.folderId,
              error: error?.response?.data,
            })
          )
        )
      )
    )
  );

import React, { useMemo } from 'react';
import { TType } from 'translations';
import { IBankDetail } from 'types/NewProfile';
import { PrivateProfileBankDetailItem } from './PrivateProfileBankDetailItem';

export interface PrivateProfileBankDetailProps {
  t: TType;
  bankDetails: IBankDetail[];
}

export const PrivateProfileBankDetail: React.FC<PrivateProfileBankDetailProps> = ({
  t,
  bankDetails,
}: PrivateProfileBankDetailProps) => {
  const dataBankDetails: IBankDetail[] = useMemo(() => {
    return bankDetails;
  }, [bankDetails]);
  return (
    <>
      {dataBankDetails.map((item: IBankDetail, index) => (
        <PrivateProfileBankDetailItem
          key={index}
          bankName={item.bankName}
          iban={item.iban}
          accountNumber={item.accountNumber}
          swiftCode={item.swiftCode}
          bankDetailsAttachmentList={item.bankDetailsAttachmentList}
          IbanAttachmentList={item.ibanAttachment}
          clearanceAttachmentList={item.clearanceAttachment}
          t={t}
        />
      ))}
    </>
  );
};

import { ProcessDefinitionKeysData } from 'types';
import { Core, PROCESS_DEFINITION_KEYS, PROCESS_DEFINITION_KEYS_OLD } from 'api';

interface GetProcessDefinitionKeys {
  (): Promise<{ data: ProcessDefinitionKeysData }>;
}

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

class ProcessDefinitionKeys extends Core {
  getProcessDefinitionKeys: GetProcessDefinitionKeys = async () =>
    this.get(disableAggregator ? PROCESS_DEFINITION_KEYS_OLD() : PROCESS_DEFINITION_KEYS());
}

export const processDefinitionKeys = new ProcessDefinitionKeys();

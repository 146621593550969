import React from 'react';
import { Card, Empty } from 'antd';
import { AppLayout } from 'layouts';
import styled from 'styled-components';
import { TextStyled } from 'ui/elements';
import { useTranslate } from 'translations';
import { useAuthContext } from 'authentication';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 13% auto 0;
  width: 330px;
`;

const LogoutStyled = styled('b')`
  cursor: pointer;
  text-decoration: underline;
`;

export const DataLoadingError = () => {
  const { t } = useTranslate();
  const { logout } = useAuthContext();

  return (
    <AppLayout displayHeader={false}>
      <Wrapper>
        <Card style={{ minWidth: 300 }}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          <TextStyled size={16} type="secondary">
            {t('error.dataLoading.accessDenied')}
            <LogoutStyled onClick={logout}>{t('error.dataLoading.logout')}</LogoutStyled>
            {t('error.dataLoading.signInAgain')}
          </TextStyled>
        </Card>
      </Wrapper>
    </AppLayout>
  );
};

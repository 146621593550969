import { Typography } from 'antd';
import { ExclamationCircle } from 'assets';
import React from 'react';
import { StyledApproveIcon, StyledApproveRow, StyledApproveText } from 'routes/Calendar';

export interface WarningTitleProps {
  ErrorMessage: string;
}

export const WarningMessageTitle: React.FC<WarningTitleProps> = ({ ErrorMessage }) => {
  const { Text } = Typography;
  return (
    <React.Fragment>
      <StyledApproveRow>
        <StyledApproveIcon>
          <ExclamationCircle />
        </StyledApproveIcon>
        <StyledApproveText>
          <Text> {ErrorMessage} </Text>
        </StyledApproveText>
      </StyledApproveRow>
    </React.Fragment>
  );
};

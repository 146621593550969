import styled from 'styled-components';
import { Row, Typography } from 'antd';
import { colors, space, typography } from 'ui/elements';

const { Text } = Typography;

export const StyledCardWrapper = styled.div`
  cursor: pointer;
`;

export const StyledAttendanceDetail = styled.div`
  flex-direction: column;
`;

export const StyledAttendanceName = styled(Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.textColorSecondary};
`;

export const StyledAttendanceTime = styled(Text)`
  font-size: ${typography.size.kilo};
  color: ${(props: { $isOnOffice?: boolean }) =>
    props.$isOnOffice ? colors.nature : colors.primaryColor};
`;

export const StyledInOffice = styled(Text)`
  font-size: ${typography.size.kilo};
  color: ${colors.nature};
`;

export const StyledAttendanceWrapper = styled(Row)`
  padding-top: ${space.normal};
`;

export const StyledOnLeaveText = styled(Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.grey};
`;

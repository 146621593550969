import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import markerImg from 'assets/svg/selectable_map_marker.svg';
import { StyledMapContainer } from '../InteractiveGoogleMap.styled';

type Props = {
  center: google.maps.LatLngLiteral;
  zoom?: number;
  onMapInitialized?: (map: google.maps.Map) => void;
  staticMarker?: { position: google.maps.LatLngLiteral; iconUrl?: string };
  removableMarker?: { position: google.maps.LatLngLiteral; iconUrl?: string };
  enableMarkerDraw?: {
    single: boolean;
    onAdd?: (markerPosition?: google.maps.LatLngLiteral) => void;
  };
  circleZone?: {
    center: google.maps.LatLngLiteral;
    radius: number;
    onAdd?: (circle?: google.maps.Circle) => void;
    dashed?: boolean;
  };
};

export const GoogleMapsContainer: FC<Props> = ({
  center,
  onMapInitialized,
  staticMarker,
  removableMarker,
  zoom,
  enableMarkerDraw,
  circleZone,
}) => {
  const ref = useRef<HTMLDivElement | undefined>();
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [map, setMap] = useState<google.maps.Map | undefined>();
  const [currentMarkerCompletedEvent, setMarkerCompletedEvent] = useState<
    google.maps.MapsEventListener | undefined
  >();
  const [currentUserMarker, setCurrentUserMarker] = useState<google.maps.Marker | undefined>();
  const [currentDrawingManager, setCurrentDrawingManager] = useState<
    google.maps.drawing.DrawingManager | undefined
  >();
  const [currentCircleZone, setCurrentCircleZone] = useState<google.maps.Circle | undefined>();
  const [removableMarkerPlaced, setRemovableMarkerPlaced] = useState<boolean>(false);

  useEffect(() => {
    if (ref?.current) {
      const tempMap = new google.maps.Map(ref.current, {
        mapId: 'DEFAULT_MAP_ID',
        fullscreenControl: true,
        mapTypeControl: false,
        zoomControl: true,
        rotateControl: false,
        streetViewControl: false,
        keyboardShortcuts: false,
        scaleControl: false,
        clickableIcons: false,
      });
      setMap(tempMap);
    }
  }, []);

  useEffect(() => {
    if (map) {
      map.setCenter(center);
      map.setZoom(zoom ?? 16);

      if (onMapInitialized && !mapLoaded) {
        setMapLoaded(true);
        onMapInitialized(map);
      }
    }
  }, [center, map, mapLoaded, onMapInitialized, zoom]);

  useEffect(() => {
    if (map && staticMarker) {
      const markerIcon = document.createElement('img');
      markerIcon.src = staticMarker.iconUrl ?? markerImg;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const marker = new google.maps.marker.AdvancedMarkerElement({
        map: map,
        position: staticMarker.position,
        content: markerIcon,
      });
    }
  }, [map, staticMarker]);

  useEffect(() => {
    if (map && removableMarker && !removableMarkerPlaced) {
      const marker = new google.maps.Marker({
        map: map,
        position: removableMarker.position,
        icon: removableMarker.iconUrl ?? markerImg,
      });
      setCurrentUserMarker(marker);
      setRemovableMarkerPlaced(true);
    }
  }, [map, removableMarker, removableMarkerPlaced]);

  useEffect(() => {
    if (map && enableMarkerDraw && !currentDrawingManager) {
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.MARKER,
        drawingControl: false,
        markerOptions: {
          icon: {
            url: markerImg,
            anchor: new google.maps.Point(15, 15),
          },
        },
      });

      drawingManager.setMap(map);
      setCurrentDrawingManager(drawingManager);
    } else {
      if (currentDrawingManager && !enableMarkerDraw) {
        if (currentMarkerCompletedEvent) {
          currentMarkerCompletedEvent.remove();
        }
        currentDrawingManager.setMap(null);
        setCurrentDrawingManager(undefined);
      }
    }
  }, [map, enableMarkerDraw, currentDrawingManager, currentMarkerCompletedEvent]);

  useEffect(() => {
    if (currentDrawingManager && enableMarkerDraw) {
      if (google.maps.event.hasListeners(currentDrawingManager, 'markercomplete')) {
        google.maps.event.clearListeners(currentDrawingManager, 'markercomplete');
      }
      const markerCompleteEvent = google.maps.event.addListenerOnce(
        currentDrawingManager,
        'markercomplete',
        (marker: google.maps.Marker) => {
          if (enableMarkerDraw.single) {
            if (currentUserMarker) {
              currentUserMarker.setMap(null);
            }
            setCurrentUserMarker(marker);
          }
          if (enableMarkerDraw.onAdd) {
            enableMarkerDraw.onAdd(marker.getPosition()?.toJSON() as google.maps.LatLngLiteral);
          }
        }
      );
      setMarkerCompletedEvent(markerCompleteEvent);
    }
  }, [currentDrawingManager, currentUserMarker, enableMarkerDraw]);

  useEffect(() => {
    if (map && circleZone && !currentCircleZone) {
      const circle = new google.maps.Circle({
        strokeColor: circleZone.dashed ? undefined : '#FC4C02CC',
        strokeOpacity: circleZone.dashed ? 0 : 1,
        strokeWeight: circleZone.dashed ? 0 : 1,
        fillColor: '#FC4C020F',
        fillOpacity: 1,
        map,
        center: circleZone.center,
        radius: circleZone.radius,
      });
      if (circleZone.dashed) {
        const lineSymbol = {
          path: 'M 0,-1 0,1',
          strokeOpacity: 1,
          scale: 4,
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const circlePoly = new google.maps.Polyline({
          path: drawCircle(circleZone.center, circleZone.radius / 1609.344, 1),
          strokeOpacity: 0,
          icons: [
            {
              icon: lineSymbol,
              offset: '0',
              repeat: '20px',
            },
          ],
          strokeWeight: 1,
          strokeColor: '#FC4C02CC',
          map: map,
        });
      }
      setCurrentCircleZone(circle);
      if (circleZone.onAdd) {
        circleZone.onAdd(circle);
      }
    } else {
      if (currentCircleZone && !circleZone) {
        currentCircleZone.setMap(null);
        setCurrentCircleZone(undefined);
      }
    }
  }, [map, circleZone, currentCircleZone]);

  const drawCircle = (point: google.maps.LatLngLiteral, radius: number, dir: number) => {
    const d2r = Math.PI / 180;
    const r2d = 180 / Math.PI;
    const earthsRadius = 3963;

    const points = 32;

    const rLat = (radius / earthsRadius) * r2d;
    const rLng = rLat / Math.cos(point.lat * d2r);

    const extp = [];
    let start = points + 1;
    let end = 0;
    if (dir === 1) {
      start = 0;
      end = points + 1;
    }
    for (let i = start; dir === 1 ? i < end : i > end; i = i + dir) {
      const theta = Math.PI * (i / (points / 2));
      const ey = point.lng + rLng * Math.cos(theta); // center a + radius x * cos(theta)
      const ex = point.lat + rLat * Math.sin(theta); // center b + radius y * sin(theta)
      extp.push(new google.maps.LatLng(ex, ey));
    }
    return extp;
  };

  return <StyledMapContainer ref={ref as RefObject<HTMLDivElement>} id="map"></StyledMapContainer>;
};

import React, { useState, FC, useCallback, useEffect, useRef } from 'react';
import { Col, Form, Popover, Row, Space, Typography } from 'antd';
import moment from 'moment';
import { eLibraryFilterItems } from '../../../routes/ELibrary/components/ELibraryFilter';
import { filterSelectKeys } from './constants';
import { ApplyFilter, ClearFilter, PlusCircleIcon } from './Filter.styled';
import { FilterCol } from './FilterCol';
import { useFilterContext } from './FilterContext';
import { FilterItem } from './FilterItem';
import { SelectOption } from './Filter.types';

const { Text, Link } = Typography;

export interface Props {
  onResetFilterCallback?: (isReset: boolean) => void;
  customTrigger?: React.ReactNode;
}

export const FilterPopover: FC<Props> = ({ onResetFilterCallback, customTrigger }) => {
  const {
    tags,
    t,
    items,
    isLoading,
    setFilterValues,
    filterValues,
    onValuesChange,
    onShowPopOver,
  } = useFilterContext();
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [isOpened, setIsOpened] = useState(false);
  const [assignUserId, setAssignUserId] = useState<string>('');
  const [assignUserName, setAssignUserName] = useState<string>('');
  const [teamMembers, setTeamMembers] = useState<string>('');
  const { category, subCategory } = filterSelectKeys;

  const onReset = useCallback(() => {
    form.resetFields();
    setAssignUserId('');
    setAssignUserName('');
    if (onResetFilterCallback) {
      onResetFilterCallback(true);
    }
  }, [form, onResetFilterCallback]);

  const onFinish = (values: typeof filterValues) => {
    const newFilterValues = Object.fromEntries(
      Object.entries(values)
        .filter(([_, filedValue]) => filedValue !== undefined)
        .map((filterItem) => {
          const [itemName, itemValue] = filterItem;

          if (itemName === 'dateRange') {
            return [itemName, [moment(itemValue[0]), moment(itemValue[1])]];
          }

          if (itemName === 'datePicker') {
            return [itemName, moment(itemValue)];
          }

          return filterItem;
        })
    );

    setFilterValues(
      assignUserId
        ? { ...newFilterValues, assignedTo: assignUserId }
        : teamMembers
        ? { ...newFilterValues, adOid: teamMembers }
        : { ...newFilterValues }
    );
    setAssignUserName(assignUserName);
    setIsOpened(false);
    if (onResetFilterCallback) {
      onResetFilterCallback(false);
    }
  };

  const toggleVisible = useCallback(() => {
    setIsOpened(!isOpened);
    if (onShowPopOver) {
      onShowPopOver(!isOpened);
    }
  }, [isOpened, onShowPopOver]);

  const resetAssignedTo = useCallback(() => {
    if (filterValues && filterValues.assignedTo !== undefined) {
      setAssignUserName(assignUserName);
    } else {
      setAssignUserId('');
      setAssignUserName('');
    }
  }, [filterValues, assignUserName]);

  useEffect(() => {
    if (isOpened) {
      form.resetFields();
      form.setFieldsValue(filterValues);
      resetAssignedTo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened, form, filterValues]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      setIsOpened(false);
    }
  }, []);

  const getLookupUserId = useCallback((assignUserId: string, assignUserName: string) => {
    if (assignUserId && assignUserName) {
      setAssignUserId(assignUserId);
      setAssignUserName(assignUserName);
    }
  }, []);

  const getTeamMember = useCallback((teamMembers: SelectOption[]) => {
    const ids = teamMembers.map((member: SelectOption) => member.key);
    setTeamMembers(ids.toString());
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleChange = (field: { [key: string]: string }) => {
    const key = Object.entries(field).map(([key]) => key)[0];
    if (key === category) {
      form.setFieldsValue({
        subCategory: undefined,
        requestName: undefined,
      });
    } else if (key === subCategory) {
      form.setFieldsValue({
        requestName: undefined,
      });
    } else if (key === eLibraryFilterItems.sector) {
      /**
       * In ELibary filter
       * department dropDown is dependent on
       * sector dropDown
       * so clear out department
       * on selection of sector
       */
      form.setFieldsValue({
        [eLibraryFilterItems.department]: undefined,
      });
    }
  };

  return (
    <Popover
      overlayClassName="filter-overlay"
      placement="bottomLeft"
      content={
        <Form
          form={form}
          id="filterForm"
          layout="vertical"
          name="FilterForm"
          onFinish={onFinish}
          ref={formRef}
          onValuesChange={(field) => {
            handleChange(field);
            onValuesChange && onValuesChange(field);
          }}
        >
          <Row gutter={24}>
            {items.map((item, i) => (
              <FilterItem
                {...item}
                key={i}
                lookupUserId={getLookupUserId}
                assigneeName={assignUserName}
                getTeamMembers={getTeamMember}
              />
            ))}
            <Col />
          </Row>
          <Row gutter={[0, 6]}>
            <Col>
              <FilterCol>
                <Space>
                  <ApplyFilter type="primary" htmlType="submit">
                    {t('button.applyFilter')}
                  </ApplyFilter>

                  <ClearFilter type="text" onClick={onReset}>
                    {t('button.clear')}
                  </ClearFilter>
                </Space>
              </FilterCol>
            </Col>
          </Row>
        </Form>
      }
      trigger="click"
      visible={isOpened}
      onVisibleChange={toggleVisible}
    >
      {customTrigger ? (
        customTrigger
      ) : (
        <Link disabled={isLoading} onClick={toggleVisible}>
          <PlusCircleIcon />
          <Text strong>{t(tags?.length ? 'button.editFilter' : 'button.addFilter')}</Text>
        </Link>
      )}
    </Popover>
  );
};

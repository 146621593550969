import styled from 'styled-components';
import { Card, Col, Typography } from 'antd';
import { colors, space } from 'ui/elements';

const { Text } = Typography;
export const StyledCol = styled(Col)`
  text-align-last: end;
`;
export const StyledText = styled(Text)`
  color: ${colors.textColorSecondary};
  font-size: 14px;
`;
export const StyledCard = styled(Card)`
  .ant-card-body {
    padding: ${space.zero} ${space.slim};
  }
`;
export const StyledSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${colors.primaryColor};
`;

export const StyledExpensesBody = styled.div`
  margin: ${space.moderate} ${space.normal};
`;

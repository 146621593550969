import styled from 'styled-components';
import { Row, Typography } from 'antd';
import { colors, space } from 'ui';

const { Text } = Typography;

export const StyledTagsContainer = styled(Row)`
  padding-left: ${space.normal};
  padding-right: ${space.normal};
  margin-top: ${space.comfy};
  margin-bottom: ${space.cozy};
  align-items: center;
  justify-content: center;
  gap: ${space.moderate};
`;

export const StayedTag = styled(Text)`
  flex-shrink: 0;
  border-radius: 20px;
  background: ${colors.borderGreySecond};
  color: ${colors.textColorSecondary};
  font-size: 12px;
  font-family: inherit;
  line-height: 18px;
  padding-top: ${space.mini};
  padding-bottom: ${space.mini};
  padding-left: ${space.cozy};
  padding-right: ${space.cozy};
`;

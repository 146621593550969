import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { colors, space, typography } from 'ui/elements';

const { Text } = Typography;

export const StyledIconCol = styled(Col)`
  margin-right: ${space.normal};
`;

export const StyledRequestName = styled(Text)`
  font-size: ${typography.size.kilo};
  font-weight: ${typography.weight.semiBold};
  color: ${colors.primaryColor};
`;

export const StyledPathName = styled(Text)`
  font-size: ${typography.size.uno};
  font-weight: ${typography.weight.regular};
  color: ${colors.lightGrey};
`;

export const StyledHeaderRow = styled(Row)`
  min-height: 100px;
`;

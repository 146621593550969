import React, { FC } from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';

import { useTranslate, withTranslate } from 'translations';
import { SiderLayout } from 'layouts/SiderLayout';
import { withMenuItems } from 'app/App.menu-items.constants';
import { useAuthContext } from 'authentication';
import { PageSpin } from 'ui/elements';

const ConnectedSiderLayout = withMenuItems(withRouter(withTranslate(SiderLayout)));

interface Props extends RouteProps {
  displaySider?: boolean;
  component: RouteProps['component'];
}

export const RouteAppLayout: FC<Props> = ({
  displaySider = true,
  component: Component,
  ...rest
}) => {
  const { isAuthenticated } = useAuthContext();
  const { t } = useTranslate();

  const route = (
    <Route {...rest} render={(props) => (Component ? <Component {...props} /> : null)} />
  );
  const { pathname } = useLocation();
  const isNotFoundPage = pathname === '/404';

  return isAuthenticated === null ? (
    <PageSpin tip={t('retrievingProfileInformation')} />
  ) : displaySider && !isNotFoundPage ? (
    <ConnectedSiderLayout theme="light">{route}</ConnectedSiderLayout>
  ) : (
    route
  );
};

import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetTaskFail,
  GetTaskStart,
  GetTaskSuccess,
  getTaskStart,
  getTaskSuccess,
  getTaskFail,
} from './taskSlice';

export const taskEpic: Epic<GetTaskStart, GetTaskSuccess | GetTaskFail, RootState, typeof API> = (
  action$,
  _,
  { task }
) =>
  action$.pipe(
    filter(getTaskStart.match),
    mergeMap(({ payload }) =>
      from(task.getTaskList({ processInstanceId: payload })).pipe(
        map(({ data }) => getTaskSuccess({ processDefinitionKey: payload, data })),
        catchError((error) =>
          of(getTaskFail({ processDefinitionKey: payload, error: error?.response?.data }))
        )
      )
    )
  );

import React, { FC, useCallback } from 'react';
import { FormInstance } from 'antd/lib/form';
import { TType } from 'translations';
import { CreateFormItemType, IProfileUpdateExperienceMapped } from 'types';
import {
  FORM_ITEM_REQUIRED_RULE,
  FORM_ITEM_50_CHARACTER_RULES,
  FORM_ITEM_30_CHARACTER_RULES,
  disableFutureDate,
  englishPatternRule,
} from 'utils';
import { WRAPPER_COL_SPAN_SIZE_21 } from 'ui/components';
import moment from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { UploadFile } from 'antd/lib/upload/interface';
import { CustomFormGroupWrapper } from './CustomFormGroupWrapper';

type Props = {
  t: TType;
  form?: FormInstance;
  hasInitialValues: boolean;
  experiences?: IProfileUpdateExperienceMapped[];
};

export const ProfileUpdateExperienceFormGroup: FC<Props> = ({
  t,
  form,
  hasInitialValues,
  experiences,
}) => {
  const onExperienceFormRemove = (index: number) => {
    if (experiences?.[index]) {
      experiences.splice(index, 1);
    }
  };

  const onExperienceDataChange = useCallback(
    (formListIndex: number, dates: RangeValue<moment.Moment>) => {
      if (form && dates) {
        const yearsOfExperience = moment(dates?.[1]).diff(moment(dates?.[0]), 'years', false);
        form.setFields([
          { name: ['experiences', formListIndex, 'yearsOfExperience'], value: yearsOfExperience },
        ]);
      }
    },
    [form]
  );

  const onAttachmentChange = useCallback(
    (formListIndex: number, file?: UploadFile | UploadFile[]) => {
      if (form) {
        form.setFields([
          {
            name: ['experiences', formListIndex, 'experienceAttachmentList'],
            value: file,
          },
        ]);
      }
    },
    [form]
  );

  const experienceFormGroup = useCallback<(formListName: number) => CreateFormItemType[]>(
    (formListName) => {
      return [
        {
          id: `${0}`,
          label: 'Name of the organization',
          name: [formListName, 'organizationName'],
          type: 'input',
          rules: [
            ...FORM_ITEM_REQUIRED_RULE,
            ...FORM_ITEM_50_CHARACTER_RULES,
            englishPatternRule(t),
          ],
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Enter name of the organization',
          disabled: !!experiences?.[formListName]?.organizationName,
        },
        {
          id: `${1}`,
          name: [formListName, 'erpWorkHistoryId'],
          type: 'input',
          hidden: true,
        },
        {
          id: `${2}`,
          label: 'Industry',
          name: [formListName, 'industry'],
          type: 'input',
          rules: [
            ...FORM_ITEM_REQUIRED_RULE,
            ...FORM_ITEM_50_CHARACTER_RULES,
            englishPatternRule(t),
          ],
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Enter industry',
        },
        {
          id: `${3}`,
          label: 'Position',
          name: [formListName, 'positionTitle'],
          type: 'input',
          rules: [
            ...FORM_ITEM_REQUIRED_RULE,
            ...FORM_ITEM_30_CHARACTER_RULES,
            englishPatternRule(t),
          ],
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Enter position',
        },
        {
          id: `${4}`,
          label: 'Period',
          name: [formListName, 'period'],
          type: 'rangePicker',
          disabledDate: disableFutureDate,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          onRangeDateChange: (values) => onExperienceDataChange(formListName, values),
        },
        {
          id: `${5}`,
          label: 'Years of experience',
          name: [formListName, 'yearsOfExperience'],
          type: 'input',
          disabled: true,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: 'Auto-calculated based on Period',
        },
        {
          id: `${6}`,
          label: 'Experience letter',
          name: [formListName, 'experienceAttachmentList'],
          type: 'file',
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          valuePropName: 'fileList',
          attachmentFileExtensions: '.png,.pdf',
          attachmentFileTypes: 'image/png,application/pdf',
          hasIconInfo: true,
          iconInfoLabel: 'Experience Letter, GOSI Certificate, or any kind of proof',
          onFileChange: (file) => onAttachmentChange(formListName, file),
        },
      ];
    },
    [experiences, onAttachmentChange, onExperienceDataChange, t]
  );
  return (
    <CustomFormGroupWrapper
      t={t}
      maxRecords={10}
      formGroupItems={experienceFormGroup}
      formListName="experiences"
      sectionTitle="Experience"
      addButtonTitle="Add additional experience"
      hasAtLeastOne={hasInitialValues}
      hasInitialValue={hasInitialValues}
      form={form}
      onListRemove={onExperienceFormRemove}
    />
  );
};

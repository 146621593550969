import styled, { css } from 'styled-components';
import { Button, Menu, Layout as AntLayout, Col } from 'antd';
import { AllApps, LogoutNavIcon, NotificationNavIcon } from 'assets';
import { colors, typography, space, xSmallAndDown, small } from 'ui/elements';

const { Header: AntHeader } = AntLayout;

export const MenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  padding: 10px 25px;
  font-weight: 500;

  span {
    margin-left: 9px;
  }
`;

export const DropDownButton = styled(Button)`
  font-weight: 600;
  padding-right: 0;
`;

const sharedNavIconStyle = css`
  font-size: ${typography.size.kilo};
  color: ${colors.lightGrey};
  transition: border-left 2s ease;
  cursor: pointer;
  margin-left: ${space.slim};
  vertical-align: middle;

  &:hover {
    color: ${colors.accentPrimary};
  }
`;
export const AppsIcon = styled(AllApps)`
  ${sharedNavIconStyle};
  width: 28px;
  height: 28px;
`;

export const LogoutAppNavIcon = styled(LogoutNavIcon)`
  ${sharedNavIconStyle};
  width: 30px;
  height: 28px;
`;

export const FavoriteIcon = styled(NotificationNavIcon)`
  ${sharedNavIconStyle};
  width: 28px;
  height: 28px;
`;

export const HEADER_HEIGHT = '56px';
export const StyledHeader = styled(AntHeader)<{ $withoutShadow?: boolean }>`
  min-height: ${HEADER_HEIGHT};
  height: auto;
  line-height: ${HEADER_HEIGHT};
  padding: 0 !important;
  box-shadow: ${($props) => ($props.$withoutShadow ? 'unset' : undefined)} !important;

  h2 {
    margin: 0;
  }
`;

export const StyledBadgeContainer = styled.div`
  position: absolute;
  top: -10px;
  left: 31px;
`;

export const WrapperHeadIcon = styled.div`
  padding-right: ${space.cozy};
  margin-left: ${space.cozy};
`;

export const HeadIcon = styled(Col)`
  width: 52px;
`;

export const StyledIconsCol = styled(Col)`
  margin-right: 17px;
  //@media only screen and (min-width: 2000px) {
  //  margin-right: 0.5rem;
  //}
  //
  //@media only screen and (min-width: 1920px) {
  //  margin-right: 0.5rem;
  //}
  //
  //@media only screen and (min-width: 1440px) {
  //  margin-right: 0.5rem;
  //}
  //
  //@media only screen and (max-width: 1440px) {
  //  margin-right: 3rem;
  //}
  //
  //@media only screen and (max-width: 1000px) {
  //  margin-right: 4.5rem;
  //}
  //
  //@media only screen and (max-width: 768px) {
  //  margin-right: 4.5rem;
  //}

  ${xSmallAndDown`margin-right: 4.5rem;`}
  ${small`margin-right: 4.5rem;`}
`;

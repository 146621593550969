import React, { useMemo } from 'react';
import { Col } from 'antd';
import { TType } from 'translations';
import { IProfileAttachment } from 'types';
import { PrivateProfileAttachment, ProfileEntryField } from 'ui';
import { StyledProfileRowContainer } from '../../NewProfile.styled';
import {
  StyledAttachmentContainer,
  StyledAttachmentHeader,
  StyledBankDetailsInfoSectionTitle,
  StyledEditBankDetailsLink,
  StyledItemWrapper,
  StyledRow,
} from './PrivayeProfileBankDetail.styles';

export interface PrivateProfileBankDetailItemProps {
  t: TType;
  bankName: string;
  iban: string;
  accountNumber: string;
  swiftCode: string;
  bankDetailsAttachmentList: IProfileAttachment[];
  IbanAttachmentList: IProfileAttachment;
  clearanceAttachmentList: IProfileAttachment;
}

export const PrivateProfileBankDetailItem: React.FC<PrivateProfileBankDetailItemProps> = ({
  t,
  bankName,
  iban,
  accountNumber,
  swiftCode,
  bankDetailsAttachmentList,
  IbanAttachmentList,
  clearanceAttachmentList,
}: PrivateProfileBankDetailItemProps) => {
  const experienceFields = useMemo(
    () => [
      { label: t('user.privateProfile.bankDetail.bankName'), value: bankName },
      { label: t('user.privateProfile.bankDetail.iban'), value: iban },
      { label: t('user.privateProfile.bankDetail.accountNumber'), value: accountNumber },
      { label: t('user.privateProfile.bankDetail.swiftCode'), value: swiftCode },
    ],
    [t, bankName, iban, accountNumber, swiftCode]
  );

  return (
    <StyledItemWrapper>
      <StyledRow>
        <Col>
          <StyledBankDetailsInfoSectionTitle>
            {t('profile.personal.sections.bankDetails')}
          </StyledBankDetailsInfoSectionTitle>
        </Col>
        <Col>
          <StyledEditBankDetailsLink
            to={{
              pathname: '/tasks',
              state: { defaultTab: '1', catalogSearch: 'Change Bank Account' },
            }}
          >
            {t('user.privateProfile.bankDetail.editLinkMessage')}
          </StyledEditBankDetailsLink>
        </Col>
      </StyledRow>
      <StyledProfileRowContainer gutter={[25, 15]} wrap={true}>
        {experienceFields.map(({ label, value }, index) => (
          <Col xs={24} sm={24} md={12} lg={8} key={index}>
            <ProfileEntryField label={label} value={value} />
          </Col>
        ))}
      </StyledProfileRowContainer>
      <StyledAttachmentContainer>
        {IbanAttachmentList && (
          <>
            <StyledAttachmentHeader>
              {t('user.privateProfile.bankDetail.ibanAttachment')}
            </StyledAttachmentHeader>
            <PrivateProfileAttachment attachment={IbanAttachmentList} />
          </>
        )}
        {clearanceAttachmentList && (
          <>
            <StyledAttachmentHeader>
              {t('user.privateProfile.bankDetail.clearanceAttachment')}
            </StyledAttachmentHeader>
            <PrivateProfileAttachment attachment={clearanceAttachmentList} />
          </>
        )}
      </StyledAttachmentContainer>
    </StyledItemWrapper>
  );
};

import {
  GETWorkfFromHomeRemainingBalance,
  GetWorkFromHomeCalculateDuration,
  PostWFHSubmitStaticFromRequest, //
} from '../../types';
import {
  Core,
  WORK_FROM_HOME_CALCULATE_DURATION,
  WORK_FROM_HOME_REMAINING_BALANCE,
  WORK_FROM_HOME_SUBMIT_REQUEST,
} from '../Core';

class WorkFromHomeRequest extends Core {
  getWorkFromHomeRemainingBalance: GETWorkfFromHomeRemainingBalance = async (params) =>
    this.get(WORK_FROM_HOME_REMAINING_BALANCE(params.hcmId));

  getWorkFromHomeCalculateDuration: GetWorkFromHomeCalculateDuration = async (params) =>
    this.get(WORK_FROM_HOME_CALCULATE_DURATION(params.startDate, params.endDate));

  postWorkFromHomeRequestSubmit: PostWFHSubmitStaticFromRequest = async (hcmPersonId, data) =>
    this.post(WORK_FROM_HOME_SUBMIT_REQUEST(hcmPersonId), data);
}

export const workFromHomeRequest = new WorkFromHomeRequest();

import React, { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TranslationsContext } from './translationsContext';
import { MessagesTypes, TType, PrimitiveType } from './translationsTypes';

export interface WithTranslateProps {
  t: TType;
}

export const useTranslate = () => {
  const context = useContext(TranslationsContext);

  if (!context) {
    throw new Error('useTranslation should be used within a TranslationsContext');
  }

  const { language, setLanguage, pageDirection } = context;
  const intlObj = useIntl();

  const t = useCallback(
    (message: MessagesTypes, values?: Record<string, PrimitiveType>): string => {
      if (!message) return '';

      return intlObj.formatMessage({ id: message }, values);
    },
    [intlObj]
  );

  const getPrefixedT = useCallback(
    (keyPrefix: string) => (key: string, args = {}) =>
      t(`${keyPrefix}.${key}` as MessagesTypes, args),
    [t]
  );

  return useMemo(() => ({ t, language, setLanguage, pageDirection, getPrefixedT }), [
    t,
    language,
    setLanguage,
    pageDirection,
    getPrefixedT,
  ]);
};

export function withTranslate<T extends WithTranslateProps = WithTranslateProps>(
  Component: React.ComponentType<T>
) {
  const displayName = Component.displayName || Component.name || 'Component';

  const ComponentWithTranslate = (props: Omit<T, keyof WithTranslateProps>) => {
    const { t } = useTranslate();
    const injectedProps = { t };
    return <Component {...injectedProps} {...(props as T)} />;
  };

  ComponentWithTranslate.displayName = `withTranslate(${displayName})`;
  return ComponentWithTranslate;
}

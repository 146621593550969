import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetSubCategoryCardFail,
  GetSubCategoryCardStart,
  GetSubCategoryCardSuccess,
  getSubCategoryCardFail,
  getSubCategoryCardStart,
  getSubCategoryCardSuccess,
} from './subCategoryCardSlice';

export const SubCategoryCardEpic: Epic<
  GetSubCategoryCardStart,
  GetSubCategoryCardSuccess | GetSubCategoryCardFail,
  RootState,
  typeof API
> = (action$, _, { subCategoryCard }) =>
  action$.pipe(
    filter(getSubCategoryCardStart.match),
    exhaustMap(({ payload }) =>
      from(subCategoryCard.getSubCategoryCard(payload.subCategoryName)).pipe(
        map(({ data }) => getSubCategoryCardSuccess(data)),
        catchError((error) => of(getSubCategoryCardFail(error?.response?.data)))
      )
    )
  );

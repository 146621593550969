import { useMutation } from 'react-query';
import { eLibrary } from 'api';
import { IELibraryDownloadDocument } from 'types';

export function useELibraryDocumentDownload() {
  return useMutation<string, unknown, IELibraryDownloadDocument, unknown>(
    async (paramsDownloadDocument) => {
      const { data } = await eLibrary.downloadDocument(paramsDownloadDocument);
      return data;
    }
  );
}

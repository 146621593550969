import { SubordinatesData } from 'types';
import { Core, SUBORDINATES } from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetSubordinates {
  (params?: AxiosRequestConfig): Promise<{ data: SubordinatesData }>;
}

class Subordinates extends Core {
  getSubordinates: GetSubordinates = async (params) => this.get(SUBORDINATES(), params);
}

export const subordinates = new Subordinates();

import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';
import { useQuery } from 'react-query';
import { nonPoInvoice } from '../NONPoInvoice';

export function useNonPoGetBudgetGetRequestType(requestId: string) {
  return useQuery(
    [QUERIES.getNonPoBudgetGetRequestType, requestId],
    async () => {
      const { data } = await nonPoInvoice.getNonPoBudgetRequestType(requestId);
      const { invoiceType, distributionCombination: dc } = data;
      const distributionCombination = {
        GlAccount: dc.GLAccount.VALUE,
        costCenter: dc.costCenter.VALUE,
        project: dc.project.VALUE,
      };
      return { invoiceType, distributionCombination };
    },
    {
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

import styled from 'styled-components';
import { Avatar, Typography } from 'antd';
import { colors, space, typography } from 'ui/elements';

const { Text } = Typography;

export const StyledAvatarGroup = styled(Avatar.Group)`
  margin-top: ${space.cozy};
  .ant-avatar {
    border: none;
  }
  .ant-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    margin-right: ${space.mini};
  }
`;

export const StyledListMoreIcon = styled.div`
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  margin-right: ${space.mini};
  color: ${colors.white};
  background: ${colors.orangeShadow};
  text-align: center;
  font-size: 16px;
`;

export const StyledText = styled(Text)`
  font-size: ${typography.size.hecto};
  font-weight: ${typography.weight.regular};
  color: ${colors.primaryColor};
`;

export const StyledPopoverContent = styled.div`
  padding: ${space.slim} 0px ${space.mini};
  color: ${colors.primaryColor};
  overflow: scroll;
  height: 250px;
  overflow-x: hidden;
`;

export const StyledAvatarName = styled(Text)`
  color: ${colors.white};
  font-size: 16px;
  align-self: center;
`;

import { useMemo } from 'react';

export const useFieldPlaceholder = ({
  placeholderPrefix,
  placeholder,
  label,
}: {
  label?: string;
  placeholder?: string | boolean;
  placeholderPrefix: string;
}): string | undefined =>
  useMemo(() => {
    if (typeof placeholder === 'boolean' && label) {
      return `${placeholderPrefix} ${label.toLowerCase()}`;
    }

    return typeof placeholder === 'string' ? placeholder : undefined;
  }, [label, placeholder, placeholderPrefix]);

import styled from 'styled-components';
import { Typography, Row } from 'antd';
import { colors } from 'ui/elements';

const { Text } = Typography;

export const StyledFullWidthRow = styled(Row)`
  width: 100%;
`;

export const StyledPrivateProfileGeneralInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;

export const StyledPrivateProfileGeneralInfoCard = styled.div`
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  border: 1px solid ${colors.borderGreySecond};
  border-radius: 5px;
`;

export const StyledPrivateProfileGeneralInfoCardHeader = styled(Text)`
  margin-bottom: 10px !important;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: ${colors.primaryColor};
`;

export const StyledPrivateProfileGeneralInfoCardBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const StyledPrivateProfileGeneralInfoCardDescription = styled(Text)`
  margin-bottom: 0 !important;
  font-size: 15px;
  font-weight: 400;
  color: ${colors.textColorSecondary};
`;

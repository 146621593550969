import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from 'api/constants';
import { IProfileUpdateDataLookup, IProfileUpdateDataResponseLookup } from 'types';
import { profileUpdate } from '../ProfileUpdate';

export const useGetProfileUpdateRequestLookups = () =>
  useQuery<IProfileUpdateDataLookup>(
    [QUERIES.profileUpdateRequestLookups],
    async () => {
      const { data } = await profileUpdate.getProfileUpdateLookups();
      return convertLookupsToSelectOptions(data);
    },
    {
      ...PERSIST_QUERY_CONFIG,
    }
  );

const convertLookupsToSelectOptions = (
  data: IProfileUpdateDataResponseLookup
): IProfileUpdateDataLookup => {
  const saudiArabiaIndex = data.countriesDetailsList.findIndex(
    (item) => item.countryName === 'Saudi Arabia'
  );
  if (saudiArabiaIndex > -1) {
    const saudiArabia = data.countriesDetailsList.splice(saudiArabiaIndex, 1);
    data.countriesDetailsList = saudiArabia.concat(data.countriesDetailsList);
  }

  return {
    processDescription: data.processDescription,
    lookups: {
      DEPENDANT_RELATIONSHIP: data.lookups.DEPENDANT_RELATIONSHIP.map((item) => ({
        value: item.id,
        label: item.value,
      })),
      MARITAL_STATUS: data.lookups.MARITAL_STATUS.map((item) => ({
        value: item.id,
        label: item.value,
      })),
      DEGREE_TYPE: data.lookups.DEGREE_TYPE.map((item) => ({
        value: item.id,
        label: item.value,
      })),
      GENDER: data.lookups.GENDER.map((item) => ({
        value: item.id,
        label: item.value,
      })),
      EMERGENCY_CONTACT_RELATIONSHIP: data.lookups.EMERGENCY_CONTACT_RELATIONSHIP.map((item) => ({
        value: item.id,
        label: item.value,
      })),
      COUNTRIES: data.countriesDetailsList.map((item) => ({
        value: item.id,
        label: item.countryName,
      })),
      PHONE_CODES: data.countriesDetailsList.map((item) => ({
        value: item.phoneCountryCode,
        label: `${item.phoneCountryCode}-${item.countryName}`,
      })),
    },
  };
};

import { UploadFile } from 'antd/lib/upload/interface';
import { TaskStatus } from 'types';
import { Values } from './TaskSteps.types';

export interface RequestInformation {
  requestPrettyId: string;
  requestId: string;
  requestName: string;
  requestDescription: string;
  commentsCount?: number;
  processDefinitionId: string;
  endEvent?: string;
  isRequestStatusComplete: boolean;
}

export interface AttachmentFile {
  [key: string]: UploadFile[];
}

export const convertToVariables = (values: Values) => {
  return Object.entries(values).reduce((acc, [fieldName, fieldValue]) => {
    acc[fieldName] = {
      value: fieldValue,
    };

    return acc;
  }, {} as Values);
};

export const filterEmptyFields = (values: Values) =>
  Object.fromEntries(Object.entries(values).filter(([_, fieldValue]) => fieldValue !== undefined));

export const getStatusColor = (status: TaskStatus) =>
  ({
    [TaskStatus.inProgress]: 'blue',
    [TaskStatus.processed]: 'green',
    [TaskStatus.pending]: 'orange',
  }[status]);

export const checkWord = (word: string[], fieldId: string) => {
  const isMatchFieldId = word.filter((word: string) => fieldId.match(word));
  if (isMatchFieldId) {
    const matchId = isMatchFieldId[0]?.length > 0;

    if (matchId) {
      return fieldId;
    }
  }
};

export const submitActiveTask = (
  rejectField: string[],
  feedbackField: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info: { values: any; forms: any }
) => {
  if (
    rejectField &&
    feedbackField &&
    info.values[rejectField[0]] === true &&
    (info.values[feedbackField[0]] === undefined ||
      info.values[feedbackField[0]] === '' ||
      info.values[feedbackField[0]] === ' ')
  ) {
    return true;
  } else {
    return false;
  }
};

export const isAttacmentsPresent = (attachmentsForm: AttachmentFile[]) => {
  if (attachmentsForm.length > 0 && attachmentsForm[0].attachment.length > 0) {
    return true;
  } else {
    return false;
  }
};

import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Tabs } from 'antd';

import { AppLayout } from 'layouts';
import { Header } from 'app';
import { TitleH1 } from 'ui';
import { useTranslate } from 'translations';
import {
  StyledTabs,
  StyledNavigationWrapper,
  StyledOpenButton,
  StyledTeamsWrapper,
  StyledTeamsSidebar,
  StyledRow,
  StyledSelectIcon,
} from '../MSTeams.styled';
import { useMSTeamsContext } from '../MSTeamsProvider';
import { MSTeamsSearch } from '../MSTeamsSearch';
import { MSTeamsTeamsSidebar } from '../MSTeamsTeamsTabs/MSTeamsTeamsSidebar';
import { MSTeamsChatSidebar } from '../MSTeamsChatTabs/MSTeamsChatSidebar';
import { MSTeamsPage } from '../MSTeamsPage';
import { MSTeamsConfirm } from '../MSTeamsConfirm';

const { TabPane } = Tabs;

export const MSTeamsLayout = () => {
  const { t } = useTranslate();
  const [activeTabKey, setActiveTabKey] = useState('0');
  const { currentSearchUserId, setCurrentSearchUserId } = useMSTeamsContext();

  const onConfirm = () => {
    window.open('msteams:');
  };
  useEffect(() => {
    if (currentSearchUserId) {
      setActiveTabKey('1');
    }
  }, [currentSearchUserId]);

  const onTabClick = useCallback(
    (key) => {
      setActiveTabKey(key);
      setCurrentSearchUserId('');
    },
    [setCurrentSearchUserId]
  );

  return (
    <AppLayout displayFooter={false}>
      <Header>
        <Row align="middle">
          <Col>
            <TitleH1>{t('msteams.title')}</TitleH1>
          </Col>

          <Col flex="1">
            <Row justify="center" align="middle">
              <Col span={7} style={{ display: 'flex' }}>
                <MSTeamsSearch />
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>

      <StyledTeamsWrapper className="layout-main">
        <StyledRow>
          <Col span={6}>
            <StyledTeamsSidebar>
              <StyledTabs
                activeKey={activeTabKey}
                defaultActiveKey={activeTabKey}
                onTabClick={onTabClick}
                destroyInactiveTabPane
              >
                <TabPane tab={t('msteams.tab.teams')} key="0">
                  <MSTeamsTeamsSidebar />
                </TabPane>
                <TabPane tab={t('msteams.tab.chat')} key="1">
                  <MSTeamsChatSidebar />
                </TabPane>
              </StyledTabs>
              <StyledNavigationWrapper>
                <StyledOpenButton>
                  <MSTeamsConfirm onConfirm={onConfirm}>
                    <Row justify="center" align="middle">
                      <Col>{t('msteams.button.openDesktop')}</Col>
                      <StyledSelectIcon />
                    </Row>
                  </MSTeamsConfirm>
                </StyledOpenButton>
              </StyledNavigationWrapper>
            </StyledTeamsSidebar>
          </Col>
          <Col span={18}>
            <MSTeamsPage activeTabKey={activeTabKey} />
          </Col>
        </StyledRow>
      </StyledTeamsWrapper>
    </AppLayout>
  );
};

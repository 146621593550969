import React, { FC, useMemo } from 'react';
import { useTranslate } from 'translations';
import { PayrollInformation } from 'ui/components';
import { useEmploymentDetails } from 'api';
import {
  IBankDetail,
  IEducationalDegree,
  IExperience,
  IProfessionalCertificate,
  ITraining,
} from 'types/NewProfile';
import { ProfileSectionContainer } from 'ui';
import { IPrivateProfileGeneralInfo, PrivateProfileGeneralInfo } from '../ProfileEmploymentDetails';
import { PrivateProfileEducationalDegree } from '../PrivateProfileEducationalDegree';
import { PrivateProfileExperience } from '../PrivateProfileExperience';
import { PrivateProfileProfessionalCertificate } from '../PrivateProfileProfessionalCertificate';
import { PrivateProfileTraining } from '../PrivateProfileTraining';
import { PrivateProfileBankDetail } from '../PrivateProfileBankDetail';
import { StyledPrivateProfileTabContentWrapper } from './PrivateProfileTabs.styled';

type Props = {
  hcmid?: string | null;
};

export const PrivateProfileEmploymentDetailsTab: FC<Props> = ({ hcmid }) => {
  const { t } = useTranslate();
  const { data: employmentDetail, isLoading, error } = useEmploymentDetails();

  const generalInfo: IPrivateProfileGeneralInfo = useMemo(() => {
    return {
      hireDate: employmentDetail?.hireDate ?? '',
      employeeId: employmentDetail?.employeeId ?? '',
      lastPromotionDate: employmentDetail?.lastPromotionDate ?? '',
      yearsWorkedInTheCompany: employmentDetail?.yearsWorkedInTheCompany ?? '',
      employmentType: employmentDetail?.employmentType ?? '',
      assignmentStatus: employmentDetail?.assignments[0]?.assignmentStatus ?? '',
    };
  }, [employmentDetail]);

  const experiences: IExperience[] = useMemo(() => {
    return employmentDetail?.experiences ?? [];
  }, [employmentDetail]);

  const professionalCertificates: IProfessionalCertificate[] = useMemo(() => {
    return employmentDetail?.professionalCertificates ?? [];
  }, [employmentDetail]);

  const training: ITraining[] = useMemo(() => {
    return employmentDetail?.training ?? [];
  }, [employmentDetail]);

  const educationalDegrees: IEducationalDegree[] = useMemo(() => {
    return employmentDetail?.educationalDegrees ?? [];
  }, [employmentDetail]);

  const bankDetails: IBankDetail[] = useMemo(() => {
    return employmentDetail?.bankDetails ?? [];
  }, [employmentDetail]);

  return (
    <StyledPrivateProfileTabContentWrapper>
      <ProfileSectionContainer
        sectionName={t('user.privateProfile.title.generalInformation')}
        isLoading={isLoading}
        isEmpty={!!error}
      >
        <PrivateProfileGeneralInfo t={t} generalInfo={generalInfo} />
      </ProfileSectionContainer>

      <ProfileSectionContainer isLoading={false}>
        {hcmid && <PayrollInformation hcmPersonId={hcmid} hireDate={generalInfo.hireDate} />}
      </ProfileSectionContainer>

      <ProfileSectionContainer
        sectionName={t('profile.personal.sections.experience')}
        isLoading={isLoading}
        isEmpty={error ? true : experiences?.length <= 0}
      >
        <PrivateProfileExperience t={t} experiences={experiences} />
      </ProfileSectionContainer>

      <ProfileSectionContainer
        sectionName={t('profile.personal.sections.educationalDegree')}
        isLoading={isLoading}
        isEmpty={error ? true : educationalDegrees?.length <= 0}
      >
        <PrivateProfileEducationalDegree t={t} educationalDegrees={educationalDegrees} />
      </ProfileSectionContainer>

      <ProfileSectionContainer
        sectionName={t('profile.personal.sections.professionalCertificates')}
        isLoading={isLoading}
        isEmpty={error ? true : professionalCertificates?.length <= 0}
        hideOnEmpty={true}
      >
        <PrivateProfileProfessionalCertificate
          t={t}
          professionalCertificates={professionalCertificates}
        />
      </ProfileSectionContainer>

      <ProfileSectionContainer
        sectionName={t('profile.personal.sections.training')}
        isLoading={isLoading}
        isEmpty={error ? true : training?.length <= 0}
        hideOnEmpty={true}
      >
        <PrivateProfileTraining t={t} training={training} />
      </ProfileSectionContainer>

      <ProfileSectionContainer
        isLoading={isLoading}
        isEmpty={error ? true : bankDetails?.length <= 0}
      >
        <PrivateProfileBankDetail t={t} bankDetails={bankDetails} />
      </ProfileSectionContainer>
    </StyledPrivateProfileTabContentWrapper>
  );
};

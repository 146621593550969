import React, { FC, memo, useState, ChangeEvent } from 'react';
import { Col, Row } from 'antd';
import { FullWidthSpin, TitleH2 } from 'ui/elements';
import { SearchOutlined } from '@ant-design/icons';
import { TType, withTranslate } from 'translations';
import { getUserDepartment, useSelector } from 'store';
import { useAllRequestCard } from 'api';
import { RequestCatalogDrawer } from '../RequestsCatalogDrawer';
import { StyledInput } from './RequestsCatalogCards.styled';
import { useCards } from './useCards';
import { RequestsCatalogCategory } from './RequestsCatalogCategory';

const ROW_STYlES = {
  paddingBottom: '24px',
};

interface Props {
  t: TType;
  selectedCategory: string | undefined;
  selectedSubCategory: string | undefined;
  search: string;
  searchTitle: string;
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const RequestsCatalogCardsComponent: FC<Props> = ({
  t,
  selectedCategory = '',
  selectedSubCategory = '',
  search,
  onSearchChange,
  searchTitle,
}) => {
  const [selectedProcess, setSelectedProcess] = useState<
    | {
        processDefinitionKey: string;
        name: string;
        notAllowToCreateRequest?: boolean;
        isDynamic?: boolean;
        isJira?: boolean;
      }
    | undefined
  >(undefined);
  const userDepartment = useSelector(getUserDepartment);

  const allRequestsCardsInfo = useAllRequestCard();

  const { title, cards, isSubTitle } = useCards({
    allCards: allRequestsCardsInfo.data,
    selectedCategory,
    selectedSubCategory,
    t,
    search,
    searchTitle,
  });

  const setProcessDefinitionKey = (processDefinitionKey: string) => {
    if (processDefinitionKey) {
      setSelectedProcess({
        processDefinitionKey,
        name: processDefinitionKey,
      });
    } else {
      setSelectedProcess(undefined);
    }
  };

  if (allRequestsCardsInfo.isLoading) {
    return (
      <div>
        <FullWidthSpin />
      </div>
    );
  }

  return (
    <div>
      <Row justify={'space-between'} style={ROW_STYlES}>
        <Col>
          <TitleH2 strong>{title}</TitleH2>
        </Col>
        <Col>
          <StyledInput
            allowClear
            placeholder={t('search')}
            prefix={<SearchOutlined />}
            onChange={onSearchChange}
            key={`${selectedCategory}-${selectedSubCategory}`}
          />
        </Col>
      </Row>

      <RequestsCatalogCategory
        cards={cards}
        isSubTitle={isSubTitle}
        onCardClick={setSelectedProcess}
        isPath={!!search}
        userDepartment={userDepartment}
      />

      {selectedProcess && (
        <RequestCatalogDrawer
          currentProcessName={selectedProcess.name}
          setProcessDefinitionKey={setProcessDefinitionKey}
          processDefinitionKey={selectedProcess.processDefinitionKey}
          notAllowToCreateRequest={selectedProcess.notAllowToCreateRequest}
          isDynamic={selectedProcess.isDynamic}
          isJira={selectedProcess.isJira}
        />
      )}
    </div>
  );
};

export const RequestsCatalogCards = memo(withTranslate(RequestsCatalogCardsComponent));

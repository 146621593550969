import React, { FC, memo } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import { RecentFiles } from 'types';
import { colors } from 'ui/elements';

import { OneDriveConfirm } from '../OneDriveConfirm';
import { OneDriveDownload } from '../OneDriveDownload';
import { OneDriveButton } from '../OneDriveMyFiles/OneDriveMyFiles.styled';
import {
  FileName,
  StyledCardFile,
  StyledColAlign,
  StyledDateText,
  StyledItemIcon,
  StyledWrapperContent,
} from './OneDriveWidget.styled';

export interface FileCardProps {
  list?: RecentFiles;
}

export const OneDriveFileCardComponent: FC<FileCardProps> = ({ list }) => {
  return (
    <StyledCardFile bgColor={colors.greyBackLabel} focusBgColor={colors.greyBackLabelHover}>
      <StyledWrapperContent align={'middle'}>
        <StyledColAlign span={4}>
          <StyledItemIcon />
        </StyledColAlign>
        <Col span={16}>
          <Row justify={'start'}>
            <Col span={24}>
              <OneDriveButton type="link" style={{ width: '100%' }}>
                <OneDriveConfirm
                  onConfirm={() => window.open(list?.webUrl, '_blank')}
                  style={{ width: '100%' }}
                >
                  <FileName strong ellipsis color={colors.lightOverlayBlue}>
                    {list?.name}
                  </FileName>
                </OneDriveConfirm>
              </OneDriveButton>
            </Col>
            <Col span={24}>
              <StyledDateText size={13}>
                {moment(list?.lastModifiedDate).format('MMM DD, YYYY')}
              </StyledDateText>
            </Col>
          </Row>
        </Col>
        <StyledColAlign span={4}>
          {list?.remoteItem && (
            <OneDriveDownload
              driveId={list.remoteItem.parentReference.driveId}
              itemId={list.remoteItem.id}
              iconColor={colors.lightGrey}
            />
          )}
        </StyledColAlign>
      </StyledWrapperContent>
    </StyledCardFile>
  );
};

export const OneDriveFileCard = memo(OneDriveFileCardComponent);

import { connect, ConnectedProps } from 'react-redux';

import {
  RootState,
  getProfileStart,
  getHasUserProfileData,
  getUserProfileDataNotReady,
  profileData,
} from 'store';

const mapDispatch = {
  getProfileStart,
};

const mapState = (state: RootState) => ({
  userProfileData: profileData(state),
  hasUserProfileData: getHasUserProfileData(state),
  userProfileDataNotReady: getUserProfileDataNotReady(state),
});

export const privateRouteConnector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof privateRouteConnector>;

export type ConnectorProps = PropsFromRedux;

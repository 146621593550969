import React, { FC, memo } from 'react';
import { useAllMemberOfGroup } from 'api';
import { StyledLeavesWrapper as StyledContentWrapper } from 'routes/Calendar';
import { constants, FullWidthSpin } from 'ui';
import { StyledDivider } from 'ui/components/TaskList/TaskSteps/TaskInfo/TaskInfo.styled';
import { useTranslate } from 'translations';
import { SelectedProps } from '../ProcessGroups';
import {
  StyledAddUserBtn,
  StyledProcessGroupDrawer,
  StyledTitleDrawer,
  StyledWrapperAddUser,
} from './ProcessGroupDrawer.styled';
import { MangerList } from './ProcessGroupDrawerManger';
import { DrawerMemberList } from './ProcessGroupDrawerMemberList';
import { AddUserToGroup } from './ProcessGroupAddUser';

interface Props {
  selectedGroupInfo: SelectedProps;
  onDrawerClose: () => void;
}

export const ProcessGroupDrawerComponent: FC<Props> = ({ selectedGroupInfo, onDrawerClose }) => {
  const allMember = useAllMemberOfGroup(selectedGroupInfo.groupId);
  const { t } = useTranslate();

  return (
    <>
      <StyledProcessGroupDrawer
        visible={!!selectedGroupInfo.groupId}
        closable
        maskClosable
        destroyOnClose
        getContainer={false}
        placement="right"
        onClose={onDrawerClose}
        width={constants.drawer.width}
      >
        <StyledContentWrapper>
          <StyledTitleDrawer>{selectedGroupInfo.groupName}</StyledTitleDrawer>
          <StyledDivider />
          {allMember.isLoading || allMember.isFetching ? (
            <FullWidthSpin />
          ) : (
            allMember.data && (
              <>
                <MangerList mangerList={allMember.data.managersList} />
                <DrawerMemberList
                  memberList={allMember.data.membersList}
                  groupId={selectedGroupInfo.groupId}
                  refetchList={allMember.refetch}
                />
              </>
            )
          )}
          <StyledWrapperAddUser>
            <AddUserToGroup groupId={selectedGroupInfo.groupId} refetchList={allMember.refetch}>
              <StyledAddUserBtn disabled={allMember.isLoading || allMember.isError}>
                {t('processManagement.groups.drawer.addUser')}
              </StyledAddUserBtn>
            </AddUserToGroup>
          </StyledWrapperAddUser>
        </StyledContentWrapper>
      </StyledProcessGroupDrawer>
    </>
  );
};

export const ProcessGroupDrawer = memo(ProcessGroupDrawerComponent);

import React, { FC } from 'react';
import { ProfileSectionContainer } from 'ui';
import {
  StyledPrivateProfileTabContentWrapper,
  StyledPrivateProfileOrganizationalHierarchy,
} from './PrivateProfileTabs.styled';

type Props = {
  hcmid?: string | null;
};

export const PrivateProfileHierarchyTab: FC<Props> = ({ hcmid }) => {
  return (
    <StyledPrivateProfileTabContentWrapper>
      <ProfileSectionContainer isEmpty={!hcmid}>
        {hcmid && <StyledPrivateProfileOrganizationalHierarchy hcmId={hcmid} withoutTitle={true} />}
      </ProfileSectionContainer>
    </StyledPrivateProfileTabContentWrapper>
  );
};

import React, { useMemo } from 'react';
import { Col } from 'antd';
import { TType } from 'translations';
import { ProfileEntryField } from 'ui';
import { StyledProfileRowContainer } from '../../NewProfile.styled';
import {
  StyledEmergencyItemTitle,
  StyledEmergencyItemWrapper,
} from './PrivateProfileEmergencyContactItem.styles';

type PrivateProfileEmergencyContactItemProps = {
  t: TType;
  familyName: string;
  mobileNumber: string;
  relationship: string;
  title: string;
};

export const PrivateProfileEmergencyContactItem: React.FC<PrivateProfileEmergencyContactItemProps> = ({
  t,
  familyName,
  mobileNumber,
  relationship,
  title,
}: PrivateProfileEmergencyContactItemProps) => {
  const emergencyFields = useMemo(
    () => [
      { label: t('user.privateProfile.emergencyContact.familyName'), value: familyName },
      { label: t('user.privateProfile.emergencyContact.relationship'), value: relationship },
      { label: t('user.privateProfile.emergencyContact.mobileNumber'), value: mobileNumber },
    ],
    [familyName, mobileNumber, relationship, t]
  );
  return (
    <StyledEmergencyItemWrapper>
      <StyledEmergencyItemTitle>{title}</StyledEmergencyItemTitle>
      <StyledProfileRowContainer gutter={[25, 15]} wrap={true}>
        {emergencyFields.map(({ label, value }, index) => (
          <Col xs={24} sm={24} md={12} lg={8} key={index}>
            <ProfileEntryField label={label} value={value} />
          </Col>
        ))}
      </StyledProfileRowContainer>
    </StyledEmergencyItemWrapper>
  );
};

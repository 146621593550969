import React, { FC } from 'react';
import { Layout } from 'antd';
import { Footer } from '../Footer';
import { AppLayoutProps } from './AppLayout.types';
import { AppLayoutTitle } from './AppLayoutTitle';
import { LayoutComponent } from './AppLayoutStyle';
import { AppLayoutAlert } from './AppLayoutAlert';

const { Content } = Layout;

interface TitleStaticComponents {
  Title: React.FC;
}

export const AppLayout: FC<AppLayoutProps> & TitleStaticComponents = ({
  children,
  title,
  displayFooter = true,
  isLoginPage,
}) => (
  <LayoutComponent $isLoginPage={isLoginPage}>
    <AppLayoutAlert />
    <Content className="content-wrapper">
      {title && <AppLayoutTitle>{title}</AppLayoutTitle>}
      {children}
    </Content>
    {displayFooter && <Footer isLoginPage={isLoginPage} />}
  </LayoutComponent>
);

AppLayout.Title = AppLayoutTitle;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus } from 'types';
import { RootFolder, RootFolderInitialState } from './oneDriveRootFolderTypes';

export const emptyRootFolderState: RootFolderInitialState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetRootFolderStart = PayloadAction;
export type GetRootFolderSuccess = PayloadAction<RootFolder>;
export type GetRootFolderFail = PayloadAction<{ error: RequestCommonError }>;

const rootFolder = createSlice({
  name: 'rootFolder',
  initialState: emptyRootFolderState,
  reducers: {
    getRootFolderStart(state, _: GetRootFolderStart) {
      state.status = RequestStatus.pending;
    },
    getRootFolderSuccess(state, action: GetRootFolderSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getRootFolderFail(state, action: GetRootFolderFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload.error;
    },
  },
});

export const { getRootFolderFail, getRootFolderStart, getRootFolderSuccess } = rootFolder.actions;

export default rootFolder.reducer;

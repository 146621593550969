import { Gutter } from 'antd/lib/grid/row';

export const AllRequestLocation = {
  pathname: `/tasks`,
  state: { defaultTab: '0' },
};

export const NewRequest = {
  pathname: `/tasks`,
  state: { defaultTab: '1' },
};

export const breakPoint = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  xxl: { span: 12 },
};

export const GUTTER_0_10: [Gutter, Gutter] = [0, 10];
export const GUTTER_0_5: [Gutter, Gutter] = [0, 5];

import { Col, Row } from 'antd';
import React from 'react';
import { space, TitleH3 } from 'ui/elements';
import styled from 'styled-components';

interface TitleGroupNameProp {
  title: string;
  withoutTopSpace?: boolean;
  bottomSpace?: number;
}

const StyledTitle = styled(TitleH3).attrs({
  type: 'secondary',
})<{ $withoutTopSpace?: boolean; $bottomSpace?: number }>`
  &.ant-typography {
    margin-bottom: ${({ $bottomSpace }) => ($bottomSpace ? $bottomSpace + 'px' : space.cozy)};
    margin-top: ${({ $withoutTopSpace }) => ($withoutTopSpace ? 0 : space.normal)};
  }
`;

export const TitleGroupName: React.FC<TitleGroupNameProp> = ({
  title,
  withoutTopSpace,
  bottomSpace,
}) => {
  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <StyledTitle $withoutTopSpace={withoutTopSpace} $bottomSpace={bottomSpace}>
          {title}
        </StyledTitle>
      </Col>
    </Row>
  );
};

import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { AppLayout } from 'layouts';
import { Header } from 'app';
import { TType, withTranslate } from 'translations';
import { TitleH1 } from 'ui/elements';
import { ELibraryMainPage, ELibrarySideBar } from './components';
import {
  StyledELibraryPage,
  StyledELibrarySidebar,
  StyledELibraryWrapper,
  StyledRow,
} from './ELibrary.styled';

interface Props {
  t: TType;
}

export const ELibrary: FC<Props> = withTranslate(({ t }) => {
  const [selectedDepartment, setSelectedDepartment] = useState<string | undefined>();
  const [selectedSector, setSelectedSector] = useState<string | undefined>();
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);

  useEffect(() => {
    if (isFilterApplied) {
      setSelectedDepartment(undefined);
      setSelectedSector(undefined);
    }
  }, [isFilterApplied]);

  return (
    <AppLayout displayFooter={false}>
      <Header>
        <Row align="middle">
          <Col>
            <TitleH1>{t('eLibrary.eLibrary')}</TitleH1>
          </Col>
        </Row>
      </Header>

      <StyledELibraryWrapper className="layout-main">
        <StyledRow>
          <Col span={6}>
            <StyledELibrarySidebar>
              <ELibrarySideBar
                t={t}
                onDepartmentSelect={(departmentName, sectorName) => {
                  setSelectedDepartment(departmentName);
                  setSelectedSector(sectorName);
                }}
                selectedDepartmentName={selectedDepartment}
                isFilterApplied={isFilterApplied}
              />
            </StyledELibrarySidebar>
          </Col>
          <Col span={18}>
            <StyledELibraryPage>
              <ELibraryMainPage
                selectedDepartmentName={selectedDepartment}
                selectedSectorName={selectedSector}
                onFilterChange={setIsFilterApplied}
                t={t}
              />
            </StyledELibraryPage>
          </Col>
        </StyledRow>
      </StyledELibraryWrapper>
    </AppLayout>
  );
});

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetuserInfoStatusFail,
  GetuserInfoStatusStart,
  GetuserInfoStatusSuccess,
  getuserInfoStatusFail,
  getuserInfoStatusStart,
  getuserInfoStatusSuccess,
} from './userInfoStatusSlice';

export const getuserInfoStatusEpic: Epic<
  GetuserInfoStatusStart,
  GetuserInfoStatusSuccess | GetuserInfoStatusFail,
  RootState,
  typeof API
> = (action$, _, { userInfoCardStatus }) =>
  action$.pipe(
    filter(getuserInfoStatusStart.match),
    exhaustMap(({ payload }) =>
      from(userInfoCardStatus.getUserStatus(payload)).pipe(
        map(({ data }) =>
          getuserInfoStatusSuccess({
            inputValue: Object.values(payload)[0],
            data,
          })
        ),
        catchError((error) =>
          of(
            getuserInfoStatusFail({
              error: error?.response?.data,
              inputValue: Object.values(payload)[0],
            })
          )
        )
      )
    )
  );

import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import { UploadFile } from 'antd/lib/upload/interface';
import { serialize } from 'utils';
import { leaseSites } from '../LeaseSites';

export const useGetAttachmentPromises = () =>
  useCallback(
    ({
      taskId,
      processInstanceId,
      activityId,
      processDefinitionId,
      attachment,
    }: {
      taskId: string;
      processInstanceId: string;
      processDefinitionId: string;
      activityId: string;
      attachment: { files?: UploadFile[]; params?: AxiosRequestConfig['params'] };
    }) => {
      const attachmentPromises = attachment?.files?.map(async (file) => {
        return leaseSites.postRequestsAssignWoTaskAttachment(
          {
            taskId,
            processInstanceId,
            activityId,
            processDefinitionId,
          },
          {
            data: serialize({ content: file.originFileObj }),
            params: attachment.params,
          }
        );
      });

      return attachmentPromises?.length ? Promise.all(attachmentPromises) : undefined;
    },
    []
  );

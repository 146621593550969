import React, { FC, useCallback, useEffect, useState } from 'react';
import { Row, Col, Button, notification } from 'antd';
import { LeavesPageRoutes } from 'namespace';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslate } from 'translations';
import { LeavesTypesData, ProfileData, RequestStatus } from 'types';
import { FilterContextType } from 'ui/components/Filter/Filter.types';
import { leaves as leavesApi } from 'api';
import {
  getCurrentUserAdoid,
  getLeavesListStart,
  getPublicHolidaysStart,
  leavesListDataSelector,
  publicHolidaysSelector,
  useDispatch,
  useSelector,
} from 'store';
import { EventList } from 'ui';

import { BalanceWidget } from 'routes/Calendar/Components';
import { LeavesFilter, concatEventList, convertFilterDates, LeaveDrawer } from 'routes/Calendar';
import { ListView } from '../../Components/LeaveListView';
import { CalendarView } from '../../Components/CalendarView';
import {
  PageHeader,
  StyledAddFilter,
  StyledButton,
  ButtonContent,
  StyledLeavesContent,
  ListActiveIconStyled,
  ListUnActiveIconStyled,
  CalendarFilledIconStyled,
  CalendarOutlinedIconStyled,
} from './Leaves.styled';

enum ViewType {
  'list' = 'list',
  'calendar' = 'calendar',
}

const limitRequestPerMonth = 35;
export const LIST_VIEW_PAGE_SIZE = 10;

interface LeavesProps {
  profileData: ProfileData;
}

export const Leaves: FC<LeavesProps> = ({ profileData }) => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [currentLeaveId, setCurrentLeaveId] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const hcmPersonId = profileData.hcmId as string;
  const [viewType, setViewType] = useState<ViewType>(ViewType.calendar);
  const [filterValues, setFilterValues] = useState<FilterContextType['filterValues']>({});
  const [events, setEvents] = useState<EventList[]>([]);
  const { data: leavesListData, status: leavesListStatus } = useSelector(leavesListDataSelector);
  const [leavesTypes, setLeavesTypes] = useState<LeavesTypesData[]>([]);
  const [isLeavesTypesLoading, setIsLeavesTypesLoading] = useState<boolean>(true);
  const { data: publicHolidays, status: publicStatus } = useSelector(publicHolidaysSelector);
  const [isLoading, setIsLoading] = useState<boolean>();
  const userAdoId = useSelector(getCurrentUserAdoid);

  const getUrl = useCallback((pagePath: string) => `${path}${pagePath}${location.search}`, [
    location.search,
    path,
  ]);

  useEffect(() => {
    if (RequestStatus.idle === publicStatus) dispatch(getPublicHolidaysStart());
  }, [dispatch, publicStatus]);

  const getLeaveList = useCallback(
    (selectedMonth: string) => {
      setIsLoading(true);

      dispatch(
        getLeavesListStart({
          hcmPersonId,
          params: {
            limit: limitRequestPerMonth,
            startDate: selectedMonth,
            endDate: selectedMonth,
          },
        })
      );
    },
    [dispatch, hcmPersonId]
  );

  useEffect(() => {
    if (leavesListData && publicHolidays) {
      setIsLoading(false);
      const eventList = concatEventList(leavesListData.list, publicHolidays);

      eventList && setEvents(eventList);
    }
  }, [leavesListData, publicHolidays]);

  useEffect(() => {
    (async () => {
      try {
        setIsLeavesTypesLoading(true);
        const resp = await leavesApi.getLeavesAllTypes();

        setLeavesTypes(resp.data);
      } catch (e) {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: t('messages.error.tryAgainLater'),
        });
      } finally {
        setIsLeavesTypesLoading(false);
      }
    })();
  }, [t]);

  const setLeavesFilter = useCallback((values) => {
    setCurrentPage(0);
    setFilterValues(values);
  }, []);

  const setListViewType = useCallback(() => setViewType(ViewType.list), []);
  const setCalendarViewType = useCallback(() => setViewType(ViewType.calendar), []);

  const dispatchGetLeaves = useCallback(() => {
    dispatch(
      getLeavesListStart({
        hcmPersonId,
        params: {
          offset: currentPage * LIST_VIEW_PAGE_SIZE,
          limit: LIST_VIEW_PAGE_SIZE,
          ...convertFilterDates(filterValues),
        },
      })
    );
  }, [currentPage, dispatch, filterValues, hcmPersonId]);

  return (
    <>
      <PageHeader size="small">
        <Row justify="space-between" align="middle">
          <Col>
            <StyledAddFilter>
              {viewType === ViewType.list ? (
                <LeavesFilter
                  setFilterValues={setLeavesFilter}
                  leavesTypes={leavesTypes}
                  isLeavesTypesLoading={isLeavesTypesLoading}
                  filterValues={filterValues}
                />
              ) : null}
            </StyledAddFilter>
          </Col>
          <Col>
            <Button type="text" onClick={setCalendarViewType}>
              <ButtonContent $active={viewType === ViewType.calendar}>
                {viewType === ViewType.calendar ? (
                  <CalendarFilledIconStyled />
                ) : (
                  <CalendarOutlinedIconStyled />
                )}{' '}
                {t('leaves.calendar.title')}
              </ButtonContent>
            </Button>
            <Button type="text" onClick={setListViewType}>
              <ButtonContent $active={viewType === ViewType.list}>
                {viewType === ViewType.list ? <ListActiveIconStyled /> : <ListUnActiveIconStyled />}{' '}
                {t('leaves.list.title')}
              </ButtonContent>
            </Button>
            <StyledButton type={'primary'}>
              <Link to={getUrl(LeavesPageRoutes.NewLeaveRequest)}>
                <PlusOutlined /> {t('leaves.createNew.title')}
              </Link>
            </StyledButton>
          </Col>
        </Row>
      </PageHeader>
      <StyledLeavesContent>
        {viewType === ViewType.calendar ? (
          <CalendarView
            setCurrentLeaveId={setCurrentLeaveId}
            refetchList={getLeaveList}
            eventList={events}
            isLoading={isLoading}
          />
        ) : (
          <ListView
            setCurrentLeaveId={setCurrentLeaveId}
            filterValues={filterValues}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            dispatchGetLeaves={dispatchGetLeaves}
            leavesListData={leavesListData.list}
            leavesListStatus={leavesListStatus}
            totalResult={leavesListData.totalResult}
          />
        )}
        <BalanceWidget
          profileData={profileData}
          leavesTypes={leavesTypes}
          isLeavesTypesLoading={isLeavesTypesLoading}
        />
      </StyledLeavesContent>

      {currentLeaveId && userAdoId && (
        <LeaveDrawer
          setCurrentLeaveId={setCurrentLeaveId}
          currentLeaveId={currentLeaveId}
          setListViewType={setListViewType}
          dispatchGetLeaves={dispatchGetLeaves}
          t={t}
          adoId={userAdoId}
          myTeamLeave={false}
        />
      )}
    </>
  );
};

import { QUERIES } from 'api/constants';
import { userInfoCardStatus } from 'api/UserInfoStatus';
import { useQuery } from 'react-query';

export function useGetUserStatus(email: string) {
  return useQuery(
    [QUERIES.getUserStatus, email],
    async () => {
      const params = {
        email: email,
      };
      const { data } = await userInfoCardStatus.getUserStatus({ params });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );
}

import { useQuery } from 'react-query';
import { staticForm } from 'api/StaticForm';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

export function useFilterDelegatorNames() {
  return useQuery(
    [QUERIES.getDelegatorsFilterNames],
    async () => (await staticForm.getDelegatorsFilterNames()).data,
    { ...PERSIST_QUERY_CONFIG, enabled: !disableAggregator }
  );
}

import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';

import {
  GetTaskCommentsFail,
  GetTaskCommentsStart,
  GetTaskCommentsSuccess,
  getTaskCommentsFail,
  getTaskCommentsStart,
  getTaskCommentsSuccess,
} from './taskCommentsSlice';

export const TaskCommentsEpic: Epic<
  GetTaskCommentsStart,
  GetTaskCommentsSuccess | GetTaskCommentsFail,
  RootState,
  typeof API
> = (action$, _, { taskComments }) =>
  action$.pipe(
    filter(getTaskCommentsStart.match),
    mergeMap(({ payload }) =>
      from(taskComments.getTaskComments(payload.taskId)).pipe(
        map(({ data }) => getTaskCommentsSuccess({ data, taskId: payload.taskId })),
        catchError((error) =>
          of(getTaskCommentsFail({ error: error?.response?.data, taskId: payload.taskId }))
        )
      )
    )
  );

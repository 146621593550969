import {
  Core,
  LEASE_CITIES,
  LEASE_CREATE_TASK_ATTACHMENT,
  LEASE_DISTRICTS,
  LEASE_SITES_ASSIGN_WO_TASK_FORM_DETAILS,
  LEASE_REQUEST,
  LEASE_SITES_FORM_DETAILS,
  LEASE_SITES_REQUESTS_ASSIGN_WO_TASK,
  LEASE_TASK_ATTACHMENT,
  LEASE_REQUEST_FORM_DETAILS,
  LEASE_SITES_LIST_FILTER_LOOKUPS,
  LEASE_SITES_LIST,
  LEASE_SITES_REQUESTS_FIELD_RUNNER,
  LEASE_SITES_REQUESTS_FIELD_RUNNER_DRAFT,
  LEASE_BANK_BRANCHES,
} from 'api';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import {
  ILeaseSitesList,
  ILeaseSitesListFilterLookups,
  ILeaseSitesListOptions,
  ILeaseSitesSubmittedCandidates,
  LeaseSitesAssignWoTaskFormDetailsResponse,
  LeaseSitesFormDetailsResponse,
  LeaseSitesRequestAssignWoTaskData,
  LeaseSitesRequestData,
  LeaseSitesRequestResponse,
  LeaseSitesFieldRunnerRequestData,
  LeaseSitesFieldRunnerResponse,
  LeaseSitesRequestsFormDetailsResponse,
  LoVs,
  PaginatedLoVs,
  LeaseSitesFieldRunnerRequestDraftData,
  ILeaseSitesCandidatesDraft,
} from 'types';

interface GetFormDetails {
  (): AxiosPromise<LeaseSitesFormDetailsResponse>;
}

interface GetCities {
  (cityName: string): AxiosPromise<PaginatedLoVs>;
}

interface GetDistricts {
  (cityId: string): AxiosPromise<LoVs>;
}

interface GetBankBranches {
  (bankId: string): AxiosPromise<LoVs>;
}

interface GetRequestsFormDetails {
  (processInstanceId: string): AxiosPromise<LeaseSitesRequestsFormDetailsResponse>;
}

interface PostRequest {
  (data: LeaseSitesRequestData): AxiosPromise<LeaseSitesRequestResponse>;
}

interface GetAssignWoTaskFormDetails {
  (): AxiosPromise<LeaseSitesAssignWoTaskFormDetailsResponse>;
}

interface PostRequestsAssignWoTask {
  (payload: {
    data: LeaseSitesRequestAssignWoTaskData;
    processInstanceId: string;
  }): AxiosPromise<LeaseSitesRequestResponse>;
}

interface PostRequestsAssignWoTaskAttachment {
  (
    payload: {
      taskId: string;
      processInstanceId: string;
      processDefinitionId: string;
      activityId: string;
    },
    config: AxiosRequestConfig
  ): AxiosPromise<string>;
}

interface GetTaskAttachment {
  (payload: { taskId: string; attachmentId: string }): AxiosPromise<string>;
}

interface GetSitesListFilterLookups {
  (): AxiosPromise<ILeaseSitesListFilterLookups>;
}

interface GetSitesList {
  (params: ILeaseSitesListOptions): AxiosPromise<ILeaseSitesList>;
}

interface GetSubmittedCandidatesForm {
  (processInstanceId: string): AxiosPromise<ILeaseSitesSubmittedCandidates>;
}

interface GetCandidatesFormDraft {
  (processInstanceId: string): AxiosPromise<ILeaseSitesCandidatesDraft>;
}

interface PostSubmittedCandidatesForm {
  (
    processInstanceId: string,
    data: LeaseSitesFieldRunnerRequestData
  ): AxiosPromise<LeaseSitesFieldRunnerResponse>;
}

interface PostDraftCandidatesForm {
  (
    processInstanceId: string,
    data: LeaseSitesFieldRunnerRequestDraftData
  ): AxiosPromise<LeaseSitesFieldRunnerResponse>;
}

class LeaseSites extends Core {
  getFormDetails: GetFormDetails = async () => this.get(LEASE_SITES_FORM_DETAILS());
  getCities: GetCities = async (cityName) => this.get(LEASE_CITIES(), { params: { cityName } });
  getDistricts: GetDistricts = async (cityId) => this.get(LEASE_DISTRICTS(cityId));
  getBankBranches: GetBankBranches = async (bankId) => this.get(LEASE_BANK_BRANCHES(bankId));
  postRequest: PostRequest = async (data) => this.post(LEASE_REQUEST(), data);
  getAssignWoTaskFormDetails: GetAssignWoTaskFormDetails = async () =>
    this.get(LEASE_SITES_ASSIGN_WO_TASK_FORM_DETAILS());
  postRequestsAssignWoTask: PostRequestsAssignWoTask = async ({ processInstanceId, data }) =>
    this.post(LEASE_SITES_REQUESTS_ASSIGN_WO_TASK(processInstanceId), data);
  postRequestsAssignWoTaskAttachment: PostRequestsAssignWoTaskAttachment = async (
    { taskId, processInstanceId, processDefinitionId, activityId },
    { data, params }
  ) =>
    this.post(
      LEASE_CREATE_TASK_ATTACHMENT(taskId, processInstanceId, activityId, processDefinitionId),
      data,
      {
        params,
      }
    );
  getTaskAttachment: GetTaskAttachment = async ({ taskId, attachmentId }) =>
    this.get(LEASE_TASK_ATTACHMENT(taskId, attachmentId), {
      responseType: 'blob',
    });
  getRequestsFormDetails: GetRequestsFormDetails = async (processInstanceId) =>
    this.get(LEASE_REQUEST_FORM_DETAILS(processInstanceId));
  getSitesList: GetSitesList = async (params) => this.get(LEASE_SITES_LIST(), { params });
  getSitesListFilterLookups: GetSitesListFilterLookups = async () =>
    this.get(LEASE_SITES_LIST_FILTER_LOOKUPS());
  getSubmittedCandidatesForm: GetSubmittedCandidatesForm = async (processInstanceId) =>
    this.get(LEASE_SITES_REQUESTS_FIELD_RUNNER(processInstanceId));
  postRequestsFieldRunner: PostSubmittedCandidatesForm = async (processInstanceId, data) =>
    this.post(LEASE_SITES_REQUESTS_FIELD_RUNNER(processInstanceId), data);
  postRequestsFieldRunnerDraft: PostDraftCandidatesForm = async (processInstanceId, data) =>
    this.post(LEASE_SITES_REQUESTS_FIELD_RUNNER_DRAFT(processInstanceId), data);
  getCandidatesFormDraft: GetCandidatesFormDraft = async (processInstanceId) =>
    this.get(LEASE_SITES_REQUESTS_FIELD_RUNNER_DRAFT(processInstanceId));
}

export const leaseSites = new LeaseSites();

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { RequestCommonError, RequestStatus } from 'types';
import { TeamsUserAvatarState } from './teamsUserAvatarTypes';

export const emptyTeamsUserAvatarInitialState: TeamsUserAvatarState = {};

export type GetTeamsUserAvatarStart = PayloadAction<{ userId: string; params: AxiosRequestConfig }>;
export type GetTeamsUserAvatarSuccess = PayloadAction<{ userId: string; data: string }>;
export type GetTeamsUserAvatarFail = PayloadAction<{ userId: string; error: RequestCommonError }>;

const teamsUserAvatar = createSlice({
  name: 'teamsUserAvatar',
  initialState: emptyTeamsUserAvatarInitialState,
  reducers: {
    getTeamsUserAvatarStart(state, action: GetTeamsUserAvatarStart) {
      state[action.payload.userId] = {
        ...state[action.payload.userId],
        status: RequestStatus.pending,
      };
    },
    getTeamsUserAvatarSuccess(state, action: GetTeamsUserAvatarSuccess) {
      state[action.payload.userId].status = RequestStatus.resolved;
      state[action.payload.userId].data = action.payload.data;
    },
    getTeamsUserAvatarFail(state, action: GetTeamsUserAvatarFail) {
      state[action.payload.userId].status = RequestStatus.rejected;
      state[action.payload.userId].error = action.payload.error;
    },
  },
});

export const {
  getTeamsUserAvatarStart,
  getTeamsUserAvatarSuccess,
  getTeamsUserAvatarFail,
} = teamsUserAvatar.actions;
export default teamsUserAvatar.reducer;

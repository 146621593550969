import { HistoricActivityInstanceItem } from 'types';
import { Core, HISTORIC_ACTIVITY_INSTANCE } from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetProcessDefinitionList {
  (prams: AxiosRequestConfig['params']): Promise<{ data: HistoricActivityInstanceItem[] }>;
}

class ActivityInstance extends Core {
  getHistoricActivityInstance: GetProcessDefinitionList = async (params) =>
    this.get(HISTORIC_ACTIVITY_INSTANCE(), params);
}

export const activityInstance = new ActivityInstance();

import React, { FC } from 'react';
import moment from 'moment';
import { ToolbarProps } from 'react-big-calendar';
import {
  StyledDate,
  StyledToolbar,
  StyledWrapperBtn,
  StyleNavigationButton,
} from 'routes/Calendar/Components/CalendarView/CalendarStyle';
import { useTranslate } from 'translations';

export interface CustomToolbarProps {
  totalSelectedMembers?: number;
}

export const CalendarToolbar: FC<ToolbarProps & CustomToolbarProps> = ({
  date,
  onNavigate,
  totalSelectedMembers,
}) => {
  const currentDate = moment(date);
  const currentDay = currentDate.format('MMM  YYYY');
  const { t } = useTranslate();

  return (
    <StyledToolbar $totalSelectedMembers={totalSelectedMembers}>
      <StyledDate>{currentDay}</StyledDate>
      <StyledWrapperBtn>
        <StyleNavigationButton onClick={() => onNavigate('PREV', date)}>
          {t('leaves.calendar.navigation.back')}
        </StyleNavigationButton>
        <StyleNavigationButton onClick={() => onNavigate('TODAY', date)}>
          {t('leaves.calendar.navigation.today')}
        </StyleNavigationButton>
        <StyleNavigationButton onClick={() => onNavigate('NEXT', date)}>
          {t('leaves.calendar.navigation.next')}
        </StyleNavigationButton>
      </StyledWrapperBtn>
    </StyledToolbar>
  );
};

import React, { FC, memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { StyledModalPopOver, useErrorNotification, useSuccessNotification } from 'ui';
import { FormInstance } from 'antd/lib/form/Form';
import { TType } from 'translations';
import { useRescheduleLeave } from 'api/Leaves/hooks/useRescheduleLeave';
import { CancelScheduledLeave, workday_count } from 'routes/Calendar';
import moment from 'moment';
import { leavesDrawerConstants } from '../constants';
import { UpdateLeaveDate } from './LeaveModalForm';

interface ModalProps {
  visible?: boolean;
  handleModalOk?: () => void;
  handleCancelButton?: () => void;
  confirmLoading?: boolean;
  children?: ReactNode;
  isScheduledAnnualLeave?: boolean;
  isScheduledLeave?: boolean;
  form?: FormInstance;
  onFinish?: () => void;
  startDate: moment.Moment;
  endDate: moment.Moment;
  duration: number | null;
  t: TType;
  leaveId?: number;
  setConfirmLoading?: (isLoading: boolean) => void;
}

const ModalPopoverComponent: FC<ModalProps> = ({
  visible,
  handleModalOk,
  confirmLoading,
  handleCancelButton,
  isScheduledAnnualLeave,
  isScheduledLeave,
  onFinish,
  form,
  startDate,
  endDate,
  duration,
  t,
  leaveId,
  setConfirmLoading,
}) => {
  const [isDaysEqual, setIsDaysEqual] = useState<boolean>(false);
  const [isLessDuration, setIsLessDuration] = useState<boolean>(false);
  const { isError, isSuccess, mutate } = useRescheduleLeave();
  const { RESCHEDULE_START_DATE, RESCHEDULE_END_DATE } = leavesDrawerConstants;

  useEffect(() => {
    if (startDate && endDate) {
      form?.setFieldsValue({
        newStartDate: startDate,
        newEndDate: endDate,
      });
    }
  }, [startDate, endDate, form]);

  useSuccessNotification(
    {
      isNotification: isSuccess,
      message: t('messages.success'),
      description: t('messages.success.requestCreated'),
    },
    [isSuccess]
  );

  useEffect(() => {
    if (setConfirmLoading) {
      if (isError) {
        setConfirmLoading(false);
      } else if (isSuccess && handleCancelButton) {
        handleCancelButton();
      }
    }
  }, [isError, isSuccess, setConfirmLoading, handleCancelButton]);

  const handleRescheduleFinish = useCallback(() => {
    if (form) {
      setIsLessDuration(false);
      setIsDaysEqual(false);
      const currentFormValues = form.getFieldsValue();
      const newStartDate = currentFormValues[RESCHEDULE_START_DATE];
      const newEndDate = currentFormValues[RESCHEDULE_END_DATE];

      const durationDays = workday_count(newStartDate.startOf('day'), newEndDate.startOf('day'));

      if (duration && durationDays < duration) {
        setIsLessDuration(true);
        return;
      }
      if (newStartDate.isSame(startDate, 'date') && newEndDate.isSame(endDate, 'date')) {
        setIsDaysEqual(true);
        return;
      }
      if (leaveId && setConfirmLoading) {
        setConfirmLoading(true);
        const data = {
          leaveRequestId: leaveId,
          startDate: moment(newStartDate).format('DD/MM/YYYY'),
          endDate: moment(newEndDate).format('DD/MM/YYYY'),
          reason: currentFormValues?.rescheduleReason,
          duration: durationDays,
          attachments: currentFormValues?.attachments,
        };
        mutate(data);
      }
    }
  }, [
    form,
    startDate,
    endDate,
    duration,
    RESCHEDULE_START_DATE,
    RESCHEDULE_END_DATE,
    leaveId,
    mutate,
    setConfirmLoading,
  ]);

  useErrorNotification(
    {
      isNotification: isLessDuration,
      message: t('messages.error.wentWrong'),
      description: t('leaves.reschedule.validation.duration'),
    },
    [isLessDuration]
  );

  useErrorNotification(
    {
      isNotification: isDaysEqual,
      message: t('messages.error.wentWrong'),
      description: t('leaves.reschedule.validation.daysEqual'),
    },
    [isDaysEqual]
  );

  return (
    <StyledModalPopOver
      destroyOnClose
      forceRender
      visible={visible}
      onOk={handleModalOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancelButton}
      closable={false}
      width="500px"
      centered={true}
      cancelText={t('close')}
      okText={
        isScheduledAnnualLeave ? t('leaves.drawer.reschedule') : t('leaves.cancel.cancelRequest')
      }
    >
      {form ? (
        isScheduledAnnualLeave ? (
          <UpdateLeaveDate
            t={t}
            form={form}
            handleRescheduleFinish={handleRescheduleFinish}
            startDate={startDate}
            duration={duration}
          />
        ) : onFinish ? (
          <CancelScheduledLeave form={form} t={t} onFinish={onFinish} />
        ) : null
      ) : null}
    </StyledModalPopOver>
  );
};

export const ModalPopover = memo(ModalPopoverComponent);

import React from 'react';
import { EndEventWrapper } from './EndEvent.styled';

export interface EndEventProps {
  endEvent?: string;
  isVisible?: boolean;
}

export const EndEvent: React.FC<EndEventProps> = ({ endEvent, isVisible }) => {
  return (
    <React.Fragment>
      {isVisible && endEvent && <EndEventWrapper>{endEvent}</EndEventWrapper>}
    </React.Fragment>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProcessInstanceInfoData, RequestCommonError, RequestStatus } from 'types';
import { AxiosRequestConfig } from 'axios';
import { ProcessInstanceState } from './processInstanceInfoTypes';

export const emptyProcessInstanceState: ProcessInstanceState = {
  status: RequestStatus.idle,
  error: null,
  data: [],
};

export type GetProcessInstanceStart = PayloadAction<AxiosRequestConfig>;
export type GetProcessInstanceSuccess = PayloadAction<ProcessInstanceInfoData[]>;
export type GetProcessInstanceFail = PayloadAction<RequestCommonError>;

const processInstanceInfo = createSlice({
  name: 'processInstanceInfo',
  initialState: emptyProcessInstanceState,
  reducers: {
    getProcessInstanceStart(state, _: GetProcessInstanceStart) {
      state.status = RequestStatus.pending;
    },
    getProcessInstanceSuccess(state, action: GetProcessInstanceSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getProcessInstanceFail(state, action: GetProcessInstanceFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getProcessInstanceStart,
  getProcessInstanceSuccess,
  getProcessInstanceFail,
} = processInstanceInfo.actions;
export default processInstanceInfo.reducer;

import { useQuery } from 'react-query';
import { leaseSites } from '../LeaseSites';
import { QUERIES, PERSIST_QUERY_CONFIG } from '../../constants';

export const useLeaseSitesFilterLookups = () =>
  useQuery(
    [QUERIES.getLeaseSitesFilterLookups],
    async () => {
      const { data } = await leaseSites.getSitesListFilterLookups();
      return data;
    },
    {
      ...PERSIST_QUERY_CONFIG,
    }
  );

import React, { FC, memo } from 'react';
import { TType } from 'translations';
import { FormInstance } from 'antd/lib/form';
import { normFile } from 'routes/Calendar';
import { CreateFormItemType } from 'types';
import { GeneratedStaticForm } from 'routes/Tasks/components';
import { StyledForm } from '../component.style';
import {
  ExclamationCircleOutlinedStyled,
  SubTitleStyled,
  TitleStyled,
} from '../../ActionButtons/ActionButtons.styled';
import { leavesDrawerConstants } from '../../constants';

interface Props {
  t: TType;
  form: FormInstance;
  onFinish: () => void;
}

const CancelScheduledLeaveComponent: FC<Props> = ({ t, form, onFinish }) => {
  const {
    CANCEL_REQUEST_FORM_NAME,
    CANCEL_REASON_NAME,
    ATTACHMENTS_NAME,
    MAX_TEXTAREA_LENGTH,
  } = leavesDrawerConstants;

  const getItems = () => {
    const res: CreateFormItemType[] = [];
    res.push(
      {
        id: `${0}`,
        label: <SubTitleStyled type="secondary">{t('leaves.cancel.reason')}</SubTitleStyled>,
        name: CANCEL_REASON_NAME,
        type: 'textArea',
        maxNumber: MAX_TEXTAREA_LENGTH,
        placeholder: t('leaves.cancel.commentPlaceholder'),
        rules: [
          {
            required: true,
            message: t('leaves.cancel.error.reasonField'),
          },
        ],
      },
      {
        id: `${1}`,
        name: ATTACHMENTS_NAME,
        type: 'file',
        getValueFromEvent: normFile,
        valuePropName: 'fileList',
        attachmentSecondary: true,
      }
    );
    return res;
  };

  const items = getItems();

  return (
    <>
      <ExclamationCircleOutlinedStyled />
      <TitleStyled type="secondary">{t('leaves.cancel.areYouSure')}</TitleStyled>
      <StyledForm name={CANCEL_REQUEST_FORM_NAME} form={form} onFinish={onFinish}>
        <GeneratedStaticForm t={t} items={items} formState={form} />
      </StyledForm>
    </>
  );
};

export const CancelScheduledLeave = memo(CancelScheduledLeaveComponent);

import { Col, Row } from 'antd';
import React from 'react';
import { colors, typography } from 'ui/elements';
import { Gutter } from 'antd/lib/grid/row';
import styled from 'styled-components';
import DateText from '../Atoms/DateText';

interface ExpenseCreateInfoProps {
  date: string;
  prettyId: string;
  color?: string;
  onPrettyIdClickHandler: (processInstanceId: string) => void;
  processInstanceId: string;
}

const StyledPrettyId = styled.a`
  color: ${colors.lightSecondaryGray};
  transition: color text-decoration 0.2s ease-in;

  &:hover,
  &:focus {
    color: ${colors.primaryColor};
    text-decoration: underline;
  }
`;

const GUTTER_14: [Gutter, Gutter] = [14, 0];

const ExpenseCreateInfoComponent: React.FC<ExpenseCreateInfoProps> = ({
  date,
  prettyId,
  color = colors.lightSecondaryGray,
  onPrettyIdClickHandler,
  processInstanceId,
}) => {
  return (
    <React.Fragment>
      <Row
        justify={'start'}
        gutter={GUTTER_14}
        align={'middle'}
        style={{ color: color, fontSize: typography.size.kilo }}
      >
        <Col>
          <DateText date={date} type={'secondary'} />
        </Col>
        <Col>|</Col>
        <Col>
          <StyledPrettyId
            onClick={(e) => {
              e.stopPropagation();
              onPrettyIdClickHandler(processInstanceId);
            }}
          >
            {prettyId}
          </StyledPrettyId>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export const ExpenseCreateInfo = React.memo(ExpenseCreateInfoComponent);

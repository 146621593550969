import { from, of } from 'rxjs';
import { mergeMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  getTeamsChannelMessagesStart,
  getTeamsChannelMessagesSuccess,
  getTeamsChannelMessagesFail,
  GetTeamsChannelMessagesStart,
  GetTeamsChannelMessagesSuccess,
  GetTeamsChannelMessagesFail,
  sendTeamsChannelMessageStart,
  sendTeamsChannelMessageSuccess,
  sendTeamsChannelMessageFail,
  SendTeamsChannelMessageStart,
  SendTeamsChannelMessageSuccess,
  SendTeamsChannelMessageFail,
  getTeamsChannelRepliesStart,
  getTeamsChannelRepliesSuccess,
  getTeamsChannelRepliesFail,
  GetTeamsChannelRepliesStart,
  GetTeamsChannelRepliesSuccess,
  GetTeamsChannelRepliesFail,
  updateTeamsChannelRepliesStart,
  updateTeamsChannelRepliesSuccess,
  updateTeamsChannelRepliesFail,
  sendTeamsChannelReplyStart,
  sendTeamsChannelReplySuccess,
  sendTeamsChannelReplyFail,
  SendTeamsChannelReplyStart,
  SendTeamsChannelReplySuccess,
  SendTeamsChannelReplyFail,
  updateTeamsChannelMessagesStart,
  updateTeamsChannelMessagesSuccess,
  updateTeamsChannelMessagesFail,
} from './teamsPostsSlice';

export const getTeamsChannelMessagesEpic: Epic<
  GetTeamsChannelMessagesStart,
  GetTeamsChannelMessagesSuccess | GetTeamsChannelMessagesFail,
  RootState,
  typeof API
> = (action$, _, { teamsPosts }) =>
  action$.pipe(
    filter(getTeamsChannelMessagesStart.match),
    mergeMap(({ payload }) =>
      from(
        teamsPosts.getTeamsChannelMessages({ teamId: payload.teamId, channelId: payload.channelId })
      ).pipe(
        map(({ data }) => getTeamsChannelMessagesSuccess(data)),
        catchError((error) => of(getTeamsChannelMessagesFail(error?.response?.data)))
      )
    )
  );

export const sendTeamsChannelMessageEpic: Epic<
  SendTeamsChannelMessageStart,
  SendTeamsChannelMessageSuccess | SendTeamsChannelMessageFail,
  RootState,
  typeof API
> = (action$, _, { teamsPosts }) =>
  action$.pipe(
    filter(sendTeamsChannelMessageStart.match),
    mergeMap(({ payload }) =>
      from(
        teamsPosts.sendTeamsChannelMessage(payload.teamId, payload.channelId, payload.values)
      ).pipe(
        map(({ data }) => sendTeamsChannelMessageSuccess(data)),
        catchError((error) => of(sendTeamsChannelMessageFail(error?.response?.data)))
      )
    )
  );

export const getTeamsChannelRepliesEpic: Epic<
  GetTeamsChannelRepliesStart,
  GetTeamsChannelRepliesSuccess | GetTeamsChannelRepliesFail,
  RootState,
  typeof API
> = (action$, _, { teamsPosts }) =>
  action$.pipe(
    filter(getTeamsChannelRepliesStart.match),
    mergeMap(({ payload }) =>
      from(
        teamsPosts.getTeamsChannelReplies({
          teamId: payload.teamId,
          channelId: payload.channelId,
          messageId: payload.messageId,
        })
      ).pipe(
        map(({ data }) => getTeamsChannelRepliesSuccess({ messageId: payload.messageId, data })),
        catchError((error) =>
          of(
            getTeamsChannelRepliesFail({
              messageId: payload.messageId,
              data: error?.response?.data,
            })
          )
        )
      )
    )
  );

export const updateChatRepliesEpic: Epic<
  GetTeamsChannelRepliesStart,
  GetTeamsChannelRepliesSuccess | GetTeamsChannelRepliesFail,
  RootState,
  typeof API
> = (action$, store, { teamsPosts }) =>
  action$.pipe(
    filter(updateTeamsChannelRepliesStart.match),
    mergeMap(({ payload }) =>
      from(teamsPosts.getTeamsChannelReplies(payload)).pipe(
        map(({ data }) => updateTeamsChannelRepliesSuccess({ messageId: payload.messageId, data })),
        catchError((error) =>
          of(
            updateTeamsChannelRepliesFail({
              messageId: payload.messageId,
              data: error?.response?.data,
            })
          )
        )
      )
    )
  );

export const sendTeamsChannelReplyEpic: Epic<
  SendTeamsChannelReplyStart,
  SendTeamsChannelReplySuccess | SendTeamsChannelReplyFail,
  RootState,
  typeof API
> = (action$, _, { teamsPosts }) =>
  action$.pipe(
    filter(sendTeamsChannelReplyStart.match),
    mergeMap(({ payload }) =>
      from(
        teamsPosts.sendTeamsChannelReply(
          payload.teamId,
          payload.channelId,
          payload.messageId,
          payload.values
        )
      ).pipe(
        map(({ data }) => sendTeamsChannelReplySuccess({ messageId: payload.messageId, data })),
        catchError((error) =>
          of(
            sendTeamsChannelReplyFail({ messageId: payload.messageId, data: error?.response?.data })
          )
        )
      )
    )
  );

export const updateChatMessagesEpic: Epic<
  GetTeamsChannelMessagesStart,
  GetTeamsChannelMessagesSuccess | GetTeamsChannelMessagesFail,
  RootState,
  typeof API
> = (action$, store, { teamsPosts }) =>
  action$.pipe(
    filter(updateTeamsChannelMessagesStart.match),
    mergeMap(({ payload }) =>
      from(
        teamsPosts.getTeamsChannelMessages(
          payload,
          store.value.teamsPosts.data.channelMessages?.skipToken !== null
            ? store.value.teamsPosts.data.channelMessages?.skipToken
            : undefined
        )
      ).pipe(
        map(({ data }) => updateTeamsChannelMessagesSuccess(data)),
        catchError((error) => of(updateTeamsChannelMessagesFail(error?.response?.data)))
      )
    )
  );

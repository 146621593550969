import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserGruopData, RequestCommonError, RequestStatus } from 'types';
import { AxiosRequestConfig } from 'axios';
import { UserGroupState } from './userGroupTypes';

export const emptyUserGroupState: UserGroupState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetUserGroupStart = PayloadAction<AxiosRequestConfig>;
export type GetUserGroupSuccess = PayloadAction<UserGruopData>;
export type GetUserGroupFail = PayloadAction<RequestCommonError>;

const userGroup = createSlice({
  name: 'userGroup',
  initialState: emptyUserGroupState,
  reducers: {
    getUserGroupStart(state, _: GetUserGroupStart) {
      state.status = RequestStatus.pending;
    },
    getUserGroupSuccess(state, action: GetUserGroupSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getUserGroupFail(state, action: GetUserGroupFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const { getUserGroupFail, getUserGroupStart, getUserGroupSuccess } = userGroup.actions;
export default userGroup.reducer;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';

export const groupMemberByLastName = (state: RootState): RootState['groupMembersByLAstName'] =>
  state.groupMembersByLAstName;

export const GroupMembersByLastNameData = createSelector(
  groupMemberByLastName,
  ({ error, status, data }) => ({
    error,
    status,
    data,
  })
);

export const downloadFile = (fileContentType: string, fileContent: string, fileName: string) => {
  const file = `data: ${fileContentType};base64,${fileContent}`;
  // create hidden link
  const element = document.createElement('a');
  document.body.appendChild(element);
  element.setAttribute('href', file);
  element.setAttribute('download', fileName);
  element.style.display = '';
  element.click();
  document.body.removeChild(element);
};

import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { TType } from 'translations';
import { RootState, profileData } from 'store';

const mapState = (state: RootState) => ({
  profileData: profileData(state)?.data,
});
const mapDispatch = {};

export const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface OwnProps {
  t: TType;
}

type ProcessDefinitionKey =
  | 'Human_Resource.Employee_Relations.Employee_Grievance_and_Complaint_Service'
  | 'Human_Resource.Employee_Relations.Iqama_Renewal'
  | 'Human_Resource.Facilities_and_administration.Administration_General_Request'
  | 'Human_Resource.Facilities_and_administration.Business_Card'
  | 'Human_Resource.Facilities_and_administration.Corrective_Maintenance'
  | 'Human_Resource.HR_services.Medical_Insurance'
  | 'Human_Resource.HR_services.Self-Service_Letters'
  | 'Human_Resource.HR_services.Sharikate_Program'
  | 'Human_Resource.HR_services.Expense_Request'
  | 'Human_Resource.HR_services.Allowance_Request'
  | 'Human_Resource.HR_services.Business_and_Training_Travel'
  | 'Human_Resource.Total_Rewards.Home_Loan_Request'
  | 'Technology_and_Innovation_Demand.Trouble_Ticket_-_IT_Helpdesk_Support.Printing_and_Scanning'
  | 'Technology_and_Innovation_Demand.Infrastructure_Services.Technical_Issue'
  | 'Finance.Expenses.Credit_Card_Request'
  | 'Finance.Expenses.Expense_Reimbursement'
  | 'Finance.Expenses.Cash_Advance_Request'
  | 'Finance.Zakat_and_Vat.Sale_of_Asset_Invoice_Request'
  | string;

export type Props = PropsFromRedux &
  RouteComponentProps<{
    processDefinitionKey: ProcessDefinitionKey;
    isDynamicForm: string;
    jiraServiceDeskId?: string;
    jiraRequestTypeId?: string;
  }> &
  OwnProps;

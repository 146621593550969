import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input, notification, Space, Spin } from 'antd';
import { useTranslate } from 'translations';
import {
  useDispatch,
  getUsersStart,
  usersData,
  useSelector,
  createOneToOneChatStart,
  profileData,
} from 'store';
import { RequestStatus } from 'types';
import { TextStyled } from 'ui';
import { LoadingOutlined } from '@ant-design/icons';
import { MSTeamsUserAvatar } from '../MSTeamsUserAvatar';
import { useMSTeamsContext } from '../MSTeamsProvider';
import { StyledAutoComplete, StyledSearchIcon } from './MSTeamsSearch.styled';

type Options = Option[];

interface Option {
  value: string;
  id: string | null;
}

export const MSTeamsSearch = () => {
  const { t } = useTranslate();
  const { data, status } = useSelector(usersData);
  const { data: profile } = useSelector(profileData);
  const [value, setValue] = useState('');
  const [options, setOptions] = useState<Options>([]);
  const dispatch = useDispatch();
  const { setCurrentSearchUserId } = useMSTeamsContext();

  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  const searchUsers = useCallback(
    (searchText: string) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        dispatch(
          getUsersStart({
            params: {
              search: searchText,
            },
          })
        );
      }, 350);
    },
    [dispatch]
  );

  const onSearch = useCallback(
    (searchText: string) => {
      if (searchText) {
        searchUsers(searchText);
      }
    },
    [searchUsers]
  );

  const onSelect = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: string, option: any) => {
      setCurrentSearchUserId(option.id);

      dispatch(
        createOneToOneChatStart({
          params: {
            userIds: [option.id, profile?.adOid].join(','),
          },
          chatMembers: [
            {
              displayName: option.value,
              email: option.mail,
              userId: option.id,
            },
            {
              displayName: `${profile?.firstName} ${profile?.lastName}`,
              email: profile?.email,
              userId: profile?.adOid as string,
            },
          ],
        })
      );

      setValue('');
      setOptions([]);
    },
    [
      dispatch,
      profile?.adOid,
      profile?.email,
      profile?.firstName,
      profile?.lastName,
      setCurrentSearchUserId,
    ]
  );

  const onChange = useCallback(
    (data: string) => {
      setValue(data);

      if (!data) {
        setOptions([]);
        setCurrentSearchUserId('');
      }
    },
    [setCurrentSearchUserId]
  );

  const onFocus = useCallback(() => {
    setValue('');
    setOptions([]);
  }, []);

  useEffect(() => {
    if (data?.users) {
      const options = data?.users.map((user) => ({
        label: (
          <Space size="small">
            <MSTeamsUserAvatar id={user.id} name={user.displayName} />
            <Space direction="horizontal">
              <TextStyled>{user.displayName ?? ''}</TextStyled>
              <TextStyled>{user.mail ?? ''}</TextStyled>
            </Space>
          </Space>
        ),
        value: user.displayName ?? '',
        id: user.id,
        email: user.mail,
      }));

      setOptions(options);
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [data]);

  useEffect(() => {
    if (status === RequestStatus.rejected) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [status, t]);

  useEffect(() => {
    if (!value) {
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledAutoComplete
      options={options}
      onSelect={onSelect}
      onSearch={onSearch}
      onChange={onChange}
      value={value}
      notFoundContent={t('msteams.notFoundContent')}
      allowClear
    >
      <Input
        onFocus={onFocus}
        prefix={
          status === RequestStatus.pending && value.length ? (
            <Spin size="small" indicator={<LoadingOutlined spin />} />
          ) : (
            <StyledSearchIcon />
          )
        }
        size="middle"
        placeholder={t('msteams.search')}
      />
    </StyledAutoComplete>
  );
};

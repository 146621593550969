import { useQuery } from 'react-query';
import { QUERIES } from '../../constants';
import { changeBankAccountRequest } from '../ChangeBankAccountRequest';

export const useChangeBankAccountRequestLookUp = () =>
  useQuery(
    [QUERIES.changeBankAccountRequestLookUp],
    async () => {
      const { data } = await changeBankAccountRequest.getLookups();
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

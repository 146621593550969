import React from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import { MSTeamsUserAvatar } from '../../../routes/MSTeams/MSTeamsUserAvatar';
import {
  GUTTER_16,
  StyleDatePicker,
  StyledLabel,
  StyledLabelAssignedDelegateStartAndEndDateContainer,
  StyledNewRequestDelegateEmail,
  StyledNewRequestDelegateInfoContainer,
  StyledNewRequestDelegateItemContainer,
  StyledNewRequestDelegateUserName,
} from '../../../routes';
import { useTranslate } from '../../../translations';
import { LeavesDelegateType } from '../../../types';

export interface LeaveAssignedDelegatesItemProps {
  delegation: LeavesDelegateType;
}
export const LeaveAssignedDelegatesItem: React.FC<LeaveAssignedDelegatesItemProps> = ({
  delegation,
}: LeaveAssignedDelegatesItemProps) => {
  const { t } = useTranslate();
  const delegationDate = (isStartDate: boolean): JSX.Element => {
    const startDate = moment(delegation.delegationStartDate);
    const endDate = moment(delegation.delegationEndDate);
    return (
      <Col span={12}>
        <StyledLabelAssignedDelegateStartAndEndDateContainer>
          <StyledLabel>
            {t(
              isStartDate
                ? `leaves.newRequest.delegationStartDate`
                : `leaves.newRequest.delegationEndDate`
            )}
          </StyledLabel>
        </StyledLabelAssignedDelegateStartAndEndDateContainer>
        <StyleDatePicker
          disabled
          defaultValue={isStartDate ? startDate : endDate}
          value={isStartDate ? startDate : endDate}
          format="DD/MM/YYYY"
        />
      </Col>
    );
  };

  return (
    <StyledNewRequestDelegateItemContainer span={24}>
      <Row>
        <MSTeamsUserAvatar id={delegation?.adOid} name={delegation?.name} size={32} />
        <StyledNewRequestDelegateInfoContainer>
          <StyledNewRequestDelegateUserName>{delegation?.name}</StyledNewRequestDelegateUserName>
          <StyledNewRequestDelegateEmail>{delegation?.email}</StyledNewRequestDelegateEmail>
        </StyledNewRequestDelegateInfoContainer>
      </Row>
      <Col span={24}>
        <Row justify={'space-between'} gutter={GUTTER_16}>
          {delegationDate(true)}
          {delegationDate(false)}
        </Row>
      </Col>
    </StyledNewRequestDelegateItemContainer>
  );
};

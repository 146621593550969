import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { borderRadius, colors, space, typography } from 'ui/elements';

const { Text } = Typography;

export const TemplateTitleStyled = styled(Text)`
  font-size: ${typography.size.giga};
  color: ${colors.primaryColor};
`;

export const TemplateMessageStyled = styled(Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.grey};
`;

export const TemplateCommentWrapper = styled.div`
  padding: ${space.cozy};
  width: inherent;
  background-color: ${colors.lightShardGray};
  border-radius: ${borderRadius.small};
`;

export const CommenterNameStyled = styled(Text)`
  font-size: ${typography.size.hecto};
  font-weight: ${typography.weight.regular};
  color: ${colors.primaryColor};
`;

export const SeeAllUpdatesStyled = styled.div`
  font-size: ${typography.size.hecto};
  color: ${colors.primaryColor};
  text-decoration: underline;
  cursor: pointer;
`;

export const DateOfNotificationStyled = styled(Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.textColorSecondary};
  font-weight: ${typography.weight.bold};
`;

export const RequestIdStyled = styled.div`
  font-size: ${typography.size.hecto};
  color: ${colors.grey};
  text-align: center;

  :hover {
    text-decoration: underline;
  }
`;

export const WrapperNotification = styled(Row)`
  transition: background-color 0.3s;
  transition: box-shadow 0.3s;

  padding: ${space.little};
  border-radius: ${borderRadius.xLarge};

  cursor: ${(props: { $isCanRedirection?: boolean }) =>
    props.$isCanRedirection ? 'pointer' : 'normal'};

  :hover {
    background-color: ${colors.hoverLightGray};
    box-shadow: 0px 0px 9px 0px ${colors.lightActive};
  }
`;

export const WrapperIcon = styled(Col)`
  text-align: center;
`;

export const WrapperBadge = styled(Col)`
  left: 2.3%;
  top: 4.5%;
  text-align: center;
  padding: ${space.zero} !important;
`;

export const WrapperSeeAllNotificationDrawer = styled.div``;

import moment from 'moment';

export const getDuration = (durationTime: string) => {
  if (durationTime) {
    const [hour, minutes] = durationTime.split(':');
    return `${+hour}h ${+minutes}min`;
  }
};

export const getDate = ({ ...date }: moment.Moment, days?: number) => {
  if (days) {
    return moment(date).subtract(days, 'days');
  } else {
    return moment(date);
  }
};

export const DEFAULT_TOTAL_ITEMS = 30;

export const DATE_FORMAT = 'MM-DD-yyyy';
export const MONTH_YEAR_FORMAT = 'MMMM YYYY';

export const ATTENDANCE_CARD_BREAKPOINT = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 24 },
  xxl: { span: 12 },
  xl: { span: 12 },
};

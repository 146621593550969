import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetOneDriveSharedLibrariesFail,
  GetOneDriveSharedLibrariesStart,
  GetOneDriveSharedLibrariesSuccess,
  getOneDriveSharedLibrariesSuccess,
  getOneDriveSharedLibrariesFail,
  getOneDriveSharedLibrariesStart,
} from './oneDriveSharedLibrariesSlice';

export const getOneDriveSharedLibrariesEpic: Epic<
  GetOneDriveSharedLibrariesStart,
  GetOneDriveSharedLibrariesSuccess | GetOneDriveSharedLibrariesFail,
  RootState,
  typeof API
> = (action$, _, { oneDriveSharedLibraries }) =>
  action$.pipe(
    filter(getOneDriveSharedLibrariesStart.match),
    mergeMap(({ payload }) =>
      from(oneDriveSharedLibraries.getOneDriveSharedLibraries(payload)).pipe(
        map(({ data }) =>
          getOneDriveSharedLibrariesSuccess({
            groupId: payload,
            data,
          })
        ),
        catchError((error) =>
          of(
            getOneDriveSharedLibrariesFail({
              groupId: payload,
              error: error?.response?.message,
            })
          )
        )
      )
    )
  );

import React, { FC, useMemo } from 'react';
import { CalendarIconRequestCard, CommentIcon } from 'assets';
import { Col } from 'antd';
import {
  StyledCommentIcon,
  StyledCommentIconContainer,
  StyledProfileFieldLabel,
  StyledProfileFieldLabelForDate,
  StyledProfileFieldValue,
  StyledRequestCardRequestCommentsRow,
  StyledRequestCardRequestIdRow,
  StyledRequestCardRequestNameRow,
  StyledRequestNameField,
} from 'routes/NewProfile/NewProfile.styled';
import moment from 'moment/moment';
import { IOngoingRequestInfo } from 'types';
import { TeamsUserAvatar, UserPopover } from 'ui';
import {
  StyledEmergencyContactContainer,
  StyledEmergencyContactLinkContainer,
} from './ProfilePersonalDetails.styled';

export interface IOnGoingRequestSectionProps {
  requestData: IOngoingRequestInfo;
}

export const OnGoingRequestSection: FC<IOnGoingRequestSectionProps> = ({
  requestData,
}: IOnGoingRequestSectionProps) => {
  return useMemo(() => {
    return (
      <StyledEmergencyContactLinkContainer to={`/tasks/${requestData?.processInstanceId}`}>
        <StyledEmergencyContactContainer>
          <StyledRequestCardRequestIdRow>
            <Col flex={'100'}>
              <StyledProfileFieldLabel>
                <p>{requestData?.requestId}</p>
              </StyledProfileFieldLabel>
            </Col>
            <Col flex={'auto'} offset={8}>
              <StyledCommentIconContainer>
                <StyledProfileFieldLabelForDate>
                  {moment(requestData?.requestCreationDate).format('DD.MM.YYYY')}
                </StyledProfileFieldLabelForDate>
                <CalendarIconRequestCard />
              </StyledCommentIconContainer>
            </Col>
          </StyledRequestCardRequestIdRow>
          <StyledRequestCardRequestNameRow>
            <Col>
              <StyledProfileFieldValue>{requestData?.requestName}</StyledProfileFieldValue>
            </Col>
          </StyledRequestCardRequestNameRow>
          <StyledRequestCardRequestNameRow>
            <Col>
              <StyledRequestNameField>{requestData?.task?.taskName}</StyledRequestNameField>
            </Col>
          </StyledRequestCardRequestNameRow>
          <StyledRequestCardRequestCommentsRow>
            <Col flex={'100'}>
              <StyledCommentIconContainer>
                <CommentIcon />
                <StyledCommentIcon>{requestData?.commentCount}</StyledCommentIcon>
              </StyledCommentIconContainer>
            </Col>
            <Col flex={'auto'} offset={8}>
              {requestData?.task?.assigneeId && (
                <UserPopover currentID={requestData?.task?.assigneeId}>
                  <TeamsUserAvatar
                    name={requestData?.task?.taskAssignee}
                    email={requestData?.task?.assigneeEmail}
                    id={requestData?.task?.assigneeId}
                  />
                </UserPopover>
              )}
            </Col>
          </StyledRequestCardRequestCommentsRow>
        </StyledEmergencyContactContainer>
      </StyledEmergencyContactLinkContainer>
    );
  }, [requestData]);
};

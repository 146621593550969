import { GeneralInfoData } from 'types';

export interface MappedGeneralInfoData {
  employeeId: string;
  hireDate: string;
  yearsWorkedInTheCompany: string;
  yearsSinceLastSalaryChange: string;
  yearsSinceLastPromotion: string;
  workerType: string;
  job: string;
  assignmentStatus: string;
  businessUnit: string;
  department: string;
  location: string;
  dateOfBirth: string;
  gender: string;
  employmentHistoryRecords: string;
  assignmentCategory: string;
  assignmentName: string;
  lastPromotionDate: string;
}

export const mapData = (data: GeneralInfoData): MappedGeneralInfoData => {
  const assignments = data.assignments[0];

  const newData: MappedGeneralInfoData = {
    ...data,
    assignmentStatus: assignments.assignmentStatus,
    assignmentCategory: assignments.assignmentCategory,
    assignmentName: assignments.assignmentName,
  };

  return newData;
};

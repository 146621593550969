import { connect, ConnectedProps } from 'react-redux';

import { RootState, getCurrentTeamsChannel } from 'store';
import { MSTeamsTeamsTabsProps } from './MSTeamsTeamsTabs';

const mapDispatch = {};

const mapState = (state: RootState, props: MSTeamsTeamsTabsProps) => ({
  currentChannelData: getCurrentTeamsChannel(state, props),
});

export const msTeamsTeamsTabsConnector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof msTeamsTeamsTabsConnector>;

export type ConnectorProps = PropsFromRedux;

import React, { FC } from 'react';

import { AppLayout } from 'layouts';
import { Header } from 'app';
import { TType, withTranslate } from 'translations';
import { TitleH1 } from 'ui/elements';
import { Col, Row } from 'antd';
import { EmployeeDirectoryUser } from 'types';
import {
  StyledEmployeeDirectoryPage,
  StyledEmployeeDirectorySidebar,
  StyledEmployeeDirectoryWrapper,
  StyledRow,
} from './EmployeeDirectory.styled';
import { EmployeeDirectorySidebar } from './EmployeeDirectorySidebar/EmployeeDirectorySidebar';
import { EmployeeDirectoryDetails } from './EmployeeDirectoryDetails/EmployeeDirectoryDetails';

interface Props {
  t: TType;
}

export const EmployeeDirectory: FC<Props> = withTranslate(({ t }) => {
  const [selectedEmployee, setSelectedEmployee] = React.useState<
    EmployeeDirectoryUser | undefined
  >();
  return (
    <AppLayout displayFooter={false}>
      <Header>
        <Row align="middle">
          <Col>
            <TitleH1>{t('employeeDirectory.employeeDirectory')}</TitleH1>
          </Col>
        </Row>
      </Header>

      <StyledEmployeeDirectoryWrapper className="layout-main">
        <StyledRow>
          <Col span={7}>
            <StyledEmployeeDirectorySidebar>
              <EmployeeDirectorySidebar onEmployeeSelect={setSelectedEmployee} />
            </StyledEmployeeDirectorySidebar>
          </Col>
          <Col span={17}>
            <StyledEmployeeDirectoryPage>
              <EmployeeDirectoryDetails email={selectedEmployee?.email} />
            </StyledEmployeeDirectoryPage>
          </Col>
        </StyledRow>
      </StyledEmployeeDirectoryWrapper>
    </AppLayout>
  );
});

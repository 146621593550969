import styled from 'styled-components';
import { FolderOpenOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { colors, space, typography } from 'ui/elements';
import { Row, Typography } from 'antd';

const { Text } = Typography;

export const StyledNumber = styled(Text)`
  font-size: ${typography.size.kilo};
  font-weight: ${typography.weight.semiBold};
  color: ${colors.primaryColor};
`;

export const StyledFolderIcon = styled(FolderOpenOutlined)`
  margin-right: ${space.slim};

  font-size: ${typography.size.kilo};
`;

export const StyledClockIcon = styled(ClockCircleOutlined)`
  margin-right: ${space.slim};

  font-size: ${typography.size.kilo};
`;
export const StyledDays = styled(Text)`
  font-size: ${typography.size.kilo};
  font-weight: ${typography.weight.regular};
  color: ${colors.primaryColor};
`;

export const StyledSpaceRow = styled(Row)`
  margin-top: ${space.normal};
`;

export const StyledSummaryRow = styled(Row)`
  text-align: center;
`;

import styled from 'styled-components';
import { colors } from 'ui/elements';
import { Button, Form, Select } from 'antd';

export const StyledCustomFormGroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
`;

export const StyledCustomFormGroupInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${colors.backgroundGrey};
  padding: 24px 0 0 24px;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: unset;
  }
`;

export const StyledCustomFormGroupInnerHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCustomFormGroupRemoveButton = styled(Button)`
  color: ${colors.lightGrey};
`;

export const StyledPhoneCodeFormItem = styled(Form.Item)`
  margin-bottom: 0 !important;

  .ant-form-item-control-input {
    min-height: 30px !important;
  }
`;

export const StyledPhoneCodeSelect = styled(Select)`
  width: 75px !important;
`;

export const StyledScrollBoxContainer = styled.div`
  width: 100%;
  margin: 10px 0;
  padding: 10px 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  border: 1px solid #dae0e7;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.textColorSecondary};

  scrollbar-width: thin;

  ul {
    padding: 5px 15px;

    li {
      margin-top: 5px;
    }
  }
`;

import React, { FC, useEffect, memo } from 'react';
import { Spin } from 'antd';
import { useCategoriesTree } from 'api';
import { useTasksContext } from '../../../TasksProvider';
import { SideBar } from './RequestCatalogTree.styled';
import { RequestTreeCollapse } from './RequestsCatalogTreeCollapse';

const SPIN_STYLES = { paddingTop: '10px' };

interface Props {
  onCategoryClick: (name: string | undefined) => void;
  onSubCategoryClick: (name: string | undefined) => void;
}

export const RequestCatalogTreeComponent: FC<Props> = ({ onCategoryClick, onSubCategoryClick }) => {
  const { isLoading, data, refetch } = useCategoriesTree();

  const { currentActiveTab } = useTasksContext();

  useEffect(() => {
    if (currentActiveTab === '0') {
      onCategoryClick(undefined);
      onSubCategoryClick(undefined);
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveTab]);

  return (
    <SideBar>
      {isLoading || !data ? (
        <Spin size={'small'} style={SPIN_STYLES} />
      ) : (
        <RequestTreeCollapse
          categoriesTree={data}
          onCategoryClick={onCategoryClick}
          onSubCategoryClick={onSubCategoryClick}
          key={currentActiveTab}
        />
      )}
    </SideBar>
  );
};

export const RequestCatalogTree = memo(RequestCatalogTreeComponent);

import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from 'api';
import { AxiosRequestConfig } from 'axios';
import { leaves } from '../Leaves';

export function useMyTeamLeavesList(hcmPersonId: string, config?: AxiosRequestConfig) {
  return useQuery(
    [QUERIES.myTeamLeaveList, hcmPersonId, config?.params],
    async () => {
      const { data } = await leaves.getMyTeamLeaveList(hcmPersonId, config);
      return data;
    },
    {
      enabled: false,
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

import { Col, Row } from 'antd';
import React, { FC, memo } from 'react';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { useTranslate } from 'translations';
import { ManagerOfGroupInfo } from 'types';
import { UserPopover } from 'ui';
import { StyledDivider } from 'ui/components/TaskList/TaskSteps/TaskInfo/TaskInfo.styled';
import { StyledMangerLabel, StyledMangerText } from './ProcessGroupDrawer.styled';

interface Props {
  mangerList: ManagerOfGroupInfo[];
}

export const MangerListComponent: FC<Props> = ({ mangerList }) => {
  const { t } = useTranslate();
  return (
    <div>
      {mangerList.length > 0 && (
        <>
          <Row gutter={[12, 10]}>
            <Col span={24}>
              <StyledMangerLabel>{t('processManagement.groups.drawer.manger')}</StyledMangerLabel>
            </Col>
            {mangerList.map((member) => (
              <Col span={24} key={member.adoid}>
                <Row align={'middle'} gutter={[12, 10]}>
                  <Col>
                    <UserPopover currentID={member.adoid}>
                      <MSTeamsUserAvatar id={member.adoid} size={40} />
                    </UserPopover>
                  </Col>
                  <Col>
                    <StyledMangerText>{member.name}</StyledMangerText>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
          <StyledDivider />
        </>
      )}
    </div>
  );
};

export const MangerList = memo(MangerListComponent);

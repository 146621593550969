import { useMutation } from 'react-query';
import { processManagement } from '../ProcessManagement';

export function useAddProcessToUserProcesses() {
  return useMutation<unknown, unknown, { selectedUserAdOid: string; processKey: number }, unknown>(
    async (body) => {
      await processManagement.addProcessToUserProcesses(body);
    }
  );
}

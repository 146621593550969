import React, { FC } from 'react';
import { isNil } from 'ramda';
import { StyledProfileFieldLabel, StyledProfileFieldValue } from './ProfileSectionContainer.styled';

type Props = {
  label?: string;
  value?: string;
  valueOnly?: boolean;
};

export const ProfileEntryField: FC<Props> = ({ label, value, valueOnly }) => {
  return valueOnly ? (
    <StyledProfileFieldValue ellipsis={{ tooltip: value }}>{value ?? '-'}</StyledProfileFieldValue>
  ) : (
    <>
      <StyledProfileFieldLabel>{label ?? ''}</StyledProfileFieldLabel>
      <StyledProfileFieldValue ellipsis={{ tooltip: value }}>
        {!isNil(value) && value?.trim()?.length > 0 ? value : '-'}
      </StyledProfileFieldValue>
    </>
  );
};

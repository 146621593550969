import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';

import {
  GetUsersTableFail,
  GetUsersTableStart,
  GetUsersTableSuccess,
  getUsersTableFail,
  getUsersTableStart,
  getUsersTableSuccess,
} from './UsersTableSlice';

export const usersTableEpic: Epic<
  GetUsersTableStart,
  GetUsersTableSuccess | GetUsersTableFail,
  RootState,
  typeof API
> = (action$, _, { usersTable }) =>
  action$.pipe(
    filter(getUsersTableStart.match),
    exhaustMap(({ payload }) =>
      from(usersTable.getUsersTable(payload.hcmId, payload.params)).pipe(
        map(({ data }) => getUsersTableSuccess(data)),
        catchError((error) => of(getUsersTableFail(error?.response?.data)))
      )
    )
  );

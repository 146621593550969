import React, { FC, memo, Fragment } from 'react';
import { FormInstance, Rule } from 'antd/lib/form';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { normFile } from 'routes/Calendar';
import { StaticFormTemplate, ListOfItem } from 'routes/Tasks/components';
import { TType } from 'translations';
import { CreateFormItemType, ExpenseReimbursementDetail } from 'types';
import {
  CommonFieldName,
  DEFAULT_TOTAL_LETTER_ITEM,
  ExpenseReportItem,
  ExpenseReportName,
  FROM_ITEM_LIST,
  WRAPPER_COL_SPAN_SIZE_21,
} from 'ui';
import { FORM_ITEM_FILE_RULES, FORM_ITEM_NUMBER_RULE, FORM_ITEM_REQUIRED_RULE } from 'utils';

interface Props {
  t: TType;
  form?: FormInstance;
  fromDetails?: ExpenseReimbursementDetail;
}

const ExpensesReportFieldsComponent: FC<Props> = ({ t, form, fromDetails }) => {
  const REQUIRED_FORM_ITEM_MAX_LENGTH_RULES: Rule[] = [
    {
      max: Number(fromDetails?.maxTotalAmount),
      required: true,
      type: 'number',
    },
  ];
  const getFormFields = () => {
    const res: CreateFormItemType[] = [];
    res.push(
      {
        id: `${0}`,
        label: t(`expenseReport.staticForm.label.cashAdvanceCreditCard`),
        name: ExpenseReportName.cashAdvanceOrCard,
        type: 'select',
        options: fromDetails?.cashAdvanceCreditCard,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        placeholder: t('expenseReport.staticForm.placeholder.item.cashAdvanceCreditCard'),
      },
      {
        id: `${1}`,
        label: t('expenseReport.staticForm.label.item.currency'),
        name: ExpenseReportItem.currency,
        type: 'select',
        options: fromDetails?.currency,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
        placeholder: t('expenseReport.staticForm.placeholder.item.currency'),
      },
      {
        id: `${2}`,
        label: t('expenseReport.staticForm.label.totalAmount'),
        name: ExpenseReportName.totalAmount,
        type: 'inputNumber',
        rules: REQUIRED_FORM_ITEM_MAX_LENGTH_RULES,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        disabled: true,
        placeholder: t('expenseReport.staticForm.placeholder.item.totalAmount'),
        maxNumber: Number(fromDetails?.maxTotalAmount),
      },
      {
        id: `${3}`,
        label: t('expenseReport.staticForm.label.costCenterDepartment'),
        name: ExpenseReportName.departmentCostCenter,
        type: 'input',
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        rules: FORM_ITEM_REQUIRED_RULE,
        disabled: true,
      }
    );
    return res;
  };
  const getItemFields = (field: FormListFieldData) => {
    const res: CreateFormItemType[] = [];
    res.push(
      {
        id: `${0}`,
        label: t('expenseReport.staticForm.label.item.expenseType'),
        name: [field.name, ExpenseReportItem.expenseType],
        type: 'select',
        field,
        options: fromDetails?.expenseTypes,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
        placeholder: t('expenses.staticForm.placeholder.Expense Type'),
      },
      {
        id: `${1}`,
        label: t('expenseReport.staticForm.label.item.amount'),
        name: [field.name, ExpenseReportItem.amount],
        type: 'inputNumber',
        field,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        rules: FORM_ITEM_NUMBER_RULE,
        hasFeedback: true,
        placeholder: t('expenseReport.staticForm.placeholder.item.totalAmount'),
      },

      {
        id: `${2}`,
        label: t('commonField.staticForm.label.description'),
        name: [field.name, CommonFieldName.description],
        type: 'textArea',
        maxNumber: DEFAULT_TOTAL_LETTER_ITEM,
        placeholder: t('tasks.textArea.placeHolder'),
        hasFeedback: true,
        field,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        rules: FORM_ITEM_REQUIRED_RULE,
      },
      {
        id: `${3}`,
        label: t('commonField.staticForm.label.attachment'),
        name: [field.name, CommonFieldName.attachment],
        type: 'file',
        field,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        valuePropName: 'fileList',
        getValueFromEvent: normFile,
        rules: FORM_ITEM_FILE_RULES,
      }
    );
    return res;
  };

  return (
    <Fragment>
      <StaticFormTemplate
        getItems={getFormFields}
        t={t}
        description={fromDetails?.processDescription}
      />
      <ListOfItem
        t={t}
        form={form}
        getItems={getItemFields}
        maxLimit={Number(fromDetails?.maxItemLimit) - 1}
        formListName={FROM_ITEM_LIST}
        itemGroupName={t('expenseReport.groupName.item')}
        btnDefaultTitle={t('addExpenseDetails')}
        btnAddTitle={t('addMore')}
      />
    </Fragment>
  );
};

export const ExpenseReportFields = memo(ExpensesReportFieldsComponent);

import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { UserInfoData } from 'types';

import { MSTeamsConfirm } from 'routes/MSTeams/MSTeamsConfirm';
import {
  MessageOutlined,
  MailOutlined,
  ApartmentOutlined,
  VideoCameraOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import {
  Navigation,
  StyledIcon,
  StyledLink,
  StyledRow,
  StyledButton,
} from './UserPopoverNavigation.styled';
import { GUTTER_32_8 } from './constants';

interface UserPopoverNavigationProps {
  data?: UserInfoData;
}

export const UserPopoverNavigation: FC<UserPopoverNavigationProps> = ({ data }) => {
  const onConfirm = () => {
    window.open('msteams:');
  };
  return (
    <Navigation>
      <Row gutter={GUTTER_32_8} align="middle">
        <Col span={4}>
          <StyledRow>
            <StyledButton type={'link'}>
              <MSTeamsConfirm onConfirm={onConfirm}>
                <StyledIcon>
                  <MessageOutlined />
                </StyledIcon>
              </MSTeamsConfirm>
            </StyledButton>
          </StyledRow>
        </Col>
        <Col span={4}>
          <StyledLink href={`mailto:${!data?.email ? data?.userPrincipalName : data.email}`}>
            <StyledIcon>
              <MailOutlined />
            </StyledIcon>
          </StyledLink>
        </Col>
        <Col span={4}>
          <StyledLink href="#">
            <StyledIcon>
              <ApartmentOutlined />
            </StyledIcon>
          </StyledLink>
        </Col>
        <Col span={4}>
          <StyledButton type={'link'}>
            <MSTeamsConfirm onConfirm={onConfirm}>
              <StyledIcon>
                <VideoCameraOutlined />
              </StyledIcon>
            </MSTeamsConfirm>
          </StyledButton>
        </Col>
        <Col span={4}>
          <StyledButton type={'link'}>
            <MSTeamsConfirm onConfirm={onConfirm}>
              <StyledIcon>
                <PhoneOutlined />
              </StyledIcon>
            </MSTeamsConfirm>
          </StyledButton>
        </Col>
      </Row>
    </Navigation>
  );
};

import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import moment from 'moment';
import {
  constants,
  FullWidthSpin,
  StatusTag,
  TagColor,
  TitleH2,
  useFullName,
  useRowGutter,
} from 'ui';
import { useTranslate } from 'translations';
import {
  camundaUsersDataSelector,
  getHistoricActivityInstanceStart,
  historicActivityInstanceResponseSelector,
  useSelector,
} from 'store';
import { LeaveInfo, LeaveStatus, RequestStatus } from 'types';
import { useDispatch } from 'react-redux';
import {
  ActionButtons,
  DEFAULT_PROPS_SPACE,
  getLeaveStatusColor,
  getLeaveStatusText,
  LeaveComments,
  SpaceText,
  STRONG_LABEL_PROPS_SPACE,
  StyledTextButton,
} from 'routes/Calendar';

import {
  AttachmentLeaveDrawer,
  LeaveAssigneeInfo,
  SPAN_SIZE_10,
  SPAN_SIZE_12,
  SPAN_SIZE_24,
  useUsersInfo,
  WarningMessageTitle,
} from 'routes';
import { useServicesContext } from '../../../../../app';
import {
  StyledCol,
  StyledLeaveRow,
  StyledLeavesDrawer,
  StyledLeavesWrapper,
  StyledText,
  StyledTimeStamp,
} from './LeavesDrawer.styled';

export interface AssigneeData {
  key: string;
  title: string;
  assigneeValue: string;
  assigneeId: string;
}

interface LeavesDrawerProps {
  setCurrentLeaveId: (leaveId: string) => void;
  currentLeaveId: string;
  setListViewType: () => void;
  dispatchGetLeaves: () => void;
  isMyTeamLeaves?: boolean;
  isLoading?: boolean;
  drawerInfo?: LeaveInfo;
  fetchNewComment: () => void;
  isError?: boolean;
  requestProcessInstanceId?: string;
}

export const LeavesDrawerDetailComponent: FC<LeavesDrawerProps> = ({
  setCurrentLeaveId,
  currentLeaveId,
  setListViewType,
  dispatchGetLeaves,
  isMyTeamLeaves,
  drawerInfo,
  fetchNewComment,
  isLoading,
  isError,
  requestProcessInstanceId,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const historicActivityInstanceResponse = useSelector(historicActivityInstanceResponseSelector);
  const [isFetchHistoryLoading, setIsFetchHisotyLoading] = useState<boolean>(false);
  const onDrawerClose = useCallback(() => setCurrentLeaveId(''), [setCurrentLeaveId]);
  const gutter16 = useRowGutter(16);
  const processInstanceId = drawerInfo?.processInstanceId || requestProcessInstanceId;
  const camundaUsers = useSelector(camundaUsersDataSelector);
  const comments = useMemo(() => drawerInfo?.comments?.slice()?.reverse(), [drawerInfo?.comments]);

  const fullName = useFullName(
    camundaUsers[drawerInfo?.adOid || '']?.data?.firstName,
    camundaUsers[drawerInfo?.adOid || '']?.data?.lastName
  );

  const { isUsersInfoLoading } = useUsersInfo(drawerInfo?.comments?.map(({ userId }) => userId));
  useEffect(() => {
    if (!processInstanceId) {
      return;
    }

    setIsFetchHisotyLoading(true);
    dispatch(
      getHistoricActivityInstanceStart({
        params: {
          activityType: 'userTask',
          processInstanceId,
          sortBy: 'startTime',
          sortOrder: 'asc',
        },
      })
    );
  }, [dispatch, processInstanceId]);

  useEffect(() => {
    isError && onDrawerClose();
  }, [isError, onDrawerClose]);

  useEffect(() => {
    if (
      processInstanceId &&
      (historicActivityInstanceResponse[processInstanceId]?.status === RequestStatus.rejected ||
        historicActivityInstanceResponse[processInstanceId]?.status === RequestStatus.resolved)
    ) {
      setIsFetchHisotyLoading(false);
    }
  }, [historicActivityInstanceResponse, processInstanceId]);

  const assigneeInformation = useMemo(() => {
    const assignees: AssigneeData[] = [];
    if (drawerInfo) {
      isMyTeamLeaves
        ? assignees.push(
            {
              key: '1',
              assigneeId: drawerInfo.requestId || drawerInfo.adOid,
              assigneeValue: drawerInfo.requestedBy || fullName,
              title: t('leaves.drawer.requestedBy'),
            },
            {
              key: '2',
              assigneeId: drawerInfo.adOid,
              assigneeValue: drawerInfo.delegatedTo || fullName,
              title: t('leaves.drawer.delegatedTo'),
            }
          )
        : assignees.push({
            key: '1',
            assigneeId: drawerInfo.adOid,
            assigneeValue: drawerInfo.delegatedTo || fullName,
            title: t('leaves.drawer.delegatedTo'),
          });
    }
    return assignees;
  }, [drawerInfo, fullName, isMyTeamLeaves, t]);

  const { setPrettyIdInfoRedirection, setIsFilterByPrettyId } = useServicesContext();

  const isPrettyIdClickable = (): boolean => {
    if (isMyTeamLeaves) {
      return drawerInfo?.status === LeaveStatus.awaitingApproval;
    }
    return true;
  };

  const handleOnRequestIdPress = useCallback(() => {
    if (isMyTeamLeaves) {
      if (drawerInfo?.status === LeaveStatus.awaitingApproval) {
        setPrettyIdInfoRedirection({
          redirectionPage: 'my-work',
          redirectionTab: 'work-0',
          taskPrettyId: drawerInfo?.requestId,
          PDK: 'Human_Resource.Employee_Services.Leave_request',
          processInstanceId: processInstanceId,
        });
        setIsFilterByPrettyId(true);
      }
    } else {
      setPrettyIdInfoRedirection({
        redirectionPage: 'tasks',
        redirectionTab: '0',
        taskPrettyId: drawerInfo?.requestId,
        processInstanceId,
      });
      setIsFilterByPrettyId(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledLeavesDrawer
      className="leavesDrawer"
      closable
      destroyOnClose
      maskClosable
      placement="right"
      visible={!!currentLeaveId}
      onClose={onDrawerClose}
      width={constants.drawer.width}
    >
      {isLoading || isUsersInfoLoading || isFetchHistoryLoading ? (
        <FullWidthSpin />
      ) : (
        drawerInfo && (
          <StyledLeavesWrapper>
            <Row gutter={gutter16}>
              <Col span={SPAN_SIZE_12}>
                <StyledTimeStamp type="secondary">
                  {drawerInfo?.createdOn
                    ? moment(drawerInfo?.createdOn).format('DD/MM/YYYY HH:mm')
                    : null}
                </StyledTimeStamp>
              </Col>
              {drawerInfo?.requestId && (
                <StyledCol span={SPAN_SIZE_10}>
                  {isPrettyIdClickable() ? (
                    <StyledTextButton onClick={handleOnRequestIdPress}>
                      <StyledText isClickable={isPrettyIdClickable()} type="secondary">
                        {drawerInfo?.requestId}
                      </StyledText>
                    </StyledTextButton>
                  ) : (
                    <StyledText isClickable={isPrettyIdClickable()} type="secondary">
                      {drawerInfo?.requestId}
                    </StyledText>
                  )}
                </StyledCol>
              )}
            </Row>
            {drawerInfo?.awaitingLineManagerApproval === true && (
              <WarningMessageTitle ErrorMessage={t('leaves.drawer.warning.approvalRequest')} />
            )}
            <Row gutter={gutter16}>
              <Col span={SPAN_SIZE_24}>
                <StyledLeaveRow align="middle" wrap={false}>
                  <Col>
                    <TitleH2 strong>{drawerInfo?.leaveType}</TitleH2>
                  </Col>
                  <Col offset={1}>
                    <StatusTag color={getLeaveStatusColor(drawerInfo.status) as TagColor}>
                      {getLeaveStatusText(t)[drawerInfo.status]}
                    </StatusTag>
                  </Col>
                </StyledLeaveRow>
              </Col>
            </Row>
            {assigneeInformation && <LeaveAssigneeInfo assignees={assigneeInformation} />}
            <Divider />
            <Row gutter={gutter16}>
              <Col span={SPAN_SIZE_12}>
                <SpaceText labelValue={t('leaves.drawer.startDate')} {...DEFAULT_PROPS_SPACE}>
                  {moment(drawerInfo?.startDate).format('DD/MM/YYYY')}
                </SpaceText>
              </Col>
              <Col span={SPAN_SIZE_12}>
                <SpaceText labelValue={t('leaves.drawer.endDate')} {...DEFAULT_PROPS_SPACE}>
                  {moment(drawerInfo?.endDate).format('DD/MM/YYYY')}
                </SpaceText>
              </Col>
            </Row>
            <Row gutter={gutter16}>
              <Col>
                <SpaceText labelValue={t('leaves.drawer.absenceDuration')} {...DEFAULT_PROPS_SPACE}>
                  {drawerInfo?.duration}
                </SpaceText>
              </Col>
            </Row>
            {drawerInfo?.reason ? (
              <Row gutter={gutter16}>
                <Col>
                  <SpaceText labelValue={t('leaves.drawer.reason')} {...STRONG_LABEL_PROPS_SPACE}>
                    {drawerInfo?.reason}
                  </SpaceText>
                </Col>
              </Row>
            ) : null}
            {drawerInfo?.details && (
              <Row gutter={gutter16}>
                <Col>
                  <SpaceText
                    labelValue={t('leaves.drawer.moreDetails')}
                    {...STRONG_LABEL_PROPS_SPACE}
                  >
                    {drawerInfo?.details}
                  </SpaceText>
                </Col>
              </Row>
            )}
            {drawerInfo.attachments.length > 0 && (
              <AttachmentLeaveDrawer
                title={t('leaves.drawer.attachments')}
                attachmentList={drawerInfo.attachments}
              />
            )}
            <Divider />
            {processInstanceId && (
              <LeaveComments
                comments={comments}
                processInstanceId={processInstanceId}
                fetchNewComments={fetchNewComment}
                isNewCommentsLoading={isLoading}
                isMyTeamLeaves={isMyTeamLeaves}
                hasBottomPadding={
                  drawerInfo?.status === LeaveStatus.planned ||
                  drawerInfo?.status === LeaveStatus.scheduled
                }
              />
            )}
            {drawerInfo && processInstanceId && !isMyTeamLeaves && (
              <ActionButtons
                status={drawerInfo.status}
                leaveId={drawerInfo.id}
                setListViewType={setListViewType}
                setCurrentLeaveId={setCurrentLeaveId}
                dispatchGetLeaves={dispatchGetLeaves}
                tasksLists={historicActivityInstanceResponse[processInstanceId]?.data}
                leaveType={drawerInfo.leaveType}
                startDate={moment(drawerInfo?.startDate)}
                endDate={moment(drawerInfo?.endDate)}
                duration={drawerInfo.duration}
                isHighFromBottom={true}
              />
            )}
          </StyledLeavesWrapper>
        )
      )}
    </StyledLeavesDrawer>
  );
};

export const LeavesDrawerDetails = memo(LeavesDrawerDetailComponent);

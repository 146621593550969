import { GroupInfo } from 'types';
import { Core, GROUP } from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetGroup {
  (prams: AxiosRequestConfig['params']): Promise<{ data: GroupInfo[] }>;
}

class Group extends Core {
  getGroup: GetGroup = async (params) => this.get(GROUP(), params);
}
export const group = new Group();

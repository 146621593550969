import styled from 'styled-components';
import { Button as AntButton, Card, Typography } from 'antd';
import { colors } from 'ui/elements';

export const Wrapper = styled.div`
  padding-bottom: 30px;
`;

export const Button = styled(AntButton)`
  width: 100%;
  transition: background-color 0.3s;
  border-radius: 2px;

  &:hover,
  &:focus {
    background-color: ${colors.orange};
    border-color: ${colors.orange};
  }
`;

export const PolicyWrapper = styled.div`
  padding-bottom: 8px;

  a {
    text-decoration: underline;
  }
`;

export const RegisterCard = styled(Card)`
  border-radius: 4px;
  padding: 30px 145px;
  width: 100%;
  background: #ffffff;

  .ant-card-body {
    padding: 0;
  }

  .ant-input-group-addon {
    color: #898c9f;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: #fc4c02;
    border-top-color: #fc4c02;
    border-right-color: #fc4c02;
    border-bottom-color: #fc4c02;
    border-left-color: #fc4c02;
    box-shadow: 0 0 0 0;
  }
`;

export const WelcomeTitle = styled(Typography.Title)`
  font-size: 28px !important;
  font-weight: 500 !important;
  text-align: center;
  line-height: 24px !important;
`;

export const PoweredByText = styled.p`
  margin: 24px 0 12px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.textColorSecondary};
`;

import styled from 'styled-components';
import { Row, Typography } from 'antd';
import { FileCsv } from 'assets';
import { colors, space } from 'ui';

const { Text } = Typography;

export const StyledAttachmentContainer = styled.div`
  margin-top: ${space.comfy};
  padding-bottom: ${space.moderate};
`;

export const StyledAttachmentTitle = styled(Text)`
  color: ${colors.textColorSecondary};
  font-size: 14px;
  font-family: inherit;
`;

export const StyledAttachmentItemWrapper = styled(Row)`
  border-radius: 10px;
  background: ${colors.lightActiveWithOpacity};
  width: 320px;
  height: 40px;
  flex-shrink: 0;
  padding-right: ${space.normal};
  padding-left: ${space.normal};
  align-items: center;
  justify-content: space-between;
  margin-top: ${space.cozy};
`;

export const StyledAttachmentItemText = styled(Text)`
  color: ${colors.primaryColor};
  font-size: 12px;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  margin-right: ${space.normal};
`;

export const StyledAttachmentIcon = styled(FileCsv)``;

import React from 'react';
import { useTasksContext } from 'routes/Tasks/TasksProvider';
import { TType } from 'translations';
import { useErrorNotification, useSuccessNotification } from 'ui';

export const useHandleSubmitStatus = (
  t: TType,
  isLoading?: boolean,
  isError?: boolean,
  isSuccess?: boolean,
  skipError?: boolean
) => {
  const { setIsSubmitLoading } = useTasksContext();

  React.useEffect(() => {
    isLoading && setIsSubmitLoading(isLoading);
    isError && setIsSubmitLoading(false);
    isSuccess && setIsSubmitLoading(false);
  }, [isError, isLoading, isSuccess, setIsSubmitLoading]);

  useSuccessNotification(
    {
      isNotification: isSuccess,
      message: t('messages.success'),
      description: t('messages.success.requestCreated'),
    },
    [isSuccess]
  );

  useErrorNotification(
    {
      isNotification: skipError ? false : isError,
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    },
    [isError]
  );
};

import { MessagesTypes } from 'translations';

export enum UserStatus {
  Available = 'Available',
  Unavailable = 'Unavailable',
}

export interface UserProfileStatus {
  id: 'string';
  availability: 'string';
  activity: UserActivityStatus;
}

export enum UserActivityStatus {
  Available = 'Available',
  Online = 'Online',
  Busy = 'Busy',
  BeRightBack = 'BeRightBack',
  Away = 'Away',
  InACall = 'InACall',
  DoNotDisturb = 'DoNotDisturb',
  Offline = 'Offline',
  InAConferenceCall = 'InAConferenceCall',
  InAMeeting = 'InAMeeting',
  OffWork = 'OffWork',
  OutOfOffice = 'OutOfOffice',
  PresenceUnknown = 'PresenceUnknown',
  Presenting = 'Presenting',
}

type TagColor = 'green' | 'gray' | 'yellow' | 'red';

export type StatusTagType<K extends string> = {
  [P in K]: {
    text: MessagesTypes;
    color: TagColor;
  };
};

export const TAG_COLOR_TEXT_MAP: StatusTagType<UserStatus> = {
  [UserStatus.Available]: {
    text: 'user.status.available',
    color: 'green',
  },
  [UserStatus.Unavailable]: {
    text: 'user.status.unavailable',
    color: 'gray',
  },
};

export const USER_STATUS_MAP: StatusTagType<UserActivityStatus> = {
  [UserActivityStatus.Available]: {
    text: 'user.status.available',
    color: 'green',
  },
  [UserActivityStatus.Online]: {
    text: 'user.status.online',
    color: 'green',
  },
  [UserActivityStatus.Busy]: {
    text: 'user.status.busy',
    color: 'red',
  },
  [UserActivityStatus.InAMeeting]: {
    text: 'user.status.inAMeeting',
    color: 'red',
  },
  [UserActivityStatus.InAConferenceCall]: {
    text: 'user.status.inAConferenceCall',
    color: 'red',
  },
  [UserActivityStatus.DoNotDisturb]: {
    text: 'user.status.doNotDisturb',
    color: 'red',
  },
  [UserActivityStatus.InACall]: {
    text: 'user.status.inACall',
    color: 'red',
  },
  [UserActivityStatus.Presenting]: {
    text: 'user.status.presenting',
    color: 'red',
  },
  [UserActivityStatus.BeRightBack]: {
    text: 'user.status.beRightBack',
    color: 'yellow',
  },
  [UserActivityStatus.Away]: {
    text: 'user.status.away',
    color: 'yellow',
  },
  [UserActivityStatus.OffWork]: {
    text: 'user.status.offWork',
    color: 'gray',
  },
  [UserActivityStatus.OutOfOffice]: {
    text: 'user.status.outOfOffice',
    color: 'gray',
  },
  [UserActivityStatus.Offline]: {
    text: 'user.status.offline',
    color: 'gray',
  },
  [UserActivityStatus.PresenceUnknown]: {
    text: 'user.status.presenceUnknown',
    color: 'gray',
  },
};

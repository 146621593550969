import styled from 'styled-components';
import { colors, space } from 'ui/elements';
import { Card, Select } from 'antd';

export const BalanceWidgetWrapper = styled(Card)`
  width: 285px;
  height: 100%;
  margin: ${space.moderate} ${space.moderate} ${space.moderate} -${space.slim};

  .ant-card-body {
    padding: ${space.normal} ${space.moderate};
  }
`;

export const StyledSelect = styled(Select)`
  width: 265px;
  margin-bottom: ${space.cozy};
  font-weight: bold;
  font-size: 18px;
`;

export const DaysBlockStyled = styled.div`
  padding: ${space.cozy};
  border: 1px solid ${colors.borderGrey};
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: ${space.slim};
  text-align: center;
`;

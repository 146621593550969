import { useCallback } from 'react';
import { AxiosError } from 'axios';

export const useErrorDescription = (defaultError = '') =>
  useCallback(
    (error: AxiosError) =>
      error?.response?.data?.errors?.[0]?.message ||
      error.response?.data.errorMessage ||
      defaultError,
    [defaultError]
  );

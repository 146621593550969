import styled from 'styled-components';
import { colors } from '../theme';

export type TagColor =
  | 'blue'
  | 'red'
  | 'orange'
  | 'jungle'
  | 'green'
  | 'gray'
  | 'yellow'
  | 'accentOrange'
  | 'skyBlue'
  | 'pink';

type StatusTagProps = {
  readonly color?: TagColor;
  readonly hideBackground?: boolean;
  readonly withBorder?: boolean;
  readonly padding?: string;
};
type TagType = {
  [K in TagColor]: {
    color: string;
    background: string;
  };
};

const TAG_COLOR_MAP: TagType = {
  blue: {
    background: '#ecf1fe',
    color: colors.blue,
  },
  orange: {
    background: '#fdeee2',
    color: colors.orange,
  },
  jungle: {
    background: '#dff9fb',
    color: colors.jungle,
  },
  red: {
    background: '#feeceb',
    color: colors.red,
  },
  green: {
    background: '#e1fae7',
    color: colors.green,
  },
  gray: {
    background: '#ecedef',
    color: colors.labelGrey,
  },
  yellow: {
    background: '#f5e59c85',
    color: colors.lightYellow,
  },
  accentOrange: {
    background: '#FDEEE2',
    color: colors.accentOrange,
  },
  pink: {
    background: '#FFEAF3',
    color: colors.darkPink,
  },
  skyBlue: {
    background: '#0099A8',
    color: colors.skyColor,
  },
};

const DEFAULT_TAG_COLOR = 'gray';

export const StatusTag = styled.span<StatusTagProps>`
  border-radius: 20px;
  height: 22px;
  line-height: 20px;
  padding: ${({ padding }) => padding ?? '2px 14px'};
  white-space: nowrap;
  border: ${({ color, withBorder }) =>
    withBorder ? '1px solid ' + TAG_COLOR_MAP[color || DEFAULT_TAG_COLOR]?.color : 'none'};
  background-color: ${({ color, hideBackground }) =>
    hideBackground ? 'transparent' : TAG_COLOR_MAP[color || DEFAULT_TAG_COLOR]?.background};
  color: ${({ color }) => TAG_COLOR_MAP[color || DEFAULT_TAG_COLOR]?.color};
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProcessDefinitionData, RequestCommonError, RequestStatus } from 'types';
import { ProcessDefinitionState } from './processDefinitionTypes';

export const emptyProcessDefinitionInitialState: ProcessDefinitionState = {};

export type PostProcessDefinitionStart = PayloadAction<string>;
export type PostProcessDefinitionSuccess = PayloadAction<{
  processDefinitionKey: string;
  data: ProcessDefinitionData;
}>;
export type PostProcessDefinitionFail = PayloadAction<{
  processDefinitionKey: string;
  error: RequestCommonError;
}>;
export type PostProcessDefinitionReset = PayloadAction<{ processDefinitionKey: string }>;

const processDefinition = createSlice({
  name: 'processDefinition',
  initialState: emptyProcessDefinitionInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    postProcessDefinitionStart(state, action: PostProcessDefinitionStart) {
      state[action.payload] = {
        ...state[action.payload],
        status: RequestStatus.pending,
      };
    },
    postProcessDefinitionSuccess(state, action: PostProcessDefinitionSuccess) {
      state[action.payload.processDefinitionKey].status = RequestStatus.resolved;
      state[action.payload.processDefinitionKey].data = action.payload.data;
    },
    postProcessDefinitionFail(state, action: PostProcessDefinitionFail) {
      state[action.payload.processDefinitionKey].status = RequestStatus.rejected;
      state[action.payload.processDefinitionKey].error = action.payload.error;
    },
    postProcessDefinitionReset(state, action: PostProcessDefinitionReset) {
      state[action.payload.processDefinitionKey].data = {} as ProcessDefinitionData;
      state[action.payload.processDefinitionKey].status = RequestStatus.idle;
      state[action.payload.processDefinitionKey].error = null;
    },
  },
});

export const {
  postProcessDefinitionStart,
  postProcessDefinitionSuccess,
  postProcessDefinitionFail,
  postProcessDefinitionReset,
} = processDefinition.actions;
export default processDefinition.reducer;

import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { HeaderTitle } from 'ui/elements';
import { useAuthContext } from 'authentication';
import { LogoutAppNavIcon, StyledHeader, StyledIconsCol, WrapperHeadIcon } from './Header.styled';
import { ConnectorProps, headerConnector } from './Header.connector';
import { AppIcon, NotificationBell } from './Components';

type Props = ConnectorProps & { withoutShadow?: boolean; children?: React.ReactNode };

export const HeaderComponent: FC<Props> = ({ children, withoutShadow }) => {
  const { logout } = useAuthContext();

  return (
    <StyledHeader $withoutShadow={withoutShadow} className="layout-fixed-header">
      <HeaderTitle align="middle" justify="space-between">
        <Col span={19}>{children}</Col>
        <StyledIconsCol span={3}>
          <Row align="middle" justify="end" wrap={false}>
            <Col>
              <NotificationBell />
            </Col>
            <Col>
              <AppIcon />
            </Col>
            <Col>
              <WrapperHeadIcon>
                <LogoutAppNavIcon onClick={logout} />
              </WrapperHeadIcon>
            </Col>
          </Row>
        </StyledIconsCol>
      </HeaderTitle>
    </StyledHeader>
  );
};

export const Header = headerConnector(HeaderComponent);

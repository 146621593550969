import { FormInstance, Rule } from 'antd/lib/form';
import React, { FC, Fragment, memo, useEffect, useState } from 'react';
import { StaticFormTemplate } from 'routes/Tasks/components';
import { MessagesTypes, TType } from 'translations';
import {
  DirectedTo,
  LetterType,
  SelfServiceLetterType,
  CreateFormItemType,
  SelectOptions,
} from 'types';
import {
  CommonFieldName,
  SelfServiceLetterFieldName,
  WRAPPER_COL_SPAN_SIZE_21,
} from 'ui/components';
import {
  englishPatternRule,
  FORM_FILE_RULES_NOT_REQUIRED,
  FORM_ITEM_REQUIRED_RULE,
  arabicPatternRule,
} from 'utils/formValidation';
import { normFile } from 'ui/components/GeneratedForm/FieldFile';

interface FormFieldsProps {
  t: TType;
  formData?: SelfServiceLetterType;
  formFields?: LetterType;
  resetField: () => void;
  directedToField?: DirectedTo;
  showField: boolean;
  resetDirectedFields: () => void;
  form?: FormInstance;
}

export const SelfServiceLetterFieldsComponent: FC<FormFieldsProps> = ({
  formData,
  formFields,
  resetField,
  t,
  directedToField,
  showField,
  resetDirectedFields,
  form,
}) => {
  const [letterTypesOption, setLetterTypesOption] = useState<SelectOptions>();
  const [directedToOption, setDirectedToOption] = useState<SelectOptions>();
  const [bankNameOption, setBankNameOption] = useState<SelectOptions>();
  const [embassyOption, setEmbassyOption] = useState<SelectOptions>();
  const [chamberOfCommerceOption, setChamberOfCommerceOption] = useState<SelectOptions>();

  useEffect(() => {
    let bankOption;
    let embassyOption;
    let chamberOfCommerceOption;
    let letterTypeOption;

    if (formData) {
      bankOption = formData.banks?.map((bank) => {
        return { value: bank.id, label: bank.englishName };
      });
      embassyOption = formData.embassies?.map((embassy) => {
        return { value: embassy.id, label: embassy.englishName };
      });
      chamberOfCommerceOption = formData.chamberOfCommerce?.map((value) => {
        return { value: value.id, label: value.type };
      });
      letterTypeOption = formData.letterType?.map((letter) => {
        return { value: letter.id, label: letter.letterType };
      });
      bankOption && setBankNameOption(bankOption);
      embassyOption && setEmbassyOption(embassyOption);
      chamberOfCommerceOption && setChamberOfCommerceOption(chamberOfCommerceOption);
      letterTypeOption && setLetterTypesOption(letterTypeOption);
    }
  }, [formData]);

  const ARABIC_FIELDS_RULES: Rule[] = [
    { required: true },
    arabicPatternRule(t),
    {
      max: 50,
      type: 'string',
    },
  ];

  const ENGLISH_FIELDS_RULES: Rule[] = [
    { required: true },
    englishPatternRule(t),
    {
      max: 50,
      type: 'string',
    },
  ];

  useEffect(() => {
    if (formFields) {
      const serviceLetterFields = formFields.directedTo?.map((field) => {
        return { value: field.id, label: field.type };
      });

      setDirectedToOption(serviceLetterFields);
    }
  }, [formFields]);

  const getItems = () => {
    const res: CreateFormItemType[] = [];

    res.push({
      id: `${0}`,
      label: t(
        `selfServiceLetter.staticForm.label.${SelfServiceLetterFieldName.letterType}` as MessagesTypes
      ),
      name: SelfServiceLetterFieldName.letterType,
      type: 'select',
      options: letterTypesOption,

      rules: FORM_ITEM_REQUIRED_RULE,
      wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
      hasFeedback: true,
      placeholder: t(
        `selfServiceLetter.staticForm.placeholder.${SelfServiceLetterFieldName.letterType}` as MessagesTypes
      ),
      resetFormFields: resetField,
    });
    if (formFields) {
      res.push({
        id: `${1}`,
        label: t(
          `selfServiceLetter.staticForm.label.${SelfServiceLetterFieldName.directedTo}` as MessagesTypes
        ),
        name: SelfServiceLetterFieldName.directedTo,
        type: 'select',
        options: directedToOption,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
        isHidden: !formFields.hasDirectedTo,
        resetFormFields: resetDirectedFields,
        placeholder: t(
          `selfServiceLetter.staticForm.placeholder.${SelfServiceLetterFieldName.directedTo}` as MessagesTypes
        ),
      });
    }
    if (directedToField?.hasOther) {
      res.push(
        {
          id: `${2}`,
          label: t(
            `selfServiceLetter.staticForm.label.${SelfServiceLetterFieldName.otherAR}` as MessagesTypes
          ),
          name: SelfServiceLetterFieldName.otherAR,
          type: 'input',
          rules: ARABIC_FIELDS_RULES,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          hasFeedback: true,
          placeholder: t(
            `selfServiceLetter.staticForm.placeholder.${SelfServiceLetterFieldName.otherAR}` as MessagesTypes
          ),
          isHidden: !directedToField?.hasOther || !showField,
        },
        {
          id: `${3}`,
          label: t(
            `selfServiceLetter.staticForm.label.${SelfServiceLetterFieldName.otherENG}` as MessagesTypes
          ),
          name: SelfServiceLetterFieldName.otherENG,
          type: 'input',
          rules: ENGLISH_FIELDS_RULES,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          hasFeedback: true,
          placeholder: t(
            `selfServiceLetter.staticForm.placeholder.${SelfServiceLetterFieldName.otherENG}` as MessagesTypes
          ),
          isHidden: !directedToField?.hasOther || !showField,
        }
      );
    }
    if (directedToField?.hasBank) {
      res.push({
        id: `${4}`,
        label: t(
          `selfServiceLetter.staticForm.label.${SelfServiceLetterFieldName.bankName}` as MessagesTypes
        ),
        name: SelfServiceLetterFieldName.bankName,
        type: 'select',
        options: bankNameOption,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
        placeholder: t(
          `selfServiceLetter.staticForm.placeholder.${SelfServiceLetterFieldName.bankName}` as MessagesTypes
        ),
        isHidden: !showField,
      });
    }
    if (directedToField?.hasEmbassy) {
      res.push({
        id: `${5}`,
        label: t(
          `selfServiceLetter.staticForm.label.${SelfServiceLetterFieldName.embassy}` as MessagesTypes
        ),
        name: SelfServiceLetterFieldName.embassy,
        type: 'select',
        options: embassyOption,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
        placeholder: t(
          `selfServiceLetter.staticForm.placeholder.${SelfServiceLetterFieldName.embassy}` as MessagesTypes
        ),
        isHidden: !directedToField?.hasEmbassy || !showField,
      });
    }
    if (
      formFields?.bankNameLabelArabic &&
      formFields.bankNameLabelEnglish &&
      formFields.bankNameValueArabic &&
      formFields.bankNameValueEnglish
    ) {
      res.push(
        {
          id: `${6}`,
          label: formFields.bankNameLabelArabic,
          name: SelfServiceLetterFieldName.userBankNameAR,
          type: 'input',
          disabled: formFields.hasBankNameLabels,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          hasFeedback: true,
          isHidden: !formFields.hasBankNameLabels,
        },
        {
          id: `${7}`,
          label: formFields.bankNameLabelEnglish,
          name: SelfServiceLetterFieldName.userBankNameENG,
          type: 'input',
          disabled: formFields.hasBankNameLabels,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          hasFeedback: true,
          isHidden: !formFields.hasBankNameLabels,
        }
      );
    }
    res.push(
      {
        id: `${8}`,
        label: t(
          `selfServiceLetter.staticForm.label.${SelfServiceLetterFieldName.chamberOfCommerce}` as MessagesTypes
        ),
        name: SelfServiceLetterFieldName.chamberOfCommerce,
        type: 'select',
        options: chamberOfCommerceOption,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
        placeholder: t(
          `selfServiceLetter.staticForm.placeholder.${SelfServiceLetterFieldName.chamberOfCommerce}` as MessagesTypes
        ),
        isHidden: formFields === undefined,
      },
      {
        id: `${9}`,
        label: t('requestForm.field.moreDetails.label'),
        name: CommonFieldName.moreDetail,
        type: 'textArea',
        maxNumber: 1024,
        hasFeedback: true,
        placeholder: t('tasks.textArea.placeHolder'),
        isHidden: formFields === undefined,
      },
      {
        id: `${10}`,
        label: t('requestForm.field.attachment.label'),
        name: CommonFieldName.attachment,
        rules: FORM_FILE_RULES_NOT_REQUIRED,
        type: 'file',
        getValueFromEvent: normFile,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        valuePropName: 'fileList',
        isHidden: formFields === undefined,
        attachmentFileTypes:
          'application/msword/,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/png,image/jpeg,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf',
        attachmentFileExtensions: '.doc,.docx,.ppt,.pptx,.png,.jpg,.jpeg,.xls,.xlsx,.pdf',
      }
    );

    return res;
  };

  return (
    <Fragment>
      <StaticFormTemplate
        t={t}
        getItems={getItems}
        description={formData?.processDescription}
        form={form}
      />
    </Fragment>
  );
};

export const SelfServiceLetterFields = memo(SelfServiceLetterFieldsComponent);

import React, { useMemo } from 'react';
import { TType } from 'translations';
import { AttachmentsItem, ExpenseItem } from 'types';
import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { colors, space, typography } from 'ui/elements';
import { ItemCard } from '../Molecules/expenseItemCard';
import { ExpenseDrawerDetails } from '../Molecules';

const StyledGroupName = styled(Typography.Text)`
  font-size: ${typography.size.giga};
  color: ${colors.primaryColor};
  display: inline-block;
  margin-bottom: ${space.cozy};
  font-weight: ${typography.weight.bold};
`;

interface ExpenseDrawerContentProps {
  t: TType;
  itemList: ExpenseItem[];
  attachmentsList: AttachmentsItem[];
  groupName: string;
  expenseType: string;
}

const ExpenseDrawerContentComponent: React.FC<ExpenseDrawerContentProps> = ({
  t,
  itemList,
  attachmentsList,
  groupName,
  expenseType,
}) => {
  const isItems = useMemo(() => itemList.length > 1, [itemList]);

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <StyledGroupName>{groupName}</StyledGroupName>
        </Col>
        <Col span={24}>
          {itemList.map((item) => (
            <ItemCard
              itemInfo={item}
              itemAttachments={attachmentsList}
              key={item.id}
              t={t}
              isItems={isItems}
            >
              <ExpenseDrawerDetails t={t} detailsItemList={item} expenseType={expenseType} />
            </ItemCard>
          ))}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export const ExpenseDrawerContent = React.memo(ExpenseDrawerContentComponent);

import { useQuery } from 'react-query';
import { QUERIES } from '../../constants';
import { nonPoInvoice } from '../NONPoInvoice';

export const useNonPoInvoiceLookUpListApi = () => {
  return useQuery(
    [QUERIES.nonPoInvoiceLookupsList],
    async () => {
      const { data } = await nonPoInvoice.getNonPoInvoiceLookUpList();
      return data;
    },
    { keepPreviousData: false }
  );
};

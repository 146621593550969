import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  GetRecentFilesFail,
  GetRecentFilesStart,
  GetRecentFilesSuccess,
  getRecentFilesStart,
  getRecentFilesSuccess,
  getRecentFilesFail,
} from './oneDriveRecentFilesSlice';

export const getProfileEpic: Epic<
  GetRecentFilesStart,
  GetRecentFilesSuccess | GetRecentFilesFail,
  RootState,
  typeof API
> = (action$, _, { recentFiles }) =>
  action$.pipe(
    filter(getRecentFilesStart.match),
    mergeMap(({ payload }) =>
      from(recentFiles.getRecentFiles(payload)).pipe(
        map(({ data }) => getRecentFilesSuccess(data)),
        catchError((error) => of(getRecentFilesFail(error?.response?.data)))
      )
    )
  );

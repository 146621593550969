import { AxiosRequestConfig } from 'axios';

export enum WFHFieldIds {
  startDateEndDate = 'startDateEndDate',
  description = 'moreDetails',
}

export interface WFHRemainingBalanceResponse {
  remaining_balance: string;
}

export interface WFHCalculateDurationResponse {
  duration: number;
}

export interface WorkFromHomSubmitFormData {
  taskId: string;
  processInstanceId: string;
  processDefinitionId: string;
  activityId: string;
}

export interface GETWorkfFromHomeRemainingBalance {
  (params: { hcmId: string }): Promise<{ data: WFHRemainingBalanceResponse }>;
}

export interface GetWorkFromHomeCalculateDuration {
  (params: { startDate: string; endDate: string }): Promise<{ data: WFHCalculateDurationResponse }>;
}

export interface PostWFHSubmitStaticFromRequest {
  (hcmPersonId: string, data: AxiosRequestConfig['data']): Promise<{
    data: WorkFromHomSubmitFormData;
  }>;
}

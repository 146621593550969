import React, { useCallback, useMemo } from 'react';
import { RequestStatus } from 'types';
import {
  employeeDirectorySearchRest,
  employeeDirectorySearchStart,
  employeeDirectorySearchSelector,
  useDispatch,
  useSelector,
} from 'store';

const sizeOfSearchResult = 10;
export const useEmployeeDirectorySearch = () => {
  const dispatch = useDispatch();
  const employeeDirectorySearch = useSelector(employeeDirectorySearchSelector);
  const searchForEmployee = React.useCallback(
    (searchTerm: string | null = null, page = 0) => {
      const requestProps = { searchParams: searchTerm, limit: sizeOfSearchResult, offset: page };
      dispatch(employeeDirectorySearchStart({ params: requestProps }));
    },
    [dispatch]
  );
  const hasData = useMemo(() => employeeDirectorySearch.data, [employeeDirectorySearch]);
  const isLoading = useMemo(() => employeeDirectorySearch.status === RequestStatus.pending, [
    employeeDirectorySearch.status,
  ]);
  const hasMore = useMemo(
    () =>
      employeeDirectorySearch.data?.list.length !== employeeDirectorySearch.data?.totalResult &&
      employeeDirectorySearch.status !== RequestStatus.pending,
    [
      employeeDirectorySearch.data?.list.length,
      employeeDirectorySearch.data?.totalResult,
      employeeDirectorySearch.status,
    ]
  );
  const isErrorEmployeeDirectorySearch = useMemo(
    () => employeeDirectorySearch.status === RequestStatus.rejected,
    [employeeDirectorySearch.status]
  );
  const loadMoreSearchResults = useCallback(
    (searchTerm: string | null = null) => {
      if (!hasMore || employeeDirectorySearch.status === RequestStatus.pending) {
        return;
      }
      if (employeeDirectorySearch.data) {
        searchForEmployee(searchTerm, employeeDirectorySearch.data.offset + 1);
      }
    },
    [searchForEmployee, hasMore, employeeDirectorySearch.data, employeeDirectorySearch.status]
  );
  const resetEmployeeSearchResults = useCallback(() => {
    dispatch(employeeDirectorySearchRest());
  }, [dispatch]);
  const listLength = useMemo(() => employeeDirectorySearch.data?.offset, [
    employeeDirectorySearch.data?.offset,
  ]);
  const searchResultList = useMemo(() => employeeDirectorySearch.data?.list, [
    employeeDirectorySearch.data?.list,
  ]);
  return {
    searchForEmployee,
    loadMoreSearchResults,
    searchResultList,
    resetEmployeeSearchResults,
    hasMore,
    isLoading,
    hasData,
    listLength,
    isErrorEmployeeDirectorySearch,
  };
};

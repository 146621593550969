import axios from 'axios';

export const getHttpClient = (baseURL: string) =>
  axios.create({
    headers: {
      'content-type': 'application/json',
    },
    baseURL,
  });

export const getOutBoundedHttpClient = () =>
  axios.create({
    headers: {
      'content-type': 'application/json',
    },
  });

import React, { FC, useMemo, ReactNode } from 'react';
import styled from 'styled-components';
import { Button, Card, Collapse, Typography } from 'antd';
import { typography } from 'ui/elements';
import { C1Location, C2Location, C3Location, C4Location, C5Location, C6Location } from 'assets';

const { Panel } = Collapse;
const { Text } = Typography;

const CandidateText = styled(Text)`
  font-size: ${typography.size.giga};
`;

const HeaderWrapper = styled.div`
  flex: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 12px;
`;

const StyledCollapse = styled(Collapse).attrs({ ghost: true })`
  .ant-collapse-header {
    padding-right: 0 !important;
  }
  .ant-collapse-content-box {
    padding-bottom: 0 !important;
  }
`;

export const CandidateCollapse: FC<Props> = ({ children, title, onRemove, id }) => {
  const icon = useMemo<ReactNode>(() => {
    const mapIdToIcon: Record<number, ReactNode> = {
      1: <C1Location />,
      2: <C2Location />,
      3: <C3Location />,
      4: <C4Location />,
      5: <C5Location />,
      6: <C6Location />,
    };
    return mapIdToIcon[id] || null;
  }, [id]);

  return (
    <Card>
      <StyledCollapse>
        <Panel
          header={
            <HeaderWrapper>
              {icon}
              <CandidateText>{title}</CandidateText>
            </HeaderWrapper>
          }
          key="1"
          extra={<Button onClick={onRemove}>Remove</Button>}
        >
          {children}
        </Panel>
      </StyledCollapse>
    </Card>
  );
};

interface Props {
  title: string;
  onRemove: () => void;
  id: number;
}

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../reducers';

export const userInfoStatus = (state: RootState): RootState['userInfoStatus'] =>
  state.userInfoStatus;

export const userInfoCardStatus = createSelector(
  userInfoStatus,
  (userInfoStatus) => userInfoStatus
);

export const profile = {
  id: '20',
  firstName: 'SOE Employee 1',
  lastName: 'QA',
  lastPromotionDate: '01/01/2022',
  department: 'Technology & Innovation',
  mobileNumber: null,
  countryCode: 'SA',
  email: 'soeportal_testing_1@tawal.com.sa',
  jobTitle: 'Specialist',
  location: 'TAWAL Head Office',
  managerName: 'SOE Employee 5 FN QA',
  managerEmail: 'soeportal_testing_4@tawal.com.sa',
  linkedin: 'LinkedIn',
  onlineStatus: null,
  lastSeen: null,
  adOid: '3f908418-5c39-44a0-8748-5ac953db3863',
  hcmId: '300000107671481',
  hcmPictureId: null,
  managerPersonId: 300000107671261,
  religion: 'MUSLIM',
  gender: 'F',
  maritalStatus: 'S',
  jobId: 300000001476053,
  departmentId: 300000003360887,
  employeeId: '909090',
  hireDate: '2022-01-01',
  dateOfBirth: '1985-01-01',
  businessUnitName: 'TAWAL_BU',
  jobName: 'Specialist',
  positionName: 'Specialist, QA',
  displayName: 'SOE Employee 1 FN QA',
  workerType: 'Employee',
  assignmentCategory: 'FR',
  nationalId: null,
  joiningDateH: '27/05/1443',
  employeeNameAR: null,
  employeeNameEN: 'SOE Employee 1 FN QA',
  nationalityAR: 'سعودي',
  nationalityEN: 'Saudi',
  departmentAR: 'التقنية والابتكار',
  departmentEN: 'Technology & Innovation',
  positionAR: 'أخصائي، QA',
  positionEN: 'Specialist, QA',
  bankNameAR: 'مصرف الراجحي',
  bankNameEN: 'Al Rajhi Bank',
  ibanNumber: 'SA2780000454608123456789',
  basicSalarySAR: '22000',
  transportationAllowanceSAR: '2200',
  housingAllowanceSAR: '5500',
  annualTicketAllowanceSAR: '1833',
  totalSalary: '31533',
  yearsWorkedInTheCompany: '1.04',
  yearsSinceLastSalaryChange: '1.04',
  yearsSinceLastPromorion: '0',
  color: '#7E5475',
  grade: '10',
  costCenter: '321',
  assignmentId: '300000107671499',
  country: null,
  isManager: true,
  probationPeriod: '90 Days',
  probationEndDate: '2022-03-31',
  employee: true,
  isInternalEmployee: true,
};

import React, { FC, Fragment, memo, useCallback, useEffect, useState } from 'react';
import { Empty, Form, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslate } from 'translations';
import moment from 'moment';
import { BusinessTrainingTravelName, FullWidthSpin } from 'ui';
import { VALIDATE_MESSAGES } from 'utils/formValidation';
import { FieldData } from 'rc-field-form/lib/interface';
import { useBusinessTravelMutation } from 'api';
import {
  AttachmentFile,
  attachmentsFieldsId,
} from 'routes/Tasks/components/pages/ServiceNewRequest/ServiceNewRequest';
import { submitAttachmentForm } from 'ui/publicFunction';
import { useHistory } from 'react-router-dom';
import { useHandleSubmitStatus, useTasksContext } from 'routes/Tasks';
import { NamePath } from 'antd/lib/form/interface';
import { getUserCurrentLocation, useSelector } from 'store';
import { BusinessTrainingTravelFields } from './BusinessTrainingTravelFields';
import { useInformationOfForm } from './hooks/useInfomationOfForm';

export interface BusinessTrainingTravelProps {
  adoid: string;
  formName?: string;
}

const LIMIT_DATE = 90;
export const BusinessTrainingTravelFormComponent: FC<BusinessTrainingTravelProps> = ({
  adoid,
  formName,
}) => {
  const { t } = useTranslate();
  const [form] = useForm();
  const [purposeId, setPurposeId] = useState<NamePath>();
  const [siteId, setSiteId] = useState<NamePath>();
  const [sourceCityId, setSourceCityId] = useState<NamePath>();
  const [destinationCityId, setDestinationCityId] = useState<NamePath>();
  const [businessDate, setBusinessDate] = useState<[moment.Moment, moment.Moment]>();
  const [actualTravelDate, setActualTravelDate] = useState<[moment.Moment, moment.Moment]>();
  const [transportation, setTransportation] = useState<NamePath>();
  const [isAttachmentsRequired, setIsAttachmentsRequired] = useState<boolean>(false);
  const [isTotalDestineRequired, setIsTotalDestineRequired] = useState<boolean>(true);
  const { setIsSubmitDisable } = useTasksContext();

  const currentLocation = useSelector(getUserCurrentLocation);

  const [attachmentData, setAttachmentData] = useState<AttachmentFile[]>([]);

  const history = useHistory();

  const useFormFields = React.useMemo(() => {
    const transportationValue = form.getFieldValue(BusinessTrainingTravelName.transportation);

    return {
      adoid,
      selectedSiteId: siteId,
      sourceCityId,
      destinationCityId,
      transportation: transportation || transportationValue,
      purposeId,
      businessDate,
      actualTravelDate: actualTravelDate,
      t: t,
    };
  }, [
    actualTravelDate,
    adoid,
    businessDate,
    destinationCityId,
    form,
    purposeId,
    siteId,
    sourceCityId,
    t,
    transportation,
  ]);

  const {
    siteIdLoading,
    siteIdOption,
    onSearchSitId,
    formData,
    isBusinessTravelLoading,
    isError,
    showSiteIds,
    totalDistance,
    citiesLoading,
    cityList,
    transportationMeanList,
    purposeList,
    isSourceDifferentFromDestination,
    ticketClass,
    disableTicketClass,
    disableMeanOfTransportation,
    planeValue,
    onSearchCityId,
    travelDuration,
    tripTypeOption,
    cityInSaudiId,
    estimatedAmount,
    submitDateVariables,
    siteInSaudiId,
    actualDateValidation,
    handleOnToDestinationSelected,
  } = useInformationOfForm(useFormFields);

  const mutation = useBusinessTravelMutation();

  const isDisableSubmitButton = React.useMemo(() => {
    if (travelDuration?.duration) {
      return travelDuration.duration > LIMIT_DATE ? true : false;
    } else {
      return false;
    }
  }, [travelDuration]);

  const onFieldsChange = useCallback(
    (changedFields: FieldData[]) => {
      const [changeField] = changedFields;
      const { name, value } = changeField || ({} as FieldData);
      if (Array.isArray(name) && name.length) {
        const [fieldName] = name;
        if (fieldName === BusinessTrainingTravelName.travelPurpose) {
          setPurposeId(value);
        }
        if (fieldName === BusinessTrainingTravelName.siteId) {
          setSiteId(value);
        }
        if (fieldName === BusinessTrainingTravelName.transportation) {
          setTransportation(value);
        }
        if (fieldName === BusinessTrainingTravelName.from) {
          setSourceCityId(value);
        }
        if (fieldName === BusinessTrainingTravelName.to) {
          setDestinationCityId(value);
          handleOnToDestinationSelected(value);
        }
        if (fieldName === BusinessTrainingTravelName.businessDates) {
          setBusinessDate(value);
          form.resetFields([BusinessTrainingTravelName.actualTravelDates]);
          form.validateFields([BusinessTrainingTravelName.actualTravelDates]);
        }
        if (fieldName === BusinessTrainingTravelName.actualTravelDates) {
          setActualTravelDate(value);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleOnToDestinationSelected]
  );

  useEffect(() => {
    if (ticketClass) {
      form.setFieldsValue({
        [BusinessTrainingTravelName.ticketClass]: ticketClass,
      });
    }
    if (totalDistance && isSourceDifferentFromDestination) {
      form.setFieldsValue({
        [BusinessTrainingTravelName.totalDistance]: totalDistance,
      });
    }
    if (disableMeanOfTransportation && planeValue) {
      form.setFieldsValue({
        [BusinessTrainingTravelName.transportation]: planeValue.id,
      });
      form.setFieldsValue({
        [BusinessTrainingTravelName.ticketClass]: planeValue.ticketClass,
      });
      setIsAttachmentsRequired(true);
      setIsTotalDestineRequired(false);
    } else {
      setIsAttachmentsRequired(false);
    }
    if (transportation === 3 || disableMeanOfTransportation) {
      setIsTotalDestineRequired(false);
    } else {
      setIsTotalDestineRequired(true);
    }
    if (travelDuration) {
      form.setFieldsValue({
        [BusinessTrainingTravelName.travelDuration]: travelDuration.duration,
      });
    }
    if (cityInSaudiId || siteInSaudiId) {
      form.setFieldsValue({
        [BusinessTrainingTravelName.tripType]: cityInSaudiId || siteInSaudiId,
      });
    }
    if (businessDate) {
      if (form.getFieldValue(BusinessTrainingTravelName.actualTravelDates) === undefined) {
        form.setFieldsValue({
          [BusinessTrainingTravelName.actualTravelDates]: !actualTravelDate
            ? businessDate
            : actualTravelDate,
        });
      }
    }

    if (currentLocation) {
      form.setFieldsValue({ [BusinessTrainingTravelName.currentLocation]: currentLocation });
    }
    if (estimatedAmount && estimatedAmount.totalEstimatedAmt) {
      form.setFieldsValue({
        [BusinessTrainingTravelName.estimatedTotalAmount]: estimatedAmount.totalEstimatedAmt,
      });
    }
    setIsSubmitDisable(isDisableSubmitButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form,
    isSourceDifferentFromDestination,
    totalDistance,
    ticketClass,
    disableMeanOfTransportation,
    planeValue,
    travelDuration,
    businessDate,
    actualTravelDate,
    cityInSaudiId,
    estimatedAmount,
    siteInSaudiId,
    currentLocation,
    transportation,
    isDisableSubmitButton,
  ]);

  const resetFields = useCallback(() => {
    form.resetFields([
      BusinessTrainingTravelName.siteId,
      BusinessTrainingTravelName.totalDistance,
      BusinessTrainingTravelName.from,
      BusinessTrainingTravelName.to,
      BusinessTrainingTravelName.destinationCountry,
      BusinessTrainingTravelName.actualTravelDates,
      BusinessTrainingTravelName.businessDates,
      BusinessTrainingTravelName.travelDuration,
      BusinessTrainingTravelName.transportation,
      BusinessTrainingTravelName.estimatedTotalAmount,
      BusinessTrainingTravelName.details,
    ]);
    setSourceCityId(undefined);
    setSiteId(undefined);
    setDestinationCityId(undefined);
    setBusinessDate(undefined);
    setActualTravelDate(undefined);
    setTransportation(undefined);
  }, [form]);

  const resetFromField = useCallback(() => form.resetFields([BusinessTrainingTravelName.to]), [
    form,
  ]);

  const resetToField = useCallback(() => {
    form.resetFields([
      BusinessTrainingTravelName.actualTravelDates,
      BusinessTrainingTravelName.businessDates,
      BusinessTrainingTravelName.travelDuration,
      BusinessTrainingTravelName.transportation,
      BusinessTrainingTravelName.estimatedTotalAmount,
    ]);
    setBusinessDate(undefined);
    setActualTravelDate(undefined);
  }, [form]);

  const onSubmitFinish = useCallback(
    async (variables) => {
      delete variables[BusinessTrainingTravelName.businessDates];
      delete variables[BusinessTrainingTravelName.actualTravelDates];

      const attachmentsForm: AttachmentFile[] = [];

      const variablesForm = Object.assign(variables, submitDateVariables);

      mutation.mutate(variablesForm);

      const currentFormFieldsName = Object.keys(variables);

      const currentAttachmentId = currentFormFieldsName.filter((variables: string) => {
        return attachmentsFieldsId.includes(variables);
      });

      if (currentAttachmentId && variables) {
        currentAttachmentId.forEach((fieldId: string) => {
          const attachmentValue = variables[fieldId];
          if (attachmentValue) {
            return attachmentsForm.push({ [fieldId]: attachmentValue });
          }
        });
      }
      if (attachmentsForm) {
        setAttachmentData(attachmentsForm);
      }
    },
    [mutation, submitDateVariables]
  );

  useHandleSubmitStatus(t, mutation.isLoading, mutation.isError, mutation.isSuccess);

  useEffect(() => {
    mutation.data &&
      submitAttachmentForm(mutation.isSuccess, mutation.data, history, undefined, attachmentData);
  }, [history, attachmentData, mutation]);

  return (
    <Fragment>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        validateMessages={VALIDATE_MESSAGES(t)}
        onFieldsChange={onFieldsChange}
        onFinish={onSubmitFinish}
      >
        {isError ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
        ) : isBusinessTravelLoading ? (
          <FullWidthSpin />
        ) : (
          <Spin spinning={mutation.isLoading} delay={100}>
            <BusinessTrainingTravelFields
              formData={formData}
              t={t}
              resetField={resetFields}
              form={form}
              destinationCity={destinationCityId || siteId}
              isCityLoading={citiesLoading}
              citiesDistance={!!totalDistance && isSourceDifferentFromDestination}
              selectedThePurpose={purposeId}
              resetToField={resetFromField}
              isSelectedSiteId={showSiteIds}
              siteIdLoading={siteIdLoading}
              onSearchSitId={onSearchSitId}
              siteIdOption={siteIdOption}
              countryCityOptions={cityList}
              transportationOptions={transportationMeanList}
              travelPurposeOptions={purposeList}
              disableTicketClass={disableTicketClass}
              isSourceDifferentFromDestination={isSourceDifferentFromDestination}
              onSearchCityId={onSearchCityId}
              travelDuration={travelDuration?.duration}
              disableMeanOfTransportation={disableMeanOfTransportation}
              tripTypeOption={tripTypeOption}
              cityInSaudiId={cityInSaudiId}
              estimatedAmount={estimatedAmount?.totalEstimatedAmt}
              siteInSaudiId={siteInSaudiId}
              actualDateValidation={actualDateValidation}
              isAttachmentsRequired={isAttachmentsRequired}
              isTotalDestineRequired={isTotalDestineRequired}
              isDurationExtendMax={isDisableSubmitButton}
              resetFormFields={resetToField}
            />
          </Spin>
        )}
      </Form>
    </Fragment>
  );
};

export const BusinessTrainingTravel = memo(BusinessTrainingTravelFormComponent);

import { useQuery } from 'react-query';
import { staticForm, QUERIES, PERSIST_QUERY_CONFIG } from 'api';
import { AxiosRequestConfig } from 'axios';

export const useFetchCustomersName = (params?: AxiosRequestConfig) => {
  return useQuery(
    [QUERIES.fetchCustomersName, params],

    async () => (await staticForm.getScrapInvoiceCustomersNames(params)).data,
    {
      enabled: !params,
      ...PERSIST_QUERY_CONFIG,
    }
  );
};

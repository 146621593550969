import React, { FC, memo, useCallback, useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Col, notification, Row } from 'antd';
import { useTranslate } from 'translations';
import { useRemoveMemberFromGroup } from 'api';
import { StyledRemove } from './ProcessGroupDrawer.styled';
import { ProcessGroupConfirm } from './ProcessGroupDrawerConfirm';

interface Props {
  groupId: string;
  userId: string;
  refetchList: () => void;
}

export const RemoveUserFromGroupComponent: FC<Props> = ({ groupId, userId, refetchList }) => {
  const { t } = useTranslate();
  const removeUser = useRemoveMemberFromGroup();

  const onConfirm = useCallback(() => {
    const variables = {
      groupId: groupId,
      userId: userId,
    };
    removeUser.mutate(variables);
  }, [removeUser, groupId, userId]);

  useEffect(() => {
    if (removeUser.isSuccess) {
      notification.success({
        message: t('processManagement.removeUser.success.title'),
        description: t('processManagement.removeUser.success.message'),
      });
      refetchList();
    }
  }, [removeUser.isSuccess, refetchList, t]);

  return (
    <ProcessGroupConfirm onConfirm={onConfirm}>
      <StyledRemove>
        <Row justify={'space-between'} gutter={[14, 0]}>
          <Col>
            <DeleteOutlined />
          </Col>
          <Col>{t('processManagement.groups.drawer.remove')}</Col>
        </Row>
      </StyledRemove>
    </ProcessGroupConfirm>
  );
};

export const RemoveUserFromGroup = memo(RemoveUserFromGroupComponent);

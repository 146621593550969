import React, { FC, Fragment, memo, useState, useCallback } from 'react';
import moment from 'moment';
import { DescriptionLabel, StaticFormTemplate } from 'routes/Tasks/components';
import { TType } from 'translations';
import { BusinessTrainingTravelType, CreateFormItemType, SelectOptions } from 'types';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
  BusinessTrainingTravelName,
  WRAPPER_COL_SPAN_SIZE_21,
  CommonFieldName,
  MAX_COMMENT_LENGTH,
} from 'ui/components';
import { FormInstance, Rule } from 'antd/lib/form';
import { FORM_ITEM_REQUIRED_RULE } from 'utils/formValidation';
import { normFile } from 'ui/components/GeneratedForm/FieldFile';
import { NamePath } from 'antd/lib/form/interface';
import { useErrorNotification } from 'ui';
import { selectFilterOption } from 'utils/getFilterOptions';
import { checkTheIncludedBetweenDates, defaultSearchParam } from './helper';

interface FormFieldsProps {
  t: TType;
  formData?: BusinessTrainingTravelType;
  disableTicketClass?: boolean;
  resetField: () => void;
  form?: FormInstance;
  destinationCity?: NamePath;
  isCityLoading: boolean;
  citiesDistance?: boolean;
  resetToField?: () => void;
  selectedThePurpose?: NamePath;
  isSelectedSiteId: boolean;
  siteIdOption?: SelectOptions;
  siteIdLoading: boolean;
  onSearchSitId: (value?: string) => void;
  travelPurposeOptions?: SelectOptions;
  countryCityOptions?: SelectOptions;
  transportationOptions?: SelectOptions;
  disableMeanOfTransportation?: boolean;
  isSourceDifferentFromDestination?: boolean;
  onSearchCityId: (cityInSaudi: boolean, value?: string) => void;
  travelDuration?: number;
  tripTypeOption?: SelectOptions;
  cityInSaudiId?: number;
  estimatedAmount?: number;
  siteInSaudiId?: number;
  actualDateValidation: () => Promise<void> | undefined;
  isAttachmentsRequired: boolean;
  isTotalDestineRequired: boolean;
  isDurationExtendMax: boolean;
  resetFormFields?: () => void;
}

export const BusinessTrainingTravelFieldsComponent: FC<FormFieldsProps> = ({
  formData,
  resetField,
  t,
  form,
  isCityLoading,
  citiesDistance,
  resetToField,
  selectedThePurpose,
  isSelectedSiteId,
  siteIdOption,
  siteIdLoading,
  onSearchSitId,
  transportationOptions,
  travelPurposeOptions,
  countryCityOptions,
  disableTicketClass,
  disableMeanOfTransportation,
  isSourceDifferentFromDestination,
  onSearchCityId,
  travelDuration,
  tripTypeOption,
  cityInSaudiId,
  estimatedAmount,
  siteInSaudiId,
  isAttachmentsRequired,
  isTotalDestineRequired,
  isDurationExtendMax,
  resetFormFields,
}) => {
  const [selectSearchText, setSearchText] = useState<string>();
  const [selectSearchFrom, setSelectSearchFrom] = useState<string>();
  const [selectSearchTo, setSelectSearchTo] = useState<string>();

  const onFocusFrom = useCallback(() => {
    if (selectSearchFrom) {
      onSearchCityId(true, selectSearchFrom);
    } else {
      onSearchCityId(defaultSearchParam.inSaudi, defaultSearchParam.search);
    }
  }, [onSearchCityId, selectSearchFrom]);

  const onFocusTo = useCallback(() => {
    if (selectSearchTo) {
      onSearchCityId(false, selectSearchTo);
    } else {
      onSearchCityId(defaultSearchParam.inSaudi, defaultSearchParam.search);
    }
  }, [onSearchCityId, selectSearchTo]);

  const disableReturningDate = useCallback((current: moment.Moment) => {
    const toDay = moment().toDate();
    const newDate = moment(toDay.setDate(toDay.getDate() - 91));

    return current.isBefore(newDate);
  }, []);

  const travelLocationsComparisonValidator = useCallback(() => {
    return isSourceDifferentFromDestination
      ? Promise.resolve()
      : Promise.reject(t('businessTravel.staticForm.validation.from.fromAndTo'));
  }, [isSourceDifferentFromDestination, t]);

  useErrorNotification(
    {
      isNotification: isDurationExtendMax,
      message: t('error.fromValidation'),
      description: t('businessTravel.staticForm.validation.from.maxDuration'),
    },
    [isDurationExtendMax]
  );
  const actualDateComparisonValidator = useCallback(() => {
    if (
      form?.getFieldValue(BusinessTrainingTravelName.actualTravelDates) &&
      form?.getFieldValue(BusinessTrainingTravelName.businessDates)
    ) {
      return checkTheIncludedBetweenDates(
        form?.getFieldValue(BusinessTrainingTravelName.actualTravelDates),
        form?.getFieldValue(BusinessTrainingTravelName.businessDates)
      )
        ? Promise.resolve()
        : Promise.reject(t('businessTravel.staticForm.validation.from.isInclude'));
    }
  }, [form, t]);

  const ACTUAL_DATE_VALIDATION: Rule[] = [
    {
      validator: actualDateComparisonValidator,
    },
    {
      required: true,
    },
  ];

  const DESTINATION_VALIDATION_RULES: Rule[] = [
    {
      validator: travelLocationsComparisonValidator,
    },
    {
      required: true,
    },
  ];

  const resetFieldsAndState = useCallback(() => {
    setSelectSearchFrom(undefined);
    setSelectSearchTo(undefined);

    resetField();
  }, [resetField]);

  const getItems = () => {
    const res: CreateFormItemType[] = [];
    res.push(
      {
        id: `${0}`,
        label: t(`businessTravel.staticForm.label.travelPurpose`),
        name: BusinessTrainingTravelName.travelPurpose,
        type: 'select',
        options: travelPurposeOptions,
        rules: FORM_ITEM_REQUIRED_RULE,
        hasFeedback: true,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        placeholder: t('businessTravel.staticForm.placeholder.travelPurpose'),
        resetFormFields: resetFieldsAndState,
        selectTheValueAsKey: true,
        filterOption: selectFilterOption,
      },
      {
        id: `${1}`,
        type: 'text',
        name: 'empty',
      }
    );
    selectedThePurpose &&
      res.push(
        {
          id: `${2}`,
          type: 'select',
          label: t(`businessTravel.staticForm.label.from`),
          name: BusinessTrainingTravelName.from,
          rules: FORM_ITEM_REQUIRED_RULE,
          options: countryCityOptions,
          hasFeedback: true,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: t('businessTravel.staticForm.placeholder.from'),
          onSearch: (searchText) => {
            setSelectSearchFrom(searchText);
            onSearchCityId(true, searchText);
          },
          searchText: selectSearchFrom,
          isLoading: isCityLoading,
          selectTheValueAsKey: true,
          filterOption: false,
          onFocus: onFocusFrom,
        },
        {
          id: `${3}`,
          type: 'select',
          label: t(`businessTravel.staticForm.label.siteId`),
          name: BusinessTrainingTravelName.siteId,
          rules: FORM_ITEM_REQUIRED_RULE,
          options: siteIdOption,
          hasFeedback: true,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: t('businessTravel.staticForm.placeholder.siteId'),
          resetFormFields: resetToField,
          isHidden: !isSelectedSiteId,
          onSearch: (value) => {
            onSearchSitId(value);
            setSearchText(value);
          },
          searchText: selectSearchText,
          isLoading: siteIdLoading,
          selectTheValueAsKey: true,
          filterOption: false,
        },
        {
          id: `${4}`,
          label: t('businessTravel.staticForm.label.to'),
          name: BusinessTrainingTravelName.to,
          dependencies: [BusinessTrainingTravelName.from],
          type: 'select',
          resetFormFields: resetFormFields,
          options: countryCityOptions,
          rules: DESTINATION_VALIDATION_RULES,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          hasFeedback: true,
          placeholder: t('businessTravel.staticForm.placeholder.to'),
          onSearch: (searchText) => {
            setSelectSearchTo(searchText);
            onSearchCityId(false, searchText);
          },
          searchText: selectSearchTo,
          isLoading: isCityLoading,
          isHidden: isSelectedSiteId,
          selectTheValueAsKey: true,
          filterOption: false,
          onFocus: onFocusTo,
        },

        {
          id: `${5}`,
          label: t('businessTravel.staticForm.label.totalDistance'),
          name: BusinessTrainingTravelName.totalDistance,
          type: 'input',
          disabled: citiesDistance,
          rules: isTotalDestineRequired ? FORM_ITEM_REQUIRED_RULE : undefined,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          isHidden: !citiesDistance,
          placeholder: t('businessTravel.staticForm.label.totalDistance'),
        },
        {
          id: `${6}`,
          label: t('businessTravel.staticFrom.label.currentLocation'),
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          type: 'input',
          name: BusinessTrainingTravelName.currentLocation,
          disabled: true,
          rules: FORM_ITEM_REQUIRED_RULE,
        },
        {
          id: `${7}`,
          label: t('businessTravel.staticFrom.label.businessDates'),
          name: BusinessTrainingTravelName.businessDates,
          type: 'rangePicker',
          rules: FORM_ITEM_REQUIRED_RULE,
          disabledDate: disableReturningDate,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          allowClear: true,
          rangePickerPlaceholder: [
            t('businessTravel.staticFrom.label.businessDates.startDate'),
            t('businessTravel.staticFrom.label.businessDates.endDate'),
          ],
        },
        {
          id: `${8}`,
          label: t('businessTravel.staticFrom.label.actualTravelDates'),
          name: BusinessTrainingTravelName.actualTravelDates,
          type: 'rangePicker',
          disabledDate: !isAttachmentsRequired ? disableReturningDate : undefined,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          rules: ACTUAL_DATE_VALIDATION,
          allowClear: true,
          rangePickerPlaceholder: [
            t('businessTravel.staticFrom.label.businessDates.startDate'),
            t('businessTravel.staticFrom.label.businessDates.endDate'),
          ],
        },
        {
          id: `${9}`,
          label: (
            <DescriptionLabel
              label={t('businessTravel.staticForm.label.travelDuration')}
              Icon={<ExclamationCircleOutlined />}
              labelDescription={t('businessTravel.staticForm.label.travelDuration.description')}
            />
          ),
          name: BusinessTrainingTravelName.travelDuration,
          type: 'inputNumber',
          disabled: true,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          isHidden: !travelDuration,
          placeholder: t('businessTravel.staticForm.label.travelDuration'),
          status: isDurationExtendMax ? 'error' : 'normal',
        },

        {
          id: `${10}`,
          label: t('businessTravel.staticForm.label.tripType'),
          name: BusinessTrainingTravelName.tripType,
          type: 'select',
          options: tripTypeOption,
          disabled: true,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          hasFeedback: true,
          isHidden: !cityInSaudiId && !siteInSaudiId,
          placeholder: t('businessTravel.staticForm.placeholder.tripType'),
          selectTheValueAsKey: true,
        },

        {
          id: `${11}`,
          label: t('businessTravel.staticForm.label.meansOfTransportation'),
          name: BusinessTrainingTravelName.transportation,
          type: 'select',
          options: transportationOptions,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          hasFeedback: true,
          placeholder: t('businessTravel.staticForm.placeholder.meansOfTransportation'),
          selectTheValueAsKey: true,
          disabled: disableMeanOfTransportation,
        },
        {
          id: `${12}`,
          label: t('businessTravel.staticForm.label.ticketClass'),
          name: BusinessTrainingTravelName.ticketClass,
          type: 'input',
          disabled: disableTicketClass,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          hasFeedback: true,
          isHidden: !disableTicketClass,
          placeholder: 'Ticket Class',
        },
        {
          id: `${13}`,
          label: t('businessTravel.staticFrom.label.estimatedTotalAmount'),
          name: BusinessTrainingTravelName.estimatedTotalAmount,
          type: 'input',
          disabled: true,
          rules: FORM_ITEM_REQUIRED_RULE,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          placeholder: t('businessTravel.staticFrom.label.estimatedTotalAmount'),
          isHidden: !estimatedAmount,
        },
        {
          id: `${14}`,
          label: t(`businessTravel.staticFrom.label.description`),
          name: BusinessTrainingTravelName.details,
          type: 'textArea',
          rules: FORM_ITEM_REQUIRED_RULE,
          maxNumber: MAX_COMMENT_LENGTH,
          placeholder: t('tasks.textArea.placeHolder'),
        },
        {
          id: `${15}`,
          label: t('requestForm.field.attachment.label'),
          name: CommonFieldName.attachment,
          type: 'file',
          getValueFromEvent: normFile,
          wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
          valuePropName: 'fileList',
        }
      );
    return res;
  };

  return (
    <Fragment>
      <StaticFormTemplate
        t={t}
        getItems={getItems}
        description={formData?.processDescription}
        form={form}
      />
    </Fragment>
  );
};

export const BusinessTrainingTravelFields = memo(BusinessTrainingTravelFieldsComponent);

import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

const { Title } = Typography;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
`;

export const AppLayoutTitle: FC = ({ children }) => (
  <TitleWrapper>
    <Title level={2}>{children}</Title>
  </TitleWrapper>
);

import { useQuery } from 'react-query';
import { jiraRequestsCenter, QUERIES } from 'api';

export function useJiraFormFields(
  serviceDeskId: string | undefined,
  serviceTypeId: string | undefined
) {
  return useQuery(
    [QUERIES.jiraFormFields, serviceDeskId, serviceTypeId],
    async () => {
      const deskId = Number(serviceDeskId);
      const typeId = Number(serviceTypeId);
      const { data } = await jiraRequestsCenter.getJiraFormFields(deskId, typeId);
      return data;
    },
    {
      enabled: serviceDeskId !== undefined && serviceTypeId !== undefined,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
}

import { RootFolderData } from 'types';
import { Core, ROOT_FOLDER, ROOT_FOLDER_CHILDREN } from 'api';
import { RootFolderChildrenData } from 'types/RootFolderChildren';

interface GetRootFolder {
  (): Promise<{ data: RootFolderData }>;
}
interface GetRootFolderChildren {
  (): Promise<{ data: RootFolderChildrenData }>;
}

class RootFolderModule extends Core {
  getRootFolder: GetRootFolder = async () => this.get(ROOT_FOLDER());
  getRootFolderChildren: GetRootFolderChildren = async () => this.get(ROOT_FOLDER_CHILDREN());
}

export const rootFolder = new RootFolderModule();

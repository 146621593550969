import styled from 'styled-components';
import { Col, Typography, Empty, Row } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { colors, typography, space, borderRadius } from 'ui/elements';

const { Text } = Typography;

export const StyledItemIcon = styled(FileOutlined)`
  font-size: 17px;
  color: ${colors.lightGrey};
`;

export const FileName = styled(Text)`
  width: 100%;
  line-height: 1;
  font-size: ${typography.size.hecto};
  color: ${(props: { color?: string }) => props.color ?? colors?.lightOverlayBlue};
  font-weight: ${typography.weight.regular};
`;

export const StyledLinkText = styled(Text)`
  color: ${(props: { color?: string }) => props.color ?? colors?.textColorSecondary};
  font-size: ${typography.size.hecto};
`;

export const DashboardTitleStyled = styled(Text).attrs({})`
  font-size: ${(props: { size?: number }) => (props.size ? props.size + 'px' : 'inherit')};
  &.ant-typography {
    display: inline;
  }
  color: ${(props: { size?: number; color?: string }) => props.color ?? colors?.textColorSecondary};
`;

export const StyledDateText = styled(Text).attrs({})`
  font-size: ${(props: { size?: number }) => (props.size ? props.size + 'px' : 'inherit')};
  &.ant-typography {
    display: inline;
  }
  color: ${colors.lightGrey};
`;

export const StyledCardFile = styled.div`
  width: 100%;
  border-radius: ${borderRadius.large};
  background-color: ${(props: { bgColor?: string; focusBgColor?: string }) =>
    props.bgColor ?? colors?.greyBackLabel};
  margin-bottom: ${space.slim};

  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    background-color: ${(props: { focusBgColor?: string }) =>
      props.focusBgColor ?? colors?.greyBackLabelHover};
  }
`;

export const StyledColAlign = styled(Col)`
  text-align-last: center;
  color: ${colors.white};
`;

export const StyledEmptyLiftSide = styled(Empty)`
  .ant-empty-description {
    color: ${colors.lightActive};
  }
  .ant-empty-img-simple-ellipse {
    fill: none;
  }
  .ant-empty-img-simple-path {
    fill: none;
  }
`;

export const StyledCol = styled(Col)`
  background-color: ${(props: { $noData: boolean; bgColor?: string }) =>
    props.$noData ? (props.bgColor != null ? props.bgColor : colors.greyBackLabel) : null};
  min-height: ${(props: { $noData: boolean }) => (props.$noData ? '150px' : 'inherit')};
`;

export const FullWidthSpinWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 24px;
`;

export const StyledWrapperContent = styled(Row)`
  display: flex;
  flex-flow: row wrap;
  height: inherit;
  padding: 10px;
`;

export interface AnnouncementCardsListResponse {
  componentData: {
    [key: string]: { infoCard: AnnouncementCardType }[];
  };
  componentOrder: string[];
}

export interface AnnouncementCardType {
  bannerRefImage: string;
  cardType: AnnouncementsCardType | null;
  createdDateTime: string | null;
  description: string;
  articleTags: string[];
  title: string;
  bannerImgAlt: string;
  expiryDateTime: string;
  galleryImages: {
    galleryRefImage: string;
    galleryImgAlt: string;
  }[];
  cta?: {
    ctaUrl: string;
    attachmentImage: string;
    ctaActionButton: AnnouncementsCardAvailabilty;
    actionHasAttachment: AnnouncementsCardAvailabilty;
    ctaTitle: string;
    ctaLinkType: string;
  };
  publish?: {
    senderName: string;
    senderEmail: string;
    publishDateTime: string;
  };
  event?: {
    attachmentImage: string | null;
    actionHasAttachment: string | null;
    eventEndDateTime: string | null;
    noOfQuesttion: number | null;
    eventStartDateTime: string | null;
  };
  cardName?: string;
}

export enum AnnouncementsCardType {
  NOTIFICATION = 'notification',
  GIFT = 'gift',
  OFFER = 'offer-card',
  FESTIVAL = 'festival-card',
}

export enum AnnouncementsCardAvailabilty {
  YES = 'yes',
  NO = 'no',
}

import styled from 'styled-components';
import { Button, Drawer, Row, Typography } from 'antd';
import { colors, typography } from 'ui/elements';

const { Text } = Typography;

export const StyledTitleDrawer = styled(Text)`
  font-size: ${typography.size.tera};
  font-weight: ${typography.weight.semiBold};
`;

export const StyledMangerLabel = styled(Text)`
  font-size: ${typography.size.kilo};
  color: ${colors.lightGrey};
`;

export const StyledMangerText = styled(Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.primaryColor};
`;

export const StyledMemberText = styled(StyledMangerText)`
  margin-left: 10px;
`;

export const StyledMemberListLabel = styled(Text)`
  font-size: ${typography.size.giga};
  color: ${colors.primaryColor};
`;

export const StyledRemove = styled(Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.lightGrey};
  cursor: pointer;
`;

export const StyledWrapperAddUser = styled.div`
  position: absolute;
  bottom: 10px;
`;
export const StyledAddUserBtn = styled(Button)`
  color: ${colors.white};
  background-color: ${colors.orangeShadow};
  border-color: ${colors.orangeShadow};

  &:hover,
  &:focus {
    color: ${colors.white};
    background-color: ${colors.orange};
    border-color: ${colors.orange};
  }
`;

export const StyledWrapperMemberList = styled(Row)`
  overflow: scroll;
  max-height: 50vh;
  overflow-x: hidden;
`;

export const StyledProcessGroupDrawer = styled(Drawer)`
  & .ant-drawer-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`;

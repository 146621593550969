import { useQuery } from 'react-query';
import { QUERIES } from '../../constants';
import { requestCatalogDrawer } from '../RequestCatalogDrawer';

export function useRequestCatalogDrawerInfo(requestName: string) {
  return useQuery(
    [QUERIES.requestCatalogDrawerInfo, requestName],
    async () => (await requestCatalogDrawer.getRequestCatalogDrawerInfo(requestName)).data,
    {
      refetchOnWindowFocus: false,
    }
  );
}

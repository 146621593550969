import styled from 'styled-components';
import { Button, Row, Tabs } from 'antd';

import { colors, space } from 'ui/elements';
import { HEADER_HEIGHT } from 'app/Header/Header.styled';
import { SelectIcon } from 'assets';

export const StyledTeamsSidebar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: ${colors.white};
  border-right: 1px solid ${colors.borderGrey};
`;

export const StyledTabs = styled(Tabs)`
  user-select: none;

  .ant-tabs-nav {
    height: ${HEADER_HEIGHT};
    margin: 0;

    &::before {
      display: none;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav-wrap .ant-tabs-nav-list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-tabs-tab {
      width: 100%;
      max-width: 140px;
      height: 24px;
      display: flex;
      justify-content: center;
      border: 1px solid black;
      margin: 0;
      transition: border-color 0.3s;
      border-color: #ced1da;

      &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;

        &:not(.ant-tabs-tab-active) {
          border-right: none;
        }
      }

      &:nth-child(2) {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;

        &:not(.ant-tabs-tab-active) {
          border-left: none;
        }
      }

      &.ant-tabs-tab-active {
        border-color: ${colors.primaryColor};
      }
    }
  }
`;

export const StyledNavigationWrapper = styled.div`
  padding: ${space.normal};
`;

export const StyledOpenButton = styled(Button)`
  width: 100%;
`;

export const StyledTeamsWrapper = styled.div`
  display: flex;
  height: calc(100% - ${HEADER_HEIGHT});
  border-top: 1px solid ${colors.borderGrey};
`;

export const StyledRow = styled(Row)`
  width: 100%;
`;

export const StyledTeamsPage = styled.div`
  height: 100%;
  min-height: calc(100vh - 57px);
  line-height: ${HEADER_HEIGHT};
  padding: 0;

  .ant-tabs {
    width: 100%;
  }

  .ant-tabs-nav {
    padding: 0 24px;
    margin: 0;
    background-color: #fff;
    min-height: ${HEADER_HEIGHT};
    border-bottom: 1px solid ${colors.borderGrey};

    ::before {
      border: none;
    }
  }
`;

export const StyledSelectIcon = styled(SelectIcon)`
  margin-left: ${space.moderate};
`;

export const UserContainer = styled.div`
  margin-right: 60px;
  font-size: 16px;
`;

export const TabPaneContent = styled.div`
  position: relative;
  background-color: #f0f2f5;
`;

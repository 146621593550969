import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskCommentsData, RequestCommonError, RequestStatus } from 'types';
import { TaskCommentsState } from './taskCommentsTypes';

export const emptyTaskCommentsState: TaskCommentsState = {
  comments: {},
  createCommentStatus: RequestStatus.idle,
  error: null,
  taskAttachmentStatus: RequestStatus.idle,
};

export type GetTaskCommentsStart = PayloadAction<{ taskId: string }>;
export type GetTaskCommentsSuccess = PayloadAction<{ taskId: string; data: TaskCommentsData[] }>;
export type GetTaskCommentsFail = PayloadAction<{
  taskId: string;
  error: RequestCommonError;
}>;

export type CreateTaskCommentStart = PayloadAction<{ taskId: string; message: string }>;
export type CreateTaskCommentSuccess = PayloadAction<TaskCommentsData>;
export type CreateTaskCommentFail = PayloadAction<RequestCommonError>;

export type UpdateTaskAttachmentStatus = PayloadAction<RequestStatus>;

const taskComments = createSlice({
  name: 'taskComments',
  initialState: emptyTaskCommentsState,
  reducers: {
    getTaskCommentsStart(state, action: GetTaskCommentsStart) {
      state.comments[action.payload.taskId] = {
        status: RequestStatus.pending,
        error: null,
      };
    },
    getTaskCommentsSuccess(state, action: GetTaskCommentsSuccess) {
      state.comments[action.payload.taskId] = {
        status: RequestStatus.resolved,
        data: action.payload.data,
        error: null,
      };
    },
    getTaskCommentsFail(state, action: GetTaskCommentsFail) {
      state.comments[action.payload.taskId] = {
        status: RequestStatus.rejected,
        error: action.payload.error,
      };
    },
    createTaskCommentStart(state, _: CreateTaskCommentStart) {
      state.createCommentStatus = RequestStatus.pending;
    },
    createTaskCommentSuccess(state, action: CreateTaskCommentSuccess) {
      state.createCommentStatus = RequestStatus.resolved;
      state.comments[action.payload.taskId] = {
        status: RequestStatus.resolved,
        data: [action.payload, ...(state.comments[action.payload.taskId]?.data || [])],
        error: null,
      };
    },
    createTaskCommentFail(state, action: CreateTaskCommentFail) {
      state.createCommentStatus = RequestStatus.rejected;
      state.error = action.payload;
    },
    updateTaskAttachmentStatus(state, action: UpdateTaskAttachmentStatus) {
      state.taskAttachmentStatus = action.payload;
    },
  },
});

export const {
  getTaskCommentsFail,
  getTaskCommentsStart,
  getTaskCommentsSuccess,
  createTaskCommentStart,
  createTaskCommentSuccess,
  createTaskCommentFail,
  updateTaskAttachmentStatus,
} = taskComments.actions;

export default taskComments.reducer;

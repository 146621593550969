import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetAssigneeUserFail,
  GetAssigneeUserStart,
  GetAssigneeUserSuccess,
  getAssigneeUserFail,
  getAssigneeUserStart,
  getAssigneeUserSuccess,
} from './assigneeUserSlice';

export const assigneeUserEpic: Epic<
  GetAssigneeUserStart,
  GetAssigneeUserSuccess | GetAssigneeUserFail,
  RootState,
  typeof API
> = (action$, _, { assigneeData }) =>
  action$.pipe(
    filter(getAssigneeUserStart.match),
    exhaustMap(({ payload }) =>
      from(assigneeData.getAssigneeUser(payload)).pipe(
        map(({ data }) => getAssigneeUserSuccess(data)),
        catchError((error) => of(getAssigneeUserFail(error?.response?.data)))
      )
    )
  );

import React from 'react';
import { StyledAppNameText, StyledAppNameWrapper } from '../AppsDrawer.styled';

export const AppNameStyle = (nameText: string) => {
  return (
    <StyledAppNameWrapper>
      <StyledAppNameText ellipsis>{nameText}</StyledAppNameText>
    </StyledAppNameWrapper>
  );
};

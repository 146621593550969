import React, { FC, useState } from 'react';
import { ProfileData } from 'types';
import { EditView } from './EditView';
import { ReadView } from './ReadView';

interface Props {
  profileData: ProfileData;
}

export const Private: FC<Props> = ({ profileData }) => {
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? (
    <EditView setIsEditing={setIsEditing} />
  ) : (
    <ReadView
      hcmPersonId={profileData.hcmId}
      setIsEditing={setIsEditing}
      hireDate={profileData.hireDate}
    />
  );
};

import React, { FC, useEffect, useMemo, useState } from 'react';
import { FilterContextType } from 'ui/components/Filter/Filter.types';
import { TType, useTranslate } from 'translations';
import moment from 'moment';
import { useLeaseSitesList } from 'api';
import { StatusTag } from 'ui';
import { TablePaginationConfig } from 'antd/lib/table';
import {
  LeaseSitesListWrapper,
  StyledColumnValue,
  StyledTable,
  StyledColumnHeader,
} from './LeaseSitesTable.styled';

interface Props {
  filterValues: FilterContextType['filterValues'];
}

const LEASE_SITES_LIST_PAGE_SIZE = 10;

const getTableColumns = (t: TType) => {
  return [
    {
      title: <StyledColumnHeader>{t('lease.leaseSites.list.column.siteId')}</StyledColumnHeader>,
      dataIndex: 'siteId',
      key: 'siteId',
      width: '7%',
    },
    {
      title: <StyledColumnHeader>{t('lease.leaseSites.list.column.landlord')}</StyledColumnHeader>,
      dataIndex: 'landlord',
      key: 'landlord',
      width: '15%',
    },
    {
      title: <StyledColumnHeader>{t('lease.leaseSites.list.column.region')}</StyledColumnHeader>,
      dataIndex: 'region',
      key: 'region',
      width: '10%',
    },
    {
      title: <StyledColumnHeader>{t('lease.leaseSites.list.column.district')}</StyledColumnHeader>,
      dataIndex: 'district',
      key: 'district',
      width: '10%',
    },
    {
      title: <StyledColumnHeader>{t('lease.leaseSites.list.column.siteType')}</StyledColumnHeader>,
      dataIndex: 'siteType',
      key: 'siteType',
      width: '10%',
    },
    {
      title: (
        <StyledColumnHeader>{t('lease.leaseSites.list.column.leaseEndDate')}</StyledColumnHeader>
      ),
      dataIndex: 'leaseEndDate',
      key: 'leaseEndDate',
      width: '10%',
    },
    {
      title: <StyledColumnHeader>{t('lease.leaseSites.list.column.rent')}</StyledColumnHeader>,
      dataIndex: 'rent',
      key: 'rent',
      width: '10%',
    },
    {
      title: (
        <StyledColumnHeader>{t('lease.leaseSites.list.column.leaseStatus')}</StyledColumnHeader>
      ),
      dataIndex: 'leaseStatus',
      key: 'leaseStatus',
      width: '13%',
    },
    {
      title: (
        <StyledColumnHeader>{t('lease.leaseSites.list.column.paymentStatus')}</StyledColumnHeader>
      ),
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      width: '15%',
    },
  ];
};

export const LeaseSitesTable: FC<Props> = ({ filterValues }) => {
  const { t } = useTranslate();
  const {
    mutate: getSitesList,
    data: sitesList,
    isLoading: isSitesListLoading,
  } = useLeaseSitesList();
  const [tablePage, setTablePage] = useState<number>(0);

  const columns = useMemo(() => getTableColumns(t), [t]);

  const sitesListData = useMemo(() => {
    const sitesListColumnValue: Record<string, unknown>[] = [];
    if (sitesList) {
      sitesList.list.forEach((site) => {
        sitesListColumnValue.push({
          key: site.id,
          siteId: <StyledColumnValue>{site.siteId}</StyledColumnValue>,
          landlord: <StyledColumnValue>{site.landlord?.value ?? '-'}</StyledColumnValue>,
          region: <StyledColumnValue>{site.region?.value ?? '-'}</StyledColumnValue>,
          district: <StyledColumnValue>{site.district?.value ?? '-'}</StyledColumnValue>,
          siteType: <StyledColumnValue>{site.siteType?.value ?? '-'}</StyledColumnValue>,
          leaseEndDate: (
            <StyledColumnValue>
              {site.leaseEndDate ? moment(site.leaseEndDate).format('DD.MM.YYYY') : '-'}
            </StyledColumnValue>
          ),
          rent: <StyledColumnValue>{site.rent ?? '-'}</StyledColumnValue>,
          leaseStatus: site.leaseStatus ? (
            <StatusTag color="skyBlue" hideBackground padding="5px 14px">
              {site.leaseStatus.value}
            </StatusTag>
          ) : (
            <StyledColumnValue>-</StyledColumnValue>
          ),
          paymentStatus: site.paymentStatus ? (
            <StatusTag color="skyBlue" hideBackground>
              {site.paymentStatus.value}
            </StatusTag>
          ) : (
            <StyledColumnValue>-</StyledColumnValue>
          ),
        });
      });
    }
    return sitesListColumnValue;
  }, [sitesList]);

  const onTableChange = (pagination: TablePaginationConfig) => {
    setTablePage(pagination.current ? pagination.current - 1 : 0);
  };

  useEffect(() => {
    let leaseEndDate = filterValues['leaseEndDate'];
    if (filterValues['leaseEndDate'] instanceof moment) {
      leaseEndDate = ((filterValues['leaseEndDate'] as unknown) as moment.Moment).format(
        'YYYY-MM-DD'
      );
    }
    getSitesList({
      page: tablePage,
      size: LEASE_SITES_LIST_PAGE_SIZE,
      ...filterValues,
      leaseEndDate: leaseEndDate as string,
    });
  }, [getSitesList, tablePage, filterValues]);

  return (
    <LeaseSitesListWrapper>
      <StyledTable
        columns={columns}
        dataSource={sitesListData}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: true,
        }}
        pagination={{
          total: sitesList?.totalResult,
          showSizeChanger: false,
          defaultCurrent: tablePage + 1,
          pageSize: LEASE_SITES_LIST_PAGE_SIZE,
          current: tablePage + 1,
          showTotal: (total, range) => `${total}`,
        }}
        loading={isSitesListLoading}
        onChange={onTableChange}
      />
    </LeaseSitesListWrapper>
  );
};

import { NotificationListData, UnreadNotificationCounter } from 'types';
import {
  Core,
  NOTIFICATION_LIST,
  NOTIFICATION_UNREAD_COUNTER,
  ALL_NOTIFICATION_UPDATES_LIST,
  MARK_NOTIFICATION_AS_READ,
} from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetUnreadNotificationCount {
  (): Promise<{ data: UnreadNotificationCounter }>;
}

interface GetNotificationsList {
  (params?: AxiosRequestConfig['params']): Promise<{ data: NotificationListData }>;
}

interface GetAllNotificationUpdatesList {
  (requestId: string, params?: AxiosRequestConfig['params']): Promise<{
    data: NotificationListData;
  }>;
}

interface MarkNotificationAsRead {
  (params: { [key: string]: string }): Promise<{
    data: string;
  }>;
}

class NotificationCenter extends Core {
  getUnreadNotificationCount: GetUnreadNotificationCount = async () =>
    this.get(NOTIFICATION_UNREAD_COUNTER());
  getNotificationsList: GetNotificationsList = async (params) =>
    this.get(NOTIFICATION_LIST(), { params });

  getAllNotificationUpdatesList: GetAllNotificationUpdatesList = async (
    requestId: string,
    params
  ) => this.get(ALL_NOTIFICATION_UPDATES_LIST(requestId), { params });

  markNotificationAsRead: MarkNotificationAsRead = async (params) =>
    this.put(MARK_NOTIFICATION_AS_READ(), null, { params });
}

export const notificationCenter = new NotificationCenter();

import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from 'api';
import { countryCityList } from '../CountryCityList';

export function useDistanceBWCities(originId: number, destinationId: number) {
  return useQuery(
    [QUERIES.handleDistance, originId, destinationId],
    async () => {
      const { data } = await countryCityList.getDistanceBWCities(originId, destinationId);
      return data;
    },
    {
      enabled: false,
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetTeamsUserAvatarFail,
  GetTeamsUserAvatarStart,
  GetTeamsUserAvatarSuccess,
  getTeamsUserAvatarSuccess,
  getTeamsUserAvatarFail,
  getTeamsUserAvatarStart,
} from './teamsUserAvatarSlice';

export const getTeamsUserAvatarEpic: Epic<
  GetTeamsUserAvatarStart,
  GetTeamsUserAvatarSuccess | GetTeamsUserAvatarFail,
  RootState,
  typeof API
> = (action$, _, { userAvatar }) =>
  action$.pipe(
    filter(getTeamsUserAvatarStart.match),
    mergeMap(({ payload }) =>
      from(
        userAvatar
          .getTeamsUserAvatar(payload.userId, payload.params)
          .then((data) => new Blob([data.data]))
          .then((blob) => URL.createObjectURL(blob))
      ).pipe(
        map((data) =>
          getTeamsUserAvatarSuccess({
            userId: payload.userId,
            data,
          })
        ),
        catchError((error) =>
          of(getTeamsUserAvatarFail({ userId: payload.userId, error: error?.response?.message }))
        )
      )
    )
  );

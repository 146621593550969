import styled from 'styled-components';
import { Typography } from 'antd';

const { Title } = Typography;

export const TitleH4 = styled(Title).attrs({
  level: 4,
})`
  &.ant-typography {
    margin: 0 !important;
    font-size: 16px;
    line-height: 24px;

    &.ant-typography-secondary {
      color: #8b93a2;
      font-weight: normal;
    }
  }
`;

import { Core, REASSIGN_TASK } from 'api';

interface PostReassignTask {
  (taskId: string, userId: string, rootPath?: string): Promise<unknown>;
}

class ReassignTask extends Core {
  postReassginTask: PostReassignTask = async (taskId, userId, rootPath) =>
    this.post(REASSIGN_TASK(taskId, rootPath), { userId: userId });
}
export const reassignTask = new ReassignTask();

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetProcessInstanceFail,
  GetProcessInstanceStart,
  GetProcessInstanceSuccess,
  getProcessInstanceFail,
  getProcessInstanceStart,
  getProcessInstanceSuccess,
} from './processInstanceInfoSlice';

export const processInstanceInfoEpic: Epic<
  GetProcessInstanceStart,
  GetProcessInstanceSuccess | GetProcessInstanceFail,
  RootState,
  typeof API
> = (action$, _, { processInstance }) =>
  action$.pipe(
    filter(getProcessInstanceStart.match),
    exhaustMap(({ payload }) =>
      from(processInstance.getProcessInstanceInfo(payload)).pipe(
        map(({ data }) => getProcessInstanceSuccess(data)),
        catchError((error) => of(getProcessInstanceFail(error?.response?.data)))
      )
    )
  );

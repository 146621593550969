import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const requestCatalogDrawerData = (state: RootState): RootState['requestCatalogDrawer'] =>
  state.requestCatalogDrawer;

export const requestCatalogDrawerSelector = createSelector(
  requestCatalogDrawerData,
  ({ error, status, data }) => ({
    error,
    status,
    data,
  })
);

import React, { FC, useCallback } from 'react';
import { Form, Select } from 'antd';
import { Options } from 'routes/Tasks/components/templates/RequestsCatalogDrawer/constants';
import { selectFilterOption } from 'utils/getFilterOptions';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { Rule } from 'antd/lib/form';
import { TType } from 'translations';

export interface SelectedOption {
  id?: string;
  label?: string;
  values: {
    [key: string]: string;
  };
}

interface Props {
  fieldId?: string | number;
  fieldLabel?: string;
  optionsList: Options;
  setSelectedOption?: (selectedOptionValue: string) => void;
  selectedOption?: string;
  isDirectToSelected?: boolean;
  rules?: Rule[];
  placeholder?: string;
  isItem?: boolean;
  itemsForm?: FormListFieldData;
  t: TType;
}

const { Option } = Select;

const COL_SPAN_SIZE = { span: 20 };

export const FieldSelect: FC<Props> = ({
  optionsList,
  setSelectedOption,
  fieldId,
  fieldLabel,
  placeholder,
  isItem,
  itemsForm,
  rules,
  t,
}) => {
  const onSelectOption = useCallback(
    (value: string) => {
      if (value && setSelectedOption) {
        setSelectedOption(value);
      }
    },
    [setSelectedOption]
  );
  const fieldName = [fieldLabel, fieldId].join('');

  return (
    <Form.Item
      {...itemsForm}
      wrapperCol={COL_SPAN_SIZE}
      hasFeedback
      label={fieldLabel}
      name={isItem ? fieldName : fieldLabel}
      rules={rules}
    >
      <Select
        onSelect={onSelectOption}
        placeholder={placeholder}
        notFoundContent={t('dropdown.noMatching')}
        showSearch
        filterOption={selectFilterOption}
      >
        {optionsList.map((option, index) => (
          <Option key={index} value={option.label}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

import styled from 'styled-components';
import { Typography, Row } from 'antd';
import { colors, typography } from 'ui/elements';

export const UserProcessesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
`;

export const UserProcesses = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ManagerActionsContainer = styled.div`
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const UserProcessTitle = styled(Typography.Text)`
  font-size: ${typography.size.giga} !important;
  font-weight: ${typography.weight.regular} !important;
  color: ${colors.labelGrey} !important;
  margin-bottom: 25px;
`;

export const FullWidthRow = styled(Row)`
  width: 100%;
`;

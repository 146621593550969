import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, UserProfileStatus } from 'types';
import { UserStatusState } from './userStatusTypes';

export const emptyUserStatusInitialState: UserStatusState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetUserStatusStart = PayloadAction;
export type GetUserStatusSuccess = PayloadAction<UserProfileStatus>;
export type GetUserStatusFail = PayloadAction<RequestCommonError>;

const userStatus = createSlice({
  name: 'userStatus',
  initialState: emptyUserStatusInitialState,
  reducers: {
    getUserStatusStart(state, _: GetUserStatusStart) {
      state.status = RequestStatus.pending;
    },
    getUserStatusSuccess(state, action: GetUserStatusSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getUserStatusFail(state, action: GetUserStatusFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const { getUserStatusStart, getUserStatusSuccess, getUserStatusFail } = userStatus.actions;
export default userStatus.reducer;

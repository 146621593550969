import {
  ExpenseData,
  ExpenseAttachment,
  AllowanceData,
  ExpensesFilterLookup,
  SubmitNewRequestForm,
  ExpenseInfo,
} from 'types';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { ALLOWANCE_SUBMIT, EXPENSES_DATA, EXPENSE_DRAWER } from '../Core/CamundaEndpoints';
import { Core, EXPENSES_ATTACHMENT, EXPENSES_ALLOWANCE, EXPENSES_LOOKUP } from '../Core';

interface GetProfileExpenses {
  (params?: AxiosRequestConfig['params']): Promise<{ data: ExpenseData }>;
}
interface GetProfileExpensesAttachment {
  (hcmPersonId: string, expensesUniqID: string): Promise<{ data: ExpenseAttachment[] }>;
}

interface GetExpensesAllowance {
  (adoId: string): Promise<{ data: AllowanceData }>;
}

interface GetExpensesLookup {
  (): Promise<{ data: ExpensesFilterLookup }>;
}

interface PostAllowanceExpense {
  (adoId: string, data: AxiosRequestConfig['data']): AxiosPromise<SubmitNewRequestForm>;
}

interface GetProfileExpensesDrawer {
  (expenseId: string, hcmId: string): Promise<{ data: ExpenseInfo }>;
}

class ProfileExpenses extends Core {
  getProfileExpenses: GetProfileExpenses = async (params) => this.get(EXPENSES_DATA(), { params });

  getProfileExpensesAttachment: GetProfileExpensesAttachment = async (
    hcmPersonId: string,
    expensesUniqID: string
  ) => this.get(EXPENSES_ATTACHMENT(hcmPersonId, expensesUniqID));

  getExpensesAllowance: GetExpensesAllowance = async (adoId: string) =>
    this.get(EXPENSES_ALLOWANCE(adoId));

  getExpensesLookup: GetExpensesLookup = async () => this.get(EXPENSES_LOOKUP());

  postAllowanceExpense: PostAllowanceExpense = async (adoId, data) =>
    this.post(ALLOWANCE_SUBMIT(adoId), data);

  getExpenseDrawer: GetProfileExpensesDrawer = async (expenseId, hcmId) =>
    this.get(EXPENSE_DRAWER(expenseId, hcmId));
}

export const profileExpenses = new ProfileExpenses();

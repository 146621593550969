import React from 'react';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { useTranslate } from 'translations';
import { ProcessContainer, ProcessName, RemoveButton } from './UserDrawerUserProcess.styled';

type Props = {
  id: number;
  name: string;
  showManagerView: boolean;
  onRemove: () => void;
  isRemoveLoading: boolean;
};

export const UserDrawerUserProcess: React.FC<Props> = ({
  id,
  name,
  showManagerView,
  onRemove,
  isRemoveLoading,
}) => {
  const { t } = useTranslate();

  return (
    <ProcessContainer>
      <ProcessName>{name}</ProcessName>
      <Popconfirm
        icon={<QuestionCircleOutlined />}
        overlayClassName="ms-teams-confirm"
        placement="bottomLeft"
        title={t('processManagement.users.drawer.confirm.message')}
        okText={t('yes')}
        cancelText={t('no')}
        onConfirm={() => onRemove()}
      >
        {showManagerView && (
          <RemoveButton icon={<DeleteOutlined />} type="text" disabled={isRemoveLoading}>
            Remove
          </RemoveButton>
        )}
      </Popconfirm>
    </ProcessContainer>
  );
};

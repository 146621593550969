import React, { FC, memo, Fragment, useCallback, useState, useEffect } from 'react';

import Form, { useForm } from 'antd/lib/form/Form';
import { useExpensesAllowance, useNewAllowanceMutation } from 'api';
import { useTranslate } from 'translations';
import { AllowanceFieldsFormName, CommonFieldName, ExpenseTypeName } from 'ui/components';
import { VALIDATE_MESSAGES } from 'utils/formValidation';
import { FieldData } from 'rc-field-form/lib/interface';
import { ExpensesType } from 'types';
import { Empty, Spin } from 'antd';
import { FullWidthSpin } from 'ui/elements/Spin';
import { useHandleSubmitStatus } from 'routes/Tasks';
import {
  AttachmentFile,
  attachmentsFieldsId,
} from 'routes/Tasks/components/pages/ServiceNewRequest/ServiceNewRequest';
import { submitAttachmentForm } from 'ui/publicFunction';
import { useHistory } from 'react-router-dom';
import { AllowanceFormFields } from './AllowanceFormFields';

interface PropsAllowanceExpense {
  adoid: string;
  formName?: string;
}
const description =
  'Raise this request if you need an allowance for health club, education or mobile.';

export const AllowanceFormComponent: FC<PropsAllowanceExpense> = ({ adoid, formName }) => {
  const { data: allowanceForm, isLoading, isError } = useExpensesAllowance(adoid);
  const { t } = useTranslate();
  const [form] = useForm();
  const [formFields, setFormFields] = useState<ExpensesType>();
  const [attachmentData, setAttachmentData] = useState<AttachmentFile[]>([]);
  const [expenseType, setExpenseType] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const history = useHistory();

  const mutation = useNewAllowanceMutation(adoid);

  const resetFields = useCallback(() => {
    form.resetFields([
      AllowanceFieldsFormName.amount,
      AllowanceFieldsFormName.description,
      AllowanceFieldsFormName.invoiceDate,
      AllowanceFieldsFormName.allowanceTypeValue,
      CommonFieldName.attachment,
    ]);
  }, [form]);

  const onFieldsChange = useCallback(
    (changedFields: FieldData[]) => {
      const [changeField] = changedFields;
      const { name, value } = changeField || ({} as FieldData);
      if (Array.isArray(name) && name.length && allowanceForm) {
        const [fieldName] = name;
        if (fieldName === 'allowanceType') {
          const formFields = allowanceForm.allowances?.filter(
            (allowanceType) => allowanceType.name === value
          );
          if (formFields) setFormFields(formFields[0]);
        }
        if (fieldName === 'allowanceTypeValue') {
          setExpenseType(+value);
        }
        if (fieldName === 'amount') {
          if (value) setAmount(value);
        }
      }
    },
    [allowanceForm]
  );

  const onSubmitFinish = useCallback(
    async (variables) => {
      const attachmentsForm: AttachmentFile[] = [];
      const currencyValue = {
        currency: formFields?.currency,
      };

      const formVariables = Object.assign(variables, currencyValue);

      await mutation.mutate(formVariables);

      const currentFormFieldsName = Object.keys(variables);

      const currentAttachmentId = currentFormFieldsName.filter((formVaribales: string) => {
        return attachmentsFieldsId.includes(formVaribales);
      });

      if (currentAttachmentId && variables) {
        currentAttachmentId.forEach((fieldId: string) => {
          const attachmentValue = variables[fieldId];
          if (attachmentValue) {
            return attachmentsForm.push({ [fieldId]: attachmentValue });
          }
        });
      }
      if (attachmentsForm) {
        setAttachmentData(attachmentsForm);
      }
    },
    [mutation, formFields?.currency]
  );

  useHandleSubmitStatus(t, mutation.isLoading, undefined, mutation.isSuccess);

  useEffect(() => {
    if (
      formFields &&
      formFields?.name === ExpenseTypeName.education &&
      formFields?.amountReadOnly &&
      expenseType
    ) {
      const calculatedAmount = formFields?.expensesAllowanceLimit?.limitAmount * expenseType;
      form.setFieldsValue({
        [AllowanceFieldsFormName.amount]: calculatedAmount,
      });
    }
  }, [form, formFields, expenseType]);

  useEffect(() => {
    if (
      formFields &&
      formFields.name === ExpenseTypeName.healthClub &&
      formFields?.expensesAllowanceLimit?.remainingBalance
    ) {
      const { remainingBalance } = formFields.expensesAllowanceLimit;
      form.setFieldsValue({
        [AllowanceFieldsFormName.remainingBalance]: remainingBalance > 0 ? remainingBalance : '',
      });
    }
  }, [form, formFields]);

  useEffect(() => {
    mutation.data &&
      submitAttachmentForm(mutation.isSuccess, mutation.data, history, undefined, attachmentData);
  }, [attachmentData, history, mutation.data, mutation.isSuccess]);

  return (
    <Fragment>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        validateMessages={VALIDATE_MESSAGES(t)}
        onFieldsChange={onFieldsChange}
        onFinish={onSubmitFinish}
      >
        {isError ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
        ) : isLoading ? (
          <FullWidthSpin />
        ) : (
          <Spin spinning={mutation.isLoading} delay={100}>
            <AllowanceFormFields
              t={t}
              allowanceOption={allowanceForm}
              isSelectedAllowance={formFields !== undefined}
              allowanceFields={formFields}
              amount={amount}
              resetFormFields={resetFields}
              form={form}
              description={
                allowanceForm?.processDescription ? allowanceForm.processDescription : description
              }
            />
          </Spin>
        )}
      </Form>
    </Fragment>
  );
};

export const AllowanceForm = memo(AllowanceFormComponent);

import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from 'antd';
import { useTranslate } from 'translations';
import { Filter } from 'ui';
import {
  FilterContextType,
  FilterItemProps,
  FilterOption,
} from 'ui/components/Filter/Filter.types';
import { useLeaseDistricts, useLeaseSitesFilterLookups } from 'api';
import { StyledFilterButton, StyledFilterButtonIcon } from '../../LeaseSites.styled';

const { Text } = Typography;

interface LeaseSitesFilterProps {
  setFilterValues: (values: FilterContextType['filterValues']) => void;
  filterValues: FilterContextType['filterValues'];
}

const leaseSitesFilterItems = {
  leaseStatus: 'leaseStatus',
  paymentStatus: 'paymentStatus',
  region: 'region',
  district: 'district',
  leaseEndDate: 'leaseEndDate',
};

export const LeaseSitesFilter: FC<LeaseSitesFilterProps> = ({ setFilterValues, filterValues }) => {
  const history = useHistory();
  const { t } = useTranslate();
  const [lookupsOptions, setLookupsOptions] = useState<{
    leaseStatus: FilterOption[];
    paymentStatus: FilterOption[];
    regionStatus: FilterOption[];
  }>({ leaseStatus: [], paymentStatus: [], regionStatus: [] });
  const [districtsOptions, setDistrictsOptions] = useState<FilterOption[]>([]);
  const { data: lookups, isLoading: isLookupsLoading } = useLeaseSitesFilterLookups();
  const { data: districts, isLoading: isDistrictsLoading } = useLeaseDistricts(
    filterValues['region'] as string
  );

  useEffect(() => {
    if (lookups) {
      const leaseStatusOptions: FilterOption[] = [];
      const paymentStatusOptions: FilterOption[] = [];
      const regionsOptions: FilterOption[] = [];
      lookups.leaseStatuses?.forEach((status) => {
        leaseStatusOptions.push({ text: status.value, value: status.key });
      });
      lookups.paymentStatuses?.forEach((status) => {
        paymentStatusOptions.push({ text: status.value, value: status.key });
      });
      lookups.regions?.forEach((region) => {
        regionsOptions.push({ text: region.value, value: region.id });
      });
      setLookupsOptions({
        leaseStatus: leaseStatusOptions,
        paymentStatus: paymentStatusOptions,
        regionStatus: regionsOptions,
      });
    }
  }, [lookups, setLookupsOptions]);

  useEffect(() => {
    if (districts?.length) {
      const districtsOptions: FilterOption[] = [];
      districts.forEach((district) => {
        districtsOptions.push({ text: district.label, value: district.value });
      });
      setDistrictsOptions(districtsOptions);
    }
  }, [districts, setDistrictsOptions]);

  const getFilterItems = useCallback(() => {
    const filterItems: FilterItemProps[] = [
      {
        type: 'select',
        label: t('lease.leaseSites.filter.leaseStatus'),
        name: leaseSitesFilterItems.leaseStatus,
        placeholder: t('lease.leaseSites.filter.leaseStatus.placeholder'),
        options: lookupsOptions.leaseStatus,
        disabled: !lookupsOptions.leaseStatus.length,
      },
      {
        type: 'select',
        label: t('lease.leaseSites.filter.paymentStatus'),
        name: leaseSitesFilterItems.paymentStatus,
        placeholder: t('lease.leaseSites.filter.paymentStatus.placeholder'),
        options: lookupsOptions.paymentStatus,
        disabled: !lookupsOptions.leaseStatus.length,
      },
      {
        type: 'select',
        label: t('lease.leaseSites.filter.region'),
        name: leaseSitesFilterItems.region,
        placeholder: t('lease.leaseSites.filter.region.placeholder'),
        options: lookupsOptions.regionStatus,
        disabled: !lookupsOptions.regionStatus.length,
      },
      {
        type: 'select',
        label: t('lease.leaseSites.filter.district'),
        name: leaseSitesFilterItems.district,
        placeholder: t('lease.leaseSites.filter.district.placeholder'),
        options: districtsOptions,
        disabled: !districtsOptions?.length || !filterValues['region'],
      },
      {
        type: 'datePicker',
        label: t('lease.leaseSites.filter.leaseEndDate'),
        name: leaseSitesFilterItems.leaseEndDate,
        placeholder: t('lease.leaseSites.filter.leaseEndDate.placeholder'),
      },
    ];
    return filterItems;
  }, [
    districtsOptions,
    filterValues,
    lookupsOptions.leaseStatus,
    lookupsOptions.paymentStatus,
    lookupsOptions.regionStatus,
    t,
  ]);

  return (
    <Filter
      items={getFilterItems()}
      isLoading={isLookupsLoading || isDistrictsLoading}
      t={t}
      history={history}
      onFilterCallback={setFilterValues}
      dateFormat={'YYYY-MM-DD'}
      enableQueryParams={false}
      initialFilterValues={filterValues}
      customTrigger={
        <StyledFilterButton type="link">
          <StyledFilterButtonIcon />
          <Text strong>{t('button.addFilter')}</Text>
        </StyledFilterButton>
      }
    />
  );
};

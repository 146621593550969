import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, ProfileData } from 'types';
import { ProfileState } from './profileTypes';

export const emptyProfileInitialState: ProfileState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetProfileStart = PayloadAction;
export type GetProfileSuccess = PayloadAction<ProfileData>;
export type GetProfileFail = PayloadAction<RequestCommonError>;

const profile = createSlice({
  name: 'profile',
  initialState: emptyProfileInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getProfileStart(state, _: GetProfileStart) {
      state.status = RequestStatus.pending;
    },
    getProfileSuccess(state, action: GetProfileSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getProfileFail(state, action: GetProfileFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const { getProfileStart, getProfileSuccess, getProfileFail } = profile.actions;
export default profile.reducer;

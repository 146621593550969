import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../reducers';

export const teamsPosts = (state: RootState): RootState['teamsPosts'] => state.teamsPosts;

export const getTeamsChannelMessagesData = createSelector(
  teamsPosts,
  ({ error, data, getTeamsChannelMessagesStatus, sendTeamsChannelMessageStatus }) => ({
    error,
    data: {
      ...data,
      channelMessages: data.channelMessages?.list,
      channelReplies: data.channelReplies,
      skipToken: data.channelMessages?.skipToken,
    },
    getTeamsChannelMessagesStatus,
    sendTeamsChannelMessageStatus,
  })
);

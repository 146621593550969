import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetGeneralInfoFail,
  GetGeneralInfoStart,
  GetGeneralInfoSuccess,
  getGeneralInfoStart,
  getGeneralInfoSuccess,
  getGeneralInfoFail,
} from './generalInfoSlice';
import { mapData } from './mapData';

export const getHierarchyEpic: Epic<
  GetGeneralInfoStart,
  GetGeneralInfoSuccess | GetGeneralInfoFail,
  RootState,
  typeof API
> = (action$, _, { generalInfo }) =>
  action$.pipe(
    filter(getGeneralInfoStart.match),
    exhaustMap(({ payload }) =>
      from(generalInfo.getGeneralInfo(payload)).pipe(
        map(({ data }) => getGeneralInfoSuccess(mapData(data))),
        catchError((error) => of(getGeneralInfoFail(error?.response?.data)))
      )
    )
  );

import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';

export const SelectTitle = styled(Text)`
  font-size: 14px;
`;

export const TaskListScroller = styled.div`
  min-height: 75vh;
  overflow-y: scroll;
`;

export const TaskListContainer = styled.div`
  min-height: 75vh;
  display: flex;
  flex-direction: column;
`;

export const SelectWrapper = styled.div`
  .ant-select-arrow svg {
    fill: black;
  }
  .ant-select-selector {
    width: 125px !important;
  }
`;

const {
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_AUTHORITY,
  REACT_APP_AUTH_REDIRECT_URI,
  REACT_APP_AUTH_SCOPE,
} = process.env;

export const authConfig = {
  auth: {
    clientId: REACT_APP_AUTH_CLIENT_ID as string, // Application (client) ID
    authority: REACT_APP_AUTH_AUTHORITY as string, // Directory (tenant) ID
    redirectUri: REACT_APP_AUTH_REDIRECT_URI as string,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: [REACT_APP_AUTH_SCOPE as string],
  state: '',
};

export interface IDTokenClaims {
  aud?: string;
  iss?: string;
  iat?: number;
  nbf?: number;
  exp?: number;
  aio?: string;
  email?: string;
  name?: string;
  nonce?: string;
  oid?: string;
  preferred_username?: string;
  rh?: string;
  roles?: string[];
  sub?: string;
  tid?: string;
  uti?: string;
  ver?: string;
}

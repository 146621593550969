import { Core, ATTACHMENT_STANDARD } from 'api';
import { AttachmentStandard } from 'types';

interface GetAttachmentStandard {
  (): Promise<{ data: AttachmentStandard[] }>;
}

class Attachment extends Core {
  getAttachmentStandard: GetAttachmentStandard = async () => this.get(ATTACHMENT_STANDARD());
}

export const attachment = new Attachment();

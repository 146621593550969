import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { camundaUsersDataSelector, getCamundaUserStart, useSelector } from 'store';
import { RequestStatus } from 'types';

export const useUsersInfo = (userIds: string[] = []) => {
  const dispatch = useDispatch();
  const camundaUsers = useSelector(camundaUsersDataSelector);

  const usersIds = useMemo(
    () =>
      userIds
        .filter((id, index, array) => array.indexOf(id) === index)
        .filter(
          (id) =>
            !(
              camundaUsers[id]?.status === RequestStatus.resolved ||
              camundaUsers[id]?.status === RequestStatus.pending ||
              camundaUsers[id]?.status === RequestStatus.rejected
            )
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userIds]
  );

  const isUsersInfoLoading = useMemo(
    () => usersIds.some((user) => camundaUsers[user]?.status === RequestStatus.pending),
    [camundaUsers, usersIds]
  );

  useEffect(() => {
    usersIds.length &&
      dispatch(
        getCamundaUserStart({
          params: {
            idIn: usersIds.join(','),
          },
        })
      );
  }, [dispatch, usersIds]);

  return { isUsersInfoLoading };
};

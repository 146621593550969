import { Col, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import React from 'react';
import { span } from 'ui/elements';
import { StyledAttendanceName, StyledAttendanceTime } from '../TeamMember';

interface Props {
  attendanceName: string;
  attendanceTime: string | null;
  inOffice?: boolean;
}

const { span24 } = span;
const GUTTER_10: [Gutter, Gutter] = [0, 10];
const AttendanceDetailsComponent: React.FC<Props> = ({
  attendanceName,
  attendanceTime,
  inOffice,
}) => {
  return (
    <React.Fragment>
      <Row gutter={GUTTER_10}>
        <Col span={span24}>
          <StyledAttendanceName ellipsis>{attendanceName}</StyledAttendanceName>
        </Col>
        <Col span={span24}>
          {attendanceTime && (
            <StyledAttendanceTime $isOnOffice={inOffice} ellipsis>
              {attendanceTime}
            </StyledAttendanceTime>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export const AttendanceDetail = React.memo(AttendanceDetailsComponent);

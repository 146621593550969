import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import {
  RequestCommonError,
  RequestStatus,
  ChatMessagesData,
  ChatMessageData,
  ChannelRepliesData,
} from 'types';
import { TeamsPostsState } from './teamsPostsTypes';

export const emptyTeamsPostsInitialState: TeamsPostsState = {
  getTeamsChannelMessagesStatus: RequestStatus.idle,
  sendTeamsChannelMessageStatus: RequestStatus.idle,
  data: {},
  error: null,
};

export type GetTeamsChannelMessagesStart = PayloadAction<{ teamId: string; channelId: string }>;
export type GetTeamsChannelMessagesSuccess = PayloadAction<ChatMessagesData>;
export type GetTeamsChannelMessagesFail = PayloadAction<RequestCommonError>;
export type SendTeamsChannelMessageStart = PayloadAction<{
  teamId: string;
  channelId: string;
  values: { body: { content: string } };
}>;
export type SendTeamsChannelMessageSuccess = PayloadAction<ChatMessageData>;
export type SendTeamsChannelMessageFail = PayloadAction<RequestCommonError>;
export type GetTeamsChannelRepliesStart = PayloadAction<{
  teamId: string;
  channelId: string;
  messageId: string;
  skipToken?: string;
}>;
export type GetTeamsChannelRepliesSuccess = PayloadAction<{
  messageId: string;
  data: ChannelRepliesData;
}>;
export type GetTeamsChannelRepliesFail = PayloadAction<{
  messageId: string;
  data: RequestCommonError;
}>;
export type SendTeamsChannelReplyStart = PayloadAction<{
  teamId: string;
  channelId: string;
  messageId: string;
  values: { body: { content: string } };
}>;
export type SendTeamsChannelReplySuccess = PayloadAction<{
  messageId: string;
  data: ChatMessageData;
}>;
export type SendTeamsChannelReplyFail = PayloadAction<{
  messageId: string;
  data: RequestCommonError;
}>;

const teamsPosts = createSlice({
  name: 'teamsPosts',
  initialState: emptyTeamsPostsInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTeamsChannelMessagesStart(state, _: GetTeamsChannelMessagesStart) {
      state.getTeamsChannelMessagesStatus = RequestStatus.pending;
      state.data.channelMessages = undefined;
      state.data.channelReplies = undefined;
    },
    getTeamsChannelMessagesSuccess(state, action: GetTeamsChannelMessagesSuccess) {
      state.getTeamsChannelMessagesStatus = RequestStatus.resolved;
      state.data.channelMessages = action.payload;
    },
    getTeamsChannelMessagesFail(state, action: GetTeamsChannelMessagesFail) {
      state.getTeamsChannelMessagesStatus = RequestStatus.rejected;
      state.error = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendTeamsChannelMessageStart(state, _: SendTeamsChannelMessageStart) {
      state.sendTeamsChannelMessageStatus = RequestStatus.pending;
    },
    sendTeamsChannelMessageSuccess(state, action: SendTeamsChannelMessageSuccess) {
      state.sendTeamsChannelMessageStatus = RequestStatus.resolved;

      if (!state.data.channelMessages) {
        return;
      }

      const lastItem = state.data.channelMessages.list[state.data.channelMessages.list.length - 1];
      lastItem.messages = [...lastItem.messages, action.payload];
    },
    sendTeamsChannelMessageFail(state, action: SendTeamsChannelMessageFail) {
      state.sendTeamsChannelMessageStatus = RequestStatus.rejected;
      state.error = action.payload;
    },
    getTeamsChannelRepliesStart(state, action: GetTeamsChannelRepliesStart) {
      state.data.channelReplies = {
        ...state.data.channelReplies,
        [action.payload.messageId]: {
          status: RequestStatus.pending,
        },
      };
    },
    getTeamsChannelRepliesSuccess(state, action: GetTeamsChannelRepliesSuccess) {
      state.data.channelReplies = {
        ...state.data.channelReplies,
        [action.payload.messageId]: {
          status: RequestStatus.resolved,
          data: {
            list: action.payload.data.list?.sort((a, b) =>
              moment(a.createdDateTime).diff(b.createdDateTime)
            ),
            skipToken: action.payload.data.skipToken,
          },
        },
      };
    },
    getTeamsChannelRepliesFail(state, action: GetTeamsChannelRepliesFail) {
      state.error = action.payload.data;
      state.data.channelReplies = {
        ...state.data.channelReplies,
        [action.payload.messageId]: {
          status: RequestStatus.rejected,
        },
      };
    },
    updateTeamsChannelRepliesStart(state, action: GetTeamsChannelRepliesStart) {
      if (!state.data.channelReplies) {
        state.data.channelReplies = {};
      }

      state.data.channelReplies[action.payload.messageId].status = RequestStatus.pending;
    },
    updateTeamsChannelRepliesSuccess(state, action: GetTeamsChannelRepliesSuccess) {
      if (!state.data.channelReplies) {
        state.data.channelReplies = {};
      }

      state.data.channelReplies[action.payload.messageId].status = RequestStatus.resolved;

      if (!action.payload.data.list) {
        return;
      }

      const messageReplies = state.data.channelReplies[action.payload.messageId];

      if (!messageReplies.data || !messageReplies.data.list) {
        messageReplies.data = action.payload.data;
        return;
      }

      const newList = [...action.payload.data.list].sort((a, b) =>
        moment(a.createdDateTime).diff(b.createdDateTime)
      );
      const oldList = [...messageReplies.data.list].sort((a, b) =>
        moment(a.createdDateTime).diff(b.createdDateTime)
      );

      const newMessageReplies = {
        skipToken: action.payload.data.skipToken,
        list: [...newList, ...oldList],
      };

      state.data.channelReplies[action.payload.messageId].data = newMessageReplies;
    },
    updateTeamsChannelRepliesFail(state, action: GetTeamsChannelRepliesFail) {
      state.error = action.payload.data;
      state.data.channelReplies = {
        ...state.data.channelReplies,
        [action.payload.messageId]: {
          status: RequestStatus.rejected,
        },
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendTeamsChannelReplyStart(state, action: SendTeamsChannelReplyStart) {
      if (!state.data.channelReplies) {
        state.data.channelReplies = {};
      }

      state.data.channelReplies[action.payload.messageId] = {
        ...state.data.channelReplies[action.payload.messageId],
        status: RequestStatus.pending,
      };
    },
    sendTeamsChannelReplySuccess(state, action: SendTeamsChannelReplySuccess) {
      if (!state.data.channelReplies) {
        return;
      }

      const replyObj = state.data.channelReplies[action.payload.messageId];
      replyObj.status = RequestStatus.resolved;

      if (!replyObj.data) {
        replyObj.data = {};
      }

      if (!replyObj.data.list) {
        replyObj.data.list = [];
      }

      replyObj.data.list.push(action.payload.data);
    },
    sendTeamsChannelReplyFail(state, action: SendTeamsChannelReplyFail) {
      state.error = action.payload.data;
      if (!state.data.channelReplies) {
        return;
      }

      const replyObj = state.data.channelReplies[action.payload.messageId];
      replyObj.status = RequestStatus.rejected;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateTeamsChannelMessagesStart(state, _: GetTeamsChannelMessagesStart) {
      state.getTeamsChannelMessagesStatus = RequestStatus.pending;
    },
    updateTeamsChannelMessagesSuccess(state, action: GetTeamsChannelMessagesSuccess) {
      state.getTeamsChannelMessagesStatus = RequestStatus.resolved;

      if (!state.data.channelMessages) {
        state.data.channelMessages = action.payload;
        return;
      }

      const messagesNotInChat = action.payload.list.filter(
        (message) =>
          !(state.data.channelMessages as ChatMessagesData).list.some(
            (msg) => msg.date === message.date
          )
      );

      const messagesInChat = action.payload.list.filter((message) =>
        (state.data.channelMessages as ChatMessagesData).list.some(
          (msg) => msg.date === message.date
        )
      );

      const updatedStateMessages = state.data.channelMessages.list.map((message) => {
        const updMsg = messagesInChat.find((msg) => msg.date === message.date);

        const msg = updMsg ? [...updMsg.messages, ...message.messages] : message.messages;

        return {
          ...message,
          messages: msg.sort((a, b) => moment(a.createdDateTime).diff(b.createdDateTime)),
        };
      });

      const newChannelMessages = {
        skipToken: action.payload.skipToken,
        list: [...messagesNotInChat, ...updatedStateMessages].sort((a, b) =>
          moment(a.date).diff(b.date)
        ),
      };

      state.data.channelMessages = newChannelMessages;
    },
    updateTeamsChannelMessagesFail(state, action: GetTeamsChannelMessagesFail) {
      state.getTeamsChannelMessagesStatus = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getTeamsChannelMessagesStart,
  getTeamsChannelMessagesSuccess,
  getTeamsChannelMessagesFail,
  sendTeamsChannelMessageStart,
  sendTeamsChannelMessageSuccess,
  sendTeamsChannelMessageFail,
  getTeamsChannelRepliesStart,
  getTeamsChannelRepliesSuccess,
  getTeamsChannelRepliesFail,
  updateTeamsChannelRepliesStart,
  updateTeamsChannelRepliesSuccess,
  updateTeamsChannelRepliesFail,
  sendTeamsChannelReplyStart,
  sendTeamsChannelReplySuccess,
  sendTeamsChannelReplyFail,
  updateTeamsChannelMessagesStart,
  updateTeamsChannelMessagesSuccess,
  updateTeamsChannelMessagesFail,
} = teamsPosts.actions;
export default teamsPosts.reducer;

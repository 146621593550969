export const SITE_ACQUISITION_TASK_SUBMIT_FORM_GROUPS = {
  candidatesDelegate: 'candidatesDelegate',
  attachments: 'attachments',
  candidatesLandlord: 'candidatesLandlord',
  candidatesProperty: 'candidatesProperty',
  fieldRunnerCandidates: 'fieldRunnerCandidates',
};

const {
  candidatesDelegate,
  attachments,
  candidatesLandlord,
  candidatesProperty,
} = SITE_ACQUISITION_TASK_SUBMIT_FORM_GROUPS;

export const SITE_ACQUISITION_TASK_SUBMIT_FORM = {
  candidateId: 'id',
  propertyTimestamp: 'propertyTimestamp',
  siteNeighborhood: 'siteNeighbourhood',
  leasedSpaceType: ['leasedSpaceTypes', 'id'],
  siteType: ['siteTypes', 'id'],
  distanceFromNominal: 'distanceFromNominal',
  latitude: 'latitude',
  longitude: 'longitude',
  powerSourceType: ['powerSourceTypes', 'id'],
  powerSourceFromOwner: 'powerSourceFromOwner',
  ownerApproval: ['ownerApproval', 'id'],
  accessRoad: ['accessRoad', 'id'],
  siteAreaRooftop: 'siteAreaRooftop',
  siteAreaGreenfield: 'siteAreaGreenfield',
  powerSourceDistanceFromSubStation: ['powerSourceDistance', 'id'],
  nearestOperatorTower: 'nearestOperatorTower',
  distanceFromNearestOperatorTower: 'distanceFromNearestTower',
  candidateCompliedWithMomraRegulations: ['momraRegulations', 'id'],
  ownerDeedAndIdDoc: ['ownerDeed', 'id'],
  penthouseAvailable: ['penthouseAvailable', 'id'],
  obstructionExist: ['obstructionExist', 'id'],
  obstructionPhoto: [attachments, 'obstructionPhoto'],
  buildingHeightWithoutParapet: 'buildingHeightWithoutParapet',
  siteAddress: 'siteAddress',
  region: ['region', 'id'],
  district: ['district', 'id'],
  city: ['city', 'id'],
  siteStreet: 'siteStreet',
  distanceFromRoad: 'distanceFromRoad',
  leaseArea: 'leaseArea',
  nearestElectricityPointDistance: 'nearestElectricityPoint',
  landlordPrice: 'landlordPrice',

  civilRanking: [candidatesProperty, 'civilRanking'],
  penthouseHeight: [candidatesProperty, 'pentHouseHeight'],
  scecoDistance: [candidatesProperty, 'scecoDistance'],
  numberOfBuildings: [candidatesProperty, 'numberOfBuildings'],
  totalRealEstateSpace: [candidatesProperty, 'totalRealEstateSpace'],
  buildingHeight: [candidatesProperty, 'buildingHeight'],
  remarks: [candidatesProperty, 'remarks'],

  propertyOwnershipDocument: [attachments, 'landlordPropertyOwnerShip'],
  propertyPictures: [attachments, 'proPicture'],
  mapPhoto: [attachments, 'map'],
  photoOfFrontView: [attachments, 'photoFront'],
  layout: [attachments, 'layout'],
  otherPropertyDocuments: [attachments, 'proOtherDocument'],
  landDeedIsAvailable: [candidatesProperty, 'landDeedApplicable', 'id'],
  landDeed: [attachments, 'landDeed'],

  isrDocument: [attachments, 'isrDocument'],
  coiDisclosureForm: [attachments, 'coiDocument'],
  mouDocument: [attachments, 'mouDocument'],

  landlordId: [candidatesLandlord, 'landlordId'],
  candidateEligibleForSite: [candidatesLandlord, 'candidateEligibleForSite', 'id'],
  justificationForIneligibleCandidate: [candidatesLandlord, 'justificationForIneligibleCandidate'],
  landlordNationalIdNumber: [candidatesLandlord, 'landlordNationalIdNumber'],
  commercialRegistrationNumber: [candidatesLandlord, 'commercialRegistrationNumber'],
  landlordType: [candidatesLandlord, 'landlordTypes', 'id'],
  landlordGovernmentType: [candidatesLandlord, 'landlordGovernmentTypes', 'id'],
  landlordName: [candidatesLandlord, 'landlordName'],
  landlordCountry: [candidatesLandlord, 'landlordCountries', 'id'],
  taxOrganizationType: [candidatesLandlord, 'taxOrganizationTypes', 'id'],
  landlordRegion: [candidatesLandlord, 'landlordRegion', 'id'],
  landlordDistrict: [candidatesLandlord, 'landlordDistrict', 'id'],
  landlordCity: [candidatesLandlord, 'landlordCity', 'id'],
  nationalPoBoxNumber: [candidatesLandlord, 'nationalPoBoxNumber'],
  landlordIsVatApplicable: [candidatesLandlord, 'landlordIsVatApplicable', 'id'],
  vatNumber: [candidatesLandlord, 'vatNumber'],

  landlordStreet: [candidatesLandlord, 'landlordStreet'],
  countryCode: [candidatesLandlord, 'countryCode'],
  landlordPhoneCountryCode: [candidatesLandlord, 'landlordCountryPhoneCode'],
  landlordTelephone: [candidatesLandlord, 'landlordTelephone'],
  landlordEmail: [candidatesLandlord, 'landlordEmail'],
  justificationForSpecialCase: [candidatesLandlord, 'justificationForSpecialCase'],
  nationalityInEnglish: [candidatesLandlord, 'nationalityEnglish'],
  nationalityCode: [candidatesLandlord, 'nationalityCode'],
  specialAccessRequestRequired: [candidatesLandlord, 'specialAccessRequestRequired'],
  dateOfBirthInGregorian: [candidatesLandlord, 'dateOfBirthInGregorian'],
  dateOfBirthInHijri: [candidatesLandlord, 'dateOfBirthInHijri'],
  familyNameInEnglish: [candidatesLandlord, 'familyName'],
  usedLanguage: [candidatesLandlord, 'usedLanguage'],
  firstNameInEnglish: [candidatesLandlord, 'firstNameInEnglish'],
  fatherNameInEnglish: [candidatesLandlord, 'fatherNameInEnglish'],
  grandfatherNameInEnglish: [candidatesLandlord, 'grandfatherNameInEnglish'],
  issuanceDateInGregorian: [candidatesLandlord, 'issuanceDateInGregorian'],
  issuanceDateInHijri: [candidatesLandlord, 'issuanceDateInHijri'],
  issuancePlaceInEnglish: [candidatesLandlord, 'issuancePlaceInEnglish'],
  iqamaExpirationDateInGregorian: [candidatesLandlord, 'iqamaExpirationDateInGregorian'],
  iqamaExpirationDateInHijri: [candidatesLandlord, 'iqamaExpirationDateInHijri'],
  nationalIdExpirationDateInGregorian: [candidatesLandlord, 'nationalIdExpirationDateGregorian'],
  nationalIdExpirationDateInHijri: [candidatesLandlord, 'nationalIdExpirationDateHijri'],
  idVersionNumber: [candidatesLandlord, 'idVersionNumber'],
  gender: [candidatesLandlord, 'gender', 'id'],
  commercialRegistrationExpiryDateInGregorian: [
    candidatesLandlord,
    'commercialRegistrationExpiryDate',
  ],
  jobTitle: [candidatesLandlord, 'jobTitle'],
  buildingNumber: [candidatesLandlord, 'buildingNumber'],
  companyName: [candidatesLandlord, 'company'],
  secondaryNumbers: [candidatesLandlord, 'secondaryNumbers'],
  unitNumber: [candidatesLandlord, 'unitNumber'],
  postalCode: [candidatesLandlord, 'postalCode'],

  profilePicture: [attachments, 'landlordProfile'],
  vatCertificate: [attachments, 'landlordVar'],
  idVerificationPicture: [attachments, 'landlordIDPicture'],
  commercialRegistrationDocument: [attachments, 'landlordCommercialDocument'],
  governmentRegistrationCopy: [attachments, 'landlordGovernmentRegis'],
  propertyOwnership: [attachments, 'proOwnerDocument'],
  authorizedSignatory: [attachments, 'landlordAuthorizedSignatory'],
  deedListOfHeirs: [attachments, 'landlordDeedListHeirs'],
  endowmentCaretaker: [attachments, 'landlordEndowmentCaretaker'],
  electricityAcknowledgment: [attachments, 'landlordElectricityAcknowledgment'],
  evaluationReport: [attachments, 'landlordEvaluationReport'],
  otherLeaseDocuments: [attachments, 'landlordOtherLeaseDocument'],
  sadadDocument: [attachments, 'landlordSADADDocument'],
  nationalAddressDocument: [attachments, 'landlordNationalAddressDocument'],
  powerOfAttorneyLetter: [attachments, 'landlordPowerAttorney'],
  inheritanceDocument: [attachments, 'landlordInheritanceDocument'],
  officialPriceQuotation: [attachments, 'landlordOfficialPriceQuotation'],
  noObjectionLetter: [attachments, 'landlordNOC'],
  proofOfEligibilityToSignForTheTent: [attachments, 'landlordProofElegibility'],
  investmentContract: [attachments, 'landlordInvestmentContract'],
  contract: [attachments, 'landlordContract'],

  delegateId: [candidatesDelegate, 'delegateId'],
  delegateNationalIdNumber: [candidatesDelegate, 'delegateNationalId'],
  delegateExpiryDate: [candidatesDelegate, 'delegateExpiryDate'],
  delegateRoles: [candidatesDelegate, 'delegateRoles'],
  delegateName: [candidatesDelegate, 'delegateName'],
  delegateCommercialRegistrationNumber: [candidatesDelegate, 'delegateCommercialRegistration'],
  delegateCountry: [candidatesDelegate, 'delegateCountry', 'id'],
  delegateRegion: [candidatesDelegate, 'delegateRegion', 'id'],
  delegateDistrict: [candidatesDelegate, 'delegateDistrict', 'id'],
  delegateCity: [candidatesDelegate, 'delegateCity', 'id'],
  delegateStartDate: [candidatesDelegate, 'delegateStartDate'],
  delegateIsVatApplicable: [candidatesDelegate, 'delegateIsVatApplicable', 'id'],
  delegationLetter: [attachments, 'landlordDelegationLetter'],

  paymentMethod: ['candidatesPayment', 'paymentMethods', 'id'],
  accountNumber: ['candidatesPayment', 'accountNumber'],
  currencyCode: ['candidatesPayment', 'currencyCode'],
  bankName: ['candidatesPayment', 'bank', 'id'],
  bankBranchName: ['candidatesPayment', 'bankBranch', 'id'],
  accountSadadDocument: [attachments, 'accountSADADDocument'],
  feedback: 'feedback',
};

export const INITIAL_VALUES = {
  // fieldRunnerCandidates: [
  //   {
  //     candidatesPayment: {
  //       paymentMethods: {
  //         id: '1',
  //       },
  //       accountNumber: '1',
  //       currencyCode: '1',
  //       bank: {
  //         id: '1',
  //       },
  //       bankBranch: {
  //         id: '1',
  //       },
  //     },
  //     candidatesDelegate: {
  //       delegateNationalId: 12,
  //       delegateRoles: '12',
  //       delegateName: '12',
  //       delegateCommercialRegistration: '12',
  //       delegateCountry: {
  //         id: '12',
  //       },
  //       delegateRegion: {
  //         id: '12',
  //       },
  //       delegateDistrict: {
  //         id: '12',
  //       },
  //       delegateCity: {
  //         id: '1',
  //       },
  //       delegateIsVatApplicable: {
  //         id: 'YES',
  //       },
  //     },
  //     candidatesLandlord: {
  //       landlordStreet: '1',
  //       landlordTelephone: 1,
  //       landlordEmail: 'email',
  //       justificationForSpecialCase: 'Justification',
  //       nationalityEnglish: 'Nationality',
  //       nationalityCode: 'Nationality code',
  //       specialAccessRequestRequired: 'access',
  //       familyName: 'Family',
  //       usedLanguage: 'language',
  //       firstNameInEnglish: 'First name',
  //       fatherNameInEnglish: 'Father',
  //       grandfatherNameInEnglish: 'Grandfather',
  //       issuancePlaceInEnglish: 'Issuance',
  //       idVersionNumber: 123,
  //       gender: {
  //         id: 'FEMALE',
  //       },
  //       jobTitle: 'Job title',
  //       buildingNumber: 'Building number',
  //       company: 'Company name',
  //       secondaryNumbers: 123123,
  //       unitNumber: 123,
  //       postalCode: 123,
  //       candidateEligibleForSite: {
  //         id: 'YES',
  //       },
  //       justificationForIneligibleCandidate: '1',
  //       landlordNationalIdNumber: '11',
  //       commercialRegistrationNumber: '11',
  //       landlordTypes: {
  //         id: '1',
  //       },
  //       landlordGovernmentTypes: {
  //         id: '1',
  //       },
  //       landlordName: '1',
  //       landlordCountries: {
  //         id: '1',
  //       },
  //       taxOrganizationTypes: {
  //         id: '1',
  //       },
  //       landlordRegion: {
  //         id: '1',
  //       },
  //       landlordDistrict: { id: '15' },
  //       landlordCity: {
  //         id: '237',
  //       },
  //       nationalPoBoxNumber: '11',
  //       landlordIsVatApplicable: {
  //         id: 'YES',
  //       },
  //       vatNumber: '11111',
  //     },
  //     siteNeighbourhood: '1',
  //     leasedSpaceTypes: {
  //       id: '1',
  //     },
  //     siteType: {
  //       id: '3',
  //     },
  //     distanceFromNominal: 1,
  //     powerSourceTypes: {
  //       id: '1',
  //     },
  //     powerSourceFromOwner: '1',
  //     ownerApproval: {
  //       id: 'YES',
  //     },
  //     accessRoad: {
  //       id: '1',
  //     },
  //     siteAreaRooftop: '1',
  //     siteAreaGreenfield: '1',
  //     powerSourceDistance: {
  //       id: 'YES',
  //     },
  //     nearestOperatorTower: '1',
  //     distanceFromNearestTower: '1',
  //     momraRegulations: {
  //       id: 'YES',
  //     },
  //     penthouseAvailable: {
  //       id: 'YES',
  //     },
  //     obstructionExist: {
  //       id: 'YES',
  //     },
  //     buildingHeightWithoutParapet: 1,
  //     siteAddress: '1',
  //     region: {
  //       id: '1',
  //     },
  //     district: {
  //       id: '15',
  //     },
  //     city: {
  //       id: '237',
  //     },
  //     siteStreet: '1',
  //     distanceFromRoad: '1',
  //     leaseArea: '1',
  //     nearestElectricityPoint: '1',
  //     landlordPrice: '1',
  //     candidatesProperty: {
  //       civilRanking: 1,
  //       pentHouseHeight: 1,
  //       scecoDistance: '1',
  //       numberOfBuildings: 1,
  //       totalRealEstateSpace: 1,
  //       buildingHeight: 1,
  //       landDeedApplicable: { id: 'YES' },
  //     },
  //     ownerDeed: { id: 'YES' },
  //   },
  // ],
};

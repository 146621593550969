import React, { FC } from 'react';
import { UserPopover, TeamsUserAvatar } from 'ui/components';
import { Link } from 'react-router-dom';
import { StyledOHNode, StyledOHTitle, StyledOHName } from './OrganizationalHierarchy.styled';

interface Props {
  id?: string;
  displayName?: string;
  title?: string;
  email?: string;
}

export const OrganizationalHierarchyEmployeeCard: FC<Props> = ({
  id,
  displayName,
  title,
  email,
}) => {
  return (
    <StyledOHNode>
      <UserPopover currentID={id}>
        <TeamsUserAvatar email={email} id={id} name={displayName} statusSize={12} size={50} />
      </UserPopover>
      <Link to={`/user-profile/${email}`}>
        <StyledOHName>{displayName}</StyledOHName>
      </Link>
      <StyledOHTitle>{title}</StyledOHTitle>
    </StyledOHNode>
  );
};

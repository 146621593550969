import styled from 'styled-components';
import { mediumAndUp } from 'ui/elements';

export const ChatContainer = styled.div`
  margin: 24px;
  flex: 1;

  ${mediumAndUp`
    margin: 12px 65px;
  `}
`;

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChatScrollbar = styled.div`
  height: calc(100vh - 195px);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ChatHolder = styled.div`
  height: 100%;
`;

import React, { FC, useState } from 'react';

import { OneDriveContext } from './OneDriveContext';

export const OneDriveContextProvider: FC = ({ children }) => {
  const [currentFolderLink, setCurrentFolderLink] = useState<string>('');

  return (
    <OneDriveContext.Provider value={{ currentFolderLink, setCurrentFolderLink }}>
      {children}
    </OneDriveContext.Provider>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, OtherUserProfileDate } from 'types';
import { UserProfileState } from './userProfileTypes';

export const emptyUserProfileInitialState: UserProfileState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetUserProfileStart = PayloadAction<{ email: string }>;
export type GetUserProfileSuccess = PayloadAction<OtherUserProfileDate>;
export type GetUserProfileFail = PayloadAction<RequestCommonError>;
export type GetUserProfileRest = PayloadAction;

const userProfile = createSlice({
  name: 'userProfile',
  initialState: emptyUserProfileInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getUserProfileStart(state, _: GetUserProfileStart) {
      state.status = RequestStatus.pending;
    },
    getUserProfileSuccess(state, action: GetUserProfileSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getUserProfileFail(state, action: GetUserProfileFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getUserProfileReset(state, _: GetUserProfileRest) {
      state.status = RequestStatus.idle;
      state.error = null;
      state.data = undefined;
    },
  },
});

export const {
  getUserProfileStart,
  getUserProfileSuccess,
  getUserProfileFail,
  getUserProfileReset,
} = userProfile.actions;
export default userProfile.reducer;

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Switch, Route } from 'react-router-dom';
import qs from 'query-string';

import { AppLayout } from 'layouts';
import { Header } from 'app';
import { TitleH1 } from 'ui';
import { TType, withTranslate } from 'translations';
import { profileData as profileDataSelector } from 'store';
import { Col, Row } from 'antd';
import { LeavesPageRoutes, PagesUrls } from 'namespace';
import { ProfileData } from 'types';
import { StyledTab, TabPaneContent } from './Calendar.styled';
import { Leaves } from './page/Leave/Leaves';
import { NewRequest } from './page/NewRequest';
import { MyTeamLeaves } from './page/MyTeamLeaves';

const { TabPane } = StyledTab;
interface Props {
  t: TType;
}

export const CalendarComponent: FC<Props> = ({ t }) => {
  const history = useHistory();
  const { data } = useSelector(profileDataSelector);
  const profileData = data as ProfileData;
  const [isManager, setIsManager] = useState<boolean>(false);

  const search = useMemo(() => qs.parse(history.location.search) as { tab: string | undefined }, [
    history.location,
  ]);

  useEffect(() => {
    if (profileData) {
      setIsManager(profileData.isManager);
    }
  }, [profileData]);

  const CalendarTab = () => (
    <AppLayout displayFooter={false}>
      <Header>
        <Row align="middle">
          <Col>
            <TitleH1>{t('menu.leaves')}</TitleH1>
          </Col>
        </Row>
      </Header>
      <StyledTab activeKey={search.tab} className="layout-main">
        <TabPane tab={t('calendar.tab.myLeaves')} key="1">
          <TabPaneContent>
            <Leaves profileData={profileData} />
          </TabPaneContent>
        </TabPane>
        {isManager && profileData.hcmId && (
          <TabPane tab={t('calendar.tab.myTeamLeave')} key="2">
            <TabPaneContent>
              <MyTeamLeaves hcmPersonId={profileData.hcmId} />
            </TabPaneContent>
          </TabPane>
        )}
      </StyledTab>
    </AppLayout>
  );

  return (
    <Switch>
      <Route
        path={`${PagesUrls.Leaves}${LeavesPageRoutes.NewLeaveRequest}`}
        component={NewRequest}
      />
      <Route path={PagesUrls.Leaves} component={CalendarTab} />
    </Switch>
  );
};

export const Calendar = withTranslate(CalendarComponent);

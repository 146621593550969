import { Col, Typography } from 'antd';
import styled from 'styled-components';
import { colors, typography } from 'ui/elements';

export const AnnouncementTitle = styled(Typography.Text)`
  color: ${colors.primaryColor};
  font-size: ${typography.size.kilo};
  width: 100%;
`;

export const AnnouncementCreate = styled(Typography.Text)`
  color: ${colors.lightGrey};
  font-size: ${typography.size.uno};
  font-weight: ${typography.weight.bold};
  width: 100%;
`;

export const AnnouncementAvatarWrapper = styled(Col)`
  margin-right: 12px;
`;

export const ViewAllAnnouncements = styled(Typography.Text)`
  font-size: ${typography.size.kilo};
  font-weight: ${typography.weight.bold};
  color: ${colors.primaryColor};
`;

import React, { FC, memo, useCallback, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { TType } from 'translations';
import { PlusCircleIcon } from 'ui/components/Filter/Filter.styled';
import { PageHeader } from 'routes/Calendar/page/Leave/Leaves.styled';
import { FilterContextType } from 'ui/components/Filter/Filter.types';
import { UserTableUserInfo } from 'types';
import { UserDrawer } from '../../components';
import { StyledUsersContent } from './Users.styled';
import { UsersTable } from './UsersTable';

const { Text } = Typography;

interface Props {
  t: TType;
}

export const UsersComponent: FC<Props> = ({ t }) => {
  const [filterValues, setFilterValues] = useState<FilterContextType['filterValues']>({});
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isUserDrawerVisible, setIsUserDrawVisible] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<UserTableUserInfo | undefined>();

  const setUsersFilter = useCallback((values) => {
    setCurrentPage(0);
    setFilterValues(values);
  }, []);

  const onUserSelect = useCallback((user: UserTableUserInfo) => {
    setIsUserDrawVisible(true);
    setSelectedUser(user);
  }, []);

  const onDrawerClose = useCallback(() => {
    setIsUserDrawVisible(false);
    setSelectedUser(undefined);
  }, []);

  return (
    <>
      <PageHeader size="small">
        <Row justify="start" align="middle">
          <Col>
            <Button type={'link'}>
              <PlusCircleIcon onClick={() => setUsersFilter({})} />
              <Text strong>{t('button.addFilter')}</Text>
            </Button>
          </Col>
        </Row>
      </PageHeader>
      <StyledUsersContent>
        <UsersTable
          t={t}
          filterValues={filterValues}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          onUserClick={onUserSelect}
        />
      </StyledUsersContent>
      <UserDrawer
        isVisible={isUserDrawerVisible}
        onClose={onDrawerClose}
        selectedUser={selectedUser}
      />
    </>
  );
};

export const Users = memo(UsersComponent);

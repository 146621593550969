import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG } from '../../constants';
import { userInfoCard } from '../UserInfoCard';

export const useGetUserInfoCard = (type?: string | null, value?: string | null) => {
  const userData: { params: unknown } | undefined =
    type && value ? { params: { [type]: value } } : undefined;
  return useQuery({
    queryKey: ['user_info_card', userData],
    queryFn: async () => {
      const { data } = await userInfoCard.getUserInfoCard(userData);
      return data;
    },
    enabled: !!type && !!value,
    ...PERSIST_QUERY_CONFIG,
  });
};

import styled from 'styled-components';
import { Button, Card, Table } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { colors } from 'ui/elements';
import { FileOutlined, FolderOutlined } from '@ant-design/icons';
import { getRandomColorById } from 'ui';

export const OneDriveWrapper = styled.div`
  margin: 0 24px 0;
`;

export const OneDriveButton = styled(Button)`
  padding: 0;
`;

export const OneDiveSubHeader = styled(Card)`
  border-bottom: 1px solid ${colors.borderGreySecond};
  .ant-card-body {
    padding: 8px 24px;
  }
`;

export const OneDriveDragger = styled(Dragger)`
  margin-top: 24px;

  &.ant-upload-drag {
    border: 2px dashed ${colors.borderGreySecond};
    background: none;
  }
`;

export const FolderIconStyled = styled(FolderOutlined)`
  font-size: 16px;
`;

export const FileIconStyled = styled(FileOutlined)`
  font-size: 16px;
`;
export const StyledTeamAvatar = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ id }: { id: string }) => getRandomColorById(id)};
  border-radius: 2px;
  font-size: 12px;
  line-height: 24px;
  color: ${colors.white};
`;
export const StyledTable = styled(Table).attrs({ size: 'middle' })`
  background-color: ${colors.white};

  .ant-tabs-nav-wrap {
    padding: 24px;
    background-color: ${colors.white};
  }
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: ${colors.labelGrey};

    .ant-tabs-tab-active {
      font-weight: 500;
    }
  }
  .ant-table-thead > tr > th {
    background-color: ${colors.white};
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }

  td span {
    word-break: break-all;
    white-space: pre-wrap;
    text-align: left;
  }
`;

export const StyledFiles = styled(Card)`
  margin-top: 24px;
  padding: 0;
`;

export const StyledText = styled.text`
  color: ${colors.textColorSecondary};
`;

export const StyledLoading = styled(Card)`
  margin-top: 24px;
  width: 100%;
`;
export const StyledButton = styled(Button)`
  padding: 0;
`;

import { useQuery } from 'react-query';
import { staticForm } from 'api/StaticForm';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';

export function useCreditCardDetail(adoId: string, processDefinitionKey: string) {
  return useQuery(
    [QUERIES.creditCardDetail, adoId, processDefinitionKey],
    async () => (await staticForm.getCreditCardDetail(adoId, processDefinitionKey)).data,
    {
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

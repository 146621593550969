import React from 'react';
import { TType } from '../../../translations';
import {
  StyledELibraryBigDownArrow,
  StyledELibraryCodeContainer,
  StyledELibraryCodeText,
  StyledELibraryLeftHint,
  StyledELibraryRightHint,
  StyledELibrarySmallDownArrow,
} from '../ELibrary.styled';

export interface ELibraryCodeProps {
  t: TType;
  text: string;
  leftHint?: boolean;
  bigArrow?: boolean;
  hint: string;
}

export const ELibraryCode: React.FC<ELibraryCodeProps> = ({
  t,
  text,
  bigArrow = false,
  leftHint = false,
  hint,
}: ELibraryCodeProps) => {
  return (
    <StyledELibraryCodeContainer>
      <StyledELibraryCodeText>{text}</StyledELibraryCodeText>
      {!bigArrow && <StyledELibrarySmallDownArrow />}
      {bigArrow && <StyledELibraryBigDownArrow />}
      {leftHint && <StyledELibraryLeftHint>{hint}</StyledELibraryLeftHint>}
      {!leftHint && <StyledELibraryRightHint>{hint}</StyledELibraryRightHint>}
    </StyledELibraryCodeContainer>
  );
};

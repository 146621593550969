import styled from 'styled-components';
import { BackArrow } from 'assets';
import { colors, space } from 'ui';
import { Col, Row, Typography } from 'antd';

const { Text } = Typography;

export const StyledBackArrow = styled(BackArrow)`
  margin-right: ${space.slim};
`;

export const StyledContainer = styled.div`
  margin: ${space.normal};
`;
export const StyledDetailContainer = styled(Col)`
  border-radius: 2px;
  background: ${colors.white};
  padding: ${space.slim};
`;

export const StyledBannerImage = styled.img`
  border-radius: 8px;
  aspect-ratio: 4.72;
  width: 100%;
  background-color: ${colors.grey};
  object-fit: cover;
`;

export const StyledTitleWrapper = styled(Row)`
  align-items: center;
  justify-content: center;
`;
export const StyledTitle = styled(Text)`
  color: ${colors.primaryColor};
  font-size: 36px;
  font-family: inherit;
  font-weight: 500;
`;

export const StyledBodyContainer = styled(Col)`
  margin-left: 130px;
  margin-right: 130px;
`;

export const StyledUserInfoContainer = styled(Row)`
  display: flex;
  justify-content: space-between;
  margin-top: ${space.comfy};
  margin-bottom: ${space.comfy};
`;

import React, { FC } from 'react';
import { Col } from 'antd';
import { TType } from 'translations';
import { UserManagerInfo } from 'ui/elements';
import { INewProfileOverview } from 'types';
import { ProfileEntryField } from 'ui';
import { StyledProfileRowContainer, StyledProfileFieldLabel } from '../../NewProfile.styled';

type Props = {
  t: TType;
  overview: INewProfileOverview;
};

export const ProfileOverview: FC<Props> = ({ t, overview }) => {
  const { location, position, department, managerName, mangerEmail, mangerPersonHcmid } = overview;
  return (
    <StyledProfileRowContainer gutter={[25, 15]} wrap={true}>
      <Col xs={24} sm={24} md={12} lg={8}>
        <ProfileEntryField label={t('user.publicProfile.location')} value={location} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        <ProfileEntryField label={t('user.publicProfile.positionName')} value={position} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        <StyledProfileFieldLabel>{t('user.publicProfile.managerName')}</StyledProfileFieldLabel>
        <UserManagerInfo
          managerEmail={mangerEmail}
          managerHcmid={mangerPersonHcmid}
          managerName={managerName}
          unStyledChildren={true}
        >
          <ProfileEntryField valueOnly={true} value={managerName} />
        </UserManagerInfo>
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        <ProfileEntryField label={t('user.publicProfile.department')} value={department} />
      </Col>
    </StyledProfileRowContainer>
  );
};

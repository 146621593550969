import React, { FC, useState, useCallback, useEffect } from 'react';
import { Popover, Row, Col, notification } from 'antd';

import { useTranslate } from 'translations';
import { reassignTask as reassignTaskApi } from 'api';
import { useTasksContext } from 'routes/Tasks/TasksProvider';
import { useHistory } from 'react-router-dom';
import { PagesUrls } from 'namespace';
import { profileData, useSelector } from 'store';

import { ReassignUserData } from '../TaskSteps.types';
import {
  StyledPopOver,
  StyledCheckIcon,
  StyledReassignTitle,
  StyledButton,
  StyledAssignTaskTitle,
  StyledAssignTaskMessage,
} from './ReassignTask.styled';
import { SearchUser } from './SearchUser';

interface ReassignTaskProps {
  children: React.ReactElement;
  taskId?: string;
  groupId?: string;
  reassignCallback?: () => void;
}

export const ReassignTask: FC<ReassignTaskProps> = ({
  children,
  taskId,
  groupId,
  reassignCallback,
}) => {
  const { t } = useTranslate();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [reassignUser, setReassignUser] = useState<ReassignUserData>();

  const [isLoading, setIsLoading] = useState<boolean>();
  const { currentActiveTab, rootPath } = useTasksContext();
  const [currentTaskId, setCurrentTaskId] = useState<string>();
  const history = useHistory();

  const [userId, setUserId] = useState<string>();
  const { data } = useSelector(profileData);

  useEffect(() => {
    if (data) {
      setUserId(data.adOid);
    }
  }, [data]);

  const onReassignedTask = useCallback(
    (e) => {
      e.stopPropagation();

      if (!reassignUser || !taskId || !reassignUser.id) {
        return;
      }

      setIsLoading(true);

      (async () => {
        if (!reassignUser.id) {
          return;
        }

        try {
          await reassignTaskApi.postReassginTask(taskId, reassignUser.id, rootPath);

          if (reassignUser.id === userId) {
            notification.success({
              message: <StyledAssignTaskTitle>{t('task.assignTask.title')}</StyledAssignTaskTitle>,
              description: (
                <StyledAssignTaskMessage>
                  {t('task.assignTask.assignToHimSelf.success.message')}
                </StyledAssignTaskMessage>
              ),
            });
          } else {
            notification.success({
              message: <StyledAssignTaskTitle>{t('task.assignTask.title')}</StyledAssignTaskTitle>,
              description: (
                <StyledAssignTaskMessage>{`${t(
                  'task.assignTask.assignTaskToOtherUser.success.message'
                )} ${reassignUser?.name}`}</StyledAssignTaskMessage>
              ),
            });
          }

          setReassignUser({ id: null, name: null });
          setIsVisible(false);

          if (reassignCallback) {
            reassignCallback();
            history.push(PagesUrls.MyWork);
          }
        } catch {
          notification.error({
            message: t('messages.error.wentWrong'),
            description: t('messages.error.tryAgainLater'),
          });
        } finally {
          setIsLoading(false);
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reassignUser, taskId, history, userId]
  );

  const onCloseHandler = useCallback((e) => {
    e.stopPropagation();
    setIsVisible(false);
  }, []);

  const onClick = useCallback(
    (e) => {
      e.stopPropagation();
      setIsVisible(true);
      if (taskId) setCurrentTaskId(taskId);
    },
    [taskId]
  );

  useEffect(() => {
    setIsVisible(false);
  }, [currentActiveTab]);

  useEffect(() => {
    if (taskId !== currentTaskId) setIsVisible(false);
  }, [currentTaskId, taskId]);

  const getCurrentSearchUserId = useCallback((reassignUserId: string, reassignUserName: string) => {
    if (!reassignUserId) {
      return;
    }

    setReassignUser({ id: reassignUserId, name: reassignUserName });
  }, []);

  const content = (
    <StyledPopOver>
      <Row align="middle" gutter={[10, 15]}>
        <Col>
          <StyledCheckIcon />
        </Col>
        <Col flex="auto">
          <StyledReassignTitle>{t('task.reassignTask.title')}</StyledReassignTitle>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 5]}>
        <Col flex="auto">
          <StyledReassignTitle>{t('task.reassignTask.chooseUser')}</StyledReassignTitle>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 15]}>
        <Col flex="auto">
          <SearchUser currentSearchUserId={getCurrentSearchUserId} groupId={groupId} />
        </Col>
      </Row>
      <Row justify="end" gutter={[5, 5]}>
        <Col>
          <StyledButton onClick={onCloseHandler}>{t('no')}</StyledButton>
        </Col>
        <Col>
          <StyledButton type={'primary'} loading={isLoading} onClick={onReassignedTask}>
            {t('yes')}
          </StyledButton>
        </Col>
      </Row>
    </StyledPopOver>
  );
  return (
    <div onClick={onClick}>
      <Popover placement="left" trigger={'click'} content={content} visible={isVisible}>
        <span>{children}</span>
      </Popover>
    </div>
  );
};

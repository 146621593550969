import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const allRequestCard = (state: RootState): RootState['allRequestsCard'] =>
  state.allRequestsCard;

export const allRequestSelector = createSelector(allRequestCard, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

import React, { FC } from 'react';
import { Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslate } from 'translations';
import { PopconfirmProps } from 'antd/lib/popconfirm';

interface Props extends Omit<PopconfirmProps, 'title'> {
  title?: PopconfirmProps['title'];
}

export const MSTeamsConfirm: FC<Props> = (props) => {
  const { t } = useTranslate();
  const { children, title = t('msteams.confirm.redirect'), ...rest } = props;

  return (
    <Popconfirm
      icon={<QuestionCircleOutlined />}
      overlayClassName="ms-teams-confirm"
      placement="bottomRight"
      title={title}
      okText={t('yes')}
      cancelText={t('no')}
      {...rest}
    >
      {children}
    </Popconfirm>
  );
};

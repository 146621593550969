import styled from 'styled-components';
import { Row, Col, Avatar, Typography } from 'antd';

import { colors, space } from 'ui/elements';
import { getRandomColorById } from 'ui';

const { Text } = Typography;

interface AdditionalProps {
  selected?: boolean;
}

export const StyledRow = styled(Row)`
  width: 100%;
  flex-wrap: nowrap;
`;

export const StyledCol = styled(Col)`
  width: 100%;
  overflow: hidden;
`;

export const StyledAvatarCol = styled(Col)`
  margin-right: ${space.cozy};
`;

export const StyledMessageListWrapper = styled.div`
  max-height: calc(100vh - 193px);
  overflow-x: hidden;
  overflow-y: auto;
`;

export const StyledMessageRow = styled(StyledRow)`
  user-select: none;
  cursor: pointer;
  padding: ${space.slim} ${space.normal};
  background-color: ${(props: AdditionalProps) => !!props.selected && '#f6f7f9'};
  transition: background-color 0.3s;

  &:hover {
    background-color: #f6f7f9;
  }
`;

export const StyledMessage = styled.div`
  font-size: 12px;
  line-height: 24px;
  color: ${colors.textColorSecondary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const StyledMessageDate = styled.span`
  font-size: 12px;
  line-height: 22px;
  color: ${colors.lightGrey};
`;

export const StyledChatGroupAvatar = styled(Avatar)`
  background-color: ${({ id }: { id: string }) => getRandomColorById(id)};
`;

export const StyledChatName = styled(Text)`
  &:hover {
    text-decoration: underline;
  }
`;

import { colors } from '../elements/theme';

const { skySecondary, nature, orangeShadow, plumSecondary, sand } = colors;

const colorsMap: {
  [id: string]: string;
} = {};

let selectedColorIds: number[] = [];

const colorList = [skySecondary, nature, orangeShadow, plumSecondary, sand];

const getUniqueId = (): number => {
  const newId = Math.floor(Math.random() * Math.floor(colorList.length));

  return selectedColorIds.includes(newId) ? getUniqueId() : newId;
};

// generate random and unique color from colorList and assign it by teamId
export const getRandomColorById = (id: string) => {
  if (colorsMap[id]) {
    return colorsMap[id];
  }

  const selectedColorId = getUniqueId();

  selectedColorIds = [...selectedColorIds, selectedColorId];

  colorsMap[id] = colorList[selectedColorId];

  if (colorList.length === selectedColorIds.length) {
    selectedColorIds = [];
  }

  return colorsMap[id];
};

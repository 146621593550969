import React, { FC, useEffect } from 'react';
import { notification } from 'antd';

import { useTranslate } from 'translations';
import { RequestStatus } from 'types';
import { FullWidthSpin } from 'ui';
import { StyledMessageListWrapper } from '../../MSTeamsChatTabs/MSTeamsChatSidebar/MSTeamsChatSidebar.styled';
import { ConnectorProps, teamsConnector } from './MSTeamsTeamsSidebar.connector';
import { MSTeamsTeamsSidebarTeam } from './MSTeamsTeamsSidebarTeam';

export const MSTeamsTeamsSidebarComponent: FC<ConnectorProps> = ({ getTeamsStart, teamsData }) => {
  const { t } = useTranslate();

  useEffect(() => {
    if (!teamsData.data) {
      getTeamsStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTeamsStart]);

  useEffect(() => {
    if (teamsData.status === RequestStatus.rejected) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [teamsData.status, t]);

  return (
    <StyledMessageListWrapper>
      {teamsData.status === RequestStatus.pending ? (
        <FullWidthSpin />
      ) : (
        teamsData.data?.map((team, i) => <MSTeamsTeamsSidebarTeam team={team} key={i} />)
      )}
    </StyledMessageListWrapper>
  );
};

export const MSTeamsTeamsSidebar = teamsConnector(MSTeamsTeamsSidebarComponent);

import { connect, ConnectedProps } from 'react-redux';

import { RootState, getUserName } from 'store';

const mapState = (state: RootState) => ({
  userName: getUserName(state),
});

export const headerConnector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof headerConnector>;

export type ConnectorProps = PropsFromRedux;

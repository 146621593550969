import { DrawerProps } from 'antd/lib/drawer';
import React, { ReactNode } from 'react';
import { useBreakpoint } from 'utils';
import { constants } from 'ui/elements';
import { DrawerHeader } from './DrawerHeader';
import { DrawerUiStyled } from './DrawerUi.styled';

interface DrawerUiProps {
  children: React.ReactNode;
  desktopWidth?: number | string;
  drawerTitle: string;
  extra?: ReactNode;
}

const DrawerUiComponent: React.FC<DrawerUiProps & DrawerProps> = ({
  children,
  desktopWidth,
  drawerTitle,
  extra,
  ...rest
}) => {
  const isMobile = useBreakpoint().down('sm');

  return (
    <DrawerUiStyled width={isMobile ? '100%' : constants.drawer.width} {...rest}>
      <DrawerHeader DrawerTitle={drawerTitle} extra={extra} />
      {children}
    </DrawerUiStyled>
  );
};

export const DrawerUi = React.memo(DrawerUiComponent);

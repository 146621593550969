import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const exportAttendance = (state: RootState): RootState['exportAttendance'] =>
  state.exportAttendance;

export const exportAttendanceData = createSelector(exportAttendance, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

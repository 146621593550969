import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetTaskPrettyIdStart,
  GetTaskPrettyIdSuccess,
  GetTaskPrettyIdFail,
  getTaskPrettyIdStart,
  getTaskPrettyIdSuccess,
  getTaskPrettyIdFail,
} from './taskPrettyIdSlice';

export const getTaskPrettyIdEpic: Epic<
  GetTaskPrettyIdStart,
  GetTaskPrettyIdSuccess | GetTaskPrettyIdFail,
  RootState,
  typeof API
> = (action$, _, { task }) =>
  action$.pipe(
    filter(getTaskPrettyIdStart.match),
    exhaustMap((payload) =>
      from(task.getTaskPrettyId(payload.payload.ids)).pipe(
        map(({ data }) =>
          getTaskPrettyIdSuccess({
            data,
          })
        ),
        catchError((error) =>
          of(getTaskPrettyIdFail({ error: error?.response?.data, ids: payload.payload.ids }))
        )
      )
    )
  );

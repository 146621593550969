import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Col, notification, Row, Spin } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { TitleH3 } from 'ui';
import { useTranslate } from 'translations';
import { RequestStatus } from 'types';
import { HierarchyTree, StyledLoadingOutlined } from './Hierarchy.styled';
import { ConnectorProps, hierarchyConnector } from './hierarchy.connector';
import { convertToTree, getInitialExpandedKeys, updateSwitcherIcon, updateTreeData } from './utils';
import { DataNode } from './Hierarchy.types';

const antLoadingIndicator = <StyledLoadingOutlined spin />;

export const HierarchyComponent: FC<ConnectorProps> = ({
  getHierarchyStart,
  currentUserHcmId,
  hierarchyData,
  getSubordinatesStart,
  subordinatesData,
}) => {
  const [treeData, setTreeData] = useState<DataNode[] | null>();
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>();
  const [defaultSelectedKey, setDefaultSelectedKey] = useState<React.Key[]>();
  const { t } = useTranslate();

  const showErrorMessage = useCallback(
    () =>
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      }),
    [t]
  );

  useEffect(() => {
    if (currentUserHcmId !== undefined && currentUserHcmId !== null) {
      getHierarchyStart(String(currentUserHcmId));
    }
  }, [currentUserHcmId, getHierarchyStart]);

  useEffect(() => {
    if (hierarchyData.status === RequestStatus.rejected) {
      showErrorMessage();
    }
  }, [hierarchyData.status, showErrorMessage]);

  useEffect(() => {
    if (subordinatesData.status === RequestStatus.rejected) {
      showErrorMessage();
    }
  }, [subordinatesData.status, showErrorMessage]);

  useEffect(() => {
    if (hierarchyData.data) {
      const initialExpandedKeys = getInitialExpandedKeys(hierarchyData.data);

      setTreeData(convertToTree(hierarchyData.data));

      setExpandedKeys(initialExpandedKeys);
      setDefaultSelectedKey([initialExpandedKeys[initialExpandedKeys.length - 1]]);
    }
  }, [hierarchyData.data]);

  useEffect(() => {
    if (subordinatesData.data) {
      Object.entries(subordinatesData.data).forEach(([key, value]) => {
        if (treeData) {
          const treeWithUpdatedSwitcherIcon = updateSwitcherIcon(treeData, key, false);

          setTreeData(updateTreeData(treeWithUpdatedSwitcherIcon, key, convertToTree(value, key)));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subordinatesData.data]);

  useEffect(() => {
    if (subordinatesData.data) {
      Object.entries(subordinatesData.data).forEach(([key]) => {
        if (expandedKeys) {
          setExpandedKeys([...expandedKeys, key]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subordinatesData.data]);

  const onExpand = useCallback(
    (expandedKeysValue: React.Key[], { node }) => {
      if (node.subordinatesIds && !node.children && treeData) {
        const params = {
          hcmPersonIds: node.subordinatesIds.join(','),
        };

        getSubordinatesStart({ params: { params }, key: node.key });
        setTreeData(updateSwitcherIcon(treeData, node.key));
      } else {
        setExpandedKeys(expandedKeysValue);
      }
    },
    [treeData, getSubordinatesStart]
  );

  return (
    <>
      <Row gutter={[0, 18]}>
        <Col span={24}>
          <TitleH3 type="prime">{t('hierarchy.title')}</TitleH3>
        </Col>

        <Col span={24}>
          {!treeData ? (
            <Spin indicator={antLoadingIndicator} />
          ) : (
            <HierarchyTree
              selectedKeys={defaultSelectedKey}
              expandedKeys={expandedKeys}
              onExpand={onExpand}
              blockNode
              switcherIcon={<DownOutlined />}
              showIcon
              treeData={treeData}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export const Hierarchy = hierarchyConnector(memo(HierarchyComponent));

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetHierarchyFail,
  GetHierarchySuccess,
  GetHierarchyStart,
  getHierarchyStart,
  getHierarchySuccess,
  getHierarchyFail,
} from './hierarchySlice';

export const getHierarchyEpic: Epic<
  GetHierarchyStart,
  GetHierarchySuccess | GetHierarchyFail,
  RootState,
  typeof API
> = (action$, _, { hierarchy }) =>
  action$.pipe(
    filter(getHierarchyStart.match),
    exhaustMap(({ payload }) =>
      from(hierarchy.getHierarchy(payload)).pipe(
        map(({ data }) => getHierarchySuccess([data])),
        catchError((error) => of(getHierarchyFail(error?.response?.data)))
      )
    )
  );

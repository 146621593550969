export interface ChatMember {
  userId: string;
  displayName: string;
  email?: string | null;
}

export interface ChatData {
  id: string;
  topic: string | null;
  chatType: ChatType | null;
  lastUpdatedDateTime: string | null;
  members: ChatMember[];
}

export enum ChatType {
  OneOnOne = 'ONE_ON_ONE',
}

export interface ChatMessageData {
  id: string;
  replyToId?: string | null;
  messageType?: string | null;
  createdDateTime: string;
  locale?: string;
  from?: {
    user?: {
      id: string;
      displayName: string;
    } | null;
    application?: {
      id: string;
      displayName: string;
    } | null;
  };
  body: {
    contentType: string;
    content: string;
  };
  attachments?: null;
  mentions?:
    | {
        id: string;
        mentionText: string;
        mentioned: {
          application: {
            displayName: string;
            id: string;
          };
          user: {
            displayName: string;
            id: string;
          };
        };
      }[]
    | null;

  isMine?: boolean;
}

export interface ChatMessages {
  date: string;
  messages: ChatMessageData[];
}

export interface ChatMessagesData {
  skipToken: string | null;
  list: ChatMessages[];
}

export interface ChannelRepliesData {
  skipToken?: string;
  list?: ChatMessageData[];
}

export interface CreateOneToOneChatData {
  chatType: ChatType;
  id: string;
  lastUpdatedDateTime: string;
  members:
    | [
        {
          displayName: string;
          email: string;
          userId: string;
        }
      ]
    | null;
  topic: string | null;
}

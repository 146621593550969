import React, { FC, useCallback } from 'react';
import { Col, Divider, Row } from 'antd';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { AppLayout } from 'layouts';
import { Header } from 'app';
import { TitleH1 } from 'ui';
import { useTranslate } from 'translations';
import { MSPlannerPageRoutes } from 'namespace';
import { MSPlannerSidebar } from '../MSPlannerSidebar';
import { MSPlannerProject } from '../MSPlannerProject';
import { MSPlannerMyTasks } from '../MSPlannerMyTasks';
import { MSPlannerPlannerHub } from '../MSPlannerPlannerHub';

export const MSPlannerLayout: FC = () => {
  const { t } = useTranslate();
  const { path } = useRouteMatch();
  const getUrl = useCallback((pagePath: string) => `${path}${pagePath}`, [path]);

  return (
    <AppLayout displayFooter={false}>
      <Header>
        <Row align="middle">
          <Col>
            <TitleH1>{t('msplanner.title')}</TitleH1>
          </Col>
        </Row>
      </Header>

      <Divider style={{ margin: 0 }} className="layout-main" />

      <Row>
        <Col span={6}>
          <MSPlannerSidebar />
        </Col>

        <Col span={18}>
          <Switch>
            <Route
              exact
              path={getUrl(MSPlannerPageRoutes.PlannerHub)}
              component={MSPlannerPlannerHub}
            />
            <Route exact path={getUrl(MSPlannerPageRoutes.MyTasks)} component={MSPlannerMyTasks} />
            <Route exact path={getUrl(MSPlannerPageRoutes.Project)} component={MSPlannerProject} />

            <Redirect to={getUrl(MSPlannerPageRoutes.MyTasks)} />
          </Switch>
        </Col>
      </Row>
    </AppLayout>
  );
};

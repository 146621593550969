import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from 'api';
import { attachment } from '../Attachment';

export function useAttachmentStandard() {
  return useQuery(
    [QUERIES.attachmentStandard],
    async () => {
      const { data } = await attachment.getAttachmentStandard();
      return data;
    },
    PERSIST_QUERY_CONFIG
  );
}

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../reducers';

export const leavesListSelector = (state: RootState): RootState['leavesList'] => state.leavesList;

export const leavesListDataSelector = createSelector(
  leavesListSelector,
  ({ error, status, data }) => ({
    error,
    status,
    data,
  })
);

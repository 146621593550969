import { PICTURE, TEAMS_USER_AVATAR, TEAMS_USER_AVATAR_BY_EMAIL } from 'api';
import { AxiosRequestConfig } from 'axios';
import { UserAvatarData } from 'types';
import { Core } from '../Core';

interface GetUserAvatar {
  (photoId: string, params: AxiosRequestConfig): Promise<{ data: UserAvatarData }>;
}

interface GetTeamsUserAvatar {
  (userId: string, params: AxiosRequestConfig): Promise<{ data: UserAvatarData }>;
}

interface GetTeamsUserAvatarByEmail {
  (data: { responseType: string; params: AxiosRequestConfig['params'] }): Promise<{
    data: UserAvatarData;
  }>;
}

class UserAvatar extends Core {
  getUserAvatar: GetUserAvatar = async (photoId, params) => this.get(PICTURE(photoId), params);
  getTeamsUserAvatar: GetTeamsUserAvatar = async (userId, params) =>
    this.get(TEAMS_USER_AVATAR(userId), params);
  getTeamsUserAvatarByEmail: GetTeamsUserAvatarByEmail = async (data) =>
    this.get(TEAMS_USER_AVATAR_BY_EMAIL(), data);
}

export const userAvatar = new UserAvatar();

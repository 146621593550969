import { connect, ConnectedProps } from 'react-redux';

import { RootState, getCurrentTeamsChannels, getTeamsChannelsStart } from 'store';
import { MSTeamsTeamsSidebarTeamProps } from './MSTeamsTeamsSidebarTeam';

const mapDispatch = {
  getTeamsChannelsStart,
};

const mapState = (state: RootState, props: MSTeamsTeamsSidebarTeamProps) => ({
  currentTeamsChannelsData: getCurrentTeamsChannels(state, props),
});

export const teamConnectorConnector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof teamConnectorConnector>;

export type ConnectorProps = PropsFromRedux;

import { Col, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import React from 'react';
import { span } from 'ui/elements';
import {
  TemplateTitleStyled,
  TemplateDateOfRequestStyled,
  TemplateMessageStyled,
  TemplateComment,
  CommenterTextStyled,
} from './SeeAllUpdate.style';

interface Props {
  title: string;
  message: string;
  hasComment: boolean;
  commenterName?: string | null;
  commentText?: string | null;
  formattedDate?: string;
  createdDate?: string;
}
const GUTTER_0_16: [Gutter, Gutter] = [0, 16];
const SeeAllUpdateTemplateComponent: React.FC<Props> = ({
  title,
  message,
  hasComment,
  commenterName,
  commentText,
  formattedDate,
}) => {
  const { span24 } = span;
  return (
    <React.Fragment>
      <Row gutter={GUTTER_0_16}>
        <Col span={span24}>
          <TemplateTitleStyled> {title}</TemplateTitleStyled>
        </Col>
        <Col span={span24}>
          <TemplateMessageStyled> {message}</TemplateMessageStyled>
        </Col>
        <Col span={span24}>
          <TemplateDateOfRequestStyled>{formattedDate}</TemplateDateOfRequestStyled>
        </Col>
        {hasComment && (
          <Col span={span24}>
            <TemplateComment>
              <CommenterTextStyled>{commenterName}</CommenterTextStyled>
              <TemplateMessageStyled>
                <div dangerouslySetInnerHTML={{ __html: commentText ?? '' }}></div>
              </TemplateMessageStyled>
            </TemplateComment>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export const SeeAllUpdateTemplate = React.memo(SeeAllUpdateTemplateComponent);

import styled from 'styled-components';
import { Tabs } from 'antd';
import { colors } from 'ui/elements';
import { OrganizationalHierarchy } from 'ui/components';

export const StyledPrivateProfileTabs = styled(Tabs)`
  .ant-tabs-nav {
    background: #ffffff;
    padding: 17px 25px 0 25px;
    border-radius: 5px;

    &:before {
      border-bottom: unset;
    }

    .ant-tabs-nav-list {
      gap: 35px;

      .ant-tabs-tab {
        margin: 0;
        font-size: 18px;
        font-weight: 400;
        color: ${colors.labelGrey};

        &.ant-tabs-tab-active {
          font-weight: 500;
          color: ${colors.primaryColor};
        }
      }
    }
  }
`;

export const StyledPrivateProfileTabContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
`;

export const StyledPrivateProfileOrganizationalHierarchy = styled(OrganizationalHierarchy)`
  width: 100%;
  height: 425px;

  .organizational-hierarchy-tree-container {
    max-height: unset;
  }
`;

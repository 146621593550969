import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AssigneeUserData, RequestCommonError, RequestStatus } from 'types';
import { AxiosRequestConfig } from 'axios';
import { AssigneeUserState } from './assigneeUserType';

export const emptyAssigneeUserState: AssigneeUserState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetAssigneeUserStart = PayloadAction<AxiosRequestConfig>;
export type GetAssigneeUserSuccess = PayloadAction<AssigneeUserData[]>;
export type GetAssigneeUserFail = PayloadAction<RequestCommonError>;

const assigneeUser = createSlice({
  name: 'assigneeUser',
  initialState: emptyAssigneeUserState,
  reducers: {
    getAssigneeUserStart(state, _: GetAssigneeUserStart) {
      state.status = RequestStatus.pending;
    },
    getAssigneeUserSuccess(state, action: GetAssigneeUserSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getAssigneeUserFail(state, action: GetAssigneeUserFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getAssigneeUserFail,
  getAssigneeUserStart,
  getAssigneeUserSuccess,
} = assigneeUser.actions;
export default assigneeUser.reducer;

import { PublicHolidaysData } from 'types';
import { Core, PUBLIC_HOLIDAYS } from 'api';

interface GetPublicHolidays {
  (): Promise<{ data: PublicHolidaysData }>;
}

class PublicHolidays extends Core {
  getPublicHolidays: GetPublicHolidays = async () => this.get(PUBLIC_HOLIDAYS());
}
export const publicHolidays = new PublicHolidays();

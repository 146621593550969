import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  getUsersStart,
  getUsersSuccess,
  getUsersFail,
  GetUsersFail,
  GetUsersStart,
  GetUsersSuccess,
} from './usersSlice';

export const getUsersEpic: Epic<
  GetUsersStart,
  GetUsersSuccess | GetUsersFail,
  RootState,
  typeof API
> = (action$, _, { users }) =>
  action$.pipe(
    filter(getUsersStart.match),
    mergeMap(({ payload }) =>
      from(users.getUsers(payload)).pipe(
        map(({ data }) => getUsersSuccess(data)),
        catchError((error) => of(getUsersFail(error?.response?.data)))
      )
    )
  );

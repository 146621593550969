import { TaskDetailsData } from 'types';
import { Core, TASK_DATA } from 'api';

interface GetTaskDetials {
  (taskId: string): Promise<{ data: TaskDetailsData }>;
}

class TaskDetails extends Core {
  getTaskDetails: GetTaskDetials = async (taskId) => this.get(TASK_DATA(taskId));
}
export const taskDetails = new TaskDetails();

import React, { FC } from 'react';
import { Row } from 'antd';
import { colors } from 'ui';
import { StyledDocumentCard, StyledDocumentID, StyledTitle } from '../ELibrary.styled';
import ELibraryIconSelector from './ELibraryIconSelector';

interface Props {
  documentId: string;
  documentTitle: string;
  departmentName?: string;
  sectorName?: string;
  onCardClick: () => void;
}

export const ELibraryDocumentCard: FC<Props> = ({
  documentId,
  documentTitle,
  departmentName,
  sectorName,
  onCardClick,
}) => {
  return (
    <StyledDocumentCard onClick={onCardClick}>
      <Row justify="space-between">
        <StyledDocumentID>{documentId}</StyledDocumentID>
        <ELibraryIconSelector
          departmentName={departmentName
            ?.replace(/\s/g, '')
            ?.replace(/-/g, '')
            ?.replace(/&/g, '')
            ?.replace(/,/g, '')}
          sectorName={sectorName
            ?.replace(/\s/g, '')
            ?.replace(/-/g, '')
            ?.replace(/&/g, '')
            ?.replace(/,/g, '')}
          style={{ fontSize: 20, height: '20px', width: '25px', color: colors.lightGrey }}
        />
      </Row>
      <StyledTitle ellipsis={{ rows: 2 }}>{documentTitle}</StyledTitle>
    </StyledDocumentCard>
  );
};

import { FormInstance } from 'antd/lib/form';
import { FormListFieldData } from 'antd/lib/form/FormList';
import React from 'react';
import { ListOfItem, StaticFieldsGroup, StaticFieldsGroupOfGroup } from 'routes/Tasks';
import { TType } from 'translations';
import { CreateFormItemType, GroupOfFormItem, GroupOfGroupFormItem } from 'types';
import { StaticFormTemplate } from '../StaticFormTemplates';

interface StaticFormWithGroupsProps {
  t: TType;
  description?: string;
  startFormFields: () => CreateFormItemType[];
  getItems?: (field: FormListFieldData) => CreateFormItemType[];
  maxLimit?: number;
  formListName: string;
  form?: FormInstance;
  itemGroupName?: string;
  listOfFormField?: () => GroupOfFormItem[];
  isHidden?: boolean;
  groupOflistOfFormField?: () => GroupOfGroupFormItem[];
}

export const StaticFormWithGroupsComponent: React.FC<StaticFormWithGroupsProps> = ({
  formListName,
  t,
  startFormFields,
  getItems,
  maxLimit,
  form,
  listOfFormField,
  itemGroupName,
  description,
  isHidden = false,
  groupOflistOfFormField,
}) => {
  return (
    <React.Fragment>
      <StaticFormTemplate t={t} description={description} form={form} getItems={startFormFields} />
      {getItems && !isHidden && (
        <ListOfItem
          t={t}
          form={form}
          getItems={getItems}
          maxLimit={maxLimit ?? 0}
          formListName={formListName}
          itemGroupName={itemGroupName ?? ''}
          btnDefaultTitle={t('scrapInvoice.staticForm.btn.default.addAssetsDetails')}
          btnAddTitle={t('scrapInvoice.staticForm.btn.add.addAssetsDetails')}
        />
      )}

      {listOfFormField && !isHidden && (
        <StaticFieldsGroup t={t} getFormFields={listOfFormField} form={form} />
      )}

      {groupOflistOfFormField && !isHidden && (
        <StaticFieldsGroupOfGroup t={t} getFormFields={groupOflistOfFormField} form={form} />
      )}
    </React.Fragment>
  );
};

export const StaticFormWithGroups = React.memo(StaticFormWithGroupsComponent);

import { AllRequestsCard } from 'types';
import { Core, CATEGORY_CARD } from 'api';

interface GetCategoryCard {
  (categoryName: string): Promise<{ data: AllRequestsCard }>;
}

class CategoryCard extends Core {
  getCategoryCard: GetCategoryCard = async (categoryName: string) =>
    this.get(CATEGORY_CARD(categoryName));
}

export const categoryCard = new CategoryCard();

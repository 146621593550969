import { createSelector } from '@reduxjs/toolkit';
import { MSTeamsUserAvatarProps } from 'routes/MSTeams/MSTeamsUserAvatar';
import { RootState } from '../reducers';

export const teamsUserAvatar = (state: RootState): RootState['teamsUserAvatar'] =>
  state.teamsUserAvatar;

const getCurrentUserId = (state: RootState, props: MSTeamsUserAvatarProps) => props.id;

export const teamsUserAvatarData = createSelector(
  [teamsUserAvatar, getCurrentUserId],
  (teamsUserAvatar, id) => (id ? teamsUserAvatar[id] : undefined)
);

import React, { FC, useState, useCallback, useEffect } from 'react';
import { Col, Collapse, Row, Typography, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import parse from 'html-react-parser';
import moment from 'moment';
import { isNil } from 'ramda';

import { ChatMessageData, RequestStatus } from 'types';
import { useTranslate } from 'translations';
import {
  useDispatch,
  getTeamsChannelRepliesStart,
  useSelector,
  getTeamsChannelMessagesData,
  sendTeamsChannelReplyStart,
  updateTeamsChannelRepliesStart,
} from 'store';
import { AsyncImage } from 'ui';
import { ChatSendMessage } from '../ChatSendMessage';
import { useMSTeamsContext } from '../../../MSTeamsProvider';
import { ReplyItem } from '../ReplyItem';
import {
  ChatCard,
  ChatCardContent,
  ReplyListBlock,
  ReplyBlock,
  ReplyBlockWrapper,
  TextSecondary,
  TextTimeStamp,
  StyledDivider,
  StyledMessageIcon,
  StyledCol,
  StyledCollapse,
  StyledSeeRepliesButton,
  AttachmentCard,
  AttachmentsWrapper,
  StyledFileIcon,
  StyledItalicText,
} from './ChatMessage.styled';

interface Props {
  id?: string;
  title?: string;
  message: string;
  messageType: string;
  createdDateTime?: string;
  secondary?: boolean;
  replyList?: ChatMessageData[];
  withReplyButton?: boolean;
  small?: boolean;
  attachments?:
    | {
        id: string;
        name?: string | null;
        content?: string | null;
        contentType?: string | null;
        contentUrl?: string | null;
        thumbnailUrl?: string | null;
      }[]
    | null;
}

const { Text } = Typography;
const { Panel } = Collapse;

export const ChatMessage: FC<Props> = ({
  id,
  title,
  message,
  messageType,
  createdDateTime,
  withReplyButton,
  small,
  attachments,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { currentTeamId, currentChannelId } = useMSTeamsContext();
  const {
    data: { channelReplies },
  } = useSelector(getTeamsChannelMessagesData);
  const [isActive, setIsActive] = useState(false);
  const channelRepliesLength =
    !isNil(id) && !isNil(channelReplies) && channelReplies[id]?.data?.list?.length;
  const status = !isNil(id) && !isNil(channelReplies) ? channelReplies[id]?.status : undefined;

  const changedMessage = message?.replace(/(<([^>]+)>)/gi, '');

  useEffect(() => {
    if (
      withReplyButton &&
      !isNil(id) &&
      (!channelReplies || !channelReplies[id] || !channelReplies[id].data)
    ) {
      dispatch(
        getTeamsChannelRepliesStart({
          teamId: currentTeamId,
          channelId: currentChannelId,
          messageId: id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMessageInputVisibility = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  const onSubmit = (values: {
    message: {
      body: { content: string; contentType?: string };
      mentions?: {
        mentioned: {
          user: {
            displayName: string;
            id: string;
          };
        };
      }[];
    };
  }) => {
    if (!isNil(id)) {
      dispatch(
        sendTeamsChannelReplyStart({
          teamId: currentTeamId,
          channelId: currentChannelId,
          messageId: id,
          values: {
            ...values.message,
          },
        })
      );
    }
  };

  const loadMore = useCallback(() => {
    if (
      !isNil(id) &&
      channelReplies &&
      channelReplies[id] &&
      channelReplies[id].data?.skipToken &&
      channelReplies[id].status !== RequestStatus.pending
    ) {
      dispatch(
        updateTeamsChannelRepliesStart({
          teamId: currentTeamId,
          channelId: currentChannelId,
          messageId: id,
          skipToken:
            !isNil(id) && channelReplies && channelReplies[id].data?.skipToken
              ? channelReplies[id].data?.skipToken
              : undefined,
        })
      );
    }
  }, [channelReplies, currentChannelId, currentTeamId, dispatch, id]);

  return (
    <Row align="top" justify="start" gutter={small ? 0 : [0, 24]}>
      <StyledCol>
        <ChatCard small={small ? 'yes' : 'no'} secondary="no">
          <ChatCardContent small={small ? 'yes' : 'no'}>
            <Row gutter={[16, 6]} align="middle">
              {!!title && (
                <Col>
                  <Text strong>{title}</Text>
                </Col>
              )}
              {!!createdDateTime && (
                <Col>
                  <TextTimeStamp type="secondary">
                    {moment(createdDateTime).format('DD/MM/YYYY HH:mm')}
                  </TextTimeStamp>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <TextSecondary secondary="yes">
                  {!!message &&
                    messageType === 'HTML' &&
                    parse(changedMessage, {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      replace: (domNode: any) => {
                        if (
                          domNode?.tagName === 'img' &&
                          domNode?.attribs.src.includes('https://graph.microsoft.com')
                        ) {
                          return <AsyncImage src={domNode?.attribs.src} />;
                        }

                        return domNode;
                      },
                    })}
                  {!!message && messageType !== 'HTML' && changedMessage}
                  {!message && <StyledItalicText>{t('msteams.deletedMessage')}</StyledItalicText>}
                </TextSecondary>
              </Col>
            </Row>
            <AttachmentsWrapper>
              <Row gutter={[8, 8]} wrap>
                {!!attachments?.length &&
                  attachments.map(({ id, name, contentUrl }) => (
                    <Col key={id}>
                      <a href={contentUrl || undefined} target="_blank" rel="noreferrer" download>
                        <AttachmentCard>
                          <StyledFileIcon /> {name}
                        </AttachmentCard>
                      </a>
                    </Col>
                  ))}
              </Row>
            </AttachmentsWrapper>
          </ChatCardContent>
          {!!message && withReplyButton && <StyledDivider />}

          {!!message && !!status && !(status === RequestStatus.resolved && !channelRepliesLength) && (
            <ReplyListBlock>
              <StyledCollapse
                ghost
                expandIcon={
                  status === RequestStatus.pending
                    ? () => <Spin size="small" indicator={<LoadingOutlined spin />} />
                    : undefined
                }
              >
                <Panel
                  key="1"
                  header={
                    <div>
                      {status === RequestStatus.pending
                        ? t('msteams.loading')
                        : channelRepliesLength}{' '}
                      {t('msteams.replies')}
                    </div>
                  }
                >
                  {!isNil(id) &&
                    channelReplies &&
                    channelReplies[id] &&
                    !!channelReplies[id].data?.skipToken && (
                      <StyledSeeRepliesButton onClick={loadMore}>
                        <Text>{t('msteams.button.seePreviousReplies')}</Text>
                      </StyledSeeRepliesButton>
                    )}
                  {!isNil(id) && !isNil(channelReplies) && channelReplies[id]?.data?.list?.length
                    ? channelReplies[id]?.data?.list?.map((item) => (
                        <ReplyItem key={item.id} {...item} />
                      ))
                    : null}
                </Panel>
              </StyledCollapse>
            </ReplyListBlock>
          )}

          {!!message && withReplyButton && !isActive && (
            <ReplyBlock onClick={toggleMessageInputVisibility}>
              <StyledMessageIcon /> {t('msteams.button.reply')}
            </ReplyBlock>
          )}

          {!!message && withReplyButton && isActive && (
            <ReplyBlockWrapper>
              {' '}
              <ChatSendMessage
                channelId={currentChannelId}
                teamId={currentTeamId}
                isActive={isActive}
                setIsActive={setIsActive}
                onSubmit={onSubmit}
                submitStatus={status}
              />
            </ReplyBlockWrapper>
          )}
        </ChatCard>
      </StyledCol>
    </Row>
  );
};

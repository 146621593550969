import { combineReducers } from '@reduxjs/toolkit';
import profileReducer from './profile/profileSlice';
import hierarchyReducer from './hierarchy/hierarchySlice';
import subordinatesReducer from './subordinates/subordinatesSlice';
import userAvatarReducer from './userAvatar/userAvatarSlice';
import generalInfoReducer from './generalInfo/generalInfoSlice';
import teamsReducer from './teams/teamsSlice';
import teamsChannelsReducer from './teamsChannels/teamsChannelsSlice';
import chatsReducer from './chats/chatsSlice';
import teamsUserAvatarReducer from './teamsUserAvatar/teamsUserAvatarSlice';
import userInfoReducer from './userInfo/userInfoSlice';
import teamsChannelMembersReducer from './teamsChannelMembers/teamsChannelMembersSlice';
import teamsPostsReducer from './teamsPosts/teamsPostsSlice';
import teamsFilesReducer from './teamsFilesList/teamsChannelFilesListSlice';
import teamsFolderReducer from './teamsChannelFolder/teamsChannelFolderSlice';
import usersReducer from './users/usersSlice';
import oneDriveSharedReducer from './oneDriveShared/oneDriveSharedSlice';
import oneDriveSharedLibrariesReducer from './oneDriveSharedLibraries/oneDriveSharedLibrariesSlice';
import processDefinitionKeysReducer from './processDefinitionKeys/processDefinitionKeysSlice';
import processDefinitionReducer from './processDefinition/processDefinitionSlice';
import taskReducer from './task/taskSlice';
import oneDriveFolderSharedReducer from './oneDriveFolderShared/oneDriveFolderSharedSlice';
import oneDriveSharedLibraryFolderListReducer from './oneDriveSharedLibraryListFolder/oneDriveSharedLibraryFolderListSlice';
import oneDriveSearchFilesReducer from './oneDriveSearch/oneDriveSearchSlic';
import oneDriveRootFolderReducer from './oneDriveRootFolder/oneDriveRootFolderSlice';
import oneDriveItemChildrenReducer from './oneDriveItemChildren/oneDriveItemChildrenSlice';
import oneDriveRecentFilesReducer from './oneDriveRecentFiles/oneDriveRecentFilesSlice';
import processInstanceReducer from './processInstanceInfo/processInstanceInfoSlice';
import taskDetailsReducer from './taskDetails/taskDetailsSlice';
import taskDiagramReducer from './taskDiagram/taskDiagramSlice';
import taskCommentsReducer from './taskComments/taskCommentsSlice';
import taskListReducer from './taskList/taskListSlice';
import reassignTaskReducer from './reassignTask/reassignTaskSlice';
import userGroupReducer from './userGroup/userGoupsSlice';
import processDefinitionListReducer from './processDefinitonList/processDefinitionListSlice';
import tasksListReducer from './tasksList/tasksListSlice';
import requestsListReducer from './requestsList/requestsListSlice';
import assigneeUserReducer from './assigneeUser/assigneeUserSlice';
import groupMembersReducer from './groupMembers/groupMembersSlice';
import claimTaskReducer from './claimTask/claimTaskSlice';
import groupMembersByLastNameReducer from './groupMembers/groupMebersByLastName/groupMebersByLastNameSlice';
import groupsTasksListReducer from './groupsTasksList/groupsTasksListSlice';
import leavesTypesReducer from './leavesTypes/leavesTypesSlice';
import historicActivityInstanceReducer from './historicActivityInstance/historicActivityInstanceSlice';
import profileExpenseReducer from './ProfileExpense/ProfileExpenseSlice';
import profileExpenseAttachmentReducer from './ProfileExpenseAttachment/ProfileExpenseAttachmentSlice';
import camundaUsersReducer from './camundaUsers/camundaUsersSlice';
import balanceReducer from './balance/balanceSlice';
import leavesHistoryReducer from './leavesHistory/leavesHistorySlice';
import leavesListReducer from './leavesList/leavesListSlice';
import historicTaskListReducer from './historicTaskList/historicTaskListSlice';
import paySlipsReducer from './paySlips/paySlipsSlice';
import requestPrettyIdReducer from './requestPrettyId/requestPrettyIdSlice';
import taskPrettyIdReducer from './taskPrettyId/taskPrettyIdSlice';
import submitFormReducer from './submitForm/submitFormSlice';
import publicHolidaysReducer from './publicHolidays/publicHolidaysSlice';
import attendanceInfoReducer from './attendance/attendanceInfoSlice';
import userStatusReducer from './userStatus/userStatusSlice';
import userProfileReducer from './userProfile/userProfileSlice';
import otherUserStatusReducer from './otherUserStatus/otherUserStatusSlice';
import categoriesTreeReducer from './categoriesTree/categoriesTreeSlice';
import allRequestsCardReducer from './allRequestCard/allRequestCardSlice';
import subCategoryCardReducer from './subCategoryCard/subCategoryCardSlice';
import categoryCardReducer from './categoryCard/categoryCardSlice';
import myRequestCardsReducer from './myRequestCards/myRequestCardsSlice';
import myTaskCardsReducer from './myTasksCards/myTaskCardsSlice';
import myGroupTaskCardReducer from './myGroupTaskCard/myGroupTaskCardsSlice';
import requestCatalogDrawerReducer from './requestCatalogDrawer/requestCatalogDrawerSlice';
import paySlipsPdfFileReducer from './paySlipsPdfFile/paySlipsPdfFileSlice';
import exportAttendanceReducer from './exportAttendance/exportAttendanceSlice';
import userInfoCardReducer from './userInfoCard/userInfoCardSlice';
import profileExpenseLookupReducer from './ProfileExpensesLookup/ProfileExpenseLookupSlice';
import userInfoStatusReducer from './userInfoStatus/userInfoStatusSlice';
import notificationsListReducer from './notificationList/notificationListSlice';
import allNotificationUpdatesListReducer from './notificationList/seeAllUpdates/allNotificationUpdatesListSlice';
import usersTableReducer from './UsersTable/UsersTableSlice';
import employeeDirectorySearchReducer from './employeeDirectorySearch/employeeDirectorySearchSlice';

export const rootReducer = combineReducers({
  profile: profileReducer,
  hierarchy: hierarchyReducer,
  subordinates: subordinatesReducer,
  userAvatar: userAvatarReducer,
  generalInfo: generalInfoReducer,
  teams: teamsReducer,
  teamsChannels: teamsChannelsReducer,
  chats: chatsReducer,
  teamsUserAvatar: teamsUserAvatarReducer,
  userInfo: userInfoReducer,
  teamsChannelMembers: teamsChannelMembersReducer,
  teamsPosts: teamsPostsReducer,
  teamsFiles: teamsFilesReducer,
  teamsFolder: teamsFolderReducer,
  users: usersReducer,
  oneDriveShared: oneDriveSharedReducer,
  oneDriveSharedLibraries: oneDriveSharedLibrariesReducer,
  processDefinitionKeys: processDefinitionKeysReducer,
  processDefinition: processDefinitionReducer,
  task: taskReducer,
  oneDriveFolderShared: oneDriveFolderSharedReducer,
  oneDriveSharedLibraryFolderList: oneDriveSharedLibraryFolderListReducer,
  oneDriveSearchFiles: oneDriveSearchFilesReducer,
  oneDriveRootFolder: oneDriveRootFolderReducer,
  oneDriveItemChildren: oneDriveItemChildrenReducer,
  oneDriveRecentFiles: oneDriveRecentFilesReducer,
  processInstanceInfo: processInstanceReducer,
  taskDetails: taskDetailsReducer,
  taskDiagram: taskDiagramReducer,
  taskComments: taskCommentsReducer,
  taskList: taskListReducer,
  reassignTask: reassignTaskReducer,
  userGroup: userGroupReducer,
  processDefinitionList: processDefinitionListReducer,
  tasksList: tasksListReducer,
  assigneeUser: assigneeUserReducer,
  groupMembers: groupMembersReducer,
  claimTask: claimTaskReducer,
  requestsList: requestsListReducer,
  groupMembersByLAstName: groupMembersByLastNameReducer,
  groupsTasksList: groupsTasksListReducer,
  leavesTypes: leavesTypesReducer,
  historicActivityInstance: historicActivityInstanceReducer,
  profileExpense: profileExpenseReducer,
  profileExpenseAttachment: profileExpenseAttachmentReducer,
  camundaUsers: camundaUsersReducer,
  balance: balanceReducer,
  leavesHistory: leavesHistoryReducer,
  leavesList: leavesListReducer,
  historicTaskList: historicTaskListReducer,
  payslips: paySlipsReducer,
  requestPrettyId: requestPrettyIdReducer,
  taskPrettyId: taskPrettyIdReducer,
  submitForm: submitFormReducer,
  publicHolidays: publicHolidaysReducer,
  attendanceInfo: attendanceInfoReducer,
  userStatus: userStatusReducer,
  userProfile: userProfileReducer,
  otherUserStatus: otherUserStatusReducer,
  categoriesTree: categoriesTreeReducer,
  allRequestsCard: allRequestsCardReducer,
  subCategoryCard: subCategoryCardReducer,
  categoryCard: categoryCardReducer,
  myRequestCards: myRequestCardsReducer,
  myTaskCards: myTaskCardsReducer,
  myGroupTaskCards: myGroupTaskCardReducer,
  requestCatalogDrawer: requestCatalogDrawerReducer,
  paySlipsPdfFile: paySlipsPdfFileReducer,
  exportAttendance: exportAttendanceReducer,
  userInfoCard: userInfoCardReducer,
  profileExpenseLookup: profileExpenseLookupReducer,
  userInfoStatus: userInfoStatusReducer,
  notificationsList: notificationsListReducer,
  allNotificationUpdatesList: allNotificationUpdatesListReducer,
  usersTable: usersTableReducer,
  employeeDirectorySearch: employeeDirectorySearchReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

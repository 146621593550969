import { useQuery } from 'react-query';
import { AllRequestCard, AllRequestsCard } from 'types';
import { QUERIES } from 'api';
import { allRequestCard } from '../AllRequestCard';

const toAllRequestCards = (data: AllRequestsCard): AllRequestCard[] => data?.requestNames;

export function useAllRequestCard() {
  return useQuery(
    [QUERIES.allRequestCard],
    async () => {
      const { data } = await allRequestCard.getAllRequestCard();
      return toAllRequestCards(data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';

import { TaskDefinitionKey, TaskStatus } from 'types';
import { useTranslate } from 'translations';
import { useCompletedTaskFormFields } from 'api';
import { StyledLabel, useTasksContext } from 'routes';

import { AssigneeInfo } from '../TaskSteps.types';
import { TaskFields } from '../TaskFields';
import { TaskAttachment } from '../TaskAttachment';
import { TitleGroupName } from '../TaskFields/Components';
import { groupAttachmentsByLabel } from '../../../RestrictedUpload';
import {
  StyledDivider,
  StyledFullWidthRow,
  TextAppliedAction,
  TextStyledH2,
} from './TaskInfo.styled';
import { TaskInfoWrapper } from './components';

interface TaskInfoProps {
  disabled?: boolean;
  status: TaskStatus;
  title: string;
  isOpened?: boolean;
  highlighted?: boolean;
  taskId: string;
  created?: string;
  processDefinitionId: string;
  processInstanceId: string;
  assigneeInfo?: AssigneeInfo;
  userGroupName?: [string];
  assigneeId?: string;
  taskPrettyId: string;
  taskDefinitionKey: TaskDefinitionKey;
  taskActivityInstanceId: string;
}

export interface TaskInfoItem {
  fieldId: string;
  fieldValue: string;
  label: string;
  typeName: string;
  groupName: string;
}

const TaskInfoComponent: FC<TaskInfoProps> = ({
  disabled,
  taskId,
  status,
  title,
  highlighted,
  isOpened,
  processDefinitionId,
  processInstanceId,
  assigneeInfo,
  created,
  userGroupName,
  taskPrettyId,
  taskDefinitionKey,
  taskActivityInstanceId,
}) => {
  const { t } = useTranslate();
  const [isThereAppliedActionBtn, setIsThereAppliedActionBtn] = useState<boolean>(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false);
  const [completedFormFiled, setCompletedFormFields] = useState<TaskInfoItem[] | undefined>([]);
  const paramsQuery = {
    processDefinitionId,
    taskDefinitionKey,
    taskActivityInstanceId,
    processInstanceId,
  };
  const { rootPath } = useTasksContext();

  const completedTaskFields = useCompletedTaskFormFields(
    taskId,
    paramsQuery,
    isCollapseOpen,
    rootPath
  );

  const onClickOnCollapse = useCallback(() => {
    setIsCollapseOpen(!isCollapseOpen);
  }, [isCollapseOpen]);

  useEffect(() => {
    if (completedTaskFields.data) {
      const taskValuesWithLabels: TaskInfoItem[] = completedTaskFields.data.taskFormDetails?.map(
        (labelItem) => {
          return {
            label: labelItem.label,
            typeName: labelItem.typeName,
            groupName: labelItem.properties?.groupName,
            fieldId: labelItem.id,
            fieldValue: labelItem.value,
          } as TaskInfoItem;
        }
      );
      setCompletedFormFields(taskValuesWithLabels);
    }
  }, [completedTaskFields.data, setCompletedFormFields]);

  const appliedAction = completedFormFiled?.filter(
    (variable) =>
      variable.typeName === 'button' &&
      variable.label &&
      (variable.fieldValue || variable.fieldValue === 'true') &&
      variable.fieldId !== 'isAnnual'
  );

  useEffect(() => {
    if (appliedAction) {
      const isAppliedAction = appliedAction.some((action) => action.typeName === 'button');
      setIsThereAppliedActionBtn(isAppliedAction);
    }
  }, [appliedAction, isThereAppliedActionBtn]);

  const fieldsInGroups = completedFormFiled?.reduce((acc, filed) => {
    if (filed?.groupName) {
      acc[filed?.groupName] = [...(acc[filed?.groupName] || []), filed];
    } else {
      const withoutGroup = '';
      acc[withoutGroup] = [...(acc[withoutGroup] || []), filed];
    }
    return acc;
  }, {} as Record<string, TaskInfoItem[] | undefined>);

  const groupedTaskInfoAttachments = useMemo(() => {
    return groupAttachmentsByLabel(completedTaskFields?.data?.attachments ?? []);
  }, [completedTaskFields]);

  return (
    <TaskInfoWrapper
      status={status}
      title={title}
      taskPrettyId={taskPrettyId}
      taskDefinitionKey={taskDefinitionKey}
      assigneeInfo={assigneeInfo}
      disabled={disabled}
      created={created}
      userGroupName={userGroupName}
      isOpened={isOpened}
      highlighted={highlighted}
      onClickOnCollapse={onClickOnCollapse}
      collapsible={completedTaskFields.isLoading ? 'disabled' : undefined}
    >
      {completedFormFiled?.length ? <StyledDivider /> : null}

      {fieldsInGroups &&
        Object.entries(fieldsInGroups).map(([groupName, group], index) => (
          <Col span={24} key={groupName}>
            {groupName && <TitleGroupName title={groupName} withoutTopSpace={index === 0} />}
            <StyledFullWidthRow gutter={[32, 16]}>
              {group?.map(({ fieldId, fieldValue, label, typeName }: TaskInfoItem, index) => (
                <TaskFields
                  key={index}
                  fieldId={fieldId}
                  fieldValue={fieldValue}
                  label={label}
                  typeName={typeName}
                />
              ))}
            </StyledFullWidthRow>
          </Col>
        ))}
      {completedTaskFields.data?.attachments && completedTaskFields.data?.attachments.length > 0 && (
        <>
          <StyledDivider />

          <Col span={24}>
            <TitleGroupName title={t('task.attachedFiles')} withoutTopSpace bottomSpace={8} />
            {Object.keys(groupedTaskInfoAttachments).map((attachmentLabel) => {
              return (
                <>
                  {attachmentLabel ? (
                    <Col span={24}>
                      <StyledLabel>{attachmentLabel}</StyledLabel>
                    </Col>
                  ) : null}
                  {groupedTaskInfoAttachments[attachmentLabel]?.map(
                    ({ id, name, attachmentLabel, taskId: attachmentTaskId }) => (
                      <TaskAttachment
                        id={id}
                        name={name}
                        taskId={attachmentTaskId ?? taskId}
                        key={id}
                        taskDefinitionKey={taskDefinitionKey}
                      />
                    )
                  )}
                </>
              );
            })}
          </Col>
        </>
      )}
      {isThereAppliedActionBtn &&
        appliedAction?.map((action) => (
          <>
            <StyledDivider />
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <TextStyledH2 type="secondary">{t('task.appliedAction')}</TextStyledH2>
                </Col>
                <Col span={24}>
                  <TextAppliedAction strong>{action.label}</TextAppliedAction>
                </Col>
              </Row>
            </Col>
          </>
        ))}
    </TaskInfoWrapper>
  );
};

export const TaskInfo = React.memo(TaskInfoComponent);

import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { TeamsUserAvatar } from 'ui/components';
import { SufaraLogoWhiteBg } from 'assets';
import { PagesUrls } from 'namespace';
import { ProfileData, USER_STATUS_MAP } from 'types';
import {
  useDispatch,
  userStatusSelectors,
  useSelector,
  getUserStatusStart,
  profileData as profileDataSelector,
  getAvatarColor,
} from 'store';
import { useServicesContext } from '../../app';
import { SiderProps, Props } from './SiderLayout.props';
import {
  ContentStyled,
  LogoWrapper,
  Layout,
  Menu,
  Sider,
  StyledProfile,
  SiderContentLayout,
  StyledProfileBadge,
  StyledLogoTitle,
} from './SiderLayout.styled';

const SIDER_WIDTH = 100;
const IMAGE_SIZE = 60;

export const SiderLayout: SiderProps<Props> = (props) => {
  const { theme, menuItems, t, children, location: { pathname } = {} } = props;
  const themeValue = useMemo(() => theme || 'dark', [theme]);
  const isDark = useMemo(() => themeValue === 'dark', [themeValue]);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string[]>([]);
  const avatarColor = useSelector(getAvatarColor);
  const userStatus = useSelector(userStatusSelectors);
  const { data } = useSelector(profileDataSelector);
  const profileData = data as ProfileData;
  const dispatch = useDispatch();

  const defaultMenuItem = useMemo(() => {
    const defaultMenuItem = menuItems.find((item) => item.linkTo === pathname);
    return defaultMenuItem?.key === undefined ? undefined : [`${defaultMenuItem?.key}`];
  }, [menuItems, pathname]);

  useEffect(() => {
    const key = menuItems.find((item) => pathname?.includes(item.linkTo))?.key;
    if (key) {
      setSelectedMenuItem([key.toString()]);
    } else if (defaultMenuItem) {
      setSelectedMenuItem(defaultMenuItem);
    }
  }, [defaultMenuItem, menuItems, pathname]);

  useEffect(() => {
    dispatch(getUserStatusStart());
  }, [dispatch]);

  const {
    setRequestInfoRedirection,
    setPrettyIdInfoRedirection,
    setIsFilterByPrettyId,
    setIsFilterByRequest,
  } = useServicesContext();

  return (
    <Layout>
      <Sider width={SIDER_WIDTH} theme={themeValue}>
        <Link to={PagesUrls.Dashboard}>
          <LogoWrapper>
            <SufaraLogoWhiteBg />
            <StyledLogoTitle>{t('appTitle')}</StyledLogoTitle>
          </LogoWrapper>
        </Link>

        <Menu
          theme={themeValue}
          selectedKeys={selectedMenuItem}
          defaultSelectedKeys={defaultMenuItem}
          inlineIndent={8}
        >
          {menuItems.map(({ key, linkTo, linkText, icon: Icon, iconLight: IconLight }) => (
            <Menu.Item
              onClick={() => {
                /**
                 * It will clear the applied filter
                 * and gives the feel of page refresh
                 * to resolve issue EMPL-10074
                 */
                setPrettyIdInfoRedirection({});
                setIsFilterByPrettyId(false);
                setIsFilterByRequest(false);
                setRequestInfoRedirection({});
              }}
              key={key}
              icon={isDark ? <IconLight /> : <Icon />}
              className="menu-item"
            >
              <Link to={linkTo}>{t(linkText)}</Link>
            </Menu.Item>
          ))}
        </Menu>
        <StyledProfile>
          <Link to={PagesUrls.Profile}>
            <StyledProfileBadge
              dot
              offset={[-10, 49]}
              color={
                userStatus.data?.activity
                  ? USER_STATUS_MAP[userStatus.data?.activity]?.color
                  : undefined
              }
            >
              <TeamsUserAvatar
                id={profileData.adOid}
                email={profileData.email ?? undefined}
                size={IMAGE_SIZE}
                name={profileData.displayName}
                color={avatarColor}
              />
            </StyledProfileBadge>
          </Link>
        </StyledProfile>
      </Sider>
      <SiderContentLayout>
        <ContentStyled>{children}</ContentStyled>
      </SiderContentLayout>
    </Layout>
  );
};

import styled from 'styled-components';
import { Button } from 'antd';
import { colors } from '../theme';

export const PrimaryButton = styled(Button)`
  &.ant-btn {
    color: ${colors.white};
    background-color: ${colors.primaryColor};
    border-color: ${colors.primaryColor};

    &:hover,
    &:focus {
      color: ${colors.white};
      background-color: ${colors.accentPrimary};
      border-color: ${colors.accentPrimary};
    }
  }
`;

import React, { FC, memo, useCallback } from 'react';
import {
  constants,
  FullWidthSpin,
  StatusTag,
  StyledDivider,
  TagColor,
  TitleH2,
  useRowGutter,
} from 'ui';
import { StyledTimeStamp, StyledAttachmentsTitle } from 'routes/Calendar';
import { Col, Empty, Row, Space, Typography, Collapse } from 'antd';
import moment from 'moment';
import { useTranslate } from 'translations';
import { useExpensesDrawerInfo } from 'api';
import { TaskAttachment } from 'ui/components/TaskList/TaskSteps/TaskAttachment';
import { useSelector } from 'react-redux';
import { profileData } from 'store';
import { getExpenseStatusColor, getExpenseStatusText } from '../helper';
import { DEFAULT_COLLAPSE_KEY } from '../constants';
import { StyledCol, StyledText } from '../Expenses.styled';
import {
  StyledDescription,
  StyledDescriptionText,
  StyledExpenseDrawer,
  StyledExpenseWrapper,
  StyledPaymentValueText,
  StyledDetailWrapper,
  StyledSectionTitle,
  StyledSpan,
  StyledAmountText,
  StyledAttachmentCol,
  StyledCollapse,
} from './ExpenseDrawer.styled';

const { Text } = Typography;
const { Panel } = Collapse;

interface ExpensesDrawerProps {
  setCurrentExpenseId: (expenseId: string) => void;
  currentExpenseId: string;
  onPrettyIdClickHandler: (processInstanceId: string) => void;
}

const ExpensesDrawerComponent: FC<ExpensesDrawerProps> = ({
  setCurrentExpenseId,
  currentExpenseId,
  onPrettyIdClickHandler,
}) => {
  const onDrawerClose = useCallback(() => setCurrentExpenseId(''), [setCurrentExpenseId]);
  const gutter16 = useRowGutter(16);
  const spanSize10 = 10;
  const spanSize12 = 12;
  const spanSize18 = 18;
  const spanSize6 = 6;
  const { t } = useTranslate();

  const userData = useSelector(profileData);
  const hcmPersonId = userData.data?.hcmId as string;

  const { data: currentExpenseInfo, isLoading, isError } = useExpensesDrawerInfo(
    currentExpenseId,
    hcmPersonId
  );

  return (
    <StyledExpenseDrawer
      closable
      destroyOnClose
      maskClosable
      getContainer={false}
      placement="right"
      visible={typeof currentExpenseId === undefined ? false : true}
      onClose={onDrawerClose}
      width={constants.drawer.width}
    >
      {isError ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
      ) : isLoading ? (
        <FullWidthSpin />
      ) : (
        currentExpenseInfo && (
          <StyledExpenseWrapper>
            <Row gutter={gutter16}>
              <Col span={spanSize12}>
                <StyledTimeStamp type="secondary">
                  {currentExpenseInfo?.expenseCreatedDate
                    ? moment(currentExpenseInfo?.expenseCreatedDate).format('DD/MM/YYYY HH:mm')
                    : null}
                </StyledTimeStamp>
              </Col>
              <StyledCol span={spanSize10}>
                <StyledText type="secondary">
                  <StyledSpan
                    onClick={() => {
                      onPrettyIdClickHandler(currentExpenseInfo?.processInstanceId);
                    }}
                  >
                    {currentExpenseInfo?.prettyId}
                  </StyledSpan>
                </StyledText>
              </StyledCol>
            </Row>
            <Row gutter={gutter16} align="middle">
              <Col span={spanSize18}>
                <TitleH2 strong>{currentExpenseInfo?.expenseName}</TitleH2>
              </Col>
              <StyledCol span={spanSize6}>
                <StatusTag color={getExpenseStatusColor(currentExpenseInfo.status) as TagColor}>
                  {getExpenseStatusText(t)[currentExpenseInfo.status]}
                </StatusTag>
              </StyledCol>
            </Row>
            <Row>
              <Col>
                <StyledDescription strong>
                  {t('profile.expenses.drawer.description.title')}
                </StyledDescription>
              </Col>
            </Row>
            <Row>
              <StyledDescriptionText>{currentExpenseInfo?.description}</StyledDescriptionText>
            </Row>
            <StyledDivider />
            {currentExpenseInfo?.payment && (
              <>
                <Row gutter={gutter16}>
                  <Col>
                    <StyledSectionTitle strong>
                      {t('profile.expenses.drawer.payment.title')}
                    </StyledSectionTitle>
                  </Col>
                </Row>
                <StyledDetailWrapper>
                  <Row gutter={gutter16} align={'middle'}>
                    <Col span={spanSize18}>
                      <Space direction="vertical" size={2}>
                        <Text type="secondary">{t('profile.expenses.drawer.invoiceId')}</Text>
                        <StyledPaymentValueText strong>
                          {currentExpenseInfo?.payment.invoiceID}
                        </StyledPaymentValueText>
                      </Space>
                    </Col>
                    <StyledCol span={spanSize6}>
                      <StatusTag
                        color={
                          getExpenseStatusColor(
                            currentExpenseInfo.payment.invoiceStatus
                          ) as TagColor
                        }
                      >
                        {getExpenseStatusText(t)[currentExpenseInfo.payment.invoiceStatus]}
                      </StatusTag>
                    </StyledCol>
                  </Row>
                  <Row gutter={gutter16} align={'middle'}>
                    <Col span={spanSize18}>
                      <Space direction="vertical" size={2}>
                        <Text type="secondary">{t('profile.expenses.drawer.totalAmount')}</Text>
                        <StyledPaymentValueText strong>
                          {currentExpenseInfo?.payment.invoiceTotalAmount}{' '}
                          {t('profile.expenses.drawer.defaultCurrencyCode')}
                        </StyledPaymentValueText>
                      </Space>
                    </Col>
                  </Row>
                </StyledDetailWrapper>
              </>
            )}
            <Row gutter={gutter16}>
              <Col>
                <StyledSectionTitle strong>
                  {t('profile.expenses.drawer.items.title')}
                </StyledSectionTitle>
              </Col>
            </Row>
            {currentExpenseInfo?.items &&
              currentExpenseInfo?.items.map((item) => (
                <StyledDetailWrapper key={item.id}>
                  <Row gutter={gutter16} align={'middle'}>
                    <Col span={spanSize18}>
                      <Space direction="vertical" size={2}>
                        <StyledPaymentValueText strong>{item.itemType}</StyledPaymentValueText>
                        <Text type="secondary">
                          {t('profile.expenses.drawer.invoiceDate')}{' '}
                          {item?.invoiceDate && moment(item?.invoiceDate).format('DD/MM/YYYY')}
                        </Text>
                      </Space>
                    </Col>
                    <StyledCol span={spanSize6}>
                      <StyledDescription> {item.itemLabel} </StyledDescription>
                    </StyledCol>
                  </Row>
                  <Row gutter={gutter16} align={'middle'}>
                    <Col>
                      <StyledAmountText strong>
                        {item.amount} {t('profile.expenses.drawer.defaultCurrencyCode')}
                      </StyledAmountText>
                    </Col>
                  </Row>
                  <StyledDivider />
                  {currentExpenseInfo?.attachments && currentExpenseInfo?.attachments?.length > 0 && (
                    <StyledCollapse defaultActiveKey={DEFAULT_COLLAPSE_KEY} ghost>
                      <Panel
                        header={
                          <StyledAttachmentsTitle>
                            {t('profile.expenses.drawer.attachments')}
                          </StyledAttachmentsTitle>
                        }
                        key="1"
                      >
                        <Row gutter={gutter16}>
                          <StyledAttachmentCol>
                            <Space direction="vertical" size={6}>
                              {currentExpenseInfo?.attachments?.map(
                                ({ id, itemId, taskId, name }) =>
                                  itemId === item.id && (
                                    <TaskAttachment id={id} taskId={taskId} name={name} key={id} />
                                  )
                              )}
                            </Space>
                          </StyledAttachmentCol>
                        </Row>
                      </Panel>
                    </StyledCollapse>
                  )}
                </StyledDetailWrapper>
              ))}
          </StyledExpenseWrapper>
        )
      )}
    </StyledExpenseDrawer>
  );
};

export const ExpensesDrawer = memo(ExpensesDrawerComponent);

import { TaskDiagramLink } from 'types';
import { Core, TASK_DIAGRAM } from 'api';

interface GetTaskDiagram {
  (processDefinitionId: string, rootPath?: string): Promise<{ data: TaskDiagramLink }>;
}

class TaskDiagram extends Core {
  getTaskDiagram: GetTaskDiagram = async (processDefinitionId, rootPath?: string) =>
    this.get(TASK_DIAGRAM(processDefinitionId, rootPath));
}

export const taskDiagram = new TaskDiagram();

import { UsersData } from 'types';
import { Core, USERS } from 'api';
import { AxiosRequestConfig } from 'axios';

type UsersType = (params: AxiosRequestConfig) => Promise<{ data: UsersData }>;

class Users extends Core {
  getUsers: UsersType = (params) => this.get(USERS(), params);
}

export const users = new Users();

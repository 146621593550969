import { Core, EMPLOYEE_DIRECTORY } from 'api';
import { EmployeeDirectorySearchResponse } from 'types';

interface SearchForEmployees {
  (params: { searchParams: string | null; offset: number; limit: number }): Promise<{
    data: EmployeeDirectorySearchResponse;
  }>;
}

class EmployeeDirectory extends Core {
  searchForEmployees: SearchForEmployees = async (params) =>
    this.get(EMPLOYEE_DIRECTORY(), { params });
}

export const employeeDirectory = new EmployeeDirectory();

import React, { FC, useCallback, useState } from 'react';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { IELibraryDepartmentListBySector } from 'types';
import { useTranslate } from 'translations';
import { Filter } from 'ui';
import { FilterContextType } from 'ui/components/Filter/Filter.types';
import { useELibraryDepartments, useELibraryDocumentTypes, useELibrarySectors } from '../../../api';

export const eLibraryFilterItems = {
  documentName: 'documentName',
  documentId: 'documentId',
  documentType: 'documentType',
  expiryDate: 'expiryDate',
  sector: 'sector',
  department: 'department',
};

interface ELibraryFilterProps {
  setFilterValues: (values: FilterContextType['filterValues']) => void;
  filterValues: FilterContextType['filterValues'];
  resetFilter: boolean;
}

export const ELibraryFilter: FC<ELibraryFilterProps> = ({
  setFilterValues,
  filterValues,
  resetFilter,
}: ELibraryFilterProps) => {
  const history = useHistory();
  const { t } = useTranslate();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const [selectedSector, setSelectedSector] = useState(
    filterValues[eLibraryFilterItems.sector] ?? ''
  );
  const [departments, setDepartments] = useState<IELibraryDepartmentListBySector>([]);

  const documentTypes = useELibraryDocumentTypes(isFilterOpen);
  const { data: sectors } = useELibrarySectors();
  const { mutateAsync: getDepartements, isLoading: loadingDepartements } = useELibraryDepartments();

  const sectorsOptions = useCallback(() => {
    return sectors?.map((sector) => ({
      label: sector,
      value: sector,
      text: sector,
    }));
  }, [sectors]);

  const departOptions = useCallback(() => {
    return departments?.map((departement) => ({
      label: departement,
      value: departement,
      text: departement,
    }));
  }, [departments]);

  const fetchDepartements = (sector: string) => {
    getDepartements(sector as string)
      .then(setDepartments)
      .catch(() => {
        notification.error({
          message: 'Something went wrong',
        });
      });
  };

  const doctypeOptions = useCallback(() => {
    return documentTypes?.data?.map((documentType: string) => ({
      label: documentType,
      value: documentType,
      text: documentType,
    }));
  }, [documentTypes.data]);

  const isDepartmentEnabled = useCallback(() => {
    return !selectedSector;
  }, [selectedSector]);

  const handleOnValueChange = (field: { [key: string]: string }) => {
    const keys = Object.keys(field);

    if (keys[0] === eLibraryFilterItems.sector) {
      setSelectedSector(field[eLibraryFilterItems.sector]);
      fetchDepartements(field[eLibraryFilterItems.sector]);
    }
  };

  const handleOnReset = (isReset: boolean) => {
    if (isReset) {
      setSelectedSector('');
    }
  };

  const onTagRemoved = (tagName: string) => {
    if (tagName === eLibraryFilterItems.sector) {
      setSelectedSector('');
    }
  };

  const onShowPopOver = useCallback(
    (isOpen: boolean) => {
      setIsFilterOpen(isOpen);
      setSelectedSector(filterValues?.[eLibraryFilterItems.sector]);
    },
    [filterValues]
  );

  return (
    <Filter
      items={[
        {
          type: 'input',
          name: eLibraryFilterItems.documentName,
          placeholder: t('eLibrary.filter.documentName'),
        },
        {
          type: 'input',
          name: eLibraryFilterItems.documentId,
          placeholder: t('eLibrary.filter.documentId'),
        },
        {
          type: 'select',
          name: eLibraryFilterItems.documentType,
          placeholder: t('eLibrary.filter.documentType'),
          options: doctypeOptions(),
        },
        {
          type: 'datePicker',
          name: eLibraryFilterItems.expiryDate,
          placeholder: t('eLibrary.filter.expiryDate'),
        },
        {
          type: 'select',
          name: eLibraryFilterItems.sector,
          placeholder: t('eLibrary.filter.sector'),
          options: sectorsOptions(),
        },
        {
          type: 'select',
          name: eLibraryFilterItems.department,
          placeholder: t('eLibrary.filter.department'),
          options: departOptions(),
          disabled: isDepartmentEnabled() || loadingDepartements,
          disabledTooltip: 'You have to select a sector first',
        },
      ]}
      t={t}
      history={history}
      isLoading={false}
      onFilterCallback={setFilterValues}
      onValuesChange={handleOnValueChange}
      onResetFilterCallback={handleOnReset}
      dateFormat={'DD-MM-YYYY'}
      enableQueryParams={false}
      initialFilterValues={filterValues}
      onTagRemoved={onTagRemoved}
      onShowPopOver={onShowPopOver}
      resetFilter={resetFilter}
    />
  );
};

import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  getRootFolderFail,
  getRootFolderStart,
  getRootFolderSuccess,
  GetRootFolderFail,
  GetRootFolderStart,
  GetRootFolderSuccess,
} from './oneDriveRootFolderSlice';

export const getOneDrive: Epic<
  GetRootFolderStart,
  GetRootFolderSuccess | GetRootFolderFail,
  RootState,
  typeof API
> = (action$, _, { rootFolder }) =>
  action$.pipe(
    filter(getRootFolderStart.match),
    mergeMap(() =>
      from(Promise.all([rootFolder.getRootFolder(), rootFolder.getRootFolderChildren()])).pipe(
        map(([{ data: rootFolderData }, { data: rootFolderChildrenData }]) =>
          getRootFolderSuccess({ ...rootFolderData, children: rootFolderChildrenData })
        ),
        catchError((error) => of(getRootFolderFail(error?.response?.data)))
      )
    )
  );

import React, { FC, useMemo } from 'react';
import { TType } from 'translations';
import { IProfileDependentDetail } from 'types/NewProfile';
import { PrivateProfileDependentDetailItem } from '../PrivateProfileDependentDetailItem';

type Props = {
  t: TType;
  dependentDetails: IProfileDependentDetail[];
};

export const PrivateProfileDependentDetails: FC<Props> = ({ t, dependentDetails }) => {
  const listOfDependentDetails: IProfileDependentDetail[] = useMemo(() => {
    return dependentDetails;
  }, [dependentDetails]);

  return (
    <>
      {listOfDependentDetails.map((item, index) => (
        <PrivateProfileDependentDetailItem
          key={index}
          dateOfBirth={item?.dateOfBirth}
          idNumber={item.idNumber}
          relationship={item.relationship}
          t={t}
          title={item?.fullName}
          dependentAttachmentList={item.dependentAttachmentList}
        />
      ))}
    </>
  );
};

import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

export const TitleH2 = styled(Text).attrs({
  level: 2,
})`
  &.ant-typography {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 24px;
  }
  &.ant-typography.ant-typography-secondary {
    color: #8b93a2;
    font-weight: normal;
  }
`;

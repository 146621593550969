import React, { FC, useCallback, useEffect, useState } from 'react';
import { Row, Col, Space, Typography } from 'antd';
import { ForkOutlined, MessageOutlined } from '@ant-design/icons';
import {
  useDispatch,
  useSelector,
  getTaskDiagramStart,
  taskDiagramData,
  getTaskDiagramReset,
} from 'store';
import { useTasksContext } from 'routes/Tasks/TasksProvider';
import { RequestStatus, TaskDefinitionKey, TaskDefinitionType } from 'types';
import { useRowGutter } from 'ui/hooks';
import { span } from 'ui/elements';
import { useTranslate } from 'translations';
import { StyledH2 } from '../TaskDescription/TaskDescription.styled';
import { RequestInformation } from '../helper';
import { DiagramModal } from './DiagramModal/DiagramModal';
import { EndEvent } from './EndEvent';
import {
  StyledButtonWithOutText,
  StyledRequestId,
  StyledRow,
  StyledCard,
} from './RequestHeader.style';

interface RequestHeaderProps {
  requestHeaderInfo?: RequestInformation;
  taskDefinitionKey?: TaskDefinitionKey;
}

const { Text } = Typography;

const disableLease = process.env.REACT_APP_DISABLE_LEASE === 'true';

export const RequestHeader: FC<RequestHeaderProps> = ({
  requestHeaderInfo: {
    isRequestStatusComplete,
    requestName,
    requestId,
    requestDescription,
    requestPrettyId,
    processDefinitionId,
    endEvent,
    commentsCount,
  } = {},
  taskDefinitionKey,
}) => {
  const { t } = useTranslate();
  const { setCommentsRequestId, rootPath } = useTasksContext();
  const dispatch = useDispatch();
  const processDiagram = useSelector(taskDiagramData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [xmlDiagram, setXmlDiagram] = useState<string>();
  const GUTTER_12 = useRowGutter(12);
  const { span24 } = span;

  const onCommentsClick = useCallback(() => requestId && setCommentsRequestId(requestId), [
    requestId,
    setCommentsRequestId,
  ]);

  const onClickProcessDiagram = useCallback(() => {
    if (processDefinitionId) {
      dispatch(
        getTaskDiagramStart({
          processDefinitionId: processDefinitionId,
          rootPath,
        })
      );
      setIsLoading(true);
      setIsVisible(true);
    }
  }, [dispatch, processDefinitionId, rootPath]);

  useEffect(() => {
    if (processDiagram.status === RequestStatus.resolved && processDiagram.data) {
      setIsLoading(false);
      const xmlDiagram = processDiagram.data.bpmn20Xml;
      setXmlDiagram(xmlDiagram);
    }
  }, [processDiagram.status, processDiagram.data]);

  const handleResetXml = useCallback(() => {
    dispatch(getTaskDiagramReset());
    setXmlDiagram(undefined);
    setIsVisible(false);
  }, [dispatch]);

  return (
    <StyledCard
      $normalPadding={taskDefinitionKey === TaskDefinitionType.SITE_ACQUISITION_REQUEST_FORM_TASK}
    >
      <Row justify={'space-between'} gutter={GUTTER_12}>
        <Col span={19}>
          <Space direction="vertical">
            <Space direction="horizontal">
              <StyledH2 ellipsis={{ tooltip: requestName }}>{requestName}</StyledH2>
              <EndEvent isVisible={isRequestStatusComplete} endEvent={endEvent} />
            </Space>
            <StyledRequestId type="secondary">
              {disableLease
                ? requestPrettyId
                : t('tasks.requestId', { requestId: requestPrettyId })}
            </StyledRequestId>
          </Space>
        </Col>
        <Col>
          <Space align="baseline" size="large">
            <>
              <StyledButtonWithOutText onClick={onCommentsClick}>
                <Space direction="horizontal">
                  <MessageOutlined />
                  {commentsCount && commentsCount > 0 ? commentsCount : null}
                </Space>
              </StyledButtonWithOutText>
              <StyledButtonWithOutText onClick={onClickProcessDiagram} loading={isLoading}>
                <ForkOutlined />
              </StyledButtonWithOutText>
            </>

            {xmlDiagram ? (
              <DiagramModal
                xmlDiagram={xmlDiagram}
                handleResetXml={handleResetXml}
                isVisible={isVisible}
              />
            ) : null}
          </Space>
        </Col>
      </Row>
      <StyledRow gutter={GUTTER_12}>
        <Col span={span24}>
          <Text type="secondary">{requestDescription}</Text>
        </Col>
      </StyledRow>
    </StyledCard>
  );
};

import { useQuery } from 'react-query';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';

import { settings } from 'api/Settings';

export function useNotificationSettings() {
  return useQuery(
    [QUERIES.notificationSettings],
    async () => {
      const { data } = await settings.getNotificationSettings();
      return data;
    },
    PERSIST_QUERY_CONFIG
  );
}

import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Form, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormField } from 'types';
import { useGetFromFieldId } from 'ui/hooks';
import { StyledCompleteButton, StyledRejectActionButton } from '../GeneratedForm.Styled';
import { useDisableFieldDependOnOtherField } from './FieldButton.hooks';

interface ButtonProps {
  field: FormField;
  form?: FormInstance;
  taskId?: string;
}

// same as FieldButton, however supports `draft` type
// is added to handle placement case with bottom action panel
export const FieldActionButton: FC<ButtonProps> = ({ field, form, taskId }) => {
  const disableFieldDependOnOtherField = useDisableFieldDependOnOtherField(taskId);
  const getFieldId = useGetFromFieldId();
  const fieldId = getFieldId(field.id);
  const button = useMemo(() => {
    if (field.id === 'submit' || field.id === 'approved') {
      return (
        <StyledCompleteButton
          disabled={
            field.properties.viewOnly === 'true' || disableFieldDependOnOtherField('approved')
          }
          loading={field.properties?.actionButtonLoading}
          htmlType="submit"
          onClick={() =>
            form?.setFieldsValue({
              [fieldId]: true,
              ...(field.id === 'approved' && { rejected: false }),
            })
          }
        >
          {field.label}
        </StyledCompleteButton>
      );
    }

    if (field.id === 'reject' || field.id === 'rejected') {
      return (
        <StyledRejectActionButton
          disabled={
            field.properties.viewOnly === 'true' || disableFieldDependOnOtherField('rejected')
          }
          htmlType={field.properties?.actionButtonHtmlType ?? 'submit'}
          loading={field.properties?.actionButtonLoading}
          onClick={() => {
            form?.setFieldsValue({
              [fieldId]: true,
              ...(field.id === 'rejected' && { approved: false }),
            });
            if (field.properties.onActionButtonClick) {
              field.properties.onActionButtonClick();
            }
          }}
        >
          {field.label}
        </StyledRejectActionButton>
      );
    }

    if (field.id === 'draft') {
      return (
        <Button
          disabled={field.properties.viewOnly === 'true'}
          htmlType="button"
          loading={field.properties?.actionButtonLoading}
          onClick={() => {
            if (field.properties.onActionButtonClick) {
              field.properties.onActionButtonClick();
            }
          }}
        >
          {field.label}
        </Button>
      );
    }
  }, [disableFieldDependOnOtherField, field.id, field.label, field.properties, fieldId, form]);

  return button ? (
    <Form.Item name={field.id} valuePropName="button">
      {button}
    </Form.Item>
  ) : null;
};

interface ButtonsProps {
  fields: FormField[];
  form?: FormInstance;
  taskId?: string;
  fixed?: boolean;
  spaceBetween?: boolean;
}

const Wrapper = styled.div<{ $fixed?: boolean; $spaceBetween?: boolean }>`
    width: 100%;
    display: flex;
    justify-content: ${({ $spaceBetween }) => ($spaceBetween ? 'space-between' : 'flex-start')};
    gap: 24px;

    ${({ $fixed }) =>
      $fixed &&
      `
    position: fixed;
    bottom: -9px;
    z-index: 1;
  `}
}
`;

export const FieldActionButtons: FC<ButtonsProps> = ({
  fields,
  taskId,
  form,
  fixed,
  spaceBetween,
}) => {
  const getFieldId = useGetFromFieldId();

  return (
    <Wrapper
      className="formButtonRow actionButtonsWrapper"
      $fixed={fixed}
      $spaceBetween={spaceBetween}
    >
      {fields.map((actionButtonField) => (
        <FieldActionButton
          key={getFieldId(actionButtonField.id)}
          field={actionButtonField}
          form={form}
          taskId={taskId}
        />
      ))}
    </Wrapper>
  );
};

import React, { FC, Fragment, memo, useCallback, useEffect, useState } from 'react';
import { Empty, Form, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { VALIDATE_MESSAGES } from 'utils';
import { TType } from 'translations';
import { CreditCardName, FullWidthSpin } from 'ui';
import { useCreditCardDetail } from 'api/StaticForm/hooks';
import {
  AttachmentFile,
  attachmentsFieldsId,
} from 'routes/Tasks/components/pages/ServiceNewRequest/ServiceNewRequest';
import { useCreditCardSubmit } from 'api/StaticForm/hooks/CreditCard/useCreditCardMutation';
import { submitAttachmentForm } from 'ui/publicFunction';
import { useHistory } from 'react-router-dom';
import { useHandleSubmitStatus } from 'routes/Tasks';
import { CreditCardFields } from './CreditCardFields';

export interface CreditCardProps {
  formName?: string;
  adoId: string;
  processDefinitionKey: string;
  t: TType;
}

export const CreditCardComponent: FC<CreditCardProps> = ({
  formName,
  adoId,
  processDefinitionKey,
  t,
}) => {
  const [attachmentData, setAttachmentData] = useState<AttachmentFile[]>([]);
  const [form] = useForm();
  const mutation = useCreditCardSubmit(adoId);
  const history = useHistory();
  const { data: creditCardDetail, isLoading, isError } = useCreditCardDetail(
    adoId,
    processDefinitionKey
  );

  useEffect(() => {
    if (creditCardDetail?.deptCostCenter) {
      form.setFieldsValue({
        [CreditCardName.costCenterDepartment]: creditCardDetail?.deptCostCenter,
      });
    }
  }, [creditCardDetail?.deptCostCenter, form]);

  const onSubmitFinish = useCallback(
    async (variables) => {
      const attachmentsForm: AttachmentFile[] = [];
      await mutation.mutate(variables);

      const currentFormFieldsName = Object.keys(variables);

      const currentAttachmentId = currentFormFieldsName.filter((formVariables: string) => {
        return attachmentsFieldsId.includes(formVariables);
      });
      if (currentAttachmentId && variables) {
        currentAttachmentId.forEach((fieldId: string) => {
          const attachmentValue = variables[fieldId];
          if (attachmentValue) {
            return attachmentsForm.push({ [fieldId]: attachmentValue });
          }
        });
      }

      if (attachmentsForm) {
        setAttachmentData(attachmentsForm);
      }
    },
    [mutation]
  );

  useHandleSubmitStatus(t, mutation.isLoading, mutation.isError, mutation.isSuccess);

  useEffect(() => {
    mutation.data &&
      submitAttachmentForm(mutation.isSuccess, mutation.data, history, undefined, attachmentData);
  }, [attachmentData, history, mutation.data, mutation.isSuccess]);

  return (
    <Fragment>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        validateMessages={VALIDATE_MESSAGES(t)}
        onFinish={onSubmitFinish}
      >
        {isError ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
        ) : isLoading ? (
          <FullWidthSpin />
        ) : (
          <Spin spinning={mutation.isLoading} delay={100}>
            <CreditCardFields t={t} formFieldDate={creditCardDetail} form={form} />
          </Spin>
        )}
      </Form>
    </Fragment>
  );
};

export const CreditCard = memo(CreditCardComponent);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { RequestCommonError, RequestStatus } from 'types';
import { LeavesListData, LeavesListState } from './leavesListTypes';

export const emptyLeavesListState: LeavesListState = {
  status: RequestStatus.idle,
  error: null,
  data: {
    list: [],
    totalResult: 0,
    offset: 0,
    limit: 0,
  },
};

export type GetLeavesListStart = PayloadAction<{
  hcmPersonId: string;
  params?: AxiosRequestConfig['params'];
}>;
export type GetLeavesListSuccess = PayloadAction<LeavesListData>;
export type GetLeavesListFail = PayloadAction<{ error: RequestCommonError }>;

const leavesList = createSlice({
  name: 'leavesList',
  initialState: emptyLeavesListState,
  reducers: {
    getLeavesListStart(state, _: GetLeavesListStart) {
      state.status = RequestStatus.pending;
    },
    getLeavesListSuccess(state, action: GetLeavesListSuccess) {
      state.status = RequestStatus.resolved;
      state.error = null;
      state.data = action.payload;
    },
    getLeavesListFail(state, action: GetLeavesListFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload.error;
    },
  },
});

export const { getLeavesListFail, getLeavesListStart, getLeavesListSuccess } = leavesList.actions;

export default leavesList.reducer;

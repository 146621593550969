import React, { FC, useCallback } from 'react';
import { Col, Row, Typography } from 'antd';
import { Gutter } from 'antd/lib/grid/row';

import { UserActivityStatus, UserInfoData, USER_STATUS_MAP, RequestStatus } from 'types';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { PagesUrls } from 'namespace';
import { Link, useLocation } from 'react-router-dom';
import {
  ModalCard,
  UserName,
  UserDepartment,
  UserJobTitle,
  WrapInfo,
  ProfileWrapRow,
  StyledEmployeeIdCol,
  StyledJobInfo,
  StyledStatusBadge,
} from './UserInformation.styled';

interface UserInfoPopOverProps {
  data?: UserInfoData;
  userStatus?: UserActivityStatus | null;
  requestStatus?: string;
}

const { Text } = Typography;
const Gutter_16_0: [Gutter, Gutter] = [16, 0];
export const UserInfromation: FC<UserInfoPopOverProps> = ({ data, userStatus, requestStatus }) => {
  const location = useLocation();
  const getUrl = useCallback((pagePath: string) => `${pagePath}${location.search}`, [
    location.search,
  ]);

  return (
    <ModalCard>
      <Row justify="start" gutter={Gutter_16_0}>
        <Col span={7}>
          <ProfileWrapRow>
            <Col span={24}>
              <Link to={getUrl(`${PagesUrls.OtherUserProfile}/${data?.email}`)}>
                <StyledStatusBadge
                  offset={[-10, 49]}
                  {...(requestStatus === RequestStatus.resolved && { dot: true })}
                  {...(userStatus && { color: USER_STATUS_MAP[userStatus]?.color })}
                >
                  <MSTeamsUserAvatar
                    id={data?.adOid}
                    statusSize={12}
                    size={60}
                    name={data?.displayName}
                  />
                </StyledStatusBadge>
              </Link>
            </Col>
            {data?.employeeId && (
              <StyledEmployeeIdCol span={24}>{data?.employeeId}</StyledEmployeeIdCol>
            )}
          </ProfileWrapRow>
        </Col>
        <Col span={17}>
          <WrapInfo>
            <Col span={24}>
              <UserName>
                <Link to={getUrl(`${PagesUrls.OtherUserProfile}/${data?.email}`)}>
                  <Text>{data?.displayName}</Text>
                </Link>
              </UserName>
            </Col>
            <StyledJobInfo>
              <Col span={24}>
                <UserJobTitle>{data?.jobTitle}</UserJobTitle>
              </Col>
              <Col span={24}>
                <UserDepartment>{data?.department}</UserDepartment>
              </Col>
            </StyledJobInfo>
          </WrapInfo>
        </Col>
      </Row>
    </ModalCard>
  );
};

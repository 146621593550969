import { Card, Divider, Typography, Col } from 'antd';
import styled from 'styled-components';
import { colors } from 'ui/elements';

export const StyledCard = styled(Card)`
  border-left: ${(props: { $highlighted?: boolean }) =>
    props.$highlighted ? '4px solid ' + colors.primaryColor : 'none'};
`;

const { Text } = Typography;
export const StyledDivider = styled(Divider)`
  margin: 10px 0;
`;

export const StyledText = styled(Text).attrs({})`
  font-size: ${(props: { size?: number }) => (props.size ? props.size + 'px' : 'inherit')};

  &.ant-typography {
    display: inline;
  }
`;

export const FormCol = styled(Col)`
  .formButtonRow {
    position: fixed;
    bottom: -9px;
    z-index: 1;
  }
  .actionButtonsWrapper {
    display: flex;
    gap: 24px;
  }
`;

export const StyledReassignWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledReassignUserInfo = styled.span`
  white-space: nowrap;
`;

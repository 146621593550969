import { Col, Row, Space, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { SPAN_SIZE_3 } from 'routes';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { useRowGutter } from 'ui';
import { AssigneeTitle } from './component.style';

interface Props {
  title: string;
  assigneeValue?: string;
  assigneeId: string;
}

const AssigneeInfoComponent: FC<Props> = ({ title, assigneeId, assigneeValue }) => {
  const gutter_10 = useRowGutter(10);
  const { Text } = Typography;
  return (
    <React.Fragment>
      <Space direction={'vertical'} size={'small'}>
        <AssigneeTitle>{title}</AssigneeTitle>
        <Row justify={'space-between'} gutter={gutter_10}>
          <Col span={SPAN_SIZE_3}>
            <MSTeamsUserAvatar id={assigneeId} name={assigneeValue} size={25} />
          </Col>
          <Col>
            <Text style={{ fontSize: 16 }} strong>
              {assigneeValue}
            </Text>
          </Col>
        </Row>
      </Space>
    </React.Fragment>
  );
};

export const AssigneeInfo = memo(AssigneeInfoComponent);

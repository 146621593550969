import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { notification } from 'antd';
import { QUERIES, task as taskApi, leaseSites } from 'api';
import { useTranslate } from 'translations';
import { FormFields, TaskDefinitionType } from 'types';
import { SITE_ACQUISITION_REQUEST_FORM_CUSTOM_FIELDS } from './useTaskFormData.constants';

const useGetUpsertedDataBasedOnActivityId = (activityId: string) => {
  return useCallback(
    async (data: FormFields): Promise<FormFields> => {
      const isSiteAcquisitionRequestForm =
        activityId === TaskDefinitionType.SITE_ACQUISITION_REQUEST_FORM_TASK;

      if (isSiteAcquisitionRequestForm) {
        const response = await leaseSites.getAssignWoTaskFormDetails().catch(() => ({
          data: {
            assigneeTypes: [],
            subContractors: [],
          },
        }));
        return SITE_ACQUISITION_REQUEST_FORM_CUSTOM_FIELDS(response.data);
      }

      return data;
    },
    [activityId]
  );
};

export function useTaskFormData({
  taskId,
  rootPath,
  isMyGroupTask,
  isCurrentUser,
  activityId,
}: {
  taskId: string;
  rootPath?: string;
  activityId: string;
  isCurrentUser: boolean;
  isMyGroupTask: boolean;
}) {
  const { t } = useTranslate();
  const getUpsertedData = useGetUpsertedDataBasedOnActivityId(activityId);

  return useQuery(
    [QUERIES.taskFormData, taskId, rootPath, activityId, isCurrentUser, isMyGroupTask],
    async () => {
      const { data } = await taskApi.getFormData(taskId, rootPath);
      const upsertedData = await getUpsertedData(data);
      const formData: FormFields =
        isCurrentUser || isMyGroupTask
          ? upsertedData
          : upsertedData
              .filter((formItem) => formItem.typeName !== 'button')
              .map((formItem) => ({
                ...formItem,
                properties: { ...formItem.properties, viewOnly: 'true' },
                validationConstraints: [],
              }));
      return formData;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      onError() {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: t('messages.error.tryAgainLater'),
        });
      },
    }
  );
}

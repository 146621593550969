import styled from 'styled-components';
import { colors } from 'ui/elements';

export const BreadcrumbWrapper = styled.div`
  padding: 10px 24px;
  span,
  .ant-breadcrumb a {
    color: ${colors.lightGrey};
  }
`;
export const StyleLink = styled.a`
  color: ${colors.primaryColor};
`;

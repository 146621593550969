import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OneDriveItemChildrenData, RequestCommonError, RequestStatus, OneDriveFolder } from 'types';
import { OneDriveItemChildrenState } from './oneDriveItemChildrenTypes';

export const emptyOneDriveItemChildrenInitialState: OneDriveItemChildrenState = {};

export type GetOneDriveItemChildrenStart = PayloadAction<string>;
export type GetOneDriveItemChildrenSuccess = PayloadAction<{
  folderId: string;
  data: OneDriveItemChildrenData;
}>;
export type GetOneDriveItemChildrenFail = PayloadAction<{
  folderId: string;
  error: RequestCommonError;
}>;
export type UpdateOneDriveItemChildren = PayloadAction<{
  folderId: string;
  folder: OneDriveFolder;
}>;

const oneDriveItemChildren = createSlice({
  name: 'oneDriveItemChildren',
  initialState: emptyOneDriveItemChildrenInitialState,
  reducers: {
    getOneDriveItemChildrenStart(state, action: GetOneDriveItemChildrenStart) {
      state[action.payload] = {
        ...state[action.payload],
        status: RequestStatus.pending,
      };
    },
    getOneDriveItemChildrenSuccess(state, action: GetOneDriveItemChildrenSuccess) {
      state[action.payload.folderId].status = RequestStatus.resolved;
      state[action.payload.folderId].data = action.payload.data;
    },
    getOneDriveItemChildrenFail(state, action: GetOneDriveItemChildrenFail) {
      state[action.payload.folderId].status = RequestStatus.rejected;
      state[action.payload.folderId].error = action.payload.error;
    },
  },
});

export const {
  getOneDriveItemChildrenStart,
  getOneDriveItemChildrenSuccess,
  getOneDriveItemChildrenFail,
} = oneDriveItemChildren.actions;

export default oneDriveItemChildren.reducer;

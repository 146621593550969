import React, { FC, useCallback, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { useTranslate } from 'translations';
import { LoadingOutlined } from '@ant-design/icons';
import { TawalLogoShortImage } from 'assets';
import { useDashboardApps } from 'api/DashboardApps';
import { colors } from 'ui/elements';
import {
  DashboardTitleStyled,
  FullWidthSpinWrapper,
} from 'ui/components/OneDrive/OneDriveWidget/OneDriveWidget.styled';
import { APPS_TITLE_SIZE, DEFAULT_ITEM_SPAN, styleSpinner } from 'routes/Dashboard';
import { AppsDrawer } from '../AppsDrawer';
import { StyledHeaderWidgetCol } from '../../organisms/YourRequestWidget/YourRequestWidget.style';
import {
  StyledAppCol,
  StyledAppWidgetRow,
  StyledAppsRow,
  StyledAppRedirectLink,
  AppTitleStyled,
  AppViewAllStyled,
} from './AppsWidget.styled';

export const AppsWidget: FC = () => {
  const { t } = useTranslate();
  const appsList = useDashboardApps();
  const [viewAllApps, setViewAllApps] = useState<boolean>(false);
  const antIcon = <LoadingOutlined style={styleSpinner} spin />;
  const viewAllHandler = useCallback(() => {
    setViewAllApps(!viewAllApps);
  }, [viewAllApps]);

  const onDrawerClose = useCallback(() => {
    setViewAllApps(false);
  }, []);

  return (
    <StyledAppWidgetRow>
      <StyledHeaderWidgetCol span={DEFAULT_ITEM_SPAN}>
        <Row justify="space-between" align={'middle'}>
          <Col>
            <DashboardTitleStyled size={APPS_TITLE_SIZE} strong color={colors.textColorSecondary}>
              {t('dashboard.app.moreApps.title')}
            </DashboardTitleStyled>
          </Col>
          <Col>
            <AppViewAllStyled onClick={viewAllHandler}>{t('common.viewAll')}</AppViewAllStyled>
          </Col>
        </Row>
      </StyledHeaderWidgetCol>
      <StyledAppsRow>
        {appsList.isLoading ? (
          <FullWidthSpinWrapper>
            <Spin indicator={antIcon} />
          </FullWidthSpinWrapper>
        ) : (
          <>
            {appsList.data?.map((item, i) => {
              return (
                i <= 2 && (
                  <StyledAppRedirectLink
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    key={item.id}
                  >
                    <StyledAppCol
                      bgColor={colors.greyBackLabel}
                      color={colors.textColorSecondary}
                      focusBgColor={colors.greyBackLabelHover}
                    >
                      <TawalLogoShortImage />
                      <AppTitleStyled color={colors.textColorSecondary}>
                        {item.appName}
                      </AppTitleStyled>
                    </StyledAppCol>
                  </StyledAppRedirectLink>
                )
              );
            })}
          </>
        )}
      </StyledAppsRow>
      {viewAllApps && appsList.data && (
        <AppsDrawer appsList={appsList.data} onDrawerClose={onDrawerClose} />
      )}
    </StyledAppWidgetRow>
  );
};

import { connect, ConnectedProps } from 'react-redux';

import { RootState, getPaySlipsStart, paySlipsData } from 'store';

const mapDispatch = {
  getPaySlipsStart,
};

const mapState = (state: RootState) => ({
  paySlipsData: paySlipsData(state),
});

export const paySlipsConnector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof paySlipsConnector>;

export type ConnectorProps = PropsFromRedux;

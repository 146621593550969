import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';
import * as API from 'api';
import { RootState } from 'store';
import { Epic } from 'store/types';
import {
  getAllNotificationUpdatesListFil,
  getAllNotificationUpdatesListStart,
  getAllNotificationUpdatesListSuccess,
  GetAllNotificationUpdatesListFail,
  GetAllNotificationUpdatesListStart,
  GetAllNotificationUpdatesListSuccess,
} from './allNotificationUpdatesListSlice';

export const allNotificationUpdatesListEpic: Epic<
  GetAllNotificationUpdatesListStart,
  GetAllNotificationUpdatesListSuccess | GetAllNotificationUpdatesListFail,
  RootState,
  typeof API
> = (action$, _, { notificationCenter }) =>
  action$.pipe(
    filter(getAllNotificationUpdatesListStart.match),
    exhaustMap((action) =>
      from(
        notificationCenter.getAllNotificationUpdatesList(
          action.payload.requestId,
          action.payload.params
        )
      ).pipe(
        map(
          ({ data }) => getAllNotificationUpdatesListSuccess(data),
          catchError((error) => of(getAllNotificationUpdatesListFil(error?.response.data)))
        )
      )
    )
  );

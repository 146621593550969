import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, LeavesHistoryData } from 'types';
import { LeavesHistoryState } from './leavesHistoryTypes';

export const emptyLeavesHistoryInitialState: LeavesHistoryState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetLeavesHistoryStart = PayloadAction<string>;
export type GetLeavesHistorySuccess = PayloadAction<LeavesHistoryData>;
export type GetLeavesHistoryFail = PayloadAction<RequestCommonError>;

const leavesHistory = createSlice({
  name: 'leavesHistory',
  initialState: emptyLeavesHistoryInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getLeavesHistoryStart(state, _: GetLeavesHistoryStart) {
      state.status = RequestStatus.pending;
    },
    getLeavesHistorySuccess(state, action: GetLeavesHistorySuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getLeavesHistoryFail(state, action: GetLeavesHistoryFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getLeavesHistoryStart,
  getLeavesHistorySuccess,
  getLeavesHistoryFail,
} = leavesHistory.actions;
export default leavesHistory.reducer;

import { FormInstance } from 'antd/lib/form';
import React, { FC, memo, Fragment } from 'react';
import { normFile } from 'routes/Calendar';
import { StaticFormTemplate } from 'routes/Tasks/components';
import { TType } from 'translations';
import { CreateFormItemType } from 'types';
import { CommonFieldName, HomeLoanName, MAX_COMMENT_LENGTH, WRAPPER_COL_SPAN_SIZE_21 } from 'ui';
import { FORM_ITEM_REQUIRED_RULE } from 'utils';

interface HomeLoanProps {
  t: TType;
  form?: FormInstance;
}
const HomeLoanFieldsComponents: FC<HomeLoanProps> = ({ t, form }) => {
  const getItems = () => {
    const res: CreateFormItemType[] = [];
    res.push(
      {
        id: `${0}`,
        label: t(`homeLoan.staticForm.label.Department`),
        name: HomeLoanName.department,
        type: 'input',
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        disabled: true,
      },
      {
        id: `${1}`,
        label: t(`homeLoan.staticForm.label.JoiningDate`),
        name: HomeLoanName.joiningDate,
        type: 'date',
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        disabled: true,
        format: 'DD.MM.YYYY',
      },
      {
        id: `${2}`,
        label: t(`homeLoan.staticForm.label.WorkingYears`),
        name: HomeLoanName.workingYears,
        type: 'inputNumber',
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        disabled: true,
      },
      {
        id: `${3}`,
        label: t(`homeLoan.staticForm.label.DoYouHaveOngoingHomeLoan`),
        name: HomeLoanName.ongoingHomeLoan,
        type: 'select',
        options: [
          { value: t(`yes`), label: t(`yes`) },
          { value: t(`no`), label: t(`no`) },
        ],
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        placeholder: t('homeLoan.staticForm.placeholder.DoYouHaveOngoingHomeLoan'),
      },
      {
        id: `${4}`,
        label: t(`homeLoan.staticForm.label.Details`),
        name: HomeLoanName.details,
        type: 'textArea',
        maxNumber: MAX_COMMENT_LENGTH,
        placeholder: t('tasks.textArea.placeHolder'),
      },
      {
        id: `${5}`,
        label: t('commonField.staticForm.label.attachment'),
        name: CommonFieldName.attachment,
        type: 'file',
        getValueFromEvent: normFile,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        valuePropName: 'fileList',
      }
    );
    return res;
  };
  return (
    <Fragment>
      <StaticFormTemplate t={t} getItems={getItems} form={form} />
    </Fragment>
  );
};

export const HomeLoanFields = memo(HomeLoanFieldsComponents);

import styled from 'styled-components';
import { Typography, Col } from 'antd';
import { colors, typography } from 'ui/elements';

export const UserDrawerSectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const UserDrawerUserInfoTitle = styled(Typography.Text)`
  font-size: ${typography.size.giga} !important;
  font-weight: ${typography.weight.regular} !important;
  color: ${colors.labelGrey} !important;
  margin-bottom: 25px !important;
`;

export const UserDrawerJobInfoItem = styled(Col)`
  margin-bottom: 24px;
`;

export const UserDrawerJobInfoItemTitle = styled(Typography.Text)`
  display: block;
  font-size: ${typography.size.hecto} !important;
  font-weight: ${typography.weight.regular} !important;
  color: ${colors.labelGrey} !important;
  margin-bottom: 2px;
`;

export const UserDrawerJobInfoItemValue = styled(Typography.Paragraph)`
  font-size: ${typography.size.kilo} !important;
  font-weight: ${typography.weight.bold} !important;
  color: ${colors.primaryColor} !important;
  margin-bottom: 0 !important;
  width: 90%;
`;

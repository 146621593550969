import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const submitFormDataSelector = (state: RootState): RootState['submitForm'] =>
  state.submitForm;

export const submitFormData = createSelector(submitFormDataSelector, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

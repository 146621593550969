import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserInfoData, RequestCommonError, RequestStatus } from 'types';
import { UserInfoState } from './userInfoTypes';

export const UserInfoInitialState: UserInfoState = {};

export type GetUserInfoStart = PayloadAction<{ userId: string }>;
export type GetUserInfoSuccess = PayloadAction<{
  userId: string;
  data: UserInfoData;
}>;
export type GetUserInfoFail = PayloadAction<{
  userId: string;
  error: RequestCommonError;
}>;

const userInfo = createSlice({
  name: 'userInfo',
  initialState: UserInfoInitialState,
  reducers: {
    getUserInfoStart(state, action: GetUserInfoStart) {
      if (state[action.payload.userId]) {
        state[action.payload.userId].status = RequestStatus.pending;
        return;
      }
      state[action.payload.userId] = {
        ...state[action.payload.userId],
        status: RequestStatus.pending,
      };
    },
    getUserInfoSuccess(state, action: GetUserInfoSuccess) {
      state[action.payload.userId].status = RequestStatus.resolved;
      state[action.payload.userId].data = action.payload.data;
    },
    getUserInfoFail(state, action: GetUserInfoFail) {
      state[action.payload.userId] = {
        ...state[action.payload.userId],
        status: RequestStatus.rejected,
      };
      state[action.payload.userId].error = action.payload.error;
    },
  },
});

export const { getUserInfoStart, getUserInfoSuccess, getUserInfoFail } = userInfo.actions;
export default userInfo.reducer;

import { ProfileData } from 'types';
import { Core, PROFILE } from '../Core';

interface GetProfile {
  (): Promise<{ data: ProfileData }>;
}

class Profile extends Core {
  getProfile: GetProfile = async () => this.get(PROFILE());
}

export const profile = new Profile();

import { useQuery } from 'react-query';
import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';
import { leaves } from '../Leaves';

export function useMyLeaveTeamDrawer(leaveId: string, adoId: string) {
  return useQuery(
    [QUERIES.myTeamLeaveDrawer, leaveId, adoId],
    async () => {
      const { data } = await leaves.getMyTeamLeaveDrawerInfo(leaveId, adoId);
      return data;
    },
    {
      enabled: false,
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

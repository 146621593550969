export const generalInfo = {
  personId: '300000002525258',
  displayName: 'Richard Al Ltaif',
  dateOfBirth: '1980-07-15',
  hireDate: '2019-03-17',
  yearsWorkedInTheCompany: '1',
  yearsSinceLastSalaryChange: '-',
  yearsSinceLastPromotion: '-',
  gender: 'Male',
  workerType: 'Employee',
  assignments: [
    {
      assignmentName: 'Chief',
      start: '2019-10-01',
      end: '4712-12-31',
      managerId: '300000002525049',
      assignmentStatus: '-',
      assignmentCategory: '-',
    },
  ],
  location: 'Riyadh',
  job: '-',
  businessUnit: '-',
  department: '-',
  employmentHistoryRecords: '-',
};

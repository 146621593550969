import React, { FC, useCallback, useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { TaskDefinitionKey, TaskStatus } from 'types';
import { useCompletedTaskFormFields, useLeaseSitesSubmittedCandidatesForm } from 'api';
import { C1Location, C2Location, C3Location, C4Location, C5Location, C6Location } from 'assets';
import C1LocationIcon from 'assets/svg/c1-location.svg';
import C2LocationIcon from 'assets/svg/c2-location.svg';
import C3LocationIcon from 'assets/svg/c3-location.svg';
import C4LocationIcon from 'assets/svg/c4-location.svg';
import C5LocationIcon from 'assets/svg/c5-location.svg';
import C6LocationIcon from 'assets/svg/c6-location.svg';
import { useTranslate } from 'translations';
import { InteractiveGoogleMap } from 'ui/components';
import { useTasksContext } from 'routes';
import { AssigneeInfo } from '../../TaskSteps.types';
import { TaskInfoWrapper } from '../components';
import { StyledDivider } from '../TaskInfo.styled';
import { TaskInfoItem } from '../TaskInfo';
import { TaskDescriptionPattern, TaskFields } from '../../TaskFields';
import {
  SiteAcquisitionCompletedCandidateCollapseContent,
  SiteAcquisitionCompletedCandidateHeader,
} from './components';
import {
  StyledCandidateCollapse,
  StyledCandidateMapContainer,
  StyledDescriptionContainer,
} from './SiteAcquisitionCompletedCandidatesTask.styled';

const { Panel } = Collapse;

interface Props {
  taskId: string;
  status: TaskStatus;
  title: string;
  taskPrettyId: string;
  taskDefinitionKey: TaskDefinitionKey;
  processDefinitionId: string;
  processInstanceId: string;
  taskActivityInstanceId: string;
  disabled?: boolean;
  isOpened?: boolean;
  highlighted?: boolean;
  created?: string;
  assigneeInfo?: AssigneeInfo;
  userGroupName?: [string];
}

export const SiteAcquisitionCompletedCandidatesTask: FC<Props> = ({
  taskId,
  status,
  title,
  taskPrettyId,
  taskDefinitionKey,
  processInstanceId,
  processDefinitionId,
  taskActivityInstanceId,
  disabled,
  isOpened,
  highlighted,
  created,
  assigneeInfo,
  userGroupName,
}) => {
  const { t } = useTranslate();
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false);
  const [completedFormFiled, setCompletedFormFields] = useState<TaskInfoItem[] | undefined>([]);
  const { data: submittedCandidates } = useLeaseSitesSubmittedCandidatesForm(
    taskId,
    isCollapseOpen
  );

  const paramsQuery = {
    processDefinitionId,
    taskDefinitionKey,
    taskActivityInstanceId,
    processInstanceId,
  };
  const { rootPath } = useTasksContext();

  const completedTaskFields = useCompletedTaskFormFields(
    taskId,
    paramsQuery,
    isCollapseOpen,
    rootPath
  );

  useEffect(() => {
    if (completedTaskFields.data) {
      const taskValuesWithLabels: TaskInfoItem[] = completedTaskFields.data.taskFormDetails
        ?.filter((item) => {
          return item.id.match(TaskDescriptionPattern)?.length;
        })
        .map((labelItem) => {
          return {
            label: labelItem.label,
            typeName: labelItem.typeName,
            groupName: labelItem.properties?.groupName,
            fieldId: labelItem.id,
            fieldValue: labelItem.value,
          } as TaskInfoItem;
        });
      setCompletedFormFields(taskValuesWithLabels);
    }
  }, [completedTaskFields.data, setCompletedFormFields]);

  const onClickOnCollapse = useCallback(() => {
    setIsCollapseOpen(!isCollapseOpen);
  }, [isCollapseOpen]);

  const candidatesIcons: { [key: number]: { icon: React.ReactNode; iconUrl: string } } = {
    1: { icon: <C1Location key="candidate-icon-1" />, iconUrl: C1LocationIcon },
    2: { icon: <C2Location key="candidate-icon-2" />, iconUrl: C2LocationIcon },
    3: { icon: <C3Location key="candidate-icon-3" />, iconUrl: C3LocationIcon },
    4: { icon: <C4Location key="candidate-icon-4" />, iconUrl: C4LocationIcon },
    5: { icon: <C5Location key="candidate-icon-5" />, iconUrl: C5LocationIcon },
    6: { icon: <C6Location key="candidate-icon-6" />, iconUrl: C6LocationIcon },
  };

  return (
    <TaskInfoWrapper
      status={status}
      title={title}
      taskPrettyId={taskPrettyId}
      taskDefinitionKey={taskDefinitionKey}
      assigneeInfo={assigneeInfo}
      disabled={disabled}
      created={created}
      userGroupName={userGroupName}
      isOpened={isOpened}
      highlighted={highlighted}
      onClickOnCollapse={onClickOnCollapse}
    >
      <StyledDivider />

      <StyledDescriptionContainer gutter={[32, 16]}>
        {completedFormFiled?.map(
          ({ fieldId, fieldValue, label, typeName }: TaskInfoItem, index) => (
            <TaskFields
              key={index}
              fieldId={fieldId}
              fieldValue={fieldValue}
              label={label}
              typeName={typeName}
            />
          )
        )}
      </StyledDescriptionContainer>

      <StyledCandidateCollapse
        bordered
        ghost
        defaultActiveKey={submittedCandidates?.candidatesList[0]?.candidateNumber ?? 1}
      >
        {submittedCandidates &&
          submittedCandidates.candidatesList.map((candidate) => (
            <Panel
              header={
                <SiteAcquisitionCompletedCandidateHeader
                  candidateNumber={candidate.candidateNumber}
                  icon={candidatesIcons[candidate.candidateNumber]?.icon}
                  t={t}
                />
              }
              key={candidate.candidateNumber}
            >
              <StyledCandidateMapContainer>
                <InteractiveGoogleMap
                  center={candidate.mapInfo.zone.center}
                  staticMarker={
                    candidate.mapInfo.markerPosition?.lat && candidate.mapInfo.markerPosition.lng
                      ? {
                          position: candidate.mapInfo.markerPosition,
                          iconUrl: candidatesIcons[candidate.candidateNumber]?.iconUrl,
                        }
                      : undefined
                  }
                  circleZone={{
                    dashed: true,
                    center: candidate.mapInfo.zone.center,
                    radius: candidate.mapInfo.zone.radius,
                  }}
                />
              </StyledCandidateMapContainer>
              <SiteAcquisitionCompletedCandidateCollapseContent
                taskDefinitionKey={taskDefinitionKey}
                key={`candidate-${candidate.candidateNumber}`}
                candidate={candidate}
              />
            </Panel>
          ))}
        {submittedCandidates?.feedback && (
          <>
            {/*<StyledDivider />*/}
            <TaskFields
              key="feedback"
              fieldId="feedback"
              fieldValue={submittedCandidates.feedback}
              label="Feedback"
              typeName="textArea"
            />
          </>
        )}
      </StyledCandidateCollapse>
    </TaskInfoWrapper>
  );
};

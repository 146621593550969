import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  getOtherUserStatusStart,
  getOtherUserStatusSuccess,
  getOtherUserStatusFail,
  GetOtherUserStatusFail,
  GetOtherUserStatusStart,
  GetOtherUserStatusSuccess,
} from './otherUserStatusSlice';

export const getOtherUserStatusEpic: Epic<
  GetOtherUserStatusStart,
  GetOtherUserStatusSuccess | GetOtherUserStatusFail,
  RootState,
  typeof API
> = (action$, _, { userStatus }) =>
  action$.pipe(
    filter(getOtherUserStatusStart.match),
    mergeMap(({ payload }) =>
      from(userStatus.getOtherUserStatus(payload.adoId)).pipe(
        map(({ data }) => getOtherUserStatusSuccess(data)),
        catchError((error) => of(getOtherUserStatusFail(error?.response?.data)))
      )
    )
  );

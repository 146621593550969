import { FormInstance, Rule } from 'antd/lib/form';
import moment from 'moment';
import React, { FC, memo, Fragment, useState, useEffect, useCallback } from 'react';
import { StaticFormTemplate } from 'routes/Tasks/components';
import { MessagesTypes, TType } from 'translations';
import { ExpensesType, AllowanceData, CreateFormItemType, SelectOptions } from 'types';
import {
  checkBetweenTwoMonths,
  AllowanceFieldsFormName,
  WRAPPER_COL_SPAN_SIZE_21,
  CommonFieldName,
  ExpenseTypeName,
} from 'ui/components';
import {
  FORM_ITEM_FILE_RULES,
  FORM_ITEM_FILE_RULES_MAX,
  FORM_ITEM_REQUIRED_RULE,
} from 'utils/formValidation';
import { normFile } from 'ui/components/GeneratedForm/FieldFile';
import { convertDate } from './helper';

interface AllowanceFormFieldsProps {
  t: TType;
  isSelectedAllowance?: boolean;
  allowanceOption?: AllowanceData;
  allowanceFields?: ExpensesType;
  description?: string;
  amount?: number;
  resetFormFields: () => void;
  form?: FormInstance;
}

const AllowanceFormFieldsComponent: FC<AllowanceFormFieldsProps> = ({
  t,
  isSelectedAllowance,
  allowanceOption,
  allowanceFields,
  description,
  amount,
  resetFormFields,
  form,
}) => {
  const [allowanceTypeOption, setAllowanceTypeOption] = useState<SelectOptions>();
  const [fieldsOption, setFieldsOption] = useState<SelectOptions>();
  const [isThereLimit, setIsThereLimit] = useState<boolean>(false);
  const [isNotInDuration, setIsNotInDuration] = useState<boolean>(false);
  const [isRemainingBalanceValid, setIsRemainingBalanceValid] = useState<boolean>(false);
  const [maxAmount, setMaxAmount] = useState<number>();

  useEffect(() => {
    let Option;
    if (allowanceOption) {
      Option = allowanceOption.allowances?.map((allowance) => {
        return { value: allowance.id, label: allowance.name };
      });
      Option && setAllowanceTypeOption(Option);
    }
  }, [allowanceOption]);

  useEffect(() => {
    if (allowanceFields) {
      const allowanceFieldsForm = allowanceFields.expensesAllowanceTypes?.map((field) => {
        return { value: field.id, label: field.type };
      });

      setFieldsOption(allowanceFieldsForm);
      setIsNotInDuration(false);
      setMaxAmount(allowanceFields.expensesAllowanceLimit.limitAmount);
      setIsRemainingBalanceValid(false);
    }
  }, [allowanceFields]);

  const limitationValidator = useCallback(() => {
    let limitOfAllowance;
    if (allowanceFields) {
      limitOfAllowance = allowanceFields.expensesAllowanceLimit.limitAmount === 0;
      setIsThereLimit(limitOfAllowance);
    }
    const value = limitOfAllowance;
    return value !== undefined
      ? value === false
        ? Promise.resolve()
        : Promise.reject(t('expenses.staticForm.error.thereIsNoLimit'))
      : Promise.reject();
  }, [allowanceFields, t]);

  const remainingValidator = useCallback(() => {
    let isRemainingBalance;
    if (allowanceFields) {
      const { remainingBalance } = allowanceFields.expensesAllowanceLimit;
      isRemainingBalance =
        allowanceFields.expensesAllowanceLimit && remainingBalance && remainingBalance > 0;
    }
    setIsRemainingBalanceValid(!isRemainingBalance);
    return isRemainingBalance === true
      ? Promise.resolve()
      : Promise.reject(t('expenses.staticForm.error.reminingBalance'));
  }, [allowanceFields, t]);

  const durationValidator = useCallback(() => {
    let isValidDuration;
    if (allowanceFields && allowanceFields?.educationStart && allowanceFields?.educationEnd) {
      const startDate = convertDate(allowanceFields?.educationStart);
      const endDate = convertDate(allowanceFields?.educationEnd, 1);
      isValidDuration = moment().isBetween(startDate, endDate);
      if (!isValidDuration) {
        setIsNotInDuration(true);
      }
    }
    if (isValidDuration === true && allowanceFields?.existRequest) {
      setIsNotInDuration(true);
      return Promise.reject(t('expenses.staticForm.error.existRequest'));
    }
    if (isValidDuration === true && allowanceFields?.inProbationPeriod) {
      setIsNotInDuration(true);
      return Promise.reject(t('expenses.staticForm.error.inProbationPeriod'));
    }
    return isValidDuration === true || isValidDuration === undefined
      ? Promise.resolve()
      : Promise.reject(t('expenses.staticForm.error.exceedLimit'));
  }, [allowanceFields, t]);

  const DURATION_VALIDATION_RULES: Rule[] = [
    {
      validator: durationValidator,
    },
  ];

  const REMAINING_BALANCE_RULES: Rule[] = [
    {
      validator: remainingValidator,
    },
  ];

  const LIMITATION_VALIDATION_RULES: Rule[] = [
    ...FORM_ITEM_REQUIRED_RULE,
    ...(allowanceFields?.name === ExpenseTypeName.education ? DURATION_VALIDATION_RULES : []),
    ...(allowanceFields?.name === ExpenseTypeName.healthClub &&
    allowanceFields?.expensesAllowanceLimit.remainingBalance
      ? REMAINING_BALANCE_RULES
      : []),
    {
      validator: limitationValidator,
    },
  ];

  const disableInvoicedDate = useCallback(
    (current: moment.Moment) => {
      let isSubmitted;
      let newDate;

      if (allowanceFields) {
        const subMonth = allowanceFields.submittedDate?.some((submitted) => {
          const currentDate = current.toDate();
          const submittedMonth = new Date([submitted.year, submitted.month].join(' '));
          return checkBetweenTwoMonths(currentDate, submittedMonth);
        });
        const toDay = moment().toDate();
        newDate = moment(toDay.setDate(toDay.getDate() - allowanceFields?.maxIssueDate));
        isSubmitted = subMonth;
      }

      return isSubmitted || current.isAfter() || current.isBefore(newDate);
    },
    [allowanceFields]
  );

  const REQUIRED_FORM_ITEM_MAX_LENGTH_RULES: Rule[] = [
    {
      max: maxAmount,
      required: true,
      type: 'number',
    },
  ];

  const compareRemainingBalanceValidator = useCallback(() => {
    let isGreaterThanRemaining;
    if (allowanceFields && amount && amount > 0) {
      const { remainingBalance } = allowanceFields.expensesAllowanceLimit;
      isGreaterThanRemaining =
        allowanceFields.expensesAllowanceLimit && remainingBalance && amount > remainingBalance;
    }
    return isGreaterThanRemaining === true
      ? Promise.reject(t('expenses.staticForm.error.exceedRemaining'))
      : Promise.resolve();
  }, [allowanceFields, t, amount]);

  const REMAINING_BALANCE_MAX_LENGTH_RULES: Rule[] = [
    ...FORM_ITEM_REQUIRED_RULE,
    {
      validator: compareRemainingBalanceValidator,
    },
  ];
  const getItems = () => {
    const res: CreateFormItemType[] = [];
    res.push({
      id: `${0}`,
      label: 'Allowance type',
      name: 'allowanceType',
      type: 'select',
      options: allowanceTypeOption,
      rules: LIMITATION_VALIDATION_RULES,
      wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
      hasFeedback: true,
      placeholder: `Select Allowance type`,
      resetFormFields: resetFormFields,
    });
    if (allowanceFields) {
      res.push({
        id: `${1}`,
        label: allowanceFields.allowanceLabel,
        name: AllowanceFieldsFormName.allowanceTypeValue,
        type: 'select',
        options: fieldsOption,
        rules: FORM_ITEM_REQUIRED_RULE,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
        isHidden: isThereLimit || isNotInDuration || isRemainingBalanceValid,
        placeholder: t(
          `expenses.staticForm.placeholder.${allowanceFields.allowanceLabel}` as MessagesTypes
        ),
      });
    }
    if (allowanceFields?.name === ExpenseTypeName.healthClub) {
      res.push({
        id: `${2}`,
        label: t('expenses.staticForm.label.remainingBalance'),
        name: AllowanceFieldsFormName.remainingBalance,
        type: 'inputNumber',
        disabled: !!allowanceFields.expensesAllowanceLimit?.remainingBalance,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
        placeholder: '0.00',
        isHidden:
          !allowanceFields.expensesAllowanceLimit?.remainingBalance ||
          !isSelectedAllowance ||
          isThereLimit ||
          isRemainingBalanceValid,
      });
    }

    res.push(
      {
        id: `${3}`,
        label: t('expenses.staticForm.label.amount'),
        name: AllowanceFieldsFormName.amount,
        type: 'inputNumber',
        disabled: allowanceFields?.amountReadOnly,
        rules:
          allowanceFields?.name === ExpenseTypeName.healthClub
            ? REMAINING_BALANCE_MAX_LENGTH_RULES
            : allowanceFields?.name === ExpenseTypeName.education
            ? FORM_ITEM_REQUIRED_RULE
            : REQUIRED_FORM_ITEM_MAX_LENGTH_RULES,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        hasFeedback: true,
        placeholder: '0.00',
        isHidden:
          !isSelectedAllowance || isThereLimit || isNotInDuration || isRemainingBalanceValid,
        maxNumber: maxAmount,
      },
      {
        id: `${4}`,
        label: t('expenses.staticForm.label.invoiceDate'),
        name: AllowanceFieldsFormName.invoiceDate,
        type: 'date',

        rules: allowanceFields?.invoiceDateRequired ? FORM_ITEM_REQUIRED_RULE : [],
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        picker: 'date',
        isHidden:
          !isSelectedAllowance || isThereLimit || isNotInDuration || isRemainingBalanceValid,
        disabledDate: disableInvoicedDate,
        placeholder: t('datePicker.date.placeholder'),
      },
      {
        id: `${5}`,
        label: t('expenses.staticForm.label.description'),
        name: AllowanceFieldsFormName.description,
        type: 'textArea',
        maxNumber: 1024,
        hasFeedback: true,
        placeholder: 'Your text here',
        isHidden:
          !isSelectedAllowance || isThereLimit || isNotInDuration || isRemainingBalanceValid,
        rules: FORM_ITEM_REQUIRED_RULE,
      },
      {
        id: `${6}`,
        label: t('requestForm.field.attachment.label'),
        name: CommonFieldName.attachment,
        type: 'file',
        getValueFromEvent: normFile,
        wrapperCol: WRAPPER_COL_SPAN_SIZE_21,
        rules: allowanceFields?.attachmentRequired
          ? FORM_ITEM_FILE_RULES
          : FORM_ITEM_FILE_RULES_MAX,
        valuePropName: 'fileList',
        isHidden:
          !isSelectedAllowance || isThereLimit || isNotInDuration || isRemainingBalanceValid,
      }
    );

    return res;
  };

  return (
    <Fragment>
      <StaticFormTemplate t={t} getItems={getItems} description={description} form={form} />
    </Fragment>
  );
};

export const AllowanceFormFields = memo(AllowanceFormFieldsComponent);

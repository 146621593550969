import React, { FC, useCallback, useEffect } from 'react';
import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TType } from 'translations';
import { FormInstance } from 'antd/lib/form';
import { CreateFormItemType } from 'types';
import { TitleH2 } from 'ui/elements';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { StaticFieldsGroup } from '../../../FieldsGroup';
import {
  StyledCustomFormGroupContainer,
  StyledCustomFormGroupInnerContainer,
  StyledCustomFormWraper,
} from './CustomFormGroup.styled';

type Props = {
  t: TType;
  formListName: string;
  formGroupItems: (formListName: number) => CreateFormItemType[];
  maxRecords: number;
  sectionTitle?: string;
  addButtonTitle: string;
  addButtonTitleSecondary?: string;
  form?: FormInstance;
  hasAtLeastOne?: boolean;
  hasInitialValue?: boolean;
  isEdit?: boolean;
  onRemoveFormListItem?: (fieldIndex: number) => void;
};

export const CustomFormGroupWrapper: FC<Props> = ({
  t,
  form,
  formGroupItems,
  formListName,
  maxRecords,
  sectionTitle,
  hasAtLeastOne,
  hasInitialValue,
  addButtonTitle,
  addButtonTitleSecondary,
  isEdit,
  onRemoveFormListItem,
}) => {
  useEffect(
    () => {
      if (hasAtLeastOne && !hasInitialValue) {
        setTimeout(() => {
          form?.setFieldsValue({ [formListName]: [{}] });
        }, 200);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasAtLeastOne, hasInitialValue]
  );

  const addFieldButtonTitle = useCallback(
    (fields: FormListFieldData[]): string => {
      if (addButtonTitleSecondary && fields?.length > 1) {
        return addButtonTitleSecondary;
      }
      return addButtonTitle;
    },
    [addButtonTitle, addButtonTitleSecondary]
  );

  return (
    <Form.List name={formListName}>
      {(fields, { add, remove }) => (
        <StyledCustomFormWraper>
          <StyledCustomFormGroupContainer>
            {sectionTitle && <TitleH2>{sectionTitle}</TitleH2>}
            {fields.map((group, index) => (
              <StyledCustomFormGroupInnerContainer key={`${formListName}-${index}`}>
                <StaticFieldsGroup
                  key={`form-group-${formListName}-${group.name}`}
                  t={t}
                  getFormFields={() => [{ [index + 1]: formGroupItems(group.name) }]}
                  form={form}
                  isShowSubTitle={false}
                  isRemoveButtonEnabled={
                    (index !== 0 && fields.length > 1 && hasAtLeastOne) || !hasAtLeastOne
                  }
                  remove={() => {
                    if (onRemoveFormListItem) {
                      onRemoveFormListItem(group.fieldKey);
                    }
                    remove(index);
                  }}
                />
              </StyledCustomFormGroupInnerContainer>
            ))}
            {fields.length < maxRecords && (
              <Button icon={<PlusOutlined />} onClick={() => add()} disabled={isEdit}>
                {addFieldButtonTitle(fields)}
              </Button>
            )}
          </StyledCustomFormGroupContainer>
        </StyledCustomFormWraper>
      )}
    </Form.List>
  );
};

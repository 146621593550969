import React from 'react';
import { Col, Row, Space, Tabs, Typography } from 'antd';

import { getAvatarSymbols } from 'utils/getAvatarSymbols';
import { useTranslate } from 'translations';
import { TabPaneContent, UserContainer } from '../../MSTeams/MSTeams.styled';
import { StyledTeamAvatar } from '../../MSTeams/MSTeamsTeamsTabs/MSTeamsTeamsSidebar/MSTeamsTeamsSidebar.styled';
import {
  StyledTabsWrapper,
  StyledTabs,
  StyledPlanTitle,
  StyledPlanTitleColumn,
  StyledPlanProjectTitle,
  StyledStarIcon,
  StyledDownIcon,
} from './MSPlannerTabs.styled';

const { Text } = Typography;
const { TabPane } = Tabs;

export const MSPlannerTabs = () => {
  const { t } = useTranslate();

  return (
    <StyledTabsWrapper>
      <StyledTabs
        tabBarExtraContent={{
          left: (
            <UserContainer>
              <Space direction="horizontal" size={16}>
                <StyledTeamAvatar id="1">{getAvatarSymbols('Project Tasks')}</StyledTeamAvatar>
                <StyledPlanTitleColumn>
                  <StyledPlanTitle>
                    <Space size={8}>
                      <Text strong>Project Tasks</Text>
                      <StyledStarIcon />
                    </Space>
                  </StyledPlanTitle>
                  <StyledPlanProjectTitle>Project War Room</StyledPlanProjectTitle>
                </StyledPlanTitleColumn>
              </Space>
            </UserContainer>
          ),
          right: (
            <Row gutter={[25, 0]}>
              <Col>
                <Space size={8}>
                  Members <StyledDownIcon />
                </Space>
              </Col>
              <Col>
                <Space size={8}>
                  Filter (0) <StyledDownIcon />
                </Space>
              </Col>
              <Col>
                <Space size={8}>
                  Group by Bucket <StyledDownIcon />
                </Space>
              </Col>
            </Row>
          ),
        }}
      >
        <TabPane tab={t('msplanner.tab.list')} key="1">
          <TabPaneContent>List content</TabPaneContent>
        </TabPane>
        <TabPane tab={t('msplanner.tab.board')} key="2">
          <TabPaneContent>Board content</TabPaneContent>
        </TabPane>
        <TabPane tab={t('msplanner.tab.charts')} key="3">
          <TabPaneContent>Charts content</TabPaneContent>
        </TabPane>
      </StyledTabs>
    </StyledTabsWrapper>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HistoricActivityInstanceItem, RequestCommonError, RequestStatus } from 'types';
import { AxiosRequestConfig } from 'axios';
import { HistoricActivityInstanceState } from './historicActivityInstanceTypes';

export const emptyHistoricActivityInstanceState: HistoricActivityInstanceState = {};

type HistoricActivityInstanceData = {
  data: HistoricActivityInstanceItem[];
  processInstanceId: string;
};

type HistoricActivityInstanceError = {
  error: RequestCommonError;
  processInstanceId: string;
};

export type GetHistoricActivityInstanceStart = PayloadAction<AxiosRequestConfig>;
export type GetHistoricActivityInstanceSuccess = PayloadAction<HistoricActivityInstanceData>;
export type GetHistoricActivityInstanceFail = PayloadAction<HistoricActivityInstanceError>;

const historicActivityInstance = createSlice({
  name: 'historicActivityInstance',
  initialState: emptyHistoricActivityInstanceState,
  reducers: {
    getHistoricActivityInstanceStart(state, action: GetHistoricActivityInstanceStart) {
      state[action.payload.params.processInstanceId] = {
        ...state[action.payload.params.processInstanceId],
        status: RequestStatus.pending,
      };
    },
    getHistoricActivityInstanceSuccess(state, action: GetHistoricActivityInstanceSuccess) {
      state[action.payload.processInstanceId] = {
        status: RequestStatus.resolved,
        data: action.payload.data,
      };
    },
    getHistoricActivityInstanceFail(state, action: GetHistoricActivityInstanceFail) {
      state[action.payload.processInstanceId] = {
        status: RequestStatus.rejected,
        error: action.payload.error,
      };
    },
    resetHistoricActivityInstance(state) {
      // state.status = RequestStatus.idle;
      // state.data = {};
    },
  },
});

export const {
  getHistoricActivityInstanceStart,
  getHistoricActivityInstanceSuccess,
  getHistoricActivityInstanceFail,
  resetHistoricActivityInstance,
} = historicActivityInstance.actions;

export default historicActivityInstance.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, TeamsData } from 'types';
import { TeamsState } from './teamsTypes';

export const emptyTeamsInitialState: TeamsState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetTeamsStart = PayloadAction;
export type GetTeamsSuccess = PayloadAction<TeamsData>;
export type GetTeamsFail = PayloadAction<RequestCommonError>;

const teams = createSlice({
  name: 'teams',
  initialState: emptyTeamsInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTeamsStart(state, _: GetTeamsStart) {
      state.status = RequestStatus.pending;
    },
    getTeamsSuccess(state, action: GetTeamsSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getTeamsFail(state, action: GetTeamsFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const { getTeamsStart, getTeamsSuccess, getTeamsFail } = teams.actions;
export default teams.reducer;

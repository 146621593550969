import { NamePath } from 'antd/lib/form/interface';
import moment from 'moment';
import { TType } from 'translations';

export interface UseInfoProps {
  adoid: string;
  selectedSiteId?: NamePath;
  sourceCityId?: NamePath;
  destinationCityId?: NamePath;
  transportation?: NamePath;
  purposeId?: NamePath;
  businessDate?: [moment.Moment, moment.Moment];
  actualTravelDate?: [moment.Moment, moment.Moment];
  t: TType;
}

export const convertToDate = (date: string) => {
  return moment(date).format('DD/MM/YYYY');
};

export const isDatesEqualOrBefore = (Date_1: moment.Moment, Date_2: moment.Moment) =>
  Date_1.isBefore(Date_2) || Date_1.isSame(Date_2);

export const isDatesEqualOrAfter = (Date_1: moment.Moment, Date_2: moment.Moment) =>
  Date_1.isAfter(Date_2) || Date_1.isSame(Date_2);

export const checkTheIncludedBetweenDates = (
  firstDate: [moment.Moment, moment.Moment],
  secondDate: [moment.Moment, moment.Moment]
) => {
  const [startDate, endDate] = firstDate;
  const [actualStartDate, actualEndDate] = secondDate;
  return (
    isDatesEqualOrBefore(startDate.startOf('day'), actualStartDate.startOf('day')) &&
    isDatesEqualOrAfter(endDate.startOf('day'), actualEndDate.startOf('day'))
  );
};

export const checkSelectedTravelLocations = (
  source: string | undefined,
  destination: string | undefined
) => {
  return !destination || !source || source !== destination;
};

export const defaultSearchParam = { search: 'a', inSaudi: true };
export const defaultPageSize = { page: 0, size: 5 };

export const durationFormat = 'DD/MM/YYYY';

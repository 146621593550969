import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const userAvatar = (state: RootState): RootState['userAvatar'] => state.userAvatar;

export const userAvatarData = createSelector(userAvatar, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

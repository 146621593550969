import React, { FC, memo } from 'react';
import { CarouselRef } from 'antd/lib/carousel';
import { BACK_ICON_STYLES, StyledLeftArrowIcon, StyledRightArrowIcon } from './CardCarousel.styled';

interface Props {
  scrollsCount: number;
  slidesLength: number;
  carouselEL: React.RefObject<CarouselRef>;
}

export const CarouselArrowsComponent: FC<Props> = ({ scrollsCount, slidesLength, carouselEL }) => {
  const goToPrevious = () => carouselEL.current?.prev();

  const goToNext = () => carouselEL.current?.next();

  const slidesCount = slidesLength - 2.75;
  return (
    <>
      <StyledLeftArrowIcon
        disabledArrow={scrollsCount < 1}
        onClick={goToPrevious}
        style={BACK_ICON_STYLES}
      />
      <StyledRightArrowIcon
        disabledArrow={scrollsCount >= slidesCount}
        onClick={goToNext}
        style={BACK_ICON_STYLES}
      />
    </>
  );
};
export const CarouselArrows = memo(CarouselArrowsComponent);

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const notificationsList = (state: RootState): RootState['notificationsList'] =>
  state.notificationsList;

export const notificationsListSelector = createSelector(
  notificationsList,
  ({ error, data, status }) => ({
    error,
    status,
    data,
  })
);

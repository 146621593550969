import React, { FC, useMemo, useCallback } from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import moment, { Moment } from 'moment';
import { FieldData } from 'rc-field-form/lib/interface';
import { useTranslate } from 'translations';
import { FORM_FILED_BREAKPOINT } from 'routes/Tasks/components/templates';
import { useGetFromFieldId, useRowGutter } from 'ui/hooks';
import { DEFAULT_ITEM_SPAN } from 'routes/Dashboard/Components/organisms/constants';
import { VALIDATE_MESSAGES } from 'utils/formValidation';
import { FormFields, FormField } from 'types';
import { FieldGroup } from './FieldGroup';
import { Field } from './Field';
import { FieldFile } from './FieldFile';
import { FieldTextArea } from './FieldTextArea';
import { FieldNumber } from './FieldNumber';
import { FieldDate } from './FieldDate';
import { FieldCheckbox } from './FieldCheckbox';
import { FieldText } from './FieldText';
import { FieldActionButtons, FieldButton } from './FieldButton';
import { FieldTel } from './FieldTel';
import { FieldEnumType } from './FieldEnumType';
import { FieldDecimal } from './FieldDecimal';
import {
  FormItemCol,
  FormItemFieldWrapper,
  FormItemWrapper,
  FormWrapper,
} from './GeneratedForm.Styled';
import { transformGeneratedFormData } from './GeneratedForm.helpers';

interface GeneratedFormProps {
  formData: FormFields;
  onSubmit?(): void;
  formName?: string;
  onFieldsChange?: (changedFields: FieldData[], allFields: FieldData[]) => void;
  fieldsToHide?: string[];
  form?: FormInstance;
  taskId?: string;
  withLabel?: boolean;
  customSpaces?: boolean;
  processDefinitionKey?: string;
  processId?: string;
}

export const GeneratedForm: FC<GeneratedFormProps> = ({
  formData,
  formName,
  onSubmit,
  onFieldsChange,
  fieldsToHide,
  form,
  taskId,
  withLabel,
  customSpaces,
  processDefinitionKey,
  processId,
}) => {
  const { t } = useTranslate();
  const gutter16 = useRowGutter(16);
  const getFieldId = useGetFromFieldId();

  const initialValues = useMemo(
    () =>
      formData.reduce((acc, item) => {
        const value = item?.value?.value;
        const { typeName, defaultValue } = item;
        const itemId = getFieldId(item.id);
        if (value !== undefined && value !== null) {
          if (typeName === 'date' && typeof item?.value?.value === 'string') {
            acc[itemId] = moment(item.value.value.replace(/\//g, '.'), 'DD/MM/YYYY');
          } else if (typeName === 'long') {
            acc[itemId] = Number(item?.value?.value);
          } else {
            acc[itemId] = item?.value?.value;
          }

          return acc;
        }

        if (defaultValue !== undefined && defaultValue !== null) {
          acc[itemId] = item.defaultValue;
          return acc;
        }

        return acc;
      }, {} as { [key: string]: string | boolean | Date | Moment | number | null }),
    [formData, getFieldId]
  );

  const getFieldWidth = useCallback((field: FormField) => {
    if (
      field.typeName === 'textArea' ||
      field.typeName === 'file' ||
      field.typeName === 'text' ||
      field.properties.fullWidth
    ) {
      return { span: DEFAULT_ITEM_SPAN, customWidth: undefined };
    } else {
      return { span: undefined, customWidth: FORM_FILED_BREAKPOINT };
    }
  }, []);

  const transformedFormData = useMemo(
    () => transformGeneratedFormData(formData, t('requestForm.field.attachments.label')),
    [formData, t]
  );

  const getFormItemByFieldType = (field: FormField) => {
    const fieldId = getFieldId(field.id);
    switch (field.typeName) {
      case 'enum':
        return <FieldEnumType key={fieldId} withLabel={withLabel} field={field} t={t} />;
      case 'multipleSelect':
        return (
          <FieldEnumType key={fieldId} withLabel={withLabel} field={field} t={t} isMulti={true} />
        );
      case 'textArea':
        return <FieldTextArea withLabel={withLabel} key={fieldId} field={field} />;
      case 'long':
        return <FieldNumber withLabel={withLabel} key={fieldId} field={field} />;
      case 'decimal':
        return <FieldDecimal withLabel={withLabel} key={fieldId} field={field} />;
      case 'tel':
        return <FieldTel withLabel={withLabel} key={fieldId} field={field} />;
      case 'file':
        return <FieldFile withLabel={withLabel} key={fieldId} field={field} form={form} />;
      case 'date':
        return <FieldDate withLabel={withLabel} key={fieldId} field={field} />;
      case 'boolean':
        return (
          <FieldCheckbox
            withLabel={withLabel}
            key={fieldId}
            field={field}
            setFieldsValue={form?.setFieldsValue}
            isBoolean={true}
          />
        );
      case 'checkbox':
        return (
          <FieldCheckbox
            withLabel={withLabel}
            key={fieldId}
            field={field}
            setFieldsValue={form?.setFieldsValue}
            isBoolean={false}
          />
        );
      case 'text':
        return <FieldText key={fieldId} field={field} />;
      case 'assignAsset':
        return <FieldEnumType key={fieldId} withLabel={withLabel} field={field} t={t} />;
      case 'assignAssetCheckbox':
        return (
          <FieldEnumType key={fieldId} withLabel={withLabel} field={field} t={t} isMulti={true} />
        );
      case 'string':
      default:
        return <Field withLabel={withLabel} key={fieldId} field={field} />;
    }
  };

  return (
    <Form
      initialValues={initialValues}
      form={form}
      layout="vertical"
      name={formName}
      id={formName}
      onFinish={onSubmit}
      validateMessages={VALIDATE_MESSAGES(t)}
      onFieldsChange={onFieldsChange}
    >
      <FormWrapper gutter={customSpaces ? undefined : gutter16}>
        {transformedFormData.map((field) => {
          const fieldId = getFieldId(field.id);

          if (field?.component === 'group') {
            return (
              <FieldGroup
                key={fieldId}
                field={field}
                fields={field?.fields}
                fieldsToHide={fieldsToHide}
                form={form}
                t={t}
              />
            );
          }
          if (field.typeName === 'button') {
            return (
              <FieldButton
                key={fieldId}
                field={field}
                form={form}
                taskId={taskId}
                processDefinitionKey={processDefinitionKey}
                processId={processId}
              />
            );
          }

          if (field.component === 'actionButtons' && field.fields?.length) {
            return <FieldActionButtons fields={field.fields} form={form} taskId={taskId} />;
          }

          const fieldWidth = getFieldWidth(field);
          const formField = getFormItemByFieldType(field);
          return (
            <FormItemCol
              $isVisible
              key={fieldId}
              {...fieldWidth.customWidth}
              span={fieldWidth.span}
            >
              {field.properties.dependencyFieldKey ? (
                <FormItemWrapper
                  shouldUpdate={(prevValues, nextValue) => {
                    return (
                      prevValues[field.properties.dependencyFieldKey ?? ''] !==
                      nextValue[field.properties.dependencyFieldKey ?? '']
                    );
                  }}
                >
                  {({ getFieldValue, setFields }) => {
                    const dependencyValue = getFieldValue(
                      field.properties.dependencyFieldKey ?? ''
                    );
                    if (field.properties.dependencyFieldAction === 'REQUIRED') {
                      if (dependencyValue === field.properties.dependencyFieldValue) {
                        return (
                          <FormItemFieldWrapper $withMargin={customSpaces}>
                            {getFormItemByFieldType({
                              ...field,
                              validationConstraints: [
                                ...field.validationConstraints,
                                { name: 'required', configuration: null },
                              ],
                              properties: {
                                ...field.properties,
                                required: 'true',
                                requiredField: true,
                              },
                            })}
                          </FormItemFieldWrapper>
                        );
                      }
                      return (
                        <FormItemFieldWrapper $withMargin={customSpaces}>
                          {formField}
                        </FormItemFieldWrapper>
                      );
                    } else {
                      if (dependencyValue === field.properties.dependencyFieldValue) {
                        if (field.properties.dependencyFieldAction === 'SHOW_REQUIRED') {
                          return (
                            <FormItemFieldWrapper $withMargin={customSpaces}>
                              {getFormItemByFieldType({
                                ...field,
                                validationConstraints: [
                                  ...field.validationConstraints,
                                  { name: 'required', configuration: null },
                                ],
                                properties: {
                                  ...field.properties,
                                  required: 'true',
                                  requiredField: true,
                                },
                              })}
                            </FormItemFieldWrapper>
                          );
                        }
                        return (
                          <FormItemFieldWrapper $withMargin={customSpaces}>
                            {formField}
                          </FormItemFieldWrapper>
                        );
                      }
                      setFields([{ name: field.id, value: undefined }]);
                      return null;
                    }
                  }}
                </FormItemWrapper>
              ) : (
                <FormItemWrapper>
                  <FormItemFieldWrapper $withMargin={customSpaces}>
                    {formField}
                  </FormItemFieldWrapper>
                </FormItemWrapper>
              )}
            </FormItemCol>
          );
        })}
      </FormWrapper>
    </Form>
  );
};

import { CaretDownFilled, SearchOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Input, Select, Tooltip } from 'antd';
import React, { FC, useMemo, useCallback } from 'react';
import { selectFilterOption } from 'utils/getFilterOptions';
import { borderRadius } from 'ui/elements';
import { SearchUser } from '../TaskList/TaskSteps/ReassignTask/SearchUser';
import { FilterCol } from './FilterCol';
import { useFilterContext } from './FilterContext';
import { FilterItemProps, ItemType } from './Filter.types';
import { SelectFilter } from './Filter.styled';

const { RangePicker } = DatePicker;

type Items = {
  [type in ItemType]: JSX.Element | null;
};

const globalStyles: React.CSSProperties = { borderRadius: borderRadius.large };

export const FilterItem: FC<FilterItemProps> = ({
  name,
  label,
  type,
  placeholder,
  options,
  loading,
  error,
  lookupUserId,
  assigneeName,
  getTeamMembers,
  disabled = false,
  disabledTooltip,
}) => {
  const { dateFormat, t } = useFilterContext();

  const getCurrentSearchUserId = useCallback(
    (assignUserId: string, assignUserName: string) => {
      if (lookupUserId) {
        lookupUserId(assignUserId, assignUserName);
      }
    },
    [lookupUserId]
  );

  const handleChange = useCallback(
    (_, options) => {
      if (getTeamMembers && options && options.length > 0) {
        getTeamMembers(options);
      }
    },
    [getTeamMembers]
  );

  const elements: Omit<Items, 'empty'> = useMemo(
    () => ({
      datePicker: (
        <DatePicker
          style={{ ...globalStyles, width: '100%' }}
          placeholder={placeholder as string}
          format={dateFormat}
          allowClear={false}
          disabled={disabled ?? error}
        />
      ),
      rangePicker: (
        <RangePicker
          style={globalStyles}
          format={dateFormat}
          allowClear={false}
          disabled={disabled ?? error}
          placeholder={placeholder as [string, string]}
        />
      ),
      select: (
        <SelectFilter
          placeholder={placeholder}
          suffixIcon={<CaretDownFilled />}
          loading={loading}
          notFoundContent={t('dropdown.noMatching')}
          disabled={disabled ?? error}
          showSearch
          filterOption={selectFilterOption}
        >
          {options?.map(({ text, value }, i) => (
            <Select.Option key={i} value={value}>
              {text}
            </Select.Option>
          ))}
        </SelectFilter>
      ),
      input: <Input placeholder={placeholder as string} disabled={disabled} />,
      lookup: (
        <SearchUser
          inputStyle={globalStyles}
          currentSearchUserId={getCurrentSearchUserId}
          groupTasksOptions={options}
          suffix={true}
          assigneeName={assigneeName}
        />
      ),
      multiSelect: (
        <SelectFilter
          mode={'multiple'}
          placeholder={placeholder}
          onChange={handleChange}
          optionLabelProp="label"
          showArrow
          suffixIcon={<SearchOutlined />}
          notFoundContent={t('dropdown.noMatching')}
          showSearch
          disabled={disabled}
        >
          {options?.map(({ value, label, text }) => (
            <Select.Option key={value} value={label as string}>
              {text}
            </Select.Option>
          ))}
        </SelectFilter>
      ),
    }),
    [
      options,
      placeholder,
      dateFormat,
      loading,
      error,
      getCurrentSearchUserId,
      assigneeName,
      handleChange,
      t,
      disabled,
    ]
  );

  return type === 'empty' ? (
    <Col />
  ) : (
    <FilterCol>
      {disabled && disabledTooltip ? (
        <Tooltip destroyTooltipOnHide={true} title={disabledTooltip}>
          <Form.Item name={name} label={label}>
            {elements[type]}
          </Form.Item>
        </Tooltip>
      ) : (
        <Form.Item name={name} label={label}>
          {elements[type]}
        </Form.Item>
      )}
    </FilterCol>
  );
};

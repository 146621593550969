import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Collapse,
  Divider,
  Row,
  Col,
  Typography,
  Space,
  Form,
  Spin,
  notification,
  Modal,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { useTranslate } from 'translations';
import styled from 'styled-components';
import { PrimaryButton, FullWidthSpin } from 'ui/elements';
import { useGetFormFieldName } from 'ui/hooks';
import {
  LeaseSitesFieldRunnerFormValues,
  LeaseSitesRequestFormDetailsCountry,
  SITE_ACQUISITION_TASK_SUBMIT_FORM,
  SITE_ACQUISITION_TASK_SUBMIT_FORM_GROUPS,
} from 'types';
import {
  useLeaseSitesRequestsFormDetails,
  useLeaseSitesRequestSaveCandidatesDraft,
  useLeaseSitesCandidatesFormDraft,
  useLeaseSitesRequestsFieldRunnerMutation,
} from 'api';
import { FieldData } from 'rc-field-form/lib/interface';
import { StyledCard, AssigneeInfo, TaskStepPanelHeader } from '../../TaskStep';
import { TaskFieldDescription } from '../../TaskFields';
import { TaskSubmitFormProps } from '../TaskSubmitForm.types';
import {
  CandidateCollapse,
  EssentialPropertyInformationFormItems,
  ComprehensivePropertyInformationFormItems,
  PropertyDocumentsFormItems,
  IsrDocumentsFormItems,
  EssentialLandlordInformationFormItems,
  ComprehensiveLandlordInformationFormItems,
  LandlordDocumentsFormItems,
  DelegateDetailsFormItems,
  AccountDetailsFormItems,
  CandidateFormGoogleMap,
  SiteAcquisitionFeedbackFormItem,
  SiteAcquisitionActionButtons,
} from './components';
import { useGetConfirmModalProps } from './SiteAcquisitionTaskSubmitForm.hooks';

const { Panel } = Collapse;
const { Text } = Typography;

const MAX_CANDIDATE_COUNT = 6;

const StyledDivider = styled(Divider)`
  margin: 4px 0 12px 0;
`;
const StyledCollapse = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 20px !important;
    left: 26px;
  }
`;

const modalConfirm = Modal.confirm;

export const SiteAcquisitionTaskSubmitForm: FC<TaskSubmitFormProps> = ({
  taskInfo,
  reloadTaskList,
  tasksStatus,
  processInstanceId,
  activityId,
  processDefinitionId,
}) => {
  const [form] = useForm();
  const { t } = useTranslate();
  const getFormFieldName = useGetFormFieldName();

  const [feedbackRequired, setFeedbackRequired] = useState(true);

  const formDataInfo = useLeaseSitesRequestsFormDetails(processInstanceId);
  const {
    data: candidatesFormDraftValues,
    isError: isCandidatesFormUndervaluesError,
    isSuccess: isCandidatesFormUndervaluesSuccess,
  } = useLeaseSitesCandidatesFormDraft(taskInfo.taskId);

  const {
    mutateAsync: saveCandidatesFormDraft,
    isLoading: isSaveDraftLoading,
  } = useLeaseSitesRequestSaveCandidatesDraft();

  const fieldRunnerMutation = useLeaseSitesRequestsFieldRunnerMutation({
    taskId: taskInfo.taskId,
    processInstanceId,
    activityId,
    processDefinitionId,
  });

  const onFieldsChange = useCallback((changedFields: FieldData[]) => {
    const [changedField] = changedFields;
    const { name, value } = changedField || ({} as FieldData);

    if (
      Array.isArray(name) &&
      name.length === 1 &&
      name[0] === SITE_ACQUISITION_TASK_SUBMIT_FORM_GROUPS.fieldRunnerCandidates &&
      Array.isArray(value)
    ) {
      setFeedbackRequired(value.length === 0);
    }
  }, []);

  const onMarkerSelect = useCallback(
    (field: FormListFieldData, info?: { lat: number; lng: number; address?: string }) => {
      const latFieldName = getFormFieldName(SITE_ACQUISITION_TASK_SUBMIT_FORM.latitude, field);
      const lngFieldName = getFormFieldName(SITE_ACQUISITION_TASK_SUBMIT_FORM.longitude, field);
      const siteAddressFieldName = getFormFieldName(
        SITE_ACQUISITION_TASK_SUBMIT_FORM.siteAddress,
        field
      );
      const siteStreetFieldName = getFormFieldName(
        SITE_ACQUISITION_TASK_SUBMIT_FORM.siteStreet,
        field
      );

      form.setFields([
        {
          name: ['fieldRunnerCandidates', ...(latFieldName as (string | number)[])],
          value: info?.lat,
        },
        {
          name: ['fieldRunnerCandidates', ...(lngFieldName as (string | number)[])],
          value: info?.lng,
        },
        {
          name: ['fieldRunnerCandidates', ...(siteAddressFieldName as (string | number)[])],
          value: info?.address,
        },
        {
          name: ['fieldRunnerCandidates', ...(siteStreetFieldName as (string | number)[])],
          value: info?.address,
        },
      ]);
    },
    [form, getFormFieldName]
  );

  const onCountrySelect = useCallback(
    (field: FormListFieldData, country?: LeaseSitesRequestFormDetailsCountry) => {
      if (country) {
        const phoneCode = getFormFieldName(
          SITE_ACQUISITION_TASK_SUBMIT_FORM.landlordPhoneCountryCode,
          field
        );
        const currencyCode = getFormFieldName(
          SITE_ACQUISITION_TASK_SUBMIT_FORM.currencyCode,
          field
        );

        form.setFields([
          {
            name: ['fieldRunnerCandidates', ...(phoneCode as (string | number)[])],
            value: country?.countryCode,
          },
          {
            name: ['fieldRunnerCandidates', ...(currencyCode as (string | number)[])],
            value: country?.currencyCode,
          },
        ]);
      }
    },
    [form, getFormFieldName]
  );

  useEffect(() => {
    if (candidatesFormDraftValues?.feedback) {
      const feedback = getFormFieldName(SITE_ACQUISITION_TASK_SUBMIT_FORM.feedback);

      form.setFields([
        {
          name: feedback as (string | number)[],
          value: candidatesFormDraftValues.feedback,
        },
      ]);
    }
  }, [candidatesFormDraftValues?.feedback, form, getFormFieldName]);

  const onSaveDraft = () => {
    const formValues = form.getFieldsValue();
    saveCandidatesFormDraft({
      processInstanceId,
      formValues: { taskId: taskInfo.taskId, ...formValues },
    })
      .then((res) => {
        res?.forEach((field, index) => {
          const candidateId = getFormFieldName(SITE_ACQUISITION_TASK_SUBMIT_FORM.candidateId, {
            name: index,
            key: index,
            fieldKey: index,
          });
          form.setFields([
            {
              name: ['fieldRunnerCandidates', ...(candidateId as (string | number)[])],
              value: field?.id,
            },
          ]);
        });
        notification.success({
          message: t('messages.success'),
          description: t('messages.success.lease.draft.completed'),
        });
      })
      .catch(() => {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: t('messages.error.tryAgainLater'),
        });
      });
  };

  const getConfirmModalProps = useGetConfirmModalProps();

  const onFinish = useCallback(
    (values: LeaseSitesFieldRunnerFormValues) => {
      const candidateCount = values?.fieldRunnerCandidates?.length || 0;

      if (candidateCount < MAX_CANDIDATE_COUNT) {
        fieldRunnerMutation.mutate(values);
        return;
      }

      modalConfirm(
        getConfirmModalProps({
          onConfirm: () => fieldRunnerMutation.mutate(values),
          candidateCount,
        })
      );
    },
    [fieldRunnerMutation, getConfirmModalProps]
  );

  if (!formDataInfo.data) {
    return <FullWidthSpin />;
  }

  return (
    <Spin spinning={fieldRunnerMutation.isLoading}>
      <Form
        form={form}
        name={'fieldRunnerCandidatesForm'}
        layout={'vertical'}
        onFieldsChange={onFieldsChange}
        scrollToFirstError
        onFinish={onFinish}
      >
        <StyledCard $highlighted>
          <StyledCollapse defaultActiveKey="1" className="tasks_collapse" ghost>
            <Panel
              header={<TaskStepPanelHeader header={taskInfo.taskName} status={taskInfo?.status} />}
              style={{ alignItems: 'center' }}
              key="1"
              extra={
                <AssigneeInfo
                  taskInfo={taskInfo}
                  t={t}
                  reloadTaskList={reloadTaskList}
                  tabStatus={tasksStatus}
                />
              }
            >
              <Row gutter={[24, 24]}>
                {taskInfo.prettyId && (
                  <Col span={24}>
                    <Text type="secondary">{`Task ID: #${taskInfo.prettyId}`}</Text>
                  </Col>
                )}
                <Col span={24}>
                  <StyledDivider />
                </Col>
                <Col span={24}>
                  <TaskFieldDescription value={taskInfo.description} />
                </Col>

                {(isCandidatesFormUndervaluesError || isCandidatesFormUndervaluesSuccess) && (
                  <Form.List
                    name="fieldRunnerCandidates"
                    initialValue={candidatesFormDraftValues?.candidates}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => {
                          return (
                            <Col span={24} key={field.key}>
                              <CandidateCollapse
                                title={`Candidate ${index + 1}`}
                                onRemove={() => {
                                  remove(field.name);
                                  candidatesFormDraftValues?.candidates?.splice(index, 1);
                                }}
                                id={index + 1}
                              >
                                {formDataInfo.data?.locationDetails && (
                                  <CandidateFormGoogleMap
                                    center={{
                                      lat: formDataInfo.data.locationDetails.latitude,
                                      lng: formDataInfo.data.locationDetails.longitude,
                                    }}
                                    selectedMarker={
                                      candidatesFormDraftValues &&
                                      candidatesFormDraftValues?.candidates?.[index]?.latitude &&
                                      candidatesFormDraftValues?.candidates?.[index]?.longitude
                                        ? {
                                            lat: candidatesFormDraftValues?.candidates?.[index]
                                              ?.latitude as number,
                                            lng: candidatesFormDraftValues?.candidates?.[index]
                                              ?.longitude as number,
                                          }
                                        : undefined
                                    }
                                    radius={formDataInfo.data.locationDetails.radius}
                                    onSelectMarker={(info) => onMarkerSelect(field, info)}
                                  />
                                )}

                                <EssentialPropertyInformationFormItems
                                  formDetails={formDataInfo.data}
                                  formListField={field}
                                />

                                <ComprehensivePropertyInformationFormItems formListField={field} />

                                <PropertyDocumentsFormItems
                                  formDetails={formDataInfo.data}
                                  formListField={field}
                                />

                                <IsrDocumentsFormItems formListField={field} />

                                <EssentialLandlordInformationFormItems
                                  onCountrySelect={(country) => {
                                    onCountrySelect(field, country);
                                  }}
                                  formDetails={formDataInfo.data}
                                  formListField={field}
                                />

                                <ComprehensiveLandlordInformationFormItems
                                  formDetails={formDataInfo.data}
                                  formListField={field}
                                />

                                <LandlordDocumentsFormItems formListField={field} />

                                <DelegateDetailsFormItems
                                  formListField={field}
                                  formDetails={formDataInfo.data}
                                />

                                <AccountDetailsFormItems
                                  formDetails={formDataInfo.data}
                                  formListField={field}
                                />
                              </CandidateCollapse>
                            </Col>
                          );
                        })}

                        {fields.length < MAX_CANDIDATE_COUNT ? (
                          <Col span={24}>
                            <Space>
                              <PrimaryButton onClick={() => add()}>{`Add Candidate ${
                                fields.length + 1
                              }`}</PrimaryButton>
                              <Text type="secondary">You can add upto 6 candidates</Text>
                            </Space>
                          </Col>
                        ) : null}
                      </>
                    )}
                  </Form.List>
                )}

                <Col span={24}>
                  <SiteAcquisitionFeedbackFormItem required={feedbackRequired} />
                </Col>

                <Col span={24}>
                  <SiteAcquisitionActionButtons
                    onSaveDraft={onSaveDraft}
                    isSaveDraftLoading={isSaveDraftLoading}
                  />
                </Col>
              </Row>
            </Panel>
          </StyledCollapse>
        </StyledCard>
      </Form>
    </Spin>
  );
};

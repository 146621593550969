import { useQuery } from 'react-query';
import { jiraRequestsCenter, QUERIES } from 'api';

export function useJiraAssetsList(
  serviceDeskId: number,
  jiraFieldId: number,
  assetKey: string,
  enabled: boolean
) {
  return useQuery(
    [QUERIES.jiraAssetsList, serviceDeskId, jiraFieldId],
    async () => {
      const { data } = await jiraRequestsCenter.getJiraAssetsList(
        serviceDeskId,
        jiraFieldId,
        assetKey
      );
      return data.list;
    },
    {
      enabled: enabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
}

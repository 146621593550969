import styled from 'styled-components';
import { Row, Typography } from 'antd';
import {
  FileArrowDown,
  FileCsv,
  FileExcel,
  FileImage,
  FilePDF,
  FilePPT,
  FileText,
  FileVideo,
  FileWorld,
} from 'assets';
import { colors } from 'ui/elements/theme/constants';

const { Text } = Typography;

export const StyledFileCsvIcon = styled(FileCsv)`
  width: 20px;
  height: 20px;
`;
export const StyledFileExcelIcon = styled(FileExcel)`
  width: 20px;
  height: 20px;
`;
export const StyledFileImageIcon = styled(FileImage)`
  width: 20px;
  height: 20px;
`;
export const StyledFilePDFIcon = styled(FilePDF)`
  width: 20px;
  height: 20px;
`;
export const StyledFilePPTIcon = styled(FilePPT)`
  width: 20px;
  height: 20px;
`;
export const StyledFileTextIcon = styled(FileText)`
  width: 20px;
  height: 20px;
`;
export const StyledFileVideoIcon = styled(FileVideo)`
  width: 20px;
  height: 20px;
`;
export const StyledFileWorldIcon = styled(FileWorld)`
  width: 20px;
  height: 20px;
`;

export const StyledFileArrowDownIcon = styled(FileArrowDown)`
  width: 20px;
  height: 20px;
`;

export const StyledCommentAttachmentWrapper = styled(Row)`
  background: ${colors.white};
  border: 1px solid ${colors.borderGreySecond};
  border-radius: 2px 8px 8px 8px;
  overflow: hidden;
  margin-left: 12px;
  margin-top: 16px;
  padding: 16px;
  display: flex;
  flex: 1;
  align-items: center;
`;

export const StyledAttachmentText = styled(Text)`
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.textColorSecondary};
`;

export const StyledAttachmentTextWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  padding-left: 8px;
  padding-right: 8px;
  align-items: flex-start;
`;

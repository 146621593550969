import React, { FC } from 'react';

import { TType } from 'translations';

interface Props {
  setIsEditing: (val: boolean) => void;
  t: TType;
}

export const EditView: FC<Props> = (/*{ setIsEditing, data, t }*/) => <>Edit view</>;

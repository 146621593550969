import React, { FC } from 'react';
import { Form, Row } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { FormField } from 'types';
import { useFromFieldName } from 'ui/hooks';
import { StyledFieldLabel, StyledInputNumber } from '../GeneratedForm.Styled';
import { useFieldPlaceholder } from '../GeneratedForm.hooks';

interface Props {
  field: FormField;
  withLabel?: boolean;
  wrapperColSpan?: number;
  formListField?: FormListFieldData;
}

export const FieldNumber: FC<Props> = ({
  formListField,
  field,
  withLabel,
  wrapperColSpan = 21,
}) => {
  const fieldIsDisabled = field.properties.viewOnly === 'true';
  const fieldIsRequired = field.validationConstraints.some((rule) => rule.name === 'required');
  const fieldMaxNumber =
    field.properties.maxValue ?? field.properties.maxNumber ?? Number.MAX_SAFE_INTEGER;

  const placeholder = useFieldPlaceholder({
    placeholder: field.placeholder,
    label: field.label,
    placeholderPrefix: 'Enter',
  });

  const name = useFromFieldName(field.id, formListField);

  return (
    <>
      {withLabel && (
        <Row>
          <StyledFieldLabel>{field.label}</StyledFieldLabel>
        </Row>
      )}
      <Form.Item
        {...formListField}
        validateFirst
        rules={[
          {
            required: fieldIsRequired,
            message: `${field.label} is required`,
          },
          { type: 'number', max: Number(fieldMaxNumber) },
        ]}
        label={withLabel ? null : field.label}
        name={name}
        wrapperCol={{ span: wrapperColSpan }}
        initialValue={field.initialValue}
      >
        <StyledInputNumber
          value={
            fieldIsDisabled && fieldIsRequired
              ? field.defaultValue
                ? Number(field.defaultValue)
                : field.value?.value
                ? Number(field.value.value)
                : undefined
              : undefined
          }
          style={{ width: '100%' }}
          disabled={fieldIsDisabled}
          autoComplete="off"
          min={0}
          max={fieldMaxNumber}
          placeholder={placeholder}
        />
      </Form.Item>
    </>
  );
};

import React, { useEffect } from 'react';
import { Col, Empty, Row, Spin } from 'antd';

import { useTranslate } from 'translations';
import { recentFiles, useDispatch, useSelector, getRecentFilesStart } from 'store';
import { RequestStatus } from 'types';

import { Link } from 'react-router-dom';
import { PagesUrls } from 'namespace';
import { LoadingOutlined } from '@ant-design/icons';
import { StyledHeaderWidgetCol } from 'routes/Dashboard/Components/organisms/YourRequestWidget/YourRequestWidget.style';
import { colors } from 'ui/elements';
import {
  StyledLinkText,
  DashboardTitleStyled,
  StyledCol,
  StyledEmptyLiftSide,
  FullWidthSpinWrapper,
} from './OneDriveWidget.styled';

import { OneDriveFileCard } from './OneDriveFileCard';

const styleSpinner = { fontSize: 30, color: 'white' };
export const OneDriveWidget = () => {
  const { t } = useTranslate();

  const dispatch = useDispatch();

  const recentFilesData = useSelector(recentFiles);
  const antIcon = <LoadingOutlined style={styleSpinner} spin />;

  useEffect(() => {
    dispatch(getRecentFilesStart({ pagesize: 3 }));
  }, [dispatch]);

  const isLoading = recentFilesData.status === RequestStatus.pending;

  return (
    <Row>
      <StyledHeaderWidgetCol span={24}>
        <Row justify="space-between">
          <Col>
            <DashboardTitleStyled size={20} strong color={colors.textColorSecondary}>
              {t('oneDrive.widget.title')}
            </DashboardTitleStyled>
          </Col>
          <Col>
            <Link to={PagesUrls.OneDrive}>
              <StyledLinkText color={colors.textColorSecondary}>
                {t('common.viewAll')}
              </StyledLinkText>
            </Link>
          </Col>
        </Row>
      </StyledHeaderWidgetCol>
      <StyledCol
        span={24}
        $noData={
          isLoading ||
          recentFilesData.data?.list.length === 0 ||
          recentFilesData.data?.list.length === undefined
        }
        bgColor={colors.greyBackLabel}
      >
        {isLoading ? (
          <FullWidthSpinWrapper>
            <Spin indicator={antIcon} />
          </FullWidthSpinWrapper>
        ) : recentFilesData.data?.list.length && recentFilesData.data?.list.length > 0 ? (
          recentFilesData.data?.list.map((item) => <OneDriveFileCard list={item} key={item.id} />)
        ) : (
          <StyledEmptyLiftSide
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('dashboard.doc.empty.description')}
          />
        )}
      </StyledCol>
    </Row>
  );
};

import React, { useMemo } from 'react';
import { Col } from 'antd';
import { TType } from 'translations';
import { IProfileAttachment } from 'types';
import { ProfileEntryField, PrivateProfileAttachments } from 'ui';
import { StyledProfileRowContainer } from '../../NewProfile.styled';
import { StyledItemTitle, StyledItemWrapper } from './PrivateProfileProfessionalCertificate.styles';

export interface PrivateProfileProfessionalCertificateItemProps {
  t: TType;
  certificateName: string;
  issuingOrganisation: string;
  issueDate: string;
  expirationDate: string;
  professionalCertificateAttachmentList: IProfileAttachment[];
}

export const PrivateProfileProfessionalCertificateItem: React.FC<PrivateProfileProfessionalCertificateItemProps> = ({
  t,
  certificateName,
  issuingOrganisation,
  issueDate,
  expirationDate,
  professionalCertificateAttachmentList,
}: PrivateProfileProfessionalCertificateItemProps) => {
  const experienceFields = useMemo(
    () => [
      {
        label: t('user.privateProfile.professionalCertificate.issuingOrganisation'),
        value: issuingOrganisation,
      },
      { label: t('user.privateProfile.professionalCertificate.issueDate'), value: issueDate },
      {
        label: t('user.privateProfile.professionalCertificate.expirationDate'),
        value: expirationDate,
      },
    ],
    [t, issuingOrganisation, issueDate, expirationDate]
  );

  return (
    <StyledItemWrapper>
      <StyledItemTitle>{`${certificateName}`}</StyledItemTitle>
      <StyledProfileRowContainer gutter={[25, 15]} wrap={true}>
        {experienceFields.map(({ label, value }, index) => (
          <Col xs={24} sm={24} md={12} lg={8} key={index}>
            <ProfileEntryField label={label} value={value} />
          </Col>
        ))}
      </StyledProfileRowContainer>
      <PrivateProfileAttachments attachments={professionalCertificateAttachmentList} />
    </StyledItemWrapper>
  );
};

import React, { FC, useState, memo, useCallback, useEffect } from 'react';
import { Popover, notification } from 'antd';
import { useTranslate } from 'translations';
import { RequestStatus } from 'types';
import { UserInfromation } from './UserInformation';
import { StyledPopover, StyledLoading } from './UserPopover.styled';
import { UserPopoverNavigation } from './UserPopoverNavigation';
import { InputMessage } from './InputMessage';
import { ConnectorProps, userInfoConnector } from './UserPopover.connector';
import { PARAM_TYPE } from './constants';

interface UserPopOverProps {
  currentID?: string;
  children: React.ReactElement;
  currentEmail?: string;
  hcmID?: string;
}
export const UserPopoverComponent: FC<UserPopOverProps & ConnectorProps> = ({
  children,
  currentID,
  currentEmail,
  hcmID,
  getUserInfoCardStart,
  userPopoverCard,
  getuserInfoStatusStart,
  userStatus,
}) => {
  const [isVisible, setVisible] = useState<boolean>(false);
  const userPopoverValue = currentEmail ? currentEmail : currentID ? currentID : hcmID;
  const popOverData = userPopoverCard[userPopoverValue || ''];
  const popOverStatus = userStatus[userPopoverValue || ''];
  const { t } = useTranslate();

  const content = (
    <StyledPopover>
      {popOverData?.status === RequestStatus.pending ? (
        <StyledLoading loading={true} />
      ) : (
        <StyledPopover>
          <UserInfromation
            data={popOverData?.data}
            userStatus={popOverStatus?.data?.status}
            requestStatus={popOverStatus?.status}
          />
          <UserPopoverNavigation data={popOverData?.data} />
          <InputMessage data={popOverData?.data} />
        </StyledPopover>
      )}
    </StyledPopover>
  );

  const requestCallBack = useCallback(
    (type: string, value) => {
      if (
        value !== null &&
        value !== undefined &&
        userPopoverCard[value]?.status !== RequestStatus.resolved &&
        userPopoverCard[value]?.status !== RequestStatus.rejected
      ) {
        getUserInfoCardStart({
          [type]: value,
          params: {
            [type]: value,
          },
        });
        getuserInfoStatusStart({
          [type]: value,
          params: {
            [type]: value,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [popOverData?.status]
  );

  const showError = useCallback(() => {
    if (!isVisible) {
      return;
    } else {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const onVisible = useCallback(() => {
    if (currentID || currentEmail || hcmID) {
      setVisible(!isVisible);
    } else if (!currentID || !currentEmail) {
      setVisible(false);
    }
  }, [isVisible, currentID, currentEmail, hcmID]);

  useEffect(() => {
    if (
      popOverData?.status === RequestStatus.rejected &&
      userPopoverValue &&
      userPopoverCard[userPopoverValue]?.status === RequestStatus.rejected
    ) {
      setVisible(false);
      showError();
    }
  }, [popOverData?.status, userPopoverValue, showError, userPopoverCard]);

  useEffect(() => {
    if (isVisible) {
      const type = currentEmail
        ? PARAM_TYPE.email
        : currentID
        ? PARAM_TYPE.adOid
        : hcmID
        ? PARAM_TYPE.hcmId
        : '';
      requestCallBack(type, userPopoverValue);
    }
  }, [isVisible, currentEmail, currentID, hcmID, requestCallBack, userPopoverValue]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popover
        placement="right"
        content={content}
        onVisibleChange={onVisible}
        visible={isVisible}
        trigger={'hover'}
      >
        <span>{children}</span>
      </Popover>
    </div>
  );
};

export const UserPopover = userInfoConnector(memo(UserPopoverComponent));

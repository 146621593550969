import React, { FC } from 'react';
import { Space } from 'antd';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { TextStyled, useFullName } from 'ui';
import { useSelector } from 'react-redux';
import { camundaUsersDataSelector } from 'store';

interface DelegateInfoProps {
  adOid: string;
  employeeName?: string;
}

export const DelegateInfo: FC<DelegateInfoProps> = ({ adOid, employeeName }) => {
  const camundaUsers = useSelector(camundaUsersDataSelector);

  const fullName = useFullName(
    camundaUsers[adOid]?.data?.firstName,
    camundaUsers[adOid]?.data?.lastName
  );

  return (
    <Space direction="horizontal">
      <MSTeamsUserAvatar id={adOid} name={employeeName || fullName} />
      <TextStyled>{employeeName || fullName}</TextStyled>
    </Space>
  );
};

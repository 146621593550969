import { Gutter } from 'antd/lib/grid/row';
import { FORM_ITEM_REQUIRED_RULE, VALIDATION_RULE_FALSE } from 'utils/formValidation';

export const GUTTER_16: [Gutter, Gutter] = [16, 16];

export const ICON_STYLES = {
  fontSize: 24,
  height: '24px',
  width: '24px',
};

export const GUTTER_0_16: [Gutter, Gutter] = [0, 16];

export const COL_SPAN_SIZE_20 = { span: 20 };

export const COL_SPAN_SIZE_8 = { span: 8 };

export const DEFAULT_COLLAPSE_KEY = ['1'];

export const FORM_FILED_BREAKPOINT = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  xxl: { span: 12 },
};

export const FORM_FILED_DETAIL_BREAKPOINT = {
  xs: { span: 24 },
  sm: { span: 12 },
  md: { span: 9 },
  xl: { span: 8 },
  xxl: { span: 6 },
};

export const CARD_COMMENTS_BREAKPOINT = {
  xs: { span: 24, offset: -6 },
  xxl: { span: 12, offset: 0 },
};

export const FIELDS_REQUIRE = (requireField: boolean) => {
  if (requireField) {
    return FORM_ITEM_REQUIRED_RULE;
  } else {
    return VALIDATION_RULE_FALSE;
  }
};

interface Option {
  id: string | number;
  label: string;
}

export type Options = Option[];

export const filesList = {
  list: [
    {
      createdBy: 'John Smith',
      createdDate: '06.11.2020 13:12',
      file: { MimeType: 'Word' },
      id: '1',
      lastAccessedDate: '06.11.2020 13:12',
      lastModifiedBy: 'John Smith',
      lastModifiedDate: '06.11.2020 13:12',
      name: 'Tawal assets',
    },
    {
      createdBy: 'John Smith',
      createdDate: '06.11.2020 13:12',
      file: { MimeType: 'Word' },
      id: '2',
      lastAccessedDate: '06.11.2020 13:12',
      lastModifiedBy: 'John Smith',
      lastModifiedDate: '06.11.2020 13:12',
      name: 'File2',
    },
    {
      createdBy: 'John Smith',
      createdDate: '06.11.2020 13:12',
      file: { MimeType: 'PowerPoint' },
      id: '3',
      lastAccessedDate: '06.11.2020 13:12',
      lastModifiedBy: 'John Smith',
      lastModifiedDate: '06.11.2020 13:12',
      name: 'Tawal assets',
    },
    {
      createdBy: 'John Smith',
      createdDate: '06.11.2020 13:12',
      file: { MimeType: 'Exul' },
      id: '4',
      lastAccessedDate: '06.11.2020 13:12',
      lastModifiedBy: 'John Smith',
      lastModifiedDate: '06.11.2020 13:12',
      name: 'Dynmic',
    },
  ],
  skipToken: 'null',
};

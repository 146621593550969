import { useMutation } from 'react-query';
import { googleMapsApi } from '../GoogleMaps';
import { getFormattedAddressFromGoogleResponse } from './helper';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const useGetGoogleMapsAddressByLocation = () => {
  return useMutation(async (position: { lat: number; lng: number }) => {
    const params = {
      latlng: `${position.lat},${position.lng}`,
      result_type: 'street_address',
      key: GOOGLE_API_KEY as string,
    };
    const { data } = await googleMapsApi.getAddressByLocation(params);
    return getFormattedAddressFromGoogleResponse(data);
  });
};

import { FormInstance } from 'antd/lib/form';
import {
  DependenciesFieldsBetweenForms,
  FiledHiddenProp,
} from 'app/ServicesProvider/ServicesContext';
import { Dispatch, SetStateAction } from 'react';
import { FormFiledType, FormFields, FormFieldId } from 'types';

export interface HomeLoanQueryProps {
  loanAmount: number | undefined;
  fixedInterestRate: number | undefined;
  loanTerm: number | undefined;
}

interface DependentFieldsProps {
  fieldName?: string | number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;

  form?: FormInstance;

  action?: 'disabled' | 'hidden';

  setFieldHidden: Dispatch<SetStateAction<FiledHiddenProp>>;

  formData?: FormFields;

  fieldType?: FormFiledType;

  taskId?: string;

  setDependenciesFieldsBetweenForms?: Dispatch<
    React.SetStateAction<DependenciesFieldsBetweenForms>
  >;
}

export const HomeLoanInitiateFieldName = {
  monthlyInstallment: 'monthlyInstallment',
  interestAmount: 'interestAmount',
  interestRate: 'interestRate',
  loanAmount: 'loanAmount',
  loanTerm: 'loanTerm',
};

interface ActionButtonDependanceProps {
  dependFieldId: string | undefined;
  dependFieldValue: string | undefined;
  fieldId: FormFieldId;
}

//this function to return the dependency properties for trigger field type
const configFieldDependency = (fieldType: FormFiledType, formData: FormFields) => {
  return formData.reduce(
    (acc, field) => {
      if (field.type.name === fieldType) {
        const listOfDependencies = {
          dependFieldId: field.properties.dependentFields,

          dependFieldValue: field.properties.isShow,

          fieldId: field.id,
        };

        acc = [...acc, listOfDependencies];
      }

      return acc;
    },

    [{} as ActionButtonDependanceProps]
  );
};

// this function handle the dependency between the field with the action
export const checkDependentField = (dependField: DependentFieldsProps | undefined): void => {
  if (dependField?.fieldType && dependField.formData) {
    const dependencyField = configFieldDependency(dependField?.fieldType, dependField.formData);

    dependencyField.forEach((field) => {
      if (
        dependField.fieldName === field.dependFieldId &&
        dependField.form &&
        dependField.value !== undefined
      ) {
        if (dependField.action === 'disabled' && dependField.setDependenciesFieldsBetweenForms) {
          if (field.dependFieldValue !== dependField.value) {
            dependField.setDependenciesFieldsBetweenForms({
              fieldId: field.fieldId,
              taskId: dependField.taskId,
            });
          }
        }

        if (dependField.action === 'hidden' && dependField.value !== field.dependFieldValue) {
          dependField.setFieldHidden({
            isHidden: dependField.value !== field.dependFieldValue,
            fieldId: field.fieldId,
          });
        }
      }
    });
  } else {
    dependField?.setFieldHidden({ isHidden: null, fieldId: undefined });
  }
};

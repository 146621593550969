import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { space } from 'ui/elements';

import {
  StyledDescription as StyleItemId,
  StyledPaymentValueText,
} from '../ExpensesDrawer/ExpenseDrawer.styled';

interface ItemTitleProps {
  title: string;
  itemId?: string;
  isItems?: boolean;
}

const StyledWrapper = styled(Row)`
  padding-bottom: ${space.little};
`;

const ItemTitle: React.FC<ItemTitleProps> = ({ itemId, title, isItems }) => {
  return (
    <React.Fragment>
      <StyledWrapper justify={'space-between'} align={'middle'}>
        <Col>
          <StyledPaymentValueText>{title}</StyledPaymentValueText>
        </Col>
        {itemId && isItems ? (
          <Col>
            <StyleItemId>{itemId}</StyleItemId>
          </Col>
        ) : null}
      </StyledWrapper>
    </React.Fragment>
  );
};

export default ItemTitle;

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetAllRequestsCardFail,
  GetAllRequestsCardStart,
  GetAllRequestsCardSuccess,
  getAllRequestsCardFail,
  getAllRequestsCardStart,
  getAllRequestsCardSuccess,
} from './allRequestCardSlice';

export const allRequestsCardEpic: Epic<
  GetAllRequestsCardStart,
  GetAllRequestsCardSuccess | GetAllRequestsCardFail,
  RootState,
  typeof API
> = (action$, _, { allRequestCard }) =>
  action$.pipe(
    filter(getAllRequestsCardStart.match),
    exhaustMap(() =>
      from(allRequestCard.getAllRequestCard()).pipe(
        map(({ data }) => getAllRequestsCardSuccess(data)),
        catchError((error) => of(getAllRequestsCardFail(error?.response?.data)))
      )
    )
  );

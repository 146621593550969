import React, { FC, Fragment, memo, useCallback, useEffect, useState } from 'react';
import { Empty, Form, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslate } from 'translations';
import { CommonFieldName, FullWidthSpin, SelfServiceLetterFieldName } from 'ui';
import { FieldData } from 'rc-field-form/lib/interface';
import { DirectedTo, LetterType } from 'types';
import { useSelfServiceLetterForm, useSelfServiceLetterSubmit } from 'api/StaticForm';
import {
  AttachmentFile,
  attachmentsFieldsId,
} from 'routes/Tasks/components/pages/ServiceNewRequest/ServiceNewRequest';
import { useHandleSubmitStatus } from 'routes/Tasks';
import { VALIDATE_MESSAGES } from 'utils/formValidation';
import { submitAttachmentForm } from 'ui/publicFunction';
import { useHistory } from 'react-router-dom';
import { SelfServiceLetterFields } from './SelfServiceLetterFields';

interface SelfServiceLetterProps {
  adoid: string;
  formName?: string;
}

export const SelfServiceLetterComponent: FC<SelfServiceLetterProps> = ({ adoid, formName }) => {
  const { data: formData, isError, isLoading } = useSelfServiceLetterForm(adoid);
  const { t } = useTranslate();
  const [form] = useForm();
  const [formFields, setFormFields] = useState<LetterType>();
  const [directedToField, setDirectedToField] = useState<DirectedTo>();
  const [attachmentData, setAttachmentData] = useState<AttachmentFile[]>([]);
  const history = useHistory();
  const mutation = useSelfServiceLetterSubmit(adoid);

  const onFieldsChange = useCallback(
    (changedFields: FieldData[], allFields: FieldData[]) => {
      const [changeField] = changedFields;
      const { name, value } = changeField || ({} as FieldData);

      if (Array.isArray(name) && name.length && formData) {
        const [fieldName] = name;
        if (fieldName === SelfServiceLetterFieldName.letterType) {
          const formFields = formData.letterType?.filter((type) => type.letterType === value);
          if (formFields) setFormFields(formFields[0]);
        }
        if (fieldName === SelfServiceLetterFieldName.directedTo) {
          const directedToField = formFields?.directedTo.filter((type) => type.type === value);
          if (directedToField) setDirectedToField(directedToField[0]);
        }
      }
    },
    [formData, formFields?.directedTo]
  );

  const onSubmitFinish = useCallback(
    async (variables) => {
      const attachmentsForm: AttachmentFile[] = [];

      await mutation.mutate(variables);

      const currentFormFieldsName = Object.keys(variables);

      const currentAttachmentId = currentFormFieldsName.filter((variables: string) => {
        return attachmentsFieldsId.includes(variables);
      });

      if (currentAttachmentId && variables) {
        currentAttachmentId.forEach((fieldId: string) => {
          const attachmentValue = variables[fieldId];
          if (attachmentValue) {
            return attachmentsForm.push({ [fieldId]: attachmentValue });
          }
        });
      }
      if (attachmentsForm) {
        setAttachmentData(attachmentsForm);
      }
    },
    [mutation]
  );

  useHandleSubmitStatus(t, mutation.isLoading, mutation.isError, mutation.isSuccess);

  useEffect(() => {
    mutation.data &&
      submitAttachmentForm(mutation.isSuccess, mutation.data, history, undefined, attachmentData);
  }, [attachmentData, history, mutation.data, mutation.isSuccess]);

  useEffect(() => {
    if (
      formFields?.bankNameLabelArabic &&
      formFields.bankNameLabelEnglish &&
      formFields.bankNameValueArabic &&
      formFields.bankNameValueEnglish
    ) {
      form.setFieldsValue({
        [SelfServiceLetterFieldName.userBankNameAR]: formFields.bankNameValueArabic,
      });
      form.setFieldsValue({
        [SelfServiceLetterFieldName.userBankNameENG]: formFields.bankNameValueEnglish,
      });
    }
  }, [form, formFields]);

  const resetFields = useCallback(() => {
    form.resetFields([
      SelfServiceLetterFieldName.directedTo,
      SelfServiceLetterFieldName.bankName,
      SelfServiceLetterFieldName.otherAR,
      SelfServiceLetterFieldName.otherENG,
      SelfServiceLetterFieldName.chamberOfCommerce,
      SelfServiceLetterFieldName.embassy,
      CommonFieldName.attachment,
    ]);
    setDirectedToField(undefined);
  }, [form]);

  const resetDirectedFields = useCallback(() => {
    form.resetFields([
      SelfServiceLetterFieldName.bankName,
      SelfServiceLetterFieldName.otherAR,
      SelfServiceLetterFieldName.otherENG,
      SelfServiceLetterFieldName.embassy,
    ]);
  }, [form]);
  return (
    <Fragment>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        validateMessages={VALIDATE_MESSAGES(t)}
        onFieldsChange={onFieldsChange}
        onFinish={onSubmitFinish}
      >
        {isError ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
        ) : isLoading ? (
          <FullWidthSpin />
        ) : (
          <Spin spinning={mutation.isLoading} delay={100}>
            <SelfServiceLetterFields
              formData={formData}
              formFields={formFields}
              t={t}
              resetField={resetFields}
              form={form}
              directedToField={directedToField}
              showField={formFields !== undefined}
              resetDirectedFields={resetDirectedFields}
            />
          </Spin>
        )}
      </Form>
    </Fragment>
  );
};

export const SelfServiceLetter = memo(SelfServiceLetterComponent);

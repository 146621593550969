import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppLayout } from 'layouts';
import { Header } from 'app';
import { TitleH1 } from 'ui';
import { TType, withTranslate } from 'translations';
import { profileData as profileDataSelector } from 'store';
import { Col, Row } from 'antd';
import { ProfileData } from 'types';
import { Public } from './Public';

interface Props {
  t: TType;
}

export const ProfileComponent: FC<Props> = ({ t }) => {
  const { data } = useSelector(profileDataSelector);
  const profileData = data as ProfileData;
  return (
    <>
      <AppLayout displayFooter={false}>
        <Header>
          <Row align="middle">
            <Col>
              <TitleH1>{t('profile.title')}</TitleH1>
            </Col>
          </Row>
        </Header>
        <Row className="layout-main"></Row>
        <Public profileData={profileData} />
      </AppLayout>
    </>
  );
};

export const Profile = withTranslate(ProfileComponent);

import React, { FC, useMemo } from 'react';
import { Card, Typography, Empty } from 'antd';

import { TType, useTranslate } from 'translations';
import { StyledTable } from 'ui/components/TeamsChannelFilesList/TeamsChannelFilesList.styled';
import {
  StyledDate,
  StyledToolbar,
  StyledWrapperBtn,
  StyleNavigationButton,
} from 'routes/Calendar/Components/CalendarView/CalendarStyle';
import { AttendanceInfoData } from 'types';
import { TitleH3 } from 'ui';
import { LIST_VIEW_PAGE_SIZE } from 'routes/Calendar';
import { ColumnType, ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { getDuration } from '../helper';
import {
  AttendanceListWrapper,
  StyledSuccessStatus,
  StyledWarningStatus,
  StyledOtherStatus,
  StyledRemarkWrapper,
  StyledTitleH3,
  StyledRightSideWrapper,
  StyledDash,
  CenteredCard,
  StyledLastModifiedDateWrapper,
  StyledLastModifiedDate,
} from './ListView.styled';

const { Text } = Typography;

interface ColumnItem {
  title: string;
  dataIndex: string;
  key: string;
  width: string;
}

interface ListViewProps {
  attendanceListData?: AttendanceInfoData[];
  isLoading?: boolean;
  currentPage: number;
  setCurrentPage: (pageNumber: number) => void;
  tableHeader: string | undefined;
  isMyTeamAttendance?: boolean;
  isSelected: boolean;
  isError?: boolean;
  getNextMonth?: () => void;
  getPriviseMonth?: () => void;
  getCurrentMonth?: () => void;
  totalResult?: number;
  isNextButtonDisable?: boolean;
  lastModifiedDate?: string;
}

const getColumns = (t: TType) => {
  const columns: ColumnsType<ColumnType<ColumnItem>> = [
    {
      title: <Text strong>{t('attendance.listView.column.date')}</Text>,
      dataIndex: 'currentDate',
      key: 'currentDate',
      width: '15%',
    },
    {
      title: <Text strong>{t('attendance.listView.column.checkintime')}</Text>,
      dataIndex: 'checkInTime',
      key: 'checkInTime',
      width: '15%',
    },
    {
      title: <Text strong>{t('attendance.listView.column.checkouttime')}</Text>,
      dataIndex: 'checkOutTime',
      key: 'checkOutTime',
      width: '15%',
    },
    {
      title: <Text strong>{t('attendance.listView.column.shiftStartTime')}</Text>,
      dataIndex: 'shiftStartDateTime',
      key: 'shiftStartDateTime',
      width: '15%',
    },
    {
      title: <Text strong>{t('attendance.listView.column.shiftEndTime')}</Text>,
      dataIndex: 'shiftEndDateTime',
      key: 'shiftEndDateTime',
      width: '15%',
    },
    {
      title: <Text strong>{t('attendance.listView.column.duration')}</Text>,
      dataIndex: 'duration',
      key: 'duration',
      width: '10%',
    },
    {
      title: <Text strong>{t('attendance.listView.column.remarks')}</Text>,
      dataIndex: 'remark',
      key: 'remark',
      width: '15%',
    },
  ];

  return columns;
};

const AttendanceListViewComponent: FC<ListViewProps> = ({
  attendanceListData,
  isLoading,
  currentPage,
  setCurrentPage,
  tableHeader,
  isMyTeamAttendance,
  isSelected,
  getCurrentMonth,
  getNextMonth,
  getPriviseMonth,
  totalResult,
  isNextButtonDisable,
  lastModifiedDate,
}) => {
  const { t } = useTranslate();

  const columns = useMemo(() => getColumns(t), [t]);

  const onTableChange = (pagination: TablePaginationConfig) => {
    const { current } = pagination;
    setCurrentPage(current ? current - 1 : 0);
  };

  const listViewData = useMemo(
    () =>
      attendanceListData?.map(
        (
          {
            currentDate,
            checkInTime,
            checkOutTime,
            shiftStartDateTime,
            shiftEndDateTime,
            remark,
            earlyCheckOutDuration,
            lateCheckInDuration,
            isAbsence,
            isHoliday,
            isOnLeave,
            isWFH,
            durationInOffice,
            isWeekend,
          },
          index
        ) => {
          const dashComponent = <StyledDash>{'-'}</StyledDash>;
          return {
            key: index,
            currentDate,
            checkInTime: checkInTime || dashComponent,
            checkOutTime: checkOutTime || dashComponent,
            shiftStartDateTime: shiftStartDateTime || dashComponent,
            shiftEndDateTime: shiftEndDateTime || dashComponent,
            duration: (
              <Text>{durationInOffice ? getDuration(durationInOffice) : dashComponent}</Text>
            ),
            remark: (
              <StyledRemarkWrapper>
                {isAbsence ? (
                  <StyledWarningStatus>{t('attendance.card.status.absent')}</StyledWarningStatus>
                ) : isWFH ? (
                  <StyledSuccessStatus>{t('attendance.card.status.wfh')}</StyledSuccessStatus>
                ) : isOnLeave || isHoliday || (isWeekend && !checkInTime) ? (
                  <StyledOtherStatus>
                    {isOnLeave
                      ? t('attendance.card.status.leave')
                      : isHoliday
                      ? t('attendance.card.status.holiday')
                      : t('attendance.card.status.weekend')}
                  </StyledOtherStatus>
                ) : checkInTime && !checkOutTime ? (
                  <StyledSuccessStatus>{t('attendance.card.in.office')}</StyledSuccessStatus>
                ) : remark && remark.length > 0 ? (
                  remark.map((status, index) =>
                    lateCheckInDuration || earlyCheckOutDuration ? (
                      <StyledWarningStatus key={index} isMoreRemarks={remark?.length > 0}>
                        {status || '-'}
                      </StyledWarningStatus>
                    ) : (
                      <StyledSuccessStatus key={index}>{status}</StyledSuccessStatus>
                    )
                  )
                ) : (
                  dashComponent
                )}
              </StyledRemarkWrapper>
            ),
          };
        }
      ),
    [attendanceListData, t]
  );

  return (
    <AttendanceListWrapper>
      {!isSelected ? (
        <CenteredCard>
          <TitleH3 type="secondary">{t('myTeamAttendance.selectToReview')}</TitleH3>
        </CenteredCard>
      ) : (
        <Card>
          {isMyTeamAttendance && getPriviseMonth && getCurrentMonth && getNextMonth ? (
            <StyledToolbar>
              <StyledDate>{tableHeader}</StyledDate>
              <StyledRightSideWrapper>
                <StyledWrapperBtn>
                  <StyleNavigationButton onClick={() => getPriviseMonth()}>
                    {t('attendance.listView.navigation.back')}
                  </StyleNavigationButton>
                  <StyleNavigationButton onClick={() => getCurrentMonth()}>
                    {t('attendance.listView.navigation.currentMonth')}
                  </StyleNavigationButton>
                  <StyleNavigationButton
                    onClick={() => getNextMonth()}
                    disabled={isNextButtonDisable}
                  >
                    {t('attendance.listView.navigation.next')}
                  </StyleNavigationButton>
                </StyledWrapperBtn>
              </StyledRightSideWrapper>
            </StyledToolbar>
          ) : (
            <StyledTitleH3>{tableHeader}</StyledTitleH3>
          )}

          <StyledTable
            columns={columns}
            dataSource={listViewData}
            pagination={{
              total: totalResult,
              showSizeChanger: false,
              defaultCurrent: currentPage + 1,
              pageSize: LIST_VIEW_PAGE_SIZE,
              current: currentPage + 1,
            }}
            scroll={{
              scrollToFirstRowOnChange: true,
              x: true,
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('attendance.card.no.time.tracked')}
                />
              ),
            }}
            loading={isLoading}
            onChange={onTableChange}
          />
          {lastModifiedDate && (
            <StyledLastModifiedDateWrapper>
              <StyledLastModifiedDate>{lastModifiedDate}</StyledLastModifiedDate>
            </StyledLastModifiedDateWrapper>
          )}
        </Card>
      )}
    </AttendanceListWrapper>
  );
};

export const AttendanceListView = React.memo(AttendanceListViewComponent);

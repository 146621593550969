import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const payslipsPdfFileData = (state: RootState): RootState['paySlipsPdfFile'] =>
  state.paySlipsPdfFile;

export const paySlipsPdfFileSelector = createSelector(
  payslipsPdfFileData,
  ({ error, status, data }) => ({
    error,
    status,
    data,
  })
);

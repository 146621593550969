import styled from 'styled-components';
import { DrawerUi } from 'ui';

export const StyledDrawerUi = styled(DrawerUi)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
`;
export const UserDrawerUserInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
`;

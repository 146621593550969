import React, { FC, useCallback } from 'react';
import { Col, Empty, Row, Spin } from 'antd';

import { useTranslate } from 'translations';

import { Link, useHistory } from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';

import {
  DashboardTitleStyled,
  FullWidthSpinWrapper,
  StyledCol,
  StyledColAlign,
  StyledEmptyLiftSide,
  StyledLinkText,
  StyledWrapperContent,
} from 'ui/components/OneDrive/OneDriveWidget/OneDriveWidget.styled';
import { useMyRequestWidget } from 'api';
import { colors } from 'ui/elements';
import {
  StyledCardFile,
  StyledCreateNewRequest,
  StyledHeaderWidgetCol,
  StylePlusIcon,
} from './YourRequestWidget.style';

import { RequestCard } from './YourRequestWidgetCard';
import { AllRequestLocation, NewRequest } from './constants';

const styleSpinner = { fontSize: 30, color: 'white' };

interface Props {
  userId: string;
}

export const YourRequestWidget: FC<Props> = ({ userId }) => {
  const { t } = useTranslate();
  const history = useHistory();
  const requestParams = { start: 0, pageSize: 3 };
  const requestList = useMyRequestWidget(userId, requestParams);
  const antIcon = <LoadingOutlined style={styleSpinner} spin />;

  const onClickHandlerNewRequest = () => {
    history.replace(NewRequest);
  };
  const onClickHandler = useCallback(
    (processInstanceId: string) => {
      const toCurrentRequest = {
        pathname: `/tasks/${processInstanceId}`,
        state: { defaultTab: '0' },
      };
      history.replace(toCurrentRequest);
    },
    [history]
  );

  return (
    <Row>
      <StyledHeaderWidgetCol span={24}>
        <Row justify="space-between" align={'middle'}>
          <Col>
            <DashboardTitleStyled size={20} strong color={colors.textColorSecondary}>
              {t('dashboard.yourRequest.title')}
            </DashboardTitleStyled>
          </Col>
          <Col>
            <Link to={AllRequestLocation}>
              <StyledLinkText color={colors.textColorSecondary}>
                {t('common.viewAll')}
              </StyledLinkText>
            </Link>
          </Col>
        </Row>
      </StyledHeaderWidgetCol>
      <Col span={24}>
        <StyledCardFile onClick={onClickHandlerNewRequest} bgColor={colors.greyBackLabel}>
          <StyledWrapperContent align={'middle'}>
            <StyledColAlign span={4}>
              <StylePlusIcon bgColor={colors.accentOrange} />
            </StyledColAlign>
            <Col span={20}>
              <StyledCreateNewRequest color={colors.primaryColor}>
                {t('dashboard.yourRequest.newRequest')}
              </StyledCreateNewRequest>
            </Col>
          </StyledWrapperContent>
        </StyledCardFile>
      </Col>

      <StyledCol
        span={24}
        $noData={
          requestList.isLoading ||
          requestList.data?.requestInfoList.length === undefined ||
          requestList.data?.requestInfoList.length === 0
        }
      >
        {requestList.isLoading ? (
          <FullWidthSpinWrapper>
            <Spin indicator={antIcon} />
          </FullWidthSpinWrapper>
        ) : requestList.data && requestList.data.requestInfoList.length > 0 ? (
          requestList.data.requestInfoList.map((item) => (
            <RequestCard list={item} key={item.prettyId} onClickHandler={onClickHandler} />
          ))
        ) : (
          <StyledEmptyLiftSide
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('dashboard.yourRequest.empty.description')}
          />
        )}
      </StyledCol>
    </Row>
  );
};

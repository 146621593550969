import React, { FC } from 'react';
import { Col, Row, Collapse } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { useGetFromFieldId } from 'ui/hooks';
import {
  CustomCollapseWithPlusMinusIcons,
  StyledCustomPlusMinusCollapsePanelTitleContainer,
} from 'ui/elements';
import { FormFields } from '../types';
import { TextInputFormItem } from './TextInputFormItem';
import { SelectInputFormItem } from './SelectInputFormItem';
import { AttachmentFormItem } from './AttachmentFormItem';
import { NumberInputFormItem } from './NumberInputFormItem';
import { TextAreaFormItem } from './TextAreaFormItem';
import { DateFormItem } from './DateFormItem';

const { Panel } = Collapse;

export const FormItemsCollapse: FC<Props> = ({ formFields, title, formListField }) => {
  const getFormFieldId = useGetFromFieldId();

  if (!formFields) {
    return null;
  }

  return (
    <CustomCollapseWithPlusMinusIcons>
      <Panel
        key={`0`}
        header={
          <StyledCustomPlusMinusCollapsePanelTitleContainer>
            <p>{title}</p>
          </StyledCustomPlusMinusCollapsePanelTitleContainer>
        }
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 0]}>
              {Object.entries(formFields).map(([_, field]) => {
                let child;
                const fieldId = getFormFieldId(field.id);
                if (field.type === 'input') {
                  child = <TextInputFormItem field={field} formListField={formListField} />;
                }
                if (field.type === 'textArea') {
                  child = <TextAreaFormItem field={field} formListField={formListField} />;
                }
                if (field.type === 'number') {
                  child = <NumberInputFormItem field={field} formListField={formListField} />;
                }
                if (field.type === 'select') {
                  child = <SelectInputFormItem field={field} formListField={formListField} />;
                }
                if (field.type === 'file') {
                  child = <AttachmentFormItem field={field} formListField={formListField} />;
                }
                if (field.type === 'date') {
                  child = <DateFormItem field={field} formListField={formListField} />;
                }
                if (field.type === 'custom') {
                  child = field.component;
                }

                return child ? (
                  <Col xs={24} xl={field.type === 'textArea' ? 24 : 12} key={fieldId}>
                    {child}
                  </Col>
                ) : null;
              })}
            </Row>
          </Col>
        </Row>
      </Panel>
    </CustomCollapseWithPlusMinusIcons>
  );
};

interface Props {
  formFields?: FormFields;
  title?: string;
  formListField: FormListFieldData;
}

import { TeamsData } from 'types';
import { Core, TEAMS } from '../Core';

interface GetTeams {
  (): Promise<{ data: TeamsData }>;
}

class Teams extends Core {
  getTeams: GetTeams = async () => this.get(TEAMS());
}

export const teams = new Teams();

import styled from 'styled-components';
import { colors } from 'ui/elements';
import { Typography } from 'antd';

const { Text } = Typography;

export const StyledProfileSectionContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(28, 43, 101, 0.14);
`;

export const StyledProfileSectionContainerTitle = styled.h3`
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.labelGrey};
`;

export const StyledProfileSectionContainerEmptyAndLoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledProfileFieldLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.textColorSecondary};
  margin-bottom: 5px;
`;

export const StyledProfileFieldValue = styled(Text)`
  font-size: 16px !important;
  font-weight: 500 !important;
  color: ${colors.primaryColor} !important;
  margin-bottom: 0 !important;
  width: 100%;
`;

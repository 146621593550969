import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { ServicesCardsInfoAgg, RequestCommonError, RequestStatus } from 'types';
import { MyRequestsCardState } from './myRequestCardsTypes';

export const emptyMyRequestCardsState: MyRequestsCardState = {
  status: RequestStatus.idle,
  error: null,
  hasMore: true,
};

export type GetMyRequestCardsStart = PayloadAction<{
  userId: string;
  params?: AxiosRequestConfig['params'];
}>;
export type GetMyRequestCardsSuccess = PayloadAction<ServicesCardsInfoAgg>;
export type GetMyRequestCardsFail = PayloadAction<RequestCommonError>;
export type GetMyRequestCardsReset = PayloadAction;

const myRequestCards = createSlice({
  name: 'myRequestCards',
  initialState: emptyMyRequestCardsState,
  reducers: {
    getMyRequestCardsStart(state, _: GetMyRequestCardsStart) {
      state.status = RequestStatus.pending;
    },
    getMyRequestCardsSuccess(state, action: GetMyRequestCardsSuccess) {
      state.status = RequestStatus.resolved;
      if (action.payload?.requestInfoList.length < 10) {
        state.hasMore = false;
      }
      if (!state.data?.requestInfoList.length) {
        state.data = action.payload;
        return;
      }
      state.data.requestInfoList = [
        ...state.data.requestInfoList,
        ...action.payload.requestInfoList,
      ];
    },
    getMyRequestCardsFail(state, action: GetMyRequestCardsFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getMyRequestCardsReset(state, _: GetMyRequestCardsReset) {
      state.status = RequestStatus.idle;
      state.error = null;
      state.data = undefined;
      state.hasMore = true;
    },
  },
});

export const {
  getMyRequestCardsFail,
  getMyRequestCardsStart,
  getMyRequestCardsSuccess,
  getMyRequestCardsReset,
} = myRequestCards.actions;
export default myRequestCards.reducer;

import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { subCategoryCard } from '../SubCategoryCard';

export function useSubCategoryCard(subCategoryName: string) {
  return useQuery(
    [QUERIES.subCategoryCard, subCategoryName],
    async () => (await subCategoryCard.getSubCategoryCard(subCategoryName)).data,
    {
      refetchOnWindowFocus: false,
      enabled: !!subCategoryName,
    }
  );
}

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const oneDriveSharedLibraryFolderList = (
  state: RootState
): RootState['oneDriveSharedLibraryFolderList'] => state.oneDriveSharedLibraryFolderList;

export const getSharedLibrariesOpenedFoldersData = createSelector(
  oneDriveSharedLibraryFolderList,
  (oneDriveSharedLibraryFolderList) => oneDriveSharedLibraryFolderList
);

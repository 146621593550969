import { from, of } from 'rxjs';
import { mergeMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  getHistoricActivityInstanceStart,
  getHistoricActivityInstanceSuccess,
  getHistoricActivityInstanceFail,
  GetHistoricActivityInstanceStart,
  GetHistoricActivityInstanceSuccess,
  GetHistoricActivityInstanceFail,
} from './historicActivityInstanceSlice';

export const historicActivityInstanceEpic: Epic<
  GetHistoricActivityInstanceStart,
  GetHistoricActivityInstanceSuccess | GetHistoricActivityInstanceFail,
  RootState,
  typeof API
> = (action$, _, { activityInstance }) =>
  action$.pipe(
    filter(getHistoricActivityInstanceStart.match),
    mergeMap(({ payload }) =>
      from(activityInstance.getHistoricActivityInstance(payload)).pipe(
        map(({ data }) =>
          getHistoricActivityInstanceSuccess({
            data,
            processInstanceId: payload.params.processInstanceId,
          })
        ),
        catchError((error) =>
          of(
            getHistoricActivityInstanceFail({
              error: error?.response?.data,
              processInstanceId: payload.params.processInstanceId,
            })
          )
        )
      )
    )
  );

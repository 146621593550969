import { Card, Col, Divider, Popover, Row, Typography } from 'antd';
import styled from 'styled-components';

import { colors, space, VerticalSpace } from 'ui/elements';
import { MailIcon, Phone, ThreeDotIcon } from '../../../assets';

const { Text } = Typography;

export const StyledAnchor = styled.a`
  color: ${colors.primaryColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;

  .anticon {
    margin-left: 6px;
  }
`;

export const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 24px;
  }
`;
export const UserName = styled.div`
  color: ${colors.primaryColor};
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-right: ${space.slim};
`;
export const StyledDivider = styled(Divider)`
  margin-top: ${space.moderate};
  margin-bottom: ${space.moderate};
`;

export const OrganizationalChartContainer = styled(Card)`
  width: 100%;
  height: 425px;
  flex-grow: 1;
  display: flex;
  margin-bottom: ${space.comfy};

  .ant-card-body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
`;

export const StyledVerticalSpace = styled(VerticalSpace)`
  height: 100%;
  overflow-y: scroll;

  .ant-space-item:last-child {
    flex-grow: 1;
    display: flex;
  }
`;

export const PublicProfileStyledItemWrapper = styled(Row)`
  margin-bottom: ${space.normal};
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const PublicProfileStyledItemTitle = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: ${colors.primaryColor};
`;

export const PublicProfileAttachmentDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.divider};
  margin-bottom: ${space.comfy};
`;

export const StyledPrivateProfileTabContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
`;

export const StyledPublicProfileTitle = styled(Text)`
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.labelGrey};
`;

export const StyledPublicProfileTitleSpace = styled.div`
  height: ${space.moderate};
`;

export const StyledPublicProfileLastSeen = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.lightGrey};
`;

export const StyledRowCenterAlign = styled(Row)`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const StyledLastSeenContainer = styled(Col)`
  padding-top: ${space.little};
  padding-bottom: ${space.little};
`;

export const StyledAnchorNonHr = styled.a`
  color: ${colors.lightOverlayBlue};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  margin-top: ${space.slim};
`;

export const PhoneIcon = styled(Phone)`
  width: 16px;
  height: 16px;
`;
export const Mail = styled(MailIcon)`
  width: 16px;
  height: 16px;
`;

export const ThreeDotMenuIconWrapper = styled.a`
  padding: 0 !important;
`;

export const ThreeDotMenuIcon = styled(ThreeDotIcon)`
  width: 16px;
  height: 20px;
`;

export const StyledPopOverNonHR = styled(Popover)`
  position: absolute;
  right: 24px;
  top: 28px;
`;

export const StyledTextNonHrPopOver = styled.a`
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
`;

export const StyledTextWrapperNonHrPopOver = styled(Col)`
  display: flex;
  flex-direction: column;
`;

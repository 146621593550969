import { from, of } from 'rxjs';
import { filter, map, catchError, exhaustMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetTaskDiagramFail,
  GetTaskDiagramStart,
  GetTaskDiagramSuccess,
  getTaskDiagramFail,
  getTaskDiagramStart,
  getTaskDiagramSuccess,
} from './taskDiagramSlice';

export const TaskDigramEpic: Epic<
  GetTaskDiagramStart,
  GetTaskDiagramSuccess | GetTaskDiagramFail,
  RootState,
  typeof API
> = (action$, _, { taskDiagram }) =>
  action$.pipe(
    filter(getTaskDiagramStart.match),
    exhaustMap(({ payload }) =>
      from(taskDiagram.getTaskDiagram(payload.processDefinitionId, payload.rootPath)).pipe(
        map(({ data }) => getTaskDiagramSuccess(data)),
        catchError((error) => of(getTaskDiagramFail(error?.response?.data)))
      )
    )
  );

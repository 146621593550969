import { useForm } from 'antd/lib/form/Form';
import React, { FC, Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Empty, Form, Spin } from 'antd';
import moment from 'moment';
import {
  useDispatch,
  useSelector,
  generalInfoData,
  getCurrentUserHcmId,
  getGeneralInfoStart,
  profileData as profileDataSelector,
} from 'store';
import { ProfileData, RequestStatus } from 'types';
import { TType } from 'translations';
import { VALIDATE_MESSAGES } from 'utils';
import { FullWidthSpin, HomeLoanName, useErrorNotification } from 'ui';
import { useHomeLoanSubmit } from 'api/StaticForm/hooks';
import { AttachmentFile, attachmentsFieldsId, useHandleSubmitStatus } from 'routes/Tasks';
import { submitAttachmentForm } from 'ui/publicFunction';
import { useHistory } from 'react-router-dom';
import { HomeLoanFields } from './HomeLoanFields';

export interface HomeLoanProps {
  adoid: string;
  t: TType;
  formName?: string;
}

export const HomeLoanFormComponent: FC<HomeLoanProps> = ({ t, adoid, formName }) => {
  const dispatch = useDispatch();
  const [form] = useForm();

  const mutation = useHomeLoanSubmit(adoid);
  const [attachmentData, setAttachmentData] = useState<AttachmentFile[]>([]);
  const history = useHistory();

  const { data } = useSelector(profileDataSelector);
  const profileData = data as ProfileData;

  const personId = useSelector(getCurrentUserHcmId);
  const generalInfo = useSelector(generalInfoData);

  const getGeneralInfo = useCallback(() => {
    if (personId) dispatch(getGeneralInfoStart(personId));
  }, [dispatch, personId]);

  const status = useMemo(() => generalInfo.status, [generalInfo.status]);

  useErrorNotification(
    {
      isNotification: status === RequestStatus.rejected,
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    },
    [generalInfo.error]
  );

  useEffect(() => {
    if (generalInfo.data) {
      form.setFieldsValue({
        [HomeLoanName.department]: profileData.department,
        [HomeLoanName.workingYears]: generalInfo?.data?.yearsWorkedInTheCompany,
        [HomeLoanName.joiningDate]: moment(profileData.hireDate),
      });
    } else {
      getGeneralInfo();
    }
  }, [form, generalInfo, profileData, getGeneralInfo]);

  useHandleSubmitStatus(t, mutation.isLoading, mutation.isError, mutation.isSuccess);

  useEffect(() => {
    mutation.data &&
      submitAttachmentForm(mutation.isSuccess, mutation.data, history, undefined, attachmentData);
  }, [attachmentData, history, mutation.data, mutation.isSuccess]);

  const onSubmitFinish = useCallback(
    async (variables) => {
      const attachmentsForm: AttachmentFile[] = [];
      await mutation.mutate(variables);

      const currentFormFieldsName = Object.keys(variables);

      const currentAttachmentId = currentFormFieldsName.filter((formVariables: string) => {
        return attachmentsFieldsId.includes(formVariables);
      });
      if (currentAttachmentId && variables) {
        currentAttachmentId.forEach((fieldId: string) => {
          const attachmentValue = variables[fieldId];
          if (attachmentValue) {
            return attachmentsForm.push({ [fieldId]: attachmentValue });
          }
        });
      }

      if (attachmentsForm) {
        setAttachmentData(attachmentsForm);
      }
    },
    [mutation]
  );

  return (
    <Fragment>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        validateMessages={VALIDATE_MESSAGES(t)}
        onFinish={onSubmitFinish}
      >
        {generalInfo.error ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('messages.error.wentWrong')} />
        ) : status === RequestStatus.pending ? (
          <FullWidthSpin />
        ) : (
          <Spin spinning={mutation.isLoading} delay={100}>
            <HomeLoanFields t={t} form={form} />
          </Spin>
        )}
      </Form>
    </Fragment>
  );
};

export const HomeLoan = memo(HomeLoanFormComponent);

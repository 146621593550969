import { FormFields } from 'types';

export const nonPoBudgetTeamFormFields = (): FormFields => {
  return [
    {
      businessKey: false,
      id: 'description',
      label: 'Task Description',
      type: {
        name: 'text',
      },
      defaultValue: 'Please review the request details and provide your approval on the request.',
      value: {
        value: 'Please review the request details and provide your approval on the request.',
        type: {
          name: 'string',
        },
      },
      validationConstraints: [],
      properties: {
        viewOnly: 'true',
        requiredField: false,
      },
      typeName: 'text',
    },
    {
      businessKey: false,
      id: 'budgetYear',
      label: 'Budget Year',
      placeholder: 'Enter the budget year',
      type: {
        name: 'string',
      },
      defaultValue: null,
      value: {
        value: null,
        type: {
          name: 'string',
        },
      },
      validationConstraints: [
        { name: 'required', configuration: null },
        { name: 'pattern', configuration: /^(20)\d{2}$/ },
      ],
      properties: {
        requiredField: true,
      },
      typeName: 'string',
    },
    {
      businessKey: false,
      id: 'glAccount',
      label: 'GL Account',
      placeholder: 'Choose GL account',
      type: {
        name: 'enum',
      },
      defaultValue: null,
      value: {
        value: null,
        type: {
          name: 'enum',
        },
      },
      validationConstraints: [{ name: 'required', configuration: null }],
      properties: {
        requiredField: true,
      },
      typeName: 'enum',
    },
    {
      businessKey: false,
      id: 'costCenter',
      label: 'Cost Center',
      placeholder: 'Choose cost center',
      type: {
        name: 'enum',
      },
      defaultValue: null,
      value: {
        value: null,
        type: {
          name: 'enum',
        },
      },
      validationConstraints: [{ name: 'required', configuration: null }],
      properties: {
        requiredField: true,
      },
      typeName: 'enum',
    },
    {
      businessKey: false,
      id: 'project',
      label: 'Project',
      placeholder: 'Choose project',
      type: {
        name: 'enum',
      },
      defaultValue: null,
      value: {
        value: null,
        type: {
          name: 'enum',
        },
      },
      validationConstraints: [{ name: 'required', configuration: null }],
      properties: {
        requiredField: true,
      },
      typeName: 'enum',
    },
    {
      businessKey: false,
      id: 'feedBack',
      label: 'Feedback',
      type: {
        name: 'textArea',
      },
      defaultValue: null,
      value: {
        value: null,
        type: {
          name: 'string',
        },
      },
      validationConstraints: [],
      properties: {
        maxlength: '1024',
        requiredField: false,
      },
      typeName: 'textArea',
    },
    {
      businessKey: false,
      id: 'attachment',
      label: 'Attachments',
      type: {
        name: 'file',
      },
      defaultValue: null,
      value: {
        value: null,
        type: {
          name: 'string',
        },
      },
      validationConstraints: [],
      properties: {
        maxAttachementsCount: '3',
        boldLabel: 'true',
        requiredField: false,
      },
      typeName: 'file',
    },
    {
      businessKey: false,
      id: 'approved',
      label: 'Approve',
      type: {
        name: 'button',
      },
      defaultValue: null,
      value: {
        value: false,
        type: {
          name: 'boolean',
        },
      },
      validationConstraints: [],
      properties: {
        requiredField: false,
        primeColor: 'true',
      },
      typeName: 'button',
    },
    {
      businessKey: false,
      id: 'rejected',
      label: 'Reject',
      type: {
        name: 'button',
      },
      defaultValue: null,
      value: {
        value: false,
        type: {
          name: 'boolean',
        },
      },
      validationConstraints: [],
      properties: {
        requiredField: false,
        primeColor: 'false',
        actionButtonHtmlType: 'button',
      },
      typeName: 'button',
    },
  ];
};

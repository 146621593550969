import { GeneralInfoData } from 'types';
import { Core, GENERAL_INFO } from 'api';

interface GetGeneralInfo {
  (hcmPersonId: string): Promise<{ data: GeneralInfoData }>;
}

class GeneralInfo extends Core {
  getGeneralInfo: GetGeneralInfo = async (hcmPersonId) => this.get(GENERAL_INFO(hcmPersonId));
}

export const generalInfo = new GeneralInfo();

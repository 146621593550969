import styled from 'styled-components';
import { Typography } from 'antd';
import { colors } from 'ui';

const { Text } = Typography;

export const StyledBodyText = styled(Text)`
  color: ${colors.primaryColor};
  font-size: 16px;
  font-family: inherit;
  line-height: 22px;
`;

export const StyledBodyTextLink = styled.a`
  color: ${colors.skyLink};
  font-size: 16px;
  font-family: inherit;
  line-height: 22px;
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${colors.skyLink};
    text-decoration: underline;
  }
`;

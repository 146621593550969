import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PayslipsPdf, RequestCommonError, RequestStatus } from 'types';
import { PaySlipsPdfFileState } from './paySlipsPdfFileType';

export const emptyPaySlipsPdfFileState: PaySlipsPdfFileState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetPaySlipsPdfFileStart = PayloadAction<{
  userId: string;
  month: string;
  year: string;
}>;
export type GetPaySlipsPdfFileSuccess = PayloadAction<PayslipsPdf>;
export type GetPaySlipsPdfFileFail = PayloadAction<RequestCommonError>;
export type GetPaySlipsPdfFileRest = PayloadAction;

const paySlipsPdfFile = createSlice({
  name: 'paySlipsPdfFile',
  initialState: emptyPaySlipsPdfFileState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getPaySlipsPdfFileStart(state, _: GetPaySlipsPdfFileStart) {
      state.status = RequestStatus.pending;
    },
    getPaySlipsPdfFileSuccess(state, action: GetPaySlipsPdfFileSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getPaySlipsPdfFileFail(state, action: GetPaySlipsPdfFileFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getPaySlipsPdfFileRest(state, _: GetPaySlipsPdfFileRest) {
      state.status = RequestStatus.idle;
      state.error = null;
      state.data = undefined;
    },
  },
});

export const {
  getPaySlipsPdfFileStart,
  getPaySlipsPdfFileSuccess,
  getPaySlipsPdfFileFail,
  getPaySlipsPdfFileRest,
} = paySlipsPdfFile.actions;
export default paySlipsPdfFile.reducer;

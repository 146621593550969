import React from 'react';
import { QuestionOutlined } from '@ant-design/icons';

import { WorkingYears, LastIncrease, HireDate } from 'assets';

interface IconSelectorProps {
  type: string;
  style?: React.CSSProperties;
}

const ProfileIconSelector: React.FC<IconSelectorProps> = ({ type, ...rest }) => {
  const Icons = {
    hireDateOutLined: <HireDate {...rest} />,
    yearsSinceLastPromotionOutLined: <LastIncrease {...rest} />,
    yearsWorkedInTheCompanyOutLined: <WorkingYears {...rest} />,
    lastPromotionDateOutLined: <LastIncrease {...rest} />,
    employeeIdOutLined: <WorkingYears {...rest} />,
  };

  const getIcon = (type: string) => {
    // Default Icon when not found
    let comp = <QuestionOutlined />;

    let typeNew = type;

    // Default is Outlined when no theme was appended (ex: 'smile')
    if (!typeNew.match(/.+(Outlined|Filled|TwoTone)$/i)) {
      typeNew += 'Outlined';
    }

    // If found by key then return value which is component
    const found = Object.entries(Icons).find(([k]) => k.toLowerCase() === typeNew.toLowerCase());
    if (found) {
      [, comp] = found;
    }

    return comp;
  };

  return getIcon(type);
};

export default ProfileIconSelector;

import React, { useMemo } from 'react';
import { useTranslate } from 'translations';
import {
  StyledDurationWFHSpin,
  StyledWFHRemainingBalanceCard,
  StyledWFHRemainingBalanceCount,
  StyledWFHRemainingBalanceLabel,
} from './WFHFormFields.styles';

export interface WFHRemainingBalanceCardProps {
  durationWFHDays: number;
  remainingWFHDays: number;
  wfhDaysBalance: number;
  isLoadingCalculateDuration: boolean;
}
export const WFHRemainingBalanceCard: React.FC<WFHRemainingBalanceCardProps> = ({
  durationWFHDays,
  remainingWFHDays,
  wfhDaysBalance,
  isLoadingCalculateDuration,
}: WFHRemainingBalanceCardProps) => {
  const { t } = useTranslate();
  const mDurationWFHDays = useMemo(() => durationWFHDays, [durationWFHDays]);
  const mRemainingWFHDays = useMemo(() => {
    return remainingWFHDays < 0 ? 0 : remainingWFHDays;
  }, [remainingWFHDays]);
  return (
    <StyledWFHRemainingBalanceCard>
      <StyledWFHRemainingBalanceLabel>
        {t('wfh.request.durationOfWFH.label')}
        {isLoadingCalculateDuration ? (
          <StyledDurationWFHSpin size={'small'} />
        ) : (
          <StyledWFHRemainingBalanceCount>{mDurationWFHDays}</StyledWFHRemainingBalanceCount>
        )}
        {t('wfh.request.days.label')}
      </StyledWFHRemainingBalanceLabel>
      <StyledWFHRemainingBalanceLabel isError={remainingWFHDays < 0 && !isLoadingCalculateDuration}>
        {t('wfh.request.remainingBalance.label')}
        {isLoadingCalculateDuration ? (
          <StyledDurationWFHSpin size={'small'} />
        ) : (
          <StyledWFHRemainingBalanceCount
            isError={remainingWFHDays < 0 && !isLoadingCalculateDuration}
          >
            {mRemainingWFHDays}
          </StyledWFHRemainingBalanceCount>
        )}
        {t('wfh.request.days.label')}
      </StyledWFHRemainingBalanceLabel>
    </StyledWFHRemainingBalanceCard>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, UserProfileStatus } from 'types';
import { OtherUserStatusState } from './otherUserStatusTypes';

export const emptyOtherUserStatusInitialState: OtherUserStatusState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetOtherUserStatusStart = PayloadAction<{ adoId: string }>;
export type GetOtherUserStatusSuccess = PayloadAction<UserProfileStatus>;
export type GetOtherUserStatusFail = PayloadAction<RequestCommonError>;
export type GetOtherUserStatusReset = PayloadAction;

const otherUserStatus = createSlice({
  name: 'otherUserStatus',
  initialState: emptyOtherUserStatusInitialState,
  reducers: {
    getOtherUserStatusStart(state, _: GetOtherUserStatusStart) {
      state.status = RequestStatus.pending;
    },
    getOtherUserStatusSuccess(state, action: GetOtherUserStatusSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getOtherUserStatusFail(state, action: GetOtherUserStatusFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getOtherUserStatusReset(state, _: GetOtherUserStatusReset) {
      state.status = RequestStatus.idle;
      state.error = null;
      state.data = undefined;
    },
  },
});

export const {
  getOtherUserStatusStart,
  getOtherUserStatusSuccess,
  getOtherUserStatusFail,
  getOtherUserStatusReset,
} = otherUserStatus.actions;
export default otherUserStatus.reducer;

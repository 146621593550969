import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../reducers';

export const teams = (state: RootState): RootState['teams'] => state.teams;

export const getTeamsData = createSelector(teams, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

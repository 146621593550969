import React, { FC, useEffect, useState } from 'react';
import { Skeleton } from 'antd';

import { auth, core } from 'api';
import { StyledImage } from './AsyncImage.styled';

export const AsyncImage: FC<{ src: string }> = ({ src }) => {
  const [base64Img, setBase64Img] = useState<string | null>(null);

  useEffect(() => {
    auth.getAccessToken().then(({ accessToken }) => {
      if (!accessToken) {
        return;
      }

      core
        .get(src, {
          responseType: 'arraybuffer',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          setBase64Img(Buffer.from(response.data, 'binary').toString('base64'));
        });
    });
  }, [src]);

  return base64Img ? (
    <StyledImage src={`data:image/png;base64,${base64Img}`} />
  ) : (
    <Skeleton.Image />
  );
};

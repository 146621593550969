import { useQuery } from 'react-query';
import { processManagement } from '../ProcessManagement';
import { QUERIES } from '../../constants';

export function useProcessGroups() {
  return useQuery(
    QUERIES.processGroups,
    async () => {
      const { data } = await processManagement.getProcessGroups();
      return data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );
}

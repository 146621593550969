import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, notification, Row, Space } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { taskComments as taskCommentsApi, useCreatNewComment, useJiraCreateComment } from 'api';
import { useTranslate } from 'translations';
import {
  StyledParagraph,
  StyledSecondaryParagraph,
} from 'ui/components/Chat/ChatSendMessage/ChatSendMessage.styled';
import { Mention, MentionItem } from 'react-mentions';
import { chatSendMessageStyles } from 'ui/components/Chat/ChatSendMessage/chatSendMessageStyles';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import {
  getGroupMembersByLastNameStart,
  getGroupMembersStart,
  GroupMembersByLastNameData,
  GroupMembersData,
  profileData,
  updateTaskAttachmentStatus,
  useSelector,
} from 'store';
import { colors, FullWidthAndHeightSpin } from 'ui/elements';
import { RequestStatus } from 'types';
import { useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { RestrictedUpload } from 'ui/components/RestrictedUpload';
import { useErrorNotification, useRowGutter } from 'ui/hooks';
import { UploadAttachment } from 'ui/components';
import { useTasksContext } from 'routes';
import { Resizer } from 'assets';
import { MAX_COMMENT_LENGTH } from '../RestrictedUpload';
import {
  StyledAddAttachment,
  StyledAddAttachmentFooter,
  StyledAddCommentButton,
  StyledAttachmentListContainer,
  StyledCancelComment,
  StyledCommentFailedCancelButton,
  StyledCommentFailedCancelButtonText,
  StyledCommentFailedComment,
  StyledCommentFailedCommentButtonWrapper,
  StyledCommentFailedMessage,
  StyledCommentFailedRetryButton,
  StyledCommentFailedRetryButtonText,
  StyledErrortext,
  StyledFailedCommentAttachment,
  StyledMentionsInput,
  StyledMentionsInputContainer,
  StyledRequestNameForFailedComment,
  StyledResizerIconContainer,
  StyledRow,
} from './TaskCommentsDrawer.styled';

export interface CommentsInputComponentProps {
  taskId: string;
  groupId?: string;
  onFinish?: () => void;
  onCloseComment?: () => void;
  requestName?: string;
  isJira?: boolean;
}

export const CommentsInputComponent: FC<CommentsInputComponentProps> = ({
  taskId,
  onFinish,
  onCloseComment,
  requestName,
  isJira,
}) => {
  const { t } = useTranslate();
  const { data } = useSelector(profileData);
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [fieldValue, setFieldValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mentionedList, setMentionedList] = useState<MentionItem[]>([]);
  const groupsMembersByFirstName = useSelector(GroupMembersData);
  const groupsMembersByLastName = useSelector(GroupMembersByLastNameData);
  const { rootPath } = useTasksContext();
  const createCommentMutation = useCreatNewComment(taskId, rootPath);
  const createJiraCommentMutation = useJiraCreateComment(taskId);
  const [commentsFileList, setCommentsFileList] = useState<UploadFile[]>([]);
  const [maxFiles, setMaxFiles] = useState<number>(0);
  const gutter16 = useRowGutter(16);

  const commentFailedMessage = () => {
    return (
      <Col>
        <StyledCommentFailedMessage>
          {t('messages.error.commentNotPosted')}
        </StyledCommentFailedMessage>
      </Col>
    );
  };

  const handelOnCommentFailedCancel = () => {
    notification.destroy();
  };

  const handelOnCommentFailedRetry = () => {
    notification.destroy();
    handleOnSubmitComment();
  };

  const commentFailedDescription = () => {
    return (
      <Col>
        {requestName && (
          <StyledRequestNameForFailedComment>{requestName}</StyledRequestNameForFailedComment>
        )}
        <br />
        <StyledCommentFailedComment>{`"${fieldValue}"\n`}</StyledCommentFailedComment>
        <br />
        {commentsFileList.length > 0 && (
          <StyledFailedCommentAttachment>
            {`${commentsFileList.length} attachment${commentsFileList.length > 1 ? `s` : ``}`}
          </StyledFailedCommentAttachment>
        )}
      </Col>
    );
  };

  const commentFailedButtons = () => {
    return (
      <StyledCommentFailedCommentButtonWrapper>
        <StyledCommentFailedRetryButton onClick={handelOnCommentFailedRetry}>
          <StyledCommentFailedRetryButtonText>{t('task.retry')}</StyledCommentFailedRetryButtonText>
        </StyledCommentFailedRetryButton>
        <StyledCommentFailedCancelButton onClick={handelOnCommentFailedCancel}>
          <StyledCommentFailedCancelButtonText>
            {t('task.cancel')}
          </StyledCommentFailedCancelButtonText>
        </StyledCommentFailedCancelButton>
      </StyledCommentFailedCommentButtonWrapper>
    );
  };

  const onFileUpload = useCallback(
    async (commentId) => {
      dispatch(updateTaskAttachmentStatus(RequestStatus.pending));
      setIsLoading(true);

      const formData = new FormData();
      commentsFileList.forEach((file) => {
        formData.append('file', (file as unknown) as string);
      });

      try {
        await taskCommentsApi.addTaskCommentAttachment(commentId, formData, rootPath);
        setCommentsFileList([]);
        setFieldValue('');
        inputRef.current?.focus();
        dispatch(updateTaskAttachmentStatus(RequestStatus.resolved));
      } catch (e) {
        notification.error({
          message: commentFailedMessage(),
          description: commentFailedDescription(),
          duration: 0,
          btn: commentFailedButtons(),
          closeIcon: <Col />,
        });
        dispatch(updateTaskAttachmentStatus(RequestStatus.rejected));
      } finally {
        setIsLoading(false);
        onFinish && onFinish();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, commentsFileList, rootPath, t, onFinish]
  );

  const onFileRemove = useCallback((file: UploadFile) => {
    setCommentsFileList((state) => {
      const index = state.indexOf(file);
      const newFileList = state.slice();
      newFileList.splice(index, 1);
      return newFileList;
    });
  }, []);

  useEffect(() => {
    if (createCommentMutation.isSuccess) {
      if (!commentsFileList.length) {
        setFieldValue('');
        inputRef.current?.focus();
        onFinish && onFinish();
      } else {
        const commentId = createCommentMutation.data.id;
        commentId && commentsFileList.length && onFileUpload(commentId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCommentMutation.isSuccess, createCommentMutation.data]);

  useEffect(() => {
    if (createJiraCommentMutation.isSuccess) {
      setFieldValue('');
      inputRef.current?.focus();
      onFinish && onFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createJiraCommentMutation.isSuccess, createJiraCommentMutation.data]);

  useEffect(() => {
    if (createCommentMutation.isSuccess) {
      setFieldValue('');
      inputRef.current?.focus();
    }
  }, [createCommentMutation.isSuccess]);

  useEffect(() => {
    if (
      groupsMembersByFirstName.status === RequestStatus.resolved ||
      groupsMembersByLastName.status === RequestStatus.resolved
    ) {
      if (inputRef.current) {
        inputRef.current.selectionStart = inputRef.current?.value?.length;
        inputRef.current?.focus();
      }
    }
  }, [groupsMembersByFirstName.status, groupsMembersByLastName.status]);

  useErrorNotification(
    {
      isNotification: createCommentMutation.isError,
      message: commentFailedMessage(),
      description: commentFailedDescription(),
      btn: commentFailedButtons(),
      duration: 0,
      closeIcon: <Col />,
    },
    [createCommentMutation.isError]
  );

  useErrorNotification(
    {
      isNotification: createJiraCommentMutation.isError,
      message: commentFailedMessage(),
      description: commentFailedDescription(),
      btn: commentFailedButtons(),
      duration: 0,
      closeIcon: <Col />,
    },
    [createJiraCommentMutation.isError]
  );

  const onChange = (
    e: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]
  ) => {
    if (e.target.value.length > MAX_COMMENT_LENGTH) return;

    /**
     * Clear the error notification
     * if any changes made on Attachments
     */
    notification.destroy();

    setFieldValue(e.target.value);
    setMentionedList(mentions);
  };

  const submitMessage = useCallback(() => {
    const mentionedUser = mentionedList.map((item) => {
      return item.id;
    });
    createCommentMutation.mutate({
      message: fieldValue,
      mentionedUserIds: mentionedUser,
    });
  }, [createCommentMutation, fieldValue, mentionedList]);

  const submitJiraMessage = useCallback(() => {
    const mentionedUser = mentionedList.map((item) => {
      return item.id;
    });

    const formData = new FormData();
    formData.append('message', fieldValue);
    formData.append('mentionedUserIds', mentionedUser.join(','));
    if (commentsFileList.length > 0) {
      commentsFileList.forEach((file) => {
        formData.append('attachment', (file as unknown) as string);
      });
    }
    createJiraCommentMutation.mutate(formData);
  }, [createJiraCommentMutation, fieldValue, mentionedList, commentsFileList]);

  const handleOnSubmitComment = useCallback(() => {
    if (isJira) {
      submitJiraMessage();
    } else {
      submitMessage();
    }
  }, [isJira, submitJiraMessage, submitMessage]);

  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  const usersList = useMemo(
    () => [...(groupsMembersByFirstName.data || []), ...(groupsMembersByLastName.data || [])],
    [groupsMembersByFirstName.data, groupsMembersByLastName.data]
  );

  const searchUsers = useCallback(
    (searchText: string) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      const capitalStr = searchText.replace(/^\w/, (c) => c.toUpperCase());
      if (capitalStr && capitalStr.length > 1) {
        timeoutId.current = setTimeout(() => {
          dispatch(
            getGroupMembersStart({
              params: {
                firstNameLike: `%${capitalStr}%`,
              },
            })
          );
          dispatch(
            getGroupMembersByLastNameStart({
              params: {
                lastNameLike: `%${capitalStr}%`,
              },
            })
          );
        }, 750);
      }

      return usersList
        ?.filter((user) => user.id !== data?.adOid)
        ?.filter((item, index, arr) => arr.findIndex(({ id }) => item.id === id) === index)
        .map((user) => ({
          id: user.id,
          display: `${user.firstName} ${user.lastName}`,
          email: user.email,
        }));
    },
    [data?.adOid, dispatch, usersList]
  );

  const getMaxCount = (max: number) => {
    setMaxFiles(max);
  };

  useMemo(() => {
    /**
     * Clear the error notification
     * if any changes made on Attachments
     */
    notification.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsFileList]);

  return (
    <>
      {commentsFileList.length > maxFiles && (
        <Row gutter={gutter16}>
          <Col>
            <StyledErrortext>
              {t('task.comments.exceedLimit', {
                max: maxFiles,
              })}
            </StyledErrortext>
          </Col>
        </Row>
      )}
      <StyledRow justify="center">
        <Col lg={24} flex="auto">
          {
            <StyledMentionsInputContainer>
              <StyledMentionsInput
                inputRef={inputRef}
                key={usersList.length}
                allowSpaceInQuery
                autoFocus={true}
                value={fieldValue}
                onChange={onChange}
                allowSuggestionsAboveCursor={true}
                spellCheck={false}
                placeholder={t('task.commentsPlaceholder')}
                maxLength={MAX_COMMENT_LENGTH}
              >
                <Mention
                  markup={`<b id="__id__">@__display__</b>`}
                  style={chatSendMessageStyles}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  renderSuggestion={(suggestion: any, search, highlightedDisplay) => {
                    return groupsMembersByFirstName?.status === RequestStatus.pending ||
                      groupsMembersByLastName?.status === RequestStatus.pending ? (
                      <FullWidthAndHeightSpin />
                    ) : (
                      <Space size={10}>
                        <MSTeamsUserAvatar name={suggestion.display} id={suggestion.id as string} />

                        <div>
                          <StyledParagraph>{highlightedDisplay}</StyledParagraph>
                          <StyledSecondaryParagraph>{suggestion?.email}</StyledSecondaryParagraph>
                        </div>
                      </Space>
                    );
                  }}
                  appendSpaceOnAdd
                  trigger="@"
                  data={searchUsers}
                />
              </StyledMentionsInput>
              <StyledAttachmentListContainer>
                <Col>
                  {commentsFileList.map((file) => (
                    <Row key={file.uid}>
                      <Col>
                        <UploadAttachment attachment={file} onRemove={onFileRemove} />
                      </Col>
                    </Row>
                  ))}
                </Col>
              </StyledAttachmentListContainer>
              <StyledResizerIconContainer>
                <Resizer />
              </StyledResizerIconContainer>
            </StyledMentionsInputContainer>
          }
        </Col>
      </StyledRow>
      <Row justify="end">
        <Col>
          <span style={{ color: colors.lightOverlayBlue, fontSize: '12px' }}>
            {fieldValue.length} / {MAX_COMMENT_LENGTH}
          </span>
        </Col>
      </Row>
      <StyledAddAttachmentFooter>
        <Col>
          <StyledAddCommentButton
            disabled={
              isLoading ||
              !fieldValue ||
              commentsFileList.length > maxFiles ||
              createCommentMutation.isLoading ||
              createJiraCommentMutation.isLoading
            }
            loading={
              isLoading || createCommentMutation.isLoading || createJiraCommentMutation.isLoading
            }
            onClick={handleOnSubmitComment}
            type="primary"
          >
            {t('task.postComment')}
          </StyledAddCommentButton>
        </Col>
        <Col>
          <RestrictedUpload
            setCommentsFileList={setCommentsFileList}
            getMaxCount={getMaxCount}
            showUploadList={false}
            multiple={true}
          >
            <StyledAddAttachment disabled={isLoading} type="text" loading={isLoading}>
              <PlusOutlined />
              {t('task.addAttachment')}
            </StyledAddAttachment>
          </RestrictedUpload>
        </Col>
        <Col flex={1} />
        <Col>
          <StyledCancelComment onClick={onCloseComment}>
            {t('task.cancelComment')}
          </StyledCancelComment>
        </Col>
      </StyledAddAttachmentFooter>
    </>
  );
};

export const CommentsInput = memo(CommentsInputComponent);

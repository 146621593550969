export const chatMessages = {
  skipToken:
    'M2U1YjAwMDAwMDMxMzkzYTMxNjM2NTY0NjQzNDMwMzEyZDYxNjQ2MzM0MmQzNDMzNjIzOTJkNjIzNjYzMzAyZDYzNjE2MzYyMzMzMjM3NjYzMDY2MzI2NDVmNjUzMjM1MzIzMzM5MzAzMjJkNjQzMjYzNjEyZDM0NjI2NDMwMmQzODY1NjYzODJkNjMzMDYxMzUzMzYyMzkzNzY0MzU2NDMwNDA3NTZlNzEyZTY3NjI2YzJlNzM3MDYxNjM2NTczMDE1YTVlOTI2Mzc4MDEwMDAwYjc5NTBiODQ3ODAxMDAwMHwxNjE3MTIzMDU1MDMx',
  list: [
    {
      date: '2021-03-23',
      messages: [
        {
          id: '12345567',
          replyToId: '1234545667',
          messageType: 'message',
          createdDateTime: '2021-03-24T13:48:54.110041Z',
          locale: 'en-en',
          from: {
            user: {
              id: 'e5e7cbe9-e3e5-475e-a968-c9fd2fa4658f',
              displayName: 'John Doe',
            },
            application: null,
          },
          body: {
            contentType: 'text/plain',
            content: 'sup dudes',
          },
          attachments: null,
          mentions: null,
          isMine: false,
        },
        {
          id: '12345567',
          replyToId: '1234545667',
          messageType: 'message',
          createdDateTime: '2021-03-24T13:48:54.110041Z',
          locale: 'en-en',
          from: {
            user: {
              id: 'e5e7cbe9-e3e5-475e-a968-c9fd2fa4658f',
              displayName: 'John Doe',
            },
            application: null,
          },
          body: {
            contentType: 'text/plain',
            content: 'sup dudes',
          },
          attachments: null,
          mentions: null,
          isMine: false,
        },
        {
          id: '12345567',
          replyToId: '1234545667',
          messageType: 'message',
          createdDateTime: '2021-03-24T13:48:54.110041Z',
          locale: 'en-en',
          from: {
            user: {
              id: 'e5e7cbe9-e3e5-475e-a968-c9fd2fa4658f',
              displayName: 'John Doe',
            },
            application: null,
          },
          body: {
            contentType: 'text/plain',
            content:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae culpa quod adipisci et voluptate necessitatibus eum libero. Amet quibusdam vero deleniti ut. Expedita minus adipisci fugiat mollitia laborum voluptate maiores.Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae culpa quod adipisci et voluptate necessitatibus eum libero. Amet quibusdam vero deleniti ut. Expedita minus adipisci fugiat mollitia laborum voluptate maiores.Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae culpa quod adipisci et voluptate necessitatibus eum libero. Amet quibusdam vero deleniti ut. Expedita minus adipisci fugiat mollitia laborum voluptate maiores.Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae culpa quod adipisci et voluptate necessitatibus eum libero. Amet quibusdam vero deleniti ut. Expedita minus adipisci fugiat mollitia laborum voluptate maiores.',
          },
          attachments: null,
          mentions: null,
          isMine: false,
        },
        {
          id: '12345567',
          replyToId: '1234545667',
          messageType: 'message',
          createdDateTime: '2021-03-24T13:48:54.110041Z',
          locale: 'en-en',
          from: {
            user: {
              id: 'e5e7cbe9-e3e5-475e-a968-c9fd2fa4658f',
              displayName: 'John Doe',
            },
            application: null,
          },
          body: {
            contentType: 'text/plain',
            content:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae culpa quod adipisci et voluptate necessitatibus eum libero. Amet quibusdam vero deleniti ut. Expedita minus adipisci fugiat mollitia laborum voluptate maiores.Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae culpa quod adipisci et voluptate necessitatibus eum libero. Amet quibusdam vero deleniti ut. Expedita minus adipisci fugiat mollitia laborum voluptate maiores.Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae culpa quod adipisci et voluptate necessitatibus eum libero. Amet quibusdam vero deleniti ut. Expedita minus adipisci fugiat mollitia laborum voluptate maiores.Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae culpa quod adipisci et voluptate necessitatibus eum libero. Amet quibusdam vero deleniti ut. Expedita minus adipisci fugiat mollitia laborum voluptate maiores.',
          },
          attachments: null,
          mentions: null,
          isMine: true,
        },
        {
          id: '12345567',
          replyToId: '1234545667',
          messageType: 'message',
          createdDateTime: '2021-03-24T13:48:54.110059Z',
          locale: 'en-en',
          from: {
            user: {
              id: 'e5e7cbe9-e3e5-475e-a968-c9fd2fa4658f',
              displayName: 'John Doe',
            },
            application: null,
          },
          body: {
            contentType: 'text/plain',
            content: 'sup dudes',
          },
          attachments: null,
          mentions: null,
          isMine: true,
        },
      ],
    },
    {
      date: '2021-03-24',
      messages: [
        {
          id: '12345567',
          replyToId: '1234545667',
          messageType: 'message',
          createdDateTime: '2021-03-24T13:48:54.110062Z',
          locale: 'en-en',
          from: {
            user: {
              id: 'e5e7cbe9-e3e5-475e-a968-c9fd2fa4658f',
              displayName: 'John Doe',
            },
            application: null,
          },
          body: {
            contentType: 'text/plain',
            content: 'sup dudes',
          },
          attachments: null,
          mentions: null,
          isMine: true,
        },
        {
          id: '12345567',
          replyToId: '1234545667',
          messageType: 'message',
          createdDateTime: '2021-03-24T13:48:54.110064Z',
          locale: 'en-en',
          from: {
            user: {
              id: 'e5e7cbe9-e3e5-475e-a968-c9fd2fa4658f',
              displayName: 'John Doe',
            },
            application: null,
          },
          body: {
            contentType: 'text/plain',
            content: 'sup dudes',
          },
          attachments: null,
          mentions: null,
          isMine: false,
        },
        {
          id: '12345567',
          replyToId: '1234545667',
          messageType: 'message',
          createdDateTime: '2021-03-24T13:48:54.110066Z',
          locale: 'en-en',
          from: {
            user: {
              id: 'e5e7cbe9-e3e5-475e-a968-c9fd2fa4658f',
              displayName: 'John Doe',
            },
            application: null,
          },
          body: {
            contentType: 'text/plain',
            content: 'sup dudes',
          },
          attachments: null,
          mentions: null,
          isMine: false,
        },
      ],
    },
    {
      date: Date().toString(),
      messages: [
        {
          id: '12345567',
          replyToId: '1234545667',
          messageType: 'message',
          createdDateTime: '2021-03-24T13:48:54.110059Z',
          locale: 'en-en',
          from: {
            user: {
              id: 'e5e7cbe9-e3e5-475e-a968-c9fd2fa4658f',
              displayName: 'John Doe',
            },
            application: null,
          },
          body: {
            contentType: 'text/plain',
            content: 'sup dudes',
          },
          attachments: null,
          mentions: null,
          isMine: true,
        },
      ],
    },
  ],
};

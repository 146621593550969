import { createContext, useContext } from 'react';
import { FilterContextType } from './Filter.types';

export const FilterContext = createContext<FilterContextType>({} as FilterContextType);
export const useFilterContext = (): FilterContextType => {
  const context = useContext<FilterContextType>(FilterContext);

  if (!context) {
    throw new Error('useFilterContext should be used within a FilterContext');
  }

  return context;
};

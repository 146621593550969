import React, { FC, memo } from 'react';
import { Col, Row } from 'antd';
import { AllRequestCard } from 'types';
import { CategoryTitle, CategoryWrapper } from './RequestsCatalogCards.styled';
import { RequestsCatalogCategoryCard } from './RequestsCatalogCategoryCard';

interface Props {
  cards: AllRequestCard[];
  isSubTitle: boolean;
  onCardClick: (
    process:
      | { processDefinitionKey: string; name: string; noAllowToCreateRequest?: boolean }
      | undefined
  ) => void;
  isPath: boolean;
  userDepartment?: string | null;
}

export const RequestsCatalogCategoryComponent: FC<Props> = ({
  cards,
  isSubTitle,
  onCardClick,
  isPath,
  userDepartment,
}) => (
  <>
    {cards?.map((allCards, index) => (
      <CategoryWrapper key={index}>
        {isSubTitle ? <CategoryTitle>{allCards.name}</CategoryTitle> : null}

        <Col>
          <Row>
            {allCards.names.map((card, index) => (
              <RequestsCatalogCategoryCard
                card={card}
                key={card.name}
                onCardClick={onCardClick}
                isPath={isPath}
                userDepartment={userDepartment}
              />
            ))}
          </Row>
        </Col>
      </CategoryWrapper>
    ))}
  </>
);

export const RequestsCatalogCategory = memo(RequestsCatalogCategoryComponent);

import React, { FC } from 'react';
import { TType } from 'translations';
import { FormField } from 'types';
import { useGetFromFieldId } from 'ui/hooks';
import { FieldSelect } from '../FieldSelect';
import { FieldGroupRadioButton } from '../FieldGroupRadioButton';

interface Props {
  field: FormField;
  t: TType;
  isMulti?: boolean;
  withLabel?: boolean;
}

export const FieldEnumType: FC<Props> = ({ field, t, isMulti, withLabel }) => {
  const getFieldId = useGetFromFieldId();
  const fieldId = getFieldId(field.id);

  return field.properties.enumType === 'radioButton' ? (
    <FieldGroupRadioButton withLabel={withLabel} key={fieldId} field={field} t={t} />
  ) : (
    <FieldSelect withLabel={withLabel} key={fieldId} field={field} t={t} isMulti={isMulti} />
  );
};

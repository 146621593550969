import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const teamsChannelFolder = (state: RootState): RootState['teamsFolder'] => state.teamsFolder;

export const currentTeamIdAndFolderId = (
  _: RootState,
  props: {
    teamId: string;
  }
) => ({
  teamId: props.teamId,
});

export const getCurrentTeamsFolderData = createSelector(
  [teamsChannelFolder, currentTeamIdAndFolderId],
  (teams, { teamId }) => (teams[teamId] && teams[teamId] ? teams[teamId] : undefined)
);

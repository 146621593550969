import React, { useMemo } from 'react';
import { IProfileAttachment } from 'types';
import { PrivateProfileAttachment } from '../PrivateProfileAttachment';
import { StyledAttachmentsWrapper } from './PrivateProfileAttachments.styles';

export interface PrivateProfileAttachmentsProps {
  attachments?: IProfileAttachment[];
  withAcknowledge?: boolean;
}

export const PrivateProfileAttachments: React.FC<PrivateProfileAttachmentsProps> = ({
  attachments,
  withAcknowledge,
}: PrivateProfileAttachmentsProps) => {
  const data: IProfileAttachment[] = useMemo(() => {
    return attachments ?? [];
  }, [attachments]);

  return (
    <StyledAttachmentsWrapper gutter={[20, 15]} wrap={true}>
      {data?.map((item, index) => (
        <PrivateProfileAttachment key={index} attachment={item} withAcknowledge={withAcknowledge} />
      ))}
    </StyledAttachmentsWrapper>
  );
};

import React, { FC } from 'react';

import { Badge, Card, Col, Row, Spin } from 'antd';
import { MailOutlined, MessageOutlined, PhoneOutlined } from '@ant-design/icons';

import { InfoItemColV2, OrganizationalChartComponent, StatusTag } from 'ui';
import { MessagesTypes, TType } from 'translations';
import { OtherUserProfileDate, USER_STATUS_MAP } from 'types';
import { MSTeamsConfirm } from 'routes/MSTeams/MSTeamsConfirm';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { useGetUserStatus } from 'api';
import {
  OrganizationalChartContainer,
  StyledAnchor,
  StyledCard,
  StyledDivider,
  StyledPublicProfileTitle,
  StyledPublicProfileTitleSpace,
  StyledRowCenterAlign,
  StyledVerticalSpace,
  UserName,
} from './PublicProfile.styled';
import { PublicProfileAttachmentSection } from './components';

export const FIELDS_TO_RENDER: (keyof OtherUserProfileDate)[] = [
  'positionName',
  'department',
  'location',
  'managerName',
];

const IMAGE_SIZE = 56;

interface Props {
  data: OtherUserProfileDate;
  t: TType;
  email: string;
}

export const PublicProfileContent: FC<Props> = ({ t, data, email }) => {
  const statusInfo = useGetUserStatus(email ?? '');

  const onConfirm = () => {
    window.open('msteams:');
  };
  return (
    <>
      <StyledVerticalSpace>
        <>
          <StyledCard>
            <Row justify="space-between">
              <Col>
                <Row align={'middle'} gutter={[21, 24]}>
                  <Col>
                    {statusInfo.status === 'success' ? (
                      <Badge
                        dot
                        offset={[-10, 49]}
                        style={{ width: '12px', height: '12px' }}
                        color={USER_STATUS_MAP[statusInfo.data?.status ?? 'PresenceUnknown'].color}
                      >
                        <MSTeamsUserAvatar
                          id={data?.adOid}
                          size={IMAGE_SIZE}
                          status={data.onlineStatus}
                          name={data.displayName}
                        />
                      </Badge>
                    ) : (
                      <Spin size={'small'} />
                    )}
                  </Col>
                  <Col>
                    <StyledRowCenterAlign>
                      <UserName>{`${data.firstName} ${data.lastName}`}</UserName>
                      {statusInfo.status === 'success' ? (
                        <StatusTag
                          color={
                            USER_STATUS_MAP[statusInfo.data?.status ?? 'PresenceUnknown']?.color
                          }
                        >
                          {t(USER_STATUS_MAP[statusInfo.data?.status ?? 'PresenceUnknown'].text)}
                        </StatusTag>
                      ) : (
                        <Spin size={'small'} />
                      )}
                    </StyledRowCenterAlign>
                  </Col>
                </Row>
              </Col>
            </Row>
            <StyledDivider />
            <Row justify="space-between">
              <Col span={16}>
                <Row justify="space-between">
                  <Col>
                    <StyledAnchor href={data.mobileNumber ? `tel:${data.mobileNumber}` : '#'}>
                      {data.mobileNumber || '-'} <PhoneOutlined />
                    </StyledAnchor>
                  </Col>
                  <Col>
                    <StyledAnchor href={data.email ? `mailto:${data.email}` : '#'}>
                      {data.email || '-'} <MailOutlined />
                    </StyledAnchor>
                  </Col>
                  <Col>
                    <StyledAnchor>
                      <MSTeamsConfirm onConfirm={onConfirm}>
                        {t('link.chat')} <MessageOutlined />
                      </MSTeamsConfirm>
                    </StyledAnchor>
                  </Col>
                </Row>
              </Col>
            </Row>
          </StyledCard>
          <Card>
            <Row gutter={[24, 24]}>
              <Col>
                <StyledPublicProfileTitle>{t('profile.title.jobDetails')}</StyledPublicProfileTitle>
              </Col>
            </Row>
            <StyledPublicProfileTitleSpace />
            <Col span={24}>
              <Row gutter={[24, 24]} justify={'space-between'}>
                {FIELDS_TO_RENDER.map((value) => (
                  <InfoItemColV2
                    span={6}
                    label={t(`user.publicProfile.${value}` as MessagesTypes)}
                    key={value}
                    mangerEmail={data.managerEmail}
                  >
                    {data[value]}
                  </InfoItemColV2>
                ))}
              </Row>
            </Col>
          </Card>
        </>

        {data?.canAccessDirectoryDetailsAttachments && (
          <PublicProfileAttachmentSection t={t} profileData={data} />
        )}

        <OrganizationalChartContainer>
          <OrganizationalChartComponent hcmId={data.hcmId ?? ''} isPublicProfile={true} />
        </OrganizationalChartContainer>
      </StyledVerticalSpace>
    </>
  );
};

import { Col, notification, Row, Spin, Typography } from 'antd';
import { task } from 'api';
import React, { useState } from 'react';
import { useTranslate } from 'translations';
import styled from 'styled-components';
import { borderRadius, colors, space } from 'ui/elements';
import { FileOutlined } from '@ant-design/icons';

interface ExpenseAttachmentProps {
  taskId: string;
  id: string;
  name: string;
  attachmentLabel?: string;
  url?: string;
}

const StyledAttachmentWrapper = styled(Row)`
  padding: ${space.slim} ${space.normal};
  border-radius: ${borderRadius.xxLarge};
  background-color: ${colors.lightActionSecond};
  transition: box-shadow 0.2s ease-in;

  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 1px 0px 2px ${colors.lightActive};
  }
`;
const ICON_COLOR = { color: colors.lightGrey };

const ExpenseAttachmentComponent: React.FC<ExpenseAttachmentProps> = ({
  taskId,
  id,
  name,
  attachmentLabel,
  url,
}) => {
  const { t } = useTranslate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { Text } = Typography;

  const handleDownloadClick = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await task.downloadTaskAttachment({
        attachmentId: id,
        taskId,
        params: {
          responseType: 'blob',
        },
      });
      saveAs(data, name);
    } catch (e) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    } finally {
      setIsLoading(false);
    }
  }, [id, name, t, taskId]);

  return (
    <React.Fragment>
      <a href={url || undefined} target="_blank" rel="noreferrer" download>
        <StyledAttachmentWrapper
          justify={'space-between'}
          align={'middle'}
          onClick={handleDownloadClick}
        >
          <Col>
            <Text>{attachmentLabel || name}</Text>
          </Col>
          <Col>
            {isLoading ? (
              <Spin size="small" style={{ ...ICON_COLOR }} />
            ) : (
              <FileOutlined style={{ ...ICON_COLOR }} />
            )}
          </Col>
        </StyledAttachmentWrapper>
      </a>
    </React.Fragment>
  );
};

export const ExpenseAttachment = React.memo(ExpenseAttachmentComponent);

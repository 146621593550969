import { Action, configureStore, EnhancedStore } from '@reduxjs/toolkit';
import { useDispatch, Provider as StoreProvider } from 'react-redux';
import { createEpicMiddleware } from 'redux-observable';

import { RootState, rootReducer } from './reducers';
import rootEpic from './rootEpic';

class StoreCreator {
  private readonly store: EnhancedStore<RootState>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(dependencies: any) {
    const epicMiddleware = createEpicMiddleware<Action, Action, RootState>({
      dependencies,
    });

    const middleware = [epicMiddleware];

    this.store = configureStore({
      reducer: rootReducer,
      middleware,
    });

    epicMiddleware.run(rootEpic);
  }

  getStore = () => ({
    store: this.store,
    StoreProvider,
    useDispatch,
  });
}

export { StoreCreator, StoreProvider };

import styled from 'styled-components';
import { Form, Card, Button } from 'antd';
import { SendIcon } from 'assets';
import { space } from '../../../elements/theme';

export const CardInput = styled(Card)`
  .ant-card-body {
    padding: 13px;
  }
`;
export const FormItem = styled(Form.Item)`
  margin: 0;
`;

export const SendButton = styled(Button)`
  padding: 0;
`;

export const ArrowR = styled(SendIcon)`
  display: flex;
  align-items: center;
  padding-left: ${space.mini};
`;

import styled from 'styled-components';
import { Row, Button, Typography, Collapse, Drawer } from 'antd';
import { colors, space, TitleH2, typography } from 'ui/elements';
import { HEADER_HEIGHT } from 'app/Header/Header.styled';
import { BigDownArrow, SmallDownArrow } from '../../assets';

const { Paragraph, Text } = Typography;

export const StyledELibrarySidebar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${colors.white};
  border-right: none;
  padding: 0;
  height: 100%;
  max-height: calc(100vh - ${HEADER_HEIGHT});
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: ${colors.scrollbarColor} ${colors.white};

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.white};
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.scrollbarColor};
    border-radius: 2px;
  }
`;

export const StyledELibraryWrapper = styled.div`
  display: flex;
  height: calc(100% - ${HEADER_HEIGHT});
  border-top: 1px solid ${colors.borderGrey};
`;

export const StyledRow = styled(Row)`
  width: 100%;
`;

export const StyledELibraryPage = styled.div`
  height: 100%;
  min-height: calc(100vh - ${HEADER_HEIGHT});
  max-height: calc(100vh - ${HEADER_HEIGHT});
  line-height: 24px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: ${colors.scrollbarColor} #f0f2f5;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f2f5;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.scrollbarColor};
    border-radius: 2px;
  }

  padding: 0;
`;

export const StyledDocumentCard = styled.div`
  width: 100%;
  height: 150px;
  border: 1px solid #eff2f8;
  background-color: white;
  padding: 24px;
  border-radius: 2px;
  cursor: pointer;
`;

export const StyledDocumentID = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: ${colors.lightGrey};
`;

export const StyledTitle = styled(Paragraph)`
  margin-top: 14px;
  line-height: 24px;
  color: ${colors.primaryColor};
`;

export const StyledELibrarySectionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const StyledELibraryFilterContainer = styled.div`
  width: 100%;
  background-color: ${colors.white};
  padding-left: 25px;
  border-left: 1.5px solid ${colors.borderGrey};
`;

export const StyledELibraryDepartmentContainer = styled.div`
  width: 100%;
  padding: 25px;
`;

export const StyledELibraryTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;

  &.sidebar-title-container {
    padding: 20px 24px 0 24px;
    margin-bottom: 0;
  }
`;

export const StyledELibraryTitle = styled(TitleH2)`
  font-size: 24px;
  font-weight: 500;
  color: ${colors.primaryColor};
`;

export const StyledELibraryDepartmentHelp = styled(Button)`
  padding: 5px 0;
  margin-left: 10px;
`;

export const StyledELibraryDepartmentsCollapse = styled(Collapse)`
  &.e-library-department-collapse {
    width: 100%;

    & > .ant-collapse-item {
      padding: 0;
      background-color: ${colors.white};

      &.ant-collapse-item-active {
        background-color: #f8f8f8;

        & > .ant-collapse-header {
          & > p {
            font-weight: 600;
          }
        }
      }

      & > .ant-collapse-header {
        padding: 15px 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & > p {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 0;
          color: ${colors.primaryColor};
        }

        & > .ant-collapse-arrow {
          padding: 0;
          margin-right: 10px;
          position: unset;
        }
      }

      & > .ant-collapse-content {
        & > .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }
`;

export const StyledELibraryDepartmentsCollapseDepartment = styled.p`
  margin-bottom: 0;
  padding: 15px 48px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  background-color: transparent;

  &.selected-department {
    border-left: 2px solid ${colors.primaryColor};
    background-color: #e6e6e6;
  }
`;

export const StyledELibraryDrawer = styled(Drawer)`
  & .ant-drawer-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const StyledELibraryTitleDrawer = styled(Text)`
  font-size: ${typography.size.tera};
  color: ${colors.lightGrey};
  margin-left: ${space.slim};
`;

export const StyledELibraryDrawerSubheading = styled(Text)`
  font-size: ${typography.size.tera};
  color: ${colors.primaryColor};
  line-height: 28.64px;
  font-weight: 500;
`;

export const StyledELibraryDrawerDivider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: ${space.normal};
  margin-bottom: ${space.normal};
  background-color: ${colors.borderGreySecond};
`;

export const StyledELibraryDrawerContainer = styled.div`
  padding-left: ${space.slim};
`;

export const StyledELibraryCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: fit-content;
`;

export const StyledELibraryCodesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: ${space.normal};
  padding-bottom: ${space.comfy};
`;

export const StyledELibraryCodeText = styled(Text)`
  color: ${colors.tawalGrey2};
  font-size: ${typography.size.tera};
  line-height: 28.64px;
  font-weight: 400;
`;

export const StyledELibraryCodeTextDash = styled(Text)`
  color: ${colors.tawalGrey2};
  font-size: ${typography.size.tera};
  line-height: 28.64px;
  font-weight: 400;
  margin-left: ${space.little};
  margin-right: ${space.little};
`;

export const StyledELibrarySmallDownArrow = styled(SmallDownArrow)`
  height: 20px;
  margin-top: ${space.slim};
`;

export const StyledELibraryBigDownArrow = styled(BigDownArrow)`
  height: 60px;
  margin-top: ${space.slim};
`;

export const StyledELibraryLeftHint = styled(Text)`
  position: absolute;
  width: max-content;
  bottom: -20px;
  right: 40%;
  color: ${colors.primaryColor};
  font-size: ${typography.size.hecto};
`;
export const StyledELibraryRightHint = styled(Text)`
  position: absolute;
  width: max-content;
  bottom: -20px;
  left: 40%;
  color: ${colors.primaryColor};
  font-size: ${typography.size.hecto};
`;

export const StyledELibraryTypesOfDocList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: ${space.cozy};
  margin-bottom: ${space.normal};
  padding-left: ${space.mini}; ;
`;

export const StyledELibraryTypesOfDocListRowItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${space.normal};
  flex: 1;
  width: 100%;
  margin-bottom: ${space.slim};
`;

export const StyledELibraryTypesOfDocListRowItemDocType = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
`;

export const StyledELibraryTypesOfDocListRowItemDocCode = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
`;

export const StyledELibraryTypesOfDocListRowItemText = styled(Text)`
  color: ${colors.primaryColor};
  font-size: ${typography.size.hecto};
`;

export const StyledELibraryTypesOfDocListTitle = styled(Text)`
  color: ${colors.labelGrey};
  font-size: ${typography.size.giga};
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: 'scroll';
`;

export const StyledDrawerFooter = styled.div`
  position: fixed;
  bottom: 14px;
  background-color: white;
`;

export const StyledViewDocumentButton = styled(Button)`
  border: none;
  background-color: ${colors.accentOrange};
  color: ${colors.white} !important;
  padding: 5px 16px;
  border-radius: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-right: 12px;

  &:hover,
  &:focus {
    background-color: ${colors.orangeShadow};
  }
`;

export const StyledDownloadDocument = styled(Button)`
  border-color: ${colors.borderGrey};
  padding: 5px 16px;
  border-radius: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const StyledDocumentTitle = styled(Paragraph)`
  color: ${colors.grey} !important;
  font-weight: 500 !important;
  font-size: 22px !important;
  letter-spacing: 0.3px !important;
  margin-bottom: 30px !important;
  width: 75%;
`;

export const StyledDocumentInfoLabel = styled.p`
  margin: 0;
  color: ${colors.lightOverlayBlue};
  font-size: 14px;
`;

export const StyledDocumentInfoValue = styled.p`
  margin: 0;
  color: ${colors.primaryColor};
  font-size: 16px;
`;

import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';

import { AppLayout } from 'layouts';
import { PagesUrls } from 'namespace';
import { LoginForm } from 'ui';
import { useAuthContext } from 'authentication';

import { LoginBackground, LoginWrapper, Wrapper } from './Login.styled';

export const Login: FC = () => {
  const { login, isAuthenticated, redirectPath } = useAuthContext();

  return isAuthenticated ? (
    <Redirect to={PagesUrls.Dashboard} />
  ) : (
    <LoginBackground>
      <AppLayout displayHeader={false} isLoginPage={true}>
        <LoginWrapper>
          <Wrapper>
            <LoginForm
              onClickLogin={() => {
                login(redirectPath);
              }}
            />
          </Wrapper>
        </LoginWrapper>
      </AppLayout>
    </LoginBackground>
  );
};

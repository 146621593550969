import React, { FC } from 'react';
import { Form, Input, Row } from 'antd';
import { Rule } from 'antd/lib/form';
import { numberPatternRule } from 'utils/formValidation';
import { useTranslate } from 'translations';
import { FormField } from 'types';
import { StyledFieldLabel } from '../GeneratedForm.Styled';

interface Props {
  field: FormField;
  withLabel?: boolean;
}

export const FieldTel: FC<Props> = ({ field, withLabel }) => {
  const fieldIsDisabled = field.properties.viewOnly === 'true';
  const fieldIsRequired = field.validationConstraints.some((rule) => rule.name === 'required');

  const { t } = useTranslate();

  const maxLength = field.properties.maxlength
    ? +field.properties.maxlength
    : field.properties.length
    ? +field.properties.length
    : 13;

  const minLength = field.properties.minLength ? +field.properties.minLength : 10;

  const FIELD_VALIDATION: Rule[] = [
    {
      required: fieldIsRequired,
    },
    {
      max: maxLength,
    },
    {
      min: minLength,
    },
    numberPatternRule(t),
  ];

  return (
    <>
      {withLabel && (
        <Row>
          <StyledFieldLabel>{field.label}</StyledFieldLabel>
        </Row>
      )}
      <Form.Item
        rules={FIELD_VALIDATION}
        label={withLabel ? null : field.label}
        name={field.id}
        wrapperCol={{ span: 21 }}
      >
        <Input
          value={
            fieldIsDisabled && fieldIsRequired
              ? field.defaultValue
                ? +field.defaultValue
                : typeof field.value.value === 'string'
                ? field.value.value
                : undefined
              : undefined
          }
          style={{ width: '100%' }}
          disabled={field.properties.viewOnly === 'true'}
          autoComplete="off"
          type="tel"
        />
      </Form.Item>
    </>
  );
};

import styled from 'styled-components';
import { colors, space, typography } from 'ui/elements';

export const EndEventWrapper = styled.div<{ $isError?: boolean }>`
  background-color: ${($props) => ($props.$isError ? colors.red : colors.skyColor)};
  font-size: ${typography.size.uno};
  font-weight: ${typography.weight.semiBold};
  color: ${colors.white};
  padding: ${space.mini} ${space.moderate};
  width: fit-content;
  border-radius: 20px;
  margin-left: 10px;
`;

import React, { FC } from 'react';
import { ColProps } from 'antd/lib/col';
import { FilterColStyled } from './FilterCol.styled';

export const FilterCol: FC<ColProps> = ({ children, xs = 24, sm = 12 }) => {
  return (
    <FilterColStyled xs={xs} sm={sm}>
      {children}
    </FilterColStyled>
  );
};

import { useQuery } from 'react-query';
import { leaseSites } from '../LeaseSites';
import { QUERIES } from '../../constants';
import { mapLovToOptions } from './helpers';

export const useLeaseDistricts = (regionId = '') =>
  useQuery(
    [QUERIES.leaseDistricts(regionId)],
    async () => {
      const { data } = await leaseSites.getDistricts(regionId);
      return mapLovToOptions(data);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!regionId,
    }
  );

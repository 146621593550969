import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExpensesFilterLookup, RequestCommonError, RequestStatus } from 'types';
import { ProfileExpenseLookupState } from './ProfileExpenseLookupTypes';

export const emptyProfileExpenseLookupState: ProfileExpenseLookupState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetProfileExpenseLookupStart = PayloadAction;
export type GetProfileExpenseLookupSuccess = PayloadAction<ExpensesFilterLookup>;
export type GetProfileExpenseLookupFail = PayloadAction<RequestCommonError>;

const profileExpenseLookup = createSlice({
  name: 'profileExpenseLookup',
  initialState: emptyProfileExpenseLookupState,
  reducers: {
    getProfileExpenseLookupStart(state, _: GetProfileExpenseLookupStart) {
      state.status = RequestStatus.pending;
    },
    getProfileExpenseLookupSuccess(state, action: GetProfileExpenseLookupSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getProfileExpenseLookupFail(state, action: GetProfileExpenseLookupFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getProfileExpenseLookupStart,
  getProfileExpenseLookupSuccess,
  getProfileExpenseLookupFail,
} = profileExpenseLookup.actions;
export default profileExpenseLookup.reducer;

export type TaskData = Task[];

export enum TaskDefinitionType {
  SITE_ACQUISITION_REQUEST_FORM_TASK = 'SITE_ACQUISITION_REQUEST_FORM_TASK',
  ASSIGN_WO_FIELD_ASSIGNMENT_TASK = 'ASSIGN_WO_FIELD_ASSIGNMENT_TASK',
  FIELD_RUNNER_TASK = 'FIELD_RUNNER_TASK',
  NON_PO_BUDGET_TEAM_FORM_TASK = 'NON_PO_BUDGET_TEAM_TASK',
}

export type TaskDefinitionKey = string | TaskDefinitionType;

export type TaskActivityId = string | TaskDefinitionType;

export interface Task {
  id: string;
  name: string;
  assignee: null;
  created: string;
  due: null;
  followUp: null;
  delegationState: null;
  description: null;
  executionId: string;
  owner: null;
  parentTaskId: null;
  priority: number;
  processDefinitionId: string;
  processInstanceId: string;
  taskDefinitionKey: TaskDefinitionKey;
  caseExecutionId: null;
  caseInstanceId: null;
  caseDefinitionId: null;
  suspended: boolean;
  formKey: string;
  tenantId: null;
  endTime?: string | null;
  startTime?: string;
}

export type TaskRenderedFormData = string;

export interface TaskDetails {
  fieldId: string;
  fieldValue?: string;
  value?: string;
  variableName?: string;
  time: string;
  variableType?: string;
  typeName?: string;
}

export interface TaskLabel {
  id: string;
  label: string;
  typeName: string;
  properties?: {
    viewOnly?: string;
    groupName?: string;
    maxAttachementsCount?: string;
  };
}

export interface TaskAssignment {
  type: string;
  userId: string | null;
  groupId: string | null;
}

export interface PrettyId {
  id: string;
  camundaId: string;
}

export interface PostTaskData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface RequestDescription {
  description: string;
  color: string;
  activityId: string;
  activityRequestName: string;
}

export interface SubmitNewRequestForm {
  taskId: string;
  processInstanceId: string;
  processDefinitionId: string;
  activityId: string;
  itemsId?: number[];
}

import styled from 'styled-components';
import { AutoComplete, Input } from 'antd';
import { colors, typography } from 'ui/elements';

export const UserDrawerSearchProcessContainer = styled.div`
  width: 100%;
`;
export const StyledSearchLabel = styled.p`
  font-size: ${typography.size.hecto};
  color: ${colors.textColorSecondary};
  font-weight: ${typography.weight.regular};
  margin-bottom: 7px;
`;
export const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
`;
export const StyledSearchInput = styled(Input)`
  .ant-input-suffix {
    svg {
      fill: ${colors.lightGrey};
    }
  }
`;

export const StyledAutoCompleteOption = styled.p`
  font-size: ${typography.size.hecto};
  color: ${colors.textColorSecondary};
  font-weight: ${typography.weight.regular};
  margin-bottom: 0;
  padding: 7px 0;
`;

import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { TType } from 'translations';
import { Form, notification, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { VALIDATE_MESSAGES } from 'utils';
import {
  EditTaskAttachment,
  EditTaskAttachmentMapped,
  NonPoEditBillDetailsDto,
  NonPoEditBillRequestDto,
  NonPoEditQuotationRequestDto,
  NONPOFiledIds,
  NonPoInvoiceAdvanceRequestResponse,
  NonPoInvoiceSuppliersResponse,
  NONPOLookupItem,
  NONPOLookUpsResponseData,
  NonPoQuotationDetailsDto,
  NonPoSubmitAdvance,
  NonPoSubmitBillDetail,
  NonPoSubmitCommon,
  NonPoSubmitExpense,
  NonPoSubmitQuotationDetail,
  NonPoUpdateRequest,
  NonPoUpdateRequestAdvanceParams,
  NonPoUpdateRequestExpenseParams,
  ProfileData,
  SelectOption,
  SubmitNewRequestForm,
} from 'types';
import { ProcessDescription, useDebounce } from 'ui';
import {
  useNonPoInvoiceAdvanceRequestsApi,
  useNonPoInvoiceConversionRateApi,
  useNonPoInvoiceEditRequestApi,
  useNonPoInvoiceLookUpListApi,
  useNonPoInvoiceSubmitAdvanceApi,
  useNonPoInvoiceSubmitExpenseApi,
  useNonPoInvoiceSuppliersApi,
  useNonPoInvoiceUpdateAdvanceApi,
  useNonPoInvoiceUpdateExpenseApi,
} from 'api';
import { profileData, useSelector } from 'store';
import { UploadFile } from 'antd/lib/upload/interface';
import { AttachmentFile } from 'routes/Tasks/components/pages';
import { submitAttachmentForm } from 'ui/publicFunction';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  BillDetailsFormGroup,
  PreferredPaymentMethodFormGroup,
  QuotationDetailsFormGroup,
  RequestDetailsFormGroup,
} from './components';
import {
  StyledFormHeaderWrapper,
  StyledRequestDetailsWraper,
} from './components/CustomFormGroup.styled';

export const MISCELLANEOUS_SUPPLIER_ID = 'Miscellaneous'; //'10125';

interface NonPoRequestFormProps {
  formName?: string;
  t: TType;
  adoId: string;
}

export interface IQuotationDetailSupplierStateType {
  [key: number]: SelectOption;
}

interface NonPoRequestFormContextType {
  expenseTypeValue: string;
  utilityTypeValue: string;
  invoiceTypeValue: string;
  paymentOutsideSAValue: string;
  serviceOutsideSAValue: string;
  paymentMethodValue: string;
  isUtility: boolean;
  isMiscellaneous: boolean;
  isEvent: boolean;
  isExpense: boolean;
  isVatVisible: boolean;
  isWthVisible: boolean;
  isPaymentMethodCheck: boolean;
  isEdit: boolean;
  dataLookUpResponse?: NONPOLookUpsResponseData;
  userData?: ProfileData;
  supplierOptions: SelectOption[];
  searchSupplier: (codeOrName: string) => void;
  isNonPoInvoiceSuppliersLoading: boolean;
  supplierNameSearchString: string;
  isPaymentMethodIBAN: boolean;
  isPaymentMethodSADAD: boolean;
  isServiceTypeMandatory: boolean;
  erpInvoiceOptions: SelectOption[];
  searchERPInvoice: (codeOrName: string) => void;
  isNonPoERPInvoiceLoading: boolean;
  erpInvoiceSearchString: string;
  showERPInvoice: boolean;
  recommendedQuotation?: number;
  isLoadingConversationRate: boolean;
  isConversationRateSuccess: boolean;
  isConversationRateFailed: boolean;
  isGovermentPayment: boolean;
  isAnyQuotationDetailMarkAsRecommended: boolean;
  requestDetailSupplierName: string;
  requestDetailSupplierLabel: string;
  quotationDetailSuppliers: IQuotationDetailSupplierStateType | undefined;
  updateDeletedQuotationIds: (filedIndex: number) => void;
}

export const NonPoRequestFormContext = createContext<NonPoRequestFormContextType>({
  dataLookUpResponse: undefined,
  expenseTypeValue: '',
  utilityTypeValue: '',
  invoiceTypeValue: '',
  paymentOutsideSAValue: '',
  serviceOutsideSAValue: '',
  paymentMethodValue: '',
  isUtility: false,
  isMiscellaneous: false,
  isEvent: false,
  isExpense: false,
  isVatVisible: true,
  isWthVisible: false,
  isPaymentMethodCheck: false,
  isEdit: false,
  supplierOptions: [],
  searchSupplier: () => {
    /**/
  },
  isNonPoInvoiceSuppliersLoading: false,
  supplierNameSearchString: '',
  isPaymentMethodIBAN: false,
  isPaymentMethodSADAD: false,
  isServiceTypeMandatory: false,
  erpInvoiceOptions: [],
  searchERPInvoice: () => {
    /**/
  },
  isNonPoERPInvoiceLoading: false,
  erpInvoiceSearchString: '',
  showERPInvoice: false,
  isLoadingConversationRate: false,
  isConversationRateSuccess: false,
  isConversationRateFailed: false,
  isGovermentPayment: false,
  isAnyQuotationDetailMarkAsRecommended: false,
  requestDetailSupplierName: '',
  requestDetailSupplierLabel: '',
  quotationDetailSuppliers: undefined,
  updateDeletedQuotationIds: () => {
    /**
     * Empty
     */
  },
});

export const NonPoRequestForm: React.FC<NonPoRequestFormProps> = ({
  formName,
  t,
  adoId,
}: NonPoRequestFormProps) => {
  const { taskId } = useParams<{ taskId: string }>();
  const { processId } = useParams<{ processId: string }>();
  const history = useHistory();
  const isEdit = useMemo(() => !!taskId, [taskId]);
  const {
    mutateAsync: nonPoInvoiceSubmitExpenseApiMutate,
    isLoading: isNonPoInvoiceSubmitExpenseApiLoading,
  } = useNonPoInvoiceSubmitExpenseApi();
  const {
    mutateAsync: nonPoInvoiceSubmitAdvanceApiMutate,
    isLoading: isNonPoInvoiceSubmitAdvanceApiLoading,
  } = useNonPoInvoiceSubmitAdvanceApi();

  const {
    mutateAsync: NonPoInvoiceUpdateAdvanceApiMutate,
    isLoading: isNonPoInvoiceUpdateAdvanceApiLoading,
  } = useNonPoInvoiceUpdateAdvanceApi();

  const {
    mutateAsync: NonPoInvoiceUpdateExpenseApiMutate,
    // isLoading: isNonPoInvoiceUpdateExpenseApiLoading,
  } = useNonPoInvoiceUpdateExpenseApi();

  const mutateNonPoInvoiceERPInvoice = useNonPoInvoiceAdvanceRequestsApi();

  const mutateNonPoInvoiceSuppliers = useNonPoInvoiceSuppliersApi();

  const { data: userData } = useSelector(profileData);
  const {
    data: dataLookUpResponse,
    isSuccess: isSuccessLookUpList,
    isLoading: isLoadingLookUpList,
  } = useNonPoInvoiceLookUpListApi();

  const {
    data: EditRequestData,
    isLoading: isLoadingGetEditRequest,
    remove: removeEditData,
  } = useNonPoInvoiceEditRequestApi(processId, isEdit && isSuccessLookUpList);
  const mutatuionConversionRate = useNonPoInvoiceConversionRateApi();
  const [currentConversionRateRequestObject, setCurrentConversionRateRequestObject] = useState<
    | {
        formListIndex: number;
        SelectedcurrencyId: number;
        SelectedAmount: number;
      }
    | undefined
  >();
  const [currentWitholdingTaxRequestObject, setCurrentWitholdingTaxRequestObject] = useState<
    | {
        formListIndex: number;
        expenseTypeKey: string;
        SelectedAmount: number;
        withholdingTaxPercentage: string;
      }
    | undefined
  >();
  const [expenseTypeValue, setExpenseTypeValue] = useState('');
  const [invoiceTypeValue, setInvoiceTypeValue] = useState('');
  const [utilityTypeValue, setUtilityTypeValue] = useState('');
  const [paymentOutsideSAValue, setPaymentOutsideSAValue] = useState('');
  const [serviceOutsideSAValue, setServiceOutsideSAValue] = useState('');
  const [paymentMethodValue, setPaymentMethodValue] = useState('');
  const [supplierNameSearchString, setSupplierNameSearchString] = useState('');
  const [erpInvoiceSearchString, setErpInvoiceSearchString] = useState('');
  const [supplierNameValue, setSupplierNameValue] = useState('');
  const [requestDetailSupplierName, setRequestDetailSupplierName] = useState('');
  const [requestDetailSupplierLabel, setRequestDetailSupplierLabel] = useState('');
  const [recommendedQuotation, setRecommendedQuotation] = useState<number | undefined>();
  const [deletedQuotationIDs, setDeletedQuotationIDs] = useState<Array<number>>([]);
  const [deletedBillsIDs, setDeletedBillsIDs] = useState<Array<number>>([]);
  const [
    isAnyQuotationDetailMarkAsRecommended,
    setIsAnyQuotationDetailMarkAsRecommended,
  ] = useState<boolean>(false);
  const [quotationDetailSuppliers, setQuotationDetailSuppliers] = useState<
    IQuotationDetailSupplierStateType | undefined
  >(undefined);

  const [form] = useForm();

  useMemo(() => {
    if (userData) {
      form.setFieldsValue({ [`${NONPOFiledIds.RequestDetail_Department}`]: userData?.department });
    }
  }, [form, userData]);

  useEffect(
    () => {
      return () => {
        removeEditData();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isVatVisible = useMemo(() => {
    if (paymentOutsideSAValue === 'NO') {
      return true;
    }
    const billDetails = form?.getFieldValue(NONPOFiledIds.BillDetails_BillDetails);
    billDetails?.forEach((item: Record<string, string | boolean | number>, index: number) => {
      form.setFields([
        {
          name: [
            NONPOFiledIds.BillDetails_BillDetails,
            index,
            NONPOFiledIds.BillDetails_VatPercentage,
          ],
          value: undefined,
        },
      ]);
      form.setFields([
        {
          name: [NONPOFiledIds.BillDetails_BillDetails, index, NONPOFiledIds.BillDetails_VatAmount],
          value: undefined,
        },
      ]);
    });
    return false;
  }, [paymentOutsideSAValue, form]);

  const isWthVisible = useMemo(() => {
    if (paymentOutsideSAValue === 'YES' && serviceOutsideSAValue === 'NO') {
      return true;
    }

    const billDetails = form?.getFieldValue(NONPOFiledIds.BillDetails_BillDetails);
    billDetails?.forEach((item: Record<string, string | boolean | number>, index: number) => {
      form.setFields([
        {
          name: [
            NONPOFiledIds.BillDetails_BillDetails,
            index,
            NONPOFiledIds.BillDetails_IncludesWithHoldingTax,
          ],
          value: undefined,
        },
      ]);
      form.setFields([
        {
          name: [
            NONPOFiledIds.BillDetails_BillDetails,
            index,
            NONPOFiledIds.BillDetails_WithHoldingTaxAmount,
          ],
          value: undefined,
        },
      ]);
    });
    return false;
  }, [paymentOutsideSAValue, serviceOutsideSAValue, form]);

  const getValueFromLookUps = useCallback(
    (nonPoFieldId: NONPOFiledIds, id: number): string | null => {
      if (!dataLookUpResponse) {
        return null;
      }
      let nonPoLookUpItems: NONPOLookupItem[] = [];
      switch (nonPoFieldId) {
        case NONPOFiledIds.RequestDetail_ExpenseType:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.EXPENSE_TYPE;
          break;
        case NONPOFiledIds.RequestDetail_InvoiceType:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.INVOICE_TYPE;
          break;
        case NONPOFiledIds.RequestDetail_UtilityType:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.UTILITY_TYPE;
          break;
        case NONPOFiledIds.RequestDetail_AlreadyPaid:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.ALREADY_PAID;
          break;
        case NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.PAYMENT_OUTSIDE_SAUDI_ARABIA;
          break;
        case NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.SERVICE_OUTSIDE_SAUDI_ARABIA;
          break;
        case NONPOFiledIds.RequestDetail_ServiceType:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.SERVICE_TYPE;
          break;
        case NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.WHT_PERCENTAGE;
          break;
        case NONPOFiledIds.BillDetails_IncludesWithHoldingTax:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.INVOICE_AMOUNT_INCLUDE_WITHOLDING_TAX;
          break;
        case NONPOFiledIds.BillDetails_VatPercentage:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.VAT_PERCENTAGE;
          break;
        case NONPOFiledIds.PaymentDetail_PaymentMethod:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.PAYMENT_METHOD;
          break;
        default:
          break;
      }
      if (nonPoLookUpItems === dataLookUpResponse?.lookups?.WHT_PERCENTAGE) {
        const value = nonPoLookUpItems.find((nonPoLookUpItem) => Number(nonPoLookUpItem.id) === id)
          ?.value;
        return value ? value : null;
      }
      const value = nonPoLookUpItems.find((nonPoLookUpItem) => Number(nonPoLookUpItem.id) === id)
        ?.key;
      return value ? value : null;
    },
    [dataLookUpResponse]
  );

  const isExpense = useMemo(() => {
    if (invoiceTypeValue !== 'EXPENSE') {
      form?.setFieldsValue({ [`${NONPOFiledIds.BillDetails_BillDetails}`]: undefined });
      return false;
    }
    form?.setFieldsValue({ [`${NONPOFiledIds.QuotationDetail_QuotationDetails}`]: undefined });
    return true;
  }, [invoiceTypeValue, form]);

  const isUtility = useMemo(() => {
    if (expenseTypeValue !== 'UTILITY_BILLS') {
      form?.setFieldsValue({ [`${NONPOFiledIds.RequestDetail_UtilityType}`]: undefined });
      return false;
    }
    return true;
  }, [expenseTypeValue, form]);

  const isGovermentPayment = useMemo(() => {
    return expenseTypeValue === 'GOVERNMENT_PAYMENTS';
  }, [expenseTypeValue]);

  const isPaymentMethodCheck = useMemo(() => {
    if (paymentMethodValue !== 'CHECK') {
      form?.setFieldsValue({ [`${NONPOFiledIds.PaymentDetail_AccountNumber}`]: undefined });
      form?.setFieldsValue({ [`${NONPOFiledIds.PaymentDetail_SadaBillerCode}`]: undefined });
      form?.setFieldsValue({ [`${NONPOFiledIds.PaymentDetail_NewAccountNumber}`]: undefined });
      return false;
    }
    return true;
  }, [paymentMethodValue, form]);

  const isPaymentMethodIBAN = useMemo(() => {
    if (paymentMethodValue !== 'IBAN') {
      form?.setFieldsValue({ [`${NONPOFiledIds.PaymentDetail_SadaBillerCode}`]: undefined });
      form?.setFieldsValue({ [`${NONPOFiledIds.PaymentDetail_NewAccountNumber}`]: undefined });
      return false;
    }
    return true;
  }, [form, paymentMethodValue]);

  const isPaymentMethodSADAD = useMemo(() => {
    if (paymentMethodValue !== 'SADAD') {
      form?.setFieldsValue({ [`${NONPOFiledIds.PaymentDetail_AccountNumber}`]: undefined });
      return false;
    }
    return true;
  }, [form, paymentMethodValue]);

  const showERPInvoice = useMemo(() => {
    return !!supplierNameValue;
  }, [supplierNameValue]);

  const isMiscellaneous = useMemo(() => {
    if (expenseTypeValue !== 'MISCELLANEOUS') {
      form?.setFieldsValue({ [`${NONPOFiledIds.RequestDetail_ServiceType}`]: undefined });
      return false;
    }
    return true;
  }, [expenseTypeValue, form]);

  const isEvent = useMemo(() => {
    if (
      expenseTypeValue === 'EVENTS' ||
      expenseTypeValue === 'CATERING' ||
      expenseTypeValue === 'LOGISTICS' ||
      expenseTypeValue === 'TRAINING' ||
      expenseTypeValue === 'CONFERENCE' ||
      expenseTypeValue === 'RESEARCH' ||
      expenseTypeValue === 'STAFF_EVENTS' ||
      expenseTypeValue === 'CORPORATE_EVENTS'
    ) {
      return true;
    }

    form?.setFieldsValue({ [`${NONPOFiledIds.RequestDetail_EventDate}`]: undefined });
    return false;
  }, [expenseTypeValue, form]);

  const isServiceTypeMandatory = useMemo(() => {
    return isMiscellaneous && paymentOutsideSAValue === 'YES' && serviceOutsideSAValue === 'NO';
  }, [isMiscellaneous, paymentOutsideSAValue, serviceOutsideSAValue]);

  const convertAttachmentToUploadType = (
    attachment: EditTaskAttachment
  ): EditTaskAttachmentMapped => {
    return {
      uid: attachment.id,
      name: `${attachment.name}`,
      status: 'done',
      url: '',
      originalObject: attachment,
    };
  };

  useEffect(() => {
    if (EditRequestData && isEdit) {
      const invoiceTypeKey = getValueFromLookUps(
        NONPOFiledIds.RequestDetail_InvoiceType,
        EditRequestData.invoiceTypeId
      );
      const expenseTypeKey = getValueFromLookUps(
        NONPOFiledIds.RequestDetail_ExpenseType,
        EditRequestData.expenseTypeId
      );
      const paymentmethodKey = getValueFromLookUps(
        NONPOFiledIds.PaymentDetail_PaymentMethod,
        EditRequestData.paymentMethodId
      );
      setInvoiceTypeValue(String(invoiceTypeKey));
      setExpenseTypeValue(String(expenseTypeKey));
      setPaymentMethodValue(String(paymentmethodKey));

      if (EditRequestData.utilityTypeId) {
        setUtilityTypeValue(
          String(
            getValueFromLookUps(
              NONPOFiledIds.RequestDetail_UtilityType,
              EditRequestData.utilityTypeId
            )
          )
        );
      }

      if (invoiceTypeKey !== 'ADVANCE') {
        setPaymentOutsideSAValue(
          String(
            getValueFromLookUps(
              NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia,
              EditRequestData.paymentOutsideSaId
            )
          )
        );
        setServiceOutsideSAValue(
          String(
            getValueFromLookUps(
              NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia,
              EditRequestData.serviceOutsideSaId
            )
          )
        );
        setRequestDetailSupplierLabel(EditRequestData.supplierName);
        if (EditRequestData.supplierName && EditRequestData.supplierNumber) {
          setSupplierNameValue(EditRequestData.supplierName);
          getAdvanceRequests(EditRequestData.supplierNumber);
        }
      } else {
        const quotations = EditRequestData.nonPoQuotationDetailsDto;
        quotations.forEach((quotation: NonPoQuotationDetailsDto, index: number) => {
          if (quotations.length > index) {
            if (quotations[index].quotationSupplierName) {
              let mQuotationSuppliers: IQuotationDetailSupplierStateType = {};
              if (quotationDetailSuppliers) {
                mQuotationSuppliers = { ...quotationDetailSuppliers };
              }
              mQuotationSuppliers[index] = {
                label: quotations[index].quotationSupplierName,
                value: quotations[index].quotationSupplierName,
              };
              setQuotationDetailSuppliers(mQuotationSuppliers);
            }

            if (quotations[index].recommended) {
              setRecommendedQuotation(index);
              setIsAnyQuotationDetailMarkAsRecommended(true);
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EditRequestData, getValueFromLookUps, isEdit]);

  useEffect(() => {
    if (EditRequestData && isEdit) {
      if (isPaymentMethodSADAD) {
        const billerCode = EditRequestData.sadadBillerCode;
        form?.setFieldsValue({
          [`${NONPOFiledIds.PaymentDetail_SadaBillerCode}`]: billerCode
            ? Number(billerCode)
            : billerCode,
          [`${NONPOFiledIds.PaymentDetail_NewAccountNumber}`]: EditRequestData.sadadAccountNumber,
        });
      }

      if (isPaymentMethodIBAN) {
        form?.setFieldsValue({
          [`${NONPOFiledIds.PaymentDetail_AccountNumber}`]: EditRequestData.accountNumber,
        });
      }
    }
  }, [EditRequestData, form, isEdit, isPaymentMethodIBAN, isPaymentMethodSADAD]);

  useEffect(
    () => {
      if (EditRequestData && isEdit) {
        form?.setFieldsValue({
          [`${NONPOFiledIds.RequestDetail_ExpenseType}`]: expenseTypeValue,
          [`${NONPOFiledIds.RequestDetail_InvoiceType}`]: invoiceTypeValue,
          [`${NONPOFiledIds.RequestDetail_Department}`]: EditRequestData.department,
          [`${NONPOFiledIds.RequestDetail_TotalAmount}`]: EditRequestData.totalAmount,
          [`${NONPOFiledIds.RequestDetail_Description}`]: EditRequestData.description,
          [`${NONPOFiledIds.RequestDetail_AlreadyPaid}`]: getValueFromLookUps(
            NONPOFiledIds.RequestDetail_AlreadyPaid,
            EditRequestData.alreadyPaidId
          ),
          [`${NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia}`]: getValueFromLookUps(
            NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia,
            EditRequestData.paymentOutsideSaId
          ),
          [`${NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia}`]: getValueFromLookUps(
            NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia,
            EditRequestData.serviceOutsideSaId
          ),
          [`${NONPOFiledIds.PaymentDetail_PaymentMethod}`]: getValueFromLookUps(
            NONPOFiledIds.PaymentDetail_PaymentMethod,
            EditRequestData.paymentMethodId
          ),
        });

        if (isServiceTypeMandatory && EditRequestData.serviceTypeId) {
          form?.setFieldsValue({
            [`${NONPOFiledIds.RequestDetail_ServiceType}`]: getValueFromLookUps(
              NONPOFiledIds.RequestDetail_ServiceType,
              EditRequestData.serviceTypeId
            ),
          });
        }

        if (EditRequestData.utilityTypeId) {
          form?.setFieldsValue({
            [`${NONPOFiledIds.RequestDetail_UtilityType}`]: getValueFromLookUps(
              NONPOFiledIds.RequestDetail_UtilityType,
              EditRequestData.utilityTypeId
            ),
          });

          if (utilityTypeValue === 'OTHERS') {
            form?.setFieldsValue({
              [`${NONPOFiledIds.RequestDetail_OtherUtilityType}`]: EditRequestData.others,
            });
          }
        }

        if (isEvent) {
          const eventDate = EditRequestData.eventDate
            ? new Date(EditRequestData.eventDate)
            : undefined;

          form?.setFieldsValue({
            [`${NONPOFiledIds.RequestDetail_EventDate}`]: eventDate ? moment(eventDate) : null,
          });
        }

        if (invoiceTypeValue === 'EXPENSE') {
          form?.setFieldsValue({
            [`${NONPOFiledIds.QuotationDetail_QuotationDetails}`]: undefined,
            [`${NONPOFiledIds.RequestDetail_Currency}`]: EditRequestData.currencyId,
            [`${NONPOFiledIds.RequestDetail_SupplierName}`]: EditRequestData.supplierName,
            [`${NONPOFiledIds.RequestDetail_ERPInvoice}`]: EditRequestData.advanceLinkIds,
          });

          if (EditRequestData.miscellaneousSupplierName) {
            form?.setFieldsValue({
              [`${NONPOFiledIds.RequestDetail_Miscellaneous_SupplierName}`]: EditRequestData.miscellaneousSupplierName,
            });
          }

          if (isWthVisible) {
            form?.setFieldsValue({
              [`${NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage}`]: getValueFromLookUps(
                NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage,
                EditRequestData.whtPercentageId
              ),
            });
          }
          const bills = EditRequestData.nonPoEditBillDetailsDto;
          bills?.forEach((bill: NonPoEditBillDetailsDto, index: number) => {
            if (bills.length > index) {
              const invoiceDate = bills[index].invoiceDate
                ? new Date(bills[index].invoiceDate)
                : undefined;
              form.setFields([
                {
                  name: [
                    NONPOFiledIds.BillDetails_BillDetails,
                    index,
                    NONPOFiledIds.BillDetails_BillId,
                  ],
                  value: bills[index].id,
                },
                {
                  name: [
                    NONPOFiledIds.BillDetails_BillDetails,
                    index,
                    NONPOFiledIds.BillDetails_InvoiceDate,
                  ],
                  value: invoiceDate ? moment(invoiceDate) : null,
                },
                {
                  name: [
                    NONPOFiledIds.BillDetails_BillDetails,
                    index,
                    NONPOFiledIds.BillDetails_InvoiceNumber,
                  ],
                  value: bills[index].invoiceNumber,
                },
                {
                  name: [
                    NONPOFiledIds.BillDetails_BillDetails,
                    index,
                    NONPOFiledIds.BillDetails_InvoiceAmount,
                  ],
                  value: bills[index].invoiceAmount,
                },
                {
                  name: [
                    NONPOFiledIds.BillDetails_BillDetails,
                    index,
                    NONPOFiledIds.BillDetails_VatPercentage,
                  ],
                  value: getValueFromLookUps(
                    NONPOFiledIds.BillDetails_VatPercentage,
                    bills[index].vatPercentageId
                  ),
                },
                {
                  name: [
                    NONPOFiledIds.BillDetails_BillDetails,
                    index,
                    NONPOFiledIds.BillDetails_VatAmount,
                  ],
                  value: bills[index].vatAmount,
                },
                {
                  name: [
                    NONPOFiledIds.BillDetails_BillDetails,
                    index,
                    NONPOFiledIds.BillDetails_IncludesWithHoldingTax,
                  ],
                  value: bills[index].invoiceIncludeWHTId,
                },
                {
                  name: [
                    NONPOFiledIds.BillDetails_BillDetails,
                    index,
                    NONPOFiledIds.BillDetails_WithHoldingTaxAmount,
                  ],
                  value: bills[index].whtAmount,
                },
                {
                  name: [
                    NONPOFiledIds.BillDetails_BillDetails,
                    index,
                    NONPOFiledIds.BillDetails_ConversionRate,
                  ],
                  value: bills[index].billConversionRate,
                },
                {
                  name: [
                    NONPOFiledIds.BillDetails_BillDetails,
                    index,
                    NONPOFiledIds.BillDetails_InvoiceAmountInSAR,
                  ],
                  value: bills[index].invoicePriceSar,
                },
                {
                  name: [
                    NONPOFiledIds.BillDetails_BillDetails,
                    index,
                    NONPOFiledIds.BillDetails_InvoiceReason,
                  ],
                  value: bills[index].invoiceReason,
                },
                {
                  name: [
                    NONPOFiledIds.BillDetails_BillDetails,
                    index,
                    NONPOFiledIds.BillDetails_AttachmentList,
                  ],
                  value: bills[index].billTaskAttachment?.map(convertAttachmentToUploadType),
                },
              ]);
            }
          });
        } else if (invoiceTypeValue === 'ADVANCE') {
          form?.setFieldsValue({
            [`${NONPOFiledIds.BillDetails_BillDetails}`]: undefined,
          });

          const qoutations = EditRequestData.nonPoQuotationDetailsDto;
          qoutations?.forEach((quotation: NonPoQuotationDetailsDto, index: number) => {
            if (qoutations.length > index) {
              form.setFields([
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    index,
                    NONPOFiledIds.QuotationDetail_QuotationId,
                  ],
                  value: qoutations[index].id,
                },
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    index,
                    NONPOFiledIds.QuotationDetail_SupplierName,
                  ],
                  value: qoutations[index].quotationSupplierName,
                },
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    index,
                    NONPOFiledIds.QuotationDetail_Miscellaneous_SupplierName,
                  ],
                  value: qoutations[index].quotationMiscellaneousSupplierName,
                },
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    index,
                    NONPOFiledIds.QuotationDetail_SupplierSiteId,
                  ],
                  value: qoutations[index].supplierSiteId,
                },
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    index,
                    NONPOFiledIds.QuotationDetail_Currency,
                  ],
                  value: qoutations[index].currencyId,
                },
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    index,
                    NONPOFiledIds.QuotationDetail_QuotedPrice,
                  ],
                  value: qoutations[index].quotedPrice,
                },
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    index,
                    NONPOFiledIds.QuotationDetail_ConversionRate,
                  ],
                  value: qoutations[index].quotationConversionRate,
                },
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    index,
                    NONPOFiledIds.QuotationDetail_QuotedPriceSAR,
                  ],
                  value: qoutations[index].quotedPriceSar,
                },
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    index,
                    NONPOFiledIds.QuotationDetail_AdvancePayment,
                  ],
                  value: qoutations[index].advancePaymentPercentage,
                },
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    index,
                    NONPOFiledIds.QuotationDetail_AdvanceAmountToBePaid,
                  ],
                  value: qoutations[index].advanceAmountToBePaid,
                },
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    index,
                    NONPOFiledIds.QuotationDetail_Description,
                  ],
                  value: qoutations[index].quotationDescription,
                },
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    index,
                    NONPOFiledIds.QuotationDetail_MarkAsRecommended,
                  ],
                  value: qoutations[index].recommended,
                },
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    index,
                    NONPOFiledIds.QuotationDetail_AttachmentList,
                  ],
                  value: qoutations[index].quotationTaskAttachment?.map(
                    convertAttachmentToUploadType
                  ),
                },
              ]);
              if (qoutations[index].recommended) {
                form.setFields([
                  {
                    name: [
                      NONPOFiledIds.QuotationDetail_QuotationDetails,
                      index,
                      NONPOFiledIds.QuotationDetail_Justification,
                    ],
                    value: qoutations[index].justification,
                  },
                ]);
              }
            }
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      EditRequestData,
      expenseTypeValue,
      form,
      getValueFromLookUps,
      invoiceTypeValue,
      isEdit,
      isEvent,
      isServiceTypeMandatory,
      isWthVisible,
    ]
  );

  const calculateTotalAmountBasedOnQuotationDetail = useCallback(() => {
    const quotationDetails = form.getFieldValue(NONPOFiledIds.QuotationDetail_QuotationDetails);
    if (quotationDetails && quotationDetails.length > 0) {
      // const sum = quotationDetails.reduce(
      //   (
      //     accumulator: number,
      //     currentValue: { quotedPrice?: number; markAsRecommended?: boolean }
      //   ) => {
      //     return (accumulator += Number(currentValue?.quotedPrice ?? 0));
      //   },
      //   0
      // );
      const qutationDetailWithMarkRecommended = quotationDetails.find(
        (quotation: Record<string, string | number | boolean>) =>
          quotation?.markAsRecommended === true
      );
      form.setFieldsValue({
        [`${NONPOFiledIds.RequestDetail_TotalAmount}`]: qutationDetailWithMarkRecommended
          ? qutationDetailWithMarkRecommended?.quotedPrice
          : undefined,
      });
    } else {
      form.setFieldsValue({ [`${NONPOFiledIds.RequestDetail_TotalAmount}`]: undefined });
    }
  }, [form]);

  const calculateTotalAmountBasedBillDetail = useCallback(() => {
    const quotationDetails = form.getFieldValue(NONPOFiledIds.BillDetails_BillDetails);
    if (quotationDetails && quotationDetails.length > 0) {
      const sum = quotationDetails.reduce(
        (accumulator: number, currentValue: { invoiceAmount?: number }) => {
          return (accumulator += Number(currentValue?.invoiceAmount ?? 0));
        },
        0
      );
      form.setFieldsValue({
        [`${NONPOFiledIds.RequestDetail_TotalAmount}`]:
          sum !== 0 ? (Number.isInteger(sum) ? sum : sum.toFixed(3)) : undefined,
      });
    } else {
      form.setFieldsValue({ [`${NONPOFiledIds.RequestDetail_TotalAmount}`]: undefined });
    }
  }, [form]);

  const getAdvanceRequests = useCallback(
    (supplierName: string) => {
      if (!supplierName) {
        return;
      }
      if (supplierName) {
        mutateNonPoInvoiceERPInvoice.mutate({
          supplierNumber: supplierName,
          processInstanceId: isEdit ? processId : '',
        });
      }
    },
    [isEdit, mutateNonPoInvoiceERPInvoice, processId]
  );

  const calculateConversionRate = useCallback(
    (formListIndex: number, SelectedcurrencyId: number, SelectedAmount: number) => {
      if (!SelectedcurrencyId || !SelectedAmount) {
        return;
      }
      mutatuionConversionRate
        .mutateAsync({
          currencyId: SelectedcurrencyId,
          amount: SelectedAmount,
        })
        .then((data) => {
          if (isExpense) {
            form.setFields([
              {
                name: [
                  NONPOFiledIds.BillDetails_BillDetails,
                  formListIndex,
                  NONPOFiledIds.BillDetails_ConversionRate,
                ],
                value: data.conversionRate,
                errors: [],
              },
            ]);
            form.setFields([
              {
                name: [
                  NONPOFiledIds.BillDetails_BillDetails,
                  formListIndex,
                  NONPOFiledIds.BillDetails_InvoiceAmountInSAR,
                ],
                value: data.amountWithConversionRate,
                errors: [],
              },
            ]);
          } else {
            form.setFields([
              {
                name: [
                  NONPOFiledIds.QuotationDetail_QuotationDetails,
                  formListIndex,
                  NONPOFiledIds.QuotationDetail_ConversionRate,
                ],
                value: data.conversionRate,
                errors: [],
              },
            ]);
            form.setFields([
              {
                name: [
                  NONPOFiledIds.QuotationDetail_QuotationDetails,
                  formListIndex,
                  NONPOFiledIds.QuotationDetail_QuotedPriceSAR,
                ],
                value: data.amountWithConversionRate,
                errors: [],
              },
            ]);
          }
        })
        .catch(() => {
          form.setFields([
            {
              name: [
                isExpense
                  ? NONPOFiledIds.BillDetails_BillDetails
                  : NONPOFiledIds.QuotationDetail_QuotationDetails,
                formListIndex,
                isExpense
                  ? NONPOFiledIds.BillDetails_ConversionRate
                  : NONPOFiledIds.QuotationDetail_ConversionRate,
              ],
              value: undefined,
              errors: [t('messages.error.conversationRate')],
            },
          ]);
        });
    },
    [form, isExpense, mutatuionConversionRate, t]
  );

  const conversionRateDebounce = useDebounce(currentConversionRateRequestObject, 500);

  useEffect(
    () => {
      if (conversionRateDebounce) {
        calculateConversionRate(
          conversionRateDebounce.formListIndex,
          conversionRateDebounce.SelectedcurrencyId,
          conversionRateDebounce.SelectedAmount
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversionRateDebounce]
  );

  const calculateConversionRateOfAllBillDetails = useCallback(() => {
    const currency = form.getFieldValue(NONPOFiledIds.RequestDetail_Currency);
    if (!currency) {
      return;
    }
    const billDetails = form.getFieldValue(NONPOFiledIds.BillDetails_BillDetails);
    if (!billDetails) {
      return;
    }
    if (billDetails.length <= 0) {
      return;
    }
    billDetails?.forEach((billDetail: Record<string, string | boolean | number>, index: number) => {
      const invoiceAmount = billDetail?.[`${NONPOFiledIds.BillDetails_InvoiceAmount}`];
      if (invoiceAmount) {
        calculateConversionRate(index, currency, invoiceAmount as number);
      }
    });
  }, [calculateConversionRate, form]);

  const resetMarkAsRecommended = useCallback(
    (event) => {
      if (isExpense) {
        return;
      }

      if (event?.[0].name?.[0] === NONPOFiledIds.QuotationDetail_QuotationDetails) {
        const [, formListIndex, changedField] = event?.[0].name;
        if (changedField === 'markAsRecommended') {
          if (formListIndex !== undefined) {
            setRecommendedQuotation(event?.[0].value ? formListIndex : undefined);
            /**
             * Clear justification
             * in case of removed mark as recommended
             */
            if (!event?.[0].value) {
              const namePathJustification = [
                `${NONPOFiledIds.QuotationDetail_QuotationDetails}`,
                formListIndex,
                `${NONPOFiledIds.QuotationDetail_Justification}`,
              ];
              form.setFields([{ name: namePathJustification, value: undefined }]);
            }

            const quotationDetails = form.getFieldValue(
              NONPOFiledIds.QuotationDetail_QuotationDetails
            );

            quotationDetails.forEach(
              (quotationDetailItem: Record<string, string | number | boolean>, index: number) => {
                if (formListIndex !== index) {
                  const namePath = [
                    `${NONPOFiledIds.QuotationDetail_QuotationDetails}`,
                    index,
                    'markAsRecommended',
                  ];

                  const namePathJustification = [
                    `${NONPOFiledIds.QuotationDetail_QuotationDetails}`,
                    index,
                    `${NONPOFiledIds.QuotationDetail_Justification}`,
                  ];

                  form.setFields([
                    { name: namePath, value: false },
                    { name: namePathJustification, value: undefined }, // Clear justification if not recommended
                  ]);
                }
              }
            );
          }
        }
      }
    },
    [form, isExpense]
  );

  const supplierOptions: SelectOption[] = useMemo(() => {
    if (mutateNonPoInvoiceSuppliers?.data) {
      const nonPOSuppliers = mutateNonPoInvoiceSuppliers?.data;
      return nonPOSuppliers
        ? nonPOSuppliers.map(({ supplierSite, supplierName, supplierCode }) => ({
            label: supplierName,
            value: supplierCode,
          }))
        : [];
    }
    return [];
  }, [mutateNonPoInvoiceSuppliers]);

  const erpInvoiceOptions: SelectOption[] = useMemo(() => {
    if (mutateNonPoInvoiceERPInvoice) {
      const nonPoERPInvoice = mutateNonPoInvoiceERPInvoice?.data;
      return nonPoERPInvoice
        ? nonPoERPInvoice.map(
            ({ invoiceId, createdDate, sufaraId, totalAmount, id, description }) => ({
              label: `${invoiceId} - ${totalAmount} - ${moment(createdDate).format('DD/M/YYYY')}`,
              value: id,
              infoHint: `${sufaraId} - ${description}`,
            })
          )
        : [];
    }
    return [];
  }, [mutateNonPoInvoiceERPInvoice]);

  const debounceSupplierSearchString = useDebounce(supplierNameSearchString, 500);

  const searchForSuppliers = useCallback((codeOrName) => {
    setSupplierNameSearchString(codeOrName);
  }, []);

  useEffect(
    () => {
      if (debounceSupplierSearchString !== '') {
        mutateNonPoInvoiceSuppliers.mutate(debounceSupplierSearchString);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debounceSupplierSearchString]
  );

  const searchERPInvoice = useCallback((erpInvoiceSearchString) => {
    //Write a code to search erp invoice
    setErpInvoiceSearchString(erpInvoiceSearchString);
  }, []);

  const validateQuotationDetailForMarkAsRecommended = useCallback(
    (variables) => {
      if (!isExpense) {
        const quotationDetails = variables['quotationDetails'];
        const quotationMarkedAsRecommended = quotationDetails?.find(
          (quotationDetail: Record<string, string | boolean | number>) =>
            quotationDetail[NONPOFiledIds.QuotationDetail_MarkAsRecommended] === true
        );

        return !!quotationMarkedAsRecommended;
      }
      return true;
    },
    [isExpense]
  );

  const checkIsAnyQuotationDetailMarkAsRecommended = useCallback(() => {
    if (isExpense) {
      setIsAnyQuotationDetailMarkAsRecommended(false);
      return;
    }
    const quotationDetails = form.getFieldValue(
      `${NONPOFiledIds.QuotationDetail_QuotationDetails}`
    );
    const quotationMarkedAsRecommended = quotationDetails?.find(
      (quotationDetail: Record<string, string | boolean | number>) =>
        quotationDetail?.[NONPOFiledIds.QuotationDetail_MarkAsRecommended] === true
    );

    setIsAnyQuotationDetailMarkAsRecommended(!!quotationMarkedAsRecommended);
  }, [form, isExpense]);

  const getIdFromLookUps = useCallback(
    (nonPoFieldId: NONPOFiledIds, key: string): number | null => {
      if (!dataLookUpResponse) {
        return null;
      }
      let nonPoLookUpItems: NONPOLookupItem[] = [];
      switch (nonPoFieldId) {
        case NONPOFiledIds.RequestDetail_ExpenseType:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.EXPENSE_TYPE;
          break;
        case NONPOFiledIds.RequestDetail_InvoiceType:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.INVOICE_TYPE;
          break;
        case NONPOFiledIds.RequestDetail_UtilityType:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.UTILITY_TYPE;
          break;
        case NONPOFiledIds.RequestDetail_AlreadyPaid:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.ALREADY_PAID;
          break;
        case NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.PAYMENT_OUTSIDE_SAUDI_ARABIA;
          break;
        case NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.SERVICE_OUTSIDE_SAUDI_ARABIA;
          break;
        case NONPOFiledIds.RequestDetail_ServiceType:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.SERVICE_TYPE;
          break;
        case NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.WHT_PERCENTAGE;
          break;
        case NONPOFiledIds.BillDetails_IncludesWithHoldingTax:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.INVOICE_AMOUNT_INCLUDE_WITHOLDING_TAX;
          break;
        case NONPOFiledIds.BillDetails_VatPercentage:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.VAT_PERCENTAGE;
          break;
        case NONPOFiledIds.PaymentDetail_PaymentMethod:
          nonPoLookUpItems = dataLookUpResponse?.lookups?.PAYMENT_METHOD;
          break;
        default:
          break;
      }

      if (nonPoFieldId === NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage) {
        const value = nonPoLookUpItems.find((nonPoLookUpItem) => nonPoLookUpItem.value === key)?.id;
        return value ? Number(value) : null;
      }
      const value = nonPoLookUpItems.find((nonPoLookUpItem) => nonPoLookUpItem.key === key)?.id;
      return value ? Number(value) : null;
    },
    [dataLookUpResponse]
  );

  const calculateWitholdingTax = useCallback(
    (
      formListIndex: number,
      expenseTypeKey: string,
      SelectedAmount: number,
      withholdingTaxPercentage: string
    ) => {
      const holdingTaxAmount = (Number(withholdingTaxPercentage) * SelectedAmount) / 100;
      form.setFields([
        {
          name: [
            NONPOFiledIds.BillDetails_BillDetails,
            formListIndex,
            NONPOFiledIds.BillDetails_WithHoldingTaxAmount,
          ],
          value: holdingTaxAmount,
          errors: [],
        },
      ]);
    },
    [form]
  );

  const witholdingTaxDebounce = useDebounce(currentWitholdingTaxRequestObject, 500);

  useEffect(
    () => {
      if (witholdingTaxDebounce) {
        calculateWitholdingTax(
          witholdingTaxDebounce.formListIndex,
          witholdingTaxDebounce.expenseTypeKey,
          witholdingTaxDebounce.SelectedAmount,
          witholdingTaxDebounce.withholdingTaxPercentage
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [witholdingTaxDebounce]
  );

  const getSupplierDataFromLookUps = useCallback(
    (key: string): NonPoInvoiceSuppliersResponse => {
      if (mutateNonPoInvoiceSuppliers?.data) {
        const data:
          | NonPoInvoiceSuppliersResponse
          | undefined = mutateNonPoInvoiceSuppliers?.data?.find(
          ({ supplierCode }) => supplierCode === key
        );
        return {
          supplierCode: data?.supplierCode ?? '',
          supplierName: data?.supplierName ?? '',
          supplierSite: data?.supplierSite ?? '',
        };
      }
      return { supplierCode: '', supplierName: '', supplierSite: '' };
    },
    [mutateNonPoInvoiceSuppliers?.data]
  );

  const commonPayloadRequestDetailAndPaymentDetail = useCallback(
    (variables): NonPoSubmitCommon => {
      const selectedSupplier = getSupplierDataFromLookUps(
        variables[`${NONPOFiledIds.RequestDetail_SupplierName}`]
      );
      return {
        accountNumber: variables[`${NONPOFiledIds.PaymentDetail_AccountNumber}`],
        alreadyPaidId: getIdFromLookUps(
          NONPOFiledIds.RequestDetail_AlreadyPaid,
          variables[`${NONPOFiledIds.RequestDetail_AlreadyPaid}`]
        ),
        currencyId: variables[`${NONPOFiledIds.RequestDetail_Currency}`],
        department: variables[`${NONPOFiledIds.RequestDetail_Department}`],
        description: variables[`${NONPOFiledIds.RequestDetail_Description}`],
        eventDate: variables[`${NONPOFiledIds.RequestDetail_EventDate}`],
        expenseTypeId: getIdFromLookUps(
          NONPOFiledIds.RequestDetail_ExpenseType,
          variables[`${NONPOFiledIds.RequestDetail_ExpenseType}`]
        ),
        invoiceTypeId: getIdFromLookUps(
          NONPOFiledIds.RequestDetail_InvoiceType,
          variables[`${NONPOFiledIds.RequestDetail_InvoiceType}`]
        ),
        others: variables[`${NONPOFiledIds.RequestDetail_OtherUtilityType}`],
        paymentMethodId: getIdFromLookUps(
          NONPOFiledIds.PaymentDetail_PaymentMethod,
          variables[`${NONPOFiledIds.PaymentDetail_PaymentMethod}`]
        ),
        paymentOutsideSaId: getIdFromLookUps(
          NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia,
          variables[`${NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia}`]
        ),
        sadadAccountNumber: variables[`${NONPOFiledIds.PaymentDetail_NewAccountNumber}`],
        sadadBillerCode: variables[`${NONPOFiledIds.PaymentDetail_SadaBillerCode}`],
        serviceOutsideSaId: getIdFromLookUps(
          NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia,
          variables[`${NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia}`]
        ),
        serviceTypeId: getIdFromLookUps(
          NONPOFiledIds.RequestDetail_ServiceType,
          variables[`${NONPOFiledIds.RequestDetail_ServiceType}`]
        ),
        supplierName: selectedSupplier.supplierName,
        miscellaneousSupplierName:
          variables[`${NONPOFiledIds.RequestDetail_Miscellaneous_SupplierName}`],
        supplierNumber: selectedSupplier.supplierCode,
        supplierSiteId: selectedSupplier.supplierSite,
        totalAmount: variables[`${NONPOFiledIds.RequestDetail_TotalAmount}`],
        utilityTypeid: getIdFromLookUps(
          NONPOFiledIds.RequestDetail_UtilityType,
          variables[`${NONPOFiledIds.RequestDetail_UtilityType}`]
        ),
        whtPercentageId: getIdFromLookUps(
          NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage,
          variables[`${NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage}`]
        ),
      };
    },
    [getIdFromLookUps, getSupplierDataFromLookUps]
  );

  const commonPayloadUpdateRequestDetailAndPaymentDetail = useCallback(
    (variables): NonPoUpdateRequest => {
      return {
        id: EditRequestData?.id as number,
        accountNumber: variables[`${NONPOFiledIds.PaymentDetail_AccountNumber}`] as number,
        alreadyPaidId: getIdFromLookUps(
          NONPOFiledIds.RequestDetail_AlreadyPaid,
          variables[`${NONPOFiledIds.RequestDetail_AlreadyPaid}`]
        ) as number,
        currencyId: variables[`${NONPOFiledIds.RequestDetail_Currency}`],
        description: variables[`${NONPOFiledIds.RequestDetail_Description}`],
        eventDate: variables[`${NONPOFiledIds.RequestDetail_EventDate}`],
        others: variables[`${NONPOFiledIds.RequestDetail_OtherUtilityType}`] as string,
        paymentMethodId: getIdFromLookUps(
          NONPOFiledIds.PaymentDetail_PaymentMethod,
          variables[`${NONPOFiledIds.PaymentDetail_PaymentMethod}`]
        ) as number,
        sadadAccountNumber: variables[`${NONPOFiledIds.PaymentDetail_NewAccountNumber}`] as number,
        sadadBillerCode: variables[`${NONPOFiledIds.PaymentDetail_SadaBillerCode}`] as number,
        serviceTypeId: getIdFromLookUps(
          NONPOFiledIds.RequestDetail_ServiceType,
          variables[`${NONPOFiledIds.RequestDetail_ServiceType}`]
        ) as number,
        miscellaneousSupplierName: variables[
          `${NONPOFiledIds.RequestDetail_Miscellaneous_SupplierName}`
        ] as string,
        totalAmount: variables[`${NONPOFiledIds.RequestDetail_TotalAmount}`],
        utilityTypeId: getIdFromLookUps(
          NONPOFiledIds.RequestDetail_UtilityType,
          variables[`${NONPOFiledIds.RequestDetail_UtilityType}`]
        ) as number,
        whtPercentageId: getIdFromLookUps(
          NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage,
          variables[`${NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage}`]
        ) as number,
      };
    },
    [getIdFromLookUps, EditRequestData]
  );

  const getBillingDetailsPayload = useCallback(
    (variables) => {
      const payloadNonPoSubmitBillDetail: NonPoSubmitBillDetail[] = variables[
        `${NONPOFiledIds.BillDetails_BillDetails}`
      ]?.map((billDetail: Record<string, string | boolean | number>) => {
        /**
         * Bill details
         */
        const nonPoSubmitBillDetail: NonPoSubmitBillDetail = {
          billConversionRate: billDetail[`${NONPOFiledIds.BillDetails_ConversionRate}`] as number,
          invoiceAmount: billDetail[`${NONPOFiledIds.BillDetails_InvoiceAmount}`] as number,
          invoiceDate: billDetail[`${NONPOFiledIds.BillDetails_InvoiceDate}`] as string,
          invoiceIncludeWHTId: getIdFromLookUps(
            NONPOFiledIds.BillDetails_IncludesWithHoldingTax,
            billDetail[`${NONPOFiledIds.BillDetails_IncludesWithHoldingTax}`] as string
          ) as number,
          invoiceNumber: billDetail[`${NONPOFiledIds.BillDetails_InvoiceNumber}`] as string,
          invoicePriceSar: billDetail[`${NONPOFiledIds.BillDetails_InvoiceAmountInSAR}`] as number,
          invoiceReason: billDetail[`${NONPOFiledIds.BillDetails_InvoiceReason}`] as string,
          vatAmount: isVatVisible
            ? (billDetail[`${NONPOFiledIds.BillDetails_VatAmount}`] as number)
            : null,
          vatPercentageId: isVatVisible
            ? (getIdFromLookUps(
                NONPOFiledIds.BillDetails_VatPercentage,
                billDetail[`${NONPOFiledIds.BillDetails_VatPercentage}`] as string
              ) as number)
            : null,
          whtAmount: isWthVisible
            ? (billDetail[`${NONPOFiledIds.BillDetails_WithHoldingTaxAmount}`] as number)
            : null,
          whtRate: 0,
          whtRateCode: '',
        };
        return nonPoSubmitBillDetail;
      });
      return payloadNonPoSubmitBillDetail;
    },
    [getIdFromLookUps, isVatVisible, isWthVisible]
  );

  const getQuotationDetailPayload = useCallback(
    (variables) => {
      const payloadQuotationDetails: NonPoSubmitQuotationDetail[] = variables[
        `${NONPOFiledIds.QuotationDetail_QuotationDetails}`
      ]?.map((quotationDetail: Record<string, string | boolean | number>) => {
        /**
         * Bill details
         */
        const selectedSupplier = getSupplierDataFromLookUps(
          quotationDetail[`${NONPOFiledIds.QuotationDetail_SupplierName}`] as string
        );
        const nonPoQuotationDetail: NonPoSubmitQuotationDetail = {
          advanceAmountToBePaid: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_AdvanceAmountToBePaid}`
          ] as number,
          advancePaymentPercentage: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_AdvancePayment}`
          ] as number,
          currencyId: quotationDetail[`${NONPOFiledIds.QuotationDetail_Currency}`] as number,
          justification: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_Justification}`
          ] as string,
          quotationConversionRate: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_ConversionRate}`
          ] as number,
          quotationDescription: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_Description}`
          ] as string,
          quotedPrice: quotationDetail[`${NONPOFiledIds.QuotationDetail_QuotedPrice}`] as number,
          quotedPriceSar: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_QuotedPriceSAR}`
          ] as number,
          recommended: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_MarkAsRecommended}`
          ] as boolean,
          quotationMiscellaneousSupplierName: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_Miscellaneous_SupplierName}`
          ] as string,
          quotationSupplierName: selectedSupplier.supplierName,
          supplierNumber: selectedSupplier.supplierCode,
          supplierSiteId: selectedSupplier.supplierSite,
        };
        return nonPoQuotationDetail;
      });
      return payloadQuotationDetails;
    },
    [getSupplierDataFromLookUps]
  );

  const updateBillingDetailsPayload = useCallback(
    (
      variables,
      deletedAttachments: {
        [key: number]: EditTaskAttachment[];
      }
    ) => {
      const payloadNonPoSubmitBillDetail: NonPoEditBillRequestDto[] = variables[
        `${NONPOFiledIds.BillDetails_BillDetails}`
      ]?.map((billDetail: Record<string, string | boolean | number>, index: number) => {
        /**
         * Bill details
         */
        const billId = billDetail[`${NONPOFiledIds.BillDetails_BillId}`] as number;
        const billDeletedAttachments = deletedAttachments[billId] ?? [];
        const nonPoSubmitBillDetail: NonPoEditBillRequestDto = {
          id: EditRequestData?.nonPoEditBillDetailsDto?.[index].id as number,
          billConversionRate: billDetail[`${NONPOFiledIds.BillDetails_ConversionRate}`] as number,
          invoiceAmount: billDetail[`${NONPOFiledIds.BillDetails_InvoiceAmount}`] as number,
          invoiceDate: billDetail[`${NONPOFiledIds.BillDetails_InvoiceDate}`] as string,
          invoiceIncludeWHTId: getIdFromLookUps(
            NONPOFiledIds.BillDetails_IncludesWithHoldingTax,
            billDetail[`${NONPOFiledIds.BillDetails_IncludesWithHoldingTax}`] as string
          ) as number,
          invoiceNumber: billDetail[`${NONPOFiledIds.BillDetails_InvoiceNumber}`] as string,
          invoicePriceSar: billDetail[`${NONPOFiledIds.BillDetails_InvoiceAmountInSAR}`] as number,
          invoiceReason: billDetail[`${NONPOFiledIds.BillDetails_InvoiceReason}`] as string,
          vatAmount: billDetail[`${NONPOFiledIds.BillDetails_VatAmount}`] as number,
          vatPercentageId: getIdFromLookUps(
            NONPOFiledIds.BillDetails_VatPercentage,
            billDetail[`${NONPOFiledIds.BillDetails_VatPercentage}`] as string
          ) as number,
          whtAmount: billDetail[`${NONPOFiledIds.BillDetails_WithHoldingTaxAmount}`] as number,
          whtRate: billDetail[`${NONPOFiledIds.BillDetails_WithHoldingRate}`] as number,
          whtRateCode: billDetail[`${NONPOFiledIds.BillDetails_WithHoldingRateCode}`] as number,
          deletedAttachment: billDeletedAttachments.map((attachment) => attachment.id),
        };
        return nonPoSubmitBillDetail;
      });
      return payloadNonPoSubmitBillDetail;
    },
    [getIdFromLookUps, EditRequestData]
  );

  const updateQuotationDetailPayload = useCallback(
    (
      variables,
      deletedAttachments: {
        [key: number]: EditTaskAttachment[];
      }
    ) => {
      const payloadQuotationDetails: NonPoEditQuotationRequestDto[] = variables[
        `${NONPOFiledIds.QuotationDetail_QuotationDetails}`
      ]?.map((quotationDetail: Record<string, string | boolean | number>, index: number) => {
        /**
         * Bill details
         */
        const quotationId = quotationDetail[
          `${NONPOFiledIds.QuotationDetail_QuotationId}`
        ] as number;
        const quotationDeletedAttachments = deletedAttachments[quotationId] ?? [];
        const nonPoQuotationDetail: NonPoEditQuotationRequestDto = {
          id: quotationId,
          advanceAmountToBePaid: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_AdvanceAmountToBePaid}`
          ] as number,
          advancePaymentPercentage: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_AdvancePayment}`
          ] as number,
          currencyId: quotationDetail[`${NONPOFiledIds.QuotationDetail_Currency}`] as number,
          justification: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_Justification}`
          ] as string,
          quotationConversionRate: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_ConversionRate}`
          ] as number,
          quotationDescription: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_Description}`
          ] as string,
          quotedPrice: quotationDetail[`${NONPOFiledIds.QuotationDetail_QuotedPrice}`] as number,
          quotedPriceSar: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_QuotedPriceSAR}`
          ] as number,
          recommended: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_MarkAsRecommended}`
          ] as boolean,
          quotationMiscellaneousSupplierName: quotationDetail[
            `${NONPOFiledIds.QuotationDetail_Miscellaneous_SupplierName}`
          ] as string,
          deletedAttachment: quotationDeletedAttachments.map((attachment) => attachment.id),
        };
        return nonPoQuotationDetail;
      });
      return payloadQuotationDetails;
    },
    []
  );

  const submitExpense = useCallback(
    (variables) => {
      /**
       * Linked advanced payments id
       */
      const payloadAdvanceLinkIds: Array<number> =
        variables[`${NONPOFiledIds.RequestDetail_ERPInvoice}`] ?? [];

      /**
       * Final expense payload
       */
      const payload: NonPoSubmitExpense = {
        advanceLinkIds: payloadAdvanceLinkIds,
        nonPoBillDetails: getBillingDetailsPayload(variables),
        ...commonPayloadRequestDetailAndPaymentDetail(variables),
      };

      /**
       * Call to submit api
       */
      nonPoInvoiceSubmitExpenseApiMutate(payload)
        .then((responseNonPoInvoice) => {
          const attachments: AttachmentFile[] = [];
          variables[`${NONPOFiledIds.BillDetails_BillDetails}`]?.forEach(
            (bill: Record<string, unknown>, index: number) => {
              const attachment = bill[
                `${NONPOFiledIds.BillDetails_AttachmentList}`
              ] as UploadFile[];
              if (attachment && responseNonPoInvoice.billId?.[index]) {
                attachments.push({ [responseNonPoInvoice.billId?.[index]]: attachment });
              }
            }
          );
          const attachmentSubmitInfo: SubmitNewRequestForm = {
            taskId: responseNonPoInvoice.taskId,
            processDefinitionId: responseNonPoInvoice.processDefinitionId,
            processInstanceId: responseNonPoInvoice.processInstanceId,
            activityId: responseNonPoInvoice.activityId,
            itemsId: responseNonPoInvoice.billId,
          };
          submitAttachmentForm(
            true,
            attachmentSubmitInfo,
            history,
            false,
            undefined,
            undefined,
            attachments,
            true
          );
        })
        .catch(() => {
          notification.error({
            message: t('messages.error.wentWrong'),
            description: t('messages.error.tryAgainLater'),
          });
        });
    },
    [
      commonPayloadRequestDetailAndPaymentDetail,
      getBillingDetailsPayload,
      nonPoInvoiceSubmitExpenseApiMutate,
      t,
      history,
    ]
  );

  const updateExpense = useCallback(
    (variables) => {
      /**
       * Linked advanced payments id
       */
      const payloadAdvanceLinkIds: Array<number> =
        variables[`${NONPOFiledIds.RequestDetail_ERPInvoice}`] ?? [];

      /**
       * Final expense payload
       */
      const attachments: AttachmentFile[] = [];
      const deletedAttachments: { [key: number]: EditTaskAttachment[] } = {};
      variables[`${NONPOFiledIds.BillDetails_BillDetails}`]?.forEach(
        (bill: Record<string, unknown>, index: number) => {
          const billId = bill[NONPOFiledIds.BillDetails_BillId];
          const billAttachments = bill[
            `${NONPOFiledIds.BillDetails_AttachmentList}`
          ] as UploadFile[];
          const previousRequestBillAttachments: EditTaskAttachment[] =
            EditRequestData?.nonPoEditBillDetailsDto?.[index]?.billTaskAttachment ?? [];
          const toBeUploadedAttachments = billAttachments.filter(
            (attachment) => !('originalObject' in attachment && attachment['originalObject'])
          );
          const previousAttachments: EditTaskAttachment[] = billAttachments
            .filter((attachment) => 'originalObject' in attachment && attachment['originalObject'])
            .map((attachment) => (attachment as never)['originalObject'] as EditTaskAttachment);
          if (billId) {
            if (toBeUploadedAttachments?.length > 0) {
              attachments.push({
                [billId as number]: toBeUploadedAttachments,
              });
            }
            const billDeletedAttachments = previousRequestBillAttachments.filter(
              (o1) => !previousAttachments.some((o2) => o1.id === o2.id)
            );
            if (billDeletedAttachments.length > 0) {
              deletedAttachments[billId as number] = billDeletedAttachments;
            }
          }
        }
      );
      const deletedBills: number[] =
        EditRequestData?.nonPoEditBillDetailsDto
          ?.filter(
            (o1) =>
              !variables[NONPOFiledIds.BillDetails_BillDetails].some(
                (o2: Record<string, unknown>) => o1.id === o2['id']
              )
          )
          .map((quotation) => quotation.id as number) ?? [];
      const payload: NonPoUpdateRequestExpenseParams = {
        taskId: taskId,
        processInstanceId: processId,
        requestData: {
          nonPoEditBillRequestDto: updateBillingDetailsPayload(variables, deletedAttachments),
          ...commonPayloadUpdateRequestDetailAndPaymentDetail(variables),
          advanceLinkIds: payloadAdvanceLinkIds,
          deletedBills: deletedBills,
        },
      };

      /**
       * Call to submit api
       */
      NonPoInvoiceUpdateExpenseApiMutate(payload)
        .then((responseNonPoInvoice) => {
          const attachmentSubmitInfo: SubmitNewRequestForm = {
            taskId: EditRequestData?.formTaskId as string,
            processDefinitionId: EditRequestData?.processDefinitionId as string,
            processInstanceId: EditRequestData?.processInstanceId as string,
            activityId: EditRequestData?.activityId as string,
            itemsId: responseNonPoInvoice.billId,
          };
          submitAttachmentForm(
            true,
            attachmentSubmitInfo,
            history,
            false,
            undefined,
            undefined,
            attachments,
            true
          );
        })
        .catch(() => {
          notification.error({
            message: t('messages.error.wentWrong'),
            description: t('messages.error.tryAgainLater'),
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      commonPayloadRequestDetailAndPaymentDetail,
      getBillingDetailsPayload,
      nonPoInvoiceSubmitExpenseApiMutate,
      deletedQuotationIDs,
      EditRequestData,
      t,
      history,
    ]
  );

  const updateAdvance = useCallback(
    (variables) => {
      const attachments: AttachmentFile[] = [];
      const deletedAttachments: { [key: number]: EditTaskAttachment[] } = {};
      variables[`${NONPOFiledIds.QuotationDetail_QuotationDetails}`]?.forEach(
        (quotation: Record<string, unknown>, index: number) => {
          const quotationId = quotation[NONPOFiledIds.QuotationDetail_QuotationId];
          const quotationAttachments = quotation[
            `${NONPOFiledIds.QuotationDetail_AttachmentList}`
          ] as UploadFile[];
          const previousRequestQuotationAttachments: EditTaskAttachment[] =
            EditRequestData?.nonPoQuotationDetailsDto?.[index]?.quotationTaskAttachment ?? [];
          const toBeUploadedAttachments = quotationAttachments.filter(
            (attachment) => !('originalObject' in attachment && attachment['originalObject'])
          );
          const previousAttachments: EditTaskAttachment[] = quotationAttachments
            .filter((attachment) => 'originalObject' in attachment && attachment['originalObject'])
            .map((attachment) => (attachment as never)['originalObject'] as EditTaskAttachment);
          if (quotationId) {
            if (toBeUploadedAttachments?.length > 0) {
              attachments.push({
                [quotationId as number]: toBeUploadedAttachments,
              });
            }
            const quotationDeletedAttachments = previousRequestQuotationAttachments.filter(
              (o1) => !previousAttachments.some((o2) => o1.id === o2.id)
            );
            if (quotationDeletedAttachments.length > 0) {
              deletedAttachments[quotationId as number] = quotationDeletedAttachments;
            }
          }
        }
      );
      const deletedQuotations: number[] =
        EditRequestData?.nonPoQuotationDetailsDto
          ?.filter(
            (o1) =>
              !variables[NONPOFiledIds.QuotationDetail_QuotationDetails].some(
                (o2: Record<string, unknown>) => o1.id === o2['id']
              )
          )
          .map((quotation) => quotation.id as number) ?? [];
      const nonPoUpdateAdvance: NonPoUpdateRequestAdvanceParams = {
        taskId: taskId,
        processInstanceId: processId,
        requestData: {
          nonPoEditQuotationRequestDto: updateQuotationDetailPayload(variables, deletedAttachments),
          ...commonPayloadUpdateRequestDetailAndPaymentDetail(variables),
          deletedQuotation: deletedQuotations,
        },
      };

      /**
       * Call to submit api
       */
      NonPoInvoiceUpdateAdvanceApiMutate(nonPoUpdateAdvance)
        .then((responseNonPoInvoice) => {
          const attachmentSubmitInfo: SubmitNewRequestForm = {
            taskId: EditRequestData?.formTaskId as string,
            processDefinitionId: EditRequestData?.processDefinitionId as string,
            processInstanceId: EditRequestData?.processInstanceId as string,
            activityId: EditRequestData?.activityId as string,
            itemsId: responseNonPoInvoice.quotationId,
          };

          submitAttachmentForm(
            true,
            attachmentSubmitInfo,
            history,
            false,
            undefined,
            undefined,
            attachments,
            true
          );
        })
        .catch((e) => {
          notification.error({
            message: t('messages.error.wentWrong'),
            description: t('messages.error.tryAgainLater'),
          });
        });
    },
    [
      taskId,
      processId,
      updateQuotationDetailPayload,
      commonPayloadUpdateRequestDetailAndPaymentDetail,
      NonPoInvoiceUpdateAdvanceApiMutate,
      EditRequestData,
      history,
      t,
    ]
  );

  const submitAdvance = useCallback(
    (variables) => {
      const nonPoSubmitAdvance: NonPoSubmitAdvance = {
        nonPoQuotationDetails: getQuotationDetailPayload(variables),
        ...commonPayloadRequestDetailAndPaymentDetail(variables),
      };
      /**
       * Call to submit api
       */
      nonPoInvoiceSubmitAdvanceApiMutate(nonPoSubmitAdvance)
        .then((responseNonPoInvoice) => {
          const attachments: AttachmentFile[] = [];
          variables[`${NONPOFiledIds.QuotationDetail_QuotationDetails}`]?.forEach(
            (quotation: Record<string, unknown>, index: number) => {
              const attachment = quotation[
                `${NONPOFiledIds.QuotationDetail_AttachmentList}`
              ] as UploadFile[];
              if (attachment && responseNonPoInvoice.quotationId?.[index]) {
                attachments.push({ [responseNonPoInvoice.quotationId?.[index]]: attachment });
              }
            }
          );
          const attachmentSubmitInfo: SubmitNewRequestForm = {
            taskId: responseNonPoInvoice.taskId,
            processDefinitionId: responseNonPoInvoice.processDefinitionId,
            processInstanceId: responseNonPoInvoice.processInstanceId,
            activityId: responseNonPoInvoice.activityId,
            itemsId: responseNonPoInvoice.quotationId,
          };
          submitAttachmentForm(
            true,
            attachmentSubmitInfo,
            history,
            false,
            undefined,
            undefined,
            attachments,
            true
          );
        })
        .catch((e) => {
          notification.error({
            message: t('messages.error.wentWrong'),
            description: t('messages.error.tryAgainLater'),
          });
        });
    },
    [
      commonPayloadRequestDetailAndPaymentDetail,
      getQuotationDetailPayload,
      nonPoInvoiceSubmitAdvanceApiMutate,
      t,
      history,
    ]
  );

  const isAdvanceRequestSelected = useCallback((variables) => {
    const selectedErpInvoiceIds = variables[NONPOFiledIds.RequestDetail_ERPInvoice];
    return !!selectedErpInvoiceIds;
  }, []);

  const getTotalPaidAmountInAdvanceRequest = useCallback(
    (variables): number => {
      const erpInvoiceData = mutateNonPoInvoiceERPInvoice.data;
      const selectedErpInvoiceIds = variables[NONPOFiledIds.RequestDetail_ERPInvoice];
      if (selectedErpInvoiceIds) {
        return (
          selectedErpInvoiceIds
            ?.toString()
            ?.split(',')
            ?.map((erpInvoiceId: string) => {
              return erpInvoiceData?.find(
                (erpInvoice) => erpInvoice.id.toString() === erpInvoiceId
              );
            })
            ?.reduce((accumulator: number, currentValue: NonPoInvoiceAdvanceRequestResponse) => {
              if (isNaN(currentValue?.totalAmountInSAR)) {
                return accumulator;
              }
              return accumulator + currentValue?.totalAmountInSAR;
            }, 0) ?? 0
        );
      }
      return 0;
    },
    [mutateNonPoInvoiceERPInvoice.data]
  );

  const getTotalAmountSAR = useCallback((variables): number => {
    const businessDetails = variables[NONPOFiledIds.BillDetails_BillDetails];
    return (
      businessDetails?.reduce(
        (accumulator: number, currentValue: { invoiceAmountInSAR: string }) => {
          return accumulator + Number(currentValue['invoiceAmountInSAR']);
        },
        0
      ) ?? 0
    );
  }, []);

  const validateSARInvoice = useCallback(
    (variables) => {
      if (isExpense && isAdvanceRequestSelected(variables)) {
        const totalPaidAmount = getTotalPaidAmountInAdvanceRequest(variables);
        const totalAmountSAR = getTotalAmountSAR(variables);
        return totalPaidAmount >= totalAmountSAR;
      }
      return true;
    },
    [isExpense, getTotalPaidAmountInAdvanceRequest, getTotalAmountSAR, isAdvanceRequestSelected]
  );

  const onSubmitForm = useCallback(
    (variables) => {
      if (!validateQuotationDetailForMarkAsRecommended(variables)) {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: t('messages.error.selectAnyMarkAsRecommended'),
        });
        return;
      }
      if (!validateSARInvoice(variables)) {
        form.setFields([
          {
            name: NONPOFiledIds.RequestDetail_TotalAmount,
            errors: [t('nonPo.error.expense.amount')],
          },
        ]);
        return;
      }

      if (isEdit) {
        if (isExpense) {
          updateExpense(variables);
        } else {
          updateAdvance(variables);
        }
      } else {
        if (isExpense) {
          submitExpense(variables);
        } else {
          submitAdvance(variables);
        }
      }
    },
    [
      form,
      validateSARInvoice,
      isEdit,
      isExpense,
      submitAdvance,
      submitExpense,
      t,
      updateAdvance,
      updateExpense,
      validateQuotationDetailForMarkAsRecommended,
    ]
  );

  const spinning = useMemo(() => {
    return (
      isNonPoInvoiceSubmitExpenseApiLoading ||
      isNonPoInvoiceSubmitAdvanceApiLoading ||
      isNonPoInvoiceUpdateAdvanceApiLoading ||
      isLoadingGetEditRequest ||
      isLoadingLookUpList
    );
  }, [
    isNonPoInvoiceSubmitAdvanceApiLoading,
    isNonPoInvoiceSubmitExpenseApiLoading,
    isNonPoInvoiceUpdateAdvanceApiLoading,
    isLoadingGetEditRequest,
    isLoadingLookUpList,
  ]);

  const resetFormFields = useCallback(
    (expenseTypeValue: string) => {
      form.resetFields([
        NONPOFiledIds.QuotationDetail_QuotationDetails,
        NONPOFiledIds.BillDetails_BillDetails,
        NONPOFiledIds.RequestDetail_InvoiceType,
        NONPOFiledIds.RequestDetail_UtilityType,
        NONPOFiledIds.RequestDetail_OtherUtilityType,
        NONPOFiledIds.RequestDetail_Department,
        NONPOFiledIds.RequestDetail_SupplierName,
        NONPOFiledIds.RequestDetail_Miscellaneous_SupplierName,
        NONPOFiledIds.RequestDetail_ERPInvoice,
        NONPOFiledIds.RequestDetail_Currency,
        NONPOFiledIds.RequestDetail_TotalAmount,
        NONPOFiledIds.RequestDetail_AlreadyPaid,
        NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia,
        NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia,
        NONPOFiledIds.RequestDetail_ServiceType,
        NONPOFiledIds.RequestDetail_EventDate,
        NONPOFiledIds.RequestDetail_Description,
        NONPOFiledIds.PaymentDetail_PaymentMethod,
        NONPOFiledIds.PaymentDetail_SadaBillerCode,
        NONPOFiledIds.PaymentDetail_PaymentMethod,
        NONPOFiledIds.PaymentDetail_AccountNumber,
        NONPOFiledIds.PaymentDetail_NewAccountNumber,
      ]);

      form.setFieldsValue({ [`${NONPOFiledIds.BillDetails_BillDetails}`]: [{}] });

      /**
       * Set least one item after reset
       */
      form.setFieldsValue({
        [`${NONPOFiledIds.QuotationDetail_QuotationDetails}`]: [
          {
            [`${NONPOFiledIds.QuotationDetail_AttachmentList}`]: undefined,
            [`${NONPOFiledIds.QuotationDetail_SupplierName}`]: undefined,
            [`${NONPOFiledIds.QuotationDetail_Currency}`]: undefined,
            [`${NONPOFiledIds.QuotationDetail_QuotedPrice}`]: undefined,
            [`${NONPOFiledIds.QuotationDetail_ConversionRate}`]: undefined,
            [`${NONPOFiledIds.QuotationDetail_QuotedPriceSAR}`]: undefined,
            [`${NONPOFiledIds.QuotationDetail_AdvancePayment}`]: undefined,
            [`${NONPOFiledIds.QuotationDetail_AdvanceAmountToBePaid}`]: undefined,
            [`${NONPOFiledIds.QuotationDetail_Description}`]: undefined,
            [`${NONPOFiledIds.QuotationDetail_MarkAsRecommended}`]: undefined,
            [`${NONPOFiledIds.QuotationDetail_Justification}`]: undefined,
          },
        ],
      });

      setInvoiceTypeValue('');
      setUtilityTypeValue('');
      setPaymentOutsideSAValue('');
      setServiceOutsideSAValue('');
      setPaymentMethodValue('');
      setSupplierNameSearchString('');
      setErpInvoiceSearchString('');
      setSupplierNameValue('');
      setRecommendedQuotation(undefined);
      setRequestDetailSupplierName('');
      setRequestDetailSupplierLabel('');
      setQuotationDetailSuppliers(undefined);
      form.setFieldsValue({ [`${NONPOFiledIds.RequestDetail_Department}`]: userData?.department });
    },
    [form, userData?.department]
  );

  const onFieldsChange = useCallback(
    (event) => {
      const expenseTypeValueInScope = form.getFieldValue(NONPOFiledIds.RequestDetail_ExpenseType);
      const requestDetailWithholdingTaxPercentage = form.getFieldValue(
        NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage
      );

      if (event?.[0].name?.[0] === NONPOFiledIds.RequestDetail_ExpenseType) {
        if (expenseTypeValueInScope !== expenseTypeValue) {
          resetFormFields(expenseTypeValueInScope);
        }
      }

      const utilityTypeValue = form.getFieldValue(NONPOFiledIds.RequestDetail_UtilityType);
      const invoiceTypeValue = form.getFieldValue(NONPOFiledIds.RequestDetail_InvoiceType);
      const paymentOutsideSAValue = form.getFieldValue(
        NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia
      );
      const serviceOutsideSAValue = form.getFieldValue(
        NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia
      );
      const paymentMethodValue = form.getFieldValue(NONPOFiledIds.PaymentDetail_PaymentMethod);
      const supplierName = form.getFieldValue(NONPOFiledIds.QuotationDetail_SupplierName);
      const supplierNameRequestDetail = form.getFieldValue(
        NONPOFiledIds.RequestDetail_SupplierName
      );

      /**
       * When supplier name changed
       * then set supplier label to state
       * to manage Miscellaneous logic in
       * request detail form
       */
      if (event?.[0].name?.[0] === NONPOFiledIds.RequestDetail_SupplierName) {
        const labelSupplierNameRequestDetail = supplierNameRequestDetail
          ? supplierOptions
              ?.find((option) => option.value === supplierNameRequestDetail)
              ?.label?.trim() ?? ''
          : '';
        setRequestDetailSupplierLabel(labelSupplierNameRequestDetail);
      }

      if (isExpense) {
        //Check
        if (
          event?.[0].name?.[0] === NONPOFiledIds.BillDetails_BillDetails ||
          event?.[0].name?.[0] === NONPOFiledIds.RequestDetail_Currency ||
          event?.[0].name?.[0] === NONPOFiledIds.RequestDetail_ExpenseType ||
          event?.[0].name?.[0] === NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia ||
          event?.[0].name?.[0] === NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia
        ) {
          const [, formListIndex, changedField] = event?.[0].name;

          if (
            changedField === NONPOFiledIds.BillDetails_InvoiceAmount ||
            changedField === NONPOFiledIds.BillDetails_VatPercentage ||
            event?.[0].name?.[0] === NONPOFiledIds.RequestDetail_Currency ||
            event?.[0].name?.[0] === NONPOFiledIds.RequestDetail_ExpenseType ||
            event?.[0].name?.[0] ===
              NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia ||
            event?.[0].name?.[0] === NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia
          ) {
            const expensesTypeId = form.getFieldValue(NONPOFiledIds.RequestDetail_ExpenseType);
            const amount = form.getFieldValue([
              NONPOFiledIds.BillDetails_BillDetails,
              formListIndex,
              NONPOFiledIds.BillDetails_InvoiceAmount,
            ]);
            const Currency = form.getFieldValue(NONPOFiledIds.RequestDetail_Currency);

            if (isVatVisible) {
              const vatChoice = form.getFieldValue([
                NONPOFiledIds.BillDetails_BillDetails,
                formListIndex,
                NONPOFiledIds.BillDetails_VatPercentage,
              ]);
              if (amount !== undefined && vatChoice !== undefined) {
                if (vatChoice === 'ZERO') {
                  form.setFields([
                    {
                      name: [
                        NONPOFiledIds.BillDetails_BillDetails,
                        formListIndex,
                        NONPOFiledIds.BillDetails_VatAmount,
                      ],
                      value: 0,
                      errors: [],
                    },
                  ]);
                } else if (vatChoice === 'FIFTEEN') {
                  const totalVat = amount * 0.15;
                  form.setFields([
                    {
                      name: [
                        NONPOFiledIds.BillDetails_BillDetails,
                        formListIndex,
                        NONPOFiledIds.BillDetails_VatAmount,
                      ],
                      value: Number.isInteger(totalVat) ? totalVat : totalVat.toFixed(3),
                      errors: [],
                    },
                  ]);
                }
              }
            }

            if (
              expenseTypeValueInScope !== undefined &&
              amount !== undefined &&
              requestDetailWithholdingTaxPercentage !== undefined
            ) {
              if (amount !== '') {
                setCurrentWitholdingTaxRequestObject({
                  formListIndex,
                  expenseTypeKey: expensesTypeId,
                  SelectedAmount: amount,
                  withholdingTaxPercentage: requestDetailWithholdingTaxPercentage,
                });
              }
            }
            if (Currency !== undefined && amount !== undefined) {
              setCurrentConversionRateRequestObject({
                formListIndex,
                SelectedcurrencyId: Currency,
                SelectedAmount: amount,
              });
            }

            if (event?.[0].name?.[0] === NONPOFiledIds.RequestDetail_Currency) {
              calculateConversionRateOfAllBillDetails();
            }
          }
        }
      } else {
        if (event?.[0].name?.[0] === NONPOFiledIds.QuotationDetail_QuotationDetails) {
          const [, formListIndex, changedField] = event?.[0].name;

          if (
            changedField === NONPOFiledIds.QuotationDetail_Currency ||
            changedField === NONPOFiledIds.QuotationDetail_QuotedPrice
          ) {
            const currencyId = form.getFieldValue([
              NONPOFiledIds.QuotationDetail_QuotationDetails,
              formListIndex,
              NONPOFiledIds.QuotationDetail_Currency,
            ]);
            const amount = form.getFieldValue([
              NONPOFiledIds.QuotationDetail_QuotationDetails,
              formListIndex,
              NONPOFiledIds.QuotationDetail_QuotedPrice,
            ]);
            if (currencyId !== undefined && amount !== undefined) {
              setCurrentConversionRateRequestObject({
                formListIndex,
                SelectedcurrencyId: currencyId,
                SelectedAmount: amount,
              });
            }
          }

          if (
            changedField === NONPOFiledIds.QuotationDetail_Currency ||
            changedField === NONPOFiledIds.QuotationDetail_QuotedPrice ||
            changedField === NONPOFiledIds.QuotationDetail_AdvancePayment
          ) {
            const quotationPrice = form.getFieldValue([
              NONPOFiledIds.QuotationDetail_QuotationDetails,
              formListIndex,
              NONPOFiledIds.QuotationDetail_QuotedPrice,
            ]);
            const advancePaymentPercentage = form.getFieldValue([
              NONPOFiledIds.QuotationDetail_QuotationDetails,
              formListIndex,
              NONPOFiledIds.QuotationDetail_AdvancePayment,
            ]);

            if (quotationPrice && advancePaymentPercentage) {
              const percentage = (quotationPrice * advancePaymentPercentage) / 100;
              form.setFields([
                {
                  name: [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    formListIndex,
                    NONPOFiledIds.QuotationDetail_AdvanceAmountToBePaid,
                  ],
                  value: Number.isInteger(percentage) ? percentage : percentage.toFixed(3),
                },
              ]);
            }
          }

          /***
           * When supplier nad changed inside Quotation details
           * this code of lines will cache the selected
           * option in order to manage
           * Miscellaneous supplier name field
           */
          if (changedField === NONPOFiledIds.QuotationDetail_SupplierName) {
            const nameSupplierName = [
              NONPOFiledIds.QuotationDetail_QuotationDetails,
              formListIndex,
              NONPOFiledIds.QuotationDetail_SupplierName,
            ];
            const supplierName = form?.getFieldValue(nameSupplierName);
            const supplierOption = supplierOptions.find((option) => option.value === supplierName);
            if (supplierOption) {
              let mQuotationSuppliers: IQuotationDetailSupplierStateType = {};
              if (quotationDetailSuppliers) {
                mQuotationSuppliers = { ...quotationDetailSuppliers };
              }
              mQuotationSuppliers[formListIndex] = supplierOption;
              setQuotationDetailSuppliers(mQuotationSuppliers);
            }
          }
        }
      }

      setExpenseTypeValue(expenseTypeValueInScope);
      setUtilityTypeValue(utilityTypeValue ?? '');
      setInvoiceTypeValue(invoiceTypeValue);
      setPaymentOutsideSAValue(paymentOutsideSAValue);
      setServiceOutsideSAValue(serviceOutsideSAValue);
      setPaymentMethodValue(paymentMethodValue);
      setSupplierNameValue(supplierName);
      setRequestDetailSupplierName(supplierNameRequestDetail);

      if (event?.[0].name?.[0] === NONPOFiledIds.RequestDetail_SupplierName) {
        getAdvanceRequests(supplierName);
      }

      if (isExpense) {
        calculateTotalAmountBasedBillDetail();
      } else {
        calculateTotalAmountBasedOnQuotationDetail();
      }

      resetMarkAsRecommended(event);
      checkIsAnyQuotationDetailMarkAsRecommended();
    },
    [
      calculateConversionRateOfAllBillDetails,
      calculateTotalAmountBasedBillDetail,
      calculateTotalAmountBasedOnQuotationDetail,
      checkIsAnyQuotationDetailMarkAsRecommended,
      expenseTypeValue,
      form,
      getAdvanceRequests,
      isExpense,
      isVatVisible,
      quotationDetailSuppliers,
      resetFormFields,
      resetMarkAsRecommended,
      supplierOptions,
    ]
  );

  const updateDeletedQuotationIds = useCallback(
    (fieldIndex: number) => {
      if (isExpense) {
        const billId = EditRequestData?.nonPoEditBillDetailsDto?.[fieldIndex]?.id;
        const billDeletedIdsUpdated = [...deletedBillsIDs];
        billDeletedIdsUpdated.push(Number(billId));
        setDeletedBillsIDs(billDeletedIdsUpdated);
      } else {
        const quotationId = EditRequestData?.nonPoQuotationDetailsDto?.[fieldIndex]?.id;
        const quotationDeletedIdsUpdated = [...deletedQuotationIDs];
        quotationDeletedIdsUpdated.push(Number(quotationId));
        setDeletedQuotationIDs(quotationDeletedIdsUpdated);
      }
    },
    [deletedQuotationIDs, EditRequestData, isExpense, deletedBillsIDs]
  );

  return (
    <NonPoRequestFormContext.Provider
      value={{
        expenseTypeValue,
        utilityTypeValue,
        invoiceTypeValue,
        paymentOutsideSAValue,
        serviceOutsideSAValue,
        isUtility,
        isEvent,
        isMiscellaneous,
        isExpense,
        isEdit,
        isVatVisible,
        isWthVisible,
        dataLookUpResponse,
        paymentMethodValue,
        isPaymentMethodCheck,
        userData,
        supplierOptions,
        searchSupplier: searchForSuppliers,
        isNonPoInvoiceSuppliersLoading: mutateNonPoInvoiceSuppliers?.isLoading ?? false,
        supplierNameSearchString: supplierNameSearchString,
        isPaymentMethodIBAN,
        isPaymentMethodSADAD: isPaymentMethodSADAD,
        isServiceTypeMandatory,
        erpInvoiceOptions,
        searchERPInvoice,
        isNonPoERPInvoiceLoading: mutateNonPoInvoiceERPInvoice?.isLoading ?? false,
        erpInvoiceSearchString,
        showERPInvoice,
        recommendedQuotation,
        isLoadingConversationRate: mutatuionConversionRate?.isLoading ?? false,
        isConversationRateSuccess: mutatuionConversionRate?.isSuccess ?? false,
        isConversationRateFailed: mutatuionConversionRate?.isError ?? false,
        isGovermentPayment,
        isAnyQuotationDetailMarkAsRecommended,
        requestDetailSupplierName,
        requestDetailSupplierLabel,
        quotationDetailSuppliers,
        updateDeletedQuotationIds: updateDeletedQuotationIds,
      }}
    >
      <React.Fragment>
        <Spin spinning={spinning} delay={100}>
          <StyledFormHeaderWrapper>
            <ProcessDescription
              showIcon={false}
              descriptionLabel={t('nonPo.request.description.label')}
              descriptionText={t('nonPo.request.description')}
              exampleText={''}
            />
          </StyledFormHeaderWrapper>
          <Form
            form={form}
            layout="vertical"
            name={formName}
            validateMessages={VALIDATE_MESSAGES(t)}
            onFieldsChange={onFieldsChange}
            onFinish={onSubmitForm}
          >
            <React.Fragment>
              <StyledRequestDetailsWraper>
                <RequestDetailsFormGroup form={form} t={t} />
              </StyledRequestDetailsWraper>
              {isExpense ? (
                <BillDetailsFormGroup form={form} hasInitialValues={isEdit} t={t} />
              ) : (
                <QuotationDetailsFormGroup form={form} hasInitialValues={isEdit} t={t} />
              )}

              <PreferredPaymentMethodFormGroup form={form} t={t} />
            </React.Fragment>
          </Form>
        </Spin>
      </React.Fragment>
    </NonPoRequestFormContext.Provider>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TaskData, RequestCommonError, RequestStatus } from 'types';
import { TaskState } from './taskTypes';

export const emptyTaskInitialState: TaskState = {};

export type GetTaskStart = PayloadAction<string>;
export type GetTaskSuccess = PayloadAction<{ processDefinitionKey: string; data: TaskData }>;
export type GetTaskFail = PayloadAction<{
  processDefinitionKey: string;
  error: RequestCommonError;
}>;

const task = createSlice({
  name: 'task',
  initialState: emptyTaskInitialState,
  reducers: {
    getTaskStart(state, action: GetTaskStart) {
      state[action.payload] = {
        ...state[action.payload],
        status: RequestStatus.pending,
      };
    },
    getTaskSuccess(state, action: GetTaskSuccess) {
      state[action.payload.processDefinitionKey].status = RequestStatus.resolved;
      state[action.payload.processDefinitionKey].data = action.payload.data;
    },
    getTaskFail(state, action: GetTaskFail) {
      state[action.payload.processDefinitionKey].status = RequestStatus.rejected;
      state[action.payload.processDefinitionKey].error = action.payload.error;
    },
  },
});

export const { getTaskStart, getTaskSuccess, getTaskFail } = task.actions;
export default task.reducer;

import { notification } from 'antd';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useTranslate } from 'translations';
import { LeaseSitesRequestAssignWoTaskData, LeaseSitesRequestAssignWoTaskFormValues } from 'types';
import { useErrorDescription, useGetAttachmentPromises } from '../../hooks';
import { leaseSites } from '../LeaseSites';

const mapToRequestData = (
  formData: LeaseSitesRequestAssignWoTaskFormValues,
  taskId: string
): LeaseSitesRequestAssignWoTaskData => {
  return {
    ...formData,
    assigneeType: {
      id: formData.assigneeType,
    },
    subContractor: formData.subContractor
      ? {
          id: formData.subContractor,
        }
      : undefined,
    taskId,
  };
};

export const useLeaseSitesRequestAssignWoTaskMutation = ({
  processInstanceId,
  taskId,
  activityId,
  processDefinitionId,
}: {
  taskId: string;
  processInstanceId: string;
  processDefinitionId: string;
  activityId: string;
}) => {
  const { t } = useTranslate();
  const errorDescription = useErrorDescription(t('messages.error.wentWrongReload'));

  const getAttachmentPromises = useGetAttachmentPromises();
  return useMutation(
    async (formValues: LeaseSitesRequestAssignWoTaskFormValues) => {
      await Promise.all([
        leaseSites.postRequestsAssignWoTask({
          processInstanceId,
          data: mapToRequestData(formValues, taskId),
        }),
        getAttachmentPromises({
          processInstanceId,
          taskId,
          activityId,
          processDefinitionId,
          attachment: {
            files: formValues?.attachment,
          },
        }),
      ]);
    },
    {
      onError: (error: AxiosError) => {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: errorDescription(error),
        });
      },
    }
  );
};

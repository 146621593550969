import { Core, ALL_ANNOUNCEMENT_CARDS, ANNOUNCEMENT_IMAGE } from 'api';
import { AnnouncementCardsListResponse } from 'types';

interface GetAnnouncementsList {
  (): Promise<{ data: AnnouncementCardsListResponse }>;
}

interface GetAnnouncementsImage {
  (imagePath: string): Promise<{ data: Blob }>;
}

class Announcements extends Core {
  getAnnouncementsList: GetAnnouncementsList = async () => this.get(ALL_ANNOUNCEMENT_CARDS());
  getAnnouncementsImage: GetAnnouncementsImage = async (imagePath) =>
    this.get(ANNOUNCEMENT_IMAGE(imagePath), {
      responseType: 'blob',
    });
}

export const announcements = new Announcements();

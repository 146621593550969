import { AttendanceInfoData } from 'types';

export interface MappedAttendanceInfoData {
  earlyCheckOutdDuration: string;
  lateCheckOutdDuration: string;
  isHoliday: boolean;
  holidayName: string | null;
  isWeekend: boolean;
  isAbsence: boolean;
  isOnLeave: boolean;
  currentDate: string | null;
  absenceName: string | null;
}

export const mapData = (data: AttendanceInfoData): MappedAttendanceInfoData => {
  const newData: MappedAttendanceInfoData = {
    ...data,
  };
  return newData;
};

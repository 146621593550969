import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';

import {
  GetFilesStart,
  GetFilesSuccess,
  GetFIlesFail,
  getFilesStart,
  getFilesSuccess,
  getFilesFail,
} from './oneDriveSearchSlic';

export const getSearchFilestEpic: Epic<
  GetFilesStart,
  GetFilesSuccess | GetFIlesFail,
  RootState,
  typeof API
> = (action$, _, { oneDriveSearch }) =>
  action$.pipe(
    filter(getFilesStart.match),
    mergeMap(({ payload }) =>
      from(oneDriveSearch.getOneDirveSearchFiles(payload)).pipe(
        map(({ data }) => getFilesSuccess(data)),
        catchError((error) => of(getFilesFail(error?.resonse?.data)))
      )
    )
  );

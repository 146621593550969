import React, { useRef, useState } from 'react';
import { Modal } from 'antd';
import { colors } from 'ui';
import {
  StyledCloseButton,
  StyledFooterImage,
  StyledFooterImageWrapper,
  StyledFullImage,
  StyledFullImageWrapper,
  StyledImageViewerContainer,
  StyledImageViewerContextContainer,
  StyledImageViewerControlBtnContainer,
  StyledImageViewerFooter,
  StyledImageViewerFooterWrapper,
  StyledImageViewerFullImageContainer,
  StyledImageViewerHeader,
  StyledLeftButton,
  StyledRightButton,
} from './AnnouncementImageViewer.styled';

export interface AnnouncementImageViewerProps {
  isModalVisible: boolean;
  onClose: () => void;
}

export const AnnouncementImageViewer: React.FC<AnnouncementImageViewerProps> = ({
  isModalVisible,
  onClose,
}: AnnouncementImageViewerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [images] = useState([
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
    'https://tawal.com.sa/resources/assets/images/homepage/eid.jpg',
  ]);

  const handleOnClosePress = () => {
    onClose();
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsMouseDown(true);
    setStartX(event.pageX - (containerRef.current?.offsetLeft || 0));
    setScrollLeft(containerRef.current?.scrollLeft || 0);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isMouseDown) return;
    event.preventDefault();
    const x = event.pageX - (containerRef.current?.offsetLeft || 0);
    const scrollX = x - startX;
    if (containerRef.current) {
      containerRef.current.scrollLeft = scrollLeft - scrollX;
    }
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleOnImageSelect = (index: number) => {
    setSelectedIndex(index);
    scrollToIndex(index);
  };

  const isLeftControllerVisible = () => {
    return selectedIndex > 0;
  };

  const isRightControllerVisible = () => {
    return selectedIndex < images.length - 1;
  };

  const handleOnLeftButtonClick = () => {
    const newIndex = selectedIndex - 1;
    setSelectedIndex(newIndex);
    scrollToIndex(newIndex);
  };

  const handleOnRightButtonLick = () => {
    const newIndex = selectedIndex + 1;
    setSelectedIndex(newIndex);
    scrollToIndex(newIndex);
  };

  const scrollToIndex = (index: number) => {
    const scrollView = containerRef.current;
    if (scrollView) {
      const element = scrollView.children[index] as HTMLElement;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  };

  return (
    <Modal
      width="100%"
      style={{ top: 0 }}
      visible={isModalVisible}
      getContainer={false}
      footer={null}
      closable={false}
      bodyStyle={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: '0',
        left: '0',
        overflow: 'auto',
        padding: '0',
      }}
      maskStyle={{ backgroundColor: colors.primaryColorWithOpacity }}
    >
      <StyledImageViewerContainer>
        <StyledImageViewerHeader>
          <StyledCloseButton onClick={handleOnClosePress} />
        </StyledImageViewerHeader>
        <StyledImageViewerContextContainer>
          <StyledImageViewerControlBtnContainer>
            {isLeftControllerVisible() && <StyledLeftButton onClick={handleOnLeftButtonClick} />}
          </StyledImageViewerControlBtnContainer>
          <StyledImageViewerFullImageContainer>
            <StyledFullImageWrapper>
              <StyledFullImage src={images[selectedIndex]} />
            </StyledFullImageWrapper>
            <StyledImageViewerFooter>
              <StyledFooterImageWrapper>
                <StyledImageViewerFooterWrapper
                  ref={containerRef}
                  isImageExceedLimit={images.length > 6}
                  className="horizontal-scroll-container"
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                >
                  {images.map((image, index) => (
                    <StyledFooterImage
                      onClick={() => handleOnImageSelect(index)}
                      isSelected={selectedIndex === index}
                      src={image}
                      key={index}
                    />
                  ))}
                </StyledImageViewerFooterWrapper>
              </StyledFooterImageWrapper>
            </StyledImageViewerFooter>
          </StyledImageViewerFullImageContainer>
          <StyledImageViewerControlBtnContainer>
            {isRightControllerVisible() && <StyledRightButton onClick={handleOnRightButtonLick} />}
          </StyledImageViewerControlBtnContainer>
        </StyledImageViewerContextContainer>
      </StyledImageViewerContainer>
    </Modal>
  );
};

import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { colors } from 'ui/elements';

const { Text } = Typography;
export const StyledPopOver = styled.div`
  width: auto;
`;
export const StyledCheckIcon = styled(CheckCircleTwoTone)`
  color: ${colors.nature};
`;

export const StyledReassignTitle = styled(Text)`
  color: ${colors.plumSecondary};
  font-size: 14px;
`;
export const StyledButton = styled(Button)`
  .ant-btn {
    padding: 5px;
  }
`;
export const StyledAssignTaskTitle = styled(Text)`
  font-weight: 600;
`;

export const StyledAssignTaskMessage = styled(Text)`
  color: ${colors.textColorSecondary};
  font-size: 15px;
`;

import styled, { css } from 'styled-components';
import { Button, Card } from 'antd';
import { colors, space } from 'ui/elements';
import { CalendarFilled, CalendarOutlined } from '@ant-design/icons';
import { ListActiveIcon, ListUnActiveIcon } from 'assets';

const iconCalendarStyles = css`
  width: 20px;
`;

const iconListStyles = css`
  height: 20px;
  vertical-align: top;
`;

export const PageHeader = styled(Card)`
  margin-top: -16px;
  box-sizing: border-box;
`;

export const StyledAddFilter = styled.div`
  margin-left: ${space.cozy};
`;

export const StyledButton = styled(Button)`
  margin-left: ${space.normal};
  background-color: ${colors.orangeShadow};
  border-color: ${colors.orangeShadow};

  &:hover,
  &:focus {
    background-color: ${colors.orange};
    border-color: ${colors.orange};
  }
  color: white;
`;

export const ButtonContent = styled.span`
  text-shadow: ${(props: { $active?: boolean }) =>
    props.$active ? '0 0 0.65px #333, 0 0 0.65px #333' : 'none'};
`;

export const ListActiveIconStyled = styled(ListActiveIcon)`
  ${iconListStyles}
`;
export const ListUnActiveIconStyled = styled(ListUnActiveIcon)`
  ${iconListStyles}
`;

export const CalendarFilledIconStyled = styled(CalendarFilled)`
  ${iconCalendarStyles}
`;

export const CalendarOutlinedIconStyled = styled(CalendarOutlined)`
  ${iconCalendarStyles}
`;

export const StyledLeavesContent = styled.div`
  display: flex;
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, HierarchyData } from 'types';
import { HierarchyState } from './hierarchyTypes';

export const emptyHierarchyInitialState: HierarchyState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetHierarchyStart = PayloadAction<string>;
export type GetHierarchySuccess = PayloadAction<HierarchyData[]>;
export type GetHierarchyFail = PayloadAction<RequestCommonError>;

const hierarchy = createSlice({
  name: 'hierarchy',
  initialState: emptyHierarchyInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getHierarchyStart(state, _: GetHierarchyStart) {
      state.status = RequestStatus.pending;
    },
    getHierarchySuccess(state, action: GetHierarchySuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getHierarchyFail(state, action: GetHierarchyFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const { getHierarchyStart, getHierarchySuccess, getHierarchyFail } = hierarchy.actions;
export default hierarchy.reducer;

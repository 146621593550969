import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetUserInfoCardFail,
  GetUserInfoCardStart,
  GetUserInfoCardSuccess,
  getUserInfoCardFail,
  getUserInfoCardStart,
  getUserInfoCardSuccess,
} from './userInfoCardSlice';

export const getUserInfoCardEpic: Epic<
  GetUserInfoCardStart,
  GetUserInfoCardSuccess | GetUserInfoCardFail,
  RootState,
  typeof API
> = (action$, _, { userInfoCard }) =>
  action$.pipe(
    filter(getUserInfoCardStart.match),
    exhaustMap(({ payload }) =>
      from(userInfoCard.getUserInfoCard(payload)).pipe(
        map(({ data }) =>
          getUserInfoCardSuccess({
            inputValue: Object.values(payload)[0],
            data,
          })
        ),
        catchError((error) =>
          of(
            getUserInfoCardFail({
              error: error?.response?.data,
              inputValue: Object.values(payload)[0],
            })
          )
        )
      )
    )
  );

import React, { FC } from 'react';
import { TType } from 'translations';
import { StyledCandidateCollapseHeaderTitleContainer } from '../SiteAcquisitionCompletedCandidatesTask.styled';

type Props = {
  candidateNumber: number;
  icon: React.ReactNode;
  t: TType;
};

export const SiteAcquisitionCompletedCandidateHeader: FC<Props> = ({
  candidateNumber,
  icon,
  t,
}) => {
  return (
    <StyledCandidateCollapseHeaderTitleContainer>
      {icon}
      <p>
        {t('lease.leaseSites.completedCandidateForm.candidate')} {candidateNumber}
      </p>
    </StyledCandidateCollapseHeaderTitleContainer>
  );
};

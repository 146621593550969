import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { processManagement } from '../ProcessManagement';

export function useAllMemberOfGroup(groupId: string) {
  return useQuery(
    [QUERIES.addMemberToGroup, groupId],
    async () => {
      const { data } = await processManagement.getAllMemberOfGroup(groupId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );
}

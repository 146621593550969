import { Col, Row, Typography } from 'antd';
import React, { useMemo } from 'react';
import { TType } from 'translations';
import { ExpenseItem } from 'types';
import styled from 'styled-components';
import { colors, typography } from 'ui/elements';
import moment from 'moment';

const BUSINESS_NAME = 'Business and Training Travel';
const CASH_ADVANCE = 'Cash Advance';
interface ExpenseContentDetailsProps {
  itemInfo: ExpenseItem;
  expenseType: string;
  t: TType;
}
const { Text } = Typography;

const StyledTravePlaces = styled(Text)`
  font-size: ${typography.size.giga};
  color: ${colors.primaryColor};
`;

const StyledSiteId = styled(Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.primaryColor};
`;

const StyledPaymentValueText = styled(Text)`
  font-size: ${typography.size.giga};
  font-weight: ${typography.weight.bold};
  color: ${colors.primaryColor};
`;

const ExpenseContentDetailComponent: React.FC<ExpenseContentDetailsProps> = ({
  itemInfo,
  expenseType,
  t,
}) => {
  const travelFromTo = useMemo(() => `${itemInfo.fromCity} ${t('to')} ${itemInfo.toCity}`, [
    itemInfo.fromCity,
    itemInfo.toCity,
    t,
  ]);

  const siteId = useMemo(() => `(${t('siteId')}: ${itemInfo.siteId})`, [itemInfo.siteId, t]);

  const paymentAmount = useMemo(
    () => `${itemInfo.amount} ${t('profile.expenses.drawer.defaultCurrencyCode')}`,
    [itemInfo.amount, t]
  );

  const invoiceDate = useMemo(
    () =>
      `${t('profile.expenses.drawer.invoiceDate')}: ${moment(itemInfo.invoiceDate).format(
        'DD/MM/YYYY'
      )}`,
    [itemInfo.invoiceDate, t]
  );

  return (
    <React.Fragment>
      {expenseType === BUSINESS_NAME ? (
        <>
          <Col span={24}>
            <Row gutter={[0, 5]} align={'middle'}>
              <Col>
                <StyledTravePlaces>{travelFromTo}</StyledTravePlaces>
              </Col>
              {itemInfo.siteId ? (
                <Col>
                  <StyledSiteId>{siteId}</StyledSiteId>
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col span={24}>
            <StyledSiteId>{itemInfo.businessDate}</StyledSiteId>
          </Col>
        </>
      ) : (
        <>
          <Col span={24}>
            <StyledPaymentValueText>{paymentAmount}</StyledPaymentValueText>
          </Col>
          {expenseType !== CASH_ADVANCE && (
            <Col span={24}>
              <Text type="secondary">{invoiceDate}</Text>
            </Col>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export const ExpenseContentDetail = React.memo(ExpenseContentDetailComponent);

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetMyTaskCardsFail,
  GetMyTaskCardsStart,
  GetMyTaskCardsSuccess,
  getMyTaskCardsFail,
  getMyTaskCardsStart,
  getMyTaskCardsSuccess,
} from './myTaskCardsSlice';

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

export const MyTaskCardsEpic: Epic<
  GetMyTaskCardsStart,
  GetMyTaskCardsSuccess | GetMyTaskCardsFail,
  RootState,
  typeof API
> = (action$, _, { servicesCards }) =>
  action$.pipe(
    filter(getMyTaskCardsStart.match),
    exhaustMap((action) =>
      from(
        disableAggregator
          ? servicesCards.getMyTaskCards(action.payload.userId, action.payload.params)
          : servicesCards.getMyTaskCardsAgg(action.payload.params)
      ).pipe(
        map(({ data }) => getMyTaskCardsSuccess(data)),
        catchError((error) => of(getMyTaskCardsFail(error?.response?.data)))
      )
    )
  );

import styled from 'styled-components';
import { Card, Spin } from 'antd';
import { borderRadius, colors, space } from 'ui/elements';
import { Loading3QuartersOutlined } from '@ant-design/icons';

export const SpinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 13%;
`;

export const SpinCard = styled(Card)`
  border-radius: ${borderRadius.large};
  padding: ${space.comfy};
  min-width: 300px;
  text-align: center;
`;

export const StyledSpin = styled(Spin)`
  .ant-spin-text {
    margin-top: ${space.cozy};
    font-weight: bold;
  }
`;

export const StyledLoadingOutlined = styled(Loading3QuartersOutlined)`
  font-size: 16px;
  &.anticon {
    color: ${colors.primaryColor};
  }
`;

import { CategoriesTreeData } from 'types';
import { Core, CATEGORIES_TREE, CATEGORIES_TREE_OLD } from 'api';

interface GetCategoriesTree {
  (): Promise<{ data: CategoriesTreeData }>;
}

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

class CategoriesTree extends Core {
  getCategoriesTree: GetCategoriesTree = async () =>
    this.get(disableAggregator ? CATEGORIES_TREE_OLD() : CATEGORIES_TREE());
}

export const categoriesTree = new CategoriesTree();

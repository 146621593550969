import React, { FC, memo } from 'react';
import { Breadcrumb as FileBreadcrumb, Button } from 'antd';
import { ConnectorProps, teamsFileListConnector } from '../TeamsChannelFilesList.connector';
import { BreadcrumbWrapper, StyleLink } from './Breadcrumb.styled';
import { folderType } from './Breadcrumb.types';

export interface Props {
  breadcrumbItems: folderType[];
  teamId: string;
  teamName?: string;
  channelId: string;
  changeFolderId: (id: string) => void;
  removerBreadcrumb: (index: number) => void;
}

export const BreadcrumbComponent: FC<Props & ConnectorProps> = ({
  breadcrumbItems,
  teamId,
  teamName,
  channelId,
  getTeamsChannelFilesStart,
  getTeamsChannelFolderStart,
  changeFolderId,
  removerBreadcrumb,
}) => {
  const onClickFile = () => {
    getTeamsChannelFilesStart({ channelId, teamId });
    removerBreadcrumb(-1);
  };
  const onClickFolder = (id: string, index: number) => {
    if (teamId && id) {
      getTeamsChannelFolderStart({ teamId, folderId: id });
      changeFolderId(id);
      removerBreadcrumb(index);
    }
  };

  return (
    <BreadcrumbWrapper>
      {breadcrumbItems.length ? (
        <FileBreadcrumb separator=">">
          <FileBreadcrumb.Item onClick={() => onClickFile()}>
            <StyleLink>{teamName}</StyleLink>
          </FileBreadcrumb.Item>

          {breadcrumbItems
            ? breadcrumbItems.map((folder, index) => (
                <FileBreadcrumb.Item key={folder.folderId}>
                  <Button type={'link'} onClick={() => onClickFolder(folder.folderId, index)}>
                    {folder.folderName}
                  </Button>
                </FileBreadcrumb.Item>
              ))
            : null}
        </FileBreadcrumb>
      ) : null}
    </BreadcrumbWrapper>
  );
};
export const Breadcrumb = teamsFileListConnector(memo(BreadcrumbComponent));

import styled from 'styled-components';
import { Button } from 'antd';
import { colors } from 'ui/elements';

export const popoverStyles = {
  width: '100%',
  maxWidth: '408px',
};

export const StyledButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 0;
`;

export const StyledPopoverTitle = styled.div`
  color: ${colors.textColorSecondary};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 12px;
`;

import styled from 'styled-components';
import { Button, DatePicker, Typography, Card, Row, Empty, Divider } from 'antd';
import { colors, TitleH3, typography } from 'ui/elements';
import { CalendarOutlined } from '@ant-design/icons';

const { Text } = Typography;

export const StyledPayrollInfoContainerCard = styled(Card)<{ $withTitle?: boolean }>`
  border: none;
  width: 100%;
  padding: 0;
  margin-bottom: ${({ $withTitle }) => ($withTitle ? '24px' : 0)};

  .ant-card-body {
    padding: 0;
  }
`;

export const StyledPayrollInfoSectionTitle = styled(TitleH3)`
  font-size: 18px !important;
  font-weight: 500 !important;
  color: ${colors.labelGrey} !important;
`;

export const StyledPayrollInfoDatePicker = styled(DatePicker)`
  border: none;
  margin-right: -15px;
  .ant-picker-input {
    border: none;
    color: ${colors.primaryColor};
  }
  .ant-picker-input > input {
    font-weight: 400;
  }
`;
export const StyledPayrollInfoCalenderIcon = styled(CalendarOutlined)`
  align-self: center;
  color: ${colors.primaryColor};
  line-height: 1;
  pointer-events: none;
  position: absolute;
  font-size: 20px;
  margin-right: 4px;
  left: -35px;
  top: 1px;
`;

export const StyledPayrollInfoWrapperRow = styled(Row)`
  width: 100%;
  justify-content: start;
`;

export const StyledPayrollInfoMonthCard = styled(Card)`
  border-radius: 4px;
  width: 210px;
  height: 130px;

  .ant-card-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
`;

export const StyledPayrollInfoCardValue = styled(Text)`
  font-size: 22px;
  font-weight: 400;
  color: ${colors.textColorSecondary};
`;

export const StyledPayrollInfoCardAmount = styled(Text)`
  font-size: 18px;
  font-weight: 400;
  color: ${colors.textColorSecondary};
`;

export const StyledPayrollInfoNoDataEmpty = styled(Empty)`
  width: 100%;
`;
export const StyledPayrollInfoButton = styled(Button)`
  font-weight: ${typography.weight.regular};
`;

export const StyledPayrollInfoDivider = styled(Divider)`
  margin: 12px 0;
`;

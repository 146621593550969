import React, { FC, memo, useCallback, useState } from 'react';
import { Col, notification } from 'antd';
import { DownloadAttachment, FullWidthSpin, useFullName, UserPopover } from 'ui';
import { camundaUsersDataSelector, taskCommentsDataSelector } from 'store';
import moment from 'moment';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { useSelector } from 'react-redux';
import { AttachmentMeta, RequestStatus } from 'types';
import { taskComments as taskCommentsApi } from 'api';
import { saveAs } from 'file-saver';
import { useTranslate } from 'translations';
import { useTasksContext } from 'routes';
import parse from 'html-react-parser';
import {
  StyleCommentContainerRow,
  StyledCommentBoxHeader,
  StyledCommentContainer,
  StyledCommentParentContainer,
  StyledCommentText,
  StyledCommentTimeText,
  StyledCommentUserNameText,
  StyledCommentUserPopoverContainer,
} from './Comment.styled';

export interface TaskCommentProps {
  id: string;
  message: string;
  userId: string;
  time: Date | string;
  attachments: AttachmentMeta[];
  isLastComment: boolean;
  isLastCommentLoading: boolean;
}

export const TaskCommentComponent: FC<TaskCommentProps> = ({
  id,
  message,
  userId,
  time,
  attachments,
  isLastComment,
  isLastCommentLoading,
}) => {
  const { t } = useTranslate();
  const camundaUsers = useSelector(camundaUsersDataSelector);
  const { taskAttachmentStatus } = useSelector(taskCommentsDataSelector);
  const { rootPath } = useTasksContext();

  const [isFileLoading, setIsFileLoading] = useState(false);

  const handleDownloadClick = useCallback(
    async (name, attachmentId) => {
      try {
        setIsFileLoading(true);
        const resp = await taskCommentsApi.downloadCommentAttachment(
          id,
          attachmentId,
          {
            responseType: 'blob',
          },
          rootPath
        );
        saveAs(resp?.data, name);
      } catch (e) {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: t('messages.error.tryAgainLater'),
        });
      } finally {
        setIsFileLoading(false);
      }
    },
    [id, rootPath, t]
  );

  const fullName = useFullName(
    camundaUsers[userId]?.data?.firstName,
    camundaUsers[userId]?.data?.lastName
  );

  if (isLastCommentLoading || (taskAttachmentStatus === RequestStatus.pending && isLastComment)) {
    return <FullWidthSpin />;
  }

  return (
    <StyleCommentContainerRow>
      <StyledCommentUserPopoverContainer>
        <UserPopover currentID={userId}>
          <MSTeamsUserAvatar id={userId} name={fullName} size={40} />
        </UserPopover>
      </StyledCommentUserPopoverContainer>

      <StyledCommentParentContainer>
        <StyledCommentContainer>
          <StyledCommentBoxHeader>
            <UserPopover currentID={userId}>
              <StyledCommentUserNameText>{fullName}</StyledCommentUserNameText>
            </UserPopover>
            <StyledCommentTimeText>{moment(time).format('DD.MM.YYYY')}</StyledCommentTimeText>
          </StyledCommentBoxHeader>
          <StyledCommentText type="secondary">{parse(message)}</StyledCommentText>
        </StyledCommentContainer>
        {attachments?.length ? (
          <Col>
            {attachments.map(({ name, id, mediaType }) => (
              <DownloadAttachment
                name={name}
                key={id}
                onDownloadClick={() => handleDownloadClick(name, id)}
                isLoading={isFileLoading}
                isComment={true}
              />
            ))}
          </Col>
        ) : null}
      </StyledCommentParentContainer>
    </StyleCommentContainerRow>
  );
};

export const TaskComment = memo(TaskCommentComponent);

import { Row, Col } from 'antd';
import styled from 'styled-components';

export const StyledLogoutRow = styled(Row)`
  cursor: pointer;
`;

export const StyledLogoutIconCol = styled(Col)`
  display: flex;
  align-items: center;
`;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const otherUserStatusData = (state: RootState): RootState['otherUserStatus'] =>
  state.otherUserStatus;

export const otherUserStatusSelectors = createSelector(
  otherUserStatusData,
  ({ error, status, data }) => ({
    error,
    status,
    data,
  })
);

import { UserActivityStatus } from 'types';

export const userInfo = {
  displayName: 'Ammar Ahmed Alsanani',
  adOid: '81354113-4ef6-46d6-b59f-ae29a3d77683',
  jobTitle: 'FullStack Developer',
  email: 'amahfouz.c@tawal.com',
  userType: 'Member',
  userPrincipalName: 'null',
  companyName: 'Tawal',
  department: 'IT',
  employeeId: '9999',
  status: 'Available' as UserActivityStatus,
};

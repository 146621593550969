import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const publicHolidays = (state: RootState): RootState['publicHolidays'] =>
  state.publicHolidays;

export const publicHolidaysSelector = createSelector(publicHolidays, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

import { PaySlipData, PayslipsPdf } from 'types';
import { Core, PAYSLIP, PAYSLIP_PDF_FILE } from 'api';
import { AxiosRequestConfig } from 'axios';

interface GetPaySlips {
  (userId: string | null, date: string, params?: AxiosRequestConfig['params']): Promise<{
    data: PaySlipData;
  }>;
}
interface GetPaySlipsPDFFile {
  (userId: string, month: string, year: string): Promise<{
    data: PayslipsPdf;
  }>;
}

class PaySlips extends Core {
  getPaySlips: GetPaySlips = async (userId, date, params) =>
    this.get(PAYSLIP(userId, date), { params });
  getPaySlipsPdfFile: GetPaySlipsPDFFile = async (userId, month, year) =>
    this.get(PAYSLIP_PDF_FILE(userId, month, year));
}

export const paySlips = new PaySlips();

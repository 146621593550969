import { Breadcrumb } from 'antd';
import styled from 'styled-components';
import { TextStyled } from 'ui/elements';

export const BreadCrumbText = styled(TextStyled).attrs({
  size: 18,
})`
  :hover {
    cursor: ${(props) => (props.strong ? 'default' : 'pointer')};
  }

  &.ant-typography {
    display: inline-flex;
  }
`;

export const OneDriveBreadCrumbStyled = styled(Breadcrumb)`
  margin: 24px 0;
  font-size: 18px;
  line-height: 1.2;
`;

import { AxiosRequestConfig } from 'axios';

import { TaskData } from 'types';
import { Core, HISTORIC_TASK } from 'api';

interface GetHistoricTaskList {
  (prams: AxiosRequestConfig['params']): Promise<{ data: TaskData }>;
}

class HistoricTask extends Core {
  getHistoricTaskList: GetHistoricTaskList = async (params) => this.get(HISTORIC_TASK(), params);
}
export const historicTask = new HistoricTask();

import React, { FC, useCallback, useState, useEffect, useMemo } from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { Col, Row, Space, Typography } from 'antd';
import { CommentOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { TaskStatus, FormField } from 'types';
import { useTranslate } from 'translations';
import { colors, FullWidthSpin, StatusTag, TagColor } from 'ui/elements';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { Forms } from 'ui/components/Requests/CreateRequestForm';
import { useTasksContext } from 'routes/Tasks/TasksProvider';
import { useGetRequestComments, UserPopover } from 'ui';
import { ReassignTask } from '../TaskSteps/ReassignTask';
import {
  CardTitle,
  StepTitle,
  StyledDivider,
  StyledCard,
  StyledReassignIcon,
  StyledRedText,
  StyledButton,
  StyledAssignedToCol,
  StyledTaskAvatar,
  EndEventText,
} from './TaskCard.styled';
import { TaskCardActionButton } from './TaskCardActionButton';
import { TaskCArdClaimTask } from './TaskCardClaimTask';

type FormData = FormField[];

interface TaskCardProps {
  processInstanceId: string;
  date: string;
  title?: string | null;
  description?: string | null;
  stepTitle: string | null;
  stepStatus: string;
  assignedTo?: string;
  assigneeId?: string;
  taskName: string;
  requestPrettyId?: string;
  mytask: boolean;
  userGroupId?: string;
  reLoadCallback?: () => void;
  isMyRequest: boolean;
  isProcessed?: boolean;
  formFields?: FormData | null;
  taskId?: string;
  endEvent?: string;
  setIsClickOnCard?: (value: boolean) => void;
  myGroupTask?: boolean;
  rootPath?: string;
}

const { Text } = Typography;

export const TaskCard: FC<TaskCardProps> = ({
  processInstanceId,
  date,
  title,
  description,
  stepTitle,
  stepStatus,
  assignedTo,
  taskName,
  assigneeId,
  mytask,
  userGroupId,
  reLoadCallback: reassignCallback,
  isMyRequest,
  isProcessed,
  requestPrettyId,
  formFields,
  taskId,
  myGroupTask,
  setIsClickOnCard,
  endEvent,
  rootPath,
}) => {
  const { t } = useTranslate();
  const { path } = useRouteMatch();
  const getUrl = useCallback((pagePath: string) => `${path}${pagePath}`, [path]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [allTasksFormInstances, setAllTasksFormInstances] = useState<Forms | null>(null);
  const { setCommentsRequestId } = useTasksContext();
  const onCommentsClick = useCallback(
    () => processInstanceId && setCommentsRequestId(processInstanceId),
    [processInstanceId, setCommentsRequestId]
  );

  const cardSelected = useCallback(
    () => {
      setIsOpen(true);
      if (setIsClickOnCard) {
        setIsClickOnCard(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { commentsCount } = useGetRequestComments(processInstanceId);

  const splitterPath = pathname.split('/');

  const id = splitterPath[splitterPath.length - 1];

  useEffect(() => {
    processInstanceId === id ? setIsOpen(true) : setIsOpen(false);
  }, [id, processInstanceId]);

  const statusColor = useMemo(
    () =>
      ({
        [TaskStatus.inProgress]: 'blue',
        [TaskStatus.processed]: 'green',
        [TaskStatus.pending]: 'orange',
      }[stepStatus]),
    [stepStatus]
  );

  return (
    <>
      {formLoading ? (
        <FullWidthSpin />
      ) : (
        <StyledCard $highlighted={isOpen}>
          <Link onClick={cardSelected} to={getUrl(`/${processInstanceId}`)}>
            <Row justify="space-between" gutter={[12, 12]}>
              <Col>
                <Text type="secondary">{date}</Text>
              </Col>
              <Col>
                <Text type="secondary" ellipsis={true} style={{ maxWidth: '100px' }}>
                  {requestPrettyId}
                </Text>
              </Col>
              <Col span={24}>
                <CardTitle>{title}</CardTitle>
              </Col>
              <Col span={24}>
                <Text
                  type="secondary"
                  ellipsis={true}
                  style={{ whiteSpace: 'nowrap', maxWidth: 'inherit' }}
                >
                  {description}
                </Text>
              </Col>
            </Row>

            <StyledDivider />

            <Row gutter={[12, 12]}>
              <Col>
                <Space align="baseline">
                  <ArrowRightOutlined />
                  <StepTitle>{stepTitle}</StepTitle>
                  <StatusTag color={statusColor as TagColor}>{stepStatus}</StatusTag>
                </Space>
              </Col>
              <Col span={24}>
                {assigneeId ? (
                  <Space align="baseline">
                    <UserPopover currentID={assigneeId}>
                      <StyledTaskAvatar
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <MSTeamsUserAvatar id={assigneeId} size={24} />
                      </StyledTaskAvatar>
                    </UserPopover>
                    <Text type="secondary">{t('task.assignedTo')}:</Text>
                    <Text strong>
                      <Row gutter={[6, 0]} wrap={false}>
                        <UserPopover currentID={assigneeId}>
                          <StyledAssignedToCol
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {assignedTo}
                          </StyledAssignedToCol>
                        </UserPopover>
                        <Col>
                          {!isMyRequest &&
                          !isProcessed &&
                          userGroupId &&
                          taskId &&
                          stepStatus !== TaskStatus.inProgress ? (
                            <ReassignTask
                              taskId={taskId}
                              groupId={userGroupId}
                              reassignCallback={reassignCallback}
                            >
                              <StyledButton>
                                <StyledReassignIcon />
                                {t('task.button.assign')}
                              </StyledButton>
                            </ReassignTask>
                          ) : null}
                        </Col>
                      </Row>
                    </Text>
                  </Space>
                ) : (
                  <Row justify="space-between" align="middle" gutter={[16, 0]}>
                    <Col>
                      {stepStatus !== TaskStatus.pending && (
                        <StyledRedText>{t('task.notAssigned')}</StyledRedText>
                      )}
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Link>
          <StyledDivider />
          <Row justify="space-between" align="middle">
            <Col>
              <Link onClick={onCommentsClick} to={getUrl(`/${processInstanceId}`)}>
                <Space>
                  <CommentOutlined style={{ color: colors.lightGrey }} />
                  <Text type="secondary">
                    {commentsCount} {t('card.comments')}
                  </Text>
                </Space>
              </Link>
            </Col>
            {!isMyRequest &&
              !isProcessed &&
              userGroupId &&
              stepStatus !== TaskStatus.inProgress && (
                <TaskCArdClaimTask
                  taskId={taskId}
                  userGroupId={userGroupId}
                  reassignCallback={reassignCallback}
                  t={t}
                />
              )}
            {!isProcessed && (mytask || (userGroupId && myGroupTask)) && formFields && (
              <TaskCardActionButton
                taskId={taskId}
                taskName={taskName}
                processInstanceId={processInstanceId}
                formFields={formFields}
                setFormLoading={setFormLoading}
                setAllTasksFormInstances={setAllTasksFormInstances}
                allTasksFormInstances={allTasksFormInstances}
                t={t}
                getUrl={getUrl}
                reassignCallback={reassignCallback}
                rootPath={rootPath}
              />
            )}
            {isProcessed && endEvent && (
              <Col>
                <EndEventText ellipsis>{endEvent}</EndEventText>
              </Col>
            )}
          </Row>
        </StyledCard>
      )}
    </>
  );
};

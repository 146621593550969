import React, { useEffect } from 'react';
import { useTranslate } from 'translations';
import { FullWidthSpin } from 'ui';
import { Divider } from 'antd';
import { UserTableUserInfo, UserTableUserProcesses } from 'types';
import { UserDrawerUserInfo } from './UserDrawerUserInfo/UserDrawerUserInfo';
import { UserDrawerUserProcesses } from './UserDrawerUserProcesses/UserDrawerUserProcesses';
import { UserDrawerUserInfoContainer, StyledDrawerUi } from './UserDrawer.styled';

interface Props {
  isVisible: boolean;
  selectedUser?: UserTableUserInfo;
  onClose: () => void;
}

const UserDrawerComponent: React.FC<Props> = ({ isVisible, onClose, selectedUser }) => {
  const { t } = useTranslate();
  const [userProcesses, setUserProcesses] = React.useState<UserTableUserProcesses | undefined>();
  useEffect(() => {
    if (isVisible === true) {
      //Todo
    }
  }, [isVisible]);
  return (
    <StyledDrawerUi
      visible={isVisible}
      drawerTitle={t('processManagement.users.drawer.title')}
      keyboard
      destroyOnClose
      onClose={onClose}
    >
      {selectedUser ? (
        <UserDrawerUserInfoContainer>
          <UserDrawerUserInfo userInfo={selectedUser} />
          {(!userProcesses ||
            (userProcesses &&
              ((!userProcesses.isManager && userProcesses.processes.length > 0) ||
                (userProcesses.isManager &&
                  userProcesses.isSelf &&
                  userProcesses.processes.length > 0) ||
                (userProcesses.isManager && !userProcesses.isSelf)))) && (
            <>
              <Divider />
              <UserDrawerUserProcesses
                userId={selectedUser.adOid}
                onProcessesLoad={(processes) => {
                  setUserProcesses(processes);
                }}
              />
            </>
          )}
        </UserDrawerUserInfoContainer>
      ) : (
        <FullWidthSpin />
      )}
    </StyledDrawerUi>
  );
};
export const UserDrawer = React.memo(UserDrawerComponent);

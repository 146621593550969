import { useMutation } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { taskComments } from '../../TaskComments';

export const useCreatNewComment = (taskId: string, rootPath?: string) => {
  return useMutation(async (commentData: AxiosRequestConfig['data']) => {
    const { data } = await taskComments.createTaskComment(taskId, commentData, rootPath);
    return data;
  });
};

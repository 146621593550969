import { AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';
import { jiraRequestsCenter } from '../Jira';

export const useJiraSubmitForm = (serviceDeskId: number, typeId: number) => {
  return useMutation(async (formData: AxiosRequestConfig['data']) => {
    const { data } = await jiraRequestsCenter.postJiraStartForm(serviceDeskId, typeId, formData);
    return data;
  });
};

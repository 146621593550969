import { AxiosRequestConfig } from 'axios';
import { AttachmentMeta, TaskCommentsData } from 'types';
import {
  Core,
  TASK_COMMENT_ATTACHMENT,
  TASK_COMMENT_ATTACHMENT_DOWNLOAD,
  TASK_COMMENT_CREATE,
  TASK_COMMENTS,
} from 'api';

interface GetTaskComments {
  (taskId: string): Promise<{ data: TaskCommentsData[] }>;
}

interface CreateTaskComment {
  (taskId: string, data: AxiosRequestConfig['data'], rootPath?: string): Promise<{
    data: TaskCommentsData;
  }>;
}

interface AddTaskCommentAttachment {
  (commentId: string, data: FormData, rootPath?: string): Promise<null>;
}

interface GetTaskCommentAttachment {
  (commentId: string, rootPath?: string): Promise<{ data: AttachmentMeta[] }>;
}

interface DownloadCommentAttachment {
  (
    commentId: string,
    attachmentId: string,
    params?: AxiosRequestConfig['params'],
    rootPath?: string
  ): Promise<{
    data: string;
  }>;
}

class TaskComments extends Core {
  getTaskComments: GetTaskComments = async (taskId) => this.get(TASK_COMMENTS(taskId));
  createTaskComment: CreateTaskComment = async (taskId, data, rootPath) =>
    this.post(TASK_COMMENT_CREATE(taskId, rootPath), data);
  addTaskCommentAttachment: AddTaskCommentAttachment = async (commentId, data, rootPath) =>
    this.post(TASK_COMMENT_ATTACHMENT(commentId, rootPath), data);
  getTaskCommentAttachment: GetTaskCommentAttachment = async (commentId, rootPath) =>
    this.get(TASK_COMMENT_ATTACHMENT(commentId, rootPath));
  downloadCommentAttachment: DownloadCommentAttachment = async (
    commentId,
    attachmentId,
    params,
    rootPath
  ) => this.get(TASK_COMMENT_ATTACHMENT_DOWNLOAD(commentId, attachmentId, rootPath), params);
}

export const taskComments = new TaskComments();

import React, { FC, useMemo } from 'react';
import { TType } from 'translations';
import { Form, Radio, Row, Space } from 'antd';
import { useGetFromFieldId } from 'ui/hooks';
import { FormField } from 'types';
import { StyledFieldLabel } from '../GeneratedForm.Styled';

interface Props {
  field: FormField;
  t: TType;
  withLabel?: boolean;
}

export const FieldGroupRadioButton: FC<Props> = ({ field, t, withLabel }) => {
  const rules = useMemo(
    () => [
      {
        required: field.validationConstraints.some((rule) => rule.name === 'required'),
        message: `${field.label} is required`,
      },
    ],
    [field.label, field.validationConstraints]
  );
  const options = useMemo(
    () =>
      field.type?.values
        ? Object.entries(field.type?.values).map(([value, label]) => ({ value, label }))
        : [],
    [field]
  );

  const getFieldId = useGetFromFieldId();
  const fieldId = getFieldId(field.id);

  return (
    <>
      {withLabel && (
        <Row>
          <StyledFieldLabel>{field.label}</StyledFieldLabel>
        </Row>
      )}
      <Form.Item id={fieldId} label={withLabel ? null : field.label} name={field.id} rules={rules}>
        <Radio.Group disabled={field.properties.viewOnly === 'true'}>
          <Space direction="vertical">
            {options.map((option, index) => (
              <Radio key={index} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

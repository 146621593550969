import {
  IProfileUpdateData,
  IProfileUpdateSubmitResponse,
  IProfileUpdateDataResponseLookup,
} from 'types';
import {
  Core,
  NEW_PROFILE_ALL_USER_DATA,
  SUBMIT_PROFILE_UPDATE_REQUEST,
  GET_PROFILE_UPDATE_REQUEST_LOOKUPS,
} from '../../Core';

interface GetProfileUpdateDate {
  (): Promise<{ data: IProfileUpdateData }>;
}

interface GetProfileUpdateLookups {
  (): Promise<{ data: IProfileUpdateDataResponseLookup }>;
}

interface SubmitProfileRequest {
  (body: IProfileUpdateData): Promise<{ data: IProfileUpdateSubmitResponse }>;
}

class ProfileUpdate extends Core {
  getProfileUpdateData: GetProfileUpdateDate = () => this.get(NEW_PROFILE_ALL_USER_DATA());
  getProfileUpdateLookups: GetProfileUpdateLookups = () =>
    this.get(GET_PROFILE_UPDATE_REQUEST_LOOKUPS());

  submitProfileRequest: SubmitProfileRequest = (body) =>
    this.post(SUBMIT_PROFILE_UPDATE_REQUEST(), body);
}

export const profileUpdate = new ProfileUpdate();

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RootState, getTeamsUserAvatarStart, teamsUserAvatarData } from 'store';
import { UserStatus } from 'types';

export interface TeamsUserAvatarProps {
  id?: string | null;
  name?: string | null;
  size?: number;
  statusSize?: number;
  status?: UserStatus | null;
  email?: string;
  styledAvatarName?: React.ReactNode;
  color?: string;
}

const mapDispatch = {
  getTeamsUserAvatarStart,
};

const mapState = (state: RootState, props: TeamsUserAvatarProps) => ({
  teamsUserAvatar: teamsUserAvatarData(state, props),
});

export const teamsUserAvatarConnector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof teamsUserAvatarConnector>;

export type ConnectorProps = PropsFromRedux;

import { Form, Divider } from 'antd';
import styled from 'styled-components';
import { colors, space, TitleH3, typography } from 'ui/elements';
import { CreateFormItemType } from 'types';

export const FormItemStyled = styled(Form.Item)<{ labelSize?: CreateFormItemType['labelSize'] }>`
  .ant-form-item-label > label {
    color: ${colors.textColorSecondary};
    font-size: ${({ labelSize }) =>
      labelSize === 'large' ? `${typography.size.giga}` : 'inherit'};
    color: ${({ labelSize }) => labelSize === 'large' && `${colors.lightGrey}`};
  }
`;

export const GroupTitle = styled(TitleH3).attrs({
  type: 'secondary',
})`
  margin-bottom: 12px !important;
`;

export const StyledDivider = styled(Divider)`
  &.ant-divider {
    margin-bottom: ${space.normal};
  }
`;

import styled from 'styled-components';
import { Button, Checkbox, Modal, Typography } from 'antd';
import { colors, TitleH3 } from 'ui/elements';
import { DownloadAttachment } from 'ui/components';

const { Paragraph } = Typography;

export const StyledEthicsCodeDialog = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;
    .ant-modal-body {
      padding: 24px;
    }
  }
`;
export const StyledEthicsCodeDialogContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;
export const StyledEthicsCodeDialogTitle = styled(TitleH3)`
  margin: 0;
  font-size: 20px !important;
  font-weight: 500 !important;
  color: ${colors.textColorSecondary} !important;
`;
export const StyledEthicsCodeDialogDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;
export const StyledEthicsCodeDialogDescription = styled(Paragraph)`
  margin-bottom: 0 !important;
  font-size: 15px;
  font-weight: 400;
  color: ${colors.textColorSecondary};
`;
export const StyledEthicsCodeDialogAttachmentContainer = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const StyledEthicsCodeDialogAttachment = styled(DownloadAttachment)`
  & > div.ant-row {
    margin: 0 !important;
    border-radius: 6px !important;
  }
`;
export const StyledEthicsCodeDialogFormContainer = styled.div``;
export const StyledEthicsCodeDialogFormCheckbox = styled(Checkbox)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${colors.accentOrange};
  .ant-checkbox {
    top: 4px !important;
    .ant-checkbox-inner {
      border-color: ${colors.accentOrange};
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${colors.accentOrange};
      }
    }
  }
`;
export const StyledEthicsCodeDialogFormSubmit = styled(Button)`
  color: ${colors.white};
  background-color: ${colors.orangeShadow};
  border-color: ${colors.orangeShadow};
  padding: 4px 25px;

  & > .anticon + span,
  & > span + .anticon {
    margin-left: 8px;
    margin-right: 8px;
  }

  &:hover,
  &:focus {
    color: ${colors.white};
    background-color: ${colors.orange};
    border-color: ${colors.orange};
  }
`;
export const StyledEthicsCodeDialogFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const StyledEthicsCodeDialogFooterNote = styled(Paragraph)`
  margin: 0 !important;
  font-size: 12px;
  font-weight: 400;
  color: ${colors.lightGrey};
`;
export const StyledEthicsCodeDialogFooterChangeLang = styled(Button)`
  font-size: 15px;
  font-weight: 500;
  color: ${colors.textColorSecondary};
`;

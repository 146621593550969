import { useMutation } from 'react-query';
import { UserTableUserProcesses } from 'types';
import { processManagement } from '../ProcessManagement';

export function useGetUserAssignedProcesses() {
  return useMutation<UserTableUserProcesses, unknown, string, unknown>(async (userId) => {
    const { data } = await processManagement.getUserAssignedProcesses(userId);
    return data;
  });
}

import React from 'react';

import { MSPlannerTabs } from '../MSPlannerTabs';

export const MSPlannerMyTasks = () => (
  <>
    <MSPlannerTabs />
    MSPlannerMyTasks
  </>
);

import React from 'react';
import { Drawer, Divider, Typography, Row, Button } from 'antd';
import styled from 'styled-components';
import { colors, space } from 'ui/elements';
import { MentionsInput } from 'react-mentions';
import { DeleteCross } from 'assets';

const { Title: AntTitle, Text } = Typography;

export type StyledAddCommentInputContainerType = {
  showShadow?: boolean;
  width?: string;
  bottom?: string;
};

export type StyledCommentsWrapperType = {
  isCommentInputVisible?: boolean;
};

export const StyledCommentsDrawer = styled(Drawer)`
  & .ant-drawer-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const StyledCommentsWrapper = styled.div<StyledCommentsWrapperType>`
  flex-grow: 1;
  width: 100%;
  padding-bottom: ${(props) => (props?.isCommentInputVisible ? '250px' : '80px')};
`;

export const StyledDivider = styled(Divider)`
  width: auto;
  margin: 0 -${space.normal} ${space.normal};
`;

export const StyledMentionsInputContainer = styled.div`
  min-height: 140px;
  border: 1px solid #dfe0eb;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  max-height: 140px;
  overflow-y: auto;
`;

export const StyledMentionsInput = styled(MentionsInput)`
  line-height: 1.2;
  padding: 16px;
  font-family: inherit;
  flex: 1px;

  strong {
    color: ${colors.mentionTextColor};
    position: relative;
    z-index: 1;
    font-family: inherit;
  }

  textarea {
    line-height: 1.2;
    font-family: inherit;
    border: 0 !important;
    outline: none;
    padding: 16px;
    overflow: hidden;
    color: ${colors.lightOverlayBlue};
    font-size: 14px !important;
  }

  textarea::placeholder {
    color: ${colors.lightActive};
  }
`;

export const StyledNoCommentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const StyledH3Title = styled(({ status, taskStatus, ...rest }) => (
  <AntTitle
    type={
      status === taskStatus.inProgress || status === taskStatus.pending ? 'primary' : 'secondary'
    }
    {...rest}
  />
)).attrs({
  level: 3,
})`
  &.ant-typography {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
  }
  &.ant-typography.ant-typography-secondary {
    color: #8b93a2;
    font-weight: normal;
  }
`;

export const StyledErrortext = styled.span`
  color: ${colors.attendanceWarning};
  margin-left: ${space.slim};
`;

export const StyledRow = styled(Row)`
  margin-bottom: ${space.zero};
`;

export const StyledAddCommentButton = styled(Button)`
  background-color: ${colors.accentOrange};
  border-color: ${colors.accentOrange};
  padding: 5px 16px;
  border-radius: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  align-self: flex-start;
  display: inline-block !important;

  &:hover,
  &:focus {
    background-color: ${colors.accentOrange};
    border-color: ${colors.accentOrange};
  }
`;

export const StyledAddAttachment = styled(Button)`
  border: none;
  padding: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  align-self: flex-start;
  display: inline-block !important;
  margin-left: 4px;

  &:hover,
  &:focus {
    background-color: ${colors.lightGreyFourth};
  }
`;

export const StyledCancelComment = styled(Button)`
  border-color: ${colors.borderGrey};
  padding: 5px 16px;
  border-radius: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  align-self: flex-start;
  display: inline-block !important;
`;

export const StyledAddCommentInputContainer = styled.div<StyledAddCommentInputContainerType>`
  position: fixed;
  bottom: ${(props) => (props?.bottom ? props.bottom : '0')};
  padding: 24px;
  background-color: white;
  margin-left: -24px;
  margin-right: -24px;
  width: ${(props) => (props?.width ? props.width : '560px')};
  box-shadow: ${(props) => (props?.showShadow ? '0px -5px 20px rgba(37, 55, 70, 0.14)' : '0px')};
`;

export const StyledResizerIconContainer = styled.div`
  position: absolute;
  bottom: -4px;
  right: 1.5px;
`;

export const StyledAttachmentListContainer = styled.div`
  bottom: 0;
`;

export const StyledUploadCommentAttachment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  background: ${colors.lightCyan};
  border-radius: 30px;
  margin-left: 16px;
  margin-bottom: 16px;
  max-width: 90%;
  overflow: auto;
`;

export const StyledUploadCommentAttachmentName = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${colors.skyLink};
  padding-left: 2px;
  padding-right: 2px;
`;

export const StyledDeleteAttachment = styled(DeleteCross)`
  width: 9px;
  height: 9px;
`;

export const StyledAddAttachmentFooter = styled(Row)`
  padding-top: 10px;
`;

export const StyledRequestNameForFailedComment = styled(Text)`
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.primaryColor};
  padding-bottom: 16px;
`;

export const StyledCommentFailedMessage = styled(Text)`
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.primaryColor};
`;

export const StyledCommentFailedComment = styled(Text)`
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.textColorSecondary};
  padding-top: 16px;
`;

export const StyledFailedCommentAttachment = styled(Text)`
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: ${colors.lightGrey};
`;

export const StyledCommentFailedRetryButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  gap: 8px;
  width: 65px;
  height: 32px;
  background: ${colors.accentOrange};
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;

  &:hover,
  &:focus {
    background-color: ${colors.accentOrange};
    border: none;
  }
`;

export const StyledCommentFailedRetryButtonText = styled(Text)`
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: ${colors.white};
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const StyledCommentFailedCancelButton = styled(Button)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  gap: 10px;
  width: 75px;
  height: 32px;
  background: ${colors.white};
  border: 1px solid ${colors.lightSilver};
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  flex: none;
  order: 1;
  flex-grow: 0;

  &:hover,
  &:focus {
    background-color: ${colors.white};
    border: none;
  }
`;

export const StyledCommentFailedCancelButtonText = styled(Text)`
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const StyledCommentFailedCommentButtonWrapper = styled(Row)`
  gap: 8px;
  margin-top: 12px;
`;

export const StyledCommentsTitle = styled(Text)`
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: ${colors.grey};
`;

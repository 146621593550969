import React from 'react';

import { MSPlannerTabs } from '../MSPlannerTabs';

export const MSPlannerProject = () => (
  <>
    <MSPlannerTabs />
    MSPlannerProject
  </>
);

import React from 'react';
import { useTranslate } from 'translations';
import { getUserProfileStart, useDispatch, userProfileData, useSelector } from 'store';
import { OtherUserProfileDate, RequestStatus } from 'types';
import { Empty, notification } from 'antd';
import { FullWidthSpin } from 'ui/elements';
import { PublicProfile } from 'ui/pages';
import { NoEmployeeSelected } from 'assets';
import {
  EmployeeDirectoryDetailsContainer,
  EmployeeDirectoryDetailsEmptyContainer,
} from './EmployeeDirectoryDetails.styled';

type Props = {
  email?: string;
};

export const EmployeeDirectoryDetails: React.FC<Props> = ({ email }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { data: userProfileInfo, status } = useSelector(userProfileData);
  const userProfile = userProfileInfo as OtherUserProfileDate;
  React.useEffect(() => {
    if (email) {
      dispatch(getUserProfileStart({ email }));
    }
  }, [dispatch, email]);
  React.useEffect(() => {
    if (status === RequestStatus.rejected) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [status, t]);
  return (
    <EmployeeDirectoryDetailsContainer
      className={
        status === RequestStatus.pending || status === RequestStatus.rejected ? 'is-loading' : ''
      }
    >
      {email ? (
        status === RequestStatus.resolved && userProfile ? (
          <PublicProfile userProfileData={userProfile} email={email ?? ''} />
        ) : status === RequestStatus.rejected ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <FullWidthSpin />
        )
      ) : (
        <EmployeeDirectoryDetailsEmptyContainer>
          <NoEmployeeSelected />
          <p>Click an employee card to view the details</p>
        </EmployeeDirectoryDetailsEmptyContainer>
      )}
    </EmployeeDirectoryDetailsContainer>
  );
};

import styled from 'styled-components';
import { Typography, Row } from 'antd';
import { colors, space, typography } from 'ui/elements';

const { Text } = Typography;

export const StyledLabel = styled(Text)`
  font-size: ${typography.size.giga};
  font-weight: ${typography.weight.regular};
  color: ${colors.lightGrey};
`;

export const StyledRow = styled(Row)`
  margin-bottom: ${space.moderate};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

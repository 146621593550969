import React, { FC, memo, useMemo } from 'react';
import styled from 'styled-components';
import { Col, DatePicker, Form, Input, Row, Select, Checkbox, InputNumber, Button } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { UploadOutlined } from '@ant-design/icons';
import { FORM_FILED_BREAKPOINT } from 'routes/Tasks/components/templates';
import { FormItemProps } from 'types/FormItem.type';
import { RestrictedUpload } from 'ui/components/RestrictedUpload';

const DEFAULT_ITEM_SPAN = 24;
const datePickerStyles = { width: '100%' };
const StyledInputNumber = styled(InputNumber)`
  &.ant-input-number {
    width: 100%;
  }
`;

export const DrawFromItemComponent: FC<FormItemProps> = ({
  items,
  span,
  itemSpan = DEFAULT_ITEM_SPAN,
  t,
}) => {
  const gutter: [Gutter, Gutter] = useMemo(() => [24, 0], []);

  const form = useMemo(
    () => (
      <Row gutter={gutter}>
        {items.map(
          ({
            id,
            type,
            label,
            name,
            options,
            disabled,
            disabledDate,
            field,
            rules,
            dependencies,
            valuePropName,
            wrapperCol,
            hasFeedback,
            placeholder,
            picker,
            formatter,
          }) => {
            let child = null;
            switch (type) {
              case 'date':
                child = (
                  <DatePicker
                    allowClear={false}
                    style={datePickerStyles}
                    disabled={disabled}
                    disabledDate={disabledDate}
                    picker={picker}
                  />
                );
                break;
              case 'input':
                child = <Input disabled={disabled} placeholder={placeholder} />;
                break;
              case 'inputNumber':
                child = (
                  <StyledInputNumber
                    min={1}
                    disabled={disabled}
                    placeholder={placeholder}
                    formatter={formatter}
                  />
                );
                break;
              case 'checkbox':
                child = <Checkbox disabled={disabled} />;
                break;
              case 'select':
                child = (
                  <Select
                    disabled={disabled}
                    placeholder={placeholder}
                    notFoundContent={t('dropdown.noMatching')}
                    showSearch
                  >
                    {options?.map(({ value, label }) => (
                      <Select.Option key={value} value={label}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                );
                break;
              case 'file':
                child = (
                  <RestrictedUpload key={id} listType="text" showUploadList={true} multiple={true}>
                    <Button icon={<UploadOutlined />}>{t('task.uploadFile')}</Button>
                  </RestrictedUpload>
                );
                break;
            }

            const props = {
              ...field,
              label,
              name,
              rules,
              dependencies,
              valuePropName,
              wrapperCol,
              hasFeedback,
            };

            return (
              <>
                {child ? (
                  type === 'file' ? (
                    <Col span={DEFAULT_ITEM_SPAN}>
                      <Form.Item {...props}>{child}</Form.Item>
                    </Col>
                  ) : (
                    <Col {...FORM_FILED_BREAKPOINT}>
                      <Form.Item {...props}>{child}</Form.Item>
                    </Col>
                  )
                ) : null}
              </>
            );
          }
        )}
      </Row>
    ),
    [gutter, items, t]
  );

  if (span) {
    return (
      <Row>
        <Col span={span}>{form}</Col>
      </Row>
    );
  }
  return form;
};

export const DrawFromItem = memo(DrawFromItemComponent);

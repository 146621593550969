import {
  Core,
  SELF_SERVICE_LETTER,
  SELF_SERVICE_LETTER_SUBMIT,
  BUSINESS_TRAVEL_FORM,
  BUSINESSTRAVEL_SUBMIT,
  CASH_ADVANCE_DETAIL,
  CASH_ADVANCE_SUBMIT,
  CREDIT_CARD_DETAIL,
  CREDIT_CARD_SUBMIT,
  EXPENSE_REPORT_DETAIL,
  EXPENSE_REPORT_SUBMIT,
  HOME_LOAN_SUBMIT,
  SCRAP_INVOICE_CUSTOMERS_NAME,
  SCRAP_INVOICE_FORM_DETAILS,
  SCRAP_INVOICE_SUBMIT,
  HOME_LOAN_INTEREST,
  DELEGATION_FORM_DETAILS,
  DELEGATION_FORM_SUBMIT,
  DELEGATOR_NAMES_LIST,
} from 'api';
import { AxiosPromise, AxiosRequestConfig } from 'axios';

import {
  BusinessTrainingTravelType,
  CashAdvanceData,
  CreditCardData,
  ExpenseReimbursementDetail,
  SelectOptions,
  SelfServiceLetterType,
  SubmitNewRequestForm,
  ScrapInvoiceRequestType,
  HomeLoanInterestDetail,
  DelegationRequestType,
  FilterDelegatorListType,
} from 'types';

interface GetSelfServiceLetter {
  (adoId: string): Promise<{ data: SelfServiceLetterType }>;
}
interface PostSubmitStaticForm {
  (data: AxiosRequestConfig['data'], adoId?: string): AxiosPromise<SubmitNewRequestForm>;
}

interface GetBusinessTravel {
  (adoId: string): Promise<{ data: BusinessTrainingTravelType }>;
}

interface GetCreditCardDetail {
  (adoId: string, processDefinitionKey: string): Promise<{ data: CreditCardData }>;
}

interface GetExpensesReimbursementDetail {
  (adoId: string): Promise<{ data: ExpenseReimbursementDetail }>;
}

interface GetCashAdvanceDetail {
  (): Promise<{ data: CashAdvanceData }>;
}

interface GetScrapInvoiceCustomersName {
  (params?: AxiosRequestConfig['params']): Promise<{ data: SelectOptions }>;
}

interface GetScrapInvoiceFormDetails {
  (): Promise<{ data: ScrapInvoiceRequestType }>;
}

interface GetHomeLoanInterest {
  (params?: AxiosRequestConfig['params']): Promise<{ data: HomeLoanInterestDetail }>;
}

interface GetDelegationFormDetails {
  (): Promise<{ data: DelegationRequestType }>;
}

interface GetDelegatorsNamesList {
  (): Promise<{ data: FilterDelegatorListType[] }>;
}

class StaticForm extends Core {
  getSelfServiceLetter: GetSelfServiceLetter = async (adoId) =>
    this.get(SELF_SERVICE_LETTER(adoId));

  postSelfServiceLetter: PostSubmitStaticForm = async (adoId, data) =>
    this.post(SELF_SERVICE_LETTER_SUBMIT(adoId), data);

  getBusinessTravel: GetBusinessTravel = async (adoId) => this.get(BUSINESS_TRAVEL_FORM(adoId));

  postBusinessTravel: PostSubmitStaticForm = async (data) =>
    this.post(BUSINESSTRAVEL_SUBMIT(), data);
  getCreditCardDetail: GetCreditCardDetail = async (adoId, processDefinitionKey) =>
    this.get(CREDIT_CARD_DETAIL(adoId, processDefinitionKey));
  postCreditCard: PostSubmitStaticForm = async (adoId, data) =>
    this.post(CREDIT_CARD_SUBMIT(adoId), data);
  getExpensesReimbursementDetail: GetExpensesReimbursementDetail = async (adoId) =>
    this.get(EXPENSE_REPORT_DETAIL(adoId));
  postExpenseReport: PostSubmitStaticForm = async (adoId, data) =>
    this.post(EXPENSE_REPORT_SUBMIT(adoId), data);
  postHomeLoan: PostSubmitStaticForm = async (adoId, data) =>
    this.post(HOME_LOAN_SUBMIT(adoId), data);
  getCashAdvanceDetail: GetCashAdvanceDetail = async () => this.get(CASH_ADVANCE_DETAIL());
  postCashAdvance: PostSubmitStaticForm = async (data) => this.post(CASH_ADVANCE_SUBMIT(), data);
  getScrapInvoiceCustomersNames: GetScrapInvoiceCustomersName = async (params) =>
    this.get(SCRAP_INVOICE_CUSTOMERS_NAME(), params);
  getScrapInvoiceFormDetails: GetScrapInvoiceFormDetails = async () =>
    this.get(SCRAP_INVOICE_FORM_DETAILS());
  postScrapInvoiceForm: PostSubmitStaticForm = async (data) =>
    this.post(SCRAP_INVOICE_SUBMIT(), data);
  getHomeLoanInterest: GetHomeLoanInterest = async (data) => this.get(HOME_LOAN_INTEREST(), data);
  getDelegationFormDetails: GetDelegationFormDetails = async () =>
    this.get(DELEGATION_FORM_DETAILS());
  postDelegationForm: PostSubmitStaticForm = async (data) =>
    this.post(DELEGATION_FORM_SUBMIT(), data);
  getDelegatorsFilterNames: GetDelegatorsNamesList = async () => this.get(DELEGATOR_NAMES_LIST());
}

export const staticForm = new StaticForm();

export const chats = [
  {
    id: 'df7b1f93-c4ec-47ab-8651-b137193acd6e',
    topic: 'topic1',
    chatType: 'ChatType1',
    lastUpdatedDateTime: '2021-03-23T15:35:39.866774Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77684',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77685',
        email: 'johndoe@gmial.com',
      },
    ],
  },
  {
    id: '8e1fa73b-db16-4857-8f5b-3cac648b2e20',
    topic: 'topic2',
    chatType: 'ChatType12',
    lastUpdatedDateTime: '2021-03-23T15:35:39.866801Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77684',
        email: 'johndoe@gmial.com',
      },
    ],
  },
  {
    id: '48e09b96-00b1-4dc3-9746-66abbbc78ec1',
    topic: null,
    chatType: 'ChatType3',
    lastUpdatedDateTime: '2021-03-23T15:35:39.86683Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
    ],
  },
  {
    id: '6be579d1-a4e9-4272-94a6-e5778c101b01',
    topic: 'topic3',
    chatType: 'ChatType3',
    lastUpdatedDateTime: '2021-03-23T15:35:39.866854Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
    ],
  },
  {
    id: '8e1fa73b-db16-4857-8f5b-3cac648b2e22',
    topic: 'topic2',
    chatType: 'ChatType12',
    lastUpdatedDateTime: '2021-03-23T15:35:39.866801Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77684',
        email: 'johndoe@gmial.com',
      },
    ],
  },
  {
    id: '8e1fa73b-db16-4857-8f5b-3cac648b2e18',
    topic: 'topic2',
    chatType: 'ChatType12',
    lastUpdatedDateTime: '2021-03-23T15:35:39.866801Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77684',
        email: 'johndoe@gmial.com',
      },
    ],
  },
  {
    id: '8e1fa73b-db16-4857-8f5b-3cac648b2e19',
    topic: 'topic2',
    chatType: 'ChatType12',
    lastUpdatedDateTime: '2021-03-23T15:35:39.866801Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77684',
        email: 'johndoe@gmial.com',
      },
    ],
  },
  {
    id: '8e1fa73b-db16-4857-8f5b-3cac648b2e10',
    topic: 'topic2',
    chatType: 'ChatType12',
    lastUpdatedDateTime: '2021-03-23T15:35:39.866801Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77684',
        email: 'johndoe@gmial.com',
      },
    ],
  },
  {
    id: '8e1fa73b-db16-4857-8f5b-3cac648b2e11',
    topic: 'topic2',
    chatType: 'ChatType12',
    lastUpdatedDateTime: '2021-03-23T15:35:39.866801Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77684',
        email: 'johndoe@gmial.com',
      },
    ],
  },
  {
    id: '8e1fa73b-db16-4857-8f5b-3cac648b2e12',
    topic: 'topic2',
    chatType: 'ChatType12',
    lastUpdatedDateTime: '2021-03-23T15:35:39.866801Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77684',
        email: 'johndoe@gmial.com',
      },
    ],
  },
  {
    id: '8e1fa73b-db16-4857-8f5b-3cac648b2e13',
    topic: 'topic2',
    chatType: 'ChatType12',
    lastUpdatedDateTime: '2021-03-23T15:35:39.866801Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77684',
        email: 'johndoe@gmial.com',
      },
    ],
  },
  {
    id: '8e1fa73b-db16-4857-8f5b-3cac648b2e14',
    topic: 'topic2',
    chatType: 'ChatType12',
    lastUpdatedDateTime: '2021-03-23T15:35:39.866801Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77684',
        email: 'johndoe@gmial.com',
      },
    ],
  },
  {
    id: '8e1fa73b-db16-4857-8f5b-3cac648b2e16',
    topic: 'topic2',
    chatType: 'ChatType12',
    lastUpdatedDateTime: '2021-03-23T15:35:39.866801Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77684',
        email: 'johndoe@gmial.com',
      },
    ],
  },
  {
    id: '8e1fa73b-db16-4857-8f5b-3cac648b2e17',
    topic: 'topic2',
    chatType: 'ChatType12',
    lastUpdatedDateTime: '2021-03-23T15:35:39.866801Z',
    members: [
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77683',
        email: 'johndoe@gmial.com',
      },
      {
        displayName: 'John Doe',
        userId: '81354113-4ef6-46d6-b59f-ae29a3d77684',
        email: 'johndoe@gmial.com',
      },
    ],
  },
];

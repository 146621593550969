import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const taskDiagram = (state: RootState): RootState['taskDiagram'] => state.taskDiagram;

export const taskDiagramData = createSelector(taskDiagram, ({ status, data, error }) => ({
  status,
  data,
  error,
}));

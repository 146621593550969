import React, { FC, useCallback, useState } from 'react';
import { Button, notification } from 'antd';
import { TextStyled } from 'ui/elements';
import { DownloadOutlined } from '@ant-design/icons';
import { oneDriveGetDriveItem } from 'api';
import { useTranslate } from 'translations';

interface Props {
  driveId: string;
  itemId: string;
  iconColor?: string;
}

export const OneDriveDownload: FC<Props> = ({ driveId, itemId, iconColor }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>();
  const { t } = useTranslate();

  const onClick = useCallback(async () => {
    if (downloadUrl) {
      document.location.href = downloadUrl;
    } else {
      setIsLoading(true);
      try {
        const { data } = await oneDriveGetDriveItem.getOneGetDriveItem(driveId, itemId);
        setDownloadUrl(data.downloadUrl);

        document.location.href = data.downloadUrl;
      } catch {
        notification.error({
          message: t('messages.error.wentWrong'),
          description: t('messages.error.tryAgainLater'),
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [downloadUrl, driveId, itemId, t]);

  return (
    <Button
      icon={
        <TextStyled type="secondary">
          <DownloadOutlined style={{ color: iconColor }} />
        </TextStyled>
      }
      onClick={onClick}
      loading={isLoading}
      type="link"
      download
    />
  );
};

import styled from 'styled-components';
import { Button, Typography } from 'antd';

const { Text } = Typography;

export const HeaderH2 = styled(Text).attrs({
  level: 2,
})`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  color: #253746;
  font-weight: normal;
`;

export const HeaderH4 = styled(Text).attrs({
  level: 4,
})`
  margin: 0 !important;
  font-size: 16px;
  line-height: 24px;
  color: #253746;
  font-weight: 400;
`;

export const StyledFormGroupRemoveButton = styled(Button)`
  color: #949cad;
`;

export const StyledFormGroupInnerHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
`;

import { Col, Row } from 'antd';
import { MyWorkIcon } from 'assets';
import React, { FC, memo } from 'react';
import { useTranslate } from 'translations';
import { RequestTeamMember } from 'types/DashboardRequestsCard';
import { span } from 'ui';
import { AvatarGroup } from '../../organisms';
import {
  StyledColWork,
  StyledMyWork,
  StyledMyWorkRow,
  StyledNumberCard,
  StyledSpaceCardRow,
  StyledWorkIcon,
} from './DashboardRequestCardSummaryInfoStyled';

export interface Props {
  numberOfTasks: number;
  requestMember?: RequestTeamMember[];
}

export const CardSummaryRequestComponent: FC<Props> = ({ requestMember, numberOfTasks }) => {
  const { t } = useTranslate();
  const { span2, span9, span13 } = span;
  return (
    <StyledSpaceCardRow>
      <StyledWorkIcon span={span2}>
        <MyWorkIcon />
      </StyledWorkIcon>
      <StyledColWork span={span9}>
        <StyledMyWorkRow>
          <StyledNumberCard ellipsis>{numberOfTasks}</StyledNumberCard>
          <StyledMyWork ellipsis>{t('dashboard.myTask.card.myWork')}</StyledMyWork>
        </StyledMyWorkRow>
      </StyledColWork>
      {requestMember && (
        <Col span={span13}>
          <Row justify={'end'} align={'middle'}>
            <AvatarGroup groupMember={requestMember} />
          </Row>
        </Col>
      )}
    </StyledSpaceCardRow>
  );
};

export const CardSummaryRequest = memo(CardSummaryRequestComponent);

import React, { FC, useEffect } from 'react';
import { Col, notification, Row } from 'antd';
import { AppLayout } from 'layouts';
import { Header } from 'app';
import { FullWidthAndHeightSpin, ProfileSectionContainer, TitleH1 } from 'ui';
import { useTranslate } from 'translations';
import { useGetPublicProfileInfo } from 'api';
import { StyledPrivateProfileOrganizationalHierarchy } from 'routes/NewProfile/components/PrivateProfileTabs/PrivateProfileTabs.styled';
import { ProfileOverview } from 'routes/NewProfile/components/ProfilePersonalDetails';
import { useHistory, useParams } from 'react-router-dom';
import {
  NewProfileContentRightSideWrapper,
  StyledNewProfileContentWrapper,
  StyledNewProfileCoverWrapper,
  StyledNewProfilePageWrapper,
  StyledPublicProfileContentWrapper,
} from './NewProfile.styled';
import { UserInfoCard } from './components';

export const PublicProfile: FC = () => {
  const { t } = useTranslate();
  const history = useHistory();
  const { id: email } = useParams<{ id: string }>();
  const {
    data: publicProfileInfo,
    isLoading: publicProfileLoading,
    isError: publicProfileError,
  } = useGetPublicProfileInfo(email);

  useEffect(() => {
    if (publicProfileError) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
      history.goBack();
    }
  }, [history, publicProfileError, t]);

  return (
    <>
      <AppLayout displayFooter={false}>
        <Header>
          <Row align="middle">
            <Col>
              {publicProfileLoading ? (
                <FullWidthAndHeightSpin />
              ) : (
                <TitleH1>
                  {publicProfileInfo?.firstName}&apos;s {t('profile.title')}
                </TitleH1>
              )}
            </Col>
          </Row>
        </Header>
        <StyledNewProfilePageWrapper>
          <StyledNewProfileCoverWrapper />
          <StyledNewProfileContentWrapper gutter={[25, 0]}>
            <Col flex="300px">
              <UserInfoCard
                t={t}
                basicInfo={publicProfileInfo?.basicInfo}
                isLoading={publicProfileLoading}
                isError={publicProfileError}
                selfView={false}
                adOid={publicProfileInfo?.adOid ?? publicProfileInfo?.hcmId}
              />
            </Col>
            <NewProfileContentRightSideWrapper flex="auto">
              <StyledPublicProfileContentWrapper>
                <ProfileSectionContainer
                  isEmpty={publicProfileError}
                  isLoading={publicProfileLoading}
                  sectionName={t('profile.personal.sections.overview')}
                >
                  {publicProfileInfo?.overView && (
                    <ProfileOverview t={t} overview={publicProfileInfo?.overView} />
                  )}
                </ProfileSectionContainer>
                <ProfileSectionContainer
                  isEmpty={!publicProfileInfo?.hcmId}
                  sectionName={t('profile.tab.hierarchy')}
                  isLoading={publicProfileLoading}
                >
                  {publicProfileInfo?.hcmId && (
                    <StyledPrivateProfileOrganizationalHierarchy
                      hcmId={publicProfileInfo?.hcmId}
                      withoutTitle={true}
                    />
                  )}
                </ProfileSectionContainer>
              </StyledPublicProfileContentWrapper>
            </NewProfileContentRightSideWrapper>
          </StyledNewProfileContentWrapper>
        </StyledNewProfilePageWrapper>
      </AppLayout>
      <StyledNewProfilePageWrapper></StyledNewProfilePageWrapper>
    </>
  );
};

import { eLibrary, QUERIES, PERSIST_QUERY_CONFIG } from 'api';
import { useQuery } from 'react-query';

export function useELibraryDocumentInfo(documentId: string, isEnabled: boolean) {
  return useQuery(
    [QUERIES.eLibraryDocumentInfo, documentId],
    async () => {
      const { data } = await eLibrary.getDocumentInfo(documentId);
      return data;
    },
    {
      enabled: !!documentId && isEnabled,
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

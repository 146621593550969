/* eslint-disable @typescript-eslint/no-explicit-any */
import { AttachmentFile } from 'routes/Tasks/components/pages/ServiceNewRequest/ServiceNewRequest';
import { task as taskApi } from 'api';
import { SubmitNewRequestForm } from 'types';
import { UploadFile } from 'antd/lib/upload/interface';

const attachmentFormData = (
  attachmentFile: UploadFile[],
  attachmentLabel: string,
  taskId: string,
  processDefinitionId: string,
  processInstanceId: string,
  activityId: string,
  jiraIssueKey?: string
) =>
  attachmentFile.map(async (attachmentInfo) => {
    const formData = new FormData();
    formData.append('content', attachmentInfo.originFileObj as Blob);
    formData.append('attachmentName', attachmentInfo.name);
    formData.append('filedId', attachmentLabel);
    formData.append('attachmentDescription', attachmentInfo.name);
    formData.append('attachmentType', attachmentInfo.type);
    jiraIssueKey && formData.append('jiraIssueKey', jiraIssueKey);

    return await taskApi.postTaskAttachment(
      taskId,
      processInstanceId,
      activityId,
      processDefinitionId,
      formData
    );
  });

const jiraAttachmentFormData = (
  attachmentFile: UploadFile[],
  attachmentLabel: string,
  processInstanceId: string
) =>
  attachmentFile.map(async (attachmentInfo) => {
    const formData = new FormData();
    formData.append('content', attachmentInfo.originFileObj as Blob);
    formData.append('attachmentName', attachmentInfo.name);
    formData.append('filedId', attachmentLabel);
    formData.append('attachmentDescription', attachmentInfo.name);
    formData.append('attachmentType', attachmentInfo.type);

    return await taskApi.postJiraTaskAttachments(processInstanceId, formData);
  });

const attachmentItemData = async (
  attachmentFile: UploadFile,
  attachmentLabel: string,
  taskId: string,
  processDefinitionId: string,
  processInstanceId: string,
  activityId: string,
  itemId?: number,
  profileEntityType?: string,
  isNonPo?: boolean
) => {
  const formData = new FormData();
  formData.append('content', attachmentFile.originFileObj as Blob);
  formData.append('attachmentName', attachmentFile.name);
  formData.append('filedId', attachmentLabel);
  formData.append('attachmentDescription', attachmentFile.name);
  formData.append('attachmentType', attachmentFile.type);
  itemId && formData.append('itemId', itemId.toString());

  const params: Record<string, string> = {};
  itemId && (params['itemId'] = itemId.toString());
  profileEntityType && (params['profileEntityType'] = profileEntityType);

  if (isNonPo) {
    return await taskApi.postNonPoTaskAttachment(
      taskId,
      processInstanceId,
      activityId,
      processDefinitionId,
      formData,
      params
    );
  }

  return await taskApi.postTaskAttachment(
    taskId,
    processInstanceId,
    activityId,
    processDefinitionId,
    formData,
    params
  );
};

export const uploadAttachments = (
  taskId: string,
  processDefinitionId: string,
  processInstanceId: string,
  activityId: string,
  attachmentList: AttachmentFile[],
  jiraIssueKey?: string
) => {
  attachmentList.forEach((attachments: AttachmentFile) =>
    Object.entries(attachments).forEach(([attachmentName, attachmentFile]) => {
      attachmentFormData(
        attachmentFile,
        attachmentName,
        taskId,
        processDefinitionId,
        processInstanceId,
        activityId,
        jiraIssueKey
      );
    })
  );
};

export const uploadJiraAttachments = (
  processInstanceId: string,
  attachmentList: AttachmentFile[]
) => {
  attachmentList.forEach((attachments: AttachmentFile) =>
    Object.entries(attachments).forEach(([attachmentName, attachmentFile]) => {
      jiraAttachmentFormData(attachmentFile, attachmentName, processInstanceId);
    })
  );
};

export const uploadAttachmentItem = (
  taskId: string,
  processDefinitionId: string,
  processInstanceId: string,
  activityId: string,
  attachmentList: [UploadFile[]],
  attachmentItemIds?: number[],
  profileEntityType?: string
) => {
  if (attachmentItemIds?.length) {
    const result = attachmentItemIds.map((attachments, index) => {
      return { itemIs: attachments, itemAttachments: attachmentList[index] };
    });

    result.forEach((attachment) => {
      Object.entries(attachment.itemAttachments).forEach(([attachmentName, attachmentFile]) => {
        attachmentItemData(
          attachmentFile,
          attachmentName,
          taskId,
          processDefinitionId,
          processInstanceId,
          activityId,
          attachment.itemIs,
          profileEntityType
        );
      });
    });
  }
};

export const uploadFormListAttachments = (
  taskId: string,
  processDefinitionId: string,
  processInstanceId: string,
  activityId: string,
  attachmentList: AttachmentFile[],
  isNonPo?: boolean
) => {
  attachmentList.forEach((attachment) => {
    Object.entries(attachment).forEach(([itemId, attachmentFiles]) => {
      attachmentFiles.forEach((attachmentFile) => {
        attachmentItemData(
          attachmentFile,
          attachmentFile.name,
          taskId,
          processDefinitionId,
          processInstanceId,
          activityId,
          Number(itemId),
          undefined,
          isNonPo
        );
      });
    });
  });
};

export const submitAttachmentForm = (
  isSubmitFormSuccess: boolean,
  requestInfo: SubmitNewRequestForm,
  history: any,
  itemList?: boolean,
  fileList?: AttachmentFile[],
  itemFileList?: [UploadFile[]],
  formListAttachmentList?: AttachmentFile[],
  isNonPo?: boolean
) => {
  if (isSubmitFormSuccess) {
    const location = {
      pathname: `/tasks/${requestInfo.processInstanceId}`,
      state: { defaultTab: '0' },
    };

    if (
      (fileList && fileList.length > 0) ||
      (itemFileList && itemFileList.length > 0) ||
      (formListAttachmentList && formListAttachmentList.length > 0 && requestInfo)
    ) {
      const taskId = requestInfo.taskId;
      const processDefinitionId = requestInfo.processDefinitionId;
      const processInstanceId = requestInfo.processInstanceId;
      const activityId = requestInfo.activityId;
      const itemsIds = requestInfo?.itemsId;
      if (itemList && itemsIds && itemsIds.length && itemFileList) {
        uploadAttachmentItem(
          taskId,
          processDefinitionId,
          processInstanceId,
          activityId,
          itemFileList,
          itemsIds
        );
      } else if (formListAttachmentList) {
        uploadFormListAttachments(
          taskId,
          processDefinitionId,
          processInstanceId,
          activityId,
          formListAttachmentList,
          isNonPo
        );
      } else if (fileList) {
        uploadAttachments(taskId, processDefinitionId, processInstanceId, activityId, fileList);
      }

      history.replace(location);
    } else {
      history.replace(location);
    }
  }
};

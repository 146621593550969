import React, { FC, memo, useMemo } from 'react';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Typography,
} from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { InfoCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { FORM_FILED_BREAKPOINT } from 'routes/Tasks/components/templates';
import { TitleH4 } from 'ui';
import { FormItemProps, InputStatus } from 'types/FormItem.type';
import { RestrictedUpload } from 'ui/components/RestrictedUpload';
import {
  StyledGroupCol,
  StyledNoFoundText,
} from '../../pages/RequestCatalog/RequestCatalog.styled';
import { FormItemStyled, GroupTitle, StyledDivider } from './GenerateStaticForm.style';

const DEFAULT_ITEM_SPAN = 24;
const datePickerStyles = { width: '100%' };
const { Text } = Typography;
const StyledInputNumber = styled(InputNumber)`
  &.ant-input-number {
    width: 100%;
    border-color: ${(props: { $status?: InputStatus }) =>
      props.$status === 'error' ? '#ff4d4f' : props.$status === 'warning' ? '#faad14' : null};
  }
`;
const StyledCaption = styled(Text)`
  font-size: 12px;
  color: #949cad;
  display: block;
  margin-bottom: 12px;
`;
const StyledRadio = styled(Radio)`
  font-size: 10px;
  color: #949cad;
`;

const StyledLabelOfCheckBoxWithTitle = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #606f8a;
`;

const { RangePicker } = DatePicker;
export const GeneratedStaticFormComponent: FC<FormItemProps> = ({ items, span, formState, t }) => {
  const gutter: [Gutter, Gutter] = useMemo(() => [24, 0], []);

  const form = useMemo(
    () => (
      <Row gutter={gutter}>
        {items.map(
          ({
            id,
            type,
            label,
            name,
            options,
            disabled,
            hidden,
            disabledDate,
            rules,
            dependencies,
            valuePropName,
            wrapperCol,
            containerCol,
            withFullRow,
            hasFeedback,
            placeholder,
            picker,
            maxNumber,
            isHidden,
            resetFormFields,
            getValueFromEvent,
            pattern,
            allowClear,
            attachmentSecondary,
            format,
            onSearch,
            onSelect,
            onDateChange,
            onRangeDateChange,
            onFileChange,
            isLoading,
            searchText,
            noResultFound,
            rangePickerPlaceholder,
            selectTheValueAsKey,
            filterOption,
            onFocus,
            ref,
            status,
            hasIconInfo,
            iconInfoLabel,
            attachmentFileTypes,
            attachmentFileExtensions,
            description,
            isFullWidth,
            withDivider,
            labelSize,
            addonBefore,
            maxAttachmentCount,
            labelCheckBoxTitle,
            initialValue,
            selectWidth,
            disclaimer,
          }) => {
            let child = null;
            const { TextArea } = Input;

            switch (type) {
              case 'date':
                child = (
                  <DatePicker
                    key={id}
                    format={format}
                    allowClear={allowClear}
                    style={datePickerStyles}
                    disabled={disabled}
                    disabledDate={disabledDate}
                    picker={picker}
                    placeholder={placeholder}
                    onChange={onDateChange}
                    getPopupContainer={(trigger: HTMLElement) =>
                      trigger.parentElement as HTMLElement
                    }
                  />
                );
                break;
              case 'rangePicker':
                child = (
                  <RangePicker
                    key={id}
                    format={format}
                    allowClear={allowClear}
                    style={datePickerStyles}
                    disabled={disabled}
                    disabledDate={disabledDate}
                    picker={picker}
                    placeholder={rangePickerPlaceholder}
                    onChange={onRangeDateChange}
                    getPopupContainer={(trigger: HTMLElement) =>
                      trigger.parentElement as HTMLElement
                    }
                  />
                );
                break;
              case 'input':
                child = (
                  <Input
                    addonBefore={addonBefore}
                    key={id}
                    disabled={disabled}
                    placeholder={placeholder}
                  />
                );
                break;
              case 'inputNumber':
                child = (
                  <StyledInputNumber
                    key={id}
                    min={1}
                    disabled={disabled}
                    placeholder={placeholder}
                    $status={status}
                  />
                );
                break;
              case 'checkbox':
                child = <Checkbox key={id} disabled={disabled} />;
                break;
              case 'checkboxWithTitle':
                child = (
                  <Checkbox key={id} defaultChecked={false} disabled={disabled}>
                    <StyledLabelOfCheckBoxWithTitle>
                      {labelCheckBoxTitle ?? ''}
                    </StyledLabelOfCheckBoxWithTitle>
                  </Checkbox>
                );
                break;
              case 'multiCheckboxes':
                child = <Checkbox.Group key={id} options={options} disabled={disabled} />;
                break;
              case 'textArea':
                child = (
                  <TextArea
                    key={id}
                    maxLength={maxNumber}
                    showCount={true}
                    placeholder={t('tasks.textArea.placeHolder')}
                  />
                );
                break;
              case 'select':
                child = (
                  <Select
                    key={id}
                    style={{ width: selectWidth }}
                    disabled={disabled}
                    placeholder={placeholder}
                    getPopupContainer={(trigger: HTMLElement) =>
                      trigger.parentElement as HTMLElement
                    }
                    onChange={resetFormFields}
                    showSearch={true}
                    onSearch={onSearch}
                    onSelect={onSelect}
                    loading={isLoading}
                    filterOption={filterOption}
                    onFocus={onFocus}
                    ref={ref}
                    notFoundContent={
                      (searchText || noResultFound) && !isLoading ? (
                        <>
                          <StyledNoFoundText>
                            {noResultFound ??
                              `${t(
                                'businessTravel.staticForm.validation.from.noResult'
                              )} "${searchText}"`}
                          </StyledNoFoundText>
                          {name === 'country' && (
                            <StyledNoFoundText>
                              {t('businessTravel.staticForm.validation.from.searchSaudi')}
                            </StyledNoFoundText>
                          )}
                        </>
                      ) : isLoading ? (
                        <StyledNoFoundText>{t('dropdown.searching')}</StyledNoFoundText>
                      ) : (
                        <StyledNoFoundText>{t('dropdown.noMatching')}</StyledNoFoundText>
                      )
                    }
                  >
                    {options &&
                      options.map((item) => (
                        <Select.Option
                          key={item.value}
                          value={selectTheValueAsKey ? item.value : item.label}
                        >
                          {item.label}
                        </Select.Option>
                      ))}
                  </Select>
                );
                break;
              case 'multiSelect':
                child = (
                  <Select
                    key={id}
                    mode={'multiple'}
                    disabled={disabled}
                    onSearch={onSearch}
                    onSelect={onSelect}
                    loading={isLoading}
                    filterOption={filterOption}
                    placeholder={placeholder}
                    getPopupContainer={(trigger: HTMLElement) =>
                      trigger.parentElement as HTMLElement
                    }
                    onChange={resetFormFields}
                    notFoundContent={isLoading ? t('dropdown.searching') : t('dropdown.noMatching')}
                    showSearch
                    showArrow={true}
                  >
                    {options?.map(({ value, label, infoHint }) => {
                      return (
                        <Select.Option
                          title={infoHint ?? label}
                          key={value}
                          value={selectTheValueAsKey ? value : label}
                        >
                          {label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                );
                break;
              case 'file':
                child = (
                  <RestrictedUpload
                    key={id}
                    listType="text"
                    multiple={true}
                    disableField={disabled}
                    form={formState}
                    fieldId={name}
                    allowedFileTypes={attachmentFileTypes}
                    allowedFileExtensions={attachmentFileExtensions}
                    onChange={onFileChange}
                    maxAttachmentCount={maxAttachmentCount}
                  >
                    {attachmentSecondary ? (
                      <Button icon={<PlusOutlined />} type="text">
                        {t('leaves.cancel.addAttachment')}
                      </Button>
                    ) : (
                      <Button icon={<UploadOutlined />} disabled={disabled}>
                        {t('task.uploadFile')}
                      </Button>
                    )}
                  </RestrictedUpload>
                );
                break;
              case 'text':
                child = <TitleH4 key={id}> {label} </TitleH4>;
                break;
              case 'groupText':
                child = <GroupTitle key={id}>{label}</GroupTitle>;
                break;
              case 'radio':
                child = (
                  <Radio.Group onChange={resetFormFields}>
                    <StyledCaption key={id}> {description} </StyledCaption>
                    {options?.map(({ value, label }) => {
                      return (
                        <StyledRadio key={value} value={selectTheValueAsKey ? value : label}>
                          {label}
                        </StyledRadio>
                      );
                    })}
                  </Radio.Group>
                );
                break;
            }

            const props = {
              label,
              name,
              rules,
              dependencies,
              valuePropName,
              wrapperCol,
              hasFeedback,
              getValueFromEvent,
              pattern,
              labelSize,
              hidden,
              initialValue,
            };

            const colSpan = containerCol ?? FORM_FILED_BREAKPOINT;

            const formItem = (
              <FormItemStyled
                {...props}
                {...(hasIconInfo
                  ? {
                      tooltip: {
                        title: iconInfoLabel,
                        icon: <InfoCircleOutlined />,
                      },
                    }
                  : {})}
              >
                {child}
              </FormItemStyled>
            );

            const item = (
              <>
                {withDivider && <StyledDivider />}
                {isHidden ? null : child ? (
                  hidden ? (
                    formItem
                  ) : type === 'file' || type === 'textArea' || isFullWidth ? (
                    <Col span={DEFAULT_ITEM_SPAN}>
                      {formItem}
                      {disclaimer && disclaimer}
                    </Col>
                  ) : type === 'text' || type === 'groupText' ? (
                    <StyledGroupCol span={DEFAULT_ITEM_SPAN}>{child}</StyledGroupCol>
                  ) : (
                    <Col {...colSpan}>
                      {formItem}
                      {disclaimer && disclaimer}
                    </Col>
                  )
                ) : null}
              </>
            );

            return withFullRow && !isHidden ? <Col span={DEFAULT_ITEM_SPAN}>{item}</Col> : item;
          }
        )}
      </Row>
    ),
    [gutter, items, t, formState]
  );

  if (span) {
    return (
      <Row>
        <Col span={span}>{form}</Col>
      </Row>
    );
  }
  return form;
};

export const GeneratedStaticForm = memo(GeneratedStaticFormComponent);

import React, { FC, useEffect } from 'react';
import { Form, Input, Row } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { useTranslate } from 'translations';
import { useServicesContext } from 'app/ServicesProvider';
import { FormField } from 'types';
import { StyledFieldLabel } from '../GeneratedForm.Styled';
import { useFromFieldName } from '../../../hooks';

const { TextArea } = Input;

interface Props {
  field: FormField;
  withLabel?: boolean;
  wrapperColSpan?: number;
  formListField?: FormListFieldData;
}

export const FieldTextArea: FC<Props> = ({
  field,
  withLabel,
  wrapperColSpan = 20,
  formListField,
}) => {
  const { t } = useTranslate();

  const maxLengthFromValidation = field.validationConstraints.find(
    (rule) => rule.name === 'maxlength'
  );

  const maxLengthFromProperties = field.properties.maxlength
    ? +field.properties.maxlength
    : undefined;

  const maxLength =
    maxLengthFromValidation && typeof maxLengthFromValidation.configuration === 'string'
      ? +maxLengthFromValidation.configuration
      : maxLengthFromProperties;

  const fieldIsDisabled = field.properties.viewOnly === 'true';

  const fieldIsRequired = field.validationConstraints.some((rule) => rule.name === 'required');

  const {
    fieldIsHidden,
    dependenciesFieldsBetweenForms,
    setDependenciesFieldsBetweenForms,
  } = useServicesContext();

  const defaultHidden = field.properties.defaultHidden
    ? field.properties.defaultHidden === 'true'
      ? true
      : false
    : false;

  const requiredDependOnOtherFields =
    fieldIsHidden && fieldIsHidden?.isHidden !== null ? fieldIsHidden.fieldId !== field.id : false;

  const hiddenField =
    fieldIsHidden?.fieldId !== undefined
      ? fieldIsHidden.fieldId !== null
        ? fieldIsHidden.fieldId === field.id
        : false
      : defaultHidden;

  useEffect(() => {
    if (
      requiredDependOnOtherFields &&
      dependenciesFieldsBetweenForms &&
      dependenciesFieldsBetweenForms.fieldId
    ) {
      const dependField = {
        ...dependenciesFieldsBetweenForms,
        isThereARequiredFields: requiredDependOnOtherFields,
      };

      setDependenciesFieldsBetweenForms(dependField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredDependOnOtherFields, setDependenciesFieldsBetweenForms]);

  const name = useFromFieldName(field.id, formListField);

  return (
    <>
      {withLabel && (
        <Row>
          <StyledFieldLabel>{field.label}</StyledFieldLabel>
        </Row>
      )}
      <Form.Item
        {...formListField}
        rules={[
          {
            required: fieldIsRequired || requiredDependOnOtherFields,
            message: `${field.label} is required`,
          },
        ]}
        wrapperCol={{ span: wrapperColSpan }}
        name={name}
        label={withLabel ? null : field.label}
        hidden={hiddenField}
        initialValue={field.initialValue}
      >
        <TextArea
          maxLength={maxLength}
          showCount={maxLength !== undefined}
          value={
            fieldIsDisabled && fieldIsRequired && field.defaultValue
              ? field.defaultValue
              : undefined
          }
          disabled={fieldIsDisabled}
          placeholder={t('tasks.textArea.placeHolder')}
        />
      </Form.Item>
    </>
  );
};

import { connect, ConnectedProps } from 'react-redux';

import {
  RootState,
  getUserInfoStart,
  userInfoData,
  getuserInfoStatusStart,
  userInfoStatus,
  getUserInfoCardStart,
  userInfoDataCard,
} from 'store';

const mapDispatch = { getUserInfoStart, getUserInfoCardStart, getuserInfoStatusStart };

const mapState = (state: RootState) => ({
  userPopover: userInfoData(state),
  userPopoverCard: userInfoDataCard(state),
  userStatus: userInfoStatus(state),
});

export const userInfoConnector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof userInfoConnector>;

export type ConnectorProps = PropsFromRedux;

import { OneDriveItemChildrenData } from 'types';
import { Core, DRIVE_ITEM_CHILDREN } from 'api';

interface GetOneDriveItemChildren {
  (folderId: string): Promise<{ data: OneDriveItemChildrenData }>;
}

class OneDriveItemChildren extends Core {
  getOneDriveItemChildren: GetOneDriveItemChildren = async (folderId) =>
    this.get(DRIVE_ITEM_CHILDREN(folderId));
}

export const oneDriveItemChildren = new OneDriveItemChildren();

import React from 'react';

import { AvatarProps } from 'antd/lib/avatar';
import { TeamOutlined } from '@ant-design/icons';
import {
  StyledAvatar,
  StyledWrapper,
} from 'routes/MSTeams/MSTeamsUserAvatar/MSTeamsUserAvatar.styled';
import { colors } from 'ui/elements';

export const GroupActiveAvatarIcon: React.FC<AvatarProps> = ({ size = 40, ...rest }) => {
  return (
    <StyledWrapper>
      <StyledAvatar
        style={{ backgroundColor: colors.skyLight }}
        icon={<TeamOutlined style={{ color: colors.skyColor }} />}
        size={size}
        {...rest}
      />
    </StyledWrapper>
  );
};

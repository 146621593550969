import React, { FC, memo } from 'react';
import { Col, Row } from 'antd';
import { RequestCardInfo } from 'types';
import { colors } from 'ui/elements';
import {
  FileName,
  StyledCardFile,
  StyledColAlign,
  StyledWrapperContent,
} from 'ui/components/OneDrive/OneDriveWidget/OneDriveWidget.styled';
import { StyledPrettyIdText, StyledSpan, StatusRequestTag } from './YourRequestWidget.style';
import { breakPoint, GUTTER_0_10, GUTTER_0_5 } from './constants';

export interface FileCardProps {
  list: RequestCardInfo;
  onClickHandler: (processInstanceId: string) => void;
}

export const RequestCardComponent: FC<FileCardProps> = ({ list, onClickHandler }) => {
  return (
    <StyledCardFile bgColor={colors.greyBackLabel} focusBgColor={colors.greyBackLabelHover}>
      <StyledWrapperContent align={'middle'} onClick={() => onClickHandler(list.processInstanceId)}>
        <StyledColAlign span={24}>
          <Row justify={'space-between'} gutter={GUTTER_0_10}>
            <Col {...breakPoint}>
              <StyledSpan>
                <FileName strong ellipsis color={colors.lightOverlayBlue}>
                  {list.processName}
                </FileName>
              </StyledSpan>
            </Col>
            <Col>
              <StatusRequestTag>{list?.status}</StatusRequestTag>
            </Col>
          </Row>
        </StyledColAlign>
        <Col span={24}>
          <Row justify={'space-between'} gutter={GUTTER_0_5}>
            <Col>
              <StyledPrettyIdText>{list.prettyId}</StyledPrettyIdText>
            </Col>
          </Row>
        </Col>
      </StyledWrapperContent>
    </StyledCardFile>
  );
};

export const RequestCard = memo(RequestCardComponent);

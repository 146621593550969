import { AxiosRequestConfig } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TaskData, RequestCommonError, RequestStatus } from 'types';
import { GroupsTasksListState } from './groupsTasksListTypes';

export const emptyGroupsTasksListState: GroupsTasksListState = {
  status: RequestStatus.idle,
  error: null,
  hasMore: true,
};

export type GetGroupsTasksListStart = PayloadAction<AxiosRequestConfig>;
export type GetGroupsTasksListSuccess = PayloadAction<TaskData>;
export type GetGroupsTasksListFail = PayloadAction<RequestCommonError>;

const groupsTasks = createSlice({
  name: 'groupsTasksList',
  initialState: emptyGroupsTasksListState,
  reducers: {
    getGroupsTasksListStart(state, _: GetGroupsTasksListStart) {
      state.status = RequestStatus.pending;
    },
    getGroupsTasksListSuccess(state, action: GetGroupsTasksListSuccess) {
      state.status = RequestStatus.resolved;

      if (action.payload?.length < 10) {
        state.hasMore = false;
      }

      if (!state.data?.length) {
        state.data = action.payload;
        return;
      }

      state.data = [...state.data, ...action.payload];
    },
    getGroupsTasksListFail(state, action: GetGroupsTasksListFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getGroupsTasksListReset(state) {
      state.status = RequestStatus.idle;
      state.data = undefined;
    },
  },
});

export const {
  getGroupsTasksListFail,
  getGroupsTasksListStart,
  getGroupsTasksListSuccess,
  getGroupsTasksListReset,
} = groupsTasks.actions;
export default groupsTasks.reducer;

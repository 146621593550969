import React, { FC } from 'react';
import { useGetUserStatus } from 'api';
import { USER_STATUS_MAP } from 'types';
import { Skeleton } from 'antd';
import { StatusTag } from 'ui';
import { useTranslate } from 'translations';

interface UsersTableStatusProps {
  currentID?: string;
  currentEmail?: string;
  hcmID?: string;
}

export const UserTableStatusComponent: FC<UsersTableStatusProps> = ({ currentEmail }) => {
  const { isLoading, data } = useGetUserStatus(currentEmail ?? '');
  const { t } = useTranslate();

  return (
    <>
      {isLoading ? (
        <Skeleton.Button active size="small" shape="round" />
      ) : (
        data?.status && (
          <StatusTag color={USER_STATUS_MAP[data.status ?? 'PresenceUnknown']?.color}>
            {t(USER_STATUS_MAP[data.status ?? 'PresenceUnknown']?.text)}
          </StatusTag>
        )
      )}
    </>
  );
};

export const UserTableStatus = React.memo(UserTableStatusComponent);

import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  GetTeamsChannelMembersFail,
  GetTeamsChannelMembersStart,
  GetTeamsChannelMembersSuccess,
  getTeamsChannelMembersFail,
  getTeamsChannelMembersStart,
  getTeamsChannelMembersSuccess,
} from './teamsChannelMembersSlice';

export const getTeamsChannelMembersEpic: Epic<
  GetTeamsChannelMembersStart,
  GetTeamsChannelMembersSuccess | GetTeamsChannelMembersFail,
  RootState,
  typeof API
> = (action$, _, { teamsChannelMembers }) =>
  action$.pipe(
    filter(getTeamsChannelMembersStart.match),
    mergeMap(({ payload }) =>
      from(teamsChannelMembers.getTeamsChannelMembers(payload.teamId, payload.channelId)).pipe(
        map(({ data }) =>
          getTeamsChannelMembersSuccess({
            teamId: payload.teamId,
            channelId: payload.channelId,
            data,
          })
        ),
        catchError((error) =>
          of(
            getTeamsChannelMembersFail({
              teamId: payload.teamId,
              channelId: payload.channelId,
              error: error?.response?.data,
            })
          )
        )
      )
    )
  );

import React, { FC } from 'react';
import { Form, Row } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { FormField } from 'types';
import moment from 'moment';
import { useFromFieldName } from 'ui/hooks';
import { StyledDatePicker, StyledFieldLabel } from '../GeneratedForm.Styled';

interface Props {
  field: FormField;
  withLabel?: boolean;
  wrapperColSpan?: number;
  formListField?: FormListFieldData;
}

export const FieldDate: FC<Props> = ({ field, withLabel, wrapperColSpan = 21, formListField }) => {
  const fieldIsRequired = field.validationConstraints.some((rule) => rule.name === 'required');
  const name = useFromFieldName(field.id, formListField);

  return (
    <>
      {withLabel && (
        <Row>
          <StyledFieldLabel>{field.label}</StyledFieldLabel>
        </Row>
      )}
      <Form.Item
        {...formListField}
        rules={[
          {
            required: fieldIsRequired,
            message: `${field.label} is required`,
          },
        ]}
        label={withLabel ? null : field.label}
        name={name}
        wrapperCol={{ span: wrapperColSpan }}
        initialValue={field.initialValue ? moment(field.initialValue) : undefined}
        getValueProps={(value) => {
          if (value && !moment.isMoment(value)) {
            return { value: moment(value) };
          }
          return value;
        }}
      >
        <StyledDatePicker
          getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
          format={field.properties?.dateFormat ?? 'DD/MM/YYYY'}
          disabled={field.properties.viewOnly === 'true'}
          placeholder={typeof field.placeholder === 'string' ? field.placeholder : undefined}
        />
      </Form.Item>
    </>
  );
};

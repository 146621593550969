import { useRequestGeneralInfo } from 'api/Camunda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RequestGeneralInfo, TaskComment } from 'types';
import { useTasksContext } from '../../routes';

export interface Comments {
  message: string;
  userId: string;
  commentId: string;
  commentDate: string;
  isActivity?: boolean;
  isJira?: boolean;
}

export const useGetRequestComments = (requestId: string) => {
  const [requestDetail, setRequestDetail] = useState<RequestGeneralInfo>({} as RequestGeneralInfo);
  const [addNewComment, setAddNewComment] = useState<boolean>(false);
  const [isFetchNewComments, setIsFetchNewComments] = useState<boolean>(false);
  const { rootPath } = useTasksContext();
  const { data: requestInfo, isError, refetch, isLoading } = useRequestGeneralInfo(
    requestId,
    rootPath,
    isFetchNewComments
  );
  const lastTask = requestDetail?.tasks?.slice(-1)[0];

  useEffect(() => {
    if (requestInfo) {
      const requestData: RequestGeneralInfo = requestInfo;

      requestData
        ? addNewComment
          ? setRequestDetail({ ...requestDetail, tasks: requestData.tasks })
          : setRequestDetail(requestData)
        : setRequestDetail({} as RequestGeneralInfo);
      setAddNewComment(false);
      setIsFetchNewComments(false);
    }
  }, [addNewComment, requestDetail, requestInfo]);

  const taskWithComments = useMemo(
    () => requestDetail && requestDetail.tasks?.filter((task) => task.comments?.length),
    [requestDetail]
  );

  const commentsCount = useMemo(
    () =>
      taskWithComments && taskWithComments.length !== 0 ? taskWithComments[0].comments.length : 0,
    [taskWithComments]
  );

  const commentsForEachTask = useMemo(
    () =>
      taskWithComments?.reverse()?.map((task) => {
        const taskStatus = task.status;
        const taskName = task.taskName;
        const taskId = task.taskId;
        const taskAssignee = task.taskAssignee;
        const comments: Comments[] = [];
        task.comments.map((comment: TaskComment) =>
          comments.push({
            message: comment.message,
            userId: comment.userId,
            commentId: comment.id,
            commentDate: comment.time,
            isActivity: comment.isActivity,
            isJira: comment.isJira,
          })
        );

        return { taskName, taskStatus, comments, taskId, taskAssignee };
      }),
    [taskWithComments]
  );

  const fetchNewComments = useCallback(() => {
    refetch();
    setAddNewComment(true);
    setIsFetchNewComments(true);
  }, [refetch]);

  const isCommentLoading = useMemo(() => isLoading, [isLoading]);

  return {
    fetchNewComments,
    commentsForEachTask,
    isCommentLoading,
    isError,
    commentsCount,
    lastTask,
    requestDetail,
    setRequestDetail,
  };
};

import styled from 'styled-components';

import { Drawer, Row, Typography } from 'antd';
import { colors, space, typography } from 'ui/elements';

const { Text } = Typography;

export const DrawerUiStyled = styled(Drawer)`
  .ant-drawer-content-wrapper {
    border: 1px solid ${colors.borderGrey};
    border-top: none;
  }
  .ant-drawer-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: initial;
  }
  &&.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: -11px 20px 20px 7px rgb(0 0 0 / 8%), -9px 0 28px 0 rgb(0 0 0 / 5%),
      -12px 0 48px 16px rgb(0 0 0 / 3%);
  }
  .ant-drawer-close {
    margin-right: var(--scroll-bar);
    padding-right: calc(10px - var(--scroll-bar));
    padding-left: 10px;
    padding-top: 32px;
    font-size: 20px;
  }
`;

export const DrawerHeaderRow = styled(Row)`
  margin-right: ${space.normal};
`;

export const DrawerTitle = styled(Text)`
  font-size: ${typography.size.tera};
  font-weight: ${typography.weight.bold};
  color: ${colors.grey};
  white-space: pre-wrap;
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProcessDefinitionListData, RequestCommonError, RequestStatus } from 'types';
import { ProcessDefinitionListState } from './processDefinitionListType';

export const emptyProcessDefinitionListInitialState: ProcessDefinitionListState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetProcessDefinitionListStart = PayloadAction;
export type GetProcessDefinitionListSuccess = PayloadAction<ProcessDefinitionListData[]>;
export type GetProcessDefinitionListFail = PayloadAction<RequestCommonError>;

const processDefinitionList = createSlice({
  name: 'processDefinitionList',
  initialState: emptyProcessDefinitionListInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getProcessDefinitionListStart(state, _: GetProcessDefinitionListStart) {
      state.status = RequestStatus.pending;
    },
    getProcessDefinitionListSuccess(state, action: GetProcessDefinitionListSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getProcessDefinitionListFail(state, action: GetProcessDefinitionListFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getProcessDefinitionListStart,
  getProcessDefinitionListSuccess,
  getProcessDefinitionListFail,
} = processDefinitionList.actions;
export default processDefinitionList.reducer;

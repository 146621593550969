import React from 'react';
import { Row, Col } from 'antd';
import { useGetUserStatus } from 'api';
import { USER_STATUS_MAP } from 'types';
import { StatusTag, FullWidthSpin } from 'ui/elements';
import { useTranslate } from 'translations';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { UserPopover } from 'ui/components';
import {
  UserDrawerUserInfoDetailsContainer,
  UserStatusContainer,
  UserName,
  UserEmail,
} from './UserDrawerUserInfoDetails.styled';

type Props = {
  adoid: string;
  name: string;
  email: string;
};

export const UserDrawerUserInfoDetails: React.FC<Props> = ({ adoid, name, email }) => {
  const { t } = useTranslate();
  const {
    data: userStatus,
    isLoading: userStatusLoading,
    isSuccess: isUserStatusSuccess,
  } = useGetUserStatus(email ?? '');
  return (
    <UserDrawerUserInfoDetailsContainer align="top">
      <Col span={17}>
        <Row align={'middle'} gutter={[21, 24]}>
          <Col span={5}>
            <UserPopover currentID={adoid}>
              <MSTeamsUserAvatar id={adoid} size={55} name={name} />
            </UserPopover>
          </Col>
          <Col span={19}>
            <UserName ellipsis={{ tooltip: name }}>{name}</UserName>
            <UserEmail ellipsis={{ tooltip: email }}>{email}</UserEmail>
          </Col>
        </Row>
      </Col>
      <UserStatusContainer span={7}>
        {userStatusLoading ? (
          <FullWidthSpin />
        ) : (
          isUserStatusSuccess &&
          userStatus && (
            <StatusTag color={USER_STATUS_MAP[userStatus.status ?? 'PresenceUnknown']?.color}>
              {t(USER_STATUS_MAP[userStatus.status ?? 'PresenceUnknown']?.text)}
            </StatusTag>
          )
        )}
      </UserStatusContainer>
    </UserDrawerUserInfoDetailsContainer>
  );
};

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FolderOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useTranslate } from 'translations';
import { Button, Card, notification, Space } from 'antd';
import moment from 'moment';
import { measureSizeOfFile } from 'utils';
import { TextStyled } from 'ui/elements';
import { UserPopover } from 'ui';
import {
  useDispatch,
  getRootFolderStart,
  useSelector,
  rootFolderData,
  getOneDriveItemChildrenStart,
  oneDriveItemChildrenData,
} from 'store';
import { RequestStatus } from 'types';
import { core, createUploadSessionForUser, oneDriveCreateNewFolderInUser } from 'api';
import { RestrictedUpload } from 'ui/components/RestrictedUpload';
import { UploadFile } from 'antd/lib/upload/interface';
import { StyledTable } from '../OneDriveShared/OneDriveShared.styled';
import { OneDriveBreadCrumbs } from '../OneDriveBreadCrumbs';
import { OneDriveCreateFolderPopup } from '../OneDriveCreateFolderPopup';
import { OneDriveConfirm } from '../OneDriveConfirm';
import {
  FileIconStyled,
  FolderIconStyled,
  OneDiveSubHeader,
  OneDriveButton,
  OneDriveDragger,
  OneDriveWrapper,
  StyledModifiedby,
} from './OneDriveMyFiles.styled';

export const OneDriveMyFiles: FC = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const rootFolder = useSelector(rootFolderData);
  const folderChildren = useSelector(oneDriveItemChildrenData);
  const [breadCrumbState, setBreadCrumbState] = useState<{ title: string; id?: string }[]>([]);
  const [tableData, setTableData] = useState(rootFolder.data?.children);
  const currentFolderId = useMemo(() => breadCrumbState[breadCrumbState.length - 1]?.id, [
    breadCrumbState,
  ]);
  const [isFolderCreateLoading, setIsFolderCreateLoading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const showError = useCallback(() => {
    notification.error({
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    });
  }, [t]);

  useEffect(() => {
    dispatch(getRootFolderStart());
  }, [dispatch]);

  useEffect(() => {
    if (currentFolderId) {
      if (breadCrumbState.length > 1) {
        if (folderChildren[currentFolderId]) {
          setTableData(folderChildren[currentFolderId]?.data);
        } else {
          dispatch(getOneDriveItemChildrenStart(currentFolderId));
        }
      } else {
        if (folderChildren[currentFolderId]) {
          setTableData(folderChildren[currentFolderId]?.data);
        } else {
          setTableData(rootFolder.data?.children);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, breadCrumbState]);

  useEffect(() => {
    if (currentFolderId && folderChildren[currentFolderId]?.data) {
      const folderData = folderChildren[currentFolderId].data;

      setTableData(folderData);
    }

    if (currentFolderId && folderChildren[currentFolderId]?.status === RequestStatus.rejected) {
      showError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderChildren]);

  useEffect(() => {
    setTableData(rootFolder.data?.children);
    setBreadCrumbState([
      {
        title: t('oneDrive.myFiles.popup.myFiles'),
        id: rootFolder.data?.id,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootFolder.data]);

  const onClickFolder = useCallback(
    (title, id) => {
      setBreadCrumbState([...breadCrumbState, { title, id }]);
      setTableData([]);
    },
    [breadCrumbState]
  );

  const onClickBreadcrumb = useCallback(
    (id) => {
      const currentFolderBreadcrumbIndex = breadCrumbState.findIndex((crumb) => crumb.id === id);

      if (~currentFolderBreadcrumbIndex) {
        setBreadCrumbState(breadCrumbState.slice(0, currentFolderBreadcrumbIndex + 1));
      }
    },
    [breadCrumbState]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnsType<any> = [
    {
      dataIndex: 'file',
      key: 'file',
      width: '5%',
    },
    {
      title: t('oneDrive.myFiles.columns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '35%',
      render: (value, row) =>
        row.download ? (
          <OneDriveButton type="link">
            <OneDriveConfirm onConfirm={() => window.open(row?.webUrl, '_blank')}>
              <TextStyled underline>{value}</TextStyled>
            </OneDriveConfirm>
          </OneDriveButton>
        ) : (
          <OneDriveButton type="link" onClick={() => onClickFolder(row.name, row.id)}>
            <TextStyled>{value}</TextStyled>
          </OneDriveButton>
        ),
    },
    {
      title: t('oneDrive.myFiles.columns.modified'),
      dataIndex: 'modified',
      key: 'modified',
      width: '20%',
    },
    {
      title: t('oneDrive.myFiles.columns.modifiedBy'),
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      width: '25%',
      render: (value, row) =>
        value && (
          <UserPopover currentID={row?.adOid}>
            <StyledModifiedby> {value} </StyledModifiedby>
          </UserPopover>
        ),
    },
    {
      title: t('oneDrive.myFiles.columns.size'),
      dataIndex: 'size',
      key: 'size',
      width: '10%',
    },
    {
      dataIndex: 'download',
      key: 'download',
      width: '5%',
      render: (value) =>
        value && (
          <Button type="link" href={value} download target="_blank">
            <DownloadOutlined />
          </Button>
        ),
    },
  ];

  const data = tableData?.map((folder, i) => {
    const isFolder = !!folder.folder;

    return {
      file: isFolder ? <FolderIconStyled /> : <FileIconStyled />,
      name: folder.name,
      modified: moment(folder.lastModifiedDate).format('MM.DD.YYYY HH:mm'),
      modifiedBy: folder.lastModifiedBy,
      adOid: folder.lastModifiedByAdOid,
      size: measureSizeOfFile(folder.size),
      webUrl: isFolder ? undefined : folder.webUrl,
      download: isFolder ? undefined : folder.downloadUrl,
      id: folder.id,
      key: i,
    };
  });

  useEffect(() => {
    if (rootFolder.status === RequestStatus.rejected) {
      showError();
    }
  }, [rootFolder.status, showError, t]);

  const onCreateNewFolder = useCallback(
    async (folderName, cb) => {
      setIsFolderCreateLoading(true);

      if (currentFolderId) {
        try {
          const { data } = await oneDriveCreateNewFolderInUser.createNewFolder(
            currentFolderId,
            folderName
          );

          dispatch(getOneDriveItemChildrenStart(currentFolderId));
          setBreadCrumbState([...breadCrumbState, { title: data.name, id: data.id }]);
          cb();
        } catch {
          showError();
        } finally {
          setIsFolderCreateLoading(false);
        }
      }
    },
    [breadCrumbState, currentFolderId, dispatch, showError]
  );

  const onUploadFile = useCallback(
    async ({ file }: { file: UploadFile }) => {
      if (currentFolderId) {
        try {
          setIsFileUploading(true);
          const { data } = await createUploadSessionForUser.uploadFile(currentFolderId, {
            name: file.name,
            fileSize: file.size,
          });

          await core.put(data.uploadUrl, file, {
            headers: {
              'Content-Type': file.type,
              'Content-Range': `bytes 0-${file.size - 1}/${file.size}`,
            },
          });

          dispatch(getOneDriveItemChildrenStart(currentFolderId));
        } catch {
          showError();
        }

        setIsFileUploading(false);
      }
    },
    [currentFolderId, dispatch, showError]
  );

  const isDataLoading =
    rootFolder.status === RequestStatus.pending ||
    rootFolder.status === RequestStatus.idle ||
    (!!currentFolderId && folderChildren[currentFolderId]?.status === RequestStatus.pending) ||
    isFileUploading;

  return (
    <>
      <OneDiveSubHeader bordered={false}>
        <Space size="large">
          <OneDriveCreateFolderPopup
            isLoading={isFolderCreateLoading}
            disabled={isDataLoading}
            onSubmit={onCreateNewFolder}
          >
            <OneDriveButton type="link">
              <FolderOutlined />
              {t('oneDrive.myFiles.newFolder')}
            </OneDriveButton>
          </OneDriveCreateFolderPopup>
          <RestrictedUpload customRequest={onUploadFile} multiple={false} showUploadList={false}>
            <OneDriveButton disabled={isDataLoading} type="link">
              <UploadOutlined />
              {t('oneDrive.myFiles.uploadFile')}
            </OneDriveButton>
          </RestrictedUpload>
        </Space>
      </OneDiveSubHeader>

      <OneDriveWrapper>
        <OneDriveBreadCrumbs onClick={onClickBreadcrumb} list={breadCrumbState} />

        <Card>
          <StyledTable
            loading={isDataLoading}
            columns={columns}
            dataSource={data}
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </Card>

        <OneDriveDragger
          customRequest={onUploadFile}
          disabled={isDataLoading}
          showUploadList={false}
        >
          <TextStyled size={16} type="secondary">
            {t('oneDrive.myFiles.dragAndDropToUpload')}
          </TextStyled>
        </OneDriveDragger>
      </OneDriveWrapper>
    </>
  );
};

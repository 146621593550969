import styled from 'styled-components';
import { colors } from 'ui/elements';
import { Col, Row, Typography } from 'antd';

const { Text } = Typography;

export const StyledRow = styled(Row)`
  flex-grow: 1;
  flex-flow: column nowrap;
`;
export const StyledOHTitleContainer = styled(Col)`
  flex: unset;
  width: 100%;
  margin-bottom: 30px;
`;
export const StyledOHUserName = styled(Text)<{ displayunderline: string }>`
  &:hover {
    text-decoration: ${(props) => props.displayunderline};
  }
`;
export const StyledOHNode = styled.div`
  padding: 5px;
  border-radius: 8px;
  display: inline-block;
`;

export const StyledOHName = styled.span`
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: ${colors.primaryColor};
`;

export const StyledOHTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: ${colors.grey};
`;

export const StyledOHTreeContainer = styled(Col)`
  width: 100%;
  overflow: auto;
  min-width: 100%;
  scrollbar-width: thin !important;
  scrollbar-color: ${colors.scrollbarColor} ${colors.white};
  -ms-overflow-style: auto;
  position: relative;
  flex-grow: 1;
  max-height: 90%;

  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > ul {
    position: absolute;
    width: 100%;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.scrollbarColor};
    border-radius: 2px;
  }

  &::-webkit-scrollbar {
    display: block;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.white};
  }
`;

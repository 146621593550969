import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetPaySlipsFail,
  GetPaySlipsSuccess,
  GetPaySlipsStart,
  getPaySlipsStart,
  getPaySlipsSuccess,
  getPaySlipsFail,
} from './paySlipsSlice';

export const getPaySlipsEpic: Epic<
  GetPaySlipsStart,
  GetPaySlipsSuccess | GetPaySlipsFail,
  RootState,
  typeof API
> = (action$, _, { paySlips }) =>
  action$.pipe(
    filter(getPaySlipsStart.match),
    exhaustMap(({ payload }) =>
      from(paySlips.getPaySlips(payload.userId, payload.year, payload.params)).pipe(
        map(({ data }) => getPaySlipsSuccess(data)),
        catchError((error) => of(getPaySlipsFail(error?.response?.data)))
      )
    )
  );

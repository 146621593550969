import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { App } from 'app/App';
import reportWebVitals from './reportWebVitals';

const { NODE_ENV, REACT_APP_ENV } = process.env;
const APP_HTML_ELEMENT_ID = '⚛';

const index = async () => {
  if (NODE_ENV !== 'production' || REACT_APP_ENV !== 'local') {
    const api = await import('api/mocks/browser');

    await api.worker.start({
      serviceWorker: {},
    });
  }

  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    document.getElementById(APP_HTML_ELEMENT_ID)
  );
};

index();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import styled from 'styled-components';
import { Card, Typography } from 'antd';
import { colors } from 'ui/elements';

const { Text } = Typography;
export const ListDot = styled.span`
  border-radius: 55%;
  background-color: ${(props: { $color: string }) => (props.$color ? props.$color : null)};

  display: inline-block;
  height: 9px;
  width: 9px;
  margin-right: 5px;
`;
export const ListStatus = styled.li`
  padding-right: 25px;
  list-style-type: none;
`;

export const UlStatus = styled.ul`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const StyledText = styled(Text)`
  font-size: 18px;
  color: ${colors.textColorSecondary};
`;

export const CalendarHeaderWrapper = styled(Card)`
  .ant-card-body {
    padding: 0px;
    display: inline;
  }
  border: none;
`;

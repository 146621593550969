import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { ExpenseData, RequestCommonError, RequestStatus } from 'types';
import { ProfileExpenseState } from './ProfileExpenseTypes';

export const emptyProfileExpenseState: ProfileExpenseState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetProfileExpenseStart = PayloadAction<{
  adOid: string;
  params?: AxiosRequestConfig['params'];
}>;
export type GetProfileExpenseSuccess = PayloadAction<ExpenseData>;
export type GetProfileExpenseFail = PayloadAction<RequestCommonError>;

const profileExpense = createSlice({
  name: 'profileExpense',
  initialState: emptyProfileExpenseState,
  reducers: {
    getProfileExpenseStart(state, _: GetProfileExpenseStart) {
      state.status = RequestStatus.pending;
    },
    getProfileExpenseSuccess(state, action: GetProfileExpenseSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getProfileExpenseFail(state, action: GetProfileExpenseFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getProfileExpenseStart,
  getProfileExpenseSuccess,
  getProfileExpenseFail,
} = profileExpense.actions;
export default profileExpense.reducer;

import React, { FC, useMemo } from 'react';
import { TType } from 'translations';
import { Col } from 'antd';
import { WorkingYears, LastIncrease, HireDate, EmployeeIDIcon } from 'assets';
import moment from 'moment';
import { ProfileEntryField } from 'ui';
import {
  StyledPrivateProfileGeneralInfoWrapper,
  StyledPrivateProfileGeneralInfoCard,
  StyledPrivateProfileGeneralInfoCardHeader,
  StyledPrivateProfileGeneralInfoCardBody,
  StyledPrivateProfileGeneralInfoCardDescription,
  StyledFullWidthRow,
} from './ProfileEmploymentDetails.styled';

export interface IPrivateProfileGeneralInfo {
  hireDate: string;
  employeeId: string;
  lastPromotionDate: string;
  yearsWorkedInTheCompany: string;
  employmentType: string;
  assignmentStatus: string;
}

type Props = {
  t: TType;
  generalInfo?: IPrivateProfileGeneralInfo;
};

export const PrivateProfileGeneralInfo: FC<Props> = ({ t, generalInfo }) => {
  const hireDate: string = useMemo(() => moment(generalInfo?.hireDate).format('DD.MM.YYYY'), [
    generalInfo,
  ]);
  const lastPromotionDate: string = useMemo(
    () =>
      generalInfo?.lastPromotionDate
        ? moment(generalInfo?.lastPromotionDate, 'DD/MM/YYYY').format('DD.MM.YYYY')
        : '-',
    [generalInfo]
  );
  const yearsWorkedInTheCompany: string = useMemo(() => {
    return generalInfo?.yearsWorkedInTheCompany ?? '-';
  }, [generalInfo]);

  const employeeId: string = useMemo(() => {
    return generalInfo?.employeeId ?? '-';
  }, [generalInfo]);

  const employmentType: string = useMemo(() => {
    return generalInfo?.employmentType ?? '-';
  }, [generalInfo]);

  const assignmentStatus: string = useMemo(() => {
    return generalInfo?.assignmentStatus ?? '-';
  }, [generalInfo]);

  return (
    <StyledPrivateProfileGeneralInfoWrapper>
      <StyledFullWidthRow gutter={[15, 15]}>
        <Col sm={24} md={12} lg={8} xl={6}>
          <StyledPrivateProfileGeneralInfoCard>
            <StyledPrivateProfileGeneralInfoCardHeader>
              {hireDate}
            </StyledPrivateProfileGeneralInfoCardHeader>
            <StyledPrivateProfileGeneralInfoCardBody>
              <HireDate />
              <StyledPrivateProfileGeneralInfoCardDescription>
                {t('user.privateProfile.hireDate')}
              </StyledPrivateProfileGeneralInfoCardDescription>
            </StyledPrivateProfileGeneralInfoCardBody>
          </StyledPrivateProfileGeneralInfoCard>
        </Col>
        <Col sm={24} md={12} lg={8} xl={6}>
          <StyledPrivateProfileGeneralInfoCard>
            <StyledPrivateProfileGeneralInfoCardHeader>
              {lastPromotionDate}
            </StyledPrivateProfileGeneralInfoCardHeader>
            <StyledPrivateProfileGeneralInfoCardBody>
              <LastIncrease />
              <StyledPrivateProfileGeneralInfoCardDescription>
                {t('user.privateProfile.lastPromotionDate')}
              </StyledPrivateProfileGeneralInfoCardDescription>
            </StyledPrivateProfileGeneralInfoCardBody>
          </StyledPrivateProfileGeneralInfoCard>
        </Col>
        <Col sm={24} md={12} lg={8} xl={6}>
          <StyledPrivateProfileGeneralInfoCard>
            <StyledPrivateProfileGeneralInfoCardHeader>
              {yearsWorkedInTheCompany}
            </StyledPrivateProfileGeneralInfoCardHeader>
            <StyledPrivateProfileGeneralInfoCardBody>
              <WorkingYears />
              <StyledPrivateProfileGeneralInfoCardDescription>
                {t('user.privateProfile.yearsWorkedInTheCompany')}
              </StyledPrivateProfileGeneralInfoCardDescription>
            </StyledPrivateProfileGeneralInfoCardBody>
          </StyledPrivateProfileGeneralInfoCard>
        </Col>
        <Col sm={24} md={12} lg={8} xl={6}>
          <StyledPrivateProfileGeneralInfoCard>
            <StyledPrivateProfileGeneralInfoCardHeader>
              {employeeId}
            </StyledPrivateProfileGeneralInfoCardHeader>
            <StyledPrivateProfileGeneralInfoCardBody>
              <EmployeeIDIcon />
              <StyledPrivateProfileGeneralInfoCardDescription>
                {t('user.privateProfile.employeeId')}
              </StyledPrivateProfileGeneralInfoCardDescription>
            </StyledPrivateProfileGeneralInfoCardBody>
          </StyledPrivateProfileGeneralInfoCard>
        </Col>
      </StyledFullWidthRow>
      <StyledFullWidthRow gutter={[25, 15]} wrap={true}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <ProfileEntryField label={t('user.privateProfile.workerType')} value={employmentType} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <ProfileEntryField
            label={t('user.privateProfile.assignmentStatus')}
            value={assignmentStatus}
          />
        </Col>
      </StyledFullWidthRow>
    </StyledPrivateProfileGeneralInfoWrapper>
  );
};

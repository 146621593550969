import {
  LeaveInfo,
  LeavesByReason,
  LeavesTypesData,
  SubmitRescheduleForm,
  TeamMembers,
  LeavesDelegateType,
} from 'types';
import {
  Core,
  LEAVES_COMPLETED,
  LEAVES_PLANNED,
  LEAVES_REMAINING,
  LEAVES_INFO,
  LEAVES_LIST,
  LEAVES_TYPES_LIST,
  LEAVES_ALL_TYPES_LIST,
  LEAVES_REASON,
  MY_TEAM_LEAVES_LIST,
  LEAVES_ASSIGNED_DELEGATORS,
} from 'api';
import { LeavesListData, LeavesListItem } from 'store/leavesList/leavesListTypes';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import {
  MY_TEAM_LEAVE_DRAWER_DETAILS,
  MY_TEAM_MEMBERS_LIST,
  LEAVE_RESCHEDULE,
  LEAVES_DELEGATORS,
} from 'api/Core';

interface GetLeavesList {
  (hcmPersonId: string, params?: AxiosRequestConfig['params']): Promise<{ data: LeavesListData }>;
}

interface GetLeavesTypes {
  (hcmPersonId: string, params?: AxiosRequestConfig['params']): Promise<{
    data: LeavesTypesData[];
  }>;
}

interface GetLeavesAllTypes {
  (params?: AxiosRequestConfig['params']): Promise<{
    data: LeavesTypesData[];
  }>;
}

interface GetLeaveInfo {
  (leaveId: string): Promise<{ data: LeaveInfo }>;
}

interface GetLeaveRemaining {
  (hcmPersonId: string, leaveId: string): Promise<{ data: { remaining_balance: string } }>;
}

interface GetLeavePlanned {
  (hcmPersonId: string, leaveId: string): Promise<{ data: { planned_balance: string } }>;
}

interface GetLeaveCompleted {
  (hcmPersonId: string, leaveId: string): Promise<{ data: { completed_balance: string } }>;
}

interface GetLeavesByReason {
  (hcmPersonId: string): Promise<{ data: LeavesByReason[] }>;
}

interface GetMyTeamLeaveList {
  (hcmPersonId: string, params?: AxiosRequestConfig['params']): Promise<{ data: LeavesListItem[] }>;
}

interface GetTeamMembers {
  (hcmPersonId: string): Promise<{ data: TeamMembers[] }>;
}

interface GetMyTeamLeavesDrawerInfo {
  (leaveId: string, adoId: string): Promise<{ data: LeaveInfo }>;
}

interface PostRescheduleLeave {
  (data: AxiosRequestConfig['data']): AxiosPromise<SubmitRescheduleForm>;
}

interface GetDelegators {
  (): Promise<{ data: LeavesDelegateType[] }>;
}

export interface GetLeaveAssignedDelegatesPayload {
  startDate: string;
  endDate: string;
}

interface GetLeaveAssignedDelegates {
  (params: GetLeaveAssignedDelegatesPayload): Promise<{ data: LeavesDelegateType[] }>;
}

class Leaves extends Core {
  getLeavesList: GetLeavesList = async (hcmPersonId, params) =>
    this.get(LEAVES_LIST(hcmPersonId), { params });
  getLeavesTypes: GetLeavesTypes = async (hcmPersonId, params) =>
    this.get(LEAVES_TYPES_LIST(hcmPersonId), params);
  getLeaveInfo: GetLeaveInfo = async (leaveId) => this.get(LEAVES_INFO(leaveId));
  getLeaveRemaining: GetLeaveRemaining = async (hcmPersonId, leaveId) =>
    this.get(LEAVES_REMAINING(hcmPersonId, leaveId));
  getLeavePlanned: GetLeavePlanned = async (hcmPersonId, leaveId) =>
    this.get(LEAVES_PLANNED(hcmPersonId, leaveId));
  getLeaveCompleted: GetLeaveCompleted = async (hcmPersonId, leaveId) =>
    this.get(LEAVES_COMPLETED(hcmPersonId, leaveId));
  getLeavesAllTypes: GetLeavesAllTypes = async (params) =>
    this.get(LEAVES_ALL_TYPES_LIST(), params);
  getLeavesByReasons: GetLeavesByReason = async (hcmPersonId) =>
    this.get(LEAVES_REASON(hcmPersonId));
  getMyTeamLeaveList: GetMyTeamLeaveList = async (hcmPersonId, params) =>
    this.get(MY_TEAM_LEAVES_LIST(hcmPersonId), params);
  getMyTeamMembersList: GetTeamMembers = async (hcmPersonId) =>
    this.get(MY_TEAM_MEMBERS_LIST(hcmPersonId));
  getMyTeamLeaveDrawerInfo: GetMyTeamLeavesDrawerInfo = async (leaveId, adoId) =>
    this.get(MY_TEAM_LEAVE_DRAWER_DETAILS(leaveId, adoId));
  postRescheduleLeave: PostRescheduleLeave = async (data) => this.post(LEAVE_RESCHEDULE(), data);
  getDelegatorsList: GetDelegators = async () => this.get(LEAVES_DELEGATORS());
  getAssignedDelegatorsList: GetLeaveAssignedDelegates = async ({ startDate, endDate }) =>
    this.get(LEAVES_ASSIGNED_DELEGATORS(startDate, endDate));
}

export const leaves = new Leaves();

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { RequestCommonError, RequestStatus } from 'types';
import { SubordinatesState, Data } from './subordinatesTypes';

export const emptySubordinatesInitialState: SubordinatesState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetSubordinatesStart = PayloadAction<{
  params: AxiosRequestConfig;
  key: string;
}>;
export type GetSubordinatesSuccess = PayloadAction<Data>;
export type GetSubordinatesFail = PayloadAction<RequestCommonError>;

const subordinates = createSlice({
  name: 'subordinates',
  initialState: emptySubordinatesInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getSubordinatesStart(state, _: GetSubordinatesStart) {
      state.status = RequestStatus.pending;
    },
    getSubordinatesSuccess(state, action: GetSubordinatesSuccess) {
      state.status = RequestStatus.resolved;

      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    getSubordinatesFail: (state, action: GetSubordinatesFail) => {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const {
  getSubordinatesStart,
  getSubordinatesSuccess,
  getSubordinatesFail,
} = subordinates.actions;

export default subordinates.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus, RequestsCatalogDrawerTypes } from 'types';
import { RequestCatalogDrawerState } from './requestCatalogDrawerTypes';

export const emptyRequestCatalogDrawerState: RequestCatalogDrawerState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetRequestCatalogDrawerStart = PayloadAction<{ requestName: string }>;
export type GetRequestCatalogDrawerSuccess = PayloadAction<RequestsCatalogDrawerTypes>;
export type GetRequestCatalogDrawerFail = PayloadAction<RequestCommonError>;
export type GetRequestCatalogDrawerReset = PayloadAction;

const requestCatalogDrawerSlice = createSlice({
  name: 'requestCatalogDrawer',
  initialState: emptyRequestCatalogDrawerState,
  reducers: {
    getRequestCatalogDrawerStart(state, _: GetRequestCatalogDrawerStart) {
      state.status = RequestStatus.pending;
    },
    getRequestCatalogDrawerSuccess(state, action: GetRequestCatalogDrawerSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getRequestCatalogDrawerFail(state, action: GetRequestCatalogDrawerFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getRequestCatalogDrawerReset(state, _: GetRequestCatalogDrawerReset) {
      state.status = RequestStatus.idle;
      state.error = null;
      state.data = undefined;
    },
  },
});

export const {
  getRequestCatalogDrawerStart,
  getRequestCatalogDrawerSuccess,
  getRequestCatalogDrawerFail,
  getRequestCatalogDrawerReset,
} = requestCatalogDrawerSlice.actions;
export default requestCatalogDrawerSlice.reducer;

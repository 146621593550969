import { AllRequestsCard } from 'types';
import { Core, SUB_CATEGORY_CARD, SUB_CATEGORY_CARD_OLD } from 'api';

interface GetSubCategoryCard {
  (subCategoryName: string): Promise<{ data: AllRequestsCard }>;
}

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

class SubCategoryCard extends Core {
  getSubCategoryCard: GetSubCategoryCard = async (subCategoryName: string) =>
    this.get(
      disableAggregator
        ? SUB_CATEGORY_CARD_OLD(subCategoryName)
        : SUB_CATEGORY_CARD(subCategoryName)
    );
}

export const subCategoryCard = new SubCategoryCard();

import { useServicesContext } from 'app';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslate } from 'translations';
import { DrawerUi, FullWidthSpin, useErrorNotification } from 'ui';
import { GeneratedNotification } from './components';
import { useNotificationDrawerInfo } from './components/useGetNotificationDrawer';
//Todo: uncomment this line when mark all as read is integrated
// import { NotificationActions } from './notificationActions';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  refreshBadge: () => void;
}
const NotificationDrawerComponent: React.FC<Props> = ({
  isVisible,
  setIsVisible,
  refreshBadge,
}) => {
  const {
    filterList,
    hasMore,
    loadMoreNotifications,
    onClose,
    listLength,
    isErrorFetchNotificationList,
    isThereData,
  } = useNotificationDrawerInfo(isVisible, setIsVisible);
  const { t } = useTranslate();
  const { setIsFilterByPrettyId, setPrettyIdInfoRedirection } = useServicesContext();
  useEffect(() => {
    if (isVisible === true) {
      setIsFilterByPrettyId(false);
      setPrettyIdInfoRedirection({});
    }
  }, [isVisible, setIsFilterByPrettyId, setPrettyIdInfoRedirection]);
  useErrorNotification(
    {
      isNotification: isErrorFetchNotificationList,
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    },
    [isErrorFetchNotificationList]
  );
  return (
    <DrawerUi
      visible={isVisible}
      drawerTitle={'Notification'}
      keyboard
      onClose={onClose}
      destroyOnClose
      //Todo: uncomment this line when mark all as read is integrated
      // extra={<NotificationActions />}
    >
      <>
        {isThereData ? (
          <FullWidthSpin />
        ) : isErrorFetchNotificationList ? (
          onClose()
        ) : (
          filterList && (
            <InfiniteScroll
              loadMore={loadMoreNotifications}
              hasMore={hasMore}
              useWindow={false}
              loader={<FullWidthSpin key={0} />}
              initialLoad={false}
            >
              <GeneratedNotification
                notificationList={filterList}
                key={listLength}
                refreshList={onClose}
                refreshBadge={refreshBadge}
              />
            </InfiniteScroll>
          )
        )}
      </>
    </DrawerUi>
  );
};
export const NotificationDrawer = React.memo(NotificationDrawerComponent);

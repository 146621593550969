import styled from 'styled-components';
import { Typography } from 'antd';
import { colors, typography } from 'ui/elements';

const { Text } = Typography;

export const StyledUserNameText = styled(Text)`
  font-size: ${typography.size.zetta};
  font-weight: ${typography.weight.semiBold};
  color: ${colors.primaryColor};
`;

import React from 'react';
import { EventProps } from 'react-big-calendar';
import { EventList } from 'ui/components';
import { Col, Row, Space, Typography } from 'antd';
import { StyledWarringIcon } from '../CalendarView/CalendarStyle';

export const CalendarEvent: React.FC<EventProps<EventList>> = ({ event }) => {
  const { Text } = Typography;
  return (
    <React.Fragment>
      <Row>
        <Space size={'small'} direction={'horizontal'} align={'start'} style={{ width: 'inherit' }}>
          {event.isAwaitingForApproval && (
            <Col>
              <StyledWarringIcon />
            </Col>
          )}
          <Col>
            <Text ellipsis>{event.employeeName}</Text>
          </Col>
          <Col>
            <Text strong ellipsis>
              {event.title}
            </Text>
          </Col>
        </Space>
      </Row>
    </React.Fragment>
  );
};

import styled from 'styled-components';
import { Col } from 'antd';

export const FilterColStyled = styled(Col)`
  #FilterForm_startDate,
  #FilterForm_endDate {
    width: 100px;
  }

  #FilterForm_createdOn {
    width: 120px;
  }

  #FilterForm_createDate,
  #FilterForm_dueDate {
    width: 110px;
  }
`;

import { useQuery } from 'react-query';
import { QUERIES } from '../../constants';
import { categoriesTree } from '../CategoriesTree';

export function useCategoriesTree() {
  return useQuery(
    [QUERIES.categoriesTree],
    async () => {
      const { data } = await categoriesTree.getCategoriesTree();
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}

import { createContext, useContext, Dispatch, SetStateAction } from 'react';

interface TasksContextType {
  currentActiveTab?: string;
  setCurrentActiveTab: Dispatch<SetStateAction<TasksContextType['currentActiveTab']>>;
  processInstanceId: string;
  setProcessInstanceId: Dispatch<SetStateAction<string>>;
  rootPath?: string;
  setRootPath: Dispatch<SetStateAction<string | undefined>>;
  commentsRequestId: string;
  setCommentsRequestId: Dispatch<SetStateAction<string>>;
  isSubmitLoading: boolean;
  setIsSubmitLoading: Dispatch<SetStateAction<boolean>>;
  isSubmitDisable: boolean;
  setIsSubmitDisable: Dispatch<SetStateAction<boolean>>;
  isFormValuesChanged: boolean;
  setIsFormValuesChanged: Dispatch<SetStateAction<boolean>>;
}

export const TasksContext = createContext({} as TasksContextType);

export const useTasksContext = () => {
  const context = useContext<TasksContextType>(TasksContext);

  if (!context) {
    throw new Error('useTasksContext should be used within a TasksContext');
  }
  return context;
};

export const hierarchy = {
  id: '300000002525049',
  displayName: 'Abdullah Abdulrahman Alzmame',
  title: 'Chief',
  photoId:
    '00020000000EACED00057708000110D9319DF7120000004AACED00057372000D6A6176612E73716C2E4461746514FA46683F3566970200007872000E6A6176612E7574696C2E44617465686A81014B5974190300007870770800000177DBA2900078-',
  subordinates: [
    {
      id: '300000002865836',
      displayName: 'Saeed Musharraf AlShehri',
      title: 'Chief',
      photoId:
        '00020000000EACED00057708000110D9319DF7120000004AACED00057372000D6A6176612E73716C2E4461746514FA46683F3566970200007872000E6A6176612E7574696C2E44617465686A81-100000003725597',
      subordinates: [
        {
          id: '300000003475854',
          displayName: 'AlMohannad Saad Aleshiwi',
          title: 'Secretary',
        },
        {
          id: '300000002594218',
          displayName: 'Mohammed Homoud Al Kussayer',
          title: 'General Manager',
          subordinatesIds: [
            '300000002627520',
            '300000002962630',
            '300000002638770',
            '300000002954406',
            '300000003106153',
          ],
        },
        {
          id: '300000003438200',
          displayName: 'Mohammed Saud AlNuhayt',
          title: 'General Manager',
          self: true,
          subordinates: [
            {
              id: '300000002962445',
              displayName: 'Abdulmohsen Abdullatif Al Manna',
              title: 'Specialist',
            },
            {
              id: '300000003024318',
              displayName: 'Abdulrahman Yahya Alyami',
              title: 'Engineer',
            },
            {
              id: '300000003024182',
              displayName: 'Ahmed Ali  Bamqabl',
              title: 'Director',
              subordinatesIds: ['300000004816304', '300000004997029'],
            },
            {
              id: '300000002951748',
              displayName: 'Alhassan HabibAllah Binsaleh',
              title: 'Manager',
            },
            {
              id: '300000002943762',
              displayName: 'Amr Sami Mohamed',
              title: 'E9005',
            },
            {
              id: '300000002857001',
              displayName: 'Fahad Abdullah Al Shahrani',
              title: 'Lead Specialist',
            },
            {
              id: '300000002861004',
              displayName: 'Faisal  Abdulrahman Al Mutairi',
              title: 'Lead Specialist',
            },
            {
              id: '300000003387400',
              displayName: 'Faisal Meshari AlMeshari',
              title: 'Lead Specialist',
            },
            {
              id: '300000002635535',
              displayName: 'Mohammad  Ismail Ghatasheh',
              title: 'Lead Specialist',
            },
            {
              id: '300000003106776',
              displayName: 'Mohammed Saud AlNuhayt',
              title: 'Manager',
            },
            {
              id: '300000003094014',
              displayName: 'Monther Abdullah AlOgayel',
              title: 'Manager',
            },
            {
              id: '300000002861176',
              displayName: 'Nasser Ibrahim Al Humud',
              title: 'Lead Specialist',
            },
            {
              id: '300000002946246',
              displayName: 'Osama mahmoud Ammar',
              title: 'Lead Specialist',
            },
            {
              id: '300000003387687',
              displayName: 'Riyadh Nasser AlShahrani',
              title: 'Lead Specialist',
            },
            {
              id: '300000003368690',
              displayName: 'Saeed Jallab AlEnazi',
              title: 'Director',
              subordinatesIds: ['300000003441557', '300000003354444'],
            },
            {
              id: '300000003454776',
              displayName: 'Saleh Zamil AlAnazi',
              title: 'Manager',
            },
            {
              id: '300000002943650',
              displayName: 'Siddiq Siddiq Mohiuddin',
              title: 'E9004',
            },
            {
              id: '300000002943874',
              displayName: 'Yaman Abdulahmid Touto',
              title: 'E9006',
            },
          ],
        },
        {
          id: '300000003500172',
          displayName: 'Mohsen Mohammed Badhdah',
          title: 'Manager',
        },
        {
          id: '300000002604036',
          displayName: 'Naif Salem Al Anazi',
          title: 'General Manager',
          subordinatesIds: [
            '300000003368980',
            '300000002635402',
            '300000003096364',
            '300000003378425',
            '300000003043697',
          ],
        },
        {
          id: '300000003106608',
          displayName: 'Rawan Farraj AlQahtani',
          title: 'Specialist',
        },
        {
          id: '300000003094290',
          displayName: 'Waleed Mohammed AlJaber',
          title: 'Director',
          photoId:
            '00020000000EACED00057708000110D9319DF7120000004AACED00057372000D6A6176612E73716C2E4461746514FA46683F3566970200007872000E6A6176612E7574696C2E44617465686A81014B5974190300007870770800000177DBA2900078-100000003725597',
        },
      ],
    },
  ],
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OneDriveSharedData, RequestCommonError, RequestStatus } from 'types';
import { OneDriveSharedState as initialState } from './oneDriveSharedTypes';

export const OneDriveSharedIntialState: initialState = { status: RequestStatus.idle, error: null };

export type GetOneDriveSharedStart = PayloadAction;
export type GetOneDriveSharedSuccess = PayloadAction<{ data: OneDriveSharedData }>;
export type GetOneDriveSharedFail = PayloadAction<{ error: RequestCommonError }>;

const oneDriveShared = createSlice({
  name: 'oneDriveShared',
  initialState: OneDriveSharedIntialState,
  reducers: {
    getOneDriveSharedStart(state, _: GetOneDriveSharedStart) {
      state.status = RequestStatus.pending;
    },
    getOneDriveSharedSuccess(state, action: GetOneDriveSharedSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload.data;
    },
    getOneDriveSharedFail(state, action: GetOneDriveSharedFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload.error;
    },
  },
});

export const {
  getOneDriveSharedFail,
  getOneDriveSharedStart,
  getOneDriveSharedSuccess,
} = oneDriveShared.actions;

export default oneDriveShared.reducer;

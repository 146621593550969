import { auth } from 'api/Auth';
import { AxiosRequestConfig, AxiosError, AxiosInstance } from 'axios';
import { getHttpClient, getOutBoundedHttpClient } from './HttpClient';

type Request = <T extends unknown>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any,
  config?: AxiosRequestConfig
) => Promise<T>;

let httpClient: AxiosInstance;
let outBoundedHttpClient: AxiosInstance;
export const initHttpClient = (apiBaseUrl: string) => {
  httpClient = getHttpClient(apiBaseUrl);
};

export const initOutBoundHttpClient = () => {
  outBoundedHttpClient = getOutBoundedHttpClient();
};

const JWT_EXPIRE_MESSAGE = 'Invalid JWT. Either expired or not yet valid.';

export class Core {
  static unauthorizedCallback?: () => void;

  static authInterceptor = (error?: AxiosError) => {
    // TODO: We should rely only on status code, but BE returns different codes.
    // https://tawalitsupport.atlassian.net/browse/TAW-2203
    if (
      error?.response?.status === 401 ||
      error?.response?.data?.errors?.some((error: { code: number; message: string }) =>
        error?.message?.includes(JWT_EXPIRE_MESSAGE)
      )
    ) {
      Core.unauthorizedCallback && Core.unauthorizedCallback();
    }

    return Promise.reject<AxiosError>(error);
  };

  static setInterceptor = (unauthorizedCallback?: () => void): void => {
    Core.unauthorizedCallback = unauthorizedCallback;

    httpClient.interceptors.response.use(undefined, Core.authInterceptor);

    httpClient.interceptors.request.use(async (req) => {
      if (req.url?.includes('/undefined/') || req.url?.includes('/null/')) {
        throw new Error('Incorrect request');
      }

      await auth.getAccessToken(['User.Read']);

      return req;
    });
  };

  get: Request = async (path, config) => await httpClient.get(path, config);

  put: Request = (path, data, config) => httpClient.put(path, data, config);

  post: Request = async (path, data, params) =>
    await httpClient.post(path, data, {
      ...params,
      headers: {
        ...params?.headers,
      },
    });

  delete: Request = (path, data) => httpClient.delete(path, data);

  setToken = (token: string): void => {
    httpClient.defaults.headers.authorization = `Bearer ${token}`;
  };

  setOnBehalfToken = (token: string): void => {
    httpClient.defaults.headers['On-Behalf-Token'] = token;
  };

  outBoundGet: Request = async (url, config) => await outBoundedHttpClient.get(url, config);
}

export const core = new Core();

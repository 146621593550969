import React, { FC, useCallback, useEffect, useState } from 'react';
import { Col, Collapse, Row } from 'antd';
import { span } from 'ui/elements';
import { RequestSteps, TaskStatus, FormFields } from 'types';
import { FullWidthSpin, TextStyled, useRowGutter } from 'ui';
import { GeneratedForm } from 'ui/components/GeneratedForm';
import { FieldData } from 'rc-field-form/lib/interface';
import { useTranslate } from 'translations';
import { FormInstance } from 'antd/lib/form';
import { Gutter } from 'antd/lib/grid/row';
import moment from 'moment';
import { useHomeLoanInterest } from 'api/StaticForm/hooks';
import { useServicesContext } from 'app';
import { FormCol, StyledCard, StyledDivider } from './TaskStep.styled';
import { AssigneeInfo, TaskStepPanelHeader } from './Components';
import { HomeLoanInitiateFieldName, HomeLoanQueryProps, checkDependentField } from './helper';

const { Panel } = Collapse;

interface TaskStepProps {
  disabled?: boolean;
  status: TaskStatus;
  isOpened?: boolean;
  highlighted?: boolean;
  noAssigned?: boolean;
  taskData?: RequestSteps;
  processDefinitionKey?: string;
  processId?: string;

  reloadTaskList?: () => void;
  formData: FormFields;
  isLoading: boolean;
  isAssignee: string;
  form: FormInstance;
  groupName: [string];
  tasksStatus: TaskStatus;
}

const { span24 } = span;
const GUTTER_0_10: [Gutter, Gutter] = [0, 10];

const disableLease = process.env.REACT_APP_DISABLE_LEASE === 'true';

export const TaskStep: FC<TaskStepProps> = ({
  disabled = false,
  status,
  isOpened,
  highlighted,
  taskData,
  reloadTaskList,
  formData,
  isLoading,
  isAssignee,
  form,
  groupName,
  tasksStatus,
  processDefinitionKey,
  processId,
}) => {
  const { t } = useTranslate();
  const GUTTER_24 = useRowGutter();
  const taskId = taskData?.taskId;

  const [triggerField, setTriggerField] = useState<string | undefined>(undefined);
  const [newEndDate, setNewEndDate] = useState<moment.Moment | undefined>(undefined);
  const [loanAmount, setLoanAmount] = useState<number | undefined>(undefined);
  const [interestRate, setInterestRate] = useState<number | undefined>(undefined);
  const [duration, setDuration] = useState<number | undefined>(undefined);
  const [homeLoadParams, setHomeLoadParams] = useState<HomeLoanQueryProps | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timeOutId, setTimeOutId] = useState<ReturnType<typeof setTimeout>>();

  const { setFieldHidden, setDependenciesFieldsBetweenForms } = useServicesContext();

  const onFieldsChange = useCallback(
    (changedFields: FieldData[]) => {
      const [changeField] = changedFields;

      const { name, value } = changeField || ({} as FieldData);

      if (Array.isArray(name) && name.length) {
        const [fieldName] = name;

        if (fieldName === 'startDate') {
          setNewEndDate(moment(value));
        }

        if (fieldName === HomeLoanInitiateFieldName.loanTerm && typeof value === 'number') {
          setDuration(value);
        }

        if (fieldName === HomeLoanInitiateFieldName.loanAmount && typeof value === 'number') {
          setLoanAmount(value);
        }

        if (fieldName === HomeLoanInitiateFieldName.interestRate && typeof value === 'number') {
          setInterestRate(value);
        }

        // handle the hide and disable the field for allocatedBudge , to improve it we need to return this field name from the BE
        if (fieldName === 'allocatedBudge') {
          checkDependentField({
            fieldName: fieldName,
            value: value,
            form: form,
            fieldType: 'textArea',
            action: 'hidden',
            formData: formData,
            setFieldHidden,
          });
          checkDependentField({
            fieldName: fieldName,
            value: value,
            form: form,
            fieldType: 'button',
            action: 'disabled',
            formData: formData,
            setFieldHidden,
            taskId,
            setDependenciesFieldsBetweenForms,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, formData]
  );

  useEffect(() => {
    setFieldHidden({ isHidden: null, fieldId: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    const formConfig = formData.filter((field) => field?.properties?.triggerFiled);

    const triggerFiledName = formConfig[0]?.properties?.triggerFiled;

    triggerFiledName ? setTriggerField(triggerFiledName) : setTriggerField(undefined);
  }, [formData]);

  const isLoanBody =
    loanAmount !== undefined && interestRate !== undefined && duration !== undefined;

  const { data: homeLoanInterest, isSuccess, isError, refetch } = useHomeLoanInterest({
    params: {
      ...homeLoadParams,
    },
  });

  useEffect(() => {
    if (isSuccess && timeOutId) {
      setTimeOutId(undefined);
    }
  }, [isSuccess, timeOutId]);

  useEffect(() => {
    if (homeLoanInterest !== undefined && !isError) {
      Object.entries(homeLoanInterest).map(([fieldName, fieldValue]) => {
        return form.setFieldsValue({ [fieldName]: fieldValue });
      });
    }
    if (isError || homeLoadParams === undefined) {
      form.resetFields([
        HomeLoanInitiateFieldName.interestAmount,
        HomeLoanInitiateFieldName.monthlyInstallment,
      ]);
    }
  }, [form, homeLoadParams, homeLoanInterest, isError, timeOutId]);

  useEffect(() => {
    if (isLoanBody) {
      setHomeLoadParams({
        loanAmount: loanAmount,
        fixedInterestRate: interestRate,
        loanTerm: duration,
      });
    } else {
      setHomeLoadParams(undefined);
    }
  }, [duration, interestRate, isLoanBody, loanAmount]);

  useEffect(() => {
    if (triggerField) {
      if (newEndDate && duration) {
        const startDate = newEndDate.clone();
        const endDateCalculation = startDate.add(duration, 'M');
        endDateCalculation && form.setFieldsValue({ [triggerField]: endDateCalculation });
      } else {
        form.setFieldsValue({ [triggerField]: undefined });
      }
    }
  }, [duration, form, newEndDate, triggerField]);

  useEffect(() => {
    if (homeLoadParams) {
      const timeOut = setTimeout(() => {
        refetch();
      }, 400);
      setTimeOutId(timeOut);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeLoadParams]);

  return (
    <React.Fragment>
      {formData.length > 0 && (
        <StyledCard $highlighted={highlighted}>
          <Collapse
            defaultActiveKey={isOpened ? '1' : undefined}
            collapsible={disabled ? 'disabled' : undefined}
            className="tasks_collapse"
            ghost
          >
            <Panel
              header={
                <TaskStepPanelHeader
                  disabled={disabled}
                  header={taskData?.taskName}
                  status={status}
                />
              }
              style={{ alignItems: 'center' }}
              key="1"
              extra={
                <AssigneeInfo
                  taskInfo={taskData}
                  t={t}
                  reloadTaskList={reloadTaskList}
                  tabStatus={tasksStatus}
                />
              }
            >
              <Row gutter={GUTTER_0_10}>
                <Col span={span24}>
                  <Row justify={'space-between'} gutter={GUTTER_24}>
                    <Col>
                      <TextStyled type="secondary">
                        {disableLease
                          ? taskData?.prettyId
                          : t('task.taskId', { taskId: taskData?.prettyId })}
                      </TextStyled>
                    </Col>
                    {groupName && (
                      <Col>
                        <TextStyled type="secondary">
                          {t('task.assignedGroup')} {groupName}
                        </TextStyled>
                      </Col>
                    )}
                  </Row>
                </Col>
                <StyledDivider />
                {isLoading ? (
                  <FullWidthSpin />
                ) : (
                  <FormCol span={span24}>
                    <GeneratedForm
                      withLabel={true}
                      formData={formData}
                      formName={taskData?.taskName}
                      form={form}
                      onFieldsChange={onFieldsChange}
                      taskId={taskId}
                      processDefinitionKey={processDefinitionKey}
                      processId={processId}
                    />
                  </FormCol>
                )}
              </Row>
            </Panel>
          </Collapse>
        </StyledCard>
      )}
    </React.Fragment>
  );
};

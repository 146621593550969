import { PublicClientApplication } from '@azure/msal-browser';

import { authConfig, loginRequest } from './authConfig';

export class Auth {
  error: Record<string, unknown | string> | null;

  private publicClientApplication: PublicClientApplication;

  constructor() {
    this.error = null;
    this.publicClientApplication = new PublicClientApplication(authConfig);
  }

  login = async (redirectPath?: string) => {
    if (redirectPath) {
      loginRequest.state = redirectPath;
    }
    try {
      // Login via redirect
      await this.publicClientApplication.loginRedirect(loginRequest);
    } catch (err) {
      this.error = this.normalizeError(err);
    }
  };

  onRedirectCallback = async () =>
    this.publicClientApplication
      .handleRedirectPromise()
      .then((response) => {
        return {
          requestedUrl: response?.state,
          accounts: this.publicClientApplication.getAllAccounts(),
        };
      })
      .catch((e) => {
        throw new Error(e);
      });

  logout = () => {
    this.publicClientApplication.logout();
  };

  async getAccessToken(
    scopes: string[] = [],
    refreshToken?: boolean
  ): Promise<{ idToken: string; accessToken: string; expireTokenOn: Date | null }> {
    const accounts = this.publicClientApplication.getAllAccounts();

    if (accounts.length <= 0) throw new Error('login_required');
    // Get the access token silently
    // If the cache contains a non-expired token, this function
    // will just return the cached token. Otherwise, it will
    // make a request to the Azure OAuth endpoint to get a token
    try {
      const silentResult = await this.publicClientApplication.acquireTokenSilent({
        forceRefresh: refreshToken,
        scopes: scopes,
        account: accounts[0],
      });

      return {
        idToken: silentResult.idToken,
        accessToken: silentResult.accessToken,
        expireTokenOn: silentResult.expiresOn,
      };
    } catch (e) {
      throw new Error('Unable to get token');
    }
  }

  setErrorMessage(message: string, debug: string) {
    this.error = { message: message, debug: debug };
  }

  normalizeError(error: string | Error) {
    let normalizedError = {};
    if (typeof error === 'string') {
      const errParts = error.split('|');
      normalizedError =
        errParts.length > 1 ? { message: errParts[1], debug: errParts[0] } : { message: error };
    } else {
      normalizedError = {
        message: error.message,
        debug: JSON.stringify(error),
      };
    }
    return normalizedError;
  }
}

export const auth = new Auth();

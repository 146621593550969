import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  PostReassignTaskFail,
  PostReassignTaskStart,
  PostReassignTaskSuccess,
  postReassignTaskFail,
  postReassignTaskStart,
  postReassignTaskSuccess,
} from './reassignTaskSlice';

export const reassignTaskEpic: Epic<
  PostReassignTaskStart,
  PostReassignTaskSuccess | PostReassignTaskFail,
  RootState,
  typeof API
> = (action$, _, { reassignTask }) =>
  action$.pipe(
    filter(postReassignTaskStart.match),
    exhaustMap(({ payload }) =>
      from(reassignTask.postReassginTask(payload.taskId, payload.userId, payload.rootPath)).pipe(
        map(() => postReassignTaskSuccess()),
        catchError((error) => of(postReassignTaskFail(error?.response?.data)))
      )
    )
  );

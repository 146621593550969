import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetUserInfoFail,
  GetUserInfoStart,
  GetUserInfoSuccess,
  getUserInfoFail,
  getUserInfoStart,
  getUserInfoSuccess,
} from './userInfoSlice';

export const getUserInfoEpic: Epic<
  GetUserInfoStart,
  GetUserInfoSuccess | GetUserInfoFail,
  RootState,
  typeof API
> = (action$, _, { userInfo }) =>
  action$.pipe(
    filter(getUserInfoStart.match),
    exhaustMap((payload) =>
      from(userInfo.getUserInfo(payload.payload.userId)).pipe(
        map(({ data }) =>
          getUserInfoSuccess({
            userId: payload.payload.userId,
            data,
          })
        ),
        catchError((error) => of(getUserInfoFail(error?.response?.data)))
      )
    )
  );

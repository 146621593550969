import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslate } from 'translations';
import { notification } from 'antd';
import { TextStyled, UserPopover } from 'ui';
import {
  useSelector,
  searchFilesResultData,
  useDispatch,
  getOneDriveFolderSharedStart,
  oneDriveFolderSharedData,
} from 'store';
import moment from 'moment';
import { RequestStatus } from 'types';
import { measureSizeOfFile } from 'utils';
import { BreadcrumbItem, OneDriveBreadCrumbs } from '../../OneDriveBreadCrumbs';
import { OneDriveConfirm } from '../../OneDriveConfirm';
import { OneDriveDownload } from '../../OneDriveDownload';
import { StyledModifiedby } from '../../OneDriveMyFiles/OneDriveMyFiles.styled';
import {
  FileIconStyled,
  OneDriveButton,
  FolderIconStyled,
  StyledTable,
  StyledFiles,
  StyledBreadcrumb,
  StyledText,
  StyledButton,
} from './OneDriveSearchResult.styled';

export const OneDriveSearchResult: FC = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { data: searchResultData, status } = useSelector(searchFilesResultData);
  const folderChildren = useSelector(oneDriveFolderSharedData);
  const [breadCrumbState, setBreadCrumbState] = useState<BreadcrumbItem[]>([
    {
      title: t('oneDrive.searchResult.title'),
      id: 'Search result',
    },
  ]);
  const [tableData, setTableData] = useState(searchResultData?.list);
  const currentFolderId = useMemo(() => breadCrumbState[breadCrumbState.length - 1]?.id, [
    breadCrumbState,
  ]);

  const { driveId, id } = breadCrumbState[breadCrumbState.length - 1];

  const showError = useCallback(() => {
    notification.error({
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    });
  }, [t]);

  const onClickBreadCrumb = useCallback(
    (id) => {
      const currentFolderBreadcrumbIndex = breadCrumbState.findIndex((crumb) => crumb.id === id);

      if (~currentFolderBreadcrumbIndex) {
        setBreadCrumbState(breadCrumbState.slice(0, currentFolderBreadcrumbIndex + 1));
      }
    },
    [breadCrumbState]
  );

  const onConfirm = useCallback((link: string) => {
    window.open(link);
  }, []);

  const onClickFolder = (folderId: string, folderName: string, driveId?: string) => {
    setBreadCrumbState([...breadCrumbState, { id: folderId, title: folderName, driveId }]);
    setTableData([]);
  };

  useEffect(() => {
    if (currentFolderId) {
      if (breadCrumbState.length > 1) {
        if (driveId && folderChildren[driveId]?.[currentFolderId]?.data) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setTableData(folderChildren[driveId]?.[currentFolderId]?.data as any);
        } else {
          if (driveId && id) {
            dispatch(
              getOneDriveFolderSharedStart({
                driveId,
                folderId: id,
              })
            );
          }
        }
      } else {
        setTableData(searchResultData?.list);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadCrumbState]);

  useEffect(() => {
    if (currentFolderId && driveId && folderChildren[driveId]?.[currentFolderId]?.data) {
      const folderData = driveId && folderChildren[driveId]?.[currentFolderId]?.data;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setTableData(folderData as any);
    }

    if (
      currentFolderId &&
      driveId &&
      folderChildren[driveId][currentFolderId]?.status === RequestStatus.rejected
    ) {
      showError();
      setTableData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderChildren]);

  useEffect(() => {
    if (status === RequestStatus.resolved) {
      setTableData(searchResultData?.list);
      setBreadCrumbState([
        {
          title: t('oneDrive.searchResult.title'),
          id: 'Search result',
        },
      ]);
    }

    if (status === RequestStatus.rejected) {
      showError();
      setTableData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnsType<any> = [
    {
      dataIndex: 'file',
      key: 'file',
      width: '5%',
    },
    {
      title: t('oneDrive.myFiles.columns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '40%',
    },
    {
      title: t('oneDrive.searchResult.columns.modified'),
      dataIndex: 'modified',
      key: 'modified',
      width: '20%',
    },
    {
      title: t('oneDrive.searchResult.columns.modifiedBy'),
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      width: '20%',
      render: (value, row) =>
        value && (
          <UserPopover currentID={row?.adOid} currentEmail={row?.email}>
            <StyledModifiedby> {value} </StyledModifiedby>
          </UserPopover>
        ),
    },
    {
      title: t('oneDrive.searchResult.columns.size'),
      dataIndex: 'size',
      key: 'size',
      width: '10%',
    },
    {
      dataIndex: 'download',
      key: 'download',
      width: '5%',
    },
  ];

  const isLoading =
    status === RequestStatus.pending ||
    (!!currentFolderId &&
      !!driveId &&
      folderChildren[driveId]?.[currentFolderId]?.status === RequestStatus.pending);

  const data = tableData?.map((item, index) => ({
    key: index,
    file: item.file ? <FileIconStyled /> : <FolderIconStyled />,
    name: item.file ? (
      <OneDriveButton type="link">
        <OneDriveConfirm onConfirm={() => onConfirm(item.webUrl)}>
          <TextStyled underline>{item?.name}</TextStyled>
        </OneDriveConfirm>
      </OneDriveButton>
    ) : (
      <StyledButton
        type="link"
        onClick={() => onClickFolder(item.id, item.name, item.parentReference.driveId)}
      >
        {item?.name}
      </StyledButton>
    ),
    modified: (
      <StyledText>
        {moment(item?.lastModifiedDate || item.createdDate).format('DD.MM.YYYY HH.mm')}
      </StyledText>
    ),
    modifiedBy: <StyledText>{item?.lastModifiedBy || item.createdBy}</StyledText>,
    size: <StyledText>{measureSizeOfFile(item.size)}</StyledText>,
    download: item.file ? (
      <OneDriveDownload driveId={item.parentReference.driveId} itemId={item.id} />
    ) : undefined,
    email: item?.lastModifiedByEmail,
  }));

  return (
    <>
      <StyledBreadcrumb>
        <OneDriveBreadCrumbs list={breadCrumbState} onClick={onClickBreadCrumb} />
      </StyledBreadcrumb>

      <StyledFiles>
        <StyledTable
          columns={columns}
          loading={isLoading}
          dataSource={data}
          pagination={{
            hideOnSinglePage: true,
          }}
        />
      </StyledFiles>
    </>
  );
};

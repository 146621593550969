import styled from 'styled-components';
import { Collapse, Tree, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { colors } from 'ui/elements';

const { Text } = Typography;

export const HierarchyTree = styled(Tree)`
  .ant-tree-node-content-wrapper {
    min-height: 48px;
    display: flex;
    align-items: center;
  }

  .ant-tree-treenode {
    display: flex;
    align-items: center;
    padding: 0;
  }

  .ant-tree-switcher-icon {
    font-size: 12px;
    color: #001529;
  }

  .ant-tree-switcher {
    min-width: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .ant-tree-icon__customize.ant-tree-iconEle {
    width: auto;
    height: auto;
    margin-right: 14px;
  }

  .ant-tree-treenode:hover {
    background-color: #f6f7f9;
  }

  .ant-tree-title {
    font-size: 16px;
  }

  .ant-typography-secondary {
    font-size: 14px;
    text-decoration: none !important;
  }

  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: initial;
  }

  .ant-tree-treenode-selected {
    background-color: ${colors.lightGreyThird};
  }
`;

export const StyledCollapse = styled(Collapse)`
  width: 100%;

  .ant-collapse-header {
    .anticon.ant-collapse-arrow,
    .ant-spin.ant-collapse-arrow {
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: inherit;
`;
export const StyledUserName = styled(Text)<{ displayunderline: string }>`
  &:hover {
    text-decoration: ${(props) => props.displayunderline};
  }
`;

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Row, Space, notification } from 'antd';

import { useTranslate } from 'translations';
import {
  StyledMentionsInput,
  StyledParagraph,
  StyledSecondaryParagraph,
} from 'ui/components/Chat/ChatSendMessage/ChatSendMessage.styled';
import { Mention, MentionItem } from 'react-mentions';
import { chatSendMessageStyles } from 'ui/components/Chat/ChatSendMessage/chatSendMessageStyles';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { profileData, useSelector } from 'store';
import { FullWidthAndHeightSpin, colors } from 'ui/elements';
import { MAX_COMMENT_LENGTH } from 'ui/components/RestrictedUpload/constants';
import { wrapIntoAnchorTag } from 'utils';
import { RequestStatus } from 'types';
import { ConnectorProps, msTeamsChatSendMessageConnector } from './ChatSendMessage.connector';

export interface ChatSendMessageProps {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  onSubmit: (values: {
    message: {
      body: { content: string; contentType?: string };
      mentions?: {
        mentioned: {
          user: {
            displayName: string;
            id: string;
          };
        };
      }[];
    };
  }) => void;
  submitStatus?: RequestStatus;
  teamId: string;
  channelId: string;
}

export const ChatSendMessageComponent: FC<ChatSendMessageProps & ConnectorProps> = ({
  isActive,
  setIsActive,
  onSubmit,
  submitStatus,
  teamId,
  channelId,
  getTeamsChannelMembersStart,
  currentChannelMembersData,
}) => {
  const { t } = useTranslate();
  const { data } = useSelector(profileData);
  const isLoading = submitStatus === RequestStatus.pending;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [fieldValue, setFieldValue] = useState('');
  const [mentionedList, setMentionedList] = useState<MentionItem[]>([]);

  const userList = currentChannelMembersData?.data;

  useEffect(() => {
    if (
      currentChannelMembersData?.status !== RequestStatus.pending &&
      !currentChannelMembersData?.data &&
      isActive
    ) {
      getTeamsChannelMembersStart({ teamId, channelId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    if (currentChannelMembersData?.status === RequestStatus.rejected) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: currentChannelMembersData?.error?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChannelMembersData?.status]);

  const toggleMessageInputVisibility = useCallback(() => {
    if (!fieldValue) {
      setIsActive(!isActive);
    }
  }, [setIsActive, isActive, fieldValue]);

  useEffect(() => {
    if (submitStatus === RequestStatus.resolved) {
      setFieldValue('');
      inputRef.current?.focus();
    }
  }, [submitStatus]);

  useEffect(() => {
    if (isActive && inputRef.current) {
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.focus();
    }
  }, [isActive, userList]);

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submitMessage();
    }

    if (e.key === 'Escape') {
      setIsActive(false);
    }
  };

  const onChange = (
    e: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]
  ) => {
    if (e.target.value.length > MAX_COMMENT_LENGTH) return;
    setFieldValue(e.target.value);
    setMentionedList(mentions);
  };

  const submitMessage = () => {
    onSubmit({
      message: {
        body: {
          contentType: 'html',
          content: wrapIntoAnchorTag(
            fieldValue
              .split('__,__')
              .map((chunk, i) => {
                return chunk.replace(/markup_id={(.*?)}/g, `id="${i}"`);
              })
              .join('')
          ),
        },
        mentions: mentionedList.map((mention, i) => ({
          id: i,
          mentionText: mention.display,
          mentioned: {
            user: {
              id: mention.id,
              displayName: mention.display,
            },
          },
        })),
      },
    });

    inputRef.current?.focus();
  };

  return (
    <Card
      bordered={false}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onBlur={(e: React.SyntheticEvent<HTMLDivElement> & { relatedTarget: any }) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          toggleMessageInputVisibility();
        }
      }}
    >
      <Row gutter={[16, 0]} justify="center">
        <Col lg={20} flex="auto">
          {
            <StyledMentionsInput
              inputRef={inputRef}
              key={userList?.length}
              allowSpaceInQuery
              value={fieldValue}
              onChange={onChange}
              onKeyDown={onKeyDown}
              allowSuggestionsAboveCursor={true}
              style={chatSendMessageStyles}
              spellCheck={false}
              placeholder={t('msteams.button.sendPlaceholder')}
              maxLength={MAX_COMMENT_LENGTH}
            >
              <Mention
                markup={`<at markup_id={"__id__"}>__display__</at>__,__`}
                style={chatSendMessageStyles}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                renderSuggestion={(suggestion: any, search, highlightedDisplay) => {
                  return currentChannelMembersData?.status === RequestStatus.pending ? (
                    <FullWidthAndHeightSpin />
                  ) : (
                    <Space size={10}>
                      <MSTeamsUserAvatar name={suggestion.display} id={suggestion.id as string} />

                      <div>
                        <StyledParagraph>{highlightedDisplay}</StyledParagraph>
                        <StyledSecondaryParagraph>{suggestion?.email}</StyledSecondaryParagraph>
                      </div>
                    </Space>
                  );
                }}
                appendSpaceOnAdd
                trigger="@"
                data={
                  userList
                    ?.filter((user) => user.userId !== data?.adOid)
                    .map((user) => ({
                      id: user.userId,
                      display: user.displayName,
                      email: user.email,
                    })) || [{ id: 0, display: 'Loading...' }]
                }
              />
            </StyledMentionsInput>
          }
          <Row justify="end">
            <Col>
              <span style={{ color: colors.lightGrey }}>
                {fieldValue.length} / {MAX_COMMENT_LENGTH}
              </span>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button disabled={isLoading} onClick={submitMessage} type="primary">
            {t('send')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export const ChatSendMessage = msTeamsChatSendMessageConnector(ChatSendMessageComponent);

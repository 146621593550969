import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from 'api';
import { staticForm } from '../../StaticForm';

export function useSelfServiceLetterForm(adoId: string) {
  return useQuery(
    [QUERIES.selfServiceLetter, adoId],
    async () => (await staticForm.getSelfServiceLetter(adoId)).data,
    {
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

import React, { FC, useCallback } from 'react';
import { Button, Row, Divider } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslate } from 'translations';
import { FormInstance, Rule } from 'antd/lib/form';
import { RestrictedUpload } from 'ui/components/RestrictedUpload';
import { useGetFromFieldId } from 'ui/hooks';
import { FormField } from 'types';
import { StyledFieldAttachmentLabel, StyledFormFile } from '../GeneratedForm.Styled';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

interface Props {
  field: FormField;
  form?: FormInstance;
  withLabel?: boolean;
  hideAttachmentLabel?: boolean;
}

export const rules = (isRequired?: boolean): Rule[] => [{ required: isRequired, type: 'array' }];

export const FieldFile: FC<Props> = ({ field, form, withLabel, hideAttachmentLabel = false }) => {
  const disableField = field.properties.viewOnly === 'true';
  const boldLabel = field.properties.boldLabel === 'true';
  const { t } = useTranslate();

  const isRequired = field.properties.requiredField;

  const maxAttachmentCount = field?.properties?.maxAttachementsCount;

  const fileLabel = useCallback(() => {
    if (field?.label === null) {
      return hideAttachmentLabel ? null : t('requestForm.field.attachment.label');
    } else if (field?.label?.includes(t('requestForm.field.attachment.label'))) {
      return hideAttachmentLabel ? null : t('requestForm.field.attachment.label');
    } else {
      return field.label ?? t('requestForm.field.attachment.label');
    }
  }, [field.label, hideAttachmentLabel, t]);

  const getFieldId = useGetFromFieldId();
  const fieldId = getFieldId(field.id);

  return (
    <>
      <Divider />
      {withLabel && (
        <Row>
          <StyledFieldAttachmentLabel>{fileLabel()}</StyledFieldAttachmentLabel>
        </Row>
      )}
      <StyledFormFile
        wrapperCol={{ span: 12 }}
        name={field.id}
        label={withLabel ? null : fileLabel()}
        valuePropName={field.typeName}
        getValueFromEvent={normFile}
        rules={rules(isRequired)}
        $FiledBold={boldLabel}
      >
        <RestrictedUpload
          key={fieldId}
          disableField={disableField}
          listType="text"
          showUploadList={true}
          multiple={true}
          form={form}
          fieldId={field.id}
          maxAttachmentCount={maxAttachmentCount}
        >
          <Button icon={<UploadOutlined />} disabled={field.properties.viewOnly === 'true'}>
            {t('task.uploadFile')}
          </Button>
        </RestrictedUpload>
      </StyledFormFile>
    </>
  );
};

import React, { FC, memo } from 'react';
import { Row, Col } from 'antd';
import { TType } from 'translations';
import { span } from 'ui/elements';
import { StyledSubmitButton } from 'routes/Calendar';
import { ArrowRightOutlined } from '@ant-design/icons';

interface Props {
  t: TType;
  handleUpdateNotification: () => void;
  isMutuateNotificationLoading: boolean;
}

export const NotificationActionButtonComponent: FC<Props> = ({
  t,
  handleUpdateNotification,
  isMutuateNotificationLoading,
}) => {
  const { span24 } = span;
  return (
    <Row>
      <Col span={span24}>
        <StyledSubmitButton
          onClick={handleUpdateNotification}
          loading={isMutuateNotificationLoading}
        >
          {t('settings.notification.action.update')}
          <ArrowRightOutlined />
        </StyledSubmitButton>
      </Col>
    </Row>
  );
};

export const NotificationActionButton = memo(NotificationActionButtonComponent);

import { ResponsePageable } from './ResponsePageable';

export enum SiteStatus {
  Active = 'Active',
  Outaged = 'Outaged',
}

export interface Site {
  id: string;
  name: string;
  status: SiteStatus;
  incidents?: number;
  region: string;
  district: string;
  governorate: string;
  latitude: number;
  longitude: number;
}

export interface SiteDetails {
  id: string;
  status: SiteStatus;
  name: string;
  incidents?: number;
  load: string;
  vendor: string;
  // GENERAL INFO
  RFAIDate: string;
  powerModel: string;
  owner: string;
  towerType: string;
  effectiveFrom: string;
  effectiveTo: string;
  // REMARKS
  remarks: string;
  // ADDRESS
  region: string;
  district: string;
  governorate: string;
  areaType: string;
  latitude: number;
  longitude: number;
  userDetails: {
    role: string;
    userName: string;
    effectiveFrom: string;
  };
  operatorDetails: {
    operator: string;
    opcoSiteId: string;
    siteType: string;
    siteClass: string;
    dependentSites: number;
    RFSDate: string;
    onAirStart: string;
    DCEMInstalled: string;
    effectiveFrom: string;
    status: string;
    anchor: string;
  };
  gridDetails: {
    powerSupplier: string;
    connectionType: string;
    accountNumber: number;
    meterNumber: number;
    dependentSites: number;
    RFSDate: string;
    onAirStart: string;
    effectiveDate: string;
    connectionDate: string;
  };
}

export type SitePageable = ResponsePageable & {
  content: Site[];
};

import { Form } from 'antd';
import React, { FC } from 'react';
import { TextStyled } from 'ui';
import { FormField } from 'types';
import {
  StyledTextWrapper,
  StyleTextLabel,
  StyledRow,
  StyledProcessDescriptionIcon,
  StyledColumn,
} from '../GeneratedForm.Styled';

interface Props {
  field: FormField;
}
const errorPattern = /error/i;
export const FieldText: FC<Props> = ({ field }) => {
  return field.label === 'Task Description' || field.label === 'Request Description' ? (
    <StyledTextWrapper>
      <StyledRow>
        <StyledProcessDescriptionIcon />
        <StyledColumn>
          <Form.Item label={<StyleTextLabel strong>{field.label}</StyleTextLabel>} name={field.id}>
            <StyleTextLabel>{field.value?.value}</StyleTextLabel>
          </Form.Item>
        </StyledColumn>
      </StyledRow>
    </StyledTextWrapper>
  ) : field.label.match(errorPattern)?.length ? (
    <Form.Item label={field.label} name={field.id}>
      <TextStyled $withOutUnderline={true}>{field.value?.value}</TextStyled>
    </Form.Item>
  ) : (
    <Form.Item label={field.label} name={field.id}>
      <TextStyled>{field.value?.value}</TextStyled>
    </Form.Item>
  );
};

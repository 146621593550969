import { Button, Typography, Select } from 'antd';

import styled from 'styled-components';
import { borderRadius, colors, space, typography } from 'ui/elements';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;
export const StyledCalendarHeader = styled.div`
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: right;
  color: #595959;
  vertical-align: middle;
  font-weight: normal;
  font-size: 14px;
  min-height: 0;
  margin-bottom: 10px;
`;

export const StyledDate = styled(Text)`
  font-size: ${typography.size.giga};
  font-weight: ${typography.weight.semiBold};
  flex-grow: 1;
  padding: 0 10px;
  text-align: start;
`;

export const StyledWrapperBtn = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

export const StyledToolbar = styled.div<{ $totalSelectedMembers?: number }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: ${typography.size.kilo};
  margin-bottom: ${(props) =>
    props.$totalSelectedMembers ? `${props.$totalSelectedMembers}0px` : space.cozy};
`;

export const StyleNavigationButton = styled(Button)`
  color: ${colors.labelGrey};
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  padding: 0.375rem 1rem;
  border-radius: ${borderRadius.large};
  line-height: normal;
  white-space: nowrap;

  :hover,
  :focus,
  :active {
    color: ${colors.secondBlack};
    background-color: ${colors.backgroundGrey};
    border-color: ${colors.labelGrey};
    font-weight: ${typography.weight.semiBold};
  }
`;

export const StyledWarringIcon = styled(ExclamationCircleOutlined)`
  color: ${colors.orangeShadow};
`;

export const StyledTeamListSelect = styled(Select)`
  border-radius: ${borderRadius.large};
  margin: ${space.zero} ${space.comfy};
  text-align: start;
  width: 25%;
  position: absolute;
  right: 25%;
  z-index: 1;
`;

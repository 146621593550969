import React, { useCallback, useState } from 'react';
import { useTranslate } from 'translations';
import { notification, Popover } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import { useAddProcessToUserProcesses, useSearchProcesses } from 'api';
import { UserDrawerSearchProcess } from '../UserDrawerSearchProcess/UserDrawerSearchProcess';
import {
  AddUserProcessButton,
  StyledPopoverContent,
  StyledPopoverSearchContainer,
  StyledPopoverTitle,
  StyledPopoverActionsContainer,
  StyledPopoverActionButton,
} from './UserDrawerAddProcessPopover.styled';

type Props = {
  userId: string;
  onAddProcess: () => void;
};

export const UserDrawerAddProcessPopover: React.FC<Props> = ({ userId, onAddProcess }) => {
  const { t } = useTranslate();
  const [selectedProcessId, setSelectedProcessId] = useState<number | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const {
    isLoading: addProcessLoading,
    mutateAsync: addProcessToUser,
  } = useAddProcessToUserProcesses();
  const {
    isLoading: isAvailableProcessesLoading,
    data: availableProcesses,
    mutateAsync: getAvailableProcesses,
  } = useSearchProcesses();

  const onClose = () => {
    setIsVisible(false);
  };

  const onProcessChange = (processId: number | null) => {
    setSelectedProcessId(processId);
  };

  const onAdd = () => {
    if (selectedProcessId) {
      addProcessToUser({ selectedUserAdOid: userId, processKey: selectedProcessId })
        .then(() => {
          onAddProcess();
          notification.success({
            message: t('processManagement.users.drawer.notification.added.success'),
          });
          onClose();
        })
        .catch(() => {
          notification.error({
            message: t('messages.error.wentWrong'),
          });
        });
    } else {
      return;
    }
  };

  const onAddProcessClick = useCallback(() => {
    getAvailableProcesses(userId).then(() => {
      setIsVisible(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const content = (
    <StyledPopoverContent>
      <StyledPopoverTitle>
        <SwapOutlined /> {t('processManagement.users.drawer.popover.title')}
      </StyledPopoverTitle>
      <StyledPopoverSearchContainer>
        {availableProcesses && (
          <UserDrawerSearchProcess processes={availableProcesses} onValueChange={onProcessChange} />
        )}
        <StyledPopoverActionsContainer>
          <StyledPopoverActionButton type="default" onClick={onClose} disabled={addProcessLoading}>
            {t('no')}
          </StyledPopoverActionButton>
          <StyledPopoverActionButton
            type="primary"
            disabled={addProcessLoading}
            loading={addProcessLoading}
            onClick={onAdd}
          >
            {t('yes')}
          </StyledPopoverActionButton>
        </StyledPopoverActionsContainer>
      </StyledPopoverSearchContainer>
    </StyledPopoverContent>
  );
  return (
    <Popover
      placement="topLeft"
      trigger="click"
      overlayClassName="addProcessPopover"
      content={content}
      visible={isVisible}
      destroyTooltipOnHide={{ keepParent: false }}
    >
      <AddUserProcessButton onClick={onAddProcessClick} loading={isAvailableProcessesLoading}>
        {t('processManagement.users.drawer.action')}
      </AddUserProcessButton>
    </Popover>
  );
};

import styled from 'styled-components';
import { Button, Card } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { colors } from 'ui/elements';
import { FileOutlined, FolderOutlined } from '@ant-design/icons';

export const OneDriveWrapper = styled.div`
  margin: 0 24px 0;
`;

export const OneDriveButton = styled(Button)`
  height: auto;
  padding: 0;
  width: 100%;
  text-align: left;
`;

export const OneDiveSubHeader = styled(Card)`
  border-bottom: 1px solid ${colors.borderGreySecond};
  .ant-card-body {
    padding: 8px 24px;
  }
`;

export const OneDriveDragger = styled(Dragger)`
  margin-top: 24px;

  &.ant-upload-drag {
    border: 2px dashed ${colors.borderGreySecond};
    background: none;
  }
`;

export const FolderIconStyled = styled(FolderOutlined)`
  font-size: 16px;
`;

export const FileIconStyled = styled(FileOutlined)`
  font-size: 16px;
`;

export const StyledModifiedby = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

import { AxiosRequestConfig } from 'axios';
import { ChatData, ChatMessageData, ChatMessagesData, CreateOneToOneChatData } from 'types';
import {
  CHATS,
  CHAT_MESSAGES,
  SEND_CHAT_MESSAGE,
  Core,
  CHATS_ONE_TO_ONE,
  CHATS_GROUP,
  CHAT_ADD_MEMBER,
} from '../Core';

interface GetChats {
  (): Promise<{ data: ChatData[] }>;
}
interface GetChatMessages {
  (chatId: string, adOid?: string, skipToken?: string): Promise<{ data: ChatMessagesData }>;
}
interface SendChatMessage {
  ({ chatId, message }: { chatId: string; message: { body: { content: string } } }): Promise<{
    data: ChatMessageData;
  }>;
}
type CreateOneToOneChat = (
  params: AxiosRequestConfig
) => Promise<{
  data: CreateOneToOneChatData;
}>;
type CreateGroupChat = (
  params: AxiosRequestConfig['params']
) => Promise<{
  data: ChatData;
}>;
type AddChatMember = ({
  chatId,
  params,
}: {
  chatId: string;
  params: AxiosRequestConfig['params'];
}) => Promise<{
  statusCode: string;
}>;

class Chats extends Core {
  getChats: GetChats = async () => this.get(CHATS());
  getChatMessages: GetChatMessages = async (chatId, adOid, skipToken) =>
    this.get(CHAT_MESSAGES(chatId), {
      params: { adOid },
      ...(skipToken ? { headers: { skipToken } } : null),
    });
  sendChatMessage: SendChatMessage = async ({ chatId, message }) =>
    this.post(SEND_CHAT_MESSAGE(chatId), { ...message });
  createOneToOneChat: CreateOneToOneChat = async (params) =>
    this.post(CHATS_ONE_TO_ONE(), null, { ...params });
  createGroupChat: CreateGroupChat = async (params) => this.post(CHATS_GROUP(), null, { params });
  addChatMember: AddChatMember = async ({ chatId, params }) =>
    this.post(CHAT_ADD_MEMBER(chatId), null, { params });
}

export const chats = new Chats();

import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import qs from 'query-string';
import { Col } from 'antd';
import { isEmpty } from 'ramda';
import { useLocation } from 'react-router';
import { useTasksContext } from 'routes/Tasks/TasksProvider';
import { DEFAULT_DATE_FORMAT } from './constants';
import { FilterContext } from './FilterContext';
import { FilterTags } from './FilterTags';
import { FilterPopover } from './FilterPopover';
import { FilterContextType, FilterProps } from './Filter.types';
import { FilterFirstRow, FilterSecondRow, StyledCol } from './Filter.styled';

interface FilterStaticComponents {
  Tags: typeof FilterTags;
  Popover: typeof FilterPopover;
}

export const Filter: FC<FilterProps> & FilterStaticComponents = ({
  children,
  isLoading,
  items,
  enableQueryParams = true,
  dateFormat = DEFAULT_DATE_FORMAT,
  onFilterCallback,
  t,
  history,
  onValuesChange,
  initialFilterValues = {},
  onResetFilterCallback,
  viewType,
  customTrigger,
  onTagRemoved,
  onShowPopOver,
  resetFilter,
}) => {
  const location = useLocation();
  const [filterValues, setFilterValues] = useState<FilterContextType['filterValues']>(
    initialFilterValues
  );
  const [isFormReset, setIsFormReset] = useState<boolean>(false);
  const { currentActiveTab } = useTasksContext();

  useEffect(() => {
    if (enableQueryParams && !isEmpty(filterValues)) {
      const currentParams = qs.parse(location.search);

      history.push({
        search: qs.stringify({ ...currentParams, ...filterValues }),
      });
    }

    if (onFilterCallback && !isFormReset) {
      onFilterCallback(filterValues);
    }
  }, [filterValues, history, enableQueryParams, onFilterCallback, location.search, isFormReset]);

  useEffect(() => {
    if (currentActiveTab || viewType) {
      setFilterValues({});
    }
  }, [currentActiveTab, setFilterValues, viewType]);

  const tags = useMemo(() => {
    const currentFieldNames = Object.keys(filterValues);

    return items
      .filter(({ name }) => currentFieldNames.includes(name as string))
      .reduce((acc, { placeholder, tagLabel, name, type }) => {
        acc[name as string] = {
          displayName: placeholder as string,
          value: filterValues[name as string],
          type,
          tagLabel,
        };

        return acc;
      }, {} as FilterContextType['tags']);
  }, [filterValues, items]);

  const providerValue = useMemo(
    () => ({
      tags,
      t,
      isLoading,
      items,
      setFilterValues,
      filterValues,
      dateFormat,
      onValuesChange,
      onShowPopOver,
    }),
    [
      tags,
      t,
      isLoading,
      items,
      setFilterValues,
      filterValues,
      dateFormat,
      onValuesChange,
      onShowPopOver,
    ]
  );

  const onReset = useCallback(
    (isReset) => {
      if (onResetFilterCallback) {
        onResetFilterCallback(isReset);
      }
      setIsFormReset(isReset);
    },
    [onResetFilterCallback]
  );

  useEffect(() => {
    if (resetFilter) {
      setFilterValues({});
    }
  }, [resetFilter]);

  return (
    <FilterContext.Provider value={providerValue}>
      <FilterFirstRow justify="space-between" align="middle">
        {!!children && <StyledCol>{children}</StyledCol>}
        <Col>
          <FilterPopover customTrigger={customTrigger} onResetFilterCallback={onReset} />
        </Col>
      </FilterFirstRow>

      <FilterSecondRow>
        <FilterTags onTagRemoved={onTagRemoved} />
      </FilterSecondRow>
    </FilterContext.Provider>
  );
};

Filter.Tags = FilterTags;
Filter.Popover = FilterPopover;

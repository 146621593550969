import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { Header } from 'app';
import { AppLayout } from 'layouts';
import { profileData as profileDataSelector, useSelector } from 'store';
import { useTranslate } from 'translations';
import { ProfileData } from 'types';
import { TitleH1 } from 'ui/elements';
import { Private } from 'routes/Profile/Private';
import { Content } from './EmploymentDetails.styled';

export const EmploymentDetails: FC = () => {
  const { data } = useSelector(profileDataSelector);
  const profileData = data as ProfileData;
  const { t } = useTranslate();

  return (
    <AppLayout>
      <Header>
        <Row align="middle">
          <Col>
            <TitleH1>{t('employmentDetails.title')}</TitleH1>
          </Col>
        </Row>
      </Header>
      <Content className="layout-main">
        <Private profileData={profileData} />
      </Content>
    </AppLayout>
  );
};

import styled from 'styled-components';
import { Collapse, Typography, Form } from 'antd';
import { typography, colors, space } from 'ui/elements';

const { Text } = Typography;
export const StyledAttachmentCollapse = styled(Collapse)`
  padding-top: 10px;
  & > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    transform: translateY(-50%) !important;
    top: 50% !important;
    left: 0 !important;
    font-size: ${typography.size.uno} !important;
    padding: 0 !important;
  }
  & > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    padding-left: ${typography.size.kilo};
  }
`;

export const AssigneeTitle = styled(Text)`
  font-size: ${typography.size.hecto};
  color: ${colors.textColorSecondary};
`;

export const StyledForm = styled(Form)`
  .ant-form-item {
    display: block;
    margin-top: ${space.cozy};
  }
  .ant-row {
    display: block;
  }
  .ant-col {
    max-width: 100%;
  }
`;

import styled from 'styled-components';
import { Button, Card, Checkbox, Col, DatePicker, Divider, Row, Typography } from 'antd';
import { ArrowLeftOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { colors, space } from 'ui/elements';

const { Text } = Typography;
export const StyledLeaveTitle = styled(Text)`
  color: ${colors.textColorSecondary};
`;

export const StyledRow = styled(Row)`
  background-color: white;
  padding-left: 20px;
`;

export const StyledRequestDetailCard = styled(Card)`
  margin: 28px 24px 0px 24px;
  .ant-card-body {
    align: center;
  }
`;

export const StyledLabel = styled(Text)`
  color: ${(props: { $isThereError?: boolean }) =>
    props.$isThereError ? 'red' : colors.textColorSecondary};
`;

export const StyledRemainingBalanceCard = styled(Card)`
  background-color: ${colors.lightGreyFourth};
  width: auto;
  border: none;
  border-radius: 4px;
`;

export const StyledDivider = styled(Divider)`
  margin: 12px 0;
`;

export const StyledH3Label = styled(Text)`
  font-size: 18px;
  color: ${colors.lightGrey};
`;

export const StyledErrorLabel = styled(Text)`
  color: ${colors.primaryColor};
`;

export const StyledErrorView = styled(Col)`
  font-size: 18px;
  border-radius: 4px;
  padding: 24px;
  border: 1px solid red;
`;

export const LinkStyle = {
  color: 'red',
  textDecorationLine: 'underline',
  margin: '0px 4px',
};

export const StyledSubmitButton = styled(Button)`
  color: ${colors.white};
  background-color: ${colors.orangeShadow};
  border-color: ${colors.orangeShadow};

  &:hover,
  &:focus {
    color: ${colors.white};
    background-color: ${colors.orange};
    border-color: ${colors.orange};
  }
`;

export const StyledSaveCol = styled(Col)`
  position: absolute;
  right: -1px;
`;

export const StyledBackIcon = styled(ArrowLeftOutlined)`
  font-size: 20px;
  margin-right: 10px;
  margin-top: 25px;
`;

export const StyledSaveButtonWrapper = styled.div`
  padding-right: 18px;
`;

export const StyledH4Label = styled(Text)`
  font-size: 16px;
  margin-left: 5px;
  color: ${(props: { $isThereError?: boolean }) => (props.$isThereError ? 'red' : 'none')};
`;

export const StyledAbsenceBalanceCard = styled(Card)`
  margin-left: 24px;
  border-radius: 4px;
  border-width: 2px;
`;

export const StyledH3Primary = styled(Text)`
  font-size: 18px;
  color: ${colors.textColorSecondary};
`;

export const StyledBalanceNumber = styled(Text)`
  font-size: 40px;
  color: ${colors.primaryColor};
`;

export const StyleDatePicker = styled(DatePicker)`
  width: 100%;
  border: ${(props: { $isThereError?: boolean }) =>
    props.$isThereError ? '1px solid red' : 'normal'};
`;
export const StyledErrorDiv = styled.div`
  color: red;
  margin-top: -20px;
  font-weight: 500;
`;
export const StyleNoLimitCol = styled(Col)`
  text-align: center;
  padding-top: 30px;
`;
export const StyledNoLimitText = styled(Text)`
  font-size: 18px;
  color: ${colors.textColorSecondary};
`;

export const StyledErrorIcon = styled(CloseCircleOutlined)`
  color: ${colors.redSecond};
  font-size: 24px;
  margin-right: 24px;
`;

export const StyledNewRequestCheckBox = styled(Checkbox)`
  color: ${colors.textColorSecondary};
  font-size: 14px;
  line-height: 22px;
`;

export const StyledYourDelegateTitleTxt = styled(Text)`
  color: ${colors.labelGrey};
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
`;

export const StyledNewRequestDelegateUserName = styled(Text)`
  color: ${colors.primaryColor};
  font-size: 16px;
  line-height: 14.6px;
  font-weight: 500;
`;

export const StyledNewRequestDelegateEmail = styled(Text)`
  color: ${colors.lightGrey};
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
`;

export const StyledNewRequestDelegateInfoContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-left: ${space.slim};
  padding-top: ${space.micro};
`;

export const StyledNewRequestDelegateItemContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-top: ${space.comfy};
`;

export const StyledLabelAssignedDelegateStartAndEndDateContainer = styled(Col)`
  margin-top: ${space.slim};
  margin-bottom: ${space.slim};
`;

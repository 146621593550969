import { Tabs } from 'antd';
import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { colors } from 'ui/elements';

export const StyledTab = styled(Tabs)`
  .ant-tabs-nav-wrap {
    padding: 0 24px;
    background-color: ${colors.white};
  }
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: ${colors.labelGrey};

    .ant-tabs-tab-active {
      font-weight: 500;
    }
  }
  .ant-table-thead > tr > th {
    background-color: ${colors.white};
  }
`;

export const TabPaneContent = styled.div`
  padding: 8px 24px 32px;
`;

export const PagePaneContent = styled.div`
  padding: 24px 24px 32px;
`;

export const TabPaneHeader = styled.div`
  padding: 0px;
`;

export const StyledBackIcon = styled(ArrowLeftOutlined)`
  font-size: 20px;
  margin-right: 10px;
  margin-top: 25px;
`;

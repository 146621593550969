import styled from 'styled-components';
import { Typography, Row, Col, Badge } from 'antd';
import { colors, borderRadius, space, typography } from '../../../elements/theme';

const { Text } = Typography;

export const ModalCard = styled.div`
  width: 268px;
  padding-bottom: 13px;
  padding-top: 13px;
  padding-left: 13px;
  background: ${colors.white};
  box-shadow: 0px 2px 20px ragb(0, 0, 0, 0.15);
  border-radius: ${borderRadius.large};
`;
export const WrapInfo = styled.div`
  display: inline-block;
  widtu: 100%;
  height: 100%;
  align-items: start;
  justify-items: flex-start;
  flex: 1;
`;
export const UserName = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: ${colors.primaryColor};
  margin: ${space.slim} ${space.zero};
`;
export const UserStatus = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: ${colors.lightGrey};
`;
export const UserEmail = styled(UserStatus)`
  color: ${colors.textColorSecondary};
`;
export const UserJobTitle = styled(UserEmail)``;

export const UserDepartment = styled(Text)`
  color: ${colors.lightGrey};
  font-size: ${typography.size.uno};
`;

export const ProfileWrapRow = styled(Row)`
  text-align: center;
`;

export const StyledEmployeeIdCol = styled(Col)`
  margin: ${space.slim} ${space.zero};
  background: ${colors.chatMessage};
  border-radius: ${borderRadius.xxLarge};
  color: ${colors.lightGrey};
`;

export const StyledJobInfo = styled(Col)`
  margin: ${space.slim} ${space.zero};
  .ant-col-24 {
    padding: ${space.zero} !important;
  }
`;

export const StyledStatusBadge = styled(Badge)`
  sup {
    width: ${typography.size.uno};
    height: ${typography.size.uno};
  }
`;

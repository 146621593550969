import { AppLayout } from 'layouts';
import React, { FC } from 'react';
import { SpinWrapper, SpinCard, StyledSpin, StyledLoadingOutlined } from './PageSpin.styled';

export interface PageSpinProps {
  spinning?: boolean;
  tip?: string;
}

export const PageSpin: FC<PageSpinProps> = ({ spinning = true, tip }) => (
  <AppLayout displayHeader={false}>
    <SpinWrapper>
      <SpinCard>
        <StyledSpin
          spinning={spinning}
          delay={0}
          indicator={<StyledLoadingOutlined spin />}
          tip={tip}
        />
      </SpinCard>
    </SpinWrapper>
  </AppLayout>
);

import { RequestsCatalogDrawerTypes } from 'types';
import { Core, REQUEST_CATALOG_DRAWER, REQUEST_CATALOG_DRAWER_OLD } from 'api';

interface GetRequestCatalogDrawerInfo {
  (requestName: string): Promise<{ data: RequestsCatalogDrawerTypes }>;
}

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

class RequestCatalogDrawer extends Core {
  getRequestCatalogDrawerInfo: GetRequestCatalogDrawerInfo = async (requestName) =>
    this.get(
      disableAggregator
        ? REQUEST_CATALOG_DRAWER_OLD(requestName)
        : REQUEST_CATALOG_DRAWER(requestName)
    );
}

export const requestCatalogDrawer = new RequestCatalogDrawer();

import React, { FC, memo } from 'react';
import { TType } from 'translations';
import {
  StyledDrawerErrorMessage,
  StyledDrawerErrorMessageColumn,
  StyledDrawerErrorMessageRow,
  StyledDrawerErrorWrapper,
  StyledErrorIcon,
  StyledLinkToRequestMessage,
} from './RequestCatalogDrawerStyle';

interface Props {
  t: TType;
  disabledMessage?: string;
  linkToRequestMessage?: string;
  onPressLinkToRequest?: () => void;
}

export const RequestsCatalogDrawerErrorMessage: FC<Props> = ({
  t,
  disabledMessage,
  linkToRequestMessage,
  onPressLinkToRequest,
}) => {
  return (
    <StyledDrawerErrorWrapper>
      <StyledDrawerErrorMessageColumn>
        <StyledDrawerErrorMessageRow>
          <StyledErrorIcon />
          <StyledDrawerErrorMessageRow>
            <StyledDrawerErrorMessage strong>
              {disabledMessage
                ? disabledMessage
                : t('requestCatalog.drawer.errorMessage.notAllowToCreateRequest')}
            </StyledDrawerErrorMessage>
          </StyledDrawerErrorMessageRow>
        </StyledDrawerErrorMessageRow>

        {linkToRequestMessage && (
          <StyledLinkToRequestMessage onClick={onPressLinkToRequest}>
            {linkToRequestMessage}
          </StyledLinkToRequestMessage>
        )}
      </StyledDrawerErrorMessageColumn>
    </StyledDrawerErrorWrapper>
  );
};
export const DrawerNoAllowMessage = memo(RequestsCatalogDrawerErrorMessage);

import { useMutation } from 'react-query';
import { IPayloadGetNonPoInvoiceAdvanceRequest, NonPoInvoiceAdvanceRequestResponse } from 'types';
import { nonPoInvoice } from '../NONPoInvoice';

export const useNonPoInvoiceAdvanceRequestsApi = () => {
  return useMutation<
    NonPoInvoiceAdvanceRequestResponse[],
    unknown,
    IPayloadGetNonPoInvoiceAdvanceRequest,
    unknown
  >(async (params) => {
    const { data } = await nonPoInvoice.getNonPoInvoiceAdvanceRequests(params);
    return data;
  });
};

import { Col } from 'antd';
import React from 'react';
import { TType } from 'translations';
import { ClaimTask } from '../ClaimTask';
import { StyledClaimTaskIcon } from './TaskCard.styled';

interface TaskCardClaimTaskProps {
  taskId: string | undefined;
  userGroupId: string | undefined;
  reassignCallback?: () => void;
  t: TType;
}

const TaskCardClaimTaskComponent: React.FC<TaskCardClaimTaskProps> = ({
  taskId,
  userGroupId,
  reassignCallback,
  t,
}) => {
  return (
    <Col>
      <ClaimTask taskId={taskId} groupId={userGroupId} claimCallback={reassignCallback}>
        <StyledClaimTaskIcon />
      </ClaimTask>
    </Col>
  );
};

export const TaskCArdClaimTask = React.memo(TaskCardClaimTaskComponent);

import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { UserPopover } from 'ui';
import { StyledUserName } from './Hierarchy.styled';

const { Text } = Typography;

interface Props {
  userName: string;
  title: string;
  strong?: boolean;
  id?: string;
  email?: string;
  hcmId?: string;
}

export const HierarchyTitle: FC<Props> = ({
  userName,
  title,
  strong = false,
  id,
  email,
  hcmId,
}) => {
  return (
    <UserPopover {...(email ? { currentEmail: email } : id ? { currentID: id } : { hcmID: hcmId })}>
      <Space size={20}>
        <StyledUserName
          strong={strong}
          displayunderline={id || email || hcmId ? 'underline' : 'none'}
        >
          {userName}
        </StyledUserName>
        <Text type="secondary">{title}</Text>
      </Space>
    </UserPopover>
  );
};

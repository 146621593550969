import React, { useMemo } from 'react';
import { Col } from 'antd';
import { TType } from 'translations';
import { IProfileAttachment } from 'types';
import { ProfileEntryField, PrivateProfileAttachments } from 'ui';
import { StyledProfileRowContainer } from '../../NewProfile.styled';
import { StyledItemTitle, StyledItemWrapper } from './PrivateProfileExperience.styles';

export interface PrivateProfileExperienceItemProps {
  t: TType;
  industry: string;
  period: string;
  yearsOfExperience: string;
  companyName: string;
  position: string;
  experienceAttachmentList: IProfileAttachment[];
}

export const PrivateProfileExperienceItem: React.FC<PrivateProfileExperienceItemProps> = ({
  t,
  companyName,
  position,
  industry,
  period,
  yearsOfExperience,
  experienceAttachmentList,
}: PrivateProfileExperienceItemProps) => {
  const experienceFields = useMemo(
    () => [
      { label: t('user.privateProfile.experience.industry'), value: industry },
      { label: t('user.privateProfile.experience.period'), value: period },
      { label: t('user.privateProfile.experience.yearOfExperience'), value: yearsOfExperience },
    ],
    [industry, period, t, yearsOfExperience]
  );

  return (
    <StyledItemWrapper>
      <StyledItemTitle>{`${companyName},${position}`}</StyledItemTitle>
      <StyledProfileRowContainer gutter={[25, 15]} wrap={true}>
        {experienceFields.map(({ label, value }, index) => (
          <Col xs={24} sm={24} md={12} lg={8} key={index}>
            <ProfileEntryField label={label} value={value} />
          </Col>
        ))}
      </StyledProfileRowContainer>
      <PrivateProfileAttachments attachments={experienceAttachmentList} />
    </StyledItemWrapper>
  );
};

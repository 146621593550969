import React, { FC } from 'react';
import { Row } from 'antd';
import { StyledDrawerTitle, StyledTitleCol } from './RequestCatalogDrawerStyle';
import { GUTTER_16 } from './constants';

export const DrawerTitle: FC = ({ children }) => (
  <Row gutter={GUTTER_16}>
    <StyledTitleCol span={24}>
      <StyledDrawerTitle>{children}</StyledDrawerTitle>
    </StyledTitleCol>
  </Row>
);

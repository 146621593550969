import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { colors, space } from 'ui';

const { Text } = Typography;

export const StyledCreatorInfoContainer = styled(Row)``;
export const StyledCreatorNameWrapper = styled(Col)`
  flex-direction: column;
  display: flex;
  padding-left: ${space.slim};
`;
export const StyledCreatorName = styled(Text)`
  color: ${colors.textColorSecondary};
  font-size: 14px;
  font-family: inherit;
  font-weight: 500;
`;
export const StyledCreationDate = styled(Text)`
  color: ${colors.grey};
  font-size: 12px;
  font-family: inherit;
`;

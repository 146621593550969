import React, { FC, useCallback, useMemo } from 'react';
import { TType } from 'translations';
import { Spin } from 'antd';
import { useELibraryDocumentTypes } from 'api';
import {
  StyledELibraryDrawer,
  StyledELibraryDrawerContainer,
  StyledELibraryDrawerDivider,
  StyledELibraryTitleDrawer,
} from '../ELibrary.styled';
import { ELibraryHowToReadDocumentCodes } from './ELibraryHowToReadDocumentCodes';
import { ELibraryTypesOfDocument } from './ELibraryTypesOfDocument';

type Props = {
  t: TType;
  isVisible: boolean;
  onClose: () => void;
};

export const ELibraryHelpDrawer: FC<Props> = ({ t, isVisible, onClose }) => {
  const onCloseClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const documentTypes = useELibraryDocumentTypes(isVisible);
  const spinning = useMemo(() => documentTypes.isLoading, [documentTypes.isLoading]);

  return (
    <StyledELibraryDrawer
      closable
      destroyOnClose
      maskClosable
      getContainer={false}
      placement="right"
      visible={isVisible}
      onClose={onCloseClick}
      width={569}
      headerStyle={{ borderStyle: 'none' }}
      title={<StyledELibraryTitleDrawer>{t('eLibrary.help')}</StyledELibraryTitleDrawer>}
    >
      <StyledELibraryDrawerContainer>
        <ELibraryHowToReadDocumentCodes t={t} />
        <StyledELibraryDrawerDivider />
        <Spin spinning={spinning} delay={100}>
          <ELibraryTypesOfDocument t={t} documentTypes={documentTypes.data} />
        </Spin>
      </StyledELibraryDrawerContainer>
    </StyledELibraryDrawer>
  );
};

import en from 'assets/translations/en.json';
import ar from 'assets/translations/ar.json';

export type MessagesTypes = keyof (typeof en & typeof ar);
export type PrimitiveType = string | number | boolean | null | undefined | Date;

export interface TType {
  (message: MessagesTypes): string;
}
export interface TPrefixedType {
  (keyPrefix: string): (key: string) => string;
}

export type TranslationsTypes = {
  [translation in Translations]: {
    [key: string]: string;
  };
};

export interface TranslationsProviderProps {
  messages: TranslationsTypes;
}

export enum Translations {
  en = 'en',
  ar = 'ar',
}

export interface Language {
  language: keyof typeof Translations;
}

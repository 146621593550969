import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { colors, space } from 'ui';

const { Text } = Typography;

export type StyledImageProps = {
  imageUrl?: string;
  width?: number;
  height?: number;
};

export const StyledImageGridContainer = styled.div`
  margin-top: ${space.comfy};
  margin-bottom: ${space.comfy};
`;

export const StyledImageGridTitle = styled(Text)`
  color: ${colors.primaryColor};
  font-size: 16px;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledImageGridImage = styled.img<StyledImageProps>`
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
  height: ${(props) => (props.height ? `${props.height}%` : '100%')};
  display: flex;
  border-radius: 8px;
  background-color: ${colors.grey};
  object-fit: cover;
`;

export const StyledImageGridRightGridWrapper = styled(Col)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${space.normal};
`;

export const StyledImageGridRightGridSubWrapper = styled(Row)`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  background-color: white;
  height: 100%;
  gap: ${space.normal};
  position: relative;
`;

export const StyledImageGridWrapper = styled(Row)`
  margin-top: ${space.cozy};
  height: 356px;
`;

export const StyledImageGridCellWrapper = styled(Row)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  gap: ${space.normal};
`;

export const StyledImageGridMoreThenFiveOpacityLayer = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  border-radius: 8px;
  opacity: 0.7;
  background-color: ${colors.primaryColor};
`;

export const StyledImageGridMoreThenFiveText = styled(Text)`
  color: ${colors.white};
  font-size: 40px;
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

import { useQuery } from 'react-query';
import { QUERIES } from 'api';
import { dashboardRequestCards } from '../DashboardRequestCard';

export function useDashboardRequestList(adoid: string) {
  return useQuery(
    [QUERIES.dashboardRequestList, adoid],
    async () => (await dashboardRequestCards.getDashboardRequestCard(adoid)).data,
    {
      refetchOnWindowFocus: false,
      enabled: !!adoid,
    }
  );
}

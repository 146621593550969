import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const userStatusData = (state: RootState): RootState['userStatus'] => state.userStatus;

export const userStatusSelectors = createSelector(userStatusData, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

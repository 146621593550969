import { createContext, useContext, Dispatch, SetStateAction } from 'react';

interface MSTeamsContextType {
  currentChannelId: string;
  setCurrentChannelId: Dispatch<SetStateAction<string>>;
  currentChatId: string;
  setCurrentChatId: Dispatch<SetStateAction<string>>;
  currentTeamId: string;
  setCurrentTeamId: Dispatch<SetStateAction<string>>;
  currentSearchUserId: string;
  setCurrentSearchUserId: Dispatch<SetStateAction<string>>;
}

export const MSTeamsContext = createContext({} as MSTeamsContextType);

export const useMSTeamsContext = () => {
  const context = useContext<MSTeamsContextType>(MSTeamsContext);

  if (!context) {
    throw new Error('useMSTeamsContext should be used within a MSTeamsContext');
  }
  return context;
};

import { Space, Spin, Typography } from 'antd';
import { DownloadOutlined, FileOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import {
  StyledAttachmentText,
  StyledAttachmentTextWrapper,
  StyledCommentAttachmentWrapper,
  StyledFileArrowDownIcon,
} from './DownloadAttachment.styled';
import { AttachmentIcon } from './AttachmentIcon';

const { Text } = Typography;

interface DownloadAttachmentProps {
  name: string;
  url?: string;
  isLoading?: boolean;
  onDownloadClick?: () => void;
  isComment?: boolean;
  className?: string;
}

export const DownloadAttachment: FC<DownloadAttachmentProps> = ({
  name,
  url,
  isLoading,
  onDownloadClick,
  isComment,
  className,
}) => {
  if (isComment) {
    return (
      <a href={url || undefined} target="_blank" rel="noreferrer" download className={className}>
        <StyledCommentAttachmentWrapper onClick={onDownloadClick}>
          <AttachmentIcon fileName={name} />
          <StyledAttachmentTextWrapper>
            <StyledAttachmentText>{name}</StyledAttachmentText>
          </StyledAttachmentTextWrapper>
          {isLoading ? <Spin size="small" /> : <StyledFileArrowDownIcon />}
        </StyledCommentAttachmentWrapper>
      </a>
    );
  } else {
    return (
      <Text type="secondary" className={className}>
        <Space>
          <FileOutlined style={{ fontSize: '15px' }} />
          {name}
          <a href={url || undefined} target="_blank" rel="noreferrer" download>
            <Text type="secondary">
              {isLoading ? <Spin size="small" /> : <DownloadOutlined onClick={onDownloadClick} />}
            </Text>
          </a>
        </Space>
      </Text>
    );
  }
};

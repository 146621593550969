import { QUERIES, PERSIST_QUERY_CONFIG } from 'api';
import { useQuery } from 'react-query';
import { nonPoInvoice } from '../NONPoInvoice';

export function useNonPoGetBudgetProjectAccounts(search?: string) {
  return useQuery(
    [QUERIES.getNonPoBudgetProjectAccounts, search],
    async () => {
      const { data } = await nonPoInvoice.getNonPoBudgetProjectAccounts(search ?? '');
      const mappedData: Record<string, string> = {};
      data.forEach((item) => (mappedData[item.VALUE] = `${item.VALUE} - ${item.DESCRIPTION}`));
      return mappedData;
    },
    {
      enabled: !!search,
      ...PERSIST_QUERY_CONFIG,
    }
  );
}

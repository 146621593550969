import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { ServicesCardsInfoAgg, RequestCommonError, RequestStatus } from 'types';
import { MyTasksCardState } from './myTaskCardsTypes';

export const emptyMyTaskCardsState: MyTasksCardState = {
  status: RequestStatus.idle,
  error: null,
  hasMore: true,
};

export type GetMyTaskCardsStart = PayloadAction<{
  userId: string;
  params?: AxiosRequestConfig['params'];
}>;
export type GetMyTaskCardsSuccess = PayloadAction<ServicesCardsInfoAgg>;
export type GetMyTaskCardsFail = PayloadAction<RequestCommonError>;
export type GetMyTaskCardsReset = PayloadAction;

const myTaskCards = createSlice({
  name: 'myTaskCards',
  initialState: emptyMyTaskCardsState,
  reducers: {
    getMyTaskCardsStart(state, _: GetMyTaskCardsStart) {
      state.status = RequestStatus.pending;
    },
    getMyTaskCardsSuccess(state, action: GetMyTaskCardsSuccess) {
      state.status = RequestStatus.resolved;
      if (action.payload?.requestInfoList.length < 10) {
        state.hasMore = false;
      }
      if (!state.data?.requestInfoList.length) {
        state.data = action.payload;
        return;
      }
      state.data.requestInfoList = [
        ...state.data.requestInfoList,
        ...action.payload.requestInfoList,
      ];
    },
    getMyTaskCardsFail(state, action: GetMyTaskCardsFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    getMyTaskCardsReset(state, _: GetMyTaskCardsReset) {
      state.status = RequestStatus.idle;
      state.error = null;
      state.data = undefined;
      state.hasMore = true;
    },
  },
});

export const {
  getMyTaskCardsFail,
  getMyTaskCardsStart,
  getMyTaskCardsSuccess,
  getMyTaskCardsReset,
} = myTaskCards.actions;
export default myTaskCards.reducer;

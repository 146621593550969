export const subordinates = [
  {
    id: '300000002962445',
    displayName: 'Abdulmohsen Abdullatif Al Manna',
    title: 'Specialist',
  },
  {
    id: '300000003024318',
    displayName: 'Abdulrahman Yahya Alyami',
    title: 'Engineer',
    photoId:
      '00020000000EACED00057708000110D9319DF7120000004AACED00057372000D6A6176612E73716C2E4461746514FA46683F356697020000787207465686A81014B5974190300007870770800000177DBA2900078-100000003725597',
  },
  {
    id: '300000003024182',
    displayName: 'Ahmed Ali  Bamqabl',
    title: 'Director',
    subordinatesIds: ['300000004816304', '300000004997029'],
  },
  {
    id: '300000002951748',
    displayName: 'Alhassan HabibAllah Binsaleh',
    title: 'Manager',
  },
  {
    id: '300000002943762',
    displayName: 'Amr Sami Mohamed',
    title: 'E9005',
    photoId:
      '00020000000EACED0005770D00057372000D6A6176612E73716C2E4461746514FA46683F3566970200007872000E6A6176612E7574696C2E44617465686A81014B5974190300007870770800000177DBA2900078-100000003725597',
  },
  {
    id: '300000002857001',
    displayName: 'Fahad Abdullah Al Shahrani',
    title: 'Lead Specialist',
  },
  {
    id: '300000002861004',
    displayName: 'Faisal  Abdulrahman Al Mutairi',
    title: 'Lead Specialist',
  },
  {
    id: '300000003387400',
    displayName: 'Faisal Meshari AlMeshari',
    title: 'Lead Specialist',
  },
  {
    id: '300000002635535',
    displayName: 'Mohammad  Ismail Ghatasheh',
    title: 'Lead Specialist',
  },
  {
    id: '300000003106776',
    displayName: 'Mohammed Saud AlNuhayt',
    title: 'Manager',
  },
  {
    id: '300000003094014',
    displayName: 'Monther Abdullah AlOgayel',
    title: 'Manager',
  },
  {
    id: '300000002861176',
    displayName: 'Nasser Ibrahim Al Humud',
    title: 'Lead Specialist',
  },
  {
    id: '300000002946246',
    displayName: 'Osama mahmoud Ammar',
    title: 'Lead Specialist',
  },
  {
    id: '300000003387687',
    displayName: 'Riyadh Nasser AlShahrani',
    title: 'Lead Specialist',
  },
  {
    id: '300000003368690',
    displayName: 'Saeed Jallab AlEnazi',
    title: 'Director',
    subordinatesIds: ['300000003441557', '300000003354444'],
  },
  {
    id: '300000003454776',
    displayName: 'Saleh Zamil AlAnazi',
    title: 'Manager',
  },
  {
    id: '300000002943650',
    displayName: 'Siddiq Siddiq Mohiuddin',
    title: 'E9004',
  },
  {
    id: '300000002943874',
    displayName: 'Yaman Abdulahmid Touto',
    title: 'E9006',
  },
];

export interface NotificationListData {
  list: NotificationList[];
  totalResult: number;
  offset: number;
  limit: number;
}

export interface NotificationList {
  requestId: string;
  requestName: string;
  notificationContentType: string | null;
  taskName: string | null;
  taskId: string | null;
  requesterName: string | null;
  requesterEmail: string | null;
  createdDate: string;
  groupId: string | null;
  groupName: string | null;
  commentText: string | null;
  commentId: string | null;
  commenterName: string | null;
  commenterId: string | null;
  processInstanceId: string | null;
  adOid: string | null;
  isRead: boolean;
  processDefinitionKey: string | null;
  formattedDate?: string;
  notificationCount: number;
  redirectionPage?: string;
  redirectionTab?: string;
  id: string;
}

export const NotificationType = {
  CREATE_PROCESS: 'CREATE_PROCESS',
  COMPLETE_PROCESS: 'COMPLETE_PROCESS',
  START_TASK: 'START_TASK',
  REJECT_TASK: 'REJECT_TASK',
  APPROVE_TASK: 'APPROVE_TASK',
  ASSIGN_TASK: 'ASSIGN_TASK',
  COMMENT_TO_REQUESTER: 'COMMENT_TO_REQUESTER',
  COMMENT_TO_ASSIGNEE: 'COMMENT_TO_ASSIGNEE',
  COMMENT_TO_GROUP: 'COMMENT_TO_GROUP',
  COMMENT_MENTION: 'COMMENT_MENTION',
};

export const chatSendMessageStyles = {
  control: {
    fontFamily: 'inherit',
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },
  '&multiLine': {
    control: {
      minHeight: 10,
      '&strong': {
        color: 'red',
      },
    },
    highlighter: {
      padding: 7,
      border: '1px solid transparent',
      height: 80,
    },
    input: {
      padding: 7,
      border: '1px solid silver',
      overflow: 'auto',
      height: 80,
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      fontSize: 14,
      maxHeight: 300,
      overflowY: 'auto',
      minWidth: 420,
      borderRadius: 4,
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)',
    },
    item: {
      padding: '8px 16px',
      boxSizing: 'border-box',
      '&focused': {
        backgroundColor: '#f6f7f9',
      },
    },
  },
};

import styled from 'styled-components';
import { Card, DatePicker, Pagination, Typography } from 'antd';
import { colors, space, borderRadius, TitleH3 } from 'ui/elements';

const { Text } = Typography;
export const AttendanceListWrapper = styled.div`
  margin: ${space.moderate} ${space.normal};
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: ${colors.lightGreyFourth};
    cursor: pointer;
  }
  .ant-table-tbody > tr > td {
    color: ${colors.textColorSecondary};
  }
  .action-icon:hover {
    cursor: pointer;
  }
  height: 100%;
`;

export const StyledDatePicker = styled(DatePicker)`
  margin: ${space.cozy} ${space.zero};
  width: 20%;
`;

export const StyledRemarkWrapper = styled.div`
  span {
    border-radius: ${borderRadius.xxLarge};
    padding: ${space.micro} ${space.cozy};
  }
`;

export const StyledWarningStatus = styled.span<{ isMoreRemarks?: boolean }>`
  background-color: ${colors.lightpink};
  color: ${colors.red};
  display: inline-flex;
  margin: ${(props) => (props.isMoreRemarks ? `${space.mini}` : `auto`)};
`;

export const StyledSuccessStatus = styled.span`
  background-color: ${colors.lightGreenBackground};
  color: ${colors.nature};
`;

export const StyledOtherStatus = styled.span`
  background-color: ${colors.backgroundGrey};
  color: ${colors.skySecondary};
`;

export const StyledPagination = styled(Pagination)`
  margin: ${space.moderate} ${space.zero};
  text-align: right;
  li {
    border: 1px solid ${colors.borderGrey} !important;
    margin-right: ${space.slim} !important;
  }

  .ant-pagination-item-active {
    border: 1px solid ${colors.primaryColor} !important;
  }
`;

export const StyledTitleH3 = styled(TitleH3)`
  margin-left: ${space.little};
`;

export const StyledRightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${space.zero} ${space.normal};
  button {
    margin: ${space.zero} ${space.slim};
  }
  svg {
    font-size: ${space.normal};
    margin-left: ${space.slim};
  }
`;

export const StyledDash = styled.div`
  margin-left: 25%;
`;

export const CenteredCard = styled(Card)`
  height: 70%;
  min-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const StyledLastModifiedDateWrapper = styled.div`
  display: flex;
  bottom: 6%;
  position: absolute;
  left: 34px;
`;

export const StyledLastModifiedDate = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: ${colors.lightOverlayBlue};
`;

export enum TaskStatus {
  pending = 'Pending',
  processed = 'Completed',
  inProgress = 'In Progress',
}

export enum TaskStates {
  pending = 'PENDING',
  active = 'ACTIVE',
  completed = 'COMPLETED',
  Completed = 'Completed',
}

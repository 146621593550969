import React, { FC, useEffect } from 'react';

import { Row, Col, Typography, Spin } from 'antd';
import { MessageOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';

import {
  InfoItemCol,
  TitleH3,
  StatusTag,
  UserAvatar,
  useRowGutter,
  OrganizationalChartComponent,
} from 'ui';
import { span } from 'ui/elements';
import { MessagesTypes, TType } from 'translations';
import { ProfileData, USER_STATUS_MAP } from 'types';
import { MSTeamsConfirm } from 'routes/MSTeams/MSTeamsConfirm';
import {
  useDispatch,
  userStatusSelectors,
  useSelector,
  getUserStatusStart,
  getAvatarColor,
  getUserName,
} from 'store';

import { StyledSettingsCard } from 'routes/Settings/Settings.styled';
import {
  StyledAnchor,
  StyledEditIcon,
  StyledEditButton,
  UserName,
  StyedDivider,
  StyledBadge,
} from './ReadView.styled';

const { Text } = Typography;

export const FIELDS_TO_RENDER: (keyof ProfileData)[] = [
  'positionName',
  'department',
  'location',
  'managerName',
];

const HIDE_EDIT_INFO = process.env.REACT_APP_DISABLE_EDIT_PROFILE_INFO !== 'true';
const IMAGE_SIZE = 60;

interface Props {
  setIsEditing: (val: boolean) => void;
  data: ProfileData;
  t: TType;
}

export const ReadView: FC<Props> = ({ t, setIsEditing, data }) => {
  const switchToEditMode = () => setIsEditing(true);
  const dispatch = useDispatch();
  const userStatus = useSelector(userStatusSelectors);
  const avatarColor = useSelector(getAvatarColor);
  const userName = useSelector(getUserName);

  const GUTTER_12 = useRowGutter(12);
  const GUTTER_24 = useRowGutter(24);

  const { span16, span4, span24, span6, span10, span49 } = span;

  useEffect(() => {
    dispatch(getUserStatusStart());
  }, [dispatch]);

  const onConfirm = () => {
    window.open('msteams:');
  };

  return (
    <>
      <StyledSettingsCard>
        <Row>
          <Col>
            <Row gutter={GUTTER_24} align={'middle'}>
              <Col>
                {userStatus.data?.activity ? (
                  <StyledBadge
                    dot
                    offset={[-span10, span49]}
                    color={USER_STATUS_MAP[userStatus.data?.activity]?.color}
                  >
                    <UserAvatar
                      id={data.hcmPictureId}
                      size={IMAGE_SIZE}
                      status={data.onlineStatus}
                      color={avatarColor}
                      userName={userName}
                    />
                  </StyledBadge>
                ) : (
                  <Spin size={'small'} />
                )}
              </Col>
              <Col>
                <UserName>{`${data.firstName} ${data.lastName}`}</UserName>
              </Col>
              <Col>
                {userStatus.data?.activity ? (
                  <StatusTag color={USER_STATUS_MAP[userStatus.data?.activity]?.color}>
                    {t(USER_STATUS_MAP[userStatus.data?.activity]?.text)}
                  </StatusTag>
                ) : (
                  <Spin size={'small'} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <StyedDivider />
        <Row justify="space-between">
          <Col span={span16}>
            <Row justify="space-between">
              <Col>
                <StyledAnchor href={data.mobileNumber ? `tel:${data.mobileNumber}` : '#'}>
                  {data.mobileNumber || '-'} <PhoneOutlined />
                </StyledAnchor>
              </Col>
              <Col>
                <StyledAnchor href={data.email ? `mailto:${data.email}` : '#'}>
                  {data.email || '-'} <MailOutlined />
                </StyledAnchor>
              </Col>
              <Col>
                <StyledAnchor>
                  <MSTeamsConfirm onConfirm={onConfirm}>
                    {t('link.chat')} <MessageOutlined />
                  </MSTeamsConfirm>
                </StyledAnchor>
              </Col>
            </Row>
          </Col>
          {HIDE_EDIT_INFO && (
            <Col span={span6}>
              <Row gutter={GUTTER_12} justify="end">
                <Col>
                  <StyledEditButton onClick={switchToEditMode}>
                    <Text underline>{t('button.editPublicInfo')}</Text>
                    <StyledEditIcon />
                  </StyledEditButton>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </StyledSettingsCard>

      <StyledSettingsCard>
        <Row gutter={GUTTER_24}>
          <Col>
            <TitleH3 type="prime">{t('profile.title.jobDetails')}</TitleH3>
          </Col>
        </Row>
        <Col span={span24}>
          <Row gutter={GUTTER_24} justify="space-between">
            {FIELDS_TO_RENDER.map((value) => (
              <InfoItemCol
                span={span4}
                label={t(`user.publicProfile.${value}` as MessagesTypes)}
                key={value}
                mangerEmail={data.managerEmail}
                mangerPersonHcmid={data.managerPersonId?.toString()}
              >
                {data[value]}
              </InfoItemCol>
            ))}
          </Row>
        </Col>
      </StyledSettingsCard>

      <StyledSettingsCard className="fill-rest-height">
        <OrganizationalChartComponent hcmId={data.hcmId ?? ''} />
      </StyledSettingsCard>
    </>
  );
};

import React, { FC } from 'react';
import { Col, Typography } from 'antd';
import styled from 'styled-components';

import { TextStrong } from 'ui';
import { ColProps } from 'antd/lib/col';

import { UserManagerInfo } from './userManagerInfo';

const { Text } = Typography;

const ColStyled = styled(Col)`
  span {
    display: black;
  }
`;
interface Props {
  label: string;
  mangerPersonHcmid?: string;
  mangerEmail?: string | null;
}

export const InfoItemCol: FC<Props & ColProps> = ({
  label,
  mangerPersonHcmid,
  mangerEmail,
  children,
  span,
  ...rest
}) => {
  if (!label && !children) {
    return null;
  }

  return (
    <ColStyled {...rest} span={span}>
      <Text type="secondary">{label}</Text>
      {label === 'Manager' ? (
        <UserManagerInfo managerEmail={mangerEmail} managerHcmid={mangerPersonHcmid}>
          {children}
        </UserManagerInfo>
      ) : (
        <TextStrong ellipsis>{children || '-'}</TextStrong>
      )}
    </ColStyled>
  );
};

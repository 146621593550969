import { Col, Empty, Row } from 'antd';
import React, { useMemo } from 'react';
import { CarouselRef } from 'antd/lib/carousel';
import { FullWidthSpin } from 'ui/elements';
import { CarouselArrows } from '../CarouselArrow';
import { StyledCarouselCol, StyledTitleTask } from '../CardCarousel.styled';

interface CarouselWrapperProps {
  children: React.ReactNode;
  carouselTitle: string;
  emptyDescription: string;
  carouselEL: React.RefObject<CarouselRef>;
  scrollsCount: number;
  isEmpty?: boolean;
  isLoading?: boolean;
  cardLength?: number;
}

const CarouselWrapperComponent: React.FC<CarouselWrapperProps> = ({
  children,
  carouselTitle,
  cardLength,
  isEmpty,
  isLoading,
  emptyDescription,
  carouselEL,
  scrollsCount,
}) => {
  const isArrowAppear = useMemo(() => !isEmpty && !isLoading && cardLength !== 0, [
    cardLength,
    isEmpty,
    isLoading,
  ]);

  return (
    <React.Fragment>
      <Row justify={'space-between'}>
        <Col>
          <StyledTitleTask>{carouselTitle}</StyledTitleTask>
        </Col>
        <Col>
          {isArrowAppear && cardLength !== undefined ? (
            <CarouselArrows
              carouselEL={carouselEL}
              slidesLength={cardLength}
              scrollsCount={scrollsCount}
            />
          ) : null}
        </Col>
      </Row>
      <Row>
        <StyledCarouselCol $isEmpty={isEmpty} span={24}>
          {isEmpty === true ? (
            <Empty description={emptyDescription} image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : isLoading ? (
            <FullWidthSpin />
          ) : (
            children
          )}
        </StyledCarouselCol>
      </Row>
    </React.Fragment>
  );
};

export const CarouselWrapper = React.memo(CarouselWrapperComponent);

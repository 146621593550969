import React, { FC } from 'react';
import { useMSTeamsContext } from 'routes/MSTeams/MSTeamsProvider';
import { TeamsChannelsData } from 'types';
import { StyledTeamChannelRow } from '../MSTeamsTeamsSidebar.styled';

interface Props {
  channels?: TeamsChannelsData;
  teamId: string;
}

export const MSTeamsTeamsSidebarChannels: FC<Props> = ({ channels, teamId }) => {
  const { currentChannelId, setCurrentChannelId, setCurrentTeamId } = useMSTeamsContext();

  return (
    <>
      {channels?.map((channel) => (
        <StyledTeamChannelRow
          selected={currentChannelId === channel.id}
          onClick={() => {
            setCurrentChannelId(channel.id);
            setCurrentTeamId(teamId);
          }}
          key={channel.id}
        >
          {channel.displayName}
        </StyledTeamChannelRow>
      ))}
    </>
  );
};

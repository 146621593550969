import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { TitleH1 } from 'ui';
import { Header } from 'app';
import { AppLayout } from 'layouts';
import { useTranslate } from 'translations';
import { Notification, Logout } from './Components';

export const Settings: FC = () => {
  const { t } = useTranslate();
  return (
    <>
      <AppLayout displayFooter={false}>
        <Header>
          <Row align="middle">
            <Col>
              <TitleH1>{t('settings.title')}</TitleH1>
            </Col>
          </Row>
        </Header>
        <Notification />
        <Logout />
      </AppLayout>
    </>
  );
};

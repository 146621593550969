import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetProcessDefinitionListFail,
  GetProcessDefinitionListStart,
  GetProcessDefinitionListSuccess,
  getProcessDefinitionListStart,
  getProcessDefinitionListSuccess,
  getProcessDefinitionListFail,
} from './processDefinitionListSlice';

export const processDefinitionListEpic: Epic<
  GetProcessDefinitionListStart,
  GetProcessDefinitionListSuccess | GetProcessDefinitionListFail,
  RootState,
  typeof API
> = (action$, _, { processDefinition }) =>
  action$.pipe(
    filter(getProcessDefinitionListStart.match),
    exhaustMap(() =>
      from(processDefinition.getProcessDefinitonList()).pipe(
        map(({ data }) => getProcessDefinitionListSuccess(data)),
        catchError((error) => of(getProcessDefinitionListFail(error?.response?.data)))
      )
    )
  );

import { Col, Row, Space, Typography } from 'antd';
import React, { FC } from 'react';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { useTranslate } from 'translations';
import { UserPopover } from 'ui/components/UserPopover';
import { space } from '../../../elements';
import { getCurrentUserAdoid, useSelector } from '../../../../store';
import { RequestedStyled, StyledAssignedToCol, StyledTaskAvatar } from './TaskCard.styled';

export type CompletedBy = {
  adOid: string;
  email: string;
  name: string;
};

export interface CompletedByDetailProps {
  completedBy?: CompletedBy;
  assigneeId?: string;
}

export const CompletedByDetail: FC<CompletedByDetailProps> = ({
  completedBy,
  assigneeId,
}: CompletedByDetailProps) => {
  const { Text } = Typography;
  const { t } = useTranslate();
  const currentUserId = useSelector(getCurrentUserAdoid);

  const renderCompleteBy = () => {
    return (
      <React.Fragment>
        <Row gutter={[12, 12]} style={{ marginTop: space.slim }}>
          <Col>
            <RequestedStyled type={'secondary'}>{t('task.card.completedBy')}</RequestedStyled>
          </Col>
          <Col>
            <Space align={'baseline'}>
              <UserPopover currentID={completedBy?.adOid} currentEmail={completedBy?.email}>
                <StyledTaskAvatar
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <MSTeamsUserAvatar id={completedBy?.adOid} size={24} name={completedBy?.name} />
                </StyledTaskAvatar>
              </UserPopover>

              <Text type={'secondary'} ellipsis strong>
                <Row gutter={[6, 0]} wrap={false}>
                  <UserPopover currentID={completedBy?.name} currentEmail={completedBy?.email}>
                    <StyledAssignedToCol
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {completedBy?.name}
                    </StyledAssignedToCol>
                  </UserPopover>
                </Row>
              </Text>
            </Space>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  const renderCompletedByMe = () => {
    return (
      <RequestedStyled style={{ marginTop: space.moderate }} type={'secondary'}>
        {t('task.card.completedBy')}
        <Text style={{ marginLeft: space.little }} type={'secondary'} ellipsis strong>
          {t('task.card.me')}
        </Text>
      </RequestedStyled>
    );
  };

  const renderContent = () => {
    if (completedBy) {
      if (completedBy?.adOid === assigneeId) {
        /**
         * If Completed by user id is
         * same as assignee id then don't show anything
         */
        return null;
      } else if (completedBy?.adOid === currentUserId) {
        /**
         * If Completed by user id is
         * same as logged used id then show completed by me
         */
        return renderCompletedByMe();
      } else {
        /**
         * If case does not match
         * any of above then show
         * complete by the use
         */
        return renderCompleteBy();
      }
    } else {
      /**
       * If completed by object is not
       * available then don't show anything
       */
      return null;
    }
  };

  return <>{renderContent()}</>;
};

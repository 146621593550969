import React from 'react';
import { StayedTag, StyledTagsContainer } from './AnnouncementTags.styled';

export const AnnouncementTags: React.FC = () => {
  return (
    <StyledTagsContainer>
      {['Announcements', 'HR'].map((tag, index) => (
        <StayedTag key={index}>{tag}</StayedTag>
      ))}
    </StyledTagsContainer>
  );
};

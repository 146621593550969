import React, { FC, useCallback } from 'react';
import { Modal } from 'antd';
import { useTranslate } from 'translations';
import TaskDiagram from './BpmnDiagram';
import { StyledDiagramWrapper } from './DiagramModal.styled';

interface DiagramModalProps {
  xmlDiagram: string;
  isVisible: boolean;
  handleResetXml: () => void;
}

export const DiagramModal: FC<DiagramModalProps> = ({ xmlDiagram, handleResetXml, isVisible }) => {
  const { t } = useTranslate();

  const handleOnCancel = useCallback(() => {
    handleResetXml();
  }, [handleResetXml]);

  return (
    <>
      <Modal
        visible={isVisible}
        width={1000}
        onCancel={handleOnCancel}
        centered
        footer={null}
        title={t('task.processDiagram')}
      >
        <StyledDiagramWrapper>
          <TaskDiagram diagramXML={xmlDiagram} />
        </StyledDiagramWrapper>
      </Modal>
    </>
  );
};

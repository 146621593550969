import React, { FC, memo } from 'react';
import { Col, Row, Collapse } from 'antd';
import { TType } from 'translations';
import { StyledCollapse, StyledDrawerStep, StyledList } from './RequestCatalogDrawerStyle';
import { DEFAULT_COLLAPSE_KEY } from './constants';

const { Panel } = Collapse;

interface Props {
  prerequisites: string[];
  t: TType;
}

export const DrawerPrerequisitesComponent: FC<Props> = ({ prerequisites, t }) => (
  <Row>
    <Col span={24}>
      <StyledCollapse ghost defaultActiveKey={DEFAULT_COLLAPSE_KEY}>
        <Panel
          key="1"
          header={
            <StyledDrawerStep strong>
              {t('requestCatalog.drawer.prerequisites.title')}
            </StyledDrawerStep>
          }
        >
          <ul>
            {prerequisites.map((item) => (
              <StyledList key={item}>{item}</StyledList>
            ))}
          </ul>
        </Panel>
      </StyledCollapse>
    </Col>
  </Row>
);
export const DrawerPrerequisites = memo(DrawerPrerequisitesComponent);

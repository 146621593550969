import React from 'react';
import { Col, Empty, Row } from 'antd';
import { Searchbar } from 'ui/components';
import { useDebounce } from 'ui/hooks';
import { useTranslate } from 'translations';
import { FilterIcon } from 'assets';
import { EmployeeDirectoryUser } from 'types';
import { FullWidthSpin } from 'ui';
import { EmployeeDirectoryUserCard } from '../EmployeeDirectoryUserCard/EmployeeDirectoryUserCard';
import { useEmployeeDirectorySearch } from '../hooks/useEmployeeDirectorySearch';
import {
  FilterIconContainer,
  StyledInfiniteScroll,
  UsersCardsContainer,
} from './EmployeeDirectorySidebar.styled';

type Props = {
  onEmployeeSelect: (employeeId: EmployeeDirectoryUser) => void;
};

export const EmployeeDirectorySidebar: React.FC<Props> = ({ onEmployeeSelect }) => {
  const { t } = useTranslate();
  const {
    searchForEmployee,
    resetEmployeeSearchResults,
    loadMoreSearchResults,
    searchResultList,
    hasMore,
    hasData,
    isLoading,
    isErrorEmployeeDirectorySearch,
  } = useEmployeeDirectorySearch();
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);
  const [selectedMember, setSelectedMember] = React.useState<number | null>(null);
  const onSearchChange = React.useCallback(
    (term: string) => {
      setSearchTerm(term);
    },
    [setSearchTerm]
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  React.useEffect(() => {
    resetEmployeeSearchResults();
    searchForEmployee(debouncedSearchTerm);
    setSelectedMember(null);
  }, [debouncedSearchTerm, resetEmployeeSearchResults, searchForEmployee]);
  const onSelectMember = React.useCallback(
    (index: number) => {
      if (searchResultList && searchResultList[index]) {
        const employee = searchResultList[index];
        onEmployeeSelect(employee);
        setSelectedMember(index);
      }
    },
    [searchResultList, onEmployeeSelect]
  );
  return (
    <>
      <Row align="middle">
        <Col span={19}>
          <Searchbar
            onChange={onSearchChange}
            isLoading={isLoading}
            placeholder={t('employeeDirectory.placeholder.search')}
          />
        </Col>
        <FilterIconContainer span={1} offset={4}>
          <FilterIcon />
        </FilterIconContainer>
      </Row>

      {!hasData && !isErrorEmployeeDirectorySearch ? (
        <UsersCardsContainer>
          <FullWidthSpin />
        </UsersCardsContainer>
      ) : isErrorEmployeeDirectorySearch ? (
        <UsersCardsContainer>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('employeeDirectory.placeholder.searchError')}
          />
        </UsersCardsContainer>
      ) : (
        searchResultList && (
          <StyledInfiniteScroll
            loadMore={() => loadMoreSearchResults(debouncedSearchTerm)}
            hasMore={hasMore}
            useWindow={false}
            loader={<FullWidthSpin key={0} />}
            initialLoad={false}
          >
            {searchResultList.map((employee, index) => (
              <EmployeeDirectoryUserCard
                key={`employee-${index}`}
                isSelected={selectedMember === index}
                onSelect={() => {
                  onSelectMember(index);
                }}
                user={employee}
              />
            ))}
          </StyledInfiniteScroll>
        )
      )}
    </>
  );
};

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  getChatsStart,
  getChatsSuccess,
  getChatsFail,
  GetChatsStart,
  GetChatsSuccess,
  GetChatsFail,
  getChatMessagesStart,
  getChatMessagesSuccess,
  getChatMessagesFail,
  GetChatMessagesStart,
  GetChatMessagesSuccess,
  GetChatMessagesFail,
  sendChatMessageStart,
  sendChatMessageSuccess,
  sendChatMessageFail,
  SendChatMessageStart,
  SendChatMessageSuccess,
  SendChatMessageFail,
  updateChatMessagesFail,
  updateChatMessagesStart,
  updateChatMessagesSuccess,
  createOneToOneChatStart,
  createOneToOneChatSuccess,
  createOneToOneChatFail,
  CreateOneToOneChatStart,
  CreateOneToOneChatSuccess,
  CreateOneToOneChatFail,
  createGroupChatStart,
  createGroupChatSuccess,
  createGroupChatFail,
  CreateGroupChatStart,
  CreateGroupChatSuccess,
  CreateGroupChatFail,
  addChatMemberStart,
  addChatMemberSuccess,
  addChatMemberFail,
  AddChatMemberStart,
  AddChatMemberSuccess,
  AddChatMemberFail,
} from './chatsSlice';

export const getChatsEpic: Epic<
  GetChatsStart,
  GetChatsSuccess | GetChatsFail,
  RootState,
  typeof API
> = (action$, _, { chats }) =>
  action$.pipe(
    filter(getChatsStart.match),
    exhaustMap(() =>
      from(chats.getChats()).pipe(
        map(({ data }) => getChatsSuccess(data)),
        catchError((error) => of(getChatsFail(error?.response?.data)))
      )
    )
  );

export const getChatMessagesEpic: Epic<
  GetChatMessagesStart,
  GetChatMessagesSuccess | GetChatMessagesFail,
  RootState,
  typeof API
> = (action$, store, { chats }) =>
  action$.pipe(
    filter(getChatMessagesStart.match),
    mergeMap(({ payload }) =>
      from(chats.getChatMessages(payload, store.value.profile?.data?.adOid)).pipe(
        map(({ data }) => getChatMessagesSuccess(data)),
        catchError((error) => of(getChatMessagesFail(error?.response?.data)))
      )
    )
  );

export const updateChatMessagesEpic: Epic<
  GetChatMessagesStart,
  GetChatMessagesSuccess | GetChatMessagesFail,
  RootState,
  typeof API
> = (action$, store, { chats }) =>
  action$.pipe(
    filter(updateChatMessagesStart.match),
    mergeMap(({ payload }) =>
      from(
        chats.getChatMessages(
          payload,
          store.value.profile?.data?.adOid,
          store.value.chats.data.chatMessages?.skipToken !== null
            ? store.value.chats.data.chatMessages?.skipToken
            : undefined
        )
      ).pipe(
        map(({ data }) => updateChatMessagesSuccess(data)),
        catchError((error) => of(updateChatMessagesFail(error?.response?.data)))
      )
    )
  );

export const sendChatMessageEpic: Epic<
  SendChatMessageStart,
  SendChatMessageSuccess | SendChatMessageFail,
  RootState,
  typeof API
> = (action$, _, { chats }) =>
  action$.pipe(
    filter(sendChatMessageStart.match),
    exhaustMap(({ payload }) =>
      from(chats.sendChatMessage(payload)).pipe(
        map(({ data }) => sendChatMessageSuccess(data)),
        catchError((error) => of(sendChatMessageFail(error?.response?.data)))
      )
    )
  );

export const createOneToOneChatEpic: Epic<
  CreateOneToOneChatStart,
  CreateOneToOneChatSuccess | CreateOneToOneChatFail,
  RootState,
  typeof API
> = (action$, _, { chats }) =>
  action$.pipe(
    filter(createOneToOneChatStart.match),
    mergeMap(({ payload }) =>
      from(chats.createOneToOneChat({ params: payload.params })).pipe(
        map(({ data }) => createOneToOneChatSuccess({ data, chatMembers: payload.chatMembers })),
        catchError((error) => of(createOneToOneChatFail(error?.response?.data)))
      )
    )
  );

export const createGroupChatEpic: Epic<
  CreateGroupChatStart,
  CreateGroupChatSuccess | CreateGroupChatFail,
  RootState,
  typeof API
> = (action$, _, { chats }) =>
  action$.pipe(
    filter(createGroupChatStart.match),
    mergeMap(({ payload }) =>
      from(chats.createGroupChat(payload.params)).pipe(
        map(({ data }) => createGroupChatSuccess({ data, chatMembers: payload.chatMembers })),
        catchError((error) => of(createGroupChatFail(error?.response?.data)))
      )
    )
  );

export const addChatMemberEpic: Epic<
  AddChatMemberStart,
  AddChatMemberSuccess | AddChatMemberFail,
  RootState,
  typeof API
> = (action$, _, { chats }) =>
  action$.pipe(
    filter(addChatMemberStart.match),
    mergeMap(({ payload }) =>
      from(chats.addChatMember({ chatId: payload.chatId, params: payload.params })).pipe(
        map(() => addChatMemberSuccess()),
        catchError((error) => of(addChatMemberFail(error?.response?.data)))
      )
    )
  );

import { BalanceData } from 'types';
import { Core, BALANCE_INFO } from 'api';

interface GetBalance {
  (userId: string): Promise<{ data: BalanceData }>;
}

class Balance extends Core {
  getBalance: GetBalance = async (userId) => this.get(BALANCE_INFO(userId));
}

export const balance = new Balance();

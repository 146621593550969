import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const tasksList = (state: RootState): RootState['tasksList'] => state.tasksList;

export const tasksListData = createSelector(tasksList, ({ error, status, data, hasMore }) => ({
  error,
  status,
  data,
  hasMore,
}));

import { useQuery } from 'react-query';
import { PERSIST_QUERY_CONFIG, QUERIES } from 'api';
import { AxiosRequestConfig } from 'axios';
import { duration } from '../travelDuration';

export const useGetTravelDuration = (config?: AxiosRequestConfig) => {
  return useQuery(
    [QUERIES.travelDuration, config?.params],
    async () => {
      const { data } = await duration.getTravelDuration(config);
      return data;
    },
    {
      enabled: false,
      ...PERSIST_QUERY_CONFIG,
    }
  );
};

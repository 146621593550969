import React from 'react';
import { useTasksContext } from 'routes';
import { StyledReassignIcon } from 'ui/components/TaskList/TaskCard/TaskCard.styled';
import { ReassignTask } from '../../ReassignTask';
import { StyledButtonWithOutText } from '../../RequestHeader/RequestHeader.style';

interface Props {
  groupId: string | undefined;
  reloadTaskList: (() => void) | undefined;
  taskId: string | undefined;
}

const ReassignButtonComponent: React.FC<Props> = ({ groupId, reloadTaskList, taskId }) => {
  const { currentActiveTab } = useTasksContext();

  return (
    <React.Fragment>
      {(currentActiveTab === 'work-0' || currentActiveTab === 'work-1') && groupId !== undefined && (
        <ReassignTask taskId={taskId} groupId={groupId} reassignCallback={reloadTaskList}>
          <StyledButtonWithOutText>
            <StyledReassignIcon />
          </StyledButtonWithOutText>
        </ReassignTask>
      )}
    </React.Fragment>
  );
};

export const ReassignButton = React.memo(ReassignButtonComponent);

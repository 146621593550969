import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetMyGroupTaskCardsFail,
  GetMyGroupTaskCardsStart,
  GetMyGroupTaskCardsSuccess,
  getMyGroupTaskCardsFail,
  getMyGroupTaskCardsStart,
  getMyGroupTaskCardsSuccess,
} from './myGroupTaskCardsSlice';

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

export const MyGroupTaskCardsEpic: Epic<
  GetMyGroupTaskCardsStart,
  GetMyGroupTaskCardsSuccess | GetMyGroupTaskCardsFail,
  RootState,
  typeof API
> = (action$, _, { servicesCards }) =>
  action$.pipe(
    filter(getMyGroupTaskCardsStart.match),
    exhaustMap((action) =>
      from(
        disableAggregator
          ? servicesCards.getMyGroupTaskCard(action.payload.userId, action.payload.params)
          : servicesCards.getMyGroupTaskCardsAgg(action.payload.params)
      ).pipe(
        map(({ data }) => getMyGroupTaskCardsSuccess(data)),
        catchError((error) => of(getMyGroupTaskCardsFail(error?.response?.data)))
      )
    )
  );

import styled from 'styled-components';
import { Row } from 'antd';
import { colors, space, TitleH3 } from 'ui/elements';
import { Link } from 'react-router-dom';

export const StyledItemWrapper = styled(Row)`
  margin-bottom: ${space.comfy};
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledRow = styled(Row)`
  justify-content: space-between;
  width: 100%;
`;
export const StyledBankDetailsInfoSectionTitle = styled(TitleH3)`
  margin-bottom: 20px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: ${colors.labelGrey} !important;
`;

export const StyledEditBankDetailsLink = styled(Link)`
  margin-left: auto;
  color: ${colors.labelGrey};
`;

export const StyledAttachmentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledAttachmentHeader = styled.div`
  margin-top: 10px !important;
  display: block;
  color: ${colors.textColorSecondary} !important;
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Tasks, RequestCommonError, RequestStatus } from 'types';
import { AxiosRequestConfig } from 'axios';
import { TaskListState } from './taskListTypes';

export const emptyTaskListState: TaskListState = {
  status: RequestStatus.idle,
  error: null,
};

export type GetTaskListStart = PayloadAction<AxiosRequestConfig>;
export type GetTaskListSuccess = PayloadAction<Tasks>;
export type GetTaskListFail = PayloadAction<RequestCommonError>;

const taskList = createSlice({
  name: 'taskList',
  initialState: emptyTaskListState,
  reducers: {
    getTaskListStart(state, _: GetTaskListStart) {
      state.status = RequestStatus.pending;
    },
    getTaskListSuccess(state, action: GetTaskListSuccess) {
      state.status = RequestStatus.resolved;
      state.data = action.payload;
    },
    getTaskListFail(state, action: GetTaskListFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
  },
});

export const { getTaskListFail, getTaskListStart, getTaskListSuccess } = taskList.actions;
export default taskList.reducer;

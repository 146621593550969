import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  GetCategoriesTreeFail,
  GetCategoriesTreeStart,
  GetCategoriesTreeSuccess,
  getCategoriesTreeStart,
  getCategoriesTreeSuccess,
  getCategoriesTreeFail,
} from './categoriesTreeSlice';

export const getCategoriesTreeEpic: Epic<
  GetCategoriesTreeStart,
  GetCategoriesTreeSuccess | GetCategoriesTreeFail,
  RootState,
  typeof API
> = (action$, _, { categoriesTree }) =>
  action$.pipe(
    filter(getCategoriesTreeStart.match),
    exhaustMap(() =>
      from(categoriesTree.getCategoriesTree()).pipe(
        map(({ data }) => getCategoriesTreeSuccess(data)),
        catchError((error) => of(getCategoriesTreeFail(error?.response?.data)))
      )
    )
  );

import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';

import { RootState } from '../reducers';
import {
  GetTeamsChannelFilesStart,
  GetTeamsChannelFilesSuccess,
  GetTeamsChannelFilesFail,
  getTeamsChannelFilesStart,
  getTeamsChannelFilesSuccess,
  getTeamsChannelFilesFail,
} from './teamsChannelFilesListSlice';

export const getTeamsCHannelFilesEpic: Epic<
  GetTeamsChannelFilesStart,
  GetTeamsChannelFilesSuccess | GetTeamsChannelFilesFail,
  RootState,
  typeof API
> = (action$, _, { teamsChannelFileList }) =>
  action$.pipe(
    filter(getTeamsChannelFilesStart.match),
    mergeMap(({ payload }) =>
      from(teamsChannelFileList.getTeamChannelFilesList(payload.teamId, payload.channelId)).pipe(
        map(({ data }) =>
          getTeamsChannelFilesSuccess({
            teamId: payload.teamId,
            channelId: payload.channelId,
            data,
          })
        ),
        catchError((error) =>
          of(
            getTeamsChannelFilesFail({
              teamId: payload.teamId,
              channelId: payload.channelId,
              error: error?.response?.data,
            })
          )
        )
      )
    )
  );

import React from 'react';
import styled from 'styled-components';
import { borderRadius, FullWidthSpin } from 'ui/elements';
import { useAnnouncementsImage } from 'api';

interface AnnouncementImageProps {
  imageURL: string;
}

const AnnouncementImageStyled = styled.img`
  border-radius: ${borderRadius.xLarge} !important;
  height: 100%;
  object-fit: cover;
`;

export const AnnouncementImage: React.FC<AnnouncementImageProps> = ({ imageURL }) => {
  const imageMutation = useAnnouncementsImage();
  React.useEffect(() => {
    imageMutation.mutate(imageURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageURL]);
  return imageMutation.data ? (
    <AnnouncementImageStyled crossOrigin="use-credentials" src={imageMutation.data} />
  ) : (
    <FullWidthSpin />
  );
};

import React, { FC } from 'react';
import { Col } from 'antd';
import styled from 'styled-components';
import { ColProps } from 'antd/lib/col';

import { UserManagerInfoV2 } from './userManagerInfoV2';
import { StyledInfoColumnTitle, StyledInfoColumnValue } from './InfoItemColV2.styled';

const ColStyled = styled(Col)`
  span {
    display: black;
  }
`;
interface Props {
  label: string;
  mangerPersonHcmid?: string;
  mangerEmail?: string | null;
}

export const InfoItemColV2: FC<Props & ColProps> = ({
  label,
  mangerPersonHcmid,
  mangerEmail,
  children,
  span,
  ...rest
}) => {
  if (!label && !children) {
    return null;
  }

  return (
    <ColStyled {...rest} span={span}>
      <StyledInfoColumnTitle type="secondary">{label}</StyledInfoColumnTitle>
      {label === 'Manager' ? (
        <UserManagerInfoV2 managerEmail={mangerEmail} managerHcmid={mangerPersonHcmid}>
          {children}
        </UserManagerInfoV2>
      ) : (
        <StyledInfoColumnValue ellipsis>{children || '-'}</StyledInfoColumnValue>
      )}
    </ColStyled>
  );
};

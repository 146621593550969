import React, { useCallback, useEffect } from 'react';
import { AppLayout } from 'layouts';
import { useLocation } from 'react-router';
import { Header, useServicesContext } from 'app';
import { useTranslate } from 'translations';
import { Requests, TitleH1 } from 'ui';
import { Col, Row } from 'antd';

import { profileData, useSelector } from 'store';
import { PagesUrls } from 'namespace';
import { useHistory } from 'react-router-dom';
import { TaskCommentsDrawer } from 'ui/components/TaskCommentsDrawer';
import { RequestCatalog } from '../components';
import { StyledTab } from '../Tasks.styled';
import { useTasksContext } from '../TasksProvider';

const { TabPane } = StyledTab;

export const TasksLayout = () => {
  const { t } = useTranslate();
  const { currentActiveTab, setCurrentActiveTab } = useTasksContext();
  const { data } = useSelector(profileData);
  const location = useLocation<{ defaultTab: string }>();
  const history = useHistory();
  const { setPrettyIdInfoRedirection, setIsFilterByPrettyId } = useServicesContext();

  const onTabClick = useCallback(
    (value: string) => () => {
      setCurrentActiveTab(value);
      setIsFilterByPrettyId(false);
      setPrettyIdInfoRedirection({});
      history.replace(PagesUrls.Tasks);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setCurrentActiveTab]
  );

  useEffect(() => {
    if (location?.state?.defaultTab) {
      setCurrentActiveTab(location?.state?.defaultTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.defaultTab]);

  const openRequestCatalogTab = useCallback(() => {
    setCurrentActiveTab('1');
  }, [setCurrentActiveTab]);

  return (
    <AppLayout displayFooter={false}>
      <Header>
        <Row align="middle">
          <Col>
            <TitleH1>{t('tasks.services')}</TitleH1>
          </Col>
        </Row>
      </Header>

      <StyledTab activeKey={currentActiveTab} className="layout-main">
        <TabPane tab={<span onClick={onTabClick('0')}>{t('tasks.tab.Requests')}</span>} key="0">
          <Requests userId={data?.adOid} openRequestCatalogTab={openRequestCatalogTab} />
        </TabPane>

        <TabPane
          tab={<span onClick={onTabClick('1')}>{t('tasks.tab.serviceCatalog')}</span>}
          key="1"
        >
          <RequestCatalog />
        </TabPane>
      </StyledTab>

      <TaskCommentsDrawer />
    </AppLayout>
  );
};

import styled from 'styled-components';
import { Row, Typography, Col } from 'antd';
import { colors, typography } from 'ui/elements';

export const UserDrawerUserInfoDetailsContainer = styled(Row)`
  width: 100%;
  margin-top: 35px;
  margin-bottom: 25px;
`;

export const UserStatusContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const UserName = styled(Typography.Title)`
  color: ${colors.primaryColor} !important;
  font-size: ${typography.size.tera} !important;
  font-weight: ${typography.weight.bold} !important;
  margin-bottom: 12px !important;
  margin-top: 0 !important;
  line-height: 24px !important;
`;

export const UserEmail = styled(Typography.Paragraph)`
  color: ${colors.textColorSecondary} !important;
  font-size: ${typography.size.hecto} !important;
  font-weight: ${typography.weight.regular} !important;
  margin-bottom: 0 !important;
  line-height: 18px !important;
`;

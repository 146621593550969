import React, { FC, useCallback, useEffect, useState } from 'react';
import { HelpIcon } from 'assets';
import { Col, Empty, notification, Row } from 'antd';
import { TType } from 'translations';
import { useELibraryDocuments, useElibraryFilter } from 'api';
import { FullWidthSpin } from 'ui/elements';
import { FilterContextType } from 'ui/components/Filter/Filter.types';
import moment from 'moment';
import {
  StyledELibraryDepartmentContainer,
  StyledELibraryDepartmentHelp,
  StyledELibraryFilterContainer,
  StyledELibrarySectionContainer,
  StyledELibraryTitle,
  StyledELibraryTitleContainer,
} from '../ELibrary.styled';
import { ELibraryDocumentCard } from './ELibraryDocumentCard';
import { ELibraryDocumentDrawer } from './ELibraryDocumentDrawer';
import { ELibraryHelpDrawer } from './ELibraryHelpDrawer';
import { ELibraryFilter } from './ELibraryFilter';

type Props = {
  selectedDepartmentName?: string;
  selectedSectorName?: string;
  onFilterChange: (isFilterApplied: boolean) => void;
  t: TType;
};

export const ELibraryMainPage: FC<Props> = ({
  selectedDepartmentName,
  selectedSectorName,
  onFilterChange,
  t,
}) => {
  const [isHelpDrawerVisible, setHelpDrawerVisibility] = useState(false);
  const [isDocumentDrawerVisible, setDocumentDrawerVisible] = useState<boolean>(false);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [isFilterReset, setIsFilterReset] = useState<boolean>(false);
  const [selectedDocId, setSelectedDocId] = useState<string>('');
  const [filterValues, setFilterValues] = useState<FilterContextType['filterValues']>({});
  const { data: documents, isLoading, error } = useELibraryDocuments(selectedDepartmentName);
  const {
    mutate: filterElLibrary,
    data: filteredDocuments,
    isLoading: loadingFilter,
    isError: filterError,
  } = useElibraryFilter();

  const toggleDocumentDrawer = (isVisible: boolean) => {
    setDocumentDrawerVisible(isVisible);
  };

  const toggleHelpDrawer = (isVisible: boolean) => {
    setHelpDrawerVisibility(isVisible);
  };

  const onDocumentCardClick = (documentId: string) => {
    setSelectedDocId(documentId);
    toggleDocumentDrawer(true);
  };

  const setELibraryFilter = useCallback((values) => {
    setFilterValues(values);
  }, []);

  useEffect(() => {
    if (Object.keys(filterValues).length > 0) {
      const customFilterValue = { ...filterValues };
      if (filterValues['expiryDate']) {
        customFilterValue.expiryDate = ((filterValues['expiryDate'] as unknown) as moment.Moment)
          .utc(true)
          .format();
      }
      filterElLibrary(customFilterValue as Record<string, string>);
      setIsFilterApplied(true);
    } else {
      setIsFilterApplied(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  useEffect(() => {
    onFilterChange(isFilterApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterApplied]);

  useEffect(() => {
    if (selectedDepartmentName) {
      setIsFilterApplied(false);
      setIsFilterReset(true);

      setTimeout(() => {
        setIsFilterReset(false);
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartmentName]);

  useEffect(() => {
    if (error || filterError) {
      notification.error({
        message: 'Something went wrong',
      });
    }
  }, [error, filterError]);

  return (
    <StyledELibrarySectionContainer>
      <StyledELibraryFilterContainer>
        <ELibraryFilter
          resetFilter={isFilterReset}
          setFilterValues={setELibraryFilter}
          filterValues={filterValues}
        />
      </StyledELibraryFilterContainer>
      {isLoading || loadingFilter ? (
        <FullWidthSpin />
      ) : (selectedDepartmentName && selectedSectorName) || filteredDocuments ? (
        <StyledELibraryDepartmentContainer>
          <StyledELibraryTitleContainer>
            <StyledELibraryTitle>
              {isFilterApplied
                ? filterValues['department'] ?? t('eLibrary.filter.filteredTitle')
                : selectedDepartmentName}
            </StyledELibraryTitle>
            <StyledELibraryDepartmentHelp type="text">
              <HelpIcon onClick={() => toggleHelpDrawer(true)} />
            </StyledELibraryDepartmentHelp>
          </StyledELibraryTitleContainer>
          {(isFilterApplied && filteredDocuments && filteredDocuments?.length > 0) ||
          (!isFilterApplied && documents && documents?.length > 0) ? (
            <Row
              gutter={[
                { xs: 8, sm: 15, md: 20, lg: 24 },
                { xs: 8, sm: 15, md: 20, lg: 24 },
              ]}
              wrap={true}
            >
              {(isFilterApplied ? filteredDocuments : documents)?.map((document) => (
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} key={document.id}>
                  <ELibraryDocumentCard
                    documentId={document.documentId}
                    documentTitle={document.title}
                    departmentName={document.department ?? selectedDepartmentName}
                    sectorName={document.sector ?? selectedSectorName}
                    onCardClick={() => {
                      onDocumentCardClick(document.id);
                    }}
                  />
                </Col>
              ))}
              <ELibraryDocumentDrawer
                isVisible={isDocumentDrawerVisible}
                closeDrawer={() => toggleDocumentDrawer(false)}
                documentID={selectedDocId}
              />
            </Row>
          ) : (
            <Empty description={t('eLibrary.filter.filteredNoData')} />
          )}
        </StyledELibraryDepartmentContainer>
      ) : null}
      <ELibraryHelpDrawer
        t={t}
        isVisible={isHelpDrawerVisible}
        onClose={() => toggleHelpDrawer(false)}
      />
    </StyledELibrarySectionContainer>
  );
};

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Typography } from 'antd';
import { typography } from 'ui/elements';
import { TextProps } from 'antd/lib/typography/Text';

const { Text } = Typography;

interface DateTextProps {
  date: string;
}

const StyledDate = styled(Text)`
  font-size: ${typography.size.kilo};
`;

const DateText: React.FC<DateTextProps & TextProps> = ({ date, ...rest }) => {
  return (
    <React.Fragment>
      <StyledDate {...rest}>{moment(date).format('DD/MM/YYYY HH:mm')}</StyledDate>
    </React.Fragment>
  );
};

export default DateText;

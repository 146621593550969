import { MyTeamList } from 'types';

export const myTeamList: MyTeamList[] = [
  {
    adOid: '65846d77-b0c7-479d-b016-341452ef0586',
    firstName: 'Moaaz ',
    lastName: 'Fadel',
    email: 'mfadel.c@tawal.com.sa',
    hcmId: '300000063239002',
    hireDate: new Date('2021-01-01'),
  },
  {
    adOid: 'f64e47cc-5551-4e7a-8138-f8ff48309fd0',
    firstName: 'Mohammed',
    lastName: 'AlRashed',
    email: 'mrashad.t@tawal.com.sa',
    hcmId: '300000014943995',
    hireDate: new Date('2021-06-01'),
  },
  {
    adOid: '4a7b3c3e-6924-472f-a201-3744d5c4cd3c',
    firstName: 'Randa',
    lastName: 'AlFowzan',
    email: 'rfowzan@tawal.com.sa',
    hcmId: '300000005431483',
    hireDate: new Date('2020-05-03'),
  },
];

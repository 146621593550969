import React, { FC, useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { Core, auth, core } from 'api';
import { useHistory, useLocation } from 'react-router-dom';
import { PagesUrls } from 'namespace';
import { AuthContext } from './AuthContext';

const showError = () =>
  notification.error({
    message: 'Something went wrong',
    description: 'Service is not available, please try again later',
  });

const REACT_APP_TOKEN_RENEWAL_INTERVAL = process.env.REACT_APP_TOKEN_RENEWAL_INTERVAL as string;
const tokenRenewalInterval: number = parseInt(REACT_APP_TOKEN_RENEWAL_INTERVAL, 10) || 3540; // default 59 minutes

export const AuthProvider: FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(null);
  const [redirectPath, setRedirectPath] = useState<string>('');
  const [requestedUrl, setRequestedUrl] = useState<string>('');
  const [tokenExpireTime, setTokenExpireTime] = useState<Date | null>(null);

  const history = useHistory();

  const location = useLocation();

  const { pathname, search } = location;

  useEffect(() => {
    setRedirectPath(`${pathname}${search}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Core.setInterceptor(() => {
      setIsAuthenticated(false);
      history.push(PagesUrls.Login);
    });
  }, [history]);

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;

    if (isAuthenticated) {
      timerId = setInterval(async () => {
        try {
          const { idToken, accessToken, expireTokenOn } = await auth.getAccessToken();
          core.setToken(idToken);
          core.setOnBehalfToken(accessToken);
          setTokenExpireTime(expireTokenOn);
        } catch (error) {
          showError();
          setIsAuthenticated(false);
        }
      }, tokenRenewalInterval * 1000);
    }

    return () => clearInterval(timerId);
  }, [isAuthenticated]);

  const getToken = useCallback(async (requestedUrl: string | undefined) => {
    auth
      .getAccessToken(['User.Read'])
      .then(({ accessToken, idToken, expireTokenOn }) => {
        if (idToken && accessToken) {
          core.setToken(idToken);
          core.setOnBehalfToken(accessToken);
          setIsAuthenticated(true);
          setTokenExpireTime(expireTokenOn);

          if (requestedUrl) setRequestedUrl(requestedUrl);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch(() => {
        showError();

        setIsAuthenticated(false);
      });
  }, []);

  React.useLayoutEffect(() => {
    setIsAuthenticated(null);

    auth
      .onRedirectCallback()
      .then(({ requestedUrl, accounts }) => {
        if (accounts.length) {
          getToken(requestedUrl);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch(() => {
        showError();

        setIsAuthenticated(false);
      });
  }, [getToken]);

  return (
    <AuthContext.Provider
      value={{
        login: auth.login,
        logout: auth.logout,
        isAuthenticated,
        redirectPath,
        requestedUrl,
        tokenExpireTime,
        getToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

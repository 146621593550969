import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const myRequestCards = (state: RootState): RootState['myRequestCards'] =>
  state.myRequestCards;

export const myRequestCardsSelector = createSelector(
  myRequestCards,
  ({ error, status, data, hasMore }) => ({
    error,
    status,
    data,
    hasMore,
  })
);

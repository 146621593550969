import { Badge, Col, Divider } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { useMarkNotificationAsRead } from 'api';
import { useServicesContext } from 'app';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { colors, span } from 'ui/elements';
import { OnAllUpdatedNotificationClickProps } from '../seeAllUpdates';
import {
  RequestIdStyled,
  WrapperBadge,
  WrapperIcon,
  WrapperNotification,
} from './NotificationDetail.style';
import { NotificationTemplate } from './NotificationTemplate';

interface Props {
  icon: ReactNode;
  requestId: string;
  requestName: string;
  titleNotification: string;
  messageNotification: string;
  hasComment: boolean;
  hasNotifications: boolean;
  commentName?: string | null;
  commentText?: string | null;
  processInstance: string | null;
  isLastElement: boolean;
  notificationType: string;
  refreshList: () => void;
  redirectionPage?: string;
  redirectionTab?: string;
  isRead: boolean;
  id: string;
  refreshBadge: () => void;
}

const { span4, span16, span24, span2 } = span;
const GUTTER_3_0: [Gutter, Gutter] = [3, 0];

const NotificationCardComponent: React.FC<Props> = ({
  icon,
  requestId,
  requestName,
  titleNotification,
  messageNotification,
  hasComment,
  hasNotifications,
  commentName,
  commentText,
  isLastElement,
  redirectionPage,
  redirectionTab,
  processInstance,
  refreshList,
  isRead,
  refreshBadge,
}) => {
  const { setIsFilterByPrettyId, setPrettyIdInfoRedirection } = useServicesContext();
  const isHaveRedirectionTabAndPage = useMemo(
    () => redirectionPage !== null && redirectionTab !== null,
    [redirectionPage, redirectionTab]
  );

  const mutation = useMarkNotificationAsRead(refreshBadge);

  const handleRedirection = useCallback(
    async (e) => {
      e.stopPropagation();
      if (isHaveRedirectionTabAndPage) {
        setPrettyIdInfoRedirection({
          taskPrettyId: requestId,
          redirectionPage: redirectionPage,
          redirectionTab: redirectionTab,
          processInstanceId: processInstance,
        });
        refreshList();
      } else {
        setPrettyIdInfoRedirection({});
        setIsFilterByPrettyId(false);
      }
      if (!isRead) await mutation.mutate(requestId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isHaveRedirectionTabAndPage, processInstance, redirectionPage, redirectionTab, requestId]
  );

  const handleOnSeeAllUpdatesClick = React.useCallback(async () => {
    if (!isRead) {
      await mutation.mutate(requestId);
    }
    return;
  }, [isRead, mutation, requestId]);

  const onAllUpdatedNotificationClick = async (pops: OnAllUpdatedNotificationClickProps) => {
    refreshList();
    setPrettyIdInfoRedirection({
      taskPrettyId: pops.requestId,
      redirectionPage: pops.redirectionPage,
      redirectionTab: pops.redirectionTab,
      processInstanceId: pops.processInstanceId,
    });
    if (!isRead) {
      await mutation.mutate(pops.requestId);
    }
  };

  return (
    <React.Fragment>
      <WrapperNotification
        justify={'space-between'}
        gutter={GUTTER_3_0}
        $isCanRedirection={isHaveRedirectionTabAndPage}
        onClick={handleRedirection}
      >
        <WrapperBadge span={1}>{!isRead && <Badge color={colors.accentOrange} />}</WrapperBadge>
        <WrapperIcon xs={span24} xl={span2}>
          {icon}
        </WrapperIcon>
        <Col xs={span24} xl={span16}>
          <NotificationTemplate
            onAllUpdatedNotificationClick={onAllUpdatedNotificationClick}
            title={titleNotification}
            message={messageNotification}
            hasComment={hasComment}
            commentText={commentText}
            commenterName={commentName}
            hasNotifications={hasNotifications}
            requestId={requestId}
            requestName={requestName}
            onSeeAllUpdatesClick={handleOnSeeAllUpdatesClick}
          />
        </Col>
        <Col xs={span24} xl={span4}>
          <RequestIdStyled>{requestId}</RequestIdStyled>
        </Col>
      </WrapperNotification>
      {!isLastElement && <Divider />}
    </React.Fragment>
  );
};

export const NotificationCard = React.memo(NotificationCardComponent);

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const userGroup = (state: RootState): RootState['userGroup'] => state.userGroup;

export const userGroupData = createSelector(userGroup, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

import { useQuery } from 'react-query';
import { leaseSites } from '../LeaseSites';
import { QUERIES } from '../../constants';
import { mapResponseToSelectOptions } from './helpers';

export const useLeaseSitesFormDetails = () =>
  useQuery(
    [QUERIES.leaseSitesFormDetails],
    async () => {
      const { data } = await leaseSites.getFormDetails();
      return mapResponseToSelectOptions(data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

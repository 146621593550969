import { UserInfoData } from 'types';
import { Core, PROFILE_INFO } from 'api';

interface GetUserInfo {
  (userId: string): Promise<{ data: UserInfoData }>;
}

class UserInfo extends Core {
  getUserInfo: GetUserInfo = async (userId) => this.get(PROFILE_INFO(userId));
}

export const userInfo = new UserInfo();

import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';

import {
  GetProfileExpenseLookupFail,
  GetProfileExpenseLookupStart,
  GetProfileExpenseLookupSuccess,
  getProfileExpenseLookupFail,
  getProfileExpenseLookupStart,
  getProfileExpenseLookupSuccess,
} from './ProfileExpenseLookupSlice';

export const profileExpenseEpic: Epic<
  GetProfileExpenseLookupStart,
  GetProfileExpenseLookupSuccess | GetProfileExpenseLookupFail,
  RootState,
  typeof API
> = (action$, _, { profileExpenses }) =>
  action$.pipe(
    filter(getProfileExpenseLookupStart.match),
    exhaustMap(({ payload }) =>
      from(profileExpenses.getExpensesLookup()).pipe(
        map(({ data }) => getProfileExpenseLookupSuccess(data)),
        catchError((error) => of(getProfileExpenseLookupFail(error?.response?.data)))
      )
    )
  );

import { useMutation } from 'react-query';
import { paySlips } from '../PaySlip';

export function usePaySlipsPdfFile() {
  return useMutation(
    async ({
      userId,
      selectedMonth,
      selectedYear,
    }: {
      userId: string;
      selectedMonth: string;
      selectedYear: string;
    }) => {
      const { data } = await paySlips.getPaySlipsPdfFile(userId, selectedMonth, selectedYear);
      return data;
    }
  );
}

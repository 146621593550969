import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';

export const payslips = (state: RootState): RootState['payslips'] => state.payslips;

export const paySlipsData = createSelector(payslips, ({ error, status, data }) => ({
  error,
  status,
  data,
}));

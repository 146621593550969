import React, { FC } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import {
  Login,
  NotFound,
  Dashboard,
  MSTeams,
  MSPlanner,
  Tasks,
  OneDriveRoute,
  Calendar,
  ProcessManagement,
  AnnouncementDetail,
  MyWork,
  Expenses,
  Settings,
  EmploymentDetails,
  Attendance,
  EmployeeDirectory,
  LeaseSites,
  ELibrary,
  PublicProfile,
  PrivateProfile,
} from 'routes';
import { PagesUrls } from 'namespace';
import { RouteAppLayout, PrivateRouteAppLayout } from './RouteLayout';

export const AppRoutes: FC = () => {
  return (
    <Switch>
      <RouteAppLayout path={PagesUrls.Login} exact component={Login} displaySider={false} />
      <PrivateRouteAppLayout path={PagesUrls.Profile} component={PrivateProfile} />
      <PrivateRouteAppLayout path={`${PagesUrls.OtherUserProfile}/:id`} component={PublicProfile} />
      <PrivateRouteAppLayout path={PagesUrls.Dashboard} exact component={Dashboard} />
      <PrivateRouteAppLayout path={PagesUrls.MSTeams} exact component={MSTeams} />
      <PrivateRouteAppLayout path={PagesUrls.MSPlanner} component={MSPlanner} />
      <PrivateRouteAppLayout path={PagesUrls.MyWork} component={MyWork} />
      <PrivateRouteAppLayout path={PagesUrls.Tasks} component={Tasks} />
      <PrivateRouteAppLayout path={PagesUrls.Leaves} component={Calendar} />
      <PrivateRouteAppLayout path={PagesUrls.OneDrive} component={OneDriveRoute} />
      <PrivateRouteAppLayout path={PagesUrls.ProcessManagement} component={ProcessManagement} />
      <PrivateRouteAppLayout path={PagesUrls.Expenses} component={Expenses} />
      <PrivateRouteAppLayout path={PagesUrls.Settings} component={Settings} />
      <PrivateRouteAppLayout path={PagesUrls.Attendance} component={Attendance} />
      <PrivateRouteAppLayout path={PagesUrls.EmploymentDetails} component={EmploymentDetails} />
      <PrivateRouteAppLayout path={PagesUrls.EmployeeDirectory} component={EmployeeDirectory} />
      <PrivateRouteAppLayout path={PagesUrls.LeaseSites} component={LeaseSites} />
      <PrivateRouteAppLayout path={PagesUrls.ELibrary} component={ELibrary} />
      <PrivateRouteAppLayout path={PagesUrls.AnnouncementDetail} component={AnnouncementDetail} />
      <RouteAppLayout path={PagesUrls.NotFound} exact component={NotFound} />
      <Redirect to={PagesUrls.NotFound} />
    </Switch>
  );
};

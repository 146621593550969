import React, { ReactNode } from 'react';

import { AvatarProps } from 'antd/lib/avatar';

import {
  StyledAvatar,
  StyledWrapper,
} from 'routes/MSTeams/MSTeamsUserAvatar/MSTeamsUserAvatar.styled';
import { colors } from 'ui';

interface Pros {
  Icon: ReactNode;
}

export const ProcessIcon: React.FC<Pros & AvatarProps> = ({ Icon, size = 40, ...rest }) => {
  return (
    <StyledWrapper>
      <StyledAvatar
        style={{ backgroundColor: colors.skyLight }}
        icon={Icon}
        size={size}
        {...rest}
      />
    </StyledWrapper>
  );
};

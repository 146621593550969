import { TeamsChannelMembersData } from 'types';
import { Core, TEAMS_CHANNEL_MEMBERS } from '../Core';

interface GetTeamsChannelMembers {
  (teamId: string, channelId: string): Promise<{ data: TeamsChannelMembersData }>;
}

class TeamsChannelMembers extends Core {
  getTeamsChannelMembers: GetTeamsChannelMembers = async (teamId, channelId) =>
    this.get(TEAMS_CHANNEL_MEMBERS(teamId, channelId));
}

export const teamsChannelMembers = new TeamsChannelMembers();
